import React from "react";
import GlobalHeader from "./GlobalHeader";
import mobileAnimation from "./../img/mobile-animation.gif";

class ScreenFallback extends React.Component {
  render(){
    const updateClickstream = this.props.updateClickstream;
    return(
      <div className="screen_fallback">
        <GlobalHeader
          updateClickstream={updateClickstream}
        />
        <h1>Please rotate your device.</h1>
        <p>This experience is optimized for landscape mode.</p>
        <img className="rotate-message" src={mobileAnimation} alt="Please rotate your device"/>
      </div>
    )
  }
}

export default ScreenFallback;
