import React from "react"

class Character4Pool extends React.Component {
    render(){
        return(
<svg version="1.1" x="0px" y="0px" viewBox="0 0 204 583.5" enableBackground="new 0 0 204 583.5" id="character-4" className="character-animated">
<g id="arm">
	<g id="HkX3dz_2_">
		<g>
			<path fill="#D9DFE7" d="M90.5,102.9c-0.1,0-0.1-0.1-0.2-0.1c-0.4,0.7-0.8,1.4-1.2,2.1c0.1,0.1,0.2,0.2,0.4,0.1
				C90,104.5,90.6,103.8,90.5,102.9z"/>
			<path fill="#E5EAEF" d="M89.2,105c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0.3-0.4,0.7-0.6,1c0.1,0,0.1,0.1,0.2,0.1
				C89.2,105.9,89.3,105.6,89.2,105z"/>
			<path fill="#EBE2CF" d="M92.5,144.1c1.2,3.7,2.3,7.5,3.7,11.1c1,2.7,1.8,5.4,2.8,8.1c0.9,2.3,1.6,4.7,2.5,6.9
				c1.3,3.2,2.2,6.5,3.4,9.8c0.8,2,1.3,4,2,6c1.3,3.3,2.4,6.7,3.7,9.9c1,2.5,1.7,5.1,3.2,7.3c1.8,2.7,4,5,7.5,5.3
				c2.7,0.2,5.2-0.5,7.5-1.9c2.5-1.4,5.1-2.9,7.1-5c2-2.2,4.2-4.3,6-6.8c1.2-1.6,2.5-3.3,3.8-4.9c2.1-2.6,4.2-5.2,6.2-7.8
				c3.1-4.1,6.2-8.2,9.1-12.4c2.4-3.5,5-6.8,7.5-10.2c2.9-4.1,6.1-7.9,8.8-12.1c1.7-2.6,3.3-5.3,5.4-7.6c1.9-2,4.2-3.4,6.7-4.6
				c3.7-1.8,7.2-3.7,9.4-7.4c1.5-2.4,2.7-4.9,4.1-7.3c0.8-1.4,1-2.4,0.1-3.7c-0.3-0.4,0.1-0.8,0.3-1.1c1-2,0.8-2.7-1-3.9
				c-0.2-0.1-0.3-0.2-0.5-0.4c-1.2-1.3-2-1.4-3.5-0.6c-1,0.5-1.9,1.2-2.6,2.1c-1.7,2.3-3.4,4.6-5.5,6.6c-2,1.9-4.5,3.3-6.9,4.7
				c-0.6,0.3-0.6-0.2-0.5-0.5c0.1-1.6,0.2-3.2,0.3-4.8c0.1-1-0.1-2.1-0.6-3c-0.2-0.5-0.4-1-1.1-0.9c-0.7,0.1-1,0.6-1,1.2
				c-0.1,0.9-0.5,1.8-0.7,2.6c-0.4,2-1.4,3.6-2.3,5.3c-1.4,2.8-3,5.6-3.6,8.8c-0.3,1.7-1.4,3-2.5,4.3c-3.6,3.8-7.3,7.5-11,11
				c-2.3,2.2-4.3,4.6-6.6,6.7c-4.2,3.7-8,7.9-12.2,11.5c-3.4,2.9-6.5,6.1-9.9,8.9c-1,0.9-2,1.8-3.1,2.6c-0.8,0.6-1.4,0.5-1.9-0.5
				c-1-1.9-1.1-4-1.6-6c-0.4-1.8-0.9-3.6-1.1-5.5c-0.5-3.7-1.5-7.3-2.3-11c-1-4.5-2.1-8.9-3.2-13.4c-0.6-2.7-1.2-5.4-2.1-8
				c-0.4-0.4-0.8-0.1-1.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1,0-0.3,0.1-0.4,0.1c-0.5,0.1-1,0.1-1.5,0.4
				c-0.4,0.2-0.8,0.2-1.2,0.3c-1,0.3-2,0.6-3,0.9c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.2-0.5,0.3-0.8,0.3c-1.5,0.4-3,0.9-4.5,1.4
				c-0.4,0.2-0.9,0.2-1.3,0.3c-1.9,1-4,1.7-5.8,2.7C93.6,141.5,92.7,142.5,92.5,144.1z"/>
			<path fill="#BBC8D6" d="M108.1,135.8c1.1-0.2,2.1-0.4,3.1-1c0.4-0.1,0.7-0.9,1.2-0.2c0.5-0.1,1.1-0.3,1.6-0.4
				c0.1-0.1,0.1-0.2,0.1-0.3c0-0.3-0.2-0.6,0.2-0.7c0.4-0.2,0.5,0.2,0.7,0.5c0.1,0.1,0.2,0.2,0.3,0.2c0.4,0,0.8-0.1,1.2-0.1
				c0,0,0-0.1,0-0.1c0.4-0.6-1.4-0.8-0.3-1.6c-0.5-0.6-0.4-1.5-1.3-1.9c-0.3-0.1-0.2-0.4,0.2-0.6c0.5-0.2,0.4-0.6,0.2-1
				c-0.2-0.2-0.2-0.4-0.2-0.7c-0.1-0.9-0.5-1.6-0.8-2.4c-0.3-0.3-0.5-0.1-0.7,0c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.1,0,0.1,0,0.2
				c-0.1,0-0.1-0.1,0-0.2c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.3-0.2-0.4c-0.3-0.3-0.3-0.8-0.8-1c-0.1,0-0.2-0.1-0.3-0.1
				c-0.3,0-0.7,0-1,0.1c-0.5,0.2-1,0.5-0.7,1.2c0.2,0.3,0.5,0.4,0.9,0.3c0.1,0,0.4-0.3,0.4,0c0,0.3-0.3,0.3-0.5,0.3
				c-0.3,0-0.5,0.1-0.7,0.2c-0.6,0.2-0.5,0.6-0.3,1.1c0,0.3-0.1,0.6-0.1,0.9c0,0.8-0.5-0.1-0.6,0.1c-0.4,0-0.9,0-0.7,0.7
				c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1c-0.1-0.2-0.1-0.3,0-0.5c0.1-0.4,0.4-0.7,0.6-1c0.1-0.3,0.1-0.7,0-1c-0.1-0.1-0.1-0.2-0.2-0.2
				c-0.4-0.3-0.7-0.6-0.5-1.1c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2,0-0.3,0-0.5,0.1c-0.2,0.2-0.4,0.5-0.5,0.8
				c-0.1,0.4-0.1,0.9-0.7,0.3c-0.1-0.1-0.4-0.1-0.5-0.1c-0.3,0.1-0.6,0-0.8-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0
				c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2,0-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.3,0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2
				c0.1-0.4,0.4-0.7,0.6-1.1c0-0.2,0-0.3-0.1-0.5c-0.2-0.9,0.6-0.3,0.8-0.6c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.6,0.1-1.2-0.3-1.7
				c-0.1-0.2-0.3-0.3-0.5-0.4c-0.3-0.2-0.9,0-0.8-0.7c0.4,0.1,0.5,0.4,0.8,0.5c1,0.1,1.1-0.4,0.9-1.1c-0.1-0.3-0.3-0.5-0.4-0.7
				c0-0.4-0.8,0.2-0.5-0.5c0-0.2-0.1-0.4-0.2-0.6c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.2-0.6-0.6-0.7c-0.3,0-0.6,0-0.8-0.3
				c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.3-0.4-0.4-0.6c-0.2-0.1-0.4,0-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.1-0.2-0.2-0.2
				c-0.1-0.2-0.3-0.3-0.4-0.5c0.2-0.3,0.4,0,0.6,0c0.1,0,0.2,0,0.3,0c0.6-0.3,1.1-0.1,1.5,0.4c0.1,0.1,0.3,0.2,0.4,0.2
				c0.1,0,0.2,0,0.3-0.1c0.3-0.3,0.6-0.6,0.8-1c0.3-0.5-0.3-1-0.1-1.5c0.2-0.5,0.7-0.9,0.4-1.5c-0.1-0.1-0.1-0.2-0.2-0.3
				c-0.2-0.2-0.6-0.3-0.8-0.4c-0.1,0-0.1,0-0.2,0c-0.2,0-0.2-0.2-0.1-0.1s0.2-0.1,0.1,0c0.2,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.5-0.2
				c0.2-0.4,0-0.7-0.1-1.1c-0.2-0.8,0.5-1.2,0.6-1.9c0.2-0.4,0.2-0.7-0.3-1c0,0-0.1,0-0.1,0c-0.3,0.1-0.5-0.1-0.7-0.2
				c-0.2-0.1-0.3-0.1-0.5-0.1c-0.2,0.2-0.2,0.4-0.2,0.6c0.1,0.3,0.1,0.4-0.3,0.4c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.1,0,0.2
				c-0.2-0.1-0.1-0.3,0-0.5c0-0.1,0-0.2,0-0.3c0-0.7-0.5-0.9-1.1-0.9c-0.2,0.1-0.3,0.3-0.4,0.5c0,0.3,0,0.6-0.1,0.9
				c-0.1,0.2,0,0.5-0.2,0.7c-0.1,0-0.1,0.1-0.2,0.1c0-0.1,0.1-0.1,0.2-0.2c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.1-0.2-0.3-0.3-0.4
				c-0.3-0.2-0.5-0.4-0.2-0.8c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0-0.5-0.2-0.7c-0.1-0.1-0.2-0.1-0.2-0.2c0.2,0,0.3,0.2,0.5,0.1
				c0.4,0,0.6-0.3,0.7-0.6c0.2-0.4,0.3-0.8,0.3-1.3c0-0.8-0.5-1.4-0.6-2.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.2-0.3-0.2-0.4-0.4
				c-0.1-0.1-0.7,0.4-0.4-0.4c0.1-0.3-0.7-0.2-1.1-0.4c-2.2,0.5-4.2,1.4-6.1,2.6c-1.3,0.8-2.5,1.8-3.4,2.9c-0.4,0.7-0.8,1.4-1.2,2.1
				c-0.2,0.3-0.4,0.7-0.6,1c-0.3,1.4-1.2,2.5-1.7,3.8c-0.7,1.8-0.9,3.7-1.2,5.6c-0.4,2.4,0.3,4.7,0.8,7c0.8,3.4,1.7,6.7,2.7,10
				c0.3,1.1,0.6,2.2,1.1,3.3c0.9,2.1,1.2,4.3,1.4,6.6c0,0.6,0.1,1.3,0.3,1.9c0.3,0.8,0.4-0.1,0.6-0.1c0.9-2.8,3.5-3.6,5.8-4.6
				c0.9-0.4,1.8-0.8,2.6-1.4c0.2-0.2,0.4-0.1,0.7-0.1c0.2,0,0.3-0.5,0.5,0c1.5-0.6,3.1-0.8,4.5-1.5c0.2,0,0.4,0,0.5-0.3
				c0-0.1,0-0.2,0-0.3c0-0.4,0.1-0.9,0.1-1.3c0-0.1-0.1-0.1-0.1-0.1c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.3,0.3,0.4,0.5
				C107.9,135.2,108.2,135.4,108.1,135.8z"/>
			<path fill="#BCC9D7" d="M106,116.9c-0.1,0.3-0.3,0.4,0.1,0.8c0.5,0.5,0.4,0.7-0.5,0.9c-0.2,0.2-0.2,0.3,0,0.5
				c0.1,0.2,0.2,0.3,0.3,0.5c0.4,0.1,0.6-0.4,1-0.4c0.2,0.1,0.4,0.2,0.7,0.3c0.4-0.1,0.4,0.3,0.6,0.5c0.1,0.1,0.2,0.1,0.3,0.1
				c0-0.4,0.2-0.3,0.5-0.3c0.2-0.2,0.2-0.4,0.2-0.6c0.2-0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0c0.4-0.1,0.6-0.4,0.9-0.8
				c0.1-0.2,0.2-0.4,0.2-0.6c0-0.6,0.4,0.2,0.5-0.1c0.4-0.2,1,0.1,1.3-0.4c0-0.9-0.5-1.7-0.7-2.6c-0.3-0.2-0.6-0.4-0.8-0.7
				c-0.2-0.2-0.4-0.3-0.7-0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.1-1-0.2-0.9-0.9c0-0.2,0-0.3-0.2-0.4c-0.2-0.1-0.4-0.1-0.7-0.1
				c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.7-0.7,0.8-1.3,0.9c-0.2,0.1-0.3,0.1-0.4,0.3c-0.1,0.1-0.1,0.2,0,0.3c0,0.2,0.5,0.1,0.2,0.5
				c-0.1,0-0.1,0.1-0.2,0.2c0,0.4,0.1,0.8,0,1.2c0,0.3,0,0.6-0.1,0.9C106,116.8,106,116.9,106,116.9z"/>
			<path fill="#BCC9D7" d="M108.8,119.6c-0.4-0.2-0.4,0-0.4,0.3c-0.5,0.3,0.6,0.4,0.1,0.6c-0.1,0.2-0.2,0.3-0.1,0.5
				c0.2,0.2,0.1,0.5,0.1,0.7c0.2,0.4,0.3,0.8,0.7,1c0.3,0.2,0.7,0.1,1,0.2c0.3,0.2,0,0.2-0.1,0.3c-0.9,0.5-1.1,1.3-1.3,2.2
				c-0.1,0.3,0,0.6,0.1,0.8c0.1,0,0.1,0.1,0.2,0.1c0.8,0.2,0.2,0.5,0.1,0.8c-0.4,0.2-1,0.3-0.6,1.1c-0.3,0.2-0.1,0.4,0,0.6
				c0,0.2,0.1,0.5,0.4,0.5c0.3,0,0.4-0.3,0.3-0.6c-0.4-0.8,0.3-0.2,0.5-0.4c0.3-0.2,0.4-0.6,0.5-0.9c0.1-0.2,0.3-0.4,0.5-0.5
				c0.1-0.3-0.9-0.5-0.1-0.9c0.3-0.4,0-0.8-0.1-1.2c0-0.5,0.5-0.7,0.8-0.9c0.3-0.4,0.6,0,0.9-0.1l0,0c0.8-0.1,0.7,0.7,1,1.1
				c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.3,0.2,0.4,0.2c0.2-0.1,0.5-0.1,0.7-0.2c0-0.2,0.1-0.4,0.1-0.4c-0.9-0.3-0.3-0.9-0.2-1.3
				c0.1-0.4-0.8-0.2-0.4-0.8c-0.1-0.6-0.4-1.2-0.1-1.8c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.5-0.1-0.9,0-1.4-0.1
				c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0.1-0.3,0c-0.1-0.2,0.2-0.2,0.2-0.3c0.3-0.2,0.7-0.4,0.9-0.7c0.1-0.2,0-0.4-0.2-0.6
				c-0.4-0.3-0.6-0.7-0.8-1.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.1-0.6,0-0.8,0.2c-0.3,0.5-0.7,0.8-1.2,0.9
				C109.1,119.4,109,119.6,108.8,119.6z"/>
			<path fill="#C4CFDB" d="M106.1,105.1c-0.6,0.5-1.1,1.1-0.9,2c0.4,0.2,0.7,0.5,0.5,1c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1,0,0.2,0,0.3
				c0.1,0,0.1,0.1,0.2,0.1c0,0.2,0.2,0.3,0.2,0.4c0.2,0.2,0.2,0.5,0.2,0.8c0,0.1,0,0.2,0,0.3c0.1,0.3,0.2,0.6,0.5,0.8
				c0.4,0.1,0.6,0,0.8-0.4c0-0.3,0-0.7,0.2-1c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.2-0.6,0.3-0.8c0-0.1,0-0.2-0.1-0.3
				c-0.1-0.1-0.2-0.2-0.4-0.2c-0.4-0.1,0-0.3,0-0.4c0.1-0.3,0-0.5-0.3-0.7c-0.4,0.1-0.7,0.1-0.9-0.3c-0.9-0.5,0.1-1.2-0.2-1.8
				C106.3,105.2,106.2,105.1,106.1,105.1z"/>
			<path fill="#C0CCD9" d="M112.1,119.6c0.2,0.1,0.3,0.2,0.3,0.5c-0.2,0.2-0.1,0.3,0.1,0.4c0.2,0.1,0.5,0.1,0.6-0.2
				c0.2-1.1-0.3-2-0.8-3c-0.4,0.2-1-0.2-1.3,0.3c0,0.4,0.6,0.5,0.6,0.9C111.9,118.9,112.1,119.2,112.1,119.6z"/>
			<path fill="#C9D3DE" d="M102.6,100.6c-0.2,0.5-0.3,0.9-0.5,1.4c0.3,0.7,1,0.5,1.5,0.8c0.2,0.1,0.4,0.1,0.6,0
				c0.2-0.3-0.1-0.5-0.1-0.8c0.3-0.7-0.1-1-0.6-1.4C103.2,100.7,102.9,100.7,102.6,100.6z"/>
			<path fill="#BBC8D6" d="M106.3,105.2c-0.3,0.7-1.3,1.5,0.2,2c0.3,0.2,0.4,0,0.6-0.2c0.2-0.3,0.1-0.6,0-0.9
				C107.1,105.7,107.1,105.2,106.3,105.2z"/>
			<path fill="#CBD5DF" d="M107.7,109.6c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0.1,0.3,0.1,0.4c0.3,0.3-0.1,1,0.5,1.2c0.3,0,0.5-0.3,0.7-0.6
				c0.4-0.9,0.2-1.6-0.3-2.4c-0.2,0-0.4,0-0.4,0.3l0,0C108.1,109.1,108,109.3,107.7,109.6z"/>
			<path fill="#C6D1DC" d="M104.2,102.6c-0.2,0.1-0.4,0.1-0.5,0c-0.2,0.2-0.2,0.4-0.1,0.6c0.2,0.4,0.6,0.6,1,0.7
				c0.1,0.1,0.3,0.2,0.4,0.2c0.3-0.1,0.6,0.1,0.9-0.1c0,0,0,0,0,0c-0.2-0.4-0.4-0.8-0.6-1.2C104.9,102.6,104.6,102.6,104.2,102.6z"
				/>
			<path fill="#CCD6E0" d="M108.7,112.1c0,0.1,0.1,0.2,0.1,0.3c0.2,0.5,1,0.5,1,1.1c0.1,0,0.1,0.1,0.2,0.1c0.2-0.1,0.5,0.1,0.7-0.2
				c0.2-0.2,0.1-0.5,0.1-0.8c-0.2-0.4-0.4-0.5-0.8-0.4c-0.4,0.1-0.7-0.1-0.5-0.6c-0.1-0.2-0.3-0.1-0.4-0.1c-0.1,0.1-0.3,0.2-0.4,0.3
				C108.7,112,108.7,112.1,108.7,112.1z"/>
			<path fill="#C5D0DC" d="M109.2,111.8c0.1,0,0.2-0.1,0.4-0.1c0.4-0.1,0.5-0.3,0.3-0.7c-0.2-0.5-0.6-0.3-0.9-0.2
				c-0.4-0.1-0.6,0.1-0.6,0.5c-0.3,0.1-0.3,0.3-0.2,0.5C108.5,112,108.8,111.8,109.2,111.8z"/>
			<path fill="#BFCCD9" d="M108.2,108.7c0.1-0.1,0.3-0.2,0.4-0.3c-0.5-0.2-0.5-0.7-0.7-1.2c-0.1-0.4-0.3-0.9-0.8-1.1
				c0,0.2-0.1,0.5-0.1,0.7c0,0.2,0.1,0.4,0.3,0.4c0.3,0.2,0.3,0.4,0.2,0.7c0,0.2,0.1,0.4,0.3,0.5C107.9,108.6,108,108.7,108.2,108.7
				z"/>
			<path fill="#C3CFDB" d="M113.2,120.2c-0.3,0.1-0.6,0.2-0.7-0.2c-0.4,0.1-0.7,0.3-0.9,0.6c-0.2,0.3,0,0.4,0.3,0.4
				c0.1,0,0.2,0.1,0.2,0.1c0.4-0.2,1,0.3,1.1-0.5C113.2,120.6,113.3,120.4,113.2,120.2z"/>
			<path fill="#EBEEF2" d="M102.6,100.6c0.3,0.6,0.6,0.4,0.9-0.1c-0.2-0.1-0.6,0.1-0.6-0.4c0.1-0.7-0.4-1.1-0.8-1.5
				C101.9,99.4,102.4,99.9,102.6,100.6z"/>
			<path fill="#CBD5DF" d="M100,97.4c0.5,0.2,1.3,0.3,0.5,1.2c-0.1,0.1,0.1,0.3,0.2,0.4c0.2,0.1,0.2-0.1,0.2-0.2
				c0.1-0.8,0.6-0.3,0.9-0.2C101.4,97.9,101.2,96.8,100,97.4z"/>
			<path fill="#C3CEDB" d="M104.2,102.6c0.2,0.5,0.6,0.2,1,0.3c-0.2-0.5-0.5-0.9-1.2-0.9C103.8,102.2,103.8,102.2,104.2,102.6z"/>
			<path fill="#D1D9E3" d="M110.8,112.8c0,0.2-0.1,0.5-0.1,0.7c-0.1,0.3,0.1,0.4,0.2,0.7c0.1,0.5,0.5,0.4,0.8,0.5
				C111.5,114,111.1,113.4,110.8,112.8z"/>
			<path fill="#CDD6E0" d="M113.2,120.8c-0.2,0.5-0.9,0-1.1,0.5c0.5,0.6,0.9,0.1,1.4,0C113.3,121.1,113.3,120.9,113.2,120.8z"/>
			<path fill="#EEF0F3" d="M113.8,123c-0.2,0-0.5,0-0.5,0.1c-0.2,0.3,0.2,0.2,0.3,0.3c0.2,0.1,0.4,0.2,0.6,0.3
				C114.5,123.3,114,123.2,113.8,123z"/>
			<path fill="#CBD5DF" d="M115.1,128c-0.1,0.2-0.2,0.4-0.3,0.5c0.2,0,0.3,0.1,0.5,0.1C115.3,128.4,115.2,128.2,115.1,128z"/>
			<path fill="#CCD5DD" d="M115.3,133.9c-0.2-0.5-0.1-1.1-0.8-1.2c-0.4-0.1-0.6,0.2-0.8,0.5c-0.3,0.5,0.2,0.7,0.2,1.1
				c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.3,0.6-0.2C115.1,134,115.2,133.9,115.3,133.9z"/>
			<path fill="#E9ECF0" d="M106.9,136c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0,0.4-0.1c0.3,0.1,0.5,0.1,0.7-0.2c0.5-0.5-0.1-0.7-0.3-1
				C107,134.8,107.1,135.6,106.9,136z"/>
			<path fill="#E4E7EC" d="M102.1,137.9c-0.3-0.3,0.4-0.5,0.1-0.9c-0.3,0.5-0.7,0.8-1.4,0.6c-0.2,0,0,0.3,0.1,0.4
				C101.4,138.3,101.8,138.2,102.1,137.9z"/>
			<path fill="#F2F3F4" d="M112.3,134.7c-0.5-0.1,0.1-0.8-0.2-0.8c-0.5,0-0.7,0.5-0.9,0.9C111.6,135.1,111.9,134.9,112.3,134.7z"/>
			<path fill="#E3E5E3" d="M107.4,136c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0.1-0.3,0.2-0.4,0.3C107.1,136.7,107.4,136.7,107.4,136z"/>
			<path fill="#EDEFF1" d="M114.9,134c-0.3-0.4-0.3-0.4-0.6,0.2C114.5,134.2,114.8,134.1,114.9,134z"/>
			<path fill="#BFCCD9" d="M108.5,120.6c0.3,0.1,0.6,0.1,0.6-0.3c0-0.6-0.6-0.1-0.8-0.4c-0.1,0-0.1-0.1-0.2-0.1
				c-0.4,0-0.6-0.4-1.1-0.3c-0.6,0.2-0.9,0.5-0.6,1.2c0.1,0.2-0.1,0.3-0.3,0.4l0,0c-0.3,0.3-0.6,0.6-1,0.8c0,0.1,0.1,0.2,0.1,0.3
				c0.2,0.6-0.6,0.6-0.6,1c0,0.1-0.1,0.1-0.1,0.2c0,0.3-0.4,0.1-0.5,0.3c-0.3,0.2-0.7,0.3-0.5,0.8c0.3,0.2,0.8-0.1,0.8,0.5
				c0.1,0,0.2-0.1,0.4-0.1c0.4-0.1,0.6,0.3,0.9,0.3c0.1,0,0.2-0.1,0.3,0c0.3,0.1,0.3,0.9,0.9,0.5c0.4-0.3,0.1-0.7,0.2-1.1
				c-0.1-0.4-0.5-0.7-0.4-1.1c0.3-0.5,0.4-1.1,0.9-1.4c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.5
				C108.1,121.1,108.1,120.8,108.5,120.6z"/>
			<path fill="#C4D0DC" d="M109,125.6c0.2-0.9,0.7-1.6,1.3-2.3c0.1-0.2,0.1-0.3,0-0.5c-0.3-0.3-0.7,0-1.1-0.1
				c-0.7,0.6-0.9-0.5-1.5-0.4c-0.6,0.2-0.7,0.8-1,1.3c0.1,0.3,0.3,0.6,0.7,0.6c0.1,0,0.3-0.1,0.4-0.1c0.3,0.1,0.3,0.2,0.3,0.5
				c0,0.1,0.1,0.2,0.1,0.4C108.4,125.2,108.5,125.6,109,125.6z"/>
			<path fill="#BECBD8" d="M104.6,115c0.3,0.2,0.2,0.5,0.4,0.8c0.2,0,0.4,0,0.6-0.2c0.1-0.2,0.1-0.5,0.2-0.7
				c0.1-0.1,0.3-0.2,0.4-0.3l0,0c0.4-0.3-0.2-0.4-0.2-0.7l0,0c-0.1-0.9-0.8-0.9-1.5-0.8l0,0c-0.3,0.2-0.2,0.5-0.1,0.7
				c0.1,0.4-0.2,0.7-0.1,1.1C104.4,115,104.5,115,104.6,115z"/>
			<path fill="#F7F8FA" d="M102.9,118.5c-0.3,0-0.7-0.4-0.7,0c0,0.7-0.4,1.4,0,2c0.1,0.2,0.5,0.3,0.6-0.1
				C103.1,119.8,103.3,119.2,102.9,118.5z"/>
			<path fill="#F1F3F5" d="M106.2,121.2c0.3,0,1.1,0,0.7-0.4c-0.8-0.8,0.2-0.8,0.3-1.2c0-0.1,0-0.1,0-0.2c0-0.3-0.3-0.4-0.4-0.6
				c-0.8-0.4-0.4,0.8-1,0.8C106.3,120.1,105.9,120.7,106.2,121.2z"/>
			<path fill="#C5D0DC" d="M103.7,103.1c0-0.2,0-0.4,0-0.5c-0.3-0.7-1.2-0.1-1.6-0.6c0,0.3-0.1,0.5-0.4,0.6
				c-0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.3,0,0.5-0.1c0.3-0.2,0.6,0.3,0.9,0
				C103.7,103.3,103.7,103.2,103.7,103.1z"/>
			<path fill="#F4F6F8" d="M92.5,122.5c-0.7,0.8-0.7,1.5,0,1.5c0.4,0,0.9,0.4,1.2-0.1c0.2-0.5-0.5-0.5-0.6-0.9
				C93,122.7,93,122.4,92.5,122.5z"/>
			<path fill="#D2DAE3" d="M104.6,113c0.4,0.4,1.2,0.2,1.5,0.8c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.1,0.1-0.3
				c-0.2-0.4-0.6-0.6-1.1-0.5c0.5-0.2,1-0.4,1-1.1c-0.5-0.5-0.7,0.1-1,0.2C105,112.2,104.8,112.6,104.6,113z"/>
			<path fill="#EBEFF2" d="M105.5,111.9c0.4,0.3,0.6-0.3,1-0.3c0.2-0.1,0.3-0.2,0.3-0.4c0-0.3-0.1-0.6-0.4-0.8
				c-0.4,0-0.7-0.1-1.1-0.1C104.9,111,105.6,111.4,105.5,111.9z"/>
			<path fill="#E4E8ED" d="M95.3,126.2c0-0.5,0.7-0.4,0.8-0.9c0-0.4,0.6-0.5,0.3-1c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0.1-0.4,0-0.4-0.2
				c-0.3,0-0.8,0-0.6,0.5c0.3,0.7-0.2,1.2-0.4,1.7C94.9,126.5,95.1,126.4,95.3,126.2z"/>
			<path fill="#C5D0DC" d="M96.8,121.3c0.2,0.1,0.4,0.3,0.6,0.1c0.2-0.2,0.1-0.5-0.1-0.6c-0.5-0.4-0.9-0.9-1.6-0.7
				c0.1,1.2,0.1,1.2,0.7,1C96.6,121,96.7,121,96.8,121.3z"/>
			<path fill="#F0F2F5" d="M99.3,112.4c-0.1,0.2-0.8,0.3-0.1,0.6c0.4,0.2,0.8,0.2,1-0.2c0.2-0.4,0.2-0.7-0.2-0.9
				C99.6,111.6,99.4,111.9,99.3,112.4z"/>
			<path fill="#C5D0DC" d="M102.1,116.9c-0.1,0.3-0.6,0.4-0.6,0.9c0.6,0.3,1.1-0.5,1.8-0.3c-1-0.5-0.6-1.1-0.3-1.7
				c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.2,0-0.4,0.2-0.5,0.4C102.1,116.3,102.1,116.6,102.1,116.9z"/>
			<path fill="#C8D2DE" d="M108.4,133.8c0.1,0.3,0.2,0.7,0.3,1c0.1,0.2,0.2,0.4,0.4,0.4c0.3-0.1,0.2-0.4,0.3-0.6
				c0.5-0.3,0.3-0.7,0-1.1c-0.3-0.2-0.6-0.1-0.9,0.1c-0.1,0-0.1,0-0.2,0.1C108.4,133.8,108.4,133.8,108.4,133.8z"/>
			<path fill="#F7F8F9" d="M105.2,122c0.8,0.3,1-0.1,1-0.8c-0.2,0-0.5,0-0.7-0.1c-0.3-0.1-0.7-0.1-0.8,0.2
				C104.5,121.7,105,121.8,105.2,122z"/>
			<path fill="#F4F6F8" d="M105.5,119.1c0-0.2,0-0.4,0-0.5c-0.3-0.3-0.5-0.6-0.8-1c-0.4,0.4-0.1,1-0.8,1.5
				C104.7,119.1,105.1,119.1,105.5,119.1z"/>
			<path fill="#EBEEF2" d="M98.9,118.8c0.5-1-0.3-1.4-0.6-2.1c0,0-0.3,0.1-0.4,0.2c-0.2,0.1-0.2,0.5,0,0.5
				C98.6,117.5,98.7,118.1,98.9,118.8z"/>
			<path fill="#D2DAE3" d="M106.2,114.5c-0.2-0.1-0.4-0.1-0.5,0c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.3-0.3,0.4-0.5,0.6
				c0,0.2,0.1,0.3,0.3,0.4c0,0.1,0.1,0.2,0.3,0.2c0.3-0.1,0.6-0.2,0.5-0.6C106.7,115.5,106.3,115,106.2,114.5z"/>
			<path fill="#C4CFDB" d="M102.8,113.7c0,0.3,0.2,0.2,0.4,0.3c0.4-0.2,0.5-0.6,0.6-0.9c-0.1-1.1-0.7-0.8-1.4-0.6l0,0
				c-0.3,0.1-0.3,0.3-0.2,0.5C102.4,113.2,102.7,113.4,102.8,113.7z"/>
			<path fill="#DCE2E9" d="M100,116.4c-0.1,0.3,0.1,0.6,0.2,0.6c0.4,0.1,0.8-0.2,1.1-0.4c0.2-0.1,0.1-0.4-0.1-0.4
				C100.8,116.1,100.4,116.3,100,116.4z"/>
			<path fill="#C4D0DC" d="M91.5,120.2c0.1-0.2-0.1-0.3-0.2-0.4c-0.3-0.1-0.4-0.8-0.9-0.6c-0.1,0-0.2,0.4-0.1,0.5
				C90.5,120.1,90.9,120.5,91.5,120.2z"/>
			<path fill="#F3F5F7" d="M107.6,124.2c-0.2-0.3-0.4-0.5-0.7-0.7c-0.1,0-0.2,0.1-0.3,0.1c-0.4,0.7,0.2,0.9,0.7,1.2
				C107.5,124.7,107.6,124.5,107.6,124.2z"/>
			<path fill="#F3F5F7" d="M91.9,128.6c0.3-0.5,1.1,0,1.3-0.6c0.1-0.2,0-0.5-0.2-0.5C92.5,127.6,92.1,128,91.9,128.6z"/>
			<path fill="#F0F2F5" d="M102.5,112.4c0.5,0.1,1.3-0.3,1.2,0.7c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.2-0.1,0.3-0.1c0-0.2,0-0.3-0.1-0.4
				C103.7,111.8,103.2,111.7,102.5,112.4z"/>
			<path fill="#C9D3DE" d="M103.7,103.1c0,0.1-0.1,0.2-0.1,0.2c0,0.4,0.4,0.7,0.1,1.2l0,0c0.1,0.2,0,0.5,0.3,0.5l0,0
				c0.3,0,0.5-0.2,0.6-0.5c0-0.2,0-0.3,0-0.5C104.5,103.4,104,103.3,103.7,103.1z"/>
			<path fill="#C1CDD9" d="M104.2,109.7c0.3,0.1,0.6,0,0.8-0.3c0.1-0.2,0.2-0.5-0.1-0.6c-0.4-0.1-0.6,0.1-0.8,0.4
				C103.9,109.4,104,109.6,104.2,109.7z"/>
			<path fill="#D6DDE5" d="M88.6,117c0.1,0.3,0.5,0.5,0.6,0.9c0.1,0.3,0.4,0.3,0.6,0.1c0.2-0.2,0.3-0.4-0.1-0.5
				C89.4,117.3,89.3,116.6,88.6,117z"/>
			<path fill="#C2CEDA" d="M106.9,136c0.3-0.4,0.6-0.8,0.9-1.1c0.3-0.6-0.4-0.4-0.5-0.6c-0.2-0.2-0.3-0.1-0.3,0.1
				C106.9,134.9,106.9,135.4,106.9,136z"/>
			<path fill="#CDD6E0" d="M97.1,112.6c-0.6-0.2-1.2,0.3-1.9,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0.2,0.2,0.3,0.2
				c0.4-0.1,0.7-0.1,1,0.1C96.8,113.2,96.7,112.7,97.1,112.6z"/>
			<path fill="#CCD5E0" d="M110.4,125c-0.2,0.4,0.1,0.8,0.1,1.2c0.2-0.1,0.5-0.1,0.7-0.2c0.3-0.2,0.5-0.4,0.1-0.7
				C111,125.3,110.7,125.2,110.4,125z"/>
			<path fill="#F3F5F7" d="M97.5,127.8c0,0,0.1,0,0.1,0c0.4-0.1,0.8,0.2,1-0.3c0-0.1,0-0.3-0.1-0.4c-0.4-0.5-0.7,0.2-1,0.2
				C97.3,127.4,97.3,127.6,97.5,127.8z"/>
			<path fill="#ECEEF2" d="M104.4,114.9c0.2-0.4,0.6-0.7,0.4-1.1c-0.3,0.1-0.3-0.4-0.6-0.4l0,0c-0.6,0.2-0.7,0.7-0.7,1.2
				c0.1,0,0.1,0.1,0.2,0.1C104,114.4,104.1,115,104.4,114.9z"/>
			<path fill="#EAEDF0" d="M109,125.6c-0.2-0.2-0.5-0.4-0.7-0.7c-0.6,0.8,0,1.1,0.6,1.4C108.9,126.1,108.9,125.8,109,125.6z"/>
			<path fill="#CFD8E1" d="M101.9,124.6c-0.1,0.3,0,0.4,0.2,0.5c0.3,0.1,0.5,0.2,0.7-0.1c0.2-0.2,0.1-0.5-0.2-0.6
				C102.3,124.4,102.1,124.5,101.9,124.6z"/>
			<path fill="#F3F5F7" d="M100,109c0-0.1,0-0.2,0-0.2c0.1-0.3,0.6-0.6,0.1-1c-0.1-0.1-0.3-0.1-0.3-0.1c-0.3,0.3-0.5,0.6-0.3,1.1
				C99.6,109,99.8,109.1,100,109z"/>
			<path fill="#EDF0F3" d="M111.4,125.3c0.2,0.3,0,0.5-0.1,0.7c0.5,0.3,0.7-0.2,0.9-0.5c0.1-0.3-0.2-0.5-0.4-0.6
				C111.4,124.8,111.4,125.1,111.4,125.3z"/>
			<path fill="#C3CEDB" d="M97.3,101c0-0.2,0.6-0.3,0.1-0.5c-0.2-0.1-0.6-0.1-0.7,0.2c-0.1,0.3,0,0.6,0.3,0.7
				C97.4,101.6,97.4,101.3,97.3,101z"/>
			<path fill="#D9DFE7" d="M101.4,103.7c-0.5,0.4-0.5,0.5,0.3,1c0.3-0.1,0.9,0.2,0.7-0.5C102.2,103.8,101.8,103.6,101.4,103.7z"/>
			<path fill="#F5F6F8" d="M105.9,108.3c0.5-0.8,0.4-1.3-0.7-1.2c0.1,0.3,0.2,0.7,0.3,1C105.6,108.3,105.7,108.3,105.9,108.3z"/>
			<path fill="#C4CFDB" d="M94.2,131.1c0.2,0.1,0.4,0.1,0.5-0.1c0.1-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.3-0.1-0.4,0.1
				C93.9,130.7,94,130.9,94.2,131.1z"/>
			<path fill="#FAFAFB" d="M103.4,114.6c0.2-0.4,0.5-0.8,0.7-1.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.4,0.2-0.5,0.4-0.5,0.8
				C103.2,114.2,103.2,114.4,103.4,114.6z"/>
			<path fill="#F6F7F8" d="M101.4,103.7c0.3,0.2,0.7,0.4,1,0.6c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.1,0-0.1c0.1-0.2,0.2-0.4,0.4-0.6
				c0-0.1-0.1-0.2-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.2-0.9,0.1C101.5,103.6,101.4,103.6,101.4,103.7z"/>
			<path fill="#FCFCFD" d="M95.5,129.7c0.3-0.1,0.5-0.2,0.9-0.3c-0.5-0.2-0.6-0.9-1.3-0.5C95.2,129.2,94.8,129.7,95.5,129.7z"/>
			<path fill="#E1E6EC" d="M102.6,115.5c-0.1-0.3,0.2-0.8-0.3-0.8c-0.2,0-0.5,0.2-0.6,0.3c-0.2,0.4,0.4,0.4,0.5,0.6
				C102.4,115.6,102.5,115.6,102.6,115.5z"/>
			<path fill="#ECEEF2" d="M104.2,128.1c0,0-0.1,0-0.1-0.1c-0.4-0.2-0.8-0.4-1.2-0.1c-0.1,0-0.1,0.3,0,0.3c0.4,0.4,0.8,0.1,1.2,0
				c0.1,0,0.1-0.1,0.1-0.1C104.2,128.1,104.2,128.1,104.2,128.1z"/>
			<path fill="#D1D9E2" d="M105.3,110.4c0.3,0.4,0.7,0.5,1.1,0.1c0,0,0,0,0,0c-0.7-0.3,0-0.8-0.1-1.2c-0.1-0.1-0.3-0.2-0.4-0.3
				C105.9,109.6,105.4,109.9,105.3,110.4z"/>
			<path fill="#F7F8F9" d="M104.7,123.4c0-0.5,1.9,0.1,0.6-1C105,122.6,104.5,122.8,104.7,123.4z"/>
			<path fill="#F2F4F6" d="M92.3,112.3c0.1,0,0.1-0.1,0.2-0.1c0.3-0.1,0.7-0.2,0.6-0.7c0-0.1-0.1-0.3-0.1-0.2
				c-0.4,0.1-0.6,0.2-0.7,0.6c0,0.1-0.1,0.2-0.1,0.3C92.1,112.3,92.2,112.3,92.3,112.3z"/>
			<path fill="#EAEDF1" d="M113.1,133.4c-0.1-0.3,0.4-0.8-0.2-0.8c-0.2,0-0.4,0.1-0.5,0.3C112.3,133.3,112.9,133.2,113.1,133.4z"/>
			<path fill="#F8F9FA" d="M103.6,125.7c-0.1,0.6-0.4,1,0,1.3c0.1,0,0.3,0,0.3-0.1C104.2,126.5,104,126.2,103.6,125.7z"/>
			<path fill="#C7D2DD" d="M105.7,116.4c-0.1,0-0.2-0.1-0.2-0.1c0,0.4-0.9,0.2-0.6,0.6c0.3,0.5,0.7-0.1,1.1,0c0,0,0,0,0,0
				C106.2,116.5,106,116.5,105.7,116.4z"/>
			<path fill="#F3F5F7" d="M109.4,133.5c-0.3,0.3,0.2,0.7,0,1.1c1,0.1,0.5-0.6,0.6-1c0.1-0.1,0.2-0.2,0.1-0.3
				C109.7,133.2,109.6,133.5,109.4,133.5L109.4,133.5z"/>
			<path fill="#F2F3F6" d="M109.1,127.3c0.5-0.1,0.8-0.6,0.7-1c-0.1-0.3-0.5-0.1-0.7,0.2C109.1,126.7,109.1,127,109.1,127.3z"/>
			<path fill="#C7D2DD" d="M95.5,129.7c-0.4-0.2-0.2-0.5-0.4-0.8c-0.5,0.7-0.1,1.1,0.3,1.7C95.9,130.3,95.5,130,95.5,129.7z"/>
			<path fill="#C8D2DD" d="M94.9,136.8c-0.4-0.6-0.9-0.5-1.3-0.7c0,0.1,0,0.3,0,0.3C93.9,136.9,94.3,136.7,94.9,136.8z"/>
			<path fill="#F1F3F5" d="M102.1,103c0-0.2-0.1-0.3-0.3-0.5c-0.4,0.1-0.3-0.9-0.8-0.5c-0.4,0.3,0,0.6,0.3,0.8
				C101.6,102.7,101.8,103.1,102.1,103z"/>
			<path fill="#C9D3DE" d="M102.4,112.9c-0.1-0.2-0.1-0.4,0-0.5c-0.4,0.1-1-1.2-1.2,0.1c0.6-0.1,0.5,0.4,0.7,0.7
				c0.1,0,0.1,0.1,0.2,0.1C102.4,113.3,102.5,113.1,102.4,112.9z"/>
			<path fill="#F1F3F5" d="M96.4,124.4c0.1,0.4-0.5,0.5-0.3,1c0.2-0.2,0.7,0.2,0.8-0.2C97,124.7,96.7,124.5,96.4,124.4z"/>
			<path fill="#D4DCE4" d="M103.2,121.9c0.3,0.2,0.3,0.8,0.7,0.7c0.1,0,0.2-0.2,0.1-0.3C103.9,122,103.6,121.9,103.2,121.9z"/>
			<path fill="#DFE4EA" d="M113.1,125.2c0-0.7,0-1.4-1-1.1C112.7,124.2,112.5,125,113.1,125.2z"/>
			<path fill="#FCFCFC" d="M99.5,106.5c0,0,0.1,0.2,0.2,0.3c0.4,0.2,0.6-0.1,0.8-0.3c0.1-0.1,0-0.3-0.1-0.4
				C100.1,106,99.9,106.1,99.5,106.5z"/>
			<path fill="#F4F5F7" d="M102.7,103.4c0.1,0,0.1,0.1,0.2,0.1c0.3,0.3,0.8,0.4,0.8,1c0.7-0.5,0-0.8-0.1-1.2
				C103.3,103.3,103,103.4,102.7,103.4z"/>
			<path fill="#E5E9EF" d="M94.6,101.5c1,0.1,0.4-0.6,0.6-1C94.4,100.6,94.7,101.1,94.6,101.5L94.6,101.5z"/>
			<path fill="#CED7E1" d="M93.2,113.6c0.1,0.8,0.7,1,1.1,1.3C94.1,114.4,93.7,114.1,93.2,113.6z"/>
			<path fill="#EDEFF3" d="M102,105.4c-0.3-0.1-0.6-0.2-0.9,0.2c0.4,0.3,0.8,0.3,1.3,0C102.4,105.5,102.2,105.4,102,105.4z"/>
			<path fill="#E4E9EE" d="M109.4,133.6c0.2,0.1,0.4-0.4,0.5,0c0.7-0.5-0.1-0.6-0.1-1.1C109.3,132.9,109.7,133.3,109.4,133.6z"/>
			<path fill="#E9ECF0" d="M93.9,120c0.2,0.6,0.3,1,0.9,1.4C94.7,120.7,94.6,120.3,93.9,120z"/>
			<path fill="#FEFEFE" d="M105.9,129.4c-0.2-0.3-0.6-0.3-1-0.5C105.3,129.5,105.3,129.5,105.9,129.4z"/>
			<path fill="#DAE1E8" d="M110,127.2c-0.2,0.4-0.4,0.7-0.4,1.2c0.3,0,0.8,0.2,0.8-0.4C110.1,127.8,110.4,127.3,110,127.2z"/>
			<path fill="#FAFBFC" d="M99.9,122c0.3,0.6,0.5,0.2,0.6-0.1c0.1-0.2,0.4-0.6-0.2-0.6C100,121.5,99.9,121.7,99.9,122z"/>
			<path fill="#F1F3F6" d="M92,121.5c0.2,0.2,0.4,0,0.6-0.1c0.2-0.2,0-0.3-0.1-0.3c-0.2,0-0.4,0-0.5,0.1
				C91.8,121.2,91.8,121.4,92,121.5z"/>
			<path fill="#E5E9EE" d="M102.1,116.9c0.4-0.1,0.2-0.5,0.4-0.6c0-0.2-0.1-0.4-0.3-0.5C101.6,116.1,101.7,116.5,102.1,116.9z"/>
			<path fill="#DBE1E8" d="M87.2,118.4c0.2,0,0.5,0,0.6-0.2c0.1-0.2-0.1-0.2-0.2-0.3c-0.2-0.1-0.4-0.1-0.5,0.1
				C87,118.2,87,118.3,87.2,118.4z"/>
			<path fill="#F2F4F6" d="M97.1,112.6c-0.4,0-0.5,0.4-0.7,0.7C97.7,114,96.9,112.9,97.1,112.6z"/>
			<path fill="#F9FAFB" d="M104.6,104.4c-0.2,0.2-0.4,0.3-0.6,0.5c0.3,0,0.5,0.5,0.8,0.2C105,104.9,104.8,104.6,104.6,104.4z"/>
			<path fill="#C8D3DE" d="M97.1,123.3c-0.4-0.1-0.7,0-0.7,0.4c0,0.1,0.1,0.2,0.1,0.2C97,124,96.9,123.5,97.1,123.3z"/>
			<path fill="#E7EAEF" d="M105.9,104.1c-0.3-0.2-0.6-0.4-0.9,0.1c0.3,0.1,0.3,0.6,0.7,0.5C105.8,104.6,105.8,104.3,105.9,104.1z"/>
			<path fill="#E6EAEF" d="M95.3,105.1c0.2,0.1,0.3-0.1,0.3-0.2c0-0.2,0.1-0.4-0.1-0.5c-0.2-0.1-0.3,0.1-0.3,0.2
				C95.1,104.7,95.1,104.9,95.3,105.1z"/>
			<path fill="#FAFAFB" d="M101.1,107c-0.1,0-0.3,0-0.4,0.1c-0.2,0.2-0.1,0.5,0,0.7c0.2,0.2,0.3,0,0.3-0.1
				C100.9,107.4,101.4,107.3,101.1,107z"/>
			<path fill="#DFE4EA" d="M104.5,125.2c0.1-0.2,0.2-0.5,0-0.6c-0.3-0.2-0.6,0-0.8,0.2c0,0.1,0.1,0.2,0.1,0.3c0.2,0.1,0.4,0.1,0.5,0
				C104.4,125.1,104.4,125.1,104.5,125.2z"/>
			<path fill="#E2E6EC" d="M111.7,128.4c0.3,0.2,0.4,0.7,0.9,0.6c0,0,0.1-0.1,0.1-0.2C112.5,128.3,112.1,128.5,111.7,128.4z"/>
			<path fill="#EFF1F4" d="M101,129.8c-0.5,0.2-0.6,0.5-0.7,0.8C100.9,130.7,101,130.4,101,129.8z"/>
			<path fill="#C3CEDB" d="M100.2,131.8c0,0.1,0,0.4,0.1,0.5c0.1,0.2,0.3,0,0.4-0.1c0.1-0.2,0.1-0.4-0.1-0.6
				C100.5,131.5,100.3,131.6,100.2,131.8z"/>
			<path fill="#CDD6E0" d="M104.4,114.9c-0.5-0.1,0.1-0.6-0.2-0.8c-0.3,0.1-0.3,0.4-0.5,0.6c0.3,0.2,0.5,0.7,1,0.3
				C104.5,114.9,104.4,114.9,104.4,114.9z"/>
			<path fill="#D3DAE3" d="M99.3,127.8c0.5-0.2,0.9-0.4,0.8-1.2C99.8,127.1,99.6,127.4,99.3,127.8z"/>
			<path fill="#E6EAEE" d="M102.4,112.9c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.8,0.5,0.1,0.7,0.3C103,113.2,102.7,113.1,102.4,112.9z"/>
			<path fill="#DFE4EA" d="M90.2,108.6c0.3,0,0.7,0,0.9-0.4c-0.2-0.5-0.6-0.1-0.9-0.2C90,108.3,90.1,108.5,90.2,108.6z"/>
			<path fill="#D2DAE3" d="M101.4,130.6c0,0.3,0.1,0.6-0.3,1.1c0.8-0.2,0.8-0.6,1.1-0.9C101.9,130.4,101.6,130.6,101.4,130.6z"/>
			<path fill="#D3DBE4" d="M94.6,101.5c-0.3,0-0.6-0.1-0.7,0.3c0,0,0.2,0.2,0.4,0.2C94.7,102.1,94.6,101.7,94.6,101.5
				C94.6,101.5,94.6,101.5,94.6,101.5z"/>
			<path fill="#F0F2F5" d="M102.5,104.1c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.1-0.5,0.3-0.8,0.4c0.1,0.1,0.3,0.2,0.4,0.2
				c0.2,0.1,0.4,0.1,0.5,0C102.8,104.7,102.7,104.4,102.5,104.1z"/>
			<path fill="#E4E8ED" d="M102.6,109c0.1,0.2,0.1,0.6,0.4,0.6c0.1,0,0.2-0.2,0.2-0.2C103.2,109.1,102.9,109,102.6,109z"/>
			<path fill="#E7EAEF" d="M100.2,128.9c0.2-0.4,0.6-0.5,0.4-0.8c0-0.1-0.2-0.1-0.3,0C100,128.2,100.1,128.4,100.2,128.9z"/>
			<path fill="#EBEEF2" d="M100.9,133.2c-0.2-0.1-0.4-0.1-0.4,0.1c-0.1,0.2-0.1,0.4,0,0.5c0.1,0.1,0.3,0.1,0.4-0.1
				C100.9,133.5,100.9,133.3,100.9,133.2z"/>
			<path fill="#C6D1DD" d="M89.9,106.4c0.2,0.1,0.3,0.2,0.4,0c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.4,0
				C89.6,106.1,89.9,106.2,89.9,106.4z"/>
			<path fill="#FDFDFD" d="M97.5,127.8c0-0.2,0-0.4,0-0.5c-0.3-0.2-0.6-0.1-0.6,0.2C96.9,127.8,97.1,127.9,97.5,127.8z"/>
			<path fill="#FBFBFC" d="M105.8,125.4c-0.2-0.4-0.5-0.6-0.9-0.3C105.2,125.2,105.2,126.1,105.8,125.4z"/>
			<path fill="#D2DAE3" d="M89.9,115.3c-0.2,0.2-0.2,0.4,0,0.5c0.1,0,0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.4-0.1-0.5
				C89.9,114.9,90,115.2,89.9,115.3z"/>
			<path fill="#E8ECF0" d="M97.6,114.6c0.1,0.1,0.4,0.4,0.4,0.4c0.4-0.2,0-0.3-0.1-0.5c0-0.1,0-0.3,0-0.5l0,0
				c-0.3,0-0.4,0.3-0.6,0.5C97.4,114.6,97.5,114.6,97.6,114.6z"/>
			<path fill="#E4E8ED" d="M104,104.9c-0.1-0.2-0.2-0.3-0.3-0.5c-0.3,0.6,0.1,0.9,0.4,1.3C104.4,105.4,103.9,105.2,104,104.9z"/>
			<path fill="#CCD5E0" d="M104,116.9c-0.1-0.4-0.4-0.7-0.7-1.1c-0.3,0.5,0,0.7,0.2,0.8C103.7,116.9,103.8,116.9,104,116.9z"/>
			<path fill="#CCD6E0" d="M92.5,122.5c0.2,0.2,0.3,0.4,0.5,0.5c0.2-0.2,0.6-0.1,0.5-0.3C93.4,122.3,92.9,122.3,92.5,122.5z"/>
			<path fill="#EAEDF1" d="M93,126.3c-0.1-0.1-0.3-0.2-0.4,0c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.4,0
				C93.1,126.6,93.1,126.4,93,126.3z"/>
			<path fill="#F5F6F8" d="M98.2,103.5c0.2,0,0.4,0,0.5-0.2c0-0.2-0.1-0.4-0.4-0.4c-0.3,0-0.4,0.2-0.3,0.5
				C98,103.6,98,103.6,98.2,103.5z"/>
			<path fill="#D3DBE4" d="M91.6,125.7c0,0.2-0.3,0.3-0.1,0.5c0.1,0.1,0.3,0.1,0.4-0.1c0.1-0.2,0.1-0.4,0-0.6
				C91.8,125.5,91.7,125.6,91.6,125.7z"/>
			<path fill="#F9F9FA" d="M108.8,131.7c-0.1,0.2-0.1,0.3,0.1,0.4c0.1,0,0.3-0.1,0.4-0.2c0.2-0.1,0.1-0.3,0-0.4
				C109.1,131.4,109,131.5,108.8,131.7z"/>
			<path fill="#D6DEE5" d="M94.9,107.9c-0.3,0.3-0.3,0.6,0,0.8c0,0,0.2,0,0.3,0C95.2,108.4,95.2,108.1,94.9,107.9z"/>
			<path fill="#F4F5F7" d="M97.4,130.2c0.1-0.2,0.1-0.5-0.2-0.5c-0.5,0-0.3,0.3-0.2,0.6C97.3,130.5,97.4,130.5,97.4,130.2z"/>
			<path fill="#F5F6F8" d="M107.8,126.8c0,0,0.1,0.2,0.2,0.2c0.1,0.1,0.3,0.1,0.4-0.1c0.1-0.1,0.1-0.3-0.1-0.4
				C108.2,126.5,108,126.5,107.8,126.8z"/>
			<path fill="#F1F2F5" d="M99.9,122c0.1-0.2,0.3-0.4,0.4-0.7c-0.3-0.1-0.6-0.1-0.7,0.2C99.4,121.9,99.8,121.9,99.9,122z"/>
			<path fill="#D3DBE4" d="M102.2,127.8c-0.2-0.1-0.3,0-0.4,0.1c0,0.1,0,0.2,0,0.3c0.1,0.1,0.3,0.1,0.4,0
				C102.4,128.1,102.3,127.9,102.2,127.8z"/>
			<path fill="#CDD6E0" d="M91.2,124.5c0-0.2-0.1-0.3-0.2-0.4c-0.2,0-0.3,0-0.4,0.1c-0.1,0.2,0,0.3,0.2,0.3
				C90.9,124.6,91.1,124.6,91.2,124.5z"/>
			<path fill="#CED7E1" d="M92,117.4c-0.1,0.2-0.1,0.4,0.1,0.4c0.1,0,0.4,0.1,0.4,0c0.1-0.1,0-0.3-0.1-0.4
				C92.2,117.4,92.1,117.4,92,117.4z"/>
			<path fill="#D9DFE7" d="M102.1,103c-0.2-0.1-0.5-0.3-0.7-0.2c0.3,0.2,0.3,0.4,0.2,0.7c0.3,0,0.6-0.1,0.9-0.1
				C102.2,103.3,102.1,103.2,102.1,103z"/>
			<path fill="#D7DEE5" d="M90.5,116.8c0.1,0.1,0.2,0.3,0.2,0.3c0.1,0,0.3,0,0.4-0.1c0.1-0.2,0-0.3-0.2-0.3
				C90.8,116.7,90.7,116.7,90.5,116.8z"/>
			<path fill="#D6DDE6" d="M114.9,131.2c-0.3-0.2-0.5-0.2-0.6,0c-0.1,0.2-0.1,0.3,0.1,0.3c0.1,0,0.3,0,0.4-0.1
				C114.9,131.4,114.9,131.2,114.9,131.2z"/>
			<path fill="#EDF0F3" d="M102,133.4c-0.2-0.1-0.5-0.3-0.7,0c0,0.1,0.1,0.3,0.1,0.4C101.8,134,101.8,133.6,102,133.4L102,133.4z"/>
			<path fill="#F9F9FA" d="M111.8,130c-0.2-0.1-0.3-0.1-0.4,0.1c0,0.1,0,0.2,0,0.3c0.1,0.1,0.3,0.1,0.4,0
				C111.9,130.3,111.9,130.2,111.8,130z"/>
			<path fill="#C9D3DE" d="M102.6,104.9c-0.2-0.1-0.4-0.1-0.5,0c0,0.2,0,0.3,0,0.5c0.1,0.1,0.3,0.2,0.4,0.3
				C102.5,105.5,102.5,105.2,102.6,104.9z"/>
			<path fill="#DAE0E7" d="M111,129.5c-0.5,0-0.7,0.1-0.8,0.5C110.7,130.1,110.7,129.8,111,129.5z"/>
			<path fill="#D9DFE7" d="M96.8,122.5c0.3,0.1,0.6,0.1,0.7-0.3c0-0.1-0.1-0.3-0.2-0.3C97,122,96.9,122.2,96.8,122.5z"/>
			<path fill="#E2E6EC" d="M98.2,103.5c-0.1,0-0.2-0.1-0.2-0.1c-0.6,0.3-0.4,0.7-0.1,1.1c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.1,0-0.1
				C98.1,104,98.1,103.8,98.2,103.5z"/>
			<path fill="#C9D3DE" d="M105.6,108.9c0-0.1,0.1-0.1,0.1-0.2c0-0.5-0.6-0.3-0.7-0.7c-0.2,0-0.4,0-0.5,0c-0.2,0.2-0.2,0.3,0.1,0.3
				C104.9,108.5,105.2,108.7,105.6,108.9z"/>
			<path fill="#FEFEFE" d="M105,108c0,0.4,0.5,0.5,0.7,0.7c0.3,0,0.2-0.3,0.2-0.4c-0.1-0.1-0.2-0.1-0.4-0.2
				C105.4,108,105.2,108,105,108z"/>
			<path fill="#FAFAFB" d="M100,101.3c0,0,0.1,0.1,0.2,0.2c0.2,0.2,0.3,0,0.3-0.1c0-0.2,0.1-0.4,0-0.4
				C100.4,100.8,100.3,101,100,101.3z"/>
			<path fill="#F0F2F5" d="M102,113.2c-0.1-1-0.1-1-0.7-0.7C101.5,112.8,101.8,113,102,113.2z"/>
			<path fill="#F0F2F5" d="M102.2,111.1c-0.1,0.2,0,0.3,0.1,0.4c0.1,0,0.2,0,0.3,0c0.1-0.1,0.1-0.3,0-0.4
				C102.5,110.9,102.3,110.9,102.2,111.1z"/>
			<path fill="#E8ECF0" d="M90.2,108.6c0-0.2,0-0.4,0-0.5c-0.2-0.1-0.5-0.2-0.5,0.1C89.7,108.5,89.8,108.8,90.2,108.6z"/>
			<path fill="#C5D0DC" d="M98,113.9c0.3,0.2,0.6,0.2,0.9-0.1c0.1-0.2,0.1-0.3,0-0.5C98.3,113.1,98.4,113.9,98,113.9
				C98,113.9,98,113.9,98,113.9z"/>
			<path fill="#F6F7F8" d="M92.7,132.4c-0.4,0-0.6,0.2-0.6,0.5c0.2,0.4,0.3,0.1,0.5-0.1C92.7,132.7,92.7,132.5,92.7,132.4z"/>
			<path fill="#FCFCFD" d="M94.9,105.7c-0.1,0.1-0.1,0.3,0.1,0.3c0.2,0,0.4,0,0.6-0.1c0-0.1,0-0.3-0.1-0.3
				C95.2,105.4,95,105.6,94.9,105.7z"/>
			<path fill="#E5E9EE" d="M96,106.3c-0.1,0.2-0.2,0.4,0,0.5c0.1,0,0.3,0,0.3,0c0.1-0.2,0-0.4-0.1-0.6C96.1,106.3,96,106.3,96,106.3
				z"/>
			<path fill="#CED7E1" d="M97.5,133.9c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.3-0.1,0.4-0.2c0.1-0.2-0.2-0.2-0.3-0.2
				C97.7,133.7,97.5,133.7,97.5,133.9z"/>
			<path fill="#ECEFF2" d="M114.3,127.9c-0.2-0.1-0.4,0-0.5,0.1c-0.1,0.1-0.1,0.3,0,0.4c0.1,0,0.2-0.1,0.4-0.2
				C114.3,128.1,114.4,128,114.3,127.9z"/>
			<path fill="#F5F6F8" d="M98.6,106.7c0.4-0.1,0.6-0.4,0.6-0.8C98.6,106,98.8,106.4,98.6,106.7z"/>
			<path fill="#E9ECF0" d="M99.6,133.3c-0.4,0.1-0.6,0.2-0.6,0.6c0,0.1,0.2,0.1,0.3,0.2C99.4,133.8,99.5,133.6,99.6,133.3z"/>
			<path fill="#F0F2F5" d="M98,104.2c-0.1,0.1-0.1,0.2-0.2,0.2c0,0.2,0,0.5,0.3,0.6c0,0,0.2-0.1,0.2-0.1
				C98.4,104.6,98.3,104.4,98,104.2z"/>
			<path fill="#DEE3EA" d="M98.9,113.4c-0.1,0.2-0.1,0.3,0,0.5c0.2,0.1,0.3,0.1,0.5,0.2C99.3,113.7,99.1,113.5,98.9,113.4z"/>
			<path fill="#F9FAFB" d="M102.1,115.8c0,0.2,0.1,0.4,0.3,0.5c0.1-0.2,0.2-0.4,0.4-0.6c-0.1,0-0.1-0.1-0.2-0.1
				c-0.1,0-0.2,0.1-0.4,0.1C102.2,115.7,102.2,115.7,102.1,115.8z"/>
			<path fill="#DAE0E8" d="M100,109c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0.3-0.1,0.6,0.1,0.7C100.1,109.6,99.9,109.2,100,109z"/>
			<path fill="#E9ECF0" d="M92.6,115.7c-0.5-0.4-0.6-0.7-1-0.7C91.7,115.4,92,115.5,92.6,115.7z"/>
			<path fill="#CAD4DE" d="M91.1,122.1c0.1,0,0.2,0,0.3,0c0.2-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3,0
				C90.9,121.8,90.9,121.9,91.1,122.1z"/>
			<path fill="#D2DAE3" d="M104.1,113.3c0.2,0.2,0.2,0.6,0.6,0.4c-0.1-0.2-0.2-0.4-0.2-0.7c-0.1-0.1-0.3-0.2-0.4-0.2
				C104.2,113,104.2,113.2,104.1,113.3z"/>
			<path fill="#CDD6E0" d="M97,102.1c0,0.1,0.1,0.2,0.2,0.4c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.2,0-0.3-0.2-0.4
				C97.1,101.9,97,101.9,97,102.1z"/>
			<path fill="#C6D1DD" d="M96.4,109.8c-0.2,0.1-0.4,0.1-0.4,0.1c0,0.1,0,0.3,0.1,0.4c0,0,0.3,0,0.3-0.1
				C96.4,110.1,96.4,110,96.4,109.8z"/>
			<path fill="#DFE4EB" d="M99.2,111.2c-0.3,0.1-0.5,0.1-0.5,0.4c0,0,0.1,0.1,0.2,0.1C99,111.7,99.1,111.5,99.2,111.2z"/>
			<path fill="#DCE1E8" d="M104,131.2c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.2-0.1,0.2-0.1c0-0.1,0-0.3-0.1-0.4
				C104.2,130.9,104.1,131,104,131.2z"/>
			<path fill="#D7DDE5" d="M107,134.4c0.1,0,0.2-0.1,0.3-0.1c0-0.2,0-0.4-0.2-0.5c-0.1,0-0.3,0-0.3,0.1
				C106.6,134.1,106.8,134.2,107,134.4z"/>
			<path fill="#F4F6F7" d="M105.3,131.8c-0.2,0.1-0.3,0.1-0.4,0.1c-0.1,0.1,0,0.3,0.1,0.4c0.1,0.1,0.3,0.1,0.3-0.1
				C105.3,132.1,105.3,132,105.3,131.8z"/>
			<path fill="#DDE3E9" d="M103.6,131.1c-0.1,0.1-0.3,0.1-0.3,0.2c-0.1,0.1-0.1,0.3,0.1,0.4c0.1,0,0.2-0.1,0.2-0.2
				C103.6,131.3,103.6,131.2,103.6,131.1z"/>
			<path fill="#F7F8F9" d="M101.4,130.6c0.2,0.1,0.5,0.1,0.7,0.2c0.1-0.1,0.2-0.3,0-0.4C101.8,130.2,101.6,130.5,101.4,130.6z"/>
			<path fill="#FEFEFE" d="M95.3,126.2c-0.2,0-0.4,0-0.6,0c0,0.4,0.3,0.6,0.6,0.5C95.5,126.7,95.4,126.4,95.3,126.2z"/>
			<path fill="#F5F6F8" d="M110,127.2c0.1,0.2,0,0.6,0.4,0.8c0.1-0.3,0.2-0.6,0.3-0.9C110.4,127,110.2,127.1,110,127.2z"/>
			<path fill="#DFE5EA" d="M104.1,123.9c0.2,0,0.4,0,0.7-0.1c-0.1-0.1-0.1-0.2-0.2-0.3C104.3,123.5,104,123.4,104.1,123.9z"/>
			<path fill="#E2E6EC" d="M104.6,108.3c0-0.1-0.1-0.2-0.1-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.1,0.2-0.1,0.3
				C104.1,108.3,104.2,108.5,104.6,108.3z"/>
			<path fill="#E7EAEF" d="M97.4,130.2c-0.1,0-0.2,0.1-0.4,0.1c0.1,0.3,0.1,0.6,0.4,0.5C97.9,130.7,97.6,130.4,97.4,130.2z"/>
			<path fill="#DFE4EB" d="M98.2,122.8c0,0.2,0,0.3,0.1,0.4c0.1,0.2,0.3,0.2,0.4,0c0.1-0.1,0.1-0.3,0-0.3
				C98.6,122.8,98.4,122.8,98.2,122.8z"/>
			<path fill="#E5E9EE" d="M113.6,125.7c0-0.1,0.2-0.3,0-0.4c-0.1,0-0.3,0.1-0.4,0.1c0,0.2,0,0.4,0,0.5c0,0.1,0.1,0.1,0.2,0.1
				C113.4,126,113.5,125.9,113.6,125.7z"/>
			<path fill="#E0E5EB" d="M112.1,124c-0.3,0-0.7-0.8-0.9,0.1C111.5,124.1,111.8,124.1,112.1,124z"/>
			<path fill="#E8EBEF" d="M92.3,112.3c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0.1-0.4,0.2-0.2,0.5c0.1,0.1,0.2,0.1,0.3,0.1
				C92.4,112.7,92.4,112.5,92.3,112.3z"/>
			<path fill="#C5D0DC" d="M92.7,103.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.3,0-0.3,0.2c0,0.1,0.1,0.2,0.1,0.3
				C92.4,103.6,92.5,103.5,92.7,103.5z"/>
			<path fill="#EAEEF1" d="M105.5,128c0.1,0,0.3,0,0.3,0c0.1-0.1,0-0.3-0.1-0.4c-0.1,0-0.2,0-0.3,0.1
				C105.4,127.7,105.4,127.9,105.5,128z"/>
			<path fill="#E7EAEF" d="M104.4,135c0.1,0.1,0.1,0.3,0.1,0.3c0.2,0.1,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.1-0.3
				C104.6,134.9,104.5,134.9,104.4,135z"/>
			<path fill="#DBE1E8" d="M97.6,114.6c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0-0.4,0.1-0.4,0.4c0,0.1,0.1,0.2,0.1,0.2
				C97.4,115,97.5,114.7,97.6,114.6z"/>
			<path fill="#E2E7EC" d="M104,116.9c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0.3-0.1,0.5,0.2,0.6C104.1,117.3,104.1,117.1,104,116.9z"/>
			<path fill="#ECEFF2" d="M91.6,129c-0.2,0-0.4,0-0.4,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.4-0.2
				C91.7,129.2,91.7,129.1,91.6,129z"/>
			<path fill="#F4F5F7" d="M108.3,131c0.1-0.2,0.2-0.5-0.2-0.5c-0.3,0-0.3,0.3-0.3,0.5C108,131.2,108.2,131.1,108.3,131z"/>
			<path fill="#C4CFDB" d="M99.8,114.9c-0.3-0.3-0.6-0.5-0.7-0.3c-0.2,0.1,0.1,0.3,0.2,0.4C99.5,115,99.6,115,99.8,114.9z"/>
			<path fill="#EEF0F3" d="M104.2,128.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.2c0.1,0.2,0.2,0.1,0.2,0
				c0.1-0.2,0.6-0.2,0.4-0.5C104.6,127.9,104.4,128,104.2,128.1z"/>
			<path fill="#E6EAEE" d="M113.4,126.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0.2-0.2,0.3-0.4,0.5c0.1,0,0.2,0.1,0.3,0.1
				C113.4,126.6,113.5,126.4,113.4,126.1z"/>
			<path fill="#F8F8FA" d="M103.5,110.4c-0.2,0.2-0.1,0.3,0,0.3c0.1,0.1,0.3,0.2,0.4,0.1c0.2-0.1,0.1-0.3,0-0.4
				C103.8,110.4,103.6,110.4,103.5,110.4z"/>
			<path fill="#F9FAFB" d="M91.2,131.7c0.2,0.1,0.3,0.1,0.3,0c0-0.1,0.1-0.3,0-0.4c-0.1-0.2-0.3-0.1-0.3,0.1
				C91.2,131.5,91.2,131.6,91.2,131.7z"/>
			<path fill="#D5DCE5" d="M102.1,126c-0.2-0.1-0.5-0.2-0.6,0.1c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.1,0,0.1-0.1
				C102.1,126.1,102.1,126,102.1,126z"/>
			<path fill="#CED6E1" d="M102.1,126c0,0.1-0.1,0.1-0.1,0.2c0.2,0.2,0.2,0.5,0.6,0.5C102.8,126.3,102.3,126.2,102.1,126z"/>
			<path fill="#FCFCFD" d="M102,133.4c0.2,0,0.5,0.1,0.5-0.3c0-0.1-0.2-0.2-0.2-0.2C101.9,133,102,133.3,102,133.4
				C102,133.4,102,133.4,102,133.4z"/>
			<path fill="#F6F7F9" d="M93.1,130.9c-0.3,0-0.5,0.1-0.6,0.3c0,0,0.1,0.1,0.2,0.1C92.8,131.4,93,131.3,93.1,130.9z"/>
			<path fill="#EFF1F4" d="M93.2,134c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0,0.2-0.1c0-0.1-0.1-0.3-0.2-0.4
				C93.4,133.9,93.3,133.9,93.2,134z"/>
			<path fill="#CBD4DF" d="M99.8,136.8c0.1,0.2,0.1,0.3,0.1,0.3c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2-0.1-0.3
				C100.1,136.8,100,136.8,99.8,136.8z"/>
			<path fill="#EFF1F4" d="M99.5,129.4c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1,0,0.2,0.1,0.2c0.1,0,0.3-0.1,0.4-0.2
				C99.6,129.6,99.6,129.5,99.5,129.4z"/>
			<path fill="#E0E5EA" d="M102.1,135.6c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3,0c0-0.1,0.1-0.2,0-0.3
				C102.3,135.4,102.2,135.5,102.1,135.6z"/>
			<path fill="#C7D2DD" d="M89.2,127.2c-0.1,0.1-0.2,0.2-0.3,0.2c0,0.1,0,0.2,0,0.2c0.1,0.1,0.2,0.1,0.3-0.1
				C89.3,127.5,89.2,127.4,89.2,127.2z"/>
			<path fill="#F5F6F7" d="M92,107.4c-0.1,0.2,0,0.3,0.1,0.3c0.1,0,0.2,0,0.3,0c0.1-0.1,0.1-0.2-0.1-0.3
				C92.2,107.4,92.1,107.4,92,107.4z"/>
			<path fill="#EAEDF1" d="M105.2,115.9c0.3,0,0.7,0.1,0.6-0.5c-0.1-0.1-0.2,0-0.3,0.1c-0.1,0.1-0.3,0.2-0.4,0.3
				C105,115.8,105.1,115.8,105.2,115.9z"/>
			<path fill="#E3E7EC" d="M93.5,104.7c0.1,0,0.2-0.1,0.3-0.1c0-0.1-0.1-0.2-0.1-0.3c0,0-0.2,0-0.2,0
				C93.3,104.5,93.4,104.6,93.5,104.7z"/>
			<path fill="#D5DCE5" d="M95.7,114c-0.2,0.1-0.3,0.1-0.3,0.1c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0,0.3-0.1
				C95.7,114.3,95.7,114.2,95.7,114z"/>
			<path fill="#EBEEF2" d="M96.7,105.7c0.1,0.1,0.1,0.3,0.2,0.3c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.1-0.3
				C96.9,105.6,96.8,105.7,96.7,105.7z"/>
			<path fill="#ECEFF2" d="M104.1,120.6c0.1,0.1,0.1,0.3,0.2,0.3c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.1-0.2
				C104.4,120.5,104.3,120.5,104.1,120.6z"/>
			<path fill="#F7F8FA" d="M108.2,124.5c0.1-0.1,0.4-0.2,0.3-0.4c-0.1-0.3-0.4-0.1-0.6,0C108,124.2,108.1,124.4,108.2,124.5z"/>
			<path fill="#E2E7EC" d="M93.1,116.4c0.1,0,0.2,0,0.3-0.1c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2,0-0.3,0
				C93.1,116.1,93.1,116.2,93.1,116.4z"/>
			<path fill="#CCD5E0" d="M96.9,118.9c-0.1,0.1-0.1,0.3,0,0.3c0.1,0,0.2-0.1,0.3-0.1c0,0,0-0.1,0-0.2
				C97,118.9,96.9,118.9,96.9,118.9z"/>
			<path fill="#F8F9FA" d="M102.5,107.1c0.1,0.2,0.1,0.3,0.1,0.3c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2-0.1-0.3
				C102.8,107.1,102.7,107.1,102.5,107.1z"/>
			<path fill="#F1F3F5" d="M104.3,125.1c-0.2-0.1-0.4-0.1-0.5,0c0,0.2,0,0.4,0.2,0.4C104.1,125.5,104.2,125.2,104.3,125.1z"/>
			<path fill="#F2F3F6" d="M96.8,121.3c0.1-0.1,0.4-0.2,0.1-0.4c-0.3-0.2-0.4,0-0.6,0.2C96.5,121.1,96.6,121.2,96.8,121.3z"/>
			<path fill="#CDD6E0" d="M86.9,115.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C87,115.1,86.9,115.2,86.9,115.3z"/>
			<path fill="#DFE4EA" d="M108.5,128.9c0-0.2,0-0.4,0-0.6c-0.2,0-0.4-0.2-0.5,0.1C108,128.7,108.3,128.7,108.5,128.9z"/>
			<path fill="#D6DCE5" d="M113.5,132.1c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0,0.2-0.1c0-0.1-0.1-0.2-0.2-0.3
				C113.7,131.9,113.5,131.9,113.5,132.1z"/>
			<path fill="#F4F6F8" d="M111.4,131.7c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1,0,0.2,0.1,0.2c0.1,0,0.2,0,0.3-0.1
				C111.5,132.1,111.4,131.9,111.4,131.7z"/>
			<path fill="#F8F9FA" d="M109.5,130.7c0.1,0.2,0.1,0.3,0.1,0.3c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2-0.1-0.3
				C109.7,130.7,109.6,130.7,109.5,130.7z"/>
			<path fill="#F3F4F6" d="M104.3,106.8c-0.2,0-0.3,0-0.4,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0,0.2,0.1,0.3,0
				C104.3,107.1,104.3,107,104.3,106.8z"/>
			<path fill="#C8D2DE" d="M87.7,119.2c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.1-0.3
				C87.9,119.2,87.8,119.2,87.7,119.2z"/>
			<path fill="#EFF1F4" d="M108.3,131c-0.2,0-0.3,0-0.5,0c0,0.2-0.1,0.6,0.2,0.6C108.3,131.6,108.2,131.2,108.3,131z"/>
			<path fill="#FCFCFD" d="M95.8,124c-0.1,0.2-0.2,0.5,0,0.6c0,0,0.2-0.3,0.4-0.4C96,124.2,95.9,124.1,95.8,124z"/>
			<path fill="#EEF0F3" d="M106.5,131.4c0.2-0.2,0.3-0.3,0.4-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.1,0.1-0.2,0.1-0.2,0.2
				C106.5,131,106.5,131.2,106.5,131.4z"/>
			<path fill="#E3E7ED" d="M104.3,129.8c-0.1-0.2-0.1-0.3-0.2-0.3c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0.1,0.3
				C104,129.8,104.2,129.8,104.3,129.8z"/>
			<path fill="#E2E6EB" d="M107.9,132.1c-0.1,0.2-0.1,0.2,0.1,0.3c0.1,0,0.2,0,0.3,0c0.1-0.1,0.1-0.2-0.1-0.3
				C108.1,132.1,108,132.1,107.9,132.1z"/>
			<path fill="#D2DAE3" d="M90.8,122.9c0.1,0.2,0.1,0.4,0.1,0.4c0.2,0,0.3,0,0.4-0.1c0.2-0.1,0.1-0.2-0.1-0.2
				C91.1,122.9,91,122.9,90.8,122.9z"/>
			<path fill="#DFE4EB" d="M108.4,133.8c0-0.1,0.1-0.1,0.1-0.2c-0.3-0.2-0.5-0.1-0.7,0.1c0,0,0,0.2,0,0.2
				C108,133.9,108.2,133.8,108.4,133.8z"/>
			<path fill="#BFCCD9" d="M106.7,111.2c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.6-0.8,0.8-1.4,1.4c0.7-0.2,1.1,0.2,1.5,0.4
				c0.4,0,0.6-0.3,0.8-0.6c0.2-0.3,0.4-0.6,0.8-0.7c0.2,0.1,0.3,0.2,0.5,0c0,0,0,0,0,0c-0.1-0.2-0.2-0.3-0.4-0.2
				c-0.1-0.2-0.1-0.4,0-0.5c-0.6-0.3,0.1-1-0.4-1.3c-0.5,0.1-0.7,0.3-0.5,0.8C107.3,111.1,107.1,111.3,106.7,111.2z"/>
			<path fill="#CBD5DF" d="M109.1,114.8c0.3,0.6,0.1,1.5,1,1.7c0.2,0,0.4-0.1,0.5-0.3c0-0.3,0.6-0.9-0.3-0.9c0-0.5-0.7-0.3-0.7-0.8
				C109.2,114.3,109.1,114.5,109.1,114.8z"/>
			<path fill="#E8EBEF" d="M109.8,113.6c0-0.3,0.8-0.6-0.1-0.8c-0.3-0.1-0.6-0.2-0.9-0.4C108.3,113.6,109.1,113.6,109.8,113.6z"/>
			<path fill="#FAFAFB" d="M107.3,112.7c-0.5,0-0.5,0.4-0.7,0.7c0,0.1-0.1,0.1-0.1,0.2c0.6,0,1.3,0.1,1.3-0.9
				C107.7,112.4,107.5,112.6,107.3,112.7z"/>
			<path fill="#D3DAE3" d="M108.5,113.8c-0.7,0.2-0.8,0.8-1,1.4C108.4,115.1,108.2,114.3,108.5,113.8z"/>
			<path fill="#E3E7EC" d="M109.3,119.3c0.5-0.2,1.3,0,1.3-0.9c-0.1-0.2-0.2-0.3-0.4-0.2c-0.2,0.4-0.4,0.7-0.8,0.9
				C109.3,119.1,109.3,119.2,109.3,119.3C109.2,119.3,109.2,119.3,109.3,119.3z"/>
			<path fill="#E3E7EC" d="M112,118.4c-0.1-0.5-0.6-0.6-0.9-0.8c-0.3,0-0.3-0.6-0.7-0.3c-0.1,0.1,0.1,0.2,0.2,0.3
				c0.1,0.4,0.5,0.4,0.7,0.7c0.1,0,0.1,0.1,0.2,0.1C111.6,118.5,111.8,118.5,112,118.4z"/>
			<path fill="#F8F9FB" d="M111.2,118.3c0-0.4-0.4-0.5-0.7-0.7c-0.1,0.2-0.2,0.4-0.4,0.6c0.1,0.1,0.3,0.1,0.4,0.2
				C110.8,118.4,111,118.3,111.2,118.3z"/>
			<path fill="#F3F5F7" d="M107.3,112.7c0.2,0,0.3,0,0.5,0c0.1-0.2,0.2-0.4,0.4-0.6C107.6,111.8,107.4,112.2,107.3,112.7z"/>
			<path fill="#D2DAE3" d="M106,113.9c0.1,0.2,0.1,0.4,0.2,0.7c0.3,0.1,0.5,0.2,1,0C106.7,114.3,106.4,114.1,106,113.9z"/>
			<path fill="#EAEDF0" d="M107.2,119.5c0.2,0.4,0.6,0.5,1,0.3c-0.1-0.2,0-0.6-0.5-0.6C107.5,119.3,107.2,119.2,107.2,119.5
				L107.2,119.5z"/>
			<path fill="#E2E6EC" d="M108.7,116.9c-0.4-0.2-0.5,0-0.7,0.1c-0.1,0.1,0,0.2,0.1,0.2c0.2,0,0.4,0.1,0.5-0.1
				C108.7,117,108.7,116.9,108.7,116.9z"/>
			<path fill="#F2F4F7" d="M105.7,116.4c0.1,0.1,0.2,0.3,0.3,0.4c0.6-0.2,0.5-0.5,0.3-0.9C106.1,116.1,105.9,116.3,105.7,116.4z"/>
			<path fill="#EAEDF1" d="M110.9,114.2c0.2-0.3,0.1-0.5-0.2-0.7c-0.2,0.1-0.4,0.1-0.7,0.2C110.3,113.9,110.6,114,110.9,114.2z"/>
			<path fill="#CFD8E2" d="M109.3,119.3c0-0.1,0.1-0.1,0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.2-0.1c0,0.2-0.1,0.5-0.1,0.7
				C109.2,119.9,109.2,119.6,109.3,119.3z"/>
			<path fill="#DEE3E9" d="M110.5,116.2c-0.2,0-0.4,0.1-0.5,0.3c0,0.2,0.1,0.3,0.3,0.3C110.6,116.7,110.6,116.4,110.5,116.2z"/>
			<path fill="#EBEEF1" d="M108.9,118.9c0.1,0,0.1,0.1,0.2,0.1c0.1-0.2,0.2-0.5-0.2-0.6c-0.1,0-0.2,0.1-0.3,0.1
				C108.8,118.7,108.9,118.8,108.9,118.9z"/>
			<path fill="#E8ECF0" d="M107.4,116.5c0.1-0.3,0.1-0.7-0.2-0.7c-0.4,0-0.1,0.3-0.1,0.5C107.2,116.4,107.3,116.4,107.4,116.5z"/>
			<path fill="#F1F3F6" d="M109.1,114.8c0.1-0.1,0.3-0.2,0.4-0.3c0-0.3-0.2-0.5-0.5-0.4C108.7,114.3,109,114.6,109.1,114.8z"/>
			<path fill="#BFCCD9" d="M107.2,119.6c0.1-0.1,0.3-0.2,0.4-0.3c-0.3-0.2-0.6-0.3-0.8-0.5C106.9,119,107.1,119.3,107.2,119.6z"/>
			<path fill="#CBD4DF" d="M107.8,122.4c0.2,0.1,0.4,0.3,0.5,0.4c0.5,1.1,0.7,0.5,1-0.1c-0.4-0.2-0.2-0.9-0.8-1
				c-0.2,0-0.3,0.2-0.5,0.3c-0.1,0-0.2,0.1-0.3,0.1C107.7,122.2,107.7,122.3,107.8,122.4z"/>
			<path fill="#F8F9FA" d="M112,118.4c-0.2-0.1-0.3-0.1-0.5,0c0,0.5,0.1,1,0.7,1.2C112.6,119.2,112.1,118.8,112,118.4z"/>
			<path fill="#FCFCFD" d="M111.8,121.1c-0.2-0.1-0.3-0.2-0.3-0.4c-0.3,0-0.6,0-0.7,0.4c0,0.2,0.1,0.3,0.2,0.3
				C111.4,121.5,111.7,121.4,111.8,121.1z"/>
			<path fill="#D9DFE7" d="M108.5,120.6c-0.8,0-1,0.4-0.6,1c0.3-0.1,0.5-0.2,0.6-0.5C108.4,120.9,108.4,120.8,108.5,120.6z"/>
			<path fill="#EBEEF1" d="M108.5,121.1c-0.2,0.2-0.4,0.3-0.6,0.5c0,0.1,0.1,0.2,0.1,0.3c0.2,0,0.4-0.1,0.5-0.3
				C108.6,121.5,109.2,121.3,108.5,121.1z"/>
			<path fill="#EBEEF1" d="M110.4,122.8c-0.1,0.2-0.2,0.3,0,0.5c0.2,0,0.6,0,0.6-0.3C110.9,122.7,110.5,122.8,110.4,122.8z"/>
			<path fill="#EBEEF1" d="M110.8,119.5c-0.2,0-0.3,0-0.4,0.1c0,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.3-0.1
				C111,119.7,111,119.6,110.8,119.5z"/>
			<path fill="#FAFAFB" d="M106.2,109.3c-0.2,0.4-0.9,0.9,0.1,1.2C106.6,110,106.8,109.6,106.2,109.3z"/>
			<path fill="#E9ECF1" d="M107.3,107.4c0-0.2-0.1-0.3-0.3-0.4c-0.1,0.2-0.2,0.3-0.4,0.3c0,0.3,0,0.5,0.4,0.5
				C107.3,107.8,107.2,107.6,107.3,107.4z"/>
			<path fill="#F9F9FB" d="M107.7,109.6c0.8,0.1,0.5-0.5,0.5-0.8C107.8,108.9,107.6,109.2,107.7,109.6z"/>
			<path fill="#EFF1F4" d="M107.8,108.5c0-0.2-0.1-0.3-0.3-0.5c-0.1,0.3-0.2,0.6-0.1,0.9C107.5,108.8,107.6,108.6,107.8,108.5z"/>
			<path fill="#E7EAEF" d="M107.5,110.8c-0.1-0.4,0.1-0.7,0.5-0.8c-0.1-0.1-0.3-0.2-0.4-0.2C107.4,110.1,107,110.5,107.5,110.8z"/>
			<path fill="#F6F7F8" d="M108.3,111.9c0.1,0.1,0.3,0.2,0.4,0.2c0.1-0.1,0.3-0.2,0.4-0.3C108.9,111.6,108.6,111.4,108.3,111.9z"/>
			<path fill="#CAD4DF" d="M107.8,122.4c0-0.1-0.1-0.2-0.1-0.3c-0.8,0.2-0.7,1.1-1.2,1.6c0.1,0,0.2-0.1,0.3-0.1
				C107.4,123.4,107.3,122.6,107.8,122.4z"/>
			<path fill="#F9FAFB" d="M105.4,115.5c0.1,0,0.2-0.1,0.3-0.1c0.1-0.3,0.1-0.6-0.1-0.8C105.1,114.7,105.3,115.1,105.4,115.5z"/>
		</g>
	</g>
</g>
<g id="body">
	<g id="sM0XBO_1_">
		<g>
			<path fill="#EBE2CF" d="M0,199.4c0.4,0.3,0.2,0.7,0.2,1.1c0.1,5.2,0.1,10.3,0.3,15.5c0.2,4,0.6,8,1,11.9
				c0.4,4.2,0.8,8.3,1.4,12.5c0.6,4.2,1.4,8.3,2.2,12.5c0.4,2,0.5,4.1,1,6.2c0.4,1.9,0.9,3.9,1.3,5.8c0.5,2.8,1.2,5.6,1.8,8.4
				c0.4,1.9,1.1,3.8,0.9,5.9c-0.3,3-0.5,6-0.6,9c-0.1,2.2-0.2,4.4-0.4,6.6c-0.5,5.5,1.6,10.2,3.6,15.1c0.9,2.2,1.9,4.4,2.8,6.6
				c0.4,0.2,0.4,0.8,0.8,1c1.3,0.5,1.9-0.4,2.4-1.3c0.4-0.6,0.7-0.9,1.5-0.6c1.2,0.5,2.3-0.4,2.4-1.9c0.2-2.1,0.2-4.1-0.1-6.2
				c-0.5-2.7-0.6-5.4-0.4-8.5c0.6,1.1,1,1.9,1.6,2.7c0.6,0.8,1.3,1.4,2.3,1.5c0.8,0.1,1.2-0.3,1.1-1.1c0-0.5-0.1-1-0.2-1.5
				c-1-3.3-1.3-6.7-2-10c-0.9-4.5-2.4-8.9-4-13.2c-0.3-0.7-0.3-1.5-0.5-2.2c-0.3-1.9-0.4-3.8-0.4-5.7c0-2.6,0-5.2,0.1-7.8
				c0.1-3.8,0.1-7.5,0-11.3c0-3.1-0.3-6.1-0.1-9.2c0.2-3.7,0.1-7.4,0.1-11.1c0-5.8,0.6-11.5,0.8-17.2c0.1-3.6,0.4-7.1,0.6-10.7
				c0.1-2.8,0.6-5.6,0.7-8.4c0.3-5.1,1-10.2,1.7-15.2c0.8-5.7,1.4-11.5,2.6-17.2c1-4.8,1.7-9.7,2.7-14.5c0.1-1.1,0.2-2.3,0.6-3.4
				c0.4-1.1-0.4-1.7-1.2-2.2c-4.2-2.9-8.8-4.9-13.4-6.9c-2.9-1.2-5.9-2.2-9.1-2.6c-0.6-0.1-0.8-0.6-1.3-0.7
				c-0.3,0.2-0.3,0.5-0.4,0.7c-0.8,5-1.7,10-2.1,15.1c-0.4,4.3-0.9,8.7-1.1,13c-0.2,2.7-0.6,5.4-0.6,8.1c-0.1,3.7-0.1,7.3-0.2,11
				c-0.1,5.1,0,10.1-0.1,15.2c-0.1,1.6,0.1,3.2-0.1,4.8C0,199.3,0,199.4,0,199.4z"/>
			<path fill="#DBB22C" d="M7.5,50.2c0,0.3,0,0.6,0,1c0.2,0.3,0.1,0.6,0.1,0.9c0.1,0.5,0.4,0.5,0.7,0.2c0.1-0.1,0.2-0.4,0.5-0.3
				c0.1,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.5-1,0.7-0.7,1.4l0,0c0.9,0.2,1.2,1,1.7,1.6c0.2,0.2,0.9-0.1,0.7,0.6
				c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.6,0.2-0.6,0.6c0.1,0.3,0.3,0.6,0.5,0.8c0.1,0.1,0.2,0.1,0.3,0.1
				c0.8-0.3,0.4,0.2,0.3,0.5c0.1,0.3,0.2,0.7,0.7,0.4c0.4-0.4,0.9-0.4,1.4,0.2c-0.6,0.1-0.9-0.2-1.3-0.1c-0.2,0-0.4,0.1-0.6,0.3
				c-0.1,0.2-0.2,0.5-0.4,0.1c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2,0-0.3,0-0.5,0.1c-0.3,0.8-0.9,1.6-0.6,2.5c0,0.4,0.3,0.6,0.7,0.6
				c0.3,0,0.6-0.1,0.9,0.1c0.5,0.4,0.8,1.1,1.6,0.4c0.2-0.1,0.6,0.2,1,0.2c0.3,0.1,0.8,0.1,0.2,0.5c-0.3,0.2-0.6,0.4-0.9,0.6
				c-0.3,0.3-0.5,0.1-0.6-0.2c-0.2-0.4-0.3-0.9-0.9-0.9c-0.5,0-0.7,0.3-0.8,0.7c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.2,0.2,0.3
				c0.5,0.2,0.6,0.7,0.8,1.2c-0.4-0.3-0.2-1.1-0.9-1.2c-0.8,0.2-0.4,1.1-0.9,1.6c-0.3-0.4-0.2-1.2-1-1.2c-0.5,1.6-1,3.1-1.4,4.7
				c-0.5,2.2-0.8,4.4,0.1,6.5c1.5,3.6,5.4,5,8.9,3.7c0.6-0.2,1.3-0.4,1.8-1c0.1-0.6-0.5-0.5-0.8-0.7c-0.3-0.2-0.9-0.2-0.9-0.8
				c0.1-0.7,0.7-0.5,1.1-0.5c0.4-0.1,0.4-0.4,0.3-0.7c-0.1-0.2-0.1-0.2,0.1,0c0,0-0.1,0,0,0.1c0.6,0.5,1.2-0.1,1.8,0
				c0.5,0,0.8-0.1,0.9-0.7c0-0.7-0.7-1-0.8-1.7c-0.3-0.4-0.7-0.2-1.1-0.6c0.4,0,0.7,0.2,1.1,0.2c0.1,0,0.2,0,0.3-0.1
				c0.4-0.3,1-0.3,1.2-0.7c0-0.1,0.1-0.2,0-0.3c0.1,0.1,0,0.3,0.1,0.3c0.3,0.2,0.6,0.3,1,0.1c0.2-0.8,0.5-1.5,0.5-2.3
				c-0.3-0.8-0.2-1.3,0.2-2.1c0.3-0.6,0.5-1.7-0.2-2.5c0.4-0.3,0.3-0.8,0.5-1.2c-0.2-2.8-0.5-5.6-0.9-8.4c-0.3-0.2-1.1-0.4-0.3-0.9
				c0.2-0.1,0.1-0.4,0-0.5l0,0c-0.9-0.1-0.4-0.5-0.3-0.9c0.2-0.9-0.3-1.7-0.3-2.6c-0.5-0.4-1-0.2-1.5,0.1c-0.2,0.2-0.6,0.2-0.8,0.5
				c-0.5,0.4-0.7,0.8,0,1.3c0.2,0.2,0.7-0.1,0.5,0.4c-0.1,0.4-0.4,0.6-0.7,0.7c-0.2,0.1-0.3,0.5-0.6,0.4c-0.1,0-0.1,0.1-0.1,0.2
				c0-0.1,0-0.1,0.1-0.2c0.3-0.2,0.5-0.4,0.8-0.6c0.2-0.1,0.3-0.3,0.2-0.6c0-0.1-0.1-0.2-0.2-0.2c-0.2-0.1-0.4-0.1-0.5,0
				c-0.1,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.2-0.5c0-0.3-0.2-0.4-0.3-0.6c0,0,0-0.1,0-0.1s0.1,0,0.1,0
				c0.3,0.3,0.6,0.4,0.9,0c0.2-0.2,0.5-0.1,0.7-0.3c0.3-0.5,0.9-0.6,1.4-0.7c0.2-2.1-0.3-4.2-0.1-6.3c0.3-3.4,0.8-6.8,2.8-9.7
				c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.2-0.1,0.4-0.2c0.2,2,0.4,4,1,5.8c1.2,3.9,2.5,7.6,5,11.1c3.2,4.5,7.8,6.8,12.5,9.1
				c2.8,1.4,5.7,2.7,8.5,4c0.4,0.2,0.8,0.3,1.3,0.2c0.7-0.1,0.9-0.6,1-1.2c0.4-3.2,1-6.4,1.5-9.6c0.3-1.9,0.6-3.7,1-5.5
				c0.4-1.7,0.2-2.2-1.5-2.7c-3.6-1.2-5.3-3.9-5.1-7.7c0.1-0.9,0.1-1.8,0.4-2.7c0.9-2.2,2.8-2.5,4.5-0.9c0.3,0.3,0.6,0.7,0.8,1
				c0.8,0.9,1.8,0.9,2.5-0.1c0.6-1,1.4-1.9,2-2.9c0.4-0.9,1.1-1.6,1.6-2.5c2-3.6,4-7.2,5.9-10.8c0.9-1.8,1.7-3.7,2.6-5.5
				c0.6-1.3,0.6-1.3,1.7-0.3c2.4,2.3,5.1,4.1,8,5.7c2.1,1.2,4.4,1.9,6.8,2.2c0.3,0,0.6,0.1,0.8-0.1c1-0.1,1.2-0.6,1-1.6
				c-1.1-5-3.1-9.6-7-13c-3.6-3.2-7.7-4.6-12.5-2.8c-1.2,0.4-2.4,0-3.6-0.4c-2.6-1.1-5.4-1.6-8.2-2c-0.6-0.3-1.3,0.1-1.9-0.2
				c-0.2,0-0.5,0-0.7,0c-0.1,0.1-0.3,0.2-0.4,0.2c-0.8,0-1,0.5-1.2,1.1c0,0.1,0,0.2-0.1,0.2c0-0.1,0-0.2-0.1-0.3
				c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4,0-0.6,0.3-0.7,0.6c-0.1,0.2,0,0.4,0,0.5c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.3-0.1-0.5,0
				c-0.1,0.1-0.1,0.2-0.1,0.3c0.1,0.5,0.2,1,0.5,1.4c0.1,0.2,0.5,0.3,0.2,0.6c-0.1-0.1,0-0.4-0.1-0.5c-0.2-0.2-0.4-0.4-0.6-0.5
				c-0.3-0.1-0.5-0.2-0.8,0c-0.4,0.5-0.6,1.1-1.1,1.5c-0.1,0.1-0.2,0.1-0.2,0.2c-0.3,0.4-0.6,0.8-0.7,1.3c0,0.4,0.2,0.6,0.6,0.7
				C47.8,7,48,6.2,48.1,6.7c0.2,0.5-0.6,0.1-0.7,0.5c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.6,0,1.2-0.3,1.7c-0.4,0.5-1,0.8-1.3,1.4
				c-0.1,0.2,0,0.4,0.1,0.7c-0.4-0.2-0.5-0.8-0.9-0.9c-0.2-0.1-0.3,0-0.4,0.1c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.2,0.1,0.3
				c0.1,0.3,0,0.6,0.3,0.8c0.2,0.2,0.7,0,0.6,0.5c0,0.3-0.2,0.6-0.4,0.7c-0.3,0.1-0.6-0.2-0.7-0.5c-0.1-0.2-0.2-0.5-0.4-0.7
				c-0.3-0.2-0.6-0.2-0.9,0c-0.3,0.3,0.1,0.7-0.1,1c-0.6,0.3-1.1,0.6-1.3,1.3c-0.2-0.4-0.3-0.7-0.7-0.7c-0.2,0-0.3,0.1-0.4,0.3
				c-0.3,1.1-1.3,1-2.1,1.1c-0.4,0.1-0.8-0.1-0.9,0.5c0.1,0.6,0.1,1.2-0.6,1.5c-0.5,0.7-0.7,1.6-1.5,2.1c-0.1,0.2-0.2,0.4-0.1,0.6
				c0.1,0.2,0.5,0.1,0.6,0.5c0,0.1-0.1,0.1-0.2,0.2c-0.2,0.2-0.4,0.4-0.5,0.7c-0.1,0.3-0.1,0.6,0.2,0.8c0.2,0.1,0.3-0.1,0.5,0
				c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0.1-0.3,0.3-0.4,0.5c0,0.2,0,0.3,0,0.5c0,0.2,0.2,0.5,0,0.7c-0.1,0.1-0.2-0.2-0.3-0.3
				c-0.4-0.6-1-0.8-1.7-0.7c-0.1,0-0.2,0.1-0.3,0.2c0.4,0.8-0.8,0.8-0.7,1.5c0,0.2,0.3,0.1,0.2,0.3c0,0-0.1-0.2-0.2-0.2
				c-0.2-0.3-0.7-0.2-0.9-0.6c-0.3-0.5-0.7-0.7-1.1-0.9c-0.5,0.7-1.4,0.1-1.9,0.7c-0.1,0.1-0.2,0.3-0.2,0.4c0.2,1.1,0.9,1.4,2.1,1.2
				c-1.3,0.5-1.9,0.3-2.1-1c-0.1-0.4-0.1-0.9-0.5-1.2c-0.1-0.8-0.5-1.6,0.1-2.4c0.5-0.6,0.1-1.2-0.6-1.2c-0.2,0.2-0.2,0.5-0.5,0.6
				c0-0.4,0.3-0.6,0.3-1c0-0.1,0-0.2,0-0.3c-0.2-0.6-0.6-0.6-1.1-0.2c-0.7,1.3-0.8,2.9-1.2,4.3c0.1,0.4,0.6,0.4,0.8,0.8
				c-0.3,0.4-0.7,0-1,0.2c0,0.3,0,0.6-0.1,0.9c0,0.1,0,0.1,0,0.2c0.1,0.2,0.4,0.2,0.3,0.5c-0.1-0.1-0.3-0.2-0.5-0.1
				c0,1.1-0.9,0.7-1.4,0.6c-2.1-0.2-4.1,0.1-6,1c-3.1,1.3-5.1,3.6-6.6,6.5c-0.8,1.6-1.4,3.2-1.9,5c-0.1,0.5-0.3,1-0.3,1.5
				c0,1.2,0.8,0.2,1.2,0.5c-1.4-0.1-1.6,0.7-1.5,1.8c0,0.3,0.1,0.6-0.1,0.8c-0.3,1.5-0.1,3-0.2,4.6c0.6,0.4,0.6,1.4,1.3,1.8
				C9.1,49.7,9.1,50,9,50.3c0.1-0.3,0-0.6-0.3-0.7C8.2,49.4,7.7,49.6,7.5,50.2z"/>
			<path fill="#F6F2E9" d="M0.2,194.4c-0.1,0-0.2,0-0.2,0c0,1.6,0,3.2,0,4.8C0.5,197.6,0.1,196,0.2,194.4z"/>
			<path fill="#F9F4E3" d="M53.3,0.3c0.1-0.1,0.2-0.2,0.2-0.3c-0.8,0-1.6,0-2.4,0c0,0.1,0,0.3,0,0.4c0,0,0.1,0.1,0.1,0.1
				c0.2,0.1,0.5,0,0.7,0C52.3,0.3,52.8,0.4,53.3,0.3z"/>
			<path fill="#F3E7C1" d="M54.2,0c0.6,0.6,1.3,0.1,1.9,0.2c0-0.1,0-0.2,0-0.2C55.5,0,54.9,0,54.2,0z"/>
			<path fill="#5C7891" d="M20.2,275.3c-0.1,1.6,0.6,2.9,1.1,4.3c2.1,5.3,3.3,10.9,4.2,16.5c0.3,1.9,0.9,3.8,1.3,5.7
				c0.2,1,0,1.3-1.1,1.1c-0.7-0.2-1.2-0.7-1.7-1.3c-0.8-1.1-1.4-2.3-2-3.5c-0.7,2.1-0.5,4.2-0.3,6.3c0.2,2.6,0.8,5.1,0.7,7.7
				c0,0.6,0,1.2-0.2,1.8c-0.2,0.7-0.4,1.3-1.2,1.4c-0.8,0.1-1.3-0.2-1.7-1c-0.4,0.7-0.7,1.3-1.1,1.9c-0.5,0.7-1.1,1.4-2.1,0.8
				c-0.5-0.2-0.3,0.4-0.5,0.5c-0.3,3.2-0.5,6.4-0.5,9.6c-0.1,3.6-0.5,7.2-0.7,10.8c-0.2,3.3-0.4,6.6-0.5,9.9
				c-0.2,4.2-0.3,8.3-0.4,12.5c-0.1,5.1-0.2,10.2-0.3,15.2c0,0.9,0.7,0.8,1.2,0.8c2.1,0.3,4.2,0.5,6.4,0.8c4,0.6,8.1,0.8,12.1,0.7
				c4,0,7.9,0.5,11.8,0.4c2.6,0,5.2,0.2,7.8-0.1c1.8-0.2,3.7,0.2,5.5,0.2c0.9,0,1.5-0.7,2.4-0.4c3.7-0.1,7.5-0.1,11.2-0.2
				c1.5,0,3.1,0,4.6,0c1.5,0.5,3.1,0.2,4.6,0.1c3.7-0.1,7.3-0.1,11-0.1c2.4,0,4.9-0.2,7.3-0.3c4.5-0.1,9.1-0.3,13.6-0.5
				c0.9,0,1.7-0.3,2.6-0.4c3.7-0.2,7.4-0.3,11-0.5c0.8,0,1.1-0.5,1.1-1.1c0-1.9,0-3.8-0.1-5.7c0-2.9,0-5.9-0.2-8.8
				c-0.2-4.3-0.5-8.6-0.5-12.9c0-2.9-0.3-5.7-0.3-8.5c0-2.4-0.3-4.9-0.5-7.3c-0.3-3.9-0.4-7.7-0.6-11.6c-0.3-4.4-0.9-8.8-1.2-13.2
				c-0.2-3.2-0.5-6.5-0.8-9.7c-0.6-7.8-1.5-15.5-2.7-23.3c-0.7-4.7-1.4-9.4-2.2-14c-0.6-3.5-1.4-7-2.3-10.5
				c-1.3-5-2.6-10.1-4.2-15.1c-1.7-5.4-3.9-10.7-6.3-15.8c-1.1-2.4-2.2-4.8-3.3-7.2c-0.3-0.7-0.9-1.2-1.2-1.9
				c-0.3-0.5-0.8-0.5-1.4-0.5c-5.8,0.6-11.6,0.8-17.4,0.9c-6.6,0.1-13.2,0.2-19.9,0.2c-3.7,0-7.4-0.4-11.1-0.7
				c-1.9-0.2-3.9-0.2-5.8-0.4c-0.3,0-0.6-0.1-0.9-0.1c-0.9,0.1-1.8-0.6-2.8-0.1c-0.6,0.4-0.9,1-1.3,1.5c-4.5,6.9-7.6,14.4-10.3,22
				c-2.9,8-5.1,16.1-6.9,24.4c-1.2,5.7-2.4,11.4-3.1,17.2c0,0.4,0,0.8,0,1.2C20.2,275.2,20.2,275.2,20.2,275.3z"/>
			<path fill="#BBC8D6" d="M45.1,209.3c4.1,0.3,8.1,0.8,12.2,0.9c6.3,0.3,12.6,0.3,18.9,0.2c4.5-0.1,9,0,13.5-0.2
				c3.4-0.1,6.8-0.5,10.2-0.9c0.5,0,0.9,0,1.3,0.2c-0.6-3,0.5-5.8,1.1-8.6c0.8-3.9,1.8-7.7,2.5-11.6c0.9-5.4,1.7-10.9,2.3-16.4
				c0.3-3.1,0.9-6.2,1.3-9.3c0.3-2.1,0.4-4.3,0.6-6.4c0.4-6.4,0.3-12.8,0.3-19.2c0-2,0.3-3.9,0.4-5.8c0.1-2-0.1-4.1-0.2-6.1
				c-0.1-4.6-0.2-9.1-1-13.6c-0.1-0.6,0-1.8-1.4-1.5c-0.3,0.1-0.3-0.7-0.3-1c-0.1-0.5,0.2-0.3,0.5-0.2c0.3,0.1,0.4-0.2,0.3-0.3
				c-0.2-0.5,0.1-1.2-0.7-1.4c-0.3-0.1-0.5-0.2-0.6-0.6c0-0.2-0.1-0.4-0.4-0.4c-0.3,0-0.6,0.1-0.9,0c-0.5-0.7-1-0.1-1.5-0.1
				c-0.2,0-0.4-0.1-0.6,0c-0.2,0-0.4,0.1-0.7,0c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c0-0.3,0.3-0.2,0.4-0.3
				c0.5-0.4,1-1,1.8-0.5c0.2,0.1,0.3,0.1,0.5,0c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.5,0.4-1.1-0.1-1.6c0.2-0.7-0.7-0.2-0.7-0.7
				c0.1-1.8,0-1.9-1.5-1.9c-0.3,0.2-0.6,0.1-0.8-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0-0.1,0-0.1s0.1,0,0.1,0c0.1,0.1,0.2,0.2,0.2,0.3
				c0.1,0.1,0.2,0.2,0.4,0.2c0.2-0.1,0.4-0.1,0.6-0.3c0.4-0.5,0.2-0.8-0.2-1.2c0-0.1-0.2-0.1,0-0.2c0.2-0.1,0.6,0.1,0.5-0.4
				c-0.3-0.6-1-1-1.2-1.7c-0.4-0.1-0.8-0.3-1.3-0.1c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0.1,0.5-0.2,0.3c-0.5-0.2-0.9-0.4-1.5-0.3
				c-0.1,0-0.2,0.1-0.2,0.2c-0.3,0.4,0.5,1.1-0.4,1.3c-0.7,0.1-0.5-0.4-0.5-0.8c0-0.3-0.2-0.5-0.5-0.6c-0.1-0.1-0.4,0-0.4-0.2
				c0,0,0.1-0.1,0.2-0.1c0.5-0.2,0.4-0.7,0.5-1c0-0.8-0.4-1.3-1.2-1.5c-0.5-0.1-0.3-1-0.9-1c0,0-0.1-0.1-0.1-0.1
				c-0.4,0-1.8-0.4-2.3-0.7c-0.9-0.6-1.8-1-2.8-1.3c-0.3-0.1-0.5-0.2-0.7,0.1c0.1,0.2,0.3,0.4,0.1,0.5c-0.3,0.2-0.6,0.1-0.8-0.1
				c-0.3-0.3-0.4-0.8-0.9-0.6c0-0.2,0.4-0.2,0.2-0.5c-1.5-0.5-3-1-4.6-1.2c-0.7-0.1-0.8,0.6-1.1,1c-1.2,1.9-2.4,3.8-3.9,5.4
				c-2.5,2.7-5.6,4.1-9.1,4.9c-2.5,0.6-5.1,0.8-7.6,0.8c-6.8,0-13.2-1.5-18.2-6.5c-1.5-1.5-2.8-3.1-3.8-4.9
				c-0.2-0.3-0.3-0.8-0.9-0.7c-2.5,0.9-4.9,1.9-7.2,3.1c-1.8,0.9-3.7,1.8-5.4,2.9c-4,2.5-7.7,5.4-10.7,9.1c-2.4,3-4.5,6.1-6,9.6
				c-0.7,1.7-1.3,3.6-1.7,5.4c-0.2,1.2-0.6,2.3-0.9,3.5c-0.6,2.4-0.8,4.9-1,7.3c0,0.4-0.1,0.9,0.6,1c3.6,0.3,7,1.5,10.2,2.9
				c3.3,1.4,6.6,2.8,9.7,4.7c1.1,0.7,2.1,1.4,3.2,2c0.8,0.5,1.1,1.1,0.7,1.9c-0.4,1-0.4,2.1-0.5,3.2c-0.2,1,0.2,1.9,0.5,2.8
				c1.7,6,3.2,12.1,4.6,18.2c0.4,1.7,0.6,3.5,1.1,5.1c0.7,2.4,0.7,4.9,1.4,7.3c0.3,1.1,0.3,2.2,0.6,3.3c0.3,1.1,0.5,2.3,0.9,3.3
				c0.3,0.2,0.6,0.1,0.8,0.1c0.3-0.3,0.8-0.1,1.2-0.4c0.1-0.1,0.1-0.3,0.1-0.4c0,0.1,0,0.2-0.1,0.3c-0.3,0.4-0.8,0-1.1,0.4
				c-0.4,0.1-0.8,0.1-0.8,0.6c0,0.2,0.2,0.4,0.4,0.6c0.4,0.2,0.8-0.1,1.2-0.1c0.2,0,0.2,0.1,0.1,0.1c0,0-0.1,0.1-0.1,0.2
				c-0.1,0.2-0.2,0.4-0.2,0.6c0.6,1.2,1.1,0.3,1.6-0.1c-0.3,0.4-0.5,0.9-1.3,0.7c-0.5-0.2-0.9,0.2-1,0.8c-0.1,0.5-0.1,0.9,0,1.4
				c0.1,0.8,0.4,1.5,0.5,2.3c0.1,0.7,0.5,1,1.2,0.6c-1.1,0.9-0.6,1.9-0.2,2.8c0.2,0.3,0.1,0.6,0.1,0.9c0.2,0.8-0.1,1.6,0.4,2.4
				c0.1,0.1,0.1,0.1,0.2,0c0.1,0,0.2-0.2,0.4-0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.6,0,0.9,0.6,1.1c-0.4,0.2-0.8,0.4-0.4,0.9
				c0.3,0.2,0.4,0.4,0.2,0.7c0,1,0.1,2,0.9,2.8c0.1,0.1,0.1,0.1,0,0.1c-0.7,0.7-0.5,1.5,0,2.2c0.8-0.1,1.6,0.5,2.4,0.2
				C44.8,208.6,44.9,209,45.1,209.3z"/>
			<path fill="#EBE2CF" d="M115.2,376.6c-13,0.4-26.1,0.8-39.1,1.2c0.6,0.2,0.9,0.7,0.9,1.3c0.1,3.1,0.5,6.1,0.6,9.1
				c0,2.3,0.4,4.6,0.5,6.9c0.2,3.2,0.2,6.3-0.1,9.5c-0.6,6-1.7,11.9-2.8,17.9c-0.8,4.4-1.2,8.7-1.7,13.1c-0.5,4.6-1.2,9.2-1.1,13.8
				c0.1,4.3,0,8.6,0.4,12.8c0.4,3.9,0.8,7.9,1.1,11.8c0.1,1.8,0.2,3.6,0.2,5.4c0.1,1.9,0.4,3.9,0.6,5.8c0.2,2.7,0.7,5.4,1,8.1
				c0.3,2.9,0.6,5.9,1,8.8c0.2,1.4,0.2,2.9,0.4,4.3c0.4,4.2,0.8,8.3,1.4,12.5c0.4,2.7,0.5,5.5,0.8,8.3c0.3,2.6,0.7,5.2,1,7.8
				c0.3,2.6,0.7,5.3,0.9,7.9c0.1,1.1,0.4,2.2,0.5,3.3c0.6,0.5,1.3,0.3,2,0.3c0.5,0,1,0,1.4,0.2c0.3,0.1,0.6,0.1,0.9,0
				c0.7-0.2,1.3-0.1,2-0.1c3.8,0,7.6,0,11.3,0c0.5,0,1.1,0.1,1.6-0.3c0.2-4.1,0.6-8.1,0.8-12.1c0.1-3.5,0.5-7,0.8-10.4
				c0.4-3.6,0.6-7.3,0.6-10.9c0-2.8,0.5-5.7,0.8-8.5c0.1-1.3,0.3-2.6,0.3-3.9c0.2-4.5,0.8-9,1.2-13.5c0.2-3,0.5-6,0.9-9
				c0.4-2.8,0.2-5.6,0.8-8.4c0.3-1.2,0.2-2.4,0.3-3.7c0.2-3.6,0.6-7.2,0.9-10.8c0.2-2.4,0.4-4.8,0.5-7.1c0.3-3.8,0.5-7.6,0.9-11.4
				c0.2-2.2,0.4-4.4,0.6-6.5c0.3-3.6,0.4-7.1,0.9-10.7c0.3-2.2,0.2-4.4,0.3-6.5c0.2-3.4,0.5-6.8,0.9-10.2c0.2-2.2,0.4-4.3,0.6-6.5
				c0.5-4.7,0.5-9.4,1.2-14.1c0.2-1.4,0-2.8,0.2-4.2C114.5,377.1,114.8,376.8,115.2,376.6z"/>
			<path fill="#EBE2CF" d="M60.3,378c-0.7-0.5-1.4,0-2.1,0c-2.9-0.1-5.8-0.1-8.8,0c-2.7,0.1-5.5,0.2-8.2-0.1
				c-3.6-0.4-7.2-0.3-10.8-0.4c-3.2-0.1-6.5-0.6-9.7-0.4c0.8,0.2,1.2,0.5,1.3,1.5c0.1,2.1,0.3,4.1,0.2,6.2c0,2.3-0.2,4.7-0.1,7
				c0.1,1.7-0.3,3.3-0.4,5c-0.2,2.7-1,5.2-1.2,7.8c-0.1,1.1-0.5,2-0.8,3c-1.5,4.4-2.9,8.9-4,13.5c-0.9,3.6-1.4,7.2-2,10.9
				c-0.2,1.7-0.4,3.4-0.7,5c-0.4,2.8-0.6,5.7-0.7,8.5c-0.2,4.4,0,8.9,0.1,13.3c0,4.1,0.4,8.2,0.6,12.3c0.3,4.3,0.7,8.6,1.3,12.9
				c0.2,1.7,0.3,3.5,0.4,5.3c0.1,2.3,0.5,4.6,0.7,6.9c0.3,3.7,0.7,7.3,0.8,11c0,1.7,0.3,3.4,0.6,5.1c0.3,2.1,0.2,4.2,0.3,6.2
				c0.1,1.5,0.3,3.1,0.5,4.6c0.1,0.9,0.2,1.9,0.2,2.8c0,3.3,0.5,6.5,0.6,9.7c0.2,4.1,0.6,8.2,1.1,12.2c0.5,0.4,1.1,0.3,1.7,0.3
				c4.1,0,8.1,0,12.2,0c0.5,0,1,0,1.5,0.1c0.3,0.1,0.5,0.1,0.8,0.1c1-0.1,1.7,0.2,2.1,1.1c0.3-0.4,0.2-0.9,0.2-1.4
				c0-1.6-0.1-3.1,0.4-4.6c0.3-1,0.1-2.2,0.1-3.3c0.1-2.9,0.7-5.7,0.7-8.6c0.1-2.7,0.4-5.4,0.7-8.1c0.4-3.5,0.7-7,1-10.4
				c0.2-2.2,0.5-4.4,0.7-6.6c0.4-4.1,1-8.2,1.4-12.2c0.4-3.5,0.7-7,1-10.4c0.3-3.3,0.7-6.5,1.1-9.8c0.6-4.8,1.3-9.6,1.8-14.4
				c0.5-4.4,0.8-8.8,1.4-13.1c0.7-5.1,0.9-10.3,1.8-15.4c0.7-4.3,1.2-8.6,2.2-12.9c0.5-2.2,0.7-4.6,1.1-6.9
				c0.5-3.4,1.4-6.7,1.8-10.1c0.7-5.6,1.9-11,2.8-16.5c0.3-1.9,0.6-3.7,0.9-5.6C59,378.5,59.3,377.9,60.3,378z"/>
			<path fill="#EBE2CF" d="M37.7,90.4c1.1,1.6,2.2,3.2,3.5,4.7c1.9,2.3,4.2,4,6.8,5.2c3.8,1.8,7.9,2.7,12,2.5
				c4.7-0.1,9.5-0.3,13.7-2.8c2.4-1.4,4.4-3.2,5.9-5.6c0.9-1.4,1.8-2.7,2.7-4c-3.6-1-7.1-2-10.7-2.9c-1.7-0.4-1.7-0.4-1.8-2.2
				c-0.1-4.4,0.1-8.8,0.3-13.1c0.1-1.9,0.1-3.9,2.2-4.9c1.5-0.8,3.2-1,4.8-1.2c2.7-0.2,5.5-0.4,7.8-2c1.6-1.1,2.3-2.9,2.8-4.7
				c0.4-1.2,0.6-2.5,0.9-3.7c0.2-0.8,0.2-1.1-0.8-1.4c-2.4-0.7-4.2-2.3-5.2-4.7c-0.3-0.7-0.2-1,0.7-0.8c1.7,0.4,3.4,0.6,5,0.9
				c0.6,0.1,1.1,0.1,1.1-0.7c0-1,0.3-2,0.3-2.9c0.1-1.1,1.1-1.3,1.8-1.7c0.6-0.4,1.3-0.7,2-1c1.2-0.5,1.5-1.4,0.8-2.4
				c-1.8-2.8-3.4-5.9-4.7-9c-0.2-0.4-0.2-0.8-0.3-1.1c-1.5,0.2-2.5,1.1-2.8,2.6c-0.2,1.1-0.4,2.3-0.8,3.4c-1.1,3.4-2.9,4.9-6.5,5
				c-0.2,0-0.5,0-0.5,0.2c-0.3,0.7-0.8,0.4-1.3,0.3c-0.3,0.1-0.6,0.3-0.8,0.6c0.4,0.5,0,1,0,1.5c0.1,0.2,0.4,0.4,0.3,0.6
				c-0.1,0.2-0.3,0-0.5,0c-0.1,0-0.2,0.1-0.2,0.2c0,0.5,0.2,1.1-0.7,1c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-0.1-0.5-0.4-0.8-0.1
				c-0.3,0.3-0.1,0.7,0.2,1c-0.8-0.6-1.7-0.7-2.6-0.6c-0.3,0.8-0.8,0.1-1.1,0.1c-0.5,0-0.5,1.2-1.3,0.6c-0.5-0.1-0.3-0.5-0.3-0.8
				c0-0.7,0-1.2-0.9-0.8c-0.4,0.4-0.7,0.1-0.9-0.2c-0.2-0.3-0.3-0.7-0.5-1.1c0.7,0.7,0.7,0.7,1.2,0c-0.1-1-1-1-1.6-1.3
				c-0.2-0.8-0.3-1.6-0.4-2.4c0.2,0.2,0.5,0.5,0.9,0.4c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.8,0.1-1.6,0.5-2.3c-0.1-0.3-0.4-0.6-0.1-0.9
				c0.3-0.2,0.5-0.4,0.9-0.2c0.2,0.1,0.5,0.2,0.5,0.1c0.2-1.3,1.1-0.5,1.7-0.6c0.8-0.2,1.6-0.3,2.5-0.2c0.5,0.3,0.9,0.1,0.9-0.5
				c-0.1-1.2-0.5-2.3-0.6-3.5c-0.1-0.6-0.4-0.9-1-1c-4.3-0.2-8.6-0.4-12.9-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.7,1-1.5,2-2.1,3
				c-0.6,1-1.3,1-2,0c-0.1-0.2-0.3-0.4-0.4-0.6c-0.8-0.9-1.7-1.9-3.2-1.5c-1.5,0.3-2.1,1.4-2.4,2.8c-0.5,2.8-0.6,5.6,1.6,8
				c1.1,1.2,2.3,1.9,3.8,2.2c1.1,0.2,1.3,0.8,1.1,1.7c-1.1,5.2-1.9,10.5-2.6,15.7c-0.1,0.5-0.4,0.8-0.7,1.2c0.6,0.5,0.6,1.1,0.4,1.8
				c-0.5,3.1-0.4,6.3-0.7,9.5c-0.3,3-0.5,6.1-0.7,9.1c-0.1,1-0.7,1.3-1.6,1.7c-2.1,0.7-4.2,1.5-6.3,2.3C41.3,89,39.5,89.7,37.7,90.4
				z"/>
			<path fill="#6E6E73" d="M85.7,546.5c-0.1,0.1-0.2,0.1-0.2,0c-1.2-0.2-2.4-0.3-3.6,0c0.3,3,0.7,6,0.9,9c0.1,1.6-0.1,3.2,0,4.7
				c0.1,3.1-0.6,6.1-0.6,9.1c0,3,0.4,6,0.9,9c0.2,1.6,0.5,1.8,2.2,1.8c2.3,0,4.6,0,6.8,0c1.1,0,1.4-0.3,1.4-1.4
				c0-1.4,0.2-2.8,0.2-4.2c0-0.5,0.2-0.8,0.9-0.5c3.2,1.8,6.3,3.6,9.5,5.5c1.4,0.8,2.8,1.3,4.4,1.5c6.9,0.8,13.8,0.1,20.7,0.3
				c0.3,0,0.8,0.1,0.7-0.5c-0.2-2.1-0.5-4.3-2.7-5.3c-1.8-0.9-3.6-1.6-5.5-2.2c-2.6-0.8-5-2-7.4-3.2c-1.7-0.9-3.5-1.6-5.1-2.8
				c-1.6-1.1-2.5-2.7-3.7-4.1c-0.8-0.9-1.4-2-2.1-2.9c-1.7-2.2-2.1-4.7-2.3-7.4c-0.2-2.2,0.2-4.4,0.1-6.5c-0.2-0.3-0.4-0.2-0.6-0.2
				c-4.7,0-9.4,0-14.1,0C86,546.4,85.8,546.4,85.7,546.5z"/>
			<path fill="#6E6E73" d="M37.7,549.6c0.2-1.2-0.3-1.7-1.5-1.5c-0.4,0.1-0.8,0-1.2,0c-4.9-0.1-9.8,0-14.7,0c-0.3,0-0.6,0-0.9,0.2
				c-0.1,2.2,0.4,4.3,0.6,6.4c0.4,3.4-0.1,6.8-0.8,10.1c-0.5,2.3-0.4,4.7-0.2,7c0.3,2.6,0.7,5.1,1.1,7.6c0.2,1.6,0.5,1.8,2.1,1.9
				c1.7,0,3.4,0.1,5.2-0.1c1.6-0.2,1.9-0.7,1.8-2.3c-0.1-1.1,0.1-2.2,0.2-3.3c0.1-1.3,0.8-1.1,1.5-0.6c1.4,0.9,2.8,1.7,4.1,2.7
				c3.8,2.8,8,4.3,12.6,4.9c4.8,0.7,9.6,0.8,14.5,0.7c0.5,0,1-0.1,1.6-0.1c1.9-0.3,2.2-1,1.7-3c-0.8-3.2-3.4-4.2-6.1-5
				c-3.3-1-6.4-2.3-9.3-4.2c-3.4-2.2-5.6-5.4-8-8.5c-1.5-2-3.1-4-4.1-6.4c-0.2-0.6-0.4-1.2-0.4-1.8
				C37.5,552.8,37.6,551.2,37.7,549.6z"/>
			<path fill="#EBE2CF" d="M86.4,20.1c-5.9-0.7-10.7-3.7-15-7.7c-1.6-1.5-1.6-1.5-2.6,0.6c-2.5,5.5-5.5,10.7-8.3,16
				c1.4,0.6,2.8,0.4,4.2,0.3c1.9-0.2,3.7-0.2,5.6-0.2c1.6,0,3.2-0.3,4.7-1.2c3.4-2.1,6.9-2,10.5-0.1c0.9,0.5,1.7,1.2,2.9,0.6
				c-0.6-2-1.4-3.9-1.8-5.9C86.3,21.9,85.6,21,86.4,20.1z"/>
			<path fill="#6E6E73" d="M88.3,28.5c-0.9-0.1-1.7-0.3-2.5-0.8c-3.7-2.3-7.5-2.2-11.2,0c-1,0.6-2,0.9-3.1,1
				c-3.7,0.2-7.3,0.2-11,0.4c-0.5,0.7-1.1,1.4-1.5,2.3c4.3,0.1,8.5,0.2,12.8,0.3c0.9,0,1.4,0.1,1.4,1.2c0.1,1.1,0.4,2.3,0.7,3.4
				c0.1,0.4,0,0.9,0.2,1.3c0,0.1,0.1,0.2,0.1,0.3c0.5,1.1,1.2,2.1,1.9,2.9c0.8,0.9,1.8,1.5,3.1,1.5c3.8,0,5.8-1.6,6.9-5.2
				c0.3-0.9,0.5-1.9,0.6-2.8c0.2-1.4,0.7-2.5,2.2-2.9c0.2,0,0.3-0.2,0.5-0.4C89.3,30,89.9,28.7,88.3,28.5z"/>
			<path fill="#F1E8D3" d="M19.4,548.4c4.8,0,9.6,0,14.3-0.1c0.4,0,0.9,0.1,1.3-0.1c-0.3-0.4-0.8-0.3-1.2-0.3c-4.8,0-9.6,0-14.4,0
				C19.4,548.1,19.4,548.2,19.4,548.4z"/>
			<path fill="#C1CDDA" d="M96.2,97c-0.4,0.1,0.1,0.8-0.5,0.7c0,0.6,0.6,0.3,0.8,0.6c0.2,0.2,0.4,0.2,0.6,0.2
				c0.1-0.3,0.2-0.4,0.5-0.3l0,0c0.5-0.2,1-0.5,1.5,0.1c0.1,0.1,0.3,0,0.4-0.1c0-0.2,0-0.4,0.2-0.5c0.1-0.2,0.6-0.3,0.5-0.7
				c-0.5-0.3-1-0.5-1.4-0.8c-0.4-0.2-0.7-0.2-1,0.2C97.4,96.6,96.8,96.8,96.2,97z"/>
			<path fill="#F4EBD6" d="M85.7,546.5c5.1,0,10.2,0,15.3,0c0-0.1,0-0.2,0-0.3c-4.7,0-9.3,0-14,0C86.6,546.3,86.1,546,85.7,546.5z"
				/>
			<path fill="#EAEEF1" d="M86.9,91.7c-0.3,0.5-0.7-0.1-1,0.1c-0.1,0.2,0.1,0.5,0.2,0.5c0.9-0.2,1.4,0.6,2.1,1
				c0.5,0.2,0.7,0.1,0.8-0.2c0.2-0.5-0.2-0.6-0.6-0.7c-0.3,0.3-0.7,0.4-0.7-0.1C87.7,91.4,87.2,91.7,86.9,91.7z"/>
			<path fill="#D9DFE7" d="M96.2,97c0.7,0.4,1.2-0.2,1.7-0.5c-0.2,0-0.3,0-0.4-0.2c-0.8-0.2-0.1-0.5,0-0.7c-0.7-0.6-1.4-0.2-2.2-0.2
				C95.8,95.7,96.3,96.2,96.2,97z"/>
			<path fill="#D1D9E3" d="M103.9,103.9c0,0.6,0,1.1,0,1.7c0.6,0.4,1.4,0.2,2.1,0.3c0,0,0.1-0.1,0.1-0.1c0.2-0.6-0.4-0.7-0.7-0.9
				c-0.9,0.1-0.1-0.8-0.5-1C104.6,103.9,104.3,104.1,103.9,103.9z"/>
			<path fill="#CBD5DF" d="M91.9,93.6c0,0.3,0.1,0.7,0.4,0.6c0.7-0.1,1,0.1,0.9,0.8c0,0,0.2,0.2,0.3,0.2c0.2,0,0.2-0.1,0.2-0.3
				c-0.3-0.8,0.4-0.6,0.7-0.6C93.6,94,92.8,93.4,91.9,93.6z"/>
			<path fill="#F1F3F6" d="M101.5,100.1c0.4,0.2,0.7,0.5,0.2,1c0.5,0.6,0.7,0.5,1.4-0.2c-0.1-0.5-0.2-0.9-0.7-1.2
				C102.2,99.9,101.6,99.4,101.5,100.1z"/>
			<path fill="#C6D1DC" d="M103.2,100.8c-0.2-0.1-0.4-0.2-0.5,0.2c-0.2,0.5-0.6,0.4-0.9,0.1c-0.2,0.1-0.3,0.2-0.5,0.2
				c-0.1,0.2,0,0.3,0.2,0.2c1.5-0.4,1.5,0.8,1.7,1.7C103.8,102.4,103.3,101.6,103.2,100.8z"/>
			<path fill="#D1D9E3" d="M105.8,107.5c0.1,0.5,0.2,0.6,0.8,0.4c0.2-0.8-0.1-1.5-0.5-2.1c0,0,0,0,0,0c-0.2,0.1-0.4,0.2-0.4,0.5
				C105.6,106.8,106.3,107.1,105.8,107.5z"/>
			<path fill="#BBC8D6" d="M97.4,95.5c-0.5-0.1-0.7,0.1-0.9,0.6c0,0.1,0,0.3,0.1,0.4c0.3,0.3,0.5-0.2,0.8-0.2
				C97.9,96,97.9,95.8,97.4,95.5z"/>
			<path fill="#C1CDDA" d="M104.9,103.9c-0.2,0.4-0.3,0.8-0.5,1.2c0.4,0.2,0.6-0.3,1-0.2C105.7,104.3,105.4,104.1,104.9,103.9z"/>
			<path fill="#F4EBD6" d="M81.9,546.5c1.2,0,2.4,0,3.6,0c-1.2-0.6-2.4-0.1-3.6-0.3C81.9,546.3,81.9,546.4,81.9,546.5z"/>
			<path fill="#E2E7ED" d="M100.3,97c-0.1,0.3-0.5,0.3-0.5,0.7c0.4,0,0.8,0.3,1.2,0C100.9,97.3,100.6,97.1,100.3,97z"/>
			<path fill="#C6D1DC" d="M101.5,100.1c0.2-0.4,0.8-0.1,1-0.5c-0.1-0.1-0.2-0.2-0.2-0.2c-0.4,0.6-0.7-0.2-1.1-0.1
				C101.1,99.6,101.3,99.9,101.5,100.1z"/>
			<path fill="#728A9E" d="M15.6,317.5c0.2-0.2,0.3-0.3,0.5-0.5c-0.2-0.2-0.1-0.8-0.7-0.7C15.5,316.8,15.5,317.1,15.6,317.5z"/>
			<path fill="#E2C674" d="M29,13.2c-0.4,0.4-1,0.8-1.1,1.5c0.1,0.4,0.4,0.6,0.5,0.9c0.2,1-0.3,2.1,0.4,3c0.1,0.4,0.3,0.6,0.6,0.7
				c0.6,0,1-0.6,1.6-0.6c0.3-0.2,0.4-0.6,0.3-0.9c-0.2-0.5-0.6-0.5-1-0.4c0.7-0.3,1-1.1,1.8-1.2c0.1-0.2,0.1-0.3,0-0.5
				c-0.2-0.3-0.5-0.5-0.9-0.4c-0.5,0.3-0.8,0.7-1.1,1.2c-0.2-0.5-0.3-1,0.1-1.5C30.7,13.7,30.4,13.3,29,13.2z"/>
			<path fill="#E2C674" d="M32.1,9.3c-0.9,1-1.6,2.2-2.7,3.1c0.7,0.4,1,1,0.9,1.8c0,0.3-0.1,0.6,0.2,0.7c0.4,0.1,0.4-0.2,0.6-0.4
				c0.5-0.7,0.6,0.3,1,0.3c0.5,0.1,0.8-0.2,1.1-0.6c0.3-1.3,0.3-1.4-0.7-1.6c-0.3-0.3-0.1,0.8-0.5,0.2c-0.2-0.2-0.4-0.4-0.5-0.7
				c0.3-0.1,0.5,0.1,0.8,0c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.7,0.3-1.2,1.1-1.3c0.2-0.4,0.2-0.7,0-1.1c-0.1-0.1-0.2-0.1-0.3-0.1
				C33,9.3,32.5,9.3,32.1,9.3z"/>
			<path fill="#E2C674" d="M36.7,5.5c-0.5,0.1-0.8,0.4-1.2,0.7c-0.2,0.6,0.2,1,0.4,1.5C36,7.9,36.1,8,36.3,8c0.7-0.3,1.3-0.9,2-1.2
				c0.2-0.3,0.3-0.5,0-1c0.4,0.5,0,1.3,0.8,1.1c0.8-0.6,0.5-1.2-0.1-1.8c-0.2-0.2-0.2-0.6-0.5-0.8c-0.6,0.2-1.2,0.6-1.7,1
				C37,5.4,36.9,5.5,36.7,5.5z"/>
			<path fill="#E2C776" d="M36.3,7.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.9-0.9-0.9-0.9-1.7,0.1C34.1,8,33.9,8,33.6,7.9
				c-0.2,0.1-0.3,0.3-0.4,0.5c0,0.6,0.5,0.7,0.8,0.9c1.1,0.1,2.2,0,3.4,0.1c0.2,0,0.4-0.1,0.6-0.2c0.1-0.3,0.3-0.5,0.6-0.6
				c0.1,0,0.2-0.1,0.3-0.1c0.3-0.2,0.8-0.3,0.6-0.7c-0.2-0.5-0.8-0.2-1.2-0.3C37.6,7.8,36.9,8,36.3,7.9z"/>
			<path fill="#DBB32E" d="M45.6,1.4c-0.2,0-0.5,0-0.7,0c-0.1,1-0.9,0.8-1.5,1c-0.8,0.1-1,1.2-2,0.9c-0.3,0-0.6,0-0.7,0.4
				c0.5,1.5,0.9,1.7,2.2,0.8c0.2-0.1,0.5-0.3,0.7-0.4c0.5-0.2,1-0.7,1.7-0.3C45.8,4.1,46,3.8,46,3.3c0-0.5-0.1-1.1-0.2-1.6
				C45.8,1.6,45.7,1.5,45.6,1.4z"/>
			<path fill="#DCB433" d="M48.4,0.7c-0.1,0-0.3,0.1-0.4,0.1c-0.5,0.1-0.9,0.3-1.3,0.7c0,0.1,0,0.2,0.1,0.3c0,0.1,0.6,0.1,0.1,0.4
				c-0.2,0.1-0.4,0.2-0.5,0.4c-0.3,0.7,0.1,1.4,0.3,2c0.2,0.6,0.7,0.6,1.1,0.3l0,0c0.4-0.4,0.4-0.8,0.2-1.3c0-0.2-0.7-0.2-0.1-0.6
				c0.5-0.3,1-0.5,1.5-0.6c0.2-0.1,0.3-0.2,0.4-0.4C49.9,0.7,49.7,0.6,48.4,0.7z"/>
			<path fill="#DEBA47" d="M9.4,55c0-0.2-0.3-0.2-0.2-0.5c0.1-0.6,0.1-1.3-0.9-0.8c-0.1,0.1-0.4-0.1-0.5-0.1c0,1,0,2.1,0.2,3.1
				c0.2,0.2,0.4,0.5,0.8,0.5c0.2,0,0.3-0.1,0.2-0.3c0.2-0.2,0.5-0.3,0.7-0.5C9.5,55.9,9.6,55.4,9.4,55z"/>
			<path fill="#DCB431" d="M32.2,14.6c-0.3-0.4-0.6-0.7-0.9-1.1c-0.6,0.2-0.1,0.9-0.6,1.2c-0.3-0.4-0.1-0.7-0.1-1.1
				c0.1-0.8-0.1-1.4-1.1-1.2c-0.3,0.2-0.4,0.5-0.4,0.8c0.6,0.4,1.8,0.5,1,1.7c-0.2,0.3-0.2,0.6,0,0.7c0.5,0.4,0.8-0.1,1.2-0.3
				c0.4,0,0.4,0.3,0.7,0.5c0.2-0.1,0.3-0.2,0.4-0.3C32.4,15.2,32.4,14.9,32.2,14.6z"/>
			<path fill="#DEBC50" d="M29,18.5c-0.7-1,0.2-2.2-0.5-3.1c-0.3,0-0.6-0.2-0.9,0c-0.2,0.2-0.3,0.5-0.5,0.7c0,0.6,0.4,0.5,0.7,0.6
				c-0.2,0.4-0.6,0.8-0.5,1.3c0,0,0,0.1,0.1,0.1c0.3,0.1,0.5,0.4,0.6,0.6C28.5,19.4,28.8,19.1,29,18.5z"/>
			<path fill="#E0C05E" d="M22.3,64.3c-0.3,0.1,0,1-0.8,0.6c-0.1-0.1-0.3,0.2-0.2,0.4c0.1,0.2-0.1,0.6,0.3,0.7
				c0.2,0.1,0.4-0.7,0.5-0.1c0.1,0.7,0.4,1.6-0.2,2c-1,0.8-0.9,1.4,0,2.1C22.5,68.2,22.4,66.3,22.3,64.3z"/>
			<path fill="#E2C775" d="M27.4,18c0.5-0.6,0.7-1.3,1.1-2.1c-0.5,0-0.8,1-1.3,0.2c-0.6,0.8-0.9,1.7-1.2,2.6c0.4-0.1,0.7-0.1,1,0.2
				c0,0,0.1-0.1,0.1-0.1C27.2,18.6,27.3,18.3,27.4,18z"/>
			<path fill="#E1C46D" d="M7.5,50.2c0.2-0.1,0.4-0.2,0.7-0.3c0.6-0.2,1-0.2,0.5,0.6C8.5,50.7,8.5,51,8.8,51
				c0.3,0.1,0.3-0.2,0.4-0.4c0.2-0.6,0.2-1.1-0.4-1.4c-0.6-0.4-0.7-0.7,0.2-0.9c-0.6-0.2-1.2,0-1.5-0.5C7.5,48.6,7.5,49.4,7.5,50.2z
				"/>
			<path fill="#E1C56F" d="M9.4,64.1c0-0.1,0-0.2,0-0.2c-0.6-0.5-1.2-0.2-1.7-0.1c-0.3,0.2-0.2,0.5-0.1,0.8c0.2,0.4,0.7,0.7,0.6,1.2
				c0,0.2-0.3,0.5,0,0.6c0.3,0.1,0.5-0.2,0.7-0.5c0.3-0.5-0.1-1.2,0.5-1.6C9.4,64.3,9.5,64.2,9.4,64.1C9.4,64.1,9.4,64.1,9.4,64.1z"
				/>
			<path fill="#DCB536" d="M7.6,63.8c0.6,0.5,1.2-0.2,1.7,0.1c0.1,0,0.2,0,0.2,0c0.1-0.5-0.4-0.7-0.4-1.2c0-0.3,0.1-0.5-0.1-0.8
				c-0.3,0-0.7,0-0.7-0.5c-0.2-0.2-0.3-0.1-0.5,0C8.1,62.3,7.7,63,7.6,63.8z"/>
			<path fill="#DEBC51" d="M7.9,61.4c0.2,0,0.3,0,0.5,0c-0.1-0.9,0.7-1.5,0.5-2.4C9,58.8,9.1,58.6,9,58.3c-0.2-0.7-0.5-0.8-1.1-0.4
				C8.3,59,7.8,60.2,7.9,61.4z"/>
			<path fill="#E2C672" d="M40.8,3.8c0.2-0.3,0.5-0.2,0.7-0.2c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.5-0.3-0.6c-0.7,0.2-1.3,0.6-1.9,1
				c0.1,0.4,0.1,0.8,0.2,1.2c0.4,0.2,0.7,0.6,1.2,0.6C40.9,5.5,41,5.3,41,5.1C41.1,4.7,41,4.2,40.8,3.8z"/>
			<path fill="#E2C674" d="M18.5,73.4c-0.2,0.8,0.4,1.1,0.8,1.7c0.2,0.3-0.1,0.8,0.4,0.9c0.4-0.3,0.5-0.8,0.7-1.2
				c-0.1-0.8-1.1-1-1.3-1.8C19,73,19,73,19,73c-0.1,0-0.2,0-0.2,0C18.6,73.1,18.5,73.2,18.5,73.4z"/>
			<path fill="#E2C675" d="M20.2,72.2c-0.3,0.4-1.1,0-1.2,0.7c0,0,0,0,0,0c0.1,0.1,0,0,0.1,0.1c0.4,0.4,0.9,0.4,1.4,0.2
				c-0.5,0.5-0.1,0.7,0.3,0.9c0.3-0.5,0.7-1,0.7-1.7c-0.3-0.2-0.6-0.3-0.9-0.2C20.3,72.1,20.2,72.1,20.2,72.2z"/>
			<path fill="#E1C570" d="M7.7,43.2c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-1.1,0.1-2,1.6-1.8c0.2,0,0.5-0.1,0.5-0.4
				c-0.1-0.4-0.4-0.4-0.7-0.4c-0.4-0.1-0.8,0.9-1.1,0C7.7,41,7.7,42.1,7.7,43.2z"/>
			<path fill="#DCB536" d="M20.7,74.2c0-1.2-0.9-1.1-1.7-1.2c-0.3,1.2,1,1.2,1.4,1.9C20.5,74.6,20.6,74.4,20.7,74.2z"/>
			<path fill="#E2C671" d="M18.2,75.6c-0.5-0.3-0.9,0.5-1.4,0.3c-0.2,0.3-0.1,0.5,0,0.8c0.2,0.4,0.8,0.5,1,1
				c0.1,0.1,0.1,0.2,0.2,0.2c0.2-0.2,0.3-0.3,0.5-0.5c0-0.3,0-0.7,0.2-1C18.9,75.8,18.5,75.7,18.2,75.6z"/>
			<path fill="#DDB73C" d="M18.2,75.6c0,0.2,0.4,0.2,0.2,0.5c-0.3,0.4-0.3,0.8,0.1,1.1c0.5-0.3,0.8-0.8,1.2-1.2
				c-0.1-0.3-0.3-0.6-0.4-0.9C19.1,75.7,18.6,75.6,18.2,75.6z"/>
			<path fill="#DDB73E" d="M34.3,7.9c0.3,0,0.4-0.1,0.5-0.4C35,7.1,35.3,7,35.4,7.6c0,0.2,0.1,0.3,0.3,0.2c0.1,0,0.2-0.1,0.3-0.1
				c0.3-0.6-0.5-0.9-0.5-1.4c-0.4,0.4-0.8,0.8-1.2,1.2C34.2,7.6,34.2,7.7,34.3,7.9z"/>
			<path fill="#E1C46B" d="M24.5,24c0.2,0.1,0.4,0.2,0.5,0.3c0.4,0.3,0.7,0,0.9-0.3c0.2-0.4-0.1-0.6-0.5-0.7c-0.2,0-0.5-0.1-0.7-0.2
				C24.4,23.3,24.5,23.7,24.5,24z"/>
			<path fill="#DEBC4D" d="M48.4,0.7c0.8,0,1.5,0.2,1,1.2c0.2,0.2,0.3,0.1,0.5,0c0.2,0,0.3,0,0.5,0c0.2-0.2,0.2-0.3,0.2-0.6
				c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1,0-0.2,0.1-0.2c0.1,0,0.3-0.1,0.4-0.2c0-0.1,0-0.2-0.1-0.3C50,0.1,49.2,0.4,48.4,0.7z"/>
			<path fill="#E0BF5B" d="M18,77.8c0-0.1,0-0.2,0-0.3c-0.7,0.1-1.3-0.9-2,0c-0.1,0.1-0.2,0.3-0.2,0.5c0.2,0.4,0.8,0.1,1,0.5
				C17.3,78.3,17.8,78.3,18,77.8z"/>
			<path fill="#E1C571" d="M41.5,3.6c0.4,0.5,0.7,0.1,1-0.1c0.3-0.3,0.6-0.6,1-0.8c0.2-0.2,0.4-0.4,0.2-0.8
				c-0.4,0.1-0.8,0.2-1.2,0.5C42,2.7,41.8,3.1,41.5,3.6L41.5,3.6z"/>
			<path fill="#E2C671" d="M43.7,1.9c-0.1,0.3-0.2,0.5-0.2,0.8c0.5,0.3,1.1-0.7,1.5,0.2c0.3-0.5-0.2-0.9-0.1-1.3
				C44.5,1.5,44,1.6,43.7,1.9z"/>
			<path fill="#E2C774" d="M8,57.8c0.4-0.1,0.8,0,0.9,0.5c0.1,0.1,0.3,0.1,0.4,0.1c0.2-0.3,0.2-0.5,0.1-0.8c0-0.3-0.1-0.6-0.5-0.7
				c-0.1,0.1-0.1,0.2-0.2,0.2c-0.3,0-0.4-0.4-0.7-0.3C7.9,57.2,7.9,57.5,8,57.8z"/>
			<path fill="#DFBE54" d="M39.6,5c0.1-0.5,0.2-0.9-0.3-1.2c-0.3,0.1-0.6,0.1-0.7,0.4c0,0.3-0.4,0.8,0.3,0.8
				C39.1,5.2,39.4,5.3,39.6,5z"/>
			<path fill="#E2C572" d="M19,51.1c0.6-0.1,1.1-0.1,1.7-0.2c-0.1-0.2-0.1-0.3-0.2-0.5C19.5,49.8,19.1,50.2,19,51.1L19,51.1z"/>
			<path fill="#DEBA4A" d="M21.2,54.4c-0.1,0.5-0.6,0.7-0.9,1.2c0.4,0.2,0.8,0.3,1.2,0.2C21.5,55.4,21.4,54.9,21.2,54.4z"/>
			<path fill="#E1C46C" d="M7.6,53.5c0.1-0.6,1-0.7,0.7-1.5l0,0c-1.2,0.6-0.4-0.9-1-1C7.5,51.9,7.3,52.8,7.6,53.5z"/>
			<path fill="#DEBA4A" d="M20.9,53.5c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.3-0.8,0.5-0.5,0.9c0.3,0.4,0.7,0,1-0.2
				C21.2,54.1,21.1,53.8,20.9,53.5z"/>
			<path fill="#DCB535" d="M41.5,3.6c0.7-0.1,0.7-0.8,1-1.3c-0.4,0.1-0.9,0.2-1.2,0.5C41,3.2,41.3,3.4,41.5,3.6z"/>
			<path fill="#DFBD54" d="M46.8,1.4c0.5,0,0.8-0.4,1.2-0.7c-0.6,0-1.2,0.1-1.7,0.3C46.3,1.6,46.5,1.6,46.8,1.4z"/>
			<path fill="#DFBD54" d="M24.3,25.5c0.4,0.1,0.4,0.5,0.6,1c0.4-0.8,0.1-1.2-0.5-1.5C24.2,25,24.2,25.2,24.3,25.5z"/>
			<path fill="#E1BF51" d="M46.8,1.4c-0.2,0-0.4-0.1-0.5-0.3C46,1,45.7,1.1,45.6,1.4c0,0.1,0,0.2,0,0.3c0.2,0.3,0.5,0.5,0.9,0.4
				c0.2-0.1,0.3-0.2,0.3-0.4C46.8,1.6,46.8,1.5,46.8,1.4z"/>
			<path fill="#DCB432" d="M27.6,15.4c0.3,0.1,0.6,0.4,0.9,0c-0.1-0.3-0.4-0.5-0.7-0.7C27.7,14.9,27.5,15.1,27.6,15.4z"/>
			<path fill="#DBB32E" d="M32.1,9.3c0.4,0.4,0.8,0.4,1.2,0c-0.2-0.4-0.4-0.7-0.9-0.5C32.3,9,32.2,9.2,32.1,9.3z"/>
			<path fill="#E2C674" d="M32.4,8.9c0.4,0,0.6,0.3,0.9,0.5c0,0,0,0,0,0c0.2-0.2,0.6,0.1,0.7-0.3c-0.3-0.3-0.7-0.3-0.9-0.7
				C32.9,8.6,32.7,8.7,32.4,8.9z"/>
			<path fill="#DDB944" d="M34.3,7.9c0-0.2,0-0.3,0-0.5c-0.2,0.2-0.5,0.3-0.7,0.5c0.1,0.2,0.2,0.4,0.5,0.4
				C34.3,8.3,34.3,8.1,34.3,7.9z"/>
			<path fill="#DEBA48" d="M50.9,0.5c0,0.1,0,0.2,0,0.3c-0.2,0.2-0.3,0.5-0.1,0.8c0,0,0.1,0.1,0.1,0.1c0.2-0.1,0.3-0.5,0.7-0.5
				c0.1-0.1,0.1-0.2,0.1-0.3c-0.1-0.3-0.3-0.3-0.6-0.4c0,0,0-0.1,0-0.1C51,0.4,51,0.4,50.9,0.5z"/>
			<path fill="#DDB63A" d="M36.7,5.5c0.2,0.3,0.4,0.4,0.6,0.1c0.1-0.2-0.2-0.2-0.4-0.3C36.9,5.3,36.8,5.4,36.7,5.5z"/>
			<path fill="#DBB22C" d="M7.9,56.9c0.3,0.1,0.3,0.7,0.7,0.3c-0.1-0.3-0.4-0.5-0.8-0.5C7.9,56.7,7.9,56.8,7.9,56.9z"/>
			<path fill="#E2C674" d="M37.7,15.8c0.2-0.9,0.6,0,0.8-0.1c0.7-0.3,1.3-0.7,2.1-0.8c0.4-0.1,0-0.5-0.1-0.8
				c-0.6-1.2-0.7-1.2-1.6-0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.4,0.5-0.8,0.4c-0.2-0.1-0.3-0.3-0.2-0.5c0.1-0.4,0.4-0.6,0.8-0.6
				c0.3,0,0.6,0.2,0.8,0.1c0.4-0.2,0.6-0.6,0.4-1.1c-0.1-0.4-0.4-0.7,0.3-1c0.7-0.4,0.9-1.2,1.2-1.9c0.2-0.4,0.2,0,0.3-0.1
				c0.6-0.1,1.1-0.1,1.1-0.9c-0.3-0.6-0.8-0.4-1.3-0.2c-0.4,0.3-0.8,0.5-1.4,0.4c-0.5-0.2-0.9,0.1-1.1,0.6c-0.2,0.1,0.4,0.4-0.1,0.4
				c-0.4,0-0.8,0.1-1.2,0.3c-0.2,0.1,0.1,0.5-0.4,0.4c-0.5-0.1-1,0.2-1.3,0.7c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.3,0.4-0.2,0.6
				c0.1,0.7,0.8,0.3,1.1,0.6c0.3-0.1,0.4-0.4,0.5-0.6c0.2-0.4,0.6-0.5,1-0.2c-0.7-0.1-1,0.4-1.1,1c0,0.7-0.6,0.9-1,1.2
				c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.6-1.6,0.4-1,1.5c0.4,0.1,0.8,0.2,1.1,0.5c0.7,0.1,1.3-0.5,2-0.1C37.5,16,37.6,15.9,37.7,15.8z"
				/>
			<path fill="#E1C36B" d="M46.6,1.9c-0.4,0-0.7-0.1-1-0.2c-0.1,0.7,0.1,1.3,0.2,2c-0.1,0-2.4,0.2-2.4,0.2c-0.2,0.6-1.1,1.1-0.5,1.9
				c0.2,0.4,0.4,0.5,0.8,0.5C44.4,6,44.5,5.5,44.5,5c0-0.1,0-0.3,0.2-0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.3,0.6,0.6,1,0.4
				c0.4-0.1,0.6,0,0.7,0.4C46.8,5.9,46.9,6,47,6c0.3-0.4,0.7-0.7,0.7-1.2c-0.9-0.1-1.3-1-1.2-2.4C46.7,2.2,46.7,2.1,46.6,1.9z"/>
			<path fill="#DDB73E" d="M36.2,20.6c0.3-0.7,0.3-0.7-0.9-0.7c0,0-0.1,0-0.1,0c-0.3,0-0.6,0.1-0.9,0.1c-0.1,0-0.2,0-0.3,0.1
				c-0.3,0.2-1-0.3-1,0.6c0.1,0.3,0.3,0.4,0.4,0.7c0.7,0.6,0,1.2,0,1.7c0.6,0.1,1.1,0.2,1.5,0.7c0.2-0.2,0.5-0.3,0.7-0.5
				c0-0.2,0-0.3,0-0.5c0.1-0.5-0.9-0.9,0-1.4c0-0.4,0.3-0.5,0.5-0.7C36,20.5,36.1,20.5,36.2,20.6z"/>
			<path fill="#E0C268" d="M41,16.8c0.2,0.6,0.2,1.4,1.2,1.3c0.7-0.1,1-0.6,1-1.4c0-0.2,0.7-0.8,1.3-0.8c0.2,0,0.5,0.1,0.5-0.2
				c0-0.4-0.4-0.3-0.6-0.3c-0.7,0.1-1.4,0.3-2,0.8C42,16.4,41.6,16.9,41,16.8L41,16.8z"/>
			<path fill="#E1C572" d="M26.9,19c0,0.1,0,0.2,0,0.2c-0.1,0.4,0.1,0.5,0.5,0.5c0.5,0.7,0.5,0.7-0.1,1.6c-0.5,0.7-0.5,0.7,0.2,1.3
				c-0.2,0.3-0.7,0.1-0.8,0.6c0.2,0.3,0.6,0.1,0.8,0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.3-0.2c1.2-0.8,0.6-1.9,0.7-2.9
				C28.3,18.7,28,18.5,26.9,19L26.9,19z"/>
			<path fill="#DBB22C" d="M39.6,12.2c0,0.7-0.1,1.1-0.9,0.8c-0.6-0.2-1.1,0.2-1.3,0.8c-0.1,0.4-0.1,0.9,0.4,1
				c0.6,0.2,1,0.1,1.3-0.6c0.4-1.1,1-0.7,1.5,0c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.1-0.3,0.1-0.4c-0.1-0.1,0,0-0.1-0.1
				c-0.2-0.2-0.8-0.3-0.1-0.6c0-0.1,0-0.2-0.1-0.3c-0.2-0.3-0.5-0.4-0.9-0.3C39.7,12.2,39.6,12.2,39.6,12.2z"/>
			<path fill="#E1C46D" d="M8.9,62.6c-0.2,0.6,0.5,0.8,0.7,1.2c0.5-1.6,1-0.2,1.4,0c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.3,0.3-0.3
				c0.1,0,0.1,0.3,0.1,0.4c0,0,0.1,0.1,0.1,0.1c0.4,0,0.8,0,1-0.5c0.1-0.4,0.2-0.7-0.2-0.9c-0.3,0-0.6-0.1-0.9-0.3
				c-0.1-0.1-0.5-0.2-0.5-0.2c-0.8,1.4-0.8-0.5-1.3-0.3C9.5,62.1,9.2,62.4,8.9,62.6z"/>
			<path fill="#E0C267" d="M10.3,74.4c0-0.4-0.4-0.6-0.7-0.9c-0.2-0.3-0.9-0.3-0.8,0c0.1,0.9-0.7,0.3-0.9,0.7l0,0
				c0.4,0.6,1.2,1.1,1.1,2c0.8-0.1,0.6-1,1.1-1.3C10.2,74.7,10.3,74.6,10.3,74.4z"/>
			<path fill="#E1C571" d="M40.1,21.4c0.2-0.5,0.2-1.2,1.1-1.1c0.3,0,0.4-0.3,0.3-0.5c-0.2-0.3,0-0.8-0.4-0.9
				c-0.3-0.1-0.3,0.3-0.4,0.5c-0.5,0.7-1.2,0.1-1.8,0.2c-0.6,0.2-0.9,0.4-0.2,0.9l0,0c0.5-0.2,0.2,0.4,0.5,0.5
				c0.3,0,0.6,0.2,0.7,0.5C39.9,21.5,40,21.5,40.1,21.4z"/>
			<path fill="#DDB840" d="M10.6,75.1c0.2,0.2,0.5,0.3,0.7,0.5l0,0c0.2,0.3,0.3,0.6,0.5,1c0.2,0.6,0.6,0.8,1.2,0.7
				c0.2,0,0.3,0,0.5,0c0.2-0.1,0.4-0.2,0.3-0.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.4,0-0.7,0.1-0.7-0.5c-0.2-0.1-0.2-0.3-0.2-0.5
				c0-0.2-0.1-0.3-0.3-0.3c-0.6-0.6-0.4-1.4-0.7-2.1c-0.3-0.1-0.4,0-0.5,0.2c0.1,0.4,0.3,0.8,0,1.2C11.2,74.9,10.7,74.9,10.6,75.1z"
				/>
			<path fill="#DCB63A" d="M30.3,23.3c0.2,0.4,0.9,0.4,1,1c0.6-0.4,1.1-0.8,1.7-1.2c0-0.8-0.9-0.5-1.1-1c-0.1-0.1-0.2-0.1-0.3-0.2
				c-0.5-0.1-0.9-0.1-1.4-0.4c-0.4,0.1-0.5,0.4-0.6,0.8C29.6,22.8,29.8,23.1,30.3,23.3z"/>
			<path fill="#E1C572" d="M44.7,12c0-0.2,0.4-0.5,0-0.7c-0.9,0.1-1.7,0-2.3-0.6c-0.1,0-0.2,0-0.3,0C41.7,11,41.4,11,41.1,11
				c-0.1,0.1-0.1,0.2-0.1,0.3c0.2,0.9,1.2,1,1.6,1.7c0.2,0.3,0.3-0.3,0.5-0.4c0.2-0.4,0.6-0.2,1-0.2c0,0,0.1,0,0.1,0
				c0.1,0,0.3,0,0.4-0.1C44.5,12.1,44.6,12.1,44.7,12z"/>
			<path fill="#E1C570" d="M28.1,24.5c-0.1,0.3-0.7,0.3-0.6,0.9c0.5-0.1,0.9,0.1,1.1,0.6c0.1,0.3,0.5,0.6,0.8,0.3
				c0.5-0.5,1.3-0.2,1.6-0.9c0-0.1,0-0.2-0.1-0.3c-0.2-0.2-0.4-0.2-0.7,0c-1.1,0.8-1.4-0.1-1.9-0.8C28.1,24.2,28,24.2,28.1,24.5z"/>
			<path fill="#E1C570" d="M12.5,54.5c0.3,0,0.7-0.1,0.6-0.6c0-0.2,0-0.4,0-0.6c0.1-0.4,1-0.3,0.6-0.8c-0.3-0.4-0.9,0-1.3-0.1
				c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1-0.4,0.2-0.5,0.5c0.2,0.1-0.8,0.9,0.4,0.6C12.4,53.5,12.2,54.2,12.5,54.5L12.5,54.5z"/>
			<path fill="#E0C265" d="M12.2,61c0.1,0.3-0.4,0.6-0.1,1.1c0.5-0.6,1.5-0.4,1.7-1.4c0-0.2,0.1-0.5-0.1-0.7c-0.2-0.2-0.5-0.2-0.6,0
				c-0.4,0.5-1,0.2-1.4,0.5C11.8,60.7,12.1,60.8,12.2,61z"/>
			<path fill="#E1C36B" d="M11.3,52.3c0-0.4-0.1-0.4-0.4-0.4c-0.5,0-0.9-0.1-1.4-0.2c-0.3,0.4,0.4,0.6,0.1,1
				c-0.1,0.3-0.8,0.4-0.3,0.8c0.1,0.1,0.9,0.3,1.2-0.2C10.7,52.8,11.3,52.8,11.3,52.3z"/>
			<path fill="#E1C570" d="M40.8,13.4c0,0.2,0,0.3,0,0.5c0.5,0.2,0.4,0.7,0.8,1.1c0-1.1,1.1-1.2,1.6-1.8c-0.8,0-1.3-1.3-2.2-0.4
				C40.9,13,40.9,13.2,40.8,13.4z"/>
			<path fill="#DFBD53" d="M38.6,20.4c-0.3-0.4-0.1-0.7,0.2-0.9c-0.2-0.6-1.4-1-1.8-0.7c-0.3,0.3,0.2,1.3,0.8,1.5
				C38.1,20.4,38.4,20.4,38.6,20.4z"/>
			<path fill="#E1C36A" d="M14.3,72.6c0.2-0.8-0.5-1.7,0.7-1.7c-0.1-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.2-0.7-0.2
				c-0.3,0-0.4,0.3-0.4,0.5c0.2,0.5,0,0.8-0.2,1.2C13.1,72.2,14,72,14.3,72.6z"/>
			<path fill="#E1C268" d="M10.2,67.5c-0.1,0.5-0.1,1.1,0.3,1.2c0.4,0.1,0.5-0.5,0.5-0.9c0-0.4,0.9-0.6,0.3-1.1
				c-0.3-0.2-0.6-0.2-0.9,0C10,66.9,10.1,67.3,10.2,67.5z"/>
			<path fill="#E1C673" d="M15.8,78c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.3,0.1-0.7,0.6-0.7c0.2,0,0.2-0.3,0.2-0.5
				c-0.1-0.2-0.4-0.1-0.6-0.3c-0.7-0.4-1.2-0.3-1.5,0.5C14.4,77.9,15.5,77.7,15.8,78z"/>
			<path fill="#E1C46C" d="M32.9,23c-0.8,0.1-1.5,0.2-1.7,1.2c0.1,0.8,0.9,0.1,1.2,0.5l0,0c0.1-0.6,1.2-0.8,0.7-1.7
				C33,23,33,23,32.9,23z"/>
			<path fill="#E1C36A" d="M13,42.7c0,0.5,1.6,1.5,2,1.3c0.2-0.1,0.4-0.6,0.2-0.7c-0.8-0.1-1-0.8-1.5-1.2
				C13.5,41.9,13.2,42.4,13,42.7z"/>
			<path fill="#DDB842" d="M41.1,13c0.3-0.1,0.5-0.6,0.8-0.2c0.4,0.7,1,0.3,1.4,0.5c0.5-0.4-0.2-0.5-0.3-0.7c-0.2,0-0.5,0.5-0.5,0.3
				c0-1-1.1-0.8-1.4-1.5c-0.3-0.1-0.4,0-0.6,0.2c-0.2,0.3,0.2,0.7,0.1,1c0,0.1,0,0.2,0,0.2C40.7,12.9,40.8,13,41.1,13z"/>
			<path fill="#E2C674" d="M47,6c-0.1-0.1-0.2-0.2-0.3-0.2C46,5.3,45.4,5.6,45,6.3C45,7,45.5,7,45.9,7.1c0.1,0,0.2,0,0.3,0
				c0.5-0.3,0.8,0.5,1.3,0.3c0-0.1,0-0.1,0-0.2c0.1-0.2,0.1-0.3,0-0.5C47.4,6.5,47,6.4,47,6z"/>
			<path fill="#DCB330" d="M47.5,7.4c-0.3-0.1-0.4-0.3-0.5-0.6c-0.3-0.5-0.5-0.2-0.7,0.1c-0.2,0.4-0.3,0.7,0.1,1
				c0.5,0.2,0.2,0.6,0.3,0.9c0.1,0.3,0.4,0.3,0.6,0.5C47.6,8.7,47.9,8.1,47.5,7.4z"/>
			<path fill="#DDB841" d="M42.3,10.8c0.6,1,1.4,1,2.4,0.5c0,0,0,0,0,0c-0.3-0.2-0.3-0.5,0-0.7c0.1-0.1,0.2-0.2,0.2-0.2
				c0-0.1,0-0.1,0.1-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.5-0.2-0.9,0.1-1.2,0.5c-0.1,0-0.2,0-0.3,0C42.9,10.5,42.4,10.1,42.3,10.8z"/>
			<path fill="#DFC05E" d="M44.7,12c-0.1,0-0.2,0-0.3,0c-0.1,0.3,0.2,0.4,0.3,0.6c-0.3,0.1-0.4-0.5-0.8-0.3c0,0,0,0,0,0
				c0.1,0.4-0.2,1.1,0.3,1.1c0.4,0,0.4,0.8,0.9,0.3c0.4-0.4,0.9-0.8,0.8-1.3C45.8,11.8,45.1,12,44.7,12z"/>
			<path fill="#DFBE59" d="M49.2,11.6c-0.7-0.3-1.1-1.5-2.3-1.1C47.6,11.4,48.1,12.2,49.2,11.6z"/>
			<path fill="#E1C36B" d="M18.2,41.9c0.2-0.6,1-1.1,0.1-1.7c-0.2-0.1-0.5-0.5-0.7-0.1C17.2,40.7,17.5,41.3,18.2,41.9z"/>
			<path fill="#E0C267" d="M10.8,59c0.3,0.2,0.9-0.1,0.9,0.9c0.3-0.7,0.5-1.1,0.9-1.8c-0.7,0.4-1.5-0.7-2,0.4
				C10.7,58.7,10.6,58.9,10.8,59z"/>
			<path fill="#E1C36B" d="M32.9,23c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.6-0.6,1-1.1,0.3-1.9c-0.4,0.8-1.2,0.8-1.9,0.9
				C31.6,23,32.7,22.4,32.9,23z"/>
			<path fill="#DFBD55" d="M35.5,19.4c1-0.4,1.2-1.3,1.4-2.2c-0.1,0-0.1,0-0.2-0.1c-0.3,0-0.6,0.2-0.7,0.4c-0.3,0.6-0.7,0.4-1.2,0.4
				c-0.2,0.4-0.2,0.8-0.3,1.2c0,0.2,0.1,0.3,0.3,0.4C35.2,19.6,35.4,19.6,35.5,19.4z"/>
			<path fill="#E0C267" d="M7.5,72.2c0.2-0.2,0.8,0.1,0.6-0.4c-0.1-0.3-0.1-1-0.7-0.8c-0.2,0.1-0.9-0.1-0.8,0.6
				C6.7,72,7,72.2,7.5,72.2z"/>
			<path fill="#E1C570" d="M10.8,42.2c0.3-0.2,0.8,0.5,0.9-0.2c0.1-0.4,0.5-0.9-0.1-1.1c-0.7-0.2-0.9,0.2-0.9,0.8
				C10.4,42,10.4,42.2,10.8,42.2z"/>
			<path fill="#DEBB4E" d="M16.9,55.4c0.1,0.4,0.2,1,0.4,0.9c0.5-0.2,1,0,1.5-0.1c0.2,0,0.3-0.4,0.3-0.6c-0.1-0.3-0.4-0.3-0.6-0.2
				C18,55.7,17.5,55.7,16.9,55.4z"/>
			<path fill="#E1C571" d="M13.7,38.4c-0.7-0.3-1.2,0.1-1.8,0.4c1,0.6,1.5,0.6,2.1-0.2C14,38.4,13.9,38.3,13.7,38.4z"/>
			<path fill="#E0C369" d="M17.8,53c0.2,0,0.5,0,0.7,0c0.1-0.2,0.1-0.3,0-0.5c-0.7-0.3-0.3-0.6,0-1c0-0.2-0.1-0.4-0.2-0.5
				c-0.5,0.6-0.6-0.2-1-0.2c-0.2,0.2-0.2,0.5-0.5,0.7c0.2,0.2,0.5,0.3,0.7,0.5c-0.1,0.3,0.1,0.4,0.2,0.5
				C17.8,52.7,17.8,52.9,17.8,53C17.8,53,17.8,53,17.8,53z"/>
			<path fill="#E1C46E" d="M30.3,23.3c-0.2-0.3-0.3-0.6-0.5-1c-0.6,0.3-1.4,0.5-1.4,1.4c0,0.1,0.1,0.2,0.2,0.3
				c0.3,0,0.5-0.5,0.7-0.3C30.1,24.6,30,23.5,30.3,23.3z"/>
			<path fill="#E1C36B" d="M11.1,36.7c-0.5,0.8-1.4,0.4-1.6,1.1C9.4,38,9.7,38.4,10,38.4C10.6,38.2,11.1,37.9,11.1,36.7z"/>
			<path fill="#DDB843" d="M47.8,3.6c0.1,0.4,0.1,0.8,0,1.2c1-0.1,1.1-0.9,1.2-1.7c0,0-0.1-0.1-0.1-0.1C48.2,2.8,47.9,3,47.8,3.6z"
				/>
			<path fill="#E1C572" d="M13,77.3c-0.4-0.2-0.8-0.5-1.2-0.7c-0.2,0.4-0.7,0.8-0.1,1.2C12.3,78.2,12.6,77.7,13,77.3z"/>
			<path fill="#E0C267" d="M10.6,47c0.4,1.1,1.1,0.3,1.7,0.3c0.2,0,0.3-0.5,0.1-0.7c-0.2-0.2-0.4-0.2-0.6,0C11.4,46.9,11,47,10.6,47
				L10.6,47z"/>
			<path fill="#E1C369" d="M11,73.2c0.2-0.1,0.3-0.2,0.5-0.2c0.5-0.1,0.6-0.4,0.3-0.7c-0.2-0.3-0.5-0.2-0.7,0
				c-0.4,0.3-0.7,0.8-0.9,1.3C10.6,73.6,10.7,73.1,11,73.2z"/>
			<path fill="#DFBF5B" d="M9.5,67.8c-1.4-0.5-1.4-0.5-1.2,1.2C8.8,68.7,9.3,68.4,9.5,67.8z"/>
			<path fill="#DEBC50" d="M13.7,38.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.9,1.2-0.6,1.5-1.4C14.5,37.1,13.6,36.9,13.7,38.4z"/>
			<path fill="#E1C46C" d="M15.6,40.3c-0.3-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6-0.2-0.7,0.2c-0.2,0.3,0.2,0.5,0.3,0.7
				c0.2,0.3,0.4,0.2,0.7,0c0.2-0.2,0.5-0.3,0.8-0.4C15.8,40.4,15.7,40.3,15.6,40.3z"/>
			<path fill="#E0C163" d="M51.8,6.5c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.2,0.4,0.7,0.7,0.4c0.5-0.4-0.3-0.3-0.4-0.6
				c-0.2-0.6-0.2-1.2-0.8-1.6C51.4,5.6,52,6,51.8,6.5L51.8,6.5z"/>
			<path fill="#E0C369" d="M12.2,61c-0.1-0.2-0.3-0.4-0.5-0.5c-0.6-0.5-1.2-0.2-1.9,0.1C10.6,61.4,11.5,60.7,12.2,61z"/>
			<path fill="#E0C061" d="M15.1,74.9c0.2,0,0.7,0.4,0.6,0c-0.2-0.6,0.5-0.8,0.4-1.4c-0.5,0.3-1.1,0.2-1.9,0.3
				c0.5,0.3,0.9,0.4,0.9,0.9C15,74.7,15,74.7,15.1,74.9C15.1,74.8,15.1,74.9,15.1,74.9z"/>
			<path fill="#DFBE57" d="M16.1,58.6c0.3,0,0.7,0.8,0.7-0.2c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0.7-0.2,0.1-0.6c-0.3-0.2-0.6-0.3-0.8,0.1
				c-0.1,0.2-0.3,0.4-0.5,0.6C15.8,58.3,15.9,58.5,16.1,58.6z"/>
			<path fill="#E0C267" d="M7.4,75.1c0-0.4,0-0.8,0.5-0.9c0,0,0,0,0,0c-0.1-0.1-0.3-0.2-0.4-0.2c-0.6-0.1-1.1,0.1-1.1,0.8
				C6.4,75.4,7.1,75.1,7.4,75.1L7.4,75.1z"/>
			<path fill="#E1C571" d="M13.7,76.8c-0.1,0.2-0.2,0.3-0.3,0.5c0,0,0,0.1,0,0.1c0.3,0.4-0.2,1,0.4,1.2c0.2,0.1,0.5-0.1,0.4-0.2
				C14.1,77.9,14.5,77.1,13.7,76.8z"/>
			<path fill="#E2C674" d="M9.4,55c0.1,0.5-0.5,1.1,0.2,1.4l0,0c0.2-0.1,0.2-0.4,0.5-0.5c0.2-0.2,0.5-0.4,0.6-0.8
				C10.3,54.8,9.8,55,9.4,55z"/>
			<path fill="#E1C46D" d="M16.1,58.6c0-0.2-0.1-0.4-0.3-0.5c-0.1,0.1-0.3,0.2-0.4,0.2c-0.3,0.2-0.7,0.3-0.7,0.7
				c0,0.3,0.3,0.3,0.6,0.3C15.8,59.3,16.1,59.2,16.1,58.6z"/>
			<path fill="#DFBE5A" d="M9.4,64.1c0,0.1,0,0.2,0,0.2c0.3,0.3,0.5,0.6,0.4,1c0,0.2,0,0.5,0.3,0.5c0.2,0,0.5,0,0.6-0.3
				c0.1-0.2,0.2-0.5-0.1-0.6C10.1,64.7,10,64.1,9.4,64.1z"/>
			<path fill="#DCB536" d="M18.5,73.4c0.2-0.1,0.3-0.3,0.3-0.5c-0.1-0.1-0.2-0.1-0.3-0.1c-0.5-0.1-1-0.1-1.5-0.2c0,0-0.1,0.3,0,0.3
				C17.4,73.3,17.8,73.6,18.5,73.4z"/>
			<path fill="#E0C163" d="M37.5,22.1c1.1,0.3-0.1-0.8,0.5-0.8c0.1,0-0.1-0.4-0.3-0.3c-0.5,0.1-0.9,0.6-0.9,1
				C36.8,22.4,37.2,22.2,37.5,22.1z"/>
			<path fill="#E1C36A" d="M14.8,51.6c-0.4,0-0.6,0.2-0.6,0.6c0.1,0.3,0.2,0.7,0.6,0.6c0.4-0.1,0.6-0.4,0.6-0.8
				C15.5,51.6,15.1,51.6,14.8,51.6z"/>
			<path fill="#E0C163" d="M8.7,70.1C8.3,71.6,9.5,71,10,71.4c0,0,0.2-0.1,0.2-0.2c0.1-0.2-0.1-0.3-0.2-0.3
				C9.4,70.8,9.2,70.4,8.7,70.1z"/>
			<path fill="#E2C87A" d="M47.3,9.4c0-0.3-0.1-0.5-0.5-0.5c-0.7,0.3-1.2,0.9-1.5,1.5c0.1,0.3,0.3,0.3,0.6,0.2
				C46.3,10.2,46.8,9.8,47.3,9.4z"/>
			<path fill="#E1C369" d="M13.4,49.8c0.7-0.1,1.3-0.1,1.5-0.8c0.1-0.2,0-0.3-0.2-0.3C13.9,48.6,13.7,49.2,13.4,49.8z"/>
			<path fill="#E1C571" d="M47.5,6.7c0,0.2,0,0.3,0,0.5c0.1,0,0.3-0.1,0.3-0.1c0.4,0.3,0.7,0.2,0.9-0.1c0.3-0.4-0.3-0.4-0.5-0.6
				C48.1,6.2,48,6,47.8,5.9C47.3,6,48,6.6,47.5,6.7z"/>
			<path fill="#E0C05F" d="M27.3,19.7c-0.2-0.2-0.3-0.3-0.5-0.5c-0.3,0.4-0.4,0.9-0.9,1.2C27.1,20.8,27.1,20.7,27.3,19.7z"/>
			<path fill="#E0C162" d="M15.2,62.4c0.5-0.1,1,0.7,1.4,0c0.1-0.2,0.2-0.5,0-0.5C16.1,61.7,15.6,62,15.2,62.4z"/>
			<path fill="#DEBA4B" d="M55.9,9.2c0.9,0,1.2-0.6,1.8-0.7C57.1,8,57.1,8,55.9,9.2z"/>
			<path fill="#E0C269" d="M15.6,46.6c0.2,0.3,0.4,0.7,0.8,1c0.1,0.1,0.4-0.1,0.3-0.3c-0.2-0.4,0.1-1-0.6-1
				C15.9,46.2,15.6,46.1,15.6,46.6z"/>
			<path fill="#DFBD54" d="M45.8,10.6c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.1-0.5,0c0,0,0,0,0,0c0.2,0.4,0.5,0.7,0.7,1.1
				c0.2,0.2,0.4,0.5,0.7,0.1c0.3-0.4,0-0.5-0.4-0.6C45.9,10.9,45.9,10.7,45.8,10.6z"/>
			<path fill="#E0C164" d="M25.8,25.3c0,0.3,0.1,0.5,0.4,0.5c0.8-0.1-0.1-0.9,0.5-1.2c0.1,0,0-0.4-0.2-0.3
				C25.8,24.4,26,25,25.8,25.3z"/>
			<path fill="#E1C570" d="M35.5,23.3c-0.5-0.3-0.7,0-0.7,0.5c0.3,0.2,0.2,0.6,0.4,0.9C35.8,24.3,35.8,23.8,35.5,23.3z"/>
			<path fill="#E0BF5E" d="M15.1,74.9c0-0.1,0-0.2,0-0.2c-0.4-0.1-0.8,0-1,0.3c-0.2,0.2-0.4,0.4-0.3,0.7c0.1,0.1,0.3,0.3,0.5,0.3
				c0.4,0,0.3-0.3,0.3-0.6C14.5,74.9,14.9,74.9,15.1,74.9z"/>
			<path fill="#DFC05E" d="M56.4,1.1c-1.4-0.2-1.4-0.2-0.9,1C55.7,1.8,56,1.5,56.4,1.1z"/>
			<path fill="#E1C36C" d="M41.1,25.3c-0.1-0.4,0.2-1.1-0.5-1.3c-0.1,0-0.3,0.1-0.3,0.1C40.2,24.7,40.5,25.1,41.1,25.3z"/>
			<path fill="#E2C572" d="M35.5,21.4c-0.3-0.2-0.5-0.2-0.7,0c-0.3,0.3,0,0.5,0.2,0.6c0.3,0.2-0.1,0.8,0.5,0.7
				c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1-0.1-0.2-0.2-0.2C35.4,21.8,35.4,21.6,35.5,21.4z"/>
			<path fill="#DEBC53" d="M40.1,21.4c-0.1,0-0.2,0-0.2,0c0.4,0.6-0.4,0.4-0.5,0.7l0,0c0.3,0.1,0.6,0.6,1,0.1
				c0.1-0.2,0.6-0.2,0.5-0.6C40.7,21.3,40.4,21.4,40.1,21.4z"/>
			<path fill="#E1C369" d="M51.8,6.5c-0.3-0.2-0.9,0-0.9-0.8c-0.4,0.5-0.6,1-0.2,1.3C50.8,7,51.7,7.2,51.8,6.5
				C51.8,6.5,51.8,6.5,51.8,6.5z"/>
			<path fill="#E0C267" d="M37.7,15.8c-0.1,0-0.1,0-0.2,0c-0.4,0.3-0.9,0.5-0.8,1.1c0.1,0.2,0.2,0.2,0.4,0.3c0,0,0,0,0,0
				C38.1,17.2,38,16.6,37.7,15.8z"/>
			<path fill="#E0C164" d="M19.9,60c0.6-0.8,0.3-1.3-0.1-1.6c-0.1-0.1-0.3,0-0.4,0.2C18.9,59.4,20,59.3,19.9,60z"/>
			<path fill="#E0C165" d="M35.4,25.4c-0.4,0.3-1,0.4-0.6,1c0.1,0.2,0.4,0.3,0.6,0.2C36,26.2,35.3,25.8,35.4,25.4z"/>
			<path fill="#E1C56F" d="M41.1,22.8c0.4,0.5,0.2,1.1,0.8,1.2c0.2,0,0.4-0.2,0.3-0.4C42.1,23.1,41.8,22.8,41.1,22.8z"/>
			<path fill="#DFBF5A" d="M54.3,5.3c0.2,0.6-0.3,1,0.3,1.3c0.2,0.1,0.4-0.1,0.4-0.3C55.3,5.8,55,5.6,54.3,5.3z"/>
			<path fill="#E1C46F" d="M10.6,75.1c0.3-0.1,0.7,0.1,1-0.1c0-0.4-0.4-0.3-0.5-0.6c-0.2,0-0.5,0-0.7,0c-0.2,0.1-0.2,0.3-0.2,0.5
				C10.2,75,10.4,75,10.6,75.1z"/>
			<path fill="#E0BF5D" d="M7.4,75.1c0.1,0.4-1.7,0.9,0.1,1.3C7.5,76,8.1,75.6,7.4,75.1C7.4,75.1,7.4,75.1,7.4,75.1z"/>
			<path fill="#E1C571" d="M12.5,54.5c-0.1,0-0.2,0-0.2,0c-0.4,0-0.9-0.1-1.1,0.4c-0.1,0.1,0.1,0.3,0.3,0.3
				C11.9,55.2,12.4,55.2,12.5,54.5C12.5,54.5,12.5,54.5,12.5,54.5z"/>
			<path fill="#E0C163" d="M51.6,3.3c1.2,0,1.2,0,1.2-1.3C52.2,2.3,52.4,3.2,51.6,3.3z"/>
			<path fill="#E0C061" d="M18.5,70.6c-0.7,0.1-0.7,0.6-0.7,1.3C18.5,71.6,19,71.4,18.5,70.6L18.5,70.6z"/>
			<path fill="#E1C570" d="M17.6,66.6c0.1-0.6,0.3-1.1-0.1-1.4c-0.1-0.1-0.5,0.1-0.5,0.2C16.9,65.8,17.2,66.1,17.6,66.6z"/>
			<path fill="#DEBC51" d="M10.8,59c0-0.2,0-0.4-0.2-0.5c-0.3,0.3-0.3-0.3-0.5-0.2c-0.2-0.2-0.2-0.5-0.5-0.7c-0.1,0-0.2,0-0.2,0
				c-0.1,0.2-0.2,0.5,0,0.7c-0.1,0.2,0,0.4,0.2,0.6c0.3,0.1,0.4,0.6,0.8,0.4C10.4,59.1,10.6,59,10.8,59z"/>
			<path fill="#DEBC52" d="M11.4,44.4c0.8,0.3,1.3,0.3,1.8,0c0,0,0-0.3,0-0.3C12.7,43.9,12.2,44.2,11.4,44.4z"/>
			<path fill="#DEBB4F" d="M58,1.1c-0.5,0.4-0.9,0.7-1.2,1c0,0,0.2,0.3,0.3,0.2c0.4-0.2,0.7-0.5,1-0.8C58.2,1.5,58.1,1.3,58,1.1z"/>
			<path fill="#DFC05E" d="M36,26.7c-0.3,0.4-1.3,0.3-0.8,1.3C35.6,27.6,36.1,27.4,36,26.7z"/>
			<path fill="#DEBA48" d="M50.4,1.2c0,0.2,0,0.5,0,0.7c0.1,0.4,0.3,0.7,0.4,1.1c1-0.4,0-0.9,0.1-1.3c0,0,0,0,0,0
				C50.7,1.6,50.9,1.1,50.4,1.2z"/>
			<path fill="#DEBC52" d="M36.7,23.5c0.6,0,1.3,0.2,1.9-0.5c-0.7-0.4-1.3,0.4-1.9,0C36.6,23.2,36.6,23.4,36.7,23.5z"/>
			<path fill="#DDB946" d="M17.9,68.2C17.4,68.6,17,69,16.4,69C17.4,69.4,17.4,69.4,17.9,68.2z"/>
			<path fill="#E2C674" d="M9.6,57.6c0,0.7,0,0.7,0.5,0.7c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.3-0.2,0.3-0.5
				C10.3,57.1,10,57.7,9.6,57.6z"/>
			<path fill="#DDB740" d="M50.9,9.5c0.7-0.4,1.1,0.4,1.5-0.3c0.1-0.1,0-0.3,0-0.4C52,9.2,51.4,8.5,50.9,9.5z"/>
			<path fill="#E1C570" d="M10.6,47c-0.1-0.3,0.1-0.8-0.5-0.6c-0.3,0.1-0.6,0.4-0.4,0.7C10,47.8,10.2,47,10.6,47
				C10.6,47,10.6,47,10.6,47z"/>
			<path fill="#E1C673" d="M49.7,3.4c0.2,0.1,0.5,0.2,0.7,0.2l0,0c0-0.5,0-1.1-0.5-1.4C49.1,2.4,49.5,2.9,49.7,3.4z"/>
			<path fill="#DDB73D" d="M16.3,76.3c0.1,0.2,0.3,0.2,0.5,0.2c0.1,0.1,0.2,0.1,0.3,0c-0.1-0.2-0.2-0.5-0.2-0.7
				c0-0.3,0.1-0.6-0.1-0.8c-0.1,0-0.2,0-0.3,0.1c-0.5,0.3,0,0.5,0.1,0.8C16.5,76.1,16.5,76.2,16.3,76.3z"/>
			<path fill="#E0C268" d="M10.3,59.3c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2-0.1-0.3,0-0.2,0.2c0.1,0.2,0.2,0.5,0.4,0.7
				c0.1,0.2,0.4,0.3,0.6,0.1C10.5,59.6,10.6,59.5,10.3,59.3z"/>
			<path fill="#E0C163" d="M12.5,66.9c0.3,0,0.6-0.1,0.4-0.4c-0.1-0.2-0.4-0.4-0.7-0.5c-0.2-0.1-0.4,0.1-0.3,0.4
				C12,66.7,12.1,67,12.5,66.9z"/>
			<path fill="#DDB948" d="M61.3,10.2c0.2,0,0.4-0.1,0.3-0.2c-0.1-0.6-0.6-0.3-0.9-0.5c-0.2-0.2-0.3,0-0.3,0.3
				C60.5,10.3,61.2,9.8,61.3,10.2z"/>
			<path fill="#E1C36A" d="M8.2,45.7c0,0.4-0.2,0.9,0.2,1.2c0.1,0,0.3-0.1,0.4-0.2C9,46.2,8.7,45.9,8.2,45.7z"/>
			<path fill="#DDB946" d="M62.3,2.2c-0.5,0.2-0.9,0.4-0.7,1c0,0.1,0.2,0.2,0.3,0.2C62.4,3.1,62.1,2.6,62.3,2.2z"/>
			<path fill="#DDB73F" d="M33.8,25.5c-0.5,0.5-0.5,0.9-0.3,1.6C33.8,26.6,34.2,26.2,33.8,25.5z"/>
			<path fill="#DDB946" d="M14.6,69.1c0.3,0.1,0.7,0.8,1,0.3c0.3-0.5-0.4-0.7-0.7-1C14.7,68.6,14.5,68.8,14.6,69.1z"/>
			<path fill="#DDB843" d="M57.5,12c0.3-0.1,0.5-0.3,0.6-0.6c0-0.2-0.2-0.4-0.4-0.4c-0.2,0.2-0.4,0.4-0.6,0.7
				C57.1,11.9,57.3,12,57.5,12z"/>
			<path fill="#DFBD55" d="M14.7,64.4c-0.3-0.7-0.4-1.1-0.9-1.2c0,0.2-0.9,0.6,0.1,0.7C14.1,64,14.2,64.1,14.7,64.4z"/>
			<path fill="#DEBC53" d="M56.6,6.5c0-0.3-0.2-0.5-0.5-0.3c-0.3,0.1-0.4,0.3-0.4,0.6c0.1,0.4,0.4,0.4,0.7,0.4
				C56.2,6.8,56.6,6.7,56.6,6.5z"/>
			<path fill="#E1C36A" d="M14,55.5c-0.6-0.3-0.9-0.1-1.3,0.2c0,0,0,0.3,0.1,0.3C13.5,56.4,13.5,55.5,14,55.5z"/>
			<path fill="#E1C56F" d="M41,16.8c0-0.3,0.3-0.7-0.3-0.8c-0.3,0-0.6,0.2-0.5,0.5C40.3,17.1,40.7,16.7,41,16.8
				C41,16.8,41,16.8,41,16.8z"/>
			<path fill="#E1C46D" d="M13.7,56.8c-0.3,0.2-0.8,0.3-0.9,0.7c0,0.1,0.1,0.2,0.2,0.2C13.6,57.8,13.7,57.4,13.7,56.8z"/>
			<path fill="#DDB740" d="M54,7.9c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0.2-0.6,0.5c0,0.2,0,0.3,0.2,0.4C53.7,9,53.7,8.5,54,8.4
				C54.1,8.2,54.1,8.1,54,7.9z"/>
			<path fill="#DEBC54" d="M11.3,52.3c-0.2,0.3-0.8,0.4-0.7,1c0.3-0.1,0.6-0.5,1-0.2c0.2-0.1,0.4-0.3,0.5-0.5
				C11.8,52.3,11.5,52.3,11.3,52.3z"/>
			<path fill="#DFBD54" d="M16.9,59c0.7-0.1,1.1-0.1,1.3-0.5c0-0.1,0-0.3-0.1-0.3C17.6,58.1,17.3,58.4,16.9,59z"/>
			<path fill="#DEBC4D" d="M51.6,1c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c0.1,0.1,0.3,0.3,0.5,0.3c0.2-0.6,0.5-1,1.2-1.2
				c-0.6,0-1.1,0-1.7,0C51.5,0.5,51.7,0.7,51.6,1z"/>
			<path fill="#E0C05F" d="M18.9,68.5c0.4,0.1,0.5,0.9,1.1,0.4c0.1-0.1,0.2-0.2,0.1-0.3C19.8,68.2,19.4,68.5,18.9,68.5z"/>
			<path fill="#E1C46D" d="M12.7,75.9c-0.2,0.2-0.5,0.4,0,0.6c0.4,0.2,0.6,0.4,0.7-0.2C13.3,76,13.1,75.8,12.7,75.9z"/>
			<path fill="#E1C36A" d="M9.1,76.9c0.5,0,0.3,0.9,0.9,0.7c0.1,0,0.2-0.3,0.2-0.3C10,76.8,9.6,76.8,9.1,76.9z"/>
			<path fill="#E0C060" d="M14.6,69.1c0.1-0.2,0.2-0.5,0.2-0.7c-0.3,0.1-0.9-0.3-0.8,0.5C14.1,69.6,14.4,69.2,14.6,69.1z"/>
			<path fill="#E2C674" d="M16.2,70.9c0.1,0.2,0,0.6,0.4,0.6c0.2,0,0.3-0.1,0.3-0.4c0-0.3-0.2-0.5-0.5-0.5
				C16.3,70.6,16.2,70.7,16.2,70.9z"/>
			<path fill="#E0C265" d="M15.6,49.4c0,0.3-0.7,0.4-0.3,0.8c0.1,0.1,0.5,0.1,0.6,0c0.5-0.4-0.1-0.5-0.2-0.8
				C15.8,49.4,15.7,49.4,15.6,49.4z"/>
			<path fill="#DFC05E" d="M19.2,42.3c0.2-0.5,0.5-0.7,0.2-1.1c-0.1-0.1-0.3-0.2-0.4,0C18.7,41.5,19,41.8,19.2,42.3z"/>
			<path fill="#DEBC51" d="M19.6,38.3c-0.5,0-0.8,0.1-0.8,0.6c0,0.1,0.2,0.3,0.3,0.3C19.6,39.2,19.5,38.7,19.6,38.3z"/>
			<path fill="#E1C572" d="M28.2,27.3c0.1,0.2,0.1,0.5,0.3,0.4c0.2-0.1,0.5-0.3,0.6-0.5c0.1-0.2-0.2-0.3-0.3-0.3
				C28.4,26.8,28.3,27,28.2,27.3z"/>
			<path fill="#DEBB4D" d="M41.2,26.9c0.4,0.2,0.2,0.9,0.8,0.8c0.1,0,0.2-0.2,0.2-0.2C42,26.9,41.6,27,41.2,26.9z"/>
			<path fill="#E1C266" d="M14.2,62c0.3-0.3,0.8-0.5,0.7-1c0-0.1-0.2-0.1-0.3-0.1C14.2,61.1,13.9,61.4,14.2,62z"/>
			<path fill="#DDB947" d="M20.4,63c-0.2,0.7-0.4,1.1-0.6,1.7C20.5,64.1,20.5,64.1,20.4,63z"/>
			<path fill="#E1C46D" d="M49.4,8.6C48.7,9,48.7,9,49.3,9.7C49.6,9.4,49.4,9,49.4,8.6L49.4,8.6z"/>
			<path fill="#DEBA48" d="M49.7,3.4c0.1-0.4-0.2-0.9,0.2-1.2c0-0.1,0-0.1,0-0.2c-0.2,0-0.3,0-0.5,0c-0.1,0.1-0.2,0.2-0.2,0.2
				c-0.2,0.3-0.4,0.6-0.2,1c0,0,0,0,0,0C49.2,3.2,49.4,3.3,49.7,3.4z"/>
			<path fill="#DDB740" d="M50.4,3.6c-0.3,0.4-0.2,0.7,0.2,1c0.1,0.1,0.3,0,0.3-0.1C51,4.1,50.9,3.7,50.4,3.6
				C50.4,3.6,50.4,3.6,50.4,3.6z"/>
			<path fill="#DFBF5B" d="M11.5,49.8c0.3,0,0.5-0.1,0.4-0.4c0-0.2-0.2-0.4-0.4-0.3c-0.3,0-0.4,0.3-0.4,0.5
				C11,49.9,11.4,49.7,11.5,49.8z"/>
			<path fill="#DFBD55" d="M46.5,15.3c0.3,0,0.5-0.2,0.5-0.4c0-0.3-0.3-0.3-0.5-0.3c-0.2,0-0.5,0-0.4,0.2
				C46.1,15.1,46.4,15.2,46.5,15.3z"/>
			<path fill="#DDB73F" d="M47.6,18.7c0.1-0.1,0.3-0.2,0.3-0.3c-0.1-0.4-0.7-0.2-0.7-0.6c-0.1,0.1-0.2,0.3-0.2,0.4
				C47,18.4,47.2,18.6,47.6,18.7z"/>
			<path fill="#E1C46C" d="M49.4,8.6c0.3-0.2,0.7-0.3,0.6-0.8c0-0.2-0.3-0.2-0.5-0.1c-0.4,0.2-0.1,0.4-0.1,0.7
				C49.4,8.5,49.4,8.6,49.4,8.6C49.4,8.6,49.4,8.6,49.4,8.6z"/>
			<path fill="#E0C267" d="M12.7,51.3c0-0.3-0.1-0.5-0.3-0.5c-0.2,0-0.3,0.2-0.3,0.4c0,0.3,0.1,0.5,0.3,0.5
				C12.6,51.7,12.7,51.5,12.7,51.3z"/>
			<path fill="#DEBA4B" d="M13.4,67.9c0.3,0,0.7,0.2,0.9-0.3c0.1-0.1,0-0.3-0.2-0.4c-0.4-0.1-0.6,0.1-0.7,0.4
				C13.4,67.7,13.3,67.8,13.4,67.9C13.4,67.9,13.4,67.9,13.4,67.9z"/>
			<path fill="#DDBA4A" d="M18.5,52.6c0,0.2,0,0.3,0,0.5c0,0,0,0,0,0c0.1,0.1,0.2,0.4,0.5,0.2c0.1-0.3,0.7-0.2,0.6-0.5
				C19.3,52.3,18.8,52.6,18.5,52.6z"/>
			<path fill="#DDB944" d="M12.5,62.7c0.4,0.2-0.2,0.7,0.2,0.9c0.3,0,0.6-0.2,0.5-0.5C13.2,62.7,12.8,62.7,12.5,62.7z"/>
			<path fill="#DBB22C" d="M8.9,62.6c0.6,0.1,0.8-0.3,1-0.7c-0.2-0.1-0.5-0.4-0.7,0C9,62.2,9,62.4,8.9,62.6z"/>
			<path fill="#E1C572" d="M48.3,12.8c0,0.2,0.1,0.4,0.3,0.3c0.2,0,0.5-0.1,0.5-0.2c0.1-0.3-0.2-0.4-0.4-0.4
				C48.5,12.5,48.3,12.6,48.3,12.8z"/>
			<path fill="#E1C36C" d="M56.9,7c0.5,0.2,0.9-0.2,1.4-0.4c-0.6-0.2-1-0.4-1.4,0.1C56.8,6.8,56.8,6.8,56.9,7
				C56.8,6.9,56.9,7,56.9,7z"/>
			<path fill="#DEBC51" d="M38.8,25.4c-0.2-0.4-0.4-0.7-0.8-1.3C38,24.9,38.3,25.2,38.8,25.4z"/>
			<path fill="#DCB63B" d="M58.3,2.8c0.5,0.4,0.8,0.4,1.3-0.2C59,2.5,58.7,3.1,58.3,2.8z"/>
			<path fill="#E1C46F" d="M28.6,24c0-0.1,0-0.1,0-0.2c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.1-0.3-0.2-0.5-0.1c0,0,0,0,0,0
				c0.1,0.4,0.1,0.9,0.5,1.2c0.1-0.1,0.1-0.1,0.2-0.2C28.4,24.2,28.5,24.1,28.6,24z"/>
			<path fill="#DEBB4C" d="M13.8,32.2c0,0.2,0.1,0.4,0.4,0.4c0.2,0,0.4-0.1,0.4-0.3c0-0.2-0.1-0.3-0.3-0.3
				C14,31.9,13.8,31.9,13.8,32.2z"/>
			<path fill="#DFBF5A" d="M13.8,58.6c0.4-0.2,0.8-0.3,0.7-0.8c0-0.1-0.2-0.2-0.3-0.1C13.7,57.9,14,58.3,13.8,58.6z"/>
			<path fill="#E1C46C" d="M54,7.9c0,0.2,0,0.3,0,0.5c0.2,0.2,0.4,0.2,0.6,0c0.1-0.2,0.2-0.3,0-0.5C54.4,7.7,54.2,7.7,54,7.9z"/>
			<path fill="#DFBD55" d="M51.1,10.3c0.4,0.2,0.4,0.2,1.1-0.2C51.7,9.6,51.5,10.2,51.1,10.3L51.1,10.3z"/>
			<path fill="#E0C061" d="M18.5,70.6c0.4-0.1,0.6-0.3,0.2-0.5c-0.2-0.1-0.6,0.1-0.9,0.1C17.9,70.5,18.3,70.2,18.5,70.6
				C18.5,70.6,18.5,70.6,18.5,70.6z"/>
			<path fill="#DFBE59" d="M31.1,27.2c-0.1,0.3-0.1,0.6-0.2,1.2C31.4,28,31.5,27.7,31.1,27.2z"/>
			<path fill="#E1C571" d="M33.2,28.3c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.2,0.3-0.4c0-0.2-0.2-0.3-0.3-0.3
				C33.3,27.9,33.1,28,33.2,28.3z"/>
			<path fill="#E0C060" d="M43.2,19.5c-0.2,0.1-0.4,0.1-0.3,0.4c0,0.2,0.2,0.3,0.4,0.2c0.2-0.1,0.4-0.2,0.3-0.4
				C43.6,19.5,43.4,19.5,43.2,19.5z"/>
			<path fill="#DEBA4B" d="M12.5,68.2c-0.2,0.2-0.5,0.3-0.7,0.5c-0.1,0.3,0.1,0.4,0.2,0.5c0.2-0.1,0.5-0.2,0.7-0.2l0,0
				c-0.1-0.2-0.1-0.5,0-0.7C12.6,68.1,12.6,68.1,12.5,68.2z"/>
			<path fill="#E1C36B" d="M19,53.3c-0.2-0.1-0.3-0.2-0.5-0.2c0,0.2,0,0.4-0.2,0.5c-0.1,0.2-0.2,0.3,0,0.5
				C18.7,53.9,19.1,53.8,19,53.3z"/>
			<path fill="#DEBB4E" d="M19.2,45.2c-0.2,0.1-0.4,0.2-0.3,0.4c0,0.2,0.2,0.2,0.4,0.2c0.2,0,0.4-0.1,0.3-0.4
				C19.5,45.3,19.3,45.2,19.2,45.2z"/>
			<path fill="#E2C672" d="M10.8,42.2c-0.1-0.2-0.1-0.3-0.2-0.5c-0.3,0.2-0.9,0.3-0.4,0.8C10.4,42.9,10.6,42.5,10.8,42.2z"/>
			<path fill="#E0C162" d="M13.6,65.9c-0.1,0.4,0.2,0.6,0.4,0.7c0,0,0.2-0.1,0.2-0.2C14.2,66,14,65.8,13.6,65.9z"/>
			<path fill="#DFBF5A" d="M15.5,64.9c0.3,0.2,0.5,0.5,0.9,0.2c0,0,0-0.2,0-0.2C16.2,64.6,15.9,64.6,15.5,64.9z"/>
			<path fill="#DEBC51" d="M19.6,65.4c-0.1-0.5-0.4-0.5-0.8-0.5C18.9,65.4,19.2,65.5,19.6,65.4z"/>
			<path fill="#DFBC52" d="M45.9,16.6c0.4,0.2,0.5,0.4,0.7,0.4c0.2,0,0.3-0.2,0.3-0.4c0-0.2,0-0.3-0.2-0.3
				C46.4,16.3,46.2,16.4,45.9,16.6z"/>
			<path fill="#DFBE56" d="M38.1,29.9c-0.2,0.3-0.4,0.6-0.1,1C38.2,30.6,38.5,30.3,38.1,29.9z"/>
			<path fill="#DEBD55" d="M39.4,29.1c-0.5,0-0.6,0.4-0.7,0.8C39.1,29.7,39.4,29.5,39.4,29.1z"/>
			<path fill="#E1C46C" d="M8.4,52.1c0.3-0.1,0.4,0.4,0.7,0.2c0.2-0.1,0.1-0.5-0.2-0.6C8.6,51.5,8.4,51.8,8.4,52.1
				C8.4,52.1,8.4,52.1,8.4,52.1z"/>
			<path fill="#E2C776" d="M9.4,59c0.1-0.1,0.2-0.2,0.2-0.2c-0.1-0.2-0.2-0.3-0.2-0.5c-0.2,0-0.3,0-0.5,0c0.1,0.2-0.3,0.5,0,0.7
				C9,59,9.2,59,9.4,59z"/>
			<path fill="#E0BF5D" d="M27.8,26c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3
				C27.6,26.4,27.8,26.3,27.8,26z"/>
			<path fill="#E1C46B" d="M36.7,23.5c0-0.2,0-0.3,0-0.5c-0.3,0-0.7,0.1-0.6,0.5C36.2,23.9,36.5,23.8,36.7,23.5z"/>
			<path fill="#DEB946" d="M37.5,25.8c-0.1,0.4,0,0.7,0.3,0.7c0,0,0.2-0.1,0.2-0.1C38.1,26.1,37.8,25.9,37.5,25.8z"/>
			<path fill="#E0C163" d="M52.1,1.5c-0.1-0.2-0.3-0.2-0.5-0.3c0,0.4,0.1,0.7,0.3,1C52.2,2,52,1.7,52.1,1.5z"/>
			<path fill="#DFBD55" d="M18.8,60.8c0.4,0.4,0.6,0.5,1,0.2C19.6,60.6,19.3,60.7,18.8,60.8z"/>
			<path fill="#E1C369" d="M34.1,38.5c0.3,0,0.4-0.1,0.4-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.4,0.1-0.4,0.3
				C33.8,38.4,34,38.4,34.1,38.5z"/>
			<path fill="#E2C674" d="M12.2,75.1c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.1,0.4-0.3,0.5-0.4c-0.1-0.1-0.2-0.2-0.4-0.2
				C12.3,74.6,12.3,74.9,12.2,75.1z"/>
			<path fill="#E3CA7F" d="M11,63.8c0.1,0.3-0.2,0.7,0.2,0.8c0.3,0.1,0.5-0.2,0.5-0.5c0,0,0,0,0,0C11.6,63.9,11.4,63.7,11,63.8
				L11,63.8z"/>
			<path fill="#DDB843" d="M16.7,53.8c-0.1,0.3-0.4,0.5-0.2,0.8c0,0,0.2,0,0.2,0C17.2,54.4,16.7,54.1,16.7,53.8z"/>
			<path fill="#E0C060" d="M12.7,68.9c0.1,0.3,0.2,0.5,0.4,0.8C13.2,69.3,13.5,68.8,12.7,68.9C12.7,68.9,12.7,68.9,12.7,68.9z"/>
			<path fill="#E2C673" d="M12.5,68.2c0.1,0,0.2,0,0.2,0c0.2-0.1,0.5-0.2,0.7-0.3c0-0.1,0-0.2,0-0.2C12.9,67.5,12.6,67.6,12.5,68.2z
				"/>
			<path fill="#E0C163" d="M12,69.1c-0.1-0.2-0.2-0.3-0.2-0.5c-0.2-0.3-0.5-0.2-0.5,0C11.2,69.1,11.7,69.1,12,69.1z"/>
			<path fill="#DFBE5A" d="M8.9,72.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.3-0.1,0.4-0.2c0.1-0.2,0-0.3-0.2-0.3C9.1,72,9,72.1,8.9,72.3z"
				/>
			<path fill="#DFBB50" d="M60.7,8.6c0,0.3,0.2,0.2,0.3,0.2c0.1,0,0.3,0,0.3-0.2c0-0.2-0.2-0.2-0.3-0.2C60.9,8.5,60.8,8.6,60.7,8.6z
				"/>
			<path fill="#E0C266" d="M54.4,3.3c0.2,0,0.4-0.1,0.4-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0.1-0.3,0.2-0.4,0.4
				C54.1,3.3,54.3,3.3,54.4,3.3z"/>
			<path fill="#DEB947" d="M43.8,20.8c-0.2,0-0.3,0.2-0.3,0.3c0,0.1,0,0.3,0.1,0.3c0.2,0.1,0.3-0.1,0.3-0.2
				C43.9,21.1,43.9,20.9,43.8,20.8z"/>
			<path fill="#DFBD54" d="M53,11.6c0.3,0.5,0.4,0.8,0.7,0.7c0,0,0.1-0.1,0.1-0.2C53.8,11.9,53.5,11.9,53,11.6z"/>
			<path fill="#E1C46D" d="M52.5,12.4c0-0.4,0.1-0.6-0.2-0.8c0,0-0.2,0.1-0.3,0.1C51.8,12.1,52.2,12.2,52.5,12.4z"/>
			<path fill="#DDB73E" d="M35.5,22.1c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0,0.4,0.1,0.6,0c0.2-0.1-0.1-0.2-0.2-0.3
				C35.9,21.6,35.7,22,35.5,22.1z"/>
			<path fill="#DFBE56" d="M33.1,31c-0.2,0-0.3,0-0.3,0.2c0,0.1,0.2,0.2,0.3,0.3c0,0,0.2-0.1,0.2-0.2C33.3,31.2,33.2,31,33.1,31z"/>
			<path fill="#E1C36A" d="M18.6,49.4c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.2
				C18.3,49.3,18.4,49.4,18.6,49.4z"/>
			<path fill="#DEBC53" d="M30.7,30.1c0,0.2,0.1,0.2,0.3,0.2c0.1,0,0.4,0.1,0.3-0.2c0-0.1-0.2-0.3-0.3-0.3
				C30.8,29.8,30.8,29.9,30.7,30.1z"/>
			<path fill="#DEBB4C" d="M17.7,52.6c-0.1-0.2-0.2-0.3-0.2-0.5c-0.2,0-0.6,0-0.6,0.3C17,52.7,17.5,52.5,17.7,52.6z"/>
			<path fill="#E2C97D" d="M18.2,51.1c0,0.2,0.1,0.4,0.2,0.5c0.2-0.1,0.4-0.3,0.5-0.5c0,0,0,0,0,0C18.7,51.1,18.5,50.7,18.2,51.1z"
				/>
			<path fill="#DFBD56" d="M10.1,55.9c-0.6-0.3-0.4,0.2-0.5,0.5C9.9,56.4,10.4,56.5,10.1,55.9z"/>
			<path fill="#E1C36A" d="M35.8,24.7c0,0.3,0.1,0.4,0.2,0.4c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.2-0.1-0.3-0.3-0.2
				C36,24.6,35.8,24.7,35.8,24.7z"/>
			<path fill="#DDB947" d="M11.3,75.6c-0.5,0.1-0.5,0.3-0.4,0.8C11.3,76.3,11.3,75.9,11.3,75.6C11.3,75.6,11.3,75.6,11.3,75.6z"/>
			<path fill="#DEBC52" d="M10.1,51c0,0.1,0.1,0.2,0.1,0.2c0.3,0.1,0.3-0.2,0.4-0.3c0.1-0.2-0.1-0.2-0.2-0.2
				C10.3,50.7,10.1,50.8,10.1,51z"/>
			<path fill="#DFBE5A" d="M36.7,33.9c-0.1-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.2,0.1-0.3,0.3c-0.1,0.5,0.3,0.5,0.6,0.5
				C36.8,34,36.8,34,36.7,33.9C36.8,33.9,36.8,33.9,36.7,33.9z"/>
			<path fill="#E1C46C" d="M9.9,44.6c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0-0.3-0.2-0.3
				C10.1,44.3,10,44.4,9.9,44.6z"/>
			<path fill="#DDBA4A" d="M54.6,12.7c0.1-0.1,0.3-0.1,0.3-0.2c0.1-0.2-0.1-0.3-0.3-0.2c-0.1,0-0.2,0.1-0.2,0.2
				C54.4,12.5,54.5,12.6,54.6,12.7z"/>
			<path fill="#DFC061" d="M16.2,65.7c-0.1,0.2-0.2,0.4,0,0.6c0,0,0.2,0,0.2,0C16.6,66,16.3,65.9,16.2,65.7z"/>
			<path fill="#E0C162" d="M27.2,33.2c-0.1,0.2-0.2,0.3-0.2,0.5c0,0,0.2,0.2,0.2,0.2c0.2,0,0.3-0.1,0.2-0.3
				C27.5,33.4,27.4,33.3,27.2,33.2z"/>
			<path fill="#E1C572" d="M44.6,10.6c-0.2,0-0.4,0-0.4,0.3c0,0.3,0.2,0.4,0.4,0.4C44.6,11,44.6,10.8,44.6,10.6z"/>
			<path fill="#DEBB4E" d="M17.5,54.2c-0.3,0.2-0.5,0.5-0.3,0.9C17.7,54.9,17.4,54.5,17.5,54.2L17.5,54.2z"/>
			<path fill="#DCB63B" d="M56.9,7c0-0.1,0-0.2,0-0.2c-0.1-0.1-0.2-0.2-0.2-0.2c-0.3,0.2-0.8,0.3-0.2,0.7C56.6,7.1,56.7,7,56.9,7z"
				/>
			<path fill="#E0C369" d="M17.8,53c-0.3,0-0.6,0-0.6,0.4c0,0.1,0.1,0.1,0.2,0.1C17.8,53.6,17.7,53.3,17.8,53
				C17.8,53,17.8,53,17.8,53z"/>
			<path fill="#DFBD53" d="M51,7.9c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.3-0.1c0-0.1-0.1-0.2-0.2-0.2
				C51.3,7.7,51.1,7.7,51,7.9z"/>
			<path fill="#DEBB4E" d="M18.2,54c0-0.2,0-0.3,0-0.5c-0.3,0.1-0.6,0.4-0.7,0.7c0,0,0,0,0,0C17.9,54.5,18,54.1,18.2,54z"/>
			<path fill="#E2C674" d="M15.6,40.3c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.1,0.5-0.3,0.3-0.5C15.9,39.7,15.7,40.1,15.6,40.3z"/>
			<path fill="#E0C164" d="M9.2,69.3c0,0,0.2,0.2,0.2,0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2-0.1-0.2-0.2-0.2
				C9.3,68.9,9.2,69,9.2,69.3z"/>
			<path fill="#E1C36C" d="M14,53.9c0,0.2,0.1,0.3,0.2,0.3c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1-0.1-0.2-0.2-0.2
				C14.2,53.7,14.1,53.8,14,53.9z"/>
			<path fill="#E1C570" d="M16.8,51.6c0.5,0,0.5-0.4,0.5-0.7C16.8,50.9,16.8,51.2,16.8,51.6z"/>
			<path fill="#E0C164" d="M17.5,59.9c-0.2-0.1-0.2,0.1-0.2,0.2c0,0.1,0.1,0.3,0.1,0.4c0.1-0.1,0.2-0.1,0.3-0.2
				C17.7,60.2,17.7,60,17.5,59.9z"/>
			<path fill="#E0C060" d="M20.9,67c0.2,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.3-0.3c0,0-0.2-0.1-0.3-0.1
				C21.2,66.8,21.1,66.9,20.9,67z"/>
			<path fill="#E0BF5C" d="M57.6,3.8c0.1-0.2,0.2-0.3,0.2-0.4c0-0.1,0-0.4-0.2-0.3c-0.1,0-0.2,0.2-0.2,0.4
				C57.4,3.5,57.5,3.6,57.6,3.8z"/>
			<path fill="#E1C571" d="M13.3,46.9c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0.3-0.2,0.3-0.3c0-0.1-0.1-0.2-0.2-0.1
				C13.4,46.6,13.3,46.6,13.3,46.9z"/>
			<path fill="#DDB73E" d="M35.5,19.4c-0.2,0-0.3,0-0.5,0c-0.3,0.1-0.6-0.1-0.8,0.3c0,0.1,0,0.2,0.2,0.3c0.3,0,0.6,0.1,0.9-0.1
				c0,0,0,0,0,0C35.4,19.8,35.4,19.6,35.5,19.4z"/>
			<path fill="#E0C36A" d="M12.5,49c0.1,0,0.2,0.2,0.2,0.2c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.1-0.1-0.2-0.2-0.2
				C12.7,48.6,12.6,48.7,12.5,49z"/>
			<path fill="#E0C163" d="M25.2,22.6c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.1-0.3-0.1
				C25.4,22.4,25.4,22.5,25.2,22.6z"/>
			<path fill="#E2C778" d="M16.5,49c-0.1,0.4,0,0.5,0.1,0.5c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.2-0.4C16.6,49,16.5,49,16.5,49z"
				/>
			<path fill="#DEBC53" d="M41.2,21.6c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3-0.2-0.3
				C41.4,21.3,41.4,21.5,41.2,21.6z"/>
			<path fill="#DDB843" d="M54.3,21.6c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.2-0.3c0-0.1-0.2-0.1-0.3-0.1
				C54.5,21.4,54.4,21.5,54.3,21.6z"/>
			<path fill="#DEBC53" d="M39.4,22.1c-0.2,0-0.5-0.3-0.5,0.2c0,0.1,0.2,0.3,0.2,0.3C39.5,22.6,39.3,22.3,39.4,22.1
				C39.4,22.1,39.4,22.1,39.4,22.1z"/>
			<path fill="#DDB740" d="M64.4,4.5c-0.2-0.2-0.5-0.2-0.6,0c-0.1,0.3,0.2,0.3,0.3,0.2C64.2,4.6,64.3,4.5,64.4,4.5z"/>
			<path fill="#DDB842" d="M12.8,64.2c-0.2,0.1-0.3,0.2-0.3,0.4c0,0.1,0.2,0.2,0.2,0.2c0.2,0,0.3-0.1,0.2-0.3
				C13,64.3,12.9,64.3,12.8,64.2z"/>
			<path fill="#DFBF5C" d="M13.1,70.2c-0.2,0.1-0.4,0.4-0.3,0.6c0.3,0.3,0.3,0,0.4-0.2C13.2,70.5,13.1,70.4,13.1,70.2z"/>
			<path fill="#E1C46C" d="M32.4,24.7c-0.1,0.1-0.3,0.2-0.3,0.3c0,0.2,0.2,0.1,0.4,0.1c0.2-0.1,0.4-0.2,0.3-0.4
				C32.7,24.5,32.5,24.7,32.4,24.7C32.4,24.7,32.4,24.7,32.4,24.7z"/>
			<path fill="#DDBA49" d="M37.1,32.1c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.2-0.2,0.2c0.1,0.1,0.1,0.2,0.3,0.3
				C36.9,32.3,37,32.2,37.1,32.1z"/>
			<path fill="#DDB945" d="M19.8,63.7c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.1,0.1-0.1,0.2
				C19.5,63.6,19.5,63.8,19.8,63.7z"/>
			<path fill="#E0C267" d="M13.5,72.7c-0.3,0.1-0.4,0.2-0.4,0.4c0,0.1,0.2,0.1,0.2,0.2c0.1-0.1,0.2-0.2,0.2-0.4
				C13.6,72.9,13.5,72.8,13.5,72.7z"/>
			<path fill="#E0C369" d="M15.6,49.4c0.1,0,0.1,0,0.2,0c0.3-0.2,0-0.4-0.1-0.6c0,0-0.1,0-0.2,0.1C15.4,49.1,15.6,49.3,15.6,49.4z"
				/>
			<path fill="#DFBF5B" d="M10.3,49.4c0,0.1,0,0.2,0,0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.3-0.1-0.3
				C10.5,49.1,10.4,49.3,10.3,49.4z"/>
			<path fill="#DFBC51" d="M60.9,3.7c0-0.1-0.1-0.3-0.2-0.2c-0.1,0-0.1,0.2-0.2,0.3c0,0.1,0.1,0.2,0.2,0.2
				C60.9,3.9,60.9,3.8,60.9,3.7z"/>
			<path fill="#DEBA4B" d="M13.2,45.1C13,45,13,44.9,12.9,44.9c-0.1,0-0.1,0.1-0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2
				C13,45.3,13.1,45.2,13.2,45.1z"/>
			<path fill="#E1C369" d="M11.2,33.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C11.3,32.9,11.2,32.9,11.2,33.2z"/>
			<path fill="#DEBC50" d="M34.8,33.3c0-0.1-0.1-0.3-0.2-0.2c-0.1,0-0.2,0.2-0.2,0.3c0,0.1,0.1,0.2,0.2,0.2
				C34.7,33.5,34.7,33.3,34.8,33.3z"/>
			<path fill="#DEBA4A" d="M12,70.8c-0.1-0.2,0.1-0.7-0.3-0.6c-0.3,0.1,0,0.4,0.1,0.6C11.8,70.9,11.9,70.9,12,70.8z"/>
			<path fill="#DEBA4A" d="M17.9,54.9c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1-0.1,0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
				C18.1,54.7,18,54.8,17.9,54.9z"/>
			<path fill="#DDB843" d="M45.4,16.4c-0.2,0-0.2,0.1-0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0.2,0.1,0.2-0.1,0.2-0.2
				C45.5,16.6,45.5,16.5,45.4,16.4z"/>
			<path fill="#E0C369" d="M38.6,16.2c-0.2,0.2-0.3,0.3-0.3,0.4c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
				C38.7,16.4,38.6,16.3,38.6,16.2z"/>
			<path fill="#E1C56F" d="M12,70.8c-0.1,0-0.2,0-0.2,0c0,0.2,0,0.5,0.3,0.5c0,0,0.1,0,0.2-0.1C12.3,71.1,12.2,70.9,12,70.8z"/>
			<path fill="#E1C571" d="M39.7,18.8c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.3-0.2c0-0.1,0-0.2-0.1-0.3
				C40,18.7,39.9,18.7,39.7,18.8z"/>
			<path fill="#DFBE5A" d="M20.2,72.2c0.1,0,0.2,0,0.2,0c0-0.3,0.1-0.7-0.3-0.7c-0.5,0,0,0.2,0,0.3C20.1,72,20.1,72.1,20.2,72.2z"/>
			<path fill="#E1C369" d="M34.8,29.8c0-0.1,0.1-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
				C34.6,29.7,34.6,29.8,34.8,29.8z"/>
			<path fill="#DFBD53" d="M39.1,20.9c0-0.3,0.1-0.7-0.5-0.5C38.6,20.7,38.8,20.9,39.1,20.9z"/>
			<path fill="#E2C775" d="M36.2,20.6c0-0.2,0-0.4-0.3-0.4c-0.1,0-0.3,0-0.2,0.2c0,0.1,0.2,0.2,0.3,0.3
				C36.1,20.6,36.1,20.6,36.2,20.6z"/>
			<path fill="#DDB945" d="M31.7,25.9c0.1,0.1,0.2,0.2,0.2,0.2c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1,0-0.3-0.2-0.2
				C31.9,25.7,31.8,25.8,31.7,25.9z"/>
			<path fill="#DFBE57" d="M15.5,53.5c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1-0.1-0.2-0.2-0.1c-0.1,0.1-0.2,0.2-0.2,0.3
				C15.3,53.2,15.4,53.3,15.5,53.5z"/>
			<path fill="#DEB945" d="M13.7,40.6c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1,0.1-0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2
				C13.4,40.7,13.5,40.6,13.7,40.6z"/>
			<path fill="#E0C165" d="M15.2,39c0.2,0.2,0.3,0.2,0.3,0.3c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C15.5,38.8,15.4,38.9,15.2,39z"/>
			<path fill="#DFBD55" d="M51.1,10.3c0-0.3-0.1-0.6-0.5-0.6C50.6,10.1,50.9,10.2,51.1,10.3C51.1,10.3,51.1,10.3,51.1,10.3z"/>
			<path fill="#E1C46D" d="M17,36.5c-0.1,0.1-0.2,0.2-0.1,0.3c0,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.2-0.2
				C17.3,36.6,17.2,36.6,17,36.5z"/>
			<path fill="#E0C267" d="M49.3,11.1c0.5,0.2,0.6,0.3,0.8,0.3c0,0,0.1-0.1,0.1-0.1C50.1,11.1,49.9,11,49.3,11.1z"/>
			<path fill="#E1C36A" d="M36.7,33.9c0,0.1,0,0.1,0,0.2c0.2,0.1,0.4,0.1,0.5,0.1c0,0,0.1-0.1,0.1-0.2
				C37.2,33.8,36.9,33.9,36.7,33.9z"/>
			<path fill="#DFBE59" d="M48.5,14.6c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1,0.1-0.2,0.2-0.2,0.2
				C48.3,14.4,48.4,14.5,48.5,14.6z"/>
			<path fill="#DBB330" d="M51.6,1c0.9-0.2,0.1-0.5,0-0.7c-0.2,0.1-0.3,0.1-0.5,0.2C51.3,0.7,51.4,0.8,51.6,1z"/>
			<path fill="#DFE4EA" d="M45.1,209.3c0.3-0.5-0.1-0.7-0.3-1c-0.3,0.2-0.4,0.5-0.4,0.8C44.6,209.1,44.9,209.2,45.1,209.3z"/>
			<path fill="#EBE2CF" d="M20.4,274.1c-0.4,0.3-0.1,0.8-0.2,1.2C20.6,274.9,20.3,274.5,20.4,274.1z"/>
			<path fill="#8BA6BE" d="M58.4,129.4c-4.5-0.2-9,0.1-13.5,0.3c-1.7,0.1-3.5,0-5.2,0.3c-0.7,0.2-1,0.4-0.9,1.1
				c0.7,3.1,0.7,6.2,1.7,9.2c0.9,2.7,1.8,5.4,3.9,7.4c2,2,4.4,3.1,7.4,3.1c2.3-0.1,4.2-0.6,6-2.1c1.5-1.3,2.1-3,2.6-4.7
				c1-4,0.8-8.1,0.1-12.1c0-0.1-0.1-0.2-0.1-0.4C60.5,129.4,60.5,129.4,58.4,129.4z"/>
			<path fill="#E9ECF0" d="M102.3,119.5c-0.7,0-1.4,0.1-0.6,1c0.3,0.4,0.2,0.8,0.1,1.2c0,0.2,0.1,0.5,0.4,0.5c0.1,0,0.3,0,0.4-0.1
				c0.2-0.2,0.2-0.4,0-0.6c-0.5-0.5,0.2-0.8,0.2-1.1c0-0.4,0.7-0.2,0.5-0.7C103.1,119.5,102.7,119.5,102.3,119.5z"/>
			<path fill="#EFF1F4" d="M103.5,105.6c-0.8-0.6-1.3-0.5-1.9,0.5c0,0.3,0.1,0.6,0.5,0.5c0.3-0.2,0.6-0.9,0.7,0.1
				c0,0.2,0.3,0.2,0.4,0.4c0.6,0.5,0.6,0.5,0.9-0.3c0.3,0.1,0,0.7,0.5,0.6c0.3-0.4,0.3-0.7,0.1-1.1
				C104.4,105.8,103.8,106,103.5,105.6z"/>
			<path fill="#FBFBFC" d="M38.4,189.6c0.5,0.7-0.3,1.6,0.5,2.2c0-0.4,0-0.9,0-1.3c0-0.6,0.3-0.9,0.8-0.6c1.3,0.6,1-1.2,1.9-1.2
				c0-0.2-0.1-0.4-0.4-0.3c-0.3,0.1-0.6,0.3-0.8,0.6c-0.4,0.6-0.7,0.4-1.1-0.1c-0.3-0.1-0.4,0.2-0.6,0.3
				C38.6,189.3,38.5,189.5,38.4,189.6z"/>
			<path fill="#F8F9FA" d="M48,196.1c-0.6,0.3-1,0.8-0.4,1.4c0.7,0.8,1.3,0.2,2,0c-0.2-0.6-1.2-0.4-1.1-1.1
				C48.3,196.2,48.3,195.9,48,196.1z"/>
			<path fill="#CBD5DF" d="M103.9,115.2c-1-0.2-1-0.2-1,0.7c0,0.9,0.1,1.5,1.1,1.9c0.2-0.8-1.3-1.4-0.1-2.1
				C103.9,115.5,104.1,115.4,103.9,115.2z"/>
			<path fill="#F6F7F9" d="M52,197.4c-0.3,0.3-1,0.2-1,0.7c0.1,0.4-0.6,1,0,1.1c0.4,0.1,1-0.2,1.5-0.4c0.2-0.1,0.1-0.3-0.1-0.3
				C51.9,198.3,52.2,197.8,52,197.4z"/>
			<path fill="#F0F2F5" d="M100.3,120.3c-1,0.1-1.5-0.9-2.2-0.3c-0.4,0.3-0.1,0.9,0.1,1C99,121.4,99.5,120.9,100.3,120.3z"/>
			<path fill="#F5F7F8" d="M104.6,111.8c0.2,0.4,0.7,0.6,0.6,1.2c0,0.3,0.2,0.3,0.4,0.3c0.2,0,0.5-0.1,0.5-0.2
				c0.1-0.5-0.2-0.9-0.4-1.4c-0.2-0.4-0.4-0.7-0.8-0.4C104.5,111.3,104.5,111.5,104.6,111.8z"/>
			<path fill="#C4CFDB" d="M104.4,106.1c0.4,0.3,0.2,0.8,0.2,1.2c0.3,0,0.7,0.1,1-0.2c-0.4-0.4,0.3-0.4,0.2-0.7
				c0.1-0.2,0.2-0.3,0.2-0.5c-0.7-0.4-1.4-0.3-2.2-0.2c0,0,0,0,0,0C103.9,106,104,106.2,104.4,106.1z"/>
			<path fill="#E1E6EB" d="M60,208.3c0.5,0.4,1.2,0.3,1.6,0.9c0.2,0.3,0.1-0.7,0.6-0.5c0-0.1,0.1-0.2,0.1-0.3
				c-0.1-0.4-1.8-0.8-2.1-0.5C60.1,208,60.1,208.1,60,208.3z"/>
			<path fill="#EDF0F3" d="M44,192.5c1.1,0.2,1,1,1.2,1.7c0.1,0.2,0,0.4,0.3,0.4c0.3,0,0.5-0.2,0.5-0.4c0-0.6-0.8-1-0.6-1.7
				C45,192.5,44.7,192.1,44,192.5z"/>
			<path fill="#EFF2F4" d="M41.9,187.5c0.6,0.9,1.3,1.2,2.4,0.2c-0.7,0.2-0.7-1.4-1.5-0.1C42.6,187.8,42.3,187.1,41.9,187.5z"/>
			<path fill="#F1F3F6" d="M45.9,186.2c0.4-0.5,0.8-0.8,1.4-0.7c0.2,0,0.4,0.6,0.6,0c0.1-0.3,0.2-0.6,0-0.9
				c-0.3-0.4-0.5,0.1-0.7,0.1C46.4,184.9,45.9,185.2,45.9,186.2z"/>
			<path fill="#FDFDFE" d="M40.3,180.2c0.6,0.1,0.6-0.9,1.3-0.6c0.1,0,0.1-0.3,0-0.4c-0.3-0.5-0.9-0.2-1.3-0.2c-0.2,0-1,0-0.7,0.7
				C39.9,179.9,40.1,180,40.3,180.2z"/>
			<path fill="#FCFCFC" d="M43,196.3c-0.5,0.4-0.8-1-1.2-0.2c-0.3,0.6,0.6,0.7,0.8,1c0.1,0.1,0.3,0.2,0.5,0.4c0.1-0.3,0.2-0.7,0.3-1
				c0.1-0.1,0.1-0.2,0-0.3C43.3,196.2,43.1,196.2,43,196.3z"/>
			<path fill="#F2F4F7" d="M53,186.1c0.5,0.8,0,1.4-0.1,2.1c0.5-0.5,1.1-1,1.2-1.7C54.2,186.3,53.6,186.1,53,186.1z"/>
			<path fill="#E2E7ED" d="M37.9,172.8c-0.2,0.6-1.4,1-0.6,2c0.5-0.5,1.2-0.9,1.1-1.8C38.3,172.9,38.2,172.7,37.9,172.8z"/>
			<path fill="#F0F2F5" d="M99,107.6c0.3,0.1-0.6,0.6,0.2,0.6c0.5,0,0.9-0.2,0.9-0.7c0-0.4-0.2-0.7-0.5-0.6
				C99,106.9,98.8,107.1,99,107.6z"/>
			<path fill="#F2F4F6" d="M43.4,180c-0.3,0.1-0.9,0.2-0.3,0.5c0.6,0.4,0.2,0.6,0.1,0.9c0,0.4,0.2,0.4,0.5,0.2
				c0.6-0.5,0.6-1,0.3-1.7C43.8,179.6,43.8,179.6,43.4,180z"/>
			<path fill="#E0E6EB" d="M44.4,201.6c0.4,0,0.9-0.1,1-0.5c0-0.3-0.7-0.3-0.6-0.8c-0.5,0-0.9,0.1-1.3,0.6
				C44,200.9,44.4,201,44.4,201.6L44.4,201.6z"/>
			<path fill="#EEF0F4" d="M49.6,194.2c0.9,0.4-0.7,1.3,0.3,1.4c0.4,0.1,0.9-0.3,0.9-0.8C50.8,194.2,50.1,194.4,49.6,194.2z"/>
			<path fill="#DDE2E9" d="M102.5,111.8c0.4-0.3,0-0.7,0.1-0.9c0.1-0.3,0.6-0.5,0.2-0.8c-0.4-0.2-0.7,0.2-1,0.5
				c-0.4,0.4,0,0.5,0.2,0.7C102.2,111.5,102.3,111.6,102.5,111.8z"/>
			<path fill="#F4F5F7" d="M104.6,122.2c0.4,0.3,0.5-0.1,0.6-0.3c0.3-0.3,1-0.3,0.6-0.9c-0.5-0.7-0.7,0-1,0.3
				c-0.1,0.1-0.4-0.1-0.5-0.1C104.1,121.6,104.3,121.9,104.6,122.2z"/>
			<path fill="#D5DCE4" d="M50.6,193.4c0.2-0.1,0.4,1.3,0.9,0.3c0.1-0.3,0.5-0.9,0.2-1.5C50.9,192.2,51.4,193.7,50.6,193.4z"/>
			<path fill="#F4F5F7" d="M99.6,98.1c-0.2,0-0.4,0-0.4,0c-0.6-0.6-0.6-0.6-1.5,0.1c0.5,0.2,1.1,0.3,1.6,0.5
				c0.2,0.1,0.5,0.3,0.6,0.1C100,98.6,99.8,98.3,99.6,98.1z"/>
			<path fill="#F1F3F5" d="M38.4,188.2c-0.3,0.3-0.3,0.5,0,0.7c0.1,0.1,0.2,0.1,0.3,0.1c0.3-0.1,0.6-0.3,0.9-0.6
				c0.3-0.1,0.8,0.1,0.7-0.4c-0.1-0.5-0.5-0.2-0.8-0.1C39.1,188,38.8,188.1,38.4,188.2z"/>
			<path fill="#D9DFE7" d="M51.8,174.9c-0.7-0.3-0.6,0.7-1.2,0.7c0.5,0.1,0.7,1,1.3,0.5C52.2,175.9,52.6,175.3,51.8,174.9
				c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.3,0,0.4c0-0.1,0-0.2,0-0.3C51.7,175.1,51.8,175,51.8,174.9z"/>
			<path fill="#EBEEF2" d="M101.8,113.4c0-1.1-0.9-1.1-1.5-1.5c-0.1-0.1-0.3,0.2-0.3,0.4c0,0.2,0.2,0.4,0.4,0.4
				C101.1,112.4,101.4,113,101.8,113.4z"/>
			<path fill="#FDFDFD" d="M45.3,189.8c-0.3,0.6-0.5,1-0.7,1.4c-0.1,0.2-0.2,0.4,0,0.6c0.2,0.2,0.5,0,0.6-0.1
				c0.2-0.1,0.5-0.2,0.1-0.6C45,190.8,45.6,190.4,45.3,189.8z"/>
			<path fill="#EBEEF1" d="M45.1,186.3c-0.1-0.8-0.3-1.4,0.3-2.1C44,184.7,43.9,185.1,45.1,186.3z"/>
			<path fill="#EBEEF2" d="M94.5,101.8c-1.1-0.4-0.5-1.7-1.2-2.2C93.1,100.9,93.3,101.4,94.5,101.8z"/>
			<path fill="#DCE2E9" d="M44.8,167.9c0.3-0.2,1,0.5,1-0.4c0-0.3,0.1-0.9-0.3-0.9C44.9,166.6,45.1,167.4,44.8,167.9z"/>
			<path fill="#DCE2E9" d="M42.7,177.4c0.7-0.4,0.8-0.9,0.5-1.6c-0.1-0.3-0.5-0.2-0.5-0.1c0.4,0.7-0.5,0.9-0.4,1.5
				C42.4,177.3,42.4,177.5,42.7,177.4z"/>
			<path fill="#F0F2F5" d="M49.9,181.4c0.2-0.4,0.3-0.8,0.1-1.4c-0.2,0.7-1.6,0.5-0.8,1.6C49.5,181.9,49.7,181.7,49.9,181.4z"/>
			<path fill="#EDEFF3" d="M42.1,191.6c0,0.7,0.5,0.4,0.8,0.5c0.6,0.4,0.7-0.3,0.7-0.4c0.1-0.6-0.5-0.3-0.8-0.5
				C42.4,190.9,42.3,191.5,42.1,191.6z"/>
			<path fill="#E8EBEF" d="M50.4,205.2c0.1,0.1,0.3,0.2,0.3,0.3c0,0.3-0.6,0.7,0.1,0.9c0.6,0.2,0.5-0.5,0.8-0.8
				c0.2-0.3,0.2-0.4-0.2-0.5C51,204.9,50.7,204.9,50.4,205.2z"/>
			<path fill="#F2F4F6" d="M53.5,181c0.4,0.5,0.6,0.9,0.6,1.5c0,0.2,0.1,0.4,0.3,0.4c0.3,0,0.2-0.3,0.2-0.4
				C54.6,181.7,54.3,181.1,53.5,181z"/>
			<path fill="#E6EAEF" d="M47,171.3c0.2-0.2,0.6-0.5,0.5-0.6c-0.1-0.4-0.5-0.6-0.8-0.7c-0.3-0.1-0.4,0.2-0.4,0.5
				C46.4,170.9,46.7,171,47,171.3z"/>
			<path fill="#C7D2DD" d="M50.4,205.2c0.3,0,0.6,0,1,0c0.1-0.3,0.2-0.6,0.3-0.8C51.3,204.7,50,203.8,50.4,205.2z"/>
			<path fill="#F3F5F7" d="M97.7,98.2c-0.3-0.1-0.5-0.2-0.5,0.2c-0.3,0.2-0.8,0.3-0.3,0.8c0.8-0.1,0.1,0.5,0.3,0.7
				C98.9,99.7,97.4,98.7,97.7,98.2z"/>
			<path fill="#EEF1F4" d="M55.2,204.5c0.1,0,0.1,0.1,0.2,0.1c0.4,0.1,0.2,1.1,0.8,0.7c0.4-0.2,0.3-1-0.3-1.3
				C55.6,204,55.2,203.9,55.2,204.5z"/>
			<path fill="#ECEFF2" d="M40.8,181.9c-0.1-0.4,0.1-0.9-0.4-1c-0.2-0.1-0.6,0.3-0.5,0.5c0.1,0.4,0.2,0.9,0.9,0.8
				C40.9,182.1,40.9,182.1,40.8,181.9C40.9,181.9,40.8,181.9,40.8,181.9z"/>
			<path fill="#E8EBEF" d="M51.2,185.7c-0.2,0-0.5-0.3-0.6,0.1c0,0.3,0.2,0.4,0.5,0.3c0.1,0,0.2,0,0.2,0c0.3,0,0.4,0.7,0.8,0.3
				c0.1-0.1,0.1-0.3,0-0.4C52.1,185.6,51.6,185.8,51.2,185.7z"/>
			<path fill="#FAFBFB" d="M44.4,178.8c-0.5,0.4-0.8-0.1-1.2-0.2l0,0c-0.1,0.2-0.4,0.5,0,0.7c0.4,0.2,0.7,0.6,1.2,0.2
				c0.7,0,0.3-0.4,0.3-0.7C44.6,178.7,44.5,178.7,44.4,178.8z"/>
			<path fill="#C7D1DD" d="M103.2,107c-0.1-0.2-0.3-0.4-0.3-0.5c0.1-0.3,0.3-0.8-0.2-0.9c-0.6-0.1-0.6,0.5-0.7,0.9
				c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.4,0.4C102.7,107,102.9,107,103.2,107z"/>
			<path fill="#F0F2F4" d="M55.7,183c-0.2,0.2-0.1,0.4,0,0.5c0.3,0.3,0.2,1,0.9,0.9c0.1,0,0.3-0.3,0.2-0.3
				C56.5,183.6,56.4,183,55.7,183z"/>
			<path fill="#F6F7F9" d="M45.4,195.6c0.7-0.6,0.5,1.1,1.2,0.5c0.2-0.2,0.2-0.5,0-0.7C46.2,194.9,45.8,195.2,45.4,195.6z"/>
			<path fill="#ECEFF2" d="M92.6,110.8c0.2,0.2-0.4,0.8,0.2,0.7c0.5-0.1,0.1-0.8,0.5-1.2c0.1-0.1-0.1-0.6-0.3-0.5
				C92.5,110,92.5,110.4,92.6,110.8z"/>
			<path fill="#E1E6EB" d="M56.4,186c0-0.8-0.3-1.1-0.8-1.2c-0.4-0.1-0.3,0.3-0.3,0.5C55.3,186.1,56,185.6,56.4,186z"/>
			<path fill="#CAD4DE" d="M48.4,166.8c-0.1,0.5-0.6,1-0.1,1.5c0.1,0.1,0.3,0,0.3,0C49.1,167.7,48.6,167.3,48.4,166.8z"/>
			<path fill="#D7DEE6" d="M104.6,122.2c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-0.2-0.1-0.5-0.2-0.5c-0.5,0-0.2,0.4-0.4,0.7
				c-0.2,0.3-0.3,0.6,0.2,0.7C104.2,122.2,104.4,122.2,104.6,122.2z"/>
			<path fill="#D2DAE3" d="M93.6,116.6c-0.4,0.5-1.1-0.4-1.5,0.4C92.5,116.4,93.5,118.9,93.6,116.6L93.6,116.6z"/>
			<path fill="#DEE4EA" d="M38.9,185.2c0.9-0.5,0.3-1.2,0.6-1.7c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.3,0.3
				C38.9,184.2,38.9,184.6,38.9,185.2z"/>
			<path fill="#F7F7F9" d="M103.9,115.2c0,0.2-0.3,0.4,0,0.5c0.3-0.1,0.7,0.4,0.9-0.1c0.1-0.2,0.1-0.6-0.1-0.8
				C104.4,114.6,104.2,115,103.9,115.2z"/>
			<path fill="#D3DBE4" d="M42.3,170.1c0.8-0.4,0.7-0.9,0.5-1.3c-0.2-0.3-0.7-0.1-0.8,0.3C41.9,169.4,42.8,169.4,42.3,170.1z"/>
			<path fill="#F1F3F5" d="M43.4,190.1c-0.4-0.1-0.5-0.8-1-0.5c-0.5,0.3,0.1,0.8,0.1,1.2C42.8,190.6,43.1,190.3,43.4,190.1
				L43.4,190.1z"/>
			<path fill="#E4E8ED" d="M89.9,108.1c-0.4,0.2-1,0.1-1.2,0.7c0,0.1,0.2,0.3,0.3,0.3C89.5,109,89.8,108.7,89.9,108.1z"/>
			<path fill="#DFE5EA" d="M37.4,169.9c0,0.2,0,0.3,0.1,0.4c0.4,0.3,0.4,0.9,0.9,1c0.1,0,0.2-0.2,0.2-0.2
				C38.3,170.6,38.1,170.1,37.4,169.9z"/>
			<path fill="#D7DEE6" d="M50.7,171.8c0.6,0.3,1.1,0.6,1.9,0.5C51.9,171.7,51.5,171.3,50.7,171.8z"/>
			<path fill="#F9FAFB" d="M56,188.5c-0.2,0-0.5,0.1-0.4,0.3c0.1,0.4,0.4,0.6,0.8,0.6c0.2,0,0.3-0.2,0.3-0.4
				C56.6,188.6,56.2,188.6,56,188.5z"/>
			<path fill="#F1F3F5" d="M39.3,177.3c0.5,0.3,0.5,0.9,1.1,0.7c0.2-0.1,0.2-0.3,0.2-0.5C40.3,176.9,39.9,177.1,39.3,177.3z"/>
			<path fill="#D8DFE7" d="M43,196.3c0.2,0,0.3,0,0.5,0c0.1,0.1,0.2,0.1,0.2,0c-0.2-0.4-0.3-0.7,0.2-1c0.2-0.1,0.6-0.3,0.3-0.6
				c-0.3-0.3-0.4,0.2-0.5,0.3C43,195.3,43.2,195.9,43,196.3z"/>
			<path fill="#F3F5F7" d="M101,125.3c0-0.2,0.1-0.4,0.3-0.6c0.1-0.1,0.6,0,0.4-0.4c-0.1-0.2-0.3-0.3-0.4-0.4
				c-0.2-0.1-0.3,0.2-0.4,0.3c-0.1,0.3-0.2,0.7-0.3,1C100.7,125.4,100.9,125.4,101,125.3z"/>
			<path fill="#D2DAE3" d="M48,182.4c-0.4,0.1-0.9,0.6-1.4,0c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.2-0.1,0.4,0.1,0.5
				c0.3,0.2,0.7,0.1,1,0.1C48.1,183.2,48.2,182.9,48,182.4z"/>
			<path fill="#E0E5EB" d="M103.2,109.2c-0.3,0.4,0,0.7,0.3,0.9c0.1,0.1,0.4,0,0.4-0.2c0.1-0.5,0.3-1-0.4-1.2c0,0-0.1,0.1-0.1,0.1
				C103.3,109,103.4,109.2,103.2,109.2z"/>
			<path fill="#F1F3F6" d="M55,177c-0.1,0.6,0,1,0.5,1.2c0.2,0.1,0.3,0,0.4-0.2C56,177.4,55.4,177.4,55,177z"/>
			<path fill="#DCE2E9" d="M50.4,184.1c0,0.3-0.3,0.6-0.1,1.1c0.5-0.1,0.8-0.5,0.9-0.9C51.2,183.9,50.7,184.2,50.4,184.1L50.4,184.1
				z"/>
			<path fill="#E5E9EE" d="M43.7,196.3c-0.1,0-0.2,0-0.2,0c0,0.1,0,0.2,0,0.3c0.5,0.1,1,0.6,1.6-0.1C44.6,196.2,44.2,196,43.7,196.3
				z"/>
			<path fill="#E6EAEF" d="M41,193.3c0.6,0.1,1,0.3,1.2-0.2c0.1-0.2,0.1-0.5-0.2-0.5C41.6,192.6,41.4,192.9,41,193.3z"/>
			<path fill="#D0D8E2" d="M97.2,99.8c0.1-0.3,0.8-0.8-0.2-0.7c-0.2,0-0.3-0.2-0.5-0.2C96.1,99.6,96.3,100,97.2,99.8z"/>
			<path fill="#CFD8E1" d="M41.8,174.5c0.2-0.2,0.2-0.5,0.2-0.7l0,0c-0.1-0.2-0.9,0.5-0.6-0.5c0.1-0.4-0.5-0.1-0.6,0
				c-0.1,0.3,0,0.6,0,0.9C41.1,174.3,41.5,174.4,41.8,174.5z"/>
			<path fill="#E0E5EB" d="M42.2,199c0.4-0.4-0.1-0.7-0.1-1.1c0-0.4-0.4-0.1-0.4,0C41.4,198.5,41.5,198.9,42.2,199L42.2,199z"/>
			<path fill="#F1F3F6" d="M45.7,183.3c-0.3-0.5-0.5-0.8-1-0.5c-0.2,0.1-0.2,0.3-0.1,0.4C44.9,183.8,45.3,183.1,45.7,183.3z"/>
			<path fill="#EAEDF1" d="M42.7,177.4c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0.6,0.4,1.1,1,1.4c0,0,0,0,0,0C43.7,177.9,43,177.7,42.7,177.4
				z"/>
			<path fill="#DCE2E9" d="M82,108.3c0.1,0.3,0.4,0.4,0.7,0.4c0.3,0,0.5-0.2,0.4-0.4c-0.1-0.3-0.4-0.4-0.6-0.3
				C82.2,108,82,108,82,108.3z"/>
			<path fill="#ECEFF2" d="M44.8,172.4c0,0.4,0,0.7,0.1,0.8c0.4,0.3-0.2,1.1,0.4,1c0.4-0.1,0-0.8,0-1.3
				C45.3,172.7,45.1,172.6,44.8,172.4z"/>
			<path fill="#F8F9FA" d="M97.9,104.4c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.3,0.2-0.7-0.3-0.9c-0.1-0.1-0.4-0.1-0.4,0.1
				c0,0.4-0.1,0.8,0.3,1C97.6,104.5,97.8,104.5,97.9,104.4z"/>
			<path fill="#DCE2E8" d="M84.5,102.7c0.4,0.3,0.5,0.8,1,0.7c0.2,0,0.2-0.2,0.1-0.4C85.3,102.5,85.1,102.4,84.5,102.7z"/>
			<path fill="#EDF0F3" d="M87.5,100.8c0.3,0,0.5-0.1,0.5-0.4c0.1-0.3-0.2-0.4-0.4-0.4c-0.3,0-0.6,0-0.6,0.4
				C87,100.6,87.2,100.7,87.5,100.8z"/>
			<path fill="#C7D2DD" d="M52.9,169.5c-0.4-0.3-0.2-1-0.7-1.1c-0.2,0-0.3,0.2-0.3,0.3C52,169.2,52.4,169.5,52.9,169.5z"/>
			<path fill="#DFE4EA" d="M98.6,111.1c-0.2-0.6,0.4-0.5,0.7-0.7c-0.3-0.2-0.7-0.6-0.9-0.3C98.3,110.3,97.7,110.9,98.6,111.1
				L98.6,111.1z"/>
			<path fill="#F6F7F9" d="M45.1,197.4c0,0.3,0,0.5,0.3,0.4c0.3-0.1,0.5-0.2,0.6-0.5c0.1-0.3-0.1-0.4-0.4-0.4
				C45.3,196.9,45.2,197.2,45.1,197.4z"/>
			<path fill="#EEF1F4" d="M35.3,160.5c-0.3,0.4-0.6,0.8-0.3,1.3c0,0.1,0.3,0.1,0.3,0C35.7,161.4,35.5,160.9,35.3,160.5z"/>
			<path fill="#EDF0F3" d="M59.8,189c-0.9,0.2-1.1,0.7-1,1.2c0.1,0.5,0.5,0.3,0.5,0C59.4,189.8,59.4,189.5,59.8,189z"/>
			<path fill="#C7D2DD" d="M96.1,118.2c-0.5-0.1-0.9-0.1-1.4-0.2C94.9,118.6,94.9,118.6,96.1,118.2z"/>
			<path fill="#CBD5DF" d="M66.5,201.3c0.6-0.2,1-0.3,1.1-0.9c0-0.1-0.2-0.2-0.2-0.2C66.7,200.2,66.7,200.7,66.5,201.3z"/>
			<path fill="#F0F2F5" d="M47.3,200.8c0.1,0.3,0.3,0.3,0.5,0.3c0.3,0,0.4-0.2,0.4-0.5c0-0.2-0.1-0.4-0.4-0.3
				C47.6,200.3,47.3,200.4,47.3,200.8z"/>
			<path fill="#DAE0E7" d="M100.8,116.2c-0.2,0.1-0.5,0.1-0.9,0c0.1,0.6,0.7,0.8,0.5,1.4c0.5-0.3,0.3-0.6,0.4-0.9
				C100.9,116.5,100.9,116.3,100.8,116.2z"/>
			<path fill="#F6F7F9" d="M36.8,179.2c0.6,0,1,0,1.2-0.4c0-0.1-0.1-0.4-0.1-0.4C37.5,178.4,37.2,178.7,36.8,179.2z"/>
			<path fill="#F4F5F8" d="M47.5,188.2c-0.2,0-0.4,0-0.5,0.1c-0.2,0.1-0.2,0.4-0.3,0.6c0,0,0.1,0.2,0.2,0.2c0.3,0,0.5-0.1,0.6-0.4
				C47.5,188.5,47.5,188.3,47.5,188.2L47.5,188.2z"/>
			<path fill="#E0E5EB" d="M103.5,108.7c-0.2-0.2-0.2-0.8-0.7-0.5c-0.2,0.1-0.4,0.4-0.3,0.6c0.1,0.4,0.5,0.2,0.7,0.3
				C103.3,109,103.4,108.8,103.5,108.7L103.5,108.7z"/>
			<path fill="#F9FAFB" d="M57.9,167.5c0.1,0.4,0.5,0.3,0.8,0.4c0.2,0,0.3-0.1,0.3-0.3c-0.1-0.3-0.3-0.5-0.7-0.5
				C58,167.1,57.9,167.2,57.9,167.5z"/>
			<path fill="#D9DFE7" d="M43.7,181.7c-0.2,0-0.4,0-0.5-0.2c-0.2-0.1-0.3-0.6-0.6-0.2c-0.1,0.1-0.2,0.3-0.2,0.4
				c0.1,0.3,0.4,0.3,0.6,0.3C43.2,181.9,43.5,182,43.7,181.7z"/>
			<path fill="#D8DFE7" d="M62.8,200.1c0.3-0.3,1,0.3,1.2-0.4c0-0.1-0.1-0.2-0.1-0.3C63.5,199.7,62.8,199.2,62.8,200.1z"/>
			<path fill="#E3E7EC" d="M44.4,178.8c0.1,0,0.2,0,0.3,0c0.1-0.6-0.3-1.1-0.6-1.6C43.7,177.9,44.3,178.3,44.4,178.8z"/>
			<path fill="#EBEEF2" d="M52.5,177.9c-0.6-0.1-1-0.3-1.3,0.2c-0.1,0.1,0.1,0.5,0.2,0.5C52,178.7,52,178.1,52.5,177.9z"/>
			<path fill="#F5F6F8" d="M44,202.6c0.1,0.3,0.2,0.4,0.5,0.4c0.3,0,0.4-0.2,0.5-0.4c0.1-0.3-0.3-0.4-0.5-0.4
				C44.3,202.2,44,202.3,44,202.6z"/>
			<path fill="#FCFCFD" d="M103.9,124.1c0.5,0.6,0.6,0.6,1.2-0.5c-0.3-0.2-0.4,0.2-0.7,0.3C104.3,124,104,123.9,103.9,124.1z"/>
			<path fill="#F7F8FA" d="M40.9,166.9c-0.3,0.1-0.4,0.3-0.3,0.5c0,0.2,0.1,0.4,0.4,0.4c0.2,0,0.5-0.1,0.4-0.4
				C41.3,167.2,41.2,167,40.9,166.9z"/>
			<path fill="#DFE4EA" d="M45.4,176.9c-0.1,1.3-0.1,1.3,0.7,1c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.2l0,0
				C45.8,176.9,45.6,176.9,45.4,176.9L45.4,176.9z"/>
			<path fill="#F6F7F9" d="M96.7,98.2c-0.2-0.4-0.8-0.1-1-0.5c-0.2-0.1-0.5-0.2-0.5,0.2c0,0.5,0.3,0.5,0.7,0.5
				C96.2,98.2,96.5,98.4,96.7,98.2z"/>
			<path fill="#D0D8E2" d="M46.4,206.2c-0.5,0.2-0.9-0.2-1.1,0.2c-0.1,0.1,0.1,0.4,0.2,0.5C45.9,207.1,46.1,206.8,46.4,206.2z"/>
			<path fill="#DCE2E9" d="M49.9,181.4c-0.2,0.1-0.5,0.2-0.7,0.3c0.3,0.3-0.2,0.8,0.2,1.1C49.8,182.4,49.9,182,49.9,181.4z"/>
			<path fill="#EAEDF1" d="M51,191.1c0.3-0.1,0.4-0.2,0.5-0.5c0.1-0.3-0.1-0.3-0.3-0.3c-0.3,0-0.5,0.1-0.5,0.4
				C50.7,190.9,50.8,191.1,51,191.1z"/>
			<path fill="#CDD6E0" d="M102,106.6c-0.2-0.2-0.3-0.3-0.5-0.5c-1,0-1,0-0.7,0.7c0.4-0.4,0.7,0.1,1,0.2c0.1,0,0.2,0,0.2,0
				C102.1,106.9,102.1,106.7,102,106.6L102,106.6z"/>
			<path fill="#D8DEE6" d="M61.6,191.9c-0.3,0.4-0.5,0.7-0.2,1.2c0,0.1,0.3,0.1,0.3,0C62,192.7,61.6,192.4,61.6,191.9z"/>
			<path fill="#D4DBE4" d="M92,114.2c0.1,0.4,0.3,0.7,0.4,1.1c0.1,0,0.2-0.1,0.3-0.1c0.2-0.5-0.4-0.6-0.4-1.1
				C92.2,114.1,92.1,114.2,92,114.2z"/>
			<path fill="#F7F7F9" d="M96.8,109c0.1,0.6,0.1,0.9,0.6,0.9c0.2,0,0.3-0.2,0.3-0.3C97.5,109.2,97.2,109.2,96.8,109z"/>
			<path fill="#CCD5E0" d="M94.7,107c0.5,0.3,0.7,0.7,1.1,0.6c0.1,0,0.2-0.4,0.1-0.5C95.7,106.8,95.3,107,94.7,107z"/>
			<path fill="#D3DBE4" d="M97.4,106.9c0.3,0,0.4,0.7,0.9,0.4c0.1-0.1,0.1-0.3,0.1-0.4C98.1,106.3,97.8,106.9,97.4,106.9z"/>
			<path fill="#FEFEFE" d="M105.8,106.3c-0.7,0.5-0.7,0.5-0.2,0.7c0.2,0.1,0.2,0.3,0.2,0.5C106.6,107.1,105.9,106.7,105.8,106.3z"/>
			<path fill="#E0E5EB" d="M93.2,103.7c0,0.6,0,1,0.4,1c0.1,0,0.3-0.1,0.3-0.1C94,104.1,93.4,104.2,93.2,103.7z"/>
			<path fill="#E8ECF0" d="M98.1,103c0.1-0.3,0.2-0.7-0.2-0.9c-0.1,0-0.3,0.1-0.3,0.2C97.4,102.7,97.7,102.9,98.1,103z"/>
			<path fill="#FCFCFC" d="M96.3,102.2c0,0.2,0.1,0.4,0.3,0.4c0.3,0,0.4-0.4,0.4-0.6c0-0.2-0.1-0.3-0.2-0.3
				C96.6,101.7,96.4,101.9,96.3,102.2z"/>
			<path fill="#E5E9EF" d="M89.5,100.6c1-0.4-0.1-0.7,0.1-1.1C88.9,99.9,89.4,100.2,89.5,100.6L89.5,100.6z"/>
			<path fill="#C7D2DD" d="M51.1,187.4c-0.1-0.6-0.5-0.9-1.1-1.1c0,0.6,0.7,0.6,0.6,1.1C50.8,187.6,51,187.6,51.1,187.4z"/>
			<path fill="#D6DDE5" d="M59.4,186c0.6-0.2,1-0.3,1-0.8c0-0.1-0.2-0.2-0.3-0.2C59.5,185,59.8,185.5,59.4,186z"/>
			<path fill="#DEE3EA" d="M47.9,180.5c-0.3,0-0.3,0.4-0.3,0.5c0,0.3,0.4,0.2,0.5,0.2c0.2,0.1,0.4-0.1,0.3-0.3
				C48.3,180.7,48.1,180.6,47.9,180.5z"/>
			<path fill="#D6DDE5" d="M41.8,180.1c0,0-0.1,0.2-0.1,0.3c-0.1,0.4,0.3,0.4,0.5,0.5c0.2,0.1,0.3-0.1,0.2-0.3
				C42.3,180.4,42.3,180.1,41.8,180.1z"/>
			<path fill="#F2F4F6" d="M41.8,174.5c-0.2-0.7-0.6-0.4-1-0.2C41.1,174.6,41.3,175.2,41.8,174.5z"/>
			<path fill="#E8ECF0" d="M48,196.1c0.2,0.1,0.3,0.2,0.5,0.3c0-0.5,0-0.9,0.5-1.4C48.1,195.1,48,195.5,48,196.1z"/>
			<path fill="#D4DBE4" d="M58,172.2c0-0.5-0.1-0.9-0.4-1c-0.1,0-0.3,0.1-0.3,0.1C57.2,171.8,57.6,172,58,172.2z"/>
			<path fill="#F8F9FA" d="M36,169c0.4,0.2,0.7,0.4,1,0c0,0,0-0.2-0.1-0.3C36.6,168.3,36.4,168.7,36,169z"/>
			<path fill="#D9E0E7" d="M60.8,200.3c0,0.2,0.2,0.3,0.3,0.4c0.2,0.1,0.4,0,0.4-0.2c0.1-0.3-0.1-0.4-0.4-0.5
				C61,200,60.9,200.1,60.8,200.3z"/>
			<path fill="#D1D9E3" d="M55.2,203.2c0.3-0.1,0.6-0.1,0.6-0.4c0-0.3-0.3-0.2-0.5-0.2c-0.2,0-0.4-0.1-0.4,0.2
				C54.9,203,55,203.2,55.2,203.2z"/>
			<path fill="#F3F4F7" d="M56.5,162.4c0,0.3,0.2,0.3,0.4,0.4c0.3,0.1,0.4-0.2,0.4-0.4c0-0.2-0.1-0.3-0.2-0.3
				C56.8,162,56.6,162.1,56.5,162.4z"/>
			<path fill="#D9E0E7" d="M101,125.3c-0.2,0-0.3,0-0.5,0c0,0.3-0.1,0.7-0.1,1.1C101.5,126.4,100.8,125.6,101,125.3z"/>
			<path fill="#D1D9E2" d="M90.7,103.7c-0.4-0.2-0.7-0.4-1.2-0.3C89.9,103.8,90.2,104.4,90.7,103.7L90.7,103.7z"/>
			<path fill="#CFD8E2" d="M96,112.6c-0.3-0.6-0.9-0.5-1.8-0.8C94.9,112.5,95.5,112.4,96,112.6L96,112.6z"/>
			<path fill="#DCE2E9" d="M50.4,184.1c-0.1-1-0.1-1-0.9-0.8C49.8,183.6,50.1,183.8,50.4,184.1C50.4,184.1,50.4,184.1,50.4,184.1z"
				/>
			<path fill="#EDEFF3" d="M97.2,100.9c0.5,0.1,0.8-0.1,1.2-0.5C97.8,100.3,97.5,100.3,97.2,100.9z"/>
			<path fill="#ECEEF2" d="M45.4,179.7c0,0.3,0.2,0.3,0.4,0.4c0.3,0.1,0.3-0.2,0.4-0.3c0.1-0.2-0.1-0.4-0.3-0.4
				C45.6,179.3,45.5,179.5,45.4,179.7z"/>
			<path fill="#D5DCE4" d="M50,203.1c-0.7,0.2-0.6,0.6-0.7,1c0.1,0,0.2,0.1,0.3,0C50.2,203.9,49.8,203.5,50,203.1z"/>
			<path fill="#EFF1F4" d="M106.6,124.5c-0.3-0.2-0.1-0.8-0.6-0.8c-0.1,0-0.2,0.2-0.2,0.3C105.9,124.3,106.1,124.5,106.6,124.5z"/>
			<path fill="#E0E5EB" d="M91.5,102.8c0,0.2,0.1,0.5,0.4,0.5c0.2,0,0.2-0.2,0.2-0.3c-0.1-0.2,0.1-0.5-0.3-0.5
				C91.6,102.5,91.5,102.7,91.5,102.8z"/>
			<path fill="#D9DFE7" d="M99.5,123.5c0.1-0.4-0.1-0.7-0.5-0.8c-0.1,0-0.2,0.2-0.2,0.2C98.9,123.1,99.1,123.5,99.5,123.5z"/>
			<path fill="#E9ECF0" d="M98.3,117.1c0.3,0.2,0.7,0.5,1,0.7C99.2,117.2,98.9,116.9,98.3,117.1z"/>
			<path fill="#E3E7EC" d="M54.3,172.4c0.3,0.6,0.4,0.9,0.6,1.2C55.1,173,55.1,173,54.3,172.4z"/>
			<path fill="#ECEFF2" d="M60.1,170.7c0.2,0,0.3-0.2,0.4-0.4c0-0.2,0-0.4-0.3-0.5c-0.3-0.1-0.3,0.2-0.4,0.4
				C59.8,170.5,59.9,170.6,60.1,170.7z"/>
			<path fill="#D5DCE5" d="M50.2,173.4c-0.4,0.2-0.8,0.3-0.8,0.8c0,0.1,0.2,0.2,0.3,0.2C50.1,174.1,50,173.7,50.2,173.4z"/>
			<path fill="#F5F7F8" d="M49.9,208.7c0.5,0.1,0.9,0.3,1.3,0.1c0,0,0-0.2,0-0.2C50.9,208.4,50.5,208.2,49.9,208.7z"/>
			<path fill="#F0F2F4" d="M57.7,208.4c0.1,0.2,0.1,0.5,0.4,0.5c0.1,0,0.3-0.2,0.3-0.3c0.1-0.3-0.2-0.4-0.4-0.4
				C57.8,208.1,57.7,208.2,57.7,208.4z"/>
			<path fill="#E2E6EC" d="M96.7,98.2c-0.3,0-0.5,0-0.7,0.2c0.3,0,0.5,0.2,0.5,0.5c0.1,0.1,0.1,0.5,0.5,0.2
				c-0.3-0.4,0.1-0.5,0.2-0.7C97,98.4,96.8,98.3,96.7,98.2z"/>
			<path fill="#D2DAE3" d="M93.6,116.6c0.6-0.1,0.3-0.8,0.6-1C93.5,115.7,93.6,116.2,93.6,116.6C93.6,116.6,93.6,116.6,93.6,116.6z"
				/>
			<path fill="#F7F8F9" d="M41.6,186.3c-0.3-0.2-0.6-0.1-0.9,0.1c0,0.1,0,0.3,0.1,0.3C41.2,186.9,41.5,186.7,41.6,186.3z"/>
			<path fill="#D9E0E7" d="M47.5,188.2c0.4-0.3,0.9-0.3,1.3-1C48.1,187.4,47.6,187.6,47.5,188.2C47.5,188.2,47.5,188.2,47.5,188.2z"
				/>
			<path fill="#F7F9FA" d="M45.6,187.7c0,0.3,0.2,0.5,0.5,0.4c0.2-0.1,0.2-0.3,0.2-0.5c0-0.4-0.3-0.3-0.5-0.2
				C45.8,187.5,45.6,187.5,45.6,187.7z"/>
			<path fill="#DEE4EA" d="M54.7,191.3c0.2,0.3,0.3,0.6,0.7,0.8C55.5,191.5,55.4,191.2,54.7,191.3z"/>
			<path fill="#EDF0F3" d="M40.6,192.4c0.3-0.3,0.4-0.7,0.2-1c0,0-0.2,0-0.2,0.1C40.1,191.7,40.5,192.1,40.6,192.4z"/>
			<path fill="#CCD5DF" d="M56,193.4c0.5-0.5,0.4-0.9,0.3-1.5C56,192.5,56,192.8,56,193.4z"/>
			<path fill="#F0F2F5" d="M57.1,193.5c-0.1,0.3-0.2,0.5-0.4,0.8c0.1,0,0.2,0.1,0.3,0.1C57.5,194.2,57.3,193.9,57.1,193.5z"/>
			<path fill="#F1F3F5" d="M37.9,172.8c0.2,0.1,0.3,0.1,0.5,0.2c0-0.3-0.1-0.5-0.3-0.6c-0.2-0.1-0.4-0.4-0.6-0.1
				C37.4,172.5,37.7,172.7,37.9,172.8z"/>
			<path fill="#E7EAEF" d="M53.2,171.6c-0.1,0.2,0,0.4,0.2,0.4c0.3,0.1,0.3-0.1,0.3-0.3c0-0.2,0-0.3-0.2-0.4
				C53.3,171.2,53.3,171.5,53.2,171.6z"/>
			<path fill="#C9D3DE" d="M56.9,173.2c-0.6,0.6-0.7,1-0.5,1.6C56.6,174.3,56.7,173.9,56.9,173.2z"/>
			<path fill="#EAEDF1" d="M62.4,169.2c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-0.2-0.4,0-0.5,0c-0.3,0.1-0.4,0.3-0.1,0.5
				C62.1,169.3,62.2,169.3,62.4,169.2z"/>
			<path fill="#DBE1E8" d="M55.1,174.5c-0.3-0.1-0.6-0.4-0.8-0.1c0,0.1,0,0.3,0,0.3C54.7,175.1,54.8,174.6,55.1,174.5z"/>
			<path fill="#EFF1F4" d="M44,165.8c0.1,0.1,0.2,0.4,0.3,0.4c0.3,0,0.3-0.3,0.3-0.5c0-0.1,0-0.4-0.2-0.3
				C44.3,165.5,43.9,165.3,44,165.8z"/>
			<path fill="#E3E7ED" d="M49.3,175.3c-0.8,0-1.1,0.2-1.2,0.8C48.6,176,48.8,175.8,49.3,175.3z"/>
			<path fill="#E2E7EC" d="M56.3,176c-0.2,0-0.3,0.1-0.3,0.3c0.1,0.2,0.2,0.4,0.5,0.4c0,0,0.1-0.1,0.2-0.2
				C56.6,176.2,56.6,176,56.3,176z"/>
			<path fill="#D0D9E2" d="M43,159.6c-0.1,0.3-0.6,0.3-0.4,0.8c0.1,0.2,0.3,0.2,0.4,0C43.2,160.1,43.3,159.9,43,159.6L43,159.6z"/>
			<path fill="#F5F7F8" d="M46.8,178.7c0.2,0.5,0.7,0.3,0.9,0.5C47.6,178.7,47.4,178.5,46.8,178.7z"/>
			<path fill="#CAD4DF" d="M104.4,135c0,0.2,0.2,0.3,0.3,0.2c0.2-0.1,0.4-0.1,0.4-0.3c0-0.1-0.2-0.2-0.4-0.2
				C104.6,134.7,104.4,134.8,104.4,135z"/>
			<path fill="#D0D8E2" d="M107.7,132c0.2,0,0.3-0.1,0.3-0.3c0-0.2,0-0.4-0.3-0.5c-0.2,0-0.3,0-0.4,0.2
				C107.3,131.8,107.6,131.9,107.7,132z"/>
			<path fill="#F7F8F9" d="M42.4,184.5c0.2-0.1,0.3-0.2,0.3-0.4c0-0.2-0.2-0.4-0.4-0.3c-0.2,0.1-0.3,0.3-0.2,0.5
				C42.1,184.4,42.2,184.5,42.4,184.5z"/>
			<path fill="#D4DCE4" d="M48,184c0.4,0.3,0.4,0.7,0.8,0.8c0,0,0.2-0.2,0.2-0.3C48.8,184.2,48.5,184.1,48,184z"/>
			<path fill="#D5DCE5" d="M106.9,119.6c-0.4,0.5,0.2,0.9-0.1,1.3C107.1,120.5,107.3,120.1,106.9,119.6z"/>
			<path fill="#F1F3F6" d="M97,118.9c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.4-0.3c0.1-0.2,0.1-0.3-0.1-0.3
				C97.3,118.6,97.2,118.7,97,118.9z"/>
			<path fill="#DFE4EA" d="M91.6,118.3c-0.2,0-0.4,0.1-0.4,0.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.4-0.2,0.5-0.4
				C92,118.3,91.8,118.4,91.6,118.3z"/>
			<path fill="#DFE4EB" d="M55.2,204.5c0.2-0.2,0.5-0.3,0.7-0.5c-0.3-0.2-0.7-0.3-1-0.5c-0.2,0.2-0.2,0.5,0,0.7
				C55,204.3,55.1,204.4,55.2,204.5z"/>
			<path fill="#E2E7ED" d="M53.8,205.1c0,0.3,0.2,0.5,0.4,0.5c0.1,0,0.2-0.2,0.2-0.2c0-0.2-0.1-0.5-0.4-0.5
				C53.8,204.8,53.8,205,53.8,205.1z"/>
			<path fill="#E5E9EE" d="M101.2,104.7c-0.3,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.2,0.3,0.2c0.2,0,0.4-0.1,0.3-0.3
				C101.4,104.9,101.2,104.8,101.2,104.7z"/>
			<path fill="#D6DDE5" d="M89.5,100.6c-0.2,0.2-0.7,0.1-0.6,0.6c0,0.1,0.3,0.1,0.4,0.1C89.9,101.1,89.6,100.8,89.5,100.6
				C89.5,100.6,89.5,100.6,89.5,100.6z"/>
			<path fill="#CED7E1" d="M58.3,206.7c-0.1-0.3,0.5-0.4,0.2-0.8c-0.1-0.1-0.3-0.1-0.4,0c-0.4,0.3-0.2,0.5-0.1,0.8
				C58.1,206.7,58.2,206.7,58.3,206.7z"/>
			<path fill="#D9DFE7" d="M44.1,199c-0.3-0.1-0.5-0.4-0.8-0.1c-0.1,0.1-0.1,0.3,0.1,0.4C43.7,199.5,43.8,199.2,44.1,199z"/>
			<path fill="#EFF1F4" d="M58.3,206.7c-0.1,0-0.2,0-0.2,0c-0.2,0.2-0.4,0.4-0.2,0.7c0.1,0.1,0.3,0.1,0.4,0
				C58.7,207.1,58.4,206.9,58.3,206.7z"/>
			<path fill="#E3E8ED" d="M88.7,94.1c0.1,0.2,0.2,0.2,0.4,0.2c0.2-0.1,0.4-0.2,0.4-0.5c-0.1-0.2-0.3-0.1-0.4,0
				C88.9,93.8,88.6,93.9,88.7,94.1z"/>
			<path fill="#EFF1F4" d="M40.8,200.9c0.3-0.1,0.7,0.2,0.8-0.2c0.1-0.2-0.1-0.5-0.4-0.5C40.7,200.2,41,200.7,40.8,200.9
				C40.8,200.9,40.8,200.9,40.8,200.9z"/>
			<path fill="#E5E9EE" d="M94.3,97.1c-0.2,0.1-0.2,0.2-0.2,0.4c0.1,0.2,0.2,0.4,0.5,0.4c0.2,0,0.1-0.2,0.1-0.4
				C94.6,97.3,94.5,97.1,94.3,97.1z"/>
			<path fill="#EAEDF1" d="M92.3,103.8c-0.3,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.2,0.3,0.2c0.2,0,0.4-0.1,0.3-0.3
				C92.5,103.9,92.3,103.8,92.3,103.8z"/>
			<path fill="#E4E8ED" d="M100.2,103.1c0.3,0.1,0.4,0.6,0.7,0.3c0.1-0.1,0.1-0.3,0-0.3C100.7,102.9,100.4,102.9,100.2,103.1z"/>
			<path fill="#E7EBEF" d="M44.4,179.5c-0.4-0.1-0.8-0.2-1.2-0.2c0.1,0.2,0.2,0.5,0.2,0.7c0.2,0,0.4-0.2,0.5,0
				C44.1,179.9,44.3,179.7,44.4,179.5z"/>
			<path fill="#EAEDF1" d="M100.2,123.3c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.2-0.3c0-0.2-0.2-0.3-0.3-0.3
				C100.2,122.9,100.2,123,100.2,123.3z"/>
			<path fill="#E5E9EE" d="M40.3,180.2c0-0.6-0.2-0.8-0.7-0.5C39.6,180.3,40,180.2,40.3,180.2z"/>
			<path fill="#EEF0F3" d="M48,182.4c0,0.3-0.2,0.6-0.5,0.7c0,0.3,0.2,0.4,0.5,0.2C48.3,183.1,48.4,182.7,48,182.4z"/>
			<path fill="#E4E8ED" d="M55.3,180.5c0,0.3,0,0.4,0.2,0.4c0.2,0,0.3-0.1,0.3-0.3c0-0.2,0-0.4-0.2-0.3
				C55.5,180.3,55.4,180.5,55.3,180.5z"/>
			<path fill="#EEF0F3" d="M60,178.5c0.1,0.2,0.2,0.3,0.3,0.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2-0.2-0.2-0.3-0.2
				C60.1,178.2,60,178.3,60,178.5z"/>
			<path fill="#D0D9E2" d="M43,159.6c0-0.3,0.6-0.1,0.4-0.5c-0.1-0.1-0.2-0.4-0.4-0.3C42.6,159,42.6,159.3,43,159.6L43,159.6z"/>
			<path fill="#DEE3EA" d="M49.2,162.2c0-0.4,0.1-0.7-0.5-0.9C48.6,161.7,49,161.9,49.2,162.2z"/>
			<path fill="#F5F6F8" d="M46.1,177.1c-0.5,0.2-0.5,0.5,0,0.7C46.6,177.6,46.6,177.4,46.1,177.1z"/>
			<path fill="#D5DCE4" d="M62.4,169.2c-0.2,0-0.3,0-0.5,0c0,0.4,0,0.7,0.5,0.9C62.8,169.8,62,169.5,62.4,169.2z"/>
			<path fill="#D1D9E2" d="M53,189.4c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.2-0.2,0.3-0.4
				C53.2,189.6,53.2,189.5,53,189.4z"/>
			<path fill="#D3DBE4" d="M39.6,188.4c-0.4,0-0.7,0.2-1,0.5c0,0.2,0.1,0.4,0.2,0.5c0.1-0.2,0.3-0.3,0.4-0.5
				C39.5,188.8,39.6,188.6,39.6,188.4z"/>
			<path fill="#CED7E1" d="M92.9,106.2c0,0.2,0.1,0.4,0.4,0.5c0.1,0,0.2-0.1,0.2-0.2c0-0.2-0.2-0.4-0.4-0.5
				C92.9,106,92.9,106.1,92.9,106.2z"/>
			<path fill="#E5E9EE" d="M85.2,105.8c-0.1,0.4-0.2,0.6,0,0.9c0,0,0.2-0.1,0.3-0.1C85.8,106.2,85.5,106.1,85.2,105.8z"/>
			<path fill="#D6DEE6" d="M104.6,111.8c0-0.2,0-0.4,0.2-0.5c-0.1-0.3-0.2-0.6-0.5-0.2C104.2,111.3,104,111.8,104.6,111.8z"/>
			<path fill="#D1D9E2" d="M66.1,198.6c0.2,0,0.3-0.1,0.2-0.2c-0.1-0.2-0.2-0.4-0.5-0.4c-0.1,0-0.1,0.1-0.2,0.2
				C65.8,198.4,65.9,198.5,66.1,198.6z"/>
			<path fill="#DDE2E9" d="M81.3,105.8c0.2,0,0.3-0.2,0.3-0.4c0-0.1,0-0.4-0.2-0.3c-0.1,0-0.3,0.2-0.4,0.4
				C81,105.6,81.1,105.7,81.3,105.8z"/>
			<path fill="#E2E6EC" d="M48.8,185.8c0.1,0.2,0.2,0.3,0.4,0.3c0.2,0,0.2-0.2,0.2-0.4c0-0.1,0-0.4-0.2-0.3
				C49,185.5,48.9,185.7,48.8,185.8z"/>
			<path fill="#D4DBE4" d="M102.1,117c-0.3,0-0.3,0.2-0.3,0.4c0,0.1,0.1,0.3,0.2,0.2c0.2,0,0.4-0.2,0.4-0.4
				C102.4,117.1,102.2,117,102.1,117z"/>
			<path fill="#DEE3EA" d="M107.9,118.9c0.2-0.5,0.4-0.8,0.5-1.1C108,118,107.7,118.2,107.9,118.9z"/>
			<path fill="#E7EAEF" d="M108,121.4c0.1-0.3,0.2-0.5,0.1-0.8c0,0-0.3,0-0.3,0C107.5,121,107.7,121.2,108,121.4z"/>
			<path fill="#E5E9EE" d="M59.4,192.9c0,0,0.2-0.1,0.3-0.2c0-0.3-0.2-0.3-0.4-0.3c-0.1,0-0.3,0-0.3,0.2
				C58.9,192.9,59.1,192.9,59.4,192.9z"/>
			<path fill="#E2E7EC" d="M43.9,193.5c-0.1-0.3-0.1-0.6-0.5-0.7c-0.1,0-0.2,0.2-0.2,0.2C43.2,193.4,43.5,193.5,43.9,193.5z"/>
			<path fill="#D3DBE4" d="M42.2,199c0,0.2-0.5,0.3-0.2,0.5c0.2,0.2,0.5,0.1,0.6-0.1C42.8,199,42.5,199,42.2,199
				C42.2,199,42.2,199,42.2,199z"/>
			<path fill="#E0E5EB" d="M43.1,184.7c0.1,0.1,0.2,0.2,0.4,0.2c0.3,0,0.3-0.2,0.2-0.4c0-0.2-0.2-0.2-0.3-0.2
				C43.2,184.3,43,184.4,43.1,184.7z"/>
			<path fill="#D3DBE4" d="M57.3,175.7c-0.1,0.5,0,0.8,0.2,0.8c0.4,0,0-0.3,0-0.5C57.6,175.9,57.4,175.8,57.3,175.7z"/>
			<path fill="#D2D9E2" d="M77.3,191c-0.2-0.1-0.3-0.1-0.4-0.1c-0.2,0.1-0.4,0.2-0.4,0.5c0.1,0,0.2,0,0.3,0
				C76.9,191.3,77.1,191.2,77.3,191z"/>
			<path fill="#CFD8E2" d="M71.5,206.8c0.4-0.1,0.7-0.3,0.6-0.6c-0.2-0.4-0.3,0-0.4,0.1C71.6,206.4,71.6,206.5,71.5,206.8z"/>
			<path fill="#D8DFE6" d="M72.1,191.4c0,0.2,0.1,0.4,0.2,0.4c0.3,0,0.2-0.3,0.2-0.4c0-0.1,0-0.3-0.1-0.3
				C72.2,191.1,72.1,191.3,72.1,191.4z"/>
			<path fill="#D9DFE7" d="M52.5,196.9c0.1-0.4-0.2-0.5-0.4-0.6c-0.1-0.1-0.2,0.1-0.2,0.2C51.9,196.8,52.1,196.9,52.5,196.9z"/>
			<path fill="#E6EAEF" d="M47,200.1c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.2
				C46.8,199.9,46.8,200,47,200.1z"/>
			<path fill="#D2DAE3" d="M53.4,183.4c-0.4-0.3-0.6-0.5-0.9-0.3c0,0-0.1,0.2-0.1,0.2C52.7,183.5,52.9,183.4,53.4,183.4z"/>
			<path fill="#D1D9E2" d="M46.1,199c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.2-0.1,0.2-0.2
				C46.3,199.2,46.3,199.1,46.1,199z"/>
			<path fill="#CFD8E1" d="M68.5,200.1c0.1-0.3,0.2-0.6,0.4-1.1C68.3,199.3,68.4,199.7,68.5,200.1z"/>
			<path fill="#DEE3EA" d="M56.5,181.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.2
				C56.6,181,56.5,181.1,56.5,181.3z"/>
			<path fill="#EFF1F4" d="M68.4,202.1c-0.5,0.2-0.7,0.4-0.7,0.7c0.2,0.2,0.3,0,0.4-0.2C68.3,202.6,68.3,202.4,68.4,202.1z"/>
			<path fill="#E1E6EC" d="M70.4,202.5c0,0.2,0.2,0.2,0.3,0.2c0.1,0,0.3,0,0.4-0.1c0-0.2-0.1-0.2-0.3-0.3
				C70.6,202.3,70.5,202.4,70.4,202.5z"/>
			<path fill="#D2DAE3" d="M39.1,180c-0.1-0.2,0-0.4-0.2-0.3c-0.1,0-0.2,0.2-0.2,0.4c0,0.1,0,0.3,0.2,0.3
				C39,180.3,39.1,180.1,39.1,180z"/>
			<path fill="#D9E0E7" d="M38.9,187c0.4,0.2,0.7,0.1,1-0.2l0,0C39.5,186.9,39,186.1,38.9,187L38.9,187z"/>
			<path fill="#FDFDFD" d="M42.6,204.3c-0.4-0.1-0.6-0.2-1,0.1C42,204.8,42.3,204.6,42.6,204.3z"/>
			<path fill="#F9FAFA" d="M51.1,187.4c-0.2,0-0.3,0-0.5,0c0,0.4,0.3,0.6,0.6,0.5C51.5,187.9,51.3,187.6,51.1,187.4z"/>
			<path fill="#E1E6EC" d="M45.6,187.7c0.1-0.1,0.2-0.2,0.3-0.2c-0.2,0-0.3,0-0.5,0c-0.3,0-0.5,0.1-0.5,0.4c0,0.1,0.1,0.2,0.1,0.2
				C45.2,187.9,45.4,187.8,45.6,187.7z"/>
			<path fill="#D6DDE5" d="M46.7,205.2c0.1,0.1,0.3,0.2,0.4,0.1c0.1-0.1,0.2-0.3,0.2-0.4c-0.1-0.2-0.2-0.1-0.4,0
				C46.9,205,46.6,205,46.7,205.2z"/>
			<path fill="#CBD5DF" d="M37.4,176.4c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.2,0.1-0.2,0.2
				C37.1,176.4,37.2,176.4,37.4,176.4z"/>
			<path fill="#D4DBE4" d="M53.3,163.9c0.2,0.1,0.3,0.2,0.3,0.1c0.1-0.1,0.3-0.2,0.3-0.3c0-0.2-0.1-0.3-0.3-0.2
				C53.5,163.6,53.4,163.8,53.3,163.9z"/>
			<path fill="#F7F8F9" d="M101.8,107c-0.3-0.2-0.4-1.1-1-0.2C101.1,106.9,101.4,107,101.8,107z"/>
			<path fill="#E4E8ED" d="M40,162.6c0.2-0.1,0.3-0.2,0.2-0.3c0-0.2-0.2-0.2-0.4-0.2c-0.1,0-0.2,0.2-0.2,0.2
				C39.7,162.4,39.8,162.6,40,162.6z"/>
			<path fill="#CFD8E2" d="M96,112.6c0.5,0.5,0.8,0.1,1.1-0.3C96.7,112.4,96.4,112.5,96,112.6L96,112.6z"/>
			<path fill="#DAE0E7" d="M39.5,160.6c0.6,0.1,0.8,0,1-0.3C40.1,160.2,39.9,160.3,39.5,160.6z"/>
			<path fill="#EAEDF1" d="M103.2,128.3c-0.1,0.1-0.1,0.2-0.2,0.4c0,0.2,0,0.3,0.2,0.3c0.2,0,0.2-0.2,0.2-0.3
				C103.4,128.5,103.5,128.4,103.2,128.3z"/>
			<path fill="#D4DBE4" d="M89.6,111.9c-0.6,0-0.8,0.2-0.9,0.5C89.1,112.5,89.3,112.3,89.6,111.9z"/>
			<path fill="#CED7E1" d="M108.4,127.4c0.3,0,0.4-0.2,0.4-0.4c0-0.1,0-0.3-0.2-0.2c-0.2,0.1-0.3,0.2-0.4,0.3
				C108.2,127.2,108.3,127.3,108.4,127.4z"/>
			<path fill="#DDE3E9" d="M54.3,171.5c0,0,0.1,0.2,0.2,0.2c0.2-0.1,0.4-0.2,0.5-0.5c0-0.1-0.1-0.2-0.2-0.1
				C54.5,171.1,54.3,171.2,54.3,171.5z"/>
			<path fill="#CED7E1" d="M95.2,115.7c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.3-0.1,0.4-0.2c0.1-0.2-0.1-0.3-0.3-0.3
				C95.4,115.5,95.3,115.6,95.2,115.7z"/>
			<path fill="#E9ECF1" d="M106.9,115.8c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0,0.3,0,0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.3
				C107.1,115.6,107,115.7,106.9,115.8z"/>
			<path fill="#F5F6F8" d="M95.6,102.1c-0.4,0.4-0.1,0.7-0.2,1.3C95.8,102.8,95.8,102.5,95.6,102.1z"/>
			<path fill="#E1E6EC" d="M49.6,165c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2-0.1-0.3-0.3-0.2c-0.1,0.1-0.2,0.2-0.3,0.3
				C49.3,165,49.4,165.1,49.6,165z"/>
			<path fill="#DDE3E9" d="M62.5,189.6c0.1-0.4,0.2-0.7,0-1C62.3,189,62.4,189.3,62.5,189.6z"/>
			<path fill="#C6D1DC" d="M101,101c-0.1-0.2-0.1-0.3-0.2-0.5c-0.3,0-0.5,0.1-0.7,0.2c0.1,0.3,0.4,0.3,0.7,0.5
				C100.9,101.3,101,101.2,101,101z"/>
			<path fill="#D9E0E7" d="M37.9,186.9c0,0.3-0.1,0.5,0.2,0.7c0-0.5,0.6-0.4,0.7-0.7c0,0,0,0,0,0C38.6,187,38.3,187,37.9,186.9z"/>
			<path fill="#E4E8ED" d="M86.5,111.3c0,0.3,0,0.7,0.3,0.6c0.4-0.1,0.1-0.3,0-0.5C86.7,111.3,86.6,111.3,86.5,111.3z"/>
			<path fill="#CFD7E1" d="M56.3,191.3c0.1,0,0.2,0.1,0.2,0.1c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1-0.1-0.2-0.2-0.1
				C56.4,191,56.3,191.1,56.3,191.3z"/>
			<path fill="#DAE0E7" d="M105.8,114.3c0.1,0.1,0.1,0.3,0.2,0.3c0.2,0.1,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2
				C106,114.1,105.9,114.2,105.8,114.3z"/>
			<path fill="#E2E7EC" d="M53.3,190.7c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.2-0.1,0.3-0.1c0-0.2-0.1-0.3-0.3-0.3
				C53.5,190.5,53.4,190.5,53.3,190.7z"/>
			<path fill="#E3E8ED" d="M94.6,113.8c-0.3,0-0.6-0.1-1-0.2C93.9,114.1,94.2,114,94.6,113.8z"/>
			<path fill="#DAE0E8" d="M57.3,204.2c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3,0,0.3-0.2c0-0.1-0.1-0.2-0.2-0.3
				C57.4,203.8,57.4,204.1,57.3,204.2z"/>
			<path fill="#CED7E0" d="M48,190.8c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.1,0.2-0.1,0.3
				C47.8,190.6,47.9,190.6,48,190.8z"/>
			<path fill="#D5DDE5" d="M70.5,203.6c0.1,0.1,0.1,0.2,0.3,0.3c0.1,0.1,0.2-0.1,0.2-0.2c0-0.1-0.2-0.3-0.3-0.3
				C70.5,203.3,70.5,203.4,70.5,203.6z"/>
			<path fill="#CFD8E2" d="M41.1,203c0,0.2,0,0.4,0.2,0.5c0.1-0.2,0.4-0.4,0.4-0.5C41.5,202.7,41.3,203,41.1,203z"/>
			<path fill="#E4E9EE" d="M87.1,114.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.3-0.3-0.2
				C87.2,114.1,87.2,114.3,87.1,114.4z"/>
			<path fill="#DAE0E7" d="M60.2,203.9c-0.1-0.4-0.3-0.6-0.6-0.8C59.6,203.6,59.8,203.7,60.2,203.9z"/>
			<path fill="#E5E9EE" d="M101,101c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0.2,0.3,0.7,0.8,0.2c-0.1-0.1-0.2-0.2-0.2-0.2
				C101.2,101.2,101.1,101.1,101,101z"/>
			<path fill="#D8DFE7" d="M64.2,200c-0.1,0.2-0.1,0.3-0.1,0.5c0,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.2,0.2-0.3
				C64.4,200.2,64.3,200.1,64.2,200z"/>
			<path fill="#F8F8FA" d="M101.7,104c0,0.2,0.2,0.2,0.3,0.2c0.1,0,0.3-0.1,0.3-0.1c0-0.2-0.1-0.3-0.3-0.3
				C101.9,103.7,101.7,103.8,101.7,104z"/>
			<path fill="#EBEEF2" d="M71,199.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3,0-0.3,0.1c-0.1,0.2,0.1,0.3,0.2,0.3
				C70.8,199.9,70.9,199.8,71,199.7z"/>
			<path fill="#D8DFE6" d="M87.5,104.1c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.2-0.2,0.2-0.3c0-0.1-0.2-0.2-0.2-0.2
				C87.7,103.8,87.7,104,87.5,104.1z"/>
			<path fill="#D5DCE5" d="M97.9,104.4c-0.2,0-0.3,0-0.5,0c0,0.3,0.2,0.6,0.5,0.6C98.3,104.8,97.9,104.6,97.9,104.4z"/>
			<path fill="#EBEEF2" d="M93.4,103.1c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.2-0.2,0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.2
				C93.5,102.8,93.4,102.9,93.4,103.1z"/>
			<path fill="#D1D9E3" d="M104.4,106.1c-0.2-0.1-0.4-0.3-0.5-0.5c-0.2,0-0.3,0-0.5,0C103.7,105.9,103.8,106.5,104.4,106.1z"/>
			<path fill="#D0D8E2" d="M65.8,201.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.2,0.2-0.2,0.3c0,0.1,0.1,0.1,0.2,0.2
				C65.6,201.4,65.7,201.3,65.8,201.1z"/>
			<path fill="#E9ECF0" d="M102,106.6c0,0.2,0,0.3,0,0.5c0.2,0.2,0.3,0.2,0.5,0C102.5,106.7,102.3,106.6,102,106.6z"/>
			<path fill="#CAD3DF" d="M58.7,196c-0.2,0-0.3,0.1-0.3,0.2c0,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.2,0,0.2-0.2
				C58.8,196.2,58.7,196.1,58.7,196z"/>
			<path fill="#D7DEE6" d="M66.2,196.2c-0.4-0.5-0.7-0.2-1.1-0.4C65.5,196.2,65.8,196.1,66.2,196.2z"/>
			<path fill="#F7F7F9" d="M63.4,196.1c0.2,0,0.4,0,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.3,0.2
				C63.2,195.9,63.3,196,63.4,196.1z"/>
			<path fill="#CFD8E1" d="M67.8,201.1c-0.2,0-0.4,0.1-0.4,0.3c0,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.3-0.3
				C68,201.2,67.8,201.2,67.8,201.1z"/>
			<path fill="#D9E0E7" d="M48,201.8c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.3-0.3-0.2
				C48.1,201.5,48.1,201.7,48,201.8z"/>
			<path fill="#DDE3E9" d="M42.1,193.9c-0.2,0-0.4,0-0.4,0.2c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3-0.1,0.3-0.2
				C42.3,194,42.1,194,42.1,193.9z"/>
			<path fill="#DEE3EA" d="M99.9,109.1c-0.2-0.2-0.4-0.4-0.6-0.3c-0.3,0.2,0.1,0.3,0.2,0.3C99.7,109.2,99.8,109.1,99.9,109.1z"/>
			<path fill="#E1E6EC" d="M45.9,192.5c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.1-0.3-0.1
				C46.1,192.3,46,192.4,45.9,192.5z"/>
			<path fill="#E2E7EC" d="M105.2,109.7c0.1-0.1,0.3-0.1,0.3-0.2c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.2
				C105,109.5,105.1,109.6,105.2,109.7z"/>
			<path fill="#E9EDF1" d="M99.5,101.4c-0.2,0-0.3,0.1-0.3,0.2c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.2-0.2,0.2-0.2
				C99.7,101.5,99.5,101.4,99.5,101.4z"/>
			<path fill="#C9D3DE" d="M57.1,174.7c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.3-0.1-0.3c-0.2-0.1-0.3,0.1-0.3,0.2
				C56.9,174.4,57,174.5,57.1,174.7z"/>
			<path fill="#E9ECF0" d="M59.7,181.4c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.3-0.1-0.3c-0.2-0.1-0.3,0.1-0.3,0.2
				C59.6,181.2,59.7,181.3,59.7,181.4z"/>
			<path fill="#F6F7F9" d="M102.6,127.2c0,0.2,0,0.3,0.1,0.3c0.1,0,0.3-0.1,0.4-0.2c0-0.1-0.1-0.3-0.2-0.4
				C102.7,127,102.6,127.1,102.6,127.2z"/>
			<path fill="#F9FAFB" d="M101.7,127.7c-0.1,0.2-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.3,0.3c0,0,0.1-0.2,0.1-0.3
				C101.9,128,101.8,127.9,101.7,127.7z"/>
			<path fill="#F1F3F5" d="M88.8,95.5c0.2,0.1,0.3,0.2,0.3,0.1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1-0.1-0.2-0.2-0.1
				C89,95.2,88.9,95.3,88.8,95.5z"/>
			<path fill="#E5E9EE" d="M40.7,175.6c0,0.2,0.1,0.3,0.2,0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.2-0.2-0.2
				C40.9,175.5,40.8,175.6,40.7,175.6z"/>
			<path fill="#ECEFF2" d="M99.2,134.6c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0,0.3,0,0.3-0.1c0-0.2-0.2-0.3-0.4-0.3
				C99.4,134.4,99.4,134.5,99.2,134.6z"/>
			<path fill="#DFE4EA" d="M45.4,176.9c0.1-0.2,0.3-0.4-0.1-0.6c-0.1,0-0.2,0.1-0.3,0.2C44.9,176.8,45.1,176.9,45.4,176.9
				C45.4,176.9,45.4,176.9,45.4,176.9z"/>
			<path fill="#E7EAEF" d="M46.1,176.9c0.2-0.1,0.4-0.2,0.3-0.5c-0.1-0.1-0.2-0.2-0.4-0.1C45.8,176.5,46,176.7,46.1,176.9
				C46.1,176.9,46.1,176.9,46.1,176.9z"/>
			<path fill="#DFE4EA" d="M40.5,164.5c0.1,0.3,0.2,0.4,0.2,0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3-0.1-0.3
				C40.7,164.3,40.6,164.5,40.5,164.5z"/>
			<path fill="#E4E8ED" d="M61.5,187.1c0.2,0,0.3-0.1,0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.1-0.2,0.2-0.2,0.2
				C61.3,186.9,61.5,187,61.5,187.1z"/>
			<path fill="#F2F3F6" d="M100.8,116.2c0,0.2,0,0.3,0,0.5c0.3-0.1,0.6-0.1,0.8-0.5C101.3,116.2,101.1,116.2,100.8,116.2z"/>
			<path fill="#F0F2F4" d="M39.8,186.7c0.3-0.3,0.3-0.7-0.1-1.2C39.8,186,39.8,186.4,39.8,186.7C39.8,186.7,39.8,186.7,39.8,186.7z"
				/>
			<path fill="#D7DDE6" d="M45,204.7c0-0.3-0.2-0.6-0.5-0.5c-0.4,0.2-0.1,0.3,0.1,0.4C44.7,204.6,44.8,204.6,45,204.7z"/>
			<path fill="#F4F5F7" d="M70.9,205c0.1,0.1,0.2,0.2,0.4,0.1c0.1,0,0.1-0.2,0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1
				C71,204.8,70.8,204.8,70.9,205z"/>
			<path fill="#D3DBE3" d="M106.2,118.7c-0.2,0.1-0.3,0.2-0.2,0.4c0,0.1,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.2-0.3
				C106.4,118.8,106.2,118.7,106.2,118.7z"/>
			<path fill="#D7DEE6" d="M62,205.3c0.1,0.1,0.1,0.3,0.2,0.3c0.2,0,0.2-0.2,0.2-0.3c0-0.1-0.1-0.2-0.1-0.3
				C62.2,205,62.1,205.2,62,205.3z"/>
			<path fill="#E3E8ED" d="M90.7,94.6c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3-0.2,0.2-0.3c-0.1-0.1-0.3-0.1-0.4-0.1
				C90.7,94.3,90.6,94.4,90.7,94.6z"/>
			<path fill="#CDD6E0" d="M91.5,99.8c0.3,0.2,0.5,0.2,0.6,0.3c0.1,0.1,0.2,0,0.2-0.1c0-0.2-0.1-0.3-0.3-0.3
				C92,99.6,91.9,99.7,91.5,99.8z"/>
			<path fill="#ECEEF2" d="M51.4,182.9c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.2-0.2,0.3c0,0.1,0.2,0.2,0.2,0.2
				C51.2,183.2,51.3,183.1,51.4,182.9z"/>
			<path fill="#E4E9EE" d="M103.9,124.1c0.2,0,0.4,0,0.5-0.2c-0.2,0-0.2-0.5-0.5-0.3C103.6,123.7,103.7,123.9,103.9,124.1z"/>
			<path fill="#D1D9E2" d="M43.4,182.6c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.2-0.2,0.2-0.3c0-0.1-0.1-0.1-0.2-0.2
				C43.6,182.3,43.5,182.4,43.4,182.6z"/>
			<path fill="#D7DEE6" d="M40.8,181.9c0,0.1,0,0.2,0,0.3c0.2-0.1,0.5,0.2,0.6,0c0.1-0.1-0.1-0.4-0.2-0.5
				C41,181.5,41,181.9,40.8,181.9z"/>
			<path fill="#E8ECF0" d="M48.8,173.7c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.4,0.2,0.2
				C48.6,174,48.7,173.8,48.8,173.7z"/>
			<path fill="#D0D9E2" d="M51.6,200.6c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1,0.1-0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2
				C51.4,200.8,51.4,200.7,51.6,200.6z"/>
			<path fill="#F9F9FA" d="M84.8,91.6c-0.1,0.4,0,0.6,0.1,0.6c0.4,0.1,0.2-0.2,0.1-0.3C85,91.8,84.9,91.7,84.8,91.6z"/>
			<path fill="#E2E6EC" d="M90.7,103.7c0.2,0,0.4,0.3,0.6,0.1c0,0,0-0.2,0-0.2C91,103.4,90.9,103.5,90.7,103.7
				C90.7,103.7,90.7,103.7,90.7,103.7z"/>
			<path fill="#E3E8ED" d="M90.4,96c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0.2-0.1,0.3c0,0.1,0.1,0.2,0.2,0.2
				C90.3,96.2,90.4,96.1,90.4,96z"/>
			<path fill="#E0E6EB" d="M44.4,201.6c-0.2,0-0.4-0.3-0.6-0.1c0,0,0,0.2,0,0.2C44.1,201.9,44.2,201.8,44.4,201.6
				C44.4,201.6,44.4,201.6,44.4,201.6z"/>
			<path fill="#D8DFE6" d="M53.7,208.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
				C53.4,208.3,53.5,208.3,53.7,208.3z"/>
			<path fill="#F5F7F8" d="M58.3,202.8c0.2-0.1,0.5-0.2,0.3-0.5c-0.3-0.3-0.4,0-0.6,0.2C58.1,202.7,58.2,202.7,58.3,202.8z"/>
			<path fill="#D1D9E2" d="M54.5,207.1c-0.2-0.1-0.2,0-0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0,0.2-0.2
				C54.6,207.2,54.5,207.1,54.5,207.1z"/>
			<path fill="#D8DFE7" d="M53.9,202.3c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.1
				C54,202.1,54,202.2,53.9,202.3z"/>
			<path fill="#E3E7ED" d="M43.1,202.3c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1-0.1,0.2-0.2
				C43.3,202.5,43.2,202.4,43.1,202.3z"/>
			<path fill="#D6DDE6" d="M58.3,202.8c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0-0.5,0.1-0.3,0.3C57.9,203.1,58.1,202.8,58.3,202.8z"/>
			<path fill="#D9DFE6" d="M67,203.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1,0,0.2,0.1,0.2c0.1,0,0.2-0.1,0.3-0.1
				C67.1,203.3,67,203.2,67,203.1z"/>
			<path fill="#D5DCE4" d="M94.9,100.4c0.1,0.1,0.2,0.3,0.3,0.2c0.1,0,0.2-0.2,0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
				C94.9,100.2,94.8,100.3,94.9,100.4z"/>
			<path fill="#FBFCFD" d="M55,204.3c0-0.2,0-0.5,0-0.7c-0.2,0-0.4,0-0.4,0.3C54.5,204,54.8,204.2,55,204.3z"/>
			<path fill="#F3F4F6" d="M100.1,100.8c0.3,0,0.5,0.1,0.7-0.2C100.4,100.1,100.3,100.5,100.1,100.8z"/>
			<path fill="#D2DAE3" d="M97.3,120.8c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0.1,0.2,0.2,0.1c0.1-0.1,0.2-0.2,0.3-0.4
				C97.5,120.9,97.4,120.8,97.3,120.8z"/>
			<path fill="#E8ECF0" d="M107.7,122.3c-0.1,0.1-0.2,0.2-0.3,0.4c0,0.2,0.1,0.2,0.2,0.1c0.1-0.1,0.2-0.2,0.3-0.4
				C107.9,122.4,107.8,122.4,107.7,122.3z"/>
			<path fill="#D7DEE5" d="M51.1,180.7c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.1,0.2-0.2
				C51.3,181,51.2,180.9,51.1,180.7z"/>
			<path fill="#E7EBEF" d="M104.9,129.7c0.1-0.1,0.1-0.2,0.1-0.2c-0.1-0.1-0.2-0.3-0.3-0.3c-0.1-0.1-0.2,0.1-0.1,0.2
				C104.7,129.5,104.8,129.6,104.9,129.7z"/>
			<path fill="#D8DEE6" d="M101.8,132.9c0.1,0.1,0.1,0.2,0.2,0.2c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.1-0.2
				C102,132.7,101.9,132.8,101.8,132.9z"/>
			<path fill="#D3DAE3" d="M106.1,136.3c-0.1-0.4-0.4-0.4-0.6-0.4C105.6,136.3,105.9,136.3,106.1,136.3z"/>
			<path fill="#EAEDF1" d="M103.7,136.8c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0,0.2-0.1c0-0.1-0.1-0.2-0.1-0.3
				C103.9,136.6,103.8,136.7,103.7,136.8z"/>
			<path fill="#E3E7ED" d="M47.4,164c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.1,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2
				C47.6,163.8,47.5,163.9,47.4,164z"/>
			<path fill="#DEE3EA" d="M50.9,168.2c-0.1-0.2-0.3-0.5-0.6-0.6C50.2,168.2,50.6,168.2,50.9,168.2L50.9,168.2z"/>
			<path fill="#C7D2DD" d="M50.9,168.2c0,0.2,0,0.5,0.3,0.5c0,0,0.1-0.1,0.1-0.1C51.3,168.3,51.1,168.3,50.9,168.2
				C50.9,168.2,50.9,168.2,50.9,168.2z"/>
			<path fill="#CFD8E1" d="M42,173.8c0.2,0.1,0.4,0.2,0.6,0.3c0-0.1,0-0.2,0-0.3C42.4,173.6,42.2,173.5,42,173.8
				C42,173.8,42,173.8,42,173.8z"/>
			<path fill="#D5DDE5" d="M40.4,171c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.2-0.2
				C40.6,170.8,40.5,170.9,40.4,171z"/>
			<path fill="#E9ECF0" d="M98.4,104.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C98.6,104.2,98.6,104.3,98.4,104.4z"/>
			<path fill="#D3DBE4" d="M61.5,198.3c0.1,0,0.2,0.1,0.2,0.1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1-0.1-0.2-0.2-0.1
				C61.7,198,61.6,198.2,61.5,198.3z"/>
			<path fill="#E9EDF0" d="M40.3,197.5c-0.1,0.2-0.4,0.5,0,0.7C40.6,198,40.6,197.8,40.3,197.5z"/>
			<path fill="#DBE1E8" d="M95.8,105.4c0.2,0.1,0.3,0.1,0.3,0.1c0.1,0,0.2-0.1,0.2-0.2c-0.1,0-0.2-0.1-0.2-0.1
				C96,105.2,95.9,105.3,95.8,105.4z"/>
			<path fill="#D4DCE4" d="M60.8,197.5c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.2-0.1
				C60.9,197.3,60.8,197.4,60.8,197.5z"/>
			<path fill="#F5F6F7" d="M90.4,106.8c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
				C90.6,107,90.5,106.9,90.4,106.8z"/>
			<path fill="#F9FAFB" d="M103.4,108.7c-0.1,0.1-0.2,0.2-0.3,0.2c0,0.1,0,0.1,0,0.2c0.1,0.1,0.2,0.3,0.4,0.2
				C103.8,109.1,103.5,108.9,103.4,108.7z"/>
			<path fill="#E7EBEF" d="M53.7,191.8c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.1-0.2
				C53.9,191.7,53.8,191.7,53.7,191.8z"/>
			<path fill="#E0E5EA" d="M98.6,111.1c0.1,0.2,0.3,0.3,0.6,0.1c0,0,0.1-0.2,0-0.2C99,110.8,98.8,111.1,98.6,111.1
				C98.6,111.1,98.6,111.1,98.6,111.1z"/>
			<path fill="#D5DCE5" d="M96.5,111.2c0,0.2,0,0.3,0.2,0.2c0.1,0,0.2-0.2,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C96.6,111,96.6,111.1,96.5,111.2z"/>
			<path fill="#D3DBE3" d="M46.3,190.4c0.2,0,0.3,0.1,0.3,0.1c0.1,0,0.1-0.2,0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1
				C46.5,190.1,46.5,190.2,46.3,190.4z"/>
			<path fill="#DEE4EA" d="M62.2,190.4c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2
				C62.4,190.2,62.4,190.3,62.2,190.4z"/>
			<path fill="#F1F3F5" d="M43.4,190.1c0.2,0,0.5,0.1,0.6-0.2c0,0-0.1-0.2-0.1-0.2C43.7,189.6,43.5,189.9,43.4,190.1
				C43.4,190.1,43.4,190.1,43.4,190.1z"/>
			<path fill="#E1E6EC" d="M96.7,113.5c-0.1,0.2,0,0.2,0.2,0.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0-0.2-0.2-0.2
				C96.8,113.4,96.7,113.5,96.7,113.5z"/>
			<path fill="#DAE0E7" d="M53.4,188.8c0,0.2,0.1,0.3,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2-0.2-0.2
				C53.5,188.6,53.4,188.7,53.4,188.8z"/>
			<path fill="#E2E7EC" d="M95.4,113.9c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
				C95.6,113.8,95.5,113.9,95.4,113.9z"/>
			<path fill="#FBFBFC" d="M57.4,186.8c-0.1,0.3-0.2,0.3-0.2,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.2-0.1,0.2-0.2
				C57.5,187.2,57.5,187.1,57.4,186.8z"/>
			<path fill="#DDB7A8" d="M74.4,37.4c-0.2-0.4-0.3-0.8-0.5-1.2c-0.4,0.4-0.5-0.3-0.7-0.2c-0.9,0.3-1.5,1-2.4,1.2
				c-0.4,0.1-0.1,1-0.9,0.8c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.3,0.1,0.6-0.2,0.8c-0.1,0.2-0.2,0.4-0.3,0.6
				c0,0.2,0,0.3,0.1,0.5c0.9,0.6,0.9,1,0.1,1.4c-0.1,0-0.2,0-0.3,0.1c-0.8,0.3-0.2,0.9-0.1,1.2c0.2,0.4,0.7,0.6,1.2,0.3
				c0.3-0.2,0.5-0.5,0.9-0.9c0,0.7,0,1.3,0.6,1.5c0.6,0.3,1.3,0.4,1.8,0c0.6-0.5,1.3-0.3,2-0.4c0.1,0,0.2-0.1,0.3-0.2
				c0-0.4-0.3-0.6-0.6-0.7c0,0-0.1,0-0.1-0.1c0,0.1,0.1,0.1,0.2,0.2c0.7-0.2,0.7-0.8,0.9-1.3c0.1-0.3,0.2-0.6,0.3-0.9
				c-0.6-1-1.2-2-1.9-2.9c-0.3,0.1-0.6-0.2-0.9,0c-0.1,0-0.1,0.4-0.3,0.1c-0.1-0.1,0.1-0.3,0.2-0.4c0-0.2,0.1-0.2,0.2-0.1
				C73.9,37.5,74.1,37.6,74.4,37.4z"/>
			<path fill="#E0BEAF" d="M75.6,43.4c-0.8-0.1-1.5-0.3-2.3,0.2c-1.4,0.9-2.1,0.2-2.1-2.1c-0.5,0.6-0.9,1-1.2,1.4
				c-0.4,0.6-0.7,0.2-1,0c-0.3-0.2-0.6-0.6-0.1-1c-0.2-0.3-0.5-0.5-0.9-0.1c-0.1,0.2-0.2,0.4-0.2,0.7c0,0,0,0.1,0,0.1s-0.1,0-0.1,0
				c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.7,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1,0,0.4-0.2,0.5c0,0.1,0.1,0.2,0.1,0.3
				c0.8-0.4,1.2,0.1,1.6,0.7c0.2,0.1,0.1,0.4,0.3,0.6c0.6,0.1,1.1,0.2,1.5,0.7c0.2,0,0.2-0.3,0.5-0.2c-0.1,0.1-0.3,0-0.4,0.2
				c-0.1,0.7,0.4,1,1,1.1c0.7,0,0.3,0.9,0.9,1c0.3-0.3-0.1-0.9,0.3-1.2c0.2-0.1,0.3-0.4,0.4-0.5c0.5-0.1,1.1,0.2,1.5-0.4
				c0.1-0.1,0-0.3,0.1-0.2c0.1,0.1,0,0.5,0.3,0.5c0.3,0,0.5-0.3,0.5-0.5C74.8,44.4,75.8,44.2,75.6,43.4z"/>
			<path fill="#E0BFB0" d="M69.8,38.2c0.4,0,0.6,0.3,0.9,0.4c0.4-0.3,0.1-0.5,0-0.7c-0.1-0.3,0-0.6,0.2-0.5c1.1,0.2,1.5-0.8,2.2-1.3
				c-0.5,0-1,0.1-1.5,0.1c-0.9,0.1-1.8,0.4-2.7-0.3c-0.2,0.4,0.3,0.8,0,1.2c-0.6-0.5-1.1-0.4-1.6,0.2c0,0,0,0.1,0.1,0.1
				c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0,0.3,0,0.4c0.1,1-0.8,0.2-1.1,0.7c0.7,0.5,0.7,1.2,0.4,1.9c-0.1,0.1-0.1,0.3,0,0.5
				c0.7,0.2,1.5,0.3,2.2,0.7c0.3,0.1,0.7,0.4,0.8,0.1c0.2-0.5,0.1-1.1,0-1.6c-0.1-0.6-0.6,0.2-0.9-0.1c-0.3-0.1-0.2-0.3,0-0.5
				c0.2-0.1,0.2-0.3,0.2-0.5c0.2-0.3-0.1-0.7,0.2-1l0,0c0.1-0.2,0.1-0.3,0-0.5C69.8,37.9,69.7,38.1,69.8,38.2z"/>
			<path fill="#E4CCBB" d="M75.6,43.4c-0.8,0.5-1,1.2-1.1,2.2c-0.4-0.4-0.1-1-0.6-1.1c-0.1,0-0.4-0.1-0.4,0.1c0,0.3,0.3,0.5,0.4,0.7
				c-0.5,0.4-1.1,0.4-1.7,0.2C72,45.8,72,46.1,72,46.3c0.8,0.5,2,0.1,2.7,1.1c0,0,0.3-0.2,0.5-0.3c0.2-0.2,0.1-0.4-0.1-0.5
				c-0.2-0.2-0.7-0.1-0.5-0.7c0.4,0.2,0.7,0.4,1.1,0.6c0-0.6,0.4-1,0.7-1.4l0,0c0.2-0.2,0.4-0.4,0.5-0.7c0-0.6,0.7-0.5,0.9-0.9
				C77.5,43,77,43.3,76.8,43C76.2,42.5,75.9,43,75.6,43.4L75.6,43.4z"/>
			<path fill="#E6D0BF" d="M69.8,45.4c-0.3-0.9-0.9-0.9-1.7-0.7c-0.3,0.2-0.5,0.4-0.4,0.8c0.1,0.3,0.4,0.2,0.6,0.2
				c1-0.3,0.7,0.3,0.5,0.7c0.3,0.2,0.8,0.4,0.5,1c1,0.9,1.2-0.1,1.4-0.7C70.4,46.2,70,45.9,69.8,45.4L69.8,45.4z"/>
			<path fill="#E4D2C1" d="M76.3,40.6c-0.5,0-0.5,0.4-0.5,0.7c0.4,0.6,0.9,1.1,1.7,1.2c0.7,0.9,1.2,0,1.7-0.3
				C78.2,41.8,77.1,41.4,76.3,40.6z"/>
			<path fill="#E0BFAF" d="M77.5,42.5c-0.6-0.4-1-0.9-1.7-1.2c-0.3,0.5-0.2,1.1-0.7,1.4l0,0c0.2,0.2,0.3,0.5,0.5,0.7
				c0.5,0,0.7-0.6,1.2-0.5C77.2,43,77.4,42.8,77.5,42.5z"/>
			<path fill="#E3C7B7" d="M68.4,45.6c-0.6-0.2-0.5-0.6-0.2-1c0-0.2,0-0.4-0.2-0.5c-0.1,0.4-0.3,0.6-0.5,0.1
				c-0.2-0.4-0.4-0.6-0.8-0.4c-0.5,0.3,0.1,0.5,0.2,0.7c0.2,0.5,0.2,1.1,0.8,1.4C68.2,46.3,68.3,46.1,68.4,45.6z"/>
			<path fill="#E3C9B8" d="M67,41.3c0-0.2,0-0.3,0-0.5c-0.5-0.2-1-0.4-1-1.1c-0.2,1.3-0.2,2.4,0.2,3.6c0.2-0.1,0.2-0.3,0.2-0.5
				c0.4-0.4,0.2-0.9,0.5-1.3C66.9,41.4,67,41.3,67,41.3z"/>
			<path fill="#E8D8C6" d="M76.3,45.1c-0.6,0.3-1.2,0.6-0.7,1.4C76.7,46.3,76.9,46,76.3,45.1z"/>
			<path fill="#E8D8C6" d="M76.8,44.4c-0.5,0-0.5,0.4-0.5,0.7c0.3-0.3,0.7,0.8,1,0C77.6,44.5,77.3,44.4,76.8,44.4z"/>
			<path fill="#DFBDAE" d="M69.4,47.3c0.1-0.3,0.7-0.1,0.5-0.5c-0.2-0.4-0.6-0.4-1-0.5C69.1,46.6,68.6,47.2,69.4,47.3z"/>
			<path fill="#E0BFB0" d="M67.7,38.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.4-0.3-0.5-0.5-0.7c0,0,0,0,0,0C67.1,37.6,67,38,67.7,38.2z"/>
			<path fill="#E4CBBA" d="M73.3,47.4c0.1,0.1,0.2,0.2,0.3,0.1c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.2
				C73.5,47.1,73.4,47.2,73.3,47.4z"/>
			<path fill="#EBE2CF" d="M85,32.3c0-2.9-1.9-4.4-5.2-3.9c-1.2,0.2-2.3,0.5-3.3,1.2c-0.8,0.6-1.1,1.2-1,2.1c0.2,2.5,0.5,5,2.4,7
				c1.2,1.3,3.3,1.3,4.6,0.1C84.5,37.1,84.9,34.8,85,32.3z"/>
			<path fill="#E2C5B5" d="M74.4,37.4c-0.4-0.2-0.7-0.5-1.1-0.7c-0.2,0.2,0.1,0.5-0.2,0.7c-0.5,0.4-0.1,0.6,0.2,0.7
				c0.6,0.3,0.3,0,0.3-0.3c0-0.4,0.7,0.3,0.8-0.3C74.4,37.6,74.4,37.5,74.4,37.4z"/>
			<path fill="#C0CCD9" d="M38.9,189.4c0-0.2-0.1-0.4-0.2-0.5c-0.1,0-0.2,0-0.2,0c0,0.2,0,0.5,0,0.7
				C38.6,189.6,38.8,189.6,38.9,189.4z"/>
			<path fill="#DCB538" d="M26.9,19c1.2-0.3,1.5,0.6,1.7,1.4c0.1,0.1,0.3,0.1,0.4,0.1c0.2-0.3,0.3-0.7,0.6-1c0-0.1,0-0.2-0.1-0.3
				c-0.2-0.2-0.5-0.3-0.5-0.7c-0.4,0.4-0.8,0.7-0.9-0.1C28,17.7,27.6,18,27.4,18C26.9,18.2,26.9,18.6,26.9,19z"/>
			<path fill="#DCB538" d="M32.2,16.1c-0.5,0-1.1-0.2-1.3,0.6c-0.1,0.3-0.4,0.4-0.8,0.4c-0.2,0-0.5,0.1-0.4,0.5
				c0.1,0.3,0.3,0.3,0.5,0.1c0.3-0.3,0.6-0.3,0.9,0c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.4-0.2,0.5-0.4C31.9,17,33.1,16.6,32.2,16.1z"/>
			<path fill="#DEBC52" d="M31.4,17.8c-0.1,0-0.2,0-0.2,0c-0.1,0.3-0.2,0.6-0.2,1c-0.3,0.1-0.7,0.2-0.6,0.7c0,0.2,0.1,0.3,0.3,0.3
				c0.6-0.1,1.1-0.9,1.7-0.1c0.3,0.2,0.7,0.6,1,0.2c0.3-0.3-0.1-0.6-0.3-0.9C32.8,18.2,32,18.1,31.4,17.8z"/>
			<path fill="#DFC05E" d="M32.2,16.1c0,0.2,0.2,0.5,0.1,0.5c-1,0-0.3,0.6-0.4,0.9c0.7,0.2,1.3-0.2,1.9-0.2c0.2-0.1,0.3-0.3,0.3-0.6
				c0-0.4-0.3-0.6-0.5-0.8c-0.1-0.1-0.3-0.1-0.5,0c-0.5,0.3-0.4-0.5-0.7-0.4c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.2-0.2,0.2
				C32,15.9,32.1,16,32.2,16.1z"/>
			<path fill="#DDB946" d="M31.2,15.4c-0.4-0.1-1.2,0.7-1.2-0.5c-0.7,0.5-0.4,1.1-0.2,1.7c0.1,0.3,0.4,0.3,0.6,0
				C30.7,16.2,30.9,15.8,31.2,15.4z"/>
			<path fill="#DBB32E" d="M30.5,19.4c0-0.4,0.4-0.5,0.5-0.7c-0.8-0.9-0.9,0.5-1.4,0.5c0,0.1,0,0.2,0,0.3
				C29.8,19.7,30.2,19.7,30.5,19.4z"/>
			<path fill="#DDB948" d="M29.7,14c-0.3,0.1-0.6,0.2-0.7,0.6c0,0,0.2,0.2,0.2,0.2C29.6,14.8,29.7,14.5,29.7,14z"/>
			<path fill="#DCB434" d="M36,11c0.3-0.6,0.8-0.7,1.4-0.7c0.7-0.1,0-0.3,0.1-0.5c0.1-0.2,0.1-0.3,0-0.5c-0.6-0.7-1.4-0.3-2.1-0.3
				c-0.4-0.1-0.9,0.1-1.3,0.1c-0.3,0-0.6,0-0.7,0.3c0.4,0.3,0.2,0.8,0.3,1.2c0.1,0,0.1,0,0.2,0.1c0.5,0.1,0.8-0.3,1.3-0.3
				c0.4,0,0.3,0.2,0.3,0.4C35.5,10.9,35.6,11.2,36,11z"/>
			<path fill="#E0C163" d="M32.4,11.8c0,0.1,0,0.2,0,0.2c-0.2,0.2-0.2,0.5,0,0.7c1.3-0.1,0.6,0.9,0.7,1.4c0.2,0.1,0.3,0,0.4-0.1
				c0.3-0.4,0.7-0.3,1.1-0.3c0.6,0.1,0.5-0.3,0.4-0.7c-0.2-0.4-0.7-0.7-0.5-1.2c-0.1-0.5-0.3-1-0.8-1.2c-0.7-0.1-0.9,0.3-0.9,0.9
				c0,0.1,0.1,0.2,0,0.4C32.7,11.8,32.6,11.7,32.4,11.8z"/>
			<path fill="#DDBA48" d="M33.4,13.9c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0.1-0.5,0.5-0.9,0.5c0,0.3,0,0.6,0,1c0.1,0,0.2,0,0.2,0
				c0.3,0.1,0.5,0.3,0.7,0.5c0.2,0,0.3,0,0.5,0c0.1-0.1,0.2-0.2,0.2-0.4c-0.1-0.4,0.2-0.7,0.3-1C34,14.2,33.9,13.9,33.4,13.9z"/>
			<path fill="#DDB947" d="M32.4,12.7c0-0.2,0-0.5,0-0.7c-0.4-0.3-0.9-0.2-1.4-0.8c0.3,0.9,0.4,1.4,0.9,1.9c0.2,0.2,0.4,0.8,0.7,0.5
				C33.1,13.3,32.2,13.1,32.4,12.7z"/>
			<path fill="#DCB537" d="M32.4,11.8c0.1,0.2,0.2,0.3,0.3,0.5c0.5-0.3-0.2-1.4,0.9-1.4c0.1-0.1,0.1-0.1,0.1-0.2
				c0-0.1,0-0.1-0.1-0.1c0,0,0,0,0,0C32.5,10.1,32.4,10.2,32.4,11.8z"/>
			<path fill="#DDB842" d="M38.9,9.1c0.4-0.3,0.8-0.7,1.4-0.2c0.5,0.4,0.6-0.3,1-0.5C41.2,8.2,41,8.1,40.9,8c-0.8-0.1-0.6-1-0.9-1.4
				c-0.3-0.1-0.6-0.1-0.8,0.2c-0.9-0.2-0.2-0.9-0.4-1.2c-1,0.1,0,1-0.5,1.2c0.1,0.3-0.2,0.6,0,1c0.3,0.2,0.7,0.1,1.1,0
				c0,0.6-0.5,0.4-0.6,0.7c-0.3,0.3-0.1,0.5,0.1,0.7C38.8,9.1,38.8,9.1,38.9,9.1z"/>
			<path fill="#DCB435" d="M39.1,6.7c0.3,0,0.7,0,1,0c0.6,0.1,0.6-0.4,0.6-0.8c0-0.1-0.2-0.3-0.1-0.4c-0.2-0.3-0.4-0.8-1-0.5
				c-0.2,0-0.5,0-0.7,0C39.2,5.6,39.6,6.1,39.1,6.7z"/>
			<path fill="#DCB435" d="M38.2,7.7c0.1-0.3,0.7-0.7,0-1c-1-0.1-1.3,0.7-1.9,1.2C36.9,8.3,37.6,8.4,38.2,7.7z"/>
			<path fill="#DDB842" d="M37.4,9.4c0,0.2,0,0.3,0,0.5c0.7-0.1,1.4-0.1,2-0.2c-0.1-0.4-0.5-0.2-0.6-0.5c0,0,0,0,0,0
				c-0.2-0.1-0.4-0.2-0.7-0.2C38.1,9,38,9,37.9,9.1C37.7,9.1,37.5,9.2,37.4,9.4z"/>
			<path fill="#DCB639" d="M38.1,9.1c0.2,0,0.5,0,0.7,0c-0.1-0.2-0.2-0.5-0.2-0.7c-0.1,0-0.2,0-0.2,0C38.2,8.6,37.9,8.8,38.1,9.1z"
				/>
			<path fill="#E2C674" d="M38.1,9.1c0.1-0.2,0.2-0.5,0.3-0.7c-0.5,0-0.8,0.2-0.5,0.7c0,0,0.1,0,0.1,0S38.1,9.1,38.1,9.1z"/>
			<path fill="#DEBC50" d="M43.2,5.5c-0.1-0.4-0.4-0.8,0.2-1.1c0.3-0.1,0.2-0.4,0.1-0.6C43.3,4,43.2,4.1,43,4.2
				c-1.3,0.9-1.3,0.9-2.2-0.4c0,0.5,0,1,0,1.5c0.5,0.7,1.4,0.4,2,0.9C43.1,6.5,42.9,5.7,43.2,5.5z"/>
			<path fill="#E1C36B" d="M49,3.1c0.2-0.3,0.3-0.6,0.2-1c-0.7-0.1-1.1,0.3-1.6,0.6c-0.8,0.5-0.3,0.7,0.2,0.8
				C48.1,3.2,48.5,3.1,49,3.1z"/>
			<path fill="#DFBD54" d="M46.6,1.9c0,0.2,0,0.3,0,0.5c0.5,0,0.9-0.1,1.4-0.5c-0.4-0.4-0.8-0.2-1.1-0.3C46.7,1.8,46.6,1.8,46.6,1.9
				z"/>
			<path fill="#DCB330" d="M43.2,5.5c-0.3,0.1-0.3,0.6-0.4,0.4c-0.5-0.8-1.4-0.3-2-0.7c-0.1,0.1-0.2,0.1-0.2,0.2
				c-0.1,0.1-0.2,0.2,0,0.3c0.2,0.3,0,0.6,0.1,0.9c0.1,0.5,0.3,0.4,0.6,0.2c0,0,0.1-0.1,0.1-0.2c0,0.1,0,0.1-0.1,0.2
				c-0.2,0.4-0.8,0.7-0.5,1.3c0.1,0.2,0.3,0.3,0.5,0.3c0.4,0,0.8-0.5,1.2,0c0.4,0.1,0.3-0.2,0.3-0.4c-0.1-0.2-0.2-0.3-0.3-0.4
				c-0.2-0.2-0.2-0.4,0.1-0.5c0.6,0.1,0.1,0.6,0.2,0.8c0,0.2,0,0.4,0.2,0.6c0.2,0.1,0.4,0.1,0.6,0.2c0.6,0.2,1,0.1,0.7-0.6
				c-0.2-0.4,0-0.8,0.2-1.1c-0.1-0.4,0.3-1.1-0.6-0.9C43.6,6,43.2,6,43.2,5.5z"/>
			<path fill="#DBB32F" d="M17,76.6c-0.1,0-0.2,0-0.3,0c-0.1,0.5-1.3,0.3-0.7,1.2c0.3-0.1,0.5-0.9,1-0.1c0.2,0.3,0.6,0.1,0.9-0.1
				C18,76.8,17.3,76.9,17,76.6z"/>
			<path fill="#DBB22C" d="M50.4,1.2c0.2,0.2,0.3,0.3,0.5,0.5c0-0.3,0-0.6,0-1c-0.2,0-0.3,0-0.5,0c-0.2,0.1-0.4,0.2-0.2,0.5
				C50.2,1.2,50.3,1.2,50.4,1.2z"/>
			<path fill="#DEBA48" d="M50.2,1.2c0.1-0.2,0.2-0.3,0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.1C49.8,0.8,49.9,1,50.2,1.2z"/>
			<path fill="#DCB536" d="M33.6,15.6c0,0.2,0,0.3,0,0.5c0,0.4,0.3,0.5,0.5,0.7c0.1,0,0.1,0,0.2,0c0.4-0.3,0.6-0.7,0.3-1.2
				c-0.4-0.4-0.3-0.6,0.2-0.7c0.5-0.1,0.7-0.3,0.7-0.7c0-0.4,0.1-0.7-0.2-1c-0.2-0.1-0.3-0.1-0.5,0.1c0.1,0.4,0.1,0.6-0.3,0.3
				c-0.6-0.4-0.9-0.2-1.1,0.4c0.4,0.2,0.5,0.5,0.5,0.9C33.9,15.2,33.4,15.3,33.6,15.6z"/>
			<path fill="#DDB841" d="M34.1,16.8c-0.2,0.1-0.2,0.3-0.2,0.5c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.1c0.1,0.1,0.2,0.1,0.3,0.1
				c0.3,0,0.6,0,0.9,0c0.6-0.1,1.4,0.4,1.8-0.6c0-0.5,0.8-0.6,0.7-1.2c-0.6-0.9-1.3,0.1-1.9,0C34.8,15.8,34.8,16.8,34.1,16.8
				L34.1,16.8z"/>
			<path fill="#DCB535" d="M34.8,13.2c0.2,0,0.3,0,0.5,0c0.2,0.2,0.2,0.6,0.5,0.7c0.5-0.3,1.2-0.4,1.2-1.2c-0.2-0.2-0.5-0.3-0.7-0.5
				c-0.1-0.5-0.8,0.1-1-0.5c-0.4-0.3-0.7,0-1,0.2C33.2,12.9,34.9,12.7,34.8,13.2z"/>
			<path fill="#DCB535" d="M40.6,11.5c0.2-0.1,0.3-0.2,0.5-0.2c0-0.1,0-0.1,0-0.2c0.3-0.3,0.7-0.4,0.8-0.8C42,10,42,9.6,41.7,9.4
				c-0.1-0.1-0.3-0.1-0.4-0.3c-0.5,0.3-0.6,0.8-0.9,1.2c-0.2,0.3-0.1,1.1-0.9,0.6c-0.3,0.5-0.7,0.9,0.1,1.3c0,0,0,0,0,0
				C40,12.1,40.3,11.9,40.6,11.5z"/>
			<path fill="#E2C675" d="M34.3,12c0.3-0.1,0.6-0.2,1-0.2c0-0.2,0.1-0.4,0.2-0.5c0.2-0.2-0.2-0.5,0-0.7c0-0.2,0.2-0.6,0-0.6
				c-0.3-0.1-0.8-0.1-0.9,0.1c-0.2,0.4-0.7,0.3-1,0.6c0,0.1,0,0.2,0,0.2C33.8,11.2,34.1,11.6,34.3,12z"/>
			<path fill="#DDB945" d="M36.2,12.3c0,0.6,0.2,0.8,0.7,0.5c0.1-0.4-0.2-1.1,0.7-0.8c0.4,0.1,0.4-0.3,0.4-0.5
				c0-0.4-0.4-0.5-0.7-0.5C36.6,11,36.5,11.7,36.2,12.3z"/>
			<path fill="#DDB842" d="M41.3,9.1c0.1,0.1,0.2,0.2,0.2,0.2c0.4,0.3,0.9,0.1,1.4,0.1c0.3,0,0.6,0,0.7-0.3c0.1-0.4-0.1-0.6-0.4-0.7
				C43,8.3,43,8.1,43,7.9c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0.1c0,0.2,0,0.4-0.2,0.5C42.4,9.1,41.7,9,41.3,9.1z"/>
			<path fill="#DBB32F" d="M34.1,16.8c0.7,0.1,1-0.5,1.4-1c-0.3-0.3-0.6-0.4-1-0.2C34.5,16,34.4,16.5,34.1,16.8z"/>
			<path fill="#E0C163" d="M35.8,13.9c0-0.3-0.1-0.6-0.5-0.7c0,0.3-0.1,0.7,0.2,0.9C35.6,14.1,35.7,14,35.8,13.9z"/>
			<path fill="#DCB535" d="M35.5,10.6c-0.4,0.2-0.1,0.5,0,0.7c0.2,0,0.4,0,0.5-0.2C35.8,10.9,35.6,10.8,35.5,10.6z"/>
			<path fill="#DCB330" d="M45.1,6.5c0.4-0.7,1-0.7,1.7-0.7C47.2,5,46.6,4.7,46.2,5c-0.7,0.5-0.9,0-1.3-0.4
				C44.3,5.3,44.2,5.9,45.1,6.5z"/>
			<path fill="#DCB330" d="M45.1,6.5c-0.5-0.6-0.5-1.2-0.2-1.9c0,0,0,0,0,0c-0.3-0.1-0.4,0-0.5,0.3c-0.3,0.4,0,0.9-0.5,1.2
				c0.6,0.1,0.3,0.6,0.5,1C44.9,8,45.1,8,46.1,7C45.9,6.6,45.3,6.9,45.1,6.5z"/>
			<path fill="#DCB330" d="M44.4,4.8c0.2-0.1,0.3-0.2,0.5-0.3c-0.1-0.2-0.2-0.4-0.5-0.3C44.2,4.4,44.1,4.6,44.4,4.8z"/>
			<path fill="#E1C46F" d="M34.1,17.5c-0.1,0-0.1,0-0.2,0c-1,0-1.1,0.6-1,1.4c0.1,0.2,0.3,0.4,0.5,0.7c-0.4,0.2-0.7-0.2-0.9-0.2
				c-1.1,0.5-0.3,0.9,0,1.3c0.3,0.1,0.5,0.1,0.7-0.1c0-0.4,0.2-0.4,0.4-0.2c0.4,0.3,0.5,0.1,0.5-0.3c0.2-0.3-0.6-0.4-0.1-0.7
				c0-0.3-0.1-0.7,0.1-1C34.3,18.1,34.4,17.8,34.1,17.5z"/>
			<path fill="#DDB73F" d="M33.1,20.6c-0.2,0-0.5,0-0.7,0c-0.5,0.1-0.4,0.8-0.8,1c-0.1,0.2,0,0.3,0.1,0.4c0,0,0,0,0,0
				c0.1,0,0.2,0,0.2,0c1.1,0.7,1.5,0.1,1.7-0.9C33.4,21,33.3,20.8,33.1,20.6z"/>
			<path fill="#DFBD55" d="M33.8,19.4c-0.3,0.4,0.1,0.5,0.2,0.7c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.1-0.1-0.1
				c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.3,0.1-0.5C34.1,19.1,34,19.2,33.8,19.4z"/>
			<path fill="#DBB32E" d="M34.3,19.9c0,0.1,0,0.2,0,0.2c0.4,0.2,0.7,0,1-0.2C35,19.9,34.6,19.9,34.3,19.9z"/>
			<path fill="#DCB432" d="M31.7,21.6c0.9,0.2,0.2-0.8,0.7-1c0-0.4-0.6-0.8,0-1.2c-0.7-0.8-1.2-0.4-1.7,0.2
				c-0.3,0.8-1.1,0.6-1.7,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0.5-0.3,1-0.1,1.6c0.1,0.2-0.4,0.7-0.7,1.1c0.1,0.2,0.2,0.3,0.2,0.5
				c0.1,0.2,0.3,0.2,0.5,0.2c0-0.8,1.1-0.7,1.2-1.4c0.2-0.2,0.3-0.5,0.5-0.7C30.7,22,31.2,21.7,31.7,21.6z"/>
			<path fill="#DBB22C" d="M28.1,23.5c0.4-0.4,0.1-0.4-0.2-0.5c-0.1,0.1-0.2,0.2-0.2,0.2C27.8,23.4,27.9,23.4,28.1,23.5z"/>
			<path fill="#E2C776" d="M40.6,11.5c-0.5,0-0.6,0.5-1,0.7c0.4,0,0.7,0.1,1,0.2C41.4,12.2,40.6,11.8,40.6,11.5z"/>
			<path fill="#DBB22C" d="M41.1,13c-0.1-0.2-0.3-0.2-0.5-0.3c-0.5,0.4-0.4,0.7,0.3,0.7C41.2,13.5,41.2,13.2,41.1,13z"/>
			<path fill="#DBB22C" d="M11,63.8c0.2,0.1,0.5,0.2,0.7,0.2c0.2-0.4,0.1-0.8,0-1.2C11.1,63,11.3,63.6,11,63.8z"/>
			<path fill="#E1C673" d="M31.7,21.6c-0.5-0.5-1,0.2-1.5,0c0.2,0.9,1,0.3,1.5,0.5C31.7,21.9,31.7,21.8,31.7,21.6z"/>
			<path fill="#E1C46E" d="M46.1,7c-0.6,0-1.1,1-1.7,0c-1.2,0.4-0.2,1-0.2,1.5c-0.4,0.3-0.7-0.4-1.1-0.1c0.2,0.4,0.6,0.9-0.4,0.8
				c-0.4-0.1-0.8,0.1-1.3,0.2c0.2,0.3,0.3,0.6,0.2,1c0,0.2,0.1,0.4,0.2,0.5c0.1,0,0.2,0,0.3,0c0.4-0.5,0.8,0.1,1.2,0
				c0.1-0.1,0.1-0.1,0-0.2c0.2,0,0.1,0.2,0.2,0.2c0.3-0.4,0.6-0.9,1.2-0.7c0.5,0.1,0.7-0.2,0.8-0.5c0-0.6,0-1.1,0.9-0.9
				c0.2,0,0.2-0.4,0-0.7c-0.5-0.2,0.1-0.7-0.2-1C46.2,7,46.1,7,46.1,7z"/>
			<path fill="#DCB433" d="M43.9,12.2c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.3,0.5,0.3,0.7,0.3c0.1,0,0.2-0.2,0.3-0.3
				c0.1-0.4-0.3-0.4-0.5-0.5c-0.1,0-0.2-0.1-0.3-0.2C44.2,12,44,12,43.9,12.2z"/>
			<path fill="#DDB842" d="M42,10.8c0-0.2,0-0.4-0.2-0.5c-0.3,0.2-0.6,0.4-0.7,0.7C41.4,11.1,41.8,11.2,42,10.8z"/>
			<path fill="#DBB22C" d="M46.6,7.9c0,0.5-0.1,0.7-0.6,0.3c-0.2-0.2-0.6-0.2-0.8,0.1c-0.2,0.2,0,0.5,0.1,0.6
				c0.5,0.6-0.3,0.7-0.5,1.1c0,0.1,0,0.2,0,0.2c0.2,0,0.3,0,0.5,0c0.6-0.4,1.1-0.8,1.4-1.5C47,8.5,47.2,8.1,46.6,7.9z"/>
			<path fill="#DDB73E" d="M43.7,10.8c-0.1-0.3,0.2-0.6-0.2-0.8c-0.1,0-0.3,0-0.3,0.1c-0.2,0.3,0.2,0.4,0.3,0.7
				C43.5,10.8,43.6,10.8,43.7,10.8z"/>
			<path fill="#DBB32E" d="M36.7,17c-0.2-0.1-0.4-0.2-0.5,0.2c-0.1,0.4-0.1,0.5-0.4,0c-0.3-0.6-0.7,0.1-1,0.3
				C34.7,17.8,34.7,18,35,18c0.4,0.1,0.2,0.6,0.5,0.9c0.5-0.5,0.7-1.3,1.5-1.6C36.9,17.2,36.8,17.1,36.7,17z"/>
			<path fill="#E2C778" d="M35,18c-0.2-0.1-0.2-0.3-0.2-0.5c-0.2-0.3-0.5-0.3-0.7,0c0,0.3,0,0.7-0.2,0.9c0.1,0.3,0.4,0.4,0.6,0.6
				c0.1,0.1,0.2,0.2,0.4,0.1C35.2,18.9,35,18.4,35,18z"/>
			<path fill="#DBB32E" d="M34.8,19.2c-0.1-0.1-0.2-0.2-0.2-0.2c-0.2-0.1-0.3,0-0.2,0.2c0,0.2,0,0.3,0,0.5c0.3,0,0.5,0.1,0.7-0.2
				C35,19.4,34.9,19.3,34.8,19.2z"/>
			<path fill="#FCFCFD" d="M51.8,174.9c-0.3,0.3-0.3,0.6-0.1,1C52,175.6,51.8,175.3,51.8,174.9C51.8,175,51.8,174.9,51.8,174.9z"/>
			<path fill="#E3C8B8" d="M69.1,42c-0.6-0.7-1.2-1.1-2.2-0.7c0,0,0,0,0,0c-0.4,0.4-0.5,0.9-0.2,1.4c0.2,0.2,0.3,0.2,0.5,0
				c0.3-0.3,0.6-0.5,1-0.7c0.2-0.3,0.5,0.1,0.7,0C69,42,69,42,69.1,42z"/>
			<path fill="#E0BFB0" d="M70.5,41.4c0.7-0.5,0.9-1.4,2.1-1.5C70.8,39.2,70.8,40.4,70.5,41.4z"/>
			<path fill="#E7D3C1" d="M69.8,38.2c-0.1-0.2,0.2-0.5,0-0.7c-0.2-0.1-0.4,0-0.5,0.1c-0.5,0.5,0.5,0.4,0.3,0.8
				C69.7,38.3,69.8,38.2,69.8,38.2z"/>
			<path fill="#E6D3C1" d="M69.6,38.4c-0.5,0.2-0.5,0.6-0.2,1C70.4,39.3,69.5,38.7,69.6,38.4z"/>
			<path fill="#E0BFAF" d="M75.1,42.7c-0.1-0.3,0.2-0.6,0-0.9c0,0-0.4,0.1-0.5,0.2C74.5,42.5,74.7,42.7,75.1,42.7L75.1,42.7z"/>
			<path fill="#E8D7C5" d="M69.1,39.8c-0.5,0.1-0.6,0.4-0.4,0.7c0.1,0.2,0.3-0.1,0.4-0.3C69.1,40.2,69.1,40,69.1,39.8z"/>
			<path fill="#EBE2CF" d="M72.2,45.6c0.7,0.7,1.2,0.3,1.7-0.2c-0.5,0.1-1,0.4-1.5-0.1C72.3,45.2,72.3,45.5,72.2,45.6z"/>
			<path fill="#E8D8C6" d="M68.2,42c-0.6-0.1-0.9,0.1-1,0.7c0.2,0,0.5,0,0.7,0l0,0C68,42.5,68.1,42.2,68.2,42z"/>
			<path fill="#DDB7A8" d="M72.6,44.9c-0.4-0.4-0.7-0.6-1.1-0.5c0,0-0.1,0.2-0.1,0.3C71.8,45,72.1,44.9,72.6,44.9z"/>
			<path fill="#E7D6C4" d="M69.8,45.4c0.1,0,0.2,0,0.2,0c0.3,0,0.4,0.6,0.8,0.2c0,0,0-0.3,0-0.3C70.5,45.2,70.1,44.6,69.8,45.4
				C69.8,45.4,69.8,45.4,69.8,45.4z"/>
			<path fill="#E8D8C6" d="M66.7,42.7c-0.1-0.5,0.2-1,0.2-1.4c-0.9,0.2-0.3,1-0.5,1.4C66.6,42.7,66.6,42.7,66.7,42.7z"/>
			<path fill="#E7D5C4" d="M71.3,46c0.2-0.3,0.3-0.5,0.6-0.9C71.3,45.3,71.3,45.6,71.3,46z"/>
			<path fill="#E8D8C6" d="M67.9,42.7c0,0.2-0.1,0.5,0.2,0.6c0,0,0.2-0.1,0.2-0.2C68.4,42.8,68.2,42.7,67.9,42.7
				C67.9,42.7,67.9,42.7,67.9,42.7z"/>
			<path fill="#EBE2CF" d="M67.4,37.2c0.2,0.2,0.3,0.5,0.5,0.7C68.3,37.3,67.8,37.3,67.4,37.2z"/>
			<path fill="#E2C675" d="M29.1,20.4c0.7,0.8,1.3,0.1,1.9-0.1c0.5-0.1,0-0.4-0.2-0.6c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3,0-0.6,0-1,0
				C29.2,19.7,28.6,19.8,29.1,20.4z"/>
			<path fill="#DCB535" d="M32.9,19c0.1-0.6,0.5-1.1,1-1.4c0-0.1,0-0.2,0-0.3c-0.7-0.1-1.3-0.2-1.9,0.3c-0.2,0.1-0.3,0.2-0.5,0.2
				C31.6,18.6,32.6,18.3,32.9,19z"/>
			<path fill="#DCB431" d="M33.1,16.1c-0.1-0.3-0.1-0.9-0.7-0.5c0,0.3,0.2,0.4,0.3,0.6c0.1,0.1-0.2,0.4,0.2,0.4
				C33,16.6,33.1,16.3,33.1,16.1z"/>
			<path fill="#E1C46F" d="M33.6,15.6c0-0.3,0.7-0.3,0.2-0.7C33.6,15.1,33.2,15.2,33.6,15.6z"/>
			<path fill="#E1C36B" d="M40.8,8.1c0.2-0.5,0.5-0.9,0.7-1.4c-0.1-0.1-0.1-0.1-0.2,0c-0.3,0.1-0.6,0.1-0.4-0.3
				c0.1-0.3,0.2-0.6-0.3-0.6c-0.2,0.3,0.1,0.8-0.5,1C39.1,7.8,40.1,7.9,40.8,8.1z"/>
			<path fill="#DFBD55" d="M43,7.9c0.2-0.3,0.5-0.7,0.3-1.1c-0.2-0.2-0.5,0.1-0.7,0.2c-0.1,0.1,0,0.3,0.1,0.4
				C42.7,7.5,42.8,7.7,43,7.9L43,7.9z"/>
			<path fill="#DDB842" d="M42.7,7.2C42.6,7.1,42.6,7,42.5,7c-0.1-0.1-0.3-0.2-0.4-0.1c-0.2,0.4-0.2,0.7,0.1,1
				C42.4,7.7,42.9,7.7,42.7,7.2z"/>
			<path fill="#DEBA4B" d="M41.3,6.7c0.1,0,0.1,0,0.2,0c0.1-0.2,0.3-0.5,0.2-0.8c0,0-0.1-0.1-0.2-0.1C41.1,6.1,41.3,6.4,41.3,6.7z"
				/>
			<path fill="#E1C572" d="M42.7,7.2c-0.1,0.3-0.5,0.3-0.5,0.7c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.2,0C43,7.6,43,7.4,42.7,7.2z"/>
			<path fill="#DCB431" d="M34.3,19.2c0.1-0.1,0.2-0.2,0.2-0.2c0-0.5-0.4-0.5-0.7-0.5c-0.4,0.3-0.1,0.7,0,1
				C34,19.4,34.2,19.3,34.3,19.2z"/>
		</g>
	</g>
</g>
</svg>

        )
    }
}

export default Character4Pool;