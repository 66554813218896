import React from "react"
import { Link } from "react-router-dom"
import data from "../data.json";

class ScenarioHeader extends React.Component {
    render() {
      const currentScreen = this.props.currentScreen;
      const scenario = data[currentScreen].properties.scenario;
      const choice = data[currentScreen].properties.choice;
      const updateLastClick = this.props.updateLastClick;
      const questions = [1, 2, 3];
      let wrapper = function(element, i) {
        if (i === choice - 1) {
          return (
            <Link
              to={scenario + "-" + choice}
              className="scenario_stepper_button scenario_stepper_button--active"
              key={i}
              onClick={() => updateLastClick(`Manual #${choice}`)}
            >
              {i + 1}
            </Link>
          );
        } else {
          const destinationName = scenario + "-" + (i + 1);
          return (
            <Link
              to={destinationName}
              className="scenario_stepper_button"
              key={i}
              onClick={() => updateLastClick(`Manual #${i + 1}`)}
            >
              {i + 1}
            </Link>
          );
        }
      };
      return (
        <div className="scenario_header">
          <p className="scenario_title">
            {data[currentScreen].properties.scenarioTitle}
          </p>
          <div className="scenario_stepper">{questions.map(wrapper)}</div>
        </div>
      );
    }
  }
export default ScenarioHeader;