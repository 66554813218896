import React from "react";
import Character1Airplane from "./Character1Airplane.js"
import Character2Airplane from "./Character2Airplane.js"
import Character3Airplane from "./Character3Airplane.js"
import Character4Airplane from "./Character4Airplane.js"

class CharacterAirplane extends React.Component {
  state = { svg: null };

  componentDidMount() {
    const svgSupport =
      !!document.createElementNS &&
      !!document.createElementNS("http://www.w3.org/2000/svg", "svg")
        .createSVGRect;
    if (svgSupport) {
      this.setState({
        svg: "svg"
      });
    } else {
      this.setState({
        svg: "png"
      });
    }
  }
  render() {
    return (
      <div
        className={
          "option character-illustration-wrapper character-airplane " +
          this.state.svg
        }
      >
        {this.props.characterReference === "character-1" && <Character1Airplane/>}
        {this.props.characterReference === "character-2" && <Character2Airplane/>}
        {this.props.characterReference === "character-3" && <Character3Airplane/>}
        {this.props.characterReference === "character-4" && <Character4Airplane/>}
        <svg className={"character-background character-airplane-background"} />
      </div>
    );
  }
}

export default CharacterAirplane;
