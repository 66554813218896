import React from "react";
import data from "../data.json";

class BodyCopy extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    return (
      <div className="body_copy_wrapper">
        {data[currentScreen].copy.map((copy, i) => (
          <p className="body_copy" key={i}>
            {copy}
          </p>
        ))}
      </div>
    );
  }
}

export default BodyCopy;
