import React from "react";
import GlobalHeader from "./GlobalHeader";
import ScreenFallback from "./ScreenFallback";
import Header from "./Header";
import BodyCopy from "./BodyCopy";
import ResultOptions from "./ResultOptions";
import AdvanceButton from "./AdvanceButton";
import data from "../data.json";

const PageNameMap = {
  '1-1-1' : 'Grow my money / Look to the future',
  '1-1-2' : 'Grow my money / Fun Choice!',
  '1-2-1' : 'Grow my money / Smart planning!',
  '1-2-2' : 'Grow my money / Smart move',
  '1-3-1' : 'Grow my money / Focus on today',
  '1-3-2' : 'Grow my money / Great call!',
  '2-1-1' : 'Go with the cash flow / Excellent thinking!',
  '2-1-2' : 'Go with the cash flow / Charge wisely',
  '2-2-1' : 'Go with the cash flow / Keep a cushion',
  '2-2-2' : 'Go with the cash flow / Look out for your future self',
  '2-3-1' : 'Go with the cash flow / The rewards of refinancing',
  '2-3-2' : 'Go with the cash flow / Excellent work!',
  '3-1-1' : 'Treat myself / Dream big, plan big',
  '3-1-2' : 'Treat myself / Best of both worlds',
  '3-2-1' : 'Treat myself / Rely on a loan',
  '3-2-2' : 'Treat myself / Well played!',
  '3-3-1' : 'Treat myself / Looking ahead',
  '3-3-2' : 'Treat myself / Split the difference',
}

class Result extends React.Component {
  componentDidMount() {
    const pageId =
      `${data[this.props.currentScreen].properties.scenario}-${data[this.props.currentScreen].properties.choice}-${data[this.props.currentScreen].properties.result}`;
    const pageName = PageNameMap[pageId];
    this.props.updateClickstream(pageName);
  }
  render() {
    const currentScreen = this.props.currentScreen;
    const checkProgress = this.props.checkProgress;
    const setOffsiteProgress = this.props.setOffsiteProgress;
    const updateClickstream = this.props.updateClickstream;
    const updateLastClick = this.props.updateLastClick;
    return (
      <div className="screen">
        <div className="screen_content">
          <GlobalHeader
            updateClickstream={updateClickstream}
          />
          <div className="content_wrapper">
            <Header currentScreen={currentScreen} />
            <BodyCopy currentScreen={currentScreen} />
          </div>
          <ResultOptions
            currentScreen={currentScreen}
            setOffsiteProgress={setOffsiteProgress}
            updateClickstream={updateClickstream}
            updateLastClick={updateLastClick}
          />
          <AdvanceButton
            currentScreen={currentScreen}
            checkProgress={checkProgress}
            updateLastClick={updateLastClick}
          />
        </div>
        <ScreenFallback
          updateClickstream={updateClickstream}
        />
      </div>
    );
  }
}

export default Result;
