import React from "react";
import GlobalHeader from "./GlobalHeader";
import ScreenFallback from "./ScreenFallback";
import QuizHeadline from "./QuizHeadline";
import BodyCopy from "./BodyCopy";
import ResultOptions from "./ResultOptions";

class ScenarioSelectComplete extends React.Component {
  componentDidMount(){
    this.props.updateClickstream("Quiz Completion Screen");
  }
  render() {
    const currentScreen = this.props.currentScreen;
    const checkProgress = this.props.checkProgress;
    const updateClickstream = this.props.updateClickstream;
    const updateLastClick = this.props.updateLastClick;
    return (
      <div className="screen screen--complete">
        <div className="screen_content">
          <GlobalHeader
            updateClickstream={updateClickstream}
          />
          <div className="content_wrapper">
            <QuizHeadline currentScreen={currentScreen} />
            <BodyCopy currentScreen={currentScreen} />
          </div>
          <ResultOptions
            checkProgress={checkProgress}
            currentScreen={currentScreen}
            updateClickstream={updateClickstream}
            updateLastClick={updateLastClick}
          />
        </div>
        <ScreenFallback
          updateClickstream={updateClickstream}
        />
      </div>
    );
  }
}

export default ScenarioSelectComplete;
