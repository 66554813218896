import React from "react";
import GlobalHeader from "./GlobalHeader";
import ScreenFallback from "./ScreenFallback";
import ScenarioHeader from "./ScenarioHeader";
import Header from "./Header";
import BodyCopy from "./BodyCopy";
import ChoiceOptions from "./ChoiceOptions";
import data from "../data.json";

const PageNameMap = {
  '1-1' : 'Grow my money / Congratulations!',
  '1-2' : "Grow my money / It's payday!",
  '1-3' : "Grow my money / Congrats on the raise!",
  '2-1' : 'Go with the cash flow / Time to celebrate!',
  '2-2' : "Go with the cash flow / Bravo!",
  '2-3' : "Go with the cash flow / Opportunity knocks!",
  '3-1' : 'Treat myself / Exciting!',
  '3-2' : "Treat myself / Splendid!",
  '3-3' : "Treat myself / Bonus!",
}

class Choice extends React.Component {
  componentDidMount() {
    const pageId =
      `${data[this.props.currentScreen].properties.scenario}-${data[this.props.currentScreen].properties.choice}`
    const pageName = PageNameMap[pageId];
    this.props.updateClickstream(pageName || pageId);
  }
  render() {
    const setQuizProgress = this.props.setQuizProgress;
    const currentScreen = this.props.currentScreen;
    const characterReference = this.props.characterReference;
    const updateLastClick = this.props.updateLastClick;
    const updateClickstream = this.props.updateClickstream;
    return (
      <div className="screen">
        <div className="screen_content">
          <GlobalHeader
            updateClickstream={updateClickstream}
          />
          <div className="content_wrapper">
            <ScenarioHeader currentScreen={currentScreen} updateLastClick={updateLastClick} />
            <Header currentScreen={currentScreen} />
            <BodyCopy currentScreen={currentScreen} />
          </div>
          <ChoiceOptions
            characterReference={characterReference}
            currentScreen={currentScreen}
            setQuizProgress={setQuizProgress}
            updateLastClick={updateLastClick}
          />
        </div>
        <ScreenFallback
          updateClickstream={updateClickstream}
        />
      </div>
    );
  }
}

export default Choice;
