import React from "react"

class Character3Mailbox extends React.Component {
    render(){
        return(
<svg version="1.1" id="character-3" className="character-animated" x="0px" y="0px" viewBox="0 0 210.4 621.1" enableBackground="new 0 0 210.4 621.1" >
<g id="arm">
	<g id="w83l6p_2_">
		<g>
			<path fill="#FBF9F1" d="M168.7,204.3c0.1,0,0.1,0.1,0.2,0.1c-0.3,0.5-0.6,1-0.8,1.5l-0.1-0.1l-0.1-0.1
				C167.8,205.1,168.1,204.6,168.7,204.3z"/>
			<path fill="#E3C77A" d="M168.7,204.3c-0.3,0.5-0.6,1-0.8,1.5c-1.5,2-2.9,4.1-4.8,5.8c-2.3,2.2-4.8,4.3-7.9,5.2
				c-3.8,1.1-7.5,0.6-11.2-0.7c-4-1.4-6.8-4.3-9.5-7.3c-4.1-4.7-7.3-10.1-10.7-15.3c-4.4-6.7-8.3-13.7-12.1-20.7
				c-2.1-3.8-4.3-7.6-6.9-11.2c-0.8-1.1-1.4-2.3-1.7-3.6c-0.2-0.8-0.6-1.5-0.8-2.2c-1-3.2-2.5-6.2-2.6-9.6c0-1.5-0.7-3-0.8-4.5
				c-0.2-2.6-0.8-5.4-0.2-8c0.6-2.9,0.7-5.9,2.3-8.6c0.7-1.2,0.7-2.9,1.6-4.1c3.3-4.3,8.3-7.2,13.9-5.4c2.4,0.8,4.2,2.2,5.6,4.5
				c2.9,5.1,5.6,10.3,7.6,15.7c1.3,3.6,2.6,7.3,3.9,10.9c1.8,5.1,3.6,10.2,5.5,15.3c2.7,7.1,5,14.3,8.4,21.1
				c0.2,0.4,0.4,0.8,0.6,1.1c1.3,1.9,3.4,1.9,4.7,0c2.6-3.8,4.8-7.9,7.2-11.9c2.2-3.6,4.1-7.4,6.5-10.9c1-1.4,1.5-3.2,2.6-4.5
				c1.1,0.4,2.2,1,3.2,1.6c0.3,0.2,0.5,0.5,0.5,0.9c-1.6,2.5-2.9,5.2-4.7,7.6c-0.9,1.2-1.5,2.6-2.3,3.9c-0.5,0.8,0.1,1,0.6,1.4
				c0.5,0.3,1,0.6,1.4-0.1c0.4-0.6,0.8-1.3,1.2-2c1.8-3.1,3.5-6.1,5.4-9.1c0.2-0.3,0.3-0.6,0.6-0.7c0.8-0.1,1.4,0.5,2,0.9
				c0.9,0.5,1.8,1.1,2.5,1.9c0.2,0.6-0.2,1.2-0.4,1.7c-1.4,3.3-3.3,6.4-4.9,9.6c-0.2,0.4-0.4,0.8-0.6,1.2c-0.4,0.8,0.1,1.3,0.7,1.6
				c0.7,0.4,1.1,0.1,1.4-0.6c1.8-3.8,4-7.4,5.9-11.2c0.1-0.2,0.2-0.4,0.4-0.6c1.1,0.4,2.2,0.7,2.8,1.8c-1.6,4.6-3.5,9.1-5.4,13.5
				c-1.9,4.4-3.7,8.9-5.6,13.3c-0.8,1.9-1.7,3.8-2.6,5.6C170.5,201,169.6,202.7,168.7,204.3z"/>
			<path fill="#C1A890" d="M179.6,163.5c-1.5-1-3-1.9-4.5-2.9c-0.9-0.2-1.7-0.7-2.3-1.3c-0.6-0.5-1.3-1-2.1-1.2
				c-0.6-0.2-1.1-0.7-1.5-1.1c2.2-4.1,4.9-8.1,7.3-12.1c2-3.3,3.9-6.6,5.7-10c0.8-1.5,0.7-3.4,0.8-5.1c0.3-4.9,0.3-9.8,1.9-14.5
				c1.2-3.4,2.5-6.8,3.9-10.2c0.4-1,0.6-2.1,0.8-3.1c0.1-0.6,0.4-1,0.9-1.1c0.6-0.1,0.8,0.5,1,0.9c0.9,1.9,1.1,4,1.1,6.1
				c0,0.4-0.2,0.7,0.1,1.3c0.6-0.9,1.2-1.6,1.7-2.4c2-3.2,3.3-6.8,5.2-10c0.8-1.3,1.7-1.7,3.1-1.2c0.6,0.2,1.1,0.3,1.6,0
				c1.8-0.9,3.5,0.4,3.8,2.2c0.2,1.1,0.3,2.1,1.3,2.9c0.7,0.6,0.8,1.5,0.8,2.3c-0.2,4.1-0.4,8.2-1.2,12.3c-0.5,2.9-1.8,5.3-3.4,7.6
				c-1.2,1.8-2.4,3.7-3.6,5.5c-1.2,1.8-2.4,3.6-3.6,5.5c-1.6,2.4-2.5,5.1-3.6,7.8c-1.8,4.4-3.6,8.7-5.3,13c-1.1,2.8-2.3,5.5-3.2,8.4
				c-0.4,1.2-1,2.4-1.5,3.7c-0.7-0.8-2.1-0.8-2.7-1.8C181.1,165,180.2,164.5,179.6,163.5z"/>
			<path fill="#DBB22B" d="M179.6,163.5c0.9,0.5,1.8,1,2.7,1.5c-1.1,2.7-2.6,5.2-4,7.8c-0.8,1.5-1.7,3-2.3,4.6
				c-0.3,0.6-0.6,0.8-1.3,0.5c-1.6-0.6-1.8-1.2-1.1-2.7C175.6,171.4,177.8,167.6,179.6,163.5z"/>
			<path fill="#DBB22B" d="M172.8,159.3c0.9,0.3,1.6,0.7,2.3,1.3c-2.4,4.1-4.7,8.2-7.1,12.2c-0.3,0.5-0.6,0.8-1.2,0.3
				c-1.8-1.4-1.8-1.4-0.7-3.3c1.9-3.2,3.8-6.3,5.7-9.4C172.2,160,172.5,159.7,172.8,159.3z"/>
		</g>
	</g>
</g>
<g id="body">
	<g id="ayehqA_1_">
		<g>
			<path fill="#615A51" d="M127.7,318.5c0,0.3,0,0.6,0,1c-0.3,0.9,0,1.9-0.1,2.8c-0.2,1.7-0.1,3.4-0.1,5c0,3.8-0.1,7.6-0.3,11.4
				c-0.1,2.8-0.3,5.5-0.4,8.2c-0.1,2.7-0.5,5.5-0.7,8.2c-0.4,4.7-1.1,9.3-1.3,14.1c-0.1,2.5-0.4,5.1-0.9,7.6
				c-0.5,2.9-0.6,5.8-0.8,8.7c-0.2,2.2-0.8,4.4-1.1,6.6c-0.5,3.9-0.7,7.9-1.5,11.8c-1.2,6.1-2.2,12.3-3.1,18.4
				c-0.5,3.5-1.4,7-1.9,10.5c-0.8,5.2-2,10.4-2.9,15.5c-1.1,6.2-2.2,12.3-3.1,18.5c-0.7,4.9-1.8,9.7-2.4,14.7
				c-0.4,3.6-1.1,7.2-1.5,10.8c-0.4,2.7-1.1,5.4-1.5,8.1c-0.6,3.5-1.2,7-1.8,10.5c-0.4,2.8-0.8,5.6-1.4,8.4
				c-0.5,2.1-0.5,4.2-1.1,6.3c-1,3.2-1,6.6-1.7,9.9c-0.6,3.1-1.1,6.2-1.4,9.3c-0.2,1.3-0.5,2.6-0.8,3.9c-0.8,3.3-0.8,6.7-1.7,9.9
				c-0.4,1.4-0.3,2.9-0.5,4.4c-0.4,3.5-1.2,6.8-1.7,10.3c-0.2,1.3-0.6,2.5-0.7,3.9c-0.1,0.8-0.8,1.4-1.8,1.5c-0.4,0-0.8,0-1.2-0.1
				c-1.7-0.3-3.5-0.2-5.2-0.1c-3.9,0.3-7.8-0.2-11.7,0c-0.5,0-1-0.1-1.5-0.1c-1.3-0.1-2.6-0.1-3.9-0.3c-1.2-0.2-1.7-0.7-1.5-1.8
				c0.5-4,0.4-8,0.8-12c0.4-3.8,0.4-7.6,0.6-11.4c0.2-3.9,0.6-7.9,0.8-11.8c0.2-2.9,0.6-5.8,0.5-8.7c0-2.1,0.5-4.2,0.7-6.3
				c0.2-2.8,0.4-5.6,0.6-8.4c0.3-3.6,0.6-7.2,1-10.7c0.4-3.1,0.7-6.2,0.9-9.3c0.3-3.7,0.9-7.4,1.3-11.1c0.4-4.2,0.9-8.4,1.4-12.6
				c0.3-2.4,0.7-4.8,1-7.2c0.9-7.3,2.3-14.5,3.1-21.8c0.6-4.8,0.9-9.6,1-14.5c0.1-6.3,0.4-12.6,0.5-18.9c0.1-5.8,0.3-11.6,0.2-17.4
				c0-1.8,0.3-3.7,0.3-5.5c-0.1-4.7,0-9.4-0.1-14.1c-0.1-3.7-0.4-7.3-0.4-11c0-3.8-0.4-7.5-0.1-11.3c0.1-0.9,0.2-1.8,0.1-2.7
				c0,3.3-0.4,6.7-0.9,10c-0.2,1.7-0.1,3.4-0.4,5.1c-0.5,2.5-0.4,5.1-0.8,7.6c-0.9,5.8-1.4,11.7-2,17.5c-0.6,6.3-1.4,12.5-2.1,18.8
				c-0.5,4.1-1.1,8.2-1.5,12.4c-0.2,2.6-0.5,5.2-0.8,7.9c-0.5,4-0.9,8-1.5,12c-0.8,5.7-2,11.3-3.1,16.8c-0.8,4-1.6,7.9-2.3,11.9
				c-0.8,5.1-2,10.2-2.9,15.3c-0.4,2.3-1.2,4.6-1.6,7c-0.9,5.7-2.7,11.2-3.9,16.8c-0.9,4.5-1.7,8.9-2.7,13.4
				c-0.9,4.2-1.6,8.4-2.4,12.6c-0.8,4.3-1.7,8.5-2.6,12.8c-1.1,5.4-1.8,10.9-2.6,16.3c0,0.2,0,0.3-0.1,0.5c-1.3,4.6-1.7,9.3-2.6,14
				c-0.2,1.3-1.2,2.2-2.5,2.3c-0.4,0-0.7,0.1-1.1,0.1c-2.6,0.3-5.1,0.2-7.6-0.1c-2.7-0.3-5.4-0.4-8.1-0.6c-1.2-0.1-2.3-0.5-3.4-0.6
				c-0.7,0.1-1.4-0.1-2.1-0.2c-2.5-0.2-3.2-1.1-3.2-3.7c0-1.4,0.1-2.9,0.2-4.3c0.3-4.9,0.3-9.9,0.8-14.8c0.3-3.2,0.7-6.5,1-9.7
				c0.2-2.1,0.5-4.2,0.6-6.3c0.1-2.9,0.7-5.8,0.9-8.7c0.5-6,1.4-11.9,2.1-17.8C20,506.3,21,499,22,491.8c1.4-9.7,3.2-19.3,4.6-29
				c0.9-5.8,2-11.6,2.8-17.4c0.6-4.4,0.8-8.7,0.5-13.1c-0.2-3.8-0.5-7.6-0.6-11.5c-0.1-4.3-0.3-8.6-0.6-12.9
				c-0.2-2.9-0.1-5.7-0.2-8.6c-0.2-3,0-6-0.3-9c-0.2-2.3-0.1-4.6-0.3-6.9c-0.3-4.5-0.6-9-0.6-13.5c-0.1-3.6-0.1-7.3-0.2-10.9
				c-0.1-3.7-0.2-7.4-0.5-11.1c-0.3-2.8-0.3-5.7-0.3-8.5c-0.1-4.2,0-8.5,0-12.7c0-4.5-0.1-9,0-13.5c0.1-6.6,0.3-13.2,0.5-19.8
				c0.2-5.1,0.7-10.3,1.2-15.4c0-0.5,0.2-0.9,0.5-1.1c1.3-0.5,2.7-0.1,4,0.1c1.7,0.2,3.4,0.4,5.1,0.5c2.1,0.1,4.2,0.4,6.3,0.3
				c2.2,0.5,4.4,0.5,6.6,0.3c2.3,0.6,4.7,0.2,7.1,0.5c0.7,0,1.4,0,2.1-0.1c3.4,0.4,6.9-0.1,10.3,0.1c0.3,0,0.5-0.1,0.7-0.2
				c0.8,0,1.6,0.1,2.5,0.1c0.4,0,0.8,0,1.2,0c3.2,0,6.5,0.1,9.7-0.2c0.8,0.2,1.6,0,2.4-0.1c0.8,0.1,1.5-0.1,2.3-0.1
				c0.3,0.1,0.5,0.2,0.8,0.1c2.2-0.4,4.5-0.2,6.8-0.5c0.9-0.1,1.8,0,2.6,0c0.8,0.1,1.6,0,2.3-0.3c0.2,0,0.4,0.1,0.7,0
				c2.8-0.4,5.7-0.8,8.6-0.7c0.4,0,0.8-0.3,1.2-0.3c0.6-0.1,1.1,0,1.7-0.2c0.6-0.2,1.2,0.1,1.8-0.2c2.5-0.3,4.9-0.4,7.4-0.7
				c0.5,0,1-0.1,1.4-0.2c0.8,0.1,1.8-0.2,2.5,0.4c0.4,0.9,0.3,1.9,0.4,2.8c0.3,4.3,0.3,8.7,0.4,13c0.1,3.8,0.1,7.5,0.1,11.3
				c0,0.4,0.1,0.8,0.1,1.2c0,1.6,0,3.1,0,4.7c0,1.3,0,2.6,0,3.8C127.6,314.5,127.4,316.5,127.7,318.5z"/>
			<path fill="#E3C779" d="M1.2,209c0.6-0.6-0.2-1.2,0-1.8c0.3-0.4,0.3-0.7,0-1c-0.1-1.7-0.4-3.4-0.1-5.1c0.2-1.2-0.2-2.2-0.1-3.3
				c0.1-0.7-0.1-1.5-0.2-2.2c-0.1-1-0.1-2-0.2-2.9c-0.1-3.2-0.2-6.4-0.3-9.6c0.3-0.1,0.6-0.1,0.9-0.1c0,0,0,0,0,0
				c-0.4,0-0.7,0-0.9-0.4c0-0.4-0.1-0.7-0.1-1c0.1-0.3-0.5-1,0.5-0.8c0.3,0.1-0.1-0.7,0-1c-0.1-0.2-0.2,0-0.3,0
				c-0.7-0.1-0.4-0.7-0.4-1c-0.1-1.8,0-3.6,0.2-5.4c0.3-0.2,0.1-0.4,0-0.6c0-0.5,0.3-1.1,0-1.6c0.2-0.2,0.3-0.5,0.6-0.5
				c0.2,0,0.4,0.1,0.6,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.7-0.3-0.8-0.7
				c0.2-3.4,0.4-6.7,0.7-10.1c0.1-0.9,0.6-1.4,1.4-1.5c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.4-0.2,0.4-0.4c0-0.1-0.1-0.2-0.2-0.3
				c-0.8-0.5-1.4-1.2-1.4-2.2c0.2-0.4,0.1-1.1,0.8-1.2c0.5,0,0.8,0.2,1,0.7c0,0,0,0.1,0,0c0-0.2,0.1-0.4,0.2-0.5
				c0.3-0.3,0.7-0.6,0.6-1.2c0-0.1,0.1-0.2,0.1-0.3c0.3-0.4,0.7-0.7,0.9-1.2c0.3-0.3,0.6-0.4,0.9-0.6c0.1,0,0.2,0.1,0.3,0.1
				c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.1c-0.4-0.1-0.6,0.5-1.1,0.3c-0.4-0.3-0.7-0.7-0.9-1.2c-0.7-0.8-0.1-1.4,0.3-2
				c0-0.3,0.1-0.5,0-0.8c0-0.3,0.1-0.5,0.1-0.8c0-0.1,0.1-0.2,0.1-0.3c0.4-0.7,0.5-1.5,0-2.2c-0.1-0.2-0.2-0.4,0-0.6
				c0.9-1.5,1.2-3.2,2-4.8c0.2-0.4,0.5-0.6,1-0.4c0.3,0.3,0.7,0.5,1.1,0.5c0-0.1,0-0.1-0.1-0.1c-0.3,0.1-0.6,0-0.8-0.2
				c-0.3-0.3-0.5-0.6-0.2-0.9c0.5-2.1,1.5-4,2.9-5.6c0.4-0.5,0.5-1.1,0.8-1.6c2.4-3.4,5.2-6.2,8.3-8.9c0.8-0.7,1.5-1.6,2.4-2
				c1.2-0.6,2-1.5,3-2.2c0.5-0.4,1-0.2,1.7,0.5c-0.6-1.5,0.5-1.7,1.1-2.1c0.4,0,0.9,0,1.3,0.2c0.1,0,0.2,0.1,0.3,0.1
				c0.3,0.4,0.7,0.6,0.9,1c0,0.3-0.1,0.4-0.3,0.6c-0.7,0.9,0.6,0.8,0.7,1.3c0.1,0.2,0,0.4-0.1,0.6c-0.4,0.8-1.4,1.1-1.7,1.9
				c-0.2,0-0.3-0.1-0.4-0.1c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.3,0,0.5-0.1c0.1-0.9,1.3-0.9,1.5-1.8c0.1-0.2,0.3-0.3,0.6-0.3
				c0.3,0,0.5,0.2,0.7,0.4c0.1,0.2,0.3,0.4,0.6,0.2c0.2-0.2,0.4-0.5,0.6-0.7c0.3-0.4,0.7-0.6,1.1-0.8c0,0,0-0.1,0-0.1
				c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.2-0.3-0.3-0.5c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.3-0.3-0.4
				c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.2-0.3-0.5-0.5-0.7c-0.2-0.2-0.5-0.2-0.7-0.2
				c-0.2-0.1-0.4-0.3-0.6-0.6c0-0.1,0-0.1,0-0.2c0.5-0.2,0.7-1.3,1.5-0.5c0.1,0.2,0.3,0.2,0.4,0c0.3-0.2-0.2-1.2,0.7-0.7
				c0.2,0.3,0.5,0.2,0.8,0.1c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c0-0.2,0.1-0.4,0.2-0.5
				c1.3-0.2,2.5-0.9,3.5-1.5c1.5-0.8,3.1-1.4,4.7-2c2.5-1.1,5.1-2.3,7.8-2.8c0.6-0.1,0.9,0.3,1.1,0.7c2.4,4,6.1,6.3,10.2,7.9
				c2.3,0.9,4.8,1.1,7.3,1.3c1.2,0.1,2.3,0.2,3.5,0.1c4.6-0.2,8.6-1.9,11.9-5.1c1.5-1.4,2.5-3.2,3.3-5c0.3-0.7,0.7-1.2,1.4-1.6
				c6.1,1.3,12,3.2,17.8,5.5c1.7,0.7,3.5,1.4,5.2,2.2c3.2,1.4,5.3,3.8,6.1,7.2c0.5,2.1,1.9,3.9,2.7,5.9c1.1,2.9,2.4,5.8,3.1,8.9
				c0.6,2.5,1.4,4.9,1.7,7.5c0.3,1.9,0.1,3.8,0.1,5.7c0.1,5.7,0.7,11.4,1.2,17.1c0.2,1.8,0.1,3.7-0.1,5.5c-0.3,3.4-0.4,6.8-0.6,10.3
				c-0.2,3.6-0.4,7.3-0.5,10.9c-0.3,5.3-0.6,10.6-0.6,15.9c0,2.7-0.2,5.3-0.4,8c-0.2,3.5-0.1,7.1,0,10.6c0.1,4.4,0.1,8.8,0.2,13.2
				c0.1,4.3,0.2,8.5,0.4,12.8c0.2,6.9,0.5,13.7,0.8,20.6c0.1,1.3,0.2,2.6,0.3,3.9c-0.8,0-1.6-0.1-2.4,0.2c-0.4-0.4-0.2-0.9-0.2-1.4
				c-0.4-4.3-0.7-8.7-1.2-13c-0.1-1.3-0.3-2.5-0.4-3.8c0-0.6-0.3-1.3-1-1.2c-0.8,0.1-0.9,0.7-0.8,1.4c0.7,5.2,0.8,10.4,1.3,15.6
				c0.1,1,0.7,1.6,1.1,2.5c-2.5,0.2-5,0.5-7.5,0.7c-0.2-0.2-0.5-0.1-0.7-0.3c-0.4-0.5-0.5-1.1-0.5-1.7c-0.2-2.8-0.5-5.5-0.8-8.2
				c-0.2-2.6-0.1-5.3-0.5-7.9c-0.1-0.8-0.6-1.2-1.4-1.2c-0.9,0-1.3,0.4-1.3,1.3c0.2,3.9,0.6,7.7,1,11.6c0.1,0.8,0.1,1.6,0.1,2.4
				c0,1.5,0,2.9,0.8,4.3c-3.3,0.3-6.7,0.6-10,0.9c-0.6-0.7-0.7-1.6-0.7-2.5c-0.1-3.4-0.4-6.7-0.3-10c0-2.1-0.1-4.2-0.4-6.3
				c-0.2-1.1-0.7-1.5-1.7-1.3c-0.8,0.1-1.1,0.6-1,1.7c0.5,3.9,0.6,7.8,0.7,11.7c0,1.6,0.3,3.1,0.2,4.7c0,0.9,0.5,1.4,1.4,1.4
				c0.5,0,1.3-0.1,1.4,0.8c-1.4,0.4-2.8,0.2-4.3,0.3c-2.9,0.2-5.9,0.3-8.8,0.4c-0.2-2.8-0.4-5.6-0.5-8.5c0-3.4-0.5-6.7-0.6-10
				c0-0.8-0.3-1.5-1.3-1.5c-0.8,0-1.3,0.6-1.3,1.6c-0.1,1.2,0.2,2.4,0.3,3.6c0.1,2.1,0.3,4.1,0.2,6.2c-0.1,2.1,0.2,4.2,0.4,6.3
				c0.1,0.8,0.2,1.5-0.1,2.2c-0.4,0.4-0.9,0-1.3,0.3c-3.3,0-6.6,0.3-9.8,0c-0.8-1.3-0.5-2.8-0.6-4.2c0-4.7-0.3-9.5-0.3-14.2
				c0-1.4-0.5-2-1.6-2c-1,0-1.4,0.3-1.5,1.3c-0.1,1.2-0.1,2.4,0,3.6c0.3,2.2,0.2,4.5,0.2,6.7c0,2,0.2,4.1,0.2,6.1c0,1,0,2-0.7,2.8
				c-1.8,0.3-3.6,0.2-5.4,0.2c-1.5,0-3,0-4.6,0c-0.1,0-0.3-0.1-0.4-0.2c1.9-2,1.1-4.5,1.1-6.8c0-3.7,0.1-7.3,0.1-11c0-0.3,0-0.6,0-1
				c-0.1-1-0.5-1.2-1.6-1.2c-0.9,0.1-1.1,0.6-1,1.4c0.1,1-0.1,2-0.1,3c0,3.9,0.4,7.8,0,11.7c-0.1,0.6,0,1.3,0.1,1.9
				c0.1,0.7,0.1,1.4-0.4,1.9c-2.4,0-4.9,0-7.3-0.3c-0.6-0.2-1.2,0.1-1.8-0.2c-0.6-0.6-0.7-1.4-0.7-2.2c0.3-5.4,0.3-10.7,0.7-16.1
				c0.1-1.3-0.3-1.7-1.6-1.8c-0.9,0-1.3,0.4-1.2,1.2c0.4,3.1,0,6.2-0.1,9.3c-0.1,2.7-0.3,5.3-0.2,8c0,0.5,0,1-0.5,1.4
				c-0.4,0.1-0.8,0.1-1.3,0c-3.8,0-7.5-0.4-11.3-0.8c-1.3-0.1-2.5-0.3-3.8-0.4c0.1-3.5,0.4-7.1,0.8-10.6c0.6-4.7,1.4-9.3,2.3-13.9
				c0.6-3.1,0.9-6.1,1.3-9.2c0.2-1.5-0.4-3-0.7-4.5c-0.7-4.4-1.5-8.8-2.4-13.1c-0.3-1.4-0.5-2.8-0.9-4.3c-0.5,0.6-0.1,1.2-0.1,1.8
				c-0.1,6.8-0.1,13.6-0.4,20.4c-0.1,2.9-0.5,5.7-0.8,8.5c-0.6,5.2-1.2,10.4-1.8,15.6c-0.7,5.6-1.4,11.3-2,16.9
				c-0.3,3.2-0.7,6.3-1.2,9.5c-1.4,1-2.9,0.3-4.3,0.1c-0.3-0.3-0.5-0.8-0.4-1.2c0.2-2.7,0.2-5.3,0.5-8c0.2-1.7,0-3.4,0.3-5
				c0.1-0.7-0.2-1.2-1-1.2c-0.8,0-0.9,0.6-0.9,1.2c0,3.9-0.6,7.7-0.6,11.6c0,0.8-0.1,1.5-0.5,2.2c-0.3,0.3-0.6,0.3-1,0.3
				c-1.6-0.3-3.1-0.5-4.7-0.8c-0.4-0.1-0.6-0.3-0.8-0.6c-0.7-1.6-0.2-3.2,0.1-4.7c0.4-2.8,0.5-5.6,0.9-8.4c0.1-0.6,0-1.2-0.7-1.3
				c-0.8-0.1-1,0.5-1.1,1.1c-0.4,1.7-0.2,3.5-0.5,5.2c-0.5,2.6-0.6,5.2-1.3,7.8c-1.1,0.6-2.1,0.4-2.9-0.5c-0.1-4.8-0.3-9.7-0.4-14.5
				c-0.1-5.9-0.2-11.8-0.1-17.6c0.1-6.3-0.2-12.5-0.2-18.8c0-3,0.1-6.1-0.2-9.1c-0.5-4.6-0.6-9.1-1.1-13.7c-0.3-2.9-0.6-5.7-0.6-8.6
				C1.6,209.2,1.3,209.2,1.2,209z"/>
			<path fill="#C1A890" d="M38.1,579.8c-0.5,3-1.2,6-1.6,9c-0.1,1.1,0.1,2.5,1.6,3c-0.9,1-2.3,1.4-3.5,2c-3.4,1.7-7.1,1.9-10.8,1.6
				c-1.8-0.1-3.5-0.2-5.2-0.8c-0.4-0.1-1-0.1-0.9-0.8c1.2-3.4,1-6.9,1-10.5c0-1.5,0.1-3.1,0.2-4.6c1.2-0.2,2.4,0.3,3.5,0.4
				c3.3,0.2,6.5,0.7,9.7,0.8C34.2,580,36.2,580.3,38.1,579.8z"/>
			<path fill="#C1A890" d="M88.3,577.9c-0.9,0.8-0.6,2-0.9,3c-0.6,2.3-1,4.6-1.2,7c0,0.6-0.3,0.9-0.8,1.1c-2.4,1-4.8,1.9-7.3,2.1
				c-2.7,0.2-5.3-0.2-7.8-1.4c-0.4-0.2-0.7-0.4-0.8-0.8c0.4-2.6,0.3-5.3,0.2-7.9c0-1,0.2-2,0.1-3.1c3.2-0.1,6.3,0,9.5,0.1
				c1.4,0.1,2.8,0,4.1-0.1C85.1,577.8,86.7,577.8,88.3,577.9z"/>
			<path fill="#DBB22B" d="M70.1,278.9c0.2-4.5-0.1-9.1-0.1-13.6c0-2-0.1-4-0.2-6c-0.1-1.2,0.4-1.6,1.5-1.5c0.2,0,0.3,0,0.5,0
				c2.1,0.1,2.3,0.3,2.3,2.4c0.1,5,0.1,10,0.2,14.9c0,1.3,0.4,2.5,0,3.8c-0.4,0-0.7,0-1.1,0c-0.8,0.3-1.6-0.5-2.5,0
				C70.5,278.9,70.3,278.9,70.1,278.9z"/>
			<path fill="#DBB22B" d="M85.5,278.6c-0.5-0.9-0.4-2-0.4-2.9c-0.2-2.3-0.4-4.5-0.3-6.8c0.1-2.3-0.2-4.7-0.4-7
				c-0.1-1-0.1-1.9,0-2.9c0.1-0.7,0.4-0.9,1.1-1c2.2-0.3,2.5,0,2.6,2.2c0.3,5.7,0.5,11.5,1,17.2c0.1,0.6-0.1,1-0.6,1.3
				c-0.6,0.1-1.2,0.2-1.7,0C86.3,278.8,85.9,278.9,85.5,278.6z"/>
			<path fill="#DBB22B" d="M101.5,277.9c-0.6-0.3-1.2-0.3-1.8-0.1c-1.3,0.4-1.5-0.4-1.6-1.3c-0.1-1.8-0.2-3.6-0.3-5.4
				c-0.1-4.1-0.3-8.3-0.7-12.4c-0.2-1.6,0-1.6,1.6-1.6c0.4,0,0.9,0,1.3,0c0.7,0,1,0.4,1,1c0.1,3.6,0.4,7.2,0.4,10.9
				c0,2.7,0.1,5.3,0.5,8c0,0.3,0.1,0.6-0.1,0.9C101.8,277.9,101.7,277.9,101.5,277.9z"/>
			<path fill="#DBB22B" d="M57.9,278.9c-0.2-1.4-0.4-2.8-0.3-4.1c0.3-3.2,0-6.4,0-9.7c0-1.8,0-3.7,0-5.5c0-1,0.3-1.6,1.4-1.3
				c0.1,0,0.2,0,0.4,0.1c0.6,0.1,1.4-0.5,1.8,0.1c0.5,0.7,0.3,1.5,0.3,2.3c-0.1,4.2-0.2,8.5-0.1,12.7c0,1.2,0,2.4,0.1,3.6
				c0.1,1.4-0.8,1.6-1.8,1.8C59.1,279,58.5,279.2,57.9,278.9z"/>
			<path fill="#DBB22B" d="M112,277c-0.7,0-1-0.3-1.1-1c-0.2-1-0.2-1.9-0.2-2.9c0-3.4-0.6-6.7-0.8-10c-0.1-1.8-0.2-3.5-0.4-5.3
				c-0.1-0.8,0.3-1.1,1.1-1.1c2.6,0,2.8,0,2.8,2.6c0.1,3.1,0.4,6.2,0.6,9.3c0.1,2,0.4,4,0.6,6c0.1,0.6,0.1,1.2,0.1,1.8
				c-0.4,0.2-0.8,0.2-1.2,0.5C113,276.9,112.5,277,112,277z"/>
			<path fill="#DBB22B" d="M122.7,276c-1.1-0.1-1.5-0.6-1.6-1.8c-0.5-5.3-0.6-10.7-1.3-15.9c-0.1-0.8-0.5-1.8,0.6-2.3
				c1.5-0.6,2.3-0.2,2.4,1.4c0.5,5.4,1,10.7,1.5,16.1c0.1,0.8,0.6,1.7-0.3,2.5C123.6,276,123.2,276,122.7,276z"/>
			<path fill="#D6BA6E" d="M48.9,278.4c0.6,0,1.2-0.1,1.7,0.2c-2.3,0.1-4.5-0.2-6.7-0.4c0.4,0,0.8,0,1.2,0
				C46.2,277.1,47.5,277.2,48.9,278.4z"/>
			<path fill="#E0C062" d="M70.8,278.9c0.8-0.5,1.7,0,2.5,0C72.4,279.2,71.6,278.8,70.8,278.9z"/>
			<path fill="#CEB677" d="M85.5,278.6c0.4,0,0.9,0,1.3,0c-0.8,0.5-1.7,0.2-2.5,0.2C84.6,278.6,85,278.7,85.5,278.6z"/>
			<path fill="#D5BA71" d="M113.5,276.9c0.3-0.4,0.7-0.4,1.2-0.5c0.2,0.1,0.3,0.2,0.5,0.2C114.6,276.8,114,276.8,113.5,276.9z"/>
			<path fill="#C1A890" d="M89,104.2c-1.1,2.6-2.3,5.1-4.3,7.1c-2.4,2.4-5.3,3.8-8.5,4.6c-4.3,1.1-8.5,0.4-12.7-0.5
				c-3.7-0.8-6.9-2.7-9.7-5.2c-1.4-1.3-2.4-2.9-3.4-4.5c1.9-1.1,4.1-1.5,6.2-2.3c1-0.4,1.3-0.9,1.3-1.8c0-5.7,0.1-11.4,0-17.1
				c-0.1-3.7-0.1-7.4-0.2-11c0-0.4,0.2-0.8-0.4-1c-0.3-0.4,0-0.6,0.2-0.8c1.2-0.6,1.9-1.6,2.6-2.8c0.1-0.1,0.1-0.2,0.2-0.3
				c0.4-0.8,0.6-1.6,1-2.4c0.2-0.2,0.3-0.4,0.3-0.7c0.4-0.9,0.7-1.9,1.2-2.8c0-0.1,0.1-0.2,0.1-0.3c0.1-0.9,0.7-1.7,0.6-2.6
				c0-0.2-0.2-0.3-0.3-0.5c-0.9-0.3-1.9-0.6-2.6-1.4c-0.1-0.1-0.2-0.1-0.2-0.2c-0.5-0.5-0.9-1.1-1.4-1.6c-0.1-0.1-0.1-0.2-0.2-0.3
				c-0.3-0.4-0.8-0.7-0.9-1.2c-0.1-0.2-0.2-0.5-0.3-0.7c-0.8-1.7-0.4-3.5-0.4-5.2c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.2-0.3,0.4-0.4
				c1-1.3,2.4-1.6,3.9-0.8c1.1,0.5,2,1.3,2.5,2.4c0.2,0.6,0.6,1,1,1.5c0.5,0.5,0.8,1.5,1.5,1.4c1-0.1,1.9-0.7,2.6-1.5
				c0.7-0.7,0.3-1.6,0-2.4c-1.1-2.6-1.7-5.2-1.7-8c0-0.2,0-0.4,0-0.6c-0.1-0.4-0.1-0.9,0-1.3c0.1-0.4,0.1-0.8,0.1-1.1
				c0-2.6,0.8-5,1.6-7.3c1-3,3.1-5.2,5.7-6.9c3-2,6.1-3.5,9.6-4.4c1.6-0.4,3.1-1,4.4-2c0.3-0.3,0.7-0.4,1.1,0
				c2.9,3,4.5,6.8,5.6,10.8c1.1,4.1,2.1,8.3,3.4,12.5c1.1,3.8,2.8,7.3,4.7,10.7c0.3,0.4,0.4,0.9,0.6,1.4c0.5,1.4,0.2,2.3-1,3.1
				c-0.4,0.3-0.8,0.7-1.3,0.9c-1.9,0.9-2.2,2.5-2.3,4.3c0,1.8,0,1.8-1.8,1.6c-1.5-0.2-3.1-0.4-4.6-0.8c-0.4-0.1-0.8-0.4-1.1,0
				c-0.3,0.4,0,0.9,0.2,1.2c1.2,2.2,3,3.9,5.5,4.5c0.9,0.2,1.2,0.6,1,1.5c-0.4,1.9-0.2,3.8-0.9,5.6c-1.2,3.2-3.9,4.8-6.8,6
				c-2.6,1.1-5.4,1.3-8.2,1.4c-0.8,0-1.6,0.3-2.4,0.2c-0.7-0.1-0.5,0.5-0.5,0.8c0.2,2.6,0.5,5.2,0.8,7.8c0.4,3.9,0.7,7.8,1.1,11.7
				c0.1,0.9,0.4,1.3,1.3,1.6C85.2,103.4,87.2,103.5,89,104.2z"/>
			<path fill="#625B52" d="M61.6,66.5c-0.2,0.9-0.7,1.7-1.2,2.4c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.1c-0.9,1.3-1.6,2.8-3.3,3.4
				c-1.4,0.7-2.8,1.2-4.4,1.4c-0.3-0.2-0.6-0.3-0.9,0c-1.1,0.5-2,0-2.9-0.4c0-0.1,0.1-0.1,0.1-0.2c0,0.1-0.1,0.1-0.1,0.2
				c-2.6-0.9-4.5-2.7-5.9-4.9c-1.4-2.1-3-3.7-5.4-4.5c0-0.3-0.1-0.4-0.4-0.3c-2.4-1.1-4.4-2.8-5.5-5.3c0.1-0.3,0.3-0.4,0.6-0.6
				c0.3-0.1,0.6-0.2,0.7-0.5c0.1-0.3,0.3-0.6,0.6-0.8c0.2-0.1,0.3-0.1,0.5,0c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.2,0,0.6,0.4,0.8
				c0.2,0.1,0.4,0.1,0.5,0.3c0.5,0.5,0.1,1.1,0.4,1.6c0,0.2,0-0.1,0,0.1c0,0,0-0.1,0-0.1c-0.2-0.5,0.1-1.2-0.4-1.6
				c-0.7-0.2-0.7-0.9-1.1-1.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.4-0.1-0.7,0.2-1.1c0.1-0.3,0.2-0.5,0.1-0.8c-0.1,0-0.1-0.1-0.2-0.1
				c-0.5,0-0.4,0.5-0.6,0.8c-0.2,0.3-0.4,0.5-0.8,0.5c-0.4,0-0.7-0.1-1,0.2c-0.3,0.1-0.6,0.2-0.8-0.1c-0.3-1.8,0-3.5,0-5.3
				c0.2-0.3,0.2-0.6,0.5-0.8c0.4-0.2,0.2-0.7,0.4-1c0.1-0.1,0.1-0.2,0.2-0.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.4-0.2-0.7-0.3-0.9
				c0-0.1,0-0.2,0-0.2c-0.1,0,0,0.1-0.1,0.1c0,0.1,0,0.3,0,0.4c0,0.3,0.3,0.5,0.4,0.7c0,0.2,0,0.3-0.1,0.4c-0.2,0.2-0.4,0.5-0.5,0.8
				c-0.2,0.3-0.3,0.8-0.8,0.8c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.3,0-0.5-0.2-0.7c-0.2-0.3-0.3-0.6-0.5-0.9c0.1-0.6-0.2-1.2,0.4-1.7
				c0.2-0.2,0.2-0.4,0.1-0.6c-0.2-0.4-0.5-0.1-0.8,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.7,0c-0.1,0-0.2,0-0.3-0.1
				c-0.3-0.2-0.7-0.2-0.8-0.6c-0.1-0.6,0.5-0.8,0.6-1.2c-0.7-1-1.2-2.1-1.5-3.2c0,0,0-0.1,0-0.1c-0.2-0.2-0.4-0.2-0.6-0.1
				c0,0,0,0,0,0s0.1,0,0.1,0c0.2,0,0.3,0.2,0.4,0.3c0.1,0.3,0.2,0.5,0.2,0.8c0,0.1,0,0.2-0.1,0.3c-0.4,0.5-0.9,0.6-1.4,0.4
				c-0.3-0.2-0.5-0.4-0.8-0.5c-0.5-0.8-0.9-1.7-0.9-2.7c0.1-0.2,0.2-0.3-0.1-0.4c-0.4-0.7-0.3-1.4,0-2.1c0.4,0.1,0.7,0.2,1.1,0.3
				c0.2,0.1,0.3,0.2,0.3,0.4c0,0,0,0.1,0,0.1s0-0.1,0-0.1c-0.1-0.2,0-0.3,0-0.5c0.3-0.3,0.3-0.7,0.4-1.1c-0.1,0.2-0.2,0.4-0.4,0.4
				c-0.4,0-0.6-0.2-0.8-0.6c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.1-0.1-0.2-0.2-0.4c0.2-1.3,0.7-2.5,1.4-3.6c0.3-0.1,0.5-0.4,0.6-0.6
				c0.3-0.3,0.3-0.7,0.4-1c0.4-0.7,0.8-1.5,1.1-2.2c0.1-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0-0.1,0-0.1
				c-0.3-0.3-0.2-0.6-0.1-0.9c0.1-0.2,0.3-0.4,0.4-0.7c0.2-0.5-0.3-0.6-0.5-0.8c-0.2-0.2-0.2-0.5-0.2-0.8c0-0.1,0-0.2,0-0.3
				c0.2-0.5,0.8-0.5,1.1-0.9c0.2-0.2,0.5-0.3,0.8-0.5c0.3-0.2,0.5-0.5,0.7-0.8c0.1-0.3,0-0.7,0.4-0.9c0.5-0.2,0.9-0.5,1.4-0.6
				c0.4,0.1,0.6,0.5,1,0.7c0.1,0,0.2,0,0.3,0c0.2-0.1,0.4-0.2,0.6-0.3c0.2,0,0.3,0,0.4,0.1c0,0,0,0,0,0s0,0,0,0
				c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.3-0.6-0.7-0.6c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.2,0-0.3,0
				c-0.2,0-0.5,0-0.7,0.2C31.2,20,30.8,20,30.4,20c-0.5,0.2-0.4,0.6-0.4,1c-0.1,0.3-0.3,0.6-0.6,0.8c-0.3,0.2-0.6,0.3-1,0.2
				c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2-0.3-0.4-0.5-0.5C27.3,21.3,27,21.3,27,21c0-0.3-0.1-0.6,0.1-0.8l0,0c0.2-0.2,0.2-0.3,0-0.5
				c0.2-1.4,0-2.9,0.9-4.1c0.2-0.1,0.1-0.4,0.2-0.5c0.4-0.5,0.7-1.1,1.2-1.4c0.4,0.1,0.7,0.4,1.1,0c0.3-0.4-0.1-0.7-0.2-1
				c1.5-2,3.8-2.6,6.1-3.1c0.7-0.2,1.4-0.2,2.1-0.2c0.1,0.1,0.3,0.3,0.4,0.4c-0.1-0.1-0.3-0.2-0.4-0.4c0.7-0.4,1.4-0.3,2.1-0.6
				c0.2,0.1,0.4-0.1,0.5-0.2c1.7-0.9,2.6-2.5,3.8-3.9c0.2-0.2,0.4-0.4,0.4-0.7c2.7-3.1,6.1-4.3,10.2-4c2.6,0.2,4.9,1.2,7.2,2.4
				c3,1.6,6.1,1.7,9.3,0.5c2.7-1,5.4-2,8.3-2c3,0,5.8,0.8,8.3,2.5c2.5,1.6,3.4,4.2,3.8,6.8c0.3,1.8-0.2,3.6-1.5,5.1
				c-0.3,0.3-0.5,0.7-0.8,1c-2.6,2.1-5.9,2.6-8.8,3.8c-4.5,1.8-8.7,4.2-11,8.7c-1.2,2.4-1.7,5-2.1,7.7c0,0.2-0.1,0.4-0.1,0.6
				c-0.3,0.5-0.3,1.1-0.8,1.5c-0.2,0.1-0.5,0.2-0.7,0.4c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0s0.1,0,0.1,0c0.2,0.1,0.4,0.2,0.7,0.2
				c0.1,0,0.2,0.1,0.3,0.1c0.2,0.2,0.5,0.4,0.6,0.8c0.4,2.4,0.7,4.9,1.5,7.2c0.3,0.8,0.5,1.6,0.8,2.4c0.3,0.6,0,1.1-0.4,1.4
				c-0.9,0.6-1.8,1.2-2.9,1.4c-1,0.2-1.4-0.7-2-1.3c-1.1-1.1-1.6-2.7-3-3.7c-1.4-1-2.8-0.9-4,0.4c-0.2,0-0.3,0-0.3,0.2
				c-0.9,2-0.8,4,0,5.9c-0.2,0.3-0.1,0.4,0.2,0.5c0.3,0.4,0.6,0.8,0.9,1.2c-0.1,0.1-0.1,0.2,0.1,0.2c0.5,0.6,1,1.1,1.4,1.7
				c-0.1,0.1-0.1,0.1-0.1,0.2c0-0.1,0.1-0.1,0.1-0.2c0.8,0.9,2.1,0.8,3.1,1.5c-0.1,0.2,0.1,0.4,0.2,0.5c0.3,1.2-0.3,2.1-0.7,3.1
				c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0.1,0.1c-0.3,1-0.6,2-1.2,2.9C61.7,66,61.5,66.2,61.6,66.5z"/>
			<path fill="#C1A890" d="M4.1,291.8c0.9,0.2,1.8,0,2.6,0.5c1.1-0.7,2.1-0.1,3.1,0.3c2,0.6,4.1,0.5,6,1.2c1-0.6,1.8-0.6,2.9,0.2
				c1.4,0.1,2.8,0.2,4.3,0.2c-0.5,3.6-1,7.3-1.3,10.9c-0.3,2.8-1,5.5-1.4,8.4c-0.3,3.1-0.9,6.2-1.6,9.3c-0.3,1.3,0.4,2.5,0.9,3.7
				c1,2.4,1.1,4.9,1.4,7.4c0.4,4.7,0.2,9.4,0.8,14c0.1,1.1,0.1,2.3,0.3,3.4c0.1,0.4,0,0.9-0.2,1.3c-0.3,0.6-0.7,1.1-1.5,0.4
				c-0.9-0.7-1.6-1.6-2.4-2.5c0,1.8-0.1,3.6,0,5.4c0.2,2.9,0.6,5.8,1.2,8.7c0.4,1.9-0.7,2.8-2.6,2.4c-0.9-0.2-1.7-0.1-2.6-0.3
				c-2.3-0.3-4.1-1.7-5.9-3c-2.3-1.6-3.3-3.9-3.9-6.4c-0.9-4.1-1.9-8.2-2.6-12.3c-0.6-3.5-0.1-7,0.6-10.4c1.3-6.8,2.4-13.6,2.4-20.6
				c0-4.1,0.1-8.2-0.1-12.3C4.2,298.4,4.3,295.1,4.1,291.8z"/>
			<path fill="#615952" d="M17.7,593.7c3.1,0.9,6.3,1.4,9.6,1.1c2.4-0.2,4.7-0.5,6.9-1.5c0.7-0.3,1.4-0.7,2-1
				c0.6-0.3,1.2-0.5,1.8-0.5c0.5,0.1,0.6,0.6,0.9,0.9c1.6,2.5,3,5,4.7,7.4c2.3,3.1,4.7,6.3,8,8.5c3.4,2.3,6.9,4.5,10.3,6.7
				c1,0.7,1.9,1.5,2.2,2.7c0.4,1.7-0.9,2.9-2.8,2.9c-1.8,0-3.6,0-5.5,0c-1.5,0-2.9,0.1-4.4,0.2c-1,0-2.1-0.2-3.1,0
				c-1.8-0.1-3.6-0.1-5.3-0.4c-3.3-0.6-6.1-1.9-8.8-3.9c-3-2.3-6.3-4.2-9.1-6.6c-0.5-0.4-1.1-0.3-1.6-0.3c-1.7-0.2-3.5-0.4-5.2-0.5
				c-0.8,0-1.1-0.2-1.3-1c-1.3-4-1.3-8.1-0.4-12.2c0.1-0.6,0.3-1.2,0.4-1.8C17.1,594,17.2,593.7,17.7,593.7z"/>
			<path fill="#E3C779" d="M2.1,152.4c0-0.2,0-0.3,0-0.5c0.3-0.3,0.1-0.6,0.2-1c0.3-1.1,0.5-2.2,0.8-3.3c0.6-0.5,1-0.1,1.5,0.3
				c0.2,0.4-0.1,0.6-0.3,0.8c-0.6,0.4-0.3,0.8,0.2,1.1c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0.1,0.1,0.4,0.2,0.5,0.4
				c0.1,0.2,0.2,0.4,0.1,0.6c-0.3,0.4-0.2,1-0.7,1.3l0,0c-0.2,0.5-0.2,1.1-0.8,1.3c-1.1,0.1-1.5-0.2-1.4-1.2
				C2.3,152.8,2.3,152.6,2.1,152.4z"/>
			<path fill="#56514A" d="M26.9,40.3c0.1,0.8,0.3,1.7,0.8,2.2c0.4,0.4,0.5,0.7,0.5,1.2c-0.2,0.4-0.5,0.3-0.8,0.2
				c-0.2-0.1-0.4-0.2-0.7-0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.4-0.2-0.7-0.2c0,0-0.1,0-0.1,0
				c-0.5-0.3-0.9-0.8-1-1.4c0.1-0.2,0.3-0.3,0.5-0.3c0.3-0.5,0.8-0.2,1.1-0.2c0.3,0,0.2-0.2,0.2-0.4c-0.1-0.2-0.2-0.4-0.4-0.6
				c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.1-0.1-0.2,0-0.3c0.4-0.6,0.9-0.5,1.4-0.2C27,40,27,40.1,26.9,40.3z"/>
			<path fill="#59534C" d="M32.4,55.4c0.3,0,0.6-0.2,0.7-0.5c0.3-0.2,0.6-0.2,0.9-0.3c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2
				c0,0.2-0.1,0.4-0.2,0.6c-0.3,0.3-0.7,0.5-0.6,1c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.3,0.2,0.8-0.3,1c-0.3,0.1-0.6,0.4-1,0.3
				c-0.3,0-0.6,0.2-0.9-0.1c-0.3-0.3-0.4-0.8-0.3-1.2c0.1-0.2,0.3-0.4,0.4-0.6C31.7,55.9,31.9,55.6,32.4,55.4z"/>
			<path fill="#E5CC85" d="M4.8,143.3c1.1,1.2,1.1,1.9,0.3,3.1c-0.2-0.1-0.4-0.3-0.4-0.4c0,0.1,0.2,0.3,0.4,0.4
				c0.1,0.2,0.2,0.4,0.3,0.7c-0.7,0.4-1.3,0.4-1.6-0.5C4,145.5,4.4,144.4,4.8,143.3z"/>
			<path fill="#E4C97F" d="M2.1,152.4c0.1,0.1,0.4,0.3,0.4,0.4c0,0.9,0.4,1,1,0.4c0.4,0.3-0.4,0.6,0.1,0.8c0.2,0,0.3,0,0.2,0.3
				c-0.1,0.5-0.1,0.9-0.2,1.4c-0.2,0-0.3-0.1-0.3-0.3c-0.2-0.5-0.3-1-0.9-1c-0.6,0-0.5,0.6-0.8,0.9c-0.1-0.2-0.1-0.3,0-0.5
				C1.8,154,2,153.2,2.1,152.4z"/>
			<path fill="#58524B" d="M28.2,45.1c0.3,0,0.5,0,0.8,0c0.2,0.3,0.4,0.4,0.7,0.3c0.2-0.1,0.5-0.1,0.7-0.1c0.6,0.3,0.3,0.9,0.4,1.3
				c-0.4,0.1-0.4-0.5-0.8-0.3c-0.3,0.2-0.2,1-0.9,0.7c-0.7-0.3-1.2-0.9-1.7-1.5c0,0,0-0.1,0.1-0.1C27.8,45.3,28.1,45.4,28.2,45.1z"
				/>
			<path fill="#F5EBD4" d="M3.6,146.6c0.5,0.2,1.2,0,1.6,0.5c0,0.4,0,0.7,0,1.1c-0.3,0.1-0.5,0.2-0.8,0c-0.6,0.2-0.8-0.5-1.3-0.5
				C3.3,147.3,3.5,146.9,3.6,146.6z"/>
			<path fill="#E7CE8B" d="M29.1,115.9c-0.5,0-1.2,0.4-1.3-0.5c0.9-0.5,1.6-1.3,2.7-1.4c0,0,0,0,0,0c0.1,0.2,0.3,0.3,0.4,0.5
				c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.2-0.2,0.3C30,116.2,29.5,115.6,29.1,115.9z"/>
			<path fill="#E6CD89" d="M1,158.2c0-0.4,0.1-0.8,0.1-1.2c0.2-0.2-0.1-0.7,0.4-0.8c0.4-0.1,0.6,0.2,0.7,0.5
				c0.2,0.5,0.7,0.5,1.1,0.6c0.2,0.1,0.3,0.3,0.3,0.6c-0.2,0.3-0.4,0.3-0.7,0.3c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.2,0.2-0.3,0.3
				c-0.1,0.1-0.2,0.2-0.3,0.3C1.6,158.9,1.3,158.6,1,158.2z"/>
			<path fill="#524D47" d="M30.4,12.7c0.6-0.2,1,0.1,0.9,0.7c0,0.6-0.5,1.1-1,1.2c-0.6,0.1-0.7-0.5-0.8-0.9c0,0,0,0,0,0
				c0.3-0.2,0.5-0.3,0.5-0.7C30.1,12.9,30.3,12.8,30.4,12.7z"/>
			<path fill="#F5ECD5" d="M3.3,157.4c-0.5-0.1-1-0.3-1.3-0.7c-0.4-0.6-0.6-0.1-0.8,0.2c0-0.8,0.1-1.5,0.5-2.2c0,0.2,0,0.3,0,0.5
				c0.7,0.4,0.7,1.6,1.7,1.7c0.2,0.1,0.3,0.2,0.3,0.3C3.6,157.4,3.5,157.5,3.3,157.4z"/>
			<path fill="#58524B" d="M23.5,34.3c0.2,0,0.3,0,0.5,0c0.2,0.2,0.1,0.5,0.2,0.7c0.1,0.3,0.2,0.6,0.5,0.8c0.2,0.2,0.1,0.5,0,0.7
				c-0.2,0.2-0.4,0.2-0.7,0.2c-0.5,0-0.5-0.4-0.7-0.7C23.2,35.4,23,34.8,23.5,34.3z"/>
			<path fill="#F6EFDC" d="M0.5,192.7c0.2,1.1,0.7,2.2,1.1,3.2c-0.5,0.2-0.6-0.3-0.9-0.3C0.2,194.7,0.5,193.7,0.5,192.7z"/>
			<path fill="#665F57" d="M27.1,20.1c0,0.3,0,0.6,0,1c0.2,0.2,0.5-0.1,0.7,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.4,0.4,0.7,0.2,1.1
				c-0.1,0.2-0.3,0.3-0.6,0.3c-0.4-0.1-0.5-0.4-0.8-0.6C26.9,21.6,26.6,20.8,27.1,20.1z"/>
			<path fill="#57514A" d="M28.1,25.9c-0.1,0.2-0.2,0.5,0,0.7c0,0.4-0.2,0.4-0.5,0.2l0,0c-0.2-0.1-0.3-0.3-0.4-0.5
				c0-0.4,0-0.8,0-1.2c0.2-0.2,0.3-0.4,0.5-0.4c0.3,0,0.5,0.2,0.6,0.5C28.2,25.5,28.1,25.7,28.1,25.9z"/>
			<path fill="#EDDCAE" d="M1,158.2c0.4-0.1,0.6,0.5,1.1,0.2c0.3-0.1,0.4,0,0.5,0.3c-0.5,0.4-1.5,0.2-1.7,1.2
				C1,159.3,1,158.7,1,158.2z"/>
			<path fill="#625C54" d="M26.4,43.4c0.1,0,0.1,0,0.2,0c0.4,0.3,0.5,0.7,0.4,1.2c-0.1,0.2-0.2,0.3-0.4,0.3
				c-0.6-0.3-0.9-0.9-1.2-1.4c0,0,0.1-0.1,0.1-0.1C25.8,43.2,26.1,43.2,26.4,43.4z"/>
			<path fill="#EBD7A2" d="M7.6,137.3c0.1,0.3,0.2,0.6,0.2,1l0,0c-0.4,0-0.8,0-1.2,0.3C6.9,138,6.8,137.2,7.6,137.3z"/>
			<path fill="#F7F0DF" d="M1.2,206.1c0.4-0.1,0.8-0.2,0.9,0.3c0.1,0.6-0.4,0.7-0.9,0.7C1.2,206.8,1,206.5,1.2,206.1z"/>
			<path fill="#625B52" d="M30,13c0.2,0.3,0.9,0.1,0.7,0.6c-0.3,0.7-0.8,0.2-1.2,0.1C29.6,13.4,29.7,13.2,30,13z"/>
			<path fill="#58534D" d="M37.2,63.8c-0.1-0.3-0.2-0.6-0.3-0.9c0.4,0.3,1.3,0.3,0.7,1.2C37.5,64,37.3,63.9,37.2,63.8z"/>
			<path fill="#3F3E3B" d="M25.9,29c0.3,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,0.2-0.5,0.1c-0.2-0.1-0.1-0.3-0.2-0.5
				C25.5,29.5,25.6,29.2,25.9,29z"/>
			<path fill="#080807" d="M31.4,50.1c-0.2,0.2-0.5,0.3-0.7,0.5c0-0.2-0.1-0.4-0.1-0.7c0.2-0.3,0.3-0.7,0.5-1
				c0.5-0.1,0.4,0.4,0.5,0.7C31.7,49.8,31.6,50,31.4,50.1z"/>
			<path fill="#151515" d="M29.2,47.1c0.3-0.2,0.6-0.5,0.9-0.7c0,0.5,0.2,1.1-0.4,1.4C29.5,47.6,29.4,47.3,29.2,47.1z"/>
			<path fill="#393734" d="M31.9,56.6c-0.2,0.2-0.5,0.3-0.8,0.3c-0.2-0.3-0.4-0.6-0.4-1c0.2-0.1,0.4-0.2,0.7-0.3
				C31.9,55.8,31.8,56.3,31.9,56.6z"/>
			<path fill="#454340" d="M26.6,44.9c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.1,0.4-0.1,0.5,0c0.2,0.1,0.5,0.3,0.7,0.4
				c0,0.3-0.3,0.3-0.5,0.4c0,0,0,0.1-0.1,0.1C27.1,45.5,26.9,45.2,26.6,44.9z"/>
			<path fill="#EFDFB7" d="M0.9,170.9c-0.2,0.1-0.5,0.2-0.7,0.3c0-0.4,0-0.8,0-1.2c0.3,0.2,0.6,0.3,0.9,0.5
				C1.2,170.6,1.1,170.7,0.9,170.9z"/>
			<path fill="#423F3B" d="M28.3,15.1c0.3,0.2,0.8,0,1,0.6c-0.4-0.3-0.8,0.7-1.3-0.1C28,15.4,28.1,15.2,28.3,15.1z"/>
			<path fill="#020302" d="M23.2,38.5c0.1-0.3,0.3-0.3,0.6-0.2c0.3,0.2,0.2,0.4,0.1,0.6c-0.2,0.4-0.4,0.1-0.6,0
				C23.3,38.7,23.2,38.6,23.2,38.5z"/>
			<path fill="#31302D" d="M24.9,41.8c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2-0.1-0.2-0.3-0.2-0.5c0.3-0.1,0-0.6,0.5-0.6
				C24.7,41.2,24.8,41.5,24.9,41.8z"/>
			<path fill="#161615" d="M51.8,73.9c0-0.4,0.4-0.8,0.7-0.8c0.4,0,0.1,0.5,0.3,0.7C52.4,73.9,52.1,73.9,51.8,73.9z"/>
			<path fill="#EFDFB6" d="M34.3,113.5c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.2-0.3-0.5-0.5-0.8c0.1-0.3,0.4-0.5,0.7-0.5
				c0.1,0.2,0.1,0.3,0.2,0.5C34.6,112.9,34.5,113.2,34.3,113.5z"/>
			<path fill="#48443F" d="M27.6,24.9c-0.2,0.1-0.3,0.2-0.5,0.3c0-0.2,0-0.5,0-0.7c0.3-0.2-0.2-0.9,0.5-1c0,0.3,0,0.5,0.3,0.7
				C28,24.5,27.9,24.7,27.6,24.9z"/>
			<path fill="#FBF9F4" d="M0.2,182.6c0.4,0.2,1,0,1.3,0.5c-0.1,0.1-0.3,0.1-0.4,0c-0.3,0.4-0.6,0.3-0.9-0.1
				C0.2,183,0.2,182.8,0.2,182.6z"/>
			<path fill="#F5ECD5" d="M0.5,179.8c0.8-0.5,0.8-0.5,1.1,0.4c-0.4,0-0.5-0.5-0.9-0.5C0.6,179.8,0.5,179.8,0.5,179.8z"/>
			<path fill="#312F2D" d="M23.3,36c0.2,0.2,0.5,0.3,0.7,0.5c0.2,0.1,0.4,0.3,0.2,0.6c-0.4-0.1-0.8-0.1-1-0.6
				C23.2,36.3,23.2,36.1,23.3,36z"/>
			<path fill="#F2E6C8" d="M0.2,172.8c0.3,0.1,0.7,0.1,1.1,0.2c-0.3,0.4-0.7,0.5-1.1,0.4C0.3,173.2,0.2,173,0.2,172.8z"/>
			<path fill="#46433F" d="M41.2,8.6C41.2,8.8,41.1,9,41,9.1c-0.2,0.2,0,0.8-0.5,0.5c-0.5-0.4,0.2-0.5,0.2-0.7
				C40.9,8.6,41.1,8.6,41.2,8.6z"/>
			<path fill="#F4EAD0" d="M1.2,209c0.4,0.2,0.5-0.4,1-0.5c0,0.6-0.3,0.8-0.8,1C1.4,209.3,1.3,209.2,1.2,209z"/>
			<path fill="#F3E8CB" d="M2.4,151c1.1,0.6,0.2,0.7-0.2,1C2.1,151.6,2.1,151.2,2.4,151z"/>
			<path d="M31.5,58.1c0.3,0.1,0.6-0.5,1-0.1c0.1,0.1,0.1,0.2,0,0.3c-0.2,0.1-0.5,0.2-0.7,0.3C31.4,58.5,31.4,58.3,31.5,58.1z"/>
			<path fill="#43403B" d="M26.9,22.3c0.4-0.1,0.7,0,0.7,0.5c0,0.2-0.1,0.3-0.2,0.4c-0.1,0-0.2-0.1-0.3-0.1
				C26.7,22.9,26.9,22.6,26.9,22.3z"/>
			<path fill="#F5EDD7" d="M33.6,112.5c0.4,0.2,0.5,0.4,0.5,0.8c-0.4-0.1-0.8-0.1-0.9-0.6C33.3,112.7,33.4,112.6,33.6,112.5z"/>
			<path fill="#10100F" d="M27.1,23c0.1,0,0.3,0.1,0.4,0.1c0,0,0,0,0,0c0,0.1,0,0.2,0.1,0.3c-0.4,0.2-0.1,0.8-0.5,1
				C27.1,24,27.1,23.5,27.1,23z"/>
			<path fill="#0C0B0B" d="M27.1,19.7c0.2-0.1,0.6-0.5,0.7-0.1c0.1,0.4-0.4,0.4-0.7,0.5C27.1,20,27.1,19.8,27.1,19.7z"/>
			<path fill="#2C2B2A" d="M30.2,48.7c0.1,0,0.2-0.1,0.3-0.1c0.1,0.3,0.2,0.6,0,0.8C30.1,49.3,30.2,49,30.2,48.7z"/>
			<path fill="#3C3A38" d="M25.5,29.8c0.1,0.2,0.1,0.3,0.2,0.5c-0.2,0.3-0.4,0.5-0.7,0.4C25.1,30.4,25.3,30.1,25.5,29.8z"/>
			<path fill="#FDFAF2" d="M32.4,113.5c0,0.2,0.1,0.3,0.1,0.5c-0.3-0.1-0.4-0.3-0.5-0.5C32.1,113.5,32.2,113.5,32.4,113.5z"/>
			<path fill="#42403C" d="M45.4,4.1c0.5,0.6-0.1,0.5-0.4,0.7C45,4.5,45.2,4.3,45.4,4.1z"/>
			<path fill="#0D0E0D" d="M27.1,26.4c0.3,0,0.5,0.2,0.4,0.5c-0.2,0.1-0.4,0.2-0.5,0C27.1,26.7,27.1,26.6,27.1,26.4z"/>
			<path fill="#615952" d="M69.5,588.9c1.9,0.5,3.7,1.4,5.8,1.6c2.9,0.3,5.6-0.4,8.2-1.4c0.9-0.3,1.9-0.5,2.6-1.2
				c2.5,0.5,4.5,1.9,6.6,3.2c3.1,1.9,6.2,3.9,9.5,5.4c2.6,1.2,5.4,2.1,8.1,3c2.3,0.8,4.7,1.4,6.8,2.8c1.5,1,2.5,2.4,2.6,4.4
				c0,0.8-0.4,1-1,1c-3.9,0.1-7.7,0.1-11.6,0.2c-2.2,0-4.4,0.3-6.6,0.2c-2.8,0-5.6,0.1-8.4-0.9c-1.8-0.6-3.3-1.7-5-2.4
				c-1.6-0.7-1.7-0.8-2,0.9c-0.2,1.6-1.3,1.6-2.4,1.7c-2.5,0.1-5,0.1-7.5,0.1c-1.9,0-3.7-0.2-5.6,0c-1.1,0.1-2.1-0.6-2.3-1.8
				c-1.1-5.1-0.7-10.2,0.9-15.2C68.5,589.9,68.9,589.3,69.5,588.9z"/>
			<path fill="#DBB22B" d="M48.9,278.4c-1.3,0-2.5-0.7-3.9-0.3c0-4.1,0.2-8.2,0.4-12.3c0.1-2.3,0-4.7-0.1-7c0-1,0.4-1.4,1.4-1.2
				c0.3,0,0.5,0.1,0.8,0.1c1.9,0.1,1.9,0.1,1.8,2c-0.2,4.7-0.4,9.5-0.6,14.2C48.8,275.4,48.7,276.9,48.9,278.4z"/>
			<path fill="#DBB22B" d="M35.6,277.5c-0.4-0.1-0.9-0.1-1.3-0.2c-0.7-0.1-0.9-0.4-0.8-1.2c0.2-4.9,1.3-9.8,1.4-14.7
				c0-1.1,0.2-2.1,0.4-3.2c0.3-1.5,1.5-1,2.3-1c0.8,0,1.4,0.5,1.3,1.5c-0.2,2.5-0.4,5-0.6,7.5c-0.3,3.1-0.6,6.3-0.8,9.4
				C37.3,277.4,37.4,277.4,35.6,277.5z"/>
			<path fill="#DBB22B" d="M18.7,294c-1,0.2-1.9-0.2-2.9-0.2c0.1-4.9,0.8-9.8,0.7-14.8c0-0.9,0.4-1.1,1.2-0.9c2,0.3,2.1,0.3,1.9,2.4
				c-0.3,3.7-0.5,7.3-0.7,11C18.8,292.2,18.8,293.1,18.7,294z"/>
			<path fill="#DBB22B" d="M9.8,292.6c-1.1,0.6-2.1-0.2-3.1-0.3c0.1-2.2,0.4-4.3,0.8-6.4c0.4-2.3,0.5-4.6,0.8-6.9
				c0.1-0.7,0-1.6,1.2-1.6c1.6-0.1,2,0.5,1.8,2.2c-0.5,3.9-0.9,7.7-1.4,11.6C9.8,291.7,9.8,292.1,9.8,292.6z"/>
			<path fill="#E5CC85" d="M30.2,114.7c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.2,0.3,0,0.5,0c0.2,0.1,0.5,0.1,0.7,0.3
				c0.2,0.2,0.6,0.2,0.7,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.2-0.5,0.4c-0.1,0.1-0.1,0.2-0.1,0.3
				c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0,0.3c-0.1,0.2-0.1,0.4-0.2,0.7c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.4,0.1-0.6,0
				c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0.1-0.2,0.1-0.2,0c0-0.2,0-0.5,0-0.7c-0.1-0.3-0.1-0.7-0.5-0.9c-0.3-0.2-0.4-0.5-0.2-0.8
				c0.2-0.1,0.3-0.2,0.5-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.3,0.4-0.6,0.1-0.9l0,0C30.1,115.2,30,115,30.2,114.7z"/>
			<path fill="#E5CC85" d="M32.9,116.1c0.1-0.2,0.2-0.3,0.2-0.5c0.1,0.2,0.2,0.3,0.2,0.5c0.4,0,0.6,0.3,0.9,0.5
				c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.4,0.5,0,0.7c0.2-0.1,0.4-0.2,0.7-0.2c0.6-0.1,0.2,0.5,0.4,0.7c-0.5,0.6-1.4,0.5-1.9,1
				c-0.2,0-0.3,0-0.5-0.1c-0.2-0.2-0.4-0.3-0.5-0.6c-0.1-0.3,0.2-0.6,0.2-0.9c0-0.1,0-0.2,0-0.3C32.5,116.8,32.5,116.4,32.9,116.1z"
				/>
			<path fill="#E8D193" d="M7.7,146.2c0.5,0.3,0.8-0.1,1.2-0.2c0.3,0,0.3,0.4,0.5,0.5c0.3,0.5,0.3,1.1-0.2,1.5
				c-0.2,0.2-0.5,0.3-0.5,0c-0.1-1.3-0.9-0.9-1.6-0.7c-0.1,0-0.2-0.1-0.3-0.1c0-0.7,0.8-0.3,1-0.7C7.6,146.4,7.6,146.3,7.7,146.2
				C7.6,146.2,7.6,146.2,7.7,146.2z"/>
			<path fill="#F9F4E8" d="M24.5,120.8c1,0,1.4,0.7,1.4,1.6c-0.1,0.3-0.2,0.6-0.5,0.7c-0.2-0.5-0.7-1-1-1.2c-1-0.8-0.2-1.3,0-2
				C24.7,120.2,24.6,120.5,24.5,120.8z"/>
			<path fill="#E8D193" d="M23.3,122.6c0.8,0,1.5,0.1,0.9,1.2c-0.4,0.2-0.3,0.7-0.5,1c-0.4,0-0.8-0.1-1.2-0.3
				c-0.2-0.3-0.5-0.5-0.2-0.9c0.2-0.2,0.5-0.3,0.7-0.5C23.2,123,23.1,122.7,23.3,122.6z"/>
			<path fill="#F0E2BE" d="M31.1,121.1c0.5,0.2,0.8,0.5,0.7,1c0.4,0,0.2-0.6,0.6-0.5c0.2,0.1,0.4,0.3,0.5,0.4
				c0.1,0.3-0.2,0.5-0.4,0.6c-0.4,0-0.5,0.8-0.8,0.4C31.4,122.5,30.8,122,31.1,121.1z"/>
			<path fill="#F6EEDA" d="M29.7,116.9c0.1,0.2,0.3,0.3,0.4,0.5c0.3-0.4,0.5-0.4,0.6,0.2c0.1,0.5,0.2,0.8-0.3,1
				c-0.6-0.1-1.1-0.4-1.3-1.1C29.1,116.9,29.3,116.9,29.7,116.9z"/>
			<path fill="#E8D193" d="M4.4,148.2c0.3,0,0.5,0,0.8,0c-0.3,0.5-1.5,0.9-0.3,1.6c-0.2,0.2-0.4,0.1-0.6,0.2c-0.3-0.2-1,0-1-0.4
				C3.3,149,3.9,148.5,4.4,148.2z"/>
			<path fill="#E9D397" d="M11.5,162.5c-0.3,0.2-0.6,0.2-0.7-0.1c-0.3-0.7-0.8-0.4-1.3,0c0.6-1.1,1.5-1.3,2.5-0.6
				C11.8,162,11.3,162,11.5,162.5z"/>
			<path fill="#E7CF8E" d="M7.6,195c0,0.2,0,0.4,0,0.6c0,0.1-0.1,0.2-0.2,0.2c-0.4,0.1-0.9,0.3-1-0.4c-0.1-0.5,0.5-1.4,1-1.5
				c0,0,0.1,0.1,0.1,0.2C7.6,194.5,7.3,194.8,7.6,195z"/>
			<path fill="#F9F5EA" d="M26.1,123.1c0.6-0.1,1.1,0.3,1.7,0c0.3,0,0.7,0,0.9,0.3c0.1,0.1,0.1,0.3,0.1,0.5c-1,0.5-1.9,0.2-2.7-0.5
				C26.1,123.3,26,123.3,26.1,123.1C26.1,123.2,26.1,123.1,26.1,123.1z"/>
			<path fill="#E6CE8C" d="M9.1,138.7c0.1,0.3,0.8,0.5,0.2,1c-0.6,0.5-0.6-0.3-0.8-0.5c-0.3-0.3-0.5-0.6-0.7-1c0,0,0,0,0,0
				c0.4-0.1,0.8-0.1,1.3-0.2C9.3,138.3,8.8,138.5,9.1,138.7z"/>
			<path fill="#F7F0DF" d="M21.5,128c-0.1,0.4,0,0.9-0.6,0.8c-0.3,0-0.5-0.3-0.4-0.6c0.1-0.5,0.3-0.9,0.8-0.9
				C21.7,127.3,21.5,127.8,21.5,128z"/>
			<path fill="#EAD59E" d="M34.3,131.3c-0.4,0.2-0.6,1.1-1.2,0.3c-0.2-0.2-0.4-0.8,0-0.9C33.7,130.4,33.9,131,34.3,131.3L34.3,131.3
				z"/>
			<path fill="#EFE0B9" d="M5.8,161.5c0.2,0,0.4,0,0.8,0c-0.7,0.4-1.2,0.9-1.7,0.6C4.5,162,4.5,161.3,5,161
				C5.4,161,5.5,161.3,5.8,161.5z"/>
			<path fill="#FBF9F2" d="M30.4,119.3c0.1,0,0.2,0,0.2,0c-0.3,0.4-0.1,1.2-1,1.1c-0.3,0-0.2,0.5-0.3,0.8c-0.3,0.2-0.5,0.2-0.8,0
				l0,0c0-0.2,0-0.4,0.3-0.3C29.2,120.2,29.6,119.6,30.4,119.3z"/>
			<path fill="#F1E4C2" d="M29.6,124.5c0.6-0.3,0.9,0,1.2,0.2c0.3,0.2,0.5,0.4,0.4,0.8c0,0.1-0.1,0.3-0.2,0.3
				c-0.4,0.1-0.5-0.3-0.6-0.6C30.3,124.9,30,124.8,29.6,124.5z"/>
			<path fill="#F8F1E1" d="M21.5,121.7c0.4-0.3,0.4-1,1.1-0.7c0.3,0.1,0.5,0.4,0.3,0.7C22.5,122.2,22,122.1,21.5,121.7z"/>
			<path fill="#F5EDD8" d="M2.2,171.4c0.5-0.6,0.9-0.1,1.3,0.2c0.2,0.2-0.2,0.7-0.4,0.7c-0.5,0.2-0.9-0.1-1-0.7
				C2.2,171.6,2.2,171.6,2.2,171.4C2.2,171.4,2.2,171.4,2.2,171.4z"/>
			<path fill="#F4EBD2" d="M12.2,134.4c0.5,0,1.3-0.2,1.3,0.3c0,0.8-0.8,0.1-1.2,0.4c-0.3,0.2-0.5,0.1-0.6-0.3
				C11.8,134.6,12,134.5,12.2,134.4z"/>
			<path fill="#EEDDB2" d="M4.6,152.9c0.1-0.3,0.1-0.6-0.1-0.9c-0.1-0.2-0.3-0.4,0-0.6c0.1,0,0.3,0.2,0.5,0.3c0.2-0.1,0.3-0.1,0.4,0
				C5,152,5.7,153.1,4.6,152.9z"/>
			<path fill="#ECD9A7" d="M18.4,131.3c-0.8-0.5-1.2,0.5-1.7-0.1c-0.1-0.1,0.2-0.7,0.2-0.7C17.4,130.5,17.9,130.5,18.4,131.3z"/>
			<path fill="#E8D295" d="M17,125.3c0.5,0.5,0,1.1,0,1.6l0,0c-0.5-0.1-1.2,0.1-0.6-0.8c0.1-0.2,0-0.4,0.1-0.7
				C16.7,125.3,16.8,125.1,17,125.3z"/>
			<path fill="#F8F1E2" d="M35.3,132.8c0.8-0.1,1.3-0.4,1.6-0.9C37,133.3,36.7,133.5,35.3,132.8z"/>
			<path fill="#F5ECD6" d="M5.8,161.5c-0.3-0.2-0.5-0.3-0.8-0.5c-0.3-0.4-0.3-0.8,0.2-1c0.2-0.1,0.4-0.1,0.5,0.1
				c0.2,0.2-0.1,0.3-0.2,0.4C5.3,160.8,5.8,161.1,5.8,161.5z"/>
			<path fill="#ECD9A8" d="M10.7,216.7c-0.1,0.4-0.3,0.5-0.6,0.6c-0.3,0-0.6-0.1-0.5-0.5c0.1-0.3,0.1-0.7,0.5-0.7
				C10.3,216.1,10.5,216.5,10.7,216.7z"/>
			<path fill="#E8D294" d="M6,217.5c-0.2,0.5-0.5,0.4-0.8,0.1c-0.2-0.2-0.6-0.3-0.4-0.6c0.2-0.4,0.6-0.2,1-0.2
				C6,216.9,5.8,217.3,6,217.5z"/>
			<path fill="#F7EFDD" d="M28.8,120.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.4-0.5,0.7-0.9,0.3c-0.2-0.1-0.5-0.3-0.4-0.5
				c0.2-0.3,0.6-0.4,0.9-0.3C28.4,120.8,28.6,120.9,28.8,120.9z"/>
			<path fill="#EBD8A4" d="M37.7,145.7c-0.9-0.1-0.6-0.9-0.5-1.3c0.2-0.5,0.7-0.1,1.1-0.1c-0.1,0.6-0.7,0.3-0.9,0.7
				C37.7,145.1,37.5,145.5,37.7,145.7z"/>
			<path fill="#E6CE8C" d="M7.4,140.4c0-0.3,0.1-0.5,0.4-0.5c0.3,0,0.6,0,0.6,0.4c0,0.3-0.2,0.6-0.5,0.6
				C7.6,140.9,7.5,140.7,7.4,140.4z"/>
			<path fill="#E8D295" d="M2.4,163.2c0-0.1,0-0.2-0.1-0.3c-0.3-0.5-0.1-0.8,0.3-1.1c0.1-0.1,0.3-0.1,0.4,0c0.2,0.4,0,0.8-0.3,1.1
				C2.6,163,2.5,163.1,2.4,163.2L2.4,163.2z"/>
			<path fill="#E9D49A" d="M3.3,195.4c-0.4-0.2-0.4-0.6-0.2-1c0.1-0.1,0.1-0.2,0.3-0.2c0.3-0.1,0.5-0.5,0.9-0.5
				c0.2,0.5-0.5,0.6-0.4,1C3.9,195,3.6,195.2,3.3,195.4z"/>
			<path fill="#F1E3C0" d="M45.6,116.9c0.1-0.3-0.1-0.7,0.2-1c0.8,1.2,0.8,1.2,0.2,1.4C45.8,117.3,45.7,117.1,45.6,116.9z"/>
			<path fill="#E9D294" d="M11.9,142.3c0.6-0.4,1.1-0.3,1.4,0.2c0.3,0.3,0,0.7-0.4,0.5C12.6,142.8,12.5,142.2,11.9,142.3z"/>
			<path fill="#F1E3C0" d="M19.2,124.8c-0.7-1.2-0.7-1.3-0.2-2.2C19.1,123.3,19.2,123.9,19.2,124.8z"/>
			<path fill="#F4E9CF" d="M26.6,136.6c0.2,0.2,1.1,0.2,0.3,0.8c-0.2,0.2-0.2,0.7-0.7,0.4c-0.5-0.3,0.1-0.5,0.1-0.8
				C26.4,136.8,26.5,136.7,26.6,136.6L26.6,136.6z"/>
			<path fill="#F9F4E9" d="M21.8,125.9c0,0.4-0.2,0.6-0.5,0.6c-0.3,0-0.6-0.1-0.5-0.4c0-0.3,0.3-0.4,0.6-0.5
				C21.6,125.5,21.7,125.7,21.8,125.9z"/>
			<path fill="#F3E8CD" d="M43.8,126.4c-0.6,0-1,0.5-1.3-0.1c-0.1-0.2-0.1-0.5,0.1-0.5C42.9,125.7,43.3,125.9,43.8,126.4z"/>
			<path fill="#ECDAA9" d="M6.8,247.8c0.3,0.1,0.3,0.4,0.3,0.6c0.1,0.4-0.2,0.4-0.5,0.4c-0.3,0-0.5-0.1-0.5-0.4
				C6.2,248.1,6.5,248,6.8,247.8z"/>
			<path fill="#F3E7CA" d="M18.4,127.7c-0.1,0.4,0.1,0.7,0.6,0.9c-1,0.1-1,0.1-1.5-1.1c0.2-0.3,0.4-0.3,0.7-0.2
				C18.3,127.3,18.4,127.5,18.4,127.7z"/>
			<path fill="#F3E8CB" d="M24.2,136.1c-0.1-0.6,0.4-1.2-0.4-1.6c1.4,0.1,1.1,0.8,0.7,1.6C24.4,136.2,24.3,136.2,24.2,136.1z"/>
			<path fill="#F9F4E7" d="M21.8,131c0.2-0.4,0.3-0.6,0.4-0.7c0.2-0.2-0.1-0.7,0.3-0.8c0.1,0,0.4,0.1,0.4,0.2
				c0.1,0.4-0.1,0.7-0.3,0.9C22.4,130.7,22.2,130.8,21.8,131z"/>
			<path fill="#F2E6C8" d="M5.7,190.8c0.4,0.5,0.7-0.8,1.1-0.1c0.1,0.2,0.1,0.4-0.1,0.5c-0.5,0.3-1,0.1-1.4-0.2c0,0-0.1,0-0.1-0.1
				C5.3,190.6,5.4,190.6,5.7,190.8z"/>
			<path fill="#F7F1E0" d="M30.2,115.4c0.3,0.3,0.8,0.7,0,1c-0.4,0.1-0.7-0.1-1-0.5c-0.1,0-0.1-0.1-0.2-0.1
				C29.2,115.1,30,115.9,30.2,115.4z"/>
			<path fill="#ECD9A7" d="M13.4,144.8c0,0.8-0.4,1.2-1.2,1.3C12.3,145.3,13.2,145.3,13.4,144.8z"/>
			<path fill="#F7EFDC" d="M1.4,170.1c-0.1-0.3-0.3-0.6,0.1-0.7c0.6-0.1,0.5,0.6,1,0.8c0.2,0.1-0.1,0.4-0.4,0.4
				C1.8,170.5,1.6,170.4,1.4,170.1z"/>
			<path fill="#F3E8CD" d="M2,168.5c-0.3,0-0.5-0.1-0.6-0.4c0-0.2,0.1-0.4,0.3-0.4c0.3,0,0.6,0.3,0.7,0.5
				C2.5,168.4,2.2,168.4,2,168.5z"/>
			<path fill="#F2E5C6" d="M3.3,184.8c0.8,0.4,1.3,0.8,0.3,1.5c-0.4-0.3,0.1-0.7-0.2-1C3.2,185.2,3.2,185,3.3,184.8z"/>
			<path fill="#F9F3E5" d="M12.6,136.4c0.1,0.5,0.2,1-0.2,1.2c-0.2,0.1-0.5-0.1-0.5-0.3C11.7,136.7,12.2,136.7,12.6,136.4z"/>
			<path fill="#E9D49A" d="M3.8,154.3c-0.1-0.1-0.2-0.2-0.2-0.3c0.4-0.3,0.4-1,1-1.2C4.8,153.6,4.7,154.2,3.8,154.3z"/>
			<path fill="#E9D297" d="M5.5,151.7c-0.1,0-0.3,0-0.4,0c0-0.2,0-0.4,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1c0.5,0.1,1-1,1.4,0
				c-0.2,0.1-0.3,0-0.5,0C5.9,151.2,5.7,151.5,5.5,151.7z"/>
			<path fill="#E8D295" d="M13.7,146.7c-0.3,0.3-0.4,0.8-0.9,0.7c-0.1,0-0.2-0.2-0.2-0.3C12.8,146.7,13.2,146.6,13.7,146.7z"/>
			<path fill="#ECDAA8" d="M10.3,146.1c0.3-0.3,0.7-0.2,1-0.1c0.2,0.1,0.4,0.3,0.4,0.6c0,0.1-0.1,0.3-0.1,0.3
				c-0.3,0.1-0.4-0.2-0.5-0.4C11,146.1,10.5,146.4,10.3,146.1z"/>
			<path fill="#F4EAD1" d="M36.5,129.3c-0.6,0.2-0.4,1.3-1.2,1.2c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0.1-0.1,0.1-0.1
				c0.3-0.5,0.1-1.2,0.7-1.6C36.2,128.9,36.5,128.9,36.5,129.3z"/>
			<path fill="#F9F3E6" d="M7.6,195c-1-0.1-0.4-0.5-0.2-0.8c0.2-0.1,0.4-0.4,0.6-0.1C8.3,194.5,7.7,194.7,7.6,195z"/>
			<path fill="#F0E2BC" d="M18.7,140.6c-0.8,0.1-1.5,0.4-1.8-0.6C17.5,140.2,18,140.3,18.7,140.6z"/>
			<path fill="#FBF9F4" d="M32.8,119.8c-0.2,0.2-0.4,0.6-0.8,0.7c-0.3-0.3-0.3-0.6-0.3-1c0.1-0.2,0.3-0.3,0.5-0.3
				C32.6,119.2,32.7,119.5,32.8,119.8z"/>
			<path fill="#E9D397" d="M10.6,190.7c-0.6-0.2-1.1-0.1-1.6-0.5C9.6,190.1,10.2,189.7,10.6,190.7z"/>
			<path fill="#F3E8CC" d="M3.1,165.8c-0.3-0.1-0.5-0.2-0.4-0.4c0.1-0.3,0.4-0.3,0.7-0.3c0.2,0,0.4,0.1,0.4,0.3
				C3.6,165.6,3.3,165.7,3.1,165.8z"/>
			<path fill="#EBD8A6" d="M10.8,186.5c-0.2,0.3-0.4,0.8-0.9,0.5c-0.3-0.1,0-0.4,0.1-0.6C10.3,185.8,10.5,186.3,10.8,186.5
				L10.8,186.5z"/>
			<path fill="#ECD9A8" d="M43.5,115c-0.7,0.5-1.2,0.3-1.7,0.1C42.3,114.8,42.7,114.7,43.5,115z"/>
			<path fill="#ECDAA9" d="M4.1,159.5c-0.1,0.5-0.2,0.8-0.6,1c-0.1,0-0.2-0.1-0.2-0.2C3.3,159.8,3.5,159.6,4.1,159.5z"/>
			<path fill="#F0E1BC" d="M46.9,111.4c-0.1-0.6-0.3-1,0.1-1.3c0.1,0,0.3,0,0.3,0C47.6,110.6,47.3,110.9,46.9,111.4z"/>
			<path fill="#EBD8A4" d="M20.3,136.9c-0.1-0.5,0.2-1,0.1-1.5C20.8,135.9,20.9,136.5,20.3,136.9z"/>
			<path fill="#E9D499" d="M12,200.7c-0.1,0.6-0.4,1-0.7,0.9c-0.3,0-0.3-0.5-0.3-0.8C11.3,200.7,11.6,201.1,12,200.7z"/>
			<path fill="#F0E1BB" d="M30,135.8c0.8-0.2,0.8,0.5,1.1,0.8C30.4,136.6,30.4,135.9,30,135.8z"/>
			<path fill="#F7F0E0" d="M18.2,127.4c-0.2,0-0.5,0-0.7,0c-0.1-0.2-0.3-0.3-0.4-0.5c0,0,0,0,0,0c0.3-0.1,0.7,0,1.1-0.3
				c0.3-0.2,0.3,0.1,0.3,0.3C18.4,127.2,18.4,127.3,18.2,127.4z"/>
			<path fill="#F7F0DF" d="M14.8,134.9c0.4-0.1,0.6-0.8,1.2-0.6c0.1,0,0.2,0.2,0.2,0.3C15.7,134.9,15.3,134.9,14.8,134.9z"/>
			<path fill="#EAD59C" d="M35.3,138c0.3-0.3-0.4-0.9,0.3-1c0.5-0.1,0.6,0.3,0.4,0.7C35.9,138.3,35.6,138.1,35.3,138z"/>
			<path fill="#ECD8A5" d="M22.8,132.1c-0.4,0.2-0.7,0.6-1.1,0.1c-0.1-0.1-0.1-0.3,0-0.3C22.1,131.5,22.4,132.1,22.8,132.1z"/>
			<path fill="#F0E2BE" d="M41.6,122.7c-0.4-0.4-0.5-0.8-0.1-1.2c0.1-0.1,0.2,0,0.3,0C41.8,121.9,41.7,122.3,41.6,122.7z"/>
			<path fill="#ECDAAA" d="M3.4,203c0.4-0.8,0.8-0.8,1.2-0.1C4.2,202.8,3.8,202.9,3.4,203z"/>
			<path fill="#F8F1E0" d="M16.5,142.6c-0.7-0.7,0-1,0.5-1.4C17.2,141.8,16.8,142.2,16.5,142.6L16.5,142.6z"/>
			<path fill="#E7CF8E" d="M30.9,145.2c0.3,0.7-0.3,0.9-0.2,1.4C30.4,146.1,30.4,145.7,30.9,145.2z"/>
			<path fill="#F5ECD5" d="M3,224.3c-0.1-0.5-0.2-0.8,0.1-1.1c0.1-0.1,0.3,0,0.4,0.2C3.6,223.8,3.4,224,3,224.3z"/>
			<path fill="#E8D294" d="M5.7,243.5c0.7,0,1,0.3,1.4,0.8C6.6,244.2,6.1,244.1,5.7,243.5z"/>
			<path fill="#EFE1BB" d="M3.1,200.6c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1,0,0.6-0.4,0.5c-0.1,0-0.3-0.3-0.3-0.5
				c-0.1-0.3,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.7-0.1C3.1,200.4,3.2,200.5,3.1,200.6C3.1,200.6,3.1,200.6,3.1,200.6z"/>
			<path fill="#F6EED9" d="M28.6,118c-0.7-0.1-1.1-0.1-1.5-0.3c-0.1,0,0-0.3,0.1-0.4C27.8,117.1,28,117.5,28.6,118z"/>
			<path fill="#E8D191" d="M28.5,123.6c-0.2-0.2-0.5-0.4-0.7-0.5c0.1-0.1,0.1-0.2,0.2-0.2c0.3,0.1,0.7-0.2,1,0
				c0.2,0.1,0.3,0.3,0.5,0.4C29.3,123.8,29,123.8,28.5,123.6z"/>
			<path fill="#F9F4E8" d="M14.7,130.1c-0.5,0.6-0.7,0.8-1.1,0.6c-0.1,0-0.1-0.2-0.1-0.3C13.7,129.9,14.1,130.2,14.7,130.1z"/>
			<path fill="#F3E7CA" d="M25.9,132.5c-0.7,0-0.8-0.4-0.8-1.1c0.5,0.3,0.8,0.7,1.1,1.1C26.1,132.6,26,132.6,25.9,132.5z"/>
			<path fill="#F4EAD1" d="M10.6,136.7c-0.5-0.1-0.9,0.1-1.2-0.3c0-0.1,0.1-0.3,0.1-0.3C9.9,136,10.3,136.1,10.6,136.7z"/>
			<path fill="#EAD59D" d="M12.4,140.1c-0.1,0.7-0.7,0.8-1.5,1.1C11.5,140.5,11.9,140.3,12.4,140.1L12.4,140.1z"/>
			<path fill="#F4E9CE" d="M21.5,140.8c-0.5,0.4-1-0.1-1.4,0.3C20.4,140.6,20.8,140.3,21.5,140.8z"/>
			<path fill="#F3E8CC" d="M5.3,151c-0.1,0-0.2,0-0.3,0.1c-0.2-0.1-0.5-0.2-0.7-0.3c-0.1-0.3,0.1-0.4,0.2-0.5
				c-0.1-0.1-0.1-0.2-0.2-0.3c0.2-0.2,0.4-0.2,0.7-0.2C4.7,150.3,5.3,150.5,5.3,151z"/>
			<path fill="#F4E9CF" d="M13.4,167.7c0.3,0,0.4,0.1,0.4,0.3c0,0.2,0,0.5-0.3,0.5c-0.2,0-0.5,0-0.5-0.3
				C13.1,168,13.3,167.8,13.4,167.7z"/>
			<path fill="#E6CE8A" d="M40.8,133.3c0.3,0.2,0.7,0.4,0.5,0.8c0,0.1-0.2,0.2-0.3,0.1C40.6,134.1,40.6,133.8,40.8,133.3z"/>
			<path fill="#F2E6C9" d="M32.1,131.9c-0.7-0.1-0.7-0.6-0.7-1.1C31.8,131.1,32.1,131.3,32.1,131.9z"/>
			<path fill="#EAD59B" d="M46.4,129.9c0,0.5,0.1,0.8-0.3,0.9c-0.1,0-0.2-0.1-0.3-0.2C45.5,130.1,46,130.2,46.4,129.9z"/>
			<path fill="#E9D397" d="M29.7,130.4c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.3,0.2-0.3,0.5-0.3c0.2,0,0.3,0.1,0.3,0.3
				C30.1,130.1,30,130.3,29.7,130.4z"/>
			<path fill="#EFDFB5" d="M3.8,188.9c0.1,0.3,0.5,0.5,0.1,0.8c-0.4,0.2-0.4-0.2-0.5-0.4c-0.2-0.1-0.4-0.3-0.5-0.4
				C3.2,188.6,3.5,188.7,3.8,188.9z"/>
			<path fill="#F9F3E6" d="M1.2,190.4c0.2,0,0.4,0,0.3,0.2c-0.1,0.3-0.2,0.5-0.5,0.5c-0.2,0-0.3-0.1-0.3-0.3
				C0.7,190.5,1,190.5,1.2,190.4z"/>
			<path fill="#F9F4E9" d="M6.2,158.4c-0.2-0.1-0.5-0.2-0.5-0.5c0.1-0.2,0.4-0.1,0.5-0.3c0.9,0,0.4,0.5,0.3,0.8
				C6.4,158.5,6.3,158.5,6.2,158.4z"/>
			<path fill="#EBD7A1" d="M14.1,159.9c-0.4,0.2-0.5,0.3-0.6,0.3c-0.3,0-0.5-0.2-0.4-0.5c0-0.1,0.2-0.3,0.3-0.3
				C13.7,159.4,13.5,159.9,14.1,159.9z"/>
			<path fill="#F2E6C8" d="M31.7,115.2c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.1,0.1-0.1
				c0.4-0.3,0.8-0.5,0.9,0.3C32,114.9,31.8,115.1,31.7,115.2z"/>
			<path fill="#E4CA82" d="M32.8,119.8c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2,0-0.4-0.2-0.3-0.4c0.1-0.3,0.3-0.3,0.6-0.4
				c0.2,0,0.5,0.1,0.7,0.1c0.1,0.1,0.1,0.2,0.2,0.2C33.2,119.3,33,119.6,32.8,119.8z"/>
			<path fill="#F6EFDD" d="M28.4,119c0.2,0.5,0.4,0.7,0.3,1.2C28,119.9,27.8,119.7,28.4,119z"/>
			<path fill="#FCFAF6" d="M21,123c0,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.4,0.3-0.4
				C20.7,122.7,20.9,122.8,21,123z"/>
			<path fill="#EBD69F" d="M9.6,158.3c-0.3,0.2-0.6,0.5-0.9,0.3c-0.1-0.1-0.1-0.4,0-0.4C9,158,9.2,158.4,9.6,158.3z"/>
			<path fill="#EBD7A1" d="M27.5,140.7c0.3,0.3,0.4,0.7,0,0.9c-0.4,0.2-0.5-0.2-0.7-0.4C27,140.9,27.3,140.8,27.5,140.7z"/>
			<path fill="#E8D194" d="M9.7,153.5c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0-0.3,0.2-0.3c0.2,0,0.3,0.1,0.3,0.3
				C10,153.3,9.9,153.4,9.7,153.5z"/>
			<path fill="#E9D296" d="M11.6,152.4c0.4,0.5,0.4,0.8,0.2,1.4C11.5,153.3,11.5,152.9,11.6,152.4z"/>
			<path fill="#F9F3E6" d="M36.5,117.1c0.4,0.3,0.6,0.6,0.9,0.8C36.6,118,36.6,118,36.5,117.1z"/>
			<path fill="#EAD69F" d="M9.7,193.4c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0.2-0.3,0.3-0.3
				C9.6,193.1,9.7,193.2,9.7,193.4z"/>
			<path fill="#F1E3C0" d="M7.9,203.6c-0.3,0.5-0.6,0.4-1,0.2C7.1,203.4,7.5,203.5,7.9,203.6z"/>
			<path fill="#EDDBAB" d="M18.2,134.9c0.3,0.1,0.4,0.3,0.3,0.5c-0.1,0.4-0.5,0.2-0.7,0.2c-0.2,0-0.1-0.3-0.1-0.5
				C17.8,134.9,17.9,134.7,18.2,134.9z"/>
			<path fill="#F2E6C7" d="M27.9,126.4c-0.9-0.6-0.9-0.6-0.7-1.5C27.4,125.4,27.6,125.7,27.9,126.4z"/>
			<path fill="#E8D090" d="M1.6,175.2c0.4,0.1,0.5,0.4,0.4,0.7c0,0.1-0.2,0.1-0.3,0.1C1.4,175.8,1.5,175.5,1.6,175.2z"/>
			<path fill="#EAD49B" d="M36.6,126.3c-0.3,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.4,0,0.4,0.3
				C36.9,126.1,36.7,126.2,36.6,126.3z"/>
			<path fill="#E7CF8D" d="M41.2,127.4c-0.5-0.3-0.5-0.6-0.3-1C41.3,126.6,41.3,126.9,41.2,127.4z"/>
			<path fill="#E7D090" d="M41.5,129.1c0,0.3,0,0.5-0.3,0.8c-0.2-0.4-0.3-0.7,0-1.1C41.3,128.9,41.4,129,41.5,129.1L41.5,129.1z"/>
			<path fill="#EDDBAC" d="M38.6,112.1c-0.2,0.3,0.3,0.6-0.1,0.8c-0.1,0-0.3,0-0.3-0.1c-0.4-0.4,0.1-0.7,0.2-1.1
				C38.6,111.8,38.8,111.8,38.6,112.1z"/>
			<path fill="#FCFBF6" d="M9.3,254.4c0,0.4-0.1,0.5-0.3,0.5c-0.2,0-0.4-0.1-0.4-0.3c0-0.3,0.2-0.4,0.5-0.4
				C9.3,254.1,9.3,254.3,9.3,254.4z"/>
			<path fill="#F1E4C3" d="M52.7,117.2c-0.5,0-0.7-0.2-0.7-0.6c0,0,0.1-0.1,0.2-0.1C52.5,116.5,52.6,116.8,52.7,117.2z"/>
			<path fill="#F6EFDC" d="M38,123.8c0.1,0.1,0.3,0.2,0.4,0.4c0.1,0.1-0.1,0.3-0.3,0.3c-0.2,0-0.5-0.1-0.5-0.4
				C37.6,123.9,37.7,123.8,38,123.8z"/>
			<path fill="#F5EDD8" d="M23.7,124.8c-0.3-0.6-0.3-1,0.5-1C24,124.2,24.2,124.7,23.7,124.8z"/>
			<path fill="#F3E8CD" d="M32.4,137.9c0.1,0.7,0.3,1-0.1,1.3c-0.1,0.1-0.3,0-0.4,0C32,138.8,32.1,138.5,32.4,137.9z"/>
			<path fill="#F2E6C9" d="M36,115c0.1,0.2,0.2,0.3,0.1,0.5c-0.1,0.2-0.2,0.4-0.5,0.5c-0.1,0-0.2-0.1-0.2-0.1
				c-0.1-0.3,0.1-0.4,0.2-0.6C35.7,115.1,35.9,115.1,36,115L36,115z"/>
			<path fill="#FBF8F1" d="M21.1,161.9c0,0.3-0.2,0.3-0.3,0.3c-0.3,0-0.4-0.2-0.4-0.5c0-0.2,0.1-0.3,0.3-0.3
				C20.8,161.6,21,161.8,21.1,161.9z"/>
			<path fill="#ECD8A5" d="M15.1,158.8c-0.1-0.3-0.2-0.4-0.2-0.6c0-0.2,0-0.4,0.2-0.5c0.2-0.1,0.3,0,0.3,0.2
				C15.4,158.1,15.3,158.3,15.1,158.8z"/>
			<path fill="#E9D296" d="M39.9,163c-0.2,0-0.3-0.2-0.4-0.4c0-0.1,0.1-0.2,0.1-0.3c0.2-0.1,0.4,0.1,0.4,0.3
				C40.1,162.8,40,163,39.9,163z"/>
			<path fill="#F3E8CC" d="M36.8,162.5c0.2,0,0.3,0,0.3,0.2c0,0.2-0.1,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.3-0.4
				C36.5,162.5,36.7,162.5,36.8,162.5z"/>
			<path fill="#F6EFDC" d="M7,162c0.1-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.4-0.4,0.6-0.1c0.2,0.3-0.1,0.4-0.3,0.5
				C7.3,161.9,7.1,161.9,7,162C7,162,7,162,7,162z"/>
			<path fill="#EEDDB2" d="M2.6,158.7c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c0.1-0.3,0.4-0.4,0.7-0.4
				c0.1,0,0.2,0,0.3,0c0.2,0.2,0.2,0.5,0,0.7C2.8,158.5,2.7,158.6,2.6,158.7z"/>
			<path fill="#EBD7A2" d="M3.3,157.4c0-0.2,0-0.3,0-0.5c0.6,0.1,1.2,0.2,0.5,1c-0.1,0.1-0.2,0.1-0.2,0
				C3.5,157.8,3.4,157.6,3.3,157.4z"/>
			<path fill="#F4EAD1" d="M9.1,138.7c-0.1,0.1-0.2,0.2-0.2,0.1c-0.1-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.3,0.4-0.4
				C9.6,138.1,9.2,138.3,9.1,138.7z"/>
			<path fill="#F2E7CB" d="M19.2,133c-0.2,0-0.2-0.2-0.2-0.3c0-0.2,0.1-0.4,0.4-0.3c0.2,0,0.3,0.2,0.2,0.3
				C19.5,132.8,19.4,132.9,19.2,133z"/>
			<path fill="#F2E7C9" d="M25.9,131c0.7-0.3,0.9,0.1,1.3,0.2C26.6,131.6,26.4,131.2,25.9,131z"/>
			<path fill="#F6EDD8" d="M23.6,130.9c0.5,0.1,0.8,0.3,0.8,0.8C23.8,131.6,23.6,131.4,23.6,130.9z"/>
			<path fill="#FCF9F2" d="M26.4,128.9c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.4-0.2,0.5-0.1c0.1,0.1,0.1,0.2,0,0.3
				c-0.1,0.2-0.3,0.2-0.5,0.2C26.7,129.1,26.5,129.1,26.4,128.9L26.4,128.9z"/>
			<path fill="#F5EDD6" d="M40.2,113.2c-0.1,0.2-0.2,0.4-0.4,0.4c-0.3,0-0.3-0.2-0.3-0.4c0-0.2,0.2-0.3,0.4-0.3
				C40.1,112.9,40.2,113,40.2,113.2z"/>
			<path fill="#EEDEB3" d="M49.3,124.8c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.4-0.1-0.4-0.4c0-0.2,0.2-0.2,0.3-0.2
				C49,124.6,49.2,124.6,49.3,124.8z"/>
			<path fill="#F9F5EA" d="M35.4,124.5c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.4-0.1-0.4-0.3
				C35.1,124.6,35.3,124.5,35.4,124.5z"/>
			<path fill="#E7CE8B" d="M29.2,116c0.3,0.1,0.8-0.1,1,0.5c0,0.1,0,0.1,0,0.2C29.7,116.6,29.3,116.6,29.2,116z"/>
			<path fill="#F5ECD5" d="M29,122.9c-0.3,0.2-0.7,0.3-1,0C28.4,122.8,28.7,122.1,29,122.9z"/>
			<path fill="#F1E5C4" d="M38.5,121.3c0.4,0.1,0.6,0.2,0.5,0.6c0,0.2-0.2,0.3-0.3,0.2C38.4,121.9,38.3,121.6,38.5,121.3z"/>
			<path fill="#EEDEB5" d="M43,119.9c0,0.6-0.1,0.8-0.4,0.9c-0.1,0-0.2-0.1-0.2-0.2C42.4,120.4,42.7,120.3,43,119.9z"/>
			<path fill="#F1E3C0" d="M42.4,119.1c0.3-0.3,0.6-0.3,1.1-0.2C43.1,119.4,42.8,119.2,42.4,119.1z"/>
			<path fill="#F6EEDA" d="M6.7,151.2c0.3,0.2,0.7,0,1,0.1c-0.2,0.7-0.7,0.4-1.1,0.3C6.6,151.5,6.5,151.3,6.7,151.2z"/>
			<path fill="#F6EEDA" d="M10.6,151.6c-0.1,0.2-0.2,0.4-0.4,0.4c-0.3,0-0.2-0.3-0.2-0.4c0-0.2,0-0.4,0.3-0.3
				C10.6,151.2,10.7,151.3,10.6,151.6z"/>
			<path fill="#F3E7C8" d="M9,149.4c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.2,0.1-0.4,0.4-0.4c0.2,0,0.3,0.2,0.2,0.3
				C9.4,149.2,9.2,149.3,9,149.4z"/>
			<path fill="#EDDCAD" d="M28.6,147.7c0,0.4-0.2,0.5-0.5,0.7c0,0-0.2-0.1-0.2-0.2C28,147.9,28.2,147.8,28.6,147.7z"/>
			<path fill="#EFE0B8" d="M42,143.5c-0.4,0.1-0.7,0.1-1-0.1c0-0.1,0-0.2,0.1-0.2C41.5,142.9,41.7,143.1,42,143.5z"/>
			<path fill="#F2E6C7" d="M21.8,143c0,0.2,0,0.4-0.3,0.5c-0.2,0-0.3,0-0.3-0.2c0-0.2,0.1-0.4,0.3-0.5
				C21.7,142.6,21.7,142.8,21.8,143z"/>
			<path fill="#F8F2E4" d="M16.5,142.5c-0.3,1.1-0.6,0.1-1,0.1C15.9,142.3,16.2,142.6,16.5,142.5C16.5,142.6,16.5,142.5,16.5,142.5z
				"/>
			<path fill="#F9F4E8" d="M12.2,138.7c-0.3-0.2-0.8-0.3-0.7-0.8c0-0.1,0.1-0.1,0.2-0.1C12,138,12.1,138.4,12.2,138.7z"/>
			<path fill="#E9D295" d="M4.7,181.6c0,0.2-0.1,0.4-0.3,0.3c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.2,0.1-0.3,0.3-0.2
				C4.6,181.4,4.7,181.5,4.7,181.6z"/>
			<path fill="#ECD8A6" d="M11.2,204.4c-0.3,0.4-0.5,0.5-0.8,0.3c0,0-0.1-0.2-0.1-0.2C10.5,204.3,10.8,204.3,11.2,204.4z"/>
			<path fill="#EAD69E" d="M5.7,234.5c-0.5-0.2-0.7-0.4-0.7-0.8C5.5,233.7,5.5,234,5.7,234.5z"/>
			<path fill="#F1E5C4" d="M14.6,191.2c0.3-0.4,0.6-0.5,1-0.2C15.3,191.4,14.9,191.3,14.6,191.2z"/>
			<path fill="#F5ECD4" d="M8.1,206.6c0.2,0.3,0.4,0.6,0,1c-0.4-0.3-0.3-0.7-0.3-1C8,206.6,8,206.6,8.1,206.6z"/>
			<path fill="#F0E2BD" d="M16.9,132.5c0.4-0.2,0.7-0.3,1-0.2C17.7,132.9,17.3,132.8,16.9,132.5z"/>
			<path fill="#E7CF8D" d="M15.7,148.8c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.3-0.1-0.3-0.2c0-0.1,0.1-0.3,0.2-0.4
				C15.6,148.4,15.7,148.5,15.7,148.8z"/>
			<path fill="#F7F0E0" d="M13.9,200.4c0.1-0.2,0.2-0.5,0.5-0.5c0.1,0,0.1,0.1,0.1,0.2c0,0.4-0.3,0.6-0.6,0.7
				c-0.1-0.1-0.1-0.1-0.1-0.2C13.8,200.4,13.9,200.4,13.9,200.4z"/>
			<path fill="#E8D193" d="M17.5,208.9c0,0.4-0.2,0.7-0.4,0.7c-0.4-0.1-0.2-0.3,0-0.5C17.1,209,17.3,209,17.5,208.9z"/>
			<path fill="#EEDEB3" d="M28.7,131c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.4,0-0.3-0.2c0.1-0.2,0.2-0.3,0.4-0.4
				C28.6,130.7,28.7,130.8,28.7,131z"/>
			<path fill="#EBD7A2" d="M5.7,190.8c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0-0.4,0.1-0.6,0.1c0-0.1,0-0.2,0.1-0.3
				C5,190.5,5.4,190.5,5.7,190.8z"/>
			<path fill="#EBD8A3" d="M35.3,130.3c0,0.1,0,0.1,0,0.2c0,0.6-0.4,0.8-1,0.7c0,0,0,0,0,0C34.6,131,34.9,130.6,35.3,130.3z"/>
			<path fill="#E9D398" d="M12.7,196.3c0,0.3,0.3,0.6-0.1,0.8c-0.1,0.1-0.4,0-0.4-0.1C12.1,196.7,12.5,196.6,12.7,196.3L12.7,196.3z
				"/>
			<path fill="#ECD9A6" d="M22.7,137.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.2
				C22.6,137,22.7,137.2,22.7,137.3z"/>
			<path fill="#F4EBD2" d="M7.6,201.2c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.2,0.3-0.3,0.5-0.2
				C7.4,200.9,7.5,201.1,7.6,201.2z"/>
			<path fill="#F4EAD1" d="M13.9,200.4c0,0.1,0,0.2,0,0.3c-0.3,0.2-0.6,0.3-0.9,0C13.2,200.3,13.6,200.5,13.9,200.4z"/>
			<path fill="#EEDEB3" d="M30.9,138.5c-0.2,0.1-0.4,0.2-0.5,0c-0.1-0.1,0-0.2,0-0.3c0.3-0.3,0.6-0.1,0.8,0
				C31.2,138.4,31.1,138.5,30.9,138.5z"/>
			<path fill="#F9F5E9" d="M40.1,134.2c0.2,0.1,0.5,0.2,0.4,0.5c0,0.2-0.2,0.2-0.4,0.1c-0.2-0.1-0.3-0.2-0.4-0.4
				C39.7,134.3,39.9,134.2,40.1,134.2z"/>
			<path fill="#E9D398" d="M35.3,126.5c-0.2-0.1-0.2-0.2-0.2-0.4c0.1-0.2,0.1-0.4,0.4-0.3c0.1,0,0.1,0.3,0.1,0.4
				C35.6,126.3,35.5,126.5,35.3,126.5z"/>
			<path fill="#EDDDB0" d="M8.1,187.4c0,0.3,0.1,0.6,0.1,0.9c-0.6,0-0.6-0.5-0.6-0.8C7.8,187.3,8,187.3,8.1,187.4z"/>
			<path fill="#F4E9CF" d="M3.6,157.9c0.1,0,0.1,0,0.2,0c-0.2,0.5-0.5,0.6-0.9,0.5c0-0.2,0-0.5,0-0.7C3.1,157.8,3.3,157.8,3.6,157.9
				z"/>
			<path fill="#FBF7EF" d="M3.3,184.8c0,0.2,0,0.4,0.1,0.5c-0.2,0.2-0.6,0.3-0.7,0C2.6,185,3,184.8,3.3,184.8z"/>
			<path fill="#F5ECD6" d="M1.1,183.2c0.1,0,0.3,0,0.4,0c0.2,0.3,0.5,0.6,0.4,1.1C1.5,183.9,1.3,183.5,1.1,183.2z"/>
			<path fill="#F5EDD7" d="M35.2,118c-0.1-0.2-0.3-0.4-0.4-0.7c0-0.2-0.1-0.4,0.2-0.5c0.2,0,0.3,0.2,0.3,0.3
				C35.2,117.5,35.2,117.7,35.2,118z"/>
			<path fill="#EAD59D" d="M26.1,123.1c0,0.1,0,0.2,0,0.2c-0.2-0.1-0.5-0.2-0.7-0.2c0.1-0.3,0.2-0.5,0.5-0.7
				C26,122.6,26.1,122.9,26.1,123.1z"/>
			<path fill="#F6EEDC" d="M39.6,117.6c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0-0.3-0.2-0.2-0.3c0.1-0.2,0.3-0.3,0.5-0.3
				C39.5,117.3,39.6,117.4,39.6,117.6z"/>
			<path fill="#F7EFDD" d="M48.6,118.4c0,0.2,0,0.3-0.2,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0-0.3,0.2-0.3
				C48.4,118.1,48.5,118.2,48.6,118.4z"/>
			<path fill="#FCF9F4" d="M24.5,120.8c0-0.3,0-0.6,0-0.8c0.2-0.2,0.5-0.2,0.8,0C25,120.2,24.7,120.5,24.5,120.8z"/>
			<path fill="#F6EEDA" d="M2.6,166.8c-0.3,0.2-0.6,0-0.5-0.3c0.1-0.4,0.4-0.5,0.8-0.2C3,166.6,2.8,166.7,2.6,166.8z"/>
			<path fill="#EFE0B8" d="M9.4,168.8c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.2
				C9.7,168.6,9.6,168.7,9.4,168.8z"/>
			<path fill="#F2E6C9" d="M32.6,119c-0.2,0.1-0.7,0-0.4,0.5c-0.2,0-0.3,0.1-0.5,0.1c-0.2-0.2-0.3-0.3-0.5-0.5
				c0.2-0.1,0.3-0.2,0.5-0.3C32.1,118.6,32.4,118.4,32.6,119z"/>
			<path fill="#EBD7A2" d="M11.7,209.4c-0.1,0.2-0.2,0.3-0.3,0.4c-0.2,0.1-0.3,0-0.3-0.2c0-0.1,0.2-0.3,0.3-0.4
				C11.5,209.2,11.6,209.3,11.7,209.4z"/>
			<path fill="#F1E3C1" d="M18.5,152.6c-0.3-0.5,0.1-0.7,0.2-0.9C18.8,152,18.9,152.3,18.5,152.6z"/>
			<path fill="#EEDDB0" d="M41.6,111.7c-0.3,0-0.4-0.2-0.4-0.4c-0.1-0.2,0.1-0.3,0.2-0.2c0.2,0,0.4,0.1,0.4,0.4
				C41.8,111.5,41.6,111.6,41.6,111.7z"/>
			<path fill="#F3E9D0" d="M6.4,230.2c0.2,0.5,0.1,0.7,0,1.2C6.2,230.9,6.1,230.6,6.4,230.2z"/>
			<path fill="#F6EDD8" d="M5.3,154.2c0.2,0.3,0.6,0.4,0.4,0.8c0,0-0.2,0.1-0.2,0C5.1,154.9,5.4,154.5,5.3,154.2z"/>
			<path fill="#ECD9A7" d="M26.4,128.9c-0.3-0.1-0.7,0.1-0.7-0.4c0-0.1,0.1-0.2,0.1-0.2C26.2,128.2,26.3,128.6,26.4,128.9
				C26.4,128.9,26.4,128.9,26.4,128.9z"/>
			<path fill="#EAD49B" d="M43.8,127.8c-0.1-0.1-0.2-0.2-0.4-0.3c0.1-0.1,0.2-0.2,0.2-0.2c0.2,0,0.4,0.1,0.4,0.3
				C44.1,127.8,44.1,127.9,43.8,127.8z"/>
			<path fill="#FBF8F2" d="M33.7,114.2c0.2,0.5-0.1,0.7-0.2,1c-0.1-0.1-0.2-0.1-0.3-0.2C33,114.6,33.3,114.5,33.7,114.2z"/>
			<path fill="#EDDBAD" d="M38.6,137.8c-0.2-0.8,0.5-0.3,0.7-0.6C39.3,137.6,38.8,137.5,38.6,137.8L38.6,137.8z"/>
			<path fill="#E8D192" d="M30.7,128.5c0.2,0,0.3,0.1,0.2,0.2c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C30.5,128.7,30.6,128.6,30.7,128.5z"/>
			<path fill="#F8F1E2" d="M38.6,112.1c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.2-0.1-0.5,0.2-0.6c0.1,0,0.3,0,0.3,0.1
				C39,111.6,38.9,111.9,38.6,112.1z"/>
			<path fill="#EAD59C" d="M28.9,125.4c-0.1,0-0.2,0.1-0.2,0.1c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.2,0.2-0.2
				C28.8,125,28.9,125.2,28.9,125.4z"/>
			<path fill="#F3E8CB" d="M23,126c0.3-0.2,0.5-0.5,0.9-0.2C23.6,126.2,23.3,126,23,126z"/>
			<path fill="#E8D193" d="M2.7,206.9c0-0.3,0-0.4,0-0.6c0-0.1,0.2-0.2,0.2-0.2c0.2,0.1,0.1,0.3,0.1,0.4
				C2.9,206.6,2.8,206.7,2.7,206.9z"/>
			<path fill="#EBD6A1" d="M4.6,200.5c0.2,0.1,0.3,0.2,0.3,0.4c0,0.1-0.1,0.1-0.2,0.2c-0.1-0.1-0.2-0.2-0.2-0.4
				C4.4,200.7,4.6,200.6,4.6,200.5z"/>
			<path fill="#FAF7EF" d="M9.3,218.4c0-0.2-0.1-0.5,0.2-0.6c0.3-0.1,0.4,0.2,0.5,0.4C10,218.7,9.7,218.6,9.3,218.4z"/>
			<path fill="#F5ECD6" d="M15.7,210.3c0.3-0.1,0.6-0.1,0.8,0.1c0,0,0,0.3-0.1,0.3C15.9,210.9,15.9,210.5,15.7,210.3z"/>
			<path fill="#FAF6EB" d="M3.1,200.6c0-0.1,0-0.2,0-0.3c0.2-0.1,0.2-0.4,0.5-0.4c0.1,0,0.2,0.2,0.2,0.2
				C3.7,200.7,3.4,200.7,3.1,200.6z"/>
			<path fill="#ECD9A5" d="M58.7,119.7c-0.4,0.1-0.6-0.1-0.8-0.4C58.3,119.2,58.5,119.4,58.7,119.7z"/>
			<path fill="#EAD59D" d="M18.2,134.4c0.2-0.2,0.3-0.5,0.6-0.3c0.1,0,0.1,0.2,0.2,0.3c-0.2,0.1-0.4,0.2-0.6,0.2
				C18.3,134.6,18.2,134.5,18.2,134.4z"/>
			<path fill="#F9F5EA" d="M30.8,134.4c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0.1-0.2,0.2-0.2
				C30.5,134.1,30.7,134.2,30.8,134.4z"/>
			<path fill="#EAD59C" d="M40.5,119.6c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.2,0.2-0.2
				C40.4,119.2,40.4,119.4,40.5,119.6z"/>
			<path fill="#EFE0B8" d="M27.5,136.4c-0.1,0-0.2,0-0.2-0.2c0-0.1,0.1-0.3,0.2-0.4c0.2-0.1,0.3,0.1,0.3,0.2
				C27.8,136.2,27.7,136.3,27.5,136.4z"/>
			<path fill="#F9F5EC" d="M23.5,133.4c0,0.2-0.2,0.3-0.3,0.2c-0.2,0-0.3-0.1-0.2-0.3c0-0.1,0.2-0.2,0.3-0.2
				C23.4,133,23.4,133.2,23.5,133.4z"/>
			<path fill="#E8D193" d="M33.7,125.4c0,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.3,0.2-0.4c0.2-0.1,0.3,0.1,0.3,0.2
				C34.2,125.1,34.1,125.2,33.7,125.4z"/>
			<path fill="#F7F0DE" d="M42.9,123.2c0.1,0.1,0.3,0.1,0.3,0.2c0,0.2-0.1,0.3-0.3,0.3c-0.2,0.1-0.4,0.1-0.3-0.2
				C42.6,123.4,42.8,123.3,42.9,123.2z"/>
			<path fill="#E9D398" d="M8.3,156.6c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.2c0.1,0,0.2,0.2,0.2,0.2
				C8.6,156.5,8.5,156.6,8.3,156.6z"/>
			<path fill="#F2E6C8" d="M12.5,155.6c-0.1,0.5-0.2,0.7-0.5,0.9C11.9,156,12.1,155.8,12.5,155.6z"/>
			<path fill="#EBD7A2" d="M5.2,190c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.2-0.2
				C5,189.8,5.1,189.9,5.2,190z"/>
			<path fill="#F4EBD3" d="M8.3,151.5c0-0.5,0.3-0.6,0.7-0.6C9,151.4,8.6,151.3,8.3,151.5z"/>
			<path fill="#EAD59D" d="M27.1,149.9c-0.1,0-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.1,0.2-0.3,0.3-0.3
				C27,149.6,27.1,149.7,27.1,149.9z"/>
			<path fill="#E7CF8D" d="M37.7,147.8c0.2,0.4,0,0.6-0.3,0.8C37.3,148.2,37.4,148,37.7,147.8z"/>
			<path fill="#EDDCAD" d="M6.4,146.8c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.2,0-0.3,0.2-0.3
				C6.1,146.6,6.2,146.7,6.4,146.8z"/>
			<path fill="#F6EEDB" d="M4.1,191.7c0.2,0.1,0.4,0.2,0.7,0.3c-0.6,0.2-0.6,0.2-0.9-0.3C3.9,191.7,4,191.7,4.1,191.7z"/>
			<path fill="#FDFDFB" d="M8.5,167.4c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.3-0.2-0.3-0.3
				C8.1,167.6,8.3,167.5,8.5,167.4z"/>
			<path fill="#EBD69F" d="M16.3,169.7c-0.2-0.3-0.2-0.5,0-0.8C16.6,169.2,16.6,169.4,16.3,169.7z"/>
			<path fill="#F1E4C3" d="M5.3,165.5c-0.5,0.4-0.7,0.5-1.1,0.4C4.4,165.5,4.7,165.5,5.3,165.5z"/>
			<path fill="#EDDCAF" d="M9.6,163.3c0,0.2,0,0.3-0.2,0.3c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1,0-0.2,0.2-0.2
				C9.4,163.1,9.5,163.2,9.6,163.3z"/>
			<path fill="#EAD69E" d="M7,162c0,0.2,0.4,0.3,0.2,0.6c0,0-0.3,0-0.4-0.1C6.5,162.2,6.7,162.1,7,162C7,162,7,162,7,162z"/>
			<path fill="#EEDDB0" d="M9.9,172.1c0.4,0.1,0.5,0.4,0.6,0.7C10,172.7,9.9,172.5,9.9,172.1z"/>
			<path fill="#E9D295" d="M3.6,182.4c0.4-0.3,0.8-0.1,1.4,0.2C4.3,182.8,3.9,182.6,3.6,182.4L3.6,182.4z"/>
			<path fill="#F4ECD5" d="M18.4,159.6c-0.1,0.1-0.1,0.2-0.2,0.2c-0.3,0-0.2-0.3-0.2-0.4c0-0.1,0.1-0.2,0.1-0.2
				C18.4,159.2,18.4,159.4,18.4,159.6z"/>
			<path fill="#F7F0E0" d="M10.4,158.9c0.3,0.3,0.5,0.5,0.4,0.8c0,0-0.2,0.1-0.2,0.1C10.4,159.5,10.3,159.3,10.4,158.9z"/>
			<path fill="#E9D294" d="M7.9,158.4c-0.1,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.1-0.2-0.1-0.3c0.1-0.1,0.2-0.2,0.4-0.3
				C7.9,158.1,7.9,158.3,7.9,158.4z"/>
			<path fill="#F4EAD2" d="M8.1,187.4c-0.2,0-0.4,0-0.5,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0.1-0.1
				C7.5,187.5,8,186.4,8.1,187.4z"/>
			<path fill="#F6EDD9" d="M12.5,157.6c-0.2,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3
				C12.6,157.4,12.5,157.5,12.5,157.6z"/>
			<path fill="#FAF6ED" d="M3.8,188.9c-0.3,0-0.6,0-0.9,0c0-0.2-0.1-0.5,0.2-0.5C3.4,188.3,3.6,188.6,3.8,188.9z"/>
			<path fill="#E8D193" d="M7.2,142.7c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3
				C7.1,142.4,7.2,142.5,7.2,142.7z"/>
			<path fill="#F3E8CD" d="M3.3,195.4c0.2-0.2,0.3-0.5,0.5-0.7C4.4,195.4,3.9,195.3,3.3,195.4z"/>
			<path fill="#EFDFB7" d="M9.7,141.4c0.1-0.2,0.2-0.2,0.4-0.1c0.1,0,0.2,0.2,0.2,0.2c0,0.2-0.1,0.3-0.3,0.2
				C9.8,141.7,9.7,141.6,9.7,141.4z"/>
			<path fill="#EFE0B9" d="M42.8,141.3c-0.1-0.2-0.2-0.3-0.3-0.4c0-0.2,0.1-0.3,0.2-0.2c0.1,0,0.2,0.2,0.2,0.3
				C43.1,141.1,43,141.2,42.8,141.3z"/>
			<path fill="#F6EED9" d="M9.9,197.7c-0.1,0.1-0.1,0.2-0.2,0.2c-0.3,0-0.3-0.3-0.3-0.5c0-0.1,0-0.3,0.2-0.2
				C9.8,197.3,9.9,197.5,9.9,197.7z"/>
			<path fill="#EBD7A2" d="M18.6,143.8c-0.5-0.2-0.4-0.5-0.5-0.8C18.6,143.1,18.5,143.4,18.6,143.8z"/>
			<path fill="#EAD59E" d="M28.5,143.3c-0.4,0.2-0.7,0.3-1.2,0.3c0.1-0.5,0.4-0.4,0.7-0.3C28.2,143.1,28.4,143.1,28.5,143.3z"/>
			<path fill="#EEDDB1" d="M38.3,139.8c0.1,0.1,0.2,0.1,0.2,0.2c0,0.3-0.2,0.3-0.4,0.3c-0.1,0-0.3,0-0.3-0.1
				C37.9,139.9,38.1,139.9,38.3,139.8z"/>
			<path fill="#F9F3E5" d="M6.5,198.6c-0.3-0.1-0.3-0.2-0.3-0.4c0-0.2,0.2-0.3,0.3-0.2c0.2,0,0.3,0.1,0.2,0.3
				C6.7,198.4,6.6,198.5,6.5,198.6z"/>
			<path fill="#F1E4C2" d="M7.7,146.2c0,0.1,0,0.2,0,0.3c-0.3-0.1-0.7,0-0.9-0.4C7.1,145.8,7.4,146,7.7,146.2z"/>
			<path fill="#FEFEFE" d="M9.3,146.4c-0.3,0-0.5-0.2-0.5-0.5c0-0.1-0.1-0.4,0.2-0.4C9.4,145.6,9.3,146.1,9.3,146.4z"/>
			<path fill="#ECDAA8" d="M41.5,139.8c0.1,0.1,0.2,0.3,0.2,0.5c0,0-0.2,0.2-0.3,0.2c-0.3,0-0.2-0.3-0.2-0.5
				C41.3,139.8,41.3,139.7,41.5,139.8z"/>
			<path fill="#EEDEB5" d="M18.4,164.7c0.3-0.2,0.5-0.3,0.8,0C18.9,165,18.7,165,18.4,164.7z"/>
			<path fill="#F6EEDA" d="M5.8,183.9c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.1
				C6.1,183.8,6,183.9,5.8,183.9z"/>
			<path fill="#EEDEB2" d="M25.2,124.7c-0.1-0.1-0.3-0.2-0.3-0.3c0-0.2,0.2-0.2,0.3-0.2c0.1,0,0.3,0,0.3,0.2
				C25.5,124.5,25.3,124.6,25.2,124.7z"/>
			<path fill="#F7F0DF" d="M19.1,196.5c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.3,0.1,0.3,0.3
				C19.2,196.3,19.1,196.4,19.1,196.5z"/>
			<path fill="#E9D398" d="M10.8,186.5c0.1-0.2,0.1-0.5,0.4-0.5c0.1,0,0.2,0.1,0.1,0.3C11.2,186.6,11,186.6,10.8,186.5
				C10.8,186.5,10.8,186.5,10.8,186.5z"/>
			<path fill="#F7F0E0" d="M30.9,138.5c0.1-0.1,0.2-0.2,0.3-0.3c0.3,0.1,0.4,0.4,0.1,0.6C31.1,139,31,138.7,30.9,138.5z"/>
			<path fill="#F4EBD3" d="M6.2,157.6c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2-0.2-0.4-0.4-0.2-0.6C5.8,157.1,5.9,157.6,6.2,157.6z"/>
			<path fill="#F3E9CE" d="M11.4,198c0.2,0,0.3,0.2,0.3,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.2-0.2-0.2-0.3
				C11.3,198.2,11.4,198.1,11.4,198z"/>
			<path fill="#F6EEDB" d="M8.1,163.2c-0.1,0-0.2,0.1-0.2,0.1c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.2,0.2-0.2
				C8,162.8,8.1,162.9,8.1,163.2z"/>
			<path fill="#E9D296" d="M7,200.5c-0.1-0.1-0.2-0.2-0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.3,0,0.2,0.1
				C7.3,200.3,7.2,200.4,7,200.5z"/>
			<path fill="#EDDBAD" d="M38.8,138.3c-0.1-0.2-0.1-0.3-0.2-0.5c0,0,0,0,0,0c0.4,0.1,1.1-0.2,0.7,0.7
				C39.2,138.3,39,138.3,38.8,138.3z"/>
			<path fill="#EEDEB4" d="M46.5,121.3c-0.1-0.1-0.3-0.3-0.2-0.3c0.1-0.1,0.2-0.3,0.3-0.3c0.1-0.1,0.2,0.1,0.2,0.2
				C46.7,121,46.6,121.1,46.5,121.3z"/>
			<path fill="#E8D091" d="M12,162c0.2,0,0.4-0.2,0.7,0c0,0,0,0.2,0,0.2c-0.2,0.3-0.5,0.1-0.7,0C11.9,162.1,11.9,162,12,162z"/>
			<path fill="#F6EDD7" d="M12,162c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.4-0.6,0.1-0.6,0.5-0.7
				C12,161.8,12,161.9,12,162z"/>
			<path fill="#EAD59C" d="M3.3,174.4c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
				C3.5,174.2,3.4,174.3,3.3,174.4z"/>
			<path fill="#F9F3E7" d="M8.1,174.9c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.1,0.2-0.3,0.3-0.2
				C8,174.6,8,174.7,8.1,174.9z"/>
			<path fill="#EEDFB5" d="M24.1,140.6c0,0.1-0.1,0.3-0.3,0.3c-0.2,0-0.2-0.2-0.1-0.4c0-0.1,0.1-0.3,0.2-0.3
				C24,140.2,24.1,140.4,24.1,140.6z"/>
			<path fill="#FBF7EF" d="M22.3,123.6c0.1,0.3,0.1,0.6,0.2,0.9C21.9,124.1,21.8,124,22.3,123.6z"/>
			<path fill="#EBD8A3" d="M2.4,163.2c0,0.2,0,0.5-0.2,0.7c-0.1,0.1-0.2,0-0.2-0.2C1.9,163.4,2.1,163.3,2.4,163.2
				C2.4,163.2,2.4,163.2,2.4,163.2z"/>
			<path fill="#E8D294" d="M15.1,137.6c0.1,0,0.2,0.1,0.2,0.2c0,0.2-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.1
				C14.8,137.7,14.9,137.7,15.1,137.6z"/>
			<path fill="#EFE0B7" d="M3.5,211.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C3.3,211.3,3.4,211.4,3.5,211.5z"/>
			<path fill="#F6EDD8" d="M25.4,152c0.6-0.2,0.9-0.1,1.1,0.3C26,152.4,25.9,152.1,25.4,152z"/>
			<path fill="#F3E8CC" d="M2,194.5c0.1,0,0.3,0.1,0.2,0.3c0,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C1.8,194.6,1.9,194.5,2,194.5z"/>
			<path fill="#E7CF8F" d="M19.3,136.2c0,0.2-0.1,0.2-0.2,0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.2,0.2-0.2
				C19.1,135.9,19.2,136.1,19.3,136.2z"/>
			<path fill="#EAD59D" d="M26.6,136.6c-0.2-0.3-0.2-0.5-0.2-1C26.9,135.9,26.8,136.2,26.6,136.6C26.6,136.6,26.6,136.6,26.6,136.6z
				"/>
			<path fill="#E9D49A" d="M29.4,144.2c-0.2,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0.1-0.2,0.2-0.2
				C29.1,144,29.2,144.1,29.4,144.2z"/>
			<path fill="#EBD7A2" d="M19.6,145.1c-0.1-0.2-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.1-0.3c0.2,0,0.2,0.2,0.2,0.3
				C19.7,144.9,19.7,145,19.6,145.1z"/>
			<path fill="#EAD59D" d="M4.1,191.7c-0.1,0-0.2,0-0.2,0c-0.1-0.1-0.3-0.1-0.3-0.3c0-0.1,0.2-0.2,0.3-0.2
				C4.1,191.3,4.1,191.5,4.1,191.7z"/>
			<path fill="#F5EBD3" d="M18.2,134.4c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.3,0.2-0.5,0.3
				C17.3,134.5,18,134.6,18.2,134.4z"/>
			<path fill="#EFDEB5" d="M8.1,206.6c-0.1,0-0.2,0-0.2,0c-0.1-0.3,0-0.5,0.2-0.7c0,0,0.2,0,0.2,0.1C8.4,206.3,8.2,206.4,8.1,206.6z
				"/>
			<path fill="#E8D193" d="M10.8,134.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.1-0.3c0-0.1,0.1-0.1,0.2-0.1
				C10.8,133.8,10.9,133.9,10.8,134.1z"/>
			<path fill="#E6CE8A" d="M3.1,193c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.1-0.3-0.1c0-0.1,0.1-0.2,0.2-0.2
				C2.9,192.9,3,192.9,3.1,193z"/>
			<path fill="#F1E3C1" d="M17.8,146.4c0,0.3-0.2,0.3-0.4,0.4c-0.1,0-0.2-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3
				C17.7,146.2,17.8,146.3,17.8,146.4z"/>
			<path fill="#E8D092" d="M17.8,200.9c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.2-0.3c0-0.1,0.2-0.2,0.3-0.1
				C17.6,200.6,17.7,200.8,17.8,200.9z"/>
			<path fill="#EDDBAD" d="M29.8,137.1c-0.1,0.2-0.1,0.4-0.3,0.3c0,0-0.1-0.2-0.1-0.3c0-0.1,0.1-0.3,0.3-0.2
				C29.8,136.8,29.8,137,29.8,137.1z"/>
			<path fill="#E6CE8A" d="M18.2,156c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2-0.1-0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.2
				C18.3,155.7,18.3,155.8,18.2,156z"/>
			<path fill="#E8D297" d="M18.2,127.4c0.1-0.2,0.2-0.3,0.2-0.5c0.4,0.2,0.5,0.5,0,0.7C18.3,127.6,18.3,127.5,18.2,127.4z"/>
			<path fill="#FAF6ED" d="M17.3,137c-0.1,0.2-0.2,0.3-0.4,0.2c-0.1-0.1-0.2-0.3-0.1-0.4c0-0.2,0.2-0.2,0.3-0.1
				C17.3,136.7,17.4,136.9,17.3,137z"/>
			<path fill="#F0E2BD" d="M5.2,240c0,0,0.1,0.1,0.1,0.2c0,0.2-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.1C4.8,240.2,4.9,240.1,5.2,240
				z"/>
			<path fill="#F6EFDC" d="M3.3,155.4c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.3-0.4,0.4-0.6,0.1C2.8,155.6,3.1,155.5,3.3,155.4z"/>
			<path fill="#FCFAF5" d="M34,143c0.1-0.1,0.2-0.5,0.5-0.3c0.3,0.2,0.1,0.5-0.2,0.7C34.1,143.4,34,143.2,34,143z"/>
			<path fill="#F0E2BE" d="M10.8,189c0,0-0.1-0.1-0.2-0.3c0-0.1,0-0.3,0.2-0.2c0.1,0.1,0.3,0.2,0.3,0.3C11.2,189,11.1,189,10.8,189z
				"/>
			<path fill="#F6EEDC" d="M1.1,189.1c0.1-0.2,0.2-0.4,0.4-0.4c0.1,0,0.2,0.1,0.2,0.2c0,0.3-0.3,0.4-0.5,0.5
				C1.1,189.3,1.1,189.2,1.1,189.1z"/>
			<path fill="#F2E6C8" d="M36.1,142.9c-0.1,0-0.1,0.1-0.2,0.2c-0.2,0-0.3-0.1-0.2-0.3c0-0.1,0.2-0.1,0.3-0.2
				C36,142.6,36.1,142.7,36.1,142.9z"/>
			<path fill="#EDDBAC" d="M13.5,152.3c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0-0.2-0.1
				C13.3,152.6,13.4,152.5,13.5,152.3z"/>
			<path fill="#EFE0B9" d="M1.1,189.1c0,0.1,0,0.2,0.1,0.4c0.1,0.2,0,0.4-0.2,0.4c-0.1,0-0.2-0.1-0.2-0.1
				C0.7,189.4,0.9,189.3,1.1,189.1z"/>
			<path fill="#EFE1BA" d="M36.7,128.8c0.2-0.1,0.5-0.5,0.7-0.2c0.2,0.2-0.2,0.4-0.5,0.5C36.8,129,36.7,129,36.7,128.8z"/>
			<path fill="#EFE0B8" d="M28.8,136.4c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1,0.1-0.2,0.2-0.2
				C28.6,136.3,28.7,136.4,28.8,136.4z"/>
			<path fill="#F8F2E4" d="M36.7,135.6c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.2,0.2-0.3,0.2c0,0-0.2-0.1-0.2-0.2
				C36.5,135.8,36.6,135.7,36.7,135.6z"/>
			<path fill="#E9D294" d="M63.8,129.5c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.2-0.1-0.2c0,0,0.1-0.2,0.1-0.2
				C63.7,129.1,63.8,129.2,63.8,129.5z"/>
			<path fill="#F6EDD8" d="M20.7,130c0,0.2-0.1,0.2-0.2,0.2c-0.2-0.1-0.4,0-0.4-0.3c0-0.1,0.2-0.2,0.2-0.2
				C20.5,129.7,20.7,129.8,20.7,130z"/>
			<path fill="#E7CF8C" d="M51.7,130.1c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.2,0.2-0.2
				C51.6,130,51.6,130,51.7,130.1z"/>
			<path fill="#E8D193" d="M43.4,109.6c0.1,0,0.2,0.1,0.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3-0.1-0.3-0.1
				C43.1,109.6,43.3,109.6,43.4,109.6z"/>
			<path fill="#E8D295" d="M34.3,113.5c0.1-0.3,0.2-0.6,0.2-1c0.4,0.3,0.4,0.6,0,0.9C34.5,113.7,34.4,113.7,34.3,113.5L34.3,113.5z"
				/>
			<path fill="#F6EFDD" d="M45.6,113.3c0.1,0.1,0.2,0.3,0.3,0.4c-0.1,0-0.2,0.2-0.3,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
				C45.3,113.3,45.4,113.2,45.6,113.3z"/>
			<path fill="#EAD49B" d="M40,116.4c0.1,0.2,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.1-0.2
				C39.9,116.6,39.9,116.5,40,116.4z"/>
			<path fill="#EFE0B7" d="M34.1,117.6c-0.2,0.1-0.4,0.2-0.5-0.1c-0.1-0.3,0.2-0.5,0.4-0.6C34.1,117.1,34.1,117.4,34.1,117.6z"/>
			<path fill="#F5EAD1" d="M41.4,117.3c-0.2,0.1-0.3,0.1-0.4,0.1c-0.2-0.1-0.3-0.2-0.3-0.4c0,0,0.2-0.1,0.3,0
				C41.2,117.1,41.3,117.2,41.4,117.3z"/>
			<path fill="#EAD59D" d="M48.7,118c-0.1-0.5-0.2-0.6-0.2-0.7c0-0.1,0.2-0.1,0.2-0.2c0,0.1,0.1,0.2,0.1,0.3
				C48.9,117.6,48.8,117.7,48.7,118z"/>
			<path fill="#EDDCAD" d="M61.4,117c0,0.1,0.1,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C61.1,117,61.2,117,61.4,117z"/>
			<path fill="#EFE0B7" d="M34.3,116.6c-0.4,0-0.7-0.2-0.9-0.5C33.9,115.8,34.1,116.2,34.3,116.6z"/>
			<path fill="#F4E9D0" d="M17.5,149.5c-0.2,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.2-0.2c0-0.1,0.1-0.1,0.2-0.1
				C17.2,149.4,17.3,149.5,17.5,149.5z"/>
			<path fill="#FAF6ED" d="M9.2,143.1c-0.1,0.1-0.3,0.2-0.4,0.4c0-0.1-0.1-0.2-0.1-0.3c0-0.3,0.2-0.3,0.5-0.4c0.1,0,0.1,0.1,0.1,0.2
				C9.2,143,9.2,143.1,9.2,143.1z"/>
			<path fill="#F4E9CF" d="M11.2,149.3c-0.8-0.2-0.5-0.6-0.2-1C11.2,148.6,11.1,149,11.2,149.3z"/>
			<path fill="#ECD9A6" d="M6.8,148.4c-0.2,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0.2-0.2
				C6.6,148.2,6.6,148.3,6.8,148.4z"/>
			<path fill="#EAD69E" d="M26,142.4c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0-0.2-0.1c0-0.1,0.1-0.1,0.1-0.2
				C25.9,142.3,26,142.3,26,142.4z"/>
			<path fill="#E9D49B" d="M4.2,156.6c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.2,0.1,0.3,0.2c0,0,0,0.1-0.1,0.2
				C4.4,156.7,4.3,156.7,4.2,156.6z"/>
			<path fill="#F3E7CB" d="M58,147.2c0,0.2-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.2
				C57.8,147,57.9,147.1,58,147.2z"/>
			<path fill="#EBD7A2" d="M19.7,142.1c-0.2,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.1
				C19.5,142,19.6,142,19.7,142.1z"/>
			<path fill="#F0E1BD" d="M43.8,145.6c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1-0.2-0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.2
				C43.7,145.4,43.8,145.5,43.8,145.6z"/>
			<path fill="#F3E7C9" d="M15.2,152.5c0.1-0.1,0.1-0.2,0.1-0.3c0.1,0.1,0.3,0.1,0.4,0.2c0,0-0.1,0.2-0.1,0.2
				C15.5,152.6,15.3,152.6,15.2,152.5z"/>
			<path fill="#E9D397" d="M24.6,153c-0.1-0.1-0.2-0.2-0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0.1,0.1,0.1,0.1
				C24.8,152.8,24.7,152.9,24.6,153z"/>
			<path fill="#F8F2E4" d="M6.7,151.2c0,0.2,0,0.3,0,0.5c-0.4-0.1-0.4-0.4-0.5-0.7c0.2-0.2,0.3,0,0.5,0C6.7,151,6.7,151.1,6.7,151.2
				z"/>
			<path fill="#F0E2BD" d="M8.6,144.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C8.4,144.1,8.5,144.2,8.6,144.3z"/>
			<path fill="#EAD59C" d="M31.9,144c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0.1,0.3,0.1
				C32.1,143.9,32,143.9,31.9,144z"/>
			<path fill="#EEDEB2" d="M6.6,143.8c0,0.2,0,0.3-0.2,0.3c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.1-0.3
				C6.4,143.6,6.5,143.7,6.6,143.8z"/>
			<path fill="#EAD49B" d="M8.6,154.9c0,0.1,0.1,0.3,0.1,0.3c0,0.1-0.2,0.1-0.3,0.1c0,0-0.1-0.1-0.1-0.2
				C8.4,155.1,8.5,155.1,8.6,154.9z"/>
			<path fill="#F3E8CB" d="M6.2,156c-0.2-0.2-0.3-0.5,0-0.7c0.2-0.1,0.3,0,0.3,0.2C6.6,155.8,6.4,155.9,6.2,156z"/>
			<path fill="#F1E4C1" d="M12.3,143.5c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.2-0.1-0.3c0-0.1,0.1-0.1,0.2-0.1
				C12.3,143.2,12.4,143.3,12.3,143.5z"/>
			<path fill="#E9D296" d="M7.4,195.8c0.1-0.1,0.2-0.2,0.2-0.2c0,0.2,0.3,0.6,0,0.7C7.4,196.4,7.4,196,7.4,195.8z"/>
			<path fill="#ECD9A6" d="M12.7,196.3c0-0.2-0.1-0.4,0.1-0.5c0,0,0.2,0.1,0.3,0.1C13.1,196.2,12.9,196.2,12.7,196.3
				C12.7,196.3,12.7,196.3,12.7,196.3z"/>
			<path fill="#F9F5EA" d="M2,203.5c0.1,0.2,0.2,0.3,0.3,0.4c0,0-0.2,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C1.8,203.8,1.9,203.7,2,203.5z"/>
			<path fill="#F7F0DF" d="M9.8,206.8c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1-0.1-0.4,0.2-0.3
				C9.6,206.5,9.7,206.6,9.8,206.8z"/>
			<path fill="#FBF8F2" d="M8.2,213.9c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.2,0,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.2
				C8,214.1,8,214,8.2,213.9z"/>
			<path fill="#F1E5C5" d="M6,217.5c-0.1-0.3-0.2-0.5-0.3-0.8C6.6,216.7,6.1,217.1,6,217.5z"/>
			<path fill="#E9D399" d="M6.1,222.6c-0.1,0-0.1,0.1-0.2,0.1c0-0.1-0.1-0.2-0.1-0.2c0.1,0,0.1-0.1,0.2-0.1
				C6.1,222.5,6.1,222.6,6.1,222.6z"/>
			<path fill="#EFE1BA" d="M7.2,233.7c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0.1,0.2,0.1,0.2,0.2c0,0-0.1,0.2-0.1,0.2
				C7.4,233.8,7.3,233.7,7.2,233.7z"/>
			<path fill="#F4EBD4" d="M8.1,234.6c0.1,0.2,0.3,0.3,0.3,0.4c0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C7.9,234.9,8,234.8,8.1,234.6z"/>
			<path fill="#EDDBAE" d="M2.8,161.1c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0,0.2-0.1,0.2
				C3.1,161.3,3,161.2,2.8,161.1z"/>
			<path fill="#E9D396" d="M25.2,164.5c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0,0.2,0.1,0.3,0.1
				C25.4,164.4,25.3,164.5,25.2,164.5z"/>
			<path fill="#EBD8A3" d="M10.9,167.3c0.1,0.2,0.1,0.2,0.1,0.3c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.2-0.2
				C10.7,167.5,10.8,167.4,10.9,167.3z"/>
			<path fill="#E8D193" d="M3.2,169.1c-0.1,0.1-0.1,0.2-0.2,0.2c0,0-0.2-0.1-0.1-0.1c0-0.1,0.1-0.2,0.2-0.3
				C3.1,169,3.1,169.1,3.2,169.1z"/>
			<path fill="#EDDAAB" d="M12.7,170.5c0,0-0.1,0.1-0.2,0.2c0-0.1-0.1-0.2-0.1-0.3c0,0,0.1-0.1,0.2-0.1
				C12.7,170.2,12.7,170.3,12.7,170.5z"/>
			<path fill="#F8F2E3" d="M0.9,170.9c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0,0.1,0,0.2,0c0.4,0,0.4,0.2,0.2,0.5
				C1.4,170.9,1.2,170.9,0.9,170.9z"/>
			<path fill="#EEDEB5" d="M2.6,177.4c0,0.2-0.1,0.3-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.2
				C2.4,177.2,2.5,177.4,2.6,177.4z"/>
			<path fill="#E8D090" d="M1.3,178.4c0.1,0,0.2,0.1,0.2,0.2c0,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.1-0.1-0.2-0.2
				C1.1,178.4,1.2,178.4,1.3,178.4z"/>
			<path fill="#EFDFB6" d="M4.7,180c-0.1,0.2-0.2,0.2-0.2,0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1,0-0.2,0.2-0.2
				C4.5,179.8,4.6,179.9,4.7,180z"/>
			<path fill="#EDDCAE" d="M3.6,182.4c0,0.2-0.1,0.4-0.3,0.4c-0.1,0-0.1-0.2-0.1-0.3C3.2,182.2,3.4,182.3,3.6,182.4
				C3.6,182.4,3.6,182.4,3.6,182.4z"/>
			<path fill="#EBD8A6" d="M9.1,186.7c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2-0.1-0.2-0.2c0,0,0.1-0.1,0.2-0.1
				C9,186.6,9.1,186.7,9.1,186.7z"/>
			<path fill="#ECD9A7" d="M7.2,187.2c0,0.1,0,0.1,0,0.2c-0.2,0-0.5,0-0.5-0.3c0-0.1,0.1-0.2,0.1-0.2C7,186.9,7.1,187.1,7.2,187.2z"
				/>
			<path fill="#E7CF8E" d="M13.5,187c0.1,0,0.1-0.1,0.2-0.1c0,0.1,0.1,0.2,0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1
				C13.6,187.2,13.5,187.1,13.5,187z"/>
			<path fill="#EBD8A4" d="M8.3,191.1c0.2,0,0.3,0,0.2,0.2c0,0.1-0.2,0.1-0.3,0.2c0,0-0.2-0.1-0.1-0.1
				C8.1,191.2,8.2,191.1,8.3,191.1z"/>
			<path fill="#E9D49B" d="M17.4,195.4c-0.1,0.2-0.2,0.1-0.3,0.1c-0.1-0.1-0.1-0.1-0.2-0.2c0.1,0,0.2-0.1,0.3-0.1
				C17.3,195.2,17.4,195.4,17.4,195.4z"/>
			<path fill="#EDDBAD" d="M43.9,132.9c-0.2,0.2-0.3,0.2-0.3,0.2c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1-0.1,0-0.2,0.2-0.2
				C43.6,132.8,43.8,132.9,43.9,132.9z"/>
			<path fill="#E9D294" d="M49.7,133c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.2
				C49.4,132.9,49.5,133,49.7,133z"/>
			<path fill="#F7F1E1" d="M12.7,133.4c-0.1,0-0.1,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.2
				C12.6,133.1,12.7,133.2,12.7,133.4z"/>
			<path fill="#F2E5C5" d="M29.2,133.4c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.1-0.2-0.3c0-0.1,0.2-0.1,0.3-0.1
				C29.1,133.1,29.2,133.2,29.2,133.4z"/>
			<path fill="#EBD8A5" d="M38.5,133.3c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.2,0.2
				C38.6,133.5,38.6,133.4,38.5,133.3z"/>
			<path fill="#EBD8A3" d="M25.2,133.2c0.3,0.1,0.6,0.2,0.5,0.7C25.2,133.8,25.2,133.5,25.2,133.2L25.2,133.2z"/>
			<path fill="#F0E1BB" d="M15.3,136.1c0,0.2-0.1,0.3-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C15.1,135.9,15.2,136,15.3,136.1z"/>
			<path fill="#ECD9A7" d="M24.2,136.1c0.1,0,0.2,0,0.3,0c0.2,0.2,0.4,0.2,0.4,0.5C24.5,136.7,24.4,136.3,24.2,136.1z"/>
			<path fill="#E9D296" d="M32.4,136.8c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.2-0.1-0.1-0.1
				C32.2,137,32.3,136.9,32.4,136.8z"/>
			<path fill="#EAD59C" d="M28.1,137.7c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.1-0.2,0.1c0-0.1-0.1-0.1-0.1-0.2
				C27.9,137.8,27.9,137.7,28.1,137.7z"/>
			<path fill="#EDDAAA" d="M38.1,119.2c-0.1,0.1-0.1,0.1-0.1,0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2,0.2-0.2
				C38,119,38.1,119.2,38.1,119.2z"/>
			<path fill="#EFE0B8" d="M27.1,118.8c0-0.2,0.1-0.4,0.4-0.5c0.1,0,0.3,0,0.2,0.2C27.5,118.7,27.3,118.8,27.1,118.8L27.1,118.8z"/>
			<path fill="#FBFAF4" d="M28.5,121.2c0.3,0,0.5,0,0.8,0C29,121.8,28.8,121.9,28.5,121.2z"/>
			<path fill="#F6EEDA" d="M20.7,121.8c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.1-0.3-0.3c0,0,0.1-0.1,0.2-0.1
				C20.5,121.5,20.6,121.6,20.7,121.8z"/>
			<path fill="#F2E7CB" d="M46,122.5c0,0.1-0.1,0.2-0.2,0.2c0,0-0.1-0.2-0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.2
				C45.9,122.4,45.9,122.5,46,122.5z"/>
			<path fill="#F9F3E7" d="M45.6,116.9c0.2,0.2,0.3,0.3,0.5,0.5c-0.1,0.3-0.3,0.1-0.5,0C45.4,117.2,45.5,117.1,45.6,116.9z"/>
			<path fill="#FAF6EE" d="M28.5,123.6c0.3-0.1,0.6-0.2,1-0.3c0.2,0.7-0.4,0.5-0.7,0.6C28.7,123.8,28.6,123.7,28.5,123.6z"/>
			<path fill="#F0E0B9" d="M44,117c-0.2,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1,0.1-0.2,0.2-0.2
				C43.8,116.9,43.9,117,44,117z"/>
			<path fill="#F1E4C2" d="M40.1,124.9c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.2
				C40.2,125.1,40.2,125,40.1,124.9z"/>
			<path fill="#F9F4E9" d="M17,125.3c-0.2,0.1-0.3,0.1-0.5,0.2c0-0.3,0-0.6,0.4-0.5C17.2,125,17.2,125.1,17,125.3z"/>
			<path fill="#FEFEFE" d="M31.7,115.2c0.2-0.2,0.3-0.3,0.5-0.5c0.1,0.2,0.4,0.3,0.4,0.7C32.2,115.3,32,115.3,31.7,115.2z"/>
			<path fill="#F7F0DF" d="M67.7,125.7c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C67.6,125.4,67.7,125.5,67.7,125.7z"/>
			<path fill="#F8F2E4" d="M31.2,114.5c0,0.1,0,0.2,0,0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.2-0.3-0.2-0.5
				C30.8,114,31,114.2,31.2,114.5z"/>
			<path fill="#F9F5E8" d="M38.4,126c0.1,0.1,0.3,0.1,0.3,0.2c0.1,0.2,0,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.2
				C38.2,126.2,38.3,126.1,38.4,126z"/>
			<path fill="#EEDDB1" d="M36.2,128.7c0.1-0.2-0.1-0.6,0.2-0.5c0.3,0.1,0,0.5,0.1,0.7c-0.1,0.1-0.1,0.1-0.2,0.1
				C36.2,128.9,36.2,128.8,36.2,128.7z"/>
			<path fill="#F3E8CB" d="M49.7,112.9c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1-0.2-0.1-0.2c0,0,0.1-0.1,0.2-0.1
				C49.6,112.6,49.7,112.7,49.7,112.9z"/>
			<path fill="#FCF9F3" d="M36.2,128.7c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.1,0,0.2,0c0.1,0.1,0.2,0.2,0.2,0.2
				c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2-0.2-0.4-0.3-0.5-0.6C36.1,128.7,36.1,128.7,36.2,128.7z"/>
			<path fill="#E9D398" d="M49.8,109.8c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1-0.1-0.1-0.2-0.2-0.2
				C49.6,109.9,49.7,109.9,49.8,109.8z"/>
			<path fill="#F0E2BC" d="M39.2,129.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.2
				C38.9,129.3,39,129.4,39.2,129.5z"/>
			<path fill="#F7F0E1" d="M43.5,140.5c-0.1-0.1-0.2-0.2-0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
				C43.7,140.3,43.6,140.4,43.5,140.5z"/>
			<path fill="#F0E2BE" d="M15.7,140.5c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.1,0.2-0.2c0.1,0.1,0.2,0.1,0.2,0.2
				C15.9,140.3,15.8,140.4,15.7,140.5z"/>
			<path fill="#EAD69F" d="M35.5,140c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2-0.1-0.3-0.2c0,0,0-0.1,0.1-0.2C35.3,139.9,35.4,140,35.5,140
				z"/>
			<path fill="#EEDDB0" d="M12.5,140.2c0-0.2-0.4-0.3-0.2-0.6c0,0,0.2,0,0.3,0.1C12.9,139.9,12.7,140,12.5,140.2
				C12.4,140.1,12.5,140.2,12.5,140.2z"/>
			<path fill="#F1E5C5" d="M19.2,139.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0-0.2-0.2c0-0.1,0.2-0.2,0.3-0.2
				C19,139,19.2,139,19.2,139.2z"/>
			<path fill="#ECD8A5" d="M42.6,137.9c0.1,0.1,0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C42.4,138.1,42.5,138,42.6,137.9z"/>
			<path fill="#F2E6C7" d="M35.3,138c0.2-0.1,0.5-0.2,0.7-0.3c0.2,0.1,0.4,0.2,0.1,0.4C35.8,138.3,35.5,138.3,35.3,138z"/>
			<path fill="#F4EBD3" d="M25,125.8c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.1-0.1,0.2c-0.1-0.1-0.2-0.1-0.2-0.2
				C24.7,126.1,24.8,126,25,125.8z"/>
			<path fill="#F3E9D0" d="M3,195.9c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2-0.1-0.3-0.1c0,0,0-0.2,0.1-0.2C2.8,195.9,2.9,195.9,3,195.9z"
				/>
			<path fill="#F7EFDD" d="M28.5,143.3c-0.2,0-0.3,0-0.5,0c0.1-0.2,0.2-0.4,0.5-0.3C28.7,143,28.6,143.2,28.5,143.3z"/>
			<path fill="#F5EBD4" d="M36,115c-0.2,0-0.4,0-0.5-0.3c0-0.1,0.1-0.2,0.2-0.2C36,114.5,35.9,114.8,36,115C36,115,36,115,36,115z"
				/>
			<path fill="#EEDDB0" d="M37.8,114.9c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.2,0,0.2,0.2
				C37.9,114.8,37.9,114.9,37.8,114.9z"/>
			<path fill="#EEDFB6" d="M4.4,198.4c0,0.1,0.1,0.1,0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1-0.1-0.1-0.1
				C4.1,198.5,4.2,198.4,4.4,198.4z"/>
			<path fill="#E9D294" d="M2.9,198.9c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.2,0.2c0,0-0.1-0.1-0.1-0.2C2.8,199,2.8,198.9,2.9,198.9z
				"/>
			<path fill="#F1E2BF" d="M32.4,125.2c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.1-0.2
				C32.2,125.3,32.3,125.3,32.4,125.2z"/>
			<path fill="#F0E2BD" d="M39.7,114.8c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0,0.2-0.1,0.2c-0.1,0-0.1-0.1-0.2-0.1
				C39.6,114.9,39.7,114.8,39.7,114.8z"/>
			<path fill="#EDDCB0" d="M39.8,140.6c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0-0.2-0.2
				C39.7,140.7,39.8,140.7,39.8,140.6z"/>
			<path fill="#EDDBAD" d="M9.7,201.5c-0.1,0-0.1,0.1-0.2,0.1c0-0.1-0.1-0.2-0.1-0.2c0.1,0,0.1-0.1,0.2,0
				C9.7,201.3,9.7,201.4,9.7,201.5z"/>
			<path fill="#F3E9CF" d="M25.8,141.1c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.2
				C26,141,25.9,141,25.8,141.1z"/>
			<path fill="#EEDDB0" d="M37.9,115.5c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.1-0.2c0,0,0.1-0.1,0.2,0
				C37.9,115.3,37.9,115.4,37.9,115.5z"/>
			<path fill="#F6EDD8" d="M11.3,206.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
				C11.4,206,11.4,206.1,11.3,206.1z"/>
			<path fill="#F0E1BB" d="M40.1,111.5c0-0.1-0.1-0.2-0.1-0.3c0,0,0.1-0.1,0.1-0.1c0,0.1,0.1,0.2,0.1,0.3
				C40.3,111.4,40.2,111.5,40.1,111.5z"/>
			<path fill="#F1E5C4" d="M46,111.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.3-0.2,0.2c-0.1,0-0.1-0.2-0.1-0.3
				C45.8,111.3,45.9,111.2,46,111.1z"/>
			<path fill="#EAD59D" d="M34.5,128.7c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
				C34.3,128.6,34.3,128.7,34.5,128.7z"/>
			<path fill="#F4EAD0" d="M53.5,111.6c-0.1,0.1-0.1,0.2-0.1,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0,0,0.1-0.2,0.1-0.2
				C53.3,111.5,53.4,111.6,53.5,111.6z"/>
			<path fill="#F3E7CA" d="M29.5,128.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0.1,0.2,0.1c-0.1,0.1-0.1,0.2-0.2,0.2
				C29.8,128.5,29.7,128.5,29.5,128.3z"/>
			<path fill="#EFDEB4" d="M34,143c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.2-0.3,0.3-0.5,0.1C33.7,143.3,33.8,143.1,34,143z"/>
			<path fill="#EFDFB6" d="M34.3,113.5c0.1,0,0.2,0,0.3,0c0,0.2,0.2,0.4,0,0.5C34.2,114.1,34.3,113.7,34.3,113.5z"/>
			<path fill="#EEDDB1" d="M42.3,113.9c-0.1-0.1-0.1-0.2-0.2-0.2c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0.1,0.2,0.1
				C42.5,113.8,42.4,113.8,42.3,113.9z"/>
			<path fill="#F4EAD2" d="M3.4,194.1c-0.1,0.1-0.2,0.2-0.3,0.2c0-0.2-0.5-0.1-0.3-0.4C2.9,193.8,3.2,194,3.4,194.1z"/>
			<path fill="#E9D398" d="M59.5,137.9c-0.1,0-0.1,0-0.2,0.1c0-0.1-0.1-0.1,0-0.2c0,0,0.1,0,0.2-0.1
				C59.5,137.7,59.5,137.8,59.5,137.9z"/>
			<path fill="#F5EBD4" d="M5.6,248.5c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1-0.1-0.1-0.2c0.1,0,0.2-0.1,0.3-0.1
				C5.5,248.4,5.5,248.5,5.6,248.5z"/>
			<path fill="#EBD8A5" d="M38.4,141.8c-0.1,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.2,0-0.2-0.2c0,0,0.1-0.1,0.2-0.1
				C38.3,141.7,38.3,141.7,38.4,141.8z"/>
			<path fill="#FBF8F0" d="M25.9,118.2c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0-0.2-0.2c0-0.1,0.1-0.2,0.2-0.3
				C25.8,117.9,25.8,118,25.9,118.2z"/>
			<path fill="#E9D49A" d="M64.1,118c-0.1,0.1-0.1,0.2-0.2,0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
				C64,117.9,64,117.9,64.1,118z"/>
			<path fill="#F0E1BC" d="M39.8,141.4c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.1c0,0-0.1-0.1-0.1-0.2
				C39.6,141.5,39.7,141.5,39.8,141.4z"/>
			<path fill="#FCF9F3" d="M34.9,120.9c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.2-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.2
				C35.1,120.7,35,120.8,34.9,120.9z"/>
			<path fill="#F7F1E1" d="M36.8,118.7c0,0.1-0.1,0.2-0.1,0.2c-0.2,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.1-0.2
				C36.7,118.6,36.7,118.6,36.8,118.7z"/>
			<path fill="#E7D08F" d="M46.1,119c-0.2,0.1-0.4,0.1-0.5-0.1c0,0,0-0.2,0.1-0.2C45.9,118.7,45.9,118.9,46.1,119L46.1,119z"/>
			<path fill="#EFDEB5" d="M33.3,141.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.2,0-0.2-0.1c0,0,0.1-0.1,0.1-0.1
				C33.2,141.2,33.3,141.2,33.3,141.3z"/>
			<path fill="#FBF8F1" d="M27.1,118.8c0,0.2,0.1,0.4-0.2,0.5c-0.1,0-0.2-0.1-0.2-0.2C26.7,118.8,26.9,118.9,27.1,118.8
				C27.1,118.8,27.1,118.8,27.1,118.8z"/>
			<path fill="#E7D08F" d="M46,119c0.1,0.2,0.3,0.4,0.1,0.7C45.9,119.5,46.1,119.3,46,119C46.1,119,46,119,46,119z"/>
			<path fill="#FAF6EC" d="M4,207.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C3.8,207.3,3.9,207.4,4,207.5z"/>
			<path fill="#E9D499" d="M39.2,124c0.1,0,0.1,0.1,0.2,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.2-0.1
				C39.1,124.1,39.1,124,39.2,124z"/>
			<path fill="#EDDBAC" d="M9.2,143.1c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0.1,0.3,0.1C9.5,142.8,9.3,142.9,9.2,143.1z
				"/>
			<path fill="#EAD49A" d="M14.3,214.7c0,0-0.1-0.1-0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1
				C14.4,214.6,14.3,214.7,14.3,214.7z"/>
			<path fill="#FCFBF9" d="M4,215.9c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.1-0.3
				C3.8,215.8,3.9,215.9,4,215.9z"/>
			<path fill="#FCFBF6" d="M27.5,140.7c-0.1,0.3-0.3,0.5-0.7,0.4C26.9,140.6,27.2,140.6,27.5,140.7z"/>
			<path fill="#E8D192" d="M57.5,123.3c-0.1,0.1-0.1,0.1-0.1,0.2c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1,0-0.1,0-0.2
				C57.4,123.2,57.4,123.3,57.5,123.3z"/>
			<path fill="#EBD6A0" d="M9.3,218.4c0.3,0,0.5,0.1,0.7-0.2C10,218.9,9.7,218.6,9.3,218.4z"/>
			<path fill="#F5ECD6" d="M23.3,122.6c0,0.2,0,0.3,0,0.5c0,0.1,0.2,0.3-0.1,0.3c-0.1,0-0.1-0.2-0.1-0.3
				C23.1,122.9,23.2,122.8,23.3,122.6z"/>
			<path fill="#F2E6C8" d="M5.1,226.8c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.1
				C5.1,226.6,5.1,226.7,5.1,226.8z"/>
			<path fill="#FEFEFE" d="M3.9,233.4c0.1,0.1,0.1,0.2,0.2,0.3c0,0-0.1,0.1-0.1,0.1c-0.1-0.1-0.1-0.2-0.2-0.3
				C3.8,233.5,3.8,233.5,3.9,233.4z"/>
			<path fill="#EBD8A5" d="M35.3,122.7c-0.1,0.1-0.2,0.1-0.3,0.2c0,0-0.1-0.1-0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2
				C35.2,122.6,35.2,122.6,35.3,122.7z"/>
			<path fill="#ECDBAB" d="M20.7,142.5c-0.1,0.1-0.2,0.1-0.2,0.2c0-0.1-0.1-0.1-0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.2
				C20.6,142.4,20.7,142.4,20.7,142.5z"/>
			<path fill="#ECDAA9" d="M36.2,142.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1c0.1,0,0.1-0.1,0.2-0.1
				C36.1,141.9,36.1,142,36.2,142.1z"/>
			<path fill="#F3E7C9" d="M10.5,240.6c-0.1,0-0.2,0-0.3,0c0,0-0.1-0.2-0.1-0.2c0.1,0,0.2-0.1,0.3-0.1
				C10.5,240.4,10.5,240.5,10.5,240.6z"/>
			<path fill="#F3E8CB" d="M40.2,122.2c-0.1,0.1-0.1,0.2-0.2,0.2c0,0-0.1-0.1-0.2-0.1c0.1-0.1,0.1-0.2,0.2-0.2
				C40,122,40.1,122.1,40.2,122.2z"/>
			<path fill="#E8D294" d="M5.7,244.8c0-0.8,0.4-0.4,0.7-0.2C6.2,244.8,6,244.8,5.7,244.8z"/>
			<path fill="#F3E7CA" d="M5.7,244.8c0.2-0.2,0.4-0.3,0.7-0.2c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.2,0.4-0.5,0.3
				C5.9,245,5.8,244.9,5.7,244.8z"/>
			<path fill="#EBD7A2" d="M6,245.1c0.2-0.1,0.3-0.2,0.5-0.3c0,0.2,0.3,0.4,0,0.5C6.4,245.3,6.1,245.2,6,245.1z"/>
			<path fill="#F4EAD1" d="M27,122c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.2
				C26.9,121.9,26.9,121.9,27,122z"/>
			<path fill="#E8D193" d="M28.2,139.8c0,0.1-0.1,0.2-0.1,0.2c-0.2,0-0.2-0.1-0.1-0.2c0-0.1,0.1-0.1,0.1-0.1
				C28.2,139.7,28.2,139.7,28.2,139.8z"/>
			<path fill="#F1E4C2" d="M16.5,135.5c-0.1,0-0.2,0.1-0.3,0c0,0-0.1-0.1-0.1-0.2c0.1,0,0.2-0.1,0.3-0.1
				C16.4,135.3,16.4,135.4,16.5,135.5z"/>
			<path fill="#ECD8A4" d="M21.9,157c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1c0.1,0,0.1-0.1,0.2-0.1
				C21.8,156.9,21.8,157,21.9,157z"/>
			<path fill="#F6EEDB" d="M11,135.3c0,0.1-0.1,0.2-0.1,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.1,0.1-0.2
				C10.8,135.1,10.9,135.2,11,135.3z"/>
			<path fill="#F1E4C2" d="M19.9,157.3c0,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.1-0.2,0.2c0-0.1-0.1-0.1-0.1-0.2
				C19.6,157.5,19.6,157.4,19.9,157.3z"/>
			<path fill="#F9F6ED" d="M17.6,145.5c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1-0.2-0.1-0.3c0,0,0.1-0.1,0.2-0.1
				C17.6,145.2,17.7,145.3,17.6,145.5z"/>
			<path fill="#F5EDD9" d="M14.8,145.2c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c0,0-0.1-0.1-0.2-0.1
				C14.5,145.3,14.6,145.3,14.8,145.2z"/>
			<path fill="#EBD7A2" d="M12.2,134.4c-0.2,0.1-0.3,0.3-0.5,0.4C11.5,134.3,12,134.5,12.2,134.4z"/>
			<path fill="#EBD7A1" d="M6.2,158.4c0.1,0,0.2,0,0.3,0c0,0.2,0.3,0.4,0,0.5C6.2,158.9,6.3,158.6,6.2,158.4z"/>
			<path fill="#EFDFB7" d="M17.8,134c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1-0.1-0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2
				C17.6,133.9,17.7,134,17.8,134z"/>
			<path fill="#F5EDD6" d="M37.7,145.7c-0.2-0.2-0.2-0.5-0.2-0.7C38,145,37.8,145.4,37.7,145.7z"/>
			<path fill="#E9D49B" d="M28.9,145.3c-0.1,0-0.1,0.1-0.2,0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0.1-0.1,0.2-0.1
				C28.8,145.2,28.8,145.3,28.9,145.3z"/>
			<path fill="#F9F5EB" d="M38.8,138.3c0.3-0.3,0.4-0.1,0.5,0.2C39.1,138.6,38.9,138.5,38.8,138.3z"/>
			<path fill="#F1E5C7" d="M14.7,152.1c0,0.1-0.1,0.1-0.2,0.2c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1,0-0.2,0.1-0.2
				C14.6,151.9,14.7,152,14.7,152.1z"/>
			<path fill="#EEDEB4" d="M19.1,146.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.1-0.2c0,0,0.1-0.1,0.2-0.1
				C19.1,145.9,19.1,146,19.1,146.1z"/>
			<path fill="#EDDCAF" d="M31.8,146.5c0,0.1,0.1,0.1,0.1,0.1c-0.1,0-0.2,0.1-0.2,0.1c0-0.1-0.1-0.1,0-0.2
				C31.6,146.5,31.7,146.5,31.8,146.5z"/>
			<path fill="#EAD59D" d="M29.8,152.9c-0.1,0-0.2,0-0.2,0c0,0,0-0.1,0-0.2c0.1,0,0.2,0,0.2,0C29.8,152.7,29.8,152.8,29.8,152.9z"/>
			<path fill="#F2E6C8" d="M40.7,135.9c-0.1,0.1-0.2,0.1-0.2,0.2c0-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.2
				C40.6,135.7,40.6,135.8,40.7,135.9z"/>
			<path fill="#F0E1BB" d="M15.2,153c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.1c0,0-0.1-0.1-0.1-0.2C15,153,15.1,153,15.2,153z"
				/>
			<path fill="#F3E8CC" d="M11.5,148.8c0.1-0.2,0.3-0.2,0.5-0.1c0,0,0,0.2,0,0.2c-0.1,0-0.3,0.1-0.4,0.1
				C11.4,149,11.4,148.9,11.5,148.8C11.4,148.8,11.4,148.8,11.5,148.8z"/>
			<path fill="#F8F1E1" d="M51.6,135.8c-0.2,0-0.3-0.1-0.2-0.2c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.2,0.1,0.3,0.1
				C51.7,135.6,51.6,135.7,51.6,135.8z"/>
			<path fill="#EBD7A2" d="M38.2,135.7c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1-0.1-0.2-0.1c0-0.1,0-0.1,0.1-0.2
				C38.1,135.7,38.2,135.7,38.2,135.7z"/>
			<path fill="#EBD69F" d="M27.9,155.2c-0.1,0-0.1,0.1-0.2,0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.1,0.2-0.1
				C27.8,155,27.9,155.1,27.9,155.2z"/>
			<path fill="#E8D193" d="M6.2,156c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0.1,0.3,0.2,0.2,0.3C6.6,155.9,6.4,155.9,6.2,156z"/>
			<path fill="#ECDAAA" d="M25.4,135.7c-0.1,0.1-0.1,0.2-0.1,0.2c-0.2,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.1-0.2
				C25.2,135.6,25.3,135.6,25.4,135.7z"/>
			<path fill="#FDFBF8" d="M2.2,171.4c0.2,0.1,0.5,0.1,0.4,0.3c-0.1,0.2-0.3,0-0.5-0.1C2.1,171.5,2.1,171.4,2.2,171.4z"/>
			<path fill="#EBD7A3" d="M14.1,172.6c0,0,0.1,0.1,0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1
				C13.9,172.7,14,172.7,14.1,172.6z"/>
			<path fill="#F0E3BF" d="M50.6,140c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.2-0.1c0-0.1,0-0.2,0.2-0.2
				C50.5,139.9,50.5,140,50.6,140z"/>
			<path fill="#F0E1BC" d="M65.4,130.5c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0.1-0.2,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
				C65.5,130.4,65.5,130.4,65.4,130.5z"/>
			<path fill="#FAF7EF" d="M49,109c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2,0-0.3-0.1c0-0.1,0.1-0.2,0.1-0.2C48.8,108.8,48.9,108.8,49,109
				z"/>
			<path fill="#EEE0B8" d="M2.6,179.3c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.1c0,0-0.1-0.1-0.1-0.2
				C2.4,179.4,2.5,179.4,2.6,179.3z"/>
			<path fill="#EEDDB0" d="M47.6,109.6c0-0.1-0.1-0.1-0.1-0.2c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.1,0.2
				C47.8,109.5,47.7,109.5,47.6,109.6z"/>
			<path fill="#F0E2BD" d="M12.3,180.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.1-0.2c0-0.1,0.1-0.1,0.2-0.1
				C12.3,180.2,12.3,180.3,12.3,180.3z"/>
			<path fill="#EEDDB1" d="M3.1,180.9c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.2-0.2c0,0,0.1-0.1,0.2-0.1
				C3,180.8,3.1,180.9,3.1,180.9z"/>
			<path fill="#E9D499" d="M44.9,109.7c0.2,0.2,0.2,0.3,0.2,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.1
				C44.8,110,44.9,109.9,44.9,109.7z"/>
			<path fill="#F0E2BD" d="M1.8,181.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.1-0.2c0-0.1,0.1-0.1,0.2-0.1
				C1.6,181.6,1.6,181.7,1.8,181.8z"/>
			<path fill="#F3E7CA" d="M41.5,129.1c0.1-0.2,0.1-0.4,0.3-0.4c0,0,0.1,0.1,0.1,0.1C41.9,129.1,41.7,129.1,41.5,129.1
				C41.5,129.1,41.5,129.1,41.5,129.1z"/>
			<path fill="#F4EAD0" d="M7,183.2c-0.1,0-0.1,0.2-0.2,0.2c-0.1,0-0.2,0-0.2-0.2c0-0.1,0.1-0.1,0.1-0.2C6.8,183,6.9,183,7,183.2z"
				/>
			<path fill="#F1E3C0" d="M14.6,183.7c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0.1-0.2,0.2-0.2
				C14.5,183.6,14.5,183.7,14.6,183.7z"/>
			<path fill="#EDDBAC" d="M8,184.2c-0.1,0.1-0.1,0.2-0.2,0.2c0,0-0.2-0.1-0.1-0.1c0-0.1,0.1-0.2,0.2-0.3
				C7.8,184.1,7.9,184.1,8,184.2z"/>
			<path fill="#EDDBAD" d="M0.7,184.9c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.1-0.1,0.2
				C0.9,185,0.8,185,0.7,184.9z"/>
			<path fill="#E9D49A" d="M20.8,144.9c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1c0.1,0,0.1-0.1,0.2-0.1
				C20.8,144.8,20.8,144.9,20.8,144.9z"/>
			<path fill="#F8F2E6" d="M18.6,133.2c0,0.1-0.1,0.1-0.1,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0-0.1,0.1-0.2
				C18.4,133.1,18.5,133.2,18.6,133.2z"/>
			<path fill="#E9D397" d="M53,139.7c-0.1,0-0.1,0-0.2,0.1c0-0.1-0.1-0.1,0-0.2c0,0,0.1,0,0.2-0.1C52.9,139.6,53,139.7,53,139.7z"/>
			<path fill="#F1E4C2" d="M4.2,163.4c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.3-0.1c0,0,0,0.1,0,0.2C4.3,163.4,4.2,163.4,4.2,163.4z
				"/>
			<path fill="#EAD59E" d="M25.1,144.8c0,0.1-0.1,0.2-0.1,0.2c-0.2,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
				C25.1,144.7,25.1,144.7,25.1,144.8z"/>
			<path fill="#E9D498" d="M45.3,132.9c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1-0.1-0.2-0.1c0-0.1,0.1-0.1,0.1-0.1
				C45.2,132.8,45.3,132.9,45.3,132.9z"/>
			<path fill="#F0E1BB" d="M27.2,132.8c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.1c0,0,0.1,0.1,0.1,0.2
				C27.4,132.8,27.3,132.8,27.2,132.8z"/>
			<path fill="#F5ECD6" d="M25.9,132.5c0.1,0,0.2,0,0.3,0c0.2,0.2,0.2,0.4-0.1,0.4C25.8,132.9,25.8,132.7,25.9,132.5z"/>
			<path fill="#F6EDD9" d="M25.2,133.2c-0.2-0.1-0.5-0.2-0.5-0.6C25.1,132.6,25.1,132.9,25.2,133.2
				C25.2,133.2,25.2,133.2,25.2,133.2z"/>
			<path fill="#FBF7EF" d="M9.7,166.6c0-0.1-0.1-0.4-0.1-0.4c0.4-0.2,0.5,0.1,0.7,0.3C10.2,166.7,10,166.7,9.7,166.6z"/>
			<path fill="#EBD8A4" d="M2.6,166.8c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0.1,0.3,0.1,0.2,0.3C3,166.7,2.8,166.7,2.6,166.8z"/>
			<path fill="#F9F4E7" d="M9.7,166.6c0.2,0,0.4,0,0.6-0.1c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0.1-0.2,0.2-0.3,0.2
				C10.2,166.8,10,166.7,9.7,166.6z"/>
			<path fill="#FAF7EF" d="M10.9,144.2c0.1,0.1,0.3,0,0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C10.7,144.3,10.8,144.3,10.9,144.2z"/>
			<path fill="#E8D294" d="M11.5,148.8c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.3-0.2-0.6-0.2-1
				C11.2,148.5,11.3,148.6,11.5,148.8z"/>
			<path fill="#ECD9A6" d="M44.1,131.6c0-0.1-0.1-0.1-0.1-0.2c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.2
				C44.3,131.6,44.2,131.6,44.1,131.6z"/>
			<path fill="#F5ECD6" d="M26.9,144.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.2,0.1c-0.1,0-0.1-0.1-0.1-0.2
				C26.6,144.3,26.7,144.2,26.9,144.2z"/>
			<path fill="#EDDDB1" d="M11.8,169c-0.1,0.1-0.1,0.2-0.2,0.2c0,0-0.2-0.1-0.2-0.1c0-0.1,0.1-0.2,0.2-0.3
				C11.7,168.9,11.7,168.9,11.8,169z"/>
			<path fill="#EAD69E" d="M1.6,170.9c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.1,0-0.2,0-0.3c0.3,0.1,0.5,0.3,0.7,0.5
				C2,170.7,1.8,170.8,1.6,170.9z"/>
			<path fill="#CA8066" d="M88,41.5c0.2,0.5,0.9,0.1,1.1,0.7c0.2,0.3,0.6-0.2,0.6-0.4c0-0.7,0.6-1,0.9-1.5c0.4-0.6,0.7,0,0.8,0.1
				c0.3,0.3-0.2,0.3-0.3,0.4c-0.3,0.6,0.2,0.8,0.5,1c0.4,0.3,0.6,0.7,0.3,1c-0.5,0.8-0.5,1.6-0.3,2.5c0.1,0.3-0.1,0.5-0.2,0.8
				c-0.4,0.7-0.9,1.1-1.8,1c-0.4,0-0.7,0-0.9,0.4c-0.1,0.2-0.2,0.4-0.5,0.6c-0.5,0.2-1,0.3-1.2,0.9c-0.2,0.6-0.8,0.7-1.3,0.9
				c-0.3,0-0.5-0.1-0.6-0.4c-0.1-0.1-0.1-0.2-0.2-0.3C85,49.3,85,49.3,85,49.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0.2
				c-0.1,1.3-0.8,0.8-1.4,0.6c-0.3-0.1-0.3-0.6-0.7-0.6c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.4-1.1,0.2-1.6,0.2c-0.4-0.2-0.5-0.6-0.8-0.9
				c-0.1-0.2-0.2-0.3-0.2-0.5c0.2-0.5-0.3-1-0.1-1.6c0.1-0.1,0.3-0.2,0.5-0.2c0.7,0.3,1.5,0.3,2.2,0.5c0,0,0-0.1,0-0.1
				c-0.1-0.3,0-0.5,0.2-0.7c0.4-0.4,0.7-0.8,0.7-1.4c0,0-0.1,0-0.1,0s0.1,0,0.1,0c-0.1,0.5-0.3,0.9-0.6,1.2c-0.4,0.4-0.8,0.5-1.1,0
				c-0.2-0.4-0.5-0.4-0.9-0.4c-1.2-0.8-1.2-1.4,0.1-2.8c0.3-0.2,0.6-0.4,0.9-0.5c0.6,0,1.1,0.6,1.7,0.3c0.5-0.4-0.4-0.5-0.1-0.9
				c0.1-0.3,0.3-0.5,0.6-0.7c0.2-0.1,0.3-0.1,0.5,0c0.7,0.6,1.2,0.5,1.5-0.4c0.3-0.3,0.7-0.6,1.1-0.2c0,0.1,0,0.1,0,0.2
				c0.3,0.1,0.3,0.5,0.5,0.7c0.1,0.1,0.2,0.2,0.2,0.2C87.9,41.3,88,41.4,88,41.5z"/>
			<path fill="#C8886E" d="M80.9,49.2c0.6,0,1.2,0.1,1.7-0.5l0,0c0.4,0.2,1.5-0.6,1,0.7c0.7,0.7,1.2,0.4,1.7-0.3c0.1,0,0.2,0,0.2,0
				c0.1,0.1,0.1,0.2,0,0.3c0.2,0.2,0.3,0.3,0.5,0.5c0,0,0.1,0,0.1,0.1c0.3,0.3,0.3,0.8,0.4,1.2c0,0.1,0,0.2,0,0.3
				c-0.1,0.2-0.2,0.3-0.2,0.4c0,0,0,0.1,0,0.1c0.3,0.3,0.7,0.4,0.9,0.8c-0.1,0.3-0.1,0.6-0.5,0.6c-0.5-0.1-1.1-0.6-1.6,0.2
				c-0.4-0.9-0.9,0-1.3,0c0-0.6-0.3-1-0.9-1.1c-0.9-0.1-0.9-0.5-0.3-1c0,0,0-0.1,0-0.1c-0.1-0.1-0.2-0.3-0.1-0.4
				c0.1-0.1,0.1-0.1,0.1-0.1c-0.1,0.1-0.1,0.1-0.2,0.1c-0.4,0.1-0.7,0-0.7-0.4c0-0.1,0-0.2-0.1-0.1C80.7,50.3,80.6,49.8,80.9,49.2z"
				/>
			<path fill="#C49E85" d="M91.2,45.8c-0.3-1.1-0.4-2.2,0.4-3.2c0.3-0.4-0.2-0.5-0.5-0.6c-0.6-0.4-0.9-0.8,0-1.2
				c0.2,0.2,0.5,0.4,0.7,0.5c-0.3,0.4,0.2,0.3,0.3,0.4c0.5,0.1,0.9,0.4,1.2,0.8c0.4,0.5,0.6,1,0.2,1.6c-0.6,0.8-0.7-0.6-1.2-0.2
				c-0.2,0.2-0.2,0.3,0,0.5c0.2,0.3,0.5,0.6,0.7,0.9c0.2,0.4,0.2,0.8,0,1.2c-0.4,0-0.7,0.2-1,0.5c-0.2,0-0.4-0.2-0.5-0.4
				c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3C91.4,46,91.3,45.9,91.2,45.8z"/>
			<path fill="#C3A188" d="M84.4,41.1c-0.2,0-0.3,0-0.5,0c-0.4-0.1-0.7,0.3-1.1,0.2c-0.5-0.2-0.8-0.2-0.9,0.4c0,0.1-0.1,0.2-0.2,0.3
				c-0.4,0.1-0.7,0.4-1.1,0.4c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0.5-0.6-0.2-0.5-0.4-0.6c-0.4-0.4-0.8-0.9-0.7-1.6
				c0.5,0.2,1,0.1,1.4-0.3c0.2-0.2,0.5-0.2,0.7-0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.1,0.2,0.1,0.3c0-0.1,0-0.2,0.1-0.3
				c0.2-0.2,0.5-0.1,0.7,0c0.6,0,1.1,0.2,1.7-0.2c0.2-0.2,0.5,0,0.7,0.2c0,0.1,0,0.2,0,0.2C84.8,40,85.4,40.6,84.4,41.1z"/>
			<path fill="#C3A38A" d="M81.8,50.2c0,0.5,0.3,0.7,0.7,0.7l0,0c0.1,0.3,0.2,0.4,0.5,0.5c0.1,0,0.1,0.1,0.1,0.1
				c0,0-0.1,0.1-0.1,0.1c-0.2,0-0.5,0.1-0.8,0.7c0.8-0.8,1.6,1.3,2.1-0.7c0.5,1.1-0.3,1.4-0.6,2c-0.2-0.2-0.5-0.5-0.7-0.7
				c-0.7,0-1,0.6-1.5,0.9c-0.1,0-0.2,0-0.3,0c-0.2-0.4-0.9-0.5-0.7-1.2c0-0.2,0-0.3,0-0.5c-0.1,0-0.1-0.1-0.1-0.2
				c0-0.1,0.1-0.2,0-0.3c-0.4-0.2-0.5-0.7-0.7-1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.6,0.6-1,0.5-1.6c0.1-0.1,0.1-0.2,0.2-0.2
				c0.4,0,0.6,0.3,0.5,0.7C81.2,49.5,80.9,50.4,81.8,50.2L81.8,50.2z"/>
			<path fill="#C59A81" d="M80.4,42c0.2,0,0.3,0,0.5,0c0.3,0.2,0.3,0.4,0.2,0.7c0,0.3,0.4,0.6-0.1,0.8c-1.1,0.4-0.6,1-0.1,1.6
				c0.7,0.6,0.6,1.1-0.5,1.5c-0.2,0-0.3,0-0.5,0c-0.4-0.2-0.3-0.6-0.4-0.9c0-0.5-0.4-0.6-0.8-0.8c-0.1-0.4-0.8-0.5-0.5-1.2
				c0.4-0.4-0.2-1.5,1-1.4C79.5,42,79.9,41.7,80.4,42z"/>
			<path fill="#C3A087" d="M77,46.1c-0.7,0-0.7-1-1.4-1.1c0.8,0,1.6-0.1,2.4-0.1c0.4,0.5,1.2-0.5,1.6,0.4c0.1,0.7,0.3,1.3,0.1,2
				c-0.5,0.8-1.2,0.7-1.9,0.3c0-0.1,0-0.2,0-0.2C77.3,47,77.3,46.4,77,46.1z"/>
			<path fill="#C3A188" d="M85.4,51.8c0.3,0,0.5-0.2,0.7-0.5c0.2-0.3,0.3-0.7,0.6-1c0.2-0.1,0.4-0.3,0.6-0.3c0.4-0.1,0.7,0.1,1,0.2
				c0.2,0.1,0.4,0.1,0.7,0.1c0.4,0,0.7,0.3,0.7,0.7c0,0.5-0.4,0.5-0.8,0.5c-0.4,0.1-0.6,0.3-0.3,0.7c0,0.3,0,0.7,0,1
				c0,0.5-0.3,0.2-0.5,0.2c-0.3-0.3-0.3-0.8-0.5-1.2c-0.2-0.3-0.4-0.5-0.7-0.2c0.1,0.2,0.1,0.5,0.2,0.7c-0.5-0.3-1.2-0.3-1.7-0.7
				C85.4,52,85.3,52,85.4,51.8C85.4,51.8,85.4,51.8,85.4,51.8z"/>
			<path fill="#C78D73" d="M84.4,41.1c0.2-0.6-0.4-1.2,0.2-1.7c0,0,0,0,0,0c0.6,0.1,0.8-0.3,1-0.7c0.3,0.1,0.3,0.5,0.5,0.7
				c0.5,0.4,0.2,0.8,0,1.2c0.3,0.8-0.2,1.4-0.8,1.6C84.9,42.3,84.8,41.5,84.4,41.1z"/>
			<path fill="#CA8066" d="M91.9,46.3c0.1,0.2,0.2,0.5,0.3,0.7c-0.2,0.3-0.3,0.6,0.2,0.7c0.2-0.1,0.3-0.1,0.5,0
				c-0.6,0.4-0.5,1.7-1.7,1.5c-0.7-0.3-0.9-0.9-0.8-1.6C90.6,47,90.9,46.2,91.9,46.3z"/>
			<path fill="#C78F75" d="M88.5,51.4c0.3,0,0.7,0.1,0.8-0.3c0-0.3-0.3-0.2-0.5-0.2c-0.3-0.5-0.1-1,0.3-1.2c0.6-0.3,1.2-0.6,1.8,0
				c0.5,1-0.5,1.1-0.9,1.5c-0.1,0.2,0,0.5-0.3,0.7c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2-0.1-0.5-0.2-0.7-0.3
				C88.6,51.7,88.4,51.6,88.5,51.4z"/>
			<path fill="#C8876D" d="M79.4,47c0.2-0.6,0.1-1.1,0-1.7c0,0,0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.4,0.1c0,0.4,0,0.8,0,1.2
				c0.2,0.5,0.5,0.9,0.5,1.4c-0.3,0.6-0.4,1.4-1.1,1.7c-0.3,0.1-0.6,0-0.9-0.2c-0.1-0.2-0.1-0.4,0-0.6c0-0.1,0-0.1,0-0.2
				C78.4,48,79.3,47.7,79.4,47z"/>
			<path fill="#C98267" d="M90.9,49.7c-1,0-1.8,0.1-2.2,1.2c-0.2,0-0.5,0-0.7,0c-0.2-0.1-0.1-0.3-0.1-0.4c0-0.6,0.6-0.8,0.6-1.4
				c0-0.3-0.3-0.5-0.4-0.8c0-0.1,0-0.2,0.1-0.3c0-0.2,0-0.3,0-0.5c0-0.5,0.4-0.3,0.6-0.4c0.4,0.1,0.5,0.4,0.7,0.7
				c0.1,0.2,0.3,0.3,0.4,0.5c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.3,0.4,0.5,0.5c0.1,0.1,0.2,0.2,0.3,0.3C90.9,49.5,90.9,49.6,90.9,49.7
				z"/>
			<path fill="#C49F86" d="M79.6,51.8c-0.3,0.2-0.1,0.8-0.7,0.8c0-0.5,0.2-1.1-0.8-1.2c-0.7,0-0.2-0.7,0.1-1.1c0,0,0.1-0.1,0.1-0.1
				c0,0,0.1-0.1,0.1-0.1c0.2-0.1,0.5-0.1,0.7-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.1,0.2,0.2l0,0
				C80.2,51,80.4,51.5,79.6,51.8z"/>
			<path fill="#C5977E" d="M79.4,47c-0.1,0.7,0.2,1.6-0.9,1.7c-0.5-0.2-0.9-0.6-0.7-1.2C78.2,47.1,78.9,47.6,79.4,47z"/>
			<path fill="#524D46" d="M66.4,38.6c0.1-0.1,0.3-0.3,0.4-0.4c0.4-0.2,0.8-0.5,1.2,0c0,0.5,0,1,0,1.4c-0.3,0.1-0.6,0.3-0.9,0
				c-0.1,0-0.2,0-0.3,0C66.8,39.2,66.5,38.9,66.4,38.6z"/>
			<path fill="#C88B71" d="M80.4,42c-0.2,0.2-0.5,0.3-0.6,0.5c-0.5,0.7-0.3-0.3-0.6-0.2c-0.1-0.5,0.1-0.9,0.5-1.2
				c0.3-0.1,0.5-0.2,0.8-0.4C80.7,41.1,80.3,41.5,80.4,42z"/>
			<path fill="#C69177" d="M84.7,39.1c-0.4,0-0.9,0-1.2,0.3c-0.6,0.5-0.9,0.2-1.2-0.3C83,38.5,84.2,38.5,84.7,39.1z"/>
			<path fill="#C78C72" d="M79.4,45.4c-0.3-0.1-0.6-0.2-0.9,0.1c-0.2,0.2-0.4,0.6-0.7,0.3c-0.3-0.3,0.1-0.6,0.2-0.8
				c0.6-0.3-0.3-0.9,0.2-1.2c0.1,0.4,0.4,0.7,0.7,1C79.1,44.8,79.8,44.8,79.4,45.4L79.4,45.4z"/>
			<path fill="#C69277" d="M79.6,51.8c0.3-0.5,0.3-1,0.3-1.5c1-0.2,0.6,0.8,1,1.2c0,0.2-0.1,0.2-0.3,0.2c0,0.1,0,0.2,0,0.2
				C80.4,51.6,79.7,53,79.6,51.8z"/>
			<path fill="#C5987E" d="M86.1,40.6c0-0.4,0-0.8,0-1.2c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.2,0.5-0.5,0.8-0.1c0.3,0.4-0.1,0.5-0.3,0.7
				c-0.1,0.1,0,0.3,0.1,0.4C86.7,40.1,86.4,40.4,86.1,40.6z"/>
			<path fill="#413E39" d="M68.1,38.1c-0.4,0-0.8,0-1.2,0c0.5-0.4,1.2-0.8,0.5-1.6c-0.2-0.2-0.1-0.4,0.2-0.4c0.7,0,0.5,0.5,0.6,0.8
				C68.1,37.4,68.1,37.8,68.1,38.1z"/>
			<path fill="#C5987E" d="M85.7,38.6c-0.3,0.9-0.4,1.1-1,0.7c0.4-0.6,0.3-1.2,0.4-1.9c0.5,0.3,0.5,0.6,0.2,1.1c0,0.1,0,0.2,0,0.3
				C85.4,38.7,85.6,38.7,85.7,38.6z"/>
			<path fill="#C79076" d="M88,41.5c-0.1-0.1-0.2-0.2-0.2-0.3c0.4-0.2,0.7-0.5,1-0.9c0.2-0.2,0.5-0.4,0.7-0.1
				c0.2,0.3-0.1,0.5-0.3,0.7C88.8,41.2,88.4,41.3,88,41.5z"/>
			<path fill="#C78D72" d="M86.9,52.1c0-0.8,0.4-0.6,0.7-0.3c0.4,0.5,0.4,1.1,0.5,1.7c-0.4,0.1-0.4-0.2-0.5-0.5
				C87.5,52.6,87.4,52.2,86.9,52.1z"/>
			<path fill="#C78D73" d="M77,46.1c1,0,0.5,0.8,0.7,1.2c-0.4-0.1-0.9-0.2-1.4-0.3C76.3,46.5,77,46.5,77,46.1z"/>
			<path fill="#C5987E" d="M90.5,39.1c0,0.4-0.3,0.4-0.6,0.4c-0.2,0-0.5,0-0.5-0.4c0-0.3,0.2-0.5,0.5-0.6
				C90.3,38.6,90.4,38.8,90.5,39.1z"/>
			<path fill="#C49E85" d="M92.8,47.8c-0.2,0-0.3,0-0.5,0c0.1-0.2,0.2-0.4,0.5-0.5c0.3-0.1,0.6-0.3,0.8,0.1c0.1,0.2,0.2,0.5,0,0.7
				C93.3,48.3,93.2,47.7,92.8,47.8z"/>
			<path fill="#C69379" d="M80.6,52.6c0.2,0.4,0.8,0.6,0.7,1.2C80.7,53.6,79.5,53.8,80.6,52.6z"/>
			<path fill="#C78E74" d="M81.6,39.1c0.1,0.2,0.1,0.4,0.2,0.5c0.2,0.2,0.4,0.4,0,0.7c-0.2,0.2-0.4,0.3-0.6,0.1
				c-0.2-0.2-0.1-0.4,0-0.6c0.1-0.2,0.1-0.5,0.1-0.7C81.4,39.1,81.5,39.1,81.6,39.1z"/>
			<path fill="#47433E" d="M58.1,54.5c-0.5,0.1-1,0.3-1.7,0c0.4-0.6,1-0.4,1.5-0.5C57.9,54.1,58,54.3,58.1,54.5z"/>
			<path fill="#C5987E" d="M89.6,38.2c-0.7,0.1-1,0.7-1.4,0.3c-0.1,0,0-0.3,0.1-0.4C88.6,37.7,88.9,38.1,89.6,38.2z"/>
			<path fill="#C78C72" d="M83.3,54.6c-0.5,0-0.8-0.2-0.9-0.6c0-0.2,0.1-0.3,0.3-0.3C83.1,53.8,83.2,54.2,83.3,54.6z"/>
			<path fill="#C78E73" d="M86,54.1c-0.4,0.6-0.8,0.7-1.3,0.4C85,54.1,85.4,54.1,86,54.1z"/>
			<path fill="#C78B71" d="M93.1,46.5c-0.2-0.4-0.1-0.8,0-1.2C93.9,46.3,93.9,46.3,93.1,46.5z"/>
			<path fill="#C79177" d="M89,39.3c-0.5,0.3-0.8,0.4-1.2,0.6C87.8,39.2,88.1,39.1,89,39.3z"/>
			<path fill="#C6957B" d="M89.5,52.1c0-0.1,0-0.2,0-0.2c0.5-0.1,0.3,0.4,0.5,0.5c0.2,0.1,0.3,0.2,0.1,0.4c-0.1,0.2-0.3,0.2-0.5,0.1
				C89.3,52.6,89.4,52.3,89.5,52.1z"/>
			<path fill="#C78F75" d="M90.9,49.4c-0.2,0-0.3,0-0.5,0c-0.5-1.3-0.5-1.5,0.3-1.9c-0.1,0.6,0.2,1.2,0.5,1.7
				C91.1,49.3,91,49.4,90.9,49.4z"/>
			<path fill="#423F3A" d="M57.9,48c-0.3-0.1-0.6-0.2-0.6-0.5c0-0.1,0.1-0.3,0.2-0.3c0.4,0,0.5,0.4,0.7,0.6C58,47.9,57.9,48,57.9,48
				z"/>
			<path fill="#C79076" d="M81.1,38.6c0,0.6-0.3,0.5-0.7,0.5c0-0.3-0.1-0.7,0.2-0.9C80.9,38.1,80.8,38.7,81.1,38.6z"/>
			<path fill="#C5977E" d="M77.3,48.4c0,0.4,0,0.6-0.3,0.8c0,0-0.2-0.1-0.2-0.2C76.8,48.7,76.9,48.4,77.3,48.4z"/>
			<path fill="#C88A70" d="M92.4,44.4c-0.5,0.1-0.6-0.2-0.7-0.6c0-0.2,0.1-0.4,0.3-0.3c0.2,0.1,0.3,0.3,0.4,0.4
				C92.4,44.1,92.4,44.2,92.4,44.4z"/>
			<path fill="#C3A188" d="M90,52.3c-0.2-0.2-0.3-0.3-0.5-0.5c0.2-0.2,0.3-0.5,0.5-0.7C90.5,51.5,90.1,51.9,90,52.3z"/>
			<path fill="#C69277" d="M78.5,50.4c-0.1,0-0.2,0-0.2,0c-0.3-0.5-0.3-1,0.2-1.5c0,0.2,0,0.3,0,0.5C78.7,49.8,78.8,50.1,78.5,50.4z
				"/>
			<path fill="#C69278" d="M81.6,53.7c0.3-0.5,0.6-1.1,1.5-0.9C82.6,53.1,82.1,53.4,81.6,53.7z"/>
			<path fill="#C69177" d="M77.4,42.1c0.2,0,0.3,0.2,0.3,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1-0.1-0.2-0.2-0.3-0.3
				C77.2,42.3,77.3,42.2,77.4,42.1z"/>
			<path fill="#C6947B" d="M77.2,43.4c0,0.1,0.1,0.2,0.2,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1-0.1-0.2-0.2-0.2-0.4
				C76.9,43.5,77,43.4,77.2,43.4z"/>
			<path fill="#403D39" d="M67.2,39.6c0.3-0.1,0.6-0.1,0.9,0c0,0.2,0,0.3,0,0.5C67.8,39.9,67.3,40.2,67.2,39.6z"/>
			<path fill="#C8886E" d="M88.5,51.4c0.1,0.2,0.1,0.3,0.2,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C88,52,87.9,51.7,88.5,51.4z"/>
			<path fill="#262523" d="M60.4,69.1c-0.2,0-0.5-0.1-0.5-0.4c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.3,0.2,0.4,0.4
				C60.5,69,60.4,69,60.4,69.1z"/>
			<path fill="#C69379" d="M92.8,49.7c-0.2-0.3-0.3-0.5-0.2-0.7c0,0,0.2,0,0.2,0C92.9,49.2,93,49.4,92.8,49.7z"/>
			<path fill="#C5957B" d="M82.2,37.9c0,0.1,0.1,0.2,0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.2-0.2
				C82,38,82.1,38,82.2,37.9z"/>
			<path fill="#524D47" d="M63,62.9c-0.2-0.1-0.5-0.2-0.7-0.3c0.3-0.4,0.5,0,0.8,0.1C63.1,62.7,63.1,62.8,63,62.9z"/>
			<path fill="#353330" d="M63.8,59.5c-0.1,0.1-0.4,0.2-0.4,0.1c-0.2-0.3,0-0.4,0.2-0.6C63.6,59.2,63.7,59.4,63.8,59.5z"/>
			<path fill="#CA8066" d="M92.1,41.8c-0.2-0.1-0.5,0-0.6-0.3c-0.1-0.2,0.2-0.1,0.3-0.1C91.9,41.5,92,41.6,92.1,41.8z"/>
			<path fill="#C5987E" d="M87.6,41c-0.2-0.2-0.5-0.3-0.5-0.7C87.4,40.4,87.6,40.7,87.6,41z"/>
			<path fill="#C78D72" d="M92.4,50.6c0,0.1,0.1,0.1,0.1,0.2c0,0-0.1,0.1-0.2,0.1c0-0.1-0.1-0.1-0.1-0.2
				C92.2,50.6,92.3,50.6,92.4,50.6z"/>
			<path fill="#4D4843" d="M61.6,66.5c-0.3-0.3-0.2-0.6,0.2-0.7C61.8,66,61.7,66.2,61.6,66.5z"/>
			<path fill="#1C1C1A" d="M59.1,55.9c-0.1,0.1-0.2,0.2-0.2,0.3c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2,0-0.4,0.3-0.4
				C59,55.7,59.1,55.8,59.1,55.9z"/>
			<path fill="#5B544D" d="M50.1,50.4c0.2,0.2,0.1,0.5,0,0.7c0,0.4-0.4,0.5-0.7,0.7c-0.4-0.1-0.5-0.4-0.5-0.7
				c-0.1-0.2-0.3-0.4-0.3-0.6c0-0.1,0.1-0.1,0.2-0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0.2,0.2,0.4,0,0.6c0,0.1,0,0.2,0,0.3
				c-0.2,0.1-0.3,0.1-0.5,0c-0.2-0.2-0.3-0.3-0.5-0.5c-0.5-0.2-0.6-0.7-0.7-1.2c-0.1-0.2-0.7-0.6,0.1-0.5c0.3,0,0.6,0,0.9-0.2
				c0.7,0.2,0.8-0.5,1.2-0.7c0.1-0.2,0.3-0.3,0.4-0.5c0.3,0,0.2,0.2,0.3,0.4c0.5,0.6-0.4,1.1,0,1.7C50.1,50,50,50.2,50.1,50.4z"/>
			<path fill="#565049" d="M31.2,38.4c-0.3-0.1-0.4-0.2-0.2-0.5c0.3-0.4,0.1-0.6-0.3-0.4c-0.6,0.3-0.8-0.1-1-0.5
				c-0.2-0.1-0.1-0.3,0-0.5c0.6-0.3,0.5-0.9,0.5-1.4c0.1-0.1,0.1-0.1,0.2-0.1c0.4,0.2,0.8,0.3,1.2,0.5c0.1,0.2,0.2,0.3,0,0.5
				c-0.2,0.1-0.4,0.2-0.7,0.2c-0.2,0.1-0.2,0.2,0,0.2c0.7,0.1,0.9,0.8,1.4,1.2C32.1,38.2,31.6,38.3,31.2,38.4z"/>
			<path fill="#4A4540" d="M31.4,51.6c0.6-0.1,1.2-0.2,1.7-0.3c0.2-0.1,0.5-0.2,0.6,0c0.2,0.3-0.1,0.5-0.2,0.6
				c-0.9,0.4-1.5,0.9-1.8,1.9c-0.1,0.4-0.6,0.3-0.7-0.2c-0.1-0.4-0.1-0.8,0.1-1.2C31.4,52.1,31.3,51.9,31.4,51.6z"/>
			<path fill="#47433E" d="M34,23.5c0.2-0.1,0.3-0.3,0.5-0.4c-0.2-0.3-0.2-0.5,0-0.8c0.8,0.7,1.3,0.2,1.7-0.5
				c0.1,0.2,0.3,0.3,0.5,0.5c-0.8,0.3-0.6,1.1-0.7,1.7l0,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.2
				c-0.2,0.3-0.2,0.5,0.2,0.7c0.2,0.3,0.6,0.5,0.5,1c-0.6-0.3-1.1-0.7-1.8-0.4c-0.2-0.6,0.4-1,0-1.5C34.2,23.8,34.1,23.7,34,23.5z"
				/>
			<path fill="#514C46" d="M48.5,60.5c-0.6,0.4-1,0-1.5-0.3c-0.3-0.2-0.4-0.4-0.3-0.7c0.4-0.1,0.6-0.3,0.3-0.7
				c-0.2-0.2-0.1-0.3,0-0.5c1-0.2,0.6,1.1,1.3,1.2c0.3-0.2,0-0.8,0.5-0.8c0.3,0,0.3,0.3,0.3,0.5c0.1,0.2,0.2,0.3,0,0.5
				c0,0.2,0,0.3,0,0.5c-0.1,0.2-0.3,0.2-0.5,0.2c-0.1,0-0.2,0-0.3,0C48.5,60.4,48.5,60.5,48.5,60.5z"/>
			<path fill="#524D46" d="M67.2,46.3c0,0.6,0,1.3-0.9,1.3c-0.8,0.1-1.2-0.2-1.3-1c-0.2-0.3-0.2-0.5,0-0.8l0,0
				c0.2-0.2,0.5-0.2,0.7,0c0.2,0,0.3,0,0.5,0C66.5,46.2,66.9,46.1,67.2,46.3z"/>
			<path fill="#514C46" d="M51.8,46.4c-0.2-0.3-0.4-0.5-0.7-0.5c-0.2-0.2-0.4-0.4-0.5-0.7c0-0.4,0.2-0.6,0.4-0.9
				c0.1-0.3,0.3-0.6,0.5-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0.3,0.3,0.6,0.5,0.8c0.4,0.4,0.4,0.9,0.7,1.4c0.1,0.6-0.4,0.6-0.7,0.7
				C52.2,46.5,52,46.5,51.8,46.4z"/>
			<path fill="#4F4A44" d="M26.1,33.3c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2-0.1-0.5-0.2-0.5-0.5c-0.4-0.4-0.1-0.9,0.2-1.1
				c0.3-0.3,0.7,0.2,1.1,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.2,0.5,0.2,0.7
				c0.2,0.3-0.1,0.5-0.2,0.7c-0.2,0-0.3,0-0.5,0C26.2,33.6,26.1,33.5,26.1,33.3z"/>
			<path fill="#565049" d="M53.7,66.5c-0.4-0.6-1.2-0.4-1.7-0.8c-0.2-0.2-0.6-0.4-0.5-0.8c0.2-0.5,0.7-0.2,0.9,0
				c0.6,0.4,1.3,0.5,1.5,1.3C54.1,66.5,54,66.6,53.7,66.5z"/>
			<path fill="#514C46" d="M53,56.2c0.5-0.7,1.4-0.6,2.1-0.8c0.8-0.2,0.9,0.8,1.3,1.4C55.4,55.7,54.1,56.3,53,56.2L53,56.2z"/>
			<path fill="#534E48" d="M37.4,53.7c-0.1,0.2-0.2,0.3-0.2,0.5c-0.3,0.3-0.6-0.2-0.9,0c-0.1,0-0.2,0-0.3,0
				c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3-0.4,0.4-0.9-0.1-1.3c0-0.1,0.1-0.2,0.2-0.2c0.5-0.3,0.9-0.6,1,0.3c-0.1,0.1-0.4,0.2-0.1,0.4
				c0.1,0.1,0.3,0.2,0.4,0.2C37.2,53.3,37.3,53.6,37.4,53.7z"/>
			<path fill="#554F48" d="M50.6,57.1c-1.4,0.5-0.4-0.8-0.7-1c0,0,0-0.1,0-0.1c0.4,0,0.8,0,1.3,0c0.1,0.2,0.4,0,0.5,0.3
				c0.2,0.3,0.3,0.6,0,0.9C51.2,57,50.9,57.2,50.6,57.1z"/>
			<path fill="#494540" d="M42.9,60.2c-0.5,0.7-1.3,0.6-1.9,0.7c-0.1-0.1-0.1-0.1,0-0.2c0,0,0.1-0.1,0.1-0.1
				c0.6-0.2,1.2-0.3,1.4-1.2c0.2-0.8,0.8-0.9,1.3,0c0.1,0.1,0.1,0.2,0.1,0.3C43.6,60.1,43.2,60,42.9,60.2z"/>
			<path fill="#46423D" d="M37.1,15.4c-1.9,0-1.9,0-1.7-1.7c0.2,1,1.4,0.6,1.8,1.4C37.2,15.2,37.2,15.3,37.1,15.4z"/>
			<path fill="#4B4641" d="M33.1,25.2c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0-0.3-0.2-0.5-0.3c-0.8-0.6-0.5-1.3-0.2-2
				c0.2-0.1,0.3,0.1,0.4,0.3c0,0.1,0,0.2,0,0.3c0.1,0.2,0.3,0.2,0.5,0c0.2,0.4,0.9,0.3,0.7,1C33.4,25.1,33.3,25.2,33.1,25.2z"/>
			<path fill="#3E3B37" d="M35.1,48.7c-0.1,0-0.2,0-0.2,0C34.5,49,34,49.5,33.7,49c-0.2-0.3,0.3-0.9,0.7-1.3
				c0.6-0.5,0.7,0.2,0.9,0.5C35.4,48.4,35.5,48.7,35.1,48.7z"/>
			<path fill="#403D39" d="M30.5,45.3c0,0.5-0.3,0.7-0.8,0.7c-0.2-0.3-0.8-0.3-0.5-0.9c0.1-0.6,0.5-1,1-0.9
				C30.9,44.4,30.3,45,30.5,45.3z"/>
			<path fill="#58524B" d="M31.4,19.4c0.2-0.7,0.7-0.1,1-0.2c0.6,0.5,1.3,0.9,0.5,1.8c-0.2,0.1-0.3,0-0.5-0.1
				c-0.3-0.3-0.7-0.5-0.7-1C31.5,19.8,31.5,19.6,31.4,19.4z"/>
			<path fill="#48443F" d="M27.3,44.6c-0.2,0-0.4,0-0.5,0c-0.3-0.3-0.2-0.8-0.2-1.2c0.1,0,0.1,0,0.2,0c0.3,0.1,0.5,0.1,0.8,0.2
				c0.2,0,0.5,0,0.7,0c0.3,0.6,0.6,1.2-0.6,0.8C27.6,44.4,27.5,44.6,27.3,44.6z"/>
			<path fill="#4A4540" d="M31.7,33.4c-0.4-0.5-0.2-1.1-0.2-1.6c0.1-0.4,0.4-0.6,0.7-0.5c0.3,0.1,0.9,0.3,0.5,0.9
				c-0.2,0-0.3,0.1-0.3,0.3C32.1,32.8,31.9,33.1,31.7,33.4L31.7,33.4z"/>
			<path fill="#514C46" d="M51.1,47.5c0.2-0.3,0.4-0.6,0.5-0.9c0.1,0.1,0.4,0,0.4,0.2c0.2,0.3,0.5,0.6,0.7,0.9
				c0.1,0.1,0.1,0.2,0,0.3c-0.5,0-1,0.3-1.4,0.5c-0.2-0.2-0.1-0.4-0.2-0.7C51.1,47.7,51.1,47.6,51.1,47.5L51.1,47.5z"/>
			<path fill="#555049" d="M50.4,68.5c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1,0.1-0.2,0.1-0.2,0c-0.2-0.3-0.5-0.6-0.7-0.9
				c0.3-0.3,0.7-0.3,1.2-0.3c0,0.4,0.5,0.4,0.7,0.7c0.1,0.1,0.2,0.3,0.2,0.4C51,68.4,50.7,68.5,50.4,68.5z"/>
			<path fill="#555049" d="M31.6,43.2c-0.4,0.5-0.6,0.6-0.9,0c-0.2-0.3-0.4-0.6-0.7-0.7c-0.2,0.2-0.3,0.6-0.7,0.5
				c0.2-1.3,1.1-0.9,1.9-0.8C31.4,42.5,31.3,42.9,31.6,43.2z"/>
			<path fill="#232220" d="M66,42c-0.4-0.8,0.8-0.8,0.7-1.5c0.3,0,0.5,0.2,0.5,0.4c-0.1,0.4,0.2,1.1-0.5,1.2
				C66.5,42.2,66.2,42,66,42L66,42z"/>
			<path fill="#34322F" d="M24.5,36.2c0.1-0.2,0.2-0.3,0.3-0.5c0.4-0.5,0-1.3,0.9-1.9c-0.3,1.1,0,2.1-0.7,2.9
				C24.6,36.7,24.3,36.7,24.5,36.2z"/>
			<path fill="#554F49" d="M45.6,28.8c-1.1,0.6-1.8-0.4-2.7-0.8c-0.1-0.2-0.2-0.4,0.2-0.5c0.9-0.3,1.1,1.1,2,1
				C45.2,28.7,45.5,28.6,45.6,28.8z"/>
			<path fill="#3C3936" d="M50.3,43.4c-0.2-0.9,0.3-1.4,1-1.8c0.2,0.3-0.3,0.7,0.2,0.9c0.1,0.6-0.3,0.9-0.7,1.1
				C50.7,43.6,50.5,43.6,50.3,43.4z"/>
			<path fill="#44413C" d="M35,46.3c-0.7-0.4-1.4-0.1-2.2,0c0.4-0.6,0.8-0.8,1.2-0.7c0.9,0.4,1.3-0.1,1.7-0.8l0,0
				c0,0.4,0.1,0.8-0.2,1.2C35.4,46.2,35.2,46.3,35,46.3z"/>
			<path fill="#111210" d="M34.3,42.3c0.4-0.2,0.7,0,1.1,0.1c0.3,0.1,0.5,0.2,0.5,0.6c0,0.2-0.1,0.2-0.3,0.3
				c-0.3,0.1-0.6,0.2-0.8-0.2C34.7,42.8,34.5,42.6,34.3,42.3z"/>
			<path fill="#2B2A27" d="M39.6,22.3c0.4-0.3,0.7-0.5,1.1-0.8c0.2,0.6,0.1,1.1-0.2,1.6c-0.3,0.5-0.5,0.3-0.7-0.1
				C40,22.7,39.8,22.5,39.6,22.3z"/>
			<path fill="#3F3C38" d="M34.1,20.6c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2-0.4-1.5-0.3-0.5-1.2c1.3-1,0.6,0.6,1,0.7
				C34.2,20.2,34.2,20.4,34.1,20.6z"/>
			<path fill="#544E48" d="M46.3,41.8c0.3,0,0.8-0.4,1,0.2c-0.1,0.2-0.1,0.5-0.2,0.7c-0.4-0.1-0.3,1.4-1,0.3
				C45.9,42.6,46.1,42.2,46.3,41.8z"/>
			<path fill="#111110" d="M30.4,20.6c-0.2,0.2-0.1,0.6-0.5,0.7c-0.2-0.1-0.1-0.3-0.2-0.4c-0.4-1.2-0.3-1.2,0.5-1.4
				C30.5,19.8,31.4,20,30.4,20.6z"/>
			<path fill="#565049" d="M44.1,47.1c0.2-0.4-0.1-1.2,0.7-1.2c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1-0.1,0.1c0.1,0.3,0.6,0.4,0.5,0.7
				c-0.2,0.5-0.8,0.3-1.2,0.5C44,47.2,44,47.2,44.1,47.1C44.1,47.1,44.1,47.1,44.1,47.1z"/>
			<path fill="#35332F" d="M29,40.8c-0.1,0.2-0.2,0.5-0.4,0.4c-0.3,0-0.2-0.3-0.2-0.5c-0.1-0.8,0.3-1.2,1.1-1.2l0,0
				c0,0.2,0,0.3,0,0.5C29.2,40.3,29.2,40.6,29,40.8z"/>
			<path fill="#4B4741" d="M58.3,65c-0.4,0.5-0.8,0.9-1.1,1.5C56.9,65.4,57.2,65,58.3,65z"/>
			<path fill="#47433E" d="M59.5,34.3c-0.3,0-0.6,0-0.8-0.1c-0.3-0.2-0.3-0.5-0.3-0.9c0-0.1,0.2-0.3,0.3-0.3
				c0.5-0.1,0.4,0.4,0.5,0.6c0.1,0.1,0.2,0.2,0.3,0.4C59.6,34.2,59.6,34.3,59.5,34.3z"/>
			<path fill="#4C4842" d="M49.2,48.2C49.1,48.9,49,49.7,48,49c0.4-0.4,0.3-1,0.5-1.5l0,0C48.7,47.7,48.8,48.1,49.2,48.2z"/>
			<path fill="#373531" d="M40.1,35.8c-0.2,0-0.5,0.1-0.6-0.1c-0.1-0.3,0.2-0.4,0.4-0.5c0.4-0.1,0.7-0.2,1.2-0.3
				c-0.1,0.4-0.2,0.6-0.3,0.8C40.5,35.9,40.3,36,40.1,35.8z"/>
			<path fill="#33312E" d="M48.5,62.4c-0.1-0.3-0.2-0.6-0.2-1c0.4-0.2,0.6-0.8,1.2-0.5c0.9,0.5-0.3,0.8-0.1,1.2
				C49.1,62.3,48.8,62.7,48.5,62.4z"/>
			<path fill="#3B3935" d="M61.4,39.1c-0.8-0.9,0.7-1.1,0.5-1.8C62.4,38.1,61.8,38.6,61.4,39.1L61.4,39.1z"/>
			<path fill="#1A1918" d="M44.6,59.6c0.3-0.1,0.7-0.1,0.8,0.3c0.2,0.4-0.4,0.5-0.5,0.8c-0.2,0.4-0.5-0.1-0.8-0.2
				C43.9,60,44.2,59.7,44.6,59.6z"/>
			<path fill="#44413C" d="M34.3,44.8c0.2-0.2,0.7,0,0.8-0.5c0.1-0.3,0.3-0.4,0.5-0.3c0.4,0.2,0.4,0.4,0.2,0.8c0,0,0,0,0,0
				c-0.6-0.3-0.9,0.8-1.5,0.3C34.3,45.1,34.3,44.9,34.3,44.8z"/>
			<path fill="#3D3B37" d="M30.4,20.6c0.4-0.5-0.6-0.7-0.2-1.2c0.4-0.2,0.8,0.4,1.2,0c0.1,0.2,0.2,0.3,0.3,0.5
				C31.3,20.2,31,20.7,30.4,20.6z"/>
			<path fill="#3A3834" d="M37.7,40.1c0.1-0.3,0-0.7-0.3-1.2c0.8,0.3,1.3,0.3,1.7,0.8c-0.3,0.3-0.6,0.3-0.9,0.5
				c-0.1,0.1-0.2,0.2-0.3,0.2C37.7,40.3,37.7,40.2,37.7,40.1z"/>
			<path fill="#514C46" d="M51.1,47.5c-0.3,0.1-0.5,0.3-0.8,0.4c-0.2-0.2-0.4-0.3-0.6-0.4c0.1-0.5,0.4-0.8,0.9-0.9
				C51.2,46.6,50.9,47.3,51.1,47.5C51.1,47.5,51.1,47.5,51.1,47.5z"/>
			<path fill="#040404" d="M31.9,56.6c-0.5-0.1-0.3-0.7-0.5-1c0.1-1.1,0.6-0.5,1-0.2C32.3,55.9,32.2,56.3,31.9,56.6z"/>
			<path fill="#534E48" d="M37.2,54.5c0.2,0,0.4,0,0.6,0c0.2,0,0.6,0,0.6,0.3c0,0.3-0.4,0.2-0.6,0.2c-0.4,0-0.5,0.5-0.8,0.5
				c-0.6-0.3-0.4-0.5,0.1-0.7C37,54.6,37.1,54.5,37.2,54.5z"/>
			<path fill="#534E47" d="M42.8,64.4c-0.1,0.4-0.3,0.6-0.7,0.7c-0.2,0-0.4-0.1-0.3-0.3c0.2-0.3,0.3-0.8,0.7-0.9
				C42.8,63.8,42.7,64.2,42.8,64.4z"/>
			<path fill="#161514" d="M29.2,29c0.6,0,0.4,0.6,0.6,0.9c0.1,0.3-0.3,0.4-0.6,0.4c-0.6,0,0-0.8-0.5-0.9C28.7,29.3,29,29.1,29.2,29
				z"/>
			<path fill="#413E39" d="M48.2,62.8c-0.7,0.3-1.4,0.6-1.3-0.8c0.4,0.2,1,0,1.3,0.6C48.3,62.7,48.3,62.7,48.2,62.8
				C48.3,62.8,48.3,62.8,48.2,62.8z"/>
			<path fill="#514C45" d="M37.7,40.1c0.1,0.1,0.1,0.2,0.2,0.3c-0.6,0.5,0,1.6-1,1.9c-0.3-0.3-0.2-0.6,0-0.9
				C37.2,40.9,37.1,40.3,37.7,40.1z"/>
			<path fill="#4F4A44" d="M31.7,33.4c-0.1,0.2-0.2,0.3-0.4,0.5c-0.3,0.3-0.6,0.1-0.8-0.1c-0.1-0.2-0.2-0.4-0.1-0.5
				c0.2-0.3,0.5-0.1,0.7,0C31.3,33.2,31.5,33.3,31.7,33.4C31.7,33.4,31.7,33.4,31.7,33.4z"/>
			<path fill="#413E39" d="M48.6,57c0.1,0.4-0.2,0.5-0.5,0.5c-0.3,0-0.6-0.2-0.7-0.5c0-0.3,0.3-0.4,0.6-0.5
				C48.5,56.5,48.6,56.7,48.6,57z"/>
			<path fill="#1E1E1C" d="M33.1,19.2c-0.4,0.6,0.5,0.7,0.5,1.2c-0.1,0.3-0.3,0.5-0.4,0.8c-0.2,0-0.3-0.1-0.3-0.3
				c0.6-0.7-0.8-1-0.4-1.6c0,0,0,0,0,0c0.1-0.1,0-0.4,0.2-0.5C32.8,18.9,32.9,19,33.1,19.2z"/>
			<path fill="#0D0D0C" d="M32.4,23.7c-0.1-0.1-0.3-0.2-0.4-0.3c0.1-0.5-0.6-1-0.1-1.6c-0.1-0.3,0-0.3,0.3-0.3
				C32.7,22.3,32.1,23,32.4,23.7z"/>
			<path fill="#534D47" d="M42.9,48.5c-0.7-0.8,0.3-0.7,0.5-1c0.4,0,0.3,0.3,0.4,0.5c-0.1,0.3-0.2,0.7-0.2,1
				C43.2,49.1,43.2,48.6,42.9,48.5z"/>
			<path fill="#080808" d="M27.6,24.9c0.1-0.2,0.2-0.4,0.2-0.6c1.4-0.3,0.7,0.6,0.7,1.2c-0.2,0.1-0.3,0.2-0.5,0
				C28,25.2,27.8,25,27.6,24.9z"/>
			<path fill="#161615" d="M27.6,26.9c0.2-0.1,0.3-0.2,0.5-0.2c0.3,0,0.3,0.3,0.4,0.5c0.2,0.4,0.1,0.7-0.2,0.9
				c-0.1,0-0.6-0.2-0.6-0.7C27.6,27.2,27.6,27,27.6,26.9z"/>
			<path fill="#292826" d="M50.8,45.1c0.2,0.2,0.4,0.4,0.3,0.7c-0.8,0.2-1.5,0.4-1.7-0.7c0.3-0.1,0.6-0.1,0.8,0
				c0.3,0.4,0.2-0.2,0.3-0.1C50.7,45,50.8,45,50.8,45.1z"/>
			<path fill="#534D47" d="M34.1,55c-0.3,0.2-0.7,0.1-1-0.1c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.9,0.5-0.2,0.7,0c0.2,0.1,0.2,0.3,0.1,0.5
				C34.3,54.7,34,54.8,34.1,55z"/>
			<path fill="#4F4A44" d="M49.4,60.9c-0.4,0.1-0.7,0.6-1.2,0.5c-0.4-0.4,0.6-0.5,0.3-0.9c0,0,0-0.1,0-0.1c-0.1,0-0.1-0.1-0.1-0.1
				c0-0.1,0-0.1,0.1-0.1c0.3,0,0.5,0,0.8,0C49.3,60.5,49.3,60.7,49.4,60.9z"/>
			<path fill="#292826" d="M53,45.6c-0.6-0.3-0.6-0.8-0.7-1.4c0,0,0.1,0,0.1,0C53.6,44.6,53.6,44.6,53,45.6z"/>
			<path fill="#302F2C" d="M51.6,40.4c-1.1,0.5-1.1,0.5-1.4-0.6C50.8,39.9,51,40.5,51.6,40.4z"/>
			<path fill="#4C4742" d="M50.4,51.6c0,0.2,0,0.5-0.1,0.7c-0.1,0.3-0.3,0.6-0.7,0.5c-0.5-0.3-0.3-0.6,0-0.9
				C49.8,51.6,50,51.4,50.4,51.6z"/>
			<path fill="#46423D" d="M34.7,12.2c0.5,0,0.5,0.3,0.7,0.5c0.2,0.2,0.5,0.1,0.5,0.4C34.9,13.4,34.9,13.4,34.7,12.2z"/>
			<path fill="#403D39" d="M54.2,45.6c-0.2,0.3-0.1,0.7-0.6,1c0.1-0.9,0.2-1.7,0.8-2.7c0,0.6,0,0.9,0,1.2
				C54.6,45.4,54.3,45.5,54.2,45.6z"/>
			<path fill="#383532" d="M47.4,52.7c-0.7,0.3-1.3,0.4-1.2-0.7C46.7,52.1,47,52.5,47.4,52.7z"/>
			<path fill="#070706" d="M35.5,23.7c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.3-0.2,0.6-0.2,0.9c-0.8,0-0.9-0.1-0.5-1.1
				C35.1,23.5,35.3,23.6,35.5,23.7z"/>
			<path fill="#534D47" d="M42.6,49.9c-0.5-0.2-0.4-0.6-0.4-1l0,0c0.2-0.1,0.5-0.2,0.7-0.3c0.3,0.1,0.6,0.3,0.5,0.7
				C43.4,49.9,43.5,50.5,42.6,49.9c0.1-0.1,0,0.1,0.1,0C42.8,49.9,42.7,49.9,42.6,49.9z"/>
			<path fill="#282725" d="M30.5,22.8c0,0.1,0.1,0.2,0.1,0.3c0,0.4,0.6,0.8,0.2,1.3c0,0-0.3,0-0.3-0.1c-0.2-0.5-0.3-1-0.2-1.5
				C30.3,22.8,30.4,22.8,30.5,22.8z"/>
			<path fill="#4F4A44" d="M52,61.4c-0.1,0.6-0.6,0.4-1,0.4c-0.3,0-0.7,0-0.7-0.4c0-0.3,0.4-0.2,0.7-0.2
				C51.4,61.2,51.8,61.1,52,61.4z"/>
			<path fill="#161614" d="M46.7,59.5c0.2,0.2,0.3,0.4,0.3,0.7c-0.3,0.2-0.4,0.4-0.5,0.7c-0.1,0.1-0.1,0.1-0.2,0
				C45.8,60.3,46.1,59.8,46.7,59.5z"/>
			<path fill="#3B3935" d="M44.9,46.1c0-0.1,0-0.1,0-0.2c0.3-0.6,1.1-1,1.4-0.8c0.5,0.4-0.2,0.5-0.5,0.7
				C45.4,45.8,45.1,45.9,44.9,46.1z"/>
			<path fill="#504A44" d="M44.6,59.6c-0.2,0.3-0.3,0.6-0.5,0.9c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.2-0.1-0.3,0-0.5c0-0.1,0-0.2,0-0.3
				C44,59.7,44.4,58.4,44.6,59.6z"/>
			<path fill="#47433E" d="M49.7,62.9c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.3,0.5,0.5,0.1,0.8c-0.2,0.2-0.4,0.2-0.6,0
				c-0.4-0.4,0-0.7,0.2-0.9C49.5,63,49.5,62.9,49.7,62.9z"/>
			<path fill="#2B2A27" d="M31.4,40.5c0-0.5,0.2-0.9,0.7-1.1c0.3-0.1,0.5-0.1,0.6,0.2c0.1,0.3,0,0.4-0.3,0.4c-0.4,0-0.5,0.3-0.7,0.6
				C31.5,40.6,31.4,40.6,31.4,40.5z"/>
			<path fill="#534E47" d="M41.7,63.1c-0.1,1.1-0.9,0.5-1.4,0.6C40.7,63.2,41.2,63.1,41.7,63.1L41.7,63.1z"/>
			<path fill="#252422" d="M59.2,67.3c-0.3,0.6-0.5,1-0.2,1.4c-0.5,0-0.8-0.2-1-0.5C58.3,67.9,58.6,67.7,59.2,67.3z"/>
			<path fill="#373532" d="M54.2,63.9c0.3,0,0.7,0.1,1,0.1c0.2,0.5-1,0.2-0.5,0.8c0.1,0.1,0,0.2,0,0.2c-0.4,0.4-0.6,0.2-0.7-0.3
				C53.7,64.4,54,64.2,54.2,63.9z"/>
			<path fill="#44403B" d="M33.6,56.6c-0.5-0.7-0.5-0.7,0.7-1.2c0.2-0.1,0.3,0,0.3,0.2c-0.2,0.2-0.4,0.4-0.3,0.8
				C34.1,56.6,33.8,56.6,33.6,56.6z"/>
			<path fill="#514C45" d="M38.2,40.1c0.2-0.4,0.6-0.5,0.9-0.5C39,39.8,39,39.9,38.9,40c-0.1,0.3,0.5,0.6-0.2,0.8
				C38,41.1,38.5,40.3,38.2,40.1z"/>
			<path fill="#4E4943" d="M28.3,21.8c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0.1,0.4,0.4,0.3,0.7c-0.2,0.2-0.5,0.4-0.7,0.5
				c-0.2,0.2-0.4,0.1-0.6-0.1C28.2,22.4,28.2,22.1,28.3,21.8z"/>
			<path fill="#4F4A44" d="M54.2,45.6c0.1-0.2,0.1-0.3,0.2-0.5c1.1,0.3,0.4,1.2,0.7,1.9C54.2,46.8,55,45.8,54.2,45.6z"/>
			<path fill="#4D4843" d="M42.7,55.4c0.6,0,1.3,0,1.9,0l0,0C44,56.8,43.3,55.7,42.7,55.4L42.7,55.4z"/>
			<path fill="#514C46" d="M45.8,57.4c0.3,0,0.6,0.1,0.8,0.1c0.7-0.1,0.7,0.2,0.4,0.6c-0.2,0.2-0.5,0.1-0.7,0
				c-0.3-0.1-0.5-0.2-0.8-0.2C45.4,57.6,45.7,57.5,45.8,57.4z"/>
			<path fill="#3E3C37" d="M37.6,57.8c0.7-0.9,1,0.4,1.6,0.3c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.3,0.1-0.4,0.1
				C38.4,58.3,38.1,57.9,37.6,57.8L37.6,57.8z"/>
			<path fill="#433F3B" d="M61.8,34.9c-0.1-0.6-0.1-1,0.3-1.3c0.1,0,0.3,0,0.3,0.1C62.7,34.2,62.4,34.5,61.8,34.9z"/>
			<path fill="#423E3A" d="M41.2,18c0.2-0.2,0.6-0.6,0.8-0.2c0.4,0.7-0.4,0.7-0.8,0.9C40.8,18.5,40.9,18.2,41.2,18z"/>
			<path fill="#3A3733" d="M26.4,31.9c0-0.2,0-0.3,0-0.5c0.7-0.6,0.7,0.1,0.9,0.5c0.3,0.6-0.1,0.3-0.4,0.3c-0.2,0-0.3,0-0.5-0.1
				C26.3,32.1,26.3,32.1,26.4,31.9C26.3,32,26.3,31.9,26.4,31.9z"/>
			<path fill="#4B4741" d="M52.6,10.2c-0.5-0.3-0.5-0.7-0.4-1.1c0.1-0.3,0.4-0.1,0.5,0C53.2,9.5,52.7,9.8,52.6,10.2z"/>
			<path fill="#353330" d="M39.9,48.8c-1,0-1.5-0.3-1.8-1.2C38.7,48,39.2,48.3,39.9,48.8z"/>
			<path fill="#32312E" d="M31.4,16.6c0.2-0.4,0.1-0.9,0.7-0.9c0.1,0,0.4,0.2,0.3,0.2C32.3,16.5,31.8,16.5,31.4,16.6z"/>
			<path fill="#494540" d="M47,46.4c0.5,0.4,0.5,1,0.3,1.4c-0.1,0.3-0.5,0-0.7-0.2C46.7,47.1,46.5,46.6,47,46.4z"/>
			<path fill="#48443F" d="M37.1,27.2c-0.3-0.5-0.5-1-0.5-1.6C37,26.1,37.8,26.3,37.1,27.2z"/>
			<path fill="#0E0E0D" d="M44.7,61.3c0.6,0.1,1.1,0,1.2,0.6c0,0.2-0.1,0.4-0.2,0.4C45.1,62.3,45.1,61.8,44.7,61.3z"/>
			<path fill="#070707" d="M31.4,50.1c0-0.2,0-0.5,0-0.7c0.2-0.2-0.2-0.8,0.5-0.7c0,0.2,0,0.5,0.1,0.7
				C32.6,50.3,32.1,50.3,31.4,50.1z"/>
			<path fill="#312F2C" d="M26.4,35.4c0.4-0.2,0.7-0.4,1-0.1c0.2,0.1,0.1,0.4-0.1,0.5C26.9,36.1,26.7,35.7,26.4,35.4z"/>
			<path fill="#1B1B19" d="M66.4,39.2c-0.4-0.1-0.9,1.1-1.1,0c-0.3-1,0.8,0,1-0.5C66.4,38.8,66.6,38.9,66.4,39.2z"/>
			<path fill="#48443F" d="M51.1,52c0.3-0.4,0.6-0.6,1-0.3c0.1,0.1,0.1,0.3,0,0.4C51.7,52.6,51.5,52,51.1,52z"/>
			<path fill="#4C4741" d="M35.1,27.8c-0.4,0-0.7,0-1-0.2c-0.4-0.5,0-0.8,0.3-1.2c0.2,0.2,0.5,0.3,0.7,0.5
				C34.9,27.2,35.1,27.5,35.1,27.8z"/>
			<path fill="#0C0C0B" d="M34.3,26.4c-0.1,0.4-0.4,0.7-0.3,1.2c-0.4,0.1-0.7,0-1-0.2l0,0C33.4,26.9,33.7,26.5,34.3,26.4z"/>
			<path fill="#4F4A44" d="M41.2,18c-0.1,0.2-0.1,0.5,0,0.7c-0.3,0.2-0.4,0.8-0.9,0.3c-0.5-0.5,0.4-0.4,0.4-0.7
				C40.8,18.2,41.1,18.1,41.2,18z"/>
			<path fill="#201F1D" d="M33.1,27.3c0.1,0.4-0.1,0.8,0.3,1.1c-0.7,0.2-0.8-0.2-0.9-0.6C32.4,27.3,32.8,27.4,33.1,27.3
				C33.1,27.4,33.1,27.3,33.1,27.3z"/>
			<path fill="#48433E" d="M35.2,58.1c1.2,0.3,0.2,1.2,0.5,1.7c-0.1,0.1-0.1,0.1-0.2,0C35.2,59.2,35.2,58.6,35.2,58.1L35.2,58.1z"/>
			<path fill="#4C4842" d="M37.6,60c-0.1,0.3-0.2,0.4-0.5,0.5c-0.4,0.3-0.5,0-0.7-0.2c0.1-0.2,0.1-0.5,0.2-0.7
				c0.3,0.1,0.2-0.4,0.5-0.3C37.2,59.6,37.3,59.9,37.6,60L37.6,60z"/>
			<path fill="#292826" d="M52,61.4c-0.3,0-0.7,0-0.9-0.2c0.3-0.2,0.3-0.6,0.5-1C52,60.6,51.9,61,52,61.4z"/>
			<path fill="#080808" d="M35.5,39.4c-0.3,1-0.8,0.8-1.4,0.3C34.5,39.4,34.9,38.9,35.5,39.4z"/>
			<path fill="#534E47" d="M41.7,63.1c0.1-0.3,0.1-0.7,0.2-1.1c0.3,0.3,0.5,0.4,0.8,0.6c0.1,0.2,0.1,0.3,0,0.5
				C42.4,63.1,42.1,63.1,41.7,63.1C41.7,63.1,41.7,63.1,41.7,63.1z"/>
			<path fill="#2F2D2B" d="M53.7,72c0,0.4,0.4,0.7,0.1,1.1c-0.9-0.7-0.9-0.7-0.5-1C53.4,72,53.6,71.9,53.7,72z"/>
			<path fill="#4C4742" d="M50.3,45.3c-0.4,0.2-0.6,0.1-0.9-0.2c0-0.2,0-0.3,0-0.5c-0.1-0.2,0-0.3,0.2-0.4c0.2-0.1,0.4,0,0.5,0.1
				c0.1,0.1,0.2,0.1,0.3,0.2C50.5,44.8,50.6,45.1,50.3,45.3z"/>
			<path fill="#403D38" d="M57.3,62.6c0.3,0.4,0.2,0.9-0.1,1.2c-0.3,0.3-0.4-0.4-0.7-0.5l0,0C56.4,62.7,57.4,63.2,57.3,62.6
				L57.3,62.6z"/>
			<path fill="#494540" d="M45.6,36.3c-0.1-0.5,0-0.9,0.4-1.1c0.1,0,0.3,0.1,0.3,0.1C46.3,35.8,45.7,35.9,45.6,36.3z"/>
			<path fill="#020202" d="M35.6,52.5c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.1-0.7,0.2-0.7-0.4c0-0.2-0.1-0.4,0.1-0.5
				C35.4,51.8,35.5,52.2,35.6,52.5z"/>
			<path fill="#171816" d="M24.5,36.2c0.1,0.2,0.3,0.3,0.4,0.5c0,0.2,0,0.5,0,0.7c-0.1,0.2-0.3,0.2-0.4,0c-0.1-0.1-0.2-0.2-0.3-0.4
				c-0.1-0.2-0.1-0.4-0.2-0.6C24.1,36.4,24.3,36.3,24.5,36.2z"/>
			<path fill="#3E3B37" d="M52,53.8c-0.6-0.4-0.9-0.7-1.3-1C51.5,52.5,51.8,52.9,52,53.8z"/>
			<path fill="#171716" d="M68.7,47.6c0.4,0.3,1,0.3,0.9,1.1C69,48.5,68.5,48.5,68.7,47.6z"/>
			<path fill="#48443F" d="M32.7,36c1,0.3,1,0.7,0.5,1.2C32.8,37,33,36.5,32.7,36z"/>
			<path fill="#0E0E0D" d="M25.2,32.3c0.2,0.2,0.3,0.3,0.5,0.5c-0.6,0-0.7,0.6-1,1.1C24.5,33.2,24.6,32.7,25.2,32.3z"/>
			<path fill="#45413C" d="M35.5,39.4c-0.5,0.1-0.9,0.2-1.4,0.3C34.5,39.4,34.6,37.7,35.5,39.4z"/>
			<path fill="#504B45" d="M40.6,42.2c-0.4-0.1-0.8-0.2-0.8-0.6c0-0.2,0.3-0.2,0.5-0.2C40.9,41.4,40.4,42,40.6,42.2z"/>
			<path fill="#46423D" d="M44.7,42.8c0.2-0.3-0.3-0.7,0.2-1c0.1-0.1,0.4,0,0.4,0C45.4,42.3,45.2,42.6,44.7,42.8z"/>
			<path fill="#44413C" d="M62,43.3c0.4-0.1,0.4,0.1,0.4,0.3c0,0.2,0.1,0.5-0.3,0.4c-0.2-0.1-0.6,0-0.5-0.3
				C61.6,43.6,61.9,43.4,62,43.3z"/>
			<path fill="#1F1F1D" d="M48.5,66.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0-0.6,0.4-1,0.2c-0.1,0-0.1-0.3-0.1-0.3
				C47.6,66.4,48,66.1,48.5,66.4z"/>
			<path fill="#43403B" d="M56.4,59c-0.1-0.3-0.2-0.6-0.5-0.7c-0.1-0.2-0.1-0.3,0-0.5c0.7,0.2,1,0.6,0.9,1.3
				C56.7,59.2,56.5,59.2,56.4,59z"/>
			<path fill="#514C46" d="M54.2,53c0.3-0.2,0.5-0.2,0.6,0.1c0.1,0.5-0.3,0.6-0.7,0.8C53.9,53.7,54,53.3,54.2,53z"/>
			<path fill="#32302D" d="M48.3,52.6c0.3,0.2,0.8,0.1,0.8,0.6c0,0.2-0.2,0.4-0.4,0.3C48.3,53.4,48.3,53,48.3,52.6z"/>
			<path fill="#4D4843" d="M42.7,51.1c1,0.2,0.2,0.8,0.4,1.4C42.3,52,42.8,51.5,42.7,51.1L42.7,51.1z"/>
			<path fill="#353330" d="M65.7,50.2c-0.3,0-0.6,0-0.8-0.1c-0.1-0.1-0.3-0.3-0.3-0.5c0.1-0.4,0.4-0.1,0.6-0.2
				C65.8,49.4,66,49.7,65.7,50.2z"/>
			<path fill="#2F2D2A" d="M31.9,48c-0.7,0-1.2-0.1-0.7-0.9C32,46.9,32.2,47.3,31.9,48z"/>
			<path fill="#45423D" d="M44.8,23.5c-0.7-0.2-1.1-0.3-1.6-0.5C43.8,22.9,44.3,22.6,44.8,23.5z"/>
			<path fill="#504B45" d="M46.9,4.4c0.4,0.2,0.8,0.3,1.4,0.6C47.4,5.3,47.1,4.9,46.9,4.4z"/>
			<path fill="#534D47" d="M29,38.5c0-0.1,0-0.2,0.1-0.3c0.2-0.1,0.5,0,0.7-0.2c0.2,0.3,0.6,0.7,0,1c-0.2,0.2-0.4,0.2-0.7,0.1
				C28.9,38.8,28.9,38.7,29,38.5z"/>
			<path fill="#42403C" d="M38.6,9.4c0.6,0,0.9,0.4,1.1,1C39,10.3,38.7,9.9,38.6,9.4L38.6,9.4z"/>
			<path fill="#3D3B36" d="M59.6,35.9c0.3,0.7,0.3,1.1-0.2,1.5C59.2,36.8,59.2,36.4,59.6,35.9z"/>
			<path fill="#3F3C37" d="M29,34.3c-0.5,0.6-0.9,0.4-1.4-0.2C28.2,34.1,28.5,34.5,29,34.3z"/>
			<path fill="#2A2927" d="M25.5,40.1c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0.1-0.2,0c-0.2-0.2-0.6,0.4-0.8-0.1c0-0.1,0-0.4,0.1-0.4
				C24.9,39.6,25.3,39.6,25.5,40.1z"/>
			<path fill="#0D0E0D" d="M32.5,29.8c-0.4-0.1-0.8-0.1-0.8-0.6c0-0.1,0.2-0.2,0.4-0.2C32.5,29,32.3,29.5,32.5,29.8z"/>
			<path fill="#44403B" d="M41.7,48.2c0.2,0.3,0.3,0.5,0.5,0.8c0,0,0,0,0,0c-0.7,0.7-0.8,0.1-1-0.4C41.3,48.3,41.4,48.2,41.7,48.2z"
				/>
			<path fill="#45413C" d="M37.9,44.7c0.5-0.1,0.5,0.1,0.5,0.3c0,0.2,0,0.4-0.3,0.4c-0.2,0-0.5,0-0.5-0.4
				C37.7,44.9,37.9,44.8,37.9,44.7z"/>
			<path fill="#0F0F0E" d="M54.2,63.9c0,0.3,0,0.6-0.2,0.9c-0.3,0-0.7,0.1-0.7-0.3C53.3,64,53.9,64.1,54.2,63.9z"/>
			<path fill="#3D3A36" d="M56.9,44.7c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.4,0.3-0.4
				C56.7,44.3,56.8,44.5,56.9,44.7z"/>
			<path fill="#45413C" d="M51.3,67.4c0.4-0.2,0.9-0.3,1.6-0.6C52.3,67.6,51.9,67.8,51.3,67.4L51.3,67.4z"/>
			<path fill="#32312E" d="M35.8,28c-0.2,0.6-0.6,0.6-1.2,0.4C35,28,35.3,28,35.8,28z"/>
			<path fill="#111110" d="M28.2,22.6c0.2,0.1,0.4,0.1,0.5,0.2c-0.2,0.8-0.8,0.3-1.2,0.4c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3
				c0.1-0.1,0.3-0.2,0.4-0.3C28.1,22.4,28.2,22.4,28.2,22.6z"/>
			<path fill="#504B45" d="M42.5,34.2c0.1,0.7,0.2,1.1,0.3,1.6C42.2,35.4,42.1,35,42.5,34.2z"/>
			<path fill="#413E39" d="M33.8,34.6c0.2,0.5,0,0.9-0.2,1.4C33.3,35.4,33.3,35,33.8,34.6z"/>
			<path fill="#524D47" d="M31.9,48c-0.1-0.4-0.2-0.8-0.7-0.9c0.1-0.1,0.1-0.2,0.2-0.2c0.2-0.2,0.3-0.1,0.5,0l0,0
				c0.3,0.3,0.6,0.7,0.5,1.2c-0.2,0.2-0.3,0.3-0.5,0.5l0,0C31.9,48.3,31.9,48.1,31.9,48z"/>
			<path fill="#242321" d="M31.6,36c0-0.2,0-0.3,0-0.5c0.1-0.2,0-0.5,0.3-0.5c0.1,0,0.3,0.2,0.3,0.3C32.3,35.7,32,35.9,31.6,36z"/>
			<path fill="#1C1C1A" d="M49.8,56c0,0,0,0.1,0,0.1c-0.8,0.1-1.1-0.1-0.9-1C49.3,55.4,49.6,55.7,49.8,56z"/>
			<path fill="#504B45" d="M39.4,13.3c0.4-0.1,0.7-0.4,0.8,0.2c0,0.1-0.1,0.4-0.2,0.4C39.6,13.9,39.6,13.5,39.4,13.3z"/>
			<path fill="#44403B" d="M34.2,56.6c0.4,0.4,0.8,0.9,1,1.5c0,0,0,0,0,0c-0.2,0-0.5,0-0.7,0c-0.4-0.2-0.2-0.6-0.2-1
				c-0.1-0.1-0.2-0.2-0.3-0.3C34,56.7,34.1,56.6,34.2,56.6z"/>
			<path fill="#4E4944" d="M58.2,39.8c0.5-0.4,0.9-0.6,1.3-0.8C59.3,39.5,59,39.9,58.2,39.8z"/>
			<path fill="#48443F" d="M63.4,45.9c0.1-0.3,0.2-0.5,0.2-0.8c0.2-0.1,0.3,0,0.5,0c0.3,0.1,0.6,0.1,0.9,0.2c0,0.2-0.2,0.2-0.3,0.3
				c-0.2,0-0.5,0-0.7,0C63.9,45.9,63.7,46.1,63.4,45.9z"/>
			<path fill="#534E47" d="M40.5,30.7c0-0.5-0.5-0.6-0.8-0.9c1.1,0,1.1,0,1,1C40.7,30.8,40.6,30.8,40.5,30.7z"/>
			<path fill="#504B45" d="M48.5,5.8c-0.1,0.2-0.1,0.5-0.3,0.4c-0.2-0.1-0.4-0.3-0.4-0.5c0-0.3,0.3-0.2,0.5-0.3
				C48.5,5.5,48.6,5.6,48.5,5.8z"/>
			<path fill="#514C45" d="M50.9,43.4c0.2-0.3,0.5-0.6,0.7-1c0.2,0,0.3,0,0.5,0c0.1,0.1,0.1,0.3,0.1,0.4c-0.1,0.2-0.2,0.3-0.3,0.5
				c0,0.1,0,0.2,0,0.3c-0.3,0.1-0.6,0.1-0.9-0.1C50.9,43.5,50.9,43.5,50.9,43.4z"/>
			<path fill="#403D39" d="M64.3,27c-0.3,0-0.4-0.2-0.4-0.4c0-0.2-0.1-0.5,0.2-0.5c0.2,0,0.4,0.1,0.4,0.3
				C64.5,26.6,64.4,26.8,64.3,27z"/>
			<path fill="#3C3A36" d="M31.9,42.2c0.5-0.2,0.9,0.2,1.2,0.5c-0.4,0.3-0.7-0.1-1,0C31.7,42.7,31.8,42.5,31.9,42.2z"/>
			<path fill="#4F4A44" d="M43.9,8.2c0.2,0.5,0.3,1,0.3,1.8C43.8,9.3,43.5,8.8,43.9,8.2L43.9,8.2z"/>
			<path fill="#2E2C2A" d="M61.4,46.5c-0.4-0.1-0.6-0.4-0.4-0.7c0.3-0.4,0.6,0,0.8,0.2C61.8,46.3,61.6,46.4,61.4,46.5z"/>
			<path fill="#242322" d="M37.6,57.8c0,0.3,0,0.6-0.2,0.8c-0.2,0-0.4-0.2-0.5-0.3c-0.3-0.3-0.2-0.6,0.1-0.8
				C37.3,57.3,37.3,57.9,37.6,57.8C37.6,57.8,37.6,57.8,37.6,57.8z"/>
			<path fill="#46423E" d="M59,57.4c0.5,0.3,0.1,0.8,0.2,1.2C58.7,58.3,59,57.8,59,57.4z"/>
			<path fill="#272725" d="M33.1,15.1c0-0.5-0.2-0.8,0.1-1.1c0.1,0,0.3,0,0.3,0.1C33.8,14.6,33.4,14.8,33.1,15.1z"/>
			<path fill="#2E2D2A" d="M35.5,59.8c0.1,0,0.1,0,0.2,0c0,0.4,0.3,0.7,0.1,1.2C35.2,60.6,35,60.3,35.5,59.8z"/>
			<path fill="#373532" d="M31.9,46.8c-0.2,0-0.3,0-0.5,0c0.2-0.5,0.9-0.5,1-1.2C32.8,46.4,32.2,46.5,31.9,46.8z"/>
			<path fill="#302F2C" d="M43.7,59.7c0,0.2,0,0.3,0,0.5c-0.1,0.2-0.2,0.5-0.3,0.8c-0.1-0.3-0.2-0.6-0.3-0.8
				C43,59.9,43.3,59.8,43.7,59.7z"/>
			<path fill="#504B45" d="M46.3,24.7c0.2,0,0.3,0.2,0.3,0.4c0,0.2,0,0.3-0.2,0.3c-0.3,0-0.3-0.2-0.3-0.4
				C46.1,24.9,46.1,24.8,46.3,24.7z"/>
			<path fill="#1C1C1A" d="M29.5,22.3c-0.1-0.2-0.2-0.5-0.3-0.7c0.2-0.2,0.6-0.3,0.5-0.7c0.1,0.1,0.2,0.3,0.2,0.4
				C29.9,21.7,29.9,22.1,29.5,22.3z"/>
			<path fill="#48443F" d="M44.4,30.8c0,0.2-0.1,0.3-0.3,0.2c-0.2-0.1-0.5-0.2-0.3-0.5c0-0.1,0.2-0.3,0.3-0.3
				C44.4,30.2,44.3,30.6,44.4,30.8z"/>
			<path fill="#413E39" d="M41,60.7c0,0.1,0,0.2,0,0.3c0,0.3,0.2,0.6-0.3,0.7c-0.1,0-0.3,0-0.3-0.1c-0.2-0.4,0.2-0.6,0.4-0.9
				C40.8,60.6,40.9,60.6,41,60.7L41,60.7z"/>
			<path fill="#040404" d="M66.7,51.6c-0.2-0.2-0.8-0.3-0.5-0.7c0.3-0.4,0.7,0,1,0.2C67.2,51.5,67.1,51.7,66.7,51.6z"/>
			<path fill="#45413C" d="M30.2,30.2c0.6,0.2,0.9,0.3,1,0.8C30.8,30.9,30.5,30.8,30.2,30.2z"/>
			<path fill="#4C4842" d="M65.7,50.2c0-0.3-0.2-0.6-0.5-0.7c0.3-0.1,0.7-1,1,0l0,0C66.1,49.7,65.9,49.9,65.7,50.2z"/>
			<path fill="#494540" d="M50.6,53.7c-0.3,0.3-0.7,0.4-1.2,0.4C49.8,53.7,50.2,53.6,50.6,53.7z"/>
			<path fill="#282725" d="M33.4,33.4c0.3,0,0.4,0.1,0.4,0.2c-0.1,0.2-0.2,0.5-0.6,0.5c-0.1,0-0.2-0.2-0.3-0.3
				C33.1,33.8,33.3,33.6,33.4,33.4z"/>
			<path fill="#34322F" d="M63.9,33.8c-0.3-0.2-0.4-0.5-0.3-0.8c0.1-0.2,0.3-0.1,0.4,0C64.2,33.3,64.1,33.6,63.9,33.8z"/>
			<path fill="#4E4943" d="M50.1,50.4c-0.1-0.2-0.2-0.3-0.2-0.5c0.3-0.2,0.2-0.7,0.6-0.8c0.3,0,0.2,0.4,0.3,0.6l0,0
				C50.5,49.8,50.3,50.1,50.1,50.4z"/>
			<path fill="#59534C" d="M41.4,56.6c0.4-0.2,0.6-0.3,0.9,0c0,0-0.1,0.2-0.1,0.3C41.9,57.1,41.6,57,41.4,56.6z"/>
			<path fill="#191918" d="M31.6,43.2c-0.7-0.1-0.7-0.5-0.4-0.9c0.2-0.1,0.3-0.2,0.5-0.2c0,0,0.1,0.1,0.1,0.1c0,0.3,0.1,0.6-0.1,0.8
				C31.7,43,31.6,43.1,31.6,43.2z"/>
			<path fill="#0D0E0D" d="M67.2,46.3c-0.4,0.2-0.8,0.2-1.1,0c-0.2-0.1,0.1-0.3,0.1-0.5C66.6,45.9,67.1,45.6,67.2,46.3z"/>
			<path fill="#504C45" d="M41.5,13.2c0.3-0.1,0.1-0.7,0.5-0.6c0.2,0,0.4,0.3,0.3,0.6C42.1,13.6,41.8,13.3,41.5,13.2L41.5,13.2z"/>
			<path fill="#383632" d="M50.6,57.1c0.3-0.3,0.6-0.3,1,0C51.3,58.4,50.9,57,50.6,57.1z"/>
			<path fill="#373431" d="M27.5,29.2c-0.2,0-0.4,0-0.4-0.2c0-0.3,0.3-0.3,0.5-0.4c0.2-0.1,0.4,0,0.4,0.3C28,29.1,27.7,29,27.5,29.2
				z"/>
			<path fill="#4F4A44" d="M50.1,37.4c0.4,0.1,1.3-1,1,0.5l0,0C50.7,37.9,50.4,37.7,50.1,37.4L50.1,37.4z"/>
			<path fill="#2E2C2A" d="M47.7,71.5c0.4-0.4,0.7-0.5,1.2-0.2C48.4,71.6,48.1,71.7,47.7,71.5z"/>
			<path fill="#0D0D0D" d="M34,44c-0.2,0-0.3-0.1-0.5,0c-0.3,0.1-0.6,0.3-0.7-0.1c-0.1-0.4,0.3-0.2,0.5-0.3c0.2-0.2,0.3-0.4,0.4-0.5
				C33.9,43.3,34.2,43.6,34,44z"/>
			<path fill="#534D47" d="M53.4,12c-0.3-0.4-0.5-0.6-0.4-0.9c0-0.1,0.2-0.1,0.3,0C53.6,11.2,53.6,11.5,53.4,12z"/>
			<path fill="#141412" d="M66.9,43.3c0.2,0,0.3,0.1,0.4,0.2c0,0.3-0.1,0.5-0.4,0.5c-0.2,0-0.3-0.1-0.4-0.2
				C66.5,43.5,66.6,43.3,66.9,43.3z"/>
			<path fill="#413E39" d="M47,49.7c0.4,0.3,0.6,0.7,0.7,1.2C46.9,50.8,47,50.2,47,49.7z"/>
			<path fill="#534D47" d="M57.3,62.6c-0.7,0.1-1-0.4-1.2-1l0,0C56.7,61.8,57,62.2,57.3,62.6C57.3,62.6,57.3,62.6,57.3,62.6z"/>
			<path fill="#524D47" d="M31.9,48.8c-0.2,0.2-0.3,0.5-0.5,0.7c-0.1-0.2-0.2-0.4-0.3-0.5c-0.3-1,0.4-0.4,0.7-0.4c0,0,0,0,0,0
				C31.9,48.6,31.9,48.7,31.9,48.8z"/>
			<path fill="#292826" d="M26,37.8c0-0.9,0.3-1.1,1-0.7C26.7,37.3,26.4,37.5,26,37.8z"/>
			<path fill="#171716" d="M51.1,62.4c0.3,0.3,0.3,0.6,0,0.9c-0.2,0.2-0.3-0.1-0.5-0.2C50.5,62.7,50.3,62.2,51.1,62.4z"/>
			<path fill="#373532" d="M33.3,29.8c0.2-0.3,0.5-0.5,0.8-0.3c0.1,0,0.1,0.2,0.1,0.3C33.9,30.1,33.6,29.8,33.3,29.8z"/>
			<path fill="#1F1E1D" d="M64.1,45.1c-0.2,0.2-0.3,0.1-0.5,0c-0.2-0.2-0.8-0.4-0.4-0.7C63.6,44.2,64,44.7,64.1,45.1z"/>
			<path fill="#1D1D1B" d="M58.5,42.7c0.3-0.3,0.7-0.4,1-0.1c0.1,0.1,0.1,0.3,0,0.3C59.2,43.1,58.9,42.7,58.5,42.7z"/>
			<path fill="#48443F" d="M44.6,63.9c0.2-0.2,0.3-0.7,0.7-0.6c0.1,0,0.1,0.2,0.1,0.3C45.3,64.1,44.9,63.9,44.6,63.9z"/>
			<path fill="#46423D" d="M54.9,50.5c0.4,0.2,0.6,0.4,0.4,0.8c0,0.1-0.2,0.1-0.3,0.1C54.8,51.2,54.8,50.9,54.9,50.5z"/>
			<path fill="#10100F" d="M37.7,16c0.6,0.3,0.6,0.3,0.1,0.9c-0.1-0.3-0.2-0.6-0.3-0.9C37.5,15.9,37.6,15.9,37.7,16z"/>
			<path fill="#292826" d="M46,50.2c0.4-0.1,0.5,0.1,0.4,0.4c0,0.2-0.2,0.4-0.4,0.2C45.7,50.6,46,50.4,46,50.2L46,50.2z"/>
			<path fill="#1C1C1A" d="M55.2,62.7c-0.1,0.3-0.3,0.8-0.6,0.5c-0.3-0.4,0.1-0.8,0.5-0.9C55.3,62.3,55.3,62.5,55.2,62.7z"/>
			<path fill="#2F2D2B" d="M62.3,29.8c0-0.4,0.1-0.6,0.4-0.7c0.1,0,0.2,0.1,0.2,0.2C62.9,29.7,62.7,29.7,62.3,29.8z"/>
			<path fill="#403D38" d="M57.8,64c0.1-0.6,0.2-0.8,0.6-0.9c0,0,0.1,0.1,0.1,0.2C58.6,63.8,58.2,63.7,57.8,64z"/>
			<path fill="#56504A" d="M39.4,28.6c-0.1,0.2,0,0.4-0.3,0.4c-0.1,0-0.2-0.2-0.2-0.4c0-0.2,0.1-0.3,0.2-0.4
				C39.4,28.2,39.4,28.4,39.4,28.6z"/>
			<path fill="#040404" d="M34,23.5c0.3,0.1,0.6,0.1,0.2,0.5c-0.3,0-0.5,0-0.8,0C33.5,23.7,33.8,23.6,34,23.5z"/>
			<path fill="#48443F" d="M56.6,52.6c0.2,0.3,0.3,0.6-0.1,0.9c-0.1,0-0.3,0-0.3,0c-0.2-0.2,0.1-0.4,0.1-0.6
				C56.4,52.7,56.5,52.6,56.6,52.6z"/>
			<path fill="#383632" d="M35.5,54c0.1,0.1,0.3,0.1,0.4,0.2c-0.3,0.2,0.1,0.7-0.4,0.8C35.1,54.7,35.5,54.4,35.5,54z"/>
			<path fill="#2C2B28" d="M51.6,56.2c-0.2-0.1-0.3-0.2-0.5-0.3c0.6,0.2,0-0.9,0.6-0.6C52.2,55.6,51.9,55.9,51.6,56.2z"/>
			<path fill="#0C0D0C" d="M34.3,57.1c0.2,0.3,0.2,0.6,0.2,1c-0.1,0.1-0.3,0.3-0.4,0.1C33.9,57.9,34,57.5,34.3,57.1z"/>
			<path fill="#201F1E" d="M56.4,59c0.1,0,0.3,0,0.4,0c0.1,0.4,0.8,0.9-0.2,1.2l0,0C56.7,59.8,56.5,59.4,56.4,59z"/>
			<path fill="#534D47" d="M56.6,61.2c-0.2,0.2-0.3,0.3-0.5,0.5c0,0,0,0,0,0c-0.6-0.2-0.4-0.6-0.2-1C56.3,60.6,56.5,60.9,56.6,61.2z
				"/>
			<path fill="#504B45" d="M57.7,62.2c-0.2-0.5-0.5-0.7-0.4-1c0-0.1,0.2-0.1,0.3-0.1C58,61.4,57.8,61.7,57.7,62.2z"/>
			<path fill="#0F0F0F" d="M48.2,62.8c0-0.1,0-0.1,0-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.3,0.5-0.5,0.9-0.2c0,0.1,0,0.1,0,0.2
				c-0.1,0.4-0.5,0.3-0.7,0.5C48.5,62.9,48.4,62.9,48.2,62.8z"/>
			<path fill="#33312E" d="M55.2,62.7c0-0.2,0-0.3-0.1-0.5C55.6,62,56,62,56,62.5C56,63.2,55.4,62.2,55.2,62.7z"/>
			<path fill="#4B4742" d="M47,8.6c0.2,0.1,0.6-0.1,0.7,0.2c0,0,0,0.2-0.1,0.2c-0.4,0.1-0.7,0-0.8-0.4C46.8,8.5,46.9,8.5,47,8.6z"/>
			<path fill="#393733" d="M65.9,9.9c-0.2,0-0.3,0-0.3-0.2c0-0.2,0.1-0.4,0.2-0.5c0.2-0.1,0.3,0.1,0.3,0.3
				C66.2,9.7,66.2,9.9,65.9,9.9z"/>
			<path fill="#060606" d="M66.6,44.5c0.3,0,0.4,0.2,0.5,0.4c0.1,0.2-0.2,0.2-0.3,0.2c-0.2,0-0.4-0.1-0.4-0.3
				C66.3,44.7,66.4,44.6,66.6,44.5z"/>
			<path fill="#1D1C1B" d="M34.3,44.8c0.1,0.1,0.1,0.2,0,0.3c-0.3-0.2-0.9,0.2-1.1-0.5C33.6,44.5,33.9,44.8,34.3,44.8z"/>
			<path fill="#242321" d="M52.1,42.9c0-0.1,0-0.3-0.1-0.4c0.2-0.1,0.5-0.2,0.6-0.1c0.2,0.1,0.1,0.4,0,0.6
				C52.5,43.3,52.3,43,52.1,42.9z"/>
			<path fill="#47433E" d="M52.8,48c0-0.1,0-0.2,0-0.3c0.4,0.1,0.9-0.1,1.1,0.4C53.4,48.4,53.1,48.1,52.8,48z"/>
			<path fill="#544E48" d="M42.2,40.7c-0.2,0-0.3-0.1-0.2-0.2c0.1-0.2,0.2-0.4,0.3-0.5c0.2-0.1,0.3,0.1,0.3,0.3
				C42.5,40.5,42.3,40.6,42.2,40.7z"/>
			<path fill="#514C46" d="M52.3,12.2c0.1,0.2,0.5,0.4,0.2,0.6c-0.3,0.2-0.7-0.1-0.9-0.4C51.8,12.3,51.9,12.1,52.3,12.2z"/>
			<path fill="#302E2C" d="M40.1,46.8c-0.3,0-0.3-0.2-0.3-0.4c0-0.2,0-0.3,0.2-0.4c0.2-0.1,0.4,0.1,0.4,0.3
				C40.4,46.5,40.3,46.7,40.1,46.8z"/>
			<path fill="#4C4742" d="M61.4,46.5c0.2-0.2,0.3-0.3,0.5-0.5c0.2,0,0.4-0.2,0.5,0c0.1,0.2,0,0.3-0.2,0.4
				C61.9,46.5,61.7,46.5,61.4,46.5z"/>
			<path fill="#35332F" d="M37.3,11c-0.1,0.5-0.2,0.8-0.7,0.8C36.6,11.4,36.8,11.2,37.3,11z"/>
			<path fill="#46433E" d="M49.7,44.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3-0.1-0.6,0.1-0.8-0.2c0-0.3,0.5-0.2,0.5-0.5
				c0.1-0.1,0.2-0.1,0.3-0.1C49.8,43.9,49.9,44.1,49.7,44.4z"/>
			<path fill="#44403B" d="M41,48c-0.1-0.8,0.6-0.6,0.9-0.5c0.3,0.1-0.2,0.3-0.2,0.5C41.5,48.2,41.2,48.2,41,48z"/>
			<path fill="#1C1B1A" d="M47,46.4c0,0.4-0.1,0.8-0.4,1.1C46.5,47,45.9,46.4,47,46.4z"/>
			<path fill="#2B2A28" d="M32.4,48c-0.2-0.4-0.3-0.8-0.5-1.2C32.6,47,32.9,47.3,32.4,48z"/>
			<path fill="#090908" d="M27.6,45.5c-0.2-0.5,0.2-0.4,0.5-0.4c0.1,0,0.1,0.1,0.2,0.1c0.1,0.2,0.4,0.4,0.1,0.6
				C28.1,45.9,27.9,45.5,27.6,45.5z"/>
			<path fill="#43403B" d="M43.7,41.5c0.3,0.4,0.2,0.6-0.1,0.9C43.3,42,43.3,41.8,43.7,41.5z"/>
			<path fill="#413D39" d="M37.5,36.9c0.3,0.1,0.5,0.3,0.8,0.4c-0.1,0.1-0.1,0.2-0.2,0.2C37.6,37.6,37.5,37.4,37.5,36.9z"/>
			<path fill="#090909" d="M34.6,16.1c0.2,0.1,0.5,0.2,0.5,0.5c0,0.3-0.2,0.3-0.4,0.3c-0.4,0-0.3-0.3-0.3-0.5
				C34.3,16.1,34.5,16,34.6,16.1z"/>
			<path fill="#363430" d="M47.5,45.4c0.5-0.1,0.9-0.2,1.2,0.3C48.3,45.5,47.8,45.9,47.5,45.4L47.5,45.4z"/>
			<path fill="#100F0F" d="M53.1,49.3c0.2-0.4,0.5-0.6,0.8-0.4c0.1,0,0.1,0.3,0,0.3C53.7,49.5,53.4,49.4,53.1,49.3z"/>
			<path fill="#514C45" d="M39.2,45c0.2,0,0.4,0,0.4,0.2c0,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.3-0.1-0.3-0.3C39,45.1,39.2,45,39.2,45z"/>
			<path fill="#111111" d="M29.2,45.2c0.1,0.2,0.2,0.4,0.5,0.5c0.6,0.1,0,0.3,0,0.4c-0.6,0-0.9-0.3-0.7-0.9
				C29.1,45.1,29.2,45.1,29.2,45.2z"/>
			<path fill="#1E1E1C" d="M29.7,32.1c-0.3,0.5-0.7,0.5-1,0.5C28.9,32.3,29.2,32.1,29.7,32.1z"/>
			<path fill="#3B3935" d="M52.3,56.6c0-0.4,0-1.1,0.7-0.4c0,0,0,0,0,0c-0.1,0.2-0.1,0.3-0.2,0.5C52.6,56.8,52.5,56.8,52.3,56.6z"/>
			<path fill="#44403C" d="M60.9,32.3c0.1-0.4,0.3-0.5,0.6-0.5c0,0,0.1,0.2,0.1,0.2C61.5,32.2,61.2,32.2,60.9,32.3z"/>
			<path fill="#45413D" d="M46.3,60.9c0.1,0,0.1,0,0.2,0c0.3,0.1,0.6,0.1,0.8,0.4C46.7,61.8,46.5,61.4,46.3,60.9z"/>
			<path fill="#4A4640" d="M54.1,68.7c0.3,0,0.7-0.1,0.9,0.3c-0.3,0.4-0.7,0.1-1.1,0.1C53.8,68.9,54,68.8,54.1,68.7z"/>
			<path fill="#403D39" d="M45.5,12.9c0.1,0.3,0.3,0.5,0,0.8c0,0-0.2,0-0.3-0.1C45.1,13.3,45.2,13.1,45.5,12.9z"/>
			<path fill="#43403B" d="M40.7,55.9c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0.1-0.2,0.2-0.2
				C40.6,55.6,40.7,55.7,40.7,55.9z"/>
			<path fill="#0D0D0C" d="M59,61.4c0.4-0.4,0.8-0.4,1,0.1c0.2,0.4-0.2,0.4-0.5,0.4C59.4,61.7,59.1,61.7,59,61.4z"/>
			<path fill="#423E3A" d="M40.8,32.8c0,0.1-0.1,0.3-0.2,0.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.2,0-0.3,0.2-0.3
				C40.6,32.4,40.7,32.6,40.8,32.8z"/>
			<path fill="#45413D" d="M47,54.8c0.2,0.1,0.3,0.2,0.3,0.3c0,0.2-0.2,0.2-0.3,0.2c-0.1-0.1-0.3-0.1-0.2-0.3
				C46.8,54.8,46.9,54.8,47,54.8z"/>
			<path fill="#2A2927" d="M66.3,30.7c-0.5-0.1-0.3-0.6-0.4-0.9c0.1,0,0.2-0.1,0.2,0C66.2,30.1,66.6,30.3,66.3,30.7z"/>
			<path fill="#4D4843" d="M42.7,55.4c-0.2-0.2-0.6-0.2-0.4-0.6c0-0.1,0.3-0.1,0.4-0.1C43.2,55,42.7,55.2,42.7,55.4
				C42.7,55.4,42.7,55.4,42.7,55.4z"/>
			<path fill="#2A2927" d="M37.1,19.5c0,0.2,0,0.4-0.2,0.4c-0.2,0-0.2-0.1-0.2-0.3c0-0.2-0.1-0.4,0.2-0.4
				C37,19.3,37.1,19.5,37.1,19.5z"/>
			<path fill="#34322E" d="M39.8,22.8c0,0-0.1,0-0.1,0c-0.3,0.1-0.7,0.5-0.8-0.1c-0.1-0.4,0.5-0.2,0.8-0.2
				C39.8,22.5,39.9,22.6,39.8,22.8z"/>
			<path fill="#232220" d="M53.8,39.2c0.1-0.4,0.2-0.7,0.6-0.7c0,0,0.1,0.1,0.1,0.2C54.5,39.1,54.2,39.1,53.8,39.2z"/>
			<path fill="#393733" d="M37,30.5c0,0.2-0.2,0.2-0.3,0.2c-0.1,0-0.4-0.1-0.4-0.2c0-0.2,0.2-0.2,0.3-0.3
				C36.8,30.3,36.9,30.3,37,30.5z"/>
			<path fill="#0C0C0C" d="M36.7,21.8c0.1-0.3-0.1-0.6,0.3-0.8c0.1,0,0.2,0,0.2,0.1c0.1,0.3,0.2,0.6-0.2,0.7
				C36.9,21.9,36.8,21.9,36.7,21.8z"/>
			<path fill="#0E0E0D" d="M53.3,39.8c-0.2,0.1-0.2,0.6-0.5,0.4c-0.1,0-0.2-0.2-0.1-0.3c0.1-0.4,0.3-0.6,0.7-0.4
				C53.4,39.6,53.4,39.7,53.3,39.8C53.3,39.8,53.3,39.8,53.3,39.8z"/>
			<path fill="#0A0A0A" d="M52.3,63.9C52,63.8,52,63.6,52,63.3c0.3-0.2,0.5-0.5,0.7-0.7l0,0c0.1-0.1,0.2-0.1,0.2,0
				c0,0.4,0.2,0.9-0.2,1.3C52.6,64,52.4,64,52.3,63.9z"/>
			<path fill="#4C4842" d="M37.7,60c0.1-0.2-0.1-0.6,0.2-0.7c0.1,0,0.3,0.2,0.3,0.3C38.3,60,37.9,59.9,37.7,60
				C37.6,60,37.7,60,37.7,60z"/>
			<path fill="#494540" d="M41,60.7c-0.1,0-0.2,0-0.3,0c-0.1-0.6,0.2-0.7,0.7-0.8C41.6,60.4,41.1,60.4,41,60.7z"/>
			<path fill="#383532" d="M56.6,63.4c-0.1,0.3,0.2,0.7-0.3,0.7c0,0-0.2-0.2-0.2-0.3C55.9,63.3,56.3,63.4,56.6,63.4
				C56.6,63.4,56.6,63.4,56.6,63.4z"/>
			<path fill="#32302D" d="M28.2,22.6c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.4-0.1-0.8-0.2-1.1c0.2-0.1,0.4,0,0.5,0.2c0,0.1,0,0.2,0.1,0.3
				C28.6,22.1,28.5,22.4,28.2,22.6z"/>
			<path fill="#3A3834" d="M43.1,46c0-0.5-0.2-0.8,0.1-1.2C43.5,45.2,43.5,45.5,43.1,46z"/>
			<path fill="#4A4640" d="M45.4,21.6c0.6,0.1,0.7,0.3,0.8,0.7C45.8,22.3,45.7,21.9,45.4,21.6z"/>
			<path fill="#373532" d="M47.5,45.3c-0.2,0.1-0.4,0.3-0.7,0.5c0.1-0.6,0.2-0.9,0.8-1C47.5,45.1,47.5,45.2,47.5,45.3
				C47.5,45.4,47.5,45.3,47.5,45.3z"/>
			<path fill="#141413" d="M49,65.8c0.3-0.4,0.6-0.6,1-0.4C49.8,65.9,49.4,65.8,49,65.8z"/>
			<path fill="#232220" d="M46.3,58.1c0.2-0.1,0.5-0.1,0.7,0c0,0.1,0,0.2,0,0.2c0,0.2,0,0.3,0,0.5C46.4,58.9,46.3,58.6,46.3,58.1z"
				/>
			<path fill="#121211" d="M40,57.9c0.4,0,0.4,0.2,0.4,0.4c0.1,0.2,0,0.3-0.2,0.3c-0.2,0-0.4-0.1-0.4-0.3C39.9,58.1,40,58,40,57.9z"
				/>
			<path fill="#2A2927" d="M56.3,66.4c0,0.5-0.1,0.8-0.4,1C55.6,66.9,55.9,66.7,56.3,66.4z"/>
			<path fill="#20201E" d="M56.6,61.2c-0.2-0.2-0.5-0.3-0.7-0.5c0.1-0.3,0.4-0.4,0.7-0.4c0,0,0,0,0,0C56.5,60.5,56.9,60.9,56.6,61.2
				z"/>
			<path fill="#312F2C" d="M53.5,68.9c0-0.3-0.6-0.7-0.1-0.9c0.5-0.3,0.4,0.4,0.6,0.6C53.9,68.8,53.8,69,53.5,68.9z"/>
			<path fill="#292825" d="M51.6,39.8c-0.4-0.3-0.7-0.5-0.6-1C51.5,39,51.6,39.3,51.6,39.8z"/>
			<path fill="#0B0B0B" d="M50.4,68.5c0.2-0.2,0.4-0.4,0.7-0.4c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.2,0.3,0.1,0.5
				C51.1,68.5,50.6,69.2,50.4,68.5z"/>
			<path fill="#44403B" d="M49.4,57c0.1,0.4,0,0.8-0.2,0.8c-0.4,0-0.2-0.3-0.1-0.5C49.1,57.2,49.3,57.1,49.4,57z"/>
			<path fill="#3D3A36" d="M53.2,36.2c-0.2-0.4-0.1-0.7,0.3-0.8C53.8,35.8,53.2,35.9,53.2,36.2z"/>
			<path fill="#1B1A19" d="M35.1,27.8c-0.2-0.3-0.9-0.6,0-0.9C35.2,27.2,35.4,27.5,35.1,27.8z"/>
			<path fill="#0E0E0D" d="M50.4,51.6c-0.2,0.1-0.5,0.2-0.7,0.2c-0.1,0-0.1-0.1-0.2-0.1c0-0.4,0.4-0.5,0.7-0.7
				C50.2,51.3,50.3,51.5,50.4,51.6z"/>
			<path fill="#3E3B37" d="M65.5,37.6c-0.1,0.2-0.1,0.4-0.3,0.4c-0.2,0-0.2-0.2-0.1-0.4c0-0.2,0.1-0.3,0.3-0.2
				C65.5,37.4,65.5,37.5,65.5,37.6z"/>
			<path fill="#423F3A" d="M36.3,10.3c0.1,0,0.3,0.1,0.3,0.2c0.1,0.2-0.1,0.3-0.3,0.2c-0.1,0-0.3-0.1-0.4-0.2
				C35.9,10.4,36.1,10.4,36.3,10.3z"/>
			<path fill="#151514" d="M35,37.4c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.2,0.4-0.5,0.3c-0.6-0.3-0.4-0.5,0-0.8
				C34.8,37.1,34.9,37.2,35,37.4z"/>
			<path fill="#3A3733" d="M64.7,16.6c-0.1,0.1-0.3,0.2-0.4,0.1c-0.2-0.1-0.2-0.3-0.1-0.4c0-0.1,0.2-0.1,0.3-0.1
				C64.7,16.3,64.8,16.4,64.7,16.6z"/>
			<path fill="#43403B" d="M47,42.7c-0.1-0.3-0.1-0.5,0.2-0.7c0.2,0.2,0.6,0,0.5,0.3C47.7,42.6,47.3,42.6,47,42.7z"/>
			<path fill="#3C3935" d="M56.4,50.2c0,0.3,0.3,0.6,0,0.7c-0.2,0.1-0.4-0.1-0.4-0.3C55.8,50.2,56.1,50.2,56.4,50.2L56.4,50.2z"/>
			<path fill="#282724" d="M33.1,25.2c0.1-0.3,0.2-0.4,0.5-0.2c0,0.3,0.2,0.6,0,0.8C33.2,25.9,33.1,25.5,33.1,25.2z"/>
			<path fill="#393734" d="M29.7,36.5c0,0.2,0,0.3,0,0.5c-0.3,0.1-0.4,0.6-0.8,0.3C29,36.8,29.4,36.7,29.7,36.5z"/>
			<path fill="#413E39" d="M35,37.4c-0.2-0.1-0.3-0.2-0.5-0.3c0.2-0.4,0.4-0.7,0.7-0.2c0.1,0.2,0.2,0.3,0,0.5
				C35.2,37.4,35.1,37.4,35,37.4z"/>
			<path fill="#423F3A" d="M36.7,15.9c0.3,0.3,0.6,0.6,0.3,0.8c-0.3,0.2-0.3-0.2-0.4-0.4C36.6,16.2,36.6,16.1,36.7,15.9z"/>
			<path fill="#0B0B0B" d="M40.2,49.3c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.1-0.3,0-0.3-0.2
				C39.8,49.5,40,49.4,40.2,49.3z"/>
			<path fill="#3E3B37" d="M35.6,48.9c0.2,0.1,0.4,0.3,0.8,0.5c-0.7,0.1-1,0-1.2-0.5C35.4,48.8,35.5,48.8,35.6,48.9z"/>
			<path fill="#0B0B0B" d="M25.4,38.6c0.2-0.3,0.6-0.5,0.7-0.2c0.2,0.3-0.1,0.6-0.5,0.7C25.5,39,25.4,38.9,25.4,38.6z"/>
			<path fill="#1E1D1B" d="M48.9,73.5c-0.1-0.3-0.3-0.7,0.2-0.9c0.1,0,0.2,0,0.2,0C49.5,73.1,49.2,73.2,48.9,73.5L48.9,73.5z"/>
			<path fill="#3C3A36" d="M34.1,47c0,0.3,0,0.4-0.2,0.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0-0.3,0.2-0.3C34,46.8,34.1,47,34.1,47z"/>
			<path fill="#554F49" d="M44.9,27.9c-0.4-0.1-0.5-0.4-0.4-0.6c0.3-0.3,0.3,0.1,0.4,0.3C45,27.6,44.9,27.8,44.9,27.9z"/>
			<path fill="#272624" d="M49.4,43.9c-0.1,0-0.2,0-0.2,0c0.1-0.9,0.8-0.4,1.2-0.5c0.2,0,0.3,0,0.5,0c0,0,0.1,0,0.1,0
				c0,0.1,0,0.2-0.1,0.4C50.7,44,50.4,44,50.1,44C49.9,44,49.7,43.8,49.4,43.9z"/>
			<path fill="#0A0A09" d="M27.2,38c0.2,0.4,0.4,0.6,0.1,0.8c-0.1,0-0.3,0-0.3,0C26.8,38.5,27.1,38.3,27.2,38z"/>
			<path fill="#44403C" d="M45.6,53.1c0,0.3-0.2,0.2-0.4,0.2c-0.2,0-0.3,0-0.3-0.2c0-0.2,0.1-0.3,0.3-0.3
				C45.3,52.8,45.5,53,45.6,53.1z"/>
			<path fill="#4F4A44" d="M47.1,11.7c-0.1,0.1-0.2,0.2-0.2,0.2c-0.2,0-0.3-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2
				C47,11.3,47.1,11.4,47.1,11.7z"/>
			<path fill="#4A4540" d="M64.5,43.4c-0.1,0.1-0.2,0.3-0.2,0.2c-0.3-0.1-0.3-0.3-0.3-0.5c0-0.1,0.1-0.3,0.2-0.2
				C64.4,43,64.4,43.2,64.5,43.4z"/>
			<path fill="#44403B" d="M59.6,11.8c0,0.2,0,0.4-0.2,0.4c-0.1,0-0.2-0.2-0.2-0.2c0-0.1,0.1-0.4,0.2-0.4
				C59.6,11.5,59.6,11.7,59.6,11.8z"/>
			<path fill="#151514" d="M32.1,25.4c0.1-0.1,0.2-0.4,0.2-0.3c0.3,0.1,0.2,0.4,0.3,0.6C32.3,26.1,32.2,25.7,32.1,25.4z"/>
			<path fill="#353330" d="M59,61.4c0.3,0.1,0.4,0.2,0.5,0.5c-0.3,0-0.6,0.1-0.7-0.2C58.7,61.6,58.9,61.5,59,61.4z"/>
			<path fill="#555049" d="M51.4,68.7c0-0.2-0.1-0.3-0.1-0.5c0.2,0,0.6-0.1,0.6,0.3C51.8,68.8,51.6,68.8,51.4,68.7z"/>
			<path fill="#34322E" d="M51.2,11.4c-0.3-0.1-0.5-0.3-0.4-0.7C51.2,10.8,51.1,11.2,51.2,11.4z"/>
			<path fill="#252523" d="M46.3,66.3c0.2,0,0.3,0.1,0.3,0.3c0,0.2,0,0.3-0.2,0.3c-0.1,0-0.3-0.2-0.3-0.3
				C46,66.4,46.1,66.3,46.3,66.3z"/>
			<path fill="#191918" d="M48.7,43.2c-0.1,0.4-0.5,0.4-0.7,0.3c-0.3-0.2,0.1-0.4,0.2-0.6C48.6,42.8,48.7,42.8,48.7,43.2z"/>
			<path fill="#46433E" d="M41.8,9.8c0,0.3,0.1,0.5,0.2,0.9C41.5,10.5,41.5,10.2,41.8,9.8z"/>
			<path fill="#242321" d="M32.2,26.4c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.2,0.3-0.4,0.2c-0.1,0-0.3-0.1-0.2-0.2
				C31.9,26.5,32,26.5,32.2,26.4z"/>
			<path fill="#1E1D1C" d="M50.9,67.7c-0.4-0.1-1,0.1-0.7-0.7c0.2,0.1,0.5,0.2,0.7,0.3C51,67.4,51,67.5,50.9,67.7L50.9,67.7z"/>
			<path fill="#2D2B29" d="M48.7,43.2c-0.2-0.1-0.3-0.2-0.5-0.3c0.2-0.3,0.4-0.5,0.7-0.8C49.1,42.6,48.9,42.9,48.7,43.2z"/>
			<path fill="#494540" d="M33.1,41c0.1-0.2,0.3-0.4,0.6-0.2c0.1,0.1,0.1,0.3,0.1,0.4C33.4,41.5,33.3,41.2,33.1,41L33.1,41z"/>
			<path fill="#302F2C" d="M50.8,69.8c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.2,0.2-0.3,0.4-0.3c0.1,0,0.2,0.1,0.2,0.2
				C51.1,69.6,50.9,69.7,50.8,69.8z"/>
			<path fill="#565049" d="M58.5,44.6c0.2-0.2,0.2-0.6,0.6-0.6c0,0,0.1,0.2,0.1,0.3C59.2,44.8,58.8,44.6,58.5,44.6L58.5,44.6z"/>
			<path fill="#282725" d="M52.8,62.6c-0.1,0.4,0.1,1.1-0.7,0.7c0-0.1,0-0.2,0-0.2c0.1-0.3,0.2-0.5,0.5-0.5
				C52.6,62.6,52.7,62.6,52.8,62.6z"/>
			<path fill="#48443F" d="M55.7,24.8c-0.1,0.2-0.3,0.2-0.5,0.2c-0.1,0-0.2-0.1-0.1-0.2c0.1-0.1,0.3-0.1,0.4-0.2
				C55.6,24.6,55.7,24.6,55.7,24.8z"/>
			<path fill="#10100F" d="M53.2,42.1c0.3,0.3,0.4,0.6,0.1,1.1C53.3,42.7,53.2,42.4,53.2,42.1z"/>
			<path fill="#403D39" d="M38.7,52c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.4,0-0.4-0.1c-0.2-0.2,0.1-0.2,0.2-0.3
				C38.4,51.8,38.6,51.7,38.7,52z"/>
			<path fill="#161514" d="M49.7,47.5c0.4-0.2,0.7-0.1,0.6,0.4c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1-0.1-0.2-0.3-0.3-0.4
				C49.6,47.7,49.6,47.6,49.7,47.5z"/>
			<path fill="#181817" d="M46.2,37.9c0.4,0.1,0.7,0.3,0.6,0.5c-0.1,0.3-0.4,0.1-0.5-0.1C46.2,38.3,46.2,38.1,46.2,37.9z"/>
			<path fill="#2D2C29" d="M36,18.2c0.2,0,0.4,0,0.4,0.2c0,0.1-0.2,0.4-0.3,0.4c-0.3,0-0.3-0.3-0.3-0.5C35.8,18.1,35.9,18.1,36,18.2
				z"/>
			<path fill="#1A1918" d="M44,53.1c0.3,0,0.4,0.2,0.3,0.3c0,0.1-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.2-0.2-0.3
				C43.8,53.3,43.9,53.2,44,53.1z"/>
			<path fill="#49453F" d="M59.5,34.3c0-0.1,0-0.2,0-0.3c0.2,0.2,0.6,0.3,0.5,0.7c0,0.1-0.1,0.1-0.2,0.1
				C59.5,34.8,59.5,34.6,59.5,34.3z"/>
			<path fill="#2C2B29" d="M54.6,34.2c-0.1,0.1-0.3,0.3-0.4,0.3c-0.2,0-0.2-0.2-0.1-0.3c0-0.2,0.2-0.3,0.3-0.2
				C54.5,33.9,54.5,34.1,54.6,34.2z"/>
			<path fill="#272725" d="M44.6,54.7c0.1,0,0-0.5,0.3-0.2c0.3,0.3,0.2,0.7-0.2,0.9c0,0,0,0,0,0c0-0.1,0-0.3-0.1-0.4
				C44.5,54.9,44.5,54.9,44.6,54.7C44.5,54.7,44.6,54.7,44.6,54.7z"/>
			<path fill="#201F1E" d="M26.9,33.6c0.1-0.2,0.2-0.5,0.2-0.7c0.2-0.1,0.4-0.1,0.4,0.2C27.5,33.4,27.2,33.5,26.9,33.6z"/>
			<path fill="#0E0E0E" d="M29,38.5c0,0.1,0.1,0.2,0.1,0.4c0.1,0.1,0.1,0.2-0.1,0.3c-0.3,0-0.6,0-0.6-0.3
				C28.4,38.4,28.7,38.4,29,38.5z"/>
			<path fill="#494540" d="M41.5,55.9c-0.2-0.1-0.5-0.2-0.2-0.5c0.3-0.3,0.5,0,0.7,0.2C41.8,55.8,41.7,55.9,41.5,55.9z"/>
			<path fill="#3F3D38" d="M44.6,38.9c-0.1,0-0.3,0-0.3-0.2c0-0.1,0.2-0.2,0.4-0.2c0.1,0,0.2,0.1,0.2,0.1
				C44.9,38.8,44.7,38.8,44.6,38.9z"/>
			<path fill="#514C46" d="M52.3,56.6c0.2,0,0.3,0,0.5,0c-0.1,0.2,0.1,0.7-0.3,0.6C52.2,57.3,52.3,56.9,52.3,56.6z"/>
			<path fill="#121312" d="M55.9,57.8c0,0.2,0,0.3,0,0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.6-0.4-0.2-0.6
				C55.7,57.2,55.8,57.6,55.9,57.8z"/>
			<path fill="#363431" d="M46.1,50.2c-1-0.1-0.1-0.6-0.2-0.8C46.2,49.5,46,49.9,46.1,50.2C46,50.2,46.1,50.2,46.1,50.2z"/>
			<path fill="#4E4943" d="M50.8,49.7c0.4,0.1,0.5,0.4,0.5,0.8C50.8,50.4,50.9,50,50.8,49.7C50.9,49.7,50.8,49.7,50.8,49.7z"/>
			<path fill="#2D2C2A" d="M37.7,16c-0.1,0-0.2,0-0.3,0c-0.1-0.2-0.2-0.5-0.3-0.7c0-0.1,0-0.2,0.1-0.3C38.3,14.9,37.4,15.8,37.7,16z
				"/>
			<path fill="#4C4742" d="M43.6,16.9c0.4,0.2,0.4,0.6,0.2,0.7c-0.3,0.3-0.3-0.1-0.3-0.2C43.5,17.3,43.5,17.2,43.6,16.9z"/>
			<path fill="#121211" d="M36,50.5c0.2,0.6,0.1,1-0.1,1c-0.3,0.1-0.3-0.3-0.2-0.5C35.7,51,35.8,50.9,36,50.5z"/>
			<path fill="#292825" d="M64.2,36.1c-0.1,0.2-0.2,0.3-0.3,0.2c-0.1,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.2,0.4-0.2
				C64,35.8,64.2,35.9,64.2,36.1z"/>
			<path fill="#141413" d="M36,18.2c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.4-0.2-0.3-0.4c0-0.1,0.3-0.2,0.4-0.2C36,17.7,36,18,36,18.2z"/>
			<path fill="#181817" d="M31.2,38.4c0.4-0.2,0.8-0.5,1.2-0.7C32.3,38.5,31.8,38.5,31.2,38.4z"/>
			<path fill="#0F0F0E" d="M31.4,51.6c0.1,0.3,0.7,0.8-0.2,0.7C31.1,52.1,30.8,51.7,31.4,51.6z"/>
			<path fill="#0A0B0A" d="M66.4,39.2c-0.1-0.2-0.1-0.4-0.2-0.5c0.1,0,0.1-0.1,0.2-0.1c0.5,0.2,0.5,0.6,0.4,1
				C66.7,39.4,66.6,39.3,66.4,39.2z"/>
			<path fill="#47433E" d="M40.5,30.7c0.1,0,0.2,0,0.2,0c0,0.2,0,0.5-0.3,0.5c-0.1,0-0.2-0.1-0.2-0.2C40.1,30.8,40.3,30.8,40.5,30.7
				z"/>
			<path fill="#504B45" d="M38.2,31.4c0,0.2,0,0.4-0.2,0.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0-0.3,0.2-0.3
				C38.1,31.3,38.2,31.4,38.2,31.4z"/>
			<path fill="#201F1D" d="M65,30.5c0.3,0.3,0.3,0.7,0,1c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.3,0-0.4C64.7,30.5,64.8,30.4,65,30.5z"/>
			<path fill="#544E48" d="M50.9,59.9c-0.1,0-0.2,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.4-0.2c0.1-0.1,0.1-0.2,0.2-0.2
				C50.8,59.6,50.9,59.7,50.9,59.9z"/>
			<path fill="#151413" d="M28.2,21.6c-0.2-0.1-0.3-0.1-0.5-0.2c0,0,0,0,0,0c-0.1-0.2-0.1-0.3,0-0.5C28.5,20.7,28.5,21.1,28.2,21.6z
				"/>
			<path fill="#4C4842" d="M37,58.3c0.2,0.1,0.3,0.2,0.5,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.4,0
				C36.8,58.8,36.9,58.6,37,58.3z"/>
			<path fill="#010101" d="M32.8,20.8c0.1,0.1,0.2,0.2,0.3,0.3c0,0.2,0,0.4-0.3,0.4c-0.4,0-0.5-0.3-0.5-0.7
				C32.5,20.9,32.7,20.9,32.8,20.8z"/>
			<path fill="#232321" d="M53.4,60.8c0.2,0,0.3,0.1,0.3,0.2c0,0.2-0.1,0.3-0.3,0.3c-0.2,0.1-0.3-0.1-0.3-0.2
				C53.1,60.9,53.2,60.8,53.4,60.8z"/>
			<path fill="#302E2B" d="M36.7,61c-0.1,0.3,0.4,0.5,0.1,0.7c-0.1,0.1-0.3,0.1-0.4-0.1C36.2,61.3,36.5,61.1,36.7,61L36.7,61z"/>
			<path fill="#47433E" d="M52.8,20.6c-0.2-0.2-0.2-0.5,0-0.7c0,0,0.2,0,0.2,0.1C53,20.2,53.1,20.5,52.8,20.6z"/>
			<path fill="#44403C" d="M58,4.2c0.3-0.3,0.6-0.4,0.7-0.2c0.2,0.3-0.2,0.2-0.3,0.2C58.3,4.2,58.2,4.2,58,4.2z"/>
			<path fill="#3A3834" d="M31.2,21.5c0,0.2-0.1,0.3-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.3c0-0.1,0-0.3,0.2-0.2
				C31.1,21.3,31.1,21.4,31.2,21.5z"/>
			<path fill="#3D3A36" d="M49.7,3c-0.1,0.4-0.2,0.7-0.5,0.7C49.2,3.4,49.4,3.2,49.7,3z"/>
			<path fill="#312F2C" d="M55.5,0.8c0,0.2-0.1,0.3-0.2,0.2c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.3,0.2-0.2
				C55.4,0.6,55.5,0.7,55.5,0.8z"/>
			<path fill="#514C46" d="M51.6,15.5c-0.1,0.1-0.1,0.2-0.2,0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0.1-0.3,0.1-0.3
				C51.5,15.2,51.5,15.4,51.6,15.5z"/>
			<path fill="#2A2927" d="M43.7,25.4c-0.3,0.1-0.4,0.2-0.5,0.2c-0.2,0-0.3-0.2-0.3-0.3c0-0.1,0.1-0.2,0.2-0.2
				C43.3,25.1,43.4,25.2,43.7,25.4z"/>
			<path fill="#3F3C37" d="M58.5,7.5c0.1,0.2,0.2,0.3,0.2,0.4c0,0.2,0,0.3-0.2,0.4c0,0-0.1-0.2-0.2-0.3C58.2,8,58.3,7.9,58.5,7.5z"
				/>
			<path fill="#48443F" d="M46,17.7c-0.2,0-0.3-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2,0,0.2,0.1
				C46.2,17.5,46.1,17.6,46,17.7z"/>
			<path fill="#504C45" d="M41.5,13.2c0,0.2,0.2,0.5-0.1,0.5c-0.2,0-0.4-0.3-0.4-0.5C41.1,12.9,41.3,13.2,41.5,13.2
				C41.5,13.2,41.5,13.2,41.5,13.2z"/>
			<path fill="#1B1B1A" d="M44.1,18.8C44,18.9,43.9,19,43.8,19c-0.2,0-0.2-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.1
				C44,18.6,44,18.7,44.1,18.8z"/>
			<path fill="#524C46" d="M38.2,19.3c-0.2,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3,0-0.3-0.2c0-0.1,0.1-0.2,0.2-0.2
				C37.8,19.1,37.9,19.2,38.2,19.3z"/>
			<path fill="#534D47" d="M48.8,12.1c0.2,0.3,0,0.5-0.2,0.7C48.3,12.4,48.6,12.3,48.8,12.1z"/>
			<path fill="#34322F" d="M35.3,19.6c-0.1,0.1-0.1,0.2-0.3,0.3c-0.1,0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.3,0.2-0.4
				C35.2,19.3,35.3,19.4,35.3,19.6z"/>
			<path fill="#4E4A44" d="M60.7,22.4c-0.3,0.2-0.4,0.1-0.4,0c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.3,0.1
				C60.8,22.3,60.7,22.4,60.7,22.4z"/>
			<path fill="#4F4A44" d="M40.6,19.9c-0.1,0.3-0.1,0.3-0.3,0.3c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.2-0.2
				C40.5,19.8,40.6,19.9,40.6,19.9z"/>
			<path fill="#504B44" d="M42.6,19.6c0.1,0.1,0.3,0.1,0.3,0.2c0,0.2-0.1,0.3-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C42.4,19.7,42.6,19.7,42.6,19.6z"/>
			<path fill="#3D3A36" d="M45.4,19.5c0,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C45,19.6,45.1,19.5,45.4,19.5z"/>
			<path fill="#1B1A19" d="M52.3,12.2c-0.2,0.1-0.5,0.2-0.7,0.3c0-0.2,0.1-0.5,0.3-0.6C52.1,11.8,52.2,12,52.3,12.2z"/>
			<path fill="#4F4A44" d="M39.2,18.7c-0.2-0.1-0.3-0.2-0.2-0.3c0-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0.2,0.2,0.3
				C39.5,18.7,39.4,18.7,39.2,18.7z"/>
			<path fill="#0B0B0A" d="M54.7,65.1c0-0.1,0-0.1,0-0.2c0.3-0.1,0.5,0,0.6,0.3c0,0.1-0.1,0.2-0.1,0.3
				C54.9,65.5,54.8,65.2,54.7,65.1z"/>
			<path fill="#49453F" d="M31.2,26.9c0.1,0.4,0.2,0.5,0.2,0.6c0,0.1,0,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.1
				C31,27.5,31.1,27.4,31.2,26.9z"/>
			<path fill="#3A3835" d="M44.9,48.5c0.4-0.3,0.7-0.2,0.8-0.1c0.2,0.4-0.2,0.1-0.3,0.2C45.3,48.6,45.2,48.5,44.9,48.5z"/>
			<path fill="#272624" d="M24.2,35c-0.9,0,0-0.5-0.2-0.7C24.5,34.4,24.4,34.7,24.2,35z"/>
			<path fill="#373531" d="M54.2,53c0,0.3,0,0.6,0,0.9C53.5,53.6,53.8,53.3,54.2,53z"/>
			<path fill="#514C46" d="M66.3,33.9c0,0.2-0.1,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.2-0.1c0-0.1,0-0.2,0.1-0.2
				C66.2,33.8,66.2,33.9,66.3,33.9z"/>
			<path fill="#353330" d="M47.6,37.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C47.4,37.4,47.5,37.4,47.6,37.5z"/>
			<path fill="#191918" d="M34.1,54.5c0.1-0.2,0.1-0.3,0.2-0.5c0.3-0.3,0.4,0,0.5,0.2c-0.2,0.2,0,0.5-0.2,0.7c-0.1,0-0.2,0-0.2,0
				C34.1,54.8,34.1,54.7,34.1,54.5z"/>
			<path fill="#3F3C38" d="M37.2,54.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2-0.2-0.5-0.3-0.7-0.5c0.3-0.5,0.6,0.1,0.9,0
				C37.2,54.3,37.2,54.4,37.2,54.5z"/>
			<path fill="#34322F" d="M55.2,38.3c-0.1-0.4,0-0.6,0.4-0.7C55.7,38,55.5,38.1,55.2,38.3z"/>
			<path fill="#141414" d="M43.9,47.9c-0.1-0.2-0.3-0.3-0.4-0.5c0.1-0.4,0.4-0.4,0.7-0.4c0,0.1,0,0.1,0,0.2
				C43.9,47.4,43.9,47.7,43.9,47.9z"/>
			<path fill="#3A3834" d="M46.3,63.3c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.3,0.2-0.4
				C46.3,63,46.3,63.1,46.3,63.3z"/>
			<path fill="#534D47" d="M34.5,54.9c0.1-0.2,0.2-0.4,0.2-0.7c0,0.5,0.1,1-0.2,1.5c-0.1-0.1-0.2-0.2-0.3-0.2
				C34.4,55.2,34.4,55.1,34.5,54.9z"/>
			<path fill="#2E2D2A" d="M52.5,62.6c-0.2,0.2-0.3,0.3-0.5,0.5c0-0.2-0.3-0.4-0.1-0.6C52.2,62.4,52.4,62.5,52.5,62.6z"/>
			<path fill="#2D2C2A" d="M36.9,55.4c0.2-0.2,0.3-0.7,0.8-0.5C37.4,55.1,37.5,55.8,36.9,55.4z"/>
			<path fill="#4B4741" d="M38.4,27.5c-0.2,0.1-0.3,0.2-0.3,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0.1-0.2,0.2-0.2
				C38.2,27.2,38.3,27.3,38.4,27.5z"/>
			<path fill="#3C3935" d="M56.8,45.5c0.1,0,0.3,0,0.3,0.2c0,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.3-0.1-0.4-0.2
				C56.5,45.5,56.7,45.6,56.8,45.5z"/>
			<path fill="#363430" d="M36.7,45.7c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C36.5,45.5,36.6,45.6,36.7,45.7z"/>
			<path fill="#0E0F0E" d="M41.4,45.7c-0.1-0.1-0.3-0.2-0.3-0.3c0-0.2,0.1-0.2,0.3-0.2c0.2,0,0.3,0.1,0.3,0.3
				C41.7,45.5,41.5,45.6,41.4,45.7z"/>
			<path fill="#3E3B37" d="M69.3,27.5c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0-0.2-0.2c0-0.1,0.1-0.3,0.1-0.3
				C69.2,27.3,69.3,27.4,69.3,27.5z"/>
			<path fill="#2C2B28" d="M56.7,35.9c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.2
				C56.5,36.1,56.6,36,56.7,35.9z"/>
			<path fill="#413E3A" d="M49.7,68.2c0.1,0,0.2,0,0.2,0c0,0.3,0,0.7-0.3,0.6C49.2,68.7,49.5,68.4,49.7,68.2z"/>
			<path fill="#4D4842" d="M54.3,51.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0-0.2-0.1c0-0.1,0.2-0.3,0.3-0.3
				C54.2,50.8,54.3,50.9,54.3,51.1z"/>
			<path fill="#3C3935" d="M34.6,50.9c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2-0.1-0.3-0.2c0-0.1,0-0.3,0.1-0.3
				C34.5,50.6,34.5,50.7,34.6,50.9z"/>
			<path fill="#312F2C" d="M38.1,50.4c0.2,0,0.4,0.1,0.4,0.3c0,0-0.2,0.2-0.2,0.1c-0.1,0-0.3-0.1-0.4-0.2
				C37.9,50.5,38,50.4,38.1,50.4z"/>
			<path fill="#151615" d="M69.1,50.2c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.3,0-0.4-0.1c-0.1-0.2,0-0.3,0.2-0.3
				C68.5,50,68.6,50,69.1,50.2z"/>
			<path fill="#4D4943" d="M58.3,69.8c-0.2-0.1-0.4-0.2-0.5-0.4c0-0.1,0.1-0.2,0.2-0.2C58.3,69.4,58.3,69.6,58.3,69.8L58.3,69.8z"/>
			<path fill="#1B1B1A" d="M31,36.5c-0.1,0.2-0.1,0.4-0.4,0.3c-0.2,0-0.3-0.2-0.2-0.3c0.1-0.2,0.3-0.4,0.5-0.2
				C31,36.3,31,36.4,31,36.5z"/>
			<path fill="#4C4842" d="M66.2,49.4c0.2,0.1,0.4,0.2,0.4,0.5c0,0.1-0.1,0.2-0.2,0.2C66.2,49.9,66.2,49.7,66.2,49.4
				C66.2,49.4,66.2,49.4,66.2,49.4z"/>
			<path fill="#2B2A28" d="M40.6,36.6c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C40.3,36.8,40.4,36.7,40.6,36.6z"/>
			<path fill="#393733" d="M56.4,50.2c-0.2-0.2-0.5-0.4-0.5-0.7l0,0c0.2-0.1,0.3-0.2,0.5-0.2l0,0C56.4,49.5,56.7,49.8,56.4,50.2
				C56.4,50.2,56.4,50.2,56.4,50.2z"/>
			<path d="M56.6,52.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2-0.2-0.5-0.5-0.5-0.9C56.4,51.9,56.3,52.4,56.6,52.6z"/>
			<path fill="#3A3734" d="M56,69.5c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1-0.1,0.3-0.2,0.2c-0.1-0.1-0.2-0.2-0.2-0.4
				C55.7,69.6,55.8,69.5,56,69.5z"/>
			<path fill="#393734" d="M58.3,69.8c0.2,0,0.5-0.1,0.5,0.3c0,0.1-0.1,0.2-0.2,0.2C58.3,70.3,58.3,70,58.3,69.8
				C58.3,69.8,58.3,69.8,58.3,69.8z"/>
			<path fill="#3B3834" d="M59.5,65.9c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.3,0.1,0.4,0.2
				C59.9,65.8,59.7,65.9,59.5,65.9z"/>
			<path fill="#494540" d="M54.7,71.3c-0.4,0-0.4-0.1-0.4-0.2c0-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3,0,0.2,0.1
				C54.8,71.1,54.7,71.3,54.7,71.3z"/>
			<path fill="#353330" d="M30.4,35c-0.1,0-0.2,0.1-0.2,0.1C30,35,29.8,34.9,30,34.7c0.1-0.1,0.3-0.2,0.4-0.2
				C30.6,34.6,30.4,34.8,30.4,35z"/>
			<path fill="#272624" d="M53.7,72c-0.1,0-0.3,0.1-0.4,0.1c0-0.2,0-0.5,0.4-0.5C53.9,71.7,53.8,71.9,53.7,72z"/>
			<path fill="#393733" d="M43.4,49.4c-0.2-0.2-0.3-0.5-0.5-0.7c0-0.1,0-0.1,0-0.2c0.3,0,0.5,0.3,0.7,0.5
				C43.6,49.1,43.5,49.3,43.4,49.4z"/>
			<path fill="#4C4742" d="M49.6,40.3c0.2,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.1-0.2,0.2c0-0.1-0.1-0.2-0.1-0.3
				C49.5,40.4,49.6,40.3,49.6,40.3z"/>
			<path fill="#0F0F0E" d="M32.4,58.3c0-0.1,0-0.2,0-0.3c0.3-0.1,0.5-0.5,0.9-0.2C33.2,58.3,32.7,58.3,32.4,58.3z"/>
			<path fill="#302F2C" d="M59.8,40.3c0.3-0.3,0.6-0.2,0.9,0C60.4,40.4,60.1,40.5,59.8,40.3z"/>
			<path fill="#2F2E2B" d="M36.4,60.2c0.2,0.1,0.5,0.2,0.7,0.2c0.1,0.4,0,0.6-0.5,0.5c0,0,0,0,0,0C36.6,60.7,36.5,60.5,36.4,60.2z"
				/>
			<path fill="#4E4943" d="M46.3,40.6c-0.1,0.2-0.3,0.4-0.6,0.3c-0.2,0-0.3-0.1-0.2-0.3c0,0,0.2,0,0.3,0
				C46,40.4,46.1,40.4,46.3,40.6z"/>
			<path fill="#4F4A44" d="M60.8,43.1c0,0.1,0.1,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C60.5,43.2,60.6,43.1,60.8,43.1z"/>
			<path fill="#0B0B0A" d="M34,44c-0.1-0.3-0.2-0.7-0.2-1c0.2,0.3,0.7,0.6,0.4,1.1C34.2,44.1,34.1,44.1,34,44
				C34.1,44.1,34.1,44,34,44z"/>
			<path fill="#0C0C0B" d="M29.3,43c0.2-0.3,0.4-0.5,0.7-0.5C29.9,42.9,30,43.4,29.3,43z"/>
			<path fill="#504B45" d="M38.8,30.9c0.1,0.1,0.2,0.2,0.2,0.2c0,0.2-0.1,0.3-0.3,0.2c-0.1,0-0.2-0.2-0.2-0.3
				C38.6,31.1,38.8,31,38.8,30.9z"/>
			<path fill="#232220" d="M52.9,59.6c0.2,0,0.3,0.2,0.3,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.3
				C52.7,59.8,52.8,59.7,52.9,59.6z"/>
			<path fill="#47433E" d="M66,42c0,0.2,0.2,0.5-0.1,0.6c-0.1,0-0.3-0.1-0.3-0.2C65.5,42,65.8,42,66,42C66,42,66,42,66,42z"/>
			<path fill="#45413C" d="M47.2,31.1c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.2
				C47,30.9,47.1,31,47.2,31.1z"/>
			<path fill="#2A2927" d="M46.3,41.8c0,0.4,0,0.9-0.2,1.3C45.7,42.5,45.9,42.1,46.3,41.8z"/>
			<path fill="#1A1918" d="M54.6,41.6c0.1-0.3,0.4-0.3,0.7-0.4C55.2,41.5,54.9,41.6,54.6,41.6z"/>
			<path fill="#252423" d="M36.9,41.3c0,0.3,0,0.6,0,0.9C35.8,41.9,36.7,41.6,36.9,41.3z"/>
			<path fill="#050505" d="M26.9,40.3c0-0.1,0-0.3,0-0.4c0.3,0,0.7-0.3,0.7,0C27.7,40.3,27.1,40,26.9,40.3z"/>
			<path fill="#4F4A45" d="M44.3,57.7c-0.1,0-0.2,0.1-0.3,0c-0.1,0-0.2-0.1-0.1-0.3c0,0,0.1-0.1,0.2-0.1
				C44.2,57.5,44.4,57.5,44.3,57.7z"/>
			<path fill="#3B3834" d="M41.5,58.2c-0.1-0.2-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.2c0.1,0,0.1,0.2,0.1,0.3
				C41.7,58,41.6,58.1,41.5,58.2z"/>
			<path fill="#56504A" d="M39.1,29.5c0.2,0.2,0.2,0.5,0,0.7c-0.2,0.2-0.3,0-0.4-0.2C38.6,29.7,38.9,29.7,39.1,29.5z"/>
			<path fill="#050505" d="M32,44.1c0.1,0.1,0.3,0.2,0.3,0.4c0.1,0.2-0.2,0.2-0.3,0.2c-0.1,0-0.4,0.1-0.4-0.2
				C31.6,44.3,31.8,44.2,32,44.1z"/>
			<path fill="#403D39" d="M38.1,62.4c-0.3,0.1-0.5,0.1-0.7-0.1c0,0,0.1-0.2,0.1-0.2C37.8,62.2,38,62.1,38.1,62.4z"/>
			<path fill="#3C3A36" d="M48.1,35.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.1
				C48,35.2,48,35.3,48.1,35.4z"/>
			<path d="M38,18.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.3,0-0.3-0.2c0-0.1,0.1-0.2,0.2-0.2C37.8,18,37.9,18.1,38,18.2z"/>
			<path fill="#353330" d="M33.4,58.8c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1-0.1-0.2-0.1-0.2-0.2
				C33.2,59,33.3,58.9,33.4,58.8z"/>
			<path fill="#45413C" d="M58,32.1c-0.2,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1,0-0.2,0.1-0.2
				C57.6,31.9,57.7,32,58,32.1z"/>
			<path fill="#1D1D1C" d="M29,20c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2C28.9,19.7,29,19.8,29,20z"
				/>
			<path fill="#302F2C" d="M66.7,51.6c0.2-0.2,0.3-0.3,0.5-0.5c0.2,0.1,0.3,0.3,0.2,0.5C67.1,51.9,66.9,51.8,66.7,51.6z"/>
			<path fill="#47433E" d="M62.3,20.6c-0.1,0-0.1,0.1-0.2,0.1c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.1-0.1,0.2,0
				C62.2,20.4,62.2,20.5,62.3,20.6z"/>
			<path fill="#565049" d="M38.8,56.9c-0.2-0.1-0.7,0.3-0.7-0.1c0-0.3,0.4-0.2,0.7-0.1C38.9,56.7,38.9,56.8,38.8,56.9z"/>
			<path fill="#201F1D" d="M26.1,33.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.2-0.2,0.4-0.4,0.3C25.8,33.7,26,33.5,26.1,33.3z"/>
			<path fill="#1C1C1A" d="M45.8,57.4c-0.1,0.2-0.2,0.3-0.2,0.5c-0.2,0-0.4,0.1-0.4-0.2C45.2,57.3,45.5,57.3,45.8,57.4z"/>
			<path fill="#4E4A44" d="M60.5,57.6c-0.1,0.2,0,0.5-0.3,0.5c0,0-0.1,0-0.1-0.1C60.1,57.7,60.3,57.7,60.5,57.6
				C60.5,57.5,60.5,57.6,60.5,57.6z"/>
			<path fill="#0C0C0C" d="M34.1,20.6c0-0.2,0-0.5,0-0.7c0.6,0,0.2,0.5,0.5,0.7c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0.1
				C34.4,20.8,34.2,20.7,34.1,20.6z"/>
			<path fill="#4B4641" d="M41.5,53.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.1-0.2c0,0,0.1,0,0.2,0C41.5,52.9,41.5,53,41.5,53.1z
				"/>
			<path fill="#4E4A44" d="M64.3,31.7c-0.2,0.2-0.4,0.3-0.6,0.1c0,0,0-0.2,0-0.2C63.9,31.4,64.1,31.6,64.3,31.7L64.3,31.7z"/>
			<path fill="#58524B" d="M32.6,18.7c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1-0.2-0.4-0.3-0.2-0.6C32.4,18.5,32.5,18.4,32.6,18.7z"/>
			<path fill="#45423D" d="M38,35.2c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.1-0.1-0.3,0.2-0.3
				C37.9,34.9,37.9,35.1,38,35.2z"/>
			<path fill="#2A2927" d="M43.5,20.7c-0.1-0.1-0.2-0.2-0.2-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.2,0.1,0.1,0.1
				C43.7,20.4,43.6,20.5,43.5,20.7z"/>
			<path fill="#1E1D1B" d="M38.8,56.9c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c0.4-0.3,0.5,0,0.7,0.3C39.3,57.3,39.1,57.3,38.8,56.9z"/>
			<path fill="#131212" d="M32.4,32.5c-0.2-0.1-0.4-0.1-0.3-0.3c0.1-0.2,0.4-0.2,0.6,0C32.6,32.3,32.5,32.4,32.4,32.5z"/>
			<path fill="#35332F" d="M29,39.1c0-0.1,0-0.2,0.1-0.3c0.2,0,0.4,0,0.7,0c0,0.3,0,0.5-0.2,0.7c0,0,0,0,0,0
				C29.3,39.4,29.2,39.3,29,39.1z"/>
			<path fill="#373531" d="M62.8,39.2c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3-0.2,0.2c-0.1-0.1-0.1-0.2-0.2-0.3
				C62.6,39.3,62.6,39.2,62.8,39.2z"/>
			<path fill="#3B3935" d="M61.4,39.1c-0.1,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.2,0-0.2-0.2C61,39.2,61.2,39.2,61.4,39.1
				C61.4,39.1,61.4,39.1,61.4,39.1z"/>
			<path fill="#2B2A28" d="M57,12.6c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.1,0.2-0.2c0.1,0.1,0.2,0.2,0.2,0.2
				C57.2,12.3,57.1,12.4,57,12.6z"/>
			<path fill="#3B3834" d="M47.9,39.8c0.1,0.2,0.2,0.3,0.2,0.3c0,0.1-0.1,0.1-0.2,0.2c0-0.1-0.1-0.1-0.1-0.2
				C47.8,40,47.8,39.9,47.9,39.8z"/>
			<path fill="#373431" d="M36.8,44.5c0.1,0.1,0.2,0.1,0.2,0.1c-0.1,0.1-0.1,0.2-0.2,0.2c0,0-0.1-0.1-0.2-0.1
				C36.6,44.6,36.7,44.5,36.8,44.5z"/>
			<path fill="#47433E" d="M46.3,40.6c-0.2,0-0.3,0-0.5,0c0.2-0.2,0.4-0.6,0.8-0.4C46.7,40.2,46.4,40.4,46.3,40.6z"/>
			<path fill="#0D0D0C" d="M29,40.8c0-0.3,0-0.7,0.5-0.8C29.5,40.5,29.4,40.7,29,40.8z"/>
			<path fill="#504B45" d="M39.7,44.1c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1,0,0.1-0.1,0.2
				C39.8,44.2,39.7,44.1,39.7,44.1z"/>
			<path fill="#47433F" d="M40.9,11.7c0.1,0.1,0.1,0.2,0.2,0.3c0,0-0.1,0.1-0.1,0.1c-0.1-0.1-0.2-0.2-0.2-0.2
				C40.8,11.8,40.8,11.7,40.9,11.7z"/>
			<path fill="#010101" d="M25.9,40.6c0.4,0,0.6,0.4,0.7,0.7c-0.2,0.1-0.3,0.1-0.5,0C26,41.1,25.8,40.9,25.9,40.6z"/>
			<path fill="#46423D" d="M34.5,11.5c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0-0.2,0-0.2-0.2c0-0.1,0.1-0.1,0.2-0.1
				C34.4,11.3,34.5,11.3,34.5,11.5z"/>
			<path fill="#0B0B0A" d="M31.4,40.5c0.1,0,0.2,0,0.3,0.1c0,0.2-0.1,0.5-0.4,0.4c-0.1,0-0.1-0.2-0.2-0.2
				C31.1,40.7,31.2,40.6,31.4,40.5z"/>
			<path fill="#4F4A44" d="M60.7,42.6c-0.1,0-0.2-0.1-0.2-0.1c0-0.2,0.2-0.2,0.3-0.2c0.1,0,0.1,0.1,0.2,0.1
				C60.9,42.4,60.8,42.5,60.7,42.6z"/>
			<path fill="#302F2C" d="M40.2,10.5c0.1,0,0.2,0.1,0.2,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0-0.2-0.1-0.2-0.1
				C40.2,10.7,40.2,10.6,40.2,10.5z"/>
			<path fill="#262523" d="M61.1,41.1C61,41.1,61,41,60.9,41c0.1-0.1,0.2-0.2,0.4-0.3c0,0,0.2,0,0.2,0.1C61.5,41,61.3,41,61.1,41.1z
				"/>
			<path fill="#252523" d="M45.5,9.7c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0.2-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.2C45.8,9.6,45.7,9.7,45.5,9.7z
				"/>
			<path fill="#49453F" d="M57.8,42.3c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
				C57.9,42.2,57.9,42.2,57.8,42.3z"/>
			<path fill="#3F3C38" d="M46,6.4c0,0.2,0.1,0.4,0.1,0.5c0,0-0.2,0.1-0.3,0.1c0-0.1-0.1-0.2-0.1-0.3C45.8,6.6,45.9,6.5,46,6.4z"/>
			<path fill="#49453F" d="M56.8,41.5c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.2,0.2-0.3,0.1c-0.1,0-0.1-0.1-0.1-0.2
				C56.5,41.5,56.6,41.4,56.8,41.5z"/>
			<path fill="#3C3A36" d="M31.7,42c-0.1-0.2-0.2-0.4,0.1-0.6c0,0,0.2,0,0.2,0c0,0.2,0,0.4-0.1,0.5C31.8,42.2,31.7,42.2,31.7,42
				L31.7,42z"/>
			<path fill="#0B0B0B" d="M40.3,51.1c0.2,0.1,0.3,0.2,0.2,0.3c0,0.1-0.2,0.2-0.3,0.1c-0.2,0-0.3-0.2-0.2-0.3
				C40.1,51.2,40.3,51.2,40.3,51.1z"/>
			<path fill="#32302D" d="M36.8,51.5c0-0.1,0-0.2,0-0.3c0,0,0.1-0.1,0.2-0.1c0,0.1,0.1,0.2,0.1,0.3C37.1,51.4,36.9,51.4,36.8,51.5z
				"/>
			<path fill="#1C1C1B" d="M66.5,36.3c0.2,0.1,0.5,0.2,0.5,0.6c0,0.1-0.1,0.2-0.1,0.2c-0.3-0.1-0.3-0.3-0.3-0.5
				C66.4,36.4,66.4,36.4,66.5,36.3C66.4,36.3,66.4,36.3,66.5,36.3z"/>
			<path fill="#1C1C1A" d="M58.9,36.8c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.3,0-0.3-0.2c0-0.1,0.1-0.2,0.2-0.2
				C58.7,36.6,58.8,36.7,58.9,36.8z"/>
			<path fill="#393733" d="M50.1,37.4c-0.2,0-0.5-0.1-0.5-0.4c0,0,0.1-0.1,0.1-0.1C50.1,37,50.1,37.2,50.1,37.4
				C50.1,37.4,50.1,37.4,50.1,37.4z"/>
			<path fill="#0D0E0D" d="M55.9,49.4c-0.2,0-0.5-0.1-0.4-0.4c0-0.1,0.2-0.2,0.2-0.2C56,48.9,55.9,49.2,55.9,49.4
				C55.9,49.4,55.9,49.4,55.9,49.4z"/>
			<path fill="#282725" d="M35.6,48.9c-0.1,0-0.2,0-0.4,0c-0.1-0.1-0.1-0.2-0.2-0.2c0.1-0.2,0.1-0.4,0.2-0.5
				C35.7,48.3,35.7,48.6,35.6,48.9z"/>
			<path fill="#2D2C29" d="M52.1,46.8c-0.2,0-0.4,0.1-0.4-0.2c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.3,0,0.4,0
				C52.2,46.5,52.1,46.7,52.1,46.8z"/>
			<path fill="#3F3C38" d="M49.1,46.4c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1-0.2-0.1-0.3
				C49,46.5,49,46.4,49.1,46.4z"/>
			<path fill="#1F1E1D" d="M35,46.3c0.2-0.1,0.3-0.2,0.5-0.2c0,0.2,0.3,0.4,0.1,0.5C35.3,46.7,35.2,46.5,35,46.3z"/>
			<path fill="#373531" d="M25.4,38.6c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0-0.4,0.1-0.5-0.2C25.1,38.8,25.2,38.7,25.4,38.6z"/>
			<path fill="#191918" d="M65,45.8c0,0.3,0,0.5,0,0.8c-0.2-0.1-0.5-0.1-0.5-0.4C64.5,46,64.8,45.9,65,45.8z"/>
			<path fill="#393633" d="M55.3,71.9c0.1,0.1,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.1-0.2-0.1-0.2-0.2
				C55.2,72.1,55.3,72,55.3,71.9z"/>
			<path fill="#31302D" d="M54.7,70.1c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.1
				C54.4,70.2,54.5,70.1,54.7,70.1z"/>
			<path fill="#0C0C0C" d="M39.8,22.8c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.1,0-0.2c0.2,0,0.4-0.1,0.5,0.2c0.1,0.2,0,0.4-0.3,0.5
				C39.8,23,39.8,22.9,39.8,22.8z"/>
			<path fill="#0C0C0B" d="M34.5,22.3c0,0.3,0,0.5,0,0.8c-0.2-0.1-0.5-0.2-0.4-0.5C34.1,22.4,34.3,22.3,34.5,22.3z"/>
			<path fill="#504B45" d="M47,69.4c-0.3,0.2-0.4,0.2-0.6,0c0,0,0-0.1,0-0.2C46.6,69.3,46.7,69.3,47,69.4z"/>
			<path fill="#555049" d="M50.9,67.7c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.3,0.3-0.3c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0,0,0
				C51.2,67.5,51,67.6,50.9,67.7z"/>
			<path fill="#46423D" d="M44.3,67c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2-0.2-0.1-0.3c0-0.1,0.1-0.1,0.2-0.1
				C44.3,66.7,44.3,66.8,44.3,67z"/>
			<path fill="#363431" d="M53.7,66.5c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.1,0.5,0.2,0.3,0.5C54,67,53.8,66.7,53.7,66.5z"/>
			<path fill="#4A4640" d="M57,22.4c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0,0,0.1-0.1,0.1-0.1
				C56.9,22.3,56.9,22.4,57,22.4z"/>
			<path fill="#3E3C37" d="M45.6,28.8c-0.2-0.1-0.3-0.2-0.5-0.3c0.2-0.1,0.4-0.4,0.6-0.1C45.7,28.4,45.6,28.7,45.6,28.8z"/>
			<path fill="#0D0D0C" d="M46.5,65.5c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.3
				C46.8,65.4,46.6,65.5,46.5,65.5z"/>
			<path fill="#534D47" d="M37.3,28.9c0.2,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.1-0.1,0.1c-0.1-0.1-0.2-0.1-0.2-0.2
				C37.2,29,37.3,29,37.3,28.9z"/>
			<path fill="#33312E" d="M48.7,62.9c0.2-0.2,0.5-0.3,0.7-0.5c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0,0.2,0,0.3
				C49.3,63,49,62.8,48.7,62.9z"/>
			<path fill="#393633" d="M42.7,63.1c0-0.2,0-0.3,0-0.5c0.2,0,0.4-0.1,0.4,0.2C43.2,63.1,43,63.2,42.7,63.1z"/>
			<path fill="#48443F" d="M65.9,25.9c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0,0.2-0.2,0.2c0,0-0.1-0.1-0.1-0.2C65.8,26,65.9,25.9,65.9,25.9z
				"/>
			<path fill="#282725" d="M30.8,25.1c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.2-0.2c0-0.1,0.2-0.2,0.2-0.2
				C30.6,24.9,30.7,25,30.8,25.1z"/>
			<path fill="#383632" d="M70.5,24.5c-0.2,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1,0.1-0.2,0.2-0.2
				C70.3,24.4,70.4,24.5,70.5,24.5z"/>
			<path fill="#4B4641" d="M30.3,27.1c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.2
				C30.2,26.9,30.3,27,30.3,27.1z"/>
			<path fill="#45423D" d="M52.5,24.4c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.1-0.1-0.2-0.1-0.2c0,0,0.1-0.2,0.1-0.1
				C52.3,24.3,52.3,24.3,52.5,24.4z"/>
			<path fill="#373531" d="M59.2,23.7c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1,0-0.2,0.1-0.2
				C59,23.6,59.1,23.7,59.2,23.7z"/>
			<path fill="#48443F" d="M51.1,72c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1-0.1-0.2-0.1-0.2-0.2C50.6,72.2,50.9,72.1,51.1,72L51.1,72z"/>
			<path fill="#090909" d="M34.5,20.9c0-0.1,0-0.2,0-0.2c0.2-0.1,0.4-0.2,0.6,0c0,0,0,0.2,0,0.2C35,21.1,34.7,20.9,34.5,20.9z"/>
			<path fill="#34322F" d="M49.2,59.7c0-0.2,0-0.3,0-0.5c0.3,0.1,0.6,0.2,0.5,0.5C49.6,60,49.3,59.7,49.2,59.7z"/>
			<path fill="#1B1B1A" d="M38.1,21.2c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.3,0.1-0.3
				C38,20.9,38.1,21,38.1,21.2z"/>
			<path fill="#21201E" d="M27.8,20.8c0,0.2,0,0.3,0,0.5c-0.3-0.1-0.7,0.3-0.7-0.3C27.3,21,27.6,20.9,27.8,20.8z"/>
			<path fill="#3C3935" d="M65,30.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.1-0.3-0.4-0.1-0.5C64.9,30,64.9,30.3,65,30.5z"/>
			<path fill="#2E2D2A" d="M60.8,60.6c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.2,0,0.3,0.1c0,0.1,0,0.2-0.1,0.2C61,60.6,60.9,60.6,60.8,60.6z
				"/>
			<path fill="#4F4A44" d="M51.1,62.4c-0.5,0-0.5,0.4-0.5,0.7c-0.2-0.2-0.3-0.5-0.1-0.7C50.7,62.1,50.9,62.3,51.1,62.4z"/>
			<path fill="#151413" d="M32.8,24c-0.1,0.2-0.1,0.5-0.4,0.5c-0.3-0.1-0.1-0.3-0.1-0.5C32.5,24,32.7,24,32.8,24z"/>
			<path fill="#363431" d="M36,24c0.2,0,0.4,0.1,0.4,0.3c0,0-0.1,0.1-0.1,0.1C36,24.4,36,24.2,36,24C36,24,36,24,36,24z"/>
			<path fill="#3B3835" d="M56.2,39.3c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1,0-0.2,0.1-0.2
				C56.1,39.2,56.2,39.3,56.2,39.3z"/>
			<path fill="#48443F" d="M34.7,59.1c0,0.1,0,0.2-0.2,0.2c0,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.1,0.2-0.2C34.7,58.9,34.7,59,34.7,59.1z
				"/>
			<path fill="#565049" d="M39.6,56.9c-0.2-0.1-0.5-0.2-0.7-0.3C39.3,56.1,39.4,56.5,39.6,56.9z"/>
			<path fill="#32312E" d="M41.6,58.9c-0.1,0.1-0.1,0.2-0.2,0.2c0,0-0.1-0.1-0.2-0.1c0.1-0.1,0.1-0.2,0.2-0.3
				C41.5,58.8,41.6,58.8,41.6,58.9z"/>
			<path fill="#565049" d="M58.5,44.6c0.1,0.2,0.3,0.4,0.1,0.6c0,0-0.1,0-0.1,0C58.4,45,58.4,44.8,58.5,44.6
				C58.5,44.6,58.5,44.6,58.5,44.6z"/>
			<path fill="#282826" d="M55.1,60.1c-0.1,0-0.2,0-0.3,0c0,0-0.1-0.1-0.1-0.2c0.1,0,0.2-0.1,0.3,0C55,59.9,55,60,55.1,60.1z"/>
			<path fill="#0F0F0E" d="M44.6,44.3c-0.2,0.1-0.3,0.1-0.3,0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.2,0.1-0.2,0.2-0.2
				C44.3,44.2,44.4,44.3,44.6,44.3z"/>
			<path fill="#413E3A" d="M53.3,39.8c0-0.1,0-0.2,0-0.3c0.2,0.1,0.5,0,0.4,0.3C53.7,40,53.4,39.9,53.3,39.8z"/>
			<path fill="#312F2D" d="M43.6,12c0.2-0.1,0.4-0.2,0.4,0.1c0,0.3-0.2,0.2-0.4,0.2C43.6,12.2,43.5,12.1,43.6,12
				C43.6,12,43.6,12,43.6,12z"/>
			<path fill="#3E3B37" d="M59.6,26.7c0.1,0,0.1-0.1,0.1-0.1c0,0.1,0.1,0.2,0.1,0.2c-0.1,0-0.1,0.1-0.2,0
				C59.6,26.9,59.6,26.8,59.6,26.7z"/>
			<path fill="#151513" d="M39.3,25c0-0.2-0.1-0.6,0.3-0.5c0.3,0.1,0.2,0.3,0,0.5C39.5,25.1,39.4,25.1,39.3,25z"/>
			<path fill="#4B4842" d="M66.9,23.4c0,0,0.1,0.1,0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1-0.1-0.1-0.1C66.8,23.5,66.9,23.5,66.9,23.4z
				"/>
			<path fill="#282825" d="M51.1,37.9c0.2,0,0.3-0.4,0.6-0.2c0,0,0,0.1,0,0.2C51.5,38.2,51.3,38.1,51.1,37.9
				C51.1,37.9,51.1,37.9,51.1,37.9z"/>
			<path fill="#45413C" d="M34.5,38c0.2-0.1,0.3-0.2,0.5-0.3c0,0.2,0,0.4-0.2,0.5C34.7,38.3,34.6,38.1,34.5,38z"/>
			<path fill="#363430" d="M29.8,37.9c-0.1,0.4-0.4,0.4-0.7,0.2C29.2,38,29.5,37.9,29.8,37.9z"/>
			<path fill="#151615" d="M48.4,47.5c-0.2-0.1-0.5-0.1-0.4-0.5c0,0,0.1-0.1,0.2-0.1C48.6,47,48.4,47.3,48.4,47.5
				C48.4,47.5,48.4,47.5,48.4,47.5z"/>
			<path fill="#4B4742" d="M39.2,38.3c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0-0.1,0.1-0.2
				C39,38.2,39.1,38.2,39.2,38.3z"/>
			<path fill="#413E39" d="M63.8,46.9c-0.1-0.1-0.1-0.2-0.2-0.2c0.1,0,0.2-0.1,0.2-0.1c0.1,0.1,0.2,0.1,0.2,0.2
				C64,46.9,63.9,46.9,63.8,46.9z"/>
			<path fill="#46433E" d="M53.4,13.2c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1c0,0-0.1,0.1-0.2,0.1
				C53.5,13.3,53.5,13.2,53.4,13.2z"/>
			<path fill="#3B3935" d="M37.7,46.8c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1-0.1-0.1-0.1c0.1,0,0.2-0.1,0.3-0.1
				C37.6,46.6,37.7,46.7,37.7,46.8z"/>
			<path fill="#403D38" d="M33.8,38.4c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0,0.2-0.2,0.2c0,0-0.1-0.1-0.1-0.2
				C33.6,38.5,33.7,38.4,33.8,38.4z"/>
			<path fill="#43403B" d="M33.9,31.6c0.1,0,0.1,0.1,0.2,0.1c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0-0.2-0.1
				C33.8,31.7,33.9,31.6,33.9,31.6z"/>
			<path fill="#34322F" d="M64,38.9c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0-0.2-0.1-0.1-0.2c0-0.1,0.1-0.1,0.2-0.1
				C63.9,38.7,64,38.8,64,38.9z"/>
			<path fill="#403D39" d="M42.2,38.8c0.1,0.1,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2-0.1-0.2-0.1
				C42.1,39,42.2,38.9,42.2,38.8z"/>
			<path fill="#4E4A44" d="M64.7,31.2c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0.1-0.5,0.1-0.7,0.2c0,0,0,0,0,0C64.3,31.4,64.5,31.3,64.7,31.2
				z"/>
			<path fill="#191917" d="M63.4,45.9c0.2-0.1,0.5-0.2,0.7-0.3c0,0.2,0,0.5-0.3,0.5C63.6,46.2,63.4,46.1,63.4,45.9z"/>
			<path fill="#34322F" d="M64.4,38.9c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.1-0.2
				C64.3,39,64.4,38.9,64.4,38.9z"/>
			<path fill="#1E1E1C" d="M64.8,45.6c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.2,0.4,0.3,0.7,0.5c-0.2,0-0.5,0-0.7,0
				C64.9,45.8,64.8,45.7,64.8,45.6z"/>
			<path fill="#2F2D2B" d="M39.3,25c0.1,0,0.2,0,0.3,0c-0.1,0.2-0.2,0.4-0.5,0.2C39,25.2,39,25,39,25C39.1,25,39.2,25,39.3,25z"/>
			<path fill="#403D38" d="M70.2,26.8c0-0.1,0-0.2,0-0.3c0,0,0.1-0.1,0.2-0.1c0,0.1,0,0.2,0,0.3C70.3,26.8,70.2,26.8,70.2,26.8z"/>
			<path fill="#4A4641" d="M41.5,42.4c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1-0.1-0.2-0.1c0-0.1,0.1-0.1,0.1-0.1
				C41.4,42.3,41.4,42.4,41.5,42.4z"/>
			<path fill="#403D39" d="M52.3,40.9c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1-0.1,0-0.2c0,0,0.1-0.1,0.2-0.1C52.3,40.8,52.3,40.8,52.3,40.9
				z"/>
			<path fill="#413E3A" d="M48.3,9.9c-0.1,0-0.2,0-0.2,0c0,0,0-0.1,0-0.2c0.1,0,0.2,0,0.2,0C48.3,9.7,48.3,9.8,48.3,9.9z"/>
			<path fill="#403D39" d="M57.9,25.6c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.1-0.1,0.2
				C58,25.6,58,25.6,57.9,25.6z"/>
			<path fill="#555049" d="M31.6,42c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0,0.2c0.1,0.2,0.2,0.3,0.2,0.5c-0.1,0.1-0.3,0.2-0.4,0.2
				C31.7,42.6,31.7,42.3,31.6,42z"/>
			<path fill="#494540" d="M33.1,41.1c-0.1,0.2-0.3,0.3-0.4,0.1c0,0,0-0.1,0-0.2C32.8,41,32.9,41,33.1,41.1
				C33.1,41,33.1,41.1,33.1,41.1z"/>
			<path fill="#2A2927" d="M64.9,41.1c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.2
				C64.8,41,64.9,41,64.9,41.1z"/>
			<path fill="#32312D" d="M53.7,41.2c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2,0-0.2-0.2c0,0,0.1-0.1,0.2-0.1
				C53.6,41.1,53.7,41.2,53.7,41.2z"/>
			<path fill="#33322F" d="M47,8.6c-0.1,0-0.2,0-0.2,0c-0.1-0.2-0.4-0.4-0.1-0.5C46.8,8,46.9,8.4,47,8.6z"/>
			<path fill="#4F4A44" d="M43.9,8.2c0-0.2-0.1-0.4,0.1-0.5c0,0,0.2,0,0.2,0.1C44.2,7.9,44.1,8.1,43.9,8.2
				C43.9,8.2,43.9,8.2,43.9,8.2z"/>
			<path fill="#33312E" d="M28,25.4c0.2,0,0.4,0,0.5,0c-0.2,0.2-0.3,0.3-0.5,0.5C27.9,25.8,27.8,25.6,28,25.4z"/>
			<path fill="#1A1A19" d="M26.1,41.3c0.2,0,0.3,0,0.5,0c0,0.5-0.2,0.5-0.6,0.2C26.1,41.4,26.1,41.4,26.1,41.3z"/>
			<path fill="#383632" d="M49.8,4.7c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0.1,0.1,0.1,0.2,0.1,0.3
				C49.9,4.5,49.9,4.6,49.8,4.7z"/>
			<path fill="#302F2C" d="M62.2,40c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.3-0.2,0.2c-0.1,0-0.1-0.2-0.1-0.3C62.1,40.1,62.2,40.1,62.2,40
				z"/>
			<path fill="#1A1A19" d="M41,27.4c-0.1-0.5-0.1-0.6-0.1-0.7c0-0.1,0.2-0.1,0.3-0.1c0,0.1,0.1,0.1,0.1,0.2
				C41.2,26.9,41.2,27,41,27.4z"/>
			<path fill="#302E2C" d="M42.4,44.1c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0-0.2-0.1-0.1-0.2c0-0.1,0.1-0.1,0.2-0.1
				C42.3,43.9,42.4,44,42.4,44.1z"/>
			<path fill="#0C0C0C" d="M36.7,59.1c0.1,0,0.3,0,0.4,0c0,0.1,0,0.1,0,0.2c-0.3-0.1,0.1,0.8-0.5,0.3C36.7,59.4,36.7,59.2,36.7,59.1
				z"/>
			<path fill="#4A4641" d="M43.6,12c0,0.1,0,0.2,0,0.3c-0.2,0-0.5,0.2-0.6-0.1C43.1,11.8,43.4,11.9,43.6,12z"/>
			<path fill="#46423D" d="M53,62.6c-0.1,0-0.1,0-0.2,0c0.1-0.2,0.2-0.5,0.5-0.3C53.3,62.4,53.1,62.6,53,62.6z"/>
			<path fill="#0E0E0E" d="M67.2,35c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.3,0-0.2-0.1c0-0.1,0.2-0.1,0.3-0.2C67,34.9,67.1,35,67.2,35z
				"/>
			<path fill="#59534C" d="M41.5,55.9c0.2-0.1,0.3-0.2,0.5-0.2c0.1,0,0.2,0.1,0.1,0.2C41.9,56,41.7,56,41.5,55.9z"/>
			<path fill="#655D54" d="M40.1,35.8c0.2,0,0.5,0,0.7-0.1C40.6,36,40.3,36.4,40.1,35.8z"/>
			<path fill="#45413D" d="M48.2,51.4c0.2,0,0.3,0,0.5,0c-0.1,0.2-0.2,0.5-0.4,0.5C48,51.8,48.2,51.5,48.2,51.4z"/>
			<path fill="#3F3D38" d="M41.8,51.5c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1,0-0.2,0.1-0.2
				C41.7,51.4,41.7,51.5,41.8,51.5z"/>
			<path fill="#393633" d="M51.5,51.1c-0.1,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.2-0.1c-0.1-0.2,0.1-0.2,0.2-0.2
				C51.4,51,51.4,51,51.5,51.1z"/>
			<path fill="#292825" d="M33.4,22.7c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.2-0.2
				C33.3,22.5,33.4,22.6,33.4,22.7z"/>
			<path fill="#0C0C0B" d="M53.5,68.9c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.1,0,0.2,0.1C54.1,68.8,54,69,54,69.1
				C53.8,69.2,53.6,69.2,53.5,68.9z"/>
			<path fill="#4C4742" d="M44.9,50.9c0,0-0.1,0.1-0.1,0.1c0,0-0.1-0.1-0.2-0.1c0-0.1,0.1-0.1,0.1-0.1
				C44.8,50.8,44.9,50.8,44.9,50.9z"/>
			<path fill="#514C46" d="M66.5,36.3c0,0.1,0,0.1,0,0.2c-0.2-0.1-0.4-0.1-0.3-0.4c0,0,0.2-0.1,0.2-0.1
				C66.4,36.1,66.4,36.2,66.5,36.3z"/>
			<path fill="#3D3A37" d="M44.3,69.2c0,0.1,0.1,0.1,0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.2-0.1
				C44.2,69.3,44.3,69.2,44.3,69.2z"/>
			<path fill="#45413D" d="M48.7,50.4c0.2,0.2,0.4,0.4,0.2,0.7c-0.1,0-0.2,0-0.2,0C48.6,50.9,48.5,50.6,48.7,50.4L48.7,50.4z"/>
			<path fill="#4A4640" d="M42.7,51.1c-0.1-0.1-0.3-0.2-0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.1C42.7,50.7,42.7,50.9,42.7,51.1
				C42.7,51.1,42.7,51.1,42.7,51.1z"/>
			<path fill="#010101" d="M39.9,15.8c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1-0.1-0.2-0.2-0.2-0.2
				C39.7,16.1,39.8,16,39.9,15.8z"/>
			<path fill="#3B3935" d="M35.1,32.3c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.2
				C35,32.2,35,32.2,35.1,32.3z"/>
			<path fill="#4A4641" d="M44.6,54.7c0,0.1,0,0.2,0,0.3c-0.2,0-0.4,0.2-0.5-0.1C44.1,54.7,44.4,54.7,44.6,54.7z"/>
			<path fill="#312F2D" d="M39.1,29.5c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0-0.1,0-0.2-0.1c-0.2-0.3-0.2-0.5,0.2-0.5
				C38.8,29.5,38.9,29.5,39.1,29.5z"/>
			<path fill="#56504A" d="M38.6,29.5c-0.1,0.2-0.2,0.3-0.2,0.5c-0.2-0.2-0.3-0.4-0.1-0.6C38.4,29.2,38.5,29.4,38.6,29.5z"/>
			<path fill="#242422" d="M32.1,21.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.2-0.3-0.4,0-0.6C32,21.1,32.1,21.4,32.1,21.6z"/>
			<path fill="#514C46" d="M53.4,57.2c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.2
				C53.3,57.1,53.3,57.1,53.4,57.2z"/>
			<path fill="#433F3B" d="M52.3,63.9c0.2,0,0.3,0,0.5,0c-0.1,0.1-0.1,0.4-0.2,0.4C52.3,64.3,52.3,64,52.3,63.9z"/>
			<path fill="#44403C" d="M46.3,53.8c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0.1,0.1,0.1
				C46.4,53.7,46.4,53.8,46.3,53.8z"/>
			<path fill="#353330" d="M41.1,64.4c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.2
				C41,64.3,41,64.3,41.1,64.4z"/>
			<path fill="#3D3A36" d="M37.4,53.7c-0.2-0.1-0.3-0.1-0.5-0.2c0-0.1,0.1-0.3,0.3-0.3C37.4,53.3,37.4,53.6,37.4,53.7z"/>
			<path fill="#3E3B37" d="M43.2,61.6c-0.1,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.2-0.2c0,0,0.1-0.1,0.2-0.1
				C43.1,61.5,43.1,61.6,43.2,61.6z"/>
			<path fill="#1D1C1B" d="M36.5,53.3c-0.1,0.1-0.3,0.1-0.3-0.1c0-0.3,0.2-0.4,0.5-0.3C36.6,53,36.6,53.1,36.5,53.3z"/>
			<path fill="#060606" d="M36.7,21.8c0.1,0,0.2,0,0.2,0c-0.1,0.2-0.2,0.3-0.3,0.5c-0.3,0-0.5-0.2-0.5-0.5
				C36.4,21.8,36.5,21.8,36.7,21.8z"/>
			<path fill="#282724" d="M39.7,52.4c-0.1,0-0.2,0.1-0.3,0.2c0-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.2
				C39.6,52.3,39.7,52.3,39.7,52.4z"/>
			<path fill="#44403C" d="M54.9,14.6c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.1-0.1-0.1-0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.2
				C54.8,14.5,54.8,14.6,54.9,14.6z"/>
			<path fill="#43403B" d="M56.4,49.2c0.1-0.2,0.1-0.3,0.2-0.5c0.1,0,0.1,0.1,0.2,0.1C56.6,48.9,56.5,49.1,56.4,49.2
				C56.4,49.2,56.4,49.2,56.4,49.2z"/>
			<path fill="#161615" d="M36.8,32.1c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0.1,0.1,0.2
				C36.9,31.9,36.9,31.9,36.8,32.1z"/>
			<path fill="#32302D" d="M43.1,27.6c-0.1,0.2-0.1,0.3-0.2,0.5c-0.2-0.2-0.3-0.4-0.1-0.6C43,27.3,43.1,27.4,43.1,27.6z"/>
			<path fill="#47433E" d="M65.2,48.6c0,0-0.1,0.1-0.1,0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1
				C65.1,48.5,65.1,48.6,65.2,48.6z"/>
			<path fill="#1C1B1A" d="M34.2,56.6c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.3,0-0.5,0c0-0.1,0-0.1,0-0.2c0.2-0.2,0.4-0.3,0.7-0.2
				C34.2,56.5,34.2,56.5,34.2,56.6z"/>
			<path fill="#494540" d="M49.3,19.6c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0.1-0.1,0.2
				C49.4,19.7,49.3,19.7,49.3,19.6z"/>
			<path fill="#171716" d="M41,48c0.2,0,0.4,0,0.7,0c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.3,0.2-0.5,0.3C41,48.4,41,48.2,41,48z"/>
			<path fill="#31302D" d="M51.1,47.8c0.2,0.2,0.2,0.4,0.2,0.7C50.8,48.4,50.9,48.1,51.1,47.8z"/>
			<path fill="#494540" d="M24.5,37.4c0.1,0,0.3,0,0.4,0c0,0.2,0,0.5-0.2,0.5C24.4,37.9,24.5,37.6,24.5,37.4z"/>
			<path fill="#020302" d="M43.3,37.9c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.2,0.2
				C43.4,38.2,43.3,38.1,43.3,37.9z"/>
			<path fill="#47433E" d="M60.4,13.6c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0.1,0.1,0.1c0,0-0.1,0.1-0.1,0.1C60.5,13.7,60.4,13.7,60.4,13.6z
				"/>
			<path fill="#544E48" d="M51.5,19.8c0.1,0,0.1,0.1,0.2,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0-0.2-0.1
				C51.4,19.9,51.4,19.9,51.5,19.8z"/>
			<path fill="#4B4742" d="M44.7,36.9c0.1,0,0.1,0.1,0.2,0.1c0,0.1-0.1,0.2-0.2,0.3c0,0-0.2-0.1-0.2-0.1
				C44.6,37.1,44.7,37,44.7,36.9z"/>
			<path fill="#353330" d="M51.2,70.5c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.2-0.1c0-0.1,0-0.2,0.2-0.2
				C51.1,70.3,51.2,70.4,51.2,70.5z"/>
			<path fill="#111110" d="M35.2,37.4c0-0.2,0-0.3,0-0.5c0.2,0.1,0.5,0.2,0.5,0.5C35.6,37.7,35.4,37.5,35.2,37.4z"/>
			<path fill="#181817" d="M36.8,48.8c0,0.1,0.2,0.2,0.1,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.2-0.2-0.3
				C36.5,48.8,36.7,48.8,36.8,48.8z"/>
			<path fill="#181716" d="M52.3,71.2c0,0.1-0.1,0.1-0.1,0.2c-0.1-0.1-0.2-0.2-0.4-0.3c0,0,0-0.2,0.1-0.2
				C52.1,71,52.2,71.1,52.3,71.2z"/>
			<path fill="#48443F" d="M51.1,72c0-0.2,0.1-0.4,0.4-0.2c0.1,0,0,0.2,0.1,0.3C51.4,72.1,51.2,72,51.1,72C51.1,72,51.1,72,51.1,72z
				"/>
			<path fill="#F6EEDB" d="M3.2,152.6c0.1-0.3,0.3-0.4,0.6-0.4c0.1,0,0.2,0.2,0.2,0.2C3.7,152.7,3.5,152.7,3.2,152.6z"/>
			<path fill="#E8D193" d="M4.5,150.2c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0-0.4-0.1-0.4-0.1C4,150.3,4.3,150.3,4.5,150.2z"/>
			<path fill="#353330" d="M26.4,43.4c-0.3,0-0.6-0.1-0.9-0.1c0.1-0.4,0.4-0.8,0.9-0.6C26.7,42.8,26.4,43.2,26.4,43.4z"/>
			<path fill="#0B0C0B" d="M27.6,43.7c-0.3,0.1-0.7,0.3-0.8-0.2c0.1-0.3,0.4-0.3,0.6-0.2C27.7,43.3,27.6,43.5,27.6,43.7z"/>
			<path fill="#0E0E0D" d="M34.1,54.5c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0-0.1,0.1-0.2,0.1C33.5,54.8,33.9,54.7,34.1,54.5z"/>
			<path fill="#E3C779" d="M5,146.4c-1.1-0.2-0.5-0.8-0.5-1.4C5.5,145.3,4.7,146,5,146.4L5,146.4z"/>
			<path fill="#F9F3E5" d="M30.2,114.7c0,0.2,0,0.5,0,0.7c-0.3-0.1-0.7-0.2-0.5-0.6C29.7,114.5,30,114.6,30.2,114.7z"/>
			<path fill="#F7F1E2" d="M2.6,157.7c-0.2,0.1-0.5,0.3-0.7,0.4C1.9,157.6,2.2,157.5,2.6,157.7z"/>
			<path fill="#F2E5C5" d="M32.9,116.1c-0.1,0.4,0.3,0.8,0,1.2c-0.6,0-0.5-0.6-0.7-0.9c-0.2-0.1-0.4-0.2-0.8-0.5
				C32,116,32.4,116.1,32.9,116.1z"/>
			<path fill="#FCFAF5" d="M32.6,119c-0.3-0.1-0.6-0.1-0.9-0.2c0.1-0.2,0.3-0.3,0.4-0.5c0.2-0.1,0.3-0.1,0.5,0
				c0.2,0.2,0.4,0.3,0.5,0.5C33,119,32.8,119.1,32.6,119z"/>
			<path fill="#E8D297" d="M32.1,116.4c0.4,0.2,0.5,0.6,0.7,0.9c0,0,0,0,0,0c-0.1,0.1-0.2,0.4-0.5,0.2l0,0c-0.2-0.2-0.3-0.5-0.5-0.7
				C32,116.7,32,116.6,32.1,116.4z"/>
			<path fill="#F3E8CB" d="M32.4,117.6c0.2-0.1,0.3-0.2,0.5-0.2c-0.1,0.3-0.2,0.6-0.2,1c-0.2,0-0.3,0-0.5,0
				C31.5,117.9,32.2,117.8,32.4,117.6z"/>
			<path fill="#F4EAD0" d="M31.9,116.9c0.3,0.1,0.4,0.4,0.5,0.7C32,117.5,31.9,117.3,31.9,116.9z"/>
			<path fill="#C78B71" d="M80.4,46.6c-0.1-0.6,0.8-0.8,0.5-1.5c0.2-0.3,0.4-0.6,0.6-0.9c0.6,0.5,0.1,1.8,1.4,1.6l0,0
				c0,0.4,0,0.8,0,1.2l0,0c-0.3,0-0.5,1.1-0.9,0.1c-0.1-0.3-0.5,0.4-0.8,0.5C80.8,47.2,80.6,46.9,80.4,46.6z"/>
			<path fill="#C3A188" d="M90.7,47.5c-0.6,0.6,0.1,1.3-0.3,1.9c-0.4-0.1-1,0.1-0.7-0.7c0.1-0.1,0.2-0.2,0-0.3
				c-0.2-0.1-0.3-0.2-0.5-0.2c0.5-0.4-0.1-0.8,0-1.2c0.1-0.1,0.1-0.2,0.2-0.2c0.7-0.1,1.4-0.1,1.7-1c0.2,0,0.3,0,0.5,0
				c0.4,0,0.4,0.2,0.2,0.5c0,0,0,0,0,0c-0.4,0.1-0.8,0.2-0.7,0.8C91.2,47.4,90.9,47.5,90.7,47.5z"/>
			<path fill="#C78F75" d="M88.3,48c0,0.1,0,0.2,0,0.2c0.1,0.2,0.2,0.5,0.3,0.7c0,0.2,0,0.3-0.1,0.5c-0.7,0.6-1.4,1.2-2.3,0.7
				c-0.1-0.1-0.2-0.2-0.2-0.2c0,0,0,0,0,0c0.2-0.5,0.8-0.7,1-1.4C87,47.9,87.6,47.6,88.3,48z"/>
			<path fill="#C78D73" d="M83.5,41.5c0.2,0.4,1.1,0,0.9,0.9c-0.2,1-0.5,0.6-0.9,0.2c-0.5-0.3-1.3-0.3-1.2-1.2
				c0.1-0.2,0.2-0.3,0.4-0.4C83,41.2,83.3,41.2,83.5,41.5z"/>
			<path fill="#C5997F" d="M82.5,41.5c0.2,0.5,0.8,0.7,1,1.2c-0.1,0.2-0.1,0.6-0.3,0.5c-0.4-0.4-1.1-0.4-1.4-0.9
				c-0.1-0.2-0.1-0.3,0-0.5l0,0C82,41.4,82.2,41.3,82.5,41.5z"/>
			<path fill="#C78B71" d="M81.8,41.8c0,0.2,0,0.3,0,0.5c-0.2,0.3-0.4,0.4-0.7,0.5c-0.3-0.2-0.6-0.3-0.2-0.7
				C81,41.4,81.5,41.7,81.8,41.8z"/>
			<path fill="#C49A81" d="M85.2,49.2c-0.3,0.4-0.5,0.9-0.8,1.4c-0.3-0.4-0.6-0.8-0.8-1.1c0.4-0.1,1.2,0.7,1.2-0.5
				c0.2-0.1,0.3-0.1,0.5,0C85.2,49,85.2,49.1,85.2,49.2z"/>
			<path fill="#C69277" d="M79.7,50.4c-0.1,0-0.2,0-0.2,0c-0.3-0.3-0.2-0.6,0-1c-0.1-0.8,0.6-1,1-1.4c0,0.2,0,0.3,0,0.5
				c0,0.1,0,0.1,0,0.2C80.3,49.3,79.8,49.8,79.7,50.4z"/>
			<path fill="#C78B71" d="M83.3,44.4c0.6,0.7,0.3,1.2-0.5,1.4c0,0,0,0,0,0C82.9,45.3,82.7,44.7,83.3,44.4L83.3,44.4z"/>
			<path fill="#C8886E" d="M85.2,49c-0.2,0-0.3,0-0.5,0c-0.5-0.2-0.5-0.6-0.3-0.9c0-0.1,0.2-0.2,0.2-0.2
				C85.1,48.1,85.1,48.6,85.2,49z"/>
			<path fill="#C4997F" d="M89.5,46.8c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0-0.5,0.1-0.7-0.2
				C88.2,47,88.8,46.7,89.5,46.8z"/>
			<path fill="#C59A80" d="M86.9,46.6c0.1,0.6-0.1,0.9-0.6,1.4C86.3,47.3,86.6,47,86.9,46.6z"/>
			<path fill="#C3A087" d="M90.8,43.7c0,0.3-0.2,0.4-0.4,0.4c-0.2,0-0.3,0-0.4-0.2c0-0.3,0.1-0.4,0.4-0.5
				C90.6,43.4,90.7,43.4,90.8,43.7z"/>
			<path fill="#C78B71" d="M82.8,47c0.1-0.2,0.3-0.4,0.4-0.4c0.4,0.2,0.3,0.6,0.4,1C83,47.8,82.9,47.4,82.8,47L82.8,47z"/>
			<path fill="#C78D73" d="M83.5,41.5c-0.2-0.1-0.5-0.2-0.7-0.2c0-0.2,0-0.3,0.2-0.2c0.3-0.2,0.6-0.6,1,0
				C83.8,41.2,83.7,41.4,83.5,41.5z"/>
			<path fill="#C78B71" d="M84.7,45.1c0,0.3,0,0.6-0.5,0.4c-0.1-0.1-0.3-0.3-0.2-0.3C84.2,44.8,84.4,45,84.7,45.1L84.7,45.1z"/>
			<path fill="#C5997F" d="M81.6,43.5c0.1-0.1,0.1-0.3,0.2-0.3c0.2-0.1,0.3,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
				C81.8,43.7,81.7,43.5,81.6,43.5z"/>
			<path fill="#C79277" d="M87,41.3c0,0.2,0.1,0.3,0.1,0.4c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2
				C86.7,41.5,86.9,41.4,87,41.3z"/>
			<path fill="#C78B71" d="M84.7,45.1c0.1-0.2,0.1-0.4,0.4-0.3c0.1,0,0.1,0.2,0.2,0.3C85.1,45.1,84.9,45.1,84.7,45.1
				C84.7,45.1,84.7,45.1,84.7,45.1z"/>
			<path fill="#C8886E" d="M82.5,48.7c-0.2,0-0.4,0-0.5-0.2c0,0,0.1-0.1,0.1-0.1C82.4,48.3,82.5,48.5,82.5,48.7L82.5,48.7z"/>
			<path fill="#C78B71" d="M83.3,44.4c-0.2,0-0.5,0.1-0.6-0.2c0,0,0-0.1,0.1-0.1C83,44,83.1,44.2,83.3,44.4
				C83.3,44.4,83.3,44.4,83.3,44.4z"/>
			<path fill="#C6947A" d="M85.9,46.6c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0.1,0.1,0.1C86,46.5,86,46.6,85.9,46.6z"
				/>
			<path fill="#C59A80" d="M85.4,49.4c0-0.1,0-0.2,0-0.3c0.1-0.1,0.3-0.5,0.3-0.4C86,49.1,85.6,49.3,85.4,49.4z"/>
			<path fill="#C49E85" d="M85.9,49.9c0.1,0,0.2,0,0.2,0c0.2,0.2,0.5,0.3,0.6,0.5c0,0.4-0.3,0.6-0.6,0.6
				C85.6,50.8,86,50.3,85.9,49.9z"/>
			<path fill="#C3A38A" d="M82.5,50.9c0.1-0.4,0.2-0.7,0.4-1.3C83.2,50.3,83.1,50.7,82.5,50.9C82.5,50.9,82.5,50.9,82.5,50.9z"/>
			<path fill="#C49A81" d="M83,51.6c0-0.1,0-0.1,0-0.2c0.2-0.1,0.3-0.6,0.8-0.5C83.8,51.4,83.4,51.5,83,51.6z"/>
			<path fill="#C2A38B" d="M85.4,51.8c0,0.1,0,0.2,0,0.3c-0.2-0.1-0.4-0.1-0.3-0.4c0-0.1,0.2-0.1,0.3-0.2
				C85.4,51.6,85.4,51.7,85.4,51.8z"/>
			<path fill="#C78F75" d="M86.1,51.1c0.2-0.2,0.3-0.5,0.5-0.7c0.2-0.1,0.3,0,0.2,0.2c-0.1,0.4-0.4,0.6-0.7,0.7
				C86.1,51.3,86.1,51.2,86.1,51.1z"/>
			<path fill="#C3A087" d="M84.9,51.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1-0.1-0.1-0.1c0.1,0,0.1-0.1,0.2-0.1
				C84.8,51,84.9,51.1,84.9,51.1z"/>
			<path fill="#C3A188" d="M81.8,50.2c0-0.2-0.3-0.4-0.1-0.6c0,0,0.1,0,0.1,0C82.1,49.8,82,50,81.8,50.2
				C81.8,50.2,81.8,50.2,81.8,50.2z"/>
			<path fill="#CA8066" d="M91.9,46.3c-0.1-0.2-0.1-0.3-0.2-0.5c0.2-0.6,0.5-0.2,0.6,0C92.7,46.4,92,46.1,91.9,46.3z"/>
			<path fill="#C78B71" d="M83,41c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0.1-0.2,0.2-0.2,0.2c-0.2,0.1-0.5,0.2-0.7,0.2
				c-0.6-0.6-0.1-0.8,0.4-1.1C82.8,40.3,83,40.5,83,41z"/>
			<path fill="#C8876D" d="M79.7,50.4c-0.1-0.7,0.2-1.2,0.7-1.7c0.5,0.7-0.3,1.1-0.5,1.7C79.8,50.4,79.7,50.4,79.7,50.4z"/>
			<path fill="#C69379" d="M80.6,51.8c0.1-0.1,0.2-0.2,0.3-0.2c0.3,0,0.6-0.3,0.8,0.1c0,0.1,0,0.3,0,0.3
				C81.3,52.3,80.9,52,80.6,51.8z"/>
			<path fill="#C1A890" d="M79.4,45.4c-0.2-0.2-0.3-0.5-0.5-0.7c0.4,0,0.4-0.4,0.6-0.7c0.3,0.5,0.3,0.9,0.3,1.4
				C79.7,45.4,79.6,45.4,79.4,45.4z"/>
			<path fill="#CA8066" d="M86.8,50.6c-0.1-0.1-0.2-0.2-0.2-0.2c-0.3-0.1-0.4-0.2-0.5-0.5c0.8,0.3,1.4-0.2,2.1-0.5
				c0.3,0.3,0,0.5-0.2,0.7c-0.2,0.2-0.6,0-0.8,0.2C87.2,50.5,87,50.5,86.8,50.6z"/>
			<path fill="#C78F75" d="M87.3,50.4c0.2-0.1,0.5-0.2,0.7-0.2c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0.1c0,0.2,0,0.3,0,0.5
				C87.8,50.7,87.6,50.5,87.3,50.4z"/>
			<path fill="#C49D83" d="M92,48.1c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.2,0-0.2-0.1c-0.1-0.1,0-0.2,0.1-0.2C91.7,48,91.8,48.1,92,48.1z
				"/>
			<path fill="#C2A58C" d="M79.4,49.4c0,0.3,0,0.6,0,1l0,0c-0.3,0.3-0.6,0.2-1,0c0,0,0,0,0,0c0-0.3,0-0.6,0-1
				C78.8,49.4,79.1,49.4,79.4,49.4z"/>
			<path fill="#CA8066" d="M88.3,49c-0.3,0-0.9,0.1-0.9-0.3c0-0.6,0.6-0.2,0.9-0.4c0.4,0.1,0.7,0.3,0.9,0.7
				C88.9,49.2,88.6,49.2,88.3,49z"/>
			<path fill="#C3A188" d="M88.3,49c0.3,0,0.6,0,1,0c-0.2,0.7-0.7,1-1.2,1.4c0-0.1,0-0.2,0-0.2c0.1-0.2,0.2-0.5,0.3-0.7
				C88.3,49.3,88.3,49.1,88.3,49z"/>
			<path fill="#CA8066" d="M89,47.3c0.2-0.1,0.3-0.1,0.5-0.2c0,0.4,0.5,0.9-0.2,1.2C89.2,47.9,89.1,47.6,89,47.3z"/>
			<path fill="#CA8066" d="M89.7,48.5c0.2,0,0.4-0.1,0.4,0.2c0,0.2-0.2,0.1-0.4,0C89.7,48.6,89.7,48.6,89.7,48.5z"/>
			<path fill="#C69277" d="M78.4,50.4c0.3,0,0.6,0,1,0C79.1,51.1,78.8,50.8,78.4,50.4z"/>
			<path fill="#45413D" d="M48.7,50.4c0.1-0.2,0.2-0.3,0.4-0.3c0,0,0.1,0.1,0.1,0.2C49,50.3,48.9,50.3,48.7,50.4
				C48.7,50.4,48.7,50.4,48.7,50.4z"/>
			<path fill="#665E55" d="M35.5,23.7c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.2-0.2-0.6,0.1-0.7C35.5,23.2,35.4,23.5,35.5,23.7z"/>
			<path fill="#252522" d="M48.4,60.2c0,0.1,0,0.2,0,0.2c-0.2,0-0.6,0.1-0.5-0.3C48,60,48.3,60.2,48.4,60.2z"/>
			<path fill="#605851" d="M50.9,43.7c0-0.1,0.1-0.2,0.1-0.3c0.4-0.3,0.6-0.1,0.8,0.3c-0.1,0.3-0.1,0.6-0.2,0.9
				c-0.4,0.3-0.8,0-1.1-0.2c-0.1-0.1-0.1-0.2-0.1-0.3C50.6,43.9,50.7,43.8,50.9,43.7z"/>
			<path fill="#413E3A" d="M50.6,44.2c0.3,0.2,0.7,0.3,1,0.5c-0.3,0.2-0.5,0.3-0.8,0.5c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.2-0.3-0.4-0.4
				l-0.1-0.1C50.1,44.2,50.3,44.1,50.6,44.2z"/>
			<path fill="#625B52" d="M26.4,31.9c0,0.1,0,0.1,0,0.2c-0.1,0.2,0,0.5-0.3,0.5c-0.1,0-0.2-0.2-0.2-0.3C25.8,32,26,31.8,26.4,31.9z
				"/>
			<path fill="#151614" d="M50.2,44.7c0.3,0,0.4,0.2,0.4,0.4c0,0.2-0.1,0.3-0.3,0.2C50.3,45.1,50.2,44.9,50.2,44.7z"/>
			<path fill="#121210" d="M42.6,49.9c0-0.2-0.3-0.4,0-0.6c0.2-0.1,0.4,0.1,0.4,0.2C43.2,49.9,42.9,49.9,42.6,49.9
				C42.7,50,42.6,49.9,42.6,49.9z"/>
			<path fill="#635B53" d="M50.6,44.2c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.2-0.2-0.3-0.2-0.5
				c0.2-0.1,0.3-0.2,0.5-0.2C50.1,43.9,50.5,43.8,50.6,44.2L50.6,44.2z"/>
			<path fill="#413E3A" d="M50.6,44.2c-0.2-0.2-0.5-0.3-0.7-0.5c0.3,0,0.6,0,1,0C50.8,43.9,50.7,44,50.6,44.2z"/>
		</g>
	</g>
</g>
</svg>
        )
    }
}

export default Character3Mailbox;