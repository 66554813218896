import React from "react";
import { Link } from "react-router-dom";
import data from "../data.json";
import CharacterAdmiringAward from "./characters/CharacterAdmiringAward";
import CharacterAirplane from "./characters/CharacterAirplane";
import CharacterBox from "./characters/CharacterBox";
import CharacterKitchen from "./characters/CharacterKitchen";
import CharacterMail from "./characters/CharacterMail";
import CharacterMailbox from "./characters/CharacterMailbox";
import CharacterParty from "./characters/CharacterParty";
import CharacterPool from "./characters/CharacterPool";
import CharacterWaterCooler from "./characters/CharacterWaterCooler";

const LinkNameMap = {
  '1-1-1' : 'Cheers',
  '1-1-2' : 'Bon Voyage',
  '1-2-1' : 'Divvy it up',
  '1-2-2' : 'Check!',
  '1-3-1' : 'Splurge',
  '1-3-2' : 'Stash it',
  '2-1-1' : 'Save up',
  '2-1-2' : 'Charge it',
  '2-2-1' : 'Let it sit',
  '2-2-2' : 'Spree time!',
  '2-3-1' : 'Go lower',
  '2-3-2' : "I'll Pass",
  '3-1-1' : 'Adios amigos!',
  '3-1-2' : 'Push pause',
  '3-2-1' : 'Borrow it',
  '3-2-2' : 'Dip in',
  '3-3-1' : 'Put it to work',
  '3-3-2' : 'To the wind',
};

class ChoiceOptions extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    const setQuizProgress = this.props.setQuizProgress;
    const characterReference = this.props.characterReference;
    const updateLastClick = this.props.updateLastClick;
    let wrapper = function(element, i) {
      if (element.type === "image") {
        return (
          <div className="option" key={i}>
            <span className="option_image">{element.image}</span>
          </div>
        );
      } else if (element.type === "internal-button") {
        return (
          <Link
            to={element.destination[1]}
            className="option option--button"
            key={i}
            onClick={() => {
              updateLastClick(LinkNameMap[element.destination[1]]);
              setQuizProgress(currentScreen, element)
            }}
          >
            <span className={"option_image image image-" + element.image}></span>
            {element.copy.map((copy, i) => (
              <p key={i} className="option_copy">
                {copy}
              </p>
            ))}
          </Link>
        );
      } else if (element.type === "external-button") {
        return (
          <Link
            to={element.destination[1]}
            className="option option--button"
            key={i}
            onClick={() => setQuizProgress(currentScreen, element)}
            target="_blank"
          >
            <span className={"option_image image image-" + element.image}></span>
            {element.copy.map((copy, i) => (
              <p key={i} className="option_copy">
                {copy}
              </p>
            ))}
          </Link>
        );
      } else if (element.type === "character") {
        if (element.image === "character-admiring-award") {
          return (
            <CharacterAdmiringAward
              key={i}
              characterReference={characterReference}
            />
          );
        } else if (element.image === "character-airplane") {
          return (
            <CharacterAirplane
              key={i}
              characterReference={characterReference}
            />
          );
        } else if (element.image === "character-water-cooler") {
          return (
            <CharacterWaterCooler
              key={i}
              characterReference={characterReference}
            />
          );
        } else if (element.image === "character-party") {
          return (
            <CharacterParty key={i} characterReference={characterReference} />
          );
        } else if (element.image === "character-kitchen") {
          return (
            <CharacterKitchen key={i} characterReference={characterReference} />
          );
        } else if (element.image === "character-mailbox") {
          return (
            <CharacterMailbox key={i} characterReference={characterReference} />
          );
        } else if (element.image === "character-pool") {
          return (
            <CharacterPool key={i} characterReference={characterReference} />
          );
        } else if (element.image === "character-box") {
          return (
            <CharacterBox key={i} characterReference={characterReference} />
          );
        } else if (element.image === "character-mail") {
          return (
            <CharacterMail key={i} characterReference={characterReference} />
          );
        }
      }
    };

    return (
      <div className="options options--choice">{data[currentScreen].options.map(wrapper)}</div>
    );
  }
}
export default ChoiceOptions;
