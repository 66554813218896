import React from "react"

class Character2Airplane extends React.Component {
    render(){
        return(
<svg version="1.1" id="character-2" className="character-animated character-animated--airplane" x="0px" y="0px" viewBox="0 0 446.7 606.1" enable-background="new 0 0 446.7 606.1">
<g id="glide">
	<g id="_x37_aV8Vf_1_">
		<g>
			<path fill="#F9F9F8" d="M226.1,111.5c0.5-0.2,0.7-0.3,0.9-0.4c0,0.2-0.1,0.4-0.4,0.5C226.6,111.6,226.4,111.5,226.1,111.5z"/>
			<path fill="#FEFEFE" d="M27.9,53.3c-1.2-1.5-2.4-3-3.6-4.5c-1.7-2.2-3.3-4.3-4.9-6.5c-1.3-1.7-2.5-3.4-3.7-5
				c-0.2-0.4-0.5-0.6-0.7-1c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.9-0.7-1.2-1.2-1.7l0,0c-0.2-0.2-0.1-0.7-0.5-0.7c-0.2-0.1-0.3-0.2-0.5-0.3
				c-0.1-0.4-0.4-0.7-0.7-1c-0.4-1.1-1.4-1.8-2-2.7c-0.1-0.2-0.3-0.2-0.5-0.1c-0.3,0.1-0.6,0.2-0.6-0.2c0-0.1,0.3-0.3,0.5-0.4
				c0.3-0.8,0-1.3-0.7-1.7c-0.7-0.4-0.8-1.1-0.8-1.9c0-0.8-0.3-1.3-1.1-1.4c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.1,0-1-0.9-0.9
				c-0.4,0-0.8-0.7-1.1-1.1l0,0c-0.5-1.1-0.4-2.3-0.3-3.5c0-0.1,0.1-0.2,0.2-0.4c0.3-0.3,0.5-0.9,1.2-0.6c0.2,0.2,0.4,0.3,0.7,0.1
				c0.6-0.4,1.5-0.2,2,0.3c-0.2,0.2-0.5,0-0.7,0.1c0.3,0,0.7,0,0.9-0.2c0.4,0,0.7-0.1,1.1,0.1c0.3,0,0.5,0,0.7,0.2
				c0.3,0.4,0.6,0.8,0.9,1.2c0.2,0.2,0,0.5,0.5,0.4c1-0.1,2-0.2,3-0.3c1.4-0.2,2.6,0.4,4,0.6c0.1,0,0.2,0,0.2,0
				c0.1,0.1,0.2,0.3,0.1,0.4c-0.6,1.3,0.3,1.2,1.1,1.2c1.4,0,2.8,0,4.1,0c0.5,0,1-0.1,1.4,0.3c1.3,0.3,2.6,0.2,3.8,0.8l0,0
				c-0.1,0.7-0.7,0.4-1.2,0.6c1,0.4,1.9,0.4,2.9,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.5-0.1,0.8,0c1,0.5,2,0.4,3.1,0.6l0,0
				c0.7,1.2,1.7,1.6,3.1,1.5c1.8,0,3.6,0,5.3,0.4c0.1,0,0.2,0,0.2,0c0.3,0.3,0.8,0.3,1.2,0.5c0.3,0.1,1.1-0.1,0.5,0.8
				c-0.2,0.3,0.2,0.2,0.3,0.2c1,0.1,1.9,0.2,3,0c0.9-0.2,1.9,0.3,2.9,0.5c1.1,0.2,2.1,0.3,3.2,0.6c3.3,0.9,6.8,1.4,10.1,2.4
				c1.2,0.4,2.5,0.2,3.6,0.8c0.2,0.2,0.3,0.5,0.4,0.7c0.2,0.7,0.6,1,1.2,0.8c0.6-0.2,1.2-0.1,1.9,0c1.2,0.3,2.4-0.4,3.5,0.1
				c2.6,0.3,5.2,0.8,7.7,1.7c0.1,0.4-0.3,0.5-0.5,0.7c-0.4,0.3-0.9,0.7-0.8,1.1c0.1,1-0.4,1.1-1.2,1.1c-0.7,0-1.1,0.2-1,1
				c0,0.2-0.2,0.3-0.3,0.5c-0.4,0.9-1.4,1-2.1,1.6c-1.1,1-2.4,1.8-3.7,2.7c-0.7,0.4-1.3,0.7-1.9,1.2c-0.1,0.1-0.2,0.2-0.2,0.2
				c-0.2,0.2-0.5,0.2-0.7,0.5l0,0c-0.5,0.5-0.9,0.2-1.3-0.4c0,0.6,0.8,0.6,0.4,1.1l0,0c-0.4,0.1-0.8,0.3-1,0.7c-0.1,0-0.2,0-0.2,0
				c-0.7-0.4-0.3,0.3-0.5,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.7-0.5-1.6-0.4-2.4-0.4c-0.4,0-0.9,0.1-1.3,0.1c-0.1,0-0.2-0.1-0.3-0.2
				c-0.2-0.8-0.8-0.9-1.4-0.8c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.5,0.4-0.4,0.6-0.6c-0.4,0.1-0.7,0.3-0.9,0.6c0,0-0.1,0-0.1,0
				c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.4-0.4-0.5-0.8-0.3c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.3-0.7-0.7-0.4c-0.1,0-0.2,0-0.3-0.1
				c-0.3-0.2-0.5-0.3-0.8-0.4c-0.1-0.1-0.1-0.2-0.1-0.2c-0.2-0.7-0.2-0.7-1.2-0.9c-0.7-0.1-1.4,0-2-0.1c-1.7-0.7-3.5-1.1-5-2.3
				c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.4,0-1-0.6-0.4c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.2,0-0.6-0.6-0.6c-0.7,0-1.4-0.1-2.1-0.3
				c-0.6-0.1-1.3-0.3-1.6-0.9c0-0.9-0.9-0.7-1.3-1c-0.3-0.2-0.8-0.2-1.3-0.1c-0.3,0-0.6-0.1-0.8-0.3c-0.5-0.9-0.4-1.2,0.5-1.3
				c-1-0.1-2-0.1-3,0c-2-0.4-3.7-1.4-5.4-2.4c-0.3-0.4-0.6-1-1.3-0.7c-0.1,0-0.2,0-0.3-0.1c-0.2-1.2-1.1-0.9-1.9-0.8
				c-0.5,0-0.9-0.1-1.2-0.5c-0.5-0.7-1.2-1-2-1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.3-0.8-0.6-1.3-0.3c-0.1,0.1-0.1,0.1-0.2,0.2
				c0,0.5,0.5,0.4,0.7,0.5c0.2,0.1,0.4,0.3,0.6,0.5c0.3,0.3,0.6,0.4,0.9,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.3,0.6,0.4,0.8,0.7
				c0.3,0.5,0.3-0.3,0.5-0.1c0.4,0,0.7,0.2,0.9,0.5c0.4,1.7,0.4,1.7,2.4,1.7c0.2,0,0.3,0.1,0.5,0.2c0.7,0.3,0.8,1.2,1.6,1.5
				c0.3,0.1,0.5,0,0.8-0.1c0.3,0,0.6,0.1,0.9,0.3c1.8,1.2,3.7,2.3,5.5,3.5c0.2,0.1,0.3,0.3,0.4,0.4c0.3,0.1-0.6,0.6,0.3,0.6
				c0.3,0,0.7,0.5,1.1,0.8c0.4,0.3,0.7,0.1,1,0.1c1,0.6,2.3,0.7,3,1.9c-0.3,1.2,0.8,1.1,1.4,1.4c0.5,0.2,1.2,0.1,1.4,0.8
				c0,0.1-0.2,0.1-0.1,0.2c0-0.1,0.1-0.1,0.2-0.2c0.2,0.3,0.4,0,0.6,0c0.3,0,0.6,0.2,0.8,0.4c0.3,0.3,0.8,0.4,1.2,0.7
				c0.3,0.3,0.8,0.4,0.9,0.8c0,0.1-0.2,0.1-0.2,0.3c0,0.1,0.2,0,0.3,0c1.2,0.4,2,1.4,3.3,1.4c1.2,0.5,2.3,1.3,3.4,2
				c0.6,0.4,0.8,1.1,0.8,1.8c-0.1,1.1,0.1,2.2-0.3,3.3c-0.2,0.5,0,0.9,0.5,1.2c1.1,0.7,1.4,1.8,1.5,3.1c0,0.6-0.2,1.3,0.4,1.9
				c0.2,0.2,0.1,0.9-0.6,0.7l0,0c-0.3-0.5-0.9-0.7-1.4-1c-2.3-1.1-4.3-2.7-6.5-4.1c-0.2-0.2-0.5-0.3-0.7-0.5l0,0
				c-0.4-0.3-0.8-0.4-1.2,0l0,0c-0.8,0.2-1.3,0.7-1.9,1.2l0,0c-0.5,0.1-0.8,0.4-1.2,0.7l0,0c-0.6-0.1-0.4-0.4-0.3-0.8
				c0.1-0.3,0.5-0.4,0.6-0.8c-0.7,0-0.7,0.5-0.7,0.8c-0.1,0.6-0.4,0.9-1,1c-1.2,0.1-1.9,0.8-2.2,1.9c-2.1,1.4-4.2,2.9-6.2,4.3
				c-0.5,0.3-0.5-0.1-0.6-0.4c-0.3-0.9-0.6-1.8-1.7-2.1c-0.6-0.2-0.4-0.9-0.4-1.4c-0.3,0.6-0.6,1-1,0.2c-0.1-0.3-0.4-0.4-0.6-0.6
				c-0.9-0.9-1.5-1.9-2.2-2.9c-0.4-0.9,0.1-1.9-0.5-3.1c-0.1,0.6,0.4,1.2-0.1,1.4c-0.5,0.2-0.7-0.3-1.1-0.5c0-0.1,0-0.2,0-0.2
				c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.8,0-1.6-1.2-1.5c-0.3,0-0.6-0.3-0.6-0.6C28.9,53.4,28.4,53.3,27.9,53.3z"/>
			<path fill="#98989C" d="M50.7,46.8c-0.2-0.2-0.5-0.3-0.7-0.5c-1.2-0.8-2.6-1.1-3.6-2.2c-0.8-1-2.1-1-3-1.9
				c-0.9-0.3-1.7-0.8-2.3-1.5c-2.2-1.4-4.4-2.9-6.7-4.1c-1-0.2-1.6-1.1-2.5-1.4c-0.1-0.1-0.2-0.2-0.2-0.2c-1.1-0.3-1.9-0.9-2.6-1.7
				c-0.2-0.2-0.5-0.3-0.7-0.5c-0.5-0.3-1-0.3-1.4-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3-0.2-0.7-0.2-0.9-0.5c-0.2-0.2-0.4-0.3-0.6-0.5
				c-0.3-0.3-1.2-0.1-0.9-1l0,0c0.6-0.4,1.1-0.2,1.7,0.2c0.1,0,0.1,0.1,0.2,0c0.8,0.1,1.5,0.6,2.2,1c0.3,0.3,0.7,0.4,1.2,0.5
				c0.8,0.1,1.3,0.6,2,1l0,0c0.5,0.1,1.1,0.2,1.5,0.7c1.9,0.8,3.6,1.8,5.5,2.4c1,0.1,1.9,0.6,2.6,1.3c0.2,0.2,0.4,0.2,0.7,0.2
				c0.9,0.4,1.9,0.6,2.7,1.2c0.5,0.4,1,0.6,1.6,0.8c0.8,0.5,1.9,0.5,2.7,1.2l0,0c0.3,0.2,0.8,0.1,0.9,0.5c1.6,0.8,3.3,1.6,5,2.1
				c1,0.5,2.3,0.5,3.1,1.5l0,0c0.3,0.1,0.7,0.2,1,0.5l0,0c0.3,0.2,0.7,0.2,1,0.5c0.1,0,0.1,0.1,0.2,0c0.4,0.1,0.8,0.1,1,0.5l0,0
				c0.2,0.2,0.6,0,0.7,0.3l0,0c0.6,0.2,1.4,0.2,1.7,0.9l0,0c0.9,0.3,2,0.3,2.6,1.2c0.1,2.4-0.6,4.6-1,7c-0.4,2.8-0.9,5.6-1.4,8.6
				c-0.2-0.3-0.4-0.5-0.5-0.7c-0.7-0.5-0.7-1.2-0.9-1.9c-0.4-1.7-1.1-3.3-1.6-5c-0.2-0.8-0.4-1.6-0.4-2.4c0-1.1-0.6-1.7-1.5-2.2
				c-0.9-0.4-1.7-0.9-2.6-1.4c-1.1-0.5-2.1-1.1-3.1-1.7c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3-0.2-0.7-0.5-1-0.7
				C51.5,47.2,51.1,46.9,50.7,46.8z"/>
			<path fill="#6D6E72" d="M170.2,89.6c0.5,1.4,1.4,1.1,2.4,0.6c0.5-0.2,1.3-0.7,1.3,0.3c0.1,0.7,1.3,1.4-0.3,2.2
				c-3.5,1.8-7.2,2.8-10.9,3.6c-0.5,0.1-1.1,0.4-1.4-0.2c-0.3-0.7-0.7-1.5-0.6-2.3c0-0.4,0.5-0.3,0.8-0.3c1.7-0.4,3.5-0.8,5.2-1.2
				c0.8-0.2,0.9-0.4,0.3-1.1c-1.7-1.9-2.8-4.2-4.1-6.3c-0.7-1.1-0.5-1.4,0.8-1.8c2-0.7,2-0.7,3,1.3c0.6,1.1,1.3,2.2,2,3.3
				C169.3,88.1,169.8,88.8,170.2,89.6z"/>
			<path fill="#6E6E72" d="M161.1,64.9c1.1,0.4,2.3,0.5,3.2,1.2c0.3,0.2,0.2,0.5,0.1,0.8c-1,3.1-1,6.2-0.5,9.3
				c0.1,0.8-0.3,1.1-0.9,1.4c-2.3,1-2.4,1-2.9-1.3c-0.5-2.2-0.6-4.4-0.2-6.6c0.2-1.4,0.3-2.8,0.9-4.2
				C160.9,65.2,160.8,64.9,161.1,64.9z"/>
			<path fill="#6E6E73" d="M78.7,69.8c1.2,0.7,2.1,1.7,3.4,2.2c0.4,0.1,0.5,0.4,0.4,0.8c-0.8,2.2-1.2,2.4-3.1,1.2
				c-3.6-2.3-6.9-5-10.3-7.6c-0.6-0.4-0.6-0.8-0.1-1.2c1-1,1.8-1.1,2.9-0.3c1.9,1.4,3.7,3,5.8,4.2C78.1,69.3,78.4,69.6,78.7,69.8z"
				/>
			<path fill="#6E6E73" d="M88.1,75.6c0.6,0.3,1.2,0.6,1.8,1c2.9,2.1,6.1,3.5,9.4,5c0.6,0.3,0.7,0.5,0.4,1c-1.2,2.6-1.1,2.5-3.6,1.2
				c-2.5-1.2-4.9-2.7-7.3-4.1c-0.7-0.4-1.3-0.9-1.9-1.4c-0.1-0.1-0.3-0.2-0.3-0.3C86.3,77.6,87.6,75.6,88.1,75.6z"/>
			<path fill="#6E6E73" d="M136.8,94.2c-0.1,0.6-0.3,1.2-0.4,1.7c-0.1,0.3-0.3,0.4-0.6,0.3c-4.2-0.5-8.5-1-12.5-2.2
				c-0.7-0.2-0.9-0.4-0.4-1c0.1-0.1,0.2-0.3,0.2-0.4c0.8-1.9,0.9-2,2.8-1.3c2.1,0.7,4.3,1,6.4,1.2c1.2,0.1,2.4,0.5,3.6,0.6
				C136.5,93.3,136.9,93.6,136.8,94.2z"/>
			<path fill="#6E6E73" d="M117.8,89.6c-0.3,1-0.5,1.9-0.9,2.7c-0.1,0.4-0.4,0.2-0.7,0.1c-4-1.2-7.8-2.8-11.6-4.5
				c-0.1,0-0.3-0.1-0.3-0.2c-0.2-0.5,0-1.1,0.4-1.3c0.7-0.4,0.3-2,1.7-1.4c2.4,1,4.8,2,7.1,3.1c1.1,0.5,2.3,0.9,3.5,1.2
				C117.4,89.3,117.8,89.2,117.8,89.6z"/>
			<path fill="#6E6E73" d="M194.9,67.4c0.1,1.4-0.4,2.7-0.3,4c-0.4,1.3-1,2.7-1.3,4c-0.2,0.8-0.5,0.9-1.2,0.6
				c-0.3-0.1-0.6-0.2-0.9-0.3c-0.8-0.3-1.2-0.8-0.8-1.8c0.6-1.4,0.7-2.9,0.9-4.4l0,0c0.5-1.6,0.1-3.1-0.3-4.7
				c-0.2-0.8,0.1-1.2,0.7-1.6c0.8-0.4,1.5-0.8,2.4-0.9c0.4,0.3,0.4,0.8,0.5,1.2c0,0.1,0.1,0.2,0.1,0.4
				C194.9,65.1,195.3,66.2,194.9,67.4z"/>
			<path fill="#6D6E73" d="M189.1,82.3c-0.5,0.6-1.1,1.1-1.5,1.9c0,0.1-0.1,0.2-0.2,0.1c-1.1,0.7-2.1,1.6-2.9,2.6
				c-0.1,0.4-0.4,0.5-0.7,0.5c-1.3,0.4-2.2,1.5-3.4,2.1c-0.8,0.4-1.2,0.1-1.5-0.6c-0.4-1-0.8-1.8,0.7-2.4c1-0.5,1.9-1.4,2.8-2.1
				c0.2-0.7,0.8-1,1.4-1.2c0.3-0.2,0.5-0.5,0.8-0.7c0.2-0.5,0.5-1,1.1-1c0.4-0.1,0.6-0.5,0.9-0.8c0.4-0.6,0.8-0.8,1.5-0.6
				c0,0,0,0,0,0c0.3,0.2,0.8,0.1,1,0.4C190.5,81,189.5,81.6,189.1,82.3L189.1,82.3z"/>
			<path fill="#6E6E73" d="M150.2,97.6c-2-0.3-4.3-0.1-6.4-0.4c-0.6-0.1-0.8-0.2-0.7-0.8c0.1-0.6,0.2-1.2,0.3-1.8
				c0.1-0.4,0.3-0.7,0.8-0.6c3.5,0.3,6.9,0.4,10.4,0.2c0.4,0,0.6,0.2,0.7,0.6c0.8,2.4,0.8,2.4-1.8,2.6
				C152.5,97.5,151.4,97.6,150.2,97.6z"/>
			<path fill="#6E6E73" d="M187,57.6c-1.6-1.8-3.6-2.9-6-3.3c-1.1-0.2-1.9-1.9-1.5-2.9c0.1-0.2,0.3-0.2,0.5-0.2
				c2.9,0,5.5,0.8,7.8,2.6c1.2,0.9,2.1,2.1,3.1,3.1c0.4,0.4,0.3,0.7-0.2,0.9c-0.3,0.1-0.6,0.2-0.8,0.3c-1,0.8-1.9,1-2.7-0.3
				C187.1,57.7,187.1,57.7,187,57.6z"/>
			<path fill="#6E6E72" d="M174.2,54.4c0.1,0.5-0.2,0.7-0.6,0.9c-2.6,1.1-4.8,2.9-6.5,5.2c-0.4,0.5-0.8,0.4-1.3,0.3
				c-2.7-0.6-2.8-0.7-1-2.8c2.3-2.7,5.2-4.6,8.5-5.8c0.5-0.2,0.7,0.1,0.7,0.5C174.1,53.2,174.2,53.8,174.2,54.4z"/>
			<path fill="#6E6E72" d="M176.2,95.5c2,2,4.5,3.3,6.9,4.6c1.9,1,1.7,0.9,0.8,2.8c-0.8,1.8-1.6,0.3-2.4,0.3l0,0
				c-1.8-1.2-3.7-2.3-5.5-3.5c-0.1-0.1-0.3-0.1-0.4-0.2c-0.5-0.5-1.6-0.8-1.5-1.6c0.1-1,0.9-1.7,1.7-2.4
				C175.8,95.4,176,95.5,176.2,95.5L176.2,95.5z"/>
			<path fill="#6E6E73" d="M214.8,108.2c0,0.6-0.4,1.1-0.3,1.6c-0.1,0.2-0.1,0.5-0.1,0.7c0.1,0.9-0.3,1-1.1,0.8
				c-0.3,0-0.6,0-0.9-0.1c-1.8-0.4-3.6,0-5.3-0.4c-0.9-0.2-1.7-0.2-2.6-0.3c-0.7-0.1-0.9-0.4-0.7-1.1c0.6-1.9,0.5-1.9,2.7-1.8
				c1.7,0.1,3.4,0.2,5.1,0.3c0.3,0.3,0.5-0.1,0.7,0C213.2,108.2,214,107.9,214.8,108.2z"/>
			<path fill="#6E6E73" d="M190.4,107c-0.1-0.1-0.3-0.2-0.4-0.3c-0.5-0.4-1.7-0.2-1.7-0.9c0-0.8,0.7-1.6,1.1-2.3
				c0.1-0.3,0.3-0.2,0.6-0.1c1.1,0.4,2.2,0.7,3.2,1.2c1.1,0.5,2.3,0.7,3.5,1.1c0.2-0.1,0.4,0,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.1
				c0.6,0.1,1.4,0,1.6,0.6c0.2,0.8-0.1,1.7-0.5,2.5c-0.3,0.5-0.6,0.2-0.9,0.1C195.2,108.4,192.8,107.7,190.4,107L190.4,107z"/>
			<path fill="#FCFCFB" d="M4.1,18c0,1.3,0,2.6,0,3.8c-1.5-1.5-2.5-3.4-3.8-5c-0.2-0.3-0.4-0.7-0.2-1.1c0.3-0.5,0.7-0.1,1.1,0
				c1,0.3,2,0.4,2.9,0.9C4.5,17,4.4,17.5,4.1,18z"/>
			<path fill="#F9F8F6" d="M60.3,53.7c1,3.1,1.9,6.2,2.9,9.3c0,0.9-0.3,1.3-1.2,0.7c0.4,0,0.6-0.5,0.4-0.6c-1.1-0.7-0.5-1.8-0.5-2.6
				c0-1.1-0.8-1.8-1.3-2.1c-0.6-0.4-0.8-0.8-0.6-1.3C60.3,56.1,60,54.9,60.3,53.7z"/>
			<path fill="#FCFCFB" d="M25.2,21.8c-0.4-0.2-0.8-0.2-1.3-0.1c-2.1,0.3-4.2,0.1-6.5,0.1c0.6-0.7,1-1.3,1.1-1.9
				c2.3,0.5,4.5,0.9,6.7,1.7C25.3,21.6,25.3,21.7,25.2,21.8C25.3,21.8,25.2,21.8,25.2,21.8z"/>
			<path fill="#FCFBF9" d="M4.1,21.8c0.4,0.3,0.8,0.7,1.2,1c0.4-0.1,0.2-0.6,0.6-0.8c0,0,0.1,0.1,0.1,0.1c0,0.8-0.1,1.6,1.2,1.7
				c0.7,0,0.9,0.9,0.8,1.6c-0.2,1.2,0.6,1.6,1.4,2c0.4,0.2,0.3,0.5,0.4,0.7c0.2,0.5-0.2,0.4-0.5,0.4c-0.1,0-0.1,0.2-0.2,0.4
				c-1-0.7-1.2-1.8-1.8-2.7c-0.9-1.3-2-2.6-2.9-3.8C4.3,22.3,4.2,22,4.1,21.8z"/>
			<path fill="#FBFAF9" d="M79,39.1c0.1-1.8,0.1-1.8,2.1-1.8c0,0,0.1,0,0.1-0.1c-0.4-1,0-1.6,1.6-2.5c0.5,0.1,1.1-0.2,1.3,0.3
				c0.2,0.6-0.4,0.8-0.7,1.1C82,37.2,80.7,38.4,79,39.1z"/>
			<path fill="#FCFBFA" d="M18.3,19.9c-0.8,0-1.7,0.1-2.4-0.4c-0.4-0.2-0.8-0.1-1.2,0c-1.5,0.4-3,0.4-4.6,0.3c0-0.6,0.5-0.1,0.6-0.5
				c-0.6-0.2-1-0.7-0.8-1.4c2.1,0.2,4.2,1,6.3,1.3C16.9,19.4,17.7,19.3,18.3,19.9z"/>
			<path fill="#FCFCFB" d="M43.2,25.6c-2.1,0-4.2,0-6.3,0c-1.1,0-2-0.8-2.1-1.9c1.9,0.3,3.8,0.7,5.7,1.3
				C41.4,25.3,42.4,25,43.2,25.6z"/>
			<path fill="#FCFBF9" d="M75.1,33.1c-1.5-0.4-3.1,0.8-4.6-0.1c-0.1,0-0.2,0-0.2,0c-2,0.4-2.1,0.3-2.2-1.6
				C70.6,31.8,72.9,32.3,75.1,33.1z"/>
			<path fill="#FBFBF9" d="M34.8,62.4c0.6-0.1,0.6,0.8,1.1,0.8c0.4-0.3-0.2-0.7,0.1-1c0.1,0,0.4,0.1,0.4,0.1
				c0.1,1.7,2.1,2.2,2.5,3.7c0,0.2-0.5,0.8,0.2,0.7c-0.8,0.9-1.4,0.2-1.7-0.4C36.8,64.8,35.6,63.7,34.8,62.4z"/>
			<path fill="#FDFCFA" d="M51.4,27.6c-0.6,0-1.2,0.1-1.7-0.2C49,27,48.5,27.5,48,27.6c-1.1,0.2-2.1-0.3-3.2-0.1
				c-0.2,0-0.3-0.1-0.4-0.4c0-0.5,0.4-0.4,0.7-0.6c-0.5-0.5-1.5,0.2-1.7-0.9C46.1,26.2,48.8,26.7,51.4,27.6z"/>
			<path fill="#FCFBFA" d="M45.4,62.4c-0.3-0.9,0.6-2.2,1.7-2.1c1,0,1.3-0.4,1.3-1.2c0-1.2,0.8-0.8,1.3-0.7c0.6,0.2,0.2,0.5-0.1,0.7
				c-0.4,0.3-1.1,0.4-0.5,1.1C47.9,61.1,46.7,61.8,45.4,62.4z"/>
			<path fill="#FCFBFA" d="M34.8,23.7c-1.1-0.1-2.3,0.3-3.3-0.5c-0.3-0.2-0.4,0.2-0.6,0.3c-0.5-0.3-1,0-1.2-0.1
				c0.2,0,0.8-0.2,1.2,0.1c-1.1,0.4-2.2,0.5-3.3-0.2c-0.2-0.1-0.6-0.1-0.5-0.5c0.1-0.3,0.4-0.1,0.6-0.1c0.4,0,0.9,0.2,1.2-0.2
				C31.1,22.4,32.9,23.3,34.8,23.7z"/>
			<path fill="#FCFBF9" d="M27.9,53.3c0.3,0,0.4-0.2,0.6-0.4c0.4-0.4,0.6-0.3,0.6,0.2c-0.1,1,0.1,1.6,1.3,1.3
				c0.4-0.1,0.6,0.1,0.6,0.6c-0.1,0.7,0,1.3,0.1,2C29.6,56.1,28.8,54.6,27.9,53.3z"/>
			<path fill="#FCFBF9" d="M31,57.3c0.4-0.1,0.6,0.5,1.1,0.3c0.2-0.6-0.5-0.7-0.6-1.1c1.2-0.3,1.1-0.3,1.3,0.9
				c0.1,0.7,0.1,1.4-0.1,2.1C31.8,59,31.5,58.1,31,57.3z"/>
			<path fill="#FBFAF9" d="M4.1,18c0-0.5,0-1,0-1.4c1.7,0.4,3.4,0.6,5,1.2c-0.4,0-0.8,0-1.2,0l0,0c-0.6,0-1.3-1-1.9,0.1
				c-0.1,0.2-0.8,0.3-0.9-0.4c0.1-0.2,0.3-0.4-0.1-0.3c-0.4,0.1-0.4,0.5-0.6,0.8C4.3,18,4.2,18,4.1,18z"/>
			<path fill="#FCFBFA" d="M25.2,21.8c0-0.1,0-0.2,0-0.2c1.3,0.3,2.7,0,3.8,1C27.8,22.3,26.5,22,25.2,21.8z"/>
			<path fill="#FBF9F6" d="M13.5,34.3c0.8,0.2,1.8,0.4,1.2,1.7C14.1,35.5,13.6,35.1,13.5,34.3z"/>
			<path fill="#FCFBFA" d="M50.2,59.5c-0.2-0.3,0.9-0.5,0.1-0.9c-0.1,0,0.2-0.3,0.3-0.2c0.5,0.3,1-0.1,1.5,0
				C51.5,58.8,51,59.4,50.2,59.5z"/>
			<path fill="#F4EDE2" d="M71.3,44.6c0.5-0.7,1.1-1.1,1.9-1.2C72.7,44,72,44.3,71.3,44.6z"/>
			<path fill="#F9F8F6" d="M60.5,62.9c0.6-0.1,1,0.5,1.6,0.5c0.1,0-0.1,0.3-0.1,0.5C61.4,63.6,60.9,63.3,60.5,62.9z"/>
			<path fill="#F6F0E8" d="M52.1,58.3c0.4-0.9,0.8-0.5,1.2,0C52.9,58.3,52.5,58.3,52.1,58.3z"/>
			<path fill="#FCFBFA" d="M49,60.2c0.2-0.5,0.6-0.8,1.2-0.7C49.8,59.8,49.5,60.2,49,60.2z"/>
			<path fill="#FBF9F6" d="M13,33.6c0.2,0,0.4-0.2,0.5-0.1c0.3,0.3-0.1,0.6,0,0.8C13.3,34.1,13.1,33.8,13,33.6z"/>
			<path fill="#F5EEE3" d="M11.8,32.4c0.6,0,0.7,0.4,0.7,1C12.2,33,11.8,32.8,11.8,32.4z"/>
			<path fill="#FBF9F6" d="M14.9,36.2c0.4,0.2,0.7,0.5,0.7,1C15.2,37,14.9,36.7,14.9,36.2z"/>
			<path fill="#DFD2B9" d="M78.7,69.8c-0.5-0.1-0.7-0.4-1-0.7C78.3,69.1,78.5,69.4,78.7,69.8z"/>
			<path fill="#F4EDE2" d="M53.3,58.3c0.4,0,0.7,0,0.7,0.5C53.7,58.8,53.4,58.6,53.3,58.3z"/>
			<path fill="#6E6F73" d="M230.6,110.1c-1.9,0.3-3.9,0.7-5.8,0.9c-1.7,0.2-3.4,0.2-5.1,0.3c-0.5,0-0.7-0.2-0.6-0.7
				c0.1-0.5,0.1-1.1,0.1-1.7c0.2-0.4,0.3-0.7,0.8-0.7c3.3,0,6.6-0.2,9.9-0.7c0.5-0.1,0.8,0.1,0.9,0.5
				C230.9,108.8,231.7,109.5,230.6,110.1z"/>
			<path fill="#F9F9F9" d="M57.8,44.4c-1.1-0.5-2.2-0.7-3.1-1.5c1.3-0.2,2.6,0.5,3.9,0c0.1,0.5-1,0.6-0.3,1.2
				C58.1,44.2,58,44.3,57.8,44.4z"/>
			<path fill="#F8F8F8" d="M46.4,44.1c1.3,0.6,2.5,1.3,3.6,2.2c-0.2,0.4-0.5,0.3-0.7,0c-0.6-0.9-1.8-0.4-2.6-1.1
				C46.1,44.7,45.9,44.6,46.4,44.1z"/>
			<path fill="#FAFAFA" d="M41,37c-0.9-0.4-1.9-0.6-2.6-1.3c0.4-0.2,0.9-0.9,1.3,0c0.1,0.1,0.2,0.2,0.3,0c0.4-0.9,0.8-0.5,1.2,0
				c0.1,0.1,0.4,0,0.6,0.1C41,35.9,40.3,36,41,37z"/>
			<path fill="#FAF9F9" d="M29.1,33.3c1,0.4,1.8,1,2.6,1.7c-0.3,0.3-0.4,1.1-0.9,0.1c-0.2-0.4-0.7,0.4-1.2-0.1
				C29.1,34.5,29.2,33.9,29.1,33.3z"/>
			<path fill="#F6F5F5" d="M41.1,40.7c0.8,0.5,1.7,0.7,2.3,1.5c-0.8,0.8-1.6-0.1-1.8-0.4c-0.4-0.8-0.6-0.3-0.9,0
				C40.5,41.3,41,41.1,41.1,40.7z"/>
			<path fill="#F7F7F6" d="M48.7,40.3c-1-0.2-1.9-0.5-2.7-1.2c0.7-0.1,1.4,0.4,2.3,0.1C48.7,39.1,48.9,39.8,48.7,40.3z"/>
			<path fill="#FAFAFA" d="M44.4,38.4c-1-0.1-1.9-0.6-2.7-1.2c1.1-0.4,2,0.4,2.9,0.7C44.8,38,44.4,38.2,44.4,38.4z"/>
			<path fill="#F9F9F9" d="M53.1,48.2c1.1,0.5,2.2,1.1,3.2,1.9c-1.3,0-2.2-1.1-3.4-1.4C52.7,48.4,52.9,48.3,53.1,48.2z"/>
			<path fill="#FAF9F9" d="M31.9,35.2c1,0.1,1.7,0.9,2.5,1.4c-0.5,0.4-1.1,0.3-1.4-0.3C32.7,35.9,32.3,35.6,31.9,35.2z"/>
			<path fill="#F9F9F8" d="M31.5,32.6c-0.8-0.1-1.4-0.4-2-1c0.3-0.3,0.7-0.1,1-0.1c0.4,0,1.4-0.4,0.7,0.7
				C31.2,32.3,31.4,32.5,31.5,32.6z"/>
			<path fill="#F7F7F7" d="M63.4,47c-0.5-0.4-1.4-0.2-1.7-0.9C62.9,45.6,63.3,45.8,63.4,47z"/>
			<path fill="#F6F5F5" d="M28.3,31.2c-0.8-0.2-1.6-0.5-2.2-1C27.1,30,27.8,30.4,28.3,31.2z"/>
			<path fill="#F9F9F8" d="M32.9,33.3c-0.6-0.1-1-0.3-1.5-0.7C32.2,32.4,32.9,32.1,32.9,33.3z"/>
			<path fill="#F8F7F7" d="M26.9,32.1c0.5,0.1,1,0.3,1.4,0.7C27.8,33.3,27.2,33,26.9,32.1z"/>
			<path fill="#F7F7F6" d="M49.7,40.8c-0.3-0.1-0.8-0.1-0.9-0.5c0.1,0,0.2,0,0.2-0.1c0.3-0.2,0.5-0.6,1-0.4
				C49.5,40.1,49.9,40.5,49.7,40.8z"/>
			<path fill="#F7F7F7" d="M61.7,46.1c-0.3,0-0.5,0-0.7-0.3c0.5-0.4,1.1-0.8,1.9-0.6C62.5,45.7,61.7,45.4,61.7,46.1z"/>
			<path fill="#F9F9F9" d="M61,45.8c-0.4,0-0.7-0.2-1-0.5c0.3-0.1,0.4-0.5,0.8-0.4C61.2,45.2,60.9,45.5,61,45.8z"/>
			<path fill="#F6F5F5" d="M25.9,30.2c-0.6-0.1-1.1-0.4-1.7-0.2C24.9,29.3,25.5,29.5,25.9,30.2z"/>
			<path fill="#FCFCFB" d="M4.3,18c0-0.9,0.5-1.1,1.2-0.9c0,0.3-0.3,0.3-0.5,0.4C4.5,17.2,4.7,17.9,4.3,18z"/>
			<path fill="#F8F7F7" d="M24.3,30c0,0.6,0.7,0.5,0.9,1C24,30.8,24,30.8,24.3,30z"/>
			<path fill="#F9F9F9" d="M59.8,45.3c-0.4,0-0.7-0.2-1-0.5C59.5,44.2,59.6,44.9,59.8,45.3z"/>
			<path fill="#F0F0F0" d="M50.7,46.8c0.6-0.1,0.9,0.2,1.2,0.7C51.4,47.3,50.9,47.3,50.7,46.8z"/>
			<path fill="#F5F4F3" d="M53.1,48.2c-0.1,0.2-0.2,0.3-0.2,0.5c-0.2-0.2-0.6,0-0.8-0.3c0.3-0.3,0.6-0.1,0.9-0.2
				C52.9,48.2,53,48.2,53.1,48.2z"/>
			<path fill="#FAF9F9" d="M31.8,36.5c0.4-0.1,0.8-0.1,0.8,0.2c0,0.5-0.3,0.1-0.4,0C32.1,36.8,32,36.7,31.8,36.5z"/>
			<path fill="#F8F8F8" d="M49.3,46.3c-0.4,0.3-0.6,0.5-1.1,0.3C48.6,46.2,48.9,46.3,49.3,46.3z"/>
			<path fill="#F9F9F9" d="M58.8,44.9c-0.4-0.1-0.7-0.1-1-0.5C58.3,44.3,58.6,44.4,58.8,44.9z"/>
			<path fill="#F8F7F7" d="M26.4,32.9c-0.1,0.1-0.2,0.2-0.3,0.1c-0.1,0-0.1-0.1-0.2-0.2c0.1,0,0.2-0.1,0.2-0.1
				C26.2,32.7,26.3,32.8,26.4,32.9z"/>
			<path fill="#F8F7F7" d="M25.7,31.4c0.4,0,0.7,0.1,0.9,0.5C26.2,31.9,25.9,31.8,25.7,31.4z"/>
			<path fill="#FBFAF9" d="M7.9,17.7C7.7,18,7.3,18,7,17.9C7.3,17.6,7.6,17.8,7.9,17.7L7.9,17.7z"/>
			<path fill="#FEFEFE" d="M35,24.2c0.6-0.4,1-0.1,1.4,0.1C35.9,24.5,35.5,24.5,35,24.2z"/>
			<path fill="#FEFEFE" d="M31,23.5c-0.5,0-1.1,0-1.6,0C29.9,23.3,30.5,23.1,31,23.5L31,23.5z"/>
		</g>
	</g>
</g>
<g id="arm_and_plane">
	<g id="plane">
		<g id="_x37_aV8Vf_2_">
			<g>
				<path fill="#FEFEFE" d="M226.3,65.1c-1.6-1.1-3.2-2.1-4.8-3.2c-2.3-1.5-4.5-3.1-6.7-4.6c-1.7-1.2-3.4-2.4-5.1-3.6
					c-0.3-0.3-0.7-0.5-1-0.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.8-1-0.9-1.7-1.2l0,0c-0.2-0.2-0.3-0.6-0.7-0.5c-0.2,0-0.3-0.1-0.5-0.1
					c-0.2-0.4-0.6-0.6-1-0.7c-0.7-0.9-1.8-1.3-2.7-1.9c-0.2-0.1-0.4-0.1-0.5,0.1c-0.2,0.2-0.5,0.4-0.7,0c0-0.1,0.2-0.3,0.4-0.5
					c0-0.8-0.5-1.2-1.2-1.4c-0.8-0.2-1.1-0.8-1.3-1.5c-0.2-0.7-0.7-1.2-1.5-1c-0.2,0-0.3,0-0.5,0c-0.5,0-0.3-0.9-1.1-0.6
					c-0.4,0.2-1-0.4-1.4-0.7l0,0c-0.8-0.9-1.1-2.1-1.3-3.3c0-0.1,0-0.3,0-0.4c0.2-0.4,0.2-1,0.9-0.9c0.3,0.1,0.4,0.2,0.7-0.1
					c0.4-0.5,1.4-0.6,2-0.3c-0.2,0.3-0.5,0.2-0.7,0.3c0.3-0.1,0.6-0.2,0.8-0.5c0.3-0.1,0.7-0.3,1-0.2c0.3-0.1,0.5-0.2,0.8,0
					c0.4,0.3,0.8,0.6,1.3,0.9c0.2,0.1,0.2,0.5,0.6,0.3c0.9-0.4,1.8-0.8,2.7-1.2c1.2-0.6,2.6-0.4,3.9-0.7c0.1,0,0.1,0,0.2-0.1
					c0.1,0.1,0.3,0.2,0.3,0.4c-0.1,1.4,0.6,1,1.4,0.8c1.3-0.5,2.6-0.8,3.9-1.3c0.4-0.2,0.9-0.3,1.4-0.2c1.3-0.1,2.6-0.6,3.9-0.4l0,0
					c0.1,0.7-0.5,0.6-1,0.9c1.1,0.1,2-0.2,2.8-0.7c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.3,0.7-0.2c1.1,0.2,2.1-0.2,3.1-0.4l0,0
					c1,0.9,2.1,1,3.4,0.5c1.7-0.6,3.4-1.2,5.2-1.3c0.1,0,0.1-0.1,0.2-0.1c0.4,0.2,0.9,0,1.3,0.1c0.3,0,1-0.4,0.7,0.6
					c-0.1,0.3,0.3,0.1,0.4,0.1c1-0.2,1.9-0.4,2.8-1c0.8-0.5,1.9-0.4,2.9-0.4c1.1-0.2,2.1-0.4,3.2-0.5c3.5-0.1,6.9-0.8,10.3-0.8
					c1.2,0,2.4-0.6,3.7-0.3c0.3,0.1,0.4,0.4,0.6,0.6c0.4,0.6,0.9,0.7,1.4,0.3c0.5-0.4,1.1-0.5,1.8-0.6c1.2-0.1,2.1-1.2,3.4-1
					c2.6-0.6,5.2-0.9,7.8-0.8c0.2,0.4-0.2,0.6-0.3,0.8c-0.3,0.4-0.6,0.9-0.4,1.3c0.5,0.9-0.1,1.2-0.8,1.4c-0.7,0.2-1,0.5-0.7,1.3
					c0.1,0.2-0.1,0.4-0.1,0.5c-0.1,1-1.1,1.4-1.5,2.2c-0.8,1.3-1.7,2.5-2.6,3.7c-0.5,0.5-1.1,1.1-1.4,1.7c-0.1,0.1-0.1,0.2-0.2,0.3
					c-0.2,0.2-0.4,0.4-0.5,0.7l0,0c-0.3,0.6-0.8,0.4-1.4,0c0.2,0.6,1,0.3,0.7,0.9l0,0c-0.3,0.3-0.7,0.5-0.7,1
					c-0.1,0-0.1,0.1-0.2,0.1c-0.7-0.1-0.2,0.4-0.3,0.6c-0.1,0.1-0.2,0.3-0.4,0.4c-0.9-0.3-1.6,0.1-2.4,0.3c-0.4,0.1-0.8,0.4-1.2,0.5
					c-0.1,0-0.2,0-0.3-0.1c-0.4-0.7-1-0.6-1.6-0.3c-0.1,0-0.2,0-0.3-0.1c-0.2-0.5,0.2-0.5,0.4-0.8c-0.3,0.2-0.6,0.5-0.7,0.8
					c0,0-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.3-0.1c-0.3-0.4-0.6-0.4-0.9,0c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.5-0.8-0.2
					c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1,0-0.2-0.1-0.2-0.2c-0.4-0.6-0.4-0.6-1.4-0.5c-0.7,0.1-1.3,0.5-2,0.5
					c-1.8-0.1-3.7,0-5.4-0.6c-0.1-0.1-0.1-0.1-0.1-0.2c-0.2-0.3-0.3-0.9-0.7-0.1c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.2-0.6-0.8-0.4
					c-0.6,0.2-1.4,0.3-2,0.4c-0.6,0.1-1.3,0.1-1.8-0.3c-0.3-0.9-1-0.4-1.6-0.5c-0.4-0.1-0.9,0.1-1.2,0.3c-0.3,0.1-0.6,0.1-0.8,0
					c-0.8-0.7-0.8-1,0-1.4c-1,0.2-2,0.6-2.9,1c-2,0.2-4-0.1-5.9-0.6c-0.4-0.3-0.9-0.8-1.5-0.3c-0.1,0-0.2,0-0.3,0
					c-0.6-1-1.3-0.5-2-0.2c-0.4,0.2-0.9,0.1-1.3-0.1c-0.7-0.5-1.4-0.6-2.2-0.3c-0.1,0-0.2,0-0.3,0c-0.5-0.1-0.9-0.3-1.3,0.1
					c0,0.1-0.1,0.2-0.1,0.3c0.2,0.4,0.6,0.2,0.9,0.3c0.3,0,0.5,0.2,0.7,0.3c0.3,0.2,0.7,0.2,1,0.2c0.2,0,0.3,0.1,0.4,0.2
					c0.3,0.3,0.7,0.2,0.9,0.4c0.4,0.4,0.2-0.4,0.5-0.2c0.4-0.1,0.7,0,1,0.2c0.9,1.4,0.9,1.4,2.8,0.9c0.2,0,0.3,0,0.5,0
					c0.7,0.1,1.2,0.8,1.9,0.9c0.4,0,0.5-0.2,0.7-0.3c0.3-0.1,0.6-0.1,0.9,0c2.1,0.5,4.2,1,6.3,1.6c0.2,0.1,0.4,0.1,0.5,0.3
					c0.3,0-0.4,0.8,0.4,0.5c0.3-0.1,0.8,0.3,1.3,0.4c0.5,0.1,0.7-0.1,1-0.2c1.2,0.2,2.4,0,3.4,0.9c0.1,1.2,1.1,0.8,1.7,0.9
					c0.6,0.1,1.2-0.3,1.6,0.3c0.1,0.1-0.2,0.1-0.1,0.2c0-0.1,0-0.1,0.1-0.2c0.3,0.2,0.4-0.1,0.6-0.2c0.3-0.1,0.6,0,0.9,0.2
					c0.4,0.2,0.9,0.1,1.4,0.2c0.4,0.2,0.8,0.1,1.1,0.5c0,0.1-0.2,0.2-0.1,0.3c0,0,0.2,0,0.3-0.1c1.2,0,2.4,0.7,3.6,0.3
					c1.3,0.1,2.6,0.5,3.8,0.8c0.7,0.2,1.1,0.8,1.3,1.5c0.2,1.1,0.8,2.1,0.8,3.2c0,0.5,0.3,0.9,0.8,1c1.3,0.4,1.9,1.3,2.4,2.5
					c0.2,0.6,0.2,1.3,1,1.6c0.3,0.1,0.4,0.8-0.4,0.8l0,0c-0.5-0.4-1.1-0.4-1.7-0.5c-2.5-0.4-4.9-1.3-7.4-1.9
					c-0.3-0.2-0.6-0.1-0.8-0.2l0,0c-0.5-0.1-0.9-0.1-1.1,0.4l0,0c-0.7,0.4-1,1.1-1.4,1.7l0,0c-0.4,0.3-0.7,0.7-0.9,1.1l0,0
					c-0.6,0.1-0.5-0.3-0.5-0.6c0-0.3,0.3-0.5,0.3-0.9c-0.7,0.2-0.5,0.7-0.4,1c0.1,0.6,0,1-0.6,1.2c-1.1,0.5-1.5,1.3-1.5,2.5
					c-1.5,2-3.1,4-4.6,6c-0.4,0.4-0.5,0-0.7-0.2c-0.6-0.7-1.1-1.5-2.2-1.5c-0.6,0-0.6-0.7-0.9-1.2c-0.1,0.7-0.2,1.2-0.9,0.5
					c-0.2-0.2-0.5-0.3-0.7-0.3c-1.1-0.5-2-1.3-3-2c-0.6-0.7-0.5-1.8-1.4-2.8c0.1,0.6,0.8,1,0.3,1.4c-0.4,0.4-0.8-0.1-1.2-0.2
					c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.2-0.4-0.4-0.6-0.7c-0.3-0.8-0.5-1.5-1.6-1c-0.3,0.1-0.7-0.1-0.8-0.4
					C227.3,64.9,226.8,65,226.3,65.1z"/>
				<path fill="#98989C" d="M245.9,51.8c-0.3-0.1-0.6-0.2-0.9-0.2c-1.3-0.4-2.8-0.2-4.1-1c-1-0.7-2.3-0.3-3.4-0.8
					c-1,0-1.8-0.2-2.7-0.7c-2.5-0.6-5-1.3-7.6-1.8c-1,0.2-1.8-0.5-2.8-0.6c-0.1,0-0.2-0.1-0.3-0.1c-1.1,0.1-2.1-0.2-3-0.8
					c-0.3-0.1-0.6-0.1-0.9-0.2c-0.5-0.1-1.1,0-1.6-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.7,0-1-0.2c-0.2-0.1-0.4-0.2-0.7-0.3
					c-0.4-0.2-1.2,0.3-1.1-0.6l0,0c0.5-0.5,1-0.5,1.6-0.3c0.1,0,0.1,0,0.2,0c0.8-0.1,1.6,0.1,2.4,0.2c0.4,0.2,0.8,0.2,1.2,0.1
					c0.8-0.1,1.4,0.2,2.2,0.3l0,0c0.5,0,1.1-0.1,1.6,0.2c2,0.1,4,0.6,6,0.6c1-0.2,2,0,2.9,0.4c0.2,0.2,0.5,0.1,0.7,0
					c1,0.1,2-0.1,2.9,0.3c0.6,0.3,1.2,0.3,1.8,0.2c1,0.3,2-0.2,2.9,0.3l0,0c0.3,0.1,0.8-0.2,1.1,0.2c1.8,0.2,3.6,0.5,5.4,0.5
					c1.1,0.2,2.4-0.3,3.4,0.4l0,0c0.4,0,0.7,0,1.1,0.1l0,0c0.4,0,0.7,0,1.1,0.1c0.1,0,0.1,0,0.2,0c0.4-0.1,0.8-0.2,1.1,0.1l0,0
					c0.2,0.2,0.5-0.2,0.7,0l0,0c0.7,0,1.4-0.2,1.9,0.3l0,0c1,0,2-0.3,2.9,0.3c0.8,2.2,0.8,4.6,1.3,6.9c0.5,2.8,0.9,5.6,1.3,8.6
					c-0.3-0.2-0.5-0.4-0.7-0.5c-0.8-0.2-1.1-0.9-1.4-1.5c-0.9-1.5-2-2.8-3.1-4.3c-0.5-0.7-0.9-1.4-1.2-2.2c-0.3-1-1.1-1.5-2.1-1.6
					c-1-0.1-1.9-0.4-2.9-0.6c-1.2-0.2-2.3-0.4-3.5-0.6c-0.1,0-0.2-0.1-0.3-0.2c-0.4-0.1-0.8-0.2-1.2-0.4
					C246.9,52,246.4,51.8,245.9,51.8z"/>
				<path fill="#FCFCFB" d="M192.7,39c0.4,1.2,0.8,2.4,1.2,3.6c-1.9-1-3.4-2.4-5.2-3.6c-0.3-0.2-0.6-0.6-0.5-1
					c0.1-0.6,0.6-0.4,1-0.4c1,0,2-0.3,3.1-0.1C192.8,38,192.9,38.4,192.7,39z"/>
				<path fill="#F9F8F6" d="M257.3,55.4c1.9,2.7,3.8,5.3,5.6,8c0.3,0.9,0.1,1.3-0.9,1.1c0.4-0.2,0.4-0.7,0.2-0.7
					c-1.3-0.3-1.1-1.6-1.3-2.3c-0.4-1.1-1.3-1.4-1.9-1.6c-0.7-0.2-1-0.5-1-1C258,57.6,257.3,56.6,257.3,55.4z"/>
				<path fill="#FCFCFB" d="M214,36c-0.5-0.1-0.8,0.1-1.2,0.3c-1.9,0.9-3.9,1.4-6.2,2.2c0.4-0.9,0.6-1.5,0.4-2.2
					c2.3-0.3,4.6-0.6,6.9-0.5C214,35.8,214,35.9,214,36C214,36,214,36,214,36z"/>
				<path fill="#FCFBF9" d="M193.9,42.6c0.5,0.2,1,0.4,1.5,0.6c0.4-0.2,0-0.7,0.3-0.9c0,0,0.2,0,0.2,0.1c0.3,0.8,0.4,1.6,1.6,1.2
					c0.7-0.2,1.1,0.6,1.2,1.3c0.2,1.2,1.1,1.4,2,1.4c0.4,0,0.5,0.4,0.6,0.6c0.3,0.4-0.1,0.4-0.3,0.5c-0.1,0-0.1,0.3-0.1,0.4
					c-1.1-0.3-1.7-1.4-2.6-2c-1.3-1-2.7-1.8-4-2.7C194.2,43,194.1,42.8,193.9,42.6z"/>
				<path fill="#FBFAF9" d="M270.5,35.7c-0.5-1.8-0.5-1.8,1.5-2.4c0,0,0-0.1,0.1-0.1c-0.7-0.8-0.5-1.5,0.7-2.9
					c0.4-0.1,1-0.5,1.3-0.1c0.4,0.5-0.1,0.9-0.3,1.3C272.7,33,271.8,34.5,270.5,35.7z"/>
				<path fill="#FCFBFA" d="M206.8,36.4c-0.8,0.2-1.6,0.6-2.4,0.4c-0.4-0.1-0.8,0.1-1.1,0.4c-1.3,0.9-2.8,1.4-4.2,1.7
					c-0.2-0.6,0.4-0.2,0.4-0.6c-0.7,0-1.1-0.3-1.2-1.1c2.1-0.5,4.3-0.4,6.4-0.7C205.3,36.4,206,36.1,206.8,36.4z"/>
				<path fill="#FCFCFB" d="M232.3,34.1c-2,0.7-4,1.3-6,2c-1.1,0.3-2.1-0.1-2.6-1.2c1.9-0.3,3.9-0.6,5.8-0.6
					C230.5,34.3,231.3,33.7,232.3,34.1z"/>
				<path fill="#FCFBF9" d="M264.9,31.2c-1.6,0.1-2.7,1.7-4.4,1.3c-0.1,0-0.1,0-0.2,0.1c-1.7,1-1.9,0.9-2.6-0.9
					C260.2,31.3,262.5,31.2,264.9,31.2z"/>
				<path fill="#FBFBF9" d="M235.8,71.6c0.6-0.3,0.8,0.5,1.3,0.4c0.3-0.5-0.4-0.6-0.3-1c0.1,0,0.4-0.1,0.4,0
					c0.6,1.6,2.7,1.4,3.5,2.8c0.1,0.2-0.2,0.9,0.5,0.6c-0.5,1.1-1.3,0.6-1.7,0.1C238.4,73.3,236.9,72.6,235.8,71.6z"/>
				<path fill="#FDFCFA" d="M240.6,33.3c-0.6,0.2-1.1,0.4-1.7,0.3c-0.7-0.1-1,0.5-1.5,0.7c-0.9,0.5-2.1,0.4-3,0.9
					c-0.2,0.1-0.4,0-0.5-0.2c-0.2-0.5,0.3-0.5,0.5-0.8c-0.6-0.4-1.4,0.7-1.9-0.3C235.2,33.7,237.9,33.4,240.6,33.3z"/>
				<path fill="#FCFBFA" d="M245.8,68.3c-0.6-0.8-0.1-2.3,0.9-2.6c0.9-0.3,1.1-0.8,0.8-1.5c-0.4-1.1,0.5-1,1-1
					c0.6,0,0.3,0.4,0.1,0.7c-0.3,0.4-0.9,0.7-0.1,1.2C247.8,66.3,246.9,67.4,245.8,68.3z"/>
				<path fill="#FCFBFA" d="M223.7,34.9c-1.1,0.2-2.1,1-3.3,0.6c-0.3-0.1-0.3,0.3-0.4,0.4c-0.5-0.1-0.9,0.3-1.2,0.3
					c0.3,0,0.7-0.4,1.2-0.3c-0.9,0.7-2,1.1-3.2,0.9c-0.3-0.1-0.6,0.1-0.6-0.4c0-0.3,0.4-0.2,0.6-0.2c0.4-0.1,0.9-0.1,1.1-0.6
					C219.7,34.7,221.8,35,223.7,34.9z"/>
				<path fill="#FCFBF9" d="M226.3,65.1c0.2-0.1,0.3-0.3,0.4-0.5c0.2-0.5,0.5-0.5,0.7,0c0.2,0.9,0.6,1.5,1.7,0.9
					c0.4-0.2,0.6-0.1,0.7,0.4c0.2,0.6,0.4,1.3,0.7,1.9C228.9,67.2,227.6,66.1,226.3,65.1z"/>
				<path fill="#FCFBF9" d="M230.5,68c0.4-0.2,0.7,0.3,1.1,0c0-0.6-0.7-0.6-1-0.9c1-0.6,1-0.6,1.6,0.4c0.3,0.6,0.6,1.3,0.6,2
					C231.9,69.3,231.3,68.6,230.5,68z"/>
				<path fill="#FBFAF9" d="M192.7,39c-0.1-0.5-0.3-0.9-0.4-1.4c1.7-0.2,3.4-0.5,5.2-0.4c-0.4,0.1-0.8,0.3-1.1,0.4l0,0
					c-0.6,0.2-1.6-0.6-1.8,0.7c0,0.3-0.7,0.5-1-0.1c0-0.2,0.1-0.4-0.2-0.2c-0.4,0.2-0.2,0.6-0.3,0.9C192.9,38.9,192.8,39,192.7,39z"
					/>
				<path fill="#FCFBFA" d="M214,36c0-0.1,0-0.2-0.1-0.2c1.3-0.1,2.6-0.8,4-0.3C216.6,35.7,215.3,35.9,214,36z"/>
				<path fill="#FBF9F6" d="M206.7,51.6c0.9,0,1.8-0.1,1.7,1.2C207.7,52.5,207,52.3,206.7,51.6z"/>
				<path fill="#FCFBFA" d="M249.5,64.1c-0.3-0.3,0.7-0.8-0.2-0.9c-0.1,0,0.1-0.3,0.2-0.3c0.6,0.1,0.9-0.4,1.4-0.5
					C250.5,62.9,250.2,63.7,249.5,64.1z"/>
				<path fill="#F4EDE2" d="M264.9,43.3c0.2-0.8,0.7-1.4,1.4-1.7C266.1,42.3,265.5,42.8,264.9,43.3z"/>
				<path fill="#F9F8F6" d="M260.3,64c0.5-0.3,1.1,0.2,1.6-0.1c0.1,0,0,0.3,0.1,0.5C261.4,64.4,260.8,64.3,260.3,64z"/>
				<path fill="#F6F0E8" d="M250.9,62.3c0.1-1,0.6-0.7,1.1-0.4C251.7,62.1,251.3,62.2,250.9,62.3z"/>
				<path fill="#FCFBFA" d="M248.5,65.1c0.1-0.5,0.3-0.9,0.9-1.1C249.2,64.5,249,64.9,248.5,65.1z"/>
				<path fill="#FBF9F6" d="M206,51c0.2-0.1,0.4-0.3,0.5-0.3c0.4,0.2,0.1,0.6,0.2,0.8C206.5,51.4,206.3,51.2,206,51z"/>
				<path fill="#F5EEE3" d="M204.5,50.3c0.6-0.1,0.8,0.2,1,0.7C205.2,50.8,204.7,50.7,204.5,50.3z"/>
				<path fill="#FBF9F6" d="M208.7,53c0.4,0.1,0.8,0.2,1,0.7C209.2,53.6,208.9,53.4,208.7,53z"/>
				<path fill="#F4EDE2" d="M252,61.9c0.3-0.1,0.7-0.2,0.8,0.2C252.6,62.3,252.3,62.2,252,61.9z"/>
				<path fill="#F9F9F9" d="M252,47.3c-1.1-0.1-2.3,0.1-3.4-0.4c1.2-0.6,2.6-0.3,3.7-1.2c0.3,0.4-0.8,0.9,0.1,1.2
					C252.2,47,252.1,47.2,252,47.3z"/>
				<path fill="#F8F8F8" d="M241,50.6c1.4,0.2,2.8,0.4,4.1,1c-0.1,0.4-0.4,0.4-0.7,0.2c-0.9-0.6-1.9,0.2-2.9-0.2
					C241,51.3,240.8,51.2,241,50.6z"/>
				<path fill="#FAFAFA" d="M233.8,45.5c-1-0.1-2,0-2.9-0.4c0.4-0.3,0.5-1.1,1.3-0.5c0.1,0.1,0.2,0.1,0.3-0.1c0.1-1,0.6-0.7,1.1-0.4
					c0.1,0.1,0.4-0.1,0.6-0.1C233.4,44.5,232.8,44.8,233.8,45.5z"/>
				<path fill="#FAF9F9" d="M221.2,45.8c1,0.1,2,0.4,3,0.8c-0.2,0.4-0.1,1.2-0.9,0.4c-0.3-0.3-0.5,0.6-1.1,0.3
					C221.6,46.9,221.5,46.3,221.2,45.8z"/>
				<path fill="#F6F5F5" d="M235,49.1c0.9,0.2,1.9,0.2,2.7,0.7c-0.5,1-1.5,0.4-1.9,0.1c-0.7-0.6-0.7-0.1-0.9,0.3
					C234.5,49.8,235,49.4,235,49.1z"/>
				<path fill="#F7F7F6" d="M242.1,46.3c-1,0.1-2,0.2-2.9-0.3c0.7-0.3,1.5-0.1,2.2-0.6C241.7,45.2,242.1,45.8,242.1,46.3z"/>
				<path fill="#FAFAFA" d="M237.4,45.8c-1,0.2-2,0.1-2.9-0.3c1-0.7,2-0.2,3-0.2C237.7,45.3,237.4,45.6,237.4,45.8z"/>
				<path fill="#F9F9F9" d="M248.7,52.4c1.2,0.1,2.4,0.4,3.6,0.8c-1.3,0.4-2.4-0.3-3.7-0.3C248.4,52.8,248.6,52.6,248.7,52.4z"/>
				<path fill="#FAF9F9" d="M224.6,46.7c1-0.2,1.9,0.3,2.8,0.6c-0.4,0.6-0.9,0.6-1.4,0.2C225.5,47.1,225,47,224.6,46.7z"/>
				<path fill="#F9F9F8" d="M223.3,44.4c-0.8,0.1-1.5,0.1-2.2-0.3c0.2-0.3,0.6-0.3,0.9-0.4c0.4-0.1,1.2-0.8,0.9,0.5
					C222.9,44.2,223.2,44.3,223.3,44.4z"/>
				<path fill="#F7F7F7" d="M258.1,48.1c-0.6-0.2-1.4,0.2-1.9-0.3C257.2,46.9,257.6,47,258.1,48.1z"/>
				<path fill="#F6F5F5" d="M219.9,44c-0.8,0.1-1.6,0-2.4-0.2C218.3,43.2,219.1,43.4,219.9,44z"/>
				<path fill="#F9F9F8" d="M224.9,44.6c-0.6,0.1-1.1,0-1.6-0.2C223.9,43.9,224.5,43.4,224.9,44.6z"/>
				<path fill="#F8F7F7" d="M218.8,45.3c0.5-0.1,1.1,0,1.6,0.3C220,46.2,219.3,46,218.8,45.3z"/>
				<path fill="#F7F7F6" d="M243.1,46.5c-0.4,0-0.8,0.1-1.1-0.2c0.1,0,0.1-0.1,0.2-0.1c0.3-0.3,0.3-0.7,0.8-0.7
					C242.8,45.8,243.3,46.1,243.1,46.5z"/>
				<path fill="#F7F7F7" d="M256.2,47.7c-0.3,0.1-0.5,0.1-0.7,0c0.4-0.5,0.8-1.1,1.7-1.2C256.9,47.1,256,47.1,256.2,47.7z"/>
				<path fill="#F9F9F9" d="M255.4,47.7c-0.4,0.1-0.7,0-1.1-0.1c0.2-0.2,0.2-0.6,0.6-0.6C255.4,47,255.3,47.5,255.4,47.7z"/>
				<path fill="#F6F5F5" d="M217.3,43.8c-0.5,0.1-1.1,0-1.6,0.3C216,43.2,216.6,43.3,217.3,43.8z"/>
				<path fill="#FCFCFB" d="M193,38.9c-0.3-0.8,0.1-1.1,0.9-1.2c0.1,0.3-0.2,0.4-0.3,0.6C192.9,38.1,193.3,38.8,193,38.9z"/>
				<path fill="#F8F7F7" d="M215.7,44.1c0.2,0.6,0.8,0.3,1.1,0.6C215.6,45,215.6,45,215.7,44.1z"/>
				<path fill="#F9F9F9" d="M254.2,47.6c-0.4,0.1-0.7,0-1.1-0.1C253.6,46.6,253.8,47.3,254.2,47.6z"/>
				<path fill="#F0F0F0" d="M245.9,51.8c0.5-0.3,1-0.1,1.4,0.3C246.8,52.1,246.3,52.3,245.9,51.8z"/>
				<path fill="#F5F4F3" d="M248.7,52.4c0,0.2-0.1,0.4-0.1,0.5c-0.3-0.1-0.6,0.2-0.9-0.1c0.2-0.3,0.5-0.3,0.8-0.4
					C248.6,52.4,248.6,52.4,248.7,52.4z"/>
				<path fill="#FAF9F9" d="M224.8,48c0.4-0.2,0.7-0.4,0.8,0c0.1,0.5-0.2,0.2-0.4,0.2C225.2,48.1,225.1,48,224.8,48z"/>
				<path fill="#F8F8F8" d="M244.5,51.8c-0.3,0.4-0.4,0.7-0.9,0.6C243.8,51.9,244.2,51.9,244.5,51.8z"/>
				<path fill="#F9F9F9" d="M253.1,47.5c-0.4,0.1-0.7,0.1-1.1-0.1C252.4,47.1,252.8,47.1,253.1,47.5z"/>
				<path fill="#F8F7F7" d="M218.6,46.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.2
					C218.3,46.1,218.4,46.1,218.6,46.2z"/>
				<path fill="#F8F7F7" d="M217.4,45c0.4-0.1,0.7-0.1,1,0.2C218.1,45.3,217.7,45.4,217.4,45z"/>
				<path fill="#FBFAF9" d="M196.3,37.6c-0.2,0.4-0.5,0.5-0.9,0.4C195.6,37.7,196,37.7,196.3,37.6L196.3,37.6z"/>
				<path fill="#FEFEFE" d="M224,35.3c0.5-0.6,0.9-0.4,1.4-0.3C225,35.3,224.6,35.4,224,35.3z"/>
				<path fill="#FEFEFE" d="M220,35.8c-0.5,0.2-1,0.3-1.5,0.5C218.9,36,219.3,35.6,220,35.8L220,35.8z"/>
			</g>
		</g>
	</g>
	<g id="arm">
		<g id="YVtFde_1_">
			<g>
				<path fill="#BFBFC2" d="M256.9,201.3c-0.2-0.4-0.5-0.8-0.7-1.2c-0.2-0.5,0-0.7,0.5-0.7c0.2,0,0.4,0.2,0.4-0.2
					c0-0.3-0.1-0.6-0.3-0.9c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.3-0.6-0.5-0.8c-0.1-0.2-0.2-0.4-0.3-0.6c-0.7-1.2-0.7-1.2,0.7-1.5
					c-0.5-0.4-1.1-0.3-1.4-0.7c-0.1-0.3-0.1-0.6,0-0.9c0.1-0.3,0.3-0.5,0.6-0.7c0.3-0.2,0.7-0.3,1.1-0.3c0.4,0,0.2-0.3,0.3-0.5
					c0-0.2,0.1-0.3,0.3-0.4c0.4-0.2,0.6-0.6,0.7-1c0.8-0.7,1.4-1.6,2.4-2.1c0.3,0,0.3-0.1,0.3-0.3c0.4-0.8,1-1.4,1.7-1.8
					c0.5,0.2,0.5-0.2,0.6-0.5c0.2-0.1,0.3-0.1,0.5-0.1c0.3,0.1,0.7,0.2,0.9,0.5c0.3,0.3,0.6,0.6,0.3,1.1c-0.9,0-1.3,0.8-2,1.2
					c-0.6-0.2-1-0.2-0.9,0.6c0.1,0-0.1,0.4,0.2,0.2c0.1-0.1,0.1-0.3,0.2-0.4c0.4-0.2,0.7-0.1,1.1,0c0.4,0.1,0.7,0.4,1.1,0.4
					c0.1,0,0.2,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.3-0.4-0.4-0.6c-0.2-0.3-0.1-0.6,0.3-0.7
					c0.3-0.1,0.2-0.4,0.3-0.6c0-0.1,0.1-0.2,0.2-0.3c0.4-0.1,0.7,0.2,1,0.1c0.1,0,0.2-0.1,0.3-0.1c0.3-0.3,0.5-0.7,1-0.5
					c0.2,0,0.2-0.1,0.1-0.3c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.1,0-0.2,0.1-0.3c0.1-0.4,0.4-0.9-0.1-1.3c-0.1-0.1-0.1-0.1-0.2-0.2
					c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.4,0.2-0.7,1.4-1.2,0.1c0.3-0.9,1-1.5,1.7-2.1c0.6,0,0.5-0.6,0.7-0.9
					c0.1-0.1,0.2-0.2,0.4-0.3c0.5,0,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.5,0.5-0.6c0.3-0.1,0.4-0.3,0.4-0.5c0.1-0.4,0.3-0.7,0.6-0.9
					c0.2-0.1,0.4,0,0.5,0.2c0,0.1,0,0.2,0,0.3c0.1,0.2,0.2,0.2,0.4,0.1c0.2-0.3-0.2-0.2-0.2-0.4c-0.1-0.2-0.3-0.4-0.2-0.7
					c0-0.3,0.1-0.5,0.3-0.8c0.1-0.3,0.3-0.6,0.5-0.8c0.3-0.6,0.5-1.2,1.1-1.6c0.2-0.2,0.6,0,0.7-0.4c0.3-0.2,0-0.4-0.1-0.6
					c0-0.7,0.4-1.1,0.9-1.5c0,0,0.1,0,0.1,0.1c0.5,0.5,0.6,1.7,1.5,1.4c0.9-0.2,1.4-1.1,1.6-2.1c0.2-0.9,1-1.6,1.4-2.4
					c2.8-5.2,5.7-10.3,8.5-15.5c0.5-0.1,0.4,0.2,0.4,0.5c0.3-0.3-0.3-0.6-0.1-0.9c4.2-7.9,8.7-15.6,14-22.8c3.4-4.6,6.9-9.2,11-13.3
					c2.7-2.7,5.5-5.5,8.6-7.8c1-0.8,2-1.6,3-2.4c0.5-0.4,1-0.5,1.5-0.2c1.6,0.7,2.9,1.7,3.5,3.5c1.1,3.5,2.3,7,3.3,10.5
					c0.6,2.4,0.6,4.8,0.4,7.2c-0.3,3.1-0.5,6.3-1.2,9.3c-0.6,2.5-1.2,5-1.9,7.5c-1.5,5.8-3.9,11.1-7.5,15.9
					c-2.8,3.7-5.4,7.6-8.3,11.2c-3.6,4.5-7.2,8.9-10.5,13.5c-2.6,3.6-5.2,7.3-8,10.7c-3.2,3.8-6.1,7.8-10.1,10.8
					c-4.7,3.4-9.8,4.6-15.5,3.4c-3.6-0.8-6.4-2.7-8.9-5.2c-0.7-0.7-1.6-1.3-2.2-2.2C258.1,201.4,257.4,201.5,256.9,201.3z"/>
				<path fill="#DDB6A7" d="M267.9,180.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.5,0.2-0.3,0.8-0.7,1c-0.6,0.6-1.1,1.3-1.7,1.9
					c-0.5,0.8-1,1.4-1.6,2.1c-0.1,0.1-0.2,0.3-0.4,0.3c-0.1,0-0.3,0-0.4,0c-0.2,0.2-0.3,0.4-0.5,0.5c-0.5,0.6-1.1,1.3-1.6,1.9
					c0,0.1-0.1,0.2-0.2,0.2c-0.8,0.7-1.6,1.4-2.4,2.2c-0.3,0.3-0.4,0.7-0.7,0.9c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.1
					c-0.2,0-0.3,0.2-0.5,0.3c-0.3,0.2-0.5,0.4-0.7,0.5c-0.2,0.2-0.3,0.3-0.5,0.5c-0.2,0.2-0.2,0.6-0.5,0.8c-0.5,0.3-0.9,0.6-1.5,0.8
					c-0.2,0.1-0.5,0-0.6-0.2c-3.6-6.5-5.8-13.4-7.7-20.5c-2.2-8.2-3.6-16.6-5.3-24.9c-1.3-6.7-2.2-13.5-3.3-20.3
					c-0.8-5.1-1.6-10.2-2.4-15.3c-0.2-1.5-0.3-3.1-0.5-4.6c-0.1-0.7-0.3-1.4-0.6-2.1c-2.3-4.5-4.5-9-6.1-13.7
					c-1.1-3.1-1.7-6.2-2.1-9.4c-0.4-4-0.9-7.9-1-11.9c-0.1-1.6,0.3-2.8,1.9-3.5c0.3-0.1,0.3-0.4,0.3-0.7c0.1-2.2,0.7-4.1,2.6-5.4
					c0.5-0.3,0.7-1,1.1-1.6c1.1-1.7,2.4-1.9,3.9-0.6c0.4,0.3,0.6,0.7,1.2,0.6c1.7-0.3,2.5,0.8,3.1,2.1c0.7,1.8,1.1,3.7,1.4,5.5
					c0.9,5.4,2.1,10.7,4.5,15.7c0.3,0.6,0.5,1.1,1,1.6c0.4-0.4,0.2-0.8,0.2-1.2c0.1-2.8-0.2-5.7,0.2-8.5c0.2-1.4,0.6-2.6,1-3.9
					c0.1-0.4,0.3-1,0.9-0.9c0.5,0.1,0.7,0.6,0.8,1.1c0.1,1.3,0.4,2.6,0.7,3.9c0.7,3.7,1.6,7.5,2.3,11.2c0.6,3.3,1.4,6.6,1.3,9.9
					c0,2.4-0.1,4.8-0.7,7.1c-0.8,3.2,0.1,6.2,1.2,9.2c1.8,5.1,3.2,10.3,4.7,15.5c0.8,2.8,1.8,5.6,2.7,8.4c1.4,4.2,2.9,8.4,4.4,12.5
					c1.4,3.8,3.1,7.4,4.5,11.2c1.4,3.5,3.4,6.8,4.9,10.2c0,0.7-0.7,1-0.7,1.7c0,0.2-0.2,0.4-0.1,0.6c0,0.3-0.4,0.2-0.6,0.4
					c-0.3,0.6-0.7,1.1-1,1.7c-0.3,0.2-0.5,0.4-0.5,0.7c-0.1,0.2-0.2,0.5-0.2,0.7c-0.2,0.2-0.3,0.4-0.5,0.5c-0.1,0.3-0.5,0.5-0.5,0.9
					c-0.2,0.2-0.2,0.4-0.5,0.5c-0.1,0.2-0.3,0.3-0.4,0.5C268.2,179.9,268.2,180.3,267.9,180.4z"/>
				<path fill="#C0C0C3" d="M252.6,194.8c0.2,0,0.4,0,0.7,0c0.5-0.2,1-0.2,1.5-0.2c0.2,0,0.3,0,0.5,0.1c-0.1,1,0.2,1.9,1,2.5
					c0.2,0.2,0.4,0.5,0.7,0.6c0,0.1,0,0.2,0,0.2c0.3,0.5-0.1,0.7-0.4,0.9c-0.3,0.2-0.7,0.4-1.1,0c0-0.1,0-0.1,0-0.2
					c-0.3-0.3-0.4-0.7-0.7-1c-0.2-0.2-0.3-0.5-0.5-0.7c-0.1-0.3-0.3-0.5-0.5-0.7C253.4,195.9,253,195.4,252.6,194.8z"/>
				<path fill="#F1F0F0" d="M255.5,199.1c0.4,0,0.7-0.1,1-0.3c0.5-0.2,0.8,0.1,0.8,0.5c0,0.3-0.2,0.4-0.5,0.2
					c-0.5-0.2-0.4,0.2-0.5,0.5C255.8,199.9,255.5,199.6,255.5,199.1z"/>
				<path fill="#E9E8E8" d="M256.9,201.3c0.1,0,0.2,0,0.2,0c0.4,0.1,0.7-0.8,1-0.3c0.2,0.3-0.2,0.8-0.3,1.3
					C257.6,201.9,257.1,201.7,256.9,201.3z"/>
				<path fill="#CBCACC" d="M286.9,152.6c0.4,0.4,0.3,0.9,0.2,1.5c-0.3-0.4,0-0.8-0.4-1.1C286.7,152.9,286.8,152.7,286.9,152.6z"/>
				<path fill="#C9C8CB" d="M253.7,196.5c0.3,0.1,1,0,0.5,0.7C253.9,197.1,253.8,196.8,253.7,196.5z"/>
				<path fill="#CCCBCD" d="M254.7,197.9c0.5,0.2,0.6,0.6,0.7,1C254.9,198.8,254.8,198.4,254.7,197.9z"/>
				<path fill="#C4C4C6" d="M263.4,185.5c0.7-0.6,1.1-1.5,1.9-1.9c0.1,0.3,0.2,0.6,0.4,0.2c0.2-0.3,0.3-0.6,0.8-0.6
					c0.2,0.2-0.5,0.7,0.2,0.7c0,0.1,0,0.2,0,0.3l0,0.1l0-0.1c1.3,0.4,0.5,1.1,0.3,1.7c0,0.4,0.3,0.5,0.5,0.7
					c-0.1,0.2-0.3,0.3-0.6,0.3c-0.7,0-0.6,0.7-0.9,1l0,0c-0.5,0.2-0.7-0.7-1.2-0.2l0,0c-0.1,0.1-0.2,0.1-0.2,0
					c0.1-0.5-0.2-0.7-0.5-1c-0.1-0.2,0.6,0,0.2-0.3C264,185.9,263.6,185.7,263.4,185.5z"/>
				<path fill="#CACACC" d="M264.6,187.4c0.1,0,0.2,0,0.2,0c0.2,0.5,0.6,1.1-0.5,1c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.7
					c-0.5,0.5-0.6-0.2-1-0.2c-0.2-0.4-0.8-0.4-0.7-1C263,187.4,263.5,186.8,264.6,187.4z"/>
				<path fill="#C6C6C8" d="M256.2,197.5c-1-0.6-1.4-1.5-0.9-2.6c-0.1-0.2-0.1-0.3,0-0.5c0.4,0.1,0.8,0.2,1.3,0.2
					c0.2,0,0.5,0,0.5,0.3c0,0.3-0.1,0.6-0.5,0.6c-0.3-0.1-0.5,0.2-0.8,0.2c-0.1,0-0.3-0.3-0.3,0c0,0.1,0.1,0.3,0.1,0.3
					c0.8,0.1,0.6,0.8,0.8,1.3C256.4,197.3,256.3,197.4,256.2,197.5z"/>
				<path fill="#E5E4E5" d="M262.7,188.3c0.2,0.3,0.8,0.4,0.7,1c-0.4-0.1-0.8-0.2-1.2-0.2c-0.2,0.2-0.4,0.5-0.7,0.2
					c0-0.4-0.2-1,0.2-1.3C262.1,187.7,262.3,188.2,262.7,188.3z"/>
				<path fill="#C8C8CA" d="M260.2,191.4c0.2,0.3,0.5,0.3,0.7,0.3c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.1
					c-0.3,0.1-0.6,0.3-0.8,0.5c-0.5-0.1-0.7,0.3-1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1-0.1-0.4,0-0.5-0.2c-0.1-0.1-0.2-0.2-0.2-0.2
					c0.1-0.5,0.8-0.6,0.7-1.3c0.1-0.6,0.5-0.5,0.9-0.4C260.1,191.3,260.2,191.4,260.2,191.4z"/>
				<path fill="#EAE9EA" d="M264.1,191.2c-0.6,0.5-0.9-0.5-1.5-0.2c-0.2,0.4,0.2,0.3,0.3,0.4c0.3,0.3,0.1,0.6-0.1,0.7
					c-0.3,0.2-0.3-0.2-0.4-0.3c-0.1-0.4-0.4-0.9,0-1.2C263,190.4,263.5,190.9,264.1,191.2z"/>
				<path fill="#E2E0E1" d="M276.6,174.4c-0.3-0.3-1-0.3-0.6-0.9c0.1-0.2,0.2-0.7,0.6-0.5C277.3,173.3,276.5,173.9,276.6,174.4z"/>
				<path fill="#F8F8F9" d="M263.4,185.5c0.5,0.4,1.4,0,1.6,1c-0.3,0-0.6-0.1-0.9-0.1c-0.3-0.2-0.5-0.3-0.8-0.5
					C263.4,185.8,263.4,185.6,263.4,185.5z"/>
				<path fill="#EAE8E9" d="M269.3,188.3c-0.1,0.3-0.3,0.3-0.4,0.2c-0.3-0.1-0.7-0.3-0.7-0.7c0-0.3,0.3-0.4,0.5-0.4
					C269,187.5,269.1,188,269.3,188.3z"/>
				<path fill="#DFDEDF" d="M264.4,200.4c0,0.2-0.2,0.3-0.3,0.3c-0.5-0.1-0.8-0.4-0.7-0.9c0-0.2,0.1-0.4,0.4-0.3
					C264.2,199.6,264.1,200.1,264.4,200.4z"/>
				<path fill="#C8C7CA" d="M277.8,175.7c0.3-0.7,1-0.6,1.5-0.7c0.1,0,0.2,0.2,0.3,0.3c0,0-0.2,0.2-0.2,0.2
					C278.9,175.4,278.4,175.9,277.8,175.7z"/>
				<path fill="#C7C7C9" d="M270.8,186.7c0-1.2,0.8-0.5,1.3-0.5c-0.3,0.3-0.6,0.5-0.8,0.8C271,187,270.8,187.1,270.8,186.7z"/>
				<path fill="#DFDEE0" d="M262.1,195.3c-0.8,0.2-1.1-0.1-1-1.1C261.5,194.7,261.7,195.2,262.1,195.3z"/>
				<path fill="#D5D5D6" d="M274.4,177c-0.2,0.3-0.4,0.7-0.8,0.7c-0.2,0-0.3-0.1-0.2-0.3c0.2-0.3,0.4-0.7,0.8-0.8
					C274.4,176.6,274.4,176.8,274.4,177z"/>
				<path fill="#C7C6C9" d="M275.7,176.7c-0.2-0.9,0.9-1.2,0.4-1.9C276.6,175.5,276.4,176.1,275.7,176.7z"/>
				<path fill="#CECED0" d="M268.1,197.2c0.3,0.3,0.6,0.7,0.4,1.1c0,0.1-0.3,0.1-0.4,0C267.4,197.9,268,197.6,268.1,197.2z"/>
				<path fill="#CCCBCD" d="M256.7,199.6c0.2,0,0.4,0,0.4-0.3c-0.1-0.3-0.4-0.4-0.6-0.4c0.2-0.2,0.5-0.3,0.5-0.7
					c0.6,0.3,0.5,0.9,0.5,1.3C257.4,200.1,257,199.9,256.7,199.6z"/>
				<path fill="#E6E4E5" d="M266,187.6c-0.1-0.6,0.1-1,0.7-1.2c0.2,0.2,0.8,0.2,0.5,0.7c-0.2,0-0.6-0.5-0.6,0.1
					C266.6,187.8,266.2,187.5,266,187.6z"/>
				<path fill="#E6E5E6" d="M260,191.4c-0.2,0.1-0.7-0.4-0.7,0.2c-0.2,0.1-0.3,0.1-0.5,0c-0.1-0.8,0.1-1.2,1-0.7
					C259.9,191.1,260.2,191.2,260,191.4z"/>
				<path fill="#C6C6C8" d="M266.5,198.3c0.7-0.2,0.9,0.1,1.1,0.5c0,0-0.1,0.3-0.2,0.3C267,199.1,266.8,198.7,266.5,198.3z"/>
				<path fill="#D1D0D2" d="M261.2,200.1c-0.6-0.1-0.2-0.8-0.8-1.1C261.9,198.8,261.3,199.6,261.2,200.1L261.2,200.1z"/>
				<path fill="#F4F3F4" d="M258.6,193.1c0.2,0,0.4,0,0.5,0.2c-0.3,0.4-0.6,0.8-1.2,0.5c-0.1-0.2-0.1-0.3,0-0.5
					C258.1,193.3,258.3,193.2,258.6,193.1z"/>
				<path fill="#EAE8E9" d="M264.6,189.5c0.3-0.1,0.6-0.5,0.9,0c0.1,0.2,0,0.5-0.2,0.5C264.9,190.2,264.6,190,264.6,189.5
					L264.6,189.5z"/>
				<path fill="#D6D5D7" d="M261.5,189.3c0.2-0.1,0.5-0.1,0.7-0.2c-0.1,0.4,0.5,0.9-0.2,1.1C261.6,190.2,261.7,189.5,261.5,189.3z"
					/>
				<path fill="#EAE9EA" d="M262.4,186.4c0.1-0.3,0.2-0.5,0.5-0.5c0,0.3,0.6,0.5,0.3,0.8C262.8,187.1,262.6,186.7,262.4,186.4z"/>
				<path fill="#E6DFDD" d="M255.7,192.9c0.1-0.3,0.4-0.5,0.7-0.7c0.2,0,0.4-0.1,0.5-0.2c0.1,0,0.1,0,0.2,0c0,0.4,0.3,0.8-0.4,0.7
					C256.4,192.7,256.1,192.8,255.7,192.9z"/>
				<path fill="#DFDEDF" d="M264.8,187.4c0.3-0.5,0.3-0.5,1.2,0.2C265.5,188.1,265.2,187.4,264.8,187.4z"/>
				<path fill="#DCD7D7" d="M255.2,194.3c0,0.2,0,0.3,0,0.5c-0.2,0-0.3,0-0.5,0c-0.2-0.2-0.2-0.5,0-0.7c0-0.3,0.2-0.6,0.5-0.7
					C255.2,193.7,255.2,194,255.2,194.3z"/>
				<path fill="#D5D4D5" d="M276,187.5c-0.9,0.2-0.9-0.3-0.9-1C275.5,186.9,275.7,187.2,276,187.5z"/>
				<path fill="#EBE7E7" d="M267,181.6c0.3-0.3-0.1-1.1,0.7-1C267.3,180.8,268.2,182,267,181.6z"/>
				<path fill="#DBD6D6" d="M267.9,180.4c0-0.4,0-0.7,0.5-0.7c-0.1,0.3,0.4,0.7,0.1,1C268.2,180.9,268.1,180.5,267.9,180.4z"/>
				<path fill="#D9D8D9" d="M258.8,191.7c0.2,0,0.3,0,0.5,0c-0.2,0.5-0.4,1-1,1.2C257.9,192.2,259.1,192.3,258.8,191.7z"/>
				<path fill="#C5C5C7" d="M293.6,152.7c0,0.2-0.2,0.4-0.4,0.3c-0.2,0-0.4-0.1-0.4-0.4c0-0.1,0.2-0.2,0.3-0.3
					C293.4,152.3,293.5,152.5,293.6,152.7z"/>
				<path fill="#DCDADC" d="M257.9,193.4c0,0.2,0,0.3,0,0.5c-0.4-0.1-0.8,0.2-1.1-0.2C257.1,193.2,257.5,193.4,257.9,193.4z"/>
				<path fill="#F4F3F4" d="M267.2,187.1c0.1-0.4-0.3-0.5-0.5-0.7c0.2,0,0.5,0,0.7,0c0.3,0.2,0.3,0.5,0.1,0.8
					C267.5,187.4,267.3,187.3,267.2,187.1z"/>
				<path fill="#E7E6E7" d="M263,194c-0.5-0.5-0.4-0.8,0.2-1.1C263.2,193.3,263.2,193.6,263,194z"/>
				<path fill="#CCC9CA" d="M272.5,173.7c-0.3-0.3,0.1-0.4,0.2-0.5c0.2-0.1,0.5-0.3,0.6,0.1c0.1,0.4-0.3,0.4-0.6,0.4
					C272.7,173.8,272.6,173.8,272.5,173.7z"/>
				<path fill="#C6C6C8" d="M277.8,188.8c-0.5-0.4-0.8-0.6-0.5-1.1C277.6,187.9,277.6,188.3,277.8,188.8z"/>
				<path fill="#E0DFE0" d="M259.3,193.1c0.1-0.5,0.2-1,0.9-0.7c0.1,0,0.2,0.1,0.2,0.2C260.2,193,259.6,192.7,259.3,193.1z"/>
				<path fill="#C9C8CB" d="M273.4,182.3c-0.4-0.3-0.6-0.7-0.2-1.2C273.3,181.6,273.4,181.9,273.4,182.3z"/>
				<path fill="#EAE9EA" d="M259.8,196.1c0.1-0.5,0.4-0.6,0.8-0.6c0,0,0.1,0.2,0.1,0.3C260.6,196.1,260.2,196,259.8,196.1z"/>
				<path fill="#CCCCCE" d="M269.4,184.8c-0.1-0.1-0.3-0.3-0.3-0.4c0-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0.1,0.2,0.3
					C269.7,184.5,269.7,184.8,269.4,184.8z"/>
				<path fill="#EBEAEA" d="M259.1,194.5c0.4,0.1,0.7,0.2,0.7,0.7c0,0.1-0.2,0.2-0.3,0.1C259.1,195.3,259.4,194.8,259.1,194.5z"/>
				<path fill="#E9E8E9" d="M276.2,179.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.2-0.3c0-0.2,0.2-0.3,0.3-0.3
					C276.2,179.2,276.3,179.4,276.2,179.6z"/>
				<path fill="#E3E2E3" d="M260,191.4c-0.1-0.2-0.2-0.3-0.2-0.5c0.3-0.1,0.3-0.7,0.7-0.3c0.3,0.3-0.4,0.4-0.2,0.8c0,0-0.1,0-0.1,0
					L260,191.4z"/>
				<path fill="#E2E1E1" d="M272.6,190.1c-0.1,0.2-0.3,0.2-0.5,0.2c-0.2,0-0.3-0.2-0.2-0.3c0.1-0.1,0.3-0.1,0.4-0.1
					C272.5,189.8,272.6,189.9,272.6,190.1z"/>
				<path fill="#F5F4F4" d="M258.8,197.4c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.1,0.2-0.3,0.3-0.3
					C258.8,197,258.8,197.2,258.8,197.4z"/>
				<path fill="#DBDADC" d="M266,191c0.4,0,0.8,0.1,1.3,0.6C266.7,191.5,266.2,191.5,266,191L266,191z"/>
				<path fill="#D1D0D2" d="M273.2,180.3c0.2-0.5,0.5-0.6,0.9-0.7C274,180.1,273.7,180.2,273.2,180.3z"/>
				<path fill="#CBCACC" d="M272.5,173.7c0.1,0,0.2,0,0.2,0c0.1,0.2,0.5,0.3,0.3,0.5c-0.3,0.3-0.7-0.2-1,0
					C272.2,174,272.3,173.9,272.5,173.7z"/>
				<path fill="#DDDCDD" d="M261,191.9c0-0.1,0-0.2,0-0.2c0.5,0,0.6,0.5,0.8,0.8c-0.4,0.1-0.5-0.3-0.8-0.3
					C260.9,192.1,260.9,192.1,261,191.9C260.9,192,261,191.9,261,191.9z"/>
				<path fill="#C8C7C9" d="M262.1,202.6c-0.7,0.1-0.8-0.1-1.1-0.4C261.5,202,261.7,202.3,262.1,202.6z"/>
				<path fill="#CBCACD" d="M268.1,193.2c0,0.3,0,0.4-0.2,0.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0-0.3,0.2-0.3
					C267.9,193,268,193.1,268.1,193.2z"/>
				<path fill="#ECEBEB" d="M266.7,183.8c-0.2,0-0.5,0-0.5-0.1c-0.2-0.3,0.2-0.4,0.3-0.6C267.2,183.1,266.9,183.4,266.7,183.8z"/>
				<path fill="#C9C8CA" d="M262.4,197.8c0,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.3-0.1-0.3-0.2c0-0.3,0.2-0.2,0.4-0.3
					C262.2,197.6,262.4,197.6,262.4,197.8z"/>
				<path fill="#C6C5C8" d="M267.4,200.8c0,0.2,0,0.3-0.2,0.3c-0.2,0-0.2-0.2-0.2-0.3c0-0.2,0-0.3,0.2-0.3
					C267.4,200.5,267.4,200.7,267.4,200.8z"/>
				<path fill="#DBDADB" d="M261.2,201.1c0,0.2-0.1,0.5-0.4,0.4c-0.1,0-0.2-0.2-0.2-0.2c0.1-0.3,0.4-0.3,0.6-0.5
					C261.3,200.8,261.3,200.9,261.2,201.1C261.3,201,261.2,201.1,261.2,201.1z"/>
				<path fill="#E5E4E4" d="M273,178.7c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.2-0.2c0-0.1,0.1-0.3,0.3-0.3
					C272.9,178.3,272.9,178.5,273,178.7z"/>
				<path fill="#D8D8D9" d="M270,181.6c0.1,0.1,0.3,0.2,0.2,0.2c0,0.1-0.1,0.3-0.2,0.3c-0.1,0.1-0.3,0-0.3-0.2
					C269.7,181.9,269.9,181.8,270,181.6z"/>
				<path fill="#D2D2D4" d="M268.8,186.5c0,0.1,0,0.3-0.2,0.2c-0.2,0-0.4-0.1-0.4-0.4c0-0.1,0.1-0.2,0.2-0.2
					C268.7,186.1,268.7,186.4,268.8,186.5z"/>
				<path fill="#E0D3CF" d="M257.4,191.7c0-0.5,0.3-0.7,0.7-0.9C258,191.2,258,191.6,257.4,191.7z"/>
				<path fill="#CECDCF" d="M260.3,193.6c0.1-0.2,0.2-0.3,0.3-0.3c0.2-0.1,0.3,0,0.3,0.2c0,0.1-0.2,0.2-0.3,0.3
					C260.5,193.8,260.4,193.7,260.3,193.6z"/>
				<path fill="#DFDADA" d="M269.9,177.8c0-0.3,0.2-0.5,0.5-0.5c0.1,0.2,0.2,0.5,0.2,0.7c-0.1,0.2-0.2,0.4-0.5,0.2
					C270.1,178.1,270.1,177.9,269.9,177.8z"/>
				<path fill="#DCDBDC" d="M271,180.1c-0.1,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.4,0-0.2-0.3c0.1-0.1,0.3-0.2,0.4-0.2
					C270.8,180,270.9,180.1,271,180.1z"/>
				<path fill="#E0DFE0" d="M272.2,179.9c0.3,0,0.3,0.2,0.2,0.3c0,0.1,0,0.3-0.2,0.3c-0.2,0-0.2-0.2-0.2-0.3
					C272.1,180.1,272.2,180,272.2,179.9z"/>
				<path fill="#ECEAEB" d="M261.1,189.8c0,0.2-0.2,0.2-0.3,0.1c-0.2,0-0.3-0.2-0.2-0.3c0-0.2,0.2-0.2,0.3-0.1
					C261,189.5,261.1,189.6,261.1,189.8z"/>
				<path fill="#E5CEC5" d="M270.6,176.6c-0.4-0.6,0.1-0.6,0.5-0.7C270.9,176.1,270.7,176.3,270.6,176.6z"/>
				<path fill="#EEECEC" d="M270.1,178.2c0.2-0.1,0.3-0.2,0.5-0.2c0.1,0.1,0.5,0.1,0.3,0.5C270.5,178.6,270.2,178.8,270.1,178.2
					C270.1,178.2,270.1,178.2,270.1,178.2z"/>
				<path fill="#CECDD0" d="M272.3,196.8c-0.1,0.1-0.1,0.3-0.2,0.3c-0.2,0.1-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.2-0.2
					C272.1,196.6,272.2,196.7,272.3,196.8z"/>
				<path fill="#CCCBCD" d="M263.8,196.8c-0.1,0.4-0.3,0.6-0.7,0.6C263.1,197,263.3,196.9,263.8,196.8z"/>
				<path fill="#C8C8CA" d="M270.5,196.8c0.2,0,0.3,0.2,0.2,0.3c0,0.1-0.2,0.1-0.2,0.2c-0.1-0.1-0.2-0.3-0.2-0.4
					C270.3,196.9,270.4,196.8,270.5,196.8z"/>
				<path fill="#DBD9DB" d="M256.2,197.5c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0.2,0.3,0.5,0.5,0.7C256.5,197.9,256.3,197.8,256.2,197.5z"/>
				<path fill="#CFCED0" d="M259.8,197.6c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.2,0.2-0.2,0.2c-0.1-0.1-0.2-0.1-0.3-0.3
					C259.7,197.8,259.8,197.7,259.8,197.6z"/>
				<path fill="#EBE9E9" d="M278.5,172.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2-0.1,0.3-0.3,0.2c-0.1,0-0.1-0.2-0.1-0.3
					C278.3,172.2,278.4,172.2,278.5,172.1z"/>
				<path fill="#DADADB" d="M269,199.8c0,0.2-0.2,0.3-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0.1-0.2,0.2-0.2
					C268.8,199.6,268.9,199.6,269,199.8z"/>
				<path fill="#EEECEB" d="M261.2,201.1c0-0.1,0-0.2,0-0.3c0-0.2,0-0.5,0-0.7c0,0,0,0,0,0C261.7,200.4,261.7,200.7,261.2,201.1z"/>
				<path fill="#D3D2D4" d="M264.6,201.9c-0.1,0.1-0.1,0.3-0.2,0.3c-0.2,0.1-0.3-0.1-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2
					C264.4,201.7,264.5,201.8,264.6,201.9z"/>
				<path fill="#D2D1D3" d="M269.8,204.5c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0-0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3
					C269.7,204.2,269.8,204.3,269.8,204.5z"/>
				<path fill="#D4D3D5" d="M270.8,186.7c0.1,0.2,0.3,0.2,0.5,0.2c-0.2,0.3-0.6,0.4-0.8,0.1C270.3,186.8,270.6,186.7,270.8,186.7z"
					/>
				<path fill="#C9C9CB" d="M273.9,181.5c0-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.2,0.1,0.1,0.2
					C274.2,181.4,274.1,181.5,273.9,181.5z"/>
				<path fill="#C9C9CB" d="M270.4,185.5c-0.1,0.1-0.2,0.3-0.4,0.3c0,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.3,0.3-0.3
					C270.1,185.3,270.2,185.4,270.4,185.5z"/>
				<path fill="#D7D4D5" d="M260.5,188.6c0.1-0.1,0.2-0.2,0.2-0.2c0.2,0.2,0.4,0.4,0,0.6C260.6,189,260.5,188.7,260.5,188.6z"/>
				<path fill="#D5D4D6" d="M264.3,189.5c-0.3-0.2-0.3-0.5,0-0.7c0.3,0.2,0.2,0.5,0.2,0.7c0,0,0,0,0,0
					C264.5,189.5,264.4,189.5,264.3,189.5z"/>
				<path fill="#D6D5D6" d="M274.6,190.9c0.1-0.3,0.3-0.6,0.5-0.5c0.4,0.2-0.1,0.3-0.2,0.4C274.8,190.9,274.7,190.9,274.6,190.9z"/>
				<path fill="#E5DFDE" d="M268.9,179.2c0-0.3,0.2-0.5,0.5-0.5C269.3,179,269.6,179.6,268.9,179.2z"/>
				<path fill="#CECED0" d="M274.1,184.3c-0.2-0.1-0.2-0.3-0.2-0.4c0-0.1,0-0.3,0.2-0.2c0.2,0.1,0.2,0.2,0.2,0.4
					C274.3,184.2,274.2,184.3,274.1,184.3z"/>
				<path fill="#DEDDDE" d="M270.1,178.2c0.2,0.3,0.5,0.2,0.7,0.3c-0.3,0.3-0.6,0.4-0.9,0.2C269.8,178.6,269.9,178.4,270.1,178.2z"
					/>
				<path fill="#CECDCF" d="M269.7,183.3c0,0.2-0.1,0.3-0.2,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.3,0.2-0.2
					C269.5,183.1,269.6,183.2,269.7,183.3z"/>
				<path fill="#F4F3F4" d="M261,191.9c0,0.1,0,0.2,0,0.2c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1-0.1-0.2-0.2-0.2-0.2
					C260.3,191.9,260.6,191.9,261,191.9z"/>
				<path fill="#E3E2E3" d="M268.3,192.7c-0.1-0.1-0.3-0.1-0.3-0.2c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.2
					C268.5,192.5,268.4,192.6,268.3,192.7z"/>
				<path fill="#D0CFD1" d="M282.3,178.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.3,0.3-0.2
					C282.2,178.2,282.3,178.3,282.3,178.4z"/>
				<path fill="#DFDDDE" d="M268.4,182.6c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0-0.2-0.2c0-0.1,0.1-0.3,0.2-0.4
					C268.1,182.4,268.3,182.5,268.4,182.6z"/>
				<path fill="#ECEAEA" d="M272.1,184.1c-0.2,0.2-0.3,0.3-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0.2-0.2
					C271.9,184,271.9,184,272.1,184.1z"/>
				<path fill="#D5D4D6" d="M271.5,182.5c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.1
					C271.5,182.3,271.5,182.4,271.5,182.5z"/>
				<path fill="#D4D3D4" d="M259.8,202.8c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
					C259.6,202.9,259.8,202.9,259.8,202.8z"/>
				<path fill="#D6D5D7" d="M261.4,203.8c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.1
					C261.2,203.6,261.2,203.7,261.4,203.8z"/>
				<path fill="#D4D3D5" d="M292.4,154c0,0-0.1,0.1-0.2,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
					C292.4,153.7,292.4,153.8,292.4,154z"/>
				<path fill="#CAC9CC" d="M282.3,168.3c0,0.1-0.1,0.3-0.2,0.2c-0.1,0-0.1-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2
					C282.2,168.1,282.3,168.2,282.3,168.3z"/>
				<path fill="#D0CFD1" d="M272.1,198.8c0-0.2-0.1-0.3-0.1-0.4c0-0.1,0.2-0.1,0.3-0.1c0,0.1,0.1,0.2,0.1,0.2
					C272.4,198.6,272.3,198.7,272.1,198.8z"/>
				<path fill="#CAC9CB" d="M282.1,172.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.2
					C281.9,172.1,281.9,172.2,282.1,172.3z"/>
				<path fill="#D2D2D4" d="M273.4,186.9c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.1c-0.1,0-0.1-0.1-0.2-0.2
					C273.2,187,273.3,186.9,273.4,186.9z"/>
				<path fill="#D8D7D8" d="M281.2,180.9c-0.2-0.2-0.2-0.3-0.2-0.3c0.1-0.1,0.2-0.3,0.3-0.3c0.1-0.1,0.2,0,0.2,0.2
					C281.4,180.6,281.3,180.7,281.2,180.9z"/>
				<path fill="#D2D2D3" d="M278,189.6c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1-0.1-0.2-0.1-0.2-0.2
					C277.8,189.8,277.9,189.7,278,189.6z"/>
				<path fill="#D4D3D5" d="M266.9,197.3c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.2
					C266.8,197.1,266.9,197.1,266.9,197.3z"/>
				<path fill="#D0CFD0" d="M266.4,190.1c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
					C266.3,189.8,266.4,189.9,266.4,190.1z"/>
				<path fill="#E7E5E6" d="M266,191c-0.2,0-0.4,0.3-0.6,0.1c0,0,0-0.2,0-0.2C265.7,190.7,265.9,190.8,266,191
					C266,191,266,191,266,191z"/>
				<path fill="#C9C8CA" d="M275.8,194.9c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.3,0-0.2-0.2
					C275.6,195,275.7,194.9,275.8,194.9z"/>
				<path fill="#D3D2D4" d="M260.2,194.4c-0.1,0-0.3,0.1-0.3,0c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.2-0.1,0.2-0.1
					C260.1,194.1,260.2,194.3,260.2,194.4z"/>
				<path fill="#CECED0" d="M265,193.8c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.1c-0.1,0-0.1-0.1-0.2-0.2
					C264.8,194,264.9,193.9,265,193.8z"/>
				<path fill="#C6C6C8" d="M254.7,194.1c0,0.2,0,0.5,0,0.7c-0.5,0.4-1-0.1-1.4,0C253.7,194.4,254.1,194,254.7,194.1z"/>
				<path fill="#F7F3F2" d="M256.9,191.9c-0.1,0.3-0.1,0.6-0.5,0.2C256.5,192,256.6,191.7,256.9,191.9z"/>
				<path fill="#E7E7E7" d="M266.7,184c0.1,0.2,0.2,0.4,0,0.6c-0.1,0-0.3,0-0.3,0C266.4,184.3,266.5,184.1,266.7,184
					C266.7,184,266.7,184,266.7,184z"/>
				<path fill="#DBDBDC" d="M267,185.7c-0.2-0.1-0.4-0.2-0.4-0.5c0,0,0.1-0.1,0.2-0.1C267.1,185.2,266.9,185.5,267,185.7
					C267,185.7,267,185.7,267,185.7z"/>
			</g>
		</g>
	</g>
</g>
<g id="body_1_">
	<g id="jV5h25_1_">
		<g>
			<path fill="#B14D4A" d="M405.6,300c-0.4,4.8-0.9,9.6-1.2,14.3c-0.2,4.5-0.8,9-0.9,13.6c-0.2,5.5-0.5,11-0.7,16.5
				c-0.2,4.4-0.2,8.9-0.5,13.3c-0.2,2.9-0.2,5.7-0.5,8.6c-0.2,1.8,0.1,3.7,0.1,5.5c-0.1,1.7-0.1,3.3-0.3,5
				c-0.3,3.8-0.5,7.6-0.3,11.4c0.1,2.4-0.2,4.8-0.1,7.1c0.3,5.4-0.2,10.8-0.1,16.2c0.1,5.1,0.1,10.2,0.1,15.3c0,3.2-0.2,6.4-0.1,9.7
				c0.1,4.1,1,8,1.6,12c1.2,7.6,2.7,15.1,4.1,22.7c1.1,6.5,2.1,13.1,3.3,19.6c0.7,3.7,1.3,7.5,1.9,11.2c0.5,3,0.8,6.1,1.4,9
				c0.6,2.9,1,5.9,1.5,8.8c0.5,2.8,1.1,5.5,1.6,8.3c0.7,4,1.5,7.9,2.2,11.9c0.9,5.4,2.1,10.8,3,16.3c0.7,4.6,1.6,9.1,2.6,13.6
				c0.6,2.6,0.3,2.5-1.9,2.6c-0.3,0-0.6,0.1-0.8,0.2c-1.1,0.6-2.4,0.6-3.6,0.7c-4.2,0.6-8.4,1.1-12.6,1.3c-0.4,0-0.9,0.2-1.3-0.2
				c-0.5,0-0.9-0.1-1.4-0.1c-1.2,0-1.7-0.9-2-1.9c-0.6-1.9-1.2-3.8-1.8-5.7c-0.8-2.7-1.6-5.3-2.4-8c-1.7-5.9-3.6-11.8-5.4-17.7
				c-1.6-5.3-3.2-10.6-4.8-15.9c-1.3-4.3-2.4-8.7-3.8-13c-1-3.1-2-6.2-3-9.3c-0.7-2.3-1.1-4.6-1.9-6.9c-0.6-1.8-1.1-3.6-1.6-5.5
				c-1.1-3.9-2.1-7.8-3.2-11.7c-0.8-2.8-1.5-5.6-2.3-8.4c-0.5-1.9-0.9-3.8-1.4-5.7c-0.8-3.3-1.7-6.5-2.5-9.8
				c-0.8-3.4-1.6-6.8-2.3-10.2c-1-4.9-1.9-9.7-2.8-14.6c-0.8-4.7-1.6-9.5-2.3-14.2c-0.7-4.3-1.4-8.5-2-12.8
				c-0.6-4.2-1.3-8.5-1.6-12.7c-0.1-1.5-0.4-3.1-0.6-4.6c-0.5-3.2-1-6.4-1.4-9.6c-0.5-3.6-1.1-7.3-1.5-10.9
				c-0.6-4.9-1.1-9.8-1.7-14.7c-0.4-3.5-0.8-7-1.2-10.5c-0.4-3.7-0.7-7.5-1.1-11.2c-0.1-0.6-0.1-1.1-0.3-1.6
				c-0.1-0.3-0.1-0.9-0.7-0.8c-0.5,0.1-0.7,0.5-0.8,0.9c-0.1,0.9-0.5,1.8-0.7,2.6c-1.3,5.5-2.6,11.1-3.8,16.6
				c-1.5,6.9-2.9,13.8-4.1,20.8c-0.7,4.1-1.6,8.1-1.9,12.2c-0.3,3.2-1.1,6.3-1.5,9.4c-0.6,4.7-1.3,9.4-1.8,14.1
				c-0.3,3.4-0.7,6.9-1.1,10.3c-0.5,5.3-1.5,10.6-1.7,15.9c-0.1,2.7,0.2,5.4,0.1,8.1c-0.2,4.9,0.4,9.8,0.3,14.8
				c0,3.4,0.4,6.8,0.4,10.1c0,2.4,0.1,4.9,0.3,7.3c0.2,3.1,0.3,6.2,0.5,9.3c0.2,2,0.3,3.9,0.4,5.9c0.2,2.7,0.1,5.4,0.3,8.1
				c0.3,4.2,0.5,8.3,0.8,12.5c0.2,3.5,0.8,7,0.9,10.5c0.2,4.3,0.9,8.5,1.2,12.8c0.2,2.7,0.8,5.3,0.9,7.9c0.2,3.6,0.8,7.1,1.3,10.6
				c0.7,5.1,1.1,10.2,1.7,15.3c0.4,3.2,1,6.4,1.3,9.7c0.1,1.5-0.4,2.2-1.9,2.5c-0.4,0.1-0.8,0.1-1.2,0.2c-0.5,0.4-1.1,0.3-1.7,0.3
				c-4.4-0.1-8.8,0.3-13.2,0.3c-0.9,0-1.7-0.4-2.6-0.3c-1,0-2.1,0-3.1,0.1c-1,0.1-1.4-0.3-1.5-1.2c-0.4-3.5-1.7-6.8-2.5-10.3
				c-1.1-5.2-2.4-10.4-3.6-15.7c-1.4-6.3-2.9-12.5-4.1-18.8c-0.8-4-1.6-8-2.3-12c-0.6-3.4-1.5-6.7-2.1-10.1
				c-0.6-3.8-1.8-7.5-2.6-11.2c-0.9-4.3-1.7-8.6-2.5-13c-0.7-3.6-1.5-7.1-2.1-10.7c-0.9-5.2-1.9-10.4-2.5-15.6
				c-0.6-4.6-1.4-9.2-2-13.9c-0.4-3.1-0.6-6.2-0.9-9.3c-0.4-4.9-0.9-9.7-1.2-14.6c-0.3-4.2-0.3-8.4-0.6-12.5
				c-0.4-5.4-0.4-10.8-0.4-16.2c0-4.3-0.1-8.6,0.1-12.8c0.1-2.6-0.1-5.3,0-7.9c0.2-4,0.5-8.1,0.8-12.1c0.3-3.4,0.6-6.8,1.1-10.1
				c0.7-4.7,1.1-9.5,2-14.3c0.8-4,1.4-8.1,2.1-12.2c0.8-4.6,1.7-9.2,2.7-13.8c1.5-7,3.5-14,5.8-20.8c1.6-4.9,3.3-9.7,5.1-14.5
				c1.8-4.6,3.6-9.1,5.3-13.7c0.8-2.2,1.1-4.5,1-6.8c0.3-0.9-0.1-1.8,0.2-2.7c0.5-0.3,1-0.3,1.5-0.2c4.8,0.6,9.6,1,14.5,1.4
				c1.9,0.2,3.7-0.1,5.6,0.4c0.3,0.1,0.7,0.1,1,0c2.8-0.1,5.7,0,8.5,0.3c4.9,0.4,9.9,0.1,14.8,0.2c2.9,0,5.8,0.2,8.6-0.1
				c4.2-0.3,8.4-0.1,12.6-0.5c4.1-0.3,8.2-0.7,12.3-0.9c1,0,1.9-0.2,2.9-0.4c1.1-0.1,2.2-0.3,3.3,0.1c0.3,0.2,0.3,0.4,0.4,0.7
				c0.3,3.6,1.4,6.9,1.9,10.5c0.4,2.8,1.1,5.5,1.5,8.2c0.1,1-0.2,1.9-0.7,2.7c-2.3,4.9-5.2,9.5-8.5,13.7c-0.8,1-1.5,2.2-2.3,3.2
				c-0.9,1.2-0.9,1.6-0.1,2.9c2.1,3.2,4,6.6,5.7,10c0.8,1.6,1.9,3,2.7,4.5c0.5,0.9,1.2,0.8,2-0.2c1.4-1.7,2.9-3.2,4.3-4.9
				c0.3-0.4,0.5-0.9,1.2-0.9c0,0,0,0.1,0,0.1C405.6,299.9,405.6,300,405.6,300z"/>
			<path fill="#BFBFC2" d="M333.8,250.8c-3.2-0.3-6.5-0.5-9.7-0.8c-3.5-0.3-7-0.5-10.5-1.1c-0.5-0.1-1-0.1-1.5-0.1
				c-0.3-1.4,0-2.8-0.1-4.2c0-0.4,0.1-0.9-0.1-1.3c0-4.1-0.1-8.2-0.3-12.3c-0.4-6.3-0.6-12.5-1-18.8c-0.3-3.8-0.3-7.6-0.4-11.4
				c-0.1-2-0.1-4-0.2-5.9c-0.3-0.6,0.2-1.3-0.2-1.9c0.1-3.4-0.3-6.8-0.2-10.1c0-4.4-0.4-8.7-0.6-13.1c-0.1-2.1-0.1-4.2-0.3-6.3
				c-0.2-2,0-4-0.1-6c-0.3-5.3-0.6-10.6-0.9-15.9c-0.1-2.4,0.1-4.8,0.5-7.1c0.5-3.7,2.2-6.9,3.3-10.4c0.6-2.1,1.6-4,2.1-6.1
				c0.2-0.8,0.7-1.5,1.2-2.2c1.4-1.9,2.8-3.8,4.3-5.6c0.6-0.8,1.4-1.5,2.2-2.1c2.2-1.6,4-3.9,6.8-4.8c5.9-1.8,11.9-3.3,18-4
				c3.6-0.4,7.1-1.2,10.7-1.6c0.9-0.1,1.5-0.7,1.6-1.7c0.1-1.1,0.3-2.1,0.5-3.2c0.3-1.5,1-1.7,2.8-1c1.4,0.5,2.3,1.6,3.5,2.4
				c1.7,1.2,3.5,2.3,5.1,3.6c0.7,0.5,1.3,0.6,2.1,0.2c3.2-1.5,6.6-2.6,9.9-4c1.1-0.5,2.3-0.9,3.5-1.1c0.6,0.1,1.2-0.2,1.7-0.4
				c1.2-0.3,1.6,0,1.8,1.3c0.3,1.6,0.2,3.2,0.2,4.9c0,0.8,0.3,1.3,1.1,1.5c1.2,0.3,2.4,0.6,3.6,0.9c3,0.8,6.1,1.4,8.9,2.8
				c0.9,0.5,1.9,1,2.8,1.5c0.3,0.4,0.7,0.7,1.2,0.7c0.8,0.3,1.6,0.6,2.4,1c0.3,0.4,0.6,0.8,1.2,0.7c1.8,1.8,4.4,2.5,6,4.5
				c0.2,0.3,0.3,0.7,0.1,1c-0.5,0.5-0.9,0.1-1.4-0.1c0.3,0.4,0.8,0.2,1.1,0.3c0.4-0.1,0.2-0.9,0.8-0.7c0.1,0.1,0.2,0.2,0.2,0.2
				c0.2,0.8,1,0.8,1.4,1.2c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0.4,0.3,0.9,1,0.7c3.2,3,6.3,6,8.8,9.5c1.6,2.2,3.3,4.4,4.5,6.9
				c0.8,1.6,2,3.2,2.5,5c0.1,0.4-0.2,0.3-0.4,0.3c-0.5-0.1-1-0.2-1.5-0.2c-0.3,0-0.3,0.2-0.3,0.5c0.1,0.8,1.1,1,1.2,1.8
				c0.2,0.4,0.2,1,0.9,1.1c-0.4-0.2-0.7-0.5-0.5-1c0-0.1,0.1-0.2,0.1-0.3c0,0,0.1-0.1,0.1-0.1c0.3-0.2,0.1-1.2,0.9-0.6
				c1,1.4,1.4,3,2.1,4.5c0.1,0.2,0.2,0.5,0.3,0.8c0.2,0.3,0.1,0.7,0.5,1c0.1,1.2,0.8,2.1,1.2,3.2c0.3,0.9,0,2,1.1,2.6
				c0.2,3.5,1.7,6.8,2.2,10.2c0.5,3.6,1.3,7.2,1.6,10.8c0.2,2.5,0.6,5,0.8,7.6c0.2,2.2,0,4.3,0.2,6.5c0.1,1.2,0.3,2.3,0.1,3.5
				c-0.2,2.5-0.2,5.1-0.3,7.6c-0.1,3.7-0.5,7.4-1.1,11c-0.6,4-1.4,7.9-2.1,11.9c-0.6,3.2-1.4,6.3-2.2,9.4c-0.9,3.3-2,6.6-3,9.9
				c-0.1,0.2-0.2,0.3-0.3,0.5c-0.7,0.2-1.3-0.2-1.9-0.4c-4-1.6-7.9-3.2-11.9-4.7c-2.3-0.9-4.8-1.4-7-2.3c-0.5-0.2-1.2-0.3-1.6-0.8
				c-0.2-0.4-0.1-0.9-0.1-1.3c0.9-5.1,1.4-10.2,2-15.3c0.5-4.5,0.6-9.1,0.6-13.6c0-5.5-0.6-11-1.6-16.4c-0.7-3.9-1.6-7.6-3-11.3
				c-1.2,6.8-2.4,13.6-3.9,20.4c-1.1,5.3-2,10.6-3.2,15.8c-1.5,6.8-2.5,13.7-3.9,20.5c-1.3,6.3-2.9,12.6-4.3,18.9
				c-0.1,0.2-0.1,0.5-0.4,0.6c-2.3-0.3-4.5,0.3-6.8,0.4c-3.8,0.2-7.7,0.6-11.5,0.8c-3.6,0.3-7.2,0.2-10.8,0.4
				c-7.8,0.4-15.5,0.2-23.3,0.1c-1.8,0-3.6-0.3-5.4-0.3c-1.8-0.1-3.6,0-5.4-0.1C334.1,250.8,334,250.5,333.8,250.8z"/>
			<path fill="#DDB6A7" d="M385.4,93.1c-1.2,0.9-2.7,1-4.1,1.6c-3.1,1.3-6.3,2.4-9.4,3.8c-0.7,0.3-1.3,0.3-2-0.2
				c-2.6-2.1-5.5-3.7-8.1-5.9c-0.2-0.2-0.3-0.3-0.5-0.5c0-1.6-0.1-3.3,0-4.9c0.1-3.3,0-6.5,0.1-9.8c0-1-0.4-1.1-1.2-1
				c-4,0.5-8,0.8-11.9-0.3c-2.7-0.7-4.6-2.4-5.4-5.1c-0.4-1.3-0.2-2.8-0.5-4.2c-0.4-1.9,0.5-2.5,2.1-3.2c2-0.8,3.9-2.1,5.4-3.7
				c0.3-0.3,0.8-0.6,0.6-1.1c-0.2-0.5-0.7-0.3-1.2-0.3c-1.9,0.3-3.9,0.3-5.9,0.2c-0.8,0-1.2-0.1-1.1-1.2c0.1-1.6,0-3.2-0.1-4.8
				c0-0.7-0.6-0.9-1.2-1c-0.9,0-1.8-0.3-2.7-0.5c-0.7-0.2-0.9-0.5-0.8-1.3c0.5-2,1.2-3.9,1.8-5.8c1.3-3.9,2.6-7.9,3.8-11.8
				c1.1-3.4,2.4-6.8,4.2-10c0.1-0.3,0.1-0.4,0.1-0.7c2.5-4.8,6.9-7.1,11.9-8.5c2.3-0.7,4.6-0.5,6.9-0.4c2.3,0.1,3.8,1.6,5.2,3.2
				c3.9,4.3,5.7,9.3,5.8,15.1c0,1.8,0.2,3.7,0.1,5.5c-0.1,2.1-0.5,4.1-0.9,6.2c-0.3,1.8-0.1,2.1,1.7,2.3c0.1,0,0.2,0,0.4,0
				c1.4,0.2,1.5,0.1,1.7-1.4c0.3-2.1,1.1-3.9,2.1-5.7c0.9-1.6,2.3-2.3,4-2.3c0.9,0,1.6,0.4,2.1,1.2c1.4,2,1.1,4.1,0.3,6.2
				c0,0.1-0.1,0.2-0.1,0.4c-0.9,2.7-2.6,4.8-5.3,6c-0.7,0.3-1,0.8-0.9,1.6c0.5,3.4,0.4,6.8,0.8,10.2c0.4,3,0.6,6.1,0.8,9.2
				c0.1,1.2,0.6,2.1,1.8,2.6c-0.8,0.2-1,0.8-0.9,1.6c0.1,4.3,0.2,8.7,0.4,13C385.5,89.2,385.4,91.2,385.4,93.1z"/>
			<path fill="#CA7F64" d="M388.5,43c0.2-0.8,0.4-1.6,0.6-2.4c0.4-1.6-0.3-3-1.2-4.2c-0.6-0.8-2.5-0.7-3.7,0
				c-0.8,0.5-1.4,1.1-1.7,1.9c-0.9,1.9-1.9,3.9-1.9,6.1c0,0.9-0.6,1.2-1.4,1c-0.5-0.1-0.9-0.2-1.4-0.2c-1.5,0-2-0.5-1.7-2
				c0.6-2.6,1.1-5.3,1.1-8c0-5.3-0.3-10.7-3.2-15.4c-1.4-2.2-3-4.3-5.2-5.9c-0.6-0.4-1.4-0.7-2.1-0.8c-4.3-0.6-8.3,0.2-12.2,2.2
				c-2.7,1.4-4.9,3.2-6.4,5.9c-0.1,0.3-0.4,0.4-0.7,0.4c-1.6-1.8-2.7-4-2.9-6.3c-0.4-4.8,1.7-9.7,6.2-12.2c3.2-1.7,6.5-3,10.2-3.1
				c3.1-0.1,5.9,0.8,8.8,1.8c3,1,5.9,2.3,9.1,2.3c0.7,0,1.4-0.2,2.1-0.2c2.9-0.2,5.7-0.3,8.6-0.4c3-0.2,5.9,0.5,8.5,1.9
				c5.9,3,9.1,8,11.1,14.1c-0.3,0.3-0.6,0.5-0.9,0.9c-0.3,0.3-0.5,0.6-0.7,1c-0.3,0.5-0.3,1,0.5,1.2c-0.5-0.7-0.1-1.3,0.2-1.9
				c0.2-0.4,0.5-0.7,0.9-0.9c0.6,1.1,0.7,2.4,0.9,3.6c-0.1,0.2-0.1,0.3-0.3,0.4c-1.3,0.3-2.6,0.6-3.7,1.4c0.3-0.3,0.8-0.4,1.2-0.6
				c0.7-0.3,0.8,0.4,1,0.8c0.1,0.3,0.1,0.6,0.2,0.9c1.2,0.5,1.2,0.5,0.5,1.6c-0.1,0.1-0.2,0.2-0.4,0.3c-0.3,0.1-0.7,0.2-0.9,0.5
				c-0.1,0.2-0.2,0.5-0.4,0.6c0.6-0.1,1.2-0.7,2-0.8c0.2,0,0.4,0,0.5,0.1c0.8,0.7,0.7,1.2-0.2,1.7c-0.3,0-0.6,0.1-0.7,0
				c0.6,0,1.1,0.3,1,1.2c-0.2,0.6-0.8,0.3-1.1,0.6c0,0.3,0.3,0.5,0.4,0.8c0,0.6-0.5,0.8-1,0.8c-0.3-0.1-0.5-0.3-0.7-0.5
				c-0.2-0.2-0.6-0.5-0.7-0.2c-0.1,0.4-0.7,0.7-0.4,1.2c0.3,0.6,0.7,1,1.3,1.2c0.6,0.2,0.9,0.6,1.1,1.2c0.4,1.2,0.4,1.2-0.9,1.8
				c-0.7-0.1-1-0.3-0.6-1c-0.5,0-0.9,0.5-1.5,0.3c-0.4-0.2-0.5-0.7-0.9-1.1c-0.2,0.4,0.7,1.4-0.6,1.1c-0.5-0.2-1.1-0.2-1.6-0.5
				c-0.3-0.3-0.9,0-1.1-0.5c-0.7-1.1,0.2-2,0.3-3c0.5-0.4,0.3-1.1,0.8-1.5c0,0,0.1,0,0.1,0c0.1-0.2-0.1-0.1-0.1-0.1
				c-0.2-0.1-0.3-0.3-0.2-0.5c0.3-0.3,0.6-0.5,0.9-0.7c0.1,0,0.2-0.1,0.3,0c0.1,0,0.2,0.1,0.3,0.1c0.5,0.4,0.3,1.2,0.8,1.5
				c0.3-0.4-0.4-0.9-0.2-1.4c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.2,0.3-0.4,0.3-0.7c0-0.2,0.1-0.3,0.1-0.4c0.1-0.2,0.2-0.4,0.4-0.4
				c0.1,0,0.1-0.1,0.2-0.1c-0.1-0.1-0.2,0-0.3,0c-0.9-0.3-0.9-0.7-0.3-1.3c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.7,0.1-1.3,0-2,0.2
				c0.2,0.3,0.4,0.2,0.6,0.3c1,0.2,0.8,0.8,0.6,1.5c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.4,0.4-0.7,0.4c-0.6,0-1.1-0.5-1.8-0.4
				c0.1,0.2,0.3,0.5,0.4,0.9c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.4,0.2,0.5,0.4,0.6c0.1,0.3,0,0.7,0,1c-0.4,0.4-0.7,0.8-0.8,1.4
				c-0.1,0.3-0.2,0.5-0.3,0.8c-0.2,0.3-0.4,0.7-0.7,0.5c-0.7-0.6-1.1,0.2-1.7,0.3c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5-0.1-0.6,0
				c-0.2,0.3,0.1,0.4,0.3,0.5c0.3,0.3,0.6,0.2,0.9,0c0.4-0.3,0.6-1,1.3-0.7c0.3,0.3,0.6,0.7,0.5,1.2c-0.2,0.2-0.7,0.4,0,0.6
				c0.4,0.2,1.1,0.1,1,0.9c-0.1,0.5-0.1,1,0.4,1.3c0.5,0.3,0.8,0.7,0,0.9c-0.3,0.1-0.6,0.2-0.9,0.3c-0.4,0.1-0.6-0.1-0.6-0.5
				c0-0.5-0.3-0.9-0.5-1.4c-0.2,0.8,0.9,1.8-0.4,2.3c-0.5,0-0.4-0.8-0.9-0.6c-0.4,0.2-1.3,0.1-0.7,1c0.1,0.2,0,0.5-0.3,0.6
				c-0.2,0-0.4,0-0.6,0c0.2,0.1,0.4,0,0.5,0c1,0.2,0.8,0.9,0.8,1.6c-0.1,0.2-0.3,0.3-0.6,0.4c-0.7,0-1.5,0-2-0.6
				c-0.3-0.4-0.7,0.1-0.9,0c0.6-0.2,1.1,0.1,1.4,0.7c0.3,0.4,0.7,0.7,1.1,0.2c0.5,0.2,1,0.5,1.6,0.5c0.4,0,0.6,0.2,0.4,0.7
				c-0.3,0.4-0.5,0.7-1,0.9c-0.1,0-0.2-0.1-0.3-0.1c0,0.5,0.2,1,0.3,1.6c0.1,0.4,0.5,0.2,0.8,0.3c0.1,0,0.2,0.1,0.3,0.1
				c0.1,0.3,0,0.5,0,0.8c-0.1,0.4,0.3,0.6,0.1,1c-0.1,0.2-0.2,0.4-0.4,0.5c-0.3,0.1-0.6,0-0.8-0.2c-0.2-0.6-0.4-1.2-1-1.5
				c-0.1-0.1-0.2-0.3-0.3-0.2c-0.1,0.1,0.1,0.2,0.1,0.3c0.7,0.7,0.7,1.3-0.1,1.9c-0.3,0.2-0.7,0.1-1,0.4c-0.1,0,0.2,0.2,0.1,0.1
				c0,0,0,0,0.1-0.1c0.2-0.2,0.5-0.2,0.8-0.2c1.1,0,1.9,0.8,2.8,1.1c0.2,0,0.3,0.1,0.4,0.2c0.2,0.4,0.1,0.7-0.1,1
				c-0.3,0.3-0.8-0.3-1,0.3c-0.1,0.2-0.1,0.4-0.3,0.6c-0.3,0.3-0.7,0.4-1.1,0.4c-0.3,0-0.5-0.1-0.6-0.3c0-0.2,0-0.3,0.1-0.5
				c0.9-0.2,0.2-1,0.5-1.4c0.1-0.1,0.1-0.1,0-0.1c-0.5,0.4,0.3,1.1-0.4,1.4c-0.4,0-0.8,0.1-0.8,0.6c-0.1,0.1-0.2,0.1-0.3,0.1
				c-0.6-0.1-1-0.4-0.9-1c0.1-0.1,0.2-0.3,0.3-0.5c-0.2,0.1-0.2,0.3-0.3,0.4c-0.2,0.1-0.4,0.2-0.6,0c-0.3-0.5-0.6-1-1.2-1.2
				c0,0.5,0.4,0.9,0.2,1.4c-0.5,0.2-1.2,0.1-1.6,0.6c-0.1,0.1-0.3,0.1-0.5,0.2c-0.7,0.4-0.1,0.6,0.1,0.9c0.3,0.3,0.6,0.3,1,0.2
				c0.7-0.5,1.2-0.3,1.6,0.3c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.2c0-0.4-0.3-0.4-0.5-0.6
				c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.3,0.3-0.9,0.8-1c0.7,0.7,1,1.6,1.6,2.4c0,0.1,0,0.2,0,0.3c-0.7,1.2,0.1,2.1,0.8,2.9
				c0.1,0.1,0.1,0.2,0.1,0.3c-0.5,0.7-1.3,0.2-2,0.5c0.6,0.3,0.8,0.9,1.2,1.3c-0.3,0.6-0.9,1-1.5,1.4c-0.1-0.1-0.1-0.2-0.1-0.3
				c0,0.1,0.1,0.2,0.1,0.3c0,0.3-0.2,0.5-0.4,0.6c-0.3,0-0.4,0.1-0.3,0.4c-0.2,0.3-0.4,0.5-0.6,0.8c-0.4-0.3-0.3-0.9-0.8-1.1
				c0.5,0.3,0.5,0.8,0.8,1.1c-2.4,2.7-4.8,5.4-7.1,8.1c-0.3,0.4-0.7,0.6-1.1,0.9c-1.3,0.3-1.8-0.3-2.1-2.4c-0.4-3.2-0.4-6.4-0.8-9.6
				c-0.4-3.4-0.2-6.9-0.9-10.3c-0.1-0.6,0-1.2,0.6-1.4C385.8,48.2,387.3,45.8,388.5,43c0.1,0.1,0.1,0.1,0.2,0.2
				C388.7,43.1,388.6,43,388.5,43z"/>
			<path fill="#DDB6A7" d="M415.4,231.1c2.1,0.7,4.3,1.3,6.4,2c4.8,1.6,9.4,3.7,14.1,5.5c0.6,0.2,1.2,0.4,1.8,0.7
				c-0.6,2.8-1.7,5.5-2.8,8.1c-2.4,6.2-5.4,12.2-8.1,18.2c-2.2,4.9-4.8,9.5-7.5,14.1c-2.2,3.6-4.3,7.2-6.7,10.6
				c-1.7,2.5-3.7,4.7-5.6,7.2c-0.6,0.8-1.2,1.6-1.6,2.5c-1,0.4-1.3,1.5-2,2.2c-1.1,1.1-2,2.3-3,3.4c-0.3,0.3-0.6,0.6-1,0.9
				c-0.7,0.5-1.2,0.3-1.7-0.4c-2.8-4.7-5.2-9.6-8.2-14.3c-1.3-2-1.3-2.2,0.2-4.1c3-4.1,6.1-8.2,8.6-12.7c0.9-1.6,1.6-3.2,2.4-4.8
				c0.2-0.5,0-0.9,0.1-1.4c0.3-0.1,0.3-0.4,0.5-0.7c2.8-5.7,5.6-11.4,7.8-17.3c2.2-5.9,4.4-11.8,5.4-18.1
				C414.7,232.1,414.8,231.5,415.4,231.1z"/>
			<path fill="#6D6E72" d="M337.9,584.7c1.7,1.6,2.1,3.8,2.4,6c0.6,3.5-0.7,6.8-1.1,10.2c-0.1,0.7-0.7,1-1.4,1.1
				c-3.2,0.4-6.4,0.2-9.7,0.3c-0.8,0-1.3-0.2-1.3-1.2c-0.1-1-0.4-2-0.4-3.1c0-0.6-0.4-0.4-0.8-0.3c-2.6,0.6-4.9,1.8-7.2,3
				c-1.4,0.8-3,1.2-4.4,2c-0.9,0.5-2.1,0.3-3,0.2c-3-0.1-5.9,0.2-8.9-0.1c-2.5-0.2-5.1,0.1-7.6,0.1c-1.9,0-3.8,0-5.6,0
				c-0.3,0-0.5-0.1-0.7-0.4c-0.7-1.6,0.1-4.1,1.7-5c2.3-1.4,5-1.9,7.4-3c3.6-1.6,7.2-3.1,10.8-4.7c2.1-1,4.3-1.8,6.5-2.7
				c1.2-0.5,2.3-1,3.4-1.7c0.5-0.3,1-0.6,1.5-0.5c1-0.3,1.7,0.4,2.5,0.8c1.6,0.9,3.4,1.3,5.2,1.7c2.2,0.5,4.3-0.2,6.3-1
				c1.1-0.4,2.1-1,3.1-1.5C337.1,584.8,337.4,584.5,337.9,584.7z"/>
			<path fill="#6D6E72" d="M423.8,588c1.6,1.6,2,3.8,2.3,5.9c0.5,3.5-0.6,6.8-1.1,10.2c-0.1,0.7-0.7,1-1.3,1
				c-1.7,0.1-3.5,0.3-5.2,0.3c-1.4-0.1-2.9,0.1-4.3,0.1c-0.9,0-1.5-0.2-1.5-1.3c-0.1-0.9-0.4-1.9-0.4-2.8c0-0.7-0.3-0.7-0.9-0.5
				c-2.6,0.6-4.9,1.8-7.2,3c-1.4,0.7-2.9,1.4-4.3,1.9c-1.3,0.5-2.9,0.4-4.4,0.3c-2.1-0.1-4.3,0-6.4,0c-2.5,0-5-0.1-7.5,0
				c-2.2,0.1-4.4,0-6.6,0c-0.8,0-1-0.2-1.1-1c-0.3-2.9,1-4.6,4-5.5c3-0.9,6-2.1,8.9-3.4c4.5-1.9,9.1-3.9,13.6-5.8
				c1.3-0.5,2.5-1.1,3.6-1.9c0.4-0.2,0.7-0.5,1.2-0.3c0.9-0.5,1.6,0.1,2.3,0.5c2.4,1.5,5,1.9,7.7,2c1.8,0,3.4-0.8,5-1.5
				c0.8-0.4,1.5-0.8,2.3-1.1C423,588,423.3,587.7,423.8,588z"/>
			<path fill="#98989C" d="M423.8,588c-1.5,0.7-3.1,1.3-4.6,2.1c-2.7,1.4-5.5,1.2-8.4,0.4c-1.6-0.4-3-1.2-4.4-2
				c-0.4-0.2-0.7-0.2-1.1-0.3c0.6-0.6,1.1-1.2,1.8-1.8c0.6-0.5,0.2-1,0.1-1.5c-1-3.4-2-6.8-3-10.1c3.2-0.3,6.4-0.6,9.6-1
				c2.6-0.3,5.2-0.9,7.9-1c0.3,3.2,0.8,6.3,1.3,9.5C423.2,584.3,423.5,586.1,423.8,588z"/>
			<path fill="#98989C" d="M337.9,584.7c-2.6,1.6-5.4,2.9-8.5,3.3c-0.9,0.1-1.9-0.1-2.8-0.3c-1.9-0.5-3.8-0.9-5.4-2.1
				c-0.4-0.3-0.9-0.6-1.5-0.7c2.4-0.9,2.5-2.6,1.7-4.7c-0.4-1.2-0.6-2.6-0.8-3.9c-0.1-0.6-0.4-0.8-0.9-0.8c0.8-0.4,1.6-0.1,2.5-0.1
				c5,0,10-0.4,15.1-0.3C337.7,578.2,337.5,581.5,337.9,584.7z"/>
			<path fill="#B5524C" d="M400,49.4c0.1-0.4-0.6-0.5-0.2-1c0.1-0.2,0.5-0.4,0.2-0.7l0,0c-0.2-0.3-0.1-0.5,0-0.8
				c0.1-0.1,0.1-0.2,0.2-0.2c0.6,0.2,0.8-0.4,1.2-0.5c0.3,0,0.5,0.2,0.7,0.4c0-0.4-0.3-0.8,0.1-1c0.3-0.1,0.5,0.1,0.7,0.3
				c0.5,0.4,1,0.9,1.8,0.6c0.1,0,0.3,0.2,0.3,0.3c0.1,0.4,0,0.7-0.2,1c-0.5,0.5-0.2,0.8,0.2,1.2c-1.1-0.8,0.3-1.4,0.1-2.1
				c0.1-0.3,0.4-0.4,0.6-0.5c0.3-0.1,0.6-0.3,0.9-0.4c0.1-0.1,0.3-0.1,0.4-0.2c0.3-0.2,0.6-0.5,0.9-0.6c0.2-0.1,0.5-0.2,0.4,0.2
				c-0.2,0.8-0.7,1.5-1.2,2.1c-0.3,0.3-0.4,0.6-0.5,0.9c-0.4,0.6-0.6,1.3-1.2,1.7c-0.8,0-1.7,0.2-2.5-0.2c-0.5-0.4-0.4-1-0.6-1.5
				c0.1-0.1,0.1,0.1,0.1-0.1c0-0.1-0.1-0.1-0.2,0c-0.3,0-0.4-0.3-0.6-0.3c0.3,0.3,0.7,0.6,0.6,1.1c-0.1,0.2-0.3,0.3-0.5,0.4
				c-0.5,0-1,0.2-1.4,0.4C399.7,50.5,399.9,49.9,400,49.4z"/>
			<path fill="#BC6456" d="M408.2,33.6c0.4-0.2,0.8-0.3,1-0.7c-0.1-0.2,0.1-0.3,0.2-0.3c0.5,0.1,1-0.1,1.1,0.9
				c0.1,0.8,0.6,1.6,0,2.5c0.1,0.1,0.2,0,0.3,0.1c0.3,1.2-0.3,2.3-0.5,3.4c-0.1,0.1-0.2,0.1-0.3,0c0-1.2-0.7-2-1.4-2.9
				c-0.3-0.4-0.5-0.7-1-0.8c-1.1-0.4-1.6-1.2-1.9-2.5c0.3,0.2,0.5,0.4,0.7,0.5c0.2-0.4-0.1-0.7-0.1-1.3c0.5,0.5,1.3-0.3,1.4,0.8
				c-0.6,0.3-0.9,1-0.5,1.4c0.5,0.5,1.3,0.3,1.8-0.4c-0.3,0.1-0.5,0.3-0.8,0.4c-0.3,0.1-0.7,0.3-1,0c-0.3-0.3-0.1-0.6,0-0.9
				C407.5,33.3,407.9,33.5,408.2,33.6z"/>
			<path fill="#C77A62" d="M401.9,49.5c0.2-0.1,0.3-0.2,0.5-0.2c0.4,0.2,0.4,0.4,0.1,0.7c0,0,0,0,0,0s0.1,0,0.1,0
				c0.2-0.1,0.4-0.1,0.6,0c0.1-0.1,0.2-0.2,0.2-0.3c0.6,0.6,1.6-0.2,2.1,0.5c-0.2,0.4-0.3,0.8-0.7,1l0,0c-1,1.3-1.6,2.9-2.9,4.1
				c-0.1-0.1-0.2-0.2-0.2-0.4c0.2-0.3,0.2-0.5-0.2-0.4c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.6,0.1-1.1,0.6-1.5c0.1,0,0.1,0.1,0.1,0.1
				c0-0.1,0-0.1-0.1-0.2c0-0.5-0.1-0.9,0.4-1.2c0.4-0.2,1.1,0.4,1.2-0.5c-0.4-0.2-0.8-0.1-1.1,0.2c-0.6,0.1-0.6,1.6-1.6,0.6
				c-0.2,0-0.3,0-0.5,0c0.1-0.5,0.4-0.9,0.3-1.5C400.8,49.8,401.4,49.7,401.9,49.5z"/>
			<path fill="#BA5E53" d="M409.7,41.8c-0.1,0.8-0.4,1.6-0.7,2.4c0,0-0.1-0.1-0.1-0.1c-0.1-1-0.9-0.4-1.4-0.6
				c-0.3-0.3-0.3-0.8-0.4-1.2c-0.2-1,1-1.7,0.4-2.8c-0.1-0.2,0.5-0.3,0.7-0.6c0.1-0.1,0.2-0.2,0.4-0.3c1.3-0.6,1.4-0.5,1.5,0.9
				c-1.1,0.3-1.2,0.5-0.6,1.6C409.5,41.3,409.8,41.5,409.7,41.8z"/>
			<path fill="#C87C63" d="M396.7,55.4c0-0.1,0-0.2,0-0.2c0.1-0.1,0.1-0.2,0.2-0.2c0.6-0.3,1.2-0.8,1.5,0.3c0.2,0.5,0.3,0,0.4-0.1
				c0.4-0.1,0.9-0.4,1.3,0.1c0.1,0-0.1,0.4,0.2,0.2c0.3-0.3,0.6-0.2,1-0.1c0.1,0.1,0.2,0.2,0.2,0.3c-0.3,0.5-0.8,1-1.3,1.4
				c-0.2-0.1-0.3-0.2-0.5-0.1c-0.1,0.1-0.1,0.3,0.1,0.4c0,0.5-0.4,0.7-0.7,1c-0.3-0.2-0.3-0.6-0.7-0.7
				C397.9,56.8,396.9,56.5,396.7,55.4z"/>
			<path fill="#C8C8CA" d="M434.8,139.2c-0.3-0.4-0.4-0.9-1-1c-0.7-0.1-0.1-0.6-0.2-0.9c0-0.1,0.1-0.1,0.1-0.1c0.1,0.1,0,0.2,0.1,0
				s0,0.1-0.1-0.1c-0.1-0.1,0.1-0.1-0.1-0.1c0-0.2,0-0.4,0.2-0.5c0.8-0.2,1.4,0.3,2.2,0.2c0.4,0.4,0.5,0.9,0.5,1.4
				c-0.8-0.3-0.3,0.7-0.7,0.7c0-0.2,0.1,0,0-0.1c-0.1,0-0.1,0.1,0,0.1c-0.1,0.1-0.2,0.2-0.2,0.2
				C435.3,139.3,435.1,139.3,434.8,139.2z"/>
			<path fill="#C3725E" d="M410.9,36c-0.3,0-0.4,0.7-0.8,0.3c-0.3-0.3,0.1-0.5,0.2-0.7c0.5-0.6,0.6-1.2-0.1-1.7
				c-0.1,0-0.2-0.1-0.2-0.2c0.4-0.7-0.2-0.9-0.7-1.1c0-0.6,0.7-0.7,0.9-1.2c0.3-0.2,0.5,0.1,0.7,0.2c0.1,0.1,0.1,0.2,0.2,0.3
				C411.4,33.3,411.2,34.7,410.9,36z"/>
			<path fill="#C3725E" d="M409.6,30.3c0.4-0.4,1-0.8,0.2-1.4c0-0.8,0.5-1.3,0.7-2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0.9,0.3,1.8,0.4,2.6
				c0.1,0.4,0,0.8-0.4,1c-0.1,0.1-0.2,0.2-0.3,0.2C410.2,30.6,409.9,30.4,409.6,30.3z"/>
			<path fill="#C06C59" d="M396.9,60.5c-0.4-0.1-0.9-0.1-0.9-0.7c0-0.2,0-0.3-0.2-0.4c-0.2,0-0.5,0.5-0.5-0.1c0-0.4,0.4-0.4,0.7-0.5
				c0.6-0.2,1.2,0.4,1.7-0.2c0.4-0.2,0.7-0.1,1,0.3c-0.3,0.5-0.8,0.8-1.3,1.2C397.1,60.1,396.9,60.2,396.9,60.5L396.9,60.5z"/>
			<path fill="#C77B62" d="M409.7,41.8c-0.7-0.4-1-1.2-1-1.9c0-1,0.7-0.5,1.2-0.4c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.2,0
				C410.4,40.3,410,41,409.7,41.8z"/>
			<path fill="#CA8168" d="M409.9,23.5c0.1-0.1,0.2-0.2,0.2-0.2c0.2,0.6,0.3,1.1,0.5,1.7c-0.1,0-0.1,0.1,0,0.2c0.1,0.1,0,0,0.1,0
				c0.4,0.4,0.1,1,0.2,1.4c0,0-0.1,0.1-0.1,0.1c-0.3,0-0.5,0.1-0.8,0c-0.3-0.2-0.7-0.4-0.8-0.8c0-0.1-0.1-0.2-0.1-0.3
				C409.3,24.9,409.5,24.2,409.9,23.5z"/>
			<path fill="#D8D7D9" d="M311.7,243.4c0.4,0.6,0.1,1.3,0.2,2c0.1,0.4-0.2,1,0.4,1.2c0.1,0,0.1,0.2,0.1,0.3
				c-1.1,0.4-0.3,1.4-0.6,2.1c-0.2,0.9,0.3,1.8-0.2,2.7C311.7,248.8,311.7,246.1,311.7,243.4z"/>
			<path fill="#B85A52" d="M398.6,58.8c-0.3-0.1-0.7-0.2-1-0.3c0,0,0,0,0,0c-0.2-0.5-0.3-0.9,0.4-0.7c0.4,0.1,0.3-0.4,0.5-0.3
				c0.8-0.2,0.4,0.5,0.5,0.7C399,58.6,398.8,58.7,398.6,58.8z"/>
			<path fill="#B5534D" d="M408.4,45.4c-0.7-0.2-0.7,0.6-1.2,0.7c-0.1-0.1-0.2-0.3-0.1-0.5c0.4-0.8,0.6-1.7,1.8-1.5l0.1,0
				C408.9,44.7,408.7,45,408.4,45.4z"/>
			<path fill="#F8F8F8" d="M420.2,115.4c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.1,0.2,0.7-0.3,0.6c-0.4-0.1-0.3-0.4-0.3-0.7
				c0-0.2,0-0.5,0-0.7C419.5,115,420.1,114.8,420.2,115.4z"/>
			<path fill="#C06C59" d="M401.5,55.7c-0.1,0-0.2,0-0.3,0c-0.2-0.4-0.5-0.7-0.4-1.2c0.1-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0,0.5,0
				c-0.5,0.5,0.2,0.3,0.2,0.5c0,0.2,0,0.4,0,0.5C401.8,55.4,401.7,55.6,401.5,55.7z"/>
			<path fill="#CAC9CC" d="M406.7,106.3c-0.3,0.1-0.7,0.2-1.1,0.4c0-0.4-0.1-0.8-0.1-1.1C406.1,105.6,406.5,105.8,406.7,106.3z"/>
			<path fill="#C16E61" d="M409.2,19.7c0,0.5-0.5,0.7-0.7,1c-0.4,0-0.4-0.2-0.2-0.5c0.1-0.6,0.5-0.6,0.9-0.7
				C409.2,19.5,409.2,19.6,409.2,19.7z"/>
			<path fill="#E4E3E5" d="M410.3,108c-0.9,0.6-1-0.1-1.2-0.7C409.7,107.3,410,107.7,410.3,108z"/>
			<path fill="#F5F4F4" d="M309.5,193c0.3,0.4,0.6,0.8,1,1.3c-0.9-0.4-0.7,0.2-0.7,0.6C309.3,194.3,309.6,193.6,309.5,193z"/>
			<path fill="#CBCACC" d="M418.7,114.5c-0.8-0.1-1.4-0.3-1.4-1.2C417.7,113.8,418.5,113.8,418.7,114.5z"/>
			<path fill="#D6D5D7" d="M417.1,113.1c-0.4,0,0.1,1-0.7,0.7l0,0c0-0.4,0-0.8,0-1.2C416.6,112.7,416.8,112.9,417.1,113.1z"/>
			<path fill="#B4514C" d="M410.6,30.3c0.1,0,0.2,0,0.3,0c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.5,0.2,0.9,0,1.4c0,0,0,0,0,0
				c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.3-0.7-0.6-1C409.8,30.5,410.3,30.4,410.6,30.3z"/>
			<path fill="#BA5F53" d="M399.8,57.4c-0.3-0.1-0.8-0.1-0.5-0.5c0.3-0.4,0.7-0.2,0.9,0.2C400.1,57.2,399.9,57.3,399.8,57.4z"/>
			<path fill="#E1E1E2" d="M439.4,144.5c-0.9,0-0.5-0.6-0.5-1C439.2,143.8,439.4,144.1,439.4,144.5z"/>
			<path fill="#B95D52" d="M394.7,62.9c-0.2-0.1-0.5-0.1-0.3-0.4c0.2-0.3,0.4-0.3,0.6,0C394.9,62.6,394.8,62.7,394.7,62.9z"/>
			<path fill="#BC6456" d="M411.1,31.9c0-0.5,0-0.9,0-1.4C411.5,31,411.4,31.5,411.1,31.9z"/>
			<path fill="#C98271" d="M406.7,48.5c-0.3-0.6,0.1-0.7,0.5-0.9C407.2,47.9,407.1,48.3,406.7,48.5z"/>
			<path fill="#BB6055" d="M410.6,25.2c-0.1,0.2,0,0.4-0.3,0.4c-0.1,0-0.1-0.1-0.1-0.2c-0.1-0.3,0.1-0.4,0.4-0.4
				C410.6,25,410.6,25.1,410.6,25.2z"/>
			<path fill="#CA7F64" d="M396.9,60.5c-0.1-0.2-0.4-0.5-0.1-0.7c0,0,0.4,0.1,0.6,0.2C397.3,60.3,397.1,60.4,396.9,60.5z"/>
			<path fill="#E4DBD9" d="M333.8,250.8c0-0.2,0-0.4,0-0.6c0.4,0,0.3,0.4,0.5,0.6C334.1,251.2,333.9,251,333.8,250.8z"/>
			<path fill="#B14C4A" d="M382.8,104.9c-0.2,2.7-0.4,5.4-0.6,8.1c-0.1,1.5-0.7,1.8-2.2,1.5c-2.7-0.5-5.1-1.7-7.5-3.1
				c-0.4-0.3-0.7-0.4-1.3-0.2c-0.5,0.3-1.3,0.5-1.8,0.2c-1.5-0.9-2.8-0.1-4.1,0.5c-1.7,0.7-3.3,1.6-5.1,1.9
				c-0.4,0.1-0.8,0.2-1.2,0.2c-1.4,0-1.7-0.3-1.8-1.7c-0.2-3.6,0.1-7.1,0.6-10.6c0.1-1,0.5-1.4,1.6-1.3c1.9,0.2,3.6,0.9,5.3,1.8
				c0.9,0.5,1.8,1,2.5,1.6c1.1,1,2.4,1,3.8,1.2c0.4,0.1,0.8-0.2,1.1-0.4c2.8-1.8,5.9-3.1,9.3-3.2c0.9,0,1.6,0.2,1.5,1.3
				C382.7,103.4,382.8,104.2,382.8,104.9C382.8,104.9,382.8,104.9,382.8,104.9z"/>
			<path fill="#FEFEFE" d="M357.1,232.3c0,1.5-0.9,2.3-3,2.6c-0.3,0-1.6-1.9-1.6-2.4c0-1.1,1.2-2.4,2.3-2.5
				C356.1,230,357.1,230.9,357.1,232.3z"/>
			<path fill="#FEFEFE" d="M359.5,202.7c0,1.3-1,2.2-2.3,2.2c-1.3,0-2.3-1.1-2.2-2.3c0-1.1,1.3-2.2,2.3-2.2
				C358.5,200.4,359.5,201.5,359.5,202.7z"/>
			<path fill="#FEFEFE" d="M365.7,146c0,1.2-1,2.3-2.3,2.3c-1.1,0-2.2-1.1-2.3-2.2c0-1.2,1.1-2.4,2.4-2.4
				C364.7,143.8,365.7,144.8,365.7,146z"/>
			<path fill="#FEFEFE" d="M360,175.7c-1.2,0-2.2-1-2.2-2.2c0-1.1,1.2-2.4,2.3-2.4c1.2,0,2.3,1.1,2.3,2.3
				C362.3,174.7,361.3,175.7,360,175.7z"/>
			<path fill="#FEFEFE" d="M368.6,120.6c0.2,1.2-1,2.2-2.1,2.2c-1.2,0.1-2.2-1-2.2-2.2c0-1.3,1-2.4,2.4-2.3
				C367.9,118.3,368.6,119.2,368.6,120.6z"/>
			<path fill="#C9C9CB" d="M319.1,240.3c0,0.1,0,0.2,0,0.4c0,0.3,0.4,0.6,0,0.9c-0.1-0.5-0.9-0.1-1-0.7c0-0.9-0.6-1-1.3-0.9
				c0.3-0.9,1.3-1,2.3-0.3c0,0.1,0,0.1,0,0.2C319.2,239.8,318.7,240,319.1,240.3C319.1,240.2,319.1,240.3,319.1,240.3z"/>
			<path fill="#C7C6C9" d="M320.3,195.6c0.1,0.2,0,0.7,0.2,0.5c1.5-0.7,1.2,0.7,1.6,1.2c-0.4,0.4-0.8-1.2-1.2,0.1c0,0-0.2,0-0.3,0
				c0.1-0.8-1.2-0.7-1.1-1.6C319.8,195.7,320,195.4,320.3,195.6z"/>
			<path fill="#CFCED0" d="M406.1,109.1c-0.4,0.1-0.8,0.2-0.8-0.3c-0.1-0.4,0.3-0.5,0.6-0.5c0.6,0,0.2-0.3,0.2-0.5
				c0.1-0.3,0.1-0.6,0.5-0.6c0.2,0,0.4,0.1,0.5,0.2c0.2,0.3-0.1,0.4-0.2,0.5c-0.2,0.2-0.2,0.3-0.2,0.6
				C406.8,109.1,406.8,109.5,406.1,109.1z"/>
			<path fill="#CECDCF" d="M412,118.1c-0.7-0.2-0.9,0.5-1.3,1c-0.1-0.6,0-1-0.4-1.3c-0.3-0.2-0.3-0.6-0.1-0.8
				c0.3-0.3,0.6-0.2,0.9,0.1c0.3,0.2,0.6,0.5,0.9,0.8C412.1,117.9,412.1,118,412,118.1C412.1,118.1,412,118.1,412,118.1z"/>
			<path fill="#C9C8CA" d="M320.2,230.4c1.5,0.6,1.6,1.2,0.5,2.5C319.8,232.2,321.3,231,320.2,230.4z"/>
			<path fill="#CCCCCE" d="M412.7,112.8c0-0.8-1-1.5-0.4-2.4c0.9,0.6,1.2,2,0.6,2.9C412.8,113.2,412.9,112.9,412.7,112.8z"/>
			<path fill="#D7D7D8" d="M328.3,213.6c-0.4-0.3-0.5,0.1-0.7,0.2c-0.2-0.2-0.5-0.3-0.7-0.5c-0.1-0.3-0.8-0.1-0.6-0.7
				c0.2-0.4,0.6,0,0.8-0.1c0.3-0.1,0.7,0.5,1-0.2c0.1-0.2,0.2,0.4,0.4,0.4C328.8,213.2,328.5,213.4,328.3,213.6z"/>
			<path fill="#F7F6F6" d="M431.9,139.8c0.4,0.2,0.9,0.4,0.9,0.9c0,0.4-0.5,0.6-0.9,0.6c-0.3,0-0.7-0.2-0.5-0.6
				C431.6,140.4,431.3,139.8,431.9,139.8z"/>
			<path fill="#F8F7F8" d="M340.9,244.7c-0.7,0-1.3,0.6-1.6,0c-0.1-0.2,0.2-1.1,0.4-1.2C340.4,243.5,340.5,244.3,340.9,244.7z"/>
			<path fill="#C6C6C8" d="M321.1,228.1c-0.3,0.2-0.6,0.7-0.9,0.7c-0.6,0-0.4-0.7-0.5-1c-0.1-0.2,0.1-0.7,0.5-0.6
				C320.6,227.3,320.8,227.6,321.1,228.1z"/>
			<path fill="#DBDADC" d="M322.7,211c-0.9,0.2-1.5-0.2-1.5-1.3c0.6-0.2,1.1-0.1,1.3,0.6C322.7,210.4,322.9,210.6,322.7,211z"/>
			<path fill="#D5D4D6" d="M335.3,229.3c-0.2-0.4-0.2-0.7,0-1c0.2-0.3,0.5-0.6-0.2-0.4c-0.3,0.1-0.3-0.2-0.1-0.2
				c0.2-0.1-0.1-0.7,0.4-0.5c0.3,0.1,0.6,0.2,0.8,0.4c0.3,0.3-0.1,0.5-0.3,0.7C335.8,228.6,335.6,228.9,335.3,229.3z"/>
			<path fill="#F4F4F4" d="M326.9,216.5c0.1,0.4-0.2,0.5-0.4,0.4c-0.4-0.2-1,0-1-0.5c0-0.4,0.5-0.8,0.9-0.8
				C327.1,215.6,326.7,216.2,326.9,216.5z"/>
			<path fill="#C7C6C9" d="M325.4,220.1c0.1-0.2,0.2-0.3,0.2-0.5c0-0.6,0.3-1.1,0.5-1.7c0.3-0.2,0.7-0.2,1,0
				c0.2,0.3-0.1,0.5-0.3,0.7c0,0.4-0.2,0.7-0.5,1C326.1,219.8,326,220.4,325.4,220.1z"/>
			<path fill="#D4D3D5" d="M436.3,145.9c-0.2-0.3-0.8-0.2-0.9-0.7c0-0.2,0.1-0.4,0.3-0.4c0.2-0.1,0.6-0.3,0.5,0.3
				c-0.1,0.4,0.4,0.5,0.6,0.5c0.6,0.2,0.7,0.5,0.6,1.1c-0.4,0-0.5-0.6-0.9-0.5C436.4,146.2,436.3,146.1,436.3,145.9z"/>
			<path fill="#F5F4F4" d="M434.8,139.2c0.2-0.2,0.5-0.2,0.7,0c0,0.3-0.1,0.7,0.4,0.6c0.3,0,0.5,0.1,0.4,0.4c0,0.2-0.1,0.4-0.2,0.5
				c-0.2,0.1-0.4,0-0.5-0.2C435.2,140.2,434.7,139.9,434.8,139.2z"/>
			<path fill="#F5F4F4" d="M339.5,247.1c0.3,0,0.8-0.4,0.6,0.3c0,0.2,0.2,0.8-0.2,0.5c-0.6-0.5-1.1,1.4-1.3,0
				C338.6,247.5,338.9,246.9,339.5,247.1z"/>
			<path fill="#CECDCF" d="M326.3,208.6c1,0.1,1.3,0.8,1.3,1.3c0,0.4-0.7,0.9-1,0C326.5,209.5,326.7,209.1,326.3,208.6z"/>
			<path fill="#EBEAEA" d="M405.6,112.1c-0.3-0.4-0.9-0.3-1-0.9c0-0.3,0.3-0.4,0.5-0.3c0.4,0.2,1,0.1,1.2,0.6
				C406.4,111.8,406,112,405.6,112.1z"/>
			<path fill="#E6E5E5" d="M326.1,217.9c0.3,0.7-0.2,1.1-0.5,1.7c-0.3,0-0.5,0-0.7,0.2c-0.3,0.2-0.5,0.2-0.5-0.2
				C325.2,219.2,325.5,218.4,326.1,217.9z"/>
			<path fill="#D1D0D2" d="M419,120c-0.5,0.2-0.6-0.2-0.4-0.4c0.3-0.5,0.3-1-0.1-1.6C419.6,118.6,419.7,118.9,419,120L419,120z"/>
			<path fill="#D8D7D9" d="M346.1,233.1c0,0.6-0.4,0.7-0.7,0.8c-0.4,0.1-0.5-0.3-0.5-0.6c-0.1-0.7,0.5-0.4,0.8-0.6
				C346.1,232.5,345.9,233,346.1,233.1z"/>
			<path fill="#D3D2D4" d="M322.7,228.7c-0.5,0.2-0.8,0.1-0.9-0.5c-0.1-0.3,0-0.6,0.4-0.7c0.7-0.3,0.5,0.4,0.8,0.6
				c0.1,0.1,0.2,0.2,0.3,0.4C323.1,228.7,323,228.7,322.7,228.7z"/>
			<path fill="#C7C6C9" d="M424.5,124.8c1,0,0.2-0.9,0.6-1.2c0.3,0,0.3,0.2,0.4,0.4c0.1,0.4,0.9,0.8,0.5,1.2
				C425.6,125.5,425,125.1,424.5,124.8L424.5,124.8z"/>
			<path fill="#C8C8CA" d="M405.2,130.2c0.3,0.5,1.2,0.3,1.4,1c0,0-0.3,0.3-0.3,0.2c-0.5-0.3-1-0.5-1.5-0.9
				C404.6,130.5,404.8,130.2,405.2,130.2z"/>
			<path fill="#CFCED0" d="M405.2,108.1c-0.7-0.9-1.7-1.2-2.9-1.2C403.8,106.4,404.4,106.7,405.2,108.1z"/>
			<path fill="#CECED0" d="M409.6,111.9c0.4-0.1,0.4-0.5,0.6-0.8c0.6,0.6,0.1,1.3,0.4,1.9c0.1,0.1-0.5,0-0.8-0.1
				C409.8,112.5,409.5,112.2,409.6,111.9z"/>
			<path fill="#DAD9DB" d="M320.9,234.7c0.4,0,0.7,0.1,0.6,0.3c-0.4,0.4,0,1.1-0.6,1.3c-0.4,0.1-0.5-0.5-0.5-0.6
				C320.3,235.3,320.6,234.9,320.9,234.7z"/>
			<path fill="#D3D3D4" d="M411.8,134.2c0,0.3,0,0.7-0.3,0.6c-0.3-0.2-0.6-0.7-0.7-1.1c-0.1-0.2,0.3-0.4,0.5-0.3
				C411.7,133.4,411.8,133.8,411.8,134.2z"/>
			<path fill="#CBCBCD" d="M339.1,240c-0.4,0.4-0.7-0.1-1-0.2c-0.2-0.5,0.9-0.1,0.4-0.7c0.3-0.2,0.7-0.3,1-0.5
				c0.3,0.5-0.8,0.4-0.2,0.9C339.5,239.8,339.2,239.9,339.1,240z"/>
			<path fill="#E2E1E2" d="M321.5,212.9c-0.5,0.7-1.4,0.4-1.9,1.1c0,0-0.2,0-0.3-0.1c-0.2-0.2,0-0.4,0.2-0.5
				C320.1,213.2,320.7,212.6,321.5,212.9z"/>
			<path fill="#D8D7D8" d="M436.3,142.6c0.4-0.6,1.5-0.6,1.7,0c0.4,0.9-0.5,0.4-0.7,0.5C436.9,142.9,436.5,142.9,436.3,142.6z"/>
			<path fill="#CCCBCD" d="M417.6,116.1c-0.6,1.2-1.4,0.4-2.1,0.1C416.2,116.1,416.9,116.3,417.6,116.1z"/>
			<path fill="#E5E4E5" d="M329.7,210.4c-0.5-0.2-1.1-0.4-1.2-1.1c0,0,0.3-0.2,0.5-0.3C329.2,209.6,330.1,209.6,329.7,210.4z"/>
			<path fill="#D6D5D7" d="M416.3,113.8c-0.2,0.4-0.7,0.2-1,0.4c-0.3,0.2-0.5,0-0.7-0.3c-0.1-0.3,0.1-0.4,0.2-0.6
				c0.3-0.4,0.4,0,0.5,0.1C415.7,113.6,416.1,113.6,416.3,113.8C416.3,113.8,416.3,113.8,416.3,113.8z"/>
			<path fill="#CDCDCF" d="M327.5,221.1c-0.6-0.8-0.4-1.5,0.2-2.2c0.1,0.2,0.2,0.4,0.4,0.6c0,0,0,0.1,0.1,0.1
				C327.5,219.8,328.1,220.8,327.5,221.1L327.5,221.1z"/>
			<path fill="#D7D6D8" d="M317,223c0.3,0.1,0.8-0.2,0.8,0.3c0,0.4-0.4,0.4-0.7,0.3c-0.3-0.1-0.8,0-0.8-0.5
				C316.4,222.6,316.7,223.1,317,223z"/>
			<path fill="#C7C6C9" d="M322.2,199.5c-0.3,0.1-0.8,0.5-0.6-0.3c0.1-0.4-0.1-0.4-0.4-0.4c-0.1-0.3-0.6-0.3-0.5-0.7
				c0.4,0.3,1.5-0.6,1.2,0.7C322.2,199,322.4,199.1,322.2,199.5z"/>
			<path fill="#EEEDED" d="M435,144.2c0,0.3-0.2,0.5-0.4,0.5c-0.6,0.1-0.7-0.6-1.1-0.8c0.1-0.1,0.2-0.3,0.3-0.3
				C434.4,143.7,434.7,144.1,435,144.2z"/>
			<path fill="#DBDADB" d="M343.5,236.9c0.3,0.4,0.9,0.1,1.1,0.5c0.1,0.2-0.4,0.7-0.5,0.7c-0.5-0.1-0.5-0.8-1-0.9
				C343.3,237.2,343.4,237.1,343.5,236.9z"/>
			<path fill="#D1D0D2" d="M419,120c0.2,0,0.3-0.1,0.5-0.1c0.4,0,0.8,0.1,0.9,0.5c0.1,0.3-0.3,0.3-0.5,0.4c-0.5,0.3-0.6-0.1-0.8-0.4
				C419,120.3,419,120.1,419,120C419,120,419,120,419,120z"/>
			<path fill="#D9D9DA" d="M314.8,223.7c0.1,0.8-0.2,1.3-1.2,1.4c0.3-0.5,0.5-0.9,0.7-1.4C314.5,223.6,314.7,223.6,314.8,223.7z"/>
			<path fill="#CBCACC" d="M404.9,121.1c-0.2-0.1-0.4-0.3-0.2-0.5c0.3-0.4,0.3-1.1,1-1.1c0.1,0,0.1,0.2,0.2,0.3
				C405.3,120,405.6,120.9,404.9,121.1z"/>
			<path fill="#FAFAFA" d="M342.7,235.4c-0.1,0.3-0.3,0.6-0.7,0.7c-0.2,0-0.4-0.1-0.3-0.4c0.1-0.4,0.3-0.8,0.7-0.8
				C342.6,234.9,342.6,235.2,342.7,235.4z"/>
			<path fill="#F7F6F6" d="M323.2,212.2c0.8-0.2,0.7,0.6,1,1c0.1,0.1-0.1,0.2-0.2,0.2c-0.5,0.1-0.6-0.4-0.8-0.7
				C323.1,212.5,323.1,212.3,323.2,212.2z"/>
			<path fill="#CDCDCF" d="M328.3,219.1c0.9-0.8,1.2-0.2,1.8,0.6c-0.7,0.1-1.2-0.9-1.7-0.1c0,0,0,0,0,0
				C328.1,219.5,328.1,219.3,328.3,219.1z"/>
			<path fill="#E4E3E4" d="M408.1,113.9c-0.3-0.8-0.5-1.4-0.7-1.9C408.3,112.1,408.3,112.1,408.1,113.9z"/>
			<path fill="#E7E6E7" d="M325.6,211.1c-0.5-0.1-0.5-0.5-0.7-0.8c-0.1-0.3,0.1-0.5,0.2-0.8c0,0,0.2,0,0.3,0c0.3,0.2,0.2,0.4,0,0.7
				C325.3,210.5,325.9,210.7,325.6,211.1z"/>
			<path fill="#EFEFEF" d="M321.4,203.1c0.3,0,0.5,0.2,0.5,0.4c0,0.4-0.4,0.5-0.7,0.6c-0.2,0.1-0.3-0.2-0.3-0.4
				C320.8,203.4,321,203.2,321.4,203.1z"/>
			<path fill="#E5E4E5" d="M409.6,111.9c0.1,0.3,0.6,0.5,0.2,1c-0.6-0.2-0.6-0.2-1.3-0.8C408.9,111.8,409.3,111.9,409.6,111.9z"/>
			<path fill="#CFCED0" d="M314,189.2c0.4-0.3,0.6,0,0.9-0.1c0.2,0,0.5-0.3,0.8,0c0.1,0.1,0.1,0.3-0.1,0.4c-0.3,0.1-0.6,0.4-1,0
				C314.4,189.4,314.2,189.3,314,189.2z"/>
			<path fill="#E3E2E3" d="M411.7,114.8c0.6,0.4,1.1,0.2,1.1,0.8c0,0.3-0.2,0.6-0.6,0.7c-0.1,0-0.3,0-0.3,0
				c-0.1-0.1-0.1-0.2-0.1-0.3C412.8,115.8,411.8,115.4,411.7,114.8z"/>
			<path fill="#DBDBDC" d="M412.7,112.8c0.2,0.1,0.2,0.3,0.2,0.5c-0.3,1.2-1,0.3-1.5,0.4C411.7,113,412.7,113.6,412.7,112.8z"/>
			<path fill="#E8E7E7" d="M436.5,153.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.3-0.9,0.2-1-0.5c0-0.2,0.1-0.3,0.3-0.2
				C435.8,152.3,436.8,151.7,436.5,153.1z"/>
			<path fill="#D5D4D6" d="M318.8,227.4c0.9,1.1,0.8,1.2-0.6,1.4C318.9,228.5,319,228,318.8,227.4z"/>
			<path fill="#D0CFD1" d="M415.8,120.5c-0.4-0.6-1-0.6-1.1-1.3C415.9,119.5,415.9,119.5,415.8,120.5z"/>
			<path fill="#D8D8D9" d="M319.8,220.2c-0.1,0.3-0.1,0.8-0.3,1c-0.2,0.1-0.5-0.3-0.5-0.5c0.1-0.3-0.1-0.8,0.4-0.9
				C319.7,219.7,319.8,219.9,319.8,220.2z"/>
			<path fill="#CDCCCE" d="M401.2,111.4c0.4,0.7,0.6,1.3-0.4,1.7C401.1,112.5,400.7,111.9,401.2,111.4z"/>
			<path fill="#E0DEDF" d="M323.1,218.5c-0.8-0.2-0.1,0.8-0.7,0.9c-0.2,0-0.5-0.2-0.4-0.5c0.1-0.4,0.2-0.8,0.7-0.8
				C323,218.2,323.1,218.2,323.1,218.5z"/>
			<path fill="#CECED0" d="M318.4,215.3c-0.3-0.3-0.9-0.2-1.1-0.7c0.3-0.1,1.2,0.3,0.7-0.8c0.7,0.4,0.7,0.8,0.5,1.2
				C318.5,215.1,318.5,215.2,318.4,215.3C318.4,215.3,318.4,215.3,318.4,215.3z"/>
			<path fill="#D4D3D5" d="M343.3,219c0,0.3-0.3,0.4-0.3,0.3c-0.3-0.3-1-0.1-0.9-0.7c0-0.3,0.4-0.3,0.6-0.3
				C343.1,218.4,343.1,218.9,343.3,219z"/>
			<path fill="#E2E1E2" d="M424.2,123.2c-0.3-0.5-0.2-1,0.2-1.4c0.1,0,0.4,0.1,0.4,0.1C425,122.5,424.4,122.7,424.2,123.2z"/>
			<path fill="#F2F1F1" d="M325.5,204.4c0.4,0.6,0.5,1.1,0.2,1.8C325.3,205.6,325,205.1,325.5,204.4z"/>
			<path fill="#C9C8CA" d="M332.1,209.5c-0.8-0.2-0.3-0.5-0.1-0.8c0.3-0.4,0.3-0.3,0.9,0.3C332.8,209.4,332.2,209,332.1,209.5
				L332.1,209.5z"/>
			<path fill="#E2E1E2" d="M318.5,194c0-0.2-0.1-0.6,0.3-0.5c0.4,0.1,0.4,0.4,0.4,0.7c0,0.2,0,0.5-0.3,0.5
				C318.2,194.8,318.7,194.3,318.5,194z"/>
			<path fill="#D5D4D5" d="M321.9,207.9c0,0.2,0,0.4-0.2,0.3c-0.6,0-0.4-0.7-0.7-1c-0.2-0.2,0.1-0.4,0.3-0.3
				C321.9,207.1,321.7,207.6,321.9,207.9z"/>
			<path fill="#D2D2D3" d="M314.3,207.8c-0.2,0-0.3,0-0.5,0c-0.2-0.1-0.4-0.2-0.3-0.5c0.1,0,0.1-0.1,0.2-0.1
				c0.3-0.1,0.5-0.8,0.9-0.3C315.3,207.6,314.4,207.5,314.3,207.8L314.3,207.8z"/>
			<path fill="#D9D8D9" d="M319.4,206.4c0.4,0.3,0.7,0.6,1.1,0.9C319.8,207.6,319.4,207.3,319.4,206.4z"/>
			<path fill="#DFDEDF" d="M344.5,248.9c-0.4-0.2-0.8-0.5-1.2-0.5c-0.3,0-0.3-0.3-0.2-0.6c0.2-0.3,0.4-0.3,0.5,0
				C343.7,248.3,344.5,248.1,344.5,248.9z"/>
			<path fill="#E4E3E4" d="M331.5,240.8c-0.1-0.1-0.1-0.2-0.1-0.3c0.1-0.4-0.5-0.7-0.1-1.1c0.2-0.2,0.4,0,0.5,0.2
				C332.1,240.1,332,240.5,331.5,240.8z"/>
			<path fill="#D3D2D4" d="M322.7,214.3c0-0.6-0.6-0.8-0.8-1.3c1.1,0.3,1.4,0.9,1.1,2c0,0-0.1-0.1-0.1-0.1
				C322.7,214.8,322.7,214.5,322.7,214.3z"/>
			<path fill="#EAE9EA" d="M331.7,203.9c1.2,0.3,0.5,0.9,0.3,1.4C331.4,204.9,331.9,204.4,331.7,203.9z"/>
			<path fill="#CECDCF" d="M312.5,206.3c-0.8-0.1-1-0.5-1.1-0.9c-0.1-0.2,0.2-0.2,0.4-0.2C312.4,205.3,312.1,205.9,312.5,206.3z"/>
			<path fill="#EAE9E9" d="M435.5,142.3c-0.3,0-0.6,0-0.9,0c-0.2,0-0.5-0.2-0.4-0.5c0.1-0.3,0.4-0.2,0.6-0.2
				c0.2,0.2,0.4,0.1,0.7,0.1C436,141.9,435.8,142.1,435.5,142.3z"/>
			<path fill="#CECED0" d="M324.8,237.8c0.1-0.8,0.9-0.9,1.4-1.4C326.2,237.5,325.2,237.4,324.8,237.8z"/>
			<path fill="#D6D5D6" d="M407.6,114c-0.1,0.4,0,1-0.5,1.1c-0.2,0-0.5-0.2-0.5-0.4C406.6,114.1,407.3,114.3,407.6,114z"/>
			<path fill="#CACACC" d="M329.1,244.7c0.3,0.5,0.6,1,0.9,1.6C328.9,246.1,329,245.3,329.1,244.7z"/>
			<path fill="#EEEDED" d="M427.1,146.7c0,0.3-0.2,0.3-0.5,0.2c-0.2-0.1-0.7,0.1-0.6-0.3c0-0.2,0.3-0.5,0.6-0.5
				C427,146.1,427.1,146.3,427.1,146.7z"/>
			<path fill="#DFDEDF" d="M327.6,243.8c-0.2-0.1-0.3-0.2-0.2-0.4c0.2-0.3,0-0.8,0.6-0.9c0.2,0,0.3,0.2,0.2,0.3
				C327.8,243,328.2,243.7,327.6,243.8z"/>
			<path fill="#F8F8F8" d="M336.9,231.7c-1.1-0.1-0.9-0.8-0.7-1.5C336.5,230.7,336.7,231.1,336.9,231.7z"/>
			<path fill="#CAC9CC" d="M438.2,154.1c0.4,0.1,0.8-0.4,1.1,0.1c0.2,0.3-0.2,0.5-0.4,0.6C438.2,155.1,438.3,154.4,438.2,154.1
				L438.2,154.1z"/>
			<path fill="#D1D0D2" d="M342.9,241.7c0.3-0.5,0.8-0.5,1.2-0.2c0.2,0.1,0.4,0.2,0.3,0.4c0,0.1-0.1,0.2-0.2,0.3
				c-0.3,0.2-0.4-0.1-0.5-0.3c-0.1-0.1-0.3-0.1-0.4-0.2C343.2,241.8,343.1,241.8,342.9,241.7z"/>
			<path fill="#D5D5D6" d="M320,180.9c-0.4-0.2-0.9,0.3-1.2-0.3c-0.1-0.2,0-0.4,0.1-0.4C319.3,180.5,319.9,180.1,320,180.9z"/>
			<path fill="#EEEDED" d="M390.2,109.4c-0.8,0.1-1.2,0-1.4-0.5c0-0.1,0.1-0.3,0.1-0.3C389.4,108.6,389.7,109,390.2,109.4z"/>
			<path fill="#C7C6C9" d="M424.5,124.8c-1.7,0,0.4-0.8-0.3-1.1C424.7,124,424.8,124.4,424.5,124.8
				C424.5,124.8,424.5,124.8,424.5,124.8z"/>
			<path fill="#CECED0" d="M313,184.4c0.4,0.2,0.8,0.3,0.8,0.7c0,0.2-0.2,0.5-0.3,0.3c-0.3-0.2-0.5-0.5-0.7-0.8
				C312.8,184.6,313,184.5,313,184.4z"/>
			<path fill="#DDDCDD" d="M439.5,152.9c-0.5,0.2-0.9,0.3-1.2-0.2c0-0.1,0-0.3,0.1-0.3C438.9,152.2,439.1,152.8,439.5,152.9z"/>
			<path fill="#F0EFF0" d="M403.9,114.4c0-0.2,0.2-0.4,0.2-0.3c0.3,0.4,0.9-0.1,1,0.4c0.1,0.3-0.2,0.3-0.4,0.3
				C404.3,115,404.1,114.8,403.9,114.4z"/>
			<path fill="#DCDBDC" d="M417.9,120.9c-0.1,0.6-0.2,1.1-0.3,1.7C417.2,121.9,417.1,121.4,417.9,120.9z"/>
			<path fill="#E4E2E3" d="M330.7,249c1.2-0.3,1.1,0.2,0.9,1C331.3,249.7,331,249.4,330.7,249z"/>
			<path fill="#E1E0E1" d="M341.3,240.9c0.5-0.6,0.6-1.2,1.2-1.1c0.3,0,0.7,0.4,0.2,0.5C342.2,240.4,342,240.7,341.3,240.9z"/>
			<path fill="#D6D5D6" d="M319.1,215.9c0.4,0.2,0.9,0.4,0.5,0.9c-0.1,0.1-0.3,0.2-0.4,0.1C318.7,216.6,319.1,216.2,319.1,215.9z"/>
			<path fill="#E9E8E9" d="M320.7,204.6c0.3,0,0.4,0.2,0.3,0.3c-0.1,0.4,0.6,0.5,0.3,0.9c-0.1,0.1-0.3,0.1-0.4,0
				C320.7,205.4,320.4,205,320.7,204.6z"/>
			<path fill="#FBFBFB" d="M333.4,248.2c0.2,0.5,0.4,0.8-0.1,1.1c-0.1,0-0.3,0-0.3,0C332.7,248.8,333,248.5,333.4,248.2z"/>
			<path fill="#D4D3D5" d="M315.3,218.9c-0.5,0.2-0.8,0.6-1.1,0.1c-0.1-0.1-0.1-0.3,0.1-0.4C314.7,218.3,314.9,218.6,315.3,218.9z"
				/>
			<path fill="#E4E3E4" d="M432.1,144.7c0.2,0.8,0.5,1.4-0.6,1.6C431.6,145.8,431.8,145.4,432.1,144.7z"/>
			<path fill="#CBCBCD" d="M336,219.9c-0.8,0.1-1-0.3-0.9-0.9C335.8,219,335.7,219.7,336,219.9z"/>
			<path fill="#DAD9DB" d="M325.4,220.1c0.3-0.2,0.6-0.3,0.9-0.5c-0.1,0.4,0.2,0.9-0.2,1.1C325.8,220.8,325.6,220.4,325.4,220.1z"/>
			<path fill="#CDCCCE" d="M325,222.8c-0.2,1-0.8,0.4-1.3,0.6C324,222.5,324.6,223,325,222.8z"/>
			<path fill="#D6D5D7" d="M413.2,136.1c0.2,0,0.3,0,0.4,0.2c-0.4,0.1-0.5,0.4-0.6,0.8c-0.2-0.1-0.4-0.3-0.5-0.5
				C412.6,136.2,412.9,136.1,413.2,136.1z"/>
			<path fill="#CCCBCD" d="M402.6,109.1c-0.2-0.1-0.4-0.3-0.3-0.4c0.2-0.2,0.1-0.6,0.5-0.6c0.2,0,0.3,0.2,0.3,0.4
				C403,108.8,402.9,109.1,402.6,109.1z"/>
			<path fill="#E4E3E4" d="M408.8,108c-0.1,0.4,0.1,0.8-0.4,1.1c-0.1,0-0.3-0.2-0.3-0.3C408,108.4,408.1,108.1,408.8,108z"/>
			<path fill="#D0CFD2" d="M358.7,240.3c0.3,0.4,0.1,0.7-0.3,0.9c-0.3,0.2-0.4-0.2-0.4-0.4C357.9,240.3,358.4,240.3,358.7,240.3
				L358.7,240.3z"/>
			<path fill="#E3E2E3" d="M427.7,132.9c-0.1,0.4,0.1,0.9-0.4,1.2c0,0-0.2-0.1-0.3-0.2C427,133.4,427.3,133.1,427.7,132.9z"/>
			<path fill="#D3D2D4" d="M405.2,129.3c0.4-0.2,0.2-0.9,0.9-0.9c0.1,0,0.1,0.2,0.1,0.3C406.2,129.2,405.8,129.3,405.2,129.3z"/>
			<path fill="#D2D2D3" d="M324.5,209.3c-0.8,0.3-1.1-0.1-1.6-0.6C323.6,208.7,324.1,208.8,324.5,209.3z"/>
			<path fill="#CBCACC" d="M317.4,208.9c0.7,0.7,0.2,1-0.4,1.7C317.1,209.8,317.6,209.4,317.4,208.9z"/>
			<path fill="#CACACC" d="M434.9,157.3c-0.1,0.2,0,0.5-0.3,0.5c-0.2,0-0.4-0.2-0.4-0.5c0-0.2,0.1-0.5,0.4-0.5
				C434.8,156.8,435,157,434.9,157.3z"/>
			<path fill="#DEDDDE" d="M329.1,206.6c0.5-0.6,0.9-0.6,1.4-0.9C330.3,206.4,330.3,206.4,329.1,206.6z"/>
			<path fill="#CBCBCD" d="M322.7,211c-0.1-0.2-0.2-0.5-0.2-0.7c0.2-0.1,0.3-0.2,0.5-0.2c0.1,0.2,0.3,0.2,0.5,0.2
				C323.7,210.9,323.4,211.2,322.7,211z"/>
			<path fill="#E7E5E6" d="M342.4,213.2c-0.1-0.2-0.3-0.3-0.3-0.4c0.1-0.3,0.2-0.7,0.6-0.7c0.1,0,0.3,0.3,0.3,0.3
				C342.9,212.6,342.6,212.8,342.4,213.2z"/>
			<path fill="#D0CFD1" d="M342,245.7c-0.3,0-0.3-0.2-0.3-0.3c0-0.3,0.2-0.5,0.5-0.5c0.2,0,0.4,0.1,0.3,0.3
				C342.4,245.3,342.2,245.5,342,245.7z"/>
			<path fill="#DAD9DA" d="M336.6,226.3c0,0.3,0,0.4-0.2,0.4c-0.3,0-0.5-0.1-0.6-0.4c-0.1-0.2,0-0.4,0.3-0.4
				C336.3,226,336.5,226.1,336.6,226.3z"/>
			<path fill="#D2D1D3" d="M347.7,244.3c0,0.4-0.2,0.5-0.6,0.4c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.2,0.4-0.4,0.6-0.5
				C347.6,243.9,347.7,244.2,347.7,244.3z"/>
			<path fill="#D2D1D3" d="M321.8,190.8c0.1,0.7,0,1.1-0.4,1.6C321.1,191.6,321.2,191.3,321.8,190.8z"/>
			<path fill="#D6D5D6" d="M403,105.2c-0.5-0.3-1-0.2-1.4-0.9C402.3,104.4,402.8,104.3,403,105.2z"/>
			<path fill="#D9D8DA" d="M319.4,242.2c0.1,0,0.2,0,0.4,0c0.3,0,0.9,0,0.7,0.5c-0.1,0.4-0.5,0-0.8,0
				C319.2,242.7,319.2,242.4,319.4,242.2z"/>
			<path fill="#EFEEEE" d="M429.8,139.4c0,0.2,0,0.4-0.2,0.4c-0.3,0-0.5-0.3-0.6-0.6c-0.1-0.2,0-0.3,0.2-0.3
				C429.5,138.9,429.6,139.2,429.8,139.4z"/>
			<path fill="#CFCED0" d="M317.4,232.9c0.2-0.9,0.6-1.1,1.3-0.9C318.3,232.3,318,232.5,317.4,232.9z"/>
			<path fill="#CECDCF" d="M341.8,203.3c0.4,0.2,0.7,0.5,0.7,1c0,0.1-0.2,0.2-0.3,0.2C341.8,204.2,342,203.6,341.8,203.3z"/>
			<path fill="#F1F1F1" d="M419.3,135.9c-0.5,0.2-0.8,0.5-1.2,0.3c0,0-0.1-0.3,0-0.3C418.4,135.5,418.8,135.5,419.3,135.9z"/>
			<path fill="#D8D7D8" d="M335.9,239c-0.6-0.1-1-0.2-0.9-0.7c0-0.1,0.2-0.3,0.2-0.3C335.6,238.2,335.8,238.5,335.9,239z"/>
			<path fill="#D4D4D5" d="M429.8,146.6c-0.3,0.4-0.5,0.6-0.8,1C428.9,146.8,428.9,146.8,429.8,146.6z"/>
			<path fill="#CCCCCE" d="M411.6,110.4c0.3,0.4,0.2,0.8,0.5,1.4c-0.8-0.2-0.7-0.8-0.7-1.2C411.4,110.5,411.4,110.4,411.6,110.4z"/>
			<path fill="#EEEDEE" d="M321.4,216.2c-0.1,0.2-0.2,0.4-0.5,0.5c-0.3,0-0.3-0.3-0.4-0.5c-0.1-0.2,0-0.4,0.3-0.3
				C320.9,216,321.3,215.9,321.4,216.2z"/>
			<path fill="#CCCCCE" d="M317,216.5c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.3-0.9-0.1-0.6-0.6c0.2-0.5,0.7-0.1,1,0
				C317.4,216.1,317.2,216.3,317,216.5z"/>
			<path fill="#F4F3F3" d="M327,239.7c-0.2-0.1-0.3-0.2-0.2-0.4c0.1-0.2,0-0.6,0.4-0.6c0.2,0,0.4,0.2,0.3,0.4
				C327.4,239.3,327.2,239.5,327,239.7z"/>
			<path fill="#D0CFD2" d="M358.7,240.2c-0.2-0.4-0.4-0.7-0.2-1.3C359,239.4,359.5,239.7,358.7,240.2
				C358.7,240.3,358.7,240.2,358.7,240.2z"/>
			<path fill="#E2E1E2" d="M315.3,213.1c0.2,0,0.5,0.1,0.5,0.3c0,0.1-0.3,0.3-0.6,0.3c-0.2,0.1-0.4-0.1-0.4-0.3
				C314.9,213.2,315.1,213.2,315.3,213.1z"/>
			<path fill="#DFDEDF" d="M423.3,121.3c0,0.3-0.2,0.4-0.5,0.5c-0.2,0.1-0.4-0.1-0.4-0.3c0-0.3,0.2-0.4,0.4-0.5
				C423.1,121,423.2,121.1,423.3,121.3z"/>
			<path fill="#CECDCF" d="M412.4,125.5c0,0.3-0.1,0.4-0.4,0.4c-0.2,0-0.3,0-0.3-0.2c0-0.2,0.1-0.5,0.3-0.6
				C412.2,125.1,412.3,125.4,412.4,125.5z"/>
			<path fill="#DFDEDF" d="M331.1,242.4c-0.3-0.5-0.3-0.8,0-1c0.1-0.1,0.3,0,0.3,0C331.6,241.7,331.4,242,331.1,242.4z"/>
			<path fill="#D5D5D6" d="M319.5,202.7c-0.9,0.1-1-0.2-0.6-1C319.1,202.1,319.3,202.4,319.5,202.7z"/>
			<path fill="#E9E8E9" d="M349.2,244.3c-0.1,0.7-0.4,1-1,1.1C348.3,244.9,348.6,244.7,349.2,244.3z"/>
			<path fill="#F2F1F1" d="M331.7,246.2c0.6,0,1,0.1,0.8,0.9C332.2,246.8,331.9,246.5,331.7,246.2z"/>
			<path fill="#F1F0F0" d="M323.9,198.6c-0.1,0.2-0.2,0.2-0.4,0.1c-0.2-0.1-0.5-0.2-0.5-0.6c0-0.2,0.2-0.2,0.4-0.2
				C323.8,198.1,323.9,198.3,323.9,198.6z"/>
			<path fill="#CDCDCF" d="M350.7,250.6c-0.3-0.3-0.8-0.3-0.9-0.8c0-0.1,0.2-0.2,0.2-0.2C350.5,249.8,350.5,250.2,350.7,250.6z"/>
			<path fill="#D3D2D4" d="M316.6,200.6c0.6-0.2,0.9-0.5,1.2-0.1c0.1,0.1,0.1,0.4,0,0.4C317.5,201.1,317.2,200.8,316.6,200.6z"/>
			<path fill="#D4D3D5" d="M332.5,202.7c-0.4-0.2-0.6-0.6-0.6-1.1C332.5,201.9,332.5,201.9,332.5,202.7z"/>
			<path fill="#CFCED0" d="M315.6,204.8c0,0.6-0.2,1-0.9,0.5C314.8,204.9,315.5,205.3,315.6,204.8z"/>
			<path fill="#D2D1D3" d="M333,207.3c0.3,0.2,0.7-0.5,0.9,0c0.1,0.2,0,0.3-0.1,0.4C333.4,208,333.2,207.6,333,207.3z"/>
			<path fill="#E5E5E5" d="M317,192.9c0,0.3-0.2,0.5-0.5,0.5c-0.2,0-0.3-0.1-0.3-0.2c0.1-0.2-0.1-0.6,0.4-0.6
				C316.8,192.6,317,192.6,317,192.9z"/>
			<path fill="#D4D3D5" d="M326.9,212.1c-0.7,0-1,0.4-1.4,0.1c0,0,0.1-0.4,0.2-0.5C326.2,211.3,326.3,211.9,326.9,212.1z"/>
			<path fill="#D1D1D2" d="M325,214.1c-0.2,0.5,0.2,0.9-0.3,1.1c-0.1,0-0.2-0.1-0.3-0.1C324.4,214.7,324.4,214.4,325,214.1z"/>
			<path fill="#D7D6D8" d="M338.2,215.8c0,0.4-0.1,0.4-0.3,0.4c-0.2,0-0.4,0-0.4-0.3c0-0.2,0.2-0.5,0.4-0.5
				C338,215.5,338.2,215.7,338.2,215.8z"/>
			<path fill="#E5E4E5" d="M330,216.4c-0.2,0.3-0.6,0.5-0.8,0.9C329.1,216.7,329.3,216.3,330,216.4z"/>
			<path fill="#D1D0D2" d="M432,151.2c0-0.1,0-0.2,0-0.4c0-0.3-0.1-0.9,0.4-0.8c0.5,0.1-0.1,0.6,0,0.9
				C432.3,151.1,432.2,151.3,432,151.2z"/>
			<path fill="#CBCBCD" d="M320.3,218.2c0.9,0.5,0.9,0.5,0.7,1.3C320.8,219.1,320.6,218.8,320.3,218.2z"/>
			<path fill="#DEDDDF" d="M321.5,220.3c0.3,0.1,0.6-0.3,0.9-0.1c0.1,0.1,0.1,0.3,0,0.4c-0.4,0.3-0.7,0-1.1-0.1
				C321.3,220.4,321.3,220.2,321.5,220.3z"/>
			<path fill="#CDCDCF" d="M327.5,221c-0.1,0.5,0.5,0.2,0.6,0.6c-0.3,0-0.6,0.5-0.9,0.1C327.1,221.6,326.9,221.1,327.5,221
				C327.5,221.1,327.5,221,327.5,221z"/>
			<path fill="#E5E4E5" d="M437,143.6c-0.3,0.3-0.7,0.3-1,0.5c-0.5,0.3-0.4-0.2-0.4-0.5c0.1-0.2,0.3-0.1,0.5-0.2c0.1,0,0.1,0,0.2,0
				c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.5,0.2C437,143.5,437,143.5,437,143.6z"/>
			<path fill="#E5E4E5" d="M436.3,142.6c0.4,0.1,0.9-0.2,1,0.5c0,0.2,0,0.4-0.2,0.5c0,0,0,0,0,0c-0.2-0.3-0.4-0.2-0.7-0.2
				c0,0,0,0,0,0C436.2,143,435.9,142.8,436.3,142.6z"/>
			<path fill="#D0CFD1" d="M318.2,225.9c-0.5-0.2-0.8-0.3-1.3-0.5C317.4,225.3,318,224.9,318.2,225.9z"/>
			<path fill="#E0DFE0" d="M321.4,225.7c0.5,0,0.7,0.3,0.8,0.6c0,0.1-0.1,0.2-0.2,0.2C321.5,226.5,321.5,226.1,321.4,225.7z"/>
			<path fill="#D4D3D5" d="M347.1,229.7c0.1,0.6,0.9,0.2,1,0.7c0,0.1-0.2,0.2-0.3,0.2C347.3,230.5,347.1,230.2,347.1,229.7z"/>
			<path fill="#E0DFE0" d="M322.7,232c-0.1,0.5-0.5,0.4-0.7,0.5c-0.1,0.1-0.4-0.1-0.3-0.2c0.2-0.2,0.5-0.4,0.7-0.6
				C322.4,231.7,322.6,231.9,322.7,232z"/>
			<path fill="#D6D5D6" d="M324.5,195c-0.4,0.2-0.7,0.4-1.3,0.7C323.4,194.8,324,194.9,324.5,195z"/>
			<path fill="#E2E1E2" d="M425.8,139.8c-0.3,0-0.3-0.2-0.3-0.4c0-0.2,0.1-0.4,0.4-0.4c0.2,0,0.3,0.2,0.3,0.4
				C426.1,139.6,426,139.8,425.8,139.8z"/>
			<path fill="#DEDDDE" d="M328.9,232.9c0.2,0.1,0.5,0.2,0.6,0.5c0,0.2-0.1,0.3-0.3,0.3c-0.3-0.1-0.5-0.3-0.6-0.5
				C328.5,233,328.7,232.9,328.9,232.9z"/>
			<path fill="#E6E5E5" d="M329.3,237.4c-0.6,0.4-0.6,0.4-1.6,0.1C328.4,237.4,328.8,237.4,329.3,237.4z"/>
			<path fill="#D4D3D5" d="M334,238.2c0.7,0.5,0.8,0.9,0.6,1.8C334.4,239.3,334.2,238.9,334,238.2z"/>
			<path fill="#D5D4D6" d="M427.7,126.5c0.4,0.1,0.9,0.1,1,0.6c0,0.1-0.1,0.2-0.1,0.2C428,127.3,428,126.7,427.7,126.5z"/>
			<path fill="#E3E2E3" d="M420.4,125.8c-0.3,0.3-0.3,0.7-0.4,1.4C419.6,126.3,419.5,125.9,420.4,125.8z"/>
			<path fill="#CFCED0" d="M424.1,125.5c0,0.5,0,0.9-0.5,1c-0.1,0-0.2,0-0.2-0.2C423.3,125.9,423.7,125.8,424.1,125.5z"/>
			<path fill="#E4E4E4" d="M400,107.4c0.5,0.2,0.8,0.3,0.6,0.8c0,0.1-0.3,0.2-0.4,0.2C399.8,108.2,400.1,107.8,400,107.4z"/>
			<path fill="#DADADB" d="M409,114.7c0,0.3-0.3,0.2-0.5,0.2c-0.2,0-0.4,0-0.4-0.3c0-0.2,0.2-0.5,0.5-0.4
				C408.8,114.3,408.9,114.5,409,114.7z"/>
			<path fill="#D0CFD1" d="M400.3,117.9c-0.3-0.2-0.8-0.4-0.6-0.9c0-0.1,0.1-0.1,0.2-0.1C400.4,117.1,400.2,117.6,400.3,117.9z"/>
			<path fill="#E6E5E6" d="M438.4,157.7c0,0.2,0,0.4-0.2,0.3c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.1,0.1-0.3,0.3-0.3
				C438.2,157.3,438.3,157.5,438.4,157.7z"/>
			<path fill="#E4E3E4" d="M313.4,177.7c0.5-0.1,0.9,0,0.9,0.7C313.8,178.4,313.7,177.9,313.4,177.7z"/>
			<path fill="#E0DFE0" d="M352.5,240.9c0.2-0.2,0.4-0.4,0.8-0.8c0,0.7,0,1.1-0.5,1.3C352.6,241.3,352.5,241.2,352.5,240.9z"/>
			<path fill="#D1D1D3" d="M331.1,215.7c-0.1,0.2-0.1,0.5-0.4,0.5c-0.1,0-0.2-0.2-0.3-0.3c0-0.3,0.3-0.4,0.5-0.4
				C331,215.3,331.1,215.5,331.1,215.7z"/>
			<path fill="#EBEAEB" d="M315.9,220.7c-0.2-0.7-0.3-1,0.1-1.2c0.1,0,0.2,0.1,0.3,0.1C316.6,220.1,316,220.1,315.9,220.7z"/>
			<path fill="#EEEDED" d="M356.6,241.9c-0.5-0.4-0.5-0.7-0.2-1c0.1-0.1,0.3-0.1,0.3,0C356.9,241.2,356.5,241.5,356.6,241.9z"/>
			<path fill="#D7D7D8" d="M398.2,106.2c-0.3,0-0.3-0.2-0.3-0.4c0-0.2,0-0.3,0.2-0.4c0.2-0.1,0.4,0.1,0.4,0.3
				C398.5,106,398.4,106.2,398.2,106.2z"/>
			<path fill="#E2E2E2" d="M329.1,235.7c-0.1,0.3-0.4,0.2-0.6,0.3c-0.2,0.1-0.3-0.1-0.3-0.3c0-0.2,0.2-0.4,0.4-0.4
				C328.9,235.4,329.1,235.4,329.1,235.7z"/>
			<path fill="#DAD9DA" d="M312.1,223.1c0.3-0.4,0.4-0.8,0.8-0.6c0.1,0,0.3,0.3,0.3,0.3C313,223.1,312.6,223,312.1,223.1z"/>
			<path fill="#D8D8D9" d="M314.8,223.7c-0.2,0-0.3,0-0.5,0c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.5,0c0.1,0.1,0.1,0.3,0,0.4
				C315.2,224,315,223.7,314.8,223.7z"/>
			<path fill="#CECDCF" d="M319.5,224.8c0,0.5,0,0.8,0,1.3C318.9,225.7,318.9,225.4,319.5,224.8z"/>
			<path fill="#D8D7D9" d="M343.3,225.9c-0.4,0.2-0.7,0-1.2-0.2C342.7,225.2,343,225.6,343.3,225.9z"/>
			<path fill="#F5F4F4" d="M327.4,228.2c0.1,0.6,0.1,0.9-0.2,1c-0.1,0-0.3,0-0.3-0.1C326.7,228.7,327.1,228.6,327.4,228.2z"/>
			<path fill="#D2D1D3" d="M430.5,144.2c-0.3-0.2-0.4-0.5-0.5-0.9c0.5,0.2,0.8,0.3,1.2,0.4C431.1,144.1,430.8,144.2,430.5,144.2z"/>
			<path fill="#D2D1D3" d="M399.9,141.4c0.6,0.4,0.2,0.9,0.4,1.5C399.6,142.4,399.8,142,399.9,141.4z"/>
			<path fill="#CAC9CB" d="M327.2,227.5c-0.1,0.1-0.3,0.4-0.5,0.4c-0.2,0.1-0.4-0.2-0.2-0.4c0.1-0.1-0.1-0.6,0.3-0.4
				C327,227.2,327.3,227.1,327.2,227.5z"/>
			<path fill="#EFEEEE" d="M409.7,113.5c-0.3,0.4-0.6,0.4-0.9,0.2c0,0,0-0.3,0-0.3C409.1,113,409.4,113.3,409.7,113.5z"/>
			<path fill="#F0EEEF" d="M358.2,246.8c0.3,0,0.6-0.1,0.8,0.3c0.1,0.2-0.1,0.5-0.3,0.4C358.3,247.5,358.2,247.2,358.2,246.8z"/>
			<path fill="#D4D3D4" d="M334.7,246c-0.3-0.1-0.8,0.2-0.8-0.3c0-0.1,0.1-0.2,0.2-0.3C334.6,245.3,334.7,245.7,334.7,246L334.7,246
				z"/>
			<path fill="#D2D1D3" d="M402.4,121.7c-0.4,0.2-0.6,0.5-1,0.5c-0.1,0-0.1-0.2-0.1-0.3C401.5,121.6,401.8,121.4,402.4,121.7z"/>
			<path fill="#D2D1D3" d="M400.9,122c-0.4,0.1-0.7,0.2-1-0.1c0,0,0-0.2,0.1-0.3C400.5,121.4,400.7,121.7,400.9,122z"/>
			<path fill="#DADADB" d="M427.6,125.6c-0.4,0.1-0.7,0-1.1-0.3C427,124.9,427.3,125,427.6,125.6z"/>
			<path fill="#E0DFE0" d="M342.1,244c0.3-0.5,0.7-0.3,1.3-0.3C342.9,244.3,342.4,243.7,342.1,244z"/>
			<path fill="#DAD9DA" d="M413.6,120.9c0.3,0,0.7-0.2,0.8,0.3c0,0.1-0.1,0.4-0.1,0.4C413.8,121.6,413.9,121.1,413.6,120.9z"/>
			<path fill="#D3D2D4" d="M333.1,243.3c0.6-0.3,1,0.3,1.4,0.2C334.1,243.7,333.6,243.7,333.1,243.3z"/>
			<path fill="#D5D5D6" d="M421.9,120.5c0.3,0.4,0.3,0.7,0,1.2C421.6,121.2,421.6,120.9,421.9,120.5z"/>
			<path fill="#D3D3D4" d="M318,197.9c0,0.2-0.1,0.4-0.3,0.5c-0.3,0-0.2-0.3-0.3-0.4c-0.1-0.2-0.1-0.4,0.2-0.4
				C317.9,197.6,318,197.7,318,197.9z"/>
			<path fill="#CFCED0" d="M400.6,127.1c0,0.5,0.3,0.9-0.1,1.2c-0.1,0-0.3,0-0.3,0C400.1,127.9,400.2,127.6,400.6,127.1z"/>
			<path fill="#D5D4D6" d="M406.9,110.5c0.4-0.3,0.4-0.8,0.9-0.7c0.1,0,0.2,0.2,0.2,0.2C407.8,110.4,407.5,110.6,406.9,110.5z"/>
			<path fill="#E4E4E4" d="M332.8,214.8c0,0.2,0,0.4-0.3,0.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.1,0.1-0.3,0.3-0.4
				C332.7,214.3,332.8,214.6,332.8,214.8z"/>
			<path fill="#F0EFF0" d="M321.4,215.4c0-0.5,0.3-0.6,0.6-0.8c0.4,0.2,0.1,0.4,0,0.6C321.9,215.4,321.7,215.5,321.4,215.4z"/>
			<path fill="#CBCACC" d="M326.5,197.4c0,0.4-0.3,0.6-0.6,0.7c-0.3-0.3,0-0.4,0.1-0.6C326.1,197.4,326.2,197.3,326.5,197.4z"/>
			<path fill="#EAE9EA" d="M320.8,198c0.1,0.3,0.8,0.1,0.5,0.7c-0.1,0-0.1,0-0.2,0c-0.3,0.1-0.4-0.1-0.5-0.3
				C320.2,198.1,320.5,198.1,320.8,198z"/>
			<path fill="#D4D3D5" d="M331.5,201.8c-0.3,0.3-0.7,0.4-1,0C330.8,201.5,331.2,201.9,331.5,201.8z"/>
			<path fill="#EEEDED" d="M323.1,204.1c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.4,0.3c-0.2,0-0.4-0.1-0.4-0.3
				C322.7,204.2,323,204.2,323.1,204.1z"/>
			<path fill="#E9E7E8" d="M436.3,145.9c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0.4-0.7,0.2-1.1,0.3C435.6,146,435.9,146,436.3,145.9z"/>
			<path fill="#CFCFD1" d="M325.1,199c0,0.2-0.1,0.4-0.3,0.3c-0.2,0-0.3-0.2-0.2-0.4c0.1-0.2,0.1-0.4,0.4-0.4
				C325.1,198.6,325.1,198.8,325.1,199z"/>
			<path fill="#EBEAEA" d="M317,216.5c0.1-0.2,0.1-0.5,0.2-0.7c0.2-0.7,0.5-0.2,0.7,0c-0.1,0.4-0.4,0.6-0.7,0.7l-0.1,0L317,216.5z"
				/>
			<path fill="#F0EFF0" d="M326.8,213.4c0.5-0.3,0.7,0,0.7,0.5C327.1,213.9,326.6,214.3,326.8,213.4z"/>
			<path fill="#D4D3D5" d="M317.2,216.5c0.2-0.3,0.4-0.5,0.7-0.7C318.1,216.4,318,216.6,317.2,216.5z"/>
			<path fill="#CFCED0" d="M344.6,216.3c0,0.2-0.1,0.4-0.4,0.3c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.2,0.2-0.3,0.4-0.3
				C344.6,216,344.6,216.2,344.6,216.3z"/>
			<path fill="#D1D0D2" d="M314.2,170.7c0.4,0.3,0.3,0.7,0.2,1.3C314.1,171.5,313.8,171.2,314.2,170.7z"/>
			<path fill="#F1F1F1" d="M328.3,213.6c0.1-0.2,0.2-0.5,0.2-0.7c0.3-0.1,0.6-0.1,0.8-0.2C329,213.1,328.8,213.5,328.3,213.6z"/>
			<path fill="#DCDBDC" d="M314.9,186.3c0.7-0.1,0.8,0.3,0.9,0.8C315.3,186.9,315,186.8,314.9,186.3z"/>
			<path fill="#DEDDDE" d="M315.9,188.3c0.3,0,0.6,0,0.9,0C316.6,188.7,316.2,188.7,315.9,188.3z"/>
			<path fill="#D3D2D3" d="M334.5,211.4c0.1-0.4,0.1-0.7,0.6-0.6c0.1,0,0.2,0.2,0.2,0.2C335.2,211.5,334.8,211.3,334.5,211.4
				L334.5,211.4z"/>
			<path fill="#DEDDDF" d="M326.8,218.6c0.1-0.2,0.2-0.5,0.3-0.7c0.3,0,0.6,0,0.5,0.4C327.5,218.7,327.1,218.6,326.8,218.6z"/>
			<path fill="#ECEBEB" d="M331.8,220.1c-0.1,0.2-0.2,0.4-0.4,0.3c-0.2,0-0.3-0.2-0.3-0.4c0-0.2,0.2-0.2,0.3-0.2
				C331.6,219.8,331.7,219.9,331.8,220.1z"/>
			<path fill="#D4D3D5" d="M315.9,194.4c0.4-0.1,0.8,0,1,0.5C316.4,195,316.2,194.6,315.9,194.4z"/>
			<path fill="#DBDADB" d="M434,149c-0.3,0-0.5-0.1-0.6-0.4c0-0.1,0.1-0.2,0.2-0.2c0.2,0.1,0.5,0.1,0.5,0.4
				C434.2,148.9,434.1,149,434,149z"/>
			<path fill="#CECED0" d="M321.1,236.9c-0.5,0.3-0.8,0.4-1.1,0C320.4,236.6,320.6,236.6,321.1,236.9z"/>
			<path fill="#D9D8D9" d="M334.3,237.2c0.1-0.6,0.2-1,0.8-0.9C334.9,236.6,334.8,236.9,334.3,237.2z"/>
			<path fill="#E6E5E5" d="M323.7,245c-0.5,0.3-0.8,0.3-1.1-0.1C323,244.7,323.3,244.8,323.7,245z"/>
			<path fill="#D2D2D3" d="M339.2,237.2c-0.1-0.6-0.2-0.9-0.3-1.5C339.3,236.2,339.7,236.4,339.2,237.2z"/>
			<path fill="#D5D5D6" d="M408.6,135.4c0,0.3-0.2,0.4-0.5,0.4c-0.2,0-0.4-0.1-0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.3
				C408.4,135.2,408.5,135.2,408.6,135.4z"/>
			<path fill="#F0EFF0" d="M434,135.6c0.1-0.4,0.2-0.7,0.6-0.8c0,0,0.2,0.2,0.2,0.2C434.7,135.5,434.5,135.6,434,135.6z"/>
			<path fill="#E8E6E7" d="M404.3,111.4c-0.1,0.3-0.3,0.3-0.5,0.2c-0.1,0-0.3-0.2-0.3-0.3c0-0.2,0.2-0.3,0.4-0.3
				C404.1,111,404.3,111.1,404.3,111.4z"/>
			<path fill="#EBEBEB" d="M314.6,232.8c0.1-0.4,0.3-0.6,0.7-0.6c0.2,0.3,0,0.4-0.2,0.6C315,232.8,314.9,233,314.6,232.8z"/>
			<path fill="#EEEDED" d="M344,245.1c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.1,0.3-0.4,0.3c-0.2,0-0.3-0.2-0.4-0.4
				C343.7,245.1,343.9,245.1,344,245.1z"/>
			<path fill="#DFDEDF" d="M398,108.4c0.2,0.1,0.3,0.2,0.3,0.4c0,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.3-0.2-0.2-0.3
				C397.8,108.7,397.8,108.5,398,108.4z"/>
			<path fill="#E9E8E8" d="M328.6,230.9c-0.4,0.3-0.7,0.2-1.1,0C328,230.5,328.3,230.7,328.6,230.9z"/>
			<path fill="#DDDCDD" d="M317.8,246.6c-0.2,0.4-0.3,0.6-0.7,0.6c-0.1,0-0.2-0.2-0.2-0.2C317.1,246.6,317.4,246.5,317.8,246.6z"/>
			<path fill="#D5D5D6" d="M401.9,110.4c-0.2,0-0.3-0.1-0.3-0.2c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.3,0.1,0.3,0.3
				C402.1,110.2,402.1,110.3,401.9,110.4z"/>
			<path fill="#D3D2D4" d="M333.7,242.6c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.3,0.3-0.3
				C333.5,242.2,333.6,242.4,333.7,242.6z"/>
			<path fill="#C9C9CB" d="M318.3,241.9c0.2,0,0.3,0,0.3,0.2c0,0.2,0,0.4-0.2,0.4c-0.1,0-0.3-0.1-0.3-0.2
				C318,242.1,318.1,242,318.3,241.9z"/>
			<path fill="#E7E6E6" d="M411.3,129.2c0,0.2-0.1,0.4-0.4,0.3c-0.1,0-0.2-0.2-0.2-0.3c0-0.2,0.2-0.3,0.4-0.3
				C411.3,128.8,411.3,129,411.3,129.2z"/>
			<path fill="#C8C8CA" d="M435.5,142.3c0.3-0.8-0.7-0.3-0.7-0.7c0.3,0,0.5,0,0.8,0c0.2,0,0.5,0.1,0.5,0.4
				C436,142.3,435.8,142.3,435.5,142.3z"/>
			<path fill="#DEDDDE" d="M429.4,142.1c0.1-0.4,0.1-0.8,0.5-0.8c0.1,0,0.2,0.1,0.2,0.2C430.2,141.9,429.8,141.9,429.4,142.1z"/>
			<path fill="#EDECEC" d="M322.7,228.7c0.1-0.2,0.3-0.2,0.5-0.3c0.3,0.3,0.1,0.7-0.1,0.8C322.8,229.4,322.8,229,322.7,228.7z"/>
			<path fill="#E7E6E7" d="M328.9,241.9c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.2,0.2,0.3,0.3
				C329.2,241.6,329.2,241.8,328.9,241.9z"/>
			<path fill="#D5D5D6" d="M320.2,229.3c0.2,0,0.3,0,0.3,0.2c0,0.2-0.1,0.4-0.4,0.4c-0.1,0-0.3-0.1-0.4-0.2
				C319.8,229.4,320,229.4,320.2,229.3z"/>
			<path fill="#FAFAFA" d="M429.7,129.9c0.1,0.5,0.4,0.7,0.2,1c0,0-0.2,0-0.3,0C429.2,130.6,429.4,130.4,429.7,129.9z"/>
			<path fill="#DAD9DA" d="M320.3,247.4c0.4,0.5,0.4,0.8,0,1.4C320.3,248.2,320.3,247.9,320.3,247.4z"/>
			<path fill="#DEDDDE" d="M328.2,211c0.2,0,0.3,0.2,0.3,0.3c0,0.2-0.1,0.3-0.3,0.2c-0.1-0.1-0.2-0.2-0.3-0.3
				C327.9,211,328,211,328.2,211z"/>
			<path fill="#F3F2F2" d="M359.8,243.8c-0.5-0.3-0.4-0.6-0.1-1.1C360,243.1,359.9,243.4,359.8,243.8z"/>
			<path fill="#DEDDDE" d="M344.2,243.2c0.2,0.1,0.3,0.2,0.3,0.4c0,0.1-0.2,0.2-0.3,0.3c-0.1-0.2-0.2-0.3-0.3-0.5
				C343.9,243.3,344,243.2,344.2,243.2z"/>
			<path fill="#ECEBEB" d="M318.6,211.6c0,0.2-0.2,0.4-0.4,0.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.4,0.3-0.4
				C318.5,211.3,318.6,211.4,318.6,211.6z"/>
			<path fill="#D0CFD1" d="M313.9,211.5c0.2,0.1,0.4,0.2,0.4,0.4c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.2-0.3-0.4
				C313.7,211.7,313.7,211.6,313.9,211.5z"/>
			<path fill="#E7E7E7" d="M322.2,199.5c-0.1-0.2-0.1-0.5-0.2-0.7c0.4,0,0.7,0.3,0.6,0.7C322.6,199.7,322.4,199.6,322.2,199.5z"/>
			<path fill="#E0DFE0" d="M328.1,201.4c0.4,0.4,0.4,0.7,0.1,1C327.9,202.1,328,201.9,328.1,201.4z"/>
			<path fill="#E6E6E6" d="M346.3,248.9c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.3-0.2-0.3-0.3
				C346.1,249.1,346.1,249,346.3,248.9z"/>
			<path fill="#D7D7D8" d="M328.7,249.2c0.2-0.8,0.5-1,1.2-1.1C329.5,248.5,329.2,248.8,328.7,249.2z"/>
			<path fill="#EBEAEB" d="M334.2,202c-0.2,0.4-0.4,0.4-0.5,0.3c-0.2-0.1-0.4-0.2-0.3-0.4c0-0.2,0.2-0.2,0.4-0.1
				C333.9,201.8,334.1,201.9,334.2,202z"/>
			<path fill="#EFEFEF" d="M316.2,248.8c0.1-0.7,0.4-0.7,0.8-0.9C317,248.4,316.7,248.6,316.2,248.8z"/>
			<path fill="#CFCED0" d="M327.6,204.3c-0.1,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.3,0.3-0.2
				C327.4,204.2,327.5,204.3,327.6,204.3z"/>
			<path fill="#F3F3F2" d="M328.8,206.6c0.2,0.5-0.1,0.7-0.1,1.1C328.2,207.3,328.4,207,328.8,206.6z"/>
			<path fill="#F6F5F5" d="M324.4,207.3c0,0.2-0.2,0.3-0.3,0.2c-0.1-0.1-0.3-0.2-0.3-0.3c0-0.2,0.2-0.2,0.3-0.2
				C324.3,206.9,324.4,207.1,324.4,207.3z"/>
			<path fill="#D7D6D8" d="M335.5,246c0-0.3-0.5-0.4-0.3-0.8c0,0,0.2,0,0.3,0C335.7,245.5,335.8,245.7,335.5,246L335.5,246z"/>
			<path fill="#D1D0D2" d="M314.3,207.9c0.6,0,0.5,0.5,0.6,0.9C314.3,208.6,314.4,208.2,314.3,207.9
				C314.3,207.8,314.3,207.9,314.3,207.9z"/>
			<path fill="#F1F1F1" d="M319.4,208.9c-0.2-0.1-0.4-0.2-0.4-0.4c0-0.2,0.2-0.2,0.4-0.1c0.2,0,0.3,0.1,0.3,0.3
				C319.8,208.8,319.6,208.9,319.4,208.9z"/>
			<path fill="#CDCDCF" d="M316.3,245.1c0,0.1,0.1,0.2,0.1,0.2c0,0.3-0.2,0.4-0.4,0.4c-0.1,0-0.2-0.1-0.2-0.2
				C316,245.5,316,245.2,316.3,245.1z"/>
			<path fill="#CFCFD0" d="M344.6,209.8c-0.2,0-0.4,0-0.3-0.3c0-0.1,0.2-0.3,0.3-0.3c0.3,0,0.3,0.2,0.3,0.4
				C344.9,209.8,344.7,209.7,344.6,209.8z"/>
			<path fill="#EBEAEA" d="M348.1,231.1c-0.2,0.3-0.4,0.5-0.7,0.3c-0.1,0-0.2-0.3-0.1-0.3C347.6,230.8,347.8,231.1,348.1,231.1z"/>
			<path fill="#DBDADB" d="M319.1,221.8c0.2,0.7-0.3,0.7-0.6,1.1C318.5,222.2,318.9,222.1,319.1,221.8z"/>
			<path fill="#E8E7E7" d="M345.2,231.2c-0.1-0.4,0-0.7,0.4-0.8c0,0,0.2,0.1,0.2,0.1C345.7,231,345.4,231.1,345.2,231.2z"/>
			<path fill="#DDDCDE" d="M320.3,223.2c-0.1,0.1-0.1,0.3-0.2,0.3c-0.2,0.1-0.2-0.2-0.3-0.3c0-0.2,0-0.3,0.2-0.4
				C320.3,222.8,320.3,223,320.3,223.2z"/>
			<path fill="#CDCCCE" d="M360.7,231.1c-0.2-0.3-0.4-0.5-0.6-0.8C360.4,230.5,361.2,230.4,360.7,231.1L360.7,231.1z"/>
			<path fill="#D0CFD1" d="M330.1,223.3c0.3,0,0.6,0.1,0.7,0.4c0,0-0.2,0.2-0.2,0.2C330.2,223.9,330.1,223.6,330.1,223.3z"/>
			<path fill="#DFDEDF" d="M318.1,230.7c0,0.3-0.1,0.4-0.3,0.4c-0.2,0-0.3-0.2-0.3-0.3c0-0.1-0.1-0.4,0.2-0.3
				C317.8,230.4,317.9,230.6,318.1,230.7z"/>
			<path fill="#E7E6E7" d="M346.7,230.3c-0.4-0.1-0.7-0.1-0.7-0.5c0-0.1,0.1-0.2,0.2-0.2C346.6,229.6,346.6,229.9,346.7,230.3z"/>
			<path fill="#CECDCF" d="M327,225.4c0,0.2-0.2,0.4-0.3,0.3c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.1,0.1-0.2,0.2-0.2
				C326.9,225,327,225.2,327,225.4z"/>
			<path fill="#D8D7D9" d="M354.4,225.6c0.2-0.2,0-0.6,0.5-0.6c0.1,0,0.2,0.1,0.2,0.2C355,225.5,354.8,225.6,354.4,225.6z"/>
			<path fill="#D2D1D3" d="M313.8,228.9c0.2,0.6,0,0.8-0.4,1.2C313.3,229.6,313.5,229.4,313.8,228.9z"/>
			<path fill="#E8E7E8" d="M337.8,225.5c0,0.3-0.1,0.4-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3
				C337.6,225.2,337.7,225.4,337.8,225.5z"/>
			<path fill="#DDDCDD" d="M323.5,225.9c0.3-0.4,0.6-0.4,0.9-0.4C324.2,226,324,226.2,323.5,225.9z"/>
			<path fill="#E2E1E1" d="M325.1,227.2c-0.2,0.8-0.5,0.9-1.2,1.1C324.4,227.9,324.7,227.7,325.1,227.2z"/>
			<path fill="#DDDCDD" d="M338.9,227.7c-0.2-0.3-0.2-0.6,0-0.9c0.1,0,0.2,0,0.2,0C339.4,227.1,339.4,227.4,338.9,227.7z"/>
			<path fill="#C9C9CB" d="M319.4,242.2c0,0.2,0,0.4,0.2,0.5c-0.1,0.1-0.2,0.4-0.2,0.3c-0.4-0.1-0.4-0.5-0.4-0.8
				C319,242,319.2,242.1,319.4,242.2z"/>
			<path fill="#D3D2D4" d="M323,215.1c0.1,0.2,0.4,0.5,0,0.7c-0.1,0.1-0.4,0.1-0.4,0c-0.3-0.3,0.2-0.4,0.2-0.7
				C322.8,215,322.9,215,323,215.1L323,215.1z"/>
			<path fill="#D1D1D3" d="M354.9,241.3c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.3,0.2,0.2,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
				C355,241.6,354.9,241.5,354.9,241.3z"/>
			<path fill="#E9E9E9" d="M348.5,240.7c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0-0.4,0.2-0.3c0.1,0,0.3,0.2,0.3,0.3
				C348.8,240.5,348.7,240.7,348.5,240.7z"/>
			<path fill="#E3E2E3" d="M314.8,216.1c0-0.2,0.1-0.3,0.4-0.3c0.2,0,0.3,0.2,0.2,0.3c0,0.1-0.2,0.3-0.3,0.3
				C315,216.4,314.9,216.3,314.8,216.1z"/>
			<path fill="#DAD9DB" d="M339.1,240c0.1-0.2,0.2-0.3,0.2-0.5c0.2-0.1,0.5-0.2,0.7-0.2h0C340.1,240.1,339.6,240,339.1,240z"/>
			<path fill="#DCDBDC" d="M330.9,218.3c0.3-0.3,0.6-0.3,1-0.2C331.6,218.6,331.2,218.3,330.9,218.3z"/>
			<path fill="#F0EFEF" d="M327.2,236.1c-0.4-0.1-0.6-0.2-0.7-0.5c0,0,0.1-0.2,0.2-0.2C327,235.5,327.1,235.7,327.2,236.1z"/>
			<path fill="#D1D0D2" d="M317.7,220.8c-0.2-0.3-0.2-0.6,0.1-0.8c0,0,0.3,0,0.3,0.1C318.2,220.5,318,220.7,317.7,220.8L317.7,220.8
				z"/>
			<path fill="#E8E7E8" d="M321.5,220.3c-0.1,0.1-0.2,0.1-0.2,0.2c-0.2,0-0.5,0.1-0.6-0.2c-0.1-0.2,0.1-0.3,0.2-0.3
				C321.3,219.9,321.4,220.1,321.5,220.3z"/>
			<path fill="#DBDADB" d="M339.7,232.8c0.3-0.6,0.5-0.8,1-0.9C340.5,232.4,340.2,232.5,339.7,232.8z"/>
			<path fill="#D8D8D9" d="M332.8,220.7c-0.2,0-0.3-0.2-0.4-0.4c0-0.2,0.1-0.3,0.2-0.3c0.2,0,0.3,0.2,0.3,0.4
				C333,220.6,333,220.7,332.8,220.7z"/>
			<path fill="#DEDDDE" d="M315.3,221.8c0,0.2-0.1,0.4-0.3,0.3c-0.1-0.1-0.2-0.3-0.3-0.5c0,0,0.1-0.2,0.2-0.2
				C315.1,221.4,315.2,221.5,315.3,221.8z"/>
			<path fill="#DEDDDF" d="M327.8,196.7c0.4,0.1,0.6,0.3,0.6,0.6c0,0.1-0.2,0.2-0.2,0.1C327.9,197.3,327.8,197.1,327.8,196.7z"/>
			<path fill="#CCCBCD" d="M413.9,126.4c0.3-0.5,0.7-0.5,1-0.6C414.8,126.3,414.5,126.4,413.9,126.4z"/>
			<path fill="#CFCED0" d="M422,126.5c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3
				C422.3,126.3,422.2,126.4,422,126.5z"/>
			<path fill="#ECEBEB" d="M411.6,110.4c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1-0.3-0.7-0.6-0.3-0.8C411.3,109.6,411.5,110.1,411.6,110.4z"
				/>
			<path fill="#D0CFD1" d="M325.9,195.7c-0.4,0-0.7-0.1-0.8-0.5c0-0.1,0.1-0.2,0.1-0.2C325.6,195,325.7,195.3,325.9,195.7z"/>
			<path fill="#D9D8DA" d="M335.9,195.1c0,0.2,0,0.3-0.2,0.4c-0.2,0.1-0.2-0.2-0.3-0.3c0-0.2,0-0.3,0.2-0.4
				C335.9,194.7,335.9,194.9,335.9,195.1z"/>
			<path fill="#E3E2E3" d="M430.2,128.6c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0-0.4,0.2-0.3c0.1,0,0.3,0.2,0.3,0.3
				C430.5,128.5,430.4,128.6,430.2,128.6z"/>
			<path fill="#DBDADB" d="M405,110.5c-0.6,0-0.9,0-0.9-0.4c0-0.1,0.1-0.2,0.2-0.3C404.4,110,404.6,110.2,405,110.5z"/>
			<path fill="#D1D0D2" d="M422.9,133.7c0.2,0.5,0,0.7-0.3,0.9C422.3,134.2,422.6,134,422.9,133.7z"/>
			<path fill="#F2F2F2" d="M432.5,135.7c-0.1-0.3-0.1-0.6,0.2-0.8c0.1,0,0.3,0,0.3,0.1C433,135.3,432.9,135.6,432.5,135.7z"/>
			<path fill="#F0F0F0" d="M401.6,107.7c-0.4,0-0.6-0.1-0.7-0.4c0,0,0.1-0.2,0.2-0.2C401.4,107,401.5,107.3,401.6,107.7z"/>
			<path fill="#E8E8E8" d="M327.2,193.6c0,0.2-0.1,0.4-0.3,0.5c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.2,0.1-0.4,0.2-0.5
				C327.1,193.3,327.2,193.5,327.2,193.6z"/>
			<path fill="#D1D1D2" d="M422.9,138.8c0.1,0.4,0,0.6-0.3,0.8c0,0-0.2-0.1-0.2-0.1C422.4,139.2,422.6,138.9,422.9,138.8z"/>
			<path fill="#D8D7D8" d="M323.5,187.6c0.5,0.3,0.4,0.7,0.5,1C323.6,188.3,323.4,188.1,323.5,187.6z"/>
			<path fill="#D1D1D2" d="M317.1,184.9c0.1-0.4,0.4-0.5,0.6-0.5c0,0,0.2,0.2,0.1,0.2C317.6,184.8,317.5,185,317.1,184.9z"/>
			<path fill="#DCDBDC" d="M403,112.1c0,0.3,0,0.4-0.2,0.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0-0.4,0.2-0.3
				C402.8,112,402.9,112.1,403,112.1z"/>
			<path fill="#E8E7E7" d="M405.2,115.8c-0.3,0.2-0.6,0.3-0.9,0.3c0-0.1-0.1-0.2,0-0.2C404.5,115.6,404.7,115.5,405.2,115.8z"/>
			<path fill="#E3E2E3" d="M413,117.1c0.4,0,0.6,0,0.8,0.3c0,0-0.1,0.2-0.2,0.2C413.3,117.6,413.1,117.5,413,117.1z"/>
			<path fill="#D1D1D2" d="M335.2,197.5c-0.3,0.1-0.6,0.1-0.9,0c0,0,0-0.3,0-0.3C334.7,197,334.9,197.3,335.2,197.5z"/>
			<path fill="#E2E1E2" d="M413.1,123c0.4,0.4,0.4,0.7,0,1C412.9,123.6,412.9,123.4,413.1,123z"/>
			<path fill="#E7E6E6" d="M420.8,121.2c-0.1,0.4-0.2,0.7-0.5,0.6c-0.1,0-0.2-0.1-0.2-0.2C420.1,121.4,420.4,121.3,420.8,121.2z"/>
			<path fill="#E0DFE0" d="M412,118.1c0-0.1,0-0.2,0-0.2c0.2,0,0.5-0.1,0.6,0.2c0.1,0.2-0.1,0.4-0.3,0.4
				C412.1,118.5,412.1,118.3,412,118.1z"/>
			<path fill="#EBEAEB" d="M438.1,149.5c0.2,0,0.3,0.2,0.3,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.2-0.1-0.4-0.1-0.3-0.4
				C437.9,149.6,438,149.6,438.1,149.5z"/>
			<path fill="#D1D0D2" d="M317.7,220.8c0,0.3,0.1,0.6-0.4,0.8C317.2,221.2,317.2,220.9,317.7,220.8
				C317.7,220.8,317.7,220.8,317.7,220.8z"/>
			<path fill="#D2D2D3" d="M438.3,147.4c0.1,0.1,0.3,0.2,0.2,0.2c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0-0.3-0.2
				C438.1,147.7,438.2,147.5,438.3,147.4z"/>
			<path fill="#DEDDDF" d="M409.1,117.9c-0.2,0.1-0.3,0.3-0.4,0.3c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3
				C408.9,117.7,409,117.8,409.1,117.9z"/>
			<path fill="#E5E5E5" d="M321.8,224.5c0.1-0.4,0.4-0.5,0.8-0.5C322.4,224.4,322.3,224.7,321.8,224.5z"/>
			<path fill="#D4D3D5" d="M334.5,225c-0.2,0.1-0.3,0.2-0.4,0.3c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2
				C334.3,224.7,334.4,224.9,334.5,225z"/>
			<path fill="#EAE9EA" d="M430.5,144.2c0.2-0.2,0.5-0.3,0.7-0.5c0.5,0.4,0,0.5-0.2,0.7C430.8,144.4,430.6,144.4,430.5,144.2
				L430.5,144.2z"/>
			<path fill="#E9E8E8" d="M420.2,119.3c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.1,0.3,0,0.3,0.2c0,0.1-0.2,0.2-0.3,0.3
				C420.5,119.5,420.3,119.4,420.2,119.3z"/>
			<path fill="#D3D3D4" d="M335.1,226.5c-0.4-0.1-0.5-0.4-0.5-0.8C334.9,225.9,335.2,226.1,335.1,226.5z"/>
			<path fill="#C8C8CA" d="M436,143.3c-0.1,0.2-0.3,0.2-0.5,0.2c0-0.3-0.5-0.4-0.2-0.6C435.7,142.6,435.8,143.2,436,143.3z"/>
			<path fill="#CAC9CB" d="M328.4,227.5c0,0.2-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.2-0.2-0.2c0.1-0.1,0.2-0.3,0.3-0.3
				C328.3,227.2,328.4,227.3,328.4,227.5z"/>
			<path fill="#CECDCF" d="M379.2,142.6c0.3-0.3,0.5-0.3,0.7-0.1c0,0,0.1,0.2,0,0.2C379.7,142.8,379.5,142.7,379.2,142.6z"/>
			<path fill="#E5E4E5" d="M432,151.2c0.2-0.1,0.3-0.2,0.5-0.2c0,0.3,0,0.6,0,1C432,151.8,432,151.5,432,151.2z"/>
			<path fill="#C7C6C9" d="M324.4,219.6c0.1,0.2,0.3,0.2,0.5,0.2c0,0.3-0.2,0.6-0.5,0.4C324.1,220.1,324.2,219.8,324.4,219.6z"/>
			<path fill="#D0CFD1" d="M338,219.7c0.7-0.3,0.9-0.1,1.3,0.4C338.8,219.9,338.5,219.9,338,219.7z"/>
			<path fill="#DFDEDF" d="M333.8,219.1c-0.2,0.1-0.3,0.2-0.3,0.2c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1-0.2,0.1-0.3,0.3-0.2
				C333.6,218.8,333.6,219,333.8,219.1z"/>
			<path fill="#DCDBDC" d="M437.4,155.1c-0.2-0.1-0.2-0.3-0.1-0.4c0-0.2,0.1-0.3,0.3-0.2c0.1,0,0.1,0.2,0.1,0.3
				C437.6,154.9,437.6,155.1,437.4,155.1z"/>
			<path fill="#D1D1D3" d="M440.5,159.1c0,0-0.2,0.1-0.3,0.1c-0.2,0.1-0.3-0.1-0.3-0.2c0-0.1,0.2-0.2,0.3-0.3
				C440.4,158.7,440.5,158.8,440.5,159.1z"/>
			<path fill="#DEDDDE" d="M312.1,217.9c0.1,0,0.3,0.1,0.3,0.2c0.1,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.3-0.1-0.4-0.2
				C311.7,218.1,311.9,218,312.1,217.9z"/>
			<path fill="#F7F7F7" d="M318.6,175.6c0,0.1-0.1,0.2-0.2,0.2c-0.3,0.1-0.3-0.2-0.4-0.3c0-0.2,0.1-0.2,0.2-0.2
				C318.5,175.2,318.6,175.3,318.6,175.6z"/>
			<path fill="#DBDBDC" d="M357.1,245.2c-0.1,0.2,0,0.4-0.3,0.3c-0.1,0-0.2-0.2-0.2-0.4c0-0.1,0-0.3,0.2-0.3
				C357,245,357,245.2,357.1,245.2z"/>
			<path fill="#EFEEEE" d="M332.5,244.5c0,0.3-0.2,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.3,0.3-0.3
				C332.3,244.2,332.4,244.4,332.5,244.5z"/>
			<path fill="#EDECEC" d="M394.9,110.3c-0.3,0.3-0.6,0.2-0.8,0.1c0,0,0-0.3,0-0.3C394.4,110,394.6,110.1,394.9,110.3z"/>
			<path fill="#CECDCF" d="M328.1,246.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0.1-0.3,0-0.3-0.2c0-0.2,0.1-0.2,0.3-0.2
				C327.9,246.2,328,246.2,328.1,246.5z"/>
			<path fill="#CECDCF" d="M401.9,124.2c-0.2-0.5-0.1-0.7,0-1C402.3,123.5,402.2,123.7,401.9,124.2z"/>
			<path fill="#D5D4D6" d="M349.4,242.9c0,0.2-0.1,0.4-0.3,0.3c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1,0.1-0.3,0.3-0.2
				C349.3,242.7,349.3,242.9,349.4,242.9z"/>
			<path fill="#D2D2D4" d="M336.7,248.6c-0.5-0.2-0.7-0.4-0.7-0.7c0.2-0.2,0.3,0,0.4,0.2C336.5,248.2,336.6,248.3,336.7,248.6z"/>
			<path fill="#EAE9EA" d="M422.6,130.5c0.2,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.1-0.3-0.2-0.4-0.3
				C422.3,130.7,422.5,130.6,422.6,130.5z"/>
			<path fill="#D4D3D4" d="M319.2,239.5c0.2,0.1,0.5,0,0.6,0.2c0.1,0.4-0.4,0.4-0.6,0.6c0,0,0,0,0,0
				C318.9,240,319,239.8,319.2,239.5L319.2,239.5z"/>
			<path fill="#F1EFF0" d="M350.5,248.4c0.1,0,0.3,0.1,0.3,0.2c0.1,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.3-0.1-0.4-0.2
				C350.1,248.6,350.3,248.5,350.5,248.4z"/>
			<path fill="#FAF9F9" d="M341.9,238.6c-0.1,0.2-0.2,0.3-0.3,0.2c-0.2,0-0.3-0.2-0.2-0.4c0-0.1,0.2-0.2,0.3-0.2
				C341.7,238.3,341.9,238.4,341.9,238.6z"/>
			<path fill="#D2D1D3" d="M345.8,236.9c-0.2,0.1-0.3,0.2-0.3,0.2c-0.1-0.1-0.3-0.2-0.3-0.3c0-0.2,0.1-0.3,0.3-0.2
				C345.6,236.6,345.6,236.7,345.8,236.9z"/>
			<path fill="#D7D6D7" d="M415.6,137.6c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.4,0-0.5-0.2c-0.1-0.2,0.1-0.2,0.2-0.2
				C415.2,137.3,415.4,137.3,415.6,137.6z"/>
			<path fill="#E4E3E3" d="M347.4,234c0,0.4,0,0.8-0.3,0.8c-0.3,0-0.2-0.4,0-0.5C347.1,234.2,347.2,234.1,347.4,234z"/>
			<path fill="#D4D4D5" d="M325.5,234.4c0,0.2-0.2,0.3-0.3,0.2c-0.2,0-0.2-0.2-0.2-0.4c0-0.1,0.2-0.2,0.2-0.2
				C325.3,234.1,325.5,234.2,325.5,234.4z"/>
			<path fill="#E6E5E6" d="M430.9,137.3c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.2,0.3-0.3,0.2c-0.1,0-0.2-0.2-0.2-0.2
				C430.5,137.4,430.7,137.3,430.9,137.3z"/>
			<path fill="#F6F6F6" d="M404.1,137.9c0-0.2,0.1-0.3,0.3-0.2c0.1,0,0.2,0.2,0.2,0.2c-0.1,0.1-0.2,0.3-0.3,0.3
				C404.2,138.2,404.1,138.1,404.1,137.9z"/>
			<path fill="#E6E5E6" d="M426.7,138.4c-0.2,0-0.3-0.1-0.3-0.2c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.2
				C426.9,138.2,426.9,138.4,426.7,138.4z"/>
			<path fill="#DDDCDD" d="M394,105.7c0.3-0.4,0.6-0.4,0.9-0.2c0,0,0.1,0.2,0,0.2C394.6,105.9,394.4,105.7,394,105.7z"/>
			<path fill="#E9E8E8" d="M329.3,230.2c0.1,0.1,0.3,0.2,0.3,0.2c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0-0.3-0.2
				C329.1,230.5,329.2,230.3,329.3,230.2z"/>
			<path fill="#EBEAEB" d="M340.9,228.2c-0.5,0-0.7-0.3-0.8-0.7C340.5,227.5,340.7,227.8,340.9,228.2z"/>
			<path fill="#D5D4D5" d="M323.1,207.8c-0.2,0.5-0.4,0.6-0.7,0.7c-0.2-0.3,0-0.4,0.1-0.5C322.7,208,322.9,208,323.1,207.8z"/>
			<path fill="#DBDADB" d="M321.5,186.8c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2
				C321.3,186.5,321.5,186.6,321.5,186.8z"/>
			<path fill="#D3D3D4" d="M314.2,214.8c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.2,0.4-0.2
				C314.1,214.4,314.2,214.6,314.2,214.8z"/>
			<path fill="#D9D8D9" d="M320.3,195.6c-0.2,0.1-0.5,0.2-0.7,0.2c0-0.3,0.1-0.6,0.4-0.6C320.3,195.2,320.3,195.4,320.3,195.6z"/>
			<path fill="#EAE8E9" d="M331.2,205.9c-0.3,0-0.3-0.2-0.4-0.4c0-0.2,0-0.4,0.2-0.3c0.1,0.1,0.2,0.3,0.3,0.5
				C331.3,205.7,331.2,205.8,331.2,205.9z"/>
			<path fill="#D3D2D4" d="M317.6,191.9c-0.1-0.3,0.1-0.7,0.3-0.7c0.4,0,0,0.3,0,0.5C317.9,191.8,317.8,191.9,317.6,191.9z"/>
			<path fill="#D2D2D4" d="M312.6,215.5c-0.2-0.4,0-0.6,0.4-0.9C313,215,313,215.3,312.6,215.5z"/>
			<path fill="#D6D5D7" d="M311.9,197.5c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.3,0,0.3,0.2
				C312.3,197.4,312.1,197.5,311.9,197.5z"/>
			<path fill="#F2F2F2" d="M326.7,205.6c0.3,0.1,0.5,0.4,0.3,0.6c-0.3,0.3-0.3-0.1-0.4-0.3C326.6,205.8,326.6,205.7,326.7,205.6z"/>
			<path fill="#EDEBEC" d="M312.4,203.7c0.2-0.1,0.2,0.1,0.2,0.2c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.2-0.1-0.2-0.2
				C312.1,204,312.2,203.8,312.4,203.7z"/>
			<path fill="#DEDDDE" d="M331,210.2c0,0.2-0.2,0.2-0.3,0.3c-0.1,0-0.3,0-0.4-0.1c0-0.1,0.2-0.2,0.3-0.3
				C330.8,210,331,210.1,331,210.2z"/>
			<path fill="#E1E0E1" d="M326.4,208.2c-0.2,0-0.2-0.2-0.3-0.3c0-0.1,0-0.3,0.1-0.4c0.2,0,0.2,0.2,0.3,0.3
				C326.6,208,326.5,208.2,326.4,208.2z"/>
			<path fill="#D6D5D7" d="M337.2,213.6c0.5,0.2,0.9,0.2,0.8,0.5c-0.1,0.4-0.3,0.1-0.5-0.1C337.4,214,337.3,213.9,337.2,213.6z"/>
			<path fill="#E8E7E8" d="M313.6,207.4c0.1,0.2,0.2,0.3,0.3,0.5c-0.5,0.3-0.6-0.1-0.7-0.4C313.2,207.1,313.5,207.4,313.6,207.4z"/>
			<path fill="#D6D5D6" d="M423.3,135.9c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.1,0.3-0.1
				C423.1,135.7,423.1,135.8,423.3,135.9z"/>
			<path fill="#D4D3D5" d="M332.4,236.7c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0-0.2-0.2c0-0.1,0.1-0.3,0.2-0.4
				C332.1,236.5,332.2,236.6,332.4,236.7z"/>
			<path fill="#D8D8D9" d="M324.8,194.1c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
				C325,194.3,324.9,194.2,324.8,194.1z"/>
			<path fill="#E1E0E1" d="M420.3,132.8c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.2-0.1,0.3-0.1
				C420.2,132.5,420.3,132.5,420.3,132.8z"/>
			<path fill="#DDDCDD" d="M319.9,194.2c0.1,0.1,0.3,0.1,0.3,0.2c0.1,0.2-0.1,0.3-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C319.7,194.3,319.9,194.3,319.9,194.2z"/>
			<path fill="#EAE8E9" d="M336.8,240.2c-0.1-0.1-0.2-0.2-0.2-0.3c0,0,0.1-0.2,0.2-0.2c0.1,0.1,0.2,0.2,0.3,0.3
				C337.1,240.1,337,240.2,336.8,240.2z"/>
			<path fill="#D2D1D3" d="M319,207c-0.1,0.3-0.2,0.4-0.2,0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.3,0.1-0.3
				C318.7,206.8,318.9,207,319,207z"/>
			<path fill="#EDECEC" d="M345.4,240.4c-0.1,0-0.2,0.2-0.2,0.1c-0.2,0-0.3-0.2-0.3-0.3c0-0.1,0.2-0.2,0.2-0.2
				C345.3,240.1,345.4,240.2,345.4,240.4z"/>
			<path fill="#E5E5E5" d="M347,240.2c0.5,0.1,0.7,0.1,1,0.2C347.6,240.9,347.3,240.7,347,240.2z"/>
			<path fill="#EBE9EA" d="M399.4,104.3c0.3-0.1,0.4-0.2,0.5-0.2c0.1,0,0.3,0,0.4,0.1c0.1,0.2,0,0.3-0.2,0.3
				C400,104.4,399.8,104.4,399.4,104.3z"/>
			<path fill="#E9E8E9" d="M401.1,105c0,0.2-0.1,0.3-0.2,0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1,0-0.2,0.2-0.2
				C401,104.8,401.1,104.9,401.1,105z"/>
			<path fill="#D3D2D4" d="M376.2,140.2c-0.1-0.5-0.1-0.7-0.1-0.8c0-0.1,0.1-0.3,0.2-0.2c0.2,0.1,0.2,0.2,0.2,0.4
				C376.4,139.7,376.3,139.8,376.2,140.2z"/>
			<path fill="#D6D5D7" d="M327,191.7c0.1,0.3,0.1,0.5-0.1,0.7c0,0-0.2,0-0.2,0C326.6,192.1,326.7,191.9,327,191.7z"/>
			<path fill="#D2D1D2" d="M329.8,232.2c-0.1,0.2-0.2,0.3-0.4,0.2c-0.1,0-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.2
				C329.7,232,329.8,232.2,329.8,232.2z"/>
			<path fill="#DEDDDE" d="M428.1,138c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.1,0.2,0.1,0.3
				C428.3,137.8,428.2,137.8,428.1,138z"/>
			<path fill="#D7D7D8" d="M317.3,233.5c0.4,0,0.6,0.2,0.5,0.5C317.5,234.1,317.4,233.8,317.3,233.5z"/>
			<path fill="#D3D2D4" d="M330.5,208.4c0.1,0.1,0.2,0.2,0.3,0.3c0,0-0.1,0.2-0.1,0.2c-0.1-0.1-0.2-0.2-0.3-0.3
				C330.3,208.5,330.3,208.4,330.5,208.4z"/>
			<path fill="#E3E2E3" d="M317.5,234.6c0.4,0.1,0.5,0.4,0.7,0.8C317.8,235.2,317.6,235,317.5,234.6z"/>
			<path fill="#D6D5D7" d="M344.5,208c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.1c0-0.2,0.1-0.2,0.3-0.3
				C344.4,207.8,344.5,207.7,344.5,208z"/>
			<path fill="#DFDEDF" d="M433.9,137.3c-0.1,0-0.2,0-0.2,0c-0.4-0.4-0.3-0.6,0.2-0.7C433.9,136.8,433.9,137.1,433.9,137.3
				L433.9,137.3z"/>
			<path fill="#EFEEEE" d="M428.8,136.6c-0.1,0.1-0.1,0.3-0.2,0.3c-0.2,0.1-0.3-0.1-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2
				C428.6,136.4,428.7,136.6,428.8,136.6z"/>
			<path fill="#D5D4D6" d="M312.6,208.4c-0.3-0.2-0.4-0.4-0.3-0.6c0,0,0.1-0.1,0.2-0.1C312.7,207.9,312.7,208.1,312.6,208.4z"/>
			<path fill="#DAD8DA" d="M405.5,123.2c0.3-0.1,0.6-0.2,0.6,0.1c0.1,0.3-0.2,0.2-0.4,0.2C405.6,123.4,405.6,123.3,405.5,123.2z"/>
			<path fill="#D5D4D5" d="M346.9,246.2c-0.1,0.2-0.2,0.3-0.4,0.2c-0.1,0-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.3-0.2
				C346.8,246,346.8,246.1,346.9,246.2z"/>
			<path fill="#D7D6D8" d="M334.7,246c0.2,0,0.5,0,0.7,0c0,0,0,0,0,0c0,0.3-0.1,0.5-0.4,0.4C334.8,246.4,334.8,246.2,334.7,246
				C334.7,246,334.7,246,334.7,246z"/>
			<path fill="#F7F6F7" d="M327.7,247.6c-0.3,0.2-0.4,0.4-0.7,0.2c0,0-0.1-0.2,0-0.2C327.1,247.4,327.3,247.4,327.7,247.6z"/>
			<path fill="#DBDADB" d="M412.1,121.6c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.3,0.1
				C412.4,121.5,412.3,121.5,412.1,121.6z"/>
			<path fill="#DBDBDB" d="M409.2,121c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.2-0.2-0.2-0.3c0-0.1,0.1-0.1,0.2-0.2
				C409,120.7,409.1,120.8,409.2,121z"/>
			<path fill="#DEDDDF" d="M318.9,248.4c0.2,0.3,0,0.7-0.3,0.6c-0.3-0.1,0-0.4,0.1-0.6C318.7,248.3,318.8,248.3,318.9,248.4z"/>
			<path fill="#D1D0D2" d="M341.4,201.6c0,0.2-0.1,0.3-0.2,0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.3,0.2-0.2
				C341.4,201.4,341.4,201.6,341.4,201.6z"/>
			<path fill="#D6D5D7" d="M334.1,198.8c0-0.1-0.2-0.2-0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0.2,0.2,0.2,0.2
				C334.4,198.7,334.3,198.8,334.1,198.8z"/>
			<path fill="#E2E1E2" d="M341.3,249.5c0,0.1,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.1-0.2-0.1-0.3
				C341,249.6,341.1,249.5,341.3,249.5z"/>
			<path fill="#DADADC" d="M330.1,249.7c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1-0.1,0.2-0.2,0.2c-0.1-0.1-0.2-0.2-0.3-0.3
				C329.8,249.8,329.9,249.7,330.1,249.7z"/>
			<path fill="#E1E0E1" d="M325.8,200.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.2-0.2,0.3-0.3
				C325.7,200,325.8,200.1,325.8,200.3z"/>
			<path fill="#D0CFD1" d="M336.8,199.9c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.3,0.2-0.4c0.1,0.1,0.2,0.1,0.2,0.2
				C337.1,199.7,337,199.9,336.8,199.9z"/>
			<path fill="#C7C6C9" d="M320.6,198.5c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.2,0.4,0.5,0,0.7c-0.2-0.2-0.4-0.2-0.5-0.5
				C320.6,198.8,320.6,198.7,320.6,198.5z"/>
			<path fill="#E2E1E2" d="M403,130.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1,0-0.2,0.2-0.2c0.1,0.1,0.3,0.1,0.3,0.3
				C403.4,130.2,403.2,130.3,403,130.3z"/>
			<path fill="#FCFCFC" d="M352.5,240.9c0.2,0.1,0.3,0.3,0.3,0.5c-0.2,0-0.5,0.2-0.6,0C352,241.2,352.3,241.1,352.5,240.9z"/>
			<path fill="#DFDEDF" d="M429,129.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.3,0.3
				C429.2,129,429,129.2,429,129.2z"/>
			<path fill="#D5D5D6" d="M425,128.5c0.2-0.1,0.4-0.2,0.5-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.2-0.1,0.3-0.3,0.3
				C425.5,128.8,425.4,128.7,425,128.5z"/>
			<path fill="#E1E0E1" d="M338.3,242.3c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.3,0.3-0.3
				C338.2,242,338.3,242.1,338.3,242.3z"/>
			<path fill="#D7D6D7" d="M407.9,127.2c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0,0.3-0.1,0.2c-0.1,0-0.2-0.2-0.3-0.3
				C407.8,127.5,407.9,127.4,407.9,127.2z"/>
			<path fill="#E0DFE0" d="M398.6,111.5c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.2,0-0.3,0.2-0.3c0.1,0,0.2,0.1,0.3,0.1
				C399,111.3,398.8,111.4,398.6,111.5z"/>
			<path fill="#D5D4D6" d="M417.3,124.4c-0.1,0.1-0.1,0.3-0.2,0.3c-0.2,0.1-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.2-0.2
				C417.1,124.2,417.2,124.3,417.3,124.4z"/>
			<path fill="#DDDCDD" d="M418.4,124c0,0.2-0.2,0.3-0.3,0.3c-0.1,0-0.3,0-0.2-0.1c0-0.1,0.2-0.2,0.3-0.3
				C418.2,123.9,418.3,124,418.4,124z"/>
			<path fill="#DBDADB" d="M422.9,123.5c-0.1,0-0.2,0.1-0.3,0.2c-0.1-0.1-0.2-0.2-0.2-0.2c0.1-0.1,0.2-0.3,0.3-0.3
				C422.9,123.2,422.9,123.3,422.9,123.5z"/>
			<path fill="#F0F0F0" d="M323.4,217c0.2,0,0.3,0.2,0.3,0.3c0,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.3-0.1-0.2-0.3
				C323.2,217.1,323.3,217.1,323.4,217z"/>
			<path fill="#ECEBEB" d="M323,215c-0.1,0-0.2,0-0.2,0c-0.3-0.2-0.3-0.5,0-0.7C323,214.5,323,214.8,323,215z"/>
			<path fill="#CBCBCD" d="M320,218.5c-0.3,0-0.4-0.1-0.3-0.3c0-0.2,0.2-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.2
				C320.1,218.3,320,218.5,320,218.5z"/>
			<path fill="#DCDBDC" d="M427.3,155.6c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0.2-0.3,0.3-0.3
				C427.4,155.2,427.4,155.4,427.3,155.6z"/>
			<path fill="#F5F5F5" d="M336.6,218.1c0,0.2-0.1,0.4-0.3,0.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.3,0.3-0.3
				C336.4,218,336.5,218.1,336.6,218.1z"/>
			<path fill="#E0DFE0" d="M428.8,145.4c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3,0,0.2,0.1c0,0.1-0.2,0.2-0.3,0.3
				C429.1,145.6,429,145.5,428.8,145.4z"/>
			<path fill="#E7E6E7" d="M329.4,224c0.1,0.1,0.1,0.2,0.2,0.4c0,0-0.1,0.1-0.2,0.2c-0.1-0.1-0.2-0.2-0.2-0.4
				C329.1,224,329.2,224,329.4,224z"/>
			<path fill="#E8E7E8" d="M332.8,218.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.1,0.3-0.1
				C332.6,218.2,332.7,218.3,332.8,218.4z"/>
			<path fill="#CFCFD1" d="M334.5,211.5c0,0.4-0.2,0.6-0.6,0.5C333.9,211.5,334.2,211.5,334.5,211.5
				C334.5,211.4,334.5,211.5,334.5,211.5z"/>
			<path fill="#E7E6E6" d="M439,146.5c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0-0.3,0.1-0.3c0.2-0.1,0.3,0.1,0.3,0.2
				C439.1,146.4,439,146.5,439,146.5z"/>
			<path fill="#D9D8DA" d="M316,185.8c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.1-0.2-0.3c0-0.1,0.2-0.1,0.3-0.1
				C315.8,185.6,315.9,185.7,316,185.8z"/>
			<path fill="#DBDADB" d="M317,185.7c0.1,0,0.4-0.1,0.3,0.2c0,0.1-0.2,0.2-0.3,0.3c0,0-0.2-0.1-0.3-0.2
				C316.7,185.7,316.9,185.7,317,185.7z"/>
			<path fill="#DBDADB" d="M430.8,146.8c0.1,0.2-0.1,0.3-0.2,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.3,0.2-0.2
				C430.6,146.6,430.7,146.8,430.8,146.8z"/>
			<path fill="#E0DFE0" d="M434.7,146.7c0.4,0.1,0.6,0.3,0.7,0.6C435,147.3,434.8,147.1,434.7,146.7z"/>
			<path fill="#D6D5D7" d="M435.9,149c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.3,0.2-0.2c0.1,0.1,0.2,0.2,0.3,0.3
				C436.2,148.9,436.1,149,435.9,149z"/>
			<path fill="#D3D2D4" d="M323.2,212.2c0,0.2,0,0.3,0,0.5c-0.2,0-0.5,0.3-0.6,0C322.5,212.3,323,212.3,323.2,212.2z"/>
			<path fill="#D7D6D8" d="M316.4,212.4c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.2,0.2,0.2,0.3c0,0.1-0.2,0.2-0.2,0.2
				C316.6,212.7,316.6,212.6,316.4,212.4z"/>
			<path fill="#DBDADB" d="M435.3,150.7c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.3,0.1-0.3c0.2-0.1,0.3,0.1,0.3,0.2
				C435.5,150.5,435.4,150.5,435.3,150.7z"/>
			<path fill="#DDDDDE" d="M428.2,150.7c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.2,0.2-0.2,0.3-0.2c0.1,0,0.1,0.2,0.2,0.3
				C428.4,150.6,428.3,150.7,428.2,150.7z"/>
			<path fill="#EBE9E9" d="M344.5,215.1c0,0.2,0,0.3-0.2,0.3c-0.1-0.1-0.3-0.1-0.4-0.3c-0.1-0.1,0.1-0.2,0.2-0.2
				C344.3,214.9,344.4,215,344.5,215.1z"/>
			<path fill="#E7E6E6" d="M318.4,215.3c0-0.1,0-0.2,0-0.3c0.3,0,0.5,0.1,0.3,0.5c-0.1,0.1-0.1,0.2-0.2,0.3
				C318.5,215.6,318.5,215.5,318.4,215.3z"/>
			<path fill="#D2D2D4" d="M321.9,180.9c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.2-0.1-0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3
				C321.8,180.6,321.9,180.6,321.9,180.9z"/>
			<path fill="#D3D2D4" d="M317.7,181c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.3,0.2-0.4
				C317.6,180.6,317.7,180.8,317.7,181z"/>
			<path fill="#C9C8CA" d="M332.1,209.5c0.3,0.2,0.6,0.4,0.8,0.9C332.3,210.2,332.1,209.9,332.1,209.5
				C332.1,209.5,332.1,209.5,332.1,209.5z"/>
			<path fill="#D5D4D6" d="M313.3,180.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2-0.1-0.2-0.2c0.1-0.1,0.2-0.3,0.3-0.3
				C313.2,180.3,313.3,180.4,313.3,180.6z"/>
			<path fill="#E2E1E3" d="M311.7,182.9c-0.2,0-0.4-0.1-0.3-0.3c0-0.1,0.2-0.2,0.2-0.1c0.2,0,0.4,0.1,0.4,0.3
				C311.9,182.9,311.8,182.9,311.7,182.9z"/>
			<path fill="#D2D2D3" d="M311.7,210.4c-0.1,0.3-0.4,0.4-0.7,0.5C311,210.4,311.3,210.4,311.7,210.4z"/>
			<path fill="#E4E3E4" d="M315.1,227.5c-0.1-0.1-0.2-0.3-0.2-0.3c0.1-0.1,0.2-0.2,0.4-0.2c0.1,0,0.2,0.1,0.2,0.2
				C315.4,227.3,315.2,227.4,315.1,227.5z"/>
			<path fill="#D4D4D5" d="M340.9,219.7c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.2-0.2
				C340.7,219.5,340.8,219.6,340.9,219.7z"/>
			<path fill="#D6D6D7" d="M347.4,243.4c-0.1,0.1-0.2,0.2-0.3,0.1c-0.1,0-0.1-0.1-0.2-0.2c0.1,0,0.2-0.1,0.2-0.1
				C347.2,243.2,347.3,243.3,347.4,243.4z"/>
			<path fill="#EFEDED" d="M406,126.6c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.1-0.3
				C405.8,126.5,405.9,126.5,406,126.6z"/>
			<path fill="#D7D7D9" d="M409.4,126.8c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.2-0.1
				C409.2,126.5,409.3,126.6,409.4,126.8z"/>
			<path fill="#D6D5D6" d="M314.6,243.2c0.1,0.1,0.3,0.1,0.3,0.2c0.1,0.1,0,0.2-0.1,0.2c-0.1,0-0.2-0.1-0.3-0.1
				C314.4,243.4,314.5,243.3,314.6,243.2z"/>
			<path fill="#DCDBDC" d="M328.7,204.9c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0-0.2-0.2c0-0.1,0.1-0.2,0.2-0.3
				C328.7,204.6,328.7,204.7,328.7,204.9z"/>
			<path fill="#E7E5E6" d="M317.3,204.8c0.2,0,0.3,0,0.2,0.2c0,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C317.1,204.9,317.2,204.8,317.3,204.8z"/>
			<path fill="#E9E7E8" d="M345.7,242.6c0,0.2,0,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0.1-0.2,0.2-0.2
				C345.5,242.4,345.6,242.5,345.7,242.6z"/>
			<path fill="#D9D8D9" d="M334.4,247.3c0.2,0,0.3,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2-0.1-0.2-0.2
				C334.3,247.5,334.4,247.4,334.4,247.3z"/>
			<path fill="#DFDEDF" d="M424.4,127.4c-0.2,0-0.3-0.1-0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0.2,0.1,0.2
				C424.5,127.3,424.5,127.4,424.4,127.4z"/>
			<path fill="#DFDDDE" d="M315.4,242.6c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
				C315.7,242.5,315.6,242.6,315.4,242.6z"/>
			<path fill="#CBCACC" d="M418.2,115c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.1,0.1-0.2,0.2-0.2
				C418,114.8,418.1,114.9,418.2,115z"/>
			<path fill="#E8E6E7" d="M330.9,199.6c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1-0.2,0.1-0.2,0.2-0.2
				C330.7,199.3,330.8,199.5,330.9,199.6z"/>
			<path fill="#DCDBDC" d="M320.6,199c0.2,0.2,0.3,0.3,0.5,0.5C320.8,199.4,320.2,199.6,320.6,199z"/>
			<path fill="#DCDBDC" d="M318.9,248.4c-0.1,0-0.2,0-0.3,0c-0.1-0.2-0.2-0.4-0.4-0.6C318.7,247.8,318.7,248.2,318.9,248.4z"/>
			<path fill="#EDECED" d="M342.9,241.7c0.2,0,0.3,0,0.5,0c0,0.2,0,0.5-0.3,0.5C342.8,242.2,343,241.9,342.9,241.7z"/>
			<path fill="#D2D2D4" d="M343.7,246.2c0.1,0.2,0,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1,0-0.2,0.2-0.2
				C343.5,246.1,343.6,246.2,343.7,246.2z"/>
			<path fill="#CCCBCE" d="M321.9,246.1c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1-0.1,0.2-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
				C321.8,246.2,321.8,246.1,321.9,246.1z"/>
			<path fill="#D0D0D1" d="M315,246.1c0,0.2,0,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.2c0,0,0.1-0.1,0.1-0.2
				C314.8,246,314.9,246.1,315,246.1z"/>
			<path fill="#EEEDED" d="M435.2,155.3c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2
				C435.1,155.4,435.1,155.3,435.2,155.3z"/>
			<path fill="#F2F1F1" d="M317.9,218.3c-0.1,0.2-0.2,0.2-0.3,0.1c-0.1-0.1-0.2-0.2-0.3-0.4c0.1,0,0.2-0.1,0.3-0.1
				C317.8,218.1,317.8,218.2,317.9,218.3z"/>
			<path fill="#E2E1E2" d="M415.4,124.2c0,0.2-0.1,0.3-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C415.3,124,415.3,124.1,415.4,124.2z"/>
			<path fill="#D2D1D3" d="M319,183.5c0.2,0,0.3,0,0.3,0.2c0,0.1-0.1,0.2-0.2,0.2c0,0-0.1-0.1-0.2-0.1
				C318.9,183.6,318.9,183.5,319,183.5z"/>
			<path fill="#DDDCDD" d="M313.5,175.6c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.1,0.2-0.2c0.1,0.1,0.2,0.2,0.1,0.2
				C313.8,175.4,313.6,175.5,313.5,175.6z"/>
			<path fill="#D5D4D6" d="M318.1,181.8c-0.2,0.2-0.3,0.2-0.3,0.3c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C317.8,181.6,317.9,181.7,318.1,181.8z"/>
			<path fill="#CAC9CC" d="M438.2,154.1c-0.2,0-0.5,0.1-0.6-0.2c0,0,0-0.2,0.1-0.2C438,153.7,438.1,153.9,438.2,154.1L438.2,154.1z"
				/>
			<path fill="#DAD9DA" d="M313.3,213.8c-0.1,0-0.2,0-0.3-0.1c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.1,0.2,0.1
				C313.5,213.5,313.4,213.7,313.3,213.8z"/>
			<path fill="#D6D5D7" d="M312.6,196.2c0.2,0,0.3,0,0.2,0.2c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2-0.1-0.2-0.2
				C312.5,196.4,312.5,196.3,312.6,196.2z"/>
			<path fill="#E5E4E4" d="M318.2,244.5c0-0.1,0.1-0.3,0.2-0.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.2,0.2
				C318.3,244.7,318.2,244.5,318.2,244.5z"/>
			<path fill="#E9E8E8" d="M435.3,153.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.2
				C435,153.4,435.1,153.5,435.3,153.6z"/>
			<path fill="#F0EFEF" d="M328.3,219.1c0,0.2,0,0.3,0,0.5c-0.5,0-0.5-0.4-0.5-0.7C327.9,219,328.1,219.1,328.3,219.1z"/>
			<path fill="#E7E6E7" d="M325.4,202.3c0-0.2,0-0.5,0.1-0.5c0.4-0.2,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
				C325.5,202.4,325.4,202.3,325.4,202.3z"/>
			<path fill="#D9D8DA" d="M338.1,243.9c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.2,0.1-0.2
				C337.9,243.6,338,243.7,338.1,243.9z"/>
			<path fill="#E1E1E2" d="M330.2,225.3c0.1,0.2,0,0.3-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1,0-0.2,0.2-0.2
				C330.1,225.2,330.2,225.3,330.2,225.3z"/>
			<path fill="#E2E1E2" d="M345.8,234.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.2
				C345.5,234.3,345.6,234.4,345.8,234.5z"/>
			<path fill="#D5D5D6" d="M310.6,190.5c0.1,0.2,0,0.3-0.1,0.3c-0.1,0-0.3-0.1-0.2-0.1c0.1-0.1,0.1-0.3,0.2-0.4
				C310.5,190.3,310.5,190.4,310.6,190.5z"/>
			<path fill="#DCDBDD" d="M320.8,234.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0.1-0.2,0.1-0.3
				C320.7,233.9,320.8,234,320.8,234.1z"/>
			<path fill="#DBDADB" d="M334.1,233.6c0-0.2,0.1-0.3,0.2-0.2c0.1,0,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.2,0.2
				C334.2,233.8,334.1,233.7,334.1,233.6z"/>
			<path fill="#D8D8D9" d="M342.8,233.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.1,0.3-0.2,0.4-0.2
				C342.7,233.2,342.8,233.3,342.8,233.4z"/>
			<path fill="#D1D1D2" d="M323.4,233.7c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.1,0.2-0.2c0.1,0.1,0.2,0.2,0.1,0.2
				C323.6,233.5,323.5,233.5,323.4,233.7z"/>
			<path fill="#D1D1D2" d="M323.8,232.7c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2-0.2c0-0.1,0.2-0.2,0.2-0.2
				C323.8,232.4,323.9,232.5,323.8,232.7z"/>
			<path fill="#D3D2D4" d="M322.5,191.7c0-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0.1,0.1,0.2
				C322.7,191.6,322.6,191.7,322.5,191.7z"/>
			<path fill="#CDCCCE" d="M360.7,231.1c0,0.2-0.1,0.4-0.1,0.5c-0.1,0-0.2-0.1-0.3-0.1C360.2,231.2,360.4,231.2,360.7,231.1
				C360.7,231.1,360.7,231.1,360.7,231.1z"/>
			<path fill="#E1E0E1" d="M432.1,142.7c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1-0.1-0.2-0.1-0.2-0.2
				C432,142.9,432,142.8,432.1,142.7z"/>
			<path fill="#E0DFE0" d="M311.8,209.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1-0.2-0.2-0.2c0.1,0,0.2-0.1,0.3-0.1
				C311.8,208.9,311.9,208.9,311.8,209.1z"/>
			<path fill="#D7D6D7" d="M324.1,192.5c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.1
				C323.9,192.3,324,192.4,324.1,192.5z"/>
			<path fill="#E8E7E7" d="M323.5,210.2c-0.3,0.1-0.5,0.2-0.5-0.2C323.4,209.6,323.4,209.9,323.5,210.2z"/>
			<path fill="#E1E0E1" d="M426.6,141.7c-0.1,0-0.3,0.1-0.3,0c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.2-0.1,0.2-0.1
				C426.5,141.4,426.6,141.5,426.6,141.7z"/>
			<path fill="#D3D2D4" d="M349.9,229.7c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.1c0.1,0,0.1,0.2,0.2,0.3
				C350.1,229.6,350,229.6,349.9,229.7z"/>
			<path fill="#DBDADB" d="M339.3,229.5c0,0.2-0.1,0.3-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C339.2,229.3,339.2,229.5,339.3,229.5z"/>
			<path fill="#DDDCDD" d="M420.4,140.5c0.2,0,0.3,0.1,0.2,0.2c0,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2-0.2
				C420.2,140.6,420.3,140.5,420.4,140.5z"/>
			<path fill="#EEECED" d="M427.6,140.6c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2
				C427.4,140.8,427.5,140.7,427.6,140.6z"/>
			<path fill="#CECDCF" d="M410.9,141c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.2
				C410.7,141.2,410.8,141.1,410.9,141z"/>
			<path fill="#D9D9DA" d="M333.1,241.2c0,0.2-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C333,241.1,333,241.2,333.1,241.2z"/>
			<path fill="#D1D0D2" d="M412.4,129.1c0.1,0.2,0.2,0.3,0.2,0.4c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.1-0.2-0.1-0.2-0.2
				C412.3,129.5,412.3,129.4,412.4,129.1z"/>
			<path fill="#D4D3D5" d="M420.1,130.3c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.1
				C420.3,130.2,420.2,130.2,420.1,130.3z"/>
			<path fill="#EFEEEE" d="M438.2,148.5c0.2,0,0.3,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C438,148.7,438.1,148.6,438.2,148.5z"/>
			<path fill="#D4D3D4" d="M332.3,221.7c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0-0.2-0.2c0-0.1,0.1-0.2,0.2-0.3
				C332.3,221.4,332.3,221.5,332.3,221.7z"/>
			<path fill="#E9E8E8" d="M418.8,131.5c0,0.2-0.1,0.3-0.2,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0-0.2,0.2-0.2
				C418.6,131.3,418.7,131.4,418.8,131.5z"/>
			<path fill="#E0DFE0" d="M353.9,240.2c-0.1-0.1-0.2-0.3-0.1-0.3c0-0.1,0.1-0.1,0.2-0.2c0,0.1,0.1,0.2,0.1,0.2
				C354.1,240,354,240.1,353.9,240.2z"/>
			<path fill="#F4F3F3" d="M352.7,239c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.2
				C352.4,238.8,352.5,238.9,352.7,239z"/>
			<path fill="#D7D7D8" d="M350.7,239.2c0-0.2,0-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.2c0,0-0.1,0.1-0.1,0.2
				C350.8,239.3,350.7,239.2,350.7,239.2z"/>
			<path fill="#DAD9DB" d="M340,239.3c0-0.2,0.1-0.5,0.4-0.4c0.1,0,0.1,0.1,0.1,0.1C340.5,239.3,340.3,239.3,340,239.3L340,239.3z"
				/>
			<path fill="#DBDADB" d="M390.4,134.6c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.1,0.1,0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.2
				C390.7,134.8,390.6,134.7,390.4,134.6z"/>
			<path fill="#DFDEDF" d="M313.4,194.1c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0.1-0.2,0.1-0.3
				C313.2,193.8,313.3,193.9,313.4,194.1z"/>
			<path fill="#D3D2D3" d="M316.5,238.4c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0.1,0.2,0.2
				C316.7,238.3,316.7,238.4,316.5,238.4z"/>
			<path fill="#D4D4D5" d="M331.8,237.7c0,0.2,0,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.2c0,0,0.1-0.1,0.1-0.2
				C331.6,237.6,331.7,237.7,331.8,237.7z"/>
			<path fill="#E8E7E8" d="M352.2,238c-0.1-0.4-0.2-0.5-0.1-0.6c0-0.1,0.2-0.1,0.2-0.2c0,0.1,0.1,0.2,0.1,0.3
				C352.4,237.6,352.3,237.7,352.2,238z"/>
			<path fill="#D7D6D8" d="M327.5,224.6c-0.1-0.1-0.2-0.2-0.1-0.3c0-0.1,0.1-0.1,0.2-0.2c0,0.1,0.1,0.2,0.1,0.2
				C327.7,224.4,327.6,224.5,327.5,224.6z"/>
			<path fill="#E5E4E5" d="M331.1,212.1c-0.1-0.4-0.2-0.5-0.2-0.5c0-0.1,0.2-0.1,0.2-0.2c0,0.1,0.1,0.2,0.1,0.3
				C331.3,211.8,331.3,211.9,331.1,212.1z"/>
			<path fill="#DEDDDE" d="M349.6,236.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.2
				C349.4,236,349.5,236.1,349.6,236.2z"/>
			<path fill="#E0DFDF" d="M432.3,136.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.2
				C432.1,136.3,432.2,136.4,432.3,136.5z"/>
			<path fill="#D2D1D3" d="M430.5,144.2c0.2,0,0.4,0,0.5,0.2c-0.2,0-0.3,0.5-0.6,0.3C430.3,144.7,430.4,144.4,430.5,144.2z"/>
			<path fill="#E0DFE0" d="M429.2,144.4c0,0.2,0,0.3-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1,0.1-0.2,0.2-0.2
				C429,144.3,429.1,144.4,429.2,144.4z"/>
			<path fill="#CF8D74" d="M350.3,48.7c0-0.1,0-0.1,0-0.2c-0.1-0.1-0.2-0.1-0.2-0.3c0-0.5,0.8-0.2,0.7-0.7c0-0.5,0.3-0.8,0.4-1.2
				c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.4-0.6,0.3-0.9c0.1-0.4-0.1-0.7,0-1.1c0.1-0.1,0.1-0.1,0.3-0.2c0.5,0,0.8,0.9,1.4,0.2
				c0.3-0.2,0.6-0.2,0.9,0c0.2,0.2,0.6,0.4,0.5,0.7c0.4-0.6-0.3-1.3-0.1-2c0.9-0.5,1.4,0,1.9,0.6c0.1,0,0.2,0,0.2-0.1
				c0-0.4,0.2-0.8,0.6-0.9c0.3-0.1,0.4,0.2,0.5,0.4c0.1,0.5,0.5,1,0,1.4c-0.1,0.1-0.3,0.3-0.5,0.3c-0.5,0.2-1.3,1.1-1.4,1.5
				c-0.1,0.1-0.3,0.2-0.4,0.2c-0.5,0.5-1.2,0.5-1.8,0.4c-0.5,0-0.5-0.4-0.5-0.7c0-0.1-0.1-0.2-0.2-0.1c0,0.1,0,0.2,0,0.2
				c-0.1,0.3,0,0.6,0.3,0.8c0.3,0.4,0.5,0.7,0.4,1.2c-0.5,0.8-0.5,0.8-0.1,2.4c-0.2,0.3-0.6,0.4-0.9,0.6c-0.7,0.5-0.8,0.2-0.7-0.5
				c0.2-0.5,0.2-0.9-0.1-1.4c-0.1-0.3,0.1-0.9-0.6-0.5C350.9,48.8,350.7,49,350.3,48.7z"/>
			<path fill="#DAAC9B" d="M353.7,37.9c0.3-0.3,1.2-0.1,0.6-1.1c-0.3-0.4,0.5-0.4,0.9-0.6c0.2,0.5-0.3,0.6-0.3,0.9
				c0.2,0.2,0.2,0.4,0.3,0.6c0.3-0.1,0.2-0.5,0.4-0.6c0.2,0,0.5,0,0.7,0c0.2,0.3,0.4,0.3,0.7,0.2c0.2-0.2,0.3-0.3,0.5-0.5
				c0.1-0.1,0.2-0.1,0.2,0c0.5,0.2,1,0.5,1.4,0.7c0.2,0.2,0,0.5,0.2,0.7c0,0.1,0,0.2,0,0.2c-0.1,0.3-0.2,0.5-0.5,0.6
				c-0.2,0-0.3,0-0.5-0.1c-0.2-0.2-0.1-0.5-0.2-0.8c-0.1-0.4-0.2-0.2-0.4-0.1c0,0.5-0.9,0-0.7,0.7c0,0.5,0.9,0.9,0.1,1.4
				c-0.7,0.5-1.5,0.7-2.2,0c-0.2-0.5,0.1-0.6,0.7-0.7c-0.8-0.3-1.4-0.4-1.5,0.6c-0.1,0.6-0.7,0.6-1.1,0.6c-0.5,0-0.5-0.4-0.4-0.8
				c0.1-0.6-0.4-0.6-0.7-0.8c0-0.1,0-0.2,0-0.2c-0.1-0.6,0.5-0.4,0.7-0.7c-0.3-0.5-0.2-1,0.2-1.4c0.3-0.2,0.6,0.1,0.7,0.4
				C353.5,37.6,353.5,37.8,353.7,37.9z"/>
			<path fill="#DAAC9B" d="M353.5,50.4c-0.4-0.4-0.5-0.9-0.9-1.4c-0.2-0.2,0.5-0.7,0.9-1c0,0,0.1,0.1,0.1,0.1c0.2,0.5,0.5,0.9,1,1.2
				c0.4,0.3,1.1,0.4,0.9,1.3c-0.1,0.2,0.6,0.3,1,0c0.5-0.3,0.8,0,0.7,0.3c-0.3,0.9,0.4,0.4,0.6,0.5c0.1,0,0.2,0.1,0.3,0.1
				c0.2,0.2,0.7,0.2,0.6,0.7c-0.8-0.2-1.1,0.6-1.7,0.9c-0.1,0.1-0.1,0.1-0.2,0c-0.4,0-0.3,1.1-1,0.5c-0.2-0.2-0.2-0.3,0-0.5
				c0.3-0.5,0.3-1-0.2-1.4c-0.4-0.1-0.7,0-0.7,0.5c-0.3,0.4-0.7,0.5-1.2,0.5c0-0.3-0.1-0.6-0.3-0.8c0-0.1,0-0.2,0.1-0.3
				c0.1-0.3,0.6-0.6,0.1-1C353.5,50.5,353.5,50.4,353.5,50.4z"/>
			<path fill="#D0927A" d="M352,39.4c0.3-0.3,0.7,0.3,1.2-0.4c-0.1,0.8-0.2,1.2-0.3,1.7c1.3-0.4,1.3-0.4,0.6-1.3
				c0.9,0.6,1.5-1,2.3,0c0.1,0.1,0.5-0.1,0.5,0.3c0,0.2-0.3,0.4-0.5,0.3c-0.4-0.2-0.5,0-0.5,0.3c0.1,0.1,0.1,0.1,0.1,0.2
				c0,0.1,0,0.1,0,0.1c-0.2,0.1-0.5,0.2-0.6,0.4c-0.2,0.3-0.6,0.6-1,0.6c-0.4,0.1-0.1,0.6-0.4,0.7c-0.2,0.1-0.3,0.1-0.5,0
				c-0.2,0,0-0.6-0.4-0.2c-0.4,0.4-0.8,0.6-1.1-0.1l0,0c-0.3-0.7-0.3-0.7,0.2-1.4C351.4,40.1,352.5,40,352,39.4z"/>
			<path fill="#DAAD9C" d="M364.8,40.6c0.8,0.3,1.4,0.7,1.6,1.6c0.1,0.3,0.4,0.6-0.2,0.9c-0.4,0.2-0.6,0.5-0.8-0.1
				c-0.1,0.1-0.2,0.2-0.4,0.3c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.6-0.8-0.6c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.3-0.3-0.3-0.4
				c0.4-0.7,0.1-1.2-0.4-1.7c-0.4-0.4-0.2-0.6,0.4-0.7l0,0C363.8,40.1,364.4,40.2,364.8,40.6z"/>
			<path fill="#CE8B72" d="M363.5,39.6c-0.8,0.6-0.2,0.9,0.3,1.1c0.7,0.3,0.8,0.6,0.2,1c-0.6,0.4,0,0.6,0.1,0.8
				c0,0.4-0.2,0.7-0.7,0.8c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.3c0.1-0.3,0-0.5-0.3-0.6c-0.3-0.3-0.6-0.6-0.9-0.9
				c0.1-0.6,0.1-1.2,0.6-1.6c0.2-0.1,0.4-0.3,0.5-0.5c0.3-0.5,0.7-0.4,0.9-0.2C364.4,39.5,363.6,39.3,363.5,39.6z"/>
			<path fill="#CD886E" d="M365.2,45.1c-0.9-0.2-0.3,0.6-0.5,0.8c-0.2,0.2-0.1,0.6,0.2,0.5c1.1-0.2,0.5,0.4,0.2,0.6
				c-0.5,0.3-0.3,1.5-1.3,1c-0.3-0.2-0.5-0.4-0.7-0.7c-0.3-0.4-0.3-0.8,0.1-1.2c0.5-0.6,0.9-1.4,1-2.2c0.1-0.1,0.2-0.2,0.3-0.2
				C365.1,44,365.4,44.4,365.2,45.1z"/>
			<path fill="#CF8F77" d="M363.3,47.3c0.3,0.2,0.4,0.5,0.5,0.8c-0.1,1.1-0.3,1.9-1.7,1.9c-0.3-0.1-0.6-0.2-0.8-0.5
				c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.5,0.3-0.8,0.5-1.2c0.2-0.2,0.4-0.3,0.6-0.2C362.6,47.9,363,47.8,363.3,47.3z"/>
			<path fill="#D7A390" d="M351.8,43.9c-0.1,0.4,0.5,0.8,0,1.2c0,0.7-1,0.4-1.1,1c-0.1,0.2-0.2,0.6-0.6,0.4c-0.5,0-0.9-0.4-1.2-0.7
				c0.2-0.6,1-0.6,1.2-1.2c0-0.1,0-0.2,0-0.2C350.7,44.3,351,43.5,351.8,43.9z"/>
			<path fill="#D9AB9A" d="M362.6,39.6c-0.2,0.6-0.3,1.1-0.5,1.7c-0.2,0.1-0.4,0-0.6-0.1c-0.2-0.3-0.7-0.2-0.9-0.5
				c-0.2-0.2-0.4-0.4-0.5-0.6c0-0.2,0-0.3,0.1-0.4c0.4,0.1,0.5-0.7,1-0.2c0.3,0,0.6-0.1,1,0C362.2,39.6,362.4,39.6,362.6,39.6z"/>
			<path fill="#DBAF9F" d="M359.5,50.9c0,0.2,0,0.3,0,0.5c-0.3,0.3-0.6,0.3-1,0c-0.2-0.3-0.1-0.6-0.2-0.8c0-0.5,0.4-0.8,0.8-1
				c0.6,0,1.2-0.8,1.8,0c0.1,0.1,0.1,0.2,0.1,0.3c0,0.4-0.1,0.8-0.5,1C360.2,51.1,359.7,50.3,359.5,50.9z"/>
			<path fill="#DAAD9C" d="M365.2,45.1c-0.2-0.5-0.4-1-1-1.2c-0.3,0.1-0.6,0.1-0.9-0.1c-0.4-0.2-0.5-0.4,0-0.6
				c0.2-0.2,0.5-0.5,0.7-0.7c0,0,0,0,0,0c0.4,0.1,0.7,0.4,1,0.7c0,0,0,0,0,0c0.2,0.2,0.3,0.5,0.5,0.7c0.3,0.4,0.4,0.8,0,1.2l-0.1,0
				L365.2,45.1z"/>
			<path fill="#CF8F76" d="M351.8,43.9c-0.7-0.3-0.9,1.1-1.7,0.5c-0.3-0.1-0.8,0.1-0.6-0.4c0.1-0.5,0.4,0.1,0.6,0
				c0.3-0.1,0.5-0.4,1-0.3c-0.3-0.4-0.5-0.7-0.7-1c-0.1-0.3-0.2-0.5,0.2-0.5c0.1,0,0.1,0,0.2,0c0.4,0.3,0.6,0.7,0.9,1.1
				C351.8,43.5,351.9,43.7,351.8,43.9L351.8,43.9z"/>
			<path fill="#D9AA98" d="M352,49.2c0.4,0.4,0.7,0.8,0,1.2c-0.1,0.1-0.2,0.1-0.2,0c-0.4,0-0.8-0.4-1.3-0.2c-0.2,0.1-0.3,0-0.3-0.2
				c-0.1-0.4,0.3-0.6,0.5-0.9c0.4-0.2,0.8,0.3,1.3,0C351.9,49.2,352,49.2,352,49.2z"/>
			<path fill="#CB8166" d="M360.2,39.6c0,0.2,0,0.3,0,0.5c-0.5,0.3-0.9,0.7-1.6,0.6c-0.2-0.1-0.3-0.2-0.4-0.3c0-0.1,0-0.2,0-0.3
				c0.3-0.3,0.4-0.7,0.8-1c0.2-0.2,0.3-0.3,0.5-0.5C359.7,39,359.9,39.3,360.2,39.6z"/>
			<path fill="#DBAF9F" d="M351.5,43.5c-0.3-0.4-0.7-0.7-0.7-1.2c0.2-0.1,0.3-0.2,0.5-0.3c1.3,1,0.5-0.9,1.3-0.8
				c0.2,0.3,0.3,0.6,0.2,1.1c0.1,0.1,0.2,0.3,0.1,0.5c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0-0.3,0c-0.4-0.3-0.6,0-0.7,0.3
				C351.6,43.4,351.6,43.4,351.5,43.5z"/>
			<path fill="#DAAD9D" d="M351.8,50.4c0.1,0,0.2,0,0.2,0c0.1,0.2-0.2,1,0.5,0.3c0.3-0.3,0.6-0.2,1-0.3c0,0,0,0,0,0
				c0.5,0.4,0.4,0.8,0,1.2l0,0c-0.2,0.3-0.5,0.1-0.7,0c-0.3-0.2-0.6-0.2-1,0c-0.1-0.2-0.2-0.4-0.2-0.7
				C351.6,50.7,351.7,50.6,351.8,50.4z"/>
			<path fill="#D0927A" d="M359.5,50.9c-0.2-0.1-0.3-0.3-0.1-0.5c0.4-0.4,0.7,0.3,1,0.2c0.1,0.2,0.1,0.3,0,0.5
				c0.6,0.4,0.5,0.8-0.1,1.3C360,52,360.4,51,359.5,50.9z"/>
			<path fill="#D9AA98" d="M350.3,48.7c0.1-0.1,0.3-0.1,0.4-0.2c0.3-0.4,0.8-0.5,1.1-0.3c0.3,0.1,0.1,0.6,0.1,1c0,0,0,0,0,0
				c-0.6,0.4-1,0-1.4-0.2C350.5,48.9,350.4,48.8,350.3,48.7z"/>
			<path fill="#CD876D" d="M358.5,51.1c0.3,0.3,0.7,0.2,1,0.2c0,0.5,0.2,1.2-0.7,0.7c-0.2-0.2-0.5-0.3-0.7-0.5
				c-0.1-0.2-0.1-0.3,0-0.5C358.1,51,358.3,51,358.5,51.1z"/>
			<path fill="#CE8B71" d="M350.1,44.7c0.2,1.1-0.8,0.7-1.2,1.2c-0.2,0-0.5-0.1-0.3-0.3C349.1,45.2,349.5,44.8,350.1,44.7z"/>
			<path fill="#D1947C" d="M360.4,51.1c0-0.2,0-0.3,0-0.5c0.3-0.3,0.4-0.6,0.5-1c0.1-0.1,0.2-0.1,0.3,0c0.3,0.3,0.7,0.5,0.4,1
				C361,50.3,361,51.5,360.4,51.1z"/>
			<path fill="#D7A390" d="M351.5,46.3c-0.2,0.4-0.3,0.9-0.7,1.2c-0.5,0-0.5-0.4-0.5-0.7C350.6,46.3,351,46.1,351.5,46.3z"/>
			<path fill="#D0927A" d="M355.6,37.2c0,0,0.1,0.1,0,0.1c-0.2,0.3,0.2,1-0.2,1c-0.6,0-0.5-0.6-0.5-1.1
				C355.1,37.2,355.4,37.2,355.6,37.2z"/>
			<path fill="#D2967F" d="M352.7,51.6c0.2-0.3,0.5,0.1,0.7,0c0.3,0.3,0.6,0.5,0.2,1C353.5,52.1,352.5,52.5,352.7,51.6z"/>
			<path fill="#DBAF9F" d="M351.5,40.6c-0.1,0.5-0.2,1-0.2,1.4c-0.2-0.3-0.6-0.3-0.5-0.8C350.8,40.5,351.4,40.9,351.5,40.6z"/>
			<path fill="#DBAF9F" d="M361.6,50.7c-0.2-0.3-0.3-0.6-0.5-1c0-0.1,0.1-0.2,0.2-0.2c0.4,0,0.6,0.2,0.7,0.5
				c-0.1,0.2-0.2,0.5-0.2,0.7c0,0,0,0,0,0C361.8,50.7,361.7,50.7,361.6,50.7z"/>
			<path fill="#CF8E75" d="M362.1,39.4c-0.3,0.1-0.6,0.9-1,0c0.3-0.2,0.4-0.7,0.9-0.6C362.4,38.9,362.2,39.2,362.1,39.4z"/>
			<path fill="#CF8E76" d="M355.9,53.1c0,0.2,0,0.3,0,0.5c-0.1,0-0.2,0-0.2-0.1c-0.3-0.2-0.8-0.1-0.9-0.6c0-0.1,0.1-0.2,0.2-0.3
				C355.5,52.3,355.5,53,355.9,53.1z"/>
			<path fill="#D1947C" d="M361.9,50.6c0.4,0.1,0.9,0.3,0.9,0.8c0,0.5-0.6,0.3-0.8-0.2C362,51,361.9,50.8,361.9,50.6
				C361.8,50.6,361.9,50.6,361.9,50.6z"/>
			<path fill="#CD886E" d="M365.5,45.1c0.1-0.4,0.1-0.8,0-1.2c0.6,0.1,0.5,0.9,1.3,1.2C366.1,45.1,365.8,45.1,365.5,45.1z"/>
			<path fill="#CF8F77" d="M351.5,46.3c-0.4,0.2-0.8,0.3-1.2,0.5c-0.1-0.1-0.2-0.2-0.2-0.2c0.2-0.2,0.3-0.3,0.5-0.5
				c0.3-0.2,0.6-0.3,1,0C351.5,46.2,351.5,46.2,351.5,46.3z"/>
			<path fill="#D7A390" d="M349.6,48.2c-0.8-0.5-0.5-0.9,0.3-1.4C349.9,47.5,349.5,47.8,349.6,48.2L349.6,48.2z"/>
			<path fill="#DAAC9B" d="M357.1,36c0.3,0,0.7,0.1,1-0.2c0.2-0.2,0.4,0,0.4,0.2c0,0.3-0.2,0.3-0.4,0.3c-0.2,0.1,0,0.6-0.5,0.5
				c0-0.3-0.2-0.5-0.5-0.5C357,36.2,357,36.1,357.1,36C357,36,357,36,357.1,36z"/>
			<path fill="#D2967F" d="M364.8,40.6c-0.5-0.2-1.3-0.1-1.2-1C364,39.8,364.6,39.9,364.8,40.6z"/>
			<path fill="#CF8E76" d="M361.4,36.8c0,0.3-0.2,0.5-0.5,0.6c-0.2,0-0.4,0-0.3-0.2c0.1-0.2,0.3-0.4,0.5-0.6
				C361.2,36.5,361.3,36.6,361.4,36.8z"/>
			<path fill="#D2947D" d="M356.8,53.1c0.1,0,0.2,0,0.2,0c0.4,0,0.8-0.1,1.3-0.1C357.9,53.6,357.8,53.6,356.8,53.1z"/>
			<path fill="#D1937C" d="M350.6,49c0.5,0.1,0.9,0.3,1.4,0.2c-0.5,1-1,0-1.4,0C350.6,49.1,350.6,49,350.6,49z"/>
			<path fill="#D0927A" d="M352.7,38.4c-0.2,0.2-0.5,0.5-0.7,0.7c-0.2,0-0.4-0.1-0.3-0.4C351.9,38.3,352.4,38.4,352.7,38.4z"/>
			<path fill="#CF8F77" d="M360.2,38.9c0.2-0.7,0.4-1.1,1.2-1C360.9,38.2,360.6,38.5,360.2,38.9z"/>
			<path fill="#D1927B" d="M359.5,38.4c-0.7,0-0.4-0.4-0.2-0.7c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.1,0.2,0
				C359.8,37.8,359.8,38.2,359.5,38.4z"/>
			<path fill="#D39984" d="M365.1,47.4c0.4,0.6,0.6,0.9,0.4,1.3C364.9,48.4,365.1,48,365.1,47.4z"/>
			<path fill="#D59E89" d="M359.7,37.5c-0.1,0-0.2,0-0.2,0c0-0.4,0-0.7,0.4-0.9c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0.1,0.4,0,0.4
				C360,37.3,359.6,37,359.7,37.5z"/>
			<path fill="#D9AA98" d="M350.1,48.2c0.1,0.1,0.1,0.2,0.2,0.3c-0.2,0.2-0.5,0.4-0.8,0.7c0-0.4,0-0.6,0-0.9c0,0,0,0,0,0
				C349.8,48.3,350,48.3,350.1,48.2z"/>
			<path fill="#CE8B72" d="M357.1,37.4c0,0.5-0.3,0.3-0.6,0.3c-0.4-0.1-0.3-0.3-0.2-0.5C356.6,37.2,356.9,37.2,357.1,37.4z"/>
			<path fill="#CF8B72" d="M362,38.2c0.1-0.4,0-0.9,0.7-1C362.4,37.5,362.6,38,362,38.2z"/>
			<path fill="#D39984" d="M349.9,42.7c-0.5,0.2-0.4-0.2-0.6-0.5c0,0,0.1-0.2,0.1-0.2c0.3,0,0.4,0.2,0.4,0.4
				C349.9,42.5,350,42.6,349.9,42.7C349.9,42.7,349.9,42.7,349.9,42.7z"/>
			<path fill="#DBAF9F" d="M350.1,40.8c0.3,0,0.5,0.2,0.3,0.5c-0.1,0.2-0.2,0.6-0.6,0.2C350,41.3,349.8,41,350.1,40.8z"/>
			<path fill="#D29781" d="M354.9,52.1c-0.2-0.1-0.2-0.3,0-0.4c0.2-0.1,0.5-0.4,0.8,0C355.4,51.8,355.1,51.9,354.9,52.1z"/>
			<path fill="#D0937B" d="M357.1,36c0,0.1,0,0.2,0,0.2c-0.3,0.4-0.5,0-0.8-0.1C356.5,35.9,356.8,36,357.1,36z"/>
			<path fill="#CE8C72" d="M350.1,40.8c0,0.3,0.1,0.5-0.2,0.7c-0.1-0.1-0.3-0.3-0.3-0.4C349.6,40.8,349.9,40.8,350.1,40.8z"/>
			<path fill="#D2957E" d="M353.7,37.9c-0.2-0.1-0.6,0.1-0.6-0.3c0-0.3,0.4-0.2,0.6-0.2C353.7,37.6,353.7,37.8,353.7,37.9z"/>
			<path fill="#DBAF9F" d="M349.9,42.7c0-0.1,0-0.2,0-0.2c0.3,0,0.3-0.7,0.7-0.2c-0.2,0.1-0.2,0.3-0.2,0.5
				C350.2,42.7,350,42.7,349.9,42.7z"/>
			<path fill="#D19279" d="M351.5,50.9c0.3,0.2,0.2,0.4,0.2,0.7C351.5,51.4,351.1,51.3,351.5,50.9z"/>
			<path fill="#D2967F" d="M357.6,36.7c0.2-0.2,0.3-0.3,0.5-0.5c-0.1,0.2,0.4,0.7-0.2,0.7c-0.1,0-0.2,0-0.2,0
				C357.5,36.9,357.5,36.8,357.6,36.7z"/>
			<path fill="#D29882" d="M364.9,39.8c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.1,0.2-0.2c0.1,0.1,0.2,0.2,0.1,0.2
				C365.1,39.6,365,39.6,364.9,39.8z"/>
			<path fill="#BC6354" d="M404.6,37.5c0.9,0.3,0.3-0.2,0.2-0.4c-0.2-0.3-0.6-0.5-0.1-0.8c0.5-0.3,0.7,0,0.9,0.4
				c0.2,0.7,0.4,1.2,1.2,0.5c0.2-0.1,0.8,0,1.3,0c-0.2,0.4-1.5,0.6-0.2,1c0.1,0.4-0.2,0.5-0.5,0.6c-0.6,0.2-1.3-0.2-1.6,0.7
				c-0.1,0.2-0.4,0-0.6-0.1c-0.4-0.5-1.1,0.3-1.5-0.4c0-0.1,0-0.2,0-0.3c0-0.3,0.5-0.5,0.4-1C404.2,37.5,404.4,37.6,404.6,37.5z"/>
			<path fill="#B6554D" d="M400,40.6c0.5-0.5,0.6-1.1,0-1.6c-0.3-0.2-0.3-0.6,0-0.8c0.2-0.2,0.3-0.4,0.6-0.3
				c0.4,0.1,0.2,0.5,0.3,0.7c0.2,0.5,0.1,1.2,0.6,1.6c0.6-0.1,0.6,0,0.4,0.7c-0.2,0.3-0.4,0.5-0.6,0.6c-0.3,0.2-0.4,0.4-0.5,0.8
				c-0.3,1.1-0.6,1.2-1.6,0.4c0-0.4,0.4-0.8,0-1.2c-0.3-0.3-0.5-0.7-0.1-1C399.5,39.9,399.6,40.8,400,40.6z"/>
			<path fill="#C4745D" d="M403.9,31c-0.1,0-0.2,0-0.3,0c-0.3,0.2-0.6,0.3-0.7,0.7c-0.2,0.1-0.2,0.2,0,0.3c0,0.4-0.2,0.4-0.5,0.2
				c-0.2-0.3,0-0.6,0-1c-0.3-0.2-0.4-0.4-0.1-0.6c0.4-0.2,0.6-0.7,1.1-0.8c0.2-0.2,0.3-0.3,0.5-0.5c0.1,0.1,0.1,0.2,0.2,0.2
				c0.1,0,0.1-0.1,0.1-0.2c-0.1-0.5,0.4-1.2-0.5-1.4c0.3-0.7,1.3-0.3,1.7-1c0.3,0.2,0.5,0.3,0.8,0.5c-0.1,0.5-1,0-0.9,0.6
				c0,0.3,0.2,0.6,0.6,0.6c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0.1c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.3,0.3,0,0.5
				c0,0.3-0.2,0.5-0.5,0.5c-0.2,0.2-0.4,0.7-0.7,0.5C403.8,30.2,404.1,31.1,403.9,31z"/>
			<path fill="#C77B62" d="M401.7,40.8c-0.1-0.2,0.4-0.7-0.2-0.7c0.2-0.6,0.8-0.4,1.2-0.5c0-0.3-0.3-0.3-0.5-0.4
				c0.2-1.2,0.9-1.8,1.7-1.5c0.3,0.3-0.2,0.7,0,1.1c0,0.1,0,0.2,0,0.2c0.2,0.7,0.2,0.7-0.3,1.1c0.3-0.1,0.7-0.1,1,0
				c0.3,0.4-0.1,0.8-0.1,1.2c-0.6-0.2-0.6,0.7-1.1,0.7c-0.5-0.1-0.7-0.6-1.1-0.9C402.2,40.6,401.8,41,401.7,40.8z"/>
			<path fill="#C06C59" d="M400,55.5c-0.3-0.4-0.7,0-1,0c-0.4,0.1-0.8,0.5-1-0.4c-0.2-0.6-0.7,0.4-1.1,0.1c0.3-0.5-0.2-1,0-1.4
				c0.1,0,0.2,0,0.2,0c0.2,0,0.5-0.1,0.7,0c0.1,0.2,0.3,0.2,0.5,0.2c0.3-0.2,1,0.4,1-0.5c0.1-0.2,0.2-0.3,0.2-0.5
				c0.2-0.1,0.3,0,0.4,0.1c0.2,0.2,0.6,0.4,0.8,0.3c0.4,0.3,0.1,0.7,0.1,1C401,55.1,400.8,55.5,400,55.5z"/>
			<path fill="#B5524C" d="M400.7,50.4c0.3,0.6,0.1,1.1-0.5,1.4c0,0-0.1,0.1-0.1,0.1c-0.9-0.4-1.7-0.8-2.6-1.3
				c-0.1-0.2-0.1-0.3,0-0.5c0.7-0.6,0.5-1.1,0-1.7c0.1-0.2,0.2-0.4,0.5-0.2c1,0,1,0.7,1,1.4c0.1,0.2,0.1,0.5,0.4,0.6
				c0.2,0,0.3,0.1,0.4,0.3c0,0.1,0,0.1,0.1,0.1c0.1,0,0-0.2,0.1-0.2C400.3,50.2,400.5,50.2,400.7,50.4z"/>
			<path fill="#C5765F" d="M395,55.7c-0.5-0.5-0.9-1.1-1.7-0.5c-0.4-0.1-0.8-0.3-1.2-0.2c-0.3-0.5-1.2-1,0-1.4
				c0.2,0.2,0.3,0.2,0.5,0c0.4-0.7,1-0.8,1.7-0.5c0.3,0.2,0.5,0.4,0.2,0.7c0,0.2,0,0.3,0,0.5c0.3,0.1,0.4,0.4,0.5,0.7l0,0
				C395.1,55.2,395.2,55.5,395,55.7z"/>
			<path fill="#BD6656" d="M409.9,23.5c0.1,0.8-0.5,1.4-0.5,2.2c-0.4,0.2-0.8-0.3-1.2-0.2c-0.5-1.3-1.2-0.5-1.8-0.1
				c-0.3,0.2-0.6,0.7-0.9,0.3c-0.4-0.5,0.3-0.6,0.6-0.8C407.2,24.2,408.4,23.4,409.9,23.5z"/>
			<path fill="#C06B59" d="M400,40.6c-0.3,0.6-0.7-0.5-0.9,0c-0.2,0.4,0.2,0.6,0.5,0.9c0.7,0.6-0.1,0.7-0.3,1
				c-0.2,0.3,0.3,0.6-0.1,0.8c-0.2,0.1-0.3,0-0.4-0.1c-0.2-0.5,0.2-1.3-0.7-1.4c-0.1,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0.1-0.1
				c0.4-0.4,0.3-0.6-0.2-0.7c-0.2,0-0.4-0.1-0.4-0.3c0-0.2,0.2-0.4,0.4-0.3c0.3,0.1,0.4-0.1,0.6-0.3C399.3,39.4,399.9,39.6,400,40.6
				z"/>
			<path fill="#C06C59" d="M398.6,57.6c-0.3,0,0.1,1.1-0.7,0.4c-0.2-0.2-0.2,0.3-0.3,0.6c-0.8-0.2-0.9-1-1.4-1.5
				c-0.6-0.6,0.6-1,0.5-1.6c0.1,0.1,0.1,0.4,0.2,0.4C398,55.9,397.9,57.1,398.6,57.6z"/>
			<path fill="#C06B59" d="M398.3,24c0.1,1.6,0.9,2.1,2.4,1.7c0.4-0.1,0.5,0.2,0.6,0.4c0.1,0.3,0.3,0.6-0.1,0.8
				c-0.5,0.3-1.5,0.1-1.7-0.5c-0.1-0.3-0.3-0.3-0.5-0.2c-0.4,0.1-0.8,0.2-0.9-0.4C397.9,25.1,397.6,24.5,398.3,24z"/>
			<path fill="#C16D5A" d="M395.3,50.4c-0.8-0.2-1.5-1.4-1.4-2.2c0.1-0.4,0.3-0.4,0.6-0.4c0.4,0,0.3,0.3,0.2,0.5c0,0.2,0,0.4,0,0.7
				c0.7-1.1,1.7,0.6,2.3-0.4c-0.3,0.4-0.6,0.8-0.9,1.1c-0.1,0.1-0.3,0.3-0.3,0.1c-0.2-0.5-0.6-0.3-0.7-0.2
				C394.8,50,395.5,50.1,395.3,50.4z"/>
			<path fill="#BA5E52" d="M400.5,35c-0.6,0-0.6,0.5-0.9,0.8c-0.4,0.4-0.8,1-1.4,0.2c-0.2-0.3-0.9-0.6-0.6-1c0.3-0.5,0.9-0.1,1.3,0
				l0,0.1l0-0.1c0.5-0.3,1-0.5,1.1-1.1c0.5,0.1,0.5,1.4,1.3,0.4C401.5,35.1,401.3,35.3,400.5,35z"/>
			<path fill="#B7584F" d="M407.5,38.7c0.2-0.2,0.3-0.3,0.5-0.5c0.7-0.4,1.2-0.8,0.7-1.7c1,0.7,1.9,1.5,1.4,2.9
				c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1-0.4-0.1-0.7-0.2-1.2c-0.6,0.3-1,0.5-1.5,0.7C407.7,39.4,407.5,39.2,407.5,38.7z"/>
			<path fill="#B5544D" d="M398.8,43.2c0.2,0,0.3,0,0.5,0c0.7,0.1,1,0.6,1.3,1.1c0.2,0.4,0.7,0.2,1,0.3c0.4,0.4,0.9,0.8,0,1.2
				c-0.1,0-0.2,0-0.4,0c-0.4-0.1-0.9-0.1-1.3-0.3c-0.2-0.2-0.4-0.4-0.2-0.6c0.2-0.4-0.1-0.4-0.3-0.3c-0.7,0.3-1.1-0.1-1.4-0.6
				c-0.1-0.3,0.1-0.3,0.2-0.5C398.5,43.4,398.7,43.3,398.8,43.2z"/>
			<path fill="#B5534C" d="M400.5,35c0.5,0.1,0.8-0.2,0.7-0.7c0.1-0.2,0.2-0.5,0.2-0.7c0.5-0.6,0.6-0.1,0.7,0.2
				c0.4,0.3,0.1,0.7,0,0.8c-0.6,0.7-0.3,1.4-0.2,2.1c-0.2,0.4-0.5,0.5-0.9,0.3c-0.3-0.2-0.3-0.5-0.1-0.8
				C400.8,35.8,400.7,35.4,400.5,35z"/>
			<path fill="#B75951" d="M410.1,26.6c0.2-0.1,0.5-0.2,0.7,0c-0.1,0.8-0.3,1.5-0.9,2.1c-0.2,0-0.3,0-0.5,0c-0.3-0.2-0.8-0.4-0.5-1
				c0.2-0.3,0.3-0.6,0.4-0.8c0-0.9-1.3,0.9-0.9-0.6c-0.1-0.4,0.2-0.3,0.4-0.4C409.3,26.2,409.7,26.4,410.1,26.6z"/>
			<path fill="#BF6857" d="M398.3,43.5c-0.1,0.2-0.2,0.3-0.2,0.5c-0.3,0.2-0.1,0.6-0.5,0.9c-0.4-0.5-1-0.8-1.3-1.4
				c-0.2-0.6-0.8,0-1.2-0.3c-0.2-0.1-0.5,0-0.4-0.4c0.1-0.2,0.3-0.2,0.5-0.2C396.3,42.9,397.3,43.2,398.3,43.5z"/>
			<path fill="#BB6153" d="M400.3,47c-0.1,0.2-0.2,0.5-0.2,0.7c-0.8,0.1-1.8,0.7-1.4-1c0.2-0.6-0.8-1.1-0.1-1.8
				c0.5,0.2,0.2,0.7,0.5,0.9c0,0,0.1,0,0.1,0.1C399.6,46.3,399.9,46.7,400.3,47z"/>
			<path fill="#B85950" d="M402.5,36.2c0-1,0-1.9,1.1-2.3c0.5-0.2,0.7-0.1,0.7,0.5C404.4,35.6,403.6,35.9,402.5,36.2z"/>
			<path fill="#C5765F" d="M408.2,33.6c-0.2,0-0.4,0.1-0.6,0.1c-0.5,0-0.3,0.4-0.3,0.6c0,0.3,0.4,0.3,0.5,0.2c0.4-0.4,1.1-0.3,1.3-1
				c0.5,0.8-0.4,1.1-0.4,1.6c-0.6-0.1-1.2-0.1-1.7-0.4c-0.5-0.2,0-0.8-0.2-1.3c-0.1-0.5,0.5-0.2,0.8-0.3
				C407.9,33.3,408,33.4,408.2,33.6z"/>
			<path fill="#BB6254" d="M403.4,29.8c0,0.7-1,0.7-1,1.4c-0.2,0.1-0.5,0.4-0.7,0.1c-0.3-0.4,0.2-0.5,0.2-0.8c0.1-0.1,0,0,0.1-0.1
				c0,0,0,0-0.1,0.1c-0.1,0-0.2,0-0.2-0.1c0-0.5-0.7-1.2-0.4-1.3C402,29,402.8,29.2,403.4,29.8z"/>
			<path fill="#BF6A58" d="M400.5,54.5c0.1-0.3,0.2-0.6,0.2-1c-0.1-0.2-0.4-0.4-0.2-0.7c0.4-0.3,0.2-0.6,0.2-1
				c0.9,0.6,0.9-0.6,1.4-0.7c0.1,0.2,0.2,0.3,0,0.5c-0.1,0.4-0.2,0.8-0.2,1.2l0,0c-0.6,0.3-0.5,1-0.7,1.4c-0.1,0.1-0.2,0.2-0.2,0.2
				C400.8,54.6,400.7,54.6,400.5,54.5z"/>
			<path fill="#BD6555" d="M408.2,20.2c0,0.2,0,0.4,0.2,0.5c0,0.1,0.1,0.1,0.1,0.2c0.4,0.6-1,0.3-0.5,0.9c0.3,0.4,0.4,0.9,0.1,1.3
				C406.6,21.6,406.6,21.4,408.2,20.2z"/>
			<path fill="#BD6555" d="M394.5,53.8c-0.1-0.2-0.2-0.5-0.2-0.7c-0.3-0.6-0.6-1.3-0.8-1.9c0.1-0.1,0.2-0.1,0.3-0.2
				c0.3,0.9,1.9,0.3,1.5,1.9l0,0C394.9,53,395,53.7,394.5,53.8z"/>
			<path fill="#BF6A58" d="M402.2,33.8c-0.2-0.1-0.4-0.5-0.7-0.2c0-0.7,0.1-1.3,0.9-1.5c0.2,0,0.4,0,0.5-0.2
				c0.3,0.1,0.9-0.2,0.9,0.3c0,0.7-0.7-0.1-0.9,0.4C403.4,33.3,402.7,33.5,402.2,33.8z"/>
			<path fill="#BB6153" d="M396.9,53.8c0.2,0.5,0.6,1,0,1.4c-0.1,0-0.2,0-0.2,0c-1-0.5-1-1.6-1.4-2.4c0,0,0,0,0,0c0.2,0,0.5,0,0.7,0
				l0,0C396,53.4,396.3,53.7,396.9,53.8z"/>
			<path fill="#BA5F52" d="M403.9,37.7c-0.2-0.1-0.5-0.1-0.8-0.2c0,0.4,0.6,1.3-0.5,1.1c-0.1,0-0.2,0.4-0.4,0.6
				c-0.8-0.3-0.5-0.9-0.5-1.4c0.7,0.2,0.6-0.8,1.2-0.7c0.6,0.2,1.1,0.3,1.7,0.5c-0.1,0.2-0.3,0.2-0.5,0.2
				C404,37.8,403.9,37.8,403.9,37.7z"/>
			<path fill="#B95C51" d="M405.3,26.9c-0.1,1-1,0.8-1.7,1c-0.6,0.5-0.8,0.1-1.1-0.3C403.4,26.7,404.4,27.2,405.3,26.9z"/>
			<path fill="#C3715C" d="M402.9,37c-0.1,0.2-0.3,0.3-0.3,0.5c0,1.2-0.5,0.4-0.9,0.3c0.2-1-0.9-0.2-1-0.7c-0.1-0.2-0.8,0.2-0.6-0.5
				c0.2-0.5,0.5-0.5,0.8-0.3c0,0.9,0.4,0.8,1,0.5C402.2,37,402.7,36.5,402.9,37z"/>
			<path fill="#C2715C" d="M410.6,30.3c0,0.2-0.5,0.3-0.2,0.5c0.5,0.3,0.4,0.7,0.4,1.2c-0.2-0.2-0.3-0.3-0.5-0.5
				c-0.2,0-0.3,0.2-0.5,0c-0.3-0.4-0.3-1.2-1.2-0.7c-0.3,0.1,0-0.6-0.3-1c0.4,0.2,0.8,0.3,1.2,0.5C409.9,30.3,410.3,30.3,410.6,30.3
				z"/>
			<path fill="#BF6A58" d="M405.1,31c0.1,0.8,0.3,1.7,0.1,2.7c-0.5-0.6-0.3-1.1-0.4-1.5c-0.3,0-0.2,0.5-0.5,0.3
				c-0.2-0.1-0.2-0.4-0.1-0.5c0.4-0.4,0.1-0.7-0.1-1C404.4,30.8,404.7,30.7,405.1,31z"/>
			<path fill="#C06C59" d="M401.8,18.4c0.1,1.1,2.1-0.4,1.7,1.4c-0.6-0.7-1.2-0.3-1.9,0C401.7,19.3,401.8,18.9,401.8,18.4z"/>
			<path fill="#C2715C" d="M408.9,27.9c0,0.4,0.3,0.6,0.5,1c-0.8,0.5-1.7,0.8-2.7,0.6c0.5-0.1,0.9-0.4,1-0.8c0.3-0.2,0.7-0.2,1-0.5
				C408.7,28,408.8,27.9,408.9,27.9z"/>
			<path fill="#C16E5A" d="M399.8,21c1.5,0.5,2.2,1.8,1.8,2.8C400.8,23.1,400.9,21.9,399.8,21z"/>
			<path fill="#B95C51" d="M394.7,58.8c-0.9,0.2-1.4-0.5-1.6-1.2c-0.2-0.6,0.4-0.8,0.9-0.8C394,57.6,394.8,58,394.7,58.8z"/>
			<path fill="#B5534D" d="M409.9,31.4c0.2,0,0.3-0.3,0.5,0c0,0.7-0.6,0.8-1,1.2c-0.1,0.1-0.2,0.2-0.2,0.2c-0.7-0.1-0.5-0.8-1-1.1
				C408.8,31.5,409.4,32,409.9,31.4z"/>
			<path fill="#BE6757" d="M394,47.3c-0.5,0.3-0.8-0.2-1.2,0.1c-0.3,0.2-0.6,0.5-0.8,0c-0.3-0.5,0.6-0.5,0.6-1c0-0.2,0.4-0.2,0.7,0
				C393.5,46.8,393.7,47.1,394,47.3z"/>
			<path fill="#C06C59" d="M394.7,58.8c-0.4-0.6-1.1-1.1-0.7-1.9c0.2,0.1,0.3,0.2,0.5,0.2c0.3,0.3,0.5,0.6,1,0.5
				c0.3-0.1,0.6-0.3,0.8,0c0.1,0.1,0.1,0.3,0.1,0.4c-0.2,0.4-0.5,0.1-0.7,0C395,57.9,395,58.6,394.7,58.8z"/>
			<path fill="#C6775F" d="M400,28.1c0,0.1,0,0.2-0.1,0.2c-1-0.5-1.4,0.4-2.1,0.7l0,0c-0.1-1.3,0.8-1.4,1.7-1.6
				C400.3,27.3,399.2,28.4,400,28.1L400,28.1z"/>
			<path fill="#C4745D" d="M389.2,30.9c-0.6-0.5-1-1.1-1.9-0.9c0.5-0.5,1.2-0.6,1.7-0.5C390.1,29.7,388.6,30.5,389.2,30.9z"/>
			<path fill="#B5534C" d="M405.8,21.4c0.5,0.6,0.5,1.7-0.1,2.1c-0.4,0.3-0.4,0.3-0.6-0.9C405.2,22.1,405.4,21.7,405.8,21.4z"/>
			<path fill="#BE6757" d="M395.6,46.9c-0.6-0.2-1.3,0.3-1.7-0.4c-0.1-0.2,0.2-0.7,0.3-0.7C394.9,45.7,395.3,46,395.6,46.9z"/>
			<path fill="#BD6455" d="M399.8,50.6c-0.1-0.2-0.3-0.2-0.5-0.2c-0.1-0.4,0.4-0.3,0.5-0.5c0-0.2,0-0.4,0.2-0.5
				c0.1,0.1-0.2,0.7,0.3,0.4c0.5-0.3,0.9-0.8,1.6-0.4c-0.2,0.6-0.9,0.5-1.2,1c-0.3,0-0.5,0-0.7,0.2
				C399.9,50.7,399.9,50.7,399.8,50.6z"/>
			<path fill="#C5755E" d="M406,20.2c-0.8,0.6-0.8,0.6-1.8-0.1c0.4-0.8,1.2-0.3,1.8-0.3C406.1,19.9,406.1,20,406,20.2z"/>
			<path fill="#B5554D" d="M398,37.1c0.4,0.7,0.4,1.2,1,1.5c-0.5-0.2-1,0.6-1.4-0.1C397.2,37.9,397.8,37.7,398,37.1z"/>
			<path fill="#BF6A58" d="M395.9,44c0.8,0.7,0.2,1.3,0,2C395.7,45.4,395,44.7,395.9,44z"/>
			<path fill="#C3735D" d="M405.4,17.4c-0.1,0.6-1.4-0.4-0.7,0.8c0.1,0.2-0.3,0.1-0.4,0.1c-0.2,0.1-0.4,0.2-0.6,0
				c-0.2-0.2-0.1-0.4,0.1-0.5C404.2,17.3,404.7,17,405.4,17.4z"/>
			<path fill="#C6775F" d="M397.9,29.1c-0.2,0.4,0.5,0.9-0.2,1.3c-0.2,0.1-0.5,0.3-0.7,0.1c-0.4-0.5,0.6-0.8,0.2-1.4
				C397.2,28.9,397.7,29,397.9,29.1C397.9,29,397.9,29.1,397.9,29.1z"/>
			<path fill="#BF6A58" d="M402.5,24.4c0.2-0.6,0.5-1,1.1-1c0.2,0,0.6,0.2,0.5,0.5c0,0.2-0.2,0.4-0.4,0.3
				C403.2,24,402.9,24.4,402.5,24.4z"/>
			<path fill="#C06C59" d="M397.5,30.9c0.6,0.1,0.9-1.1,1.6-0.2c0.1,0.2,0.2,0.4,0.1,0.5c-0.1,0.2-0.4,0.1-0.5,0
				C398.4,30.8,397.9,31.4,397.5,30.9z"/>
			<path fill="#BE6757" d="M397.9,53.3c0.3-0.4,0-0.8,0-1.2c0-0.2,0.1-0.4,0.4-0.4c0.2,0,0.5,0,0.5,0.1c0.2,0.5-0.2,0.1-0.3,0.3
				c-0.3,0.5,0.4,0.5,0.5,0.9C398.6,53.1,398.2,53.2,397.9,53.3C397.9,53.3,397.9,53.3,397.9,53.3z"/>
			<path fill="#B6554D" d="M400.5,52.8c0,0.3,0.5,0.4,0.2,0.7c-0.8,0.9-0.7-0.4-1.2-0.5c0-0.2-0.2-0.5-0.1-0.5
				C399.9,52.2,400.1,52.7,400.5,52.8z"/>
			<path fill="#BE6757" d="M406,20.2c0-0.2,0-0.3,0-0.5v0c0.2-0.4,0.3-0.8,0.7-1c0.5,0.5,0.4,1.2,0.7,1.7
				C407,20.5,406.5,20.5,406,20.2L406,20.2z"/>
			<path fill="#C6775F" d="M396.5,29.1c-0.3-0.4-1-0.4-0.8-1.2c0.1-0.2,0.3-0.3,0.6-0.2C397.1,28.1,396.4,28.7,396.5,29.1z"/>
			<path fill="#B4524C" d="M408.9,26.2c-0.2,0-0.4,0-0.5,0.2c-0.4-0.2-0.2-0.6-0.3-0.9c0.3-0.4,0.3-0.4,1.2,0.2c0,0.1,0,0.2,0,0.2
				C409.3,26.1,409.1,26.2,408.9,26.2z"/>
			<path fill="#C16D5A" d="M399.5,16.4c0-0.5,0.3-0.7,0.5-0.5c0.3,0.3,0.9,0.1,0.9,0.6c0,0.3-0.4,0.3-0.6,0.2
				C400,16.7,399.5,16.9,399.5,16.4z"/>
			<path fill="#BC6455" d="M392.6,59.5c-0.2-0.1-0.4-0.2-0.5-0.2c-0.3-0.1-0.4-0.3-0.4-0.6c0-0.2,0.2-0.4,0.4-0.4
				c0.4-0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.5,0.3,0.8C392.7,59.6,392.7,59.6,392.6,59.5z"/>
			<path fill="#BB6153" d="M406.5,31.2c0,0.3,0.6,0.5,0.3,0.9c-0.3,0.3-0.6-0.1-0.9-0.2c-0.4-0.1-0.2-0.4-0.1-0.6
				C405.9,30.8,406.2,31.1,406.5,31.2L406.5,31.2z"/>
			<path fill="#BF6B58" d="M391.6,61.3c-0.2-0.4-0.7-0.8-0.4-1.4c0.1-0.1,0.3,0,0.4,0.1C392.3,60.5,391.7,60.9,391.6,61.3z"/>
			<path fill="#C16E5A" d="M394.2,30.5c0.5,0.3,1,0.1,1.1,0.5c-0.5,0.1-0.2,0.8-0.3,0.8C394.4,31.8,394.5,31.1,394.2,30.5z"/>
			<path fill="#BC6354" d="M394.1,63.6c-0.8,0.1-0.4-1.2-1.3-0.9C393.7,62.3,393.8,62.3,394.1,63.6C394,63.6,394.1,63.6,394.1,63.6z
				"/>
			<path fill="#BF6A58" d="M400.9,13.6c-0.5,0.1-1,0.2-1.3-0.4c-0.2-0.4,0.2-0.5,0.7-0.2c0.1,0.1,0.3,0.2,0.4,0.2
				C400.8,13.4,400.9,13.4,400.9,13.6z"/>
			<path fill="#BB6154" d="M397.6,50.2c0,0.2,0,0.3,0,0.5c-0.7-0.1-0.6,0.3-0.6,0.8c-0.5-0.2-0.7-0.5-0.6-0.9
				C396.6,49.9,397.3,50.6,397.6,50.2z"/>
			<path fill="#C6775F" d="M397.5,28.5c-0.4-0.1-0.3-0.3-0.3-0.5c-0.1-0.4-0.8,0.2-0.7-0.4c0.1-0.4,0.6-0.3,0.9-0.1
				C398,27.8,397.6,28.1,397.5,28.5z"/>
			<path fill="#BB6153" d="M397.9,53.8c-0.2,0.2-0.5,0.1-0.7,0c-0.1-0.3-0.4-0.7-0.1-0.9c0.4-0.3,0.4,0.5,0.8,0.5c0,0,0,0,0,0
				C397.9,53.5,397.9,53.6,397.9,53.8z"/>
			<path fill="#BF6958" d="M393.3,8.6c-0.6-0.2-0.8,0.6-1.3,0.3c-0.1-0.1-0.1-0.3,0.1-0.4C392.4,8.3,392.6,7.8,393.3,8.6z"/>
			<path fill="#C5755E" d="M406.7,18.7c0,0.5-0.5,0.7-0.7,1c0.1-0.5-0.4-0.9-0.2-1.5C406.1,18.4,406.4,18.6,406.7,18.7z"/>
			<path fill="#C06A58" d="M405.8,21.4c-0.1,0.5-0.2,0.9-0.7,1.2C404.2,21.5,405.3,21.6,405.8,21.4z"/>
			<path fill="#C16E5A" d="M400,28.1c0,0,0-0.1,0.1-0.1c0.3-0.3,0.5-0.8,1-0.4c0.2,0.1,0.1,0.2,0,0.4
				C400.8,28.4,400.4,28.3,400,28.1C400,28.1,400,28.1,400,28.1z"/>
			<path fill="#B5554D" d="M405.1,31c-0.3,0-0.6,0-1,0c-0.1,0-0.2,0-0.2,0c0-1,1-0.2,1.2-0.7C405.1,30.5,405.4,30.7,405.1,31z"/>
			<path fill="#C4745E" d="M391.8,32.5c0.4,0.1,0.9-0.2,1.2,0.3c0,0.1-0.1,0.3-0.2,0.4C392.3,33.4,392.1,32.8,391.8,32.5z"/>
			<path fill="#BE6757" d="M406.2,36.8c0-0.6,0.1-1.1-0.7-0.9c0.4-0.5,0.7-0.4,1-0.2C406.9,36.1,406.6,36.3,406.2,36.8z"/>
			<path fill="#BB6053" d="M394.7,41.5c-0.1-0.2-0.4-0.4-0.5-0.6c0-0.2,0.3-0.3,0.5-0.3c0.3,0,0.5,0.1,0.4,0.4
				C395.2,41.3,395,41.4,394.7,41.5z"/>
			<path fill="#BA5E52" d="M387.8,49.6c0.2-0.7,0.7-0.9,1.6-1.3C388.9,49.1,388.3,49.3,387.8,49.6z"/>
			<path fill="#B34F4A" d="M398.1,41.5c0,0.1,0,0.1,0,0.2c-0.3,0.2-0.6,0.4-1,0.1c-0.2-0.2,0-0.4,0.1-0.5
				C397.7,40.9,397.8,41.4,398.1,41.5z"/>
			<path fill="#B95C51" d="M395.5,57.6c-0.3,0.3-0.7,0.3-1,0.1c-0.2-0.2,0-0.4,0.1-0.6C395.1,56.7,395.1,57.4,395.5,57.6z"/>
			<path fill="#BA6052" d="M392.6,59.5c0.1,0,0.2,0,0.2,0c0.3,0.3,0.8-0.3,1,0.3c0.1,0.2,0.1,0.3-0.2,0.3
				C393.2,60.2,392.7,60.1,392.6,59.5z"/>
			<path fill="#BD6555" d="M394.1,39.6c0.1,0,0.4-0.1,0.4,0.2c0,0.3-0.2,0.4-0.5,0.4c-0.3,0-0.5,0-0.5-0.4
				C393.6,39.6,393.9,39.6,394.1,39.6z"/>
			<path fill="#BE6857" d="M401.4,25.5c0.6-0.4,1.1-0.1,1.6,0C402.5,25.8,402.1,25.8,401.4,25.5z"/>
			<path fill="#BE6857" d="M403.9,13.5c0.5,0.2,0.7,0.5,0.8,0.9c0,0.1-0.2,0.2-0.4,0.1C403.9,14.4,403.7,14.1,403.9,13.5z"/>
			<path fill="#C16D5A" d="M398.1,48.2c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2-0.1-0.4-0.3-0.6-0.4C397.4,48.1,398,47,398.1,48.2z"/>
			<path fill="#B95C51" d="M403.4,21c0.1,0.3-0.1,0.4-0.3,0.3c-0.3,0-0.5-0.1-0.4-0.4c0-0.2,0.1-0.4,0.4-0.4
				C403.4,20.5,403.3,20.8,403.4,21z"/>
			<path fill="#C26F5B" d="M391.7,17.4c-0.5,0.2-0.9,0.1-1.2-0.3c0-0.1,0-0.3,0.1-0.3C391.2,16.6,391.3,17.3,391.7,17.4z"/>
			<path fill="#C06B59" d="M390.6,47.6c-0.6-0.4-0.8-0.7-0.7-1.1c0-0.1,0.3-0.2,0.4-0.1C390.8,46.7,390.4,47.1,390.6,47.6z"/>
			<path fill="#B5534C" d="M390,50.4c0.1-0.5-0.6-0.6-0.2-1c0.2-0.2,0.5-0.3,0.6-0.1C390.6,49.6,390.5,50,390,50.4z"/>
			<path fill="#BF6958" d="M384.4,62.5c0,0.4-0.1,0.5-0.3,0.5c-0.2,0-0.3-0.2-0.3-0.4c0-0.3,0.1-0.5,0.4-0.4
				C384.4,62.3,384.4,62.5,384.4,62.5z"/>
			<path fill="#BE6756" d="M397.1,7c0.6,0.1,0.9,0.3,1,0.7c0,0-0.1,0.1-0.1,0.2C397.5,7.9,397.3,7.6,397.1,7z"/>
			<path fill="#BE6756" d="M391.4,25.2c0.7-0.2,1.2-0.3,1.6-0.4C392.6,25.3,392.3,25.7,391.4,25.2z"/>
			<path fill="#C16F5A" d="M407,27.1c0-0.9,0-0.9,0.9-0.5C407.7,27.1,407.1,26.8,407,27.1L407,27.1z"/>
			<path fill="#B7584F" d="M399.3,53.5c0.7,0.3,0,0.5-0.1,0.6c-0.4,0.7-0.5-0.2-0.8-0.1C398.5,53.6,399.1,53.9,399.3,53.5z"/>
			<path fill="#C06A58" d="M398.2,13.1c-0.2-0.3-0.4-0.6-0.6-0.9C398.6,12.4,398.6,12.4,398.2,13.1z"/>
			<path fill="#BB6153" d="M392.1,55c0.5-0.5,0.8,0,1.2,0.2C392.8,55.6,392.4,55.4,392.1,55z"/>
			<path fill="#B7564F" d="M395.4,61.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2-0.4-0.3-0.2-0.6c0.1-0.1,0.2-0.2,0.3-0.1
				c0.3,0.1,0.2,0.4,0.3,0.6C395.5,61.6,395.5,61.8,395.4,61.9C395.5,61.9,395.4,61.9,395.4,61.9z"/>
			<path fill="#BF6A58" d="M391.6,63.7c0,0.3-0.1,0.5-0.4,0.5c-0.2,0-0.2-0.2-0.2-0.3c0-0.2,0.2-0.4,0.4-0.4
				C391.5,63.4,391.6,63.6,391.6,63.7z"/>
			<path fill="#C16F5A" d="M407,27.1c0.3,0.2,0.3,0.6,0.4,1C406.6,27.7,406.6,27.7,407,27.1C407,27.1,407,27.1,407,27.1z"/>
			<path fill="#C26F5B" d="M390.2,54.3c0.1,0.3,0.7,0.1,0.6,0.6c0,0.1-0.3,0.2-0.4,0.2C390.1,54.9,389.8,54.6,390.2,54.3L390.2,54.3
				z"/>
			<path fill="#B85B51" d="M395.9,52.8c0.1-0.3-0.4-0.6,0-0.8c0.1-0.1,0.5,0.1,0.5,0.2C396.6,52.6,396.3,52.8,395.9,52.8
				C395.9,52.8,395.9,52.8,395.9,52.8z"/>
			<path fill="#BC6455" d="M396.1,32.3c0-0.4,0.1-0.6,0.4-0.7c0.1,0,0.3,0.2,0.3,0.3C396.7,32.2,396.5,32.3,396.1,32.3z"/>
			<path fill="#C06C59" d="M401.8,21.8c0.7,0,1,0,1.4,0.3C402.6,22.5,402.4,22.2,401.8,21.8z"/>
			<path fill="#BB6153" d="M403.2,15.5c0.4-0.2,0.6-0.3,1-0.3c0,0.1,0.1,0.2,0,0.2C404,15.7,403.7,16,403.2,15.5z"/>
			<path fill="#BE6757" d="M405.8,28.8c0-0.1,0-0.2,0-0.2c0.1-0.6,0.4-0.2,0.7-0.2c0.1,0,0.2,0.2,0.2,0.2
				C406.4,29,406.1,28.8,405.8,28.8z"/>
			<path fill="#BB6153" d="M399.8,44.9c0,0.2,0,0.4,0.2,0.5c-0.1,0.6-0.5,0.7-1,0.5c0,0,0,0,0,0C399.1,45.4,399.5,45.2,399.8,44.9z"
				/>
			<path fill="#BF6A58" d="M390.3,9.2c0,0.3-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3
				C390.1,9,390.3,9.2,390.3,9.2z"/>
			<path fill="#BD6555" d="M399.8,49.9c-0.2,0.2-0.3,0.3-0.5,0.5c-0.8,0-0.3-0.4-0.2-0.7C399.3,49.8,399.5,49.8,399.8,49.9z"/>
			<path fill="#BB6254" d="M407.4,16.5c0,0.2-0.1,0.3-0.3,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.2,0.2-0.2,0.3-0.2
				C407.4,16.2,407.4,16.3,407.4,16.5z"/>
			<path fill="#BF6A58" d="M405,24.9c-0.2,0.4-0.4,0.4-0.5,0.3c-0.2-0.1-0.4-0.2-0.3-0.4c0-0.2,0.2-0.2,0.4-0.1
				C404.7,24.7,404.9,24.8,405,24.9z"/>
			<path fill="#BD6555" d="M388.5,43c0.4,0,0.7,0.2,0.8,0.6C388.8,43.7,388.6,43.5,388.5,43C388.5,43,388.5,43,388.5,43z"/>
			<path fill="#B5544D" d="M408.7,28.1c-0.2,0.3-0.5,0.7-1,0.5C407.8,27.9,408.3,28.1,408.7,28.1z"/>
			<path fill="#BB6053" d="M396.1,30.8c-0.2-0.1-0.4-0.2-0.3-0.4c0-0.1,0.2-0.3,0.3-0.3c0.2-0.1,0.3,0.1,0.3,0.2
				C396.3,30.5,396.2,30.7,396.1,30.8z"/>
			<path fill="#BC6354" d="M390.4,56.4c0.1-0.5,0.2-0.9,0.5-0.8c0.4,0.1,0.1,0.3,0,0.5C390.8,56.2,390.7,56.2,390.4,56.4z"/>
			<path fill="#BC6355" d="M395,55c-0.2-0.2-0.3-0.5-0.5-0.7C395.1,54.2,395.6,54.2,395,55z"/>
			<path fill="#BF6B58" d="M394,41.8c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.1,0.3-0.1,0.4-0.1
				C394,41.5,394.1,41.6,394,41.8z"/>
			<path fill="#BA5E52" d="M390.8,42.3c-0.2,0-0.4,0.1-0.3-0.1c0-0.1,0.2-0.3,0.3-0.3c0.1,0,0.3,0.1,0.4,0.2
				C391.3,42.3,390.9,42.2,390.8,42.3z"/>
			<path fill="#BA5E52" d="M392.6,53.5c0,0.2,0.4,0.5,0,0.6c-0.4,0.1-0.5-0.3-0.5-0.6C392.3,53.5,392.4,53.5,392.6,53.5z"/>
			<path fill="#B95C51" d="M398,26.7c0.1,0.1,0.3,0.2,0.2,0.2c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0-0.3-0.2
				C397.7,26.9,397.9,26.8,398,26.7z"/>
			<path fill="#C5755E" d="M406,20.2c0.5,0,1,0,1.4,0.2c-0.1,0.2-0.3,0.2-0.5,0.2c-0.3,0-0.5,0-0.7-0.2
				C406.2,20.3,406.1,20.3,406,20.2z"/>
			<path fill="#B6564E" d="M404.1,29.3c0.2,0.1,0.3,0.3,0.3,0.6c0,0-0.2,0.1-0.3,0.1c-0.3-0.1-0.3-0.4-0.3-0.7
				C403.9,29.3,404,29.3,404.1,29.3z"/>
			<path fill="#B5544D" d="M402.9,31.7c-0.4-0.8,0.3-0.7,0.7-0.7C403.5,31.3,403.2,31.5,402.9,31.7z"/>
			<path fill="#BE6756" d="M391.3,18.6c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.2-0.1,0.3-0.3,0.2c-0.1-0.1-0.2-0.2-0.3-0.3
				C391,18.7,391.1,18.6,391.3,18.6z"/>
			<path fill="#BD6555" d="M396,35c-0.2,0-0.3-0.1-0.2-0.3c0-0.1,0.2-0.2,0.2-0.2c0.1,0.1,0.3,0.2,0.3,0.3
				C396.4,34.9,396.2,35,396,35z"/>
			<path fill="#B5544D" d="M405.5,29.8c-0.2,0-0.5,0.4-0.6,0c-0.1-0.3,0.4-0.3,0.6-0.5C405.5,29.5,405.5,29.6,405.5,29.8z"/>
			<path fill="#BE6757" d="M406.3,20.4c0.3,0,0.6,0,0.7,0.2C406.6,21,406.4,20.9,406.3,20.4z"/>
			<path fill="#BD6656" d="M393.1,11.4c0,0.2-0.1,0.3-0.2,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0.1-0.2,0.2-0.2
				C393,11.2,393.1,11.4,393.1,11.4z"/>
			<path fill="#BB6153" d="M400,47.8c0.1,0.1,0.4,0.1,0.4,0.2c0,0.4-0.4,0.4-0.6,0.5C399.9,48.3,399.9,48,400,47.8z"/>
			<path fill="#BA5F52" d="M397,25.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.2-0.2-0.2-0.3c0-0.1,0.2-0.2,0.2-0.2
				C396.8,24.9,396.8,25,397,25.2z"/>
			<path fill="#BF6957" d="M400.2,24.5c0,0,0.1,0.1,0.2,0.3c-0.1,0-0.2,0.2-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
				C399.8,24.4,400,24.4,400.2,24.5z"/>
			<path fill="#BC6355" d="M395,55.7c0-0.2,0-0.5,0-0.7c0.3,0.1,0.5,0.3,0.4,0.7C395.4,55.9,395.1,55.7,395,55.7z"/>
			<path fill="#C26F5B" d="M390.2,54.3c-0.1-0.2-0.2-0.4,0-0.6c0.1-0.1,0.3-0.1,0.3,0.1C390.6,54,390.4,54.1,390.2,54.3L390.2,54.3z
				"/>
			<path fill="#BC6254" d="M389.6,61.4c0.2,0,0.3,0.2,0.3,0.3c0,0.1-0.1,0.3-0.1,0.3c-0.2,0-0.3-0.2-0.3-0.3
				C389.5,61.6,389.6,61.5,389.6,61.4z"/>
			<path fill="#C3735D" d="M406.2,16.9c-0.3,0.2-0.4,0.3-0.4,0.3c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1-0.2,0.1-0.2,0.2-0.1
				C405.8,16.7,406,16.8,406.2,16.9z"/>
			<path fill="#C16E5A" d="M402.6,23.2c0,0-0.1,0.2-0.2,0.2c-0.1,0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C402.5,22.9,402.6,23,402.6,23.2z"/>
			<path fill="#BB6153" d="M402.2,20.5c0,0.1-0.1,0.3-0.2,0.2c-0.1,0-0.1-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2
				C402.1,20.3,402.1,20.4,402.2,20.5z"/>
			<path fill="#BD6656" d="M393.2,49.7c-0.2,0.2-0.3,0.3-0.4,0.3c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C392.9,49.6,393.1,49.7,393.2,49.7z"/>
			<path fill="#B4524C" d="M405.8,35c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.2,0.2-0.2
				C405.5,34.7,405.6,34.8,405.8,35z"/>
			<path fill="#B95B50" d="M393.2,52.5c0,0-0.2-0.1-0.2-0.2c-0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
				C393.5,52.4,393.4,52.5,393.2,52.5z"/>
			<path fill="#B95C51" d="M393,60.9c0,0.2-0.1,0.3-0.2,0.2c-0.1,0-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.2-0.1
				C392.9,60.7,392.9,60.8,393,60.9z"/>
			<path fill="#B7584F" d="M392.1,36.5c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.1-0.2-0.1-0.2-0.2
				C391.9,36.8,392,36.7,392.1,36.5z"/>
			<path fill="#BE6757" d="M388.4,65.4c-0.1,0.1-0.2,0.2-0.3,0.1c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2
				C388.2,65.1,388.3,65.3,388.4,65.4z"/>
			<path fill="#BA6053" d="M393.4,28.6c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0-0.2-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.4-0.3
				C393.3,28.5,393.3,28.5,393.4,28.6z"/>
			<path fill="#BE6857" d="M398.6,39.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.2-0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1
				C398.6,39.2,398.6,39.3,398.6,39.4z"/>
			<path fill="#BF6958" d="M407.6,30.6c-0.1-0.1-0.2-0.2-0.1-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0.1,0.2,0.2,0.2,0.2
				C407.8,30.4,407.7,30.5,407.6,30.6z"/>
			<path fill="#BB6153" d="M406.5,31.2c0-0.2,0.1-0.4,0.1-0.5c0.1,0,0.2,0.1,0.3,0.1C407,31.2,406.7,31.2,406.5,31.2
				C406.5,31.2,406.5,31.2,406.5,31.2z"/>
			<path fill="#B7574E" d="M390.7,52.8c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
				C391,52.7,390.9,52.8,390.7,52.8z"/>
			<path fill="#C77960" d="M401.5,45.6c1-0.3-0.3-0.6,0-1c-0.4-0.1-0.3-0.5-0.4-0.7c-0.3,0.1-0.1,0.6-0.5,0.5
				c-0.4-0.4-0.8-0.8-1.2-1.2c-0.1-0.2-0.4-0.5,0-0.7c0.6,0.3,1.4,0.9,1.2-0.6c-0.1-0.6,0.4-0.5,0.8-0.7c0,0,0.1,0.1,0.1,0.1
				c0.1,0.3,0.1,0.6,0.1,0.9c0.6,1.2,0.7,1.3,2.2,0.7c0.5,0,1,0,1.5-0.1c0.2,0.8-0.3,1.1-0.9,1.4c-0.3,0.2-0.2,0.4-0.2,0.7
				c0,0.2,0.2,0,0,0c0,0-0.1-0.1-0.1-0.2c0-0.2,0-0.3,0.1-0.4c0.2-0.1,0.6-0.1,0.6,0.1c0.4,1.1,1,0.4,1.5,0.3c0.5,0,0.8,0.3,1,0.8
				c0,0.2,0,0.3,0,0.5c-0.2,0-0.3,0-0.5,0c-0.5-0.3-0.7,0.1-0.7,0.5c-0.2,0.1-0.5,0.2-0.7,0.2c-0.1,0.1-0.2,0.1-0.3,0
				c0,0-0.1,0-0.1,0c-1.2,0-1.2,0-2.7-1c0,0.5,0.5,0.9,0.2,1.5c-0.6,0-0.5-0.8-1-0.9C401.3,46.1,401.3,45.9,401.5,45.6z"/>
			<path fill="#C77961" d="M399.1,45.8c0.4,0,0.7-0.2,1-0.5c0.4,0,0.9-0.2,1.2,0.2c0.1,0.7-0.5,0.9-1,1.2c0,0.1,0,0.2,0,0.2
				C399.6,46.9,399.2,46.5,399.1,45.8z"/>
			<path fill="#C77A61" d="M403.2,49.9c-0.2,0.1-0.5,0.2-0.7,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.2-0.2,0.5-0.4,0.2-0.7
				c-0.3-0.6-0.9-1-1.5-1.4c0.9-0.3,1.2,0.4,1.8,0.5l0,0C402.8,48.8,403,49.4,403.2,49.9z"/>
			<path fill="#C06B59" d="M405.1,46.8c0.1,0,0.2,0,0.3,0c0.1,0.7-0.8,1.5,0.2,2.2c0.1,0,0,0.3,0,0.4c-0.2,0.2-0.4,0.1-0.5-0.1
				c-0.4-0.4-0.9-0.9-0.3-1.5C404.9,47.6,405.1,47.2,405.1,46.8z"/>
			<path fill="#BB6153" d="M400.3,46.8c0.1-0.6,0.7-0.7,1-1.2c0.1,0,0.2,0,0.3,0c0,0.2,0,0.5,0,0.7
				C401.1,46.6,401.2,47.9,400.3,46.8z"/>
			<path fill="#B5534D" d="M406,46.6c-0.2-0.3-0.5-0.8,0-1c0.5-0.3,0.5,0.3,0.7,0.5C406.5,46.2,406.3,46.4,406,46.6z"/>
			<path fill="#C77A62" d="M403.5,48.1c0.2,0.4,0.4,0.6,0.7,1C403.5,49,403.3,48.8,403.5,48.1z"/>
			<path fill="#BF6A59" d="M402.7,48.3c0-0.2-0.1-0.5,0.2-0.6c0,0,0.2,0,0.2,0.1C403.1,48,402.9,48.1,402.7,48.3
				C402.7,48.3,402.7,48.3,402.7,48.3z"/>
			<path fill="#B7584F" d="M410.5,37.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.2,0.2-0.1
				C410.3,37,410.5,37,410.5,37.3z"/>
			<path fill="#B5544D" d="M402.2,51.6c0-0.2,0-0.3,0-0.5c0.6-0.7,0.6-0.7,1.8-0.1c0,0.3-0.7,0-0.5,0.5c0.1,0.3,0.1,0.6-0.2,0.7
				c-0.4,0.1-0.2-0.4-0.3-0.5C402.7,51.7,402.4,51.7,402.2,51.6z"/>
			<path fill="#BF6A58" d="M402.2,49.9c0.1,0.1,0.2,0.2,0.2,0.2c-0.1,0.2-0.2,0.4-0.4,0.3C401.8,50.3,402,50.1,402.2,49.9z"/>
			<path fill="#BF6A58" d="M401.9,52.8c0.2,0.1,0.4,0.2,0.4,0.5c0,0-0.1,0.1-0.2,0.1C401.9,53.3,402,53,401.9,52.8
				C401.9,52.8,401.9,52.8,401.9,52.8z"/>
			<path fill="#B6544D" d="M401.9,54.7c-0.2,0-0.4,0.1-0.5-0.1c0-0.1,0.2-0.2,0.3-0.4C402.1,54.2,402.1,54.5,401.9,54.7z"/>
			<path fill="#BA5E52" d="M404.8,51.1c-0.2-0.1-0.4-0.2-0.4-0.5c0,0,0.1-0.1,0.2-0.1C404.9,50.6,404.8,50.9,404.8,51.1
				C404.8,51.1,404.8,51.1,404.8,51.1z"/>
			<path fill="#C77B62" d="M407.5,38.7c0.1,0.3,0.4,0.5,0.7,0.5c-0.2,0.3-0.9,0.5-0.2,1c0.1,0.1,0,0.6-0.2,0.8
				c-0.9,0.8-0.5,1.7-0.4,2.5c0.4,1-0.3,1.2-1,1.3c-0.4,0.2-0.8,0.3-0.8-0.3c0-0.5-0.2-1-0.3-1.5c-0.1-0.8-0.4-0.5-0.8-0.3
				c-1.3-0.5,0-1,0-1.5c0.3-0.3,0.5-0.1,0.8,0c0.1,0,0.1,0.1,0.2,0.1c0.3,0.1,0.4,0.4,0.6,0.6c0-0.1,0-0.1,0-0.2
				c0-0.6-0.5-0.2-0.7-0.4c-0.2-0.4,0.1-0.8-0.2-1.2c-0.1-0.3,0-0.6,0.2-0.8c0.1,0.1,0.4,0.2,0.4,0.2
				C406.4,38.5,406.4,38.5,407.5,38.7z"/>
			<path fill="#B5524C" d="M406.3,44.6c0.4-0.4,1.4-0.2,1.2-1.2c0.5,0,0.7-0.6,1.3-0.7c0,0.5,0.1,1,0.1,1.4
				c-0.7,0.3-1.2,0.9-1.7,1.4c-0.3-0.2-0.6-0.5-1-0.7C406.2,44.8,406.2,44.8,406.3,44.6C406.2,44.7,406.2,44.6,406.3,44.6z"/>
			<path fill="#B5544D" d="M400.5,54.5c0.2,0,0.3,0,0.5,0c0.1,0.4,0.2,0.8,0.2,1.2c-0.3-0.1-0.6-0.2-0.9,0c-0.3,0.2-0.3,0-0.3-0.2
				C400.4,55.3,400.9,55.1,400.5,54.5z"/>
			<path fill="#BC6354" d="M399.6,56c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.1-0.2
				C399.4,55.8,399.5,55.9,399.6,56z"/>
			<path fill="#E4E3E3" d="M435.8,139c-0.3-0.2-0.6-0.4-0.3-0.9C436,138.3,435.7,138.7,435.8,139L435.8,139z"/>
			<path fill="#DFDEDF" d="M433.9,137.3c0.2,0.1,0.4,0.2,0.4,0.5c0,0-0.1,0.1-0.2,0.1C433.8,137.8,433.9,137.5,433.9,137.3
				C433.9,137.3,433.9,137.3,433.9,137.3z"/>
			<path fill="#BD6656" d="M408.9,26.2c0.1-0.2,0.3-0.2,0.5-0.2c0.2,0.2,0.5,0.5,0.7,0.7C409.7,26.6,409.2,26.5,408.9,26.2z"/>
			<path fill="#F9F9F9" d="M319.1,239.5c0,0.2,0,0.5,0,0.7c-0.2,0.1-0.3,0.7-0.6,0.3C318.2,240,318.7,239.8,319.1,239.5z"/>
			<path fill="#FDFDFE" d="M436.3,143.3c0.2-0.1,0.2-0.5,0.5-0.4c0.3,0.1,0.2,0.4,0.2,0.6C436.7,143.5,436.5,143.6,436.3,143.3z"/>
			<path fill="#CB8166" d="M363.3,43.2c-0.2,0.9,0.9,0.2,0.9,0.7l0,0c-0.2,0.2-0.1,0.5-0.2,0.8c-0.2,0.7-0.4,0.7-1,0
				c0.2,0.5,0.2,1-0.3,1.4c-0.1,0-0.6-0.4-0.2,0.2c0.2,0.2,0.2,0.6,0.3,0.9c0.1,0.2,0.1,0.5-0.1,0.6c-0.3,0.2-0.5,0-0.7-0.3
				c-0.2,0.1-0.3,0.2-0.5,0.2c-0.7,0.3-1.3,0.5-1.9-0.1c-0.1-0.1,0-0.3-0.1-0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.3-0.3,0.6-0.6,0.9
				c-0.2,0.2-0.3,0.4-0.7,0.4c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.1,0.2-0.2,0.2-0.3c0-0.1-0.2,0-0.3,0c-0.6-0.2-0.6-0.6-0.3-1.2
				c-0.7,0.7,0.3,1.5-0.4,2c-0.1,0.1-0.3,0.2-0.5,0.1c-0.3,0-0.5,0-0.8,0c-0.5-0.1-0.5-0.5-0.3-0.8c0.1-0.5-0.4-0.2-0.5-0.4
				c-0.2-0.1,0,0.1-0.1,0.1c-0.5,0.5-1,0.6-1.5,0.2c0,0,0,0,0,0c-0.2-0.4-0.3-0.8-0.5-1.2c-0.2-0.2-0.3-0.4-0.2-0.7
				c-0.1-0.4-0.1-0.9,0.4-0.8c1,0.1-0.2,0.8,0.3,1.1c0.4,0,0.3-0.5,0.7-0.6c0.3,1.5,0.8-0.2,1.2,0.1c0.2,0.2,0.3,0.2,0.5,0
				c0-0.1,0.1-0.3,0.1-0.3c-0.5-1.1-0.4-1.2,0.9-1.2c1.5-0.1,0.7-1.1,0.7-1.8c-0.2,0.2-0.6,0.2-0.4,0.6c0.3,0.6-0.2,0.6-0.5,0.7
				c-0.6,0.3-0.2-0.3-0.3-0.4c-0.4-0.5-1-0.6-1.6-0.6c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.2,0-0.3c0.1-0.3,0.3-0.6,0.4-1
				c0.3-0.2,0.2-0.5,0.1-0.8c0-0.1,0-0.2,0-0.2c0.5-0.1,0.8,0.2,1.2,0.4c0.2-0.5,0.2-1.3,1-0.4c0.1-0.1,0.2-0.1,0.3-0.1
				c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.4,0.9,0.2,1.3,0.6c0.1,0.1-0.1,0.2,0.1,0.1c0.1-0.1-0.1-0.1-0.1-0.2c0.3-1,1-0.5,1.5-0.4
				c0.3,0.2,0.9-0.2,1,0.5c0.1,0.4-0.3,0.4-0.4,0.6c0.5,0,1.1,0,1.5,0.5c0,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0.3,0.4,0.5,0.5
				C363.3,43.1,363.3,43.1,363.3,43.2z"/>
			<path fill="#CA8065" d="M354.7,42.7c0.9,0.8,0.4,1.7,0.1,2.7c0.1-0.6-0.4-0.8-0.6-1.2c0-0.2-0.3-0.4-0.3-0.6c0-0.1,0-0.2,0.1-0.3
				c0.1-0.2,0.3-0.4,0.5-0.6C354.5,42.6,354.6,42.6,354.7,42.7z"/>
			<path fill="#DBAF9F" d="M351.8,43.9c-0.1-0.2-0.2-0.3-0.2-0.5c0,0,0,0,0,0c0.3-0.2,0-1.5,1-0.5l0,0c-0.1,0.7,0.8,0.7,1,1.2
				c-0.2,0.3-0.6,0.5-0.6,1.3C352.5,44.6,352.4,44,351.8,43.9z"/>
			<path fill="#D29781" d="M353.5,44.2c-0.5-0.2-1.3-0.3-1-1.2c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.3,0.5-0.4,0.6,0.1
				c0,0.5,0.4,0.5,0.6,0.7c0,0.3,0.5,0.4,0.2,0.7C354,44,353.7,44,353.5,44.2z"/>
			<path fill="#DCB4A5" d="M351.5,46.1c-0.3,0-0.6,0-1,0c0.1-0.3-0.5-1.2,0.5-0.6c0.7,0.4,0.2-1,0.7-0.4
				C352.1,45.6,351.7,45.8,351.5,46.1z"/>
			<path fill="#D8A794" d="M352.7,46.1c0.1,0.2,0.2,0.5,0.2,0.7C352.5,46.7,352.4,46.5,352.7,46.1z"/>
			<path fill="#D6A390" d="M355.9,45.9c0,0.2,0.2,0.4-0.1,0.4c-0.3,0.1-0.4-0.2-0.4-0.5C355.5,45.9,355.7,45.9,355.9,45.9z"/>
			<path fill="#D39882" d="M359,39.1c-0.2,0.4,0,1-0.7,1c-0.5-0.2-0.7-0.8-1.2-1c-0.8-1.1,0.6-0.6,0.7-1c0.4,0.2,0.5,0.7,0.7,1
				C358.7,39.1,358.8,39.1,359,39.1z"/>
			<path fill="#CD886F" d="M358.5,39.1c-0.4-0.2-0.7-0.5-0.7-1c0.2-0.2,0.2-0.6,0.5-0.6c0.4,0,0.3,0.5,0.3,0.8
				C358.6,38.6,358.5,38.9,358.5,39.1z"/>
			<path fill="#CC866C" d="M357.1,39.1c0.8-0.1,1,0.4,1.2,1c0,0,0,0,0,0c-0.2,0.2-0.4,0.4-0.7,0.2c-0.1,0-0.2,0-0.3,0
				C357.7,39.8,357,39.5,357.1,39.1z"/>
			<path fill="#CF8D74" d="M354.1,38.5c0.1,0.1,0.4,0.2,0.3,0.3c0,0.3-0.3,0.3-0.5,0.3c-0.1,0-0.4,0-0.3-0.2
				C353.7,38.7,353.8,38.4,354.1,38.5z"/>
			<path fill="#CE8C73" d="M354.9,47.5c0.3,0,0.6,0,0.9,0c0.2,0,0.4-0.2,0.4,0.1c0,0.1-0.2,0.3-0.4,0.4c-0.3,0.1-0.4,0.3-0.3,0.6
				c0.1,0.3,0.3,0.2,0.6,0.1c0.2-0.2,0.3-0.3,0.5,0c0.2,0.2,0.2,0.4,0.1,0.6c-0.1,0.2-0.3,0.3-0.6,0.5c0.2-0.1,0.3-0.2,0.4-0.3
				c0.3-0.2,0.3-0.6,0.5-0.8c0-0.4,0.5-0.8-0.2-1.3c-0.4-0.2,0.4-0.9,1.5-0.9c-0.9,0.7-0.9,0.7-0.3,1.2c0.2,0.2,0.1,0.3,0,0.5
				c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0.3,0.5,0.6,0.9,0.8c0,0,0.1,0.1,0.1,0.1c-0.2,0.3-0.8,0.4-0.7,1c-0.1,0.1-0.3,0.2-0.4,0.1
				c-0.1,0-0.1-0.2-0.2-0.1c-0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.6-0.5-0.8,0.3-1.2,0.4
				c-0.5-0.5,0.6-1,0-1.6c-0.4,1.6-1.4,0-2,0.4c0,0-0.1-0.2,0-0.4c0.3-0.4,0.2-0.7-0.3-0.9c-0.7-0.3-1.3-0.7-1.1-1.6
				C354,48,354.6,48.2,354.9,47.5L354.9,47.5z"/>
			<path fill="#D2967F" d="M353.5,51.6c0.1-0.4,0.1-0.8,0-1.2c0.2-0.1,0.6-0.4,0.7,0C354.3,50.9,354.1,51.4,353.5,51.6z"/>
			<path fill="#CC856A" d="M353.9,43.4c-0.6,0.2-1.3,0.6-0.8-0.6c0.1-0.3-0.2-0.1-0.4-0.1c0-0.2,0-0.3,0-0.5c0.2,0,0.3,0,0.5,0
				c0.3-0.2,0.1-0.5,0.3-0.7c0.3-0.2,0.8-0.3,0.9-0.7c0.5,0,0.5,0.4,0.5,0.7c0.2,0.4-0.8,0.5-0.3,1c0,0.1,0,0.2,0,0.2
				c-0.1,0-0.2,0-0.2,0C354.4,43,353.8,43,353.9,43.4L353.9,43.4z"/>
			<path fill="#DBAF9F" d="M354.9,41.5c-0.2-0.2-0.3-0.5-0.5-0.7c0.1-0.6,0.4-0.4,0.7-0.2c0.8,0.3,0.2,0.6,0,0.9
				C355.1,41.6,355,41.7,354.9,41.5z"/>
			<path fill="#DBAF9E" d="M353.5,41.5c0.1,0.3,0.4,0.7-0.3,0.7c0-0.2-0.1-0.4-0.1-0.7c0-0.1-0.2-0.3,0-0.3
				C353.3,41.2,353.4,41.4,353.5,41.5z"/>
			<path fill="#CF8D75" d="M365,43.2c-0.5,0-0.7-0.3-1-0.7C365.2,41.6,364.7,43,365,43.2z"/>
			<path fill="#D59F8A" d="M362.6,42.5c-0.4-1-1.3-0.4-1.9-0.5c0.1-0.6,0.7-0.6,1-0.9c0.2,0.1,0.3,0.2,0.5,0.2c0.5,0,0.5,0.6,1,0.7
				C363.1,42.4,363,42.6,362.6,42.5z"/>
			<path fill="#DBB1A1" d="M362.6,42.5c0.2-0.2,0.3-0.3,0.5-0.5c0.4,0.1,0.4,0.4,0.2,0.7C363,42.8,362.6,43.1,362.6,42.5L362.6,42.5
				z"/>
			<path fill="#D59F8A" d="M362.6,42.5c0.2,0.3,0.5,0.2,0.7,0.2c0,0.1,0,0.2,0,0.2C363,42.8,362.4,43.3,362.6,42.5z"/>
			<path fill="#DAAE9D" d="M362.1,47.5c0.3,0.1,0.9,0.4,0.6-0.3c-0.1-0.3-0.3-0.6-0.4-1c-0.1-0.5-0.2-0.5,0.3-0.5
				c0.2,0,0.4-0.6,0.3-0.9c-0.1-0.2-0.7-0.3-0.3-0.6c0.4-0.3,0.4,0.3,0.6,0.5c0.1,0.1,0.1,0.2,0.2,0.3c0.4-0.3,0.3-0.9,0.8-1.1
				c0.2,1-0.3,1.8-0.9,2.4c-0.4,0.4-0.4,0.6-0.1,1c0,0.4,0,0.7-0.5,0.8C362.4,48.2,362.1,48,362.1,47.5z"/>
			<path fill="#D8A693" d="M364.2,47.2c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.3,0.2-0.2,0.3-0.2c0.1,0,0.3,0.1,0.3,0.1
				C364.6,47.2,364.4,47.2,364.2,47.2z"/>
			<path fill="#DAAE9E" d="M359.7,47.6c0.6,0.5,1.3,0,1.9,0.2c-0.2,0.4-0.3,0.8-0.5,1.2c-0.1,0.1-0.2,0.1-0.3,0.1
				c-0.2,0-0.3-0.1-0.3-0.3c-0.1-0.2-0.2-0.3-0.4-0.2c-0.4-0.1-0.7-0.3-0.7-0.7C359.4,47.7,359.5,47.6,359.7,47.6z"/>
			<path fill="#CB8166" d="M360.9,49c0.1,0,0.2,0,0.2,0c0.2,0.1,0.2,0.3,0.3,0.5c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.2,0-0.3,0
				c0,0,0,0,0,0C360.6,49.5,360.4,49.2,360.9,49z"/>
			<path fill="#D09078" d="M359.2,41.3c-0.4-0.1-0.8-0.2-1.2-0.2c-0.7,0-0.2-0.3-0.2-0.6c0.2-0.2,0.5-0.2,0.7,0
				c0.6,0.1,1-0.7,1.7-0.5c0.3,0,0.5,0.2,0.5,0.5c-0.2,0-0.3,0.5-0.5,0.3c-0.5-0.7-0.6,0.1-0.9,0.2
				C359.3,41.1,359.3,41.2,359.2,41.3C359.3,41.3,359.2,41.3,359.2,41.3z"/>
			<path fill="#CF8F77" d="M360.9,49c-0.2,0.2-0.1,0.5,0,0.7c-0.6-0.5-1.1,0-1.7,0c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.1-0.4
				c0.2-0.3-0.2-0.4-0.2-0.6c-0.1-0.1-0.1-0.3-0.1-0.5c0.2-0.2,0.3-0.5,0.5-0.7c0.2-0.1,0.3,0,0.3,0.2c0.1,0.3,0.4,0.6,0.7,0.7
				c0.1,0.1,0.3,0.1,0.5,0.2C360.7,48.8,360.8,48.9,360.9,49z"/>
			<path fill="#DAAC9B" d="M358,50.6c0.2,0,0.3,0,0.5,0c0.3,0.2,0.2,0.3,0,0.5c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.2,0-0.2
				C358,50.8,357.9,50.8,358,50.6C358,50.7,358,50.6,358,50.6z"/>
			<path fill="#DAAC9B" d="M358.5,40.6c-0.2,0-0.5,0-0.7,0c-0.1-0.1-0.2-0.2-0.2-0.2c0.2-0.1,0.5-0.2,0.7-0.2
				C358.3,40.2,358.4,40.4,358.5,40.6z"/>
			<path fill="#D29781" d="M352.5,43c-0.7-0.5-0.6,0.5-1,0.5c0.1-0.3-0.3-0.7,0.1-0.9C352,42.4,352.4,42.4,352.5,43z"/>
			<path fill="#CA7F64" d="M405.3,39.1c0,0.3,0,0.6,0,0.9c-0.2,0.2-0.5,0.2-0.7,0c-0.5,0.2-1,0-1.6-0.1c0.4-0.2,0.6-0.3,0.9-0.4
				c-0.1-0.3-0.7-0.1-0.5-0.6c0-0.1,0.3,0,0.4-0.1C404.3,39.4,404.9,38.3,405.3,39.1z"/>
			<path fill="#CA7F64" d="M403.9,37.7c0.1,0,0.2,0,0.2,0c0.7,0.5-0.2,0.6-0.2,1C403.4,38.3,403.7,38,403.9,37.7z"/>
			<path fill="#C77960" d="M401.7,40.8c0.2,0,0.3-0.3,0.5,0c0.1,0.4-0.1,0.7-0.3,1.1c-0.5,0.1-0.5-0.3-0.6-0.6c0,0,0,0,0,0
				C401.4,41.1,401.5,41,401.7,40.8z"/>
			<path fill="#C26F5B" d="M404.6,41.1c0,0.3,0,0.5-0.3,0.7c-0.4,0.4-0.2,0.6,0.2,0.7c0.5-0.2,0.7,0,0.5,0.5
				c-0.5,0.2-0.9,0.6-1.4,0.2c-1.3,0.3-1.3,0.2-1.3-0.9c0.1-0.1,0.1-0.2,0.2-0.2c0.2-0.1,0.7,0.3,0.6-0.4c0.4-0.2,0.8-0.5,1.2-0.7
				C404.4,41,404.5,41,404.6,41.1z"/>
			<path fill="#B85A50" d="M403.1,41.8c0.1,0.3,0.9,0.2,0.6,0.7c-0.4,0.6-0.7-0.1-1-0.2c-0.2-0.1-0.3-0.2-0.5-0.3
				c-0.1,0-0.2-0.1-0.2-0.2c-0.3-0.4,0.1-0.7,0.2-1C402.7,40.9,402.8,41.4,403.1,41.8z"/>
			<path fill="#B4504B" d="M404.6,41.1c-0.1,0-0.2,0-0.2,0c-0.5-0.5,0.2-0.6,0.2-1c0.2,0,0.5,0,0.7,0c1,0.3,0,0.6,0,1
				c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.1-0.1,0.1C405.1,41.2,404.8,41.1,404.6,41.1z"/>
			<path fill="#C5755E" d="M399.8,50.6c0.1,0,0.2,0,0.2,0c0.2,0.2,0.3,0.4,0.1,0.6c-0.1,0.1-0.2,0-0.3,0
				C399.6,51,399.5,50.8,399.8,50.6z"/>
			<path fill="#CA7F64" d="M398.8,35.1c0.2,0.1,0.4,0.2,0.4,0.5c0,0-0.1,0.1-0.2,0.1C398.7,35.5,398.9,35.3,398.8,35.1
				C398.8,35,398.8,35.1,398.8,35.1z"/>
			<path fill="#BC6254" d="M400.5,44.4c0.2-0.3-0.2-1,0.5-1c0.8,0.1,0.1,0.9,0.5,1.3C401.1,44.8,400.5,45.7,400.5,44.4z"/>
			<path fill="#C06C59" d="M409.8,28c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2C410.1,27.9,410,28,409.8,28z
				"/>
			<path fill="#C5745E" d="M403.5,35.2c-0.1,0.2-0.2,0.3-0.3,0.2c-0.2,0-0.2-0.2-0.2-0.4c0-0.1,0.2-0.2,0.2-0.2
				C403.4,34.9,403.5,35,403.5,35.2z"/>
			<path fill="#CA7F64" d="M401.9,30.5c0.1-0.2,0.3-0.4,0.4-0.6c0.1,0.1,0.2,0.2,0.2,0.2C402.4,30.4,402.2,30.5,401.9,30.5
				C401.9,30.5,401.9,30.5,401.9,30.5z"/>
			<path fill="#B5534D" d="M405.1,43c0-0.3-0.2-0.5-0.5-0.5c0-0.4,0.2-0.7,0.7-0.7c0.4,0.1,0.3,0.4,0.3,0.7c0,0.7,0.6,1.3,0.3,2
				c-0.1,0.3,0.3,0.1,0.4,0.1c0,0.1,0,0.2,0,0.2c-0.3,0.2-0.6,0.4-0.9,0.6c-0.5,0.3-0.7,0.2-0.7-0.4c0.1-0.5-0.4-0.5-0.6-0.7l0,0
				C404.3,43.9,405.1,43.7,405.1,43z"/>
			<path fill="#B6564E" d="M402.4,42.3c0,0.8,0.4,1.1,1.2,1c-0.3,0.7-1.1,0.2-1.5,0.6c-0.2-0.6-1.2-0.7-0.9-1.6c0.1,0,0.1,0,0.2,0
				C401.7,42.1,402.1,42.1,402.4,42.3z"/>
			<path fill="#B6554D" d="M403.3,44.8c-0.1,0.3-0.4,0.5-0.6,0.5c-0.3-0.1-0.8-0.4-0.8-0.7c-0.1-0.4,0.4-0.4,0.7-0.3
				C402.9,44.3,403.3,44.4,403.3,44.8z"/>
			<path fill="#BD6455" d="M404.1,44.4c0,0.1,0,0.2,0,0.2c-0.1,0.3,0.4,0.6,0,0.9c0,0-0.1,0-0.2,0C404,45.2,403.4,44.6,404.1,44.4
				C404.1,44.4,404.1,44.4,404.1,44.4z"/>
			<path fill="#CA7F64" d="M401.9,41.8c0.1,0,0.2,0,0.2,0c-0.1,0.6-0.6,0.4-1,0.5c0,0,0,0,0,0c0-0.3-0.2-0.6,0-1
				C401.5,41.4,401.6,41.8,401.9,41.8z"/>
			<path fill="#B85B50" d="M405.3,41.3c0-0.1,0-0.2,0-0.2c1.6-0.7,0.7,0.9,1.4,1.3C405.7,42.3,405.9,41.4,405.3,41.3z"/>
			<path fill="#D09077" d="M360.7,44.2c-1,0.6-1.3-0.4-1.8-0.8C359.5,43.5,360.2,43.5,360.7,44.2L360.7,44.2z"/>
			<path fill="#D6A38F" d="M359.2,49.2c0,0.2,0,0.3,0,0.5c-0.6,0-1-0.2-1-1c0.2-0.2,0.3-0.3,0.5-0.5c0.1,0.1,0.2,0.2,0.2,0.2
				C358.8,48.8,359.1,49,359.2,49.2z"/>
			<path fill="#D29781" d="M354.9,41.5c0.1,0,0.2,0,0.2,0c0.1,0.4-0.3,0.6-0.5,1c-0.2,0-0.5,0.1-0.6-0.2
				C354,41.7,354.7,41.9,354.9,41.5z"/>
			<path fill="#D7A38F" d="M356.1,48.7c-0.2,0.2-0.5,0.5-0.7,0.2c-0.2-0.3,0-0.7,0-1.1c0.2,0,0.4,0.1,0.5,0.1
				C355.7,48.3,355.5,48.7,356.1,48.7z"/>
			<path fill="#D1937C" d="M356.9,41.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.1-0.3-0.2-0.4-0.4c-0.1-0.2,0.1-0.4,0.3-0.4
				C356.8,41.1,356.9,41.2,356.9,41.5z"/>
			<path fill="#D5A08C" d="M359.5,47.8c-0.1-0.1-0.2-0.2-0.3-0.2c0.2-0.4,0.6-0.7,1.1-1.1c0,0.7-0.6,0.7-0.6,1.1
				C359.6,47.6,359.6,47.7,359.5,47.8z"/>
			<path fill="#D7A693" d="M360.1,46.3c0.3-0.5,0.6-0.4,1.3-0.3C360.8,46.1,360.5,46.2,360.1,46.3z"/>
			<path fill="#D6A38F" d="M359.2,41.3c0-0.1,0-0.2,0-0.2c0.4,0,0.5,0.3,0.5,0.6c0,0.1-0.1,0.1-0.1,0.1
				C359.3,41.8,359.3,41.5,359.2,41.3z"/>
			<path fill="#D59E8A" d="M359.2,46.1c0-0.3-0.2-0.6,0.2-0.8c0,0,0.2,0,0.2,0.1C359.8,45.7,359.5,45.9,359.2,46.1L359.2,46.1z"/>
			<path fill="#D09077" d="M360.7,44.2c0.3,0.2,0.5,0.4,0.8,0.6C360.8,45,360.6,44.7,360.7,44.2L360.7,44.2z"/>
			<path fill="#CE8C73" d="M354.9,47.5c-0.2,0-0.5,0.1-0.6-0.2c0,0,0.1-0.2,0.2-0.2C354.8,47,354.9,47.3,354.9,47.5
				C354.9,47.5,354.9,47.5,354.9,47.5z"/>
			<path fill="#D5A08B" d="M358,48.3c0-0.2,0-0.3,0-0.5c0.1-0.1,0.1-0.3,0.2-0.4c0.1,0.1,0.2,0.1,0.2,0.2
				C358.5,47.9,358.3,48.1,358,48.3z"/>
			<path fill="#D8A896" d="M357.1,48.7c0,0.4,0,0.7-0.5,0.7l0,0c0-0.2,0-0.5,0-0.7C356.7,48.7,356.9,48.7,357.1,48.7z"/>
			<path fill="#D09078" d="M357.7,41.5c0.3,0.4,0.4,0.6,0.3,1C357.7,42.2,357.7,41.9,357.7,41.5z"/>
			<path fill="#D09179" d="M357.2,45.8c-0.1,0-0.2,0-0.2-0.1c0-0.1,0.1-0.2,0.2-0.3c0,0,0.2,0,0.2,0.1
				C357.5,45.6,357.3,45.7,357.2,45.8z"/>
			<path fill="#D8A693" d="M361.1,42.6c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0-0.2-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.4-0.3
				C361,42.5,361,42.5,361.1,42.6z"/>
			<path fill="#D59E8A" d="M359.2,46.1c0,0.2,0.1,0.5-0.2,0.6c0,0-0.2-0.1-0.2-0.1C358.8,46.3,359,46.2,359.2,46.1
				C359.2,46.1,359.2,46.1,359.2,46.1z"/>
			<path fill="#D29781" d="M353.9,43.5c-0.3-0.5,0.2-0.5,0.5-0.7C354.4,43.1,354.3,43.3,353.9,43.5z"/>
			<path fill="#D7A490" d="M356.6,49.4c0,0.4-0.4,0.5-0.7,0.9C355.9,49.7,356.1,49.5,356.6,49.4C356.6,49.5,356.6,49.4,356.6,49.4z"
				/>
			<path fill="#DCB1A2" d="M358,50.6c0,0.1,0,0.2,0,0.2c-0.2,0-0.5-0.1-0.4-0.4c0-0.1,0.3-0.2,0.4-0.2C358.2,50.3,358,50.5,358,50.6
				z"/>
			<path fill="#CB8166" d="M360.6,48.7c-0.2,0.1-0.3,0.1-0.5,0.2c0-0.1,0-0.3,0-0.4c0-0.2,0.1-0.5,0.4-0.4
				C360.9,48.3,360.8,48.5,360.6,48.7z"/>
			<path fill="#CB8166" d="M359.2,49.2c-0.5-0.1-0.6-0.3-0.2-0.7C358.6,48.9,360.1,48.6,359.2,49.2z"/>
			<path fill="#C77960" d="M401.2,42.3c0.3-0.2,0.6-0.3,1-0.5c0.4-0.1,0.3,0.3,0.5,0.5c0,0-0.1,0-0.1,0l-0.1,0
				C402,42.3,401.6,42.6,401.2,42.3z"/>
		</g>
	</g>
</g>
</svg>
        )
    }
}

export default Character2Airplane;