import React from "react";
import GlobalHeader from "./GlobalHeader";
import ScreenFallback from "./ScreenFallback";
import { Link } from "react-router-dom";
import data from "../data.json";

class Legal extends React.Component {
  componentDidMount(){
    this.props.updateClickstream("Legal");
  }
  render() {
    const currentScreen = this.props.currentScreen;
    const updateLastClick = this.props.updateLastClick;
    const updateClickstream = this.props.updateClickstream;
    return (
      <div className="screen screen--legal">
        <div className="screen_content">
          <GlobalHeader
            updateClickstream={updateClickstream}
          />
          <div className="legal_copy_wrapper">
            {data[currentScreen].copy.map((copy, i) => (
              <p key={i} className="option_copy">
                {copy}
              </p>
            ))}
          </div>
          <div className="advance_button_wrapper">
            <Link
              to="/Contact"
              onClick={() => updateLastClick('Connect with us')}
            >
              Connect with us
            </Link>
          </div>
        </div>
        <ScreenFallback
          updateClickstream={updateClickstream}
        />
      </div>
    );
  }
}

export default Legal;
