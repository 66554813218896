import React from "react"

class Character3Airplane extends React.Component {
    render(){
        return(
<svg version="1.1" id="character-3" className="character-animated character-animated--airplane" x="0px" y="0px" viewBox="0 0 442.4 621.1" enable-background="new 0 0 442.4 621.1">
<g id="glide">
	<g id="_x37_aV8Vf_1_">
		<g>
			<path fill="#F9F9F8" d="M226.1,125.3c0.5-0.2,0.7-0.3,0.9-0.4c0,0.2-0.1,0.4-0.4,0.5C226.6,125.4,226.4,125.4,226.1,125.3z"/>
			<path fill="#FEFEFE" d="M27.9,67.1c-1.2-1.5-2.4-3-3.6-4.5c-1.7-2.2-3.3-4.3-4.9-6.5c-1.3-1.7-2.5-3.4-3.7-5
				c-0.2-0.4-0.5-0.6-0.7-1c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.9-0.7-1.2-1.2-1.7l0,0c-0.2-0.2-0.1-0.7-0.5-0.7c-0.2-0.1-0.3-0.2-0.5-0.3
				c-0.1-0.4-0.4-0.7-0.7-1c-0.4-1.1-1.4-1.8-2-2.7c-0.1-0.2-0.3-0.2-0.5-0.1c-0.3,0.1-0.6,0.2-0.6-0.2c0-0.1,0.3-0.3,0.5-0.4
				c0.3-0.8,0-1.3-0.7-1.7c-0.7-0.4-0.8-1.1-0.8-1.9c0-0.8-0.3-1.3-1.1-1.4c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.1,0-1-0.9-0.9
				c-0.4,0-0.8-0.7-1.1-1.1l0,0c-0.5-1.1-0.4-2.3-0.3-3.5c0-0.1,0.1-0.2,0.2-0.4c0.3-0.3,0.5-0.9,1.2-0.6c0.2,0.2,0.4,0.3,0.7,0.1
				c0.6-0.4,1.5-0.2,2,0.3c-0.2,0.2-0.5,0-0.7,0.1c0.3,0,0.7,0,0.9-0.2c0.4,0,0.7-0.1,1.1,0.1c0.3,0,0.5,0,0.7,0.2
				c0.3,0.4,0.6,0.8,0.9,1.2c0.2,0.2,0,0.5,0.5,0.4c1-0.1,2-0.2,3-0.3c1.4-0.2,2.6,0.4,4,0.6c0.1,0,0.2,0,0.2,0
				c0.1,0.1,0.2,0.3,0.1,0.4c-0.6,1.3,0.3,1.2,1.1,1.2c1.4,0,2.8,0,4.1,0c0.5,0,1-0.1,1.4,0.3c1.3,0.3,2.6,0.2,3.8,0.8l0,0
				c-0.1,0.7-0.7,0.4-1.2,0.6c1,0.4,1.9,0.4,2.9,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.5-0.1,0.8,0c1,0.5,2,0.4,3.1,0.6l0,0
				c0.7,1.2,1.7,1.6,3.1,1.5c1.8,0,3.6,0,5.3,0.4c0.1,0,0.2,0,0.2,0c0.3,0.3,0.8,0.3,1.2,0.5c0.3,0.1,1.1-0.1,0.5,0.8
				c-0.2,0.3,0.2,0.2,0.3,0.2c1,0.1,1.9,0.2,3,0c0.9-0.2,1.9,0.3,2.9,0.5c1.1,0.2,2.1,0.3,3.2,0.6c3.3,0.9,6.8,1.4,10.1,2.4
				c1.2,0.4,2.5,0.2,3.6,0.8c0.2,0.2,0.3,0.5,0.4,0.7c0.2,0.7,0.6,1,1.2,0.8c0.6-0.2,1.2-0.1,1.9,0c1.2,0.3,2.4-0.4,3.5,0.1
				c2.6,0.3,5.2,0.8,7.7,1.7c0.1,0.4-0.3,0.5-0.5,0.7c-0.4,0.3-0.9,0.7-0.8,1.1c0.1,1-0.4,1.1-1.2,1.1c-0.7,0-1.1,0.2-1,1
				c0,0.2-0.2,0.3-0.3,0.5c-0.4,0.9-1.4,1-2.1,1.6c-1.1,1-2.4,1.8-3.7,2.7c-0.7,0.4-1.3,0.7-1.9,1.2c-0.1,0.1-0.2,0.2-0.2,0.2
				c-0.2,0.2-0.5,0.2-0.7,0.5l0,0c-0.5,0.5-0.9,0.2-1.3-0.4c0,0.6,0.8,0.6,0.4,1.1l0,0c-0.4,0.1-0.8,0.3-1,0.7c-0.1,0-0.2,0-0.2,0
				c-0.7-0.4-0.3,0.3-0.5,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.7-0.5-1.6-0.4-2.4-0.4c-0.4,0-0.9,0.1-1.3,0.1c-0.1,0-0.2-0.1-0.3-0.2
				c-0.2-0.8-0.8-0.9-1.4-0.8c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.5,0.4-0.4,0.6-0.6c-0.4,0.1-0.7,0.3-0.9,0.6c0,0-0.1,0-0.1,0
				c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.4-0.4-0.5-0.8-0.3c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.3-0.7-0.7-0.4c-0.1,0-0.2,0-0.3-0.1
				c-0.3-0.2-0.5-0.3-0.8-0.4c-0.1-0.1-0.1-0.2-0.1-0.2c-0.2-0.7-0.2-0.7-1.2-0.9c-0.7-0.1-1.4,0-2-0.1c-1.7-0.7-3.5-1.1-5-2.3
				c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.4,0-1-0.6-0.4c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.2,0-0.6-0.6-0.6c-0.7,0-1.4-0.1-2.1-0.3
				c-0.6-0.1-1.3-0.3-1.6-0.9c0-0.9-0.9-0.7-1.3-1c-0.3-0.2-0.8-0.2-1.3-0.1c-0.3,0-0.6-0.1-0.8-0.3c-0.5-0.9-0.4-1.2,0.5-1.3
				c-1-0.1-2-0.1-3,0c-2-0.4-3.7-1.4-5.4-2.4c-0.3-0.4-0.6-1-1.3-0.7c-0.1,0-0.2,0-0.3-0.1c-0.2-1.2-1.1-0.9-1.9-0.8
				c-0.5,0-0.9-0.1-1.2-0.5c-0.5-0.7-1.2-1-2-1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.3-0.8-0.6-1.3-0.3c-0.1,0.1-0.1,0.1-0.2,0.2
				c0,0.5,0.5,0.4,0.7,0.5c0.2,0.1,0.4,0.3,0.6,0.5c0.3,0.3,0.6,0.4,0.9,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.3,0.6,0.4,0.8,0.7
				c0.3,0.5,0.3-0.3,0.5-0.1c0.4,0,0.7,0.2,0.9,0.5c0.4,1.7,0.4,1.7,2.4,1.7c0.2,0,0.3,0.1,0.5,0.2c0.7,0.3,0.8,1.2,1.6,1.5
				c0.3,0.1,0.5,0,0.8-0.1c0.3,0,0.6,0.1,0.9,0.3c1.8,1.2,3.7,2.3,5.5,3.5c0.2,0.1,0.3,0.3,0.4,0.4c0.3,0.1-0.6,0.6,0.3,0.6
				c0.3,0,0.7,0.5,1.1,0.8c0.4,0.3,0.7,0.1,1,0.1c1,0.6,2.3,0.7,3,1.9c-0.3,1.2,0.8,1.1,1.4,1.4c0.5,0.2,1.2,0.1,1.4,0.8
				c0,0.1-0.2,0.1-0.1,0.2c0-0.1,0.1-0.1,0.2-0.2c0.2,0.3,0.4,0,0.6,0c0.3,0,0.6,0.2,0.8,0.4c0.3,0.3,0.8,0.4,1.2,0.7
				c0.3,0.3,0.8,0.4,0.9,0.8c0,0.1-0.2,0.1-0.2,0.3c0,0.1,0.2,0,0.3,0c1.2,0.4,2,1.4,3.3,1.4c1.2,0.5,2.3,1.3,3.4,2
				c0.6,0.4,0.8,1.1,0.8,1.8c-0.1,1.1,0.1,2.2-0.3,3.3c-0.2,0.5,0,0.9,0.5,1.2c1.1,0.7,1.4,1.8,1.5,3.1c0,0.6-0.2,1.3,0.4,1.9
				c0.2,0.2,0.1,0.9-0.6,0.7l0,0c-0.3-0.5-0.9-0.7-1.4-1c-2.3-1.1-4.3-2.7-6.5-4.1c-0.2-0.2-0.5-0.3-0.7-0.5l0,0
				c-0.4-0.3-0.8-0.4-1.2,0l0,0c-0.8,0.2-1.3,0.7-1.9,1.2l0,0c-0.5,0.1-0.8,0.4-1.2,0.7l0,0c-0.6-0.1-0.4-0.4-0.3-0.8
				c0.1-0.3,0.5-0.4,0.6-0.8c-0.7,0-0.7,0.5-0.7,0.8c-0.1,0.6-0.4,0.9-1,1c-1.2,0.1-1.9,0.8-2.2,1.9c-2.1,1.4-4.2,2.9-6.2,4.3
				c-0.5,0.3-0.5-0.1-0.6-0.4c-0.3-0.9-0.6-1.8-1.7-2.1c-0.6-0.2-0.4-0.9-0.4-1.4c-0.3,0.6-0.6,1-1,0.2c-0.1-0.3-0.4-0.4-0.6-0.6
				c-0.9-0.9-1.5-1.9-2.2-2.9c-0.4-0.9,0.1-1.9-0.5-3.1c-0.1,0.6,0.4,1.2-0.1,1.4c-0.5,0.2-0.7-0.3-1.1-0.5c0-0.1,0-0.2,0-0.2
				c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.8,0-1.6-1.2-1.5c-0.3,0-0.6-0.3-0.6-0.6C28.9,67.2,28.4,67.2,27.9,67.1z"/>
			<path fill="#98989C" d="M50.7,60.6c-0.2-0.2-0.5-0.3-0.7-0.5c-1.2-0.8-2.6-1.1-3.6-2.2c-0.8-1-2.1-1-3-1.9
				c-0.9-0.3-1.7-0.8-2.3-1.5c-2.2-1.4-4.4-2.9-6.7-4.1c-1-0.2-1.6-1.1-2.5-1.4c-0.1-0.1-0.2-0.2-0.2-0.2c-1.1-0.3-1.9-0.9-2.6-1.7
				c-0.2-0.2-0.5-0.3-0.7-0.5c-0.5-0.3-1-0.3-1.4-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3-0.2-0.7-0.2-0.9-0.5c-0.2-0.2-0.4-0.3-0.6-0.5
				c-0.3-0.3-1.2-0.1-0.9-1l0,0c0.6-0.4,1.1-0.2,1.7,0.2c0.1,0,0.1,0.1,0.2,0c0.8,0.1,1.5,0.6,2.2,1c0.3,0.3,0.7,0.4,1.2,0.5
				c0.8,0.1,1.3,0.6,2,1l0,0c0.5,0.1,1.1,0.2,1.5,0.7c1.9,0.8,3.6,1.8,5.5,2.4c1,0.1,1.9,0.6,2.6,1.3c0.2,0.2,0.4,0.2,0.7,0.2
				c0.9,0.4,1.9,0.6,2.7,1.2c0.5,0.4,1,0.6,1.6,0.8c0.8,0.5,1.9,0.5,2.7,1.2l0,0c0.3,0.2,0.8,0.1,0.9,0.5c1.6,0.8,3.3,1.6,5,2.1
				c1,0.5,2.3,0.5,3.1,1.5l0,0c0.3,0.1,0.7,0.2,1,0.5l0,0c0.3,0.2,0.7,0.2,1,0.5c0.1,0,0.1,0.1,0.2,0c0.4,0.1,0.8,0.1,1,0.5l0,0
				c0.2,0.2,0.6,0,0.7,0.3l0,0c0.6,0.2,1.4,0.2,1.7,0.9l0,0c0.9,0.3,2,0.3,2.6,1.2c0.1,2.4-0.6,4.6-1,7c-0.4,2.8-0.9,5.6-1.4,8.6
				c-0.2-0.3-0.4-0.5-0.5-0.7c-0.7-0.5-0.7-1.2-0.9-1.9c-0.4-1.7-1.1-3.3-1.6-5c-0.2-0.8-0.4-1.6-0.4-2.4c0-1.1-0.6-1.7-1.5-2.2
				c-0.9-0.4-1.7-0.9-2.6-1.4c-1.1-0.5-2.1-1.1-3.1-1.7c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3-0.2-0.7-0.5-1-0.7
				C51.5,61.1,51.1,60.7,50.7,60.6z"/>
			<path fill="#6D6E72" d="M170.2,103.4c0.5,1.4,1.4,1.1,2.4,0.6c0.5-0.2,1.3-0.7,1.3,0.3c0.1,0.7,1.3,1.4-0.3,2.2
				c-3.5,1.8-7.2,2.8-10.9,3.6c-0.5,0.1-1.1,0.4-1.4-0.2c-0.3-0.7-0.7-1.5-0.6-2.3c0-0.4,0.5-0.3,0.8-0.3c1.7-0.4,3.5-0.8,5.2-1.2
				c0.8-0.2,0.9-0.4,0.3-1.1c-1.7-1.9-2.8-4.2-4.1-6.3c-0.7-1.1-0.5-1.4,0.8-1.8c2-0.7,2-0.7,3,1.3c0.6,1.1,1.3,2.2,2,3.3
				C169.3,102,169.8,102.6,170.2,103.4z"/>
			<path fill="#6E6E72" d="M161.1,78.7c1.1,0.4,2.3,0.5,3.2,1.2c0.3,0.2,0.2,0.5,0.1,0.8c-1,3.1-1,6.2-0.5,9.3
				c0.1,0.8-0.3,1.1-0.9,1.4c-2.3,1-2.4,1-2.9-1.3c-0.5-2.2-0.6-4.4-0.2-6.6c0.2-1.4,0.3-2.8,0.9-4.2
				C160.9,79,160.8,78.7,161.1,78.7z"/>
			<path fill="#6E6E73" d="M78.7,83.7c1.2,0.7,2.1,1.7,3.4,2.2c0.4,0.1,0.5,0.4,0.4,0.8c-0.8,2.2-1.2,2.4-3.1,1.2
				c-3.6-2.3-6.9-5-10.3-7.6c-0.6-0.4-0.6-0.8-0.1-1.2c1-1,1.8-1.1,2.9-0.3c1.9,1.4,3.7,3,5.8,4.2C78.1,83.1,78.4,83.4,78.7,83.7z"
				/>
			<path fill="#6E6E73" d="M88.1,89.5c0.6,0.3,1.2,0.6,1.8,1c2.9,2.1,6.1,3.5,9.4,5c0.6,0.3,0.7,0.5,0.4,1c-1.2,2.6-1.1,2.5-3.6,1.2
				c-2.5-1.2-4.9-2.7-7.3-4.1c-0.7-0.4-1.3-0.9-1.9-1.4c-0.1-0.1-0.3-0.2-0.3-0.3C86.3,91.4,87.6,89.5,88.1,89.5z"/>
			<path fill="#6E6E73" d="M136.8,108.1c-0.1,0.6-0.3,1.2-0.4,1.7c-0.1,0.3-0.3,0.4-0.6,0.3c-4.2-0.5-8.5-1-12.5-2.2
				c-0.7-0.2-0.9-0.4-0.4-1c0.1-0.1,0.2-0.3,0.2-0.4c0.8-1.9,0.9-2,2.8-1.3c2.1,0.7,4.3,1,6.4,1.2c1.2,0.1,2.4,0.5,3.6,0.6
				C136.5,107.1,136.9,107.4,136.8,108.1z"/>
			<path fill="#6E6E73" d="M117.8,103.5c-0.3,1-0.5,1.9-0.9,2.7c-0.1,0.4-0.4,0.2-0.7,0.1c-4-1.2-7.8-2.8-11.6-4.5
				c-0.1,0-0.3-0.1-0.3-0.2c-0.2-0.5,0-1.1,0.4-1.3c0.7-0.4,0.3-2,1.7-1.4c2.4,1,4.8,2,7.1,3.1c1.1,0.5,2.3,0.9,3.5,1.2
				C117.4,103.2,117.8,103.1,117.8,103.5z"/>
			<path fill="#6E6E73" d="M194.9,81.2c0.1,1.4-0.4,2.7-0.3,4c-0.4,1.3-1,2.7-1.3,4c-0.2,0.8-0.5,0.9-1.2,0.6
				c-0.3-0.1-0.6-0.2-0.9-0.3c-0.8-0.3-1.2-0.8-0.8-1.8c0.6-1.4,0.7-2.9,0.9-4.4l0,0c0.5-1.6,0.1-3.1-0.3-4.7
				c-0.2-0.8,0.1-1.2,0.7-1.6c0.8-0.4,1.5-0.8,2.4-0.9c0.4,0.3,0.4,0.8,0.5,1.2c0,0.1,0.1,0.2,0.1,0.4
				C194.9,78.9,195.3,80.1,194.9,81.2z"/>
			<path fill="#6D6E73" d="M189.1,96.1c-0.5,0.6-1.1,1.1-1.5,1.9c0,0.1-0.1,0.2-0.2,0.1c-1.1,0.7-2.1,1.6-2.9,2.6
				c-0.1,0.4-0.4,0.5-0.7,0.5c-1.3,0.4-2.2,1.5-3.4,2.1c-0.8,0.4-1.2,0.1-1.5-0.6c-0.4-1-0.8-1.8,0.7-2.4c1-0.5,1.9-1.4,2.8-2.1
				c0.2-0.7,0.8-1,1.4-1.2c0.3-0.2,0.5-0.5,0.8-0.7c0.2-0.5,0.5-1,1.1-1c0.4-0.1,0.6-0.5,0.9-0.8c0.4-0.6,0.8-0.8,1.5-0.6
				c0,0,0,0,0,0c0.3,0.2,0.8,0.1,1,0.4C190.5,94.9,189.5,95.5,189.1,96.1L189.1,96.1z"/>
			<path fill="#6E6E73" d="M150.2,111.5c-2-0.3-4.3-0.1-6.4-0.4c-0.6-0.1-0.8-0.2-0.7-0.8c0.1-0.6,0.2-1.2,0.3-1.8
				c0.1-0.4,0.3-0.7,0.8-0.6c3.5,0.3,6.9,0.4,10.4,0.2c0.4,0,0.6,0.2,0.7,0.6c0.8,2.4,0.8,2.4-1.8,2.6
				C152.5,111.3,151.4,111.4,150.2,111.5z"/>
			<path fill="#6E6E73" d="M187,71.4c-1.6-1.8-3.6-2.9-6-3.3c-1.1-0.2-1.9-1.9-1.5-2.9c0.1-0.2,0.3-0.2,0.5-0.2
				c2.9,0,5.5,0.8,7.8,2.6c1.2,0.9,2.1,2.1,3.1,3.1c0.4,0.4,0.3,0.7-0.2,0.9c-0.3,0.1-0.6,0.2-0.8,0.3c-1,0.8-1.9,1-2.7-0.3
				C187.1,71.6,187.1,71.5,187,71.4z"/>
			<path fill="#6E6E72" d="M174.2,68.2c0.1,0.5-0.2,0.7-0.6,0.9c-2.6,1.1-4.8,2.9-6.5,5.2c-0.4,0.5-0.8,0.4-1.3,0.3
				c-2.7-0.6-2.8-0.7-1-2.8c2.3-2.7,5.2-4.6,8.5-5.8c0.5-0.2,0.7,0.1,0.7,0.5C174.1,67,174.2,67.6,174.2,68.2z"/>
			<path fill="#6E6E72" d="M176.2,109.3c2,2,4.5,3.3,6.9,4.6c1.9,1,1.7,0.9,0.8,2.8c-0.8,1.8-1.6,0.3-2.4,0.3l0,0
				c-1.8-1.2-3.7-2.3-5.5-3.5c-0.1-0.1-0.3-0.1-0.4-0.2c-0.5-0.5-1.6-0.8-1.5-1.6c0.1-1,0.9-1.7,1.7-2.4
				C175.8,109.3,176,109.3,176.2,109.3L176.2,109.3z"/>
			<path fill="#6E6E73" d="M214.8,122c0,0.6-0.4,1.1-0.3,1.6c-0.1,0.2-0.1,0.5-0.1,0.7c0.1,0.9-0.3,1-1.1,0.8c-0.3,0-0.6,0-0.9-0.1
				c-1.8-0.4-3.6,0-5.3-0.4c-0.9-0.2-1.7-0.2-2.6-0.3c-0.7-0.1-0.9-0.4-0.7-1.1c0.6-1.9,0.5-1.9,2.7-1.8c1.7,0.1,3.4,0.2,5.1,0.3
				c0.3,0.3,0.5-0.1,0.7,0C213.2,122.1,214,121.8,214.8,122z"/>
			<path fill="#6E6E73" d="M190.4,120.8c-0.1-0.1-0.3-0.2-0.4-0.3c-0.5-0.4-1.7-0.2-1.7-0.9c0-0.8,0.7-1.6,1.1-2.3
				c0.1-0.3,0.3-0.2,0.6-0.1c1.1,0.4,2.2,0.7,3.2,1.2c1.1,0.5,2.3,0.7,3.5,1.1c0.2-0.1,0.4,0,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.1
				c0.6,0.1,1.4,0,1.6,0.6c0.2,0.8-0.1,1.7-0.5,2.5c-0.3,0.5-0.6,0.2-0.9,0.1C195.2,122.3,192.8,121.5,190.4,120.8L190.4,120.8z"/>
			<path fill="#FCFCFB" d="M4.1,31.8c0,1.3,0,2.6,0,3.8c-1.5-1.5-2.5-3.4-3.8-5c-0.2-0.3-0.4-0.7-0.2-1.1c0.3-0.5,0.7-0.1,1.1,0
				c1,0.3,2,0.4,2.9,0.9C4.5,30.8,4.4,31.3,4.1,31.8z"/>
			<path fill="#F9F8F6" d="M60.3,67.6c1,3.1,1.9,6.2,2.9,9.3c0,0.9-0.3,1.3-1.2,0.7c0.4,0,0.6-0.5,0.4-0.6c-1.1-0.7-0.5-1.8-0.5-2.6
				c0-1.1-0.8-1.8-1.3-2.1c-0.6-0.4-0.8-0.8-0.6-1.3C60.3,69.9,60,68.7,60.3,67.6z"/>
			<path fill="#FCFCFB" d="M25.2,35.6c-0.4-0.2-0.8-0.2-1.3-0.1c-2.1,0.3-4.2,0.1-6.5,0.1c0.6-0.7,1-1.3,1.1-1.9
				c2.3,0.5,4.5,0.9,6.7,1.7C25.3,35.5,25.3,35.5,25.2,35.6C25.3,35.6,25.2,35.6,25.2,35.6z"/>
			<path fill="#FCFBF9" d="M4.1,35.6c0.4,0.3,0.8,0.7,1.2,1c0.4-0.1,0.2-0.6,0.6-0.8C5.9,35.9,6,36,6,36c0,0.8-0.1,1.6,1.2,1.7
				c0.7,0,0.9,0.9,0.8,1.6c-0.2,1.2,0.6,1.6,1.4,2c0.4,0.2,0.3,0.5,0.4,0.7c0.2,0.5-0.2,0.4-0.5,0.4c-0.1,0-0.1,0.2-0.2,0.4
				c-1-0.7-1.2-1.8-1.8-2.7c-0.9-1.3-2-2.6-2.9-3.8C4.3,36.1,4.2,35.9,4.1,35.6z"/>
			<path fill="#FBFAF9" d="M79,52.9c0.1-1.8,0.1-1.8,2.1-1.8c0,0,0.1,0,0.1-0.1c-0.4-1,0-1.6,1.6-2.5c0.5,0.1,1.1-0.2,1.3,0.3
				c0.2,0.6-0.4,0.8-0.7,1.1C82,51.1,80.7,52.3,79,52.9z"/>
			<path fill="#FCFBFA" d="M18.3,33.7c-0.8,0-1.7,0.1-2.4-0.4c-0.4-0.2-0.8-0.1-1.2,0c-1.5,0.4-3,0.4-4.6,0.3c0-0.6,0.5-0.1,0.6-0.5
				c-0.6-0.2-1-0.7-0.8-1.4c2.1,0.2,4.2,1,6.3,1.3C16.9,33.3,17.7,33.2,18.3,33.7z"/>
			<path fill="#FCFCFB" d="M43.2,39.5c-2.1,0-4.2,0-6.3,0c-1.1,0-2-0.8-2.1-1.9c1.9,0.3,3.8,0.7,5.7,1.3
				C41.4,39.1,42.4,38.9,43.2,39.5z"/>
			<path fill="#FCFBF9" d="M75.1,46.9c-1.5-0.4-3.1,0.8-4.6-0.1c-0.1,0-0.2,0-0.2,0c-2,0.4-2.1,0.3-2.2-1.6
				C70.6,45.6,72.9,46.1,75.1,46.9z"/>
			<path fill="#FBFBF9" d="M34.8,76.2c0.6-0.1,0.6,0.8,1.1,0.8c0.4-0.3-0.2-0.7,0.1-1c0.1,0,0.4,0.1,0.4,0.1
				c0.1,1.7,2.1,2.2,2.5,3.7c0,0.2-0.5,0.8,0.2,0.7c-0.8,0.9-1.4,0.2-1.7-0.4C36.8,78.7,35.6,77.6,34.8,76.2z"/>
			<path fill="#FDFCFA" d="M51.4,41.4c-0.6,0-1.2,0.1-1.7-0.2c-0.6-0.3-1.1,0.1-1.7,0.2c-1.1,0.2-2.1-0.3-3.2-0.1
				c-0.2,0-0.3-0.1-0.4-0.4c0-0.5,0.4-0.4,0.7-0.6c-0.5-0.5-1.5,0.2-1.7-0.9C46.1,40,48.8,40.6,51.4,41.4z"/>
			<path fill="#FCFBFA" d="M45.4,76.2c-0.3-0.9,0.6-2.2,1.7-2.1c1,0,1.3-0.4,1.3-1.2c0-1.2,0.8-0.8,1.3-0.7c0.6,0.2,0.2,0.5-0.1,0.7
				c-0.4,0.3-1.1,0.4-0.5,1.1C47.9,75,46.7,75.7,45.4,76.2z"/>
			<path fill="#FCFBFA" d="M34.8,37.6c-1.1-0.1-2.3,0.3-3.3-0.5c-0.3-0.2-0.4,0.2-0.6,0.3c-0.5-0.3-1,0-1.2-0.1
				c0.2,0,0.8-0.2,1.2,0.1c-1.1,0.4-2.2,0.5-3.3-0.2c-0.2-0.1-0.6-0.1-0.5-0.5c0.1-0.3,0.4-0.1,0.6-0.1c0.4,0,0.9,0.2,1.2-0.2
				C31.1,36.2,32.9,37.1,34.8,37.6z"/>
			<path fill="#FCFBF9" d="M27.9,67.1c0.3,0,0.4-0.2,0.6-0.4c0.4-0.4,0.6-0.3,0.6,0.2c-0.1,1,0.1,1.6,1.3,1.3
				c0.4-0.1,0.6,0.1,0.6,0.6c-0.1,0.7,0,1.3,0.1,2C29.6,69.9,28.8,68.5,27.9,67.1z"/>
			<path fill="#FCFBF9" d="M31,71.2c0.4-0.1,0.6,0.5,1.1,0.3c0.2-0.6-0.5-0.7-0.6-1.1c1.2-0.3,1.1-0.3,1.3,0.9
				c0.1,0.7,0.1,1.4-0.1,2.1C31.8,72.8,31.5,71.9,31,71.2z"/>
			<path fill="#FBFAF9" d="M4.1,31.8c0-0.5,0-1,0-1.4c1.7,0.4,3.4,0.6,5,1.2c-0.4,0-0.8,0-1.2,0l0,0c-0.6,0-1.3-1-1.9,0.1
				c-0.1,0.2-0.8,0.3-0.9-0.4C5.2,31.2,5.3,31,4.9,31c-0.4,0.1-0.4,0.5-0.6,0.8C4.3,31.8,4.2,31.8,4.1,31.8z"/>
			<path fill="#FCFBFA" d="M25.2,35.6c0-0.1,0-0.2,0-0.2c1.3,0.3,2.7,0,3.8,1C27.8,36.1,26.5,35.9,25.2,35.6z"/>
			<path fill="#FBF9F6" d="M13.5,48.1c0.8,0.2,1.8,0.4,1.2,1.7C14.1,49.3,13.6,48.9,13.5,48.1z"/>
			<path fill="#FCFBFA" d="M50.2,73.3c-0.2-0.3,0.9-0.5,0.1-0.9c-0.1,0,0.2-0.3,0.3-0.2c0.5,0.3,1-0.1,1.5,0
				C51.5,72.6,51,73.2,50.2,73.3z"/>
			<path fill="#F4EDE2" d="M71.3,58.5c0.5-0.7,1.1-1.1,1.9-1.2C72.7,57.9,72,58.2,71.3,58.5z"/>
			<path fill="#F9F8F6" d="M60.5,76.7c0.6-0.1,1,0.5,1.6,0.5c0.1,0-0.1,0.3-0.1,0.5C61.4,77.4,60.9,77.1,60.5,76.7z"/>
			<path fill="#F6F0E8" d="M52.1,72.1c0.4-0.9,0.8-0.5,1.2,0C52.9,72.1,52.5,72.1,52.1,72.1z"/>
			<path fill="#FCFBFA" d="M49,74c0.2-0.5,0.6-0.8,1.2-0.7C49.8,73.6,49.5,74,49,74z"/>
			<path fill="#FBF9F6" d="M13,47.4c0.2,0,0.4-0.2,0.5-0.1c0.3,0.3-0.1,0.6,0,0.8C13.3,47.9,13.1,47.7,13,47.4z"/>
			<path fill="#F5EEE3" d="M11.8,46.2c0.6,0,0.7,0.4,0.7,1C12.2,46.9,11.8,46.7,11.8,46.2z"/>
			<path fill="#FBF9F6" d="M14.9,50.1c0.4,0.2,0.7,0.5,0.7,1C15.2,50.8,14.9,50.5,14.9,50.1z"/>
			<path fill="#DFD2B9" d="M78.7,83.7c-0.5-0.1-0.7-0.4-1-0.7C78.3,82.9,78.5,83.2,78.7,83.7z"/>
			<path fill="#F4EDE2" d="M53.3,72.1c0.4,0,0.7,0,0.7,0.5C53.7,72.6,53.4,72.4,53.3,72.1z"/>
			<path fill="#6E6F73" d="M230.6,123.9c-1.9,0.3-3.9,0.7-5.8,0.9c-1.7,0.2-3.4,0.2-5.1,0.3c-0.5,0-0.7-0.2-0.6-0.7
				c0.1-0.5,0.1-1.1,0.1-1.7c0.2-0.4,0.3-0.7,0.8-0.7c3.3,0,6.6-0.2,9.9-0.7c0.5-0.1,0.8,0.1,0.9,0.5
				C230.9,122.6,231.7,123.4,230.6,123.9z"/>
			<path fill="#F9F9F9" d="M57.8,58.2c-1.1-0.5-2.2-0.7-3.1-1.5c1.3-0.2,2.6,0.5,3.9,0c0.1,0.5-1,0.6-0.3,1.2
				C58.1,58,58,58.1,57.8,58.2z"/>
			<path fill="#F8F8F8" d="M46.4,57.9c1.3,0.6,2.5,1.3,3.6,2.2c-0.2,0.4-0.5,0.3-0.7,0c-0.6-0.9-1.8-0.4-2.6-1.1
				C46.1,58.6,45.9,58.4,46.4,57.9z"/>
			<path fill="#FAFAFA" d="M41,50.8c-0.9-0.4-1.9-0.6-2.6-1.3c0.4-0.2,0.9-0.9,1.3,0c0.1,0.1,0.2,0.2,0.3,0c0.4-0.9,0.8-0.5,1.2,0
				c0.1,0.1,0.4,0,0.6,0.1C41,49.8,40.3,49.8,41,50.8z"/>
			<path fill="#FAF9F9" d="M29.1,47.2c1,0.4,1.8,1,2.6,1.7c-0.3,0.3-0.4,1.1-0.9,0.1c-0.2-0.4-0.7,0.4-1.2-0.1
				C29.1,48.4,29.2,47.8,29.1,47.2z"/>
			<path fill="#F6F5F5" d="M41.1,54.6c0.8,0.5,1.7,0.7,2.3,1.5c-0.8,0.8-1.6-0.1-1.8-0.4c-0.4-0.8-0.6-0.3-0.9,0
				C40.5,55.1,41,54.9,41.1,54.6z"/>
			<path fill="#F7F7F6" d="M48.7,54.2c-1-0.2-1.9-0.5-2.7-1.2c0.7-0.1,1.4,0.4,2.3,0.1C48.7,52.9,48.9,53.6,48.7,54.2z"/>
			<path fill="#FAFAFA" d="M44.4,52.2c-1-0.1-1.9-0.6-2.7-1.2c1.1-0.4,2,0.4,2.9,0.7C44.8,51.9,44.4,52,44.4,52.2z"/>
			<path fill="#F9F9F9" d="M53.1,62c1.1,0.5,2.2,1.1,3.2,1.9c-1.3,0-2.2-1.1-3.4-1.4C52.7,62.3,52.9,62.2,53.1,62z"/>
			<path fill="#FAF9F9" d="M31.9,49.1c1,0.1,1.7,0.9,2.5,1.4c-0.5,0.4-1.1,0.3-1.4-0.3C32.7,49.8,32.3,49.5,31.9,49.1z"/>
			<path fill="#F9F9F8" d="M31.5,46.5c-0.8-0.1-1.4-0.4-2-1c0.3-0.3,0.7-0.1,1-0.1c0.4,0,1.4-0.4,0.7,0.7
				C31.2,46.2,31.4,46.4,31.5,46.5z"/>
			<path fill="#F7F7F7" d="M63.4,60.9c-0.5-0.4-1.4-0.2-1.7-0.9C62.9,59.4,63.3,59.6,63.4,60.9z"/>
			<path fill="#F6F5F5" d="M28.3,45.1c-0.8-0.2-1.6-0.5-2.2-1C27.1,43.8,27.8,44.3,28.3,45.1z"/>
			<path fill="#F9F9F8" d="M32.9,47.2c-0.6-0.1-1-0.3-1.5-0.7C32.2,46.2,32.9,45.9,32.9,47.2z"/>
			<path fill="#F8F7F7" d="M26.9,46c0.5,0.1,1,0.3,1.4,0.7C27.8,47.1,27.2,46.8,26.9,46z"/>
			<path fill="#F7F7F6" d="M49.7,54.7c-0.3-0.1-0.8-0.1-0.9-0.5c0.1,0,0.2,0,0.2-0.1c0.3-0.2,0.5-0.6,1-0.4
				C49.5,53.9,49.9,54.4,49.7,54.7z"/>
			<path fill="#F7F7F7" d="M61.7,59.9c-0.3,0-0.5,0-0.7-0.3c0.5-0.4,1.1-0.8,1.9-0.6C62.5,59.5,61.7,59.2,61.7,59.9z"/>
			<path fill="#F9F9F9" d="M61,59.7c-0.4,0-0.7-0.2-1-0.5c0.3-0.1,0.4-0.5,0.8-0.4C61.2,59,60.9,59.4,61,59.7z"/>
			<path fill="#F6F5F5" d="M25.9,44c-0.6-0.1-1.1-0.4-1.7-0.2C24.9,43.1,25.5,43.3,25.9,44z"/>
			<path fill="#FCFCFB" d="M4.3,31.8c0-0.9,0.5-1.1,1.2-0.9c0,0.3-0.3,0.3-0.5,0.4C4.5,31.1,4.7,31.8,4.3,31.8z"/>
			<path fill="#F8F7F7" d="M24.3,43.8c0,0.6,0.7,0.5,0.9,1C24,44.7,24,44.6,24.3,43.8z"/>
			<path fill="#F9F9F9" d="M59.8,59.2c-0.4,0-0.7-0.2-1-0.5C59.5,58,59.6,58.8,59.8,59.2z"/>
			<path fill="#F0F0F0" d="M50.7,60.6c0.6-0.1,0.9,0.2,1.2,0.7C51.4,61.2,50.9,61.2,50.7,60.6z"/>
			<path fill="#F5F4F3" d="M53.1,62c-0.1,0.2-0.2,0.3-0.2,0.5c-0.2-0.2-0.6,0-0.8-0.3c0.3-0.3,0.6-0.1,0.9-0.2
				C52.9,62,53,62,53.1,62z"/>
			<path fill="#FAF9F9" d="M31.8,50.4c0.4-0.1,0.8-0.1,0.8,0.2c0,0.5-0.3,0.1-0.4,0C32.1,50.6,32,50.5,31.8,50.4z"/>
			<path fill="#F8F8F8" d="M49.3,60.1c-0.4,0.3-0.6,0.5-1.1,0.3C48.6,60,48.9,60.2,49.3,60.1z"/>
			<path fill="#F9F9F9" d="M58.8,58.7c-0.4-0.1-0.7-0.1-1-0.5C58.3,58.2,58.6,58.2,58.8,58.7z"/>
			<path fill="#F8F7F7" d="M26.4,46.7c-0.1,0.1-0.2,0.2-0.3,0.1c-0.1,0-0.1-0.1-0.2-0.2c0.1,0,0.2-0.1,0.2-0.1
				C26.2,46.5,26.3,46.6,26.4,46.7z"/>
			<path fill="#F8F7F7" d="M25.7,45.3c0.4,0,0.7,0.1,0.9,0.5C26.2,45.7,25.9,45.7,25.7,45.3z"/>
			<path fill="#FBFAF9" d="M7.9,31.6c-0.3,0.3-0.6,0.3-1,0.1C7.3,31.4,7.6,31.6,7.9,31.6L7.9,31.6z"/>
			<path fill="#FEFEFE" d="M35,38.1c0.6-0.4,1-0.1,1.4,0.1C35.9,38.3,35.5,38.3,35,38.1z"/>
			<path fill="#FEFEFE" d="M31,37.3c-0.5,0-1.1,0-1.6,0C29.9,37.2,30.5,36.9,31,37.3L31,37.3z"/>
		</g>
	</g>
</g>
<g id="arm_and_plane">
	<g id="plane">
		<g id="_x37_aV8Vf_2_">
			<g>
				<path fill="#FEFEFE" d="M222.3,99.9c-1.6-1.1-3.2-2.1-4.8-3.2c-2.3-1.5-4.5-3.1-6.7-4.6c-1.7-1.2-3.4-2.4-5.1-3.6
					c-0.3-0.3-0.7-0.5-1-0.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.8-1-0.9-1.7-1.2l0,0c-0.2-0.2-0.3-0.6-0.7-0.5c-0.2,0-0.3-0.1-0.5-0.1
					c-0.2-0.4-0.6-0.6-1-0.7c-0.7-0.9-1.8-1.3-2.7-1.9c-0.2-0.1-0.4-0.1-0.5,0.1c-0.2,0.2-0.5,0.4-0.7,0c0-0.1,0.2-0.3,0.4-0.5
					c0-0.8-0.5-1.2-1.2-1.4c-0.8-0.2-1.1-0.8-1.3-1.5c-0.2-0.7-0.7-1.2-1.5-1c-0.2,0-0.3,0-0.5,0c-0.5,0-0.3-0.9-1.1-0.6
					c-0.4,0.2-1-0.4-1.4-0.7l0,0c-0.8-0.9-1.1-2.1-1.3-3.3c0-0.1,0-0.3,0-0.4c0.2-0.4,0.2-1,0.9-0.9c0.3,0.1,0.4,0.2,0.7-0.1
					c0.4-0.5,1.4-0.6,2-0.3c-0.2,0.3-0.5,0.2-0.7,0.3c0.3-0.1,0.6-0.2,0.8-0.5c0.3-0.1,0.7-0.3,1-0.2c0.3-0.1,0.5-0.2,0.8,0
					c0.4,0.3,0.8,0.6,1.3,0.9c0.2,0.1,0.2,0.5,0.6,0.3c0.9-0.4,1.8-0.8,2.7-1.2c1.2-0.6,2.6-0.4,3.9-0.7c0.1,0,0.1,0,0.2-0.1
					c0.1,0.1,0.3,0.2,0.3,0.4c-0.1,1.4,0.6,1,1.4,0.8c1.3-0.5,2.6-0.8,3.9-1.3c0.4-0.2,0.9-0.3,1.4-0.2c1.3-0.1,2.6-0.6,3.9-0.4l0,0
					c0.1,0.7-0.5,0.6-1,0.9c1.1,0.1,2-0.2,2.8-0.7c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.3,0.7-0.2c1.1,0.2,2.1-0.2,3.1-0.4l0,0
					c1,0.9,2.1,1,3.4,0.5c1.7-0.6,3.4-1.2,5.2-1.3c0.1,0,0.1-0.1,0.2-0.1c0.4,0.2,0.9,0,1.3,0.1c0.3,0,1-0.4,0.7,0.6
					c-0.1,0.3,0.3,0.1,0.4,0.1c1-0.2,1.9-0.4,2.8-1c0.8-0.5,1.9-0.4,2.9-0.4c1.1-0.2,2.1-0.4,3.2-0.5c3.5-0.1,6.9-0.8,10.3-0.8
					c1.2,0,2.4-0.6,3.7-0.3c0.3,0.1,0.4,0.4,0.6,0.6c0.4,0.6,0.9,0.7,1.4,0.3c0.5-0.4,1.1-0.5,1.8-0.6c1.2-0.1,2.1-1.2,3.4-1
					c2.6-0.6,5.2-0.9,7.8-0.8c0.2,0.4-0.2,0.6-0.3,0.8c-0.3,0.4-0.6,0.9-0.4,1.3c0.5,0.9-0.1,1.2-0.8,1.4c-0.7,0.2-1,0.5-0.7,1.3
					c0.1,0.2-0.1,0.4-0.1,0.5c-0.1,1-1.1,1.4-1.5,2.2c-0.8,1.3-1.7,2.5-2.6,3.7c-0.5,0.5-1.1,1.1-1.4,1.7c-0.1,0.1-0.1,0.2-0.2,0.3
					c-0.2,0.2-0.4,0.4-0.5,0.7l0,0c-0.3,0.6-0.8,0.4-1.4,0c0.2,0.6,1,0.3,0.7,0.9l0,0c-0.3,0.3-0.7,0.5-0.7,1
					c-0.1,0-0.1,0.1-0.2,0.1c-0.7-0.1-0.2,0.4-0.3,0.6c-0.1,0.1-0.2,0.3-0.4,0.4c-0.9-0.3-1.6,0.1-2.4,0.3c-0.4,0.1-0.8,0.4-1.2,0.5
					c-0.1,0-0.2,0-0.3-0.1c-0.4-0.7-1-0.6-1.6-0.3c-0.1,0-0.2,0-0.3-0.1c-0.2-0.5,0.2-0.5,0.4-0.8c-0.3,0.2-0.6,0.5-0.7,0.8
					c0,0-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.3-0.1c-0.3-0.4-0.6-0.4-0.9,0c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.5-0.8-0.2
					c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1,0-0.2-0.1-0.2-0.2c-0.4-0.6-0.4-0.6-1.4-0.5c-0.7,0.1-1.3,0.5-2,0.5
					c-1.8-0.1-3.7,0-5.4-0.6c-0.1-0.1-0.1-0.1-0.1-0.2c-0.2-0.3-0.3-0.9-0.7-0.1c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.2-0.6-0.8-0.4
					c-0.6,0.2-1.4,0.3-2,0.4c-0.6,0.1-1.3,0.1-1.8-0.3c-0.3-0.9-1-0.4-1.6-0.5c-0.4-0.1-0.9,0.1-1.2,0.3c-0.3,0.1-0.6,0.1-0.8,0
					c-0.8-0.7-0.8-1,0-1.4c-1,0.2-2,0.6-2.9,1c-2,0.2-4-0.1-5.9-0.6c-0.4-0.3-0.9-0.8-1.5-0.3c-0.1,0-0.2,0-0.3,0
					c-0.6-1-1.3-0.5-2-0.2c-0.4,0.2-0.9,0.1-1.3-0.1c-0.7-0.5-1.4-0.6-2.2-0.3c-0.1,0-0.2,0-0.3,0c-0.5-0.1-0.9-0.3-1.3,0.1
					c0,0.1-0.1,0.2-0.1,0.3c0.2,0.4,0.6,0.2,0.9,0.3c0.3,0,0.5,0.2,0.7,0.3c0.3,0.2,0.7,0.2,1,0.2c0.2,0,0.3,0.1,0.4,0.2
					c0.3,0.3,0.7,0.2,0.9,0.4c0.4,0.4,0.2-0.4,0.5-0.2c0.4-0.1,0.7,0,1,0.2c0.9,1.4,0.9,1.4,2.8,0.9c0.2,0,0.3,0,0.5,0
					c0.7,0.1,1.2,0.8,1.9,0.9c0.4,0,0.5-0.2,0.7-0.3c0.3-0.1,0.6-0.1,0.9,0c2.1,0.5,4.2,1,6.3,1.6c0.2,0.1,0.4,0.1,0.5,0.3
					c0.3,0-0.4,0.8,0.4,0.5c0.3-0.1,0.8,0.3,1.3,0.4c0.5,0.1,0.7-0.1,1-0.2c1.2,0.2,2.4,0,3.4,0.9c0.1,1.2,1.1,0.8,1.7,0.9
					c0.6,0.1,1.2-0.3,1.6,0.3c0.1,0.1-0.2,0.1-0.1,0.2c0-0.1,0-0.1,0.1-0.2c0.3,0.2,0.4-0.1,0.6-0.2c0.3-0.1,0.6,0,0.9,0.2
					c0.4,0.2,0.9,0.1,1.4,0.2c0.4,0.2,0.8,0.1,1.1,0.5c0,0.1-0.2,0.2-0.1,0.3c0,0,0.2,0,0.3-0.1c1.2,0,2.4,0.7,3.6,0.3
					c1.3,0.1,2.6,0.5,3.8,0.8c0.7,0.2,1.1,0.8,1.3,1.5c0.2,1.1,0.8,2.1,0.8,3.2c0,0.5,0.3,0.9,0.8,1c1.3,0.4,1.9,1.3,2.4,2.5
					c0.2,0.6,0.2,1.3,1,1.6c0.3,0.1,0.4,0.8-0.4,0.8l0,0c-0.5-0.4-1.1-0.4-1.7-0.5c-2.5-0.4-4.9-1.3-7.4-1.9
					c-0.3-0.2-0.6-0.1-0.8-0.2l0,0c-0.5-0.1-0.9-0.1-1.1,0.4l0,0c-0.7,0.4-1,1.1-1.4,1.7l0,0c-0.4,0.3-0.7,0.7-0.9,1.1l0,0
					c-0.6,0.1-0.5-0.3-0.5-0.6c0-0.3,0.3-0.5,0.3-0.9c-0.7,0.2-0.5,0.7-0.4,1c0.1,0.6,0,1-0.6,1.2c-1.1,0.5-1.5,1.3-1.5,2.5
					c-1.5,2-3.1,4-4.6,6c-0.4,0.4-0.5,0-0.7-0.2c-0.6-0.7-1.1-1.5-2.2-1.5c-0.6,0-0.6-0.7-0.9-1.2c-0.1,0.7-0.2,1.2-0.9,0.5
					c-0.2-0.2-0.5-0.3-0.7-0.3c-1.1-0.5-2-1.3-3-2c-0.6-0.7-0.5-1.8-1.4-2.8c0.1,0.6,0.8,1,0.3,1.4c-0.4,0.4-0.8-0.1-1.2-0.2
					c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.2-0.4-0.4-0.6-0.7c-0.3-0.8-0.5-1.5-1.6-1c-0.3,0.1-0.7-0.1-0.8-0.4
					C223.3,99.7,222.8,99.8,222.3,99.9z"/>
				<path fill="#98989C" d="M241.9,86.7c-0.3-0.1-0.6-0.2-0.9-0.2c-1.3-0.4-2.8-0.2-4.1-1c-1-0.7-2.3-0.3-3.4-0.8
					c-1,0-1.8-0.2-2.7-0.7c-2.5-0.6-5-1.3-7.6-1.8c-1,0.2-1.8-0.5-2.8-0.6c-0.1,0-0.2-0.1-0.3-0.1c-1.1,0.1-2.1-0.2-3-0.8
					c-0.3-0.1-0.6-0.1-0.9-0.2c-0.5-0.1-1.1,0-1.6-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.7,0-1-0.2c-0.2-0.1-0.4-0.2-0.7-0.3
					c-0.4-0.2-1.2,0.3-1.1-0.6l0,0c0.5-0.5,1-0.5,1.6-0.3c0.1,0,0.1,0,0.2,0c0.8-0.1,1.6,0.1,2.4,0.2c0.4,0.2,0.8,0.2,1.2,0.1
					c0.8-0.1,1.4,0.2,2.2,0.3l0,0c0.5,0,1.1-0.1,1.6,0.2c2,0.1,4,0.6,6,0.6c1-0.2,2,0,2.9,0.4c0.2,0.2,0.5,0.1,0.7,0
					c1,0.1,2-0.1,2.9,0.3c0.6,0.3,1.2,0.3,1.8,0.2c1,0.3,2-0.2,2.9,0.3l0,0c0.3,0.1,0.8-0.2,1.1,0.2c1.8,0.2,3.6,0.5,5.4,0.5
					c1.1,0.2,2.4-0.3,3.4,0.4l0,0c0.4,0,0.7,0,1.1,0.1l0,0c0.4,0,0.7,0,1.1,0.1c0.1,0,0.1,0,0.2,0c0.4-0.1,0.8-0.2,1.1,0.1l0,0
					c0.2,0.2,0.5-0.2,0.7,0l0,0c0.7,0,1.4-0.2,1.9,0.3l0,0c1,0,2-0.3,2.9,0.3c0.8,2.2,0.8,4.6,1.3,6.9c0.5,2.8,0.9,5.6,1.3,8.6
					c-0.3-0.2-0.5-0.4-0.7-0.5c-0.8-0.2-1.1-0.9-1.4-1.5c-0.9-1.5-2-2.8-3.1-4.3c-0.5-0.7-0.9-1.4-1.2-2.2c-0.3-1-1.1-1.5-2.1-1.6
					c-1-0.1-1.9-0.4-2.9-0.6c-1.2-0.2-2.3-0.4-3.5-0.6c-0.1,0-0.2-0.1-0.3-0.2c-0.4-0.1-0.8-0.2-1.2-0.4
					C242.9,86.8,242.4,86.6,241.9,86.7z"/>
				<path fill="#FCFCFB" d="M188.7,73.8c0.4,1.2,0.8,2.4,1.2,3.6c-1.9-1-3.4-2.4-5.2-3.6c-0.3-0.2-0.6-0.6-0.5-1
					c0.1-0.6,0.6-0.4,1-0.4c1,0,2-0.3,3.1-0.1C188.8,72.8,188.9,73.3,188.7,73.8z"/>
				<path fill="#F9F8F6" d="M253.3,90.3c1.9,2.7,3.8,5.3,5.6,8c0.3,0.9,0.1,1.3-0.9,1.1c0.4-0.2,0.4-0.7,0.2-0.7
					c-1.3-0.3-1.1-1.6-1.3-2.3c-0.4-1.1-1.3-1.4-1.9-1.6c-0.7-0.2-1-0.5-1-1C254,92.5,253.3,91.4,253.3,90.3z"/>
				<path fill="#FCFCFB" d="M210,70.9c-0.5-0.1-0.8,0.1-1.2,0.3c-1.9,0.9-3.9,1.4-6.2,2.2c0.4-0.9,0.6-1.5,0.4-2.2
					c2.3-0.3,4.6-0.6,6.9-0.5C210,70.7,210,70.7,210,70.9C210,70.8,210,70.9,210,70.9z"/>
				<path fill="#FCFBF9" d="M189.9,77.5c0.5,0.2,1,0.4,1.5,0.6c0.4-0.2,0-0.7,0.3-0.9c0,0,0.2,0,0.2,0.1c0.3,0.8,0.4,1.6,1.6,1.2
					c0.7-0.2,1.1,0.6,1.2,1.3c0.2,1.2,1.1,1.4,2,1.4c0.4,0,0.5,0.4,0.6,0.6c0.3,0.4-0.1,0.4-0.3,0.5c-0.1,0-0.1,0.3-0.1,0.4
					c-1.1-0.3-1.7-1.4-2.6-2c-1.3-1-2.7-1.8-4-2.7C190.2,77.9,190.1,77.6,189.9,77.5z"/>
				<path fill="#FBFAF9" d="M266.5,70.5c-0.5-1.8-0.5-1.8,1.5-2.4c0,0,0-0.1,0.1-0.1c-0.7-0.8-0.5-1.5,0.7-2.9
					c0.4-0.1,1-0.5,1.3-0.1c0.4,0.5-0.1,0.9-0.3,1.3C268.7,67.8,267.8,69.4,266.5,70.5z"/>
				<path fill="#FCFBFA" d="M202.8,71.2c-0.8,0.2-1.6,0.6-2.4,0.4c-0.4-0.1-0.8,0.1-1.1,0.4c-1.3,0.9-2.8,1.4-4.2,1.7
					c-0.2-0.6,0.4-0.2,0.4-0.6c-0.7,0-1.1-0.3-1.2-1.1c2.1-0.5,4.3-0.4,6.4-0.7C201.3,71.2,202,70.9,202.8,71.2z"/>
				<path fill="#FCFCFB" d="M228.3,68.9c-2,0.7-4,1.3-6,2c-1.1,0.3-2.1-0.1-2.6-1.2c1.9-0.3,3.9-0.6,5.8-0.6
					C226.5,69.1,227.3,68.6,228.3,68.9z"/>
				<path fill="#FCFBF9" d="M260.9,66c-1.6,0.1-2.7,1.7-4.4,1.3c-0.1,0-0.1,0-0.2,0.1c-1.7,1-1.9,0.9-2.6-0.9
					C256.2,66.2,258.5,66,260.9,66z"/>
				<path fill="#FBFBF9" d="M231.8,106.4c0.6-0.3,0.8,0.5,1.3,0.4c0.3-0.5-0.4-0.6-0.3-1c0.1,0,0.4-0.1,0.4,0
					c0.6,1.6,2.7,1.4,3.5,2.8c0.1,0.2-0.2,0.9,0.5,0.6c-0.5,1.1-1.3,0.6-1.7,0.1C234.4,108.2,232.9,107.5,231.8,106.4z"/>
				<path fill="#FDFCFA" d="M236.6,68.2c-0.6,0.2-1.1,0.4-1.7,0.3c-0.7-0.1-1,0.5-1.5,0.7c-0.9,0.5-2.1,0.4-3,0.9
					c-0.2,0.1-0.4,0-0.5-0.2c-0.2-0.5,0.3-0.5,0.5-0.8c-0.6-0.4-1.4,0.7-1.9-0.3C231.2,68.5,233.9,68.2,236.6,68.2z"/>
				<path fill="#FCFBFA" d="M241.8,103.1c-0.6-0.8-0.1-2.3,0.9-2.6c0.9-0.3,1.1-0.8,0.8-1.5c-0.4-1.1,0.5-1,1-1
					c0.6,0,0.3,0.4,0.1,0.7c-0.3,0.4-0.9,0.7-0.1,1.2C243.8,101.1,242.9,102.2,241.8,103.1z"/>
				<path fill="#FCFBFA" d="M219.7,69.7c-1.1,0.2-2.1,1-3.3,0.6c-0.3-0.1-0.3,0.3-0.4,0.4c-0.5-0.1-0.9,0.3-1.2,0.3
					c0.3,0,0.7-0.4,1.2-0.3c-0.9,0.7-2,1.1-3.2,0.9c-0.3-0.1-0.6,0.1-0.6-0.4c0-0.3,0.4-0.2,0.6-0.2c0.4-0.1,0.9-0.1,1.1-0.6
					C215.7,69.6,217.8,69.9,219.7,69.7z"/>
				<path fill="#FCFBF9" d="M222.3,99.9c0.2-0.1,0.3-0.3,0.4-0.5c0.2-0.5,0.5-0.5,0.7,0c0.2,0.9,0.6,1.5,1.7,0.9
					c0.4-0.2,0.6-0.1,0.7,0.4c0.2,0.6,0.4,1.3,0.7,1.9C224.9,102.1,223.6,101,222.3,99.9z"/>
				<path fill="#FCFBF9" d="M226.5,102.8c0.4-0.2,0.7,0.3,1.1,0c0-0.6-0.7-0.6-1-0.9c1-0.6,1-0.6,1.6,0.4c0.3,0.6,0.6,1.3,0.6,2
					C227.9,104.2,227.3,103.4,226.5,102.8z"/>
				<path fill="#FBFAF9" d="M188.7,73.8c-0.1-0.5-0.3-0.9-0.4-1.4c1.7-0.2,3.4-0.5,5.2-0.4c-0.4,0.1-0.8,0.3-1.1,0.4l0,0
					c-0.6,0.2-1.6-0.6-1.8,0.7c0,0.3-0.7,0.5-1-0.1c0-0.2,0.1-0.4-0.2-0.2c-0.4,0.2-0.2,0.6-0.3,0.9
					C188.9,73.8,188.8,73.8,188.7,73.8z"/>
				<path fill="#FCFBFA" d="M210,70.9c0-0.1,0-0.2-0.1-0.2c1.3-0.1,2.6-0.8,4-0.3C212.6,70.5,211.3,70.7,210,70.9z"/>
				<path fill="#FBF9F6" d="M202.7,86.4c0.9,0,1.8-0.1,1.7,1.2C203.7,87.3,203,87.1,202.7,86.4z"/>
				<path fill="#FCFBFA" d="M245.5,98.9c-0.3-0.3,0.7-0.8-0.2-0.9c-0.1,0,0.1-0.3,0.2-0.3c0.6,0.1,0.9-0.4,1.4-0.5
					C246.5,97.8,246.2,98.5,245.5,98.9z"/>
				<path fill="#F4EDE2" d="M260.9,78.2c0.2-0.8,0.7-1.4,1.4-1.7C262.1,77.2,261.5,77.7,260.9,78.2z"/>
				<path fill="#F9F8F6" d="M256.3,98.9c0.5-0.3,1.1,0.2,1.6-0.1c0.1,0,0,0.3,0.1,0.5C257.4,99.3,256.8,99.1,256.3,98.9z"/>
				<path fill="#F6F0E8" d="M246.9,97.1c0.1-1,0.6-0.7,1.1-0.4C247.7,96.9,247.3,97,246.9,97.1z"/>
				<path fill="#FCFBFA" d="M244.5,100c0.1-0.5,0.3-0.9,0.9-1.1C245.2,99.3,245,99.7,244.5,100z"/>
				<path fill="#FBF9F6" d="M202,85.9c0.2-0.1,0.4-0.3,0.5-0.3c0.4,0.2,0.1,0.6,0.2,0.8C202.5,86.2,202.3,86.1,202,85.9z"/>
				<path fill="#F5EEE3" d="M200.5,85.1c0.6-0.1,0.8,0.2,1,0.7C201.2,85.6,200.7,85.5,200.5,85.1z"/>
				<path fill="#FBF9F6" d="M204.7,87.8c0.4,0.1,0.8,0.2,1,0.7C205.2,88.4,204.9,88.2,204.7,87.8z"/>
				<path fill="#F4EDE2" d="M248,96.8c0.3-0.1,0.7-0.2,0.8,0.2C248.6,97.1,248.3,97,248,96.8z"/>
				<path fill="#F9F9F9" d="M248,82.2c-1.1-0.1-2.3,0.1-3.4-0.4c1.2-0.6,2.6-0.3,3.7-1.2c0.3,0.4-0.8,0.9,0.1,1.2
					C248.2,81.9,248.1,82,248,82.2z"/>
				<path fill="#F8F8F8" d="M237,85.4c1.4,0.2,2.8,0.4,4.1,1c-0.1,0.4-0.4,0.4-0.7,0.2c-0.9-0.6-1.9,0.2-2.9-0.2
					C237,86.1,236.8,86,237,85.4z"/>
				<path fill="#FAFAFA" d="M229.8,80.4c-1-0.1-2,0-2.9-0.4c0.4-0.3,0.5-1.1,1.3-0.5c0.1,0.1,0.2,0.1,0.3-0.1c0.1-1,0.6-0.7,1.1-0.4
					c0.1,0.1,0.4-0.1,0.6-0.1C229.4,79.3,228.8,79.7,229.8,80.4z"/>
				<path fill="#FAF9F9" d="M217.2,80.6c1,0.1,2,0.4,3,0.8c-0.2,0.4-0.1,1.2-0.9,0.4c-0.3-0.3-0.5,0.6-1.1,0.3
					C217.6,81.7,217.5,81.2,217.2,80.6z"/>
				<path fill="#F6F5F5" d="M231,83.9c0.9,0.2,1.9,0.2,2.7,0.7c-0.5,1-1.5,0.4-1.9,0.1c-0.7-0.6-0.7-0.1-0.9,0.3
					C230.5,84.6,231,84.3,231,83.9z"/>
				<path fill="#F7F7F6" d="M238.1,81.1c-1,0.1-2,0.2-2.9-0.3c0.7-0.3,1.5-0.1,2.2-0.6C237.7,80,238.1,80.6,238.1,81.1z"/>
				<path fill="#FAFAFA" d="M233.4,80.6c-1,0.2-2,0.1-2.9-0.3c1-0.7,2-0.2,3-0.2C233.7,80.2,233.4,80.4,233.4,80.6z"/>
				<path fill="#F9F9F9" d="M244.7,87.3c1.2,0.1,2.4,0.4,3.6,0.8c-1.3,0.4-2.4-0.3-3.7-0.3C244.4,87.6,244.6,87.4,244.7,87.3z"/>
				<path fill="#FAF9F9" d="M220.6,81.5c1-0.2,1.9,0.3,2.8,0.6c-0.4,0.6-0.9,0.6-1.4,0.2C221.5,82,221,81.8,220.6,81.5z"/>
				<path fill="#F9F9F8" d="M219.3,79.2c-0.8,0.1-1.5,0.1-2.2-0.3c0.2-0.3,0.6-0.3,0.9-0.4c0.4-0.1,1.2-0.8,0.9,0.5
					C218.9,79,219.2,79.1,219.3,79.2z"/>
				<path fill="#F7F7F7" d="M254.1,82.9c-0.6-0.2-1.4,0.2-1.9-0.3C253.2,81.7,253.6,81.8,254.1,82.9z"/>
				<path fill="#F6F5F5" d="M215.9,78.9c-0.8,0.1-1.6,0-2.4-0.2C214.3,78.1,215.1,78.3,215.9,78.9z"/>
				<path fill="#F9F9F8" d="M220.9,79.4c-0.6,0.1-1.1,0-1.6-0.2C219.9,78.8,220.5,78.2,220.9,79.4z"/>
				<path fill="#F8F7F7" d="M214.8,80.2c0.5-0.1,1.1,0,1.6,0.3C216,81,215.3,80.9,214.8,80.2z"/>
				<path fill="#F7F7F6" d="M239.1,81.3c-0.4,0-0.8,0.1-1.1-0.2c0.1,0,0.1-0.1,0.2-0.1c0.3-0.3,0.3-0.7,0.8-0.7
					C238.8,80.7,239.3,81,239.1,81.3z"/>
				<path fill="#F7F7F7" d="M252.2,82.6c-0.3,0.1-0.5,0.1-0.7,0c0.4-0.5,0.8-1.1,1.7-1.2C252.9,81.9,252,81.9,252.2,82.6z"/>
				<path fill="#F9F9F9" d="M251.4,82.5c-0.4,0.1-0.7,0-1.1-0.1c0.2-0.2,0.2-0.6,0.6-0.6C251.4,81.8,251.3,82.3,251.4,82.5z"/>
				<path fill="#F6F5F5" d="M213.3,78.6c-0.5,0.1-1.1,0-1.6,0.3C212,78.1,212.6,78.1,213.3,78.6z"/>
				<path fill="#FCFCFB" d="M189,73.8c-0.3-0.8,0.1-1.1,0.9-1.2c0.1,0.3-0.2,0.4-0.3,0.6C188.9,73,189.3,73.6,189,73.8z"/>
				<path fill="#F8F7F7" d="M211.7,78.9c0.2,0.6,0.8,0.3,1.1,0.6C211.6,79.8,211.6,79.8,211.7,78.9z"/>
				<path fill="#F9F9F9" d="M250.2,82.4c-0.4,0.1-0.7,0-1.1-0.1C249.6,81.4,249.8,82.1,250.2,82.4z"/>
				<path fill="#F0F0F0" d="M241.9,86.7c0.5-0.3,1-0.1,1.4,0.3C242.8,87,242.3,87.1,241.9,86.7z"/>
				<path fill="#F5F4F3" d="M244.7,87.3c0,0.2-0.1,0.4-0.1,0.5c-0.3-0.1-0.6,0.2-0.9-0.1c0.2-0.3,0.5-0.3,0.8-0.4
					C244.6,87.3,244.6,87.3,244.7,87.3z"/>
				<path fill="#FAF9F9" d="M220.8,82.8c0.4-0.2,0.7-0.4,0.8,0c0.1,0.5-0.2,0.2-0.4,0.2C221.2,82.9,221.1,82.9,220.8,82.8z"/>
				<path fill="#F8F8F8" d="M240.5,86.6c-0.3,0.4-0.4,0.7-0.9,0.6C239.8,86.8,240.2,86.8,240.5,86.6z"/>
				<path fill="#F9F9F9" d="M249.1,82.3c-0.4,0.1-0.7,0.1-1.1-0.1C248.4,82,248.8,81.9,249.1,82.3z"/>
				<path fill="#F8F7F7" d="M214.6,81c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.2
					C214.3,80.9,214.4,81,214.6,81z"/>
				<path fill="#F8F7F7" d="M213.4,79.9c0.4-0.1,0.7-0.1,1,0.2C214.1,80.2,213.7,80.2,213.4,79.9z"/>
				<path fill="#FBFAF9" d="M192.3,72.4c-0.2,0.4-0.5,0.5-0.9,0.4C191.6,72.5,192,72.5,192.3,72.4L192.3,72.4z"/>
				<path fill="#FEFEFE" d="M220,70.2c0.5-0.6,0.9-0.4,1.4-0.3C221,70.1,220.6,70.2,220,70.2z"/>
				<path fill="#FEFEFE" d="M216,70.7c-0.5,0.2-1,0.3-1.5,0.5C214.9,70.9,215.3,70.4,216,70.7L216,70.7z"/>
			</g>
		</g>
	</g>
	<g id="arm">
		<g id="w83l6p_2_">
			<g>
				<path fill="#FBF9F1" d="M273.7,204.3c-0.1,0-0.1,0.1-0.2,0.1c0.3,0.5,0.6,1,0.8,1.5l0.1-0.1l0.1-0.1
					C274.6,205.1,274.3,204.6,273.7,204.3z"/>
				<path fill="#E3C77A" d="M273.7,204.3c0.3,0.5,0.6,1,0.8,1.5c1.5,2,2.9,4.1,4.8,5.8c2.3,2.2,4.8,4.3,7.9,5.2
					c3.8,1.1,7.5,0.6,11.2-0.7c4-1.4,6.8-4.3,9.5-7.3c4.1-4.7,7.3-10.1,10.7-15.3c4.4-6.7,8.3-13.7,12.1-20.7
					c2.1-3.8,4.3-7.6,6.9-11.2c0.8-1.1,1.4-2.3,1.7-3.6c0.2-0.8,0.6-1.5,0.8-2.2c1-3.2,2.5-6.2,2.6-9.6c0-1.5,0.7-3,0.8-4.5
					c0.2-2.6,0.8-5.4,0.2-8c-0.6-2.9-0.7-5.9-2.3-8.6c-0.7-1.2-0.7-2.9-1.6-4.1c-3.3-4.3-8.3-7.2-13.9-5.4c-2.4,0.8-4.2,2.2-5.6,4.5
					c-2.9,5.1-5.6,10.3-7.6,15.7c-1.3,3.6-2.6,7.3-3.9,10.9c-1.8,5.1-3.6,10.2-5.5,15.3c-2.7,7.1-5,14.3-8.4,21.1
					c-0.2,0.4-0.4,0.8-0.6,1.1c-1.3,1.9-3.4,1.9-4.7,0c-2.6-3.8-4.8-7.9-7.2-11.9c-2.2-3.6-4.1-7.4-6.5-10.9c-1-1.4-1.5-3.2-2.6-4.5
					c-1.1,0.4-2.2,1-3.2,1.6c-0.3,0.2-0.5,0.5-0.5,0.9c1.6,2.5,2.9,5.2,4.7,7.6c0.9,1.2,1.5,2.6,2.3,3.9c0.5,0.8-0.1,1-0.6,1.4
					c-0.5,0.3-1,0.6-1.4-0.1c-0.4-0.6-0.8-1.3-1.2-2c-1.8-3.1-3.5-6.1-5.4-9.1c-0.2-0.3-0.3-0.6-0.6-0.7c-0.8-0.1-1.4,0.5-2,0.9
					c-0.9,0.5-1.8,1.1-2.5,1.9c-0.2,0.6,0.2,1.2,0.4,1.7c1.4,3.3,3.3,6.4,4.9,9.6c0.2,0.4,0.4,0.8,0.6,1.2c0.4,0.8-0.1,1.3-0.7,1.6
					c-0.7,0.4-1.1,0.1-1.4-0.6c-1.8-3.8-4-7.4-5.9-11.2c-0.1-0.2-0.2-0.4-0.4-0.6c-1.1,0.4-2.2,0.7-2.8,1.8
					c1.6,4.6,3.5,9.1,5.4,13.5c1.9,4.4,3.7,8.9,5.6,13.3c0.8,1.9,1.7,3.8,2.6,5.6C271.9,201,272.8,202.7,273.7,204.3z"/>
				<path fill="#C1A890" d="M262.8,163.5c1.5-1,3-1.9,4.5-2.9c0.9-0.2,1.7-0.7,2.3-1.3c0.6-0.5,1.3-1,2.1-1.2
					c0.6-0.2,1.1-0.7,1.5-1.1c-2.2-4.1-4.9-8.1-7.3-12.1c-2-3.3-3.9-6.6-5.7-10c-0.8-1.5-0.7-3.4-0.8-5.1c-0.3-4.9-0.3-9.8-1.9-14.5
					c-1.2-3.4-2.5-6.8-3.9-10.2c-0.4-1-0.6-2.1-0.8-3.1c-0.1-0.6-0.4-1-0.9-1.1c-0.6-0.1-0.8,0.5-1,0.9c-0.9,1.9-1.1,4-1.1,6.1
					c0,0.4,0.2,0.7-0.1,1.3c-0.6-0.9-1.2-1.6-1.7-2.4c-2-3.2-3.3-6.8-5.2-10c-0.8-1.3-1.7-1.7-3.1-1.2c-0.6,0.2-1.1,0.3-1.6,0
					c-1.8-0.9-3.5,0.4-3.8,2.2c-0.2,1.1-0.3,2.1-1.3,2.9c-0.7,0.6-0.8,1.5-0.8,2.3c0.2,4.1,0.4,8.2,1.2,12.3
					c0.5,2.9,1.8,5.3,3.4,7.6c1.2,1.8,2.4,3.7,3.6,5.5c1.2,1.8,2.4,3.6,3.6,5.5c1.6,2.4,2.5,5.1,3.6,7.8c1.8,4.4,3.6,8.7,5.3,13
					c1.1,2.8,2.3,5.5,3.2,8.4c0.4,1.2,1,2.4,1.5,3.7c0.7-0.8,2.1-0.8,2.7-1.8C261.3,165,262.2,164.5,262.8,163.5z"/>
				<path fill="#DBB22B" d="M262.8,163.5c-0.9,0.5-1.8,1-2.7,1.5c1.1,2.7,2.6,5.2,4,7.8c0.8,1.5,1.7,3,2.3,4.6
					c0.3,0.6,0.6,0.8,1.3,0.5c1.6-0.6,1.8-1.2,1.1-2.7C266.7,171.4,264.6,167.6,262.8,163.5z"/>
				<path fill="#DBB22B" d="M269.6,159.3c-0.9,0.3-1.6,0.7-2.3,1.3c2.4,4.1,4.7,8.2,7.1,12.2c0.3,0.5,0.6,0.8,1.2,0.3
					c1.8-1.4,1.8-1.4,0.7-3.3c-1.9-3.2-3.8-6.3-5.7-9.4C270.2,160,269.9,159.7,269.6,159.3z"/>
			</g>
		</g>
	</g>
</g>
<g id="body">
	<g id="ayehqA_1_">
		<g>
			<path fill="#615A51" d="M314.7,318.5c0,0.3,0,0.6,0,1c0.3,0.9,0,1.9,0.1,2.8c0.2,1.7,0.1,3.4,0.1,5c0,3.8,0.1,7.6,0.3,11.4
				c0.1,2.8,0.3,5.5,0.4,8.2c0.1,2.7,0.5,5.5,0.7,8.2c0.4,4.7,1.1,9.3,1.3,14.1c0.1,2.5,0.4,5.1,0.9,7.6c0.5,2.9,0.6,5.8,0.8,8.7
				c0.2,2.2,0.8,4.4,1.1,6.6c0.5,3.9,0.7,7.9,1.5,11.8c1.2,6.1,2.2,12.3,3.1,18.4c0.5,3.5,1.4,7,1.9,10.5c0.8,5.2,2,10.4,2.9,15.5
				c1.1,6.2,2.2,12.3,3.1,18.5c0.7,4.9,1.8,9.7,2.4,14.7c0.4,3.6,1.1,7.2,1.5,10.8c0.4,2.7,1.1,5.4,1.5,8.1c0.6,3.5,1.2,7,1.8,10.5
				c0.4,2.8,0.8,5.6,1.4,8.4c0.5,2.1,0.5,4.2,1.1,6.3c1,3.2,1,6.6,1.7,9.9c0.6,3.1,1.1,6.2,1.4,9.3c0.2,1.3,0.5,2.6,0.8,3.9
				c0.8,3.3,0.8,6.7,1.7,9.9c0.4,1.4,0.3,2.9,0.5,4.4c0.4,3.5,1.2,6.8,1.7,10.3c0.2,1.3,0.6,2.5,0.7,3.9c0.1,0.8,0.8,1.4,1.8,1.5
				c0.4,0,0.8,0,1.2-0.1c1.7-0.3,3.5-0.2,5.2-0.1c3.9,0.3,7.8-0.2,11.7,0c0.5,0,1-0.1,1.5-0.1c1.3-0.1,2.6-0.1,3.9-0.3
				c1.2-0.2,1.7-0.7,1.5-1.8c-0.5-4-0.4-8-0.8-12c-0.4-3.8-0.4-7.6-0.6-11.4c-0.2-3.9-0.6-7.9-0.8-11.8c-0.2-2.9-0.6-5.8-0.5-8.7
				c0-2.1-0.5-4.2-0.7-6.3c-0.2-2.8-0.4-5.6-0.6-8.4c-0.3-3.6-0.6-7.2-1-10.7c-0.4-3.1-0.7-6.2-0.9-9.3c-0.3-3.7-0.9-7.4-1.3-11.1
				c-0.4-4.2-0.9-8.4-1.4-12.6c-0.3-2.4-0.7-4.8-1-7.2c-0.9-7.3-2.3-14.5-3.1-21.8c-0.6-4.8-0.9-9.6-1-14.5
				c-0.1-6.3-0.4-12.6-0.5-18.9c-0.1-5.8-0.3-11.6-0.2-17.4c0-1.8-0.3-3.7-0.3-5.5c0.1-4.7,0-9.4,0.1-14.1c0.1-3.7,0.4-7.3,0.4-11
				c0-3.8,0.4-7.5,0.1-11.3c-0.1-0.9-0.2-1.8-0.1-2.7c0,3.3,0.4,6.7,0.9,10c0.2,1.7,0.1,3.4,0.4,5.1c0.5,2.5,0.4,5.1,0.8,7.6
				c0.9,5.8,1.4,11.7,2,17.5c0.6,6.3,1.4,12.5,2.1,18.8c0.5,4.1,1.1,8.2,1.5,12.4c0.2,2.6,0.5,5.2,0.8,7.9c0.5,4,0.9,8,1.5,12
				c0.8,5.7,2,11.3,3.1,16.8c0.8,4,1.6,7.9,2.3,11.9c0.8,5.1,2,10.2,2.9,15.3c0.4,2.3,1.2,4.6,1.6,7c0.9,5.7,2.7,11.2,3.9,16.8
				c0.9,4.5,1.7,8.9,2.7,13.4c0.9,4.2,1.6,8.4,2.4,12.6c0.8,4.3,1.7,8.5,2.6,12.8c1.1,5.4,1.8,10.9,2.6,16.3c0,0.2,0,0.3,0.1,0.5
				c1.3,4.6,1.7,9.3,2.6,14c0.2,1.3,1.2,2.2,2.5,2.3c0.4,0,0.7,0.1,1.1,0.1c2.6,0.3,5.1,0.2,7.6-0.1c2.7-0.3,5.4-0.4,8.1-0.6
				c1.2-0.1,2.3-0.5,3.4-0.6c0.7,0.1,1.4-0.1,2.1-0.2c2.5-0.2,3.2-1.1,3.2-3.7c0-1.4-0.1-2.9-0.2-4.3c-0.3-4.9-0.3-9.9-0.8-14.8
				c-0.3-3.2-0.7-6.5-1-9.7c-0.2-2.1-0.5-4.2-0.6-6.3c-0.1-2.9-0.7-5.8-0.9-8.7c-0.5-6-1.4-11.9-2.1-17.8
				c-0.9-7.3-1.8-14.5-2.8-21.7c-1.4-9.7-3.2-19.3-4.6-29c-0.9-5.8-2-11.6-2.8-17.4c-0.6-4.4-0.8-8.7-0.5-13.1
				c0.2-3.8,0.5-7.6,0.6-11.5c0.1-4.3,0.3-8.6,0.6-12.9c0.2-2.9,0.1-5.7,0.2-8.6c0.2-3,0-6,0.3-9c0.2-2.3,0.1-4.6,0.3-6.9
				c0.3-4.5,0.6-9,0.6-13.5c0.1-3.6,0.1-7.3,0.2-10.9c0.1-3.7,0.2-7.4,0.5-11.1c0.3-2.8,0.3-5.7,0.3-8.5c0.1-4.2,0-8.5,0-12.7
				c0-4.5,0.1-9,0-13.5c-0.1-6.6-0.3-13.2-0.5-19.8c-0.2-5.1-0.7-10.3-1.2-15.4c0-0.5-0.2-0.9-0.5-1.1c-1.3-0.5-2.7-0.1-4,0.1
				c-1.7,0.2-3.4,0.4-5.1,0.5c-2.1,0.1-4.2,0.4-6.3,0.3c-2.2,0.5-4.4,0.5-6.6,0.3c-2.3,0.6-4.7,0.2-7.1,0.5c-0.7,0-1.4,0-2.1-0.1
				c-3.4,0.4-6.9-0.1-10.3,0.1c-0.3,0-0.5-0.1-0.7-0.2c-0.8,0-1.6,0.1-2.5,0.1c-0.4,0-0.8,0-1.2,0c-3.2,0-6.5,0.1-9.7-0.2
				c-0.8,0.2-1.6,0-2.4-0.1c-0.8,0.1-1.5-0.1-2.3-0.1c-0.3,0.1-0.5,0.2-0.8,0.1c-2.2-0.4-4.5-0.2-6.8-0.5c-0.9-0.1-1.8,0-2.6,0
				c-0.8,0.1-1.6,0-2.3-0.3c-0.2,0-0.4,0.1-0.7,0c-2.8-0.4-5.7-0.8-8.6-0.7c-0.4,0-0.8-0.3-1.2-0.3c-0.6-0.1-1.1,0-1.7-0.2
				c-0.6-0.2-1.2,0.1-1.8-0.2c-2.5-0.3-4.9-0.4-7.4-0.7c-0.5,0-1-0.1-1.4-0.2c-0.8,0.1-1.8-0.2-2.5,0.4c-0.4,0.9-0.3,1.9-0.4,2.8
				c-0.3,4.3-0.3,8.7-0.4,13c-0.1,3.8-0.1,7.5-0.1,11.3c0,0.4-0.1,0.8-0.1,1.2c0,1.6,0,3.1,0,4.7c0,1.3,0,2.6,0,3.8
				C314.8,314.5,315,316.5,314.7,318.5z"/>
			<path fill="#E3C779" d="M441.2,209c-0.6-0.6,0.2-1.2,0-1.8c-0.3-0.4-0.3-0.7,0-1c0.1-1.7,0.4-3.4,0.1-5.1
				c-0.2-1.2,0.2-2.2,0.1-3.3c-0.1-0.7,0.1-1.5,0.2-2.2c0.1-1,0.1-2,0.2-2.9c0.1-3.2,0.2-6.4,0.3-9.6c-0.3-0.1-0.6-0.1-0.9-0.1
				c0,0,0,0,0,0c0.4,0,0.7,0,0.9-0.4c0-0.4,0.1-0.7,0.1-1c-0.1-0.3,0.5-1-0.5-0.8c-0.3,0.1,0.1-0.7,0-1c0.1-0.2,0.2,0,0.3,0
				c0.7-0.1,0.4-0.7,0.4-1c0.1-1.8,0-3.6-0.2-5.4c-0.3-0.2-0.1-0.4,0-0.6c0-0.5-0.3-1.1,0-1.6c-0.2-0.2-0.3-0.5-0.6-0.5
				c-0.2,0-0.4,0.1-0.6,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0.1c0.1,0,0.2,0,0.3,0c0.4-0.1,0.7-0.3,0.8-0.7
				c-0.2-3.4-0.4-6.7-0.7-10.1c-0.1-0.9-0.6-1.4-1.4-1.5c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-0.1-0.4-0.2-0.4-0.4c0-0.1,0.1-0.2,0.2-0.3
				c0.8-0.5,1.4-1.2,1.4-2.2c-0.2-0.4-0.1-1.1-0.8-1.2c-0.5,0-0.8,0.2-1,0.7c0,0,0,0.1,0,0c0-0.2-0.1-0.4-0.2-0.5
				c-0.3-0.3-0.7-0.6-0.6-1.2c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.4-0.7-0.7-0.9-1.2c-0.3-0.3-0.6-0.4-0.9-0.6c-0.1,0-0.2,0.1-0.3,0.1
				c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.1c0.4-0.1,0.6,0.5,1.1,0.3c0.4-0.3,0.7-0.7,0.9-1.2c0.7-0.8,0.1-1.4-0.3-2c0-0.3-0.1-0.5,0-0.8
				c0-0.3-0.1-0.5-0.1-0.8c0-0.1-0.1-0.2-0.1-0.3c-0.4-0.7-0.5-1.5,0-2.2c0.1-0.2,0.2-0.4,0-0.6c-0.9-1.5-1.2-3.2-2-4.8
				c-0.2-0.4-0.5-0.6-1-0.4c-0.3,0.3-0.7,0.5-1.1,0.5c0-0.1,0-0.1,0.1-0.1c0.3,0.1,0.6,0,0.8-0.2c0.3-0.3,0.5-0.6,0.2-0.9
				c-0.5-2.1-1.5-4-2.9-5.6c-0.4-0.5-0.5-1.1-0.8-1.6c-2.4-3.4-5.2-6.2-8.3-8.9c-0.8-0.7-1.5-1.6-2.4-2c-1.2-0.6-2-1.5-3-2.2
				c-0.5-0.4-1-0.2-1.7,0.5c0.6-1.5-0.5-1.7-1.1-2.1c-0.4,0-0.9,0-1.3,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.4-0.7,0.6-0.9,1
				c0,0.3,0.1,0.4,0.3,0.6c0.7,0.9-0.6,0.8-0.7,1.3c-0.1,0.2,0,0.4,0.1,0.6c0.4,0.8,1.4,1.1,1.7,1.9c0.2,0,0.3-0.1,0.4-0.1
				c-0.1,0-0.1,0-0.2,0.1c-0.2,0.1-0.3,0-0.5-0.1c-0.1-0.9-1.3-0.9-1.5-1.8c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.2-0.7,0.4
				c-0.1,0.2-0.3,0.4-0.6,0.2c-0.2-0.2-0.4-0.5-0.6-0.7c-0.3-0.4-0.7-0.6-1.1-0.8c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.1,0.3-0.2
				c0.2-0.1,0.2-0.3,0.3-0.5c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.5-0.3
				c0.2-0.2,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.2,0.7-0.2c0.2-0.1,0.4-0.3,0.6-0.6c0-0.1,0-0.1,0-0.2c-0.5-0.2-0.7-1.3-1.5-0.5
				c-0.1,0.2-0.3,0.2-0.4,0c-0.3-0.2,0.2-1.2-0.7-0.7c-0.2,0.3-0.5,0.2-0.8,0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1
				c0-0.1,0-0.1,0.1-0.2c0-0.2-0.1-0.4-0.2-0.5c-1.3-0.2-2.5-0.9-3.5-1.5c-1.5-0.8-3.1-1.4-4.7-2c-2.5-1.1-5.1-2.3-7.8-2.8
				c-0.6-0.1-0.9,0.3-1.1,0.7c-2.4,4-6.1,6.3-10.2,7.9c-2.3,0.9-4.8,1.1-7.3,1.3c-1.2,0.1-2.3,0.2-3.5,0.1
				c-4.6-0.2-8.6-1.9-11.9-5.1c-1.5-1.4-2.5-3.2-3.3-5c-0.3-0.7-0.7-1.2-1.4-1.6c-6.1,1.3-12,3.2-17.8,5.5c-1.7,0.7-3.5,1.4-5.2,2.2
				c-3.2,1.4-5.3,3.8-6.1,7.2c-0.5,2.1-1.9,3.9-2.7,5.9c-1.1,2.9-2.4,5.8-3.1,8.9c-0.6,2.5-1.4,4.9-1.7,7.5
				c-0.3,1.9-0.1,3.8-0.1,5.7c-0.1,5.7-0.7,11.4-1.2,17.1c-0.2,1.8-0.1,3.7,0.1,5.5c0.3,3.4,0.4,6.8,0.6,10.3
				c0.2,3.6,0.4,7.3,0.5,10.9c0.3,5.3,0.6,10.6,0.6,15.9c0,2.7,0.2,5.3,0.4,8c0.2,3.5,0.1,7.1,0,10.6c-0.1,4.4-0.1,8.8-0.2,13.2
				c-0.1,4.3-0.2,8.5-0.4,12.8c-0.2,6.9-0.5,13.7-0.8,20.6c-0.1,1.3-0.2,2.6-0.3,3.9c0.8,0,1.6-0.1,2.4,0.2c0.4-0.4,0.2-0.9,0.2-1.4
				c0.4-4.3,0.7-8.7,1.2-13c0.1-1.3,0.3-2.5,0.4-3.8c0-0.6,0.3-1.3,1-1.2c0.8,0.1,0.9,0.7,0.8,1.4c-0.7,5.2-0.8,10.4-1.3,15.6
				c-0.1,1-0.7,1.6-1.1,2.5c2.5,0.2,5,0.5,7.5,0.7c0.2-0.2,0.5-0.1,0.7-0.3c0.4-0.5,0.5-1.1,0.5-1.7c0.2-2.8,0.5-5.5,0.8-8.2
				c0.2-2.6,0.1-5.3,0.5-7.9c0.1-0.8,0.6-1.2,1.4-1.2c0.9,0,1.3,0.4,1.3,1.3c-0.2,3.9-0.6,7.7-1,11.6c-0.1,0.8-0.1,1.6-0.1,2.4
				c0,1.5,0,2.9-0.8,4.3c3.3,0.3,6.7,0.6,10,0.9c0.6-0.7,0.7-1.6,0.7-2.5c0.1-3.4,0.4-6.7,0.3-10c0-2.1,0.1-4.2,0.4-6.3
				c0.2-1.1,0.7-1.5,1.7-1.3c0.8,0.1,1.1,0.6,1,1.7c-0.5,3.9-0.6,7.8-0.7,11.7c0,1.6-0.3,3.1-0.2,4.7c0,0.9-0.5,1.4-1.4,1.4
				c-0.5,0-1.3-0.1-1.4,0.8c1.4,0.4,2.8,0.2,4.3,0.3c2.9,0.2,5.9,0.3,8.8,0.4c0.2-2.8,0.4-5.6,0.5-8.5c0-3.4,0.5-6.7,0.5-10
				c0-0.8,0.3-1.5,1.3-1.5c0.8,0,1.3,0.6,1.3,1.6c0.1,1.2-0.2,2.4-0.3,3.6c-0.1,2.1-0.3,4.1-0.2,6.2c0.1,2.1-0.2,4.2-0.4,6.3
				c-0.1,0.8-0.2,1.5,0.1,2.2c0.4,0.4,0.9,0,1.3,0.3c3.3,0,6.6,0.3,9.8,0c0.8-1.3,0.5-2.8,0.6-4.2c0-4.7,0.3-9.5,0.3-14.2
				c0-1.4,0.5-2,1.6-2c1,0,1.4,0.3,1.5,1.3c0.1,1.2,0.1,2.4,0,3.6c-0.3,2.2-0.2,4.5-0.2,6.7c0,2-0.2,4.1-0.2,6.1c0,1,0,2,0.7,2.8
				c1.8,0.3,3.6,0.2,5.4,0.2c1.5,0,3,0,4.6,0c0.1,0,0.3-0.1,0.4-0.2c-1.9-2-1.1-4.5-1.1-6.8c0-3.7-0.1-7.3-0.1-11c0-0.3,0-0.6,0-1
				c0.1-1,0.5-1.2,1.6-1.2c0.9,0.1,1.1,0.6,1,1.4c-0.1,1,0.1,2,0.1,3c0,3.9-0.4,7.8,0,11.7c0.1,0.6,0,1.3-0.1,1.9
				c-0.1,0.7-0.1,1.4,0.4,1.9c2.4,0,4.9,0,7.3-0.3c0.6-0.2,1.2,0.1,1.8-0.2c0.6-0.6,0.7-1.4,0.7-2.2c-0.3-5.4-0.3-10.7-0.7-16.1
				c-0.1-1.3,0.3-1.7,1.6-1.8c0.9,0,1.3,0.4,1.2,1.2c-0.4,3.1,0,6.2,0.1,9.3c0.1,2.7,0.3,5.3,0.2,8c0,0.5,0,1,0.5,1.4
				c0.4,0.1,0.8,0.1,1.3,0c3.8,0,7.5-0.4,11.3-0.8c1.3-0.1,2.5-0.3,3.8-0.4c-0.1-3.5-0.4-7.1-0.8-10.6c-0.6-4.7-1.4-9.3-2.3-13.9
				c-0.6-3.1-0.9-6.1-1.3-9.2c-0.2-1.5,0.4-3,0.7-4.5c0.7-4.4,1.5-8.8,2.4-13.1c0.3-1.4,0.5-2.8,0.9-4.3c0.5,0.6,0.1,1.2,0.1,1.8
				c0.1,6.8,0.1,13.6,0.4,20.4c0.1,2.9,0.5,5.7,0.8,8.5c0.6,5.2,1.2,10.4,1.8,15.6c0.7,5.6,1.4,11.3,2,16.9c0.3,3.2,0.7,6.3,1.2,9.5
				c1.4,1,2.9,0.3,4.3,0.1c0.3-0.3,0.5-0.8,0.4-1.2c-0.2-2.7-0.2-5.3-0.5-8c-0.2-1.7,0-3.4-0.3-5c-0.1-0.7,0.2-1.2,1-1.2
				c0.8,0,0.9,0.6,0.9,1.2c0,3.9,0.6,7.7,0.6,11.6c0,0.8,0.1,1.5,0.5,2.2c0.3,0.3,0.6,0.3,1,0.3c1.6-0.3,3.1-0.5,4.7-0.8
				c0.4-0.1,0.6-0.3,0.8-0.6c0.7-1.6,0.2-3.2-0.1-4.7c-0.4-2.8-0.5-5.6-0.9-8.4c-0.1-0.6,0-1.2,0.7-1.3c0.8-0.1,1,0.5,1.1,1.1
				c0.4,1.7,0.2,3.5,0.5,5.2c0.5,2.6,0.6,5.2,1.3,7.8c1.1,0.6,2.1,0.4,2.9-0.5c0.1-4.8,0.3-9.7,0.4-14.5c0.1-5.9,0.2-11.8,0.1-17.6
				c-0.1-6.3,0.2-12.5,0.2-18.8c0-3-0.1-6.1,0.2-9.1c0.5-4.6,0.6-9.1,1.1-13.7c0.3-2.9,0.6-5.7,0.6-8.6
				C440.8,209.2,441.1,209.2,441.2,209z"/>
			<path fill="#C1A890" d="M404.3,579.8c0.5,3,1.2,6,1.6,9c0.1,1.1-0.1,2.5-1.6,3c0.9,1,2.3,1.4,3.5,2c3.4,1.7,7.1,1.9,10.8,1.6
				c1.8-0.1,3.5-0.2,5.2-0.8c0.4-0.1,1-0.1,0.9-0.8c-1.2-3.4-1-6.9-1-10.5c0-1.5-0.1-3.1-0.2-4.6c-1.2-0.2-2.4,0.3-3.5,0.4
				c-3.3,0.2-6.5,0.7-9.7,0.8C408.2,580,406.2,580.3,404.3,579.8z"/>
			<path fill="#C1A890" d="M354.1,577.9c0.9,0.8,0.6,2,0.9,3c0.6,2.3,1,4.6,1.2,7c0,0.6,0.3,0.9,0.8,1.1c2.4,1,4.8,1.9,7.3,2.1
				c2.7,0.2,5.3-0.2,7.8-1.4c0.4-0.2,0.7-0.4,0.8-0.8c-0.4-2.6-0.3-5.3-0.2-7.9c0-1-0.2-2-0.1-3.1c-3.2-0.1-6.3,0-9.5,0.1
				c-1.4,0.1-2.8,0-4.1-0.1C357.3,577.8,355.7,577.8,354.1,577.9z"/>
			<path fill="#DBB22B" d="M372.3,278.9c-0.2-4.5,0.1-9.1,0.1-13.6c0-2,0.1-4,0.2-6c0.1-1.2-0.4-1.6-1.5-1.5c-0.2,0-0.3,0-0.5,0
				c-2.1,0.1-2.3,0.3-2.3,2.4c-0.1,5-0.1,10-0.2,14.9c0,1.3-0.4,2.5,0,3.8c0.4,0,0.7,0,1.1,0c0.8,0.3,1.6-0.5,2.5,0
				C371.9,278.9,372.1,278.9,372.3,278.9z"/>
			<path fill="#DBB22B" d="M356.9,278.6c0.5-0.9,0.4-2,0.4-2.9c0.2-2.3,0.4-4.5,0.3-6.8c-0.1-2.3,0.2-4.7,0.4-7c0.1-1,0.1-1.9,0-2.9
				c-0.1-0.7-0.4-0.9-1.1-1c-2.2-0.3-2.5,0-2.6,2.2c-0.3,5.7-0.5,11.5-1,17.2c-0.1,0.6,0.1,1,0.6,1.3c0.6,0.1,1.2,0.2,1.7,0
				C356.1,278.8,356.5,278.9,356.9,278.6z"/>
			<path fill="#DBB22B" d="M340.9,277.9c0.6-0.3,1.2-0.3,1.8-0.1c1.3,0.4,1.5-0.4,1.6-1.3c0.1-1.8,0.2-3.6,0.3-5.4
				c0.1-4.1,0.3-8.3,0.7-12.4c0.2-1.6,0-1.6-1.6-1.6c-0.4,0-0.9,0-1.3,0c-0.7,0-1,0.4-1,1c-0.1,3.6-0.4,7.2-0.4,10.9
				c0,2.7-0.1,5.3-0.5,8c0,0.3-0.1,0.6,0.1,0.9C340.6,277.9,340.7,277.9,340.9,277.9z"/>
			<path fill="#DBB22B" d="M384.5,278.9c0.2-1.4,0.4-2.8,0.3-4.1c-0.3-3.2,0-6.4,0-9.7c0-1.8,0-3.7,0-5.5c0-1-0.3-1.6-1.4-1.3
				c-0.1,0-0.2,0-0.4,0.1c-0.6,0.1-1.4-0.5-1.8,0.1c-0.5,0.7-0.3,1.5-0.3,2.3c0.1,4.2,0.2,8.5,0.1,12.7c0,1.2,0,2.4-0.1,3.6
				c-0.1,1.4,0.8,1.6,1.8,1.8C383.3,279,383.9,279.2,384.5,278.9z"/>
			<path fill="#DBB22B" d="M330.4,277c0.7,0,1-0.3,1.1-1c0.2-1,0.2-1.9,0.2-2.9c0-3.4,0.6-6.7,0.8-10c0.1-1.8,0.2-3.5,0.4-5.3
				c0.1-0.8-0.3-1.1-1.1-1.1c-2.6,0-2.8,0-2.8,2.6c-0.1,3.1-0.4,6.2-0.6,9.3c-0.1,2-0.4,4-0.6,6c-0.1,0.6-0.1,1.2-0.1,1.8
				c0.4,0.2,0.8,0.2,1.2,0.5C329.4,276.9,329.9,277,330.4,277z"/>
			<path fill="#DBB22B" d="M319.7,276c1.1-0.1,1.5-0.6,1.6-1.8c0.5-5.3,0.6-10.7,1.3-15.9c0.1-0.8,0.5-1.8-0.6-2.3
				c-1.5-0.6-2.3-0.2-2.4,1.4c-0.5,5.4-1,10.7-1.5,16.1c-0.1,0.8-0.6,1.7,0.3,2.5C318.8,276,319.2,276,319.7,276z"/>
			<path fill="#D6BA6E" d="M393.5,278.4c-0.6,0-1.2-0.1-1.7,0.2c2.3,0.1,4.5-0.2,6.7-0.4c-0.4,0-0.8,0-1.2,0
				C396.2,277.1,394.9,277.2,393.5,278.4z"/>
			<path fill="#E0C062" d="M371.6,278.9c-0.8-0.5-1.7,0-2.5,0C370,279.2,370.8,278.8,371.6,278.9z"/>
			<path fill="#CEB677" d="M356.9,278.6c-0.4,0-0.9,0-1.3,0c0.8,0.5,1.7,0.2,2.5,0.2C357.8,278.6,357.4,278.7,356.9,278.6z"/>
			<path fill="#D5BA71" d="M328.9,276.9c-0.3-0.4-0.7-0.4-1.2-0.5c-0.2,0.1-0.3,0.2-0.5,0.2C327.8,276.8,328.4,276.8,328.9,276.9z"
				/>
			<path fill="#C1A890" d="M353.4,104.2c1.1,2.6,2.3,5.1,4.3,7.1c2.4,2.4,5.3,3.8,8.5,4.6c4.3,1.1,8.5,0.4,12.7-0.5
				c3.7-0.8,6.9-2.7,9.7-5.2c1.4-1.3,2.4-2.9,3.4-4.5c-1.9-1.1-4.1-1.5-6.2-2.3c-1-0.4-1.3-0.9-1.3-1.8c0-5.7-0.1-11.4,0-17.1
				c0.1-3.7,0.1-7.4,0.2-11c0-0.4-0.2-0.8,0.4-1c0.3-0.4,0-0.6-0.2-0.8c-1.2-0.6-1.9-1.6-2.6-2.8c-0.1-0.1-0.1-0.2-0.2-0.3
				c-0.4-0.8-0.6-1.6-1-2.4c-0.2-0.2-0.3-0.4-0.3-0.7c-0.4-0.9-0.7-1.9-1.2-2.8c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.9-0.7-1.7-0.6-2.6
				c0-0.2,0.2-0.3,0.3-0.5c0.9-0.3,1.9-0.6,2.6-1.4c0.1-0.1,0.2-0.1,0.2-0.2c0.5-0.5,0.9-1.1,1.4-1.6c0.1-0.1,0.1-0.2,0.2-0.3
				c0.3-0.4,0.8-0.7,0.9-1.2c0.1-0.2,0.2-0.5,0.3-0.7c0.8-1.7,0.4-3.5,0.4-5.2c0-0.3-0.2-0.6-0.4-0.8c-0.1-0.2-0.2-0.3-0.4-0.4
				c-1-1.3-2.4-1.6-3.9-0.8c-1.1,0.5-2,1.3-2.5,2.4c-0.2,0.6-0.6,1-1,1.5c-0.5,0.5-0.8,1.5-1.5,1.4c-1-0.1-1.9-0.7-2.6-1.5
				c-0.7-0.7-0.3-1.6,0-2.4c1.1-2.6,1.7-5.2,1.7-8c0-0.2,0-0.4,0-0.6c0.1-0.4,0.1-0.9,0-1.3c-0.1-0.4-0.1-0.8-0.1-1.1
				c0-2.6-0.8-5-1.6-7.3c-1-3-3.1-5.2-5.7-6.9c-3-2-6.1-3.5-9.6-4.4c-1.6-0.4-3.1-1-4.4-2c-0.3-0.3-0.7-0.4-1.1,0
				c-2.9,3-4.5,6.8-5.6,10.8c-1.1,4.1-2.1,8.3-3.4,12.5c-1.1,3.8-2.8,7.3-4.7,10.7c-0.3,0.4-0.4,0.9-0.6,1.4c-0.5,1.4-0.2,2.3,1,3.1
				c0.4,0.3,0.8,0.7,1.3,0.9c1.9,0.9,2.2,2.5,2.3,4.3c0,1.8,0,1.8,1.8,1.6c1.5-0.2,3.1-0.4,4.6-0.8c0.4-0.1,0.8-0.4,1.1,0
				c0.3,0.4,0,0.9-0.2,1.2c-1.2,2.2-3,3.9-5.5,4.5c-0.9,0.2-1.2,0.6-1,1.5c0.4,1.9,0.2,3.8,0.9,5.6c1.2,3.2,3.9,4.8,6.8,6
				c2.6,1.1,5.4,1.3,8.2,1.4c0.8,0,1.6,0.3,2.4,0.2c0.7-0.1,0.5,0.5,0.5,0.8c-0.2,2.6-0.5,5.2-0.8,7.8c-0.4,3.9-0.7,7.8-1.1,11.7
				c-0.1,0.9-0.4,1.3-1.3,1.6C357.2,103.4,355.2,103.5,353.4,104.2z"/>
			<path fill="#625B52" d="M380.8,66.5c0.2,0.9,0.7,1.7,1.2,2.4c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.1c0.9,1.3,1.6,2.8,3.3,3.4
				c1.4,0.7,2.8,1.2,4.4,1.4c0.3-0.2,0.6-0.3,0.9,0c1.1,0.5,2,0,2.9-0.4c0-0.1-0.1-0.1-0.1-0.2c0,0.1,0.1,0.1,0.1,0.2
				c2.6-0.9,4.5-2.7,5.9-4.9c1.4-2.1,3-3.7,5.4-4.5c0-0.3,0.1-0.4,0.4-0.3c2.4-1.1,4.4-2.8,5.5-5.3c-0.1-0.3-0.3-0.4-0.6-0.6
				c-0.3-0.1-0.6-0.2-0.7-0.5c-0.1-0.3-0.3-0.6-0.6-0.8c-0.2-0.1-0.3-0.1-0.5,0c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.2,0,0.6-0.4,0.8
				c-0.2,0.1-0.4,0.1-0.5,0.3c-0.5,0.5-0.1,1.1-0.4,1.6c0,0.2,0-0.1,0,0.1c0,0,0-0.1,0-0.1c0.2-0.5-0.1-1.2,0.4-1.6
				c0.7-0.2,0.7-0.9,1.1-1.3c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.4,0.1-0.7-0.2-1.1c-0.1-0.3-0.2-0.5-0.1-0.8c0.1,0,0.1-0.1,0.2-0.1
				c0.5,0,0.4,0.5,0.6,0.8c0.2,0.3,0.4,0.5,0.8,0.5c0.4,0,0.7-0.1,1,0.2c0.3,0.1,0.6,0.2,0.8-0.1c0.3-1.8,0-3.5,0-5.3
				c-0.2-0.3-0.2-0.6-0.5-0.8c-0.4-0.2-0.2-0.7-0.4-1c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.4,0.2-0.7,0.3-0.9
				c0-0.1,0-0.2,0-0.2c0.1,0,0,0.1,0.1,0.1c0,0.1,0,0.3,0,0.4c0,0.3-0.3,0.5-0.4,0.7c0,0.2,0,0.3,0.1,0.4c0.2,0.2,0.4,0.5,0.5,0.8
				c0.2,0.3,0.3,0.8,0.8,0.8c0.2-0.1,0.2-0.3,0.2-0.5c0-0.3,0-0.5,0.2-0.7c0.2-0.3,0.3-0.6,0.5-0.9c-0.1-0.6,0.2-1.2-0.4-1.7
				c-0.2-0.2-0.2-0.4-0.1-0.6c0.2-0.4,0.5-0.1,0.8,0c0.1,0,0.2,0,0.3,0c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0,0.3-0.1
				c0.3-0.2,0.7-0.2,0.8-0.6c0.1-0.6-0.5-0.8-0.6-1.2c0.7-1,1.2-2.1,1.5-3.2c0,0,0-0.1,0-0.1c0.2-0.2,0.4-0.2,0.6-0.1c0,0,0,0,0,0
				s-0.1,0-0.1,0c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.1,0,0.2,0.1,0.3c0.4,0.5,0.9,0.6,1.4,0.4
				c0.3-0.2,0.5-0.4,0.8-0.5c0.5-0.8,0.9-1.7,0.9-2.7c-0.1-0.2-0.2-0.3,0.1-0.4c0.4-0.7,0.3-1.4,0-2.1c-0.4,0.1-0.7,0.2-1.1,0.3
				c-0.2,0.1-0.3,0.2-0.3,0.4c0,0,0,0.1,0,0.1s0-0.1,0-0.1c0.1-0.2,0-0.3,0-0.5c-0.3-0.3-0.3-0.7-0.4-1.1c0.1,0.2,0.2,0.4,0.4,0.4
				c0.4,0,0.6-0.2,0.8-0.6c0-0.2,0.1-0.5,0.2-0.7c0.1-0.1,0.1-0.2,0.2-0.4c-0.2-1.3-0.7-2.5-1.4-3.6c-0.3-0.1-0.5-0.4-0.6-0.6
				c-0.3-0.3-0.3-0.7-0.4-1c-0.4-0.7-0.8-1.5-1.1-2.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.1,0-0.1,0-0.1
				c0.3-0.3,0.2-0.6,0.1-0.9c-0.1-0.2-0.3-0.4-0.4-0.7c-0.2-0.5,0.3-0.6,0.5-0.8c0.2-0.2,0.2-0.5,0.2-0.8c0-0.1,0-0.2,0-0.3
				c-0.2-0.5-0.8-0.5-1.1-0.9c-0.2-0.2-0.5-0.3-0.8-0.5c-0.3-0.2-0.5-0.5-0.7-0.8c-0.1-0.3,0-0.7-0.4-0.9c-0.5-0.2-0.9-0.5-1.4-0.6
				c-0.4,0.1-0.6,0.5-1,0.7c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2,0-0.3,0-0.4,0.1c0,0,0,0,0,0c0,0,0,0,0,0
				c0.1,0,0.1-0.1,0.2-0.1c0.2-0.2,0.3-0.6,0.7-0.6c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.2,0,0.2,0.1c0.1,0,0.2,0,0.3,0
				c0.2,0,0.5,0,0.7,0.2c0.3,0.3,0.7,0.3,1.1,0.3c0.5,0.2,0.4,0.6,0.4,1c0.1,0.3,0.3,0.6,0.6,0.8c0.3,0.2,0.6,0.3,1,0.2
				c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0.3-0.4,0.5-0.5c0.3-0.1,0.5-0.1,0.6-0.4c0-0.3,0.1-0.6-0.1-0.8l0,0c-0.2-0.2-0.2-0.3,0-0.5
				c-0.2-1.4,0-2.9-0.9-4.1c-0.2-0.1-0.1-0.4-0.2-0.5c-0.4-0.5-0.7-1.1-1.2-1.4c-0.4,0.1-0.7,0.4-1.1,0c-0.3-0.4,0.1-0.7,0.2-1
				c-1.5-2-3.8-2.6-6.1-3.1c-0.7-0.2-1.4-0.2-2.1-0.2c-0.1,0.1-0.3,0.3-0.4,0.4c0.1-0.1,0.3-0.2,0.4-0.4c-0.7-0.4-1.4-0.3-2.1-0.6
				c-0.2,0.1-0.4-0.1-0.5-0.2c-1.7-0.9-2.6-2.5-3.8-3.9c-0.2-0.2-0.4-0.4-0.4-0.7c-2.7-3.1-6.1-4.3-10.2-4c-2.6,0.2-4.9,1.2-7.2,2.4
				c-3,1.6-6.1,1.7-9.3,0.5c-2.7-1-5.4-2-8.3-2c-3,0-5.8,0.8-8.3,2.5c-2.5,1.6-3.4,4.2-3.8,6.8c-0.3,1.8,0.2,3.6,1.5,5.1
				c0.3,0.3,0.5,0.7,0.8,1c2.6,2.1,5.9,2.6,8.8,3.8c4.5,1.8,8.7,4.2,11,8.7c1.2,2.4,1.7,5,2.1,7.7c0,0.2,0.1,0.4,0.1,0.6
				c0.3,0.5,0.3,1.1,0.8,1.5c0.2,0.1,0.5,0.2,0.7,0.4c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0s-0.1,0-0.1,0c-0.2,0.1-0.4,0.2-0.7,0.2
				c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.2-0.5,0.4-0.6,0.8c-0.4,2.4-0.7,4.9-1.5,7.2c-0.3,0.8-0.5,1.6-0.8,2.4c-0.3,0.6,0,1.1,0.4,1.4
				c0.9,0.6,1.8,1.2,2.9,1.4c1,0.2,1.4-0.7,2-1.3c1.1-1.1,1.6-2.7,3-3.7c1.4-1,2.8-0.9,4,0.4c0.2,0,0.3,0,0.3,0.2c0.9,2,0.8,4,0,5.9
				c0.2,0.3,0.1,0.4-0.2,0.5c-0.3,0.4-0.6,0.8-0.9,1.2c0.1,0.1,0.1,0.2-0.1,0.2c-0.5,0.6-1,1.1-1.4,1.7c0.1,0.1,0.1,0.1,0.1,0.2
				c0-0.1-0.1-0.1-0.1-0.2c-0.8,0.9-2.1,0.8-3.1,1.5c0.1,0.2-0.1,0.4-0.2,0.5c-0.3,1.2,0.3,2.1,0.7,3.1c0.1,0,0.1,0.1,0.1,0.2
				c0,0.1,0,0.1-0.1,0.1c0.3,1,0.6,2,1.2,2.9C380.7,66,380.9,66.2,380.8,66.5z"/>
			<path fill="#C1A890" d="M438.3,291.8c-0.9,0.2-1.8,0-2.6,0.5c-1.1-0.7-2.1-0.1-3.1,0.3c-2,0.6-4.1,0.5-6,1.2
				c-1-0.6-1.8-0.6-2.9,0.2c-1.4,0.1-2.8,0.2-4.3,0.2c0.5,3.6,1,7.3,1.3,10.9c0.3,2.8,1,5.5,1.4,8.4c0.3,3.1,0.9,6.2,1.6,9.3
				c0.3,1.3-0.4,2.5-0.9,3.7c-1,2.4-1.1,4.9-1.4,7.4c-0.4,4.7-0.2,9.4-0.8,14c-0.1,1.1-0.1,2.3-0.3,3.4c-0.1,0.4,0,0.9,0.2,1.3
				c0.3,0.6,0.7,1.1,1.5,0.4c0.9-0.7,1.6-1.6,2.4-2.5c0,1.8,0.1,3.6,0,5.4c-0.2,2.9-0.6,5.8-1.2,8.7c-0.4,1.9,0.7,2.8,2.6,2.4
				c0.9-0.2,1.7-0.1,2.6-0.3c2.3-0.3,4.1-1.7,5.9-3c2.3-1.6,3.3-3.9,3.9-6.4c0.9-4.1,1.9-8.2,2.6-12.3c0.6-3.5,0.1-7-0.6-10.4
				c-1.3-6.8-2.4-13.6-2.4-20.6c0-4.1-0.1-8.2,0.1-12.3C438.2,298.4,438.1,295.1,438.3,291.8z"/>
			<path fill="#615952" d="M424.7,593.7c-3.1,0.9-6.3,1.4-9.6,1.1c-2.4-0.2-4.7-0.5-6.9-1.5c-0.7-0.3-1.4-0.7-2-1
				c-0.6-0.3-1.2-0.5-1.8-0.5c-0.5,0.1-0.6,0.6-0.9,0.9c-1.6,2.5-3,5-4.7,7.4c-2.3,3.1-4.7,6.3-8,8.5c-3.4,2.3-6.9,4.5-10.3,6.7
				c-1,0.7-1.9,1.5-2.2,2.7c-0.4,1.7,0.9,2.9,2.8,2.9c1.8,0,3.6,0,5.5,0c1.5,0,2.9,0.1,4.4,0.2c1,0,2.1-0.2,3.1,0
				c1.8-0.1,3.6-0.1,5.3-0.4c3.3-0.6,6.1-1.9,8.8-3.9c3-2.3,6.3-4.2,9.1-6.6c0.5-0.4,1.1-0.3,1.6-0.3c1.7-0.2,3.5-0.4,5.2-0.5
				c0.8,0,1.1-0.2,1.3-1c1.3-4,1.3-8.1,0.4-12.2c-0.1-0.6-0.3-1.2-0.4-1.8C425.3,594,425.2,593.7,424.7,593.7z"/>
			<path fill="#E3C779" d="M440.3,152.4c0-0.2,0-0.3,0-0.5c-0.3-0.3-0.1-0.6-0.2-1c-0.3-1.1-0.5-2.2-0.8-3.3c-0.6-0.5-1-0.1-1.5,0.3
				c-0.2,0.4,0.1,0.6,0.3,0.8c0.6,0.4,0.3,0.8-0.2,1.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.4,0.2-0.5,0.4
				c-0.1,0.2-0.2,0.4-0.1,0.6c0.3,0.4,0.2,1,0.7,1.3l0,0c0.2,0.5,0.2,1.1,0.8,1.3c1.1,0.1,1.5-0.2,1.4-1.2
				C440.1,152.8,440.1,152.6,440.3,152.4z"/>
			<path fill="#56514A" d="M415.5,40.3c-0.1,0.8-0.3,1.7-0.8,2.2c-0.4,0.4-0.5,0.7-0.5,1.2c0.2,0.4,0.5,0.3,0.8,0.2
				c0.2-0.1,0.4-0.2,0.7-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.2,0.7-0.2c0,0,0.1,0,0.1,0
				c0.5-0.3,0.9-0.8,1-1.4c-0.1-0.2-0.3-0.3-0.5-0.3c-0.3-0.5-0.8-0.2-1.1-0.2c-0.3,0-0.2-0.2-0.2-0.4c0.1-0.2,0.2-0.4,0.4-0.6
				c0.1-0.1,0.3-0.2,0.4-0.2c0.1-0.1,0.1-0.2,0-0.3c-0.4-0.6-0.9-0.5-1.4-0.2C415.4,40,415.4,40.1,415.5,40.3z"/>
			<path fill="#59534C" d="M410,55.4c-0.3,0-0.6-0.2-0.7-0.5c-0.3-0.2-0.6-0.2-0.9-0.3c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.2,0.1,0.4,0.2,0.6c0.3,0.3,0.7,0.5,0.6,1c0,0.1,0.1,0.2,0.2,0.3c0.1,0.3-0.2,0.8,0.3,1
				c0.3,0.1,0.6,0.4,1,0.3c0.3,0,0.6,0.2,0.9-0.1c0.3-0.3,0.4-0.8,0.3-1.2c-0.1-0.2-0.3-0.4-0.4-0.6C410.7,55.9,410.4,55.6,410,55.4
				z"/>
			<path fill="#E5CC85" d="M437.6,143.3c-1.1,1.2-1.1,1.9-0.3,3.1c0.2-0.1,0.4-0.3,0.4-0.4c0,0.1-0.2,0.3-0.4,0.4
				c-0.1,0.2-0.2,0.4-0.3,0.7c0.7,0.4,1.3,0.4,1.6-0.5C438.4,145.5,438,144.4,437.6,143.3z"/>
			<path fill="#E4C97F" d="M440.3,152.4c-0.1,0.1-0.4,0.3-0.4,0.4c0,0.9-0.4,1-1,0.4c-0.4,0.3,0.4,0.6-0.1,0.8c-0.2,0-0.3,0-0.2,0.3
				c0.1,0.5,0.1,0.9,0.2,1.4c0.2,0,0.3-0.1,0.3-0.3c0.2-0.5,0.3-1,0.9-1c0.6,0,0.5,0.6,0.8,0.9c0.1-0.2,0.1-0.3,0-0.5
				C440.6,154,440.4,153.2,440.3,152.4z"/>
			<path fill="#58524B" d="M414.2,45.1c-0.3,0-0.5,0-0.8,0c-0.2,0.3-0.4,0.4-0.7,0.3c-0.2-0.1-0.5-0.1-0.7-0.1
				c-0.6,0.3-0.3,0.9-0.4,1.3c0.4,0.1,0.4-0.5,0.8-0.3c0.3,0.2,0.2,1,0.9,0.7c0.7-0.3,1.2-0.9,1.7-1.5c0,0,0-0.1-0.1-0.1
				C414.6,45.3,414.3,45.4,414.2,45.1z"/>
			<path fill="#F5EBD4" d="M438.8,146.6c-0.5,0.2-1.2,0-1.6,0.5c0,0.4,0,0.7,0,1.1c0.3,0.1,0.5,0.2,0.8,0c0.6,0.2,0.8-0.5,1.3-0.5
				C439.1,147.3,438.9,146.9,438.8,146.6z"/>
			<path fill="#E7CE8B" d="M413.3,115.9c0.5,0,1.2,0.4,1.3-0.5c-0.9-0.5-1.6-1.3-2.7-1.4c0,0,0,0,0,0c-0.1,0.2-0.3,0.3-0.4,0.5
				c0,0.2,0,0.4,0.1,0.6c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0.1,0.2,0.2,0.3C412.4,116.2,412.9,115.6,413.3,115.9z"/>
			<path fill="#E6CD89" d="M441.3,158.2c0-0.4-0.1-0.8-0.1-1.2c-0.2-0.2,0.1-0.7-0.4-0.8c-0.4-0.1-0.6,0.2-0.7,0.5
				c-0.2,0.5-0.7,0.5-1.1,0.6c-0.2,0.1-0.3,0.3-0.3,0.6c0.2,0.3,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0c0.1,0.1,0.2,0.2,0.3,0.3
				c0.1,0.1,0.2,0.2,0.3,0.3C440.8,158.9,441.1,158.6,441.3,158.2z"/>
			<path fill="#524D47" d="M412,12.7c-0.6-0.2-1,0.1-0.9,0.7c0,0.6,0.5,1.1,1,1.2c0.6,0.1,0.7-0.5,0.8-0.9c0,0,0,0,0,0
				c-0.3-0.2-0.5-0.3-0.5-0.7C412.3,12.9,412.1,12.8,412,12.7z"/>
			<path fill="#F5ECD5" d="M439.1,157.4c0.5-0.1,1-0.3,1.3-0.7c0.4-0.6,0.6-0.1,0.8,0.2c0-0.8-0.1-1.5-0.5-2.2c0,0.2,0,0.3,0,0.5
				c-0.7,0.4-0.7,1.6-1.7,1.7c-0.2,0.1-0.3,0.2-0.3,0.3C438.8,157.4,438.9,157.5,439.1,157.4z"/>
			<path fill="#58524B" d="M418.9,34.3c-0.2,0-0.3,0-0.5,0c-0.2,0.2-0.1,0.5-0.2,0.7c-0.1,0.3-0.2,0.6-0.5,0.8
				c-0.2,0.2-0.1,0.5,0,0.7c0.2,0.2,0.4,0.2,0.7,0.2c0.5,0,0.5-0.4,0.7-0.7C419.2,35.4,419.4,34.8,418.9,34.3z"/>
			<path fill="#F6EFDC" d="M441.9,192.7c-0.2,1.1-0.7,2.2-1.1,3.2c0.5,0.2,0.6-0.3,0.9-0.3C442.2,194.7,441.9,193.7,441.9,192.7z"/>
			<path fill="#665F57" d="M415.3,20.1c0,0.3,0,0.6,0,1c-0.2,0.2-0.5-0.1-0.7,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.4-0.4,0.7-0.2,1.1
				c0.1,0.2,0.3,0.3,0.6,0.3c0.4-0.1,0.5-0.4,0.8-0.6C415.5,21.6,415.7,20.8,415.3,20.1z"/>
			<path fill="#57514A" d="M414.3,25.9c0.1,0.2,0.2,0.5,0,0.7c0,0.4,0.2,0.4,0.5,0.2l0,0c0.2-0.1,0.3-0.3,0.4-0.5c0-0.4,0-0.8,0-1.2
				c-0.2-0.2-0.3-0.4-0.5-0.4c-0.3,0-0.5,0.2-0.6,0.5C414.2,25.5,414.3,25.7,414.3,25.9z"/>
			<path fill="#EDDCAE" d="M441.3,158.2c-0.4-0.1-0.6,0.5-1.1,0.2c-0.3-0.1-0.4,0-0.5,0.3c0.5,0.4,1.5,0.2,1.7,1.2
				C441.4,159.3,441.4,158.7,441.3,158.2z"/>
			<path fill="#625C54" d="M416,43.4c-0.1,0-0.1,0-0.2,0c-0.4,0.3-0.5,0.7-0.4,1.2c0.1,0.2,0.2,0.3,0.4,0.3c0.6-0.3,0.9-0.9,1.2-1.4
				c0,0-0.1-0.1-0.1-0.1C416.6,43.2,416.3,43.2,416,43.4z"/>
			<path fill="#EBD7A2" d="M434.7,137.3c-0.1,0.3-0.2,0.6-0.2,1l0,0c0.4,0,0.8,0,1.2,0.3C435.5,138,435.6,137.2,434.7,137.3z"/>
			<path fill="#F7F0DF" d="M441.2,206.1c-0.4-0.1-0.8-0.2-0.9,0.3c-0.1,0.6,0.4,0.7,0.9,0.7C441.2,206.8,441.4,206.5,441.2,206.1z"
				/>
			<path fill="#625B52" d="M412.4,13c-0.2,0.3-0.9,0.1-0.7,0.6c0.3,0.7,0.8,0.2,1.2,0.1C412.8,13.4,412.7,13.2,412.4,13z"/>
			<path fill="#58534D" d="M405.2,63.8c0.1-0.3,0.2-0.6,0.3-0.9c-0.4,0.3-1.3,0.3-0.7,1.2C404.9,64,405.1,63.9,405.2,63.8z"/>
			<path fill="#3F3E3B" d="M416.5,29c-0.3,0.3-0.3,0.7-0.3,1.1c0,0.6,0.3,0.2,0.5,0.1c0.2-0.1,0.1-0.3,0.2-0.5
				C416.9,29.5,416.8,29.2,416.5,29z"/>
			<path fill="#080807" d="M411,50.1c0.2,0.2,0.5,0.3,0.7,0.5c0-0.2,0.1-0.4,0.1-0.7c-0.2-0.3-0.3-0.7-0.5-1
				c-0.5-0.1-0.4,0.4-0.5,0.7C410.7,49.8,410.8,50,411,50.1z"/>
			<path fill="#151515" d="M413.2,47.1c-0.3-0.2-0.6-0.5-0.9-0.7c0,0.5-0.2,1.1,0.4,1.4C412.9,47.6,413,47.3,413.2,47.1z"/>
			<path fill="#393734" d="M410.5,56.6c0.2,0.2,0.5,0.3,0.8,0.3c0.2-0.3,0.4-0.6,0.4-1c-0.2-0.1-0.4-0.2-0.7-0.3
				C410.5,55.8,410.6,56.3,410.5,56.6z"/>
			<path fill="#454340" d="M415.8,44.9c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.4-0.1-0.5,0c-0.2,0.1-0.5,0.3-0.7,0.4
				c0,0.3,0.3,0.3,0.5,0.4c0,0,0,0.1,0.1,0.1C415.3,45.5,415.5,45.2,415.8,44.9z"/>
			<path fill="#EFDFB7" d="M441.5,170.9c0.2,0.1,0.5,0.2,0.7,0.3c0-0.4,0-0.8,0-1.2c-0.3,0.2-0.6,0.3-0.9,0.5
				C441.2,170.6,441.3,170.7,441.5,170.9z"/>
			<path fill="#423F3B" d="M414.1,15.1c-0.3,0.2-0.8,0-1,0.6c0.4-0.3,0.8,0.7,1.3-0.1C414.4,15.4,414.3,15.2,414.1,15.1z"/>
			<path fill="#020302" d="M419.2,38.5c-0.1-0.3-0.3-0.3-0.6-0.2c-0.3,0.2-0.2,0.4-0.1,0.6c0.2,0.4,0.4,0.1,0.6,0
				C419.1,38.7,419.2,38.6,419.2,38.5z"/>
			<path fill="#31302D" d="M417.5,41.8c0.2,0.1,0.4,0.2,0.5,0.3c0.2-0.1,0.2-0.3,0.2-0.5c-0.3-0.1,0-0.6-0.5-0.6
				C417.7,41.2,417.6,41.5,417.5,41.8z"/>
			<path fill="#161615" d="M390.6,73.9c0-0.4-0.4-0.8-0.7-0.8c-0.4,0-0.1,0.5-0.3,0.7C390,73.9,390.3,73.9,390.6,73.9z"/>
			<path fill="#EFDFB6" d="M408.1,113.5c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.2,0.3-0.5,0.5-0.8c-0.1-0.3-0.4-0.5-0.7-0.5
				c-0.1,0.2-0.1,0.3-0.2,0.5C407.8,112.9,407.9,113.2,408.1,113.5z"/>
			<path fill="#48443F" d="M414.8,24.9c0.2,0.1,0.3,0.2,0.5,0.3c0-0.2,0-0.5,0-0.7c-0.3-0.2,0.2-0.9-0.5-1c0,0.3,0,0.5-0.3,0.7
				C414.4,24.5,414.5,24.7,414.8,24.9z"/>
			<path fill="#FBF9F4" d="M442.2,182.6c-0.4,0.2-1,0-1.3,0.5c0.1,0.1,0.3,0.1,0.4,0c0.3,0.4,0.6,0.3,0.9-0.1
				C442.2,183,442.2,182.8,442.2,182.6z"/>
			<path fill="#F5ECD5" d="M441.9,179.8c-0.8-0.5-0.8-0.5-1.1,0.4c0.4,0,0.5-0.5,0.9-0.5C441.8,179.8,441.9,179.8,441.9,179.8z"/>
			<path fill="#312F2D" d="M419.1,36c-0.2,0.2-0.5,0.3-0.7,0.5c-0.2,0.1-0.4,0.3-0.2,0.6c0.4-0.1,0.8-0.1,1-0.6
				C419.2,36.3,419.2,36.1,419.1,36z"/>
			<path fill="#F2E6C8" d="M442.2,172.8c-0.3,0.1-0.7,0.1-1.1,0.2c0.3,0.4,0.7,0.5,1.1,0.4C442.1,173.2,442.2,173,442.2,172.8z"/>
			<path fill="#46433F" d="M401.1,8.6c0.1,0.1,0.1,0.3,0.2,0.4c0.2,0.2,0,0.8,0.5,0.5c0.5-0.4-0.2-0.5-0.2-0.7
				C401.5,8.6,401.3,8.6,401.1,8.6z"/>
			<path fill="#F4EAD0" d="M441.2,209c-0.4,0.2-0.5-0.4-1-0.5c0,0.6,0.3,0.8,0.8,1C441,209.3,441.1,209.2,441.2,209z"/>
			<path fill="#F3E8CB" d="M440,151c-1.1,0.6-0.2,0.7,0.2,1C440.3,151.6,440.3,151.2,440,151z"/>
			<path d="M410.9,58.1c-0.3,0.1-0.6-0.5-1-0.1c-0.1,0.1-0.1,0.2,0,0.3c0.2,0.1,0.5,0.2,0.7,0.3C411,58.5,411,58.3,410.9,58.1z"/>
			<path fill="#43403B" d="M415.5,22.3c-0.4-0.1-0.7,0-0.7,0.5c0,0.2,0.1,0.3,0.2,0.4c0.1,0,0.2-0.1,0.3-0.1
				C415.7,22.9,415.5,22.6,415.5,22.3z"/>
			<path fill="#F5EDD7" d="M408.8,112.5c-0.4,0.2-0.5,0.4-0.5,0.8c0.4-0.1,0.8-0.1,0.9-0.6C409.1,112.7,409,112.6,408.8,112.5z"/>
			<path fill="#10100F" d="M415.3,23c-0.1,0-0.3,0.1-0.4,0.1l0,0c0,0.1,0,0.2-0.1,0.3c0.4,0.2,0.1,0.8,0.5,1
				C415.3,24,415.3,23.5,415.3,23z"/>
			<path fill="#0C0B0B" d="M415.3,19.7c-0.2-0.1-0.6-0.5-0.7-0.1c-0.1,0.4,0.4,0.4,0.7,0.5C415.3,20,415.3,19.8,415.3,19.7z"/>
			<path fill="#2C2B2A" d="M412.2,48.7c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.3-0.2,0.6,0,0.8C412.3,49.3,412.1,49,412.2,48.7z"/>
			<path fill="#3C3A38" d="M416.9,29.8c-0.1,0.2-0.1,0.3-0.2,0.5c0.2,0.3,0.4,0.5,0.7,0.4C417.3,30.4,417.1,30.1,416.9,29.8z"/>
			<path fill="#FDFAF2" d="M410,113.5c0,0.2-0.1,0.3-0.1,0.5c0.3-0.1,0.4-0.3,0.5-0.5C410.3,113.5,410.2,113.5,410,113.5z"/>
			<path fill="#42403C" d="M397,4.1c-0.5,0.6,0.1,0.5,0.4,0.7C397.3,4.5,397.2,4.3,397,4.1z"/>
			<path fill="#0D0E0D" d="M415.3,26.4c-0.3,0-0.5,0.2-0.4,0.5c0.2,0.1,0.4,0.2,0.5,0C415.3,26.7,415.3,26.6,415.3,26.4z"/>
			<path fill="#615952" d="M372.9,588.9c-1.9,0.5-3.7,1.4-5.8,1.6c-2.9,0.3-5.6-0.4-8.2-1.4c-0.9-0.3-1.9-0.5-2.6-1.2
				c-2.5,0.5-4.5,1.9-6.6,3.2c-3.1,1.9-6.2,3.9-9.5,5.4c-2.6,1.2-5.4,2.1-8.1,3c-2.3,0.8-4.7,1.4-6.8,2.8c-1.5,1-2.5,2.4-2.6,4.4
				c0,0.8,0.4,1,1,1c3.9,0.1,7.7,0.1,11.6,0.2c2.2,0,4.4,0.3,6.6,0.2c2.8,0,5.6,0.1,8.4-0.9c1.8-0.6,3.3-1.7,5-2.4
				c1.6-0.7,1.7-0.8,2,0.9c0.2,1.6,1.3,1.6,2.4,1.7c2.5,0.1,5,0.1,7.5,0.1c1.9,0,3.7-0.2,5.6,0c1.1,0.1,2.1-0.6,2.3-1.8
				c1.1-5.1,0.7-10.2-0.9-15.2C373.9,589.9,373.5,589.3,372.9,588.9z"/>
			<path fill="#DBB22B" d="M393.5,278.4c1.3,0,2.5-0.7,3.9-0.3c0-4.1-0.2-8.2-0.4-12.3c-0.1-2.3,0-4.7,0.1-7c0-1-0.4-1.4-1.4-1.2
				c-0.3,0-0.5,0.1-0.8,0.1c-1.9,0.1-1.9,0.1-1.8,2c0.2,4.7,0.4,9.5,0.6,14.2C393.6,275.4,393.7,276.9,393.5,278.4z"/>
			<path fill="#DBB22B" d="M406.8,277.5c0.4-0.1,0.9-0.1,1.3-0.2c0.7-0.1,0.9-0.4,0.8-1.2c-0.2-4.9-1.3-9.8-1.4-14.7
				c0-1.1-0.2-2.1-0.4-3.2c-0.3-1.5-1.5-1-2.3-1c-0.8,0-1.4,0.5-1.3,1.5c0.2,2.5,0.4,5,0.6,7.5c0.3,3.1,0.6,6.3,0.8,9.4
				C405.1,277.4,405,277.4,406.8,277.5z"/>
			<path fill="#DBB22B" d="M423.7,294c1,0.2,1.9-0.2,2.9-0.2c-0.1-4.9-0.8-9.8-0.7-14.8c0-0.9-0.4-1.1-1.2-0.9
				c-2,0.3-2.1,0.3-1.9,2.4c0.3,3.7,0.5,7.3,0.7,11C423.5,292.2,423.6,293.1,423.7,294z"/>
			<path fill="#DBB22B" d="M432.6,292.6c1.1,0.6,2.1-0.2,3.1-0.3c-0.1-2.2-0.4-4.3-0.8-6.4c-0.4-2.3-0.5-4.6-0.8-6.9
				c-0.1-0.7,0-1.6-1.2-1.6c-1.6-0.1-2,0.5-1.8,2.2c0.5,3.9,0.9,7.7,1.4,11.6C432.6,291.7,432.6,292.1,432.6,292.6z"/>
			<path fill="#E5CC85" d="M412.2,114.7c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.2-0.3,0-0.5,0c-0.2,0.1-0.5,0.1-0.7,0.3
				c-0.2,0.2-0.6,0.2-0.7,0.5c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0.2,0,0.4,0.1,0.6c0.1,0.2,0.3,0.2,0.5,0.4c0.1,0.1,0.1,0.2,0.1,0.3
				c0,0.2-0.1,0.3-0.1,0.5c0,0.1,0,0.2,0,0.3c0.1,0.2,0.1,0.4,0.2,0.7c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.1,0.4,0.1,0.6,0
				c0.2,0.1,0.3,0.2,0.5,0.2c0.1,0.1,0.2,0.1,0.2,0c0-0.2,0-0.5,0-0.7c0.1-0.3,0.1-0.7,0.5-0.9c0.3-0.2,0.4-0.5,0.2-0.8
				c-0.2-0.1-0.3-0.2-0.5-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.3-0.4-0.6-0.1-0.9l0,0C412.3,115.2,412.4,115,412.2,114.7z"/>
			<path fill="#E5CC85" d="M409.5,116.1c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1,0.2-0.2,0.3-0.2,0.5c-0.4,0-0.6,0.3-0.9,0.5
				c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.4,0.5,0,0.7c-0.2-0.1-0.4-0.2-0.7-0.2c-0.6-0.1-0.2,0.5-0.4,0.7c0.5,0.6,1.4,0.5,1.9,1
				c0.2,0,0.3,0,0.5-0.1c0.2-0.2,0.4-0.3,0.5-0.6c0.1-0.3-0.2-0.6-0.2-0.9c0-0.1,0-0.2,0-0.3C409.9,116.8,409.9,116.4,409.5,116.1z"
				/>
			<path fill="#E8D193" d="M434.7,146.2c-0.5,0.3-0.8-0.1-1.2-0.2c-0.3,0-0.3,0.4-0.5,0.5c-0.3,0.5-0.3,1.1,0.2,1.5
				c0.2,0.2,0.5,0.3,0.5,0c0.1-1.3,0.9-0.9,1.6-0.7c0.1,0,0.2-0.1,0.3-0.1c0-0.7-0.8-0.3-1-0.7C434.8,146.4,434.8,146.3,434.7,146.2
				C434.8,146.2,434.8,146.2,434.7,146.2z"/>
			<path fill="#F9F4E8" d="M417.9,120.8c-1,0-1.4,0.7-1.4,1.6c0.1,0.3,0.2,0.6,0.5,0.7c0.2-0.5,0.7-1,1-1.2c1-0.8,0.2-1.3,0-2
				C417.7,120.2,417.8,120.5,417.9,120.8z"/>
			<path fill="#E8D193" d="M419.1,122.6c-0.8,0-1.5,0.1-0.9,1.2c0.4,0.2,0.3,0.7,0.5,1c0.4,0,0.8-0.1,1.2-0.3
				c0.2-0.3,0.5-0.5,0.2-0.9c-0.2-0.2-0.5-0.3-0.7-0.5C419.2,123,419.3,122.7,419.1,122.6z"/>
			<path fill="#F0E2BE" d="M411.3,121.1c-0.5,0.2-0.8,0.5-0.7,1c-0.4,0-0.2-0.6-0.6-0.5c-0.2,0.1-0.4,0.3-0.5,0.4
				c-0.1,0.3,0.2,0.5,0.4,0.6c0.4,0,0.5,0.8,0.8,0.4C411,122.5,411.6,122,411.3,121.1z"/>
			<path fill="#F6EEDA" d="M412.7,116.9c-0.1,0.2-0.3,0.3-0.4,0.5c-0.3-0.4-0.5-0.4-0.6,0.2c-0.1,0.5-0.2,0.8,0.3,1
				c0.6-0.1,1.1-0.4,1.3-1.1C413.3,116.9,413.1,116.9,412.7,116.9z"/>
			<path fill="#E8D193" d="M438,148.2c-0.3,0-0.5,0-0.8,0c0.3,0.5,1.5,0.9,0.3,1.6c0.2,0.2,0.4,0.1,0.6,0.2c0.3-0.2,1,0,1-0.4
				C439.1,149,438.5,148.5,438,148.2z"/>
			<path fill="#E9D397" d="M430.9,162.5c0.3,0.2,0.6,0.2,0.7-0.1c0.3-0.7,0.8-0.4,1.3,0c-0.6-1.1-1.5-1.3-2.5-0.6
				C430.6,162,431.1,162,430.9,162.5z"/>
			<path fill="#E7CF8E" d="M434.8,195c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.2,0.2c0.4,0.1,0.9,0.3,1-0.4c0.1-0.5-0.5-1.4-1-1.5
				c0,0-0.1,0.1-0.1,0.2C434.8,194.5,435.1,194.8,434.8,195z"/>
			<path fill="#F9F5EA" d="M416.3,123.1c-0.6-0.1-1.1,0.3-1.7,0c-0.3,0-0.7,0-0.9,0.3c-0.1,0.1-0.1,0.3-0.1,0.5
				c1,0.5,1.9,0.2,2.7-0.5C416.3,123.3,416.4,123.3,416.3,123.1C416.3,123.2,416.3,123.1,416.3,123.1z"/>
			<path fill="#E6CE8C" d="M433.3,138.7c-0.1,0.3-0.8,0.5-0.2,1c0.6,0.5,0.6-0.3,0.8-0.5c0.3-0.3,0.5-0.6,0.7-1c0,0,0,0,0,0
				c-0.4-0.1-0.8-0.1-1.3-0.2C433.1,138.3,433.6,138.5,433.3,138.7z"/>
			<path fill="#F7F0DF" d="M420.9,128c0.1,0.4,0,0.9,0.6,0.8c0.3,0,0.5-0.3,0.4-0.6c-0.1-0.5-0.3-0.9-0.8-0.9
				C420.7,127.3,420.9,127.8,420.9,128z"/>
			<path fill="#EAD59E" d="M408.1,131.3c0.4,0.2,0.6,1.1,1.2,0.3c0.2-0.2,0.4-0.8,0-0.9C408.7,130.4,408.5,131,408.1,131.3
				L408.1,131.3z"/>
			<path fill="#EFE0B9" d="M436.6,161.5c-0.2,0-0.4,0-0.8,0c0.7,0.4,1.2,0.9,1.7,0.6c0.3-0.2,0.4-0.8-0.1-1.1
				C437,161,436.9,161.3,436.6,161.5z"/>
			<path fill="#FBF9F2" d="M411.9,119.3c-0.1,0-0.2,0-0.2,0c0.3,0.4,0.1,1.2,1,1.1c0.3,0,0.2,0.5,0.3,0.8c0.3,0.2,0.5,0.2,0.8,0l0,0
				c0-0.2,0-0.4-0.3-0.3C413.2,120.2,412.8,119.6,411.9,119.3z"/>
			<path fill="#F1E4C2" d="M412.8,124.5c-0.6-0.3-0.9,0-1.2,0.2c-0.3,0.2-0.5,0.4-0.4,0.8c0,0.1,0.1,0.3,0.2,0.3
				c0.4,0.1,0.5-0.3,0.6-0.6C412,124.9,412.4,124.8,412.8,124.5z"/>
			<path fill="#F8F1E1" d="M420.9,121.7c-0.4-0.3-0.4-1-1.1-0.7c-0.3,0.1-0.5,0.4-0.3,0.7C419.9,122.2,420.4,122.1,420.9,121.7z"/>
			<path fill="#F5EDD8" d="M440.2,171.4c-0.5-0.6-0.9-0.1-1.3,0.2c-0.2,0.2,0.2,0.7,0.4,0.7c0.5,0.2,0.9-0.1,1-0.7
				C440.2,171.6,440.2,171.6,440.2,171.4C440.2,171.4,440.2,171.4,440.2,171.4z"/>
			<path fill="#F4EBD2" d="M430.2,134.4c-0.5,0-1.3-0.2-1.3,0.3c0,0.8,0.8,0.1,1.2,0.4c0.3,0.2,0.5,0.1,0.6-0.3
				C430.6,134.6,430.4,134.5,430.2,134.4z"/>
			<path fill="#EEDDB2" d="M437.8,152.9c-0.1-0.3-0.1-0.6,0.1-0.9c0.1-0.2,0.3-0.4,0-0.6c-0.1,0-0.3,0.2-0.5,0.3
				c-0.2-0.1-0.3-0.1-0.4,0C437.4,152,436.7,153.1,437.8,152.9z"/>
			<path fill="#ECD9A7" d="M424,131.3c0.8-0.5,1.2,0.5,1.7-0.1c0.1-0.1-0.2-0.7-0.2-0.7C425,130.5,424.5,130.5,424,131.3z"/>
			<path fill="#E8D295" d="M425.4,125.3c-0.5,0.5,0,1.1,0,1.6l0,0c0.5-0.1,1.2,0.1,0.6-0.8c-0.1-0.2,0-0.4-0.1-0.7
				C425.7,125.3,425.6,125.1,425.4,125.3z"/>
			<path fill="#F8F1E2" d="M407.1,132.8c-0.8-0.1-1.3-0.4-1.6-0.9C405.4,133.3,405.7,133.5,407.1,132.8z"/>
			<path fill="#F5ECD6" d="M436.6,161.5c0.3-0.2,0.5-0.3,0.8-0.5c0.3-0.4,0.3-0.8-0.2-1c-0.2-0.1-0.4-0.1-0.5,0.1
				c-0.2,0.2,0.1,0.3,0.2,0.4C437.1,160.8,436.6,161.1,436.6,161.5z"/>
			<path fill="#ECD9A8" d="M431.7,216.7c0.1,0.4,0.3,0.5,0.6,0.6c0.3,0,0.6-0.1,0.5-0.5c-0.1-0.3-0.1-0.7-0.5-0.7
				C432.1,216.1,431.9,216.5,431.7,216.7z"/>
			<path fill="#E8D294" d="M436.4,217.5c0.2,0.5,0.5,0.4,0.8,0.1c0.2-0.2,0.6-0.3,0.4-0.6c-0.2-0.4-0.6-0.2-1-0.2
				C436.4,216.9,436.6,217.3,436.4,217.5z"/>
			<path fill="#F7EFDD" d="M413.6,120.9c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.4,0.5,0.7,0.9,0.3c0.2-0.1,0.5-0.3,0.4-0.5
				c-0.2-0.3-0.6-0.4-0.9-0.3C414,120.8,413.8,120.9,413.6,120.9z"/>
			<path fill="#EBD8A4" d="M404.7,145.7c0.9-0.1,0.6-0.9,0.5-1.3c-0.2-0.5-0.7-0.1-1.1-0.1c0.1,0.6,0.7,0.3,0.9,0.7
				C404.7,145.1,404.9,145.5,404.7,145.7z"/>
			<path fill="#E6CE8C" d="M435,140.4c0-0.3-0.1-0.5-0.4-0.5c-0.3,0-0.6,0-0.6,0.4c0,0.3,0.2,0.6,0.5,0.6
				C434.8,140.9,434.9,140.7,435,140.4z"/>
			<path fill="#E8D295" d="M440,163.2c0-0.1,0-0.2,0.1-0.3c0.3-0.5,0.1-0.8-0.3-1.1c-0.1-0.1-0.3-0.1-0.4,0c-0.2,0.4,0,0.8,0.3,1.1
				C439.8,163,439.9,163.1,440,163.2L440,163.2z"/>
			<path fill="#E9D49A" d="M439.1,195.4c0.4-0.2,0.4-0.6,0.2-1c-0.1-0.1-0.1-0.2-0.3-0.2c-0.3-0.1-0.5-0.5-0.9-0.5
				c-0.2,0.5,0.5,0.6,0.4,1C438.5,195,438.8,195.2,439.1,195.4z"/>
			<path fill="#F1E3C0" d="M396.8,116.9c-0.1-0.3,0.1-0.7-0.2-1c-0.8,1.2-0.8,1.2-0.2,1.4C396.6,117.3,396.7,117.1,396.8,116.9z"/>
			<path fill="#E9D294" d="M430.4,142.3c-0.6-0.4-1.1-0.3-1.4,0.2c-0.3,0.3,0,0.7,0.4,0.5C429.7,142.8,429.9,142.2,430.4,142.3z"/>
			<path fill="#F1E3C0" d="M423.1,124.8c0.7-1.2,0.7-1.3,0.2-2.2C423.3,123.3,423.2,123.9,423.1,124.8z"/>
			<path fill="#F4E9CF" d="M415.8,136.6c-0.2,0.2-1.1,0.2-0.3,0.8c0.2,0.2,0.2,0.7,0.7,0.4c0.5-0.3-0.1-0.5-0.1-0.8
				C416,136.8,415.9,136.7,415.8,136.6L415.8,136.6z"/>
			<path fill="#F9F4E9" d="M420.6,125.9c0,0.4,0.2,0.6,0.5,0.6c0.3,0,0.6-0.1,0.5-0.4c0-0.3-0.3-0.4-0.6-0.5
				C420.8,125.5,420.7,125.7,420.6,125.9z"/>
			<path fill="#F3E8CD" d="M398.6,126.4c0.6,0,1,0.5,1.3-0.1c0.1-0.2,0.1-0.5-0.1-0.5C399.5,125.7,399.1,125.9,398.6,126.4z"/>
			<path fill="#ECDAA9" d="M435.6,247.8c-0.3,0.1-0.3,0.4-0.3,0.6c-0.1,0.4,0.2,0.4,0.5,0.4c0.3,0,0.5-0.1,0.5-0.4
				C436.2,248.1,435.9,248,435.6,247.8z"/>
			<path fill="#F3E7CA" d="M424,127.7c0.1,0.4-0.1,0.7-0.6,0.9c1,0.1,1,0.1,1.5-1.1c-0.2-0.3-0.4-0.3-0.7-0.2
				C424.1,127.3,424,127.5,424,127.7z"/>
			<path fill="#F3E8CB" d="M418.2,136.1c0.1-0.6-0.4-1.2,0.4-1.6c-1.4,0.1-1.1,0.8-0.7,1.6C418,136.2,418.1,136.2,418.2,136.1z"/>
			<path fill="#F9F4E7" d="M420.6,131c-0.2-0.4-0.3-0.6-0.4-0.7c-0.2-0.2,0.1-0.7-0.3-0.8c-0.1,0-0.4,0.1-0.4,0.2
				c-0.1,0.4,0.1,0.7,0.3,0.9C420,130.7,420.2,130.8,420.6,131z"/>
			<path fill="#F2E6C8" d="M436.7,190.8c-0.4,0.5-0.7-0.8-1.1-0.1c-0.1,0.2-0.1,0.4,0.1,0.5c0.5,0.3,1,0.1,1.4-0.2
				c0,0,0.1,0,0.1-0.1C437.1,190.6,437,190.6,436.7,190.8z"/>
			<path fill="#F7F1E0" d="M412.2,115.4c-0.3,0.3-0.8,0.7,0,1c0.4,0.1,0.7-0.1,1-0.5c0.1,0,0.1-0.1,0.2-0.1
				C413.2,115.1,412.4,115.9,412.2,115.4z"/>
			<path fill="#ECD9A7" d="M429,144.8c0,0.8,0.4,1.2,1.2,1.3C430.1,145.3,429.2,145.3,429,144.8z"/>
			<path fill="#F7EFDC" d="M440.9,170.1c0.1-0.3,0.3-0.6-0.1-0.7c-0.6-0.1-0.5,0.6-1,0.8c-0.2,0.1,0.1,0.4,0.4,0.4
				C440.5,170.5,440.8,170.4,440.9,170.1z"/>
			<path fill="#F3E8CD" d="M440.4,168.5c0.3,0,0.5-0.1,0.6-0.4c0-0.2-0.1-0.4-0.3-0.4c-0.3,0-0.6,0.3-0.7,0.5
				C439.9,168.4,440.2,168.4,440.4,168.5z"/>
			<path fill="#F2E5C6" d="M439.1,184.8c-0.8,0.4-1.3,0.8-0.3,1.5c0.4-0.3-0.1-0.7,0.2-1C439.2,185.2,439.2,185,439.1,184.8z"/>
			<path fill="#F9F3E5" d="M429.8,136.4c-0.1,0.5-0.2,1,0.2,1.2c0.2,0.1,0.5-0.1,0.5-0.3C430.7,136.7,430.2,136.7,429.8,136.4z"/>
			<path fill="#E9D49A" d="M438.6,154.3c0.1-0.1,0.2-0.2,0.2-0.3c-0.4-0.3-0.4-1-1-1.2C437.6,153.6,437.7,154.2,438.6,154.3z"/>
			<path fill="#E9D297" d="M436.9,151.7c0.1,0,0.3,0,0.4,0c0-0.2,0-0.4,0-0.7c-0.1-0.1-0.2-0.1-0.3-0.1c-0.5,0.1-1-1-1.4,0
				c0.2,0.1,0.3,0,0.5,0C436.5,151.2,436.7,151.5,436.9,151.7z"/>
			<path fill="#E8D295" d="M428.7,146.7c0.3,0.3,0.4,0.8,0.9,0.7c0.1,0,0.2-0.2,0.2-0.3C429.6,146.7,429.2,146.6,428.7,146.7z"/>
			<path fill="#ECDAA8" d="M432.1,146.1c-0.3-0.3-0.7-0.2-1-0.1c-0.2,0.1-0.4,0.3-0.4,0.6c0,0.1,0.1,0.3,0.1,0.3
				c0.3,0.1,0.4-0.2,0.5-0.4C431.4,146.1,431.9,146.4,432.1,146.1z"/>
			<path fill="#F4EAD1" d="M405.9,129.3c0.6,0.2,0.4,1.3,1.2,1.2c0.1-0.1,0.1-0.1,0.1-0.1c0,0-0.1-0.1-0.1-0.1
				c-0.3-0.5-0.1-1.2-0.7-1.6C406.2,128.9,405.9,128.9,405.9,129.3z"/>
			<path fill="#F9F3E6" d="M434.8,195c1-0.1,0.4-0.5,0.2-0.8c-0.2-0.1-0.4-0.4-0.6-0.1C434.1,194.5,434.7,194.7,434.8,195z"/>
			<path fill="#F0E2BC" d="M423.7,140.6c0.8,0.1,1.5,0.4,1.8-0.6C424.9,140.2,424.4,140.3,423.7,140.6z"/>
			<path fill="#FBF9F4" d="M409.6,119.8c0.2,0.2,0.4,0.6,0.8,0.7c0.3-0.3,0.3-0.6,0.3-1c-0.1-0.2-0.3-0.3-0.5-0.3
				C409.8,119.2,409.7,119.5,409.6,119.8z"/>
			<path fill="#E9D397" d="M431.8,190.7c0.6-0.2,1.1-0.1,1.6-0.5C432.8,190.1,432.2,189.7,431.8,190.7z"/>
			<path fill="#F3E8CC" d="M439.3,165.8c0.3-0.1,0.5-0.2,0.4-0.4c-0.1-0.3-0.4-0.3-0.7-0.3c-0.2,0-0.4,0.1-0.4,0.3
				C438.8,165.6,439.1,165.7,439.3,165.8z"/>
			<path fill="#EBD8A6" d="M431.6,186.5c0.2,0.3,0.4,0.8,0.9,0.5c0.3-0.1,0-0.4-0.1-0.6C432.1,185.8,431.9,186.3,431.6,186.5
				L431.6,186.5z"/>
			<path fill="#ECD9A8" d="M398.9,115c0.7,0.5,1.2,0.3,1.7,0.1C400.1,114.8,399.7,114.7,398.9,115z"/>
			<path fill="#ECDAA9" d="M438.3,159.5c0.1,0.5,0.2,0.8,0.6,1c0.1,0,0.2-0.1,0.2-0.2C439.1,159.8,438.9,159.6,438.3,159.5z"/>
			<path fill="#F0E1BC" d="M395.5,111.4c0.1-0.6,0.3-1-0.1-1.3c-0.1,0-0.3,0-0.3,0C394.8,110.6,395.1,110.9,395.5,111.4z"/>
			<path fill="#EBD8A4" d="M422.1,136.9c0.1-0.5-0.2-1-0.1-1.5C421.6,135.9,421.5,136.5,422.1,136.9z"/>
			<path fill="#E9D499" d="M430.4,200.7c0.1,0.6,0.4,1,0.7,0.9c0.3,0,0.3-0.5,0.3-0.8C431.1,200.7,430.7,201.1,430.4,200.7z"/>
			<path fill="#F0E1BB" d="M412.4,135.8c-0.8-0.2-0.8,0.5-1.1,0.8C412,136.6,412,135.9,412.4,135.8z"/>
			<path fill="#F7F0E0" d="M424.2,127.4c0.2,0,0.5,0,0.7,0c0.1-0.2,0.3-0.3,0.4-0.5c0,0,0,0,0,0c-0.3-0.1-0.7,0-1.1-0.3
				c-0.3-0.2-0.3,0.1-0.3,0.3C424,127.2,424,127.3,424.2,127.4z"/>
			<path fill="#F7F0DF" d="M427.6,134.9c-0.4-0.1-0.6-0.8-1.2-0.6c-0.1,0-0.2,0.2-0.2,0.3C426.6,134.9,427.1,134.9,427.6,134.9z"/>
			<path fill="#EAD59C" d="M407.1,138c-0.3-0.3,0.4-0.9-0.3-1c-0.5-0.1-0.6,0.3-0.4,0.7C406.5,138.3,406.8,138.1,407.1,138z"/>
			<path fill="#ECD8A5" d="M419.6,132.1c0.4,0.2,0.7,0.6,1.1,0.1c0.1-0.1,0.1-0.3,0-0.3C420.3,131.5,420,132.1,419.6,132.1z"/>
			<path fill="#F0E2BE" d="M400.8,122.7c0.4-0.4,0.5-0.8,0.1-1.2c-0.1-0.1-0.2,0-0.3,0C400.6,121.9,400.7,122.3,400.8,122.7z"/>
			<path fill="#ECDAAA" d="M439,203c-0.4-0.8-0.8-0.8-1.2-0.1C438.2,202.8,438.6,202.9,439,203z"/>
			<path fill="#F8F1E0" d="M425.9,142.6c0.7-0.7,0-1-0.5-1.4C425.2,141.8,425.6,142.2,425.9,142.6L425.9,142.6z"/>
			<path fill="#E7CF8E" d="M411.5,145.2c-0.3,0.7,0.3,0.9,0.2,1.4C412,146.1,411.9,145.7,411.5,145.2z"/>
			<path fill="#F5ECD5" d="M439.4,224.3c0.1-0.5,0.2-0.8-0.1-1.1c-0.1-0.1-0.3,0-0.4,0.2C438.8,223.8,439,224,439.4,224.3z"/>
			<path fill="#E8D294" d="M436.6,243.5c-0.7,0-1,0.3-1.4,0.8C435.8,244.2,436.2,244.1,436.6,243.5z"/>
			<path fill="#EFE1BB" d="M439.3,200.6c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0,0.6,0.4,0.5c0.1,0,0.3-0.3,0.3-0.5
				c0.1-0.3-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.1-0.7-0.1C439.2,200.4,439.2,200.5,439.3,200.6C439.3,200.6,439.3,200.6,439.3,200.6z"
				/>
			<path fill="#F6EED9" d="M413.8,118c0.7-0.1,1.1-0.1,1.5-0.3c0.1,0,0-0.3-0.1-0.4C414.6,117.1,414.4,117.5,413.8,118z"/>
			<path fill="#E8D191" d="M413.8,123.6c0.2-0.2,0.5-0.4,0.7-0.5c-0.1-0.1-0.1-0.2-0.2-0.2c-0.3,0.1-0.7-0.2-1,0
				c-0.2,0.1-0.3,0.3-0.5,0.4C413.1,123.8,413.4,123.8,413.8,123.6z"/>
			<path fill="#F9F4E8" d="M427.7,130.1c0.5,0.6,0.7,0.8,1.1,0.6c0.1,0,0.1-0.2,0.1-0.3C428.7,129.9,428.3,130.2,427.7,130.1z"/>
			<path fill="#F3E7CA" d="M416.5,132.5c0.7,0,0.8-0.4,0.8-1.1c-0.5,0.3-0.8,0.7-1.1,1.1C416.3,132.6,416.4,132.6,416.5,132.5z"/>
			<path fill="#F4EAD1" d="M431.8,136.7c0.5-0.1,0.9,0.1,1.2-0.3c0-0.1-0.1-0.3-0.1-0.3C432.5,136,432.1,136.1,431.8,136.7z"/>
			<path fill="#EAD59D" d="M429.9,140.1c0.1,0.7,0.7,0.8,1.5,1.1C430.9,140.5,430.5,140.3,429.9,140.1L429.9,140.1z"/>
			<path fill="#F4E9CE" d="M420.9,140.8c0.5,0.4,1-0.1,1.4,0.3C422,140.6,421.6,140.3,420.9,140.8z"/>
			<path fill="#F3E8CC" d="M437.1,151c0.1,0,0.2,0,0.3,0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.1-0.3-0.1-0.4-0.2-0.5
				c0.1-0.1,0.1-0.2,0.2-0.3c-0.2-0.2-0.4-0.2-0.7-0.2C437.7,150.3,437.1,150.5,437.1,151z"/>
			<path fill="#F4E9CF" d="M429,167.7c-0.3,0-0.4,0.1-0.4,0.3c0,0.2,0,0.5,0.3,0.5c0.2,0,0.5,0,0.5-0.3
				C429.3,168,429.1,167.8,429,167.7z"/>
			<path fill="#E6CE8A" d="M401.6,133.3c-0.3,0.2-0.7,0.4-0.5,0.8c0,0.1,0.2,0.2,0.3,0.1C401.8,134.1,401.8,133.8,401.6,133.3z"/>
			<path fill="#F2E6C9" d="M410.3,131.9c0.7-0.1,0.7-0.6,0.7-1.1C410.6,131.1,410.3,131.3,410.3,131.9z"/>
			<path fill="#EAD59B" d="M396,129.9c0,0.5-0.1,0.8,0.3,0.9c0.1,0,0.2-0.1,0.3-0.2C396.8,130.1,396.4,130.2,396,129.9z"/>
			<path fill="#E9D397" d="M412.7,130.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.3-0.2-0.3-0.5-0.3c-0.2,0-0.3,0.1-0.3,0.3
				C412.3,130.1,412.4,130.3,412.7,130.4z"/>
			<path fill="#EFDFB5" d="M438.6,188.9c-0.1,0.3-0.5,0.5-0.1,0.8c0.4,0.2,0.4-0.2,0.5-0.4c0.2-0.1,0.4-0.3,0.5-0.4
				C439.2,188.6,438.9,188.7,438.6,188.9z"/>
			<path fill="#F9F3E6" d="M441.2,190.4c-0.2,0-0.4,0-0.3,0.2c0.1,0.3,0.2,0.5,0.5,0.5c0.2,0,0.3-0.1,0.3-0.3
				C441.7,190.5,441.4,190.5,441.2,190.4z"/>
			<path fill="#F9F4E9" d="M436.2,158.4c0.2-0.1,0.5-0.2,0.5-0.5c-0.1-0.2-0.4-0.1-0.5-0.3c-0.9,0-0.4,0.5-0.3,0.8
				C436,158.5,436.1,158.5,436.2,158.4z"/>
			<path fill="#EBD7A1" d="M428.3,159.9c0.4,0.2,0.5,0.3,0.6,0.3c0.3,0,0.5-0.2,0.4-0.5c0-0.1-0.2-0.3-0.3-0.3
				C428.7,159.4,428.9,159.9,428.3,159.9z"/>
			<path fill="#F2E6C8" d="M410.7,115.2c0.2-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.1-0.1
				c-0.4-0.3-0.8-0.5-0.9,0.3C410.4,114.9,410.6,115.1,410.7,115.2z"/>
			<path fill="#E4CA82" d="M409.6,119.8c0.2-0.1,0.4-0.2,0.6-0.4c0.2,0,0.4-0.2,0.3-0.4c-0.1-0.3-0.3-0.3-0.6-0.4
				c-0.2,0-0.5,0.1-0.7,0.1c-0.1,0.1-0.1,0.2-0.2,0.2C409.2,119.3,409.4,119.6,409.6,119.8z"/>
			<path fill="#F6EFDD" d="M414,119c-0.2,0.5-0.4,0.7-0.3,1.2C414.4,119.9,414.6,119.7,414,119z"/>
			<path fill="#FCFAF6" d="M421.4,123c0,0.2,0.2,0.3,0.4,0.3c0.2,0,0.4-0.1,0.4-0.3c0-0.2-0.1-0.4-0.3-0.4
				C421.7,122.7,421.5,122.8,421.4,123z"/>
			<path fill="#EBD69F" d="M432.8,158.3c0.3,0.2,0.6,0.5,0.9,0.3c0.1-0.1,0.1-0.4,0-0.4C433.4,158,433.2,158.4,432.8,158.3z"/>
			<path fill="#EBD7A1" d="M414.9,140.7c-0.3,0.3-0.4,0.7,0,0.9c0.4,0.2,0.5-0.2,0.7-0.4C415.4,140.9,415.1,140.8,414.9,140.7z"/>
			<path fill="#E8D194" d="M432.7,153.5c0.2,0,0.3-0.1,0.3-0.3c0-0.2,0-0.3-0.2-0.3c-0.2,0-0.3,0.1-0.3,0.3
				C432.4,153.3,432.5,153.4,432.7,153.5z"/>
			<path fill="#E9D296" d="M430.8,152.4c-0.4,0.5-0.4,0.8-0.2,1.4C430.9,153.3,430.9,152.9,430.8,152.4z"/>
			<path fill="#F9F3E6" d="M405.9,117.1c-0.4,0.3-0.6,0.6-0.9,0.8C405.8,118,405.8,118,405.9,117.1z"/>
			<path fill="#EAD69F" d="M432.7,193.4c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.4-0.1,0.4-0.3c0-0.1-0.2-0.3-0.3-0.3
				C432.8,193.1,432.7,193.2,432.7,193.4z"/>
			<path fill="#F1E3C0" d="M434.5,203.6c0.3,0.5,0.6,0.4,1,0.2C435.3,203.4,434.9,203.5,434.5,203.6z"/>
			<path fill="#EDDBAB" d="M424.2,134.9c-0.3,0.1-0.4,0.3-0.3,0.5c0.1,0.4,0.5,0.2,0.7,0.2c0.2,0,0.1-0.3,0.1-0.5
				C424.6,134.9,424.5,134.7,424.2,134.9z"/>
			<path fill="#F2E6C7" d="M414.5,126.4c0.9-0.6,0.9-0.6,0.7-1.5C415,125.4,414.8,125.7,414.5,126.4z"/>
			<path fill="#E8D090" d="M440.8,175.2c-0.4,0.1-0.5,0.4-0.4,0.7c0,0.1,0.2,0.1,0.3,0.1C441,175.8,440.9,175.5,440.8,175.2z"/>
			<path fill="#EAD49B" d="M405.8,126.3c0.3,0,0.4-0.1,0.4-0.3c0-0.2-0.1-0.4-0.3-0.4c-0.2,0-0.4,0-0.4,0.3
				C405.5,126.1,405.7,126.2,405.8,126.3z"/>
			<path fill="#E7CF8D" d="M401.2,127.4c0.5-0.3,0.5-0.6,0.3-1C401.1,126.6,401.1,126.9,401.2,127.4z"/>
			<path fill="#E7D090" d="M400.9,129.1c0,0.3,0,0.5,0.3,0.8c0.2-0.4,0.3-0.7,0-1.1C401.1,128.9,401,129,400.9,129.1L400.9,129.1z"
				/>
			<path fill="#EDDBAC" d="M403.8,112.1c0.2,0.3-0.3,0.6,0.1,0.8c0.1,0,0.3,0,0.3-0.1c0.4-0.4-0.1-0.7-0.2-1.1
				C403.8,111.8,403.6,111.8,403.8,112.1z"/>
			<path fill="#FCFBF6" d="M433.1,254.4c0,0.4,0.1,0.5,0.3,0.5c0.2,0,0.4-0.1,0.4-0.3c0-0.3-0.2-0.4-0.5-0.4
				C433.1,254.1,433.1,254.3,433.1,254.4z"/>
			<path fill="#F1E4C3" d="M389.7,117.2c0.5,0,0.7-0.2,0.7-0.6c0,0-0.1-0.1-0.2-0.1C389.9,116.5,389.8,116.8,389.7,117.2z"/>
			<path fill="#F6EFDC" d="M404.4,123.8c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.1,0.1,0.3,0.3,0.3c0.2,0,0.5-0.1,0.5-0.4
				C404.8,123.9,404.7,123.8,404.4,123.8z"/>
			<path fill="#F5EDD8" d="M418.7,124.8c0.3-0.6,0.3-1-0.5-1C418.4,124.2,418.2,124.7,418.7,124.8z"/>
			<path fill="#F3E8CD" d="M410,137.9c-0.1,0.7-0.3,1,0.1,1.3c0.1,0.1,0.3,0,0.4,0C410.4,138.8,410.3,138.5,410,137.9z"/>
			<path fill="#F2E6C9" d="M406.4,115c-0.1,0.2-0.2,0.3-0.1,0.5c0.1,0.2,0.2,0.4,0.5,0.5c0.1,0,0.2-0.1,0.2-0.1
				c0.1-0.3-0.1-0.4-0.2-0.6C406.7,115.1,406.5,115.1,406.4,115L406.4,115z"/>
			<path fill="#FBF8F1" d="M421.3,161.9c0,0.3,0.2,0.3,0.3,0.3c0.3,0,0.4-0.2,0.4-0.5c0-0.2-0.1-0.3-0.3-0.3
				C421.5,161.6,421.4,161.8,421.3,161.9z"/>
			<path fill="#ECD8A5" d="M427.3,158.8c0.1-0.3,0.2-0.4,0.2-0.6c0-0.2,0-0.4-0.2-0.5c-0.2-0.1-0.3,0-0.3,0.2
				C427,158.1,427.1,158.3,427.3,158.8z"/>
			<path fill="#E9D296" d="M402.5,163c0.2,0,0.3-0.2,0.4-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.1-0.4,0.1-0.4,0.3
				C402.3,162.8,402.4,163,402.5,163z"/>
			<path fill="#F3E8CC" d="M405.5,162.5c-0.2,0-0.3,0-0.3,0.2c0,0.2,0.1,0.4,0.4,0.4c0.2,0,0.4-0.2,0.3-0.4
				C405.9,162.5,405.7,162.5,405.5,162.5z"/>
			<path fill="#F6EFDC" d="M435.4,162c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.4-0.4-0.6-0.1c-0.2,0.3,0.1,0.4,0.3,0.5
				C435.1,161.9,435.3,161.9,435.4,162C435.4,162,435.4,162,435.4,162z"/>
			<path fill="#EEDDB2" d="M439.7,158.7c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.1-0.2,0.2-0.3c-0.1-0.3-0.4-0.4-0.7-0.4
				c-0.1,0-0.2,0-0.3,0c-0.2,0.2-0.2,0.5,0,0.7C439.6,158.5,439.7,158.6,439.7,158.7z"/>
			<path fill="#EBD7A2" d="M439.1,157.4c0-0.2,0-0.3,0-0.5c-0.6,0.1-1.2,0.2-0.5,1c0.1,0.1,0.2,0.1,0.2,0
				C438.9,157.8,439,157.6,439.1,157.4z"/>
			<path fill="#F4EAD1" d="M433.3,138.7c0.1,0.1,0.2,0.2,0.2,0.1c0.1-0.3-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.3-0.4-0.4
				C432.8,138.1,433.2,138.3,433.3,138.7z"/>
			<path fill="#F2E7CB" d="M423.2,133c0.2,0,0.2-0.2,0.2-0.3c0-0.2-0.1-0.4-0.4-0.3c-0.2,0-0.3,0.2-0.2,0.3
				C422.8,132.8,423,132.9,423.2,133z"/>
			<path fill="#F2E7C9" d="M416.5,131c-0.7-0.3-0.9,0.1-1.3,0.2C415.8,131.6,416,131.2,416.5,131z"/>
			<path fill="#F6EDD8" d="M418.8,130.9c-0.5,0.1-0.8,0.3-0.8,0.8C418.6,131.6,418.8,131.4,418.8,130.9z"/>
			<path fill="#FCF9F2" d="M416,128.9c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-0.1-0.4-0.2-0.5-0.1c-0.1,0.1-0.1,0.2,0,0.3
				c0.1,0.2,0.3,0.2,0.5,0.2C415.7,129.1,415.9,129.1,416,128.9L416,128.9z"/>
			<path fill="#F5EDD6" d="M402.2,113.2c0.1,0.2,0.2,0.4,0.4,0.4c0.3,0,0.3-0.2,0.3-0.4c0-0.2-0.2-0.3-0.4-0.3
				C402.3,112.9,402.2,113,402.2,113.2z"/>
			<path fill="#EEDEB3" d="M393.1,124.8c0.1,0.2,0.2,0.3,0.4,0.3c0.2,0,0.4-0.1,0.4-0.4c0-0.2-0.2-0.2-0.3-0.2
				C393.3,124.6,393.2,124.6,393.1,124.8z"/>
			<path fill="#F9F5EA" d="M407,124.5c-0.2,0-0.4,0.1-0.4,0.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.4-0.1,0.4-0.3
				C407.3,124.6,407.1,124.5,407,124.5z"/>
			<path fill="#E7CE8B" d="M413.2,116c-0.3,0.1-0.8-0.1-1,0.5c0,0.1,0,0.1,0,0.2C412.7,116.6,413.1,116.6,413.2,116z"/>
			<path fill="#F5ECD5" d="M413.4,122.9c0.3,0.2,0.7,0.3,1,0C414,122.8,413.7,122.1,413.4,122.9z"/>
			<path fill="#F1E5C4" d="M403.9,121.3c-0.4,0.1-0.6,0.2-0.5,0.6c0,0.2,0.2,0.3,0.3,0.2C404,121.9,404.1,121.6,403.9,121.3z"/>
			<path fill="#EEDEB5" d="M399.4,119.9c0,0.6,0.1,0.8,0.4,0.9c0.1,0,0.2-0.1,0.2-0.2C400,120.4,399.7,120.3,399.4,119.9z"/>
			<path fill="#F1E3C0" d="M400,119.1c-0.3-0.3-0.6-0.3-1.1-0.2C399.3,119.4,399.6,119.2,400,119.1z"/>
			<path fill="#F6EEDA" d="M435.7,151.2c-0.3,0.2-0.7,0-1,0.1c0.2,0.7,0.7,0.4,1.1,0.3C435.8,151.5,435.9,151.3,435.7,151.2z"/>
			<path fill="#F6EEDA" d="M431.8,151.6c0.1,0.2,0.2,0.4,0.4,0.4c0.3,0,0.2-0.3,0.2-0.4c0-0.2,0-0.4-0.3-0.3
				C431.8,151.2,431.7,151.3,431.8,151.6z"/>
			<path fill="#F3E7C8" d="M433.4,149.4c0.2,0,0.3-0.1,0.2-0.3c-0.1-0.2-0.1-0.4-0.4-0.4c-0.2,0-0.3,0.2-0.2,0.3
				C433,149.2,433.2,149.3,433.4,149.4z"/>
			<path fill="#EDDCAD" d="M413.8,147.7c0,0.4,0.2,0.5,0.5,0.7c0,0,0.2-0.1,0.2-0.2C414.4,147.9,414.2,147.8,413.8,147.7z"/>
			<path fill="#EFE0B8" d="M400.3,143.5c0.4,0.1,0.7,0.1,1-0.1c0-0.1,0-0.2-0.1-0.2C400.9,142.9,400.7,143.1,400.3,143.5z"/>
			<path fill="#F2E6C7" d="M420.6,143c0,0.2,0,0.4,0.3,0.5c0.2,0,0.3,0,0.3-0.2c0-0.2-0.1-0.4-0.3-0.5
				C420.7,142.6,420.6,142.8,420.6,143z"/>
			<path fill="#F8F2E4" d="M425.9,142.5c0.3,1.1,0.6,0.1,1,0.1C426.5,142.3,426.2,142.6,425.9,142.5
				C425.9,142.6,425.9,142.5,425.9,142.5z"/>
			<path fill="#F9F4E8" d="M430.2,138.7c0.3-0.2,0.8-0.3,0.7-0.8c0-0.1-0.1-0.1-0.2-0.1C430.4,138,430.3,138.4,430.2,138.7z"/>
			<path fill="#E9D295" d="M437.7,181.6c0,0.2,0.1,0.4,0.3,0.3c0.1-0.1,0.2-0.2,0.2-0.4c0-0.2-0.1-0.3-0.3-0.2
				C437.8,181.4,437.7,181.5,437.7,181.6z"/>
			<path fill="#ECD8A6" d="M431.2,204.4c0.3,0.4,0.5,0.5,0.8,0.3c0,0,0.1-0.2,0.1-0.2C431.9,204.3,431.6,204.3,431.2,204.4z"/>
			<path fill="#EAD69E" d="M436.7,234.5c0.5-0.2,0.7-0.4,0.7-0.8C436.9,233.7,436.9,234,436.7,234.5z"/>
			<path fill="#F1E5C4" d="M427.8,191.2c-0.3-0.4-0.6-0.5-1-0.2C427.1,191.4,427.4,191.3,427.8,191.2z"/>
			<path fill="#F5ECD4" d="M434.3,206.6c-0.2,0.3-0.4,0.6,0,1c0.4-0.3,0.3-0.7,0.3-1C434.4,206.6,434.4,206.6,434.3,206.6z"/>
			<path fill="#F0E2BD" d="M425.5,132.5c-0.4-0.2-0.7-0.3-1-0.2C424.7,132.9,425.1,132.8,425.5,132.5z"/>
			<path fill="#E7CF8D" d="M426.7,148.8c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.1,0.3-0.1,0.3-0.2c0-0.1-0.1-0.3-0.2-0.4
				C426.8,148.4,426.7,148.5,426.7,148.8z"/>
			<path fill="#F7F0E0" d="M428.5,200.4c-0.1-0.2-0.2-0.5-0.5-0.5c-0.1,0-0.1,0.1-0.1,0.2c0,0.4,0.3,0.6,0.6,0.7
				c0.1-0.1,0.1-0.1,0.1-0.2C428.6,200.4,428.5,200.4,428.5,200.4z"/>
			<path fill="#E8D193" d="M424.9,208.9c0,0.4,0.2,0.7,0.4,0.7c0.4-0.1,0.2-0.3,0-0.5C425.3,209,425.1,209,424.9,208.9z"/>
			<path fill="#EEDEB3" d="M413.7,131c0,0.2,0.1,0.3,0.3,0.4c0.2,0.1,0.4,0,0.3-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
				C413.8,130.7,413.7,130.8,413.7,131z"/>
			<path fill="#EBD7A2" d="M436.7,190.8c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0-0.1,0-0.2-0.1-0.3
				C437.4,190.5,437,190.5,436.7,190.8z"/>
			<path fill="#EBD8A3" d="M407.1,130.3c0,0.1,0,0.1,0,0.2c0,0.6,0.4,0.8,1,0.7c0,0,0,0,0,0C407.8,131,407.5,130.6,407.1,130.3z"/>
			<path fill="#E9D398" d="M429.7,196.3c0,0.3-0.3,0.6,0.1,0.8c0.1,0.1,0.4,0,0.4-0.1C430.2,196.7,429.9,196.6,429.7,196.3
				L429.7,196.3z"/>
			<path fill="#ECD9A6" d="M419.7,137.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.2,0.2-0.4c0-0.1-0.1-0.2-0.2-0.2
				C419.8,137,419.7,137.2,419.7,137.3z"/>
			<path fill="#F4EBD2" d="M434.8,201.2c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.3-0.1,0.2-0.3c-0.1-0.2-0.3-0.3-0.5-0.2
				C435,200.9,434.9,201.1,434.8,201.2z"/>
			<path fill="#F4EAD1" d="M428.5,200.4c0,0.1,0,0.2,0,0.3c0.3,0.2,0.6,0.3,0.9,0C429.2,200.3,428.8,200.5,428.5,200.4z"/>
			<path fill="#EEDEB3" d="M411.5,138.5c0.2,0.1,0.4,0.2,0.5,0c0.1-0.1,0-0.2,0-0.3c-0.3-0.3-0.6-0.1-0.8,0
				C411.2,138.4,411.3,138.5,411.5,138.5z"/>
			<path fill="#F9F5E9" d="M402.3,134.2c-0.2,0.1-0.5,0.2-0.4,0.5c0,0.2,0.2,0.2,0.4,0.1c0.2-0.1,0.3-0.2,0.4-0.4
				C402.7,134.3,402.5,134.2,402.3,134.2z"/>
			<path fill="#E9D398" d="M407.1,126.5c0.2-0.1,0.2-0.2,0.2-0.4c-0.1-0.2-0.1-0.4-0.4-0.3c-0.1,0-0.1,0.3-0.1,0.4
				C406.8,126.3,406.9,126.5,407.1,126.5z"/>
			<path fill="#EDDDB0" d="M434.3,187.4c0,0.3-0.1,0.6-0.1,0.9c0.6,0,0.6-0.5,0.6-0.8C434.6,187.3,434.4,187.3,434.3,187.4z"/>
			<path fill="#F4E9CF" d="M438.8,157.9c-0.1,0-0.1,0-0.2,0c0.2,0.5,0.5,0.6,0.9,0.5c0-0.2,0-0.5,0-0.7
				C439.3,157.8,439.1,157.8,438.8,157.9z"/>
			<path fill="#FBF7EF" d="M439.1,184.8c0,0.2,0,0.4-0.1,0.5c0.2,0.2,0.6,0.3,0.7,0C439.8,185,439.4,184.8,439.1,184.8z"/>
			<path fill="#F5ECD6" d="M441.3,183.2c-0.1,0-0.3,0-0.4,0c-0.2,0.3-0.5,0.6-0.4,1.1C440.9,183.9,441.1,183.5,441.3,183.2z"/>
			<path fill="#F5EDD7" d="M407.2,118c0.1-0.2,0.3-0.4,0.4-0.7c0-0.2,0.1-0.4-0.2-0.5c-0.2,0-0.3,0.2-0.3,0.3
				C407.2,117.5,407.2,117.7,407.2,118z"/>
			<path fill="#EAD59D" d="M416.3,123.1c0,0.1,0,0.2,0,0.2c0.2-0.1,0.5-0.2,0.7-0.2c-0.1-0.3-0.2-0.5-0.5-0.7
				C416.4,122.6,416.3,122.9,416.3,123.1z"/>
			<path fill="#F6EEDC" d="M402.8,117.6c0.2,0.1,0.4,0.3,0.6,0.3c0.2,0,0.3-0.2,0.2-0.3c-0.1-0.2-0.3-0.3-0.5-0.3
				C402.9,117.3,402.8,117.4,402.8,117.6z"/>
			<path fill="#F7EFDD" d="M393.8,118.4c0,0.2,0,0.3,0.2,0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.2,0-0.3-0.2-0.3
				C394,118.1,393.8,118.2,393.8,118.4z"/>
			<path fill="#FCF9F4" d="M417.9,120.8c0-0.3,0-0.6,0-0.8c-0.2-0.2-0.5-0.2-0.8,0C417.4,120.2,417.7,120.5,417.9,120.8z"/>
			<path fill="#F6EEDA" d="M439.8,166.8c0.3,0.2,0.6,0,0.5-0.3c-0.1-0.4-0.4-0.5-0.8-0.2C439.4,166.6,439.6,166.7,439.8,166.8z"/>
			<path fill="#EFE0B8" d="M432.9,168.8c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.2
				C432.7,168.6,432.8,168.7,432.9,168.8z"/>
			<path fill="#F2E6C9" d="M409.8,119c0.2,0.1,0.7,0,0.4,0.5c0.2,0,0.3,0.1,0.5,0.1c0.2-0.2,0.3-0.3,0.5-0.5
				c-0.2-0.1-0.3-0.2-0.5-0.3C410.3,118.6,410,118.4,409.8,119z"/>
			<path fill="#EBD7A2" d="M430.7,209.4c0.1,0.2,0.2,0.3,0.3,0.4c0.2,0.1,0.3,0,0.3-0.2c0-0.1-0.2-0.3-0.3-0.4
				C430.9,209.2,430.8,209.3,430.7,209.4z"/>
			<path fill="#F1E3C1" d="M423.9,152.6c0.3-0.5-0.1-0.7-0.2-0.9C423.6,152,423.5,152.3,423.9,152.6z"/>
			<path fill="#EEDDB0" d="M400.8,111.7c0.3,0,0.4-0.2,0.4-0.4c0.1-0.2-0.1-0.3-0.2-0.2c-0.2,0-0.4,0.1-0.4,0.4
				C400.6,111.5,400.8,111.6,400.8,111.7z"/>
			<path fill="#F3E9D0" d="M436,230.2c-0.2,0.5-0.1,0.7,0,1.2C436.2,230.9,436.3,230.6,436,230.2z"/>
			<path fill="#F6EDD8" d="M437.1,154.2c-0.2,0.3-0.6,0.4-0.4,0.8c0,0,0.2,0.1,0.2,0C437.3,154.9,437,154.5,437.1,154.2z"/>
			<path fill="#ECD9A7" d="M416,128.9c0.3-0.1,0.7,0.1,0.7-0.4c0-0.1-0.1-0.2-0.1-0.2C416.2,128.2,416.1,128.6,416,128.9
				C416,128.9,416,128.9,416,128.9z"/>
			<path fill="#EAD49B" d="M398.6,127.8c0.1-0.1,0.2-0.2,0.4-0.3c-0.1-0.1-0.2-0.2-0.2-0.2c-0.2,0-0.4,0.1-0.4,0.3
				C398.3,127.8,398.3,127.9,398.6,127.8z"/>
			<path fill="#FBF8F2" d="M408.7,114.2c-0.2,0.5,0.1,0.7,0.2,1c0.1-0.1,0.2-0.1,0.3-0.2C409.4,114.6,409.1,114.5,408.7,114.2z"/>
			<path fill="#EDDBAD" d="M403.8,137.8c0.2-0.8-0.5-0.3-0.7-0.6C403.1,137.6,403.6,137.5,403.8,137.8L403.8,137.8z"/>
			<path fill="#E8D192" d="M411.7,128.5c-0.2,0-0.3,0.1-0.2,0.2c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2-0.1,0.2-0.2
				C411.9,128.7,411.8,128.6,411.7,128.5z"/>
			<path fill="#F8F1E2" d="M403.8,112.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2,0.1-0.5-0.2-0.6c-0.1,0-0.3,0-0.3,0.1
				C403.4,111.6,403.5,111.9,403.8,112.1z"/>
			<path fill="#EAD59C" d="M413.5,125.4c0.1,0,0.2,0.1,0.2,0.1c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.2-0.2-0.2
				C413.6,125,413.5,125.2,413.5,125.4z"/>
			<path fill="#F3E8CB" d="M419.4,126c-0.3-0.2-0.5-0.5-0.9-0.2C418.8,126.2,419.1,126,419.4,126z"/>
			<path fill="#E8D193" d="M439.7,206.9c0-0.3,0-0.4,0-0.6c0-0.1-0.2-0.2-0.2-0.2c-0.2,0.1-0.1,0.3-0.1,0.4
				C439.5,206.6,439.6,206.7,439.7,206.9z"/>
			<path fill="#EBD6A1" d="M437.8,200.5c-0.2,0.1-0.3,0.2-0.3,0.4c0,0.1,0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.2,0.2-0.4
				C438,200.7,437.8,200.6,437.8,200.5z"/>
			<path fill="#FAF7EF" d="M433,218.4c0-0.2,0.1-0.5-0.2-0.6c-0.3-0.1-0.4,0.2-0.5,0.4C432.4,218.7,432.7,218.6,433,218.4z"/>
			<path fill="#F5ECD6" d="M426.7,210.3c-0.3-0.1-0.6-0.1-0.8,0.1c0,0,0,0.3,0.1,0.3C426.5,210.9,426.5,210.5,426.7,210.3z"/>
			<path fill="#FAF6EB" d="M439.3,200.6c0-0.1,0-0.2,0-0.3c-0.2-0.1-0.2-0.4-0.5-0.4c-0.1,0-0.2,0.2-0.2,0.2
				C438.7,200.7,439,200.7,439.3,200.6z"/>
			<path fill="#ECD9A5" d="M383.7,119.7c0.4,0.1,0.6-0.1,0.8-0.4C384.1,119.2,383.9,119.4,383.7,119.7z"/>
			<path fill="#EAD59D" d="M424.2,134.4c-0.2-0.2-0.3-0.5-0.6-0.3c-0.1,0-0.1,0.2-0.2,0.3c0.2,0.1,0.4,0.2,0.6,0.2
				C424.1,134.6,424.2,134.5,424.2,134.4z"/>
			<path fill="#F9F5EA" d="M411.6,134.4c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.4-0.1,0.4-0.3c0-0.1-0.1-0.2-0.2-0.2
				C411.9,134.1,411.7,134.2,411.6,134.4z"/>
			<path fill="#EAD59C" d="M401.9,119.6c0.1,0,0.1,0.1,0.2,0.1c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.2-0.2-0.2
				C402,119.2,402,119.4,401.9,119.6z"/>
			<path fill="#EFE0B8" d="M414.9,136.4c0.1,0,0.2,0,0.2-0.2c0-0.1-0.1-0.3-0.2-0.4c-0.2-0.1-0.3,0.1-0.3,0.2
				C414.6,136.2,414.7,136.3,414.9,136.4z"/>
			<path fill="#F9F5EC" d="M418.9,133.4c0,0.2,0.2,0.3,0.3,0.2c0.2,0,0.3-0.1,0.2-0.3c0-0.1-0.2-0.2-0.3-0.2
				C419,133,419,133.2,418.9,133.4z"/>
			<path fill="#E8D193" d="M408.7,125.4c0,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.3-0.2-0.4c-0.2-0.1-0.3,0.1-0.3,0.2
				C408.2,125.1,408.3,125.2,408.7,125.4z"/>
			<path fill="#F7F0DE" d="M399.5,123.2c-0.1,0.1-0.3,0.1-0.3,0.2c0,0.2,0.1,0.3,0.3,0.3c0.2,0.1,0.4,0.1,0.3-0.2
				C399.8,123.4,399.6,123.3,399.5,123.2z"/>
			<path fill="#E9D398" d="M434.1,156.6c0-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.3-0.2c-0.1,0-0.2,0.2-0.2,0.2
				C433.8,156.5,433.9,156.6,434.1,156.6z"/>
			<path fill="#F2E6C8" d="M429.9,155.6c0.1,0.5,0.2,0.7,0.5,0.9C430.5,156,430.3,155.8,429.9,155.6z"/>
			<path fill="#EBD7A2" d="M437.2,190c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2
				C437.4,189.8,437.3,189.9,437.2,190z"/>
			<path fill="#F4EBD3" d="M434.1,151.5c0-0.5-0.3-0.6-0.7-0.6C433.4,151.4,433.8,151.3,434.1,151.5z"/>
			<path fill="#EAD59D" d="M415.3,149.9c0.1,0,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.1-0.2-0.3-0.3-0.3
				C415.4,149.6,415.3,149.7,415.3,149.9z"/>
			<path fill="#E7CF8D" d="M404.7,147.8c-0.2,0.4,0,0.6,0.3,0.8C405.1,148.2,405,148,404.7,147.8z"/>
			<path fill="#EDDCAD" d="M436,146.8c0.1,0.2,0.2,0.3,0.3,0.4c0.1-0.1,0.2-0.1,0.2-0.2c0-0.2,0-0.3-0.2-0.3
				C436.3,146.6,436.2,146.7,436,146.8z"/>
			<path fill="#F6EEDB" d="M438.3,191.7c-0.2,0.1-0.4,0.2-0.7,0.3c0.6,0.2,0.6,0.2,0.9-0.3C438.5,191.7,438.4,191.7,438.3,191.7z"/>
			<path fill="#FDFDFB" d="M433.9,167.4c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.3-0.2,0.3-0.3
				C434.2,167.6,434.1,167.5,433.9,167.4z"/>
			<path fill="#EBD69F" d="M426.1,169.7c0.2-0.3,0.2-0.5,0-0.8C425.8,169.2,425.8,169.4,426.1,169.7z"/>
			<path fill="#F1E4C3" d="M437.1,165.5c0.5,0.4,0.7,0.5,1.1,0.4C438,165.5,437.7,165.5,437.1,165.5z"/>
			<path fill="#EDDCAF" d="M432.8,163.3c0,0.2,0,0.3,0.2,0.3c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0-0.2-0.2-0.2
				C433,163.1,432.9,163.2,432.8,163.3z"/>
			<path fill="#EAD69E" d="M435.4,162c0,0.2-0.4,0.3-0.2,0.6c0,0,0.3,0,0.4-0.1C435.9,162.2,435.7,162.1,435.4,162
				C435.4,162,435.4,162,435.4,162z"/>
			<path fill="#EEDDB0" d="M432.5,172.1c-0.4,0.1-0.5,0.4-0.6,0.7C432.4,172.7,432.5,172.5,432.5,172.1z"/>
			<path fill="#E9D295" d="M438.8,182.4c-0.4-0.3-0.8-0.1-1.4,0.2C438.1,182.8,438.5,182.6,438.8,182.4L438.8,182.4z"/>
			<path fill="#F4ECD5" d="M424,159.6c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0,0.2-0.3,0.2-0.4c0-0.1-0.1-0.2-0.1-0.2
				C424,159.2,424,159.4,424,159.6z"/>
			<path fill="#F7F0E0" d="M432,158.9c-0.3,0.3-0.5,0.5-0.4,0.8c0,0,0.2,0.1,0.2,0.1C432,159.5,432.1,159.3,432,158.9z"/>
			<path fill="#E9D294" d="M434.5,158.4c0.1,0.2,0.2,0.3,0.4,0.3c0.1,0,0.1-0.2,0.1-0.3c-0.1-0.1-0.2-0.2-0.4-0.3
				C434.5,158.1,434.5,158.3,434.5,158.4z"/>
			<path fill="#F4EAD2" d="M434.3,187.4c0.2,0,0.4,0,0.5,0c0.1,0,0.3,0,0.4-0.1c0.1,0,0.1-0.1,0.1-0.1c0,0,0-0.1-0.1-0.1
				C434.9,187.5,434.3,186.4,434.3,187.4z"/>
			<path fill="#F6EDD9" d="M429.9,157.6c0.2,0,0.4-0.1,0.4-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3
				C429.7,157.4,429.9,157.5,429.9,157.6z"/>
			<path fill="#FAF6ED" d="M438.6,188.9c0.3,0,0.6,0,0.9,0c0-0.2,0.1-0.5-0.2-0.5C439,188.3,438.7,188.6,438.6,188.9z"/>
			<path fill="#E8D193" d="M435.2,142.7c0.1,0,0.2,0.1,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
				C435.3,142.4,435.2,142.5,435.2,142.7z"/>
			<path fill="#F3E8CD" d="M439.1,195.4c-0.2-0.2-0.3-0.5-0.5-0.7C438,195.4,438.5,195.3,439.1,195.4z"/>
			<path fill="#EFDFB7" d="M432.7,141.4c-0.1-0.2-0.2-0.2-0.4-0.1c-0.1,0-0.2,0.2-0.2,0.2c0,0.2,0.1,0.3,0.3,0.2
				C432.6,141.7,432.7,141.6,432.7,141.4z"/>
			<path fill="#EFE0B9" d="M399.6,141.3c0.1-0.2,0.2-0.3,0.3-0.4c0-0.2-0.1-0.3-0.2-0.2c-0.1,0-0.2,0.2-0.2,0.3
				C399.3,141.1,399.4,141.2,399.6,141.3z"/>
			<path fill="#F6EED9" d="M432.5,197.7c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0,0.3-0.3,0.3-0.5c0-0.1,0-0.3-0.2-0.2
				C432.6,197.3,432.5,197.5,432.5,197.7z"/>
			<path fill="#EBD7A2" d="M423.8,143.8c0.5-0.2,0.4-0.5,0.5-0.8C423.8,143.1,423.9,143.4,423.8,143.8z"/>
			<path fill="#EAD59E" d="M413.9,143.3c0.4,0.2,0.7,0.3,1.2,0.3c-0.1-0.5-0.4-0.4-0.7-0.3C414.2,143.1,414,143.1,413.9,143.3z"/>
			<path fill="#EEDDB1" d="M404.1,139.8c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.3,0.2,0.3,0.4,0.3c0.1,0,0.3,0,0.3-0.1
				C404.5,139.9,404.3,139.9,404.1,139.8z"/>
			<path fill="#F9F3E5" d="M435.9,198.6c0.3-0.1,0.3-0.2,0.3-0.4c0-0.2-0.2-0.3-0.3-0.2c-0.2,0-0.3,0.1-0.2,0.3
				C435.7,198.4,435.8,198.5,435.9,198.6z"/>
			<path fill="#F1E4C2" d="M434.7,146.2c0,0.1,0,0.2,0,0.3c0.3-0.1,0.7,0,0.9-0.4C435.2,145.8,435,146,434.7,146.2z"/>
			<path fill="#FEFEFE" d="M433.1,146.4c0.3,0,0.5-0.2,0.5-0.5c0-0.1,0.1-0.4-0.2-0.4C433,145.6,433.1,146.1,433.1,146.4z"/>
			<path fill="#ECDAA8" d="M400.9,139.8c-0.1,0.1-0.2,0.3-0.2,0.5c0,0,0.2,0.2,0.3,0.2c0.3,0,0.2-0.3,0.2-0.5
				C401.1,139.8,401.1,139.7,400.9,139.8z"/>
			<path fill="#EEDEB5" d="M424,164.7c-0.3-0.2-0.5-0.3-0.8,0C423.5,165,423.7,165,424,164.7z"/>
			<path fill="#F6EEDA" d="M436.6,183.9c0.1,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.1
				C436.3,183.8,436.4,183.9,436.6,183.9z"/>
			<path fill="#EEDEB2" d="M417.2,124.7c0.1-0.1,0.3-0.2,0.3-0.3c0-0.2-0.2-0.2-0.3-0.2c-0.1,0-0.3,0-0.3,0.2
				C416.9,124.5,417.1,124.6,417.2,124.7z"/>
			<path fill="#F7F0DF" d="M423.3,196.5c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.3,0.1-0.3,0.3
				C423.2,196.3,423.3,196.4,423.3,196.5z"/>
			<path fill="#E9D398" d="M431.6,186.5c-0.1-0.2-0.1-0.5-0.4-0.5c-0.1,0-0.2,0.1-0.1,0.3C431.2,186.6,431.4,186.6,431.6,186.5
				C431.6,186.5,431.6,186.5,431.6,186.5z"/>
			<path fill="#F7F0E0" d="M411.5,138.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3,0.1-0.4,0.4-0.1,0.6C411.3,139,411.4,138.7,411.5,138.5z"/>
			<path fill="#F4EBD3" d="M436.2,157.6c0.2,0.1,0.3,0.2,0.5,0.3c0.2-0.2,0.4-0.4,0.2-0.6C436.6,157.1,436.5,157.6,436.2,157.6z"/>
			<path fill="#F3E9CE" d="M431,198c-0.2,0-0.3,0.2-0.3,0.3c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.2-0.2,0.2-0.3
				C431.1,198.2,431,198.1,431,198z"/>
			<path fill="#F6EEDB" d="M434.3,163.2c0.1,0,0.2,0.1,0.2,0.1c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.2-0.2-0.2
				C434.4,162.8,434.3,162.9,434.3,163.2z"/>
			<path fill="#E9D296" d="M435.4,200.5c0.1-0.1,0.2-0.2,0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3,0-0.2,0.1
				C435.1,200.3,435.2,200.4,435.4,200.5z"/>
			<path fill="#EDDBAD" d="M403.6,138.3c0.1-0.2,0.1-0.3,0.2-0.5c0,0,0,0,0,0c-0.4,0.1-1.1-0.2-0.7,0.7
				C403.2,138.3,403.4,138.3,403.6,138.3z"/>
			<path fill="#EEDEB4" d="M395.9,121.3c0.1-0.1,0.3-0.3,0.2-0.3c-0.1-0.1-0.2-0.3-0.3-0.3c-0.1-0.1-0.2,0.1-0.2,0.2
				C395.7,121,395.8,121.1,395.9,121.3z"/>
			<path fill="#E8D091" d="M430.4,162c-0.2,0-0.4-0.2-0.7,0c0,0,0,0.2,0,0.2c0.2,0.3,0.5,0.1,0.7,0C430.5,162.1,430.5,162,430.4,162
				z"/>
			<path fill="#F6EDD7" d="M430.4,162c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.4-0.6-0.1-0.6-0.5-0.7
				C430.4,161.8,430.4,161.9,430.4,162z"/>
			<path fill="#EAD59C" d="M439.1,174.4c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
				C438.9,174.2,439,174.3,439.1,174.4z"/>
			<path fill="#F9F3E7" d="M434.3,174.9c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0,0.3-0.1,0.2-0.3c-0.1-0.1-0.2-0.3-0.3-0.2
				C434.4,174.6,434.4,174.7,434.3,174.9z"/>
			<path fill="#EEDFB5" d="M418.3,140.6c0,0.1,0.1,0.3,0.3,0.3c0.2,0,0.2-0.2,0.1-0.4c0-0.1-0.1-0.3-0.2-0.3
				C418.4,140.2,418.3,140.4,418.3,140.6z"/>
			<path fill="#FBF7EF" d="M420.1,123.6c-0.1,0.3-0.1,0.6-0.2,0.9C420.5,124.1,420.5,124,420.1,123.6z"/>
			<path fill="#EBD8A3" d="M440,163.2c0,0.2,0,0.5,0.2,0.7c0.1,0.1,0.2,0,0.2-0.2C440.5,163.4,440.3,163.3,440,163.2
				C440,163.2,440,163.2,440,163.2z"/>
			<path fill="#E8D294" d="M427.3,137.6c-0.1,0-0.2,0.1-0.2,0.2c0,0.2,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.1
				C427.6,137.7,427.5,137.7,427.3,137.6z"/>
			<path fill="#EFE0B7" d="M438.9,211.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C439.1,211.3,439,211.4,438.9,211.5z"/>
			<path fill="#F6EDD8" d="M417,152c-0.6-0.2-0.9-0.1-1.1,0.3C416.4,152.4,416.5,152.1,417,152z"/>
			<path fill="#F3E8CC" d="M440.3,194.5c-0.1,0-0.3,0.1-0.2,0.3c0,0.1,0.2,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
				C440.6,194.6,440.5,194.5,440.3,194.5z"/>
			<path fill="#E7CF8F" d="M423.1,136.2c0,0.2,0.1,0.2,0.2,0.2c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.2-0.2-0.2
				C423.3,135.9,423.2,136.1,423.1,136.2z"/>
			<path fill="#EAD59D" d="M415.8,136.6c0.2-0.3,0.2-0.5,0.2-1C415.5,135.9,415.6,136.2,415.8,136.6
				C415.8,136.6,415.8,136.6,415.8,136.6z"/>
			<path fill="#E9D49A" d="M413,144.2c0.2,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2-0.1-0.2-0.2-0.2
				C413.3,144,413.2,144.1,413,144.2z"/>
			<path fill="#EBD7A2" d="M422.8,145.1c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1-0.1-0.3-0.1-0.3c-0.2,0-0.2,0.2-0.2,0.3
				C422.6,144.9,422.7,145,422.8,145.1z"/>
			<path fill="#EAD59D" d="M438.3,191.7c0.1,0,0.2,0,0.2,0c0.1-0.1,0.3-0.1,0.3-0.3c0-0.1-0.2-0.2-0.3-0.2
				C438.3,191.3,438.3,191.5,438.3,191.7z"/>
			<path fill="#F5EBD3" d="M424.2,134.4c-0.1,0.1-0.1,0.1-0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.1,0.3,0.2,0.5,0.3
				C425.1,134.5,424.4,134.6,424.2,134.4z"/>
			<path fill="#EFDEB5" d="M434.3,206.6c0.1,0,0.2,0,0.2,0c0.1-0.3,0-0.5-0.2-0.7c0,0-0.2,0-0.2,0.1
				C434,206.3,434.2,206.4,434.3,206.6z"/>
			<path fill="#E8D193" d="M431.6,134.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2-0.1,0.1-0.3c0-0.1-0.1-0.1-0.2-0.1
				C431.6,133.8,431.5,133.9,431.6,134.1z"/>
			<path fill="#E6CE8A" d="M439.2,193c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.3-0.1c0-0.1-0.1-0.2-0.2-0.2
				C439.5,192.9,439.4,192.9,439.2,193z"/>
			<path fill="#F1E3C1" d="M424.5,146.4c0,0.3,0.2,0.3,0.4,0.4c0.1,0,0.2-0.1,0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
				C424.7,146.2,424.6,146.3,424.5,146.4z"/>
			<path fill="#E8D092" d="M424.6,200.9c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.2-0.3c0-0.1-0.2-0.2-0.3-0.1
				C424.7,200.6,424.7,200.8,424.6,200.9z"/>
			<path fill="#EDDBAD" d="M412.6,137.1c0.1,0.2,0.1,0.4,0.3,0.3c0,0,0.1-0.2,0.1-0.3c0-0.1-0.1-0.3-0.3-0.2
				C412.6,136.8,412.6,137,412.6,137.1z"/>
			<path fill="#E6CE8A" d="M424.1,156c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2-0.1,0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
				C424.1,155.7,424.1,155.8,424.1,156z"/>
			<path fill="#E8D297" d="M424.2,127.4c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4,0.2-0.5,0.5,0,0.7C424.1,127.6,424.1,127.5,424.2,127.4z"/>
			<path fill="#FAF6ED" d="M425.1,137c0.1,0.2,0.2,0.3,0.4,0.2c0.1-0.1,0.2-0.3,0.1-0.4c0-0.2-0.2-0.2-0.3-0.1
				C425.1,136.7,425,136.9,425.1,137z"/>
			<path fill="#F0E2BD" d="M437.2,240c0,0-0.1,0.1-0.1,0.2c0,0.2,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.1
				C437.6,240.2,437.5,240.1,437.2,240z"/>
			<path fill="#F6EFDC" d="M439.1,155.4c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.3,0.4,0.4,0.6,0.1C439.6,155.6,439.2,155.5,439.1,155.4z"/>
			<path fill="#FCFAF5" d="M408.4,143c-0.1-0.1-0.2-0.5-0.5-0.3c-0.3,0.2-0.1,0.5,0.2,0.7C408.3,143.4,408.4,143.2,408.4,143z"/>
			<path fill="#F0E2BE" d="M431.6,189c0,0,0.1-0.1,0.2-0.3c0-0.1,0-0.3-0.2-0.2c-0.1,0.1-0.3,0.2-0.3,0.3
				C431.2,189,431.3,189,431.6,189z"/>
			<path fill="#F6EEDC" d="M441.3,189.1c-0.1-0.2-0.2-0.4-0.4-0.4c-0.1,0-0.2,0.1-0.2,0.2c0,0.3,0.3,0.4,0.5,0.5
				C441.3,189.3,441.3,189.2,441.3,189.1z"/>
			<path fill="#F2E6C8" d="M406.3,142.9c0.1,0,0.1,0.1,0.2,0.2c0.2,0,0.3-0.1,0.2-0.3c0-0.1-0.2-0.1-0.3-0.2
				C406.4,142.6,406.3,142.7,406.3,142.9z"/>
			<path fill="#EDDBAC" d="M428.9,152.3c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0,0.2-0.1
				C429.1,152.6,429,152.5,428.9,152.3z"/>
			<path fill="#EFE0B9" d="M441.3,189.1c0,0.1,0,0.2-0.1,0.4c-0.1,0.2,0,0.4,0.2,0.4c0.1,0,0.2-0.1,0.2-0.1
				C441.7,189.4,441.5,189.3,441.3,189.1z"/>
			<path fill="#EFE1BA" d="M405.7,128.8c-0.2-0.1-0.5-0.5-0.7-0.2c-0.2,0.2,0.2,0.4,0.5,0.5C405.6,129,405.7,129,405.7,128.8z"/>
			<path fill="#EFE0B8" d="M413.6,136.4c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1-0.1-0.2-0.2-0.2
				C413.8,136.3,413.7,136.4,413.6,136.4z"/>
			<path fill="#F8F2E4" d="M405.7,135.6c-0.2,0-0.3,0.1-0.3,0.3c0,0.1,0.2,0.2,0.3,0.2c0,0,0.2-0.1,0.2-0.2
				C405.9,135.8,405.8,135.7,405.7,135.6z"/>
			<path fill="#E9D294" d="M378.6,129.5c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1-0.2,0.1-0.2c0,0-0.1-0.2-0.1-0.2
				C378.7,129.1,378.6,129.2,378.6,129.5z"/>
			<path fill="#F6EDD8" d="M421.7,130c0,0.2,0.1,0.2,0.2,0.2c0.2-0.1,0.4,0,0.4-0.3c0-0.1-0.2-0.2-0.2-0.2
				C421.9,129.7,421.7,129.8,421.7,130z"/>
			<path fill="#E7CF8C" d="M390.7,130.1c0.1,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.2-0.2-0.2
				C390.8,130,390.8,130,390.7,130.1z"/>
			<path fill="#E8D193" d="M399,109.6c-0.1,0-0.2,0.1-0.3,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3-0.1,0.3-0.1
				C399.3,109.6,399.1,109.6,399,109.6z"/>
			<path fill="#E8D295" d="M408.1,113.5c-0.1-0.3-0.2-0.6-0.2-1c-0.4,0.3-0.4,0.6,0,0.9C407.9,113.7,408,113.7,408.1,113.5
				L408.1,113.5z"/>
			<path fill="#F6EFDD" d="M396.8,113.3c-0.1,0.1-0.2,0.3-0.3,0.4c0.1,0,0.2,0.2,0.3,0.1c0.1-0.1,0.2-0.2,0.3-0.3
				C397.1,113.3,397,113.2,396.8,113.3z"/>
			<path fill="#EAD49B" d="M402.4,116.4c-0.1,0.2-0.2,0.2-0.2,0.3c0,0.1,0,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.2
				C402.5,116.6,402.5,116.5,402.4,116.4z"/>
			<path fill="#EFE0B7" d="M408.3,117.6c0.2,0.1,0.4,0.2,0.5-0.1c0.1-0.3-0.2-0.5-0.4-0.6C408.3,117.1,408.3,117.4,408.3,117.6z"/>
			<path fill="#F5EAD1" d="M401,117.3c0.2,0.1,0.3,0.1,0.4,0.1c0.2-0.1,0.3-0.2,0.3-0.4c0,0-0.2-0.1-0.3,0
				C401.2,117.1,401.1,117.2,401,117.3z"/>
			<path fill="#EAD59D" d="M393.7,118c0.1-0.5,0.2-0.6,0.2-0.7c0-0.1-0.2-0.1-0.2-0.2c0,0.1-0.1,0.2-0.1,0.3
				C393.5,117.6,393.6,117.7,393.7,118z"/>
			<path fill="#EDDCAD" d="M381,117c0,0.1-0.1,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
				C381.3,117,381.2,117,381,117z"/>
			<path fill="#EFE0B7" d="M408.1,116.6c0.4,0,0.7-0.2,0.9-0.5C408.5,115.8,408.3,116.2,408.1,116.6z"/>
			<path fill="#F4E9D0" d="M424.9,149.5c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0,0.2-0.2c0-0.1-0.1-0.1-0.2-0.1
				C425.2,149.4,425.1,149.5,424.9,149.5z"/>
			<path fill="#FAF6ED" d="M433.2,143.1c0.1,0.1,0.3,0.2,0.4,0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.3-0.2-0.3-0.5-0.4
				c-0.1,0-0.1,0.1-0.1,0.2C433.2,143,433.2,143.1,433.2,143.1z"/>
			<path fill="#F4E9CF" d="M431.2,149.3c0.8-0.2,0.5-0.6,0.2-1C431.2,148.6,431.3,149,431.2,149.3z"/>
			<path fill="#ECD9A6" d="M435.6,148.4c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2-0.2-0.2
				C435.8,148.2,435.8,148.3,435.6,148.4z"/>
			<path fill="#EAD69E" d="M416.3,142.4c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.2,0,0.2-0.1c0-0.1-0.1-0.1-0.1-0.2
				C416.5,142.3,416.4,142.3,416.3,142.4z"/>
			<path fill="#E9D49B" d="M438.2,156.6c0-0.1-0.1-0.2-0.1-0.2c-0.1,0-0.2,0.1-0.3,0.2c0,0,0,0.1,0.1,0.2
				C438,156.7,438.1,156.7,438.2,156.6z"/>
			<path fill="#F3E7CB" d="M384.4,147.2c0,0.2,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
				C384.6,147,384.5,147.1,384.4,147.2z"/>
			<path fill="#EBD7A2" d="M422.7,142.1c0.2,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.1-0.2-0.1
				C422.9,142,422.8,142,422.7,142.1z"/>
			<path fill="#F0E1BD" d="M398.6,145.6c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.1-0.2,0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
				C398.7,145.4,398.6,145.5,398.6,145.6z"/>
			<path fill="#F3E7C9" d="M427.2,152.5c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1,0.1-0.3,0.1-0.4,0.2c0,0,0.1,0.2,0.1,0.2
				C426.9,152.6,427.1,152.6,427.2,152.5z"/>
			<path fill="#E9D397" d="M417.8,153c0.1-0.1,0.2-0.2,0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0,0-0.1,0.1-0.1,0.1
				C417.6,152.8,417.7,152.9,417.8,153z"/>
			<path fill="#F8F2E4" d="M435.7,151.2c0,0.2,0,0.3,0,0.5c0.4-0.1,0.4-0.4,0.5-0.7c-0.2-0.2-0.3,0-0.5,0
				C435.7,151,435.7,151.1,435.7,151.2z"/>
			<path fill="#F0E2BD" d="M433.8,144.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C434,144.1,433.9,144.2,433.8,144.3z"/>
			<path fill="#EAD59C" d="M410.5,144c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.3,0.1
				C410.3,143.9,410.4,143.9,410.5,144z"/>
			<path fill="#EEDEB2" d="M435.8,143.8c0,0.2,0,0.3,0.2,0.3c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.1-0.3
				C436,143.6,435.9,143.7,435.8,143.8z"/>
			<path fill="#EAD49B" d="M433.8,154.9c0,0.1-0.1,0.3-0.1,0.3c0,0.1,0.2,0.1,0.3,0.1c0,0,0.1-0.1,0.1-0.2
				C434,155.1,433.9,155.1,433.8,154.9z"/>
			<path fill="#F3E8CB" d="M436.2,156c0.2-0.2,0.3-0.5,0-0.7c-0.2-0.1-0.3,0-0.3,0.2C435.8,155.8,436,155.9,436.2,156z"/>
			<path fill="#F1E4C1" d="M430.1,143.5c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2-0.2,0.1-0.3c0-0.1-0.1-0.1-0.2-0.1
				C430.1,143.2,430,143.3,430.1,143.5z"/>
			<path fill="#E9D296" d="M435,195.8c-0.1-0.1-0.2-0.2-0.2-0.2c0,0.2-0.3,0.6,0,0.7C435,196.4,435,196,435,195.8z"/>
			<path fill="#ECD9A6" d="M429.7,196.3c0-0.2,0.1-0.4-0.1-0.5c0,0-0.2,0.1-0.3,0.1C429.3,196.2,429.5,196.2,429.7,196.3
				C429.7,196.3,429.7,196.3,429.7,196.3z"/>
			<path fill="#F9F5EA" d="M440.4,203.5c-0.1,0.2-0.2,0.3-0.3,0.4c0,0,0.2,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
				C440.6,203.8,440.5,203.7,440.4,203.5z"/>
			<path fill="#F7F0DF" d="M432.6,206.8c0.1,0.1,0.1,0.2,0.2,0.2c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1,0.1-0.4-0.2-0.3
				C432.8,206.5,432.7,206.6,432.6,206.8z"/>
			<path fill="#FBF8F2" d="M434.2,213.9c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.2,0,0.3,0.2,0.3c0.1,0,0.2-0.1,0.2-0.2
				C434.4,214.1,434.4,214,434.2,213.9z"/>
			<path fill="#F1E5C5" d="M436.4,217.5c0.1-0.3,0.2-0.5,0.3-0.8C435.8,216.7,436.3,217.1,436.4,217.5z"/>
			<path fill="#E9D399" d="M436.3,222.6c0.1,0,0.1,0.1,0.2,0.1c0-0.1,0.1-0.2,0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1
				C436.3,222.5,436.3,222.6,436.3,222.6z"/>
			<path fill="#EFE1BA" d="M435.2,233.7c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0.1,0.2,0.1,0.2
				C435,233.8,435.1,233.7,435.2,233.7z"/>
			<path fill="#F4EBD4" d="M434.3,234.6c-0.1,0.2-0.3,0.3-0.3,0.4c-0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
				C434.5,234.9,434.4,234.8,434.3,234.6z"/>
			<path fill="#EDDBAE" d="M439.6,161.1c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.1,0,0.2,0.1,0.2
				C439.3,161.3,439.4,161.2,439.6,161.1z"/>
			<path fill="#E9D396" d="M417.2,164.5c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.3,0.1
				C417,164.4,417.1,164.5,417.2,164.5z"/>
			<path fill="#EBD8A3" d="M431.5,167.3c-0.1,0.2-0.1,0.2-0.1,0.3c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.2-0.1,0.2-0.2
				C431.7,167.5,431.6,167.4,431.5,167.3z"/>
			<path fill="#E8D193" d="M439.2,169.1c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0.2-0.1,0.1-0.1c0-0.1-0.1-0.2-0.2-0.3
				C439.3,169,439.3,169.1,439.2,169.1z"/>
			<path fill="#EDDAAB" d="M429.7,170.5c0,0,0.1,0.1,0.2,0.2c0-0.1,0.1-0.2,0.1-0.3c0,0-0.1-0.1-0.2-0.1
				C429.7,170.2,429.6,170.3,429.7,170.5z"/>
			<path fill="#F8F2E3" d="M441.5,170.9c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1,0-0.1,0-0.2,0c-0.4,0-0.4,0.2-0.2,0.5
				C441,170.9,441.2,170.9,441.5,170.9z"/>
			<path fill="#EEDEB5" d="M439.8,177.4c0,0.2,0.1,0.3,0.2,0.2c0.1,0,0.2-0.1,0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
				C440,177.2,439.9,177.4,439.8,177.4z"/>
			<path fill="#E8D090" d="M441.1,178.4c-0.1,0-0.2,0.1-0.2,0.2c0,0,0.2,0.1,0.2,0.1c0.1-0.1,0.1-0.1,0.2-0.2
				C441.3,178.4,441.2,178.4,441.1,178.4z"/>
			<path fill="#EFDFB6" d="M437.7,180c0.1,0.2,0.2,0.2,0.2,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0-0.2-0.2-0.2
				C437.9,179.8,437.8,179.9,437.7,180z"/>
			<path fill="#EDDCAE" d="M438.8,182.4c0,0.2,0.1,0.4,0.3,0.4c0.1,0,0.1-0.2,0.1-0.3C439.2,182.2,439,182.3,438.8,182.4
				C438.8,182.4,438.8,182.4,438.8,182.4z"/>
			<path fill="#EBD8A6" d="M433.3,186.7c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.2-0.1,0.2-0.2c0,0-0.1-0.1-0.2-0.1
				C433.4,186.6,433.3,186.7,433.3,186.7z"/>
			<path fill="#ECD9A7" d="M435.2,187.2c0,0.1,0,0.1,0,0.2c0.2,0,0.5,0,0.5-0.3c0-0.1-0.1-0.2-0.1-0.2
				C435.4,186.9,435.3,187.1,435.2,187.2z"/>
			<path fill="#E7CF8E" d="M428.9,187c-0.1,0-0.1-0.1-0.2-0.1c0,0.1-0.1,0.2-0.1,0.2c0.1,0,0.1,0.1,0.2,0.1
				C428.8,187.2,428.9,187.1,428.9,187z"/>
			<path fill="#EBD8A4" d="M434.1,191.1c-0.2,0-0.3,0-0.2,0.2c0,0.1,0.2,0.1,0.3,0.2c0,0,0.2-0.1,0.1-0.1
				C434.3,191.2,434.2,191.1,434.1,191.1z"/>
			<path fill="#E9D49B" d="M425,195.4c0.1,0.2,0.2,0.1,0.3,0.1c0.1-0.1,0.1-0.1,0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1
				C425.1,195.2,425,195.4,425,195.4z"/>
			<path fill="#EDDBAD" d="M398.5,132.9c0.2,0.2,0.3,0.2,0.3,0.2c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.1,0-0.2-0.2-0.2
				C398.8,132.8,398.6,132.9,398.5,132.9z"/>
			<path fill="#E9D294" d="M392.7,133c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
				C393,132.9,392.9,133,392.7,133z"/>
			<path fill="#F7F1E1" d="M429.7,133.4c0.1,0,0.1,0.2,0.2,0.2c0.1,0,0.1-0.1,0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
				C429.8,133.1,429.7,133.2,429.7,133.4z"/>
			<path fill="#F2E5C5" d="M413.2,133.4c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.2-0.3c0-0.1-0.2-0.1-0.3-0.1
				C413.3,133.1,413.2,133.2,413.2,133.4z"/>
			<path fill="#EBD8A5" d="M403.9,133.3c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0.2,0.2
				C403.8,133.5,403.8,133.4,403.9,133.3z"/>
			<path fill="#EBD8A3" d="M417.2,133.2c-0.3,0.1-0.6,0.2-0.5,0.7C417.2,133.8,417.2,133.5,417.2,133.2L417.2,133.2z"/>
			<path fill="#F0E1BB" d="M427.1,136.1c0,0.2,0.1,0.3,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C427.3,135.9,427.2,136,427.1,136.1z"/>
			<path fill="#ECD9A7" d="M418.2,136.1c-0.1,0-0.2,0-0.3,0c-0.2,0.2-0.4,0.2-0.4,0.5C417.9,136.7,418,136.3,418.2,136.1z"/>
			<path fill="#E9D296" d="M410,136.8c-0.1,0.1-0.2,0.2-0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0,0,0.2-0.1,0.1-0.1
				C410.2,137,410.1,136.9,410,136.8z"/>
			<path fill="#EAD59C" d="M414.3,137.7c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.1,0.2,0.1c0-0.1,0.1-0.1,0.1-0.2
				C414.5,137.8,414.5,137.7,414.3,137.7z"/>
			<path fill="#EDDAAA" d="M404.3,119.2c0.1,0.1,0.1,0.1,0.1,0.2c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.2-0.2-0.2
				C404.4,119,404.3,119.2,404.3,119.2z"/>
			<path fill="#EFE0B8" d="M415.3,118.8c0-0.2-0.1-0.4-0.4-0.5c-0.1,0-0.3,0-0.2,0.2C414.9,118.7,415.1,118.8,415.3,118.8
				L415.3,118.8z"/>
			<path fill="#FBFAF4" d="M413.9,121.2c-0.3,0-0.5,0-0.8,0C413.4,121.8,413.6,121.9,413.9,121.2z"/>
			<path fill="#F6EEDA" d="M421.7,121.8c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.3-0.3c0,0-0.1-0.1-0.2-0.1
				C421.9,121.5,421.8,121.6,421.7,121.8z"/>
			<path fill="#F2E7CB" d="M396.4,122.5c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1-0.2,0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
				C396.5,122.4,396.5,122.5,396.4,122.5z"/>
			<path fill="#F9F3E7" d="M396.8,116.9c-0.2,0.2-0.3,0.3-0.5,0.5c0.1,0.3,0.3,0.1,0.5,0C397,117.2,396.9,117.1,396.8,116.9z"/>
			<path fill="#FAF6EE" d="M413.8,123.6c-0.3-0.1-0.6-0.2-1-0.3c-0.2,0.7,0.4,0.5,0.7,0.6C413.7,123.8,413.8,123.7,413.8,123.6z"/>
			<path fill="#F0E0B9" d="M398.4,117c0.2,0.1,0.2,0.2,0.2,0.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1-0.1-0.2-0.2-0.2
				C398.6,116.9,398.5,117,398.4,117z"/>
			<path fill="#F1E4C2" d="M402.3,124.9c-0.1-0.1-0.2-0.1-0.3-0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.2
				C402.2,125.1,402.2,125,402.3,124.9z"/>
			<path fill="#F9F4E9" d="M425.4,125.3c0.2,0.1,0.3,0.1,0.5,0.2c0-0.3,0-0.6-0.4-0.5C425.2,125,425.2,125.1,425.4,125.3z"/>
			<path fill="#FEFEFE" d="M410.7,115.2c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1,0.2-0.4,0.3-0.4,0.7C410.2,115.3,410.4,115.3,410.7,115.2z"
				/>
			<path fill="#F7F0DF" d="M374.7,125.7c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C374.8,125.4,374.7,125.5,374.7,125.7z"/>
			<path fill="#F8F2E4" d="M411.2,114.5c0,0.1,0,0.2,0,0.2c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.1,0.2-0.3,0.2-0.5
				C411.6,114,411.4,114.2,411.2,114.5z"/>
			<path fill="#F9F5E8" d="M404,126c-0.1,0.1-0.3,0.1-0.3,0.2c-0.1,0.2,0,0.3,0.2,0.3c0.1,0,0.2-0.1,0.2-0.2
				C404.2,126.2,404.1,126.1,404,126z"/>
			<path fill="#EEDDB1" d="M406.2,128.7c-0.1-0.2,0.1-0.6-0.2-0.5c-0.3,0.1,0,0.5-0.1,0.7c0.1,0.1,0.1,0.1,0.2,0.1
				C406.2,128.9,406.2,128.8,406.2,128.7z"/>
			<path fill="#F3E8CB" d="M392.7,112.9c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1-0.2,0.1-0.2c0,0-0.1-0.1-0.2-0.1
				C392.8,112.6,392.7,112.7,392.7,112.9z"/>
			<path fill="#FCF9F3" d="M406.2,128.7c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0.1-0.2,0.2-0.2,0.2
				c0.1,0.1,0.3,0.2,0.4,0.2c0.2-0.2,0.4-0.3,0.5-0.6C406.3,128.7,406.3,128.7,406.2,128.7z"/>
			<path fill="#E9D398" d="M392.6,109.8c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0,0.1,0.1,0.2,0.1c0.1-0.1,0.1-0.2,0.2-0.2
				C392.8,109.9,392.7,109.9,392.6,109.8z"/>
			<path fill="#F0E2BC" d="M403.2,129.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
				C403.5,129.3,403.4,129.4,403.2,129.5z"/>
			<path fill="#F7F0E1" d="M398.9,140.5c0.1-0.1,0.2-0.2,0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
				C398.7,140.3,398.8,140.4,398.9,140.5z"/>
			<path fill="#F0E2BE" d="M426.7,140.5c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.1-0.2-0.2c-0.1,0.1-0.2,0.1-0.2,0.2
				C426.5,140.3,426.6,140.4,426.7,140.5z"/>
			<path fill="#EAD69F" d="M406.9,140c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.2-0.1,0.3-0.2c0,0,0-0.1-0.1-0.2
				C407.1,139.9,407,140,406.9,140z"/>
			<path fill="#EEDDB0" d="M429.9,140.2c0-0.2,0.4-0.3,0.2-0.6c0,0-0.2,0-0.3,0.1C429.5,139.9,429.7,140,429.9,140.2
				C429.9,140.1,429.9,140.2,429.9,140.2z"/>
			<path fill="#F1E5C5" d="M423.2,139.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0,0.2-0.2c0-0.1-0.2-0.2-0.3-0.2
				C423.4,139,423.2,139,423.2,139.2z"/>
			<path fill="#ECD8A5" d="M399.8,137.9c-0.1,0.1-0.1,0.1-0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
				C400,138.1,399.9,138,399.8,137.9z"/>
			<path fill="#F2E6C7" d="M407.1,138c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2,0.1-0.4,0.2-0.1,0.4C406.6,138.3,406.9,138.3,407.1,138z"/>
			<path fill="#F4EBD3" d="M417.4,125.8c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.1,0.1,0.2c0.1-0.1,0.2-0.1,0.2-0.2
				C417.7,126.1,417.6,126,417.4,125.8z"/>
			<path fill="#F3E9D0" d="M439.4,195.9c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.2-0.1,0.3-0.1c0,0,0-0.2-0.1-0.2
				C439.6,195.9,439.5,195.9,439.4,195.9z"/>
			<path fill="#F7EFDD" d="M413.9,143.3c0.2,0,0.3,0,0.5,0c-0.1-0.2-0.2-0.4-0.5-0.3C413.7,143,413.8,143.2,413.9,143.3z"/>
			<path fill="#F5EBD4" d="M406.4,115c0.2,0,0.4,0,0.5-0.3c0-0.1-0.1-0.2-0.2-0.2C406.4,114.5,406.5,114.8,406.4,115
				C406.4,115,406.4,115,406.4,115z"/>
			<path fill="#EEDDB0" d="M404.6,114.9c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.2,0-0.2,0.2
				C404.5,114.8,404.5,114.9,404.6,114.9z"/>
			<path fill="#EEDFB6" d="M438,198.4c0,0.1-0.1,0.1-0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1-0.1,0.1-0.1
				C438.3,198.5,438.2,198.4,438,198.4z"/>
			<path fill="#E9D294" d="M439.5,198.9c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.2,0.2c0,0,0.1-0.1,0.1-0.2
				C439.6,199,439.5,198.9,439.5,198.9z"/>
			<path fill="#F1E2BF" d="M410,125.2c-0.1,0-0.1,0.1-0.2,0.1c0,0.1,0,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.2
				C410.2,125.3,410.1,125.3,410,125.2z"/>
			<path fill="#F0E2BD" d="M402.7,114.8c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1,0,0.2,0.1,0.2c0.1,0,0.1-0.1,0.2-0.1
				C402.8,114.9,402.7,114.8,402.7,114.8z"/>
			<path fill="#EDDCB0" d="M402.6,140.6c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.2,0,0.2-0.2
				C402.7,140.7,402.6,140.7,402.6,140.6z"/>
			<path fill="#EDDBAD" d="M432.7,201.5c0.1,0,0.1,0.1,0.2,0.1c0-0.1,0.1-0.2,0.1-0.2c-0.1,0-0.1-0.1-0.2,0
				C432.7,201.3,432.7,201.4,432.7,201.5z"/>
			<path fill="#F3E9CF" d="M416.6,141.1c0.1-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.2,0.2
				C416.4,141,416.5,141,416.6,141.1z"/>
			<path fill="#EEDDB0" d="M404.5,115.5c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.1-0.2c0,0-0.1-0.1-0.2,0
				C404.5,115.3,404.5,115.4,404.5,115.5z"/>
			<path fill="#F6EDD8" d="M431.1,206.1c0,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.2-0.1c-0.1,0-0.1,0.1-0.1,0.2
				C431,206,431,206.1,431.1,206.1z"/>
			<path fill="#F0E1BB" d="M402.3,111.5c0-0.1,0.1-0.2,0.1-0.3c0,0-0.1-0.1-0.1-0.1c0,0.1-0.1,0.2-0.1,0.3
				C402.1,111.4,402.2,111.5,402.3,111.5z"/>
			<path fill="#F1E5C4" d="M396.4,111.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.2,0.2c0.1,0,0.1-0.2,0.1-0.3
				C396.6,111.3,396.5,111.2,396.4,111.1z"/>
			<path fill="#EAD59D" d="M407.9,128.7c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2-0.1,0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1
				C408.1,128.6,408.1,128.7,407.9,128.7z"/>
			<path fill="#F4EAD0" d="M388.9,111.6c0.1,0.1,0.1,0.2,0.1,0.2c0.1-0.1,0.2-0.1,0.2-0.2c0,0-0.1-0.2-0.1-0.2
				C389.1,111.5,389,111.6,388.9,111.6z"/>
			<path fill="#F3E7CA" d="M412.9,128.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0.1-0.2,0.1c0.1,0.1,0.1,0.2,0.2,0.2
				C412.6,128.5,412.7,128.5,412.9,128.3z"/>
			<path fill="#EFDEB4" d="M408.4,143c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.2,0.3,0.3,0.5,0.1C408.7,143.3,408.6,143.1,408.4,143z"/>
			<path fill="#EFDFB6" d="M408.1,113.5c-0.1,0-0.2,0-0.3,0c0,0.2-0.2,0.4,0,0.5C408.2,114.1,408.1,113.7,408.1,113.5z"/>
			<path fill="#EEDDB1" d="M400.1,113.9c0.1-0.1,0.1-0.2,0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0.1-0.2,0.1
				C399.9,113.8,400,113.8,400.1,113.9z"/>
			<path fill="#F4EAD2" d="M439,194.1c0.1,0.1,0.2,0.2,0.3,0.2c0-0.2,0.5-0.1,0.3-0.4C439.5,193.8,439.2,194,439,194.1z"/>
			<path fill="#E9D398" d="M382.9,137.9c0.1,0,0.1,0,0.2,0.1c0-0.1,0.1-0.1,0-0.2c0,0-0.1,0-0.2-0.1
				C382.9,137.7,382.9,137.8,382.9,137.9z"/>
			<path fill="#F5EBD4" d="M436.8,248.5c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1-0.1,0.1-0.2c-0.1,0-0.2-0.1-0.3-0.1
				C436.9,248.4,436.9,248.5,436.8,248.5z"/>
			<path fill="#EBD8A5" d="M404,141.8c0.1,0.1,0.1,0.1,0.1,0.2c0.1,0,0.2,0,0.2-0.2c0,0-0.1-0.1-0.2-0.1
				C404.1,141.7,404.1,141.7,404,141.8z"/>
			<path fill="#FBF8F0" d="M416.5,118.2c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0,0.2-0.2c0-0.1-0.1-0.2-0.2-0.3
				C416.6,117.9,416.6,118,416.5,118.2z"/>
			<path fill="#E9D49A" d="M378.3,118c0.1,0.1,0.1,0.2,0.2,0.3c0-0.1,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.1-0.2
				C378.4,117.9,378.3,117.9,378.3,118z"/>
			<path fill="#F0E1BC" d="M402.6,141.4c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.1c0,0,0.1-0.1,0.1-0.2
				C402.8,141.5,402.7,141.5,402.6,141.4z"/>
			<path fill="#FCF9F3" d="M407.5,120.9c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.2-0.1-0.2-0.1c0,0-0.1,0.1-0.1,0.2
				C407.3,120.7,407.3,120.8,407.5,120.9z"/>
			<path fill="#F7F1E1" d="M405.6,118.7c0,0.1,0.1,0.2,0.1,0.2c0.2,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.1-0.1-0.2
				C405.7,118.6,405.7,118.6,405.6,118.7z"/>
			<path fill="#E7D08F" d="M396.3,119c0.2,0.1,0.4,0.1,0.5-0.1c0,0,0-0.2-0.1-0.2C396.5,118.7,396.5,118.9,396.3,119L396.3,119z"/>
			<path fill="#EFDEB5" d="M409.1,141.3c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.2,0,0.2-0.1c0,0-0.1-0.1-0.1-0.1
				C409.2,141.2,409.1,141.2,409.1,141.3z"/>
			<path fill="#FBF8F1" d="M415.3,118.8c0,0.2-0.1,0.4,0.2,0.5c0.1,0,0.2-0.1,0.2-0.2C415.7,118.8,415.5,118.9,415.3,118.8
				C415.3,118.8,415.3,118.8,415.3,118.8z"/>
			<path fill="#E7D08F" d="M396.3,119c-0.1,0.2-0.3,0.4-0.1,0.7C396.5,119.5,396.3,119.3,396.3,119C396.3,119,396.3,119,396.3,119z"
				/>
			<path fill="#FAF6EC" d="M438.4,207.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C438.6,207.3,438.5,207.4,438.4,207.5z"/>
			<path fill="#E9D499" d="M403.2,124c-0.1,0-0.1,0.1-0.2,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.2-0.1
				C403.3,124.1,403.3,124,403.2,124z"/>
			<path fill="#EDDBAC" d="M433.2,143.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0.1-0.3,0.1
				C432.9,142.8,433.1,142.9,433.2,143.1z"/>
			<path fill="#EAD49A" d="M428.1,214.7c0,0,0.1-0.1,0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0.1-0.1,0.1
				C428,214.6,428.1,214.7,428.1,214.7z"/>
			<path fill="#FCFBF9" d="M438.4,215.9c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.1-0.3
				C438.6,215.8,438.5,215.9,438.4,215.9z"/>
			<path fill="#FCFBF6" d="M414.9,140.7c0.1,0.3,0.3,0.5,0.7,0.4C415.5,140.6,415.2,140.6,414.9,140.7z"/>
			<path fill="#E8D192" d="M384.9,123.3c0.1,0.1,0.1,0.1,0.1,0.2c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0-0.1,0-0.2
				C385,123.2,385,123.3,384.9,123.3z"/>
			<path fill="#EBD6A0" d="M433,218.4c-0.3,0-0.5,0.1-0.7-0.2C432.4,218.9,432.7,218.6,433,218.4z"/>
			<path fill="#F5ECD6" d="M419.1,122.6c0,0.2,0,0.3,0,0.5c0,0.1-0.2,0.3,0.1,0.3c0.1,0,0.1-0.2,0.1-0.3
				C419.3,122.9,419.2,122.8,419.1,122.6z"/>
			<path fill="#F2E6C8" d="M437.3,226.8c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.2-0.1
				C437.3,226.6,437.3,226.7,437.3,226.8z"/>
			<path fill="#FEFEFE" d="M438.5,233.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0.1,0.1,0.1,0.1c0.1-0.1,0.1-0.2,0.2-0.3
				C438.6,233.5,438.5,233.5,438.5,233.4z"/>
			<path fill="#EBD8A5" d="M407.1,122.7c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1-0.1,0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
				C407.2,122.6,407.2,122.6,407.1,122.7z"/>
			<path fill="#ECDBAB" d="M421.7,142.5c0.1,0.1,0.2,0.1,0.2,0.2c0-0.1,0.1-0.1,0.1-0.1c-0.1-0.1-0.2-0.2-0.2-0.2
				C421.8,142.4,421.7,142.4,421.7,142.5z"/>
			<path fill="#ECDAA9" d="M406.2,142.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1-0.1,0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1
				C406.3,141.9,406.2,142,406.2,142.1z"/>
			<path fill="#F3E7C9" d="M431.9,240.6c0.1,0,0.2,0,0.3,0c0,0,0.1-0.2,0.1-0.2c-0.1,0-0.2-0.1-0.3-0.1
				C431.9,240.4,431.9,240.5,431.9,240.6z"/>
			<path fill="#F3E8CB" d="M402.2,122.2c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0.1-0.1,0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
				C402.4,122,402.3,122.1,402.2,122.2z"/>
			<path fill="#E8D294" d="M436.7,244.8c0-0.8-0.4-0.4-0.7-0.2C436.1,244.8,436.4,244.8,436.7,244.8z"/>
			<path fill="#F3E7CA" d="M436.7,244.8c-0.2-0.2-0.4-0.3-0.7-0.2c0,0.1,0,0.1,0,0.2c0.1,0.2,0.2,0.4,0.5,0.3
				C436.5,245,436.6,244.9,436.7,244.8z"/>
			<path fill="#EBD7A2" d="M436.4,245.1c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.2-0.3,0.4,0,0.5C436,245.3,436.3,245.2,436.4,245.1z"/>
			<path fill="#F4EAD1" d="M415.4,122c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.2-0.2
				C415.5,121.9,415.4,121.9,415.4,122z"/>
			<path fill="#E8D193" d="M414.2,139.8c0,0.1,0.1,0.2,0.1,0.2c0.2,0,0.2-0.1,0.1-0.2c0-0.1-0.1-0.1-0.1-0.1
				C414.2,139.7,414.2,139.7,414.2,139.8z"/>
			<path fill="#F1E4C2" d="M425.9,135.5c0.1,0,0.2,0.1,0.3,0c0,0,0.1-0.1,0.1-0.2c-0.1,0-0.2-0.1-0.3-0.1
				C426,135.3,426,135.4,425.9,135.5z"/>
			<path fill="#ECD8A4" d="M420.5,157c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1-0.1,0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1
				C420.6,156.9,420.6,157,420.5,157z"/>
			<path fill="#F6EEDB" d="M431.4,135.3c0,0.1,0.1,0.2,0.1,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.1-0.1-0.2
				C431.6,135.1,431.5,135.2,431.4,135.3z"/>
			<path fill="#F1E4C2" d="M422.5,157.3c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0-0.1,0.1-0.1,0.1-0.2
				C422.8,157.5,422.8,157.4,422.5,157.3z"/>
			<path fill="#F9F6ED" d="M424.8,145.5c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1-0.2,0.1-0.3c0,0-0.1-0.1-0.2-0.1
				C424.8,145.2,424.7,145.3,424.8,145.5z"/>
			<path fill="#F5EDD9" d="M427.6,145.2c-0.1,0.1-0.1,0.1-0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1-0.1,0.2-0.1
				C427.9,145.3,427.8,145.3,427.6,145.2z"/>
			<path fill="#EBD7A2" d="M430.2,134.4c0.2,0.1,0.3,0.3,0.5,0.4C430.9,134.3,430.4,134.5,430.2,134.4z"/>
			<path fill="#EBD7A1" d="M436.2,158.4c-0.1,0-0.2,0-0.3,0c0,0.2-0.3,0.4,0,0.5C436.2,158.9,436.1,158.6,436.2,158.4z"/>
			<path fill="#EFDFB7" d="M424.6,134c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0.1-0.1,0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
				C424.8,133.9,424.7,134,424.6,134z"/>
			<path fill="#F5EDD6" d="M404.7,145.7c0.2-0.2,0.2-0.5,0.2-0.7C404.4,145,404.6,145.4,404.7,145.7z"/>
			<path fill="#E9D49B" d="M413.5,145.3c0.1,0,0.1,0.1,0.2,0.1c0-0.1,0.1-0.1,0.1-0.2c0,0-0.1-0.1-0.2-0.1
				C413.6,145.2,413.6,145.3,413.5,145.3z"/>
			<path fill="#F9F5EB" d="M403.6,138.3c-0.3-0.3-0.4-0.1-0.5,0.2C403.3,138.6,403.5,138.5,403.6,138.3z"/>
			<path fill="#F1E5C7" d="M427.7,152.1c0,0.1,0.1,0.1,0.2,0.2c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0-0.2-0.1-0.2
				C427.8,151.9,427.7,152,427.7,152.1z"/>
			<path fill="#EEDEB4" d="M423.3,146.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2-0.1,0.1-0.2c0,0-0.1-0.1-0.2-0.1
				C423.3,145.9,423.3,146,423.3,146.1z"/>
			<path fill="#EDDCAF" d="M410.6,146.5c0,0.1-0.1,0.1-0.1,0.1c0.1,0,0.2,0.1,0.2,0.1c0-0.1,0.1-0.1,0-0.2
				C410.8,146.5,410.7,146.5,410.6,146.5z"/>
			<path fill="#EAD59D" d="M412.6,152.9c0.1,0,0.2,0,0.2,0c0,0,0-0.1,0-0.2c-0.1,0-0.2,0-0.2,0C412.6,152.7,412.6,152.8,412.6,152.9
				z"/>
			<path fill="#F2E6C8" d="M401.7,135.9c0.1,0.1,0.2,0.1,0.2,0.2c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
				C401.8,135.7,401.8,135.8,401.7,135.9z"/>
			<path fill="#F0E1BB" d="M427.2,153c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.1c0,0,0.1-0.1,0.1-0.2
				C427.4,153,427.3,153,427.2,153z"/>
			<path fill="#F3E8CC" d="M430.9,148.8c-0.1-0.2-0.3-0.2-0.5-0.1c0,0,0,0.2,0,0.2c0.1,0,0.3,0.1,0.4,0.1
				C430.9,149,431,148.9,430.9,148.8C431,148.8,431,148.8,430.9,148.8z"/>
			<path fill="#F8F1E1" d="M390.8,135.8c0.2,0,0.3-0.1,0.2-0.2c0-0.1-0.1-0.2-0.1-0.2c-0.1,0-0.2,0.1-0.3,0.1
				C390.7,135.6,390.8,135.7,390.8,135.8z"/>
			<path fill="#EBD7A2" d="M404.2,135.7c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.1-0.1-0.2
				C404.3,135.7,404.2,135.7,404.2,135.7z"/>
			<path fill="#EBD69F" d="M414.5,155.2c0.1,0,0.1,0.1,0.2,0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1
				C414.6,155,414.5,155.1,414.5,155.2z"/>
			<path fill="#E8D193" d="M436.2,156c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1,0.1-0.3,0.2-0.2,0.3C435.8,155.9,436,155.9,436.2,156z"/>
			<path fill="#ECDAAA" d="M417,135.7c0.1,0.1,0.1,0.2,0.1,0.2c0.2,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.1-0.1-0.2
				C417.2,135.6,417.1,135.6,417,135.7z"/>
			<path fill="#FDFBF8" d="M440.2,171.4c-0.2,0.1-0.5,0.1-0.4,0.3c0.1,0.2,0.3,0,0.5-0.1C440.3,171.5,440.3,171.4,440.2,171.4z"/>
			<path fill="#EBD7A3" d="M428.3,172.6c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1
				C428.4,172.7,428.4,172.7,428.3,172.6z"/>
			<path fill="#F0E3BF" d="M391.8,140c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2-0.2-0.2
				C391.9,139.9,391.9,140,391.8,140z"/>
			<path fill="#F0E1BC" d="M377,130.5c0-0.1,0.1-0.1,0.1-0.2c0-0.2-0.1-0.2-0.2-0.1c-0.1,0-0.1,0.1-0.1,0.2
				C376.9,130.4,376.9,130.4,377,130.5z"/>
			<path fill="#FAF7EF" d="M393.4,109c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.2,0,0.3-0.1c0-0.1-0.1-0.2-0.1-0.2
				C393.6,108.8,393.5,108.8,393.4,109z"/>
			<path fill="#EEE0B8" d="M439.8,179.3c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.1c0,0,0.1-0.1,0.1-0.2
				C440,179.4,439.9,179.4,439.8,179.3z"/>
			<path fill="#EEDDB0" d="M394.8,109.6c0-0.1,0.1-0.1,0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0.1-0.1,0.2
				C394.6,109.5,394.7,109.5,394.8,109.6z"/>
			<path fill="#F0E2BD" d="M430.1,180.3c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1
				C430.1,180.2,430.1,180.3,430.1,180.3z"/>
			<path fill="#EEDDB1" d="M439.3,180.9c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.2-0.1,0.2-0.2c0,0-0.1-0.1-0.2-0.1
				C439.4,180.8,439.3,180.9,439.3,180.9z"/>
			<path fill="#E9D499" d="M397.5,109.7c-0.2,0.2-0.2,0.3-0.2,0.3c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.2-0.1
				C397.6,110,397.5,109.9,397.5,109.7z"/>
			<path fill="#F0E2BD" d="M440.6,181.8c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1
				C440.8,181.6,440.8,181.7,440.6,181.8z"/>
			<path fill="#F3E7CA" d="M400.9,129.1c-0.1-0.2-0.1-0.4-0.3-0.4c0,0-0.1,0.1-0.1,0.1C400.5,129.1,400.7,129.1,400.9,129.1
				C400.9,129.1,400.9,129.1,400.9,129.1z"/>
			<path fill="#F4EAD0" d="M435.4,183.2c0.1,0,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.2-0.2c0-0.1-0.1-0.1-0.1-0.2
				C435.6,183,435.5,183,435.4,183.2z"/>
			<path fill="#F1E3C0" d="M427.8,183.7c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.2-0.2
				C427.9,183.6,427.9,183.7,427.8,183.7z"/>
			<path fill="#EDDBAC" d="M434.4,184.2c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0.2-0.1,0.1-0.1c0-0.1-0.1-0.2-0.2-0.3
				C434.6,184.1,434.5,184.1,434.4,184.2z"/>
			<path fill="#EDDBAD" d="M441.6,184.9c0-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0.1,0.1,0.1,0.2
				C441.5,185,441.6,185,441.6,184.9z"/>
			<path fill="#E9D49A" d="M421.6,144.9c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1-0.1,0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1
				C421.6,144.8,421.6,144.9,421.6,144.9z"/>
			<path fill="#F8F2E6" d="M423.8,133.2c0,0.1,0.1,0.1,0.1,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0-0.1-0.1-0.2
				C424,133.1,423.9,133.2,423.8,133.2z"/>
			<path fill="#E9D397" d="M389.4,139.7c0.1,0,0.1,0,0.2,0.1c0-0.1,0.1-0.1,0-0.2c0,0-0.1,0-0.2-0.1
				C389.5,139.6,389.4,139.7,389.4,139.7z"/>
			<path fill="#F1E4C2" d="M438.2,163.4c0-0.1,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.3-0.1c0,0,0,0.1,0,0.2
				C438.1,163.4,438.2,163.4,438.2,163.4z"/>
			<path fill="#EAD59E" d="M417.3,144.8c0,0.1,0.1,0.2,0.1,0.2c0.2,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.1-0.2
				C417.3,144.7,417.3,144.7,417.3,144.8z"/>
			<path fill="#E9D498" d="M397.1,132.9c0,0.1,0.1,0.1,0.1,0.1c0,0,0.1-0.1,0.2-0.1c0-0.1-0.1-0.1-0.1-0.1
				C397.2,132.8,397.1,132.9,397.1,132.9z"/>
			<path fill="#F0E1BB" d="M415.2,132.8c0-0.1,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.2-0.1c0,0-0.1,0.1-0.1,0.2
				C415,132.8,415.1,132.8,415.2,132.8z"/>
			<path fill="#F5ECD6" d="M416.5,132.5c-0.1,0-0.2,0-0.3,0c-0.2,0.2-0.2,0.4,0.1,0.4C416.6,132.9,416.6,132.7,416.5,132.5z"/>
			<path fill="#F6EDD9" d="M417.2,133.2c0.2-0.1,0.5-0.2,0.5-0.6C417.3,132.6,417.3,132.9,417.2,133.2
				C417.2,133.2,417.2,133.2,417.2,133.2z"/>
			<path fill="#FBF7EF" d="M432.7,166.6c0-0.1,0.1-0.4,0.1-0.4c-0.4-0.2-0.5,0.1-0.7,0.3C432.2,166.7,432.4,166.7,432.7,166.6z"/>
			<path fill="#EBD8A4" d="M439.8,166.8c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1,0.1-0.3,0.1-0.2,0.3C439.4,166.7,439.6,166.7,439.8,166.8z"
				/>
			<path fill="#F9F4E7" d="M432.7,166.6c-0.2,0-0.4,0-0.6-0.1c-0.1,0.1-0.2,0.1-0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.2
				C432.2,166.8,432.4,166.7,432.7,166.6z"/>
			<path fill="#FAF7EF" d="M431.5,144.2c-0.1,0.1-0.3,0-0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2
				C431.7,144.3,431.6,144.3,431.5,144.2z"/>
			<path fill="#E8D294" d="M430.9,148.8c0,0.1-0.1,0.1-0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.3-0.3,0.2-0.6,0.2-1
				C431.2,148.5,431.1,148.6,430.9,148.8z"/>
			<path fill="#ECD9A6" d="M398.2,131.6c0-0.1,0.1-0.1,0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0.1-0.1,0.2
				C398.1,131.6,398.2,131.6,398.2,131.6z"/>
			<path fill="#F5ECD6" d="M415.5,144.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.2,0.2,0.1c0.1,0,0.1-0.1,0.1-0.2
				C415.8,144.3,415.7,144.2,415.5,144.2z"/>
			<path fill="#EDDDB1" d="M430.6,169c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.2-0.3
				C430.7,168.9,430.7,168.9,430.6,169z"/>
			<path fill="#EAD69E" d="M440.8,170.9c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0-0.2,0-0.3c-0.3,0.1-0.5,0.3-0.7,0.5
				C440.4,170.7,440.6,170.8,440.8,170.9z"/>
			<path fill="#CA8066" d="M354.4,41.5c-0.2,0.5-0.9,0.1-1.1,0.7c-0.2,0.3-0.6-0.2-0.6-0.4c0-0.7-0.6-1-0.9-1.5
				c-0.4-0.6-0.7,0-0.8,0.1c-0.3,0.3,0.2,0.3,0.3,0.4c0.3,0.6-0.2,0.8-0.5,1c-0.4,0.3-0.6,0.7-0.3,1c0.5,0.8,0.5,1.6,0.3,2.5
				c-0.1,0.3,0.1,0.5,0.2,0.8c0.4,0.7,0.9,1.1,1.8,1c0.4,0,0.7,0,0.9,0.4c0.1,0.2,0.2,0.4,0.5,0.6c0.5,0.2,1,0.3,1.2,0.9
				c0.2,0.6,0.8,0.7,1.3,0.9c0.3,0,0.5-0.1,0.6-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2
				c0,0,0,0,0,0.2c0.1,1.3,0.8,0.8,1.4,0.6c0.3-0.1,0.3-0.6,0.7-0.6c0.1,0,0.2,0.1,0.3,0.1c0.5,0.4,1.1,0.2,1.6,0.2
				c0.4-0.2,0.5-0.6,0.8-0.9c0.1-0.2,0.2-0.3,0.2-0.5c-0.2-0.5,0.3-1,0.1-1.6c-0.1-0.1-0.3-0.2-0.5-0.2c-0.7,0.3-1.5,0.3-2.2,0.5
				c0,0,0-0.1,0-0.1c0.1-0.3,0-0.5-0.2-0.7c-0.4-0.4-0.7-0.8-0.7-1.4c0,0,0.1,0,0.1,0s-0.1,0-0.1,0c0.1,0.5,0.3,0.9,0.6,1.2
				c0.4,0.4,0.8,0.5,1.1,0c0.2-0.4,0.5-0.4,0.9-0.4c1.2-0.8,1.2-1.4-0.1-2.8c-0.3-0.2-0.6-0.4-0.9-0.5c-0.6,0-1.1,0.6-1.7,0.3
				c-0.5-0.4,0.4-0.5,0.1-0.9c-0.1-0.3-0.3-0.5-0.6-0.7c-0.2-0.1-0.3-0.1-0.5,0c-0.7,0.6-1.2,0.5-1.5-0.4c-0.3-0.3-0.7-0.6-1.1-0.2
				c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.3,0.5-0.5,0.7c-0.1,0.1-0.2,0.2-0.2,0.2C354.5,41.3,354.4,41.4,354.4,41.5z"/>
			<path fill="#C8886E" d="M361.5,49.2c-0.6,0-1.2,0.1-1.7-0.5l0,0c-0.4,0.2-1.5-0.6-1,0.7c-0.7,0.7-1.2,0.4-1.7-0.3
				c-0.1,0-0.2,0-0.2,0c-0.1,0.1-0.1,0.2,0,0.3c-0.2,0.2-0.3,0.3-0.5,0.5c0,0-0.1,0-0.1,0.1c-0.3,0.3-0.3,0.8-0.4,1.2
				c0,0.1,0,0.2,0,0.3c0.1,0.2,0.2,0.3,0.2,0.4c0,0,0,0.1,0,0.1c-0.3,0.3-0.7,0.4-0.9,0.8c0.1,0.3,0.1,0.6,0.5,0.6
				c0.5-0.1,1.1-0.6,1.6,0.2c0.4-0.9,0.9,0,1.3,0c0-0.6,0.3-1,0.9-1.1c0.9-0.1,0.9-0.5,0.3-1c0,0,0-0.1,0-0.1
				c0.1-0.1,0.2-0.3,0.1-0.4c-0.1-0.1-0.1-0.1-0.1-0.1c0.1,0.1,0.1,0.1,0.2,0.1c0.4,0.1,0.7,0,0.7-0.4c0-0.1,0-0.2,0.1-0.1
				C361.7,50.3,361.8,49.8,361.5,49.2z"/>
			<path fill="#C49E85" d="M351.2,45.8c0.3-1.1,0.4-2.2-0.4-3.2c-0.3-0.4,0.2-0.5,0.5-0.6c0.6-0.4,0.9-0.8,0-1.2
				c-0.2,0.2-0.5,0.4-0.7,0.5c0.3,0.4-0.2,0.3-0.3,0.4c-0.5,0.1-0.9,0.4-1.2,0.8c-0.4,0.5-0.6,1-0.2,1.6c0.6,0.8,0.7-0.6,1.2-0.2
				c0.2,0.2,0.2,0.3,0,0.5c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.4-0.2,0.8,0,1.2c0.4,0,0.7,0.2,1,0.5c0.2,0,0.4-0.2,0.5-0.4
				c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3C351,46,351.1,45.9,351.2,45.8z"/>
			<path fill="#C3A188" d="M358,41.1c0.2,0,0.3,0,0.5,0c0.4-0.1,0.7,0.3,1.1,0.2c0.5-0.2,0.8-0.2,0.9,0.4c0,0.1,0.1,0.2,0.2,0.3
				c0.4,0.1,0.7,0.4,1.1,0.4c0.2,0,0.3-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.4c-0.5-0.6,0.2-0.5,0.4-0.6c0.4-0.4,0.8-0.9,0.7-1.6
				c-0.5,0.2-1,0.1-1.4-0.3c-0.2-0.2-0.5-0.2-0.7-0.5c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.1,0.2-0.1,0.3c0-0.1,0-0.2-0.1-0.3
				c-0.2-0.2-0.5-0.1-0.7,0c-0.6,0-1.1,0.2-1.7-0.2c-0.2-0.2-0.5,0-0.7,0.2c0,0.1,0,0.2,0,0.2C357.6,40,357,40.6,358,41.1z"/>
			<path fill="#C3A38A" d="M360.6,50.2c0,0.5-0.3,0.7-0.7,0.7l0,0c-0.1,0.3-0.2,0.4-0.5,0.5c-0.1,0-0.1,0.1-0.1,0.1
				c0,0,0.1,0.1,0.1,0.1c0.2,0,0.5,0.1,0.8,0.7c-0.8-0.8-1.6,1.3-2.1-0.7c-0.5,1.1,0.3,1.4,0.6,2c0.2-0.2,0.5-0.5,0.7-0.7
				c0.7,0,1,0.6,1.5,0.9c0.1,0,0.2,0,0.3,0c0.2-0.4,0.9-0.5,0.7-1.2c0-0.2,0-0.3,0-0.5c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2,0-0.3
				c0.4-0.2,0.5-0.7,0.7-1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.6-0.6-1-0.5-1.6c-0.1-0.1-0.1-0.2-0.2-0.2c-0.4,0-0.6,0.3-0.5,0.7
				C361.2,49.5,361.5,50.4,360.6,50.2L360.6,50.2z"/>
			<path fill="#C59A81" d="M362,42c-0.2,0-0.3,0-0.5,0c-0.3,0.2-0.3,0.4-0.2,0.7c0,0.3-0.4,0.6,0.1,0.8c1.1,0.4,0.6,1,0.1,1.6
				c-0.7,0.6-0.6,1.1,0.5,1.5c0.2,0,0.3,0,0.5,0c0.4-0.2,0.3-0.6,0.4-0.9c0-0.5,0.4-0.6,0.8-0.8c0.1-0.4,0.8-0.5,0.5-1.2
				c-0.4-0.4,0.2-1.5-1-1.4C362.8,42,362.5,41.7,362,42z"/>
			<path fill="#C3A087" d="M365.4,46.1c0.7,0,0.7-1,1.4-1.1c-0.8,0-1.6-0.1-2.4-0.1c-0.4,0.5-1.2-0.5-1.6,0.4
				c-0.1,0.7-0.3,1.3-0.1,2c0.5,0.8,1.2,0.7,1.9,0.3c0-0.1,0-0.2,0-0.2C365.1,47,365.1,46.4,365.4,46.1z"/>
			<path fill="#C3A188" d="M357,51.8c-0.3,0-0.5-0.2-0.7-0.5c-0.2-0.3-0.3-0.7-0.6-1c-0.2-0.1-0.4-0.3-0.6-0.3
				c-0.4-0.1-0.7,0.1-1,0.2c-0.2,0.1-0.4,0.1-0.7,0.1c-0.4,0-0.7,0.3-0.7,0.7c0,0.5,0.4,0.5,0.8,0.5c0.4,0.1,0.6,0.3,0.3,0.7
				c0,0.3,0,0.7,0,1c0,0.5,0.3,0.2,0.5,0.2c0.3-0.3,0.3-0.8,0.5-1.2c0.2-0.3,0.4-0.5,0.7-0.2c-0.1,0.2-0.1,0.5-0.2,0.7
				c0.5-0.3,1.2-0.3,1.7-0.7C357,52,357.1,52,357,51.8C357,51.8,357,51.8,357,51.8z"/>
			<path fill="#C78D73" d="M358,41.1c-0.2-0.6,0.4-1.2-0.2-1.7c0,0,0,0,0,0c-0.6,0.1-0.8-0.3-1-0.7c-0.3,0.1-0.3,0.5-0.5,0.7
				c-0.5,0.4-0.2,0.8,0,1.2c-0.3,0.8,0.2,1.4,0.8,1.6C357.5,42.3,357.6,41.5,358,41.1z"/>
			<path fill="#CA8066" d="M350.5,46.3c-0.1,0.2-0.2,0.5-0.3,0.7c0.2,0.3,0.3,0.6-0.2,0.7c-0.2-0.1-0.3-0.1-0.5,0
				c0.6,0.4,0.5,1.7,1.7,1.5c0.7-0.3,0.9-0.9,0.8-1.6C351.8,47,351.5,46.2,350.5,46.3z"/>
			<path fill="#C78F75" d="M353.9,51.4c-0.3,0-0.7,0.1-0.8-0.3c0-0.3,0.3-0.2,0.5-0.2c0.3-0.5,0.1-1-0.3-1.2c-0.6-0.3-1.2-0.6-1.8,0
				c-0.5,1,0.5,1.1,0.9,1.5c0.1,0.2,0,0.5,0.3,0.7c0.1,0.1,0.1,0.2,0.2,0.2c0.2-0.1,0.5-0.2,0.7-0.3C353.8,51.7,354,51.6,353.9,51.4
				z"/>
			<path fill="#C8876D" d="M363,47c-0.2-0.6-0.1-1.1,0-1.7c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.3,0-0.4,0.1c0,0.4,0,0.8,0,1.2
				c-0.2,0.5-0.5,0.9-0.5,1.4c0.3,0.6,0.4,1.4,1.1,1.7c0.3,0.1,0.6,0,0.9-0.2c0.1-0.2,0.1-0.4,0-0.6c0-0.1,0-0.1,0-0.2
				C364,48,363.1,47.7,363,47z"/>
			<path fill="#C98267" d="M351.5,49.7c1,0,1.8,0.1,2.2,1.2c0.2,0,0.5,0,0.7,0c0.2-0.1,0.1-0.3,0.1-0.4c0-0.6-0.6-0.8-0.6-1.4
				c0-0.3,0.3-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.3c0-0.2,0-0.3,0-0.5c0-0.5-0.4-0.3-0.6-0.4c-0.4,0.1-0.5,0.4-0.7,0.7
				c-0.1,0.2-0.3,0.3-0.4,0.5c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.3,0.4-0.5,0.5c-0.1,0.1-0.2,0.2-0.3,0.3
				C351.5,49.5,351.5,49.6,351.5,49.7z"/>
			<path fill="#C49F86" d="M362.8,51.8c0.3,0.2,0.1,0.8,0.7,0.8c0-0.5-0.2-1.1,0.8-1.2c0.7,0,0.2-0.7-0.1-1.1c0,0-0.1-0.1-0.1-0.1
				c0,0-0.1-0.1-0.1-0.1c-0.2-0.1-0.5-0.1-0.7-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.2l0,0
				C362.2,51,362,51.5,362.8,51.8z"/>
			<path fill="#C5977E" d="M363,47c0.1,0.7-0.2,1.6,0.9,1.7c0.5-0.2,0.9-0.6,0.7-1.2C364.2,47.1,363.4,47.6,363,47z"/>
			<path fill="#524D46" d="M376,38.6c-0.1-0.1-0.3-0.3-0.4-0.4c-0.4-0.2-0.8-0.5-1.2,0c0,0.5,0,1,0,1.4c0.3,0.1,0.6,0.3,0.9,0
				c0.1,0,0.2,0,0.3,0C375.6,39.2,375.8,38.9,376,38.6z"/>
			<path fill="#C88B71" d="M362,42c0.2,0.2,0.5,0.3,0.6,0.5c0.5,0.7,0.3-0.3,0.6-0.2c0.1-0.5-0.1-0.9-0.5-1.2
				c-0.3-0.1-0.5-0.2-0.8-0.4C361.7,41.1,362.1,41.5,362,42z"/>
			<path fill="#C69177" d="M357.7,39.1c0.4,0,0.9,0,1.2,0.3c0.6,0.5,0.9,0.2,1.2-0.3C359.4,38.5,358.2,38.5,357.7,39.1z"/>
			<path fill="#C78C72" d="M363,45.4c0.3-0.1,0.6-0.2,0.9,0.1c0.2,0.2,0.4,0.6,0.7,0.3c0.3-0.3-0.1-0.6-0.2-0.8
				c-0.6-0.3,0.3-0.9-0.2-1.2c-0.1,0.4-0.4,0.7-0.7,1C363.3,44.8,362.6,44.8,363,45.4L363,45.4z"/>
			<path fill="#C69277" d="M362.8,51.8c-0.3-0.5-0.3-1-0.3-1.5c-1-0.2-0.6,0.8-1,1.2c0,0.2,0.1,0.2,0.3,0.2c0,0.1,0,0.2,0,0.2
				C362,51.6,362.7,53,362.8,51.8z"/>
			<path fill="#C5987E" d="M356.3,40.6c0-0.4,0-0.8,0-1.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.5-0.5-0.8-0.1
				c-0.3,0.4,0.1,0.5,0.3,0.7c0.1,0.1,0,0.3-0.1,0.4C355.7,40.1,356,40.4,356.3,40.6z"/>
			<path fill="#413E39" d="M374.3,38.1c0.4,0,0.8,0,1.2,0c-0.5-0.4-1.2-0.8-0.5-1.6c0.2-0.2,0.1-0.4-0.2-0.4c-0.7,0-0.5,0.5-0.6,0.8
				C374.3,37.4,374.3,37.8,374.3,38.1z"/>
			<path fill="#C5987E" d="M356.7,38.6c0.3,0.9,0.4,1.1,1,0.7c-0.4-0.6-0.3-1.2-0.4-1.9c-0.5,0.3-0.5,0.6-0.2,1.1c0,0.1,0,0.2,0,0.3
				C356.9,38.7,356.8,38.7,356.7,38.6z"/>
			<path fill="#C79076" d="M354.4,41.5c0.1-0.1,0.2-0.2,0.2-0.3c-0.4-0.2-0.7-0.5-1-0.9c-0.2-0.2-0.5-0.4-0.7-0.1
				c-0.2,0.3,0.1,0.5,0.3,0.7C353.6,41.2,354,41.3,354.4,41.5z"/>
			<path fill="#C78D72" d="M355.5,52.1c0-0.8-0.4-0.6-0.7-0.3c-0.4,0.5-0.4,1.1-0.5,1.7c0.4,0.1,0.4-0.2,0.5-0.5
				C354.9,52.6,355,52.2,355.5,52.1z"/>
			<path fill="#C78D73" d="M365.4,46.1c-1,0-0.5,0.8-0.7,1.2c0.4-0.1,0.9-0.2,1.4-0.3C366.1,46.5,365.4,46.5,365.4,46.1z"/>
			<path fill="#C5987E" d="M351.9,39.1c0,0.4,0.3,0.4,0.6,0.4c0.2,0,0.5,0,0.5-0.4c0-0.3-0.2-0.5-0.5-0.6
				C352.1,38.6,352,38.8,351.9,39.1z"/>
			<path fill="#C49E85" d="M349.6,47.8c0.2,0,0.3,0,0.5,0c-0.1-0.2-0.2-0.4-0.5-0.5c-0.3-0.1-0.6-0.3-0.8,0.1
				c-0.1,0.2-0.2,0.5,0,0.7C349.1,48.3,349.2,47.7,349.6,47.8z"/>
			<path fill="#C69379" d="M361.8,52.6c-0.2,0.4-0.8,0.6-0.7,1.2C361.7,53.6,362.9,53.8,361.8,52.6z"/>
			<path fill="#C78E74" d="M360.8,39.1c-0.1,0.2-0.1,0.4-0.2,0.5c-0.2,0.2-0.4,0.4,0,0.7c0.2,0.2,0.4,0.3,0.6,0.1
				c0.2-0.2,0.1-0.4,0-0.6c-0.1-0.2-0.1-0.5-0.1-0.7C361,39.1,360.9,39.1,360.8,39.1z"/>
			<path fill="#47433E" d="M384.3,54.5c0.5,0.1,1,0.3,1.7,0c-0.4-0.6-1-0.4-1.5-0.5C384.5,54.1,384.4,54.3,384.3,54.5z"/>
			<path fill="#C5987E" d="M352.8,38.2c0.7,0.1,1,0.7,1.4,0.3c0.1,0,0-0.3-0.1-0.4C353.8,37.7,353.5,38.1,352.8,38.2z"/>
			<path fill="#C78C72" d="M359.1,54.6c0.5,0,0.8-0.2,0.9-0.6c0-0.2-0.1-0.3-0.3-0.3C359.3,53.8,359.2,54.2,359.1,54.6z"/>
			<path fill="#C78E73" d="M356.4,54.1c0.4,0.6,0.8,0.7,1.3,0.4C357.4,54.1,357,54.1,356.4,54.1z"/>
			<path fill="#C78B71" d="M349.3,46.5c0.2-0.4,0.1-0.8,0-1.2C348.5,46.3,348.5,46.3,349.3,46.5z"/>
			<path fill="#C79177" d="M353.4,39.3c0.5,0.3,0.8,0.4,1.2,0.6C354.6,39.2,354.3,39.1,353.4,39.3z"/>
			<path fill="#C6957B" d="M352.9,52.1c0-0.1,0-0.2,0-0.2c-0.5-0.1-0.3,0.4-0.5,0.5c-0.2,0.1-0.3,0.2-0.1,0.4
				c0.1,0.2,0.3,0.2,0.5,0.1C353.1,52.6,353,52.3,352.9,52.1z"/>
			<path fill="#C78F75" d="M351.5,49.4c0.2,0,0.3,0,0.5,0c0.5-1.3,0.5-1.5-0.3-1.9c0.1,0.6-0.2,1.2-0.5,1.7
				C351.3,49.3,351.4,49.4,351.5,49.4z"/>
			<path fill="#423F3A" d="M384.5,48c0.3-0.1,0.6-0.2,0.6-0.5c0-0.1-0.1-0.3-0.2-0.3c-0.4,0-0.5,0.4-0.7,0.6
				C384.4,47.9,384.5,48,384.5,48z"/>
			<path fill="#C79076" d="M361.3,38.6c0,0.6,0.3,0.5,0.7,0.5c0-0.3,0.1-0.7-0.2-0.9C361.5,38.1,361.6,38.7,361.3,38.6z"/>
			<path fill="#C5977E" d="M365.1,48.4c0,0.4,0,0.6,0.3,0.8c0,0,0.2-0.1,0.2-0.2C365.6,48.7,365.5,48.4,365.1,48.4z"/>
			<path fill="#C88A70" d="M350,44.4c0.5,0.1,0.6-0.2,0.7-0.6c0-0.2-0.1-0.4-0.3-0.3c-0.2,0.1-0.3,0.3-0.4,0.4
				C350,44.1,350,44.2,350,44.4z"/>
			<path fill="#C3A188" d="M352.4,52.3c0.2-0.2,0.3-0.3,0.5-0.5c-0.2-0.2-0.3-0.5-0.5-0.7C351.9,51.5,352.3,51.9,352.4,52.3z"/>
			<path fill="#C69277" d="M363.9,50.4c0.1,0,0.2,0,0.2,0c0.3-0.5,0.3-1-0.2-1.5c0,0.2,0,0.3,0,0.5
				C363.7,49.8,363.6,50.1,363.9,50.4z"/>
			<path fill="#C69278" d="M360.8,53.7c-0.3-0.5-0.6-1.1-1.5-0.9C359.8,53.1,360.3,53.4,360.8,53.7z"/>
			<path fill="#C69177" d="M365,42.1c-0.2,0-0.3,0.2-0.3,0.3c0,0.1,0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.2,0.3-0.3
				C365.2,42.3,365.1,42.2,365,42.1z"/>
			<path fill="#C6947B" d="M365.2,43.4c0,0.1-0.1,0.2-0.2,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1-0.1,0.2-0.2,0.2-0.4
				C365.5,43.5,365.4,43.4,365.2,43.4z"/>
			<path fill="#403D39" d="M375.2,39.6c-0.3-0.1-0.6-0.1-0.9,0c0,0.2,0,0.3,0,0.5C374.6,39.9,375.1,40.2,375.2,39.6z"/>
			<path fill="#C8886E" d="M353.9,51.4c-0.1,0.2-0.1,0.3-0.2,0.5c0.1,0.2,0.1,0.3,0.2,0.5C354.4,52,354.5,51.7,353.9,51.4z"/>
			<path fill="#262523" d="M382,69.1c0.2,0,0.5-0.1,0.5-0.4c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.3,0.2-0.4,0.4C381.9,69,382,69,382,69.1
				z"/>
			<path fill="#C69379" d="M349.6,49.7c0.2-0.3,0.3-0.5,0.2-0.7c0,0-0.2,0-0.2,0C349.5,49.2,349.4,49.4,349.6,49.7z"/>
			<path fill="#C5957B" d="M360.2,37.9c0,0.1-0.1,0.2-0.2,0.2c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1-0.1,0.2-0.2
				C360.4,38,360.3,38,360.2,37.9z"/>
			<path fill="#524D47" d="M379.4,62.9c0.2-0.1,0.5-0.2,0.7-0.3c-0.3-0.4-0.5,0-0.8,0.1C379.3,62.7,379.3,62.8,379.4,62.9z"/>
			<path fill="#353330" d="M378.6,59.5c0.1,0.1,0.4,0.2,0.4,0.1c0.2-0.3,0-0.4-0.2-0.6C378.8,59.2,378.7,59.4,378.6,59.5z"/>
			<path fill="#CA8066" d="M350.3,41.8c0.2-0.1,0.5,0,0.6-0.3c0.1-0.2-0.2-0.1-0.3-0.1C350.4,41.5,350.4,41.6,350.3,41.8z"/>
			<path fill="#C5987E" d="M354.8,41c0.2-0.2,0.5-0.3,0.5-0.7C354.9,40.4,354.8,40.7,354.8,41z"/>
			<path fill="#C78D72" d="M350,50.6c0,0.1-0.1,0.1-0.1,0.2c0,0,0.1,0.1,0.2,0.1c0-0.1,0.1-0.1,0.1-0.2
				C350.2,50.6,350.1,50.6,350,50.6z"/>
			<path fill="#4D4843" d="M380.8,66.5c0.3-0.3,0.2-0.6-0.2-0.7C380.6,66,380.7,66.2,380.8,66.5z"/>
			<path fill="#1C1C1A" d="M383.3,55.9c0.1,0.1,0.2,0.2,0.2,0.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0-0.4-0.3-0.4
				C383.4,55.7,383.3,55.8,383.3,55.9z"/>
			<path fill="#5B544D" d="M392.3,50.4c-0.2,0.2-0.1,0.5,0,0.7c0,0.4,0.4,0.5,0.7,0.7c0.4-0.1,0.5-0.4,0.5-0.7
				c0.1-0.2,0.3-0.4,0.3-0.6c0-0.1-0.1-0.1-0.2-0.1c0.1,0,0.1,0.1,0.2,0.1c0,0.2-0.2,0.4,0,0.6c0,0.1,0,0.2,0,0.3
				c0.2,0.1,0.3,0.1,0.5,0c0.2-0.2,0.3-0.3,0.5-0.5c0.5-0.2,0.6-0.7,0.7-1.2c0.1-0.2,0.7-0.6-0.1-0.5c-0.3,0-0.6,0-0.9-0.2
				c-0.7,0.2-0.8-0.5-1.2-0.7c-0.1-0.2-0.3-0.3-0.4-0.5c-0.3,0-0.2,0.2-0.3,0.4c-0.5,0.6,0.4,1.1,0,1.7
				C392.3,50,392.4,50.2,392.3,50.4z"/>
			<path fill="#565049" d="M411.2,38.4c0.3-0.1,0.4-0.2,0.2-0.5c-0.3-0.4-0.1-0.6,0.3-0.4c0.6,0.3,0.8-0.1,1-0.5
				c0.2-0.1,0.1-0.3,0-0.5c-0.6-0.3-0.5-0.9-0.5-1.4c-0.1-0.1-0.1-0.1-0.2-0.1c-0.4,0.2-0.8,0.3-1.2,0.5c-0.1,0.2-0.2,0.3,0,0.5
				c0.2,0.1,0.4,0.2,0.7,0.2c0.2,0.1,0.2,0.2,0,0.2c-0.7,0.1-0.9,0.8-1.4,1.2C410.3,38.2,410.8,38.3,411.2,38.4z"/>
			<path fill="#4A4540" d="M411,51.6c-0.6-0.1-1.2-0.2-1.7-0.3c-0.2-0.1-0.5-0.2-0.6,0c-0.2,0.3,0.1,0.5,0.2,0.6
				c0.9,0.4,1.5,0.9,1.8,1.9c0.1,0.4,0.6,0.3,0.7-0.2c0.1-0.4,0.1-0.8-0.1-1.2C411,52.1,411.1,51.9,411,51.6z"/>
			<path fill="#47433E" d="M408.4,23.5c-0.2-0.1-0.3-0.3-0.5-0.4c0.2-0.3,0.2-0.5,0-0.8c-0.8,0.7-1.3,0.2-1.7-0.5
				c-0.1,0.2-0.3,0.3-0.5,0.5c0.8,0.3,0.6,1.1,0.7,1.7l0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0.1,0.2,0.2
				c0.2,0.3,0.2,0.5-0.2,0.7c-0.2,0.3-0.6,0.5-0.5,1c0.6-0.3,1.1-0.7,1.8-0.4c0.2-0.6-0.4-1,0-1.5C408.2,23.8,408.3,23.7,408.4,23.5
				z"/>
			<path fill="#514C46" d="M393.9,60.5c0.6,0.4,1,0,1.5-0.3c0.3-0.2,0.4-0.4,0.3-0.7c-0.4-0.1-0.6-0.3-0.3-0.7
				c0.2-0.2,0.1-0.3,0-0.5c-1-0.2-0.6,1.1-1.3,1.2c-0.3-0.2,0-0.8-0.5-0.8c-0.3,0-0.3,0.3-0.3,0.5c-0.1,0.2-0.2,0.3,0,0.5
				c0,0.2,0,0.3,0,0.5c0.1,0.2,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3,0C393.9,60.4,393.9,60.5,393.9,60.5z"/>
			<path fill="#524D46" d="M375.2,46.3c0,0.6,0,1.3,0.9,1.3c0.8,0.1,1.2-0.2,1.3-1c0.2-0.3,0.2-0.5,0-0.8l0,0
				c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0-0.3,0-0.5,0C375.9,46.2,375.5,46.1,375.2,46.3z"/>
			<path fill="#514C46" d="M390.6,46.4c0.2-0.3,0.4-0.5,0.7-0.5c0.2-0.2,0.4-0.4,0.5-0.7c0-0.4-0.2-0.6-0.4-0.9
				c-0.1-0.3-0.3-0.6-0.5-0.8c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0.3-0.3,0.6-0.5,0.8c-0.4,0.4-0.4,0.9-0.7,1.4
				c-0.1,0.6,0.4,0.6,0.7,0.7C390.2,46.5,390.4,46.5,390.6,46.4z"/>
			<path fill="#4F4A44" d="M416.3,33.3c0.2-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.5-0.2,0.5-0.5c0.4-0.4,0.1-0.9-0.2-1.1
				c-0.3-0.3-0.7,0.2-1.1,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.1,0.2
				c-0.1,0.2-0.2,0.5-0.2,0.7c-0.2,0.3,0.1,0.5,0.2,0.7c0.2,0,0.3,0,0.5,0C416.2,33.6,416.3,33.5,416.3,33.3z"/>
			<path fill="#565049" d="M388.7,66.5c0.4-0.6,1.2-0.4,1.7-0.8c0.2-0.2,0.6-0.4,0.5-0.8c-0.2-0.5-0.7-0.2-0.9,0
				c-0.6,0.4-1.3,0.5-1.5,1.3C388.3,66.5,388.4,66.6,388.7,66.5z"/>
			<path fill="#514C46" d="M389.4,56.2c-0.5-0.7-1.4-0.6-2.1-0.8c-0.8-0.2-0.9,0.8-1.3,1.4C387,55.7,388.3,56.3,389.4,56.2
				L389.4,56.2z"/>
			<path fill="#534E48" d="M405,53.7c0.1,0.2,0.2,0.3,0.2,0.5c0.3,0.3,0.6-0.2,0.9,0c0.1,0,0.2,0,0.3,0c0.1-0.1,0.3-0.1,0.4-0.2
				c0.3-0.4-0.4-0.9,0.1-1.3c0-0.1-0.1-0.2-0.2-0.2c-0.5-0.3-0.9-0.6-1,0.3c0.1,0.1,0.4,0.2,0.1,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
				C405.2,53.3,405.1,53.6,405,53.7z"/>
			<path fill="#554F48" d="M391.8,57.1c1.4,0.5,0.4-0.8,0.7-1c0,0,0-0.1,0-0.1c-0.4,0-0.8,0-1.3,0c-0.1,0.2-0.4,0-0.5,0.3
				c-0.2,0.3-0.3,0.6,0,0.9C391.2,57,391.5,57.2,391.8,57.1z"/>
			<path fill="#494540" d="M399.5,60.2c0.5,0.7,1.3,0.6,1.9,0.7c0.1-0.1,0.1-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1
				c-0.6-0.2-1.2-0.3-1.4-1.2c-0.2-0.8-0.8-0.9-1.3,0c-0.1,0.1-0.1,0.2-0.1,0.3C398.8,60.1,399.2,60,399.5,60.2z"/>
			<path fill="#46423D" d="M405.3,15.4c1.9,0,1.9,0,1.7-1.7c-0.2,1-1.4,0.6-1.8,1.4C405.2,15.2,405.2,15.3,405.3,15.4z"/>
			<path fill="#4B4641" d="M409.3,25.2c0.1,0.2,0.3,0.3,0.4,0.5c0.2,0,0.3-0.2,0.5-0.3c0.8-0.6,0.5-1.3,0.2-2
				c-0.2-0.1-0.3,0.1-0.4,0.3c0,0.1,0,0.2,0,0.3c-0.1,0.2-0.3,0.2-0.5,0c-0.2,0.4-0.9,0.3-0.7,1C409,25.1,409.1,25.2,409.3,25.2z"/>
			<path fill="#3E3B37" d="M407.3,48.7c0.1,0,0.2,0,0.2,0c0.4,0.3,0.9,0.8,1.2,0.3c0.2-0.3-0.3-0.9-0.7-1.3
				c-0.6-0.5-0.7,0.2-0.9,0.5C407,48.4,406.9,48.7,407.3,48.7z"/>
			<path fill="#403D39" d="M411.9,45.3c0,0.5,0.3,0.7,0.8,0.7c0.2-0.3,0.8-0.3,0.5-0.9c-0.1-0.6-0.5-1-1-0.9
				C411.5,44.4,412.1,45,411.9,45.3z"/>
			<path fill="#58524B" d="M411,19.4c-0.2-0.7-0.7-0.1-1-0.2c-0.6,0.5-1.3,0.9-0.5,1.8c0.2,0.1,0.3,0,0.5-0.1c0.3-0.3,0.7-0.5,0.7-1
				C410.9,19.8,410.9,19.6,411,19.4z"/>
			<path fill="#48443F" d="M415.1,44.6c0.2,0,0.4,0,0.5,0c0.3-0.3,0.2-0.8,0.2-1.2c-0.1,0-0.1,0-0.2,0c-0.3,0.1-0.5,0.1-0.8,0.2
				c-0.2,0-0.5,0-0.7,0c-0.3,0.6-0.6,1.2,0.6,0.8C414.8,44.4,414.9,44.6,415.1,44.6z"/>
			<path fill="#4A4540" d="M410.7,33.4c0.4-0.5,0.2-1.1,0.2-1.6c-0.1-0.4-0.4-0.6-0.7-0.5c-0.3,0.1-0.9,0.3-0.5,0.9
				c0.2,0,0.3,0.1,0.3,0.3C410.3,32.8,410.5,33.1,410.7,33.4L410.7,33.4z"/>
			<path fill="#514C46" d="M391.3,47.5c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1,0.1-0.4,0-0.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9
				c-0.1,0.1-0.1,0.2,0,0.3c0.5,0,1,0.3,1.4,0.5c0.2-0.2,0.1-0.4,0.2-0.7C391.3,47.7,391.3,47.6,391.3,47.5L391.3,47.5z"/>
			<path fill="#555049" d="M392,68.5c0.2-0.1,0.4-0.2,0.5-0.3c0.1,0.1,0.2,0.1,0.2,0c0.2-0.3,0.5-0.6,0.7-0.9
				c-0.3-0.3-0.7-0.3-1.2-0.3c0,0.4-0.5,0.4-0.7,0.7c-0.1,0.1-0.2,0.3-0.2,0.4C391.4,68.4,391.7,68.5,392,68.5z"/>
			<path fill="#555049" d="M410.8,43.2c0.4,0.5,0.6,0.6,0.9,0c0.2-0.3,0.4-0.6,0.7-0.7c0.2,0.2,0.3,0.6,0.7,0.5
				c-0.2-1.3-1.1-0.9-1.9-0.8C411,42.5,411.1,42.9,410.8,43.2z"/>
			<path fill="#232220" d="M376.4,42c0.4-0.8-0.8-0.8-0.7-1.5c-0.3,0-0.5,0.2-0.5,0.4c0.1,0.4-0.2,1.1,0.5,1.2
				C375.9,42.2,376.2,42,376.4,42L376.4,42z"/>
			<path fill="#34322F" d="M417.9,36.2c-0.1-0.2-0.2-0.3-0.3-0.5c-0.4-0.5,0-1.3-0.9-1.9c0.3,1.1,0,2.1,0.7,2.9
				C417.8,36.7,418.1,36.7,417.9,36.2z"/>
			<path fill="#554F49" d="M396.8,28.8c1.1,0.6,1.8-0.4,2.7-0.8c0.1-0.2,0.2-0.4-0.2-0.5c-0.9-0.3-1.1,1.1-2,1
				C397.2,28.7,396.9,28.6,396.8,28.8z"/>
			<path fill="#3C3936" d="M392,43.4c0.2-0.9-0.3-1.4-1-1.8c-0.2,0.3,0.3,0.7-0.2,0.9c-0.1,0.6,0.3,0.9,0.7,1.1
				C391.7,43.6,391.9,43.6,392,43.4z"/>
			<path fill="#44413C" d="M407.4,46.3c0.7-0.4,1.4-0.1,2.2,0c-0.4-0.6-0.8-0.8-1.2-0.7c-0.9,0.4-1.3-0.1-1.7-0.8l0,0
				c0,0.4-0.1,0.8,0.2,1.2C407,46.2,407.2,46.3,407.4,46.3z"/>
			<path fill="#111210" d="M408.1,42.3c-0.4-0.2-0.7,0-1.1,0.1c-0.3,0.1-0.5,0.2-0.5,0.6c0,0.2,0.1,0.2,0.3,0.3
				c0.3,0.1,0.6,0.2,0.8-0.2C407.7,42.8,407.9,42.6,408.1,42.3z"/>
			<path fill="#2B2A27" d="M402.8,22.3c-0.4-0.3-0.7-0.5-1.1-0.8c-0.2,0.6-0.1,1.1,0.2,1.6c0.3,0.5,0.5,0.3,0.7-0.1
				C402.4,22.7,402.6,22.5,402.8,22.3z"/>
			<path fill="#3F3C38" d="M408.3,20.6c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.4,1.5-0.3,0.5-1.2c-1.3-1-0.6,0.6-1,0.7
				C408.2,20.2,408.2,20.4,408.3,20.6z"/>
			<path fill="#544E48" d="M396.1,41.8c-0.3,0-0.8-0.4-1,0.2c0.1,0.2,0.1,0.5,0.2,0.7c0.4-0.1,0.3,1.4,1,0.3
				C396.5,42.6,396.3,42.2,396.1,41.8z"/>
			<path fill="#111110" d="M412,20.6c0.2,0.2,0.1,0.6,0.5,0.7c0.2-0.1,0.1-0.3,0.2-0.4c0.4-1.2,0.3-1.2-0.5-1.4
				C411.9,19.8,411,20,412,20.6z"/>
			<path fill="#565049" d="M398.3,47.1c-0.2-0.4,0.1-1.2-0.7-1.2c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0.1,0.1
				c-0.1,0.3-0.6,0.4-0.5,0.7c0.2,0.5,0.8,0.3,1.2,0.5C398.4,47.2,398.4,47.2,398.3,47.1C398.3,47.1,398.3,47.1,398.3,47.1z"/>
			<path fill="#35332F" d="M413.4,40.8c0.1,0.2,0.2,0.5,0.4,0.4c0.3,0,0.2-0.3,0.2-0.5c0.1-0.8-0.3-1.2-1.1-1.2l0,0
				c0,0.2,0,0.3,0,0.5C413.2,40.3,413.2,40.6,413.4,40.8z"/>
			<path fill="#4B4741" d="M384.1,65c0.4,0.5,0.8,0.9,1.1,1.5C385.5,65.4,385.2,65,384.1,65z"/>
			<path fill="#47433E" d="M382.9,34.3c0.3,0,0.6,0,0.8-0.1c0.3-0.2,0.3-0.5,0.3-0.9c0-0.1-0.2-0.3-0.3-0.3
				c-0.5-0.1-0.4,0.4-0.5,0.6c-0.1,0.1-0.2,0.2-0.3,0.4C382.8,34.2,382.8,34.3,382.9,34.3z"/>
			<path fill="#4C4842" d="M393.2,48.2c0.1,0.7,0.2,1.5,1.2,0.7c-0.4-0.4-0.3-1-0.5-1.5l0,0C393.7,47.7,393.6,48.1,393.2,48.2z"/>
			<path fill="#373531" d="M402.3,35.8c0.2,0,0.5,0.1,0.6-0.1c0.1-0.3-0.2-0.4-0.4-0.5c-0.4-0.1-0.7-0.2-1.2-0.3
				c0.1,0.4,0.2,0.6,0.3,0.8C401.8,35.9,402.1,36,402.3,35.8z"/>
			<path fill="#33312E" d="M393.9,62.4c0.1-0.3,0.2-0.6,0.2-1c-0.4-0.2-0.6-0.8-1.2-0.5c-0.9,0.5,0.3,0.8,0.1,1.2
				C393.3,62.3,393.6,62.7,393.9,62.4z"/>
			<path fill="#3B3935" d="M381,39.1c0.8-0.9-0.7-1.1-0.5-1.8C380,38.1,380.6,38.6,381,39.1L381,39.1z"/>
			<path fill="#1A1918" d="M397.8,59.6c-0.3-0.1-0.7-0.1-0.8,0.3c-0.2,0.4,0.4,0.5,0.5,0.8c0.2,0.4,0.5-0.1,0.8-0.2
				C398.4,60,398.2,59.7,397.8,59.6z"/>
			<path fill="#44413C" d="M408.1,44.8c-0.2-0.2-0.7,0-0.8-0.5c-0.1-0.3-0.3-0.4-0.5-0.3c-0.4,0.2-0.4,0.4-0.2,0.8c0,0,0,0,0,0
				c0.6-0.3,0.9,0.8,1.5,0.3C408.1,45.1,408.1,44.9,408.1,44.8z"/>
			<path fill="#3D3B37" d="M412,20.6c-0.4-0.5,0.6-0.7,0.2-1.2c-0.4-0.2-0.8,0.4-1.2,0c-0.1,0.2-0.2,0.3-0.3,0.5
				C411.1,20.2,411.4,20.7,412,20.6z"/>
			<path fill="#3A3834" d="M404.7,40.1c-0.1-0.3,0-0.7,0.3-1.2c-0.8,0.3-1.3,0.3-1.7,0.8c0.3,0.3,0.6,0.3,0.9,0.5
				c0.1,0.1,0.2,0.2,0.3,0.2C404.7,40.3,404.7,40.2,404.7,40.1z"/>
			<path fill="#514C46" d="M391.3,47.5c0.3,0.1,0.5,0.3,0.8,0.4c0.2-0.2,0.4-0.3,0.6-0.4c-0.1-0.5-0.4-0.8-0.9-0.9
				C391.2,46.6,391.5,47.3,391.3,47.5C391.3,47.5,391.3,47.5,391.3,47.5z"/>
			<path fill="#040404" d="M410.5,56.6c0.5-0.1,0.3-0.7,0.5-1c-0.1-1.1-0.6-0.5-1-0.2C410.1,55.9,410.2,56.3,410.5,56.6z"/>
			<path fill="#534E48" d="M405.2,54.5c-0.2,0-0.4,0-0.6,0c-0.2,0-0.6,0-0.6,0.3c0,0.3,0.4,0.2,0.6,0.2c0.4,0,0.5,0.5,0.8,0.5
				c0.6-0.3,0.4-0.5-0.1-0.7C405.4,54.6,405.3,54.5,405.2,54.5z"/>
			<path fill="#534E47" d="M399.6,64.4c0.1,0.4,0.3,0.6,0.7,0.7c0.2,0,0.4-0.1,0.3-0.3c-0.2-0.3-0.3-0.8-0.7-0.9
				C399.6,63.8,399.7,64.2,399.6,64.4z"/>
			<path fill="#161514" d="M413.2,29c-0.6,0-0.4,0.6-0.6,0.9c-0.1,0.3,0.3,0.4,0.6,0.4c0.6,0,0-0.8,0.5-0.9
				C413.7,29.3,413.4,29.1,413.2,29z"/>
			<path fill="#413E39" d="M394.1,62.8c0.7,0.3,1.4,0.6,1.3-0.8c-0.4,0.2-1,0-1.3,0.6C394.1,62.7,394.1,62.7,394.1,62.8
				C394.1,62.8,394.1,62.8,394.1,62.8z"/>
			<path fill="#514C45" d="M404.7,40.1c-0.1,0.1-0.1,0.2-0.2,0.3c0.6,0.5,0,1.6,1,1.9c0.3-0.3,0.2-0.6,0-0.9
				C405.2,40.9,405.3,40.3,404.7,40.1z"/>
			<path fill="#4F4A44" d="M410.7,33.4c0.1,0.2,0.2,0.3,0.4,0.5c0.3,0.3,0.6,0.1,0.8-0.1c0.1-0.2,0.2-0.4,0.1-0.5
				c-0.2-0.3-0.5-0.1-0.7,0C411.1,33.2,410.9,33.3,410.7,33.4C410.7,33.4,410.7,33.4,410.7,33.4z"/>
			<path fill="#413E39" d="M393.7,57c-0.1,0.4,0.2,0.5,0.5,0.5c0.3,0,0.6-0.2,0.7-0.5c0-0.3-0.3-0.4-0.6-0.5
				C393.9,56.5,393.8,56.7,393.7,57z"/>
			<path fill="#1E1E1C" d="M409.3,19.2c0.4,0.6-0.5,0.7-0.5,1.2c0.1,0.3,0.3,0.5,0.4,0.8c0.2,0,0.3-0.1,0.3-0.3
				c-0.6-0.7,0.8-1,0.4-1.6c0,0,0,0,0,0c-0.1-0.1,0-0.4-0.2-0.5C409.6,18.9,409.5,19,409.3,19.2z"/>
			<path fill="#0D0D0C" d="M410,23.7c0.1-0.1,0.3-0.2,0.4-0.3c-0.1-0.5,0.6-1,0.1-1.6c0.1-0.3,0-0.3-0.3-0.3
				C409.7,22.3,410.3,23,410,23.7z"/>
			<path fill="#534D47" d="M399.5,48.5c0.7-0.8-0.3-0.7-0.5-1c-0.4,0-0.3,0.3-0.4,0.5c0.1,0.3,0.2,0.7,0.2,1
				C399.2,49.1,399.2,48.6,399.5,48.5z"/>
			<path fill="#080808" d="M414.8,24.9c-0.1-0.2-0.2-0.4-0.2-0.6c-1.4-0.3-0.7,0.6-0.7,1.2c0.2,0.1,0.3,0.2,0.5,0
				C414.4,25.2,414.6,25,414.8,24.9z"/>
			<path fill="#161615" d="M414.8,26.9c-0.2-0.1-0.3-0.2-0.5-0.2c-0.3,0-0.3,0.3-0.4,0.5c-0.2,0.4-0.1,0.7,0.2,0.9
				c0.1,0,0.6-0.2,0.6-0.7C414.8,27.2,414.8,27,414.8,26.9z"/>
			<path fill="#292826" d="M391.6,45.1c-0.2,0.2-0.4,0.4-0.3,0.7c0.8,0.2,1.5,0.4,1.7-0.7c-0.3-0.1-0.6-0.1-0.8,0
				c-0.3,0.4-0.2-0.2-0.3-0.1C391.7,45,391.6,45,391.6,45.1z"/>
			<path fill="#534D47" d="M408.3,55c0.3,0.2,0.7,0.1,1-0.1c-0.1-0.3-0.3-0.6-0.4-0.9c-0.2-0.9-0.5-0.2-0.7,0
				c-0.2,0.1-0.2,0.3-0.1,0.5C408.1,54.7,408.3,54.8,408.3,55z"/>
			<path fill="#4F4A44" d="M393,60.9c0.4,0.1,0.7,0.6,1.2,0.5c0.4-0.4-0.6-0.5-0.3-0.9c0,0,0-0.1,0-0.1c0.1,0,0.1-0.1,0.1-0.1
				c0-0.1,0-0.1-0.1-0.1c-0.3,0-0.5,0-0.8,0C393.1,60.5,393.1,60.7,393,60.9z"/>
			<path fill="#292826" d="M389.4,45.6c0.6-0.3,0.6-0.8,0.7-1.4c0,0-0.1,0-0.1,0C388.8,44.6,388.8,44.6,389.4,45.6z"/>
			<path fill="#302F2C" d="M390.8,40.4c1.1,0.5,1.1,0.5,1.4-0.6C391.6,39.9,391.4,40.5,390.8,40.4z"/>
			<path fill="#4C4742" d="M392,51.6c0,0.2,0,0.5,0.1,0.7c0.1,0.3,0.3,0.6,0.7,0.5c0.5-0.3,0.3-0.6,0-0.9
				C392.6,51.6,392.4,51.4,392,51.6z"/>
			<path fill="#46423D" d="M407.7,12.2c-0.5,0-0.5,0.3-0.7,0.5c-0.2,0.2-0.5,0.1-0.5,0.4C407.5,13.4,407.5,13.4,407.7,12.2z"/>
			<path fill="#403D39" d="M388.2,45.6c0.2,0.3,0.1,0.7,0.6,1c-0.1-0.9-0.2-1.7-0.8-2.7c0,0.6,0,0.9,0,1.2
				C387.8,45.4,388.1,45.5,388.2,45.6z"/>
			<path fill="#383532" d="M395,52.7c0.7,0.3,1.3,0.4,1.2-0.7C395.7,52.1,395.4,52.5,395,52.7z"/>
			<path fill="#070706" d="M406.9,23.7c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.3,0.2,0.6,0.2,0.9c0.8,0,0.9-0.1,0.5-1.1
				C407.3,23.5,407.1,23.6,406.9,23.7z"/>
			<path fill="#534D47" d="M399.8,49.9c0.5-0.2,0.4-0.6,0.4-1l0,0c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3,0.1-0.6,0.3-0.5,0.7
				C399,49.9,398.9,50.5,399.8,49.9c-0.1-0.1,0,0.1-0.1,0C399.6,49.9,399.7,49.9,399.8,49.9z"/>
			<path fill="#282725" d="M411.9,22.8c0,0.1-0.1,0.2-0.1,0.3c0,0.4-0.6,0.8-0.2,1.3c0,0,0.3,0,0.3-0.1c0.2-0.5,0.3-1,0.2-1.5
				C412.1,22.8,412,22.8,411.9,22.8z"/>
			<path fill="#4F4A44" d="M390.4,61.4c0.1,0.6,0.6,0.4,1,0.4c0.3,0,0.7,0,0.7-0.4c0-0.3-0.4-0.2-0.7-0.2
				C391,61.2,390.6,61.1,390.4,61.4z"/>
			<path fill="#161614" d="M395.7,59.5c-0.2,0.2-0.3,0.4-0.3,0.7c0.3,0.2,0.4,0.4,0.5,0.7c0.1,0.1,0.1,0.1,0.2,0
				C396.6,60.3,396.3,59.8,395.7,59.5z"/>
			<path fill="#3B3935" d="M397.5,46.1c0-0.1,0-0.1,0-0.2c-0.3-0.6-1.1-1-1.4-0.8c-0.5,0.4,0.2,0.5,0.5,0.7
				C397,45.8,397.2,45.9,397.5,46.1z"/>
			<path fill="#504A44" d="M397.8,59.6c0.2,0.3,0.3,0.6,0.5,0.9c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.2,0.1-0.3,0-0.5c0-0.1,0-0.2,0-0.3
				C398.4,59.7,398,58.4,397.8,59.6z"/>
			<path fill="#47433E" d="M392.7,62.9c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.3-0.5,0.5-0.1,0.8c0.2,0.2,0.4,0.2,0.6,0
				c0.4-0.4,0-0.7-0.2-0.9C392.9,63,392.9,62.9,392.7,62.9z"/>
			<path fill="#2B2A27" d="M411,40.5c0-0.5-0.2-0.9-0.7-1.1c-0.3-0.1-0.5-0.1-0.6,0.2c-0.1,0.3,0,0.4,0.3,0.4c0.4,0,0.5,0.3,0.7,0.6
				C410.9,40.6,411,40.6,411,40.5z"/>
			<path fill="#534E47" d="M400.7,63.1c0.1,1.1,0.9,0.5,1.4,0.6C401.7,63.2,401.2,63.1,400.7,63.1L400.7,63.1z"/>
			<path fill="#252422" d="M383.2,67.3c0.3,0.6,0.5,1,0.2,1.4c0.5,0,0.8-0.2,1-0.5C384.1,67.9,383.8,67.7,383.2,67.3z"/>
			<path fill="#373532" d="M388.2,63.9c-0.3,0-0.7,0.1-1,0.1c-0.2,0.5,1,0.2,0.5,0.8c-0.1,0.1,0,0.2,0,0.2c0.4,0.4,0.6,0.2,0.7-0.3
				C388.7,64.4,388.4,64.2,388.2,63.9z"/>
			<path fill="#44403B" d="M408.8,56.6c0.5-0.7,0.5-0.7-0.7-1.2c-0.2-0.1-0.3,0-0.3,0.2c0.2,0.2,0.4,0.4,0.3,0.8
				C408.3,56.6,408.6,56.6,408.8,56.6z"/>
			<path fill="#514C45" d="M404.2,40.1c-0.2-0.4-0.6-0.5-0.9-0.5c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.3-0.5,0.6,0.2,0.8
				C404.4,41.1,403.9,40.3,404.2,40.1z"/>
			<path fill="#4E4943" d="M414.1,21.8c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0.1-0.4,0.4-0.3,0.7c0.2,0.2,0.5,0.4,0.7,0.5
				c0.2,0.2,0.4,0.1,0.6-0.1C414.2,22.4,414.2,22.1,414.1,21.8z"/>
			<path fill="#4F4A44" d="M388.2,45.6c-0.1-0.2-0.1-0.3-0.2-0.5c-1.1,0.3-0.4,1.2-0.7,1.9C388.2,46.8,387.4,45.8,388.2,45.6z"/>
			<path fill="#4D4843" d="M399.7,55.4c-0.6,0-1.3,0-1.9,0l0,0C398.4,56.8,399.1,55.7,399.7,55.4L399.7,55.4z"/>
			<path fill="#514C46" d="M396.6,57.4c-0.3,0-0.6,0.1-0.8,0.1c-0.7-0.1-0.7,0.2-0.4,0.6c0.2,0.2,0.5,0.1,0.7,0
				c0.3-0.1,0.5-0.2,0.8-0.2C397,57.6,396.7,57.5,396.6,57.4z"/>
			<path fill="#3E3C37" d="M404.8,57.8c-0.7-0.9-1,0.4-1.6,0.3c0,0.1,0,0.2,0,0.2c0.1,0.1,0.3,0.1,0.4,0.1
				C404,58.3,404.3,57.9,404.8,57.8L404.8,57.8z"/>
			<path fill="#433F3B" d="M380.6,34.9c0.1-0.6,0.1-1-0.3-1.3c-0.1,0-0.3,0-0.3,0.1C379.7,34.2,380,34.5,380.6,34.9z"/>
			<path fill="#423E3A" d="M401.2,18c-0.2-0.2-0.6-0.6-0.8-0.2c-0.4,0.7,0.4,0.7,0.8,0.9C401.6,18.5,401.5,18.2,401.2,18z"/>
			<path fill="#3A3733" d="M416,31.9c0-0.2,0-0.3,0-0.5c-0.7-0.6-0.7,0.1-0.9,0.5c-0.3,0.6,0.1,0.3,0.4,0.3c0.2,0,0.3,0,0.5-0.1
				C416.1,32.1,416.1,32.1,416,31.9C416.1,32,416.1,31.9,416,31.9z"/>
			<path fill="#4B4741" d="M389.8,10.2c0.5-0.3,0.5-0.7,0.4-1.1c-0.1-0.3-0.4-0.1-0.5,0C389.2,9.5,389.7,9.8,389.8,10.2z"/>
			<path fill="#353330" d="M402.5,48.8c1,0,1.5-0.3,1.8-1.2C403.7,48,403.2,48.3,402.5,48.8z"/>
			<path fill="#32312E" d="M411,16.6c-0.2-0.4-0.1-0.9-0.7-0.9c-0.1,0-0.4,0.2-0.3,0.2C410.1,16.5,410.6,16.5,411,16.6z"/>
			<path fill="#494540" d="M395.4,46.4c-0.5,0.4-0.5,1-0.3,1.4c0.1,0.3,0.5,0,0.7-0.2C395.7,47.1,395.9,46.6,395.4,46.4z"/>
			<path fill="#48443F" d="M405.3,27.2c0.3-0.5,0.5-1,0.5-1.6C405.4,26.1,404.6,26.3,405.3,27.2z"/>
			<path fill="#0E0E0D" d="M397.7,61.3c-0.6,0.1-1.1,0-1.2,0.6c0,0.2,0.1,0.4,0.2,0.4C397.2,62.3,397.3,61.8,397.7,61.3z"/>
			<path fill="#070707" d="M411,50.1c0-0.2,0-0.5,0-0.7c-0.2-0.2,0.2-0.8-0.5-0.7c0,0.2,0,0.5-0.1,0.7
				C409.8,50.3,410.3,50.3,411,50.1z"/>
			<path fill="#312F2C" d="M416,35.4c-0.4-0.2-0.7-0.4-1-0.1c-0.2,0.1-0.1,0.4,0.1,0.5C415.5,36.1,415.7,35.7,416,35.4z"/>
			<path fill="#1B1B19" d="M376,39.2c0.4-0.1,0.9,1.1,1.1,0c0.3-1-0.8,0-1-0.5C376,38.8,375.8,38.9,376,39.2z"/>
			<path fill="#48443F" d="M391.3,52c-0.3-0.4-0.6-0.6-1-0.3c-0.1,0.1-0.1,0.3,0,0.4C390.7,52.6,390.9,52,391.3,52z"/>
			<path fill="#4C4741" d="M407.3,27.8c0.4,0,0.7,0,1-0.2c0.4-0.5,0-0.8-0.3-1.2c-0.2,0.2-0.5,0.3-0.7,0.5
				C407.5,27.2,407.3,27.5,407.3,27.8z"/>
			<path fill="#0C0C0B" d="M408.1,26.4c0.1,0.4,0.4,0.7,0.3,1.2c0.4,0.1,0.7,0,1-0.2l0,0C409,26.9,408.7,26.5,408.1,26.4z"/>
			<path fill="#4F4A44" d="M401.2,18c0.1,0.2,0.1,0.5,0,0.7c0.3,0.2,0.4,0.8,0.9,0.3c0.5-0.5-0.4-0.4-0.4-0.7
				C401.6,18.2,401.3,18.1,401.2,18z"/>
			<path fill="#201F1D" d="M409.3,27.3c-0.1,0.4,0.1,0.8-0.3,1.1c0.7,0.2,0.8-0.2,0.9-0.6C410,27.3,409.6,27.4,409.3,27.3
				C409.3,27.4,409.3,27.3,409.3,27.3z"/>
			<path fill="#48433E" d="M407.2,58.1c-1.2,0.3-0.2,1.2-0.5,1.7c0.1,0.1,0.1,0.1,0.2,0C407.2,59.2,407.1,58.6,407.2,58.1
				L407.2,58.1z"/>
			<path fill="#4C4842" d="M404.7,60c0.1,0.3,0.2,0.4,0.5,0.5c0.4,0.3,0.5,0,0.7-0.2c-0.1-0.2-0.1-0.5-0.2-0.7
				c-0.3,0.1-0.2-0.4-0.5-0.3C405.2,59.6,405.1,59.9,404.7,60L404.7,60z"/>
			<path fill="#292826" d="M390.4,61.4c0.3,0,0.7,0,0.9-0.2c-0.3-0.2-0.3-0.6-0.5-1C390.4,60.6,390.5,61,390.4,61.4z"/>
			<path fill="#080808" d="M406.9,39.4c0.3,1,0.8,0.8,1.4,0.3C407.9,39.4,407.5,38.9,406.9,39.4z"/>
			<path fill="#534E47" d="M400.7,63.1c-0.1-0.3-0.1-0.7-0.2-1.1c-0.3,0.3-0.5,0.4-0.8,0.6c-0.1,0.2-0.1,0.3,0,0.5
				C400,63.1,400.3,63.1,400.7,63.1C400.7,63.1,400.7,63.1,400.7,63.1z"/>
			<path fill="#2F2D2B" d="M388.7,72c0,0.4-0.4,0.7-0.1,1.1c0.9-0.7,0.9-0.7,0.5-1C389,72,388.8,71.9,388.7,72z"/>
			<path fill="#4C4742" d="M392.1,45.3c0.4,0.2,0.6,0.1,0.9-0.2c0-0.2,0-0.3,0-0.5c0.1-0.2,0-0.3-0.2-0.4c-0.2-0.1-0.4,0-0.5,0.1
				c-0.1,0.1-0.2,0.1-0.3,0.2C391.9,44.8,391.8,45.1,392.1,45.3z"/>
			<path fill="#403D38" d="M385.1,62.6c-0.3,0.4-0.2,0.9,0.1,1.2c0.3,0.3,0.4-0.4,0.7-0.5l0,0C386,62.7,385,63.2,385.1,62.6
				L385.1,62.6z"/>
			<path fill="#494540" d="M396.8,36.3c0.1-0.5,0-0.9-0.4-1.1c-0.1,0-0.3,0.1-0.3,0.1C396.1,35.8,396.7,35.9,396.8,36.3z"/>
			<path fill="#020202" d="M406.8,52.5c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.1,0.7,0.2,0.7-0.4c0-0.2,0.1-0.4-0.1-0.5
				C407,51.8,406.9,52.2,406.8,52.5z"/>
			<path fill="#171816" d="M417.9,36.2c-0.1,0.2-0.3,0.3-0.4,0.5c0,0.2,0,0.5,0,0.7c0.1,0.2,0.3,0.2,0.4,0c0.1-0.1,0.2-0.2,0.3-0.4
				c0.1-0.2,0.1-0.4,0.2-0.6C418.3,36.4,418.1,36.3,417.9,36.2z"/>
			<path fill="#3E3B37" d="M390.3,53.8c0.6-0.4,0.9-0.7,1.3-1C390.9,52.5,390.6,52.9,390.3,53.8z"/>
			<path fill="#171716" d="M373.7,47.6c-0.4,0.3-1,0.3-0.9,1.1C373.4,48.5,373.9,48.5,373.7,47.6z"/>
			<path fill="#48443F" d="M409.7,36c-1,0.3-1,0.7-0.5,1.2C409.6,37,409.4,36.5,409.7,36z"/>
			<path fill="#0E0E0D" d="M417.2,32.3c-0.2,0.2-0.3,0.3-0.5,0.5c0.6,0,0.7,0.6,1,1.1C417.9,33.2,417.8,32.7,417.2,32.3z"/>
			<path fill="#45413C" d="M406.9,39.4c0.5,0.1,0.9,0.2,1.4,0.3C407.9,39.4,407.8,37.7,406.9,39.4z"/>
			<path fill="#504B45" d="M401.8,42.2c0.4-0.1,0.8-0.2,0.8-0.6c0-0.2-0.3-0.2-0.5-0.2C401.5,41.4,402,42,401.8,42.2z"/>
			<path fill="#46423D" d="M397.7,42.8c-0.2-0.3,0.3-0.7-0.2-1c-0.1-0.1-0.4,0-0.4,0C397,42.3,397.2,42.6,397.7,42.8z"/>
			<path fill="#44413C" d="M380.4,43.3c-0.4-0.1-0.4,0.1-0.4,0.3c0,0.2-0.1,0.5,0.3,0.4c0.2-0.1,0.6,0,0.5-0.3
				C380.8,43.6,380.5,43.4,380.4,43.3z"/>
			<path fill="#1F1F1D" d="M393.9,66.4c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0,0.6,0.4,1,0.2c0.1,0,0.1-0.3,0.1-0.3
				C394.8,66.4,394.4,66.1,393.9,66.4z"/>
			<path fill="#43403B" d="M386,59c0.1-0.3,0.2-0.6,0.5-0.7c0.1-0.2,0.1-0.3,0-0.5c-0.7,0.2-1,0.6-0.9,1.3
				C385.7,59.2,385.9,59.2,386,59z"/>
			<path fill="#514C46" d="M388.2,53c-0.3-0.2-0.5-0.2-0.6,0.1c-0.1,0.5,0.3,0.6,0.7,0.8C388.5,53.7,388.4,53.3,388.2,53z"/>
			<path fill="#32302D" d="M394.1,52.6c-0.3,0.2-0.8,0.1-0.8,0.6c0,0.2,0.2,0.4,0.4,0.3C394.1,53.4,394.1,53,394.1,52.6z"/>
			<path fill="#4D4843" d="M399.7,51.1c-1,0.2-0.2,0.8-0.4,1.4C400.1,52,399.6,51.5,399.7,51.1L399.7,51.1z"/>
			<path fill="#353330" d="M376.7,50.2c0.3,0,0.6,0,0.8-0.1c0.1-0.1,0.3-0.3,0.3-0.5c-0.1-0.4-0.4-0.1-0.6-0.2
				C376.6,49.4,376.4,49.7,376.7,50.2z"/>
			<path fill="#2F2D2A" d="M410.5,48c0.7,0,1.2-0.1,0.7-0.9C410.4,46.9,410.2,47.3,410.5,48z"/>
			<path fill="#45423D" d="M397.6,23.5c0.7-0.2,1.1-0.3,1.6-0.5C398.6,22.9,398.1,22.6,397.6,23.5z"/>
			<path fill="#504B45" d="M395.5,4.4c-0.4,0.2-0.8,0.3-1.4,0.6C395,5.3,395.3,4.9,395.5,4.4z"/>
			<path fill="#534D47" d="M413.4,38.5c0-0.1,0-0.2-0.1-0.3c-0.2-0.1-0.5,0-0.7-0.2c-0.2,0.3-0.6,0.7,0,1c0.2,0.2,0.4,0.2,0.7,0.1
				C413.5,38.8,413.5,38.7,413.4,38.5z"/>
			<path fill="#42403C" d="M403.8,9.4c-0.6,0-0.9,0.4-1.1,1C403.4,10.3,403.7,9.9,403.8,9.4L403.8,9.4z"/>
			<path fill="#3D3B36" d="M382.8,35.9c-0.3,0.7-0.3,1.1,0.2,1.5C383.2,36.8,383.2,36.4,382.8,35.9z"/>
			<path fill="#3F3C37" d="M413.4,34.3c0.5,0.6,0.9,0.4,1.4-0.2C414.2,34.1,413.9,34.5,413.4,34.3z"/>
			<path fill="#2A2927" d="M416.9,40.1c0,0.1,0,0.2,0,0.3c0.1,0,0.2,0.1,0.2,0c0.2-0.2,0.6,0.4,0.8-0.1c0-0.1,0-0.4-0.1-0.4
				C417.5,39.6,417.1,39.6,416.9,40.1z"/>
			<path fill="#0D0E0D" d="M409.9,29.8c0.4-0.1,0.8-0.1,0.8-0.6c0-0.1-0.2-0.2-0.4-0.2C409.9,29,410.1,29.5,409.9,29.8z"/>
			<path fill="#44403B" d="M400.7,48.2c-0.2,0.3-0.3,0.5-0.5,0.8c0,0,0,0,0,0c0.7,0.7,0.8,0.1,1-0.4C401.1,48.3,401,48.2,400.7,48.2
				z"/>
			<path fill="#45413C" d="M404.5,44.7c-0.5-0.1-0.5,0.1-0.5,0.3c0,0.2,0,0.4,0.3,0.4c0.2,0,0.5,0,0.5-0.4
				C404.7,44.9,404.5,44.8,404.5,44.7z"/>
			<path fill="#0F0F0E" d="M388.2,63.9c0,0.3,0,0.6,0.2,0.9c0.3,0,0.7,0.1,0.7-0.3C389.1,64,388.5,64.1,388.2,63.9z"/>
			<path fill="#3D3A36" d="M385.5,44.7c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.4-0.1,0.4-0.3c0-0.2-0.1-0.4-0.3-0.4
				C385.7,44.3,385.6,44.5,385.5,44.7z"/>
			<path fill="#45413C" d="M391.1,67.4c-0.4-0.2-0.9-0.3-1.6-0.6C390.1,67.6,390.5,67.8,391.1,67.4L391.1,67.4z"/>
			<path fill="#32312E" d="M406.6,28c0.2,0.6,0.6,0.6,1.2,0.4C407.4,28,407.1,28,406.6,28z"/>
			<path fill="#111110" d="M414.2,22.6c-0.2,0.1-0.4,0.1-0.5,0.2c0.2,0.8,0.8,0.3,1.2,0.4c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3
				c-0.1-0.1-0.3-0.2-0.4-0.3C414.3,22.4,414.2,22.4,414.2,22.6z"/>
			<path fill="#504B45" d="M399.9,34.2c-0.1,0.7-0.2,1.1-0.3,1.6C400.2,35.4,400.3,35,399.9,34.2z"/>
			<path fill="#413E39" d="M408.6,34.6c-0.2,0.5,0,0.9,0.2,1.4C409.1,35.4,409.1,35,408.6,34.6z"/>
			<path fill="#524D47" d="M410.5,48c0.1-0.4,0.2-0.8,0.7-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2-0.2-0.3-0.1-0.5,0l0,0
				c-0.3,0.3-0.6,0.7-0.5,1.2c0.2,0.2,0.3,0.3,0.5,0.5l0,0C410.5,48.3,410.5,48.1,410.5,48z"/>
			<path fill="#242321" d="M410.8,36c0-0.2,0-0.3,0-0.5c-0.1-0.2,0-0.5-0.3-0.5c-0.1,0-0.3,0.2-0.3,0.3
				C410.1,35.7,410.4,35.9,410.8,36z"/>
			<path fill="#1C1C1A" d="M392.6,56c0,0,0,0.1,0,0.1c0.8,0.1,1.1-0.1,0.9-1C393.1,55.4,392.8,55.7,392.6,56z"/>
			<path fill="#504B45" d="M403,13.3c-0.4-0.1-0.7-0.4-0.8,0.2c0,0.1,0.1,0.4,0.2,0.4C402.8,13.9,402.8,13.5,403,13.3z"/>
			<path fill="#44403B" d="M408.2,56.6c-0.4,0.4-0.8,0.9-1,1.5c0,0,0,0,0,0c0.2,0,0.5,0,0.7,0c0.4-0.2,0.2-0.6,0.2-1
				c0.1-0.1,0.2-0.2,0.3-0.3C408.4,56.7,408.3,56.6,408.2,56.6z"/>
			<path fill="#4E4944" d="M384.2,39.8c-0.5-0.4-0.9-0.6-1.3-0.8C383.1,39.5,383.4,39.9,384.2,39.8z"/>
			<path fill="#48443F" d="M379,45.9c-0.1-0.3-0.2-0.5-0.2-0.8c-0.2-0.1-0.3,0-0.5,0c-0.3,0.1-0.6,0.1-0.9,0.2
				c0,0.2,0.2,0.2,0.3,0.3c0.2,0,0.5,0,0.7,0C378.5,45.9,378.7,46.1,379,45.9z"/>
			<path fill="#534E47" d="M401.9,30.7c0-0.5,0.5-0.6,0.8-0.9c-1.1,0-1.1,0-1,1C401.7,30.8,401.8,30.8,401.9,30.7z"/>
			<path fill="#504B45" d="M393.8,5.8c0.1,0.2,0.1,0.5,0.3,0.4c0.2-0.1,0.4-0.3,0.4-0.5c0-0.3-0.3-0.2-0.5-0.3
				C393.9,5.5,393.8,5.6,393.8,5.8z"/>
			<path fill="#514C45" d="M391.5,43.4c-0.2-0.3-0.5-0.6-0.7-1c-0.2,0-0.3,0-0.5,0c-0.1,0.1-0.1,0.3-0.1,0.4
				c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0,0.2,0,0.3c0.3,0.1,0.6,0.1,0.9-0.1C391.5,43.5,391.5,43.5,391.5,43.4z"/>
			<path fill="#403D39" d="M378.1,27c0.3,0,0.4-0.2,0.4-0.4c0-0.2,0.1-0.5-0.2-0.5c-0.2,0-0.4,0.1-0.4,0.3
				C377.9,26.6,378,26.8,378.1,27z"/>
			<path fill="#3C3A36" d="M410.5,42.2c-0.5-0.2-0.9,0.2-1.2,0.5c0.4,0.3,0.7-0.1,1,0C410.7,42.7,410.6,42.5,410.5,42.2z"/>
			<path fill="#4F4A44" d="M398.5,8.2c-0.2,0.5-0.3,1-0.3,1.8C398.6,9.3,398.9,8.8,398.5,8.2L398.5,8.2z"/>
			<path fill="#2E2C2A" d="M381,46.5c0.4-0.1,0.6-0.4,0.4-0.7c-0.3-0.4-0.6,0-0.8,0.2C380.6,46.3,380.8,46.4,381,46.5z"/>
			<path fill="#242322" d="M404.8,57.8c0,0.3,0,0.6,0.2,0.8c0.2,0,0.4-0.2,0.5-0.3c0.3-0.3,0.2-0.6-0.1-0.8
				C405,57.3,405.1,57.9,404.8,57.8C404.8,57.8,404.8,57.8,404.8,57.8z"/>
			<path fill="#46423E" d="M383.4,57.4c-0.5,0.3-0.1,0.8-0.2,1.2C383.7,58.3,383.4,57.8,383.4,57.4z"/>
			<path fill="#272725" d="M409.2,15.1c0-0.5,0.2-0.8-0.1-1.1c-0.1,0-0.3,0-0.3,0.1C408.6,14.6,408.9,14.8,409.2,15.1z"/>
			<path fill="#2E2D2A" d="M406.9,59.8c-0.1,0-0.1,0-0.2,0c0,0.4-0.3,0.7-0.1,1.2C407.1,60.6,407.4,60.3,406.9,59.8z"/>
			<path fill="#373532" d="M410.5,46.8c0.2,0,0.3,0,0.5,0c-0.2-0.5-0.9-0.5-1-1.2C409.6,46.4,410.2,46.5,410.5,46.8z"/>
			<path fill="#302F2C" d="M398.7,59.7c0,0.2,0,0.3,0,0.5c0.1,0.2,0.2,0.5,0.3,0.8c0.1-0.3,0.2-0.6,0.3-0.8
				C399.3,59.9,399.1,59.8,398.7,59.7z"/>
			<path fill="#504B45" d="M396.1,24.7c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0,0.3,0.2,0.3c0.3,0,0.3-0.2,0.3-0.4
				C396.3,24.9,396.3,24.8,396.1,24.7z"/>
			<path fill="#1C1C1A" d="M412.9,22.3c0.1-0.2,0.2-0.5,0.3-0.7c-0.2-0.2-0.6-0.3-0.5-0.7c-0.1,0.1-0.2,0.3-0.2,0.4
				C412.5,21.7,412.5,22.1,412.9,22.3z"/>
			<path fill="#48443F" d="M398,30.8c0,0.2,0.1,0.3,0.3,0.2c0.2-0.1,0.5-0.2,0.3-0.5c0-0.1-0.2-0.3-0.3-0.3
				C398,30.2,398.1,30.6,398,30.8z"/>
			<path fill="#413E39" d="M401.4,60.7c0,0.1,0,0.2,0,0.3c0,0.3-0.2,0.6,0.3,0.7c0.1,0,0.3,0,0.3-0.1c0.2-0.4-0.2-0.6-0.4-0.9
				C401.6,60.6,401.5,60.6,401.4,60.7L401.4,60.7z"/>
			<path fill="#040404" d="M375.7,51.6c0.2-0.2,0.8-0.3,0.5-0.7c-0.3-0.4-0.7,0-1,0.2C375.2,51.5,375.3,51.7,375.7,51.6z"/>
			<path fill="#45413C" d="M412.2,30.2c-0.6,0.2-0.9,0.3-1,0.8C411.6,30.9,411.9,30.8,412.2,30.2z"/>
			<path fill="#4C4842" d="M376.7,50.2c0-0.3,0.2-0.6,0.5-0.7c-0.3-0.1-0.7-1-1,0l0,0C376.3,49.7,376.5,49.9,376.7,50.2z"/>
			<path fill="#494540" d="M391.8,53.7c0.3,0.3,0.7,0.4,1.2,0.4C392.6,53.7,392.2,53.6,391.8,53.7z"/>
			<path fill="#282725" d="M409,33.4c-0.3,0-0.4,0.1-0.4,0.2c0.1,0.2,0.2,0.5,0.6,0.5c0.1,0,0.2-0.2,0.3-0.3
				C409.3,33.8,409.1,33.6,409,33.4z"/>
			<path fill="#34322F" d="M378.5,33.8c0.3-0.2,0.4-0.5,0.3-0.8c-0.1-0.2-0.3-0.1-0.4,0C378.2,33.3,378.3,33.6,378.5,33.8z"/>
			<path fill="#4E4943" d="M392.3,50.4c0.1-0.2,0.2-0.3,0.2-0.5c-0.3-0.2-0.2-0.7-0.6-0.8c-0.3,0-0.2,0.4-0.3,0.6l0,0
				C391.9,49.8,392.1,50.1,392.3,50.4z"/>
			<path fill="#59534C" d="M401,56.6c-0.4-0.2-0.6-0.3-0.9,0c0,0,0.1,0.2,0.1,0.3C400.5,57.1,400.8,57,401,56.6z"/>
			<path fill="#191918" d="M410.8,43.2c0.7-0.1,0.7-0.5,0.4-0.9c-0.2-0.1-0.3-0.2-0.5-0.2c0,0-0.1,0.1-0.1,0.1
				c0,0.3-0.1,0.6,0.1,0.8C410.7,43,410.8,43.1,410.8,43.2z"/>
			<path fill="#0D0E0D" d="M375.2,46.3c0.4,0.2,0.8,0.2,1.1,0c0.2-0.1-0.1-0.3-0.1-0.5C375.8,45.9,375.3,45.6,375.2,46.3z"/>
			<path fill="#504C45" d="M400.9,13.2c-0.3-0.1-0.1-0.7-0.5-0.6c-0.2,0-0.4,0.3-0.3,0.6C400.3,13.6,400.6,13.3,400.9,13.2
				L400.9,13.2z"/>
			<path fill="#383632" d="M391.8,57.1c-0.3-0.3-0.6-0.3-1,0C391.1,58.4,391.5,57,391.8,57.1z"/>
			<path fill="#373431" d="M414.9,29.2c0.2,0,0.4,0,0.4-0.2c0-0.3-0.3-0.3-0.5-0.4c-0.2-0.1-0.4,0-0.4,0.3
				C414.4,29.1,414.7,29,414.9,29.2z"/>
			<path fill="#4F4A44" d="M392.3,37.4c-0.4,0.1-1.3-1-1,0.5l0,0C391.7,37.9,392,37.7,392.3,37.4L392.3,37.4z"/>
			<path fill="#2E2C2A" d="M394.7,71.5c-0.4-0.4-0.7-0.5-1.2-0.2C394,71.6,394.3,71.7,394.7,71.5z"/>
			<path fill="#0D0D0D" d="M408.4,44c0.2,0,0.3-0.1,0.5,0c0.3,0.1,0.6,0.3,0.7-0.1c0.1-0.4-0.3-0.2-0.5-0.3
				c-0.2-0.2-0.3-0.4-0.4-0.5C408.5,43.3,408.2,43.6,408.4,44z"/>
			<path fill="#534D47" d="M389,12c0.3-0.4,0.5-0.6,0.4-0.9c0-0.1-0.2-0.1-0.3,0C388.8,11.2,388.8,11.5,389,12z"/>
			<path fill="#141412" d="M375.5,43.3c-0.2,0-0.3,0.1-0.4,0.2c0,0.3,0.1,0.5,0.4,0.5c0.2,0,0.3-0.1,0.4-0.2
				C375.9,43.5,375.8,43.3,375.5,43.3z"/>
			<path fill="#413E39" d="M395.4,49.7c-0.4,0.3-0.6,0.7-0.7,1.2C395.5,50.8,395.4,50.2,395.4,49.7z"/>
			<path fill="#534D47" d="M385.1,62.6c0.7,0.1,1-0.4,1.2-1l0,0C385.7,61.8,385.4,62.2,385.1,62.6C385.1,62.6,385.1,62.6,385.1,62.6
				z"/>
			<path fill="#524D47" d="M410.5,48.8c0.2,0.2,0.3,0.5,0.5,0.7c0.1-0.2,0.2-0.4,0.3-0.5c0.3-1-0.4-0.4-0.7-0.4c0,0,0,0,0,0
				C410.5,48.6,410.5,48.7,410.5,48.8z"/>
			<path fill="#292826" d="M416.4,37.8c0-0.9-0.3-1.1-1-0.7C415.7,37.3,416,37.5,416.4,37.8z"/>
			<path fill="#171716" d="M391.3,62.4c-0.3,0.3-0.3,0.6,0,0.9c0.2,0.2,0.3-0.1,0.5-0.2C391.9,62.7,392.1,62.2,391.3,62.4z"/>
			<path fill="#373532" d="M409.1,29.8c-0.2-0.3-0.5-0.5-0.8-0.3c-0.1,0-0.1,0.2-0.1,0.3C408.5,30.1,408.8,29.8,409.1,29.8z"/>
			<path fill="#1F1E1D" d="M378.3,45.1c0.2,0.2,0.3,0.1,0.5,0c0.2-0.2,0.8-0.4,0.4-0.7C378.8,44.2,378.4,44.7,378.3,45.1z"/>
			<path fill="#1D1D1B" d="M383.9,42.7c-0.3-0.3-0.7-0.4-1-0.1c-0.1,0.1-0.1,0.3,0,0.3C383.2,43.1,383.5,42.7,383.9,42.7z"/>
			<path fill="#48443F" d="M397.8,63.9c-0.2-0.2-0.3-0.7-0.7-0.6c-0.1,0-0.1,0.2-0.1,0.3C397.1,64.1,397.5,63.9,397.8,63.9z"/>
			<path fill="#46423D" d="M387.5,50.5c-0.4,0.2-0.6,0.4-0.4,0.8c0,0.1,0.2,0.1,0.3,0.1C387.6,51.2,387.6,50.9,387.5,50.5z"/>
			<path fill="#10100F" d="M404.7,16c-0.6,0.3-0.6,0.3-0.1,0.9c0.1-0.3,0.2-0.6,0.3-0.9C404.9,15.9,404.8,15.9,404.7,16z"/>
			<path fill="#292826" d="M396.4,50.2c-0.4-0.1-0.5,0.1-0.4,0.4c0,0.2,0.2,0.4,0.4,0.2C396.7,50.6,396.4,50.4,396.4,50.2
				L396.4,50.2z"/>
			<path fill="#1C1C1A" d="M387.2,62.7c0.1,0.3,0.3,0.8,0.6,0.5c0.3-0.4-0.1-0.8-0.5-0.9C387.1,62.3,387.1,62.5,387.2,62.7z"/>
			<path fill="#2F2D2B" d="M380.1,29.8c0-0.4-0.1-0.6-0.4-0.7c-0.1,0-0.2,0.1-0.2,0.2C379.5,29.7,379.7,29.7,380.1,29.8z"/>
			<path fill="#403D38" d="M384.6,64c-0.1-0.6-0.2-0.8-0.6-0.9c0,0-0.1,0.1-0.1,0.2C383.8,63.8,384.2,63.7,384.6,64z"/>
			<path fill="#56504A" d="M403,28.6c0.1,0.2,0,0.4,0.3,0.4c0.1,0,0.2-0.2,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4
				C403,28.2,403,28.4,403,28.6z"/>
			<path fill="#040404" d="M408.4,23.5c-0.3,0.1-0.6,0.1-0.2,0.5c0.3,0,0.5,0,0.8,0C408.9,23.7,408.6,23.6,408.4,23.5z"/>
			<path fill="#48443F" d="M385.8,52.6c-0.2,0.3-0.3,0.6,0.1,0.9c0.1,0,0.3,0,0.3,0c0.2-0.2-0.1-0.4-0.1-0.6
				C386,52.7,385.9,52.6,385.8,52.6z"/>
			<path fill="#383632" d="M406.9,54c-0.1,0.1-0.3,0.1-0.4,0.2c0.3,0.2-0.1,0.7,0.4,0.8C407.3,54.7,406.9,54.4,406.9,54z"/>
			<path fill="#2C2B28" d="M390.8,56.2c0.2-0.1,0.3-0.2,0.5-0.3c-0.6,0.2,0-0.9-0.6-0.6C390.2,55.6,390.5,55.9,390.8,56.2z"/>
			<path fill="#0C0D0C" d="M408.1,57.1c-0.2,0.3-0.2,0.6-0.2,1c0.1,0.1,0.3,0.3,0.4,0.1C408.5,57.9,408.4,57.5,408.1,57.1z"/>
			<path fill="#201F1E" d="M386,59c-0.1,0-0.3,0-0.4,0c-0.1,0.4-0.8,0.9,0.2,1.2l0,0C385.7,59.8,385.9,59.4,386,59z"/>
			<path fill="#534D47" d="M385.8,61.2c0.2,0.2,0.3,0.3,0.5,0.5c0,0,0,0,0,0c0.6-0.2,0.4-0.6,0.2-1C386,60.6,385.9,60.9,385.8,61.2z
				"/>
			<path fill="#504B45" d="M384.7,62.2c0.2-0.5,0.5-0.7,0.4-1c0-0.1-0.2-0.1-0.3-0.1C384.4,61.4,384.6,61.7,384.7,62.2z"/>
			<path fill="#0F0F0F" d="M394.1,62.8c0-0.1,0-0.1,0-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2-0.3-0.5-0.5-0.9-0.2c0,0.1,0,0.1,0,0.2
				c0.1,0.4,0.5,0.3,0.7,0.5C393.9,62.9,394,62.9,394.1,62.8z"/>
			<path fill="#33312E" d="M387.2,62.7c0-0.2,0-0.3,0.1-0.5c-0.4-0.2-0.9-0.2-0.8,0.3C386.4,63.2,387,62.2,387.2,62.7z"/>
			<path fill="#4B4742" d="M395.4,8.6c-0.2,0.1-0.6-0.1-0.7,0.2c0,0,0,0.2,0.1,0.2c0.4,0.1,0.7,0,0.8-0.4
				C395.6,8.5,395.5,8.5,395.4,8.6z"/>
			<path fill="#393733" d="M376.5,9.9c0.2,0,0.3,0,0.3-0.2c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.3,0.1-0.3,0.3
				C376.2,9.7,376.2,9.9,376.5,9.9z"/>
			<path fill="#060606" d="M375.8,44.5c-0.3,0-0.4,0.2-0.5,0.4c-0.1,0.2,0.2,0.2,0.3,0.2c0.2,0,0.4-0.1,0.4-0.3
				C376.1,44.7,376,44.6,375.8,44.5z"/>
			<path fill="#1D1C1B" d="M408.1,44.8c-0.1,0.1-0.1,0.2,0,0.3c0.3-0.2,0.9,0.2,1.1-0.5C408.8,44.5,408.5,44.8,408.1,44.8z"/>
			<path fill="#242321" d="M390.3,42.9c0-0.1,0-0.3,0.1-0.4c-0.2-0.1-0.5-0.2-0.6-0.1c-0.2,0.1-0.1,0.4,0,0.6
				C389.9,43.3,390.1,43,390.3,42.9z"/>
			<path fill="#47433E" d="M389.6,48c0-0.1,0-0.2,0-0.3c-0.4,0.1-0.9-0.1-1.1,0.4C389,48.4,389.3,48.1,389.6,48z"/>
			<path fill="#544E48" d="M400.2,40.7c0.2,0,0.3-0.1,0.2-0.2c-0.1-0.2-0.2-0.4-0.3-0.5c-0.2-0.1-0.3,0.1-0.3,0.3
				C399.9,40.5,400.1,40.6,400.2,40.7z"/>
			<path fill="#514C46" d="M390.1,12.2c-0.1,0.2-0.5,0.4-0.2,0.6c0.3,0.2,0.7-0.1,0.9-0.4C390.6,12.3,390.4,12.1,390.1,12.2z"/>
			<path fill="#302E2C" d="M402.3,46.8c0.3,0,0.3-0.2,0.3-0.4c0-0.2,0-0.3-0.2-0.4c-0.2-0.1-0.4,0.1-0.4,0.3
				C402,46.5,402.1,46.7,402.3,46.8z"/>
			<path fill="#4C4742" d="M381,46.5c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2,0-0.4-0.2-0.5,0c-0.1,0.2,0,0.3,0.2,0.4
				C380.5,46.5,380.7,46.5,381,46.5z"/>
			<path fill="#35332F" d="M405.1,11c0.1,0.5,0.2,0.8,0.7,0.8C405.8,11.4,405.6,11.2,405.1,11z"/>
			<path fill="#46433E" d="M392.7,44.4c0.1,0.1,0.2,0.2,0.3,0.2c0.3-0.1,0.6,0.1,0.8-0.2c0-0.3-0.5-0.2-0.5-0.5
				c-0.1-0.1-0.2-0.1-0.3-0.1C392.6,43.9,392.5,44.1,392.7,44.4z"/>
			<path fill="#44403B" d="M401.4,48c0.1-0.8-0.6-0.6-0.9-0.5c-0.3,0.1,0.2,0.3,0.2,0.5C400.9,48.2,401.2,48.2,401.4,48z"/>
			<path fill="#1C1B1A" d="M395.4,46.4c0,0.4,0.1,0.8,0.4,1.1C395.9,47,396.5,46.4,395.4,46.4z"/>
			<path fill="#2B2A28" d="M410,48c0.2-0.4,0.3-0.8,0.5-1.2C409.8,47,409.5,47.3,410,48z"/>
			<path fill="#090908" d="M414.8,45.5c0.2-0.5-0.2-0.4-0.5-0.4c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.2-0.4,0.4-0.1,0.6
				C414.3,45.9,414.5,45.5,414.8,45.5z"/>
			<path fill="#43403B" d="M398.7,41.5c-0.3,0.4-0.2,0.6,0.1,0.9C399.1,42,399.1,41.8,398.7,41.5z"/>
			<path fill="#413D39" d="M404.9,36.9c-0.3,0.1-0.5,0.3-0.8,0.4c0.1,0.1,0.1,0.2,0.2,0.2C404.8,37.6,404.9,37.4,404.9,36.9z"/>
			<path fill="#090909" d="M407.8,16.1c-0.2,0.1-0.5,0.2-0.5,0.5c0,0.3,0.2,0.3,0.4,0.3c0.4,0,0.3-0.3,0.3-0.5
				C408.1,16.1,407.9,16,407.8,16.1z"/>
			<path fill="#363430" d="M394.9,45.4c-0.5-0.1-0.9-0.2-1.2,0.3C394.1,45.5,394.6,45.9,394.9,45.4L394.9,45.4z"/>
			<path fill="#100F0F" d="M389.3,49.3c-0.2-0.4-0.5-0.6-0.8-0.4c-0.1,0-0.1,0.3,0,0.3C388.7,49.5,389,49.4,389.3,49.3z"/>
			<path fill="#514C45" d="M403.2,45c-0.2,0-0.4,0-0.4,0.2c0,0.2,0.2,0.3,0.4,0.3c0.2,0,0.3-0.1,0.3-0.3
				C403.4,45.1,403.2,45,403.2,45z"/>
			<path fill="#111111" d="M413.2,45.2c-0.1,0.2-0.2,0.4-0.5,0.5c-0.6,0.1,0,0.3,0,0.4c0.6,0,0.9-0.3,0.7-0.9
				C413.3,45.1,413.2,45.1,413.2,45.2z"/>
			<path fill="#1E1E1C" d="M412.7,32.1c0.3,0.5,0.7,0.5,1,0.5C413.5,32.3,413.2,32.1,412.7,32.1z"/>
			<path fill="#3B3935" d="M390.1,56.6c0-0.4,0-1.1-0.7-0.4c0,0,0,0,0,0c0.1,0.2,0.1,0.3,0.2,0.5C389.8,56.8,389.9,56.8,390.1,56.6z
				"/>
			<path fill="#44403C" d="M381.5,32.3c-0.1-0.4-0.3-0.5-0.6-0.5c0,0-0.1,0.2-0.1,0.2C380.9,32.2,381.2,32.2,381.5,32.3z"/>
			<path fill="#45413D" d="M396.1,60.9c-0.1,0-0.1,0-0.2,0c-0.3,0.1-0.6,0.1-0.8,0.4C395.7,61.8,395.9,61.4,396.1,60.9z"/>
			<path fill="#4A4640" d="M388.3,68.7c-0.3,0-0.7-0.1-0.9,0.3c0.3,0.4,0.7,0.1,1.1,0.1C388.6,68.9,388.4,68.8,388.3,68.7z"/>
			<path fill="#403D39" d="M396.9,12.9c-0.1,0.3-0.3,0.5,0,0.8c0,0,0.2,0,0.3-0.1C397.3,13.3,397.2,13.1,396.9,12.9z"/>
			<path fill="#43403B" d="M401.7,55.9c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3-0.2,0.3-0.4c0-0.1-0.1-0.2-0.2-0.2
				C401.8,55.6,401.7,55.7,401.7,55.9z"/>
			<path fill="#0D0D0C" d="M383.4,61.4c-0.4-0.4-0.8-0.4-1,0.1c-0.2,0.4,0.2,0.4,0.5,0.4C383,61.7,383.3,61.7,383.4,61.4z"/>
			<path fill="#423E3A" d="M401.6,32.8c0,0.1,0.1,0.3,0.2,0.2c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.2,0-0.3-0.2-0.3
				C401.8,32.4,401.7,32.6,401.6,32.8z"/>
			<path fill="#45413D" d="M395.4,54.8c-0.2,0.1-0.3,0.2-0.3,0.3c0,0.2,0.2,0.2,0.3,0.2c0.1-0.1,0.3-0.1,0.2-0.3
				C395.6,54.8,395.5,54.8,395.4,54.8z"/>
			<path fill="#2A2927" d="M376.1,30.7c0.5-0.1,0.3-0.6,0.4-0.9c-0.1,0-0.2-0.1-0.2,0C376.2,30.1,375.8,30.3,376.1,30.7z"/>
			<path fill="#4D4843" d="M399.7,55.4c0.2-0.2,0.6-0.2,0.4-0.6c0-0.1-0.3-0.1-0.4-0.1C399.2,55,399.7,55.2,399.7,55.4
				C399.7,55.4,399.7,55.4,399.7,55.4z"/>
			<path fill="#2A2927" d="M405.3,19.5c0,0.2,0,0.4,0.2,0.4c0.2,0,0.2-0.1,0.2-0.3c0-0.2,0.1-0.4-0.2-0.4
				C405.4,19.3,405.3,19.5,405.3,19.5z"/>
			<path fill="#34322E" d="M402.6,22.8c0,0,0.1,0,0.1,0c0.3,0.1,0.7,0.5,0.8-0.1c0.1-0.4-0.5-0.2-0.8-0.2
				C402.5,22.5,402.5,22.6,402.6,22.8z"/>
			<path fill="#232220" d="M388.6,39.2c-0.1-0.4-0.2-0.7-0.6-0.7c0,0-0.1,0.1-0.1,0.2C387.9,39.1,388.2,39.1,388.6,39.2z"/>
			<path fill="#393733" d="M405.4,30.5c0,0.2,0.2,0.2,0.3,0.2c0.1,0,0.4-0.1,0.4-0.2c0-0.2-0.2-0.2-0.3-0.3
				C405.6,30.3,405.5,30.3,405.4,30.5z"/>
			<path fill="#0C0C0C" d="M405.7,21.8c-0.1-0.3,0.1-0.6-0.3-0.8c-0.1,0-0.2,0-0.2,0.1c-0.1,0.3-0.2,0.6,0.2,0.7
				C405.5,21.9,405.6,21.9,405.7,21.8z"/>
			<path fill="#0E0E0D" d="M389.1,39.8c0.2,0.1,0.2,0.6,0.5,0.4c0.1,0,0.2-0.2,0.1-0.3c-0.1-0.4-0.3-0.6-0.7-0.4
				C389,39.6,389,39.7,389.1,39.8C389.1,39.8,389.1,39.8,389.1,39.8z"/>
			<path fill="#0A0A0A" d="M390.1,63.9c0.2-0.1,0.3-0.3,0.2-0.5c-0.3-0.2-0.5-0.5-0.7-0.7l0,0c-0.1-0.1-0.2-0.1-0.2,0
				c0,0.4-0.2,0.9,0.2,1.3C389.8,64,390,64,390.1,63.9z"/>
			<path fill="#4C4842" d="M404.7,60c-0.1-0.2,0.1-0.6-0.2-0.7c-0.1,0-0.3,0.2-0.3,0.3C404.1,60,404.5,59.9,404.7,60
				C404.7,60,404.7,60,404.7,60z"/>
			<path fill="#494540" d="M401.4,60.7c0.1,0,0.2,0,0.3,0c0.1-0.6-0.2-0.7-0.7-0.8C400.8,60.4,401.3,60.4,401.4,60.7z"/>
			<path fill="#383532" d="M385.8,63.4c0.1,0.3-0.2,0.7,0.3,0.7c0,0,0.2-0.2,0.2-0.3C386.5,63.3,386.1,63.4,385.8,63.4
				C385.8,63.4,385.8,63.4,385.8,63.4z"/>
			<path fill="#32302D" d="M414.2,22.6c0.1,0,0.1-0.1,0.2-0.1c0.1-0.4,0.1-0.8,0.2-1.1c-0.2-0.1-0.4,0-0.5,0.2c0,0.1,0,0.2-0.1,0.3
				C413.8,22.1,413.9,22.4,414.2,22.6z"/>
			<path fill="#3A3834" d="M399.3,46c0-0.5,0.2-0.8-0.1-1.2C398.9,45.2,398.9,45.5,399.3,46z"/>
			<path fill="#4A4640" d="M397,21.6c-0.6,0.1-0.7,0.3-0.8,0.7C396.6,22.3,396.7,21.9,397,21.6z"/>
			<path fill="#373532" d="M394.9,45.3c0.2,0.1,0.4,0.3,0.7,0.5c-0.1-0.6-0.2-0.9-0.8-1C394.9,45.1,394.9,45.2,394.9,45.3
				C394.9,45.4,394.9,45.3,394.9,45.3z"/>
			<path fill="#141413" d="M393.4,65.8c-0.3-0.4-0.6-0.6-1-0.4C392.6,65.9,393,65.8,393.4,65.8z"/>
			<path fill="#232220" d="M396.1,58.1c-0.2-0.1-0.5-0.1-0.7,0c0,0.1,0,0.2,0,0.2c0,0.2,0,0.3,0,0.5C396,58.9,396.1,58.6,396.1,58.1
				z"/>
			<path fill="#121211" d="M402.4,57.9c-0.4,0-0.4,0.2-0.4,0.4c-0.1,0.2,0,0.3,0.2,0.3c0.2,0,0.4-0.1,0.4-0.3
				C402.5,58.1,402.4,58,402.4,57.9z"/>
			<path fill="#2A2927" d="M386.1,66.4c0,0.5,0.1,0.8,0.4,1C386.8,66.9,386.5,66.7,386.1,66.4z"/>
			<path fill="#20201E" d="M385.8,61.2c0.2-0.2,0.5-0.3,0.7-0.5c-0.1-0.3-0.4-0.4-0.7-0.4c0,0,0,0,0,0
				C385.9,60.5,385.5,60.9,385.8,61.2z"/>
			<path fill="#312F2C" d="M388.9,68.9c0-0.3,0.6-0.7,0.1-0.9c-0.5-0.3-0.4,0.4-0.6,0.6C388.5,68.8,388.6,69,388.9,68.9z"/>
			<path fill="#292825" d="M390.7,39.8c0.4-0.3,0.7-0.5,0.6-1C390.9,39,390.8,39.3,390.7,39.8z"/>
			<path fill="#0B0B0B" d="M392,68.5c-0.2-0.2-0.4-0.4-0.7-0.4c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.2,0.3-0.1,0.5
				C391.3,68.5,391.8,69.2,392,68.5z"/>
			<path fill="#44403B" d="M393,57c-0.1,0.4,0,0.8,0.2,0.8c0.4,0,0.2-0.3,0.1-0.5C393.3,57.2,393.1,57.1,393,57z"/>
			<path fill="#3D3A36" d="M389.2,36.2c0.2-0.4,0.1-0.7-0.3-0.8C388.6,35.8,389.2,35.9,389.2,36.2z"/>
			<path fill="#1B1A19" d="M407.3,27.8c0.2-0.3,0.9-0.6,0-0.9C407.2,27.2,407,27.5,407.3,27.8z"/>
			<path fill="#0E0E0D" d="M392,51.6c0.2,0.1,0.5,0.2,0.7,0.2c0.1,0,0.1-0.1,0.2-0.1c0-0.4-0.4-0.5-0.7-0.7
				C392.2,51.3,392.1,51.5,392,51.6z"/>
			<path fill="#3E3B37" d="M376.9,37.6c0.1,0.2,0.1,0.4,0.3,0.4c0.2,0,0.2-0.2,0.1-0.4c0-0.2-0.1-0.3-0.3-0.2
				C376.9,37.4,376.9,37.5,376.9,37.6z"/>
			<path fill="#423F3A" d="M406.1,10.3c-0.1,0-0.3,0.1-0.3,0.2c-0.1,0.2,0.1,0.3,0.3,0.2c0.1,0,0.3-0.1,0.4-0.2
				C406.5,10.4,406.3,10.4,406.1,10.3z"/>
			<path fill="#151514" d="M407.4,37.4c0,0.1,0,0.1,0,0.2c0.1,0.2,0.2,0.4,0.5,0.3c0.6-0.3,0.4-0.5,0-0.8
				C407.6,37.1,407.5,37.2,407.4,37.4z"/>
			<path fill="#3A3733" d="M377.7,16.6c0.1,0.1,0.3,0.2,0.4,0.1c0.2-0.1,0.2-0.3,0.1-0.4c0-0.1-0.2-0.1-0.3-0.1
				C377.7,16.3,377.6,16.4,377.7,16.6z"/>
			<path fill="#43403B" d="M395.4,42.7c0.1-0.3,0.1-0.5-0.2-0.7c-0.2,0.2-0.6,0-0.5,0.3C394.7,42.6,395.1,42.6,395.4,42.7z"/>
			<path fill="#3C3935" d="M386,50.2c0,0.3-0.3,0.6,0,0.7c0.2,0.1,0.4-0.1,0.4-0.3C386.6,50.2,386.3,50.2,386,50.2L386,50.2z"/>
			<path fill="#282724" d="M409.3,25.2c-0.1-0.3-0.2-0.4-0.5-0.2c0,0.3-0.2,0.6,0,0.8C409.2,25.9,409.3,25.5,409.3,25.2z"/>
			<path fill="#393734" d="M412.7,36.5c0,0.2,0,0.3,0,0.5c0.3,0.1,0.4,0.6,0.8,0.3C413.4,36.8,413,36.7,412.7,36.5z"/>
			<path fill="#413E39" d="M407.4,37.4c0.2-0.1,0.3-0.2,0.5-0.3c-0.2-0.4-0.4-0.7-0.7-0.2c-0.1,0.2-0.2,0.3,0,0.5
				C407.2,37.4,407.3,37.4,407.4,37.4z"/>
			<path fill="#423F3A" d="M405.7,15.9c-0.3,0.3-0.6,0.6-0.3,0.8c0.3,0.2,0.3-0.2,0.4-0.4C405.8,16.2,405.8,16.1,405.7,15.9z"/>
			<path fill="#0B0B0B" d="M402.2,49.3c-0.2,0-0.3,0.1-0.3,0.3c0,0.1,0.1,0.3,0.2,0.4c0.2,0.1,0.3,0,0.3-0.2
				C402.6,49.5,402.4,49.4,402.2,49.3z"/>
			<path fill="#3E3B37" d="M406.8,48.9c-0.2,0.1-0.4,0.3-0.8,0.5c0.7,0.1,1,0,1.2-0.5C407,48.8,406.9,48.8,406.8,48.9z"/>
			<path fill="#0B0B0B" d="M417,38.6c-0.2-0.3-0.6-0.5-0.7-0.2c-0.2,0.3,0.1,0.6,0.5,0.7C416.9,39,417,38.9,417,38.6z"/>
			<path fill="#1E1D1B" d="M393.5,73.5c0.1-0.3,0.3-0.7-0.2-0.9c-0.1,0-0.2,0-0.2,0C392.9,73.1,393.2,73.2,393.5,73.5L393.5,73.5z"
				/>
			<path fill="#3C3A36" d="M408.3,47c0,0.3,0,0.4,0.2,0.4c0.2,0,0.3-0.1,0.3-0.3c0-0.2,0-0.3-0.2-0.3C408.4,46.8,408.3,47,408.3,47z
				"/>
			<path fill="#554F49" d="M397.5,27.9c0.4-0.1,0.5-0.4,0.4-0.6c-0.3-0.3-0.3,0.1-0.4,0.3C397.4,27.6,397.5,27.8,397.5,27.9z"/>
			<path fill="#272624" d="M393,43.9c0.1,0,0.2,0,0.2,0c-0.1-0.9-0.8-0.4-1.2-0.5c-0.2,0-0.3,0-0.5,0c0,0-0.1,0-0.1,0
				c0,0.1,0,0.2,0.1,0.4c0.2,0.3,0.5,0.3,0.8,0.2C392.5,44,392.7,43.8,393,43.9z"/>
			<path fill="#0A0A09" d="M415.2,38c-0.2,0.4-0.4,0.6-0.1,0.8c0.1,0,0.3,0,0.3,0C415.6,38.5,415.3,38.3,415.2,38z"/>
			<path fill="#44403C" d="M396.8,53.1c0,0.3,0.2,0.2,0.4,0.2c0.2,0,0.3,0,0.3-0.2c0-0.2-0.1-0.3-0.3-0.3
				C397.1,52.8,396.9,53,396.8,53.1z"/>
			<path fill="#4F4A44" d="M395.3,11.7c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0,0.3-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.2
				C395.4,11.3,395.3,11.4,395.3,11.7z"/>
			<path fill="#4A4540" d="M377.9,43.4c0.1,0.1,0.2,0.3,0.2,0.2c0.3-0.1,0.3-0.3,0.3-0.5c0-0.1-0.1-0.3-0.2-0.2
				C378,43,378,43.2,377.9,43.4z"/>
			<path fill="#44403B" d="M382.8,11.8c0,0.2,0,0.4,0.2,0.4c0.1,0,0.2-0.2,0.2-0.2c0-0.1-0.1-0.4-0.2-0.4
				C382.8,11.5,382.8,11.7,382.8,11.8z"/>
			<path fill="#151514" d="M410.3,25.4c-0.1-0.1-0.2-0.4-0.2-0.3c-0.3,0.1-0.2,0.4-0.3,0.6C410.1,26.1,410.2,25.7,410.3,25.4z"/>
			<path fill="#353330" d="M383.4,61.4c-0.3,0.1-0.4,0.2-0.5,0.5c0.3,0,0.6,0.1,0.7-0.2C383.7,61.6,383.5,61.5,383.4,61.4z"/>
			<path fill="#555049" d="M391,68.7c0-0.2,0.1-0.3,0.1-0.5c-0.2,0-0.6-0.1-0.6,0.3C390.6,68.8,390.8,68.8,391,68.7z"/>
			<path fill="#34322E" d="M391.2,11.4c0.3-0.1,0.5-0.3,0.4-0.7C391.2,10.8,391.3,11.2,391.2,11.4z"/>
			<path fill="#252523" d="M396.1,66.3c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0,0.3,0.2,0.3c0.1,0,0.3-0.2,0.3-0.3
				C396.4,66.4,396.3,66.3,396.1,66.3z"/>
			<path fill="#191918" d="M393.7,43.2c0.1,0.4,0.5,0.4,0.7,0.3c0.3-0.2-0.1-0.4-0.2-0.6C393.8,42.8,393.7,42.8,393.7,43.2z"/>
			<path fill="#46433E" d="M400.6,9.8c0,0.3-0.1,0.5-0.2,0.9C400.9,10.5,400.9,10.2,400.6,9.8z"/>
			<path fill="#242321" d="M410.2,26.4c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.2,0.3,0.4,0.2c0.1,0,0.3-0.1,0.2-0.2
				C410.5,26.5,410.4,26.5,410.2,26.4z"/>
			<path fill="#1E1D1C" d="M391.5,67.7c0.4-0.1,1,0.1,0.7-0.7c-0.2,0.1-0.5,0.2-0.7,0.3C391.4,67.4,391.4,67.5,391.5,67.7
				L391.5,67.7z"/>
			<path fill="#2D2B29" d="M393.7,43.2c0.2-0.1,0.3-0.2,0.5-0.3c-0.2-0.3-0.4-0.5-0.7-0.8C393.3,42.6,393.5,42.9,393.7,43.2z"/>
			<path fill="#494540" d="M409.3,41c-0.1-0.2-0.3-0.4-0.6-0.2c-0.1,0.1-0.1,0.3-0.1,0.4C409,41.5,409.1,41.2,409.3,41L409.3,41z"/>
			<path fill="#302F2C" d="M391.6,69.8c0.1-0.1,0.2-0.2,0.2-0.2c0-0.2-0.2-0.3-0.4-0.3c-0.1,0-0.2,0.1-0.2,0.2
				C391.3,69.6,391.5,69.7,391.6,69.8z"/>
			<path fill="#565049" d="M383.9,44.6c-0.2-0.2-0.2-0.6-0.6-0.6c0,0-0.1,0.2-0.1,0.3C383.2,44.8,383.6,44.6,383.9,44.6L383.9,44.6z
				"/>
			<path fill="#282725" d="M389.6,62.6c0.1,0.4-0.1,1.1,0.7,0.7c0-0.1,0-0.2,0-0.2c-0.1-0.3-0.2-0.5-0.5-0.5
				C389.8,62.6,389.7,62.6,389.6,62.6z"/>
			<path fill="#48443F" d="M386.7,24.8c0.1,0.2,0.3,0.2,0.5,0.2c0.1,0,0.2-0.1,0.1-0.2c-0.1-0.1-0.3-0.1-0.4-0.2
				C386.8,24.6,386.7,24.6,386.7,24.8z"/>
			<path fill="#10100F" d="M389.2,42.1c-0.3,0.3-0.4,0.6-0.1,1.1C389.1,42.7,389.2,42.4,389.2,42.1z"/>
			<path fill="#403D39" d="M403.7,52c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.4,0,0.4-0.1c0.2-0.2-0.1-0.2-0.2-0.3
				C404,51.8,403.8,51.7,403.7,52z"/>
			<path fill="#161514" d="M392.7,47.5c-0.4-0.2-0.7-0.1-0.6,0.4c0.1,0.1,0.3,0.1,0.4,0.2c0.1-0.1,0.2-0.3,0.3-0.4
				C392.8,47.7,392.8,47.6,392.7,47.5z"/>
			<path fill="#181817" d="M396.2,37.9c-0.4,0.1-0.7,0.3-0.6,0.5c0.1,0.3,0.4,0.1,0.5-0.1C396.2,38.3,396.2,38.1,396.2,37.9z"/>
			<path fill="#2D2C29" d="M406.4,18.2c-0.2,0-0.4,0-0.4,0.2c0,0.1,0.2,0.4,0.3,0.4c0.3,0,0.3-0.3,0.3-0.5
				C406.6,18.1,406.5,18.1,406.4,18.2z"/>
			<path fill="#1A1918" d="M398.4,53.1c-0.3,0-0.4,0.2-0.3,0.3c0,0.1,0.2,0.3,0.3,0.3c0.2,0,0.3-0.2,0.2-0.3
				C398.6,53.3,398.5,53.2,398.4,53.1z"/>
			<path fill="#49453F" d="M382.9,34.3c0-0.1,0-0.2,0-0.3c-0.2,0.2-0.6,0.3-0.5,0.7c0,0.1,0.1,0.1,0.2,0.1
				C382.9,34.8,382.9,34.6,382.9,34.3z"/>
			<path fill="#2C2B29" d="M387.8,34.2c0.1,0.1,0.3,0.3,0.4,0.3c0.2,0,0.2-0.2,0.1-0.3c0-0.2-0.2-0.3-0.3-0.2
				C387.9,33.9,387.9,34.1,387.8,34.2z"/>
			<path fill="#272725" d="M397.8,54.7c-0.1,0,0-0.5-0.3-0.2c-0.3,0.3-0.2,0.7,0.2,0.9c0,0,0,0,0,0c0-0.1,0-0.3,0.1-0.4
				C397.9,54.9,397.9,54.9,397.8,54.7C397.9,54.7,397.8,54.7,397.8,54.7z"/>
			<path fill="#201F1E" d="M415.5,33.6c-0.1-0.2-0.2-0.5-0.2-0.7c-0.2-0.1-0.4-0.1-0.4,0.2C414.9,33.4,415.2,33.5,415.5,33.6z"/>
			<path fill="#0E0E0E" d="M413.4,38.5c0,0.1-0.1,0.2-0.1,0.4c-0.1,0.1-0.1,0.2,0.1,0.3c0.3,0,0.6,0,0.6-0.3
				C414,38.4,413.7,38.4,413.4,38.5z"/>
			<path fill="#494540" d="M400.9,55.9c0.2-0.1,0.5-0.2,0.2-0.5c-0.3-0.3-0.5,0-0.7,0.2C400.6,55.8,400.7,55.9,400.9,55.9z"/>
			<path fill="#3F3D38" d="M397.8,38.9c0.1,0,0.3,0,0.3-0.2c0-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0.1-0.2,0.1
				C397.5,38.8,397.7,38.8,397.8,38.9z"/>
			<path fill="#514C46" d="M390.1,56.6c-0.2,0-0.3,0-0.5,0c0.1,0.2-0.1,0.7,0.3,0.6C390.2,57.3,390.1,56.9,390.1,56.6z"/>
			<path fill="#121312" d="M386.5,57.8c0,0.2,0,0.3,0,0.5c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.6-0.4,0.2-0.6
				C386.7,57.2,386.6,57.6,386.5,57.8z"/>
			<path fill="#363431" d="M396.3,50.2c1-0.1,0.1-0.6,0.2-0.8C396.2,49.5,396.4,49.9,396.3,50.2C396.4,50.2,396.3,50.2,396.3,50.2z"
				/>
			<path fill="#4E4943" d="M391.5,49.7c-0.4,0.1-0.5,0.4-0.5,0.8C391.6,50.4,391.5,50,391.5,49.7C391.5,49.7,391.5,49.7,391.5,49.7z
				"/>
			<path fill="#2D2C2A" d="M404.7,16c0.1,0,0.2,0,0.3,0c0.1-0.2,0.2-0.5,0.3-0.7c0-0.1,0-0.2-0.1-0.3C404.1,14.9,405,15.8,404.7,16z
				"/>
			<path fill="#4C4742" d="M398.8,16.9c-0.4,0.2-0.4,0.6-0.2,0.7c0.3,0.3,0.3-0.1,0.3-0.2C398.9,17.3,398.9,17.2,398.8,16.9z"/>
			<path fill="#121211" d="M406.4,50.5c-0.2,0.6-0.1,1,0.1,1c0.3,0.1,0.3-0.3,0.2-0.5C406.7,51,406.6,50.9,406.4,50.5z"/>
			<path fill="#292825" d="M378.2,36.1c0.1,0.2,0.2,0.3,0.3,0.2c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.2-0.4-0.2
				C378.3,35.8,378.2,35.9,378.2,36.1z"/>
			<path fill="#141413" d="M406.4,18.2c0.1,0,0.2,0,0.3,0c0.2-0.1,0.4-0.2,0.3-0.4c0-0.1-0.3-0.2-0.4-0.2
				C406.4,17.7,406.4,18,406.4,18.2z"/>
			<path fill="#181817" d="M411.2,38.4c-0.4-0.2-0.8-0.5-1.2-0.7C410.1,38.5,410.6,38.5,411.2,38.4z"/>
			<path fill="#0F0F0E" d="M411,51.6c-0.1,0.3-0.7,0.8,0.2,0.7C411.3,52.1,411.6,51.7,411,51.6z"/>
			<path fill="#0A0B0A" d="M376,39.2c0.1-0.2,0.1-0.4,0.2-0.5c-0.1,0-0.1-0.1-0.2-0.1c-0.5,0.2-0.5,0.6-0.4,1
				C375.7,39.4,375.8,39.3,376,39.2z"/>
			<path fill="#47433E" d="M401.9,30.7c-0.1,0-0.2,0-0.2,0c0,0.2,0,0.5,0.3,0.5c0.1,0,0.2-0.1,0.2-0.2
				C402.2,30.8,402.1,30.8,401.9,30.7z"/>
			<path fill="#504B45" d="M404.2,31.4c0,0.2,0,0.4,0.2,0.4c0.1,0,0.2-0.1,0.2-0.2c0-0.2,0-0.3-0.2-0.3
				C404.3,31.3,404.2,31.4,404.2,31.4z"/>
			<path fill="#201F1D" d="M377.4,30.5c-0.3,0.3-0.3,0.7,0,1c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.3,0-0.4
				C377.7,30.5,377.6,30.4,377.4,30.5z"/>
			<path fill="#544E48" d="M391.5,59.9c0.1,0,0.2,0.2,0.2,0.2c0.1,0,0.2-0.1,0.4-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
				C391.6,59.6,391.5,59.7,391.5,59.9z"/>
			<path fill="#151413" d="M414.2,21.6c0.2-0.1,0.3-0.1,0.5-0.2c0,0,0,0,0,0c0.1-0.2,0.1-0.3,0-0.5
				C413.9,20.7,413.9,21.1,414.2,21.6z"/>
			<path fill="#4C4842" d="M405.4,58.3c-0.2,0.1-0.3,0.2-0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.4,0
				C405.6,58.8,405.5,58.6,405.4,58.3z"/>
			<path fill="#010101" d="M409.6,20.8c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.2,0,0.4,0.3,0.4c0.4,0,0.5-0.3,0.5-0.7
				C409.9,20.9,409.7,20.9,409.6,20.8z"/>
			<path fill="#232321" d="M389,60.8c-0.2,0-0.3,0.1-0.3,0.2c0,0.2,0.1,0.3,0.3,0.3c0.2,0.1,0.3-0.1,0.3-0.2
				C389.3,60.9,389.2,60.8,389,60.8z"/>
			<path fill="#302E2B" d="M405.7,61c0.1,0.3-0.4,0.5-0.1,0.7c0.1,0.1,0.3,0.1,0.4-0.1C406.2,61.3,405.9,61.1,405.7,61L405.7,61z"/>
			<path fill="#47433E" d="M389.6,20.6c0.2-0.2,0.2-0.5,0-0.7c0,0-0.2,0-0.2,0.1C389.4,20.2,389.3,20.5,389.6,20.6z"/>
			<path fill="#44403C" d="M384.4,4.2c-0.3-0.3-0.6-0.4-0.7-0.2c-0.2,0.3,0.2,0.2,0.3,0.2C384.1,4.2,384.2,4.2,384.4,4.2z"/>
			<path fill="#3A3834" d="M411.2,21.5c0,0.2,0.1,0.3,0.2,0.2c0.1,0,0.2-0.2,0.2-0.3c0-0.1,0-0.3-0.2-0.2
				C411.3,21.3,411.3,21.4,411.2,21.5z"/>
			<path fill="#3D3A36" d="M392.7,3c0.1,0.4,0.2,0.7,0.5,0.7C393.2,3.4,393,3.2,392.7,3z"/>
			<path fill="#312F2C" d="M386.9,0.8c0,0.2,0.1,0.3,0.2,0.2c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1-0.1-0.3-0.2-0.2
				C387,0.6,386.9,0.7,386.9,0.8z"/>
			<path fill="#514C46" d="M390.8,15.5c0.1,0.1,0.1,0.2,0.2,0.3c0-0.1,0.1-0.2,0.1-0.2c0-0.1-0.1-0.3-0.1-0.3
				C390.9,15.2,390.9,15.4,390.8,15.5z"/>
			<path fill="#2A2927" d="M398.7,25.4c0.3,0.1,0.4,0.2,0.5,0.2c0.2,0,0.3-0.2,0.3-0.3c0-0.1-0.1-0.2-0.2-0.2
				C399.1,25.1,399,25.2,398.7,25.4z"/>
			<path fill="#3F3C37" d="M383.9,7.5c-0.1,0.2-0.2,0.3-0.2,0.4c0,0.2,0,0.3,0.2,0.4c0,0,0.1-0.2,0.2-0.3
				C384.2,8,384.1,7.9,383.9,7.5z"/>
			<path fill="#48443F" d="M396.4,17.7c0.2,0,0.3-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2,0-0.2,0.1
				C396.2,17.5,396.3,17.6,396.4,17.7z"/>
			<path fill="#504C45" d="M400.9,13.2c0,0.2-0.2,0.5,0.1,0.5c0.2,0,0.4-0.3,0.4-0.5C401.3,12.9,401.1,13.2,400.9,13.2
				C400.9,13.2,400.9,13.2,400.9,13.2z"/>
			<path fill="#1B1B1A" d="M398.3,18.8c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.2-0.2,0.2-0.4c0-0.1-0.1-0.2-0.2-0.1
				C398.4,18.6,398.4,18.7,398.3,18.8z"/>
			<path fill="#524C46" d="M404.2,19.3c0.2,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3,0,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2
				C404.6,19.1,404.5,19.2,404.2,19.3z"/>
			<path fill="#534D47" d="M393.6,12.1c-0.2,0.3,0,0.5,0.2,0.7C394.1,12.4,393.8,12.3,393.6,12.1z"/>
			<path fill="#34322F" d="M407.1,19.6c0.1,0.1,0.1,0.2,0.3,0.3c0.1,0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.3-0.2-0.4
				C407.2,19.3,407.1,19.4,407.1,19.6z"/>
			<path fill="#4E4A44" d="M381.7,22.4c0.3,0.2,0.4,0.1,0.4,0c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.3,0.1
				C381.6,22.3,381.7,22.4,381.7,22.4z"/>
			<path fill="#4F4A44" d="M401.8,19.9c0.1,0.3,0.1,0.3,0.3,0.3c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.2-0.2
				C401.9,19.8,401.8,19.9,401.8,19.9z"/>
			<path fill="#504B44" d="M399.8,19.6c-0.1,0.1-0.3,0.1-0.3,0.2c0,0.2,0.1,0.3,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2
				C400,19.7,399.8,19.7,399.8,19.6z"/>
			<path fill="#3D3A36" d="M397,19.5c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
				C397.4,19.6,397.3,19.5,397,19.5z"/>
			<path fill="#1B1A19" d="M390.1,12.2c0.2,0.1,0.5,0.2,0.7,0.3c0-0.2-0.1-0.5-0.3-0.6C390.3,11.8,390.2,12,390.1,12.2z"/>
			<path fill="#4F4A44" d="M403.2,18.7c0.2-0.1,0.3-0.2,0.2-0.3c0-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.2-0.2,0.3
				C402.9,18.7,403,18.7,403.2,18.7z"/>
			<path fill="#0B0B0A" d="M387.7,65.1c0-0.1,0-0.1,0-0.2c-0.3-0.1-0.5,0-0.6,0.3c0,0.1,0.1,0.2,0.1,0.3
				C387.5,65.5,387.6,65.2,387.7,65.1z"/>
			<path fill="#49453F" d="M411.2,26.9c-0.1,0.4-0.2,0.5-0.2,0.6c0,0.1,0,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.1
				C411.4,27.5,411.3,27.4,411.2,26.9z"/>
			<path fill="#3A3835" d="M397.5,48.5c-0.4-0.3-0.7-0.2-0.8-0.1c-0.2,0.4,0.2,0.1,0.3,0.2C397.1,48.6,397.2,48.5,397.5,48.5z"/>
			<path fill="#272624" d="M418.2,35c0.9,0,0-0.5,0.2-0.7C417.9,34.4,418,34.7,418.2,35z"/>
			<path fill="#373531" d="M388.2,53c0,0.3,0,0.6,0,0.9C388.9,53.6,388.6,53.3,388.2,53z"/>
			<path fill="#514C46" d="M376.1,33.9c0,0.2,0.1,0.2,0.2,0.2c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2-0.1-0.2
				C376.2,33.8,376.2,33.9,376.1,33.9z"/>
			<path fill="#353330" d="M394.8,37.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C395,37.4,394.9,37.4,394.8,37.5z"/>
			<path fill="#191918" d="M408.3,54.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.3-0.3-0.4,0-0.5,0.2c0.2,0.2,0,0.5,0.2,0.7c0.1,0,0.2,0,0.2,0
				C408.2,54.8,408.3,54.7,408.3,54.5z"/>
			<path fill="#3F3C38" d="M405.2,54.5c0.1,0.1,0.1,0.2,0.2,0.2c0.2-0.2,0.5-0.3,0.7-0.5c-0.3-0.5-0.6,0.1-0.9,0
				C405.2,54.3,405.2,54.4,405.2,54.5z"/>
			<path fill="#34322F" d="M387.2,38.3c0.1-0.4,0-0.6-0.4-0.7C386.7,38,386.9,38.1,387.2,38.3z"/>
			<path fill="#141414" d="M398.5,47.9c0.1-0.2,0.3-0.3,0.4-0.5c-0.1-0.4-0.4-0.4-0.7-0.4c0,0.1,0,0.1,0,0.2
				C398.5,47.4,398.5,47.7,398.5,47.9z"/>
			<path fill="#3A3834" d="M396.1,63.3c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.3-0.2-0.4
				C396.1,63,396.1,63.1,396.1,63.3z"/>
			<path fill="#534D47" d="M407.9,54.9c-0.1-0.2-0.2-0.4-0.2-0.7c0,0.5-0.1,1,0.2,1.5c0.1-0.1,0.2-0.2,0.3-0.2
				C408,55.2,408,55.1,407.9,54.9z"/>
			<path fill="#2E2D2A" d="M389.9,62.6c0.2,0.2,0.3,0.3,0.5,0.5c0-0.2,0.3-0.4,0.1-0.6C390.2,62.4,390,62.5,389.9,62.6z"/>
			<path fill="#2D2C2A" d="M405.5,55.4c-0.2-0.2-0.3-0.7-0.8-0.5C405,55.1,404.9,55.8,405.5,55.4z"/>
			<path fill="#4B4741" d="M404,27.5c0.2,0.1,0.3,0.2,0.3,0.2c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1-0.1-0.2-0.2-0.2
				C404.2,27.2,404.1,27.3,404,27.5z"/>
			<path fill="#3C3935" d="M385.6,45.5c-0.1,0-0.3,0-0.3,0.2c0,0.1,0.2,0.2,0.2,0.2c0.1,0,0.3-0.1,0.4-0.2
				C385.9,45.5,385.7,45.6,385.6,45.5z"/>
			<path fill="#363430" d="M405.7,45.7c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C405.9,45.5,405.8,45.6,405.7,45.7z"/>
			<path fill="#0E0F0E" d="M401,45.7c0.1-0.1,0.3-0.2,0.3-0.3c0-0.2-0.1-0.2-0.3-0.2c-0.2,0-0.3,0.1-0.3,0.3
				C400.7,45.5,400.8,45.6,401,45.7z"/>
			<path fill="#3E3B37" d="M373.1,27.5c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0,0.2-0.2c0-0.1-0.1-0.3-0.1-0.3
				C373.2,27.3,373.1,27.4,373.1,27.5z"/>
			<path fill="#2C2B28" d="M385.7,35.9c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.2,0.1,0.3,0.2,0.3c0.1,0,0.2-0.1,0.2-0.2
				C385.9,36.1,385.8,36,385.7,35.9z"/>
			<path fill="#413E3A" d="M392.7,68.2c-0.1,0-0.2,0-0.2,0c0,0.3,0,0.7,0.3,0.6C393.1,68.7,392.9,68.4,392.7,68.2z"/>
			<path fill="#4D4842" d="M388.1,51.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0,0.2-0.1c0-0.1-0.2-0.3-0.3-0.3
				C388.2,50.8,388.1,50.9,388.1,51.1z"/>
			<path fill="#3C3935" d="M407.8,50.9c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2-0.1,0.3-0.2c0-0.1,0-0.3-0.1-0.3
				C407.9,50.6,407.9,50.7,407.8,50.9z"/>
			<path fill="#312F2C" d="M404.3,50.4c-0.2,0-0.4,0.1-0.4,0.3c0,0,0.2,0.2,0.2,0.1c0.1,0,0.3-0.1,0.4-0.2
				C404.5,50.5,404.4,50.4,404.3,50.4z"/>
			<path fill="#151615" d="M373.3,50.2c0.3,0.1,0.5,0.2,0.6,0.2c0.2,0,0.3,0,0.4-0.1c0.1-0.2,0-0.3-0.2-0.3
				C373.9,50,373.8,50,373.3,50.2z"/>
			<path fill="#4D4943" d="M384.1,69.8c0.2-0.1,0.4-0.2,0.5-0.4c0-0.1-0.1-0.2-0.2-0.2C384.1,69.4,384.1,69.6,384.1,69.8L384.1,69.8
				z"/>
			<path fill="#1B1B1A" d="M411.4,36.5c0.1,0.2,0.1,0.4,0.4,0.3c0.2,0,0.3-0.2,0.2-0.3c-0.1-0.2-0.3-0.4-0.5-0.2
				C411.4,36.3,411.4,36.4,411.4,36.5z"/>
			<path fill="#4C4842" d="M376.2,49.4c-0.2,0.1-0.4,0.2-0.4,0.5c0,0.1,0.1,0.2,0.2,0.2C376.2,49.9,376.2,49.7,376.2,49.4
				C376.2,49.4,376.2,49.4,376.2,49.4z"/>
			<path fill="#2B2A28" d="M401.8,36.6c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2-0.1,0.2-0.2
				C402.1,36.8,402,36.7,401.8,36.6z"/>
			<path fill="#393733" d="M386,50.2c0.2-0.2,0.5-0.4,0.5-0.7l0,0c-0.2-0.1-0.3-0.2-0.5-0.2l0,0C386,49.5,385.7,49.8,386,50.2
				C386,50.2,386,50.2,386,50.2z"/>
			<path d="M385.8,52.6c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.2,0.5-0.5,0.5-0.9C386,51.9,386.1,52.4,385.8,52.6z"/>
			<path fill="#3A3734" d="M386.4,69.5c-0.1,0.1-0.1,0.2-0.2,0.4c0,0.1,0.1,0.3,0.2,0.2c0.1-0.1,0.2-0.2,0.2-0.4
				C386.7,69.6,386.6,69.5,386.4,69.5z"/>
			<path fill="#393734" d="M384.1,69.8c-0.2,0-0.5-0.1-0.5,0.3c0,0.1,0.1,0.2,0.2,0.2C384.1,70.3,384.1,70,384.1,69.8
				C384.1,69.8,384.1,69.8,384.1,69.8z"/>
			<path fill="#3B3834" d="M382.9,65.9c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0.1-0.4,0.2
				C382.5,65.8,382.7,65.9,382.9,65.9z"/>
			<path fill="#494540" d="M387.7,71.3c0.4,0,0.4-0.1,0.4-0.2c0-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3,0-0.2,0.1
				C387.6,71.1,387.7,71.3,387.7,71.3z"/>
			<path fill="#353330" d="M412,35c0.1,0,0.2,0.1,0.2,0.1c0.2-0.1,0.4-0.2,0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2
				C411.8,34.6,412,34.8,412,35z"/>
			<path fill="#272624" d="M388.7,72c0.1,0,0.3,0.1,0.4,0.1c0-0.2,0-0.5-0.4-0.5C388.5,71.7,388.6,71.9,388.7,72z"/>
			<path fill="#393733" d="M399,49.4c0.2-0.2,0.3-0.5,0.5-0.7c0-0.1,0-0.1,0-0.2c-0.3,0-0.5,0.3-0.7,0.5
				C398.8,49.1,398.9,49.3,399,49.4z"/>
			<path fill="#4C4742" d="M392.8,40.3c-0.2,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.1,0.2,0.2c0-0.1,0.1-0.2,0.1-0.3
				C392.9,40.4,392.8,40.3,392.8,40.3z"/>
			<path fill="#0F0F0E" d="M410,58.3c0-0.1,0-0.2,0-0.3c-0.3-0.1-0.5-0.5-0.9-0.2C409.2,58.3,409.6,58.3,410,58.3z"/>
			<path fill="#302F2C" d="M382.6,40.3c-0.3-0.3-0.6-0.2-0.9,0C382,40.4,382.3,40.5,382.6,40.3z"/>
			<path fill="#2F2E2B" d="M406,60.2c-0.2,0.1-0.5,0.2-0.7,0.2c-0.1,0.4,0,0.6,0.5,0.5c0,0,0,0,0,0C405.8,60.7,405.9,60.5,406,60.2z
				"/>
			<path fill="#4E4943" d="M396.1,40.6c0.1,0.2,0.3,0.4,0.6,0.3c0.2,0,0.3-0.1,0.2-0.3c0,0-0.2,0-0.3,0
				C396.4,40.4,396.3,40.4,396.1,40.6z"/>
			<path fill="#4F4A44" d="M381.6,43.1c0,0.1-0.1,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
				C381.9,43.2,381.8,43.1,381.6,43.1z"/>
			<path fill="#0B0B0A" d="M408.4,44c0.1-0.3,0.2-0.7,0.2-1c-0.2,0.3-0.7,0.6-0.4,1.1C408.2,44.1,408.3,44.1,408.4,44
				C408.3,44.1,408.3,44,408.4,44z"/>
			<path fill="#0C0C0B" d="M413.1,43c-0.2-0.3-0.4-0.5-0.7-0.5C412.5,42.9,412.4,43.4,413.1,43z"/>
			<path fill="#504B45" d="M403.6,30.9c-0.1,0.1-0.2,0.2-0.2,0.2c0,0.2,0.1,0.3,0.3,0.2c0.1,0,0.2-0.2,0.2-0.3
				C403.8,31.1,403.6,31,403.6,30.9z"/>
			<path fill="#232220" d="M389.5,59.6c-0.2,0-0.3,0.2-0.3,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.2,0.2-0.3
				C389.7,59.8,389.6,59.7,389.5,59.6z"/>
			<path fill="#47433E" d="M376.4,42c0,0.2-0.2,0.5,0.1,0.6c0.1,0,0.3-0.1,0.3-0.2C376.9,42,376.6,42,376.4,42
				C376.4,42,376.4,42,376.4,42z"/>
			<path fill="#45413C" d="M395.2,31.1c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1-0.1,0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
				C395.4,30.9,395.3,31,395.2,31.1z"/>
			<path fill="#2A2927" d="M396.1,41.8c0,0.4,0,0.9,0.2,1.3C396.7,42.5,396.5,42.1,396.1,41.8z"/>
			<path fill="#1A1918" d="M387.8,41.6c-0.1-0.3-0.4-0.3-0.7-0.4C387.2,41.5,387.5,41.6,387.8,41.6z"/>
			<path fill="#252423" d="M405.5,41.3c0,0.3,0,0.6,0,0.9C406.6,41.9,405.7,41.6,405.5,41.3z"/>
			<path fill="#050505" d="M415.5,40.3c0-0.1,0-0.3,0-0.4c-0.3,0-0.7-0.3-0.7,0C414.7,40.3,415.3,40,415.5,40.3z"/>
			<path fill="#4F4A45" d="M398.1,57.7c0.1,0,0.2,0.1,0.3,0c0.1,0,0.2-0.1,0.1-0.3c0,0-0.1-0.1-0.2-0.1
				C398.2,57.5,398,57.5,398.1,57.7z"/>
			<path fill="#3B3834" d="M400.9,58.2c0.1-0.2,0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.3-0.2c-0.1,0-0.1,0.2-0.1,0.3
				C400.7,58,400.8,58.1,400.9,58.2z"/>
			<path fill="#56504A" d="M403.3,29.5c-0.2,0.2-0.2,0.5,0,0.7c0.2,0.2,0.3,0,0.4-0.2C403.8,29.7,403.5,29.7,403.3,29.5z"/>
			<path fill="#050505" d="M410.4,44.1c-0.1,0.1-0.3,0.2-0.3,0.4c-0.1,0.2,0.2,0.2,0.3,0.2c0.1,0,0.4,0.1,0.4-0.2
				C410.8,44.3,410.6,44.2,410.4,44.1z"/>
			<path fill="#403D39" d="M404.3,62.4c0.3,0.1,0.5,0.1,0.7-0.1c0,0-0.1-0.2-0.1-0.2C404.6,62.2,404.4,62.1,404.3,62.4z"/>
			<path fill="#3C3A36" d="M394.3,35.4c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.1
				C394.4,35.2,394.4,35.3,394.3,35.4z"/>
			<path d="M404.4,18.2c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.1,0.3,0,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2C404.6,18,404.5,18.1,404.4,18.2z"/>
			<path fill="#353330" d="M409,58.8c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.1,0.2-0.2
				C409.2,59,409.1,58.9,409,58.8z"/>
			<path fill="#45413C" d="M384.4,32.1c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0-0.2-0.1-0.2
				C384.8,31.9,384.7,32,384.4,32.1z"/>
			<path fill="#1D1D1C" d="M413.4,20c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.2-0.1,0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.2
				C413.5,19.7,413.4,19.8,413.4,20z"/>
			<path fill="#302F2C" d="M375.7,51.6c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2,0.1-0.3,0.3-0.2,0.5C375.3,51.9,375.5,51.8,375.7,51.6z"/>
			<path fill="#47433E" d="M380.1,20.6c0.1,0,0.1,0.1,0.2,0.1c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.1-0.1-0.2,0
				C380.2,20.4,380.1,20.5,380.1,20.6z"/>
			<path fill="#565049" d="M403.6,56.9c0.2-0.1,0.7,0.3,0.7-0.1c0-0.3-0.4-0.2-0.7-0.1C403.5,56.7,403.5,56.8,403.6,56.9z"/>
			<path fill="#201F1D" d="M416.3,33.3c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.2,0.2,0.4,0.4,0.3C416.6,33.7,416.4,33.5,416.3,33.3z"/>
			<path fill="#1C1C1A" d="M396.6,57.4c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0,0.4,0.1,0.4-0.2C397.2,57.3,396.9,57.3,396.6,57.4z"/>
			<path fill="#4E4A44" d="M381.9,57.6c0.1,0.2,0,0.5,0.3,0.5c0,0,0.1,0,0.1-0.1C382.3,57.7,382.1,57.7,381.9,57.6
				C381.9,57.5,381.9,57.6,381.9,57.6z"/>
			<path fill="#0C0C0C" d="M408.3,20.6c0-0.2,0-0.5,0-0.7c-0.6,0-0.2,0.5-0.5,0.7c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0.1,0.1
				C408,20.8,408.2,20.7,408.3,20.6z"/>
			<path fill="#4B4641" d="M400.9,53.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.1-0.2c0,0-0.1,0-0.2,0
				C400.9,52.9,400.9,53,400.9,53.1z"/>
			<path fill="#4E4A44" d="M378.1,31.7c0.2,0.2,0.4,0.3,0.6,0.1c0,0,0-0.2,0-0.2C378.5,31.4,378.3,31.6,378.1,31.7L378.1,31.7z"/>
			<path fill="#58524B" d="M409.8,18.7c0.1,0.2,0.2,0.3,0.2,0.5c0.1-0.2,0.4-0.3,0.2-0.6C410,18.5,409.9,18.4,409.8,18.7z"/>
			<path fill="#45423D" d="M404.4,35.2c0.1,0.1,0.1,0.2,0.2,0.2c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0.1-0.3-0.2-0.3
				C404.5,34.9,404.5,35.1,404.4,35.2z"/>
			<path fill="#2A2927" d="M398.9,20.7c0.1-0.1,0.2-0.2,0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.2,0.1-0.1,0.1
				C398.7,20.4,398.8,20.5,398.9,20.7z"/>
			<path fill="#1E1D1B" d="M403.6,56.9c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c-0.4-0.3-0.5,0-0.7,0.3C403.1,57.3,403.3,57.3,403.6,56.9z"/>
			<path fill="#131212" d="M410,32.5c0.2-0.1,0.4-0.1,0.3-0.3c-0.1-0.2-0.4-0.2-0.6,0C409.8,32.3,409.9,32.4,410,32.5z"/>
			<path fill="#35332F" d="M413.4,39.1c0-0.1,0-0.2-0.1-0.3c-0.2,0-0.4,0-0.7,0c0,0.3,0,0.5,0.2,0.7c0,0,0,0,0,0
				C413.1,39.4,413.2,39.3,413.4,39.1z"/>
			<path fill="#373531" d="M379.6,39.2c-0.1,0.1-0.1,0.2-0.2,0.4c0,0.1,0,0.3,0.2,0.2c0.1-0.1,0.1-0.2,0.2-0.3
				C379.8,39.3,379.8,39.2,379.6,39.2z"/>
			<path fill="#3B3935" d="M381,39.1c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.2,0,0.2-0.2C381.4,39.2,381.2,39.2,381,39.1
				C381,39.1,381,39.1,381,39.1z"/>
			<path fill="#2B2A28" d="M385.4,12.6c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.1-0.2-0.2c-0.1,0.1-0.2,0.2-0.2,0.2
				C385.2,12.3,385.3,12.4,385.4,12.6z"/>
			<path fill="#3B3834" d="M394.5,39.8c-0.1,0.2-0.2,0.3-0.2,0.3c0,0.1,0.1,0.1,0.2,0.2c0-0.1,0.1-0.1,0.1-0.2
				C394.6,40,394.5,39.9,394.5,39.8z"/>
			<path fill="#373431" d="M405.6,44.5c-0.1,0.1-0.2,0.1-0.2,0.1c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0.1-0.1,0.2-0.1
				C405.8,44.6,405.7,44.5,405.6,44.5z"/>
			<path fill="#47433E" d="M396.1,40.6c0.2,0,0.3,0,0.5,0c-0.2-0.2-0.4-0.6-0.8-0.4C395.7,40.2,396,40.4,396.1,40.6z"/>
			<path fill="#0D0D0C" d="M413.4,40.8c0-0.3,0-0.7-0.5-0.8C412.9,40.5,413,40.7,413.4,40.8z"/>
			<path fill="#504B45" d="M402.7,44.1c0-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0,0.1,0.1,0.2
				C402.6,44.2,402.7,44.1,402.7,44.1z"/>
			<path fill="#47433F" d="M401.5,11.7c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0.1,0.1,0.1,0.1c0.1-0.1,0.2-0.2,0.2-0.2
				C401.6,11.8,401.5,11.7,401.5,11.7z"/>
			<path fill="#010101" d="M416.5,40.6c-0.4,0-0.6,0.4-0.7,0.7c0.2,0.1,0.3,0.1,0.5,0C416.4,41.1,416.6,40.9,416.5,40.6z"/>
			<path fill="#46423D" d="M407.9,11.5c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0,0.2,0,0.2-0.2c0-0.1-0.1-0.1-0.2-0.1
				C408,11.3,407.9,11.3,407.9,11.5z"/>
			<path fill="#0B0B0A" d="M411,40.5c-0.1,0-0.2,0-0.3,0.1c0,0.2,0.1,0.5,0.4,0.4c0.1,0,0.1-0.2,0.2-0.2
				C411.3,40.7,411.1,40.6,411,40.5z"/>
			<path fill="#4F4A44" d="M381.7,42.6c0.1,0,0.2-0.1,0.2-0.1c0-0.2-0.2-0.2-0.3-0.2c-0.1,0-0.1,0.1-0.2,0.1
				C381.5,42.4,381.6,42.5,381.7,42.6z"/>
			<path fill="#302F2C" d="M402.2,10.5c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0,0.2,0.1,0.3c0.1,0,0.2-0.1,0.2-0.1
				C402.2,10.7,402.2,10.6,402.2,10.5z"/>
			<path fill="#262523" d="M381.3,41.1c0.1,0,0.1-0.1,0.2-0.1c-0.1-0.1-0.2-0.2-0.4-0.3c0,0-0.2,0-0.2,0.1
				C380.9,41,381.1,41,381.3,41.1z"/>
			<path fill="#252523" d="M396.9,9.7c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.2-0.1-0.2-0.1c0,0-0.1,0.1-0.1,0.2
				C396.6,9.6,396.7,9.7,396.9,9.7z"/>
			<path fill="#49453F" d="M384.6,42.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
				C384.5,42.2,384.5,42.2,384.6,42.3z"/>
			<path fill="#3F3C38" d="M396.4,6.4c0,0.2-0.1,0.4-0.1,0.5c0,0,0.2,0.1,0.3,0.1c0-0.1,0.1-0.2,0.1-0.3
				C396.6,6.6,396.5,6.5,396.4,6.4z"/>
			<path fill="#49453F" d="M385.6,41.5c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0.2,0.2,0.3,0.1c0.1,0,0.1-0.1,0.1-0.2
				C385.9,41.5,385.8,41.4,385.6,41.5z"/>
			<path fill="#3C3A36" d="M410.7,42c0.1-0.2,0.2-0.4-0.1-0.6c0,0-0.2,0-0.2,0c0,0.2,0,0.4,0.1,0.5C410.6,42.2,410.7,42.2,410.7,42
				L410.7,42z"/>
			<path fill="#0B0B0B" d="M402.1,51.1c-0.2,0.1-0.3,0.2-0.2,0.3c0,0.1,0.2,0.2,0.3,0.1c0.2,0,0.3-0.2,0.2-0.3
				C402.3,51.2,402.1,51.2,402.1,51.1z"/>
			<path fill="#32302D" d="M405.6,51.5c0-0.1,0-0.2,0-0.3c0,0-0.1-0.1-0.2-0.1c0,0.1-0.1,0.2-0.1,0.3
				C405.3,51.4,405.5,51.4,405.6,51.5z"/>
			<path fill="#1C1C1B" d="M375.9,36.3c-0.2,0.1-0.5,0.2-0.5,0.6c0,0.1,0.1,0.2,0.1,0.2c0.3-0.1,0.3-0.3,0.3-0.5
				C376,36.4,376,36.4,375.9,36.3C376,36.3,376,36.3,375.9,36.3z"/>
			<path fill="#1C1C1A" d="M383.5,36.8c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.1,0.3,0,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2
				C383.7,36.6,383.6,36.7,383.5,36.8z"/>
			<path fill="#393733" d="M392.3,37.4c0.2,0,0.5-0.1,0.5-0.4c0,0-0.1-0.1-0.1-0.1C392.3,37,392.3,37.2,392.3,37.4
				C392.3,37.4,392.3,37.4,392.3,37.4z"/>
			<path fill="#0D0E0D" d="M386.5,49.4c0.2,0,0.5-0.1,0.4-0.4c0-0.1-0.2-0.2-0.2-0.2C386.4,48.9,386.5,49.2,386.5,49.4
				C386.5,49.4,386.5,49.4,386.5,49.4z"/>
			<path fill="#282725" d="M406.8,48.9c0.1,0,0.2,0,0.4,0c0.1-0.1,0.1-0.2,0.2-0.2c-0.1-0.2-0.1-0.4-0.2-0.5
				C406.7,48.3,406.7,48.6,406.8,48.9z"/>
			<path fill="#2D2C29" d="M390.3,46.8c0.2,0,0.4,0.1,0.4-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.4,0
				C390.2,46.5,390.3,46.7,390.3,46.8z"/>
			<path fill="#3F3C38" d="M393.3,46.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.1-0.2,0.1-0.3
				C393.4,46.5,393.4,46.4,393.3,46.4z"/>
			<path fill="#1F1E1D" d="M407.4,46.3c-0.2-0.1-0.3-0.2-0.5-0.2c0,0.2-0.3,0.4-0.1,0.5C407.1,46.7,407.2,46.5,407.4,46.3z"/>
			<path fill="#373531" d="M417,38.6c-0.1,0.2-0.2,0.3-0.3,0.5c0.2,0,0.4,0.1,0.5-0.2C417.3,38.8,417.1,38.7,417,38.6z"/>
			<path fill="#191918" d="M377.4,45.8c0,0.3,0,0.5,0,0.8c0.2-0.1,0.5-0.1,0.5-0.4C377.9,46,377.6,45.9,377.4,45.8z"/>
			<path fill="#393633" d="M387.1,71.9c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0.2,0.1,0.2-0.1,0.2-0.2
				C387.2,72.1,387.1,72,387.1,71.9z"/>
			<path fill="#31302D" d="M387.7,70.1c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1,0,0.2,0.2,0.2c0.1,0,0.2-0.1,0.3-0.1
				C388,70.2,387.9,70.1,387.7,70.1z"/>
			<path fill="#0C0C0C" d="M402.6,22.8c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.1,0-0.2c-0.2,0-0.4-0.1-0.5,0.2c-0.1,0.2,0,0.4,0.3,0.5
				C402.6,23,402.6,22.9,402.6,22.8z"/>
			<path fill="#0C0C0B" d="M407.9,22.3c0,0.3,0,0.5,0,0.8c0.2-0.1,0.5-0.2,0.4-0.5C408.3,22.4,408.1,22.3,407.9,22.3z"/>
			<path fill="#504B45" d="M395.4,69.4c0.3,0.2,0.4,0.2,0.6,0c0,0,0-0.1,0-0.2C395.8,69.3,395.7,69.3,395.4,69.4z"/>
			<path fill="#555049" d="M391.5,67.7c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.3-0.3-0.3c-0.1,0.1-0.2,0.3-0.3,0.4c0,0,0,0,0,0
				C391.2,67.5,391.4,67.6,391.5,67.7z"/>
			<path fill="#46423D" d="M398.1,67c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2-0.2,0.1-0.3c0-0.1-0.1-0.1-0.2-0.1
				C398.1,66.7,398.1,66.8,398.1,67z"/>
			<path fill="#363431" d="M388.7,66.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.1-0.5,0.2-0.3,0.5C388.3,67,388.5,66.7,388.7,66.5z"/>
			<path fill="#4A4640" d="M385.4,22.4c0.1,0.1,0.1,0.1,0.2,0.1c0.1-0.1,0.1-0.2,0.1-0.3c0,0-0.1-0.1-0.1-0.1
				C385.5,22.3,385.5,22.4,385.4,22.4z"/>
			<path fill="#3E3C37" d="M396.8,28.8c0.2-0.1,0.3-0.2,0.5-0.3c-0.2-0.1-0.4-0.4-0.6-0.1C396.7,28.4,396.8,28.7,396.8,28.8z"/>
			<path fill="#0D0D0C" d="M395.9,65.5c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.2-0.2-0.2c-0.1,0-0.2,0.2-0.2,0.3
				C395.6,65.4,395.7,65.5,395.9,65.5z"/>
			<path fill="#534D47" d="M405.1,28.9c-0.2,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.1,0.1,0.1c0.1-0.1,0.2-0.1,0.2-0.2
				C405.2,29,405.1,29,405.1,28.9z"/>
			<path fill="#33312E" d="M393.7,62.9c-0.2-0.2-0.5-0.3-0.7-0.5c-0.1,0.2-0.2,0.3-0.3,0.5c0,0.1,0,0.2,0,0.3
				C393,63,393.4,62.8,393.7,62.9z"/>
			<path fill="#393633" d="M399.7,63.1c0-0.2,0-0.3,0-0.5c-0.2,0-0.4-0.1-0.4,0.2C399.2,63.1,399.4,63.2,399.7,63.1z"/>
			<path fill="#48443F" d="M376.5,25.9c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0.2,0.2c0,0,0.1-0.1,0.1-0.2
				C376.6,26,376.5,25.9,376.5,25.9z"/>
			<path fill="#282725" d="M411.6,25.1c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.2-0.2c0-0.1-0.2-0.2-0.2-0.2
				C411.8,24.9,411.7,25,411.6,25.1z"/>
			<path fill="#383632" d="M371.9,24.5c0.2,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1-0.1-0.2-0.2-0.2
				C372.1,24.4,372,24.5,371.9,24.5z"/>
			<path fill="#4B4641" d="M412.1,27.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1-0.2,0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
				C412.2,26.9,412.1,27,412.1,27.1z"/>
			<path fill="#45423D" d="M389.9,24.4c0.1,0.1,0.2,0.1,0.3,0.2c0-0.1,0.1-0.2,0.1-0.2c0,0-0.1-0.2-0.1-0.1
				C390.1,24.3,390,24.3,389.9,24.4z"/>
			<path fill="#373531" d="M383.2,23.7c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0-0.2-0.1-0.2
				C383.4,23.6,383.3,23.7,383.2,23.7z"/>
			<path fill="#48443F" d="M391.3,72c0.1,0.2,0.1,0.5,0.2,0.7c0.1-0.1,0.2-0.1,0.2-0.2C391.8,72.2,391.5,72.1,391.3,72L391.3,72z"/>
			<path fill="#090909" d="M407.9,20.9c0-0.1,0-0.2,0-0.2c-0.2-0.1-0.4-0.2-0.6,0c0,0,0,0.2,0,0.2C407.4,21.1,407.7,20.9,407.9,20.9
				z"/>
			<path fill="#34322F" d="M393.2,59.7c0-0.2,0-0.3,0-0.5c-0.3,0.1-0.6,0.2-0.5,0.5C392.8,60,393.1,59.7,393.2,59.7z"/>
			<path fill="#1B1B1A" d="M404.3,21.2c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.1-0.3
				C404.4,20.9,404.3,21,404.3,21.2z"/>
			<path fill="#21201E" d="M414.6,20.8c0,0.2,0,0.3,0,0.5c0.3-0.1,0.7,0.3,0.7-0.3C415.1,21,414.8,20.9,414.6,20.8z"/>
			<path fill="#3C3935" d="M377.4,30.5c0.1,0.1,0.2,0.2,0.3,0.3c0.3-0.1,0.3-0.4,0.1-0.5C377.5,30,377.5,30.3,377.4,30.5z"/>
			<path fill="#2E2D2A" d="M381.6,60.6c0-0.1-0.1-0.2-0.1-0.2c-0.1,0-0.2,0-0.3,0.1c0,0.1,0,0.2,0.1,0.2
				C381.3,60.6,381.5,60.6,381.6,60.6z"/>
			<path fill="#4F4A44" d="M391.3,62.4c0.5,0,0.5,0.4,0.5,0.7c0.2-0.2,0.3-0.5,0.1-0.7C391.7,62.1,391.5,62.3,391.3,62.4z"/>
			<path fill="#151413" d="M409.6,24c0.1,0.2,0.1,0.5,0.4,0.5c0.3-0.1,0.1-0.3,0.1-0.5C409.9,24,409.7,24,409.6,24z"/>
			<path fill="#363431" d="M406.4,24c-0.2,0-0.4,0.1-0.4,0.3c0,0,0.1,0.1,0.1,0.1C406.4,24.4,406.4,24.2,406.4,24
				C406.4,24,406.4,24,406.4,24z"/>
			<path fill="#3B3835" d="M386.2,39.3c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0-0.2-0.1-0.2
				C386.3,39.2,386.2,39.3,386.2,39.3z"/>
			<path fill="#48443F" d="M407.7,59.1c0,0.1,0,0.2,0.2,0.2c0,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.2
				C407.7,58.9,407.7,59,407.7,59.1z"/>
			<path fill="#565049" d="M402.8,56.9c0.2-0.1,0.5-0.2,0.7-0.3C403.1,56.1,403,56.5,402.8,56.9z"/>
			<path fill="#32312E" d="M400.8,58.9c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0.1-0.1,0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.3
				C400.9,58.8,400.8,58.8,400.8,58.9z"/>
			<path fill="#565049" d="M383.9,44.6c-0.1,0.2-0.3,0.4-0.1,0.6c0,0,0.1,0,0.1,0C384,45,384,44.8,383.9,44.6L383.9,44.6z"/>
			<path fill="#282826" d="M387.3,60.1c0.1,0,0.2,0,0.3,0c0,0,0.1-0.1,0.1-0.2c-0.1,0-0.2-0.1-0.3,0C387.4,59.9,387.4,60,387.3,60.1
				z"/>
			<path fill="#0F0F0E" d="M397.8,44.3c0.2,0.1,0.3,0.1,0.3,0.1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.2-0.1-0.2-0.2-0.2
				C398.1,44.2,398,44.3,397.8,44.3z"/>
			<path fill="#413E3A" d="M389.1,39.8c0-0.1,0-0.2,0-0.3c-0.2,0.1-0.5,0-0.4,0.3C388.7,40,389,39.9,389.1,39.8z"/>
			<path fill="#312F2D" d="M398.8,12c-0.2-0.1-0.4-0.2-0.4,0.1c0,0.3,0.2,0.2,0.4,0.2C398.8,12.2,398.9,12.1,398.8,12
				C398.8,12,398.8,12,398.8,12z"/>
			<path fill="#3E3B37" d="M382.8,26.7c-0.1,0-0.1-0.1-0.1-0.1c0,0.1-0.1,0.2-0.1,0.2c0.1,0,0.1,0.1,0.2,0
				C382.8,26.9,382.8,26.8,382.8,26.7z"/>
			<path fill="#151513" d="M403.1,25c0-0.2,0.1-0.6-0.3-0.5c-0.3,0.1-0.2,0.3,0,0.5C402.9,25.1,403,25.1,403.1,25z"/>
			<path fill="#4B4842" d="M375.4,23.4c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1-0.1,0.1-0.1
				C375.6,23.5,375.5,23.5,375.4,23.4z"/>
			<path fill="#282825" d="M391.3,37.9c-0.2,0-0.3-0.4-0.6-0.2c0,0,0,0.1,0,0.2C390.9,38.2,391.1,38.1,391.3,37.9
				C391.3,37.9,391.3,37.9,391.3,37.9z"/>
			<path fill="#45413C" d="M407.9,38c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.2,0,0.4,0.2,0.5C407.7,38.3,407.8,38.1,407.9,38z"/>
			<path fill="#363430" d="M412.6,37.9c0.1,0.4,0.4,0.4,0.7,0.2C413.2,38,412.9,37.9,412.6,37.9z"/>
			<path fill="#151615" d="M394,47.5c0.2-0.1,0.5-0.1,0.4-0.5c0,0-0.1-0.1-0.2-0.1C393.8,47,394,47.3,394,47.5
				C394,47.5,394,47.5,394,47.5z"/>
			<path fill="#4B4742" d="M403.2,38.3c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0-0.1-0.1-0.2
				C403.4,38.2,403.3,38.2,403.2,38.3z"/>
			<path fill="#413E39" d="M378.6,46.9c0.1-0.1,0.1-0.2,0.2-0.2c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0.1-0.2,0.1-0.2,0.2
				C378.4,46.9,378.5,46.9,378.6,46.9z"/>
			<path fill="#46433E" d="M389,13.2c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0.1,0.2,0.1
				C388.9,13.3,388.9,13.2,389,13.2z"/>
			<path fill="#3B3935" d="M404.7,46.8c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1-0.1,0.1-0.1c-0.1,0-0.2-0.1-0.3-0.1
				C404.8,46.6,404.7,46.7,404.7,46.8z"/>
			<path fill="#403D38" d="M408.6,38.4c-0.1,0-0.1,0.1-0.2,0.1c0,0.1,0,0.2,0.2,0.2c0,0,0.1-0.1,0.1-0.2
				C408.8,38.5,408.7,38.4,408.6,38.4z"/>
			<path fill="#43403B" d="M408.5,31.6c-0.1,0-0.1,0.1-0.2,0.1c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.1,0,0.2-0.1
				C408.5,31.7,408.5,31.6,408.5,31.6z"/>
			<path fill="#34322F" d="M378.4,38.9c0.1,0,0.1,0.1,0.2,0.1c0.2,0,0.2-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1
				C378.5,38.7,378.4,38.8,378.4,38.9z"/>
			<path fill="#403D39" d="M400.2,38.8c-0.1,0.1-0.2,0.1-0.2,0.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.2-0.1,0.2-0.1
				C400.3,39,400.2,38.9,400.2,38.8z"/>
			<path fill="#4E4A44" d="M377.7,31.2c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0.1,0.5,0.1,0.7,0.2c0,0,0,0,0,0
				C378.1,31.4,377.9,31.3,377.7,31.2z"/>
			<path fill="#191917" d="M379,45.9c-0.2-0.1-0.5-0.2-0.7-0.3c0,0.2,0,0.5,0.3,0.5C378.8,46.2,379,46.1,379,45.9z"/>
			<path fill="#34322F" d="M378,38.9c-0.1,0-0.1,0.1-0.2,0.1c0,0.1,0,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.2C378.1,39,378,38.9,378,38.9
				z"/>
			<path fill="#1E1E1C" d="M377.6,45.6c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.2-0.4,0.3-0.7,0.5c0.2,0,0.5,0,0.7,0
				C377.5,45.8,377.6,45.7,377.6,45.6z"/>
			<path fill="#2F2D2B" d="M403.1,25c-0.1,0-0.2,0-0.3,0c0.1,0.2,0.2,0.4,0.5,0.2c0.1,0,0.1-0.2,0.1-0.3C403.3,25,403.2,25,403.1,25
				z"/>
			<path fill="#403D38" d="M372.2,26.8c0-0.1,0-0.2,0-0.3c0,0-0.1-0.1-0.2-0.1c0,0.1,0,0.2,0,0.3C372.1,26.8,372.2,26.8,372.2,26.8z
				"/>
			<path fill="#4A4641" d="M400.9,42.4c0,0.1,0.1,0.1,0.1,0.1c0,0,0.1-0.1,0.2-0.1c0-0.1-0.1-0.1-0.1-0.1
				C401,42.3,401,42.4,400.9,42.4z"/>
			<path fill="#403D39" d="M390.1,40.9c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1-0.1,0-0.2c0,0-0.1-0.1-0.2-0.1
				C390.1,40.8,390.1,40.8,390.1,40.9z"/>
			<path fill="#413E3A" d="M394.1,9.9c0.1,0,0.2,0,0.2,0c0,0,0-0.1,0-0.2c-0.1,0-0.2,0-0.2,0C394.1,9.7,394.1,9.8,394.1,9.9z"/>
			<path fill="#403D39" d="M384.5,25.6c0-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.2,0.1-0.2,0.1c0,0.1,0.1,0.1,0.1,0.2
				C384.4,25.6,384.4,25.6,384.5,25.6z"/>
			<path fill="#555049" d="M410.8,42c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.2,0,0.2c-0.1,0.2-0.2,0.3-0.2,0.5c0.1,0.1,0.3,0.2,0.4,0.2
				C410.7,42.6,410.7,42.3,410.8,42z"/>
			<path fill="#494540" d="M409.3,41.1c0.1,0.2,0.3,0.3,0.4,0.1c0,0,0-0.1,0-0.2C409.6,41,409.5,41,409.3,41.1
				C409.3,41,409.3,41.1,409.3,41.1z"/>
			<path fill="#2A2927" d="M377.5,41.1c0.1,0.1,0.2,0.1,0.3,0.2c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
				C377.6,41,377.5,41,377.5,41.1z"/>
			<path fill="#32312D" d="M388.7,41.2c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.2,0,0.2-0.2c0,0-0.1-0.1-0.2-0.1
				C388.8,41.1,388.7,41.2,388.7,41.2z"/>
			<path fill="#33322F" d="M395.4,8.6c0.1,0,0.2,0,0.2,0c0.1-0.2,0.4-0.4,0.1-0.5C395.6,8,395.5,8.4,395.4,8.6z"/>
			<path fill="#4F4A44" d="M398.5,8.2c0-0.2,0.1-0.4-0.1-0.5c0,0-0.2,0-0.2,0.1C398.2,7.9,398.3,8.1,398.5,8.2
				C398.5,8.2,398.5,8.2,398.5,8.2z"/>
			<path fill="#33312E" d="M414.4,25.4c-0.2,0-0.4,0-0.5,0c0.2,0.2,0.3,0.3,0.5,0.5C414.5,25.8,414.6,25.6,414.4,25.4z"/>
			<path fill="#1A1A19" d="M416.3,41.3c-0.2,0-0.3,0-0.5,0c0,0.5,0.2,0.5,0.6,0.2C416.3,41.4,416.3,41.4,416.3,41.3z"/>
			<path fill="#383632" d="M392.6,4.7c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c-0.1,0.1-0.1,0.2-0.1,0.3
				C392.5,4.5,392.5,4.6,392.6,4.7z"/>
			<path fill="#302F2C" d="M380.2,40c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.2,0.2c0.1,0,0.1-0.2,0.1-0.3
				C380.3,40.1,380.2,40.1,380.2,40z"/>
			<path fill="#1A1A19" d="M401.4,27.4c0.1-0.5,0.1-0.6,0.1-0.7c0-0.1-0.2-0.1-0.3-0.1c0,0.1-0.1,0.1-0.1,0.2
				C401.2,26.9,401.2,27,401.4,27.4z"/>
			<path fill="#302E2C" d="M400,44.1c0.1,0,0.1,0.1,0.2,0.1c0.2,0,0.2-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1
				C400.1,43.9,400,44,400,44.1z"/>
			<path fill="#0C0C0C" d="M405.7,59.1c-0.1,0-0.3,0-0.4,0c0,0.1,0,0.1,0,0.2c0.3-0.1-0.1,0.8,0.5,0.3
				C405.7,59.4,405.7,59.2,405.7,59.1z"/>
			<path fill="#4A4641" d="M398.8,12c0,0.1,0,0.2,0,0.3c0.2,0,0.5,0.2,0.6-0.1C399.3,11.8,399,11.9,398.8,12z"/>
			<path fill="#46423D" d="M389.4,62.6c0.1,0,0.1,0,0.2,0c-0.1-0.2-0.2-0.5-0.5-0.3C389.1,62.4,389.3,62.6,389.4,62.6z"/>
			<path fill="#0E0E0E" d="M375.2,35c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.3,0,0.2-0.1c0-0.1-0.2-0.1-0.3-0.2
				C375.4,34.9,375.3,35,375.2,35z"/>
			<path fill="#59534C" d="M400.9,55.9c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.2,0.1-0.1,0.2C400.5,56,400.7,56,400.9,55.9z"/>
			<path fill="#655D54" d="M402.3,35.8c-0.2,0-0.5,0-0.7-0.1C401.8,36,402,36.4,402.3,35.8z"/>
			<path fill="#45413D" d="M394.2,51.4c-0.2,0-0.3,0-0.5,0c0.1,0.2,0.2,0.5,0.4,0.5C394.4,51.8,394.2,51.5,394.2,51.4z"/>
			<path fill="#3F3D38" d="M400.6,51.5c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0-0.2-0.1-0.2
				C400.7,51.4,400.6,51.5,400.6,51.5z"/>
			<path fill="#393633" d="M390.9,51.1c0.1,0.1,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.2-0.1c0.1-0.2-0.1-0.2-0.2-0.2
				C391,51,391,51,390.9,51.1z"/>
			<path fill="#292825" d="M408.9,22.7c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.2-0.2
				C409.1,22.5,409,22.6,408.9,22.7z"/>
			<path fill="#0C0C0B" d="M388.9,68.9c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0-0.1,0-0.2,0.1c0.1,0.1,0.1,0.3,0.2,0.4
				C388.6,69.2,388.8,69.2,388.9,68.9z"/>
			<path fill="#4C4742" d="M397.5,50.9c0,0,0.1,0.1,0.1,0.1c0,0,0.1-0.1,0.2-0.1c0-0.1-0.1-0.1-0.1-0.1
				C397.6,50.8,397.5,50.8,397.5,50.9z"/>
			<path fill="#514C46" d="M375.9,36.3c0,0.1,0,0.1,0,0.2c0.2-0.1,0.4-0.1,0.3-0.4c0,0-0.2-0.1-0.2-0.1
				C376,36.1,376,36.2,375.9,36.3z"/>
			<path fill="#3D3A37" d="M398.1,69.2c0,0.1-0.1,0.1-0.1,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1-0.1,0.2-0.1
				C398.2,69.3,398.1,69.2,398.1,69.2z"/>
			<path fill="#45413D" d="M393.7,50.4c-0.2,0.2-0.4,0.4-0.2,0.7c0.1,0,0.2,0,0.2,0C393.8,50.9,393.9,50.6,393.7,50.4L393.7,50.4z"
				/>
			<path fill="#4A4640" d="M399.7,51.1c0.1-0.1,0.3-0.2,0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.1C399.7,50.7,399.7,50.9,399.7,51.1
				C399.7,51.1,399.7,51.1,399.7,51.1z"/>
			<path fill="#010101" d="M402.5,15.8c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1-0.1,0.2-0.2,0.2-0.2
				C402.7,16.1,402.6,16,402.5,15.8z"/>
			<path fill="#3B3935" d="M407.3,32.3c0.1,0.1,0.1,0.1,0.2,0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.2-0.2
				C407.4,32.2,407.4,32.2,407.3,32.3z"/>
			<path fill="#4A4641" d="M397.8,54.7c0,0.1,0,0.2,0,0.3c0.2,0,0.4,0.2,0.5-0.1C398.3,54.7,398,54.7,397.8,54.7z"/>
			<path fill="#312F2D" d="M403.3,29.5c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0,0.1,0,0.2-0.1c0.2-0.3,0.2-0.5-0.2-0.5
				C403.6,29.5,403.5,29.5,403.3,29.5z"/>
			<path fill="#56504A" d="M403.8,29.5c0.1,0.2,0.2,0.3,0.2,0.5c0.2-0.2,0.3-0.4,0.1-0.6C404,29.2,403.9,29.4,403.8,29.5z"/>
			<path fill="#242422" d="M410.3,21.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1-0.2,0.3-0.4,0-0.6C410.4,21.1,410.3,21.4,410.3,21.6z"/>
			<path fill="#514C46" d="M389,57.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.2
				C389.1,57.1,389.1,57.1,389,57.2z"/>
			<path fill="#433F3B" d="M390.1,63.9c-0.2,0-0.3,0-0.5,0c0.1,0.1,0.1,0.4,0.2,0.4C390.1,64.3,390.1,64,390.1,63.9z"/>
			<path fill="#44403C" d="M396.1,53.8c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0.1-0.1,0.1
				C396,53.7,396,53.8,396.1,53.8z"/>
			<path fill="#353330" d="M401.3,64.4c0.1,0.1,0.2,0.1,0.3,0.2c0-0.1,0.1-0.1,0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
				C401.4,64.3,401.4,64.3,401.3,64.4z"/>
			<path fill="#3D3A36" d="M405,53.7c0.2-0.1,0.3-0.1,0.5-0.2c0-0.1-0.1-0.3-0.3-0.3C405,53.3,405,53.6,405,53.7z"/>
			<path fill="#3E3B37" d="M399.2,61.6c0.1,0.1,0.1,0.1,0.1,0.1c0.1,0,0.2-0.1,0.2-0.2c0,0-0.1-0.1-0.2-0.1
				C399.3,61.5,399.3,61.6,399.2,61.6z"/>
			<path fill="#1D1C1B" d="M405.9,53.3c0.1,0.1,0.3,0.1,0.3-0.1c0-0.3-0.2-0.4-0.5-0.3C405.8,53,405.8,53.1,405.9,53.3z"/>
			<path fill="#060606" d="M405.7,21.8c-0.1,0-0.2,0-0.2,0c0.1,0.2,0.2,0.3,0.3,0.5c0.3,0,0.5-0.2,0.5-0.5
				C406,21.8,405.9,21.8,405.7,21.8z"/>
			<path fill="#282724" d="M402.7,52.4c0.1,0,0.2,0.1,0.3,0.2c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
				C402.8,52.3,402.7,52.3,402.7,52.4z"/>
			<path fill="#44403C" d="M387.5,14.6c0.1,0.1,0.2,0.1,0.3,0.2c0-0.1,0.1-0.1,0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.2
				C387.6,14.5,387.6,14.6,387.5,14.6z"/>
			<path fill="#43403B" d="M386,49.2c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0-0.1,0.1-0.2,0.1C385.8,48.9,385.9,49.1,386,49.2
				C386,49.2,386,49.2,386,49.2z"/>
			<path fill="#161615" d="M405.6,32.1c0.1-0.1,0.1-0.2,0.2-0.3c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0.1-0.1,0.2
				C405.4,31.9,405.5,31.9,405.6,32.1z"/>
			<path fill="#32302D" d="M399.3,27.6c0.1,0.2,0.1,0.3,0.2,0.5c0.2-0.2,0.3-0.4,0.1-0.6C399.4,27.3,399.3,27.4,399.3,27.6z"/>
			<path fill="#47433E" d="M377.2,48.6c0,0,0.1,0.1,0.1,0.1c0,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1
				C377.3,48.5,377.3,48.6,377.2,48.6z"/>
			<path fill="#1C1B1A" d="M408.2,56.6c0.1,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3,0,0.5,0c0-0.1,0-0.1,0-0.2c-0.2-0.2-0.4-0.3-0.7-0.2
				C408.2,56.5,408.2,56.5,408.2,56.6z"/>
			<path fill="#494540" d="M393.1,19.6c0-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0.1,0.1,0.2
				C393,19.7,393.1,19.7,393.1,19.6z"/>
			<path fill="#171716" d="M401.4,48c-0.2,0-0.4,0-0.7,0c0,0.1,0,0.1,0,0.2c0.2,0.1,0.3,0.2,0.5,0.3C401.4,48.4,401.4,48.2,401.4,48
				z"/>
			<path fill="#31302D" d="M391.3,47.8c-0.2,0.2-0.2,0.4-0.2,0.7C391.6,48.4,391.5,48.1,391.3,47.8z"/>
			<path fill="#494540" d="M417.9,37.4c-0.1,0-0.3,0-0.4,0c0,0.2,0,0.5,0.2,0.5C418,37.9,417.9,37.6,417.9,37.4z"/>
			<path fill="#020302" d="M399.1,37.9c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.2,0.2,0.2
				C399,38.2,399.1,38.1,399.1,37.9z"/>
			<path fill="#47433E" d="M382,13.6c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.1C381.9,13.7,382,13.7,382,13.6z"
				/>
			<path fill="#544E48" d="M390.9,19.8c-0.1,0-0.1,0.1-0.2,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0,0.2-0.1
				C391,19.9,391,19.9,390.9,19.8z"/>
			<path fill="#4B4742" d="M397.7,36.9c-0.1,0-0.1,0.1-0.2,0.1c0,0.1,0.1,0.2,0.2,0.3c0,0,0.2-0.1,0.2-0.1
				C397.8,37.1,397.7,37,397.7,36.9z"/>
			<path fill="#353330" d="M391.2,70.5c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2-0.2-0.2
				C391.2,70.3,391.2,70.4,391.2,70.5z"/>
			<path fill="#111110" d="M407.2,37.4c0-0.2,0-0.3,0-0.5c-0.2,0.1-0.5,0.2-0.5,0.5C406.7,37.7,407,37.5,407.2,37.4z"/>
			<path fill="#181817" d="M405.6,48.8c0,0.1-0.2,0.2-0.1,0.2c0,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.2,0.2-0.3
				C405.8,48.8,405.7,48.8,405.6,48.8z"/>
			<path fill="#181716" d="M390.1,71.2c0,0.1,0.1,0.1,0.1,0.2c0.1-0.1,0.2-0.2,0.4-0.3c0,0,0-0.2-0.1-0.2
				C390.3,71,390.2,71.1,390.1,71.2z"/>
			<path fill="#48443F" d="M391.3,72c0-0.2-0.1-0.4-0.4-0.2c-0.1,0,0,0.2-0.1,0.3C391,72.1,391.2,72,391.3,72
				C391.3,72,391.3,72,391.3,72z"/>
			<path fill="#F6EEDB" d="M439.2,152.6c-0.1-0.3-0.3-0.4-0.6-0.4c-0.1,0-0.2,0.2-0.2,0.2C438.7,152.7,438.9,152.7,439.2,152.6z"/>
			<path fill="#E8D193" d="M437.9,150.2c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0,0.4-0.1,0.4-0.1C438.4,150.3,438.1,150.3,437.9,150.2z"/>
			<path fill="#353330" d="M416,43.4c0.3,0,0.6-0.1,0.9-0.1c-0.1-0.4-0.4-0.8-0.9-0.6C415.7,42.8,416,43.2,416,43.4z"/>
			<path fill="#0B0C0B" d="M414.8,43.7c0.3,0.1,0.7,0.3,0.8-0.2c-0.1-0.3-0.4-0.3-0.6-0.2C414.7,43.3,414.8,43.5,414.8,43.7z"/>
			<path fill="#0E0E0D" d="M408.3,54.5c-0.1,0.1-0.1,0.3-0.2,0.4c0.1,0,0.1,0.1,0.2,0.1C408.9,54.8,408.5,54.7,408.3,54.5z"/>
			<path fill="#E3C779" d="M437.4,146.4c1.1-0.2,0.5-0.8,0.5-1.4C436.9,145.3,437.7,146,437.4,146.4L437.4,146.4z"/>
			<path fill="#F9F3E5" d="M412.2,114.7c0,0.2,0,0.5,0,0.7c0.3-0.1,0.7-0.2,0.5-0.6C412.6,114.5,412.4,114.6,412.2,114.7z"/>
			<path fill="#F7F1E2" d="M439.8,157.7c0.2,0.1,0.5,0.3,0.7,0.4C440.5,157.6,440.2,157.5,439.8,157.7z"/>
			<path fill="#F2E5C5" d="M409.5,116.1c0.1,0.4-0.3,0.8,0,1.2c0.6,0,0.5-0.6,0.7-0.9c0.2-0.1,0.4-0.2,0.8-0.5
				C410.4,116,410,116.1,409.5,116.1z"/>
			<path fill="#FCFAF5" d="M409.8,119c0.3-0.1,0.6-0.1,0.9-0.2c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.1-0.3-0.1-0.5,0
				c-0.2,0.2-0.4,0.3-0.5,0.5C409.4,119,409.5,119.1,409.8,119z"/>
			<path fill="#E8D297" d="M410.3,116.4c-0.4,0.2-0.5,0.6-0.7,0.9c0,0,0,0,0,0c0.1,0.1,0.2,0.4,0.5,0.2l0,0c0.2-0.2,0.3-0.5,0.5-0.7
				C410.4,116.7,410.4,116.6,410.3,116.4z"/>
			<path fill="#F3E8CB" d="M410,117.6c-0.2-0.1-0.3-0.2-0.5-0.2c0.1,0.3,0.2,0.6,0.2,1c0.2,0,0.3,0,0.5,0
				C410.9,117.9,410.2,117.8,410,117.6z"/>
			<path fill="#F4EAD0" d="M410.5,116.9c-0.3,0.1-0.4,0.4-0.5,0.7C410.4,117.5,410.5,117.3,410.5,116.9z"/>
			<path fill="#C78B71" d="M362,46.6c0.1-0.6-0.8-0.8-0.5-1.5c-0.2-0.3-0.4-0.6-0.6-0.9c-0.6,0.5-0.1,1.8-1.4,1.6l0,0
				c0,0.4,0,0.8,0,1.2l0,0c0.3,0,0.5,1.1,0.9,0.1c0.1-0.3,0.5,0.4,0.8,0.5C361.6,47.2,361.8,46.9,362,46.6z"/>
			<path fill="#C3A188" d="M351.7,47.5c0.6,0.6-0.1,1.3,0.3,1.9c0.4-0.1,1,0.1,0.7-0.7c-0.1-0.1-0.2-0.2,0-0.3
				c0.2-0.1,0.3-0.2,0.5-0.2c-0.5-0.4,0.1-0.8,0-1.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.7-0.1-1.4-0.1-1.7-1c-0.2,0-0.3,0-0.5,0
				c-0.4,0-0.4,0.2-0.2,0.5c0,0,0,0,0,0c0.4,0.1,0.8,0.2,0.7,0.8C351.2,47.4,351.5,47.5,351.7,47.5z"/>
			<path fill="#C78F75" d="M354.1,48c0,0.1,0,0.2,0,0.2c-0.1,0.2-0.2,0.5-0.3,0.7c0,0.2,0,0.3,0.1,0.5c0.7,0.6,1.4,1.2,2.3,0.7
				c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0,0,0,0c-0.2-0.5-0.8-0.7-1-1.4C355.4,47.9,354.8,47.6,354.1,48z"/>
			<path fill="#C78D73" d="M358.9,41.5c-0.2,0.4-1.1,0-0.9,0.9c0.2,1,0.5,0.6,0.9,0.2c0.5-0.3,1.3-0.3,1.2-1.2
				c-0.1-0.2-0.2-0.3-0.4-0.4C359.4,41.2,359.1,41.2,358.9,41.5z"/>
			<path fill="#C5997F" d="M359.9,41.5c-0.2,0.5-0.8,0.7-1,1.2c0.1,0.2,0.1,0.6,0.3,0.5c0.4-0.4,1.1-0.4,1.4-0.9
				c0.1-0.2,0.1-0.3,0-0.5l0,0C360.4,41.4,360.2,41.3,359.9,41.5z"/>
			<path fill="#C78B71" d="M360.6,41.8c0,0.2,0,0.3,0,0.5c0.2,0.3,0.4,0.4,0.7,0.5c0.3-0.2,0.6-0.3,0.2-0.7
				C361.4,41.4,360.9,41.7,360.6,41.8z"/>
			<path fill="#C49A81" d="M357.2,49.2c0.3,0.4,0.5,0.9,0.8,1.4c0.3-0.4,0.6-0.8,0.8-1.1c-0.4-0.1-1.2,0.7-1.2-0.5
				c-0.2-0.1-0.3-0.1-0.5,0C357.2,49,357.2,49.1,357.2,49.2z"/>
			<path fill="#C69277" d="M362.7,50.4c0.1,0,0.2,0,0.2,0c0.3-0.3,0.2-0.6,0-1c0.1-0.8-0.6-1-1-1.4c0,0.2,0,0.3,0,0.5
				c0,0.1,0,0.1,0,0.2C362.1,49.3,362.6,49.8,362.7,50.4z"/>
			<path fill="#C78B71" d="M359.1,44.4c-0.6,0.7-0.3,1.2,0.5,1.4c0,0,0,0,0,0C359.5,45.3,359.7,44.7,359.1,44.4L359.1,44.4z"/>
			<path fill="#C8886E" d="M357.2,49c0.2,0,0.3,0,0.5,0c0.5-0.2,0.5-0.6,0.3-0.9c0-0.1-0.2-0.2-0.2-0.2
				C357.2,48.1,357.3,48.6,357.2,49z"/>
			<path fill="#C4997F" d="M352.9,46.8c0,0.1,0,0.2,0,0.3c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0,0.5,0.1,0.7-0.2
				C354.2,47,353.6,46.7,352.9,46.8z"/>
			<path fill="#C59A80" d="M355.5,46.6c-0.1,0.6,0.1,0.9,0.6,1.4C356.1,47.3,355.8,47,355.5,46.6z"/>
			<path fill="#C3A087" d="M351.6,43.7c0,0.3,0.2,0.4,0.4,0.4c0.2,0,0.3,0,0.4-0.2c0-0.3-0.1-0.4-0.4-0.5
				C351.8,43.4,351.7,43.4,351.6,43.7z"/>
			<path fill="#C78B71" d="M359.6,47c-0.1-0.2-0.3-0.4-0.4-0.4c-0.4,0.2-0.3,0.6-0.4,1C359.4,47.8,359.5,47.4,359.6,47L359.6,47z"/>
			<path fill="#C78D73" d="M358.9,41.5c0.2-0.1,0.5-0.2,0.7-0.2c0-0.2,0-0.3-0.2-0.2c-0.3-0.2-0.6-0.6-1,0
				C358.6,41.2,358.7,41.4,358.9,41.5z"/>
			<path fill="#C78B71" d="M357.7,45.1c0,0.3,0,0.6,0.5,0.4c0.1-0.1,0.3-0.3,0.2-0.3C358.2,44.8,358,45,357.7,45.1L357.7,45.1z"/>
			<path fill="#C5997F" d="M360.8,43.5c-0.1-0.1-0.1-0.3-0.2-0.3c-0.2-0.1-0.3,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
				C360.6,43.7,360.7,43.5,360.8,43.5z"/>
			<path fill="#C79277" d="M355.4,41.3c0,0.2-0.1,0.3-0.1,0.4c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.2-0.2
				C355.7,41.5,355.5,41.4,355.4,41.3z"/>
			<path fill="#C78B71" d="M357.7,45.1c-0.1-0.2-0.1-0.4-0.4-0.3c-0.1,0-0.1,0.2-0.2,0.3C357.3,45.1,357.5,45.1,357.7,45.1
				C357.7,45.1,357.7,45.1,357.7,45.1z"/>
			<path fill="#C8886E" d="M359.9,48.7c0.2,0,0.4,0,0.5-0.2c0,0-0.1-0.1-0.1-0.1C360,48.3,359.9,48.5,359.9,48.7L359.9,48.7z"/>
			<path fill="#C78B71" d="M359.1,44.4c0.2,0,0.5,0.1,0.6-0.2c0,0,0-0.1-0.1-0.1C359.4,44,359.3,44.2,359.1,44.4
				C359.1,44.4,359.1,44.4,359.1,44.4z"/>
			<path fill="#C6947A" d="M356.5,46.6c0,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1,0.1-0.1,0.1
				C356.4,46.5,356.4,46.6,356.5,46.6z"/>
			<path fill="#C59A80" d="M357,49.4c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.3-0.5-0.3-0.4C356.4,49.1,356.8,49.3,357,49.4z"/>
			<path fill="#C49E85" d="M356.5,49.9c-0.1,0-0.2,0-0.2,0c-0.2,0.2-0.5,0.3-0.6,0.5c0,0.4,0.3,0.6,0.6,0.6
				C356.8,50.8,356.4,50.3,356.5,49.9z"/>
			<path fill="#C3A38A" d="M359.9,50.9c-0.1-0.4-0.2-0.7-0.4-1.3C359.2,50.3,359.3,50.7,359.9,50.9
				C359.9,50.9,359.9,50.9,359.9,50.9z"/>
			<path fill="#C49A81" d="M359.4,51.6c0-0.1,0-0.1,0-0.2c-0.2-0.1-0.3-0.6-0.8-0.5C358.6,51.4,359,51.5,359.4,51.6z"/>
			<path fill="#C2A38B" d="M357,51.8c0,0.1,0,0.2,0,0.3c0.2-0.1,0.4-0.1,0.3-0.4c0-0.1-0.2-0.1-0.3-0.2C357,51.6,357,51.7,357,51.8z
				"/>
			<path fill="#C78F75" d="M356.3,51.1c-0.2-0.2-0.3-0.5-0.5-0.7c-0.2-0.1-0.3,0-0.2,0.2c0.1,0.4,0.4,0.6,0.7,0.7
				C356.3,51.3,356.3,51.2,356.3,51.1z"/>
			<path fill="#C3A087" d="M357.5,51.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1-0.1,0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1
				C357.6,51,357.5,51.1,357.5,51.1z"/>
			<path fill="#C3A188" d="M360.6,50.2c0-0.2,0.3-0.4,0.1-0.6c0,0-0.1,0-0.1,0C360.3,49.8,360.4,50,360.6,50.2
				C360.6,50.2,360.6,50.2,360.6,50.2z"/>
			<path fill="#CA8066" d="M350.5,46.3c0.1-0.2,0.1-0.3,0.2-0.5c-0.2-0.6-0.5-0.2-0.6,0C349.7,46.4,350.4,46.1,350.5,46.3z"/>
			<path fill="#C78B71" d="M359.4,41c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0.1,0.5,0.2,0.7,0.2
				c0.6-0.6,0.1-0.8-0.4-1.1C359.6,40.3,359.4,40.5,359.4,41z"/>
			<path fill="#C8876D" d="M362.7,50.4c0.1-0.7-0.2-1.2-0.7-1.7c-0.5,0.7,0.3,1.1,0.5,1.7C362.6,50.4,362.7,50.4,362.7,50.4z"/>
			<path fill="#C69379" d="M361.8,51.8c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3,0-0.6-0.3-0.8,0.1c0,0.1,0,0.3,0,0.3
				C361.1,52.3,361.4,52,361.8,51.8z"/>
			<path fill="#C1A890" d="M363,45.4c0.2-0.2,0.3-0.5,0.5-0.7c-0.4,0-0.4-0.4-0.6-0.7c-0.3,0.5-0.3,0.9-0.3,1.4
				C362.7,45.4,362.8,45.4,363,45.4z"/>
			<path fill="#CA8066" d="M355.5,50.6c0.1-0.1,0.2-0.2,0.2-0.2c0.3-0.1,0.4-0.2,0.5-0.5c-0.8,0.3-1.4-0.2-2.1-0.5
				c-0.3,0.3,0,0.5,0.2,0.7c0.2,0.2,0.6,0,0.8,0.2C355.2,50.5,355.4,50.5,355.5,50.6z"/>
			<path fill="#C78F75" d="M355.1,50.4c-0.2-0.1-0.5-0.2-0.7-0.2c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0.1,0.1c0,0.2,0,0.3,0,0.5
				C354.6,50.7,354.8,50.5,355.1,50.4z"/>
			<path fill="#C49D83" d="M350.4,48.1c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.2,0,0.2-0.1c0.1-0.1,0-0.2-0.1-0.2
				C350.7,48,350.6,48.1,350.4,48.1z"/>
			<path fill="#C2A58C" d="M363,49.4c0,0.3,0,0.6,0,1l0,0c0.3,0.3,0.6,0.2,1,0c0,0,0,0,0,0c0-0.3,0-0.6,0-1
				C363.6,49.4,363.3,49.4,363,49.4z"/>
			<path fill="#CA8066" d="M354.1,49c0.3,0,0.9,0.1,0.9-0.3c0-0.6-0.6-0.2-0.9-0.4c-0.4,0.1-0.7,0.3-0.9,0.7
				C353.5,49.2,353.8,49.2,354.1,49z"/>
			<path fill="#C3A188" d="M354.1,49c-0.3,0-0.6,0-1,0c0.2,0.7,0.7,1,1.2,1.4c0-0.1,0-0.2,0-0.2c-0.1-0.2-0.2-0.5-0.3-0.7
				C354.1,49.3,354.1,49.1,354.1,49z"/>
			<path fill="#CA8066" d="M353.4,47.3c-0.2-0.1-0.3-0.1-0.5-0.2c0,0.4-0.5,0.9,0.2,1.2C353.2,47.9,353.3,47.6,353.4,47.3z"/>
			<path fill="#CA8066" d="M352.7,48.5c-0.2,0-0.4-0.1-0.4,0.2c0,0.2,0.2,0.1,0.4,0C352.7,48.6,352.7,48.6,352.7,48.5z"/>
			<path fill="#C69277" d="M363.9,50.4c-0.3,0-0.6,0-1,0C363.3,51.1,363.6,50.8,363.9,50.4z"/>
			<path fill="#45413D" d="M393.7,50.4c-0.1-0.2-0.2-0.3-0.4-0.3c0,0-0.1,0.1-0.1,0.2C393.4,50.3,393.5,50.3,393.7,50.4
				C393.7,50.4,393.7,50.4,393.7,50.4z"/>
			<path fill="#665E55" d="M406.9,23.7c0.1,0.1,0.3,0.1,0.4,0.2c0-0.2,0.2-0.6-0.1-0.7C406.9,23.2,407,23.5,406.9,23.7z"/>
			<path fill="#252522" d="M394,60.2c0,0.1,0,0.2,0,0.2c0.2,0,0.6,0.1,0.5-0.3C394.4,60,394.1,60.2,394,60.2z"/>
			<path fill="#605851" d="M391.5,43.7c0-0.1-0.1-0.2-0.1-0.3c-0.4-0.3-0.6-0.1-0.8,0.3c0.1,0.3,0.1,0.6,0.2,0.9
				c0.4,0.3,0.8,0,1.1-0.2c0.1-0.1,0.1-0.2,0.1-0.3C391.8,43.9,391.7,43.8,391.5,43.7z"/>
			<path fill="#413E3A" d="M391.8,44.2c-0.3,0.2-0.7,0.3-1,0.5c0.3,0.2,0.5,0.3,0.8,0.5c0.1,0,0.1,0,0.2,0c0.2-0.1,0.2-0.3,0.4-0.4
				l0.1-0.1C392.3,44.2,392.1,44.1,391.8,44.2z"/>
			<path fill="#625B52" d="M416,31.9c0,0.1,0,0.1,0,0.2c0.1,0.2,0,0.5,0.3,0.5c0.1,0,0.2-0.2,0.2-0.3C416.6,32,416.4,31.8,416,31.9z
				"/>
			<path fill="#151614" d="M392.2,44.7c-0.3,0-0.4,0.2-0.4,0.4c0,0.2,0.1,0.3,0.3,0.2C392.1,45.1,392.2,44.9,392.2,44.7z"/>
			<path fill="#121210" d="M399.8,49.9c0-0.2,0.3-0.4,0-0.6c-0.2-0.1-0.4,0.1-0.4,0.2C399.2,49.9,399.5,49.9,399.8,49.9
				C399.7,50,399.8,49.9,399.8,49.9z"/>
			<path fill="#635B53" d="M391.8,44.2c0.2,0.1,0.3,0.3,0.5,0.4c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.2,0.2-0.3,0.2-0.5
				c-0.2-0.1-0.3-0.2-0.5-0.2C392.3,43.9,391.9,43.8,391.8,44.2L391.8,44.2z"/>
			<path fill="#413E3A" d="M391.8,44.2c0.2-0.2,0.5-0.3,0.7-0.5c-0.3,0-0.6,0-1,0C391.6,43.9,391.7,44,391.8,44.2z"/>
		</g>
	</g>
</g>
</svg>
        )
    }
}

export default Character3Airplane;