import React from "react";
import { Link } from "react-router-dom";
import data from "../data.json";

class ResultOptions extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    const setOffsiteProgress = this.props.setOffsiteProgress;
    const updateClickstream = this.props.updateClickstream;
    const updateLastClick = this.props.updateLastClick;
    let wrapper = function(element, i) {
      switch (element.type) {
        case "image":
          return (
            <div className="option" key={i}>
              <span className={"option_image image image-" + element.image} />
            </div>
          );
        case "phone":
          return (
            <div className="option option--phone" key={i}>
              <span className={"option_image image image-" + element.image} />
              {element.copy.map((copy, i) => (
                <p key={i} className="option_copy">
                  {copy}
                </p>
              ))}
            </div>
          );
        case "schedule-button":
          return (
            <a
              href={element.destination[1]}
              className="option option--button option--schedule"
              key={i}
              target="_blank"
              onClick={() => {
                updateClickstream(element.destination[1], element.copy.join(' '));
              }}
            >
              <span className={"option_image image image-" + element.image} />
              {element.copy.map((copy, i) => (
                <p key={i} className="option_copy">
                  {copy}
                </p>
              ))}
            </a>
          );
        case "internal-button":
          return (
            <a
              href={element.destination[1]}
              className="option option--button"
              key={i}
              onClick={() => {
                updateLastClick(element.destination[1]);
                setOffsiteProgress(currentScreen, element)
              }}
            >
              <span className={"option_image image image-" + element.image} />
              {element.copy.map((copy, i) => (
                <p key={i} className="option_copy">
                  {copy}
                </p>
              ))}
            </a>
          );
        case "external-button":
          return (
            <a
              href={element.destination[1]}
              className="option option--button"
              key={i}
              target="_blank"
              onClick={() => {
                let url       = element.destination[1];
                let assetName = element.asset_name;
                let segmented = url.includes('/awardtype/') ? url.split('/awardtype/')[1] : url;
                if (assetName) {
                  segmented   = `${segmented}/${assetName}`;
                }
                updateLastClick(element.copy[0]);
                updateClickstream(segmented, element.copy[0], true);
                setOffsiteProgress(currentScreen, element);
              }}
            >
              <span className={"option_image image image-" + element.image} />
              {element.copy.map((copy, i) => (
                <p key={i} className="option_copy">
                  {copy}
                </p>
              ))}
            </a>
          );
        case "contact-page-button":
          return (
            <Link
              to={element.destination[1]}
              className="option option--button"
              key={i}
              onClick={() => updateLastClick(element.copy[0])}
            >
              <span className={"option_image image image-" + element.image} />
              {element.copy.map((copy, i) => (
                <p key={i} className="option_copy">
                  {copy}
                </p>
              ))}
            </Link>
          );
        case "link-only-button":
          return (
            <a
              href={element.destination[1]}
              className="option option--button"
              key={i}
              target="_blank"
              onClick={() => {
                updateClickstream(element.destination[1], element.copy[0], true);
              }}
            >
              <span className={"option_image image image-" + element.image} />
              {element.copy.map((copy, i) => (
                <p key={i} className="option_copy">
                  {copy}
                </p>
              ))}
            </a>
          );
        default:
          break;
      }
    };
    return (
      <div className="options options--result">
        {data[currentScreen].options.map(wrapper)}
      </div>
    );
  }
}

export default ResultOptions;
