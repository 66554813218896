import React from "react";
import Character1AdmiringAward from "./Character1AdmiringAward.js"
import Character2AdmiringAward from "./Character2AdmiringAward.js"
import Character3AdmiringAward from "./Character3AdmiringAward.js"
import Character4AdmiringAward from "./Character4AdmiringAward.js"

class CharacterAdmiringAward extends React.Component {
  state = { svg: null };

  componentDidMount() {
    const svgSupport =
      !!document.createElementNS &&
      !!document.createElementNS("http://www.w3.org/2000/svg", "svg")
        .createSVGRect;
    if (svgSupport) {
      this.setState({
        svg: "svg"
      });
    } else {
      this.setState({
        svg: "png"
      });
    }
  }
  render() {
    return (
      <div
        className={
          "option character-illustration-wrapper character-admiring-award " +
          this.state.svg
        }
      >
        {this.props.characterReference === "character-1" && <Character1AdmiringAward/>}
        {this.props.characterReference === "character-2" && <Character2AdmiringAward/>}
        {this.props.characterReference === "character-3" && <Character3AdmiringAward/>}
        {this.props.characterReference === "character-4" && <Character4AdmiringAward/>}
        <div className={"character-background character-admiring-award-background"} ></div>
      </div>
    );
  }
}

export default CharacterAdmiringAward;
