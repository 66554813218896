import React from "react";
import { Route, Switch } from "react-router-dom";
import Landing from "./Landing";
import CharacterSelect from "./CharacterSelect";
import ScenarioSelect from "./ScenarioSelect";
import ScenarioSelectComplete from "./ScenarioSelectComplete";
import Contact from "./Contact";
import Choice from "./Choice";
import Result from "./Result";
import Legal from "./Legal";
import data from "../data.json";

class Screen extends React.Component {
  componentDidMount() {}

  render() {
    const characterReference = this.props.characterReference
    const checkProgress = this.props.checkProgress;
    const checkScenarioProgress = this.props.checkScenarioProgress;
    const setQuizProgress = this.props.setQuizProgress;
    const setOffsiteProgress = this.props.setOffsiteProgress;
    const setCharacter = this.props.setCharacter;
    const updateClickstream = this.props.updateClickstream;
    const updateLastClick = this.props.updateLastClick;
    let wrapper = function(element, i) {
      switch (element.properties.type) {
        case "landing":
          return (
            <Route
              path="/"
              exact
              key={i}
              render={() => {
                return (
                  <Landing
                    currentScreen={i}
                    updateClickstream={updateClickstream}
                    updateLastClick={updateLastClick}
                  />
                )
              }}
            />
          );
        case "character-select":
          return (
            <Route
              path="/character-select"
              key={i}
              render={() => (
                <CharacterSelect
                  currentScreen={i}
                  checkProgress={checkProgress}
                  setQuizProgress={setQuizProgress}
                  setCharacter={setCharacter}
                  updateClickstream={updateClickstream}
                  updateLastClick={updateLastClick}
                />
              )}
            />
          );
        case "scenario-select":
          return (
            <Route
              path="/scenario-select"
              key={i}
              render={() => (
                <ScenarioSelect
                  currentScreen={i}
                  checkProgress={checkProgress}
                  checkScenarioProgress={checkScenarioProgress}
                  characterReference={characterReference}
                  updateClickstream={updateClickstream}
                  updateLastClick={updateLastClick}
                />
              )}
            />
          );
        case "scenario-select-complete":
          return (
            <Route
              path="/scenario-select-complete"
              key={i}
              render={() => (
                <ScenarioSelectComplete
                  currentScreen={i}
                  checkProgress={checkProgress}
                  updateClickstream={updateClickstream}
                  updateLastClick={updateLastClick}
                />
              )}
            />
          );
        case "contact":
          return (
            <Route
              path="/contact"
              key={i}
              render={() => (
                <Contact
                  currentScreen={i}
                  checkProgress={checkProgress}
                  setOffsiteProgress={setOffsiteProgress}
                  updateClickstream={updateClickstream}
                  updateLastClick={updateLastClick}
                />
              )}
            />
          );
        case "choice":
          const choicePath =
            "/" + element.properties.scenario + "-" + element.properties.choice;
          return (
            <Route
              path={choicePath}
              key={i}
              render={() => (
                <Choice
                  characterReference={characterReference}
                  currentScreen={i}
                  checkProgress={checkProgress}
                  setQuizProgress={setQuizProgress}
                  updateClickstream={updateClickstream}
                  updateLastClick={updateLastClick}
                />
              )}
            />
          );
        case "result":
          const resultPath =
            "/" +
            element.properties.scenario +
            "-" +
            element.properties.choice +
            "-" +
            element.properties.result;
          return (
            <Route
              path={resultPath}
              key={i}
              render={() => (
                <Result
                  currentScreen={i}
                  checkProgress={checkProgress}
                  setOffsiteProgress={setOffsiteProgress}
                  updateClickstream={updateClickstream}
                  updateLastClick={updateLastClick}
                />
              )}
            />
          );
        case "legal":
          return (
            <Route
              path="/legal"
              key={i}
              render={() => {
                return (
                  <Legal
                    currentScreen={i}
                    updateClickstream={updateClickstream}
                    updateLastClick={updateLastClick}
                  />
                );
              }}
            />
          );
        default:
          break;
      }
    };
    return <Switch>{data.map(wrapper)}</Switch>;
  }
}

export default Screen;
