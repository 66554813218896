import React from "react";
import { Link } from "react-router-dom";
import data from "../data.json";

class CharacterOptions extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    const setCharacter = this.props.setCharacter;
    const updateLastClick = this.props.updateLastClick;
    let wrapper = function(element, i) {
      let characterName = element.image.split('-').map(s => `${s[0].toUpperCase()}${s.slice(1)}`).join(' ');
      return (
        <Link
          to="/scenario-select"
          className="option option--button"
          key={i}
          onClick={() => {
            updateLastClick(characterName);
            setCharacter(element.image)
          }}
        >
          <span className={"option_image character " + element.image}>{element.image}</span>
        </Link>
      );
    };
    return (
      <div className="options options--character">{data[currentScreen].options.map(wrapper)}</div>
    );
  }
}
export default CharacterOptions;
