import React from "react";
import Character1Party from "./Character1Party.js"
import Character2Party from "./Character2Party.js"
import Character3Party from "./Character3Party.js"
import Character4Party from "./Character4Party.js"

class CharacterParty extends React.Component {
  state = { svg: null };

  componentDidMount() {
    const svgSupport =
      !!document.createElementNS &&
      !!document.createElementNS("http://www.w3.org/2000/svg", "svg")
        .createSVGRect;
    if (svgSupport) {
      this.setState({
        svg: "svg"
      });
    } else {
      this.setState({
        svg: "png"
      });
    }
  }
  render() {
    return (
      <div
        className={
          "option character-illustration-wrapper character-party " +
          this.state.svg
        }
      >
        {this.props.characterReference === "character-1" && <Character1Party/>}
        {this.props.characterReference === "character-2" && <Character2Party/>}
        {this.props.characterReference === "character-3" && <Character3Party/>}
        {this.props.characterReference === "character-4" && <Character4Party/>}
        <svg className={"character-background character-party-background"} />
      </div>
    );
  }
}

export default CharacterParty;
