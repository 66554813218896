import React from "react";
import GlobalHeader from "./GlobalHeader";
import ScreenFallback from "./ScreenFallback";
import Header from "./Header";
import BodyCopy from "./BodyCopy";
import CharacterOptions from "./CharacterOptions";

class CharacterSelect extends React.Component {
  componentDidMount(){
    this.props.updateClickstream("Character Select");
  }
  render() {
    const currentScreen = this.props.currentScreen;
    const setCharacter = this.props.setCharacter;
    const updateLastClick = this.props.updateLastClick;
    const updateClickstream = this.props.updateClickstream;
    return (
      <div className="screen">
        <div className="screen_content">
          <GlobalHeader
            updateClickstream={updateClickstream}
          />
          <div className="content_wrapper">
            <Header currentScreen={currentScreen} />
            <BodyCopy currentScreen={currentScreen} />
          </div>
          <CharacterOptions
            currentScreen={currentScreen}
            setCharacter={setCharacter}
            updateLastClick={updateLastClick}
          />
        </div>
        <ScreenFallback
          updateClickstream={updateClickstream}
        />
      </div>
    );
  }
}

export default CharacterSelect;
