import React from "react";
import Character1WaterCooler from "./Character1WaterCooler.js"
import Character2WaterCooler from "./Character2WaterCooler.js"
import Character3WaterCooler from "./Character3WaterCooler.js"
import Character4WaterCooler from "./Character4WaterCooler.js"

class CharacterWaterCooler extends React.Component {
  state = { svg: null };

  componentDidMount() {
    const svgSupport =
      !!document.createElementNS &&
      !!document.createElementNS("http://www.w3.org/2000/svg", "svg")
        .createSVGRect;
    if (svgSupport) {
      this.setState({
        svg: "svg"
      });
    } else {
      this.setState({
        svg: "png"
      });
    }
  }
  render() {
    return (
      <div
        className={
          "option character-illustration-wrapper character-water-cooler " +
          this.state.svg
        }
      >
        {this.props.characterReference === "character-1" && <Character1WaterCooler/>}
        {this.props.characterReference === "character-2" && <Character2WaterCooler/>}
        {this.props.characterReference === "character-3" && <Character3WaterCooler/>}
        {this.props.characterReference === "character-4" && <Character4WaterCooler/>}
        <svg className={"character-background character-water-cooler-background"} />
      </div>
    );
  }
}

export default CharacterWaterCooler;
