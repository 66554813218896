import React from "react"

class Character1Mailbox extends React.Component {
    render(){
        return(
<svg version="1.1" id="character-1" className="character-animated" x="0px" y="0px" viewBox="0 0 222 576.7" enableBackground="new 0 0 222 576.7">
<g id="arm">
	<g id="ftxyMM_1_">
		<g>
			<path fill="#EDE2CF" d="M194.7,171c-0.5,0.2-1,0.5-0.8,1c0.3,0.9-0.3,1.3-0.7,1.9c-0.5-0.6,0.4-0.8,0.2-1.4c-0.7,0.4-1.2,1-1,1.6
				c0.2,0.8-0.2,1.3-0.5,1.9c-1.3,0-1.2,1.1-1.6,1.8c-0.3,0.8-1,1.1-1.7,1.5c-0.5,0.4-0.9,0.5-1-0.3c0-0.2,0-0.4-0.2-0.5
				c-0.4,0.3-0.2,0.6,0,0.9c0.3,0.5,0.2,0.9-0.1,1.3l0,0c-0.3,0.1-0.6,0.2-1,0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-0.5-1-1.1-1-1.9
				c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1,0.1,0,0.2,0,0.3c0.1,0.4,0,0.7-0.4,0.9c-0.7,0.1-0.8,1.1-1.5,1.2c-0.1,0-0.2,0-0.3-0.1
				c-0.3-0.1-0.5-0.3-0.6-0.6c-0.2-0.3-0.3-0.7-0.2-1.1c0.1-0.2,0.4-0.3,0.4-0.5c0-0.3-0.4-0.4-0.5-0.6c-0.2-0.3-0.5-0.5-0.7-0.7
				c-0.4-0.2-0.7-0.3-1.1-0.4c-0.3,0.2-0.8-0.1-0.8,0.4c0,0.4,0.1,0.7,0.2,1.1c0,0.3,0.1,0.6,0.5,0.6c0.3,0.2,0.7,0.3,1.1,0.3
				c0.5,0,0.8,0.4,0.9,0.9c0,0.5-0.2,0.8-0.8,0.9c-0.5,0.1-1,0.2-1.4,0.6c-0.4,0.3-0.8,0.5-1.1,0c0.2-0.7-0.6-0.3-0.7-0.7
				c0-0.1,0-0.4-0.1-0.4c-0.2-0.1-0.2,0.1-0.3,0.2c-0.1,0.3,0,0.7-0.2,1c-0.1,0.1-0.3,0.2-0.4,0.2c-0.6-0.2-0.6-1-1.3-1.3
				c0.1,0.5,0.9,0.6,0.6,1.2c-0.1,0.2-0.2,0.3-0.3,0.4c-0.4,0.3,0.5,1.2-0.4,1.3c0.9-0.2,0-1,0.5-1.4c0.4-0.3,0.8,0.3,1.3-0.1
				c0.2-0.1,0.4,0.3,0.4,0.7c-0.3,0.6,0.5,1.2,0,1.9c-2.1,0.1-4.1-0.1-6-1c-0.1-0.1-0.1-0.3-0.1-0.3c-0.2,0,0.5-0.9-0.3-0.3
				c-0.1,0.1-0.3,0.1-0.5,0.1c-0.5-0.2-0.9-0.6-0.8-1.4c-1.1,0.7-1.5,0.2-2.3-0.4c-0.8-0.6-1.5-1.4-2.4-1.8c-1-0.5-1.6-1.3-2.2-2
				c-1.8-2.1-3.3-4.4-4.7-6.9c-3-5.4-5.8-11-8.6-16.5c-2.6-5.2-5.4-10.3-8-15.5c-1.1-2.1-2.1-4.2-3.6-6.2c-3.1-4.3-4.8-9.1-5-14.3
				c-0.1-1.3-0.4-2.6-0.3-3.9c0.1-2.2,0.4-4.4,0.7-6.5c0.2-2.2,1-4.3,2-6.2c1.6-3,3-6.1,5.4-8.6c4.5-4.7,12.7-3.5,15.5,2.3
				c2.2,4.6,4.4,9.1,5.9,14.1c1.5,5.1,3.3,10.1,5.1,15.1c2.6,7.1,4.9,14.4,7.8,21.4c0.6,1.4,0.9,3,1.5,4.4c0.1,0.2-0.1,0.6,0.3,0.7
				c0.4,0,0.7-0.2,0.8-0.6c0.3-1,0.9-1.9,1.3-2.8c1.4-3.3,2.7-6.6,4.3-9.8c0.8-1.7,1.3-3.5,2.1-5.2c1.7-3.9,3.5-7.8,7.4-10
				c1.7-0.9,1.8-2.7,2.5-4.2c1-2.3,1.7-4.6,2.7-6.8c0.8-0.2,1.6,0.2,2.3,0.4c3,0.9,6,2,9.1,2.9c-0.2,2-1,3.9-1.5,5.9
				c-0.3,1-0.6,2.1-0.8,3.1c-0.2,0.9-0.1,1.8,0.3,2.7c1,2.2,1.4,4.6,1.1,7.1c-0.4,3.7-1.7,7.1-3.2,10.6c-0.5,1.3-1,2.6-1.4,3.9
				c-0.9,3-2.1,5.8-3.1,8.7c-0.9,2.5-1.7,5-2.6,7.5c-0.1,0.4-0.2,0.8-0.8,1c-0.7,0.2-0.2,0.8-0.1,1.2c-0.4,0.7-0.3,1.6-1.4,1.8
				c-0.3,0-0.1,0.2,0,0.3C196.2,169.5,195.6,170.7,194.7,171z"/>
			<path fill="#C1A790" d="M209.4,114.9c-0.2,0.1-0.4,0.2-0.7,0.1c-3.6-1.1-7.1-2.3-10.7-3.4c1.1-3.4,2.2-6.8,3.4-10.2
				c0.3-0.8,0.4-1.7,0.8-2.5c0.5-1.1,0.4-2.1-0.4-3.3c-1.1-1.7-1.5-3.8-1.8-5.9c-0.4-3.2-1-6.4-1.2-9.7c-0.1-1.3-0.5-2.5-0.6-3.8
				c-0.1-0.5-0.5-1.2,0.3-1.5c0.6-0.2,1.4,0,1.8,0.6c0.8,1,1.6,2.1,1.9,3.4c0.3,1.1,0.6,2.2,0.9,3.4c0.1,0.6,0.5,1.1,1,1.2
				c0.6,0.1,0.7-0.6,0.9-1.1c2.1-4.4,3.9-8.9,6.3-13.1c0.6-1,1.4-1.6,2.6-1.4c1,0.2,1.6-0.5,2.4-0.8c1.6-0.6,2.3-0.4,3.3,1
				c0.7,1,1.1,2.2,1.9,3.2c0.6,0.8,0.3,1.8,0.2,2.7c-0.1,1.3-0.1,2.5,0.1,3.8c0.2,1.2-0.1,2.3-0.6,3.4c-2,5-3.8,10.1-6,15
				c-0.8,1.9-2.3,3.4-2.7,5.5c-0.9,3.8-1.8,7.7-2.7,11.5C209.8,113.7,209.6,114.3,209.4,114.9z"/>
			<path fill="#EFE5D4" d="M186.1,180.7c0.4,0,0.7,0,1.1,0.1c-0.6,0.6-1.2,1.2-2,1.6c-0.6,0.3-0.8,0.1-0.8-0.5
				c-0.1-0.3-0.4-0.6-0.3-0.9c0.5-0.6,0.9-1.2,0.8-2.1c0-0.2,0.1-0.3,0.3-0.4C185.2,179.4,185.8,180,186.1,180.7z"/>
			<path fill="#EFE6D6" d="M133.4,107.9c0.2,1.4,0.1,2.8-0.2,4.1c-0.2,0.8,0,1.7-0.5,2.4C132.1,112.1,132.4,110,133.4,107.9z"/>
			<path fill="#F2EBDF" d="M182,183.9c-1,0.5-2.2,0.7-3.3,0.9c-0.4-0.1-0.5-0.5-0.4-0.8c0.4-0.5,1.1-0.5,1.7-0.5
				c0.2,0.5,0.2-0.1,0.4-0.1c0.3-0.2,0.6-0.5,1-0.5C181.7,183.1,182.3,183.3,182,183.9z"/>
			<path fill="#FBF8F4" d="M194.7,171c0.7-0.2,0.8-1,1.1-1.5c0.3-0.5-0.2-0.5-0.4-0.6c-0.4-0.2-0.3-0.5-0.1-0.8
				c0.1-0.2,0.4-0.5,0.5-0.2c0.6,0.8,0.6-0.1,0.8-0.3c0.2-0.3,0.4-0.6,0.5-0.9c-0.5,1.6-1.1,3.1-2,4.5
				C195.1,171.4,194.9,171.4,194.7,171z"/>
			<path fill="#F4EEE4" d="M187.7,178.9c0.1,0.5,0.3,0.7,0.8,0.5c-0.2,0.7-0.6,1.2-1.4,1.4c0-0.5,0.2-0.9-0.3-1.4
				c-0.4-0.3-0.1-0.9,0.2-1.3c0.2-0.3,0.4-0.2,0.5,0.1C187.5,178.5,187.2,178.8,187.7,178.9z"/>
			<path fill="#F7F2EA" d="M190.2,177.9c-0.1-0.4,0-0.8,0.1-1.1c0.2-0.7,0.6-1.4,1.5-0.7C191.5,176.8,191,177.5,190.2,177.9z"/>
			<path fill="#F0E7D8" d="M184.3,181c0,0.3,0,0.6,0.1,0.9c-0.6-0.2-0.3,0.8-0.8,0.8c0.2,0.7-0.5,0.8-0.8,1.1
				c-1-0.2,0.1-0.8-0.2-1.2c0.4-0.3,0.7-0.7,0.7-1.3C183.5,181,183.8,180.7,184.3,181z"/>
			<path fill="#FAF8F4" d="M178.4,184c0.1,0.3,0.3,0.5,0.4,0.8c-0.5,0.3-0.9,0.2-1.4,0c0.2-0.7-0.7-1.1-0.3-1.8
				C178.1,182.5,177.9,183.8,178.4,184z"/>
			<path fill="#FDFDFC" d="M182.5,182.6c0.1,0.2,0.8,0.2,0.1,0.6c-0.4,0.2-0.3,0.4,0,0.6c-0.2,0.2-0.4,0.2-0.7,0.2
				c-0.1-0.5-0.7-0.6-0.8-1c0-0.1,0-0.2,0-0.3c0.2-0.2,0.4-0.2,0.7-0.3C182.2,182.3,182.4,182.4,182.5,182.6z"/>
			<path fill="#FCFCFA" d="M170.5,183.3c0.6-0.1,0.6-1.2,1.2-0.8c0.7,0.5-0.7,0.7-0.3,1.3C171,183.7,170.7,183.5,170.5,183.3z"/>
			<path fill="#EEE3D1" d="M181.3,182.7c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.3,0.8-0.9,0.6c-0.1,0.1-0.2,0.1-0.3,0.1
				c-0.5,0.2-1.1,0.3-1.6,0.5c-0.5,0.3-0.7,0.1-0.6-0.4c0-0.5-0.3-0.7-0.7-0.7c0.1-0.3-0.1-0.7-0.2-0.6c-0.4,0.7-0.8-0.3-1.2,0.1
				c-0.1,0-0.2,0-0.2-0.1c0.1-0.1,0.2-0.3,0.2-0.4c0.3-0.1,0.6,0,0.9-0.1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.3,0.5,0.2
				c0.3,0.1,0.4,0.4,0.6,0.5c0.3-0.1,0.1-0.3,0.1-0.5c0-0.2,0-0.4,0.3-0.5c0.3,0.5,0.6,0.1,0.9-0.1c0.2,0.6,0.2,0.5,0.9,0.5
				C180.7,182.1,181.5,181.5,181.3,182.7z"/>
			<path fill="#FBF9F6" d="M181.3,182.7c0-0.5-0.2-0.6-0.7-0.5c-0.5,0.1-1,0.1-1.5,0.2c-0.2-0.4,0.5-0.6,0.2-0.9
				c0.4-0.5,0.9-0.5,1.4-0.6c0.4,0,0.7,0.1,1.1,0.4c0.4,0.3,0.3,0.8,0.2,1.2C181.8,182.6,181.6,182.6,181.3,182.7z"/>
			<path fill="#EFE5D4" d="M182,182.5c-0.5-0.2-0.1-0.7-0.3-1c0-0.3,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.2,0.6-0.4
				c0.8-0.1,0.6-1.4,1.6-1.3c0.3,0.4,0.1,0.8-0.3,1c-0.3,0.2-0.6,0.5-0.8,0.8c0.5,0.8,0,1.2-0.7,1.3
				C182.4,182.6,182.2,182.5,182,182.5z"/>
			<path fill="#F3EDE2" d="M181.4,180.2c-0.1-0.4-0.3-0.8-0.9-0.6c-0.4,0.1-0.8,0-1-0.5c-0.2-0.3-0.2-0.5,0.1-0.7c0.1,0,0.2,0,0.3,0
				c0.8,0.5,0.9-0.4,1.2-0.7c0.2-0.1,0.4-0.1,0.5,0.2c0.3,0.3,1.1,0.3,0.8,1c-0.2,0-0.5-0.3-0.7,0.2c0,0.4,0.1,0.7,0.3,1
				C181.9,180.4,181.7,180.5,181.4,180.2z"/>
			<path fill="#FCFAF7" d="M183.3,181.3c0-0.4,0-0.9,0.6-1c0.5-0.1,0.4-0.5,0.5-0.8c0.6-0.1,0-0.5,0.2-0.7c0.2,0,0.3,0,0.4,0.2
				c-0.1,0.6,0.4,1,0.4,1.4c0,0.6-1.2-0.3-1.1,0.7C183.9,181.1,183.6,181.2,183.3,181.3z"/>
			<path fill="#FBF9F5" d="M176.6,181.9c-0.3,0.4-0.6,0.2-0.9,0.1c0.1-0.4-0.1-0.5-0.5-0.5c-0.4,0-0.3-0.4-0.3-0.7
				c0-0.3,0.2-0.4,0.4-0.5c0.3-0.1,0.4,0.1,0.5,0.3C176,181,176.3,181.4,176.6,181.9z"/>
			<path fill="#F3ECE0" d="M193.2,169c0.1,0.2,0.3,0.2,0.6,0.2c0.4,0,0.5,0.2,0.2,0.6c-0.9,1-0.9,1-1.7-0.2
				C192.4,169.2,192.7,169,193.2,169z"/>
			<path fill="#FDFCFB" d="M176.3,177.8c0.4,0.3,1.1,0.5,0.8,1.3c-0.1,0.5-0.4,0.7-0.8,0.6c-0.7-0.2-0.2-0.4,0-0.7
				C176.6,178.7,175.7,178.3,176.3,177.8z"/>
			<path fill="#F9F6F1" d="M177.3,182c-0.3,0.2-0.3-0.1-0.4-0.2c-0.1-0.3-0.2-0.9-0.1-1c0.4-0.2,0.2-1,0.7-0.8
				c0.6,0.2,0.3,0.7,0.1,1.1c0.1,0.2,0.2,0.4,0.1,0.6C177.6,181.9,177.5,182,177.3,182z"/>
			<path fill="#F6F1E9" d="M188.2,169.5c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.3-0.3-0.5,0-0.8c0.3-0.3,0.7-0.3,0.9,0
				c0.2,0.2,0.2,0.5,0.5,0.5c0.1,0.2,0,0.3-0.1,0.4C188.8,169.3,188.5,169.4,188.2,169.5z"/>
			<path fill="#F9F7F3" d="M180.4,171.2c-0.4-0.4-1.1-0.6-0.9-1.3c0.1-0.4,0.6-0.1,0.9,0C181.2,170.3,180.4,170.7,180.4,171.2z"/>
			<path fill="#FAF7F3" d="M181.7,173.1c0.4,0.1,0.4,0.4,0.2,0.5c-0.3,0.2-0.6,0.7-0.9,0.4c-0.3-0.2-0.6-0.7-0.4-1
				C180.9,172.5,181.3,173.1,181.7,173.1z"/>
			<path fill="#F0E7D7" d="M188.1,172.5c-0.3,0.4,0.7,0.8,0.1,1.2c-0.2,0.1-0.3-0.1-0.5-0.2c-0.8-0.3-0.4-0.8-0.3-1.2
				c0-0.2,0-0.3,0.1-0.5C187.9,171.7,188,172.1,188.1,172.5z"/>
			<path fill="#FBFAF6" d="M184.4,175.5c0.2,0.2,0.1,0.4,0,0.5c-0.2,0.3,0.3,0.9-0.4,1.1c-0.1,0-0.4,0-0.4-0.1
				c-0.2-0.5,0.2-0.9,0.4-1.3C184,175.5,184.2,175.4,184.4,175.5z"/>
			<path fill="#F4EEE3" d="M175.5,182.3c0.1,0,0.1,0.1,0.2,0.1c0.1,0.7,0.2,1.3-0.7,1.7c-0.2,0.1-0.3,0.3-0.5,0
				c-0.2-0.2-0.1-0.6,0.1-0.6c0.8-0.1,0.9-0.3,0.5-1C175.2,182.5,175.3,182.4,175.5,182.3z"/>
			<path fill="#FBFAF6" d="M172.2,180.4c-0.3-0.2-0.3-0.4-0.3-0.4c0.6,0.1,0.4-1.1,1.2-0.7c0.2,0.1,0.5,0.3,0.3,0.5
				C173.1,180.1,172.6,180.3,172.2,180.4z"/>
			<path fill="#FAF7F3" d="M181.6,177.9c-0.2-0.1-0.4-0.1-0.5,0c-0.2,0.8-0.7,0.7-1.2,0.3c-0.1-0.1-0.2-0.3-0.2-0.5
				c0-1,0.9-0.5,1.3-0.7C181.3,177.3,181.5,177.6,181.6,177.9z"/>
			<path fill="#F3EDE2" d="M184.7,163.5c0.6,0.6,0.6,0.6,0.2,1.7C183.7,164.7,185,164.1,184.7,163.5z"/>
			<path fill="#F0E7D7" d="M174.8,181.7c-0.2,0.6-0.4,1.1-0.6,1.5c-0.1,0.2-0.2,0.4-0.4,0.3c-0.3-0.1-0.1-0.3-0.1-0.5
				C173.8,182.4,174,181.9,174.8,181.7z"/>
			<path fill="#F1EADC" d="M181.4,180.2c0.2,0,0.5,0,0.7-0.2c0.2,0.2,0.3,0.4,0.5,0.6c0,0.3-0.3,0.4-0.4,0.6
				c-0.1,0.1-0.3,0.2-0.4,0.3c-0.3-0.2-0.7-0.4-1-0.6C181.1,180.9,181.3,180.6,181.4,180.2z"/>
			<path fill="#FBF8F5" d="M192.4,165.6c0.2-0.3,0.5-0.4,0.5-0.3c0.2,0.4,0.3,0.8,0.3,1.3c0,0.1-0.3,0.3-0.5,0.1
				C192.5,166.4,192.2,166,192.4,165.6z"/>
			<path fill="#FDFCFB" d="M188.2,169.5c0.2-0.3,0.3-0.6,0.8-0.4c0.2-0.1,0.3,0.2,0.4,0.2c0.2,0.3,0.3,0.5-0.1,0.6
				C188.7,170.2,188.4,169.9,188.2,169.5z"/>
			<path fill="#F3EDE1" d="M186.2,176.7c-0.1-0.4-0.2-0.8-0.4-1.2c0.9-0.2,0.7,1,1.4,0.9c0.1,0,0.2,0,0.2,0.1c0,0.2,0,0.4,0,0.5
				c-0.4,0.1-0.7-0.2-1-0.3C186.3,176.7,186.3,176.7,186.2,176.7z"/>
			<path fill="#FDFBFA" d="M193.2,169c-0.2,0.4-0.6,0.4-0.9,0.6c0-0.8,0.3-1.2,1.2-1.4C193.3,168.5,193.2,168.7,193.2,169z"/>
			<path fill="#F4EEE3" d="M188.5,174.5c-0.3,0.4,0.6,0.7,0.2,1.1c-0.1,0.1-0.4,0.2-0.5,0c-0.3-0.3-0.4-0.7-0.1-1.1
				C188.1,174.5,188.3,174.4,188.5,174.5z"/>
			<path fill="#FAF7F2" d="M178.7,177.3c-0.2-0.9,0.8-0.4,1-0.8c0.5,0.4,0.1,0.8-0.1,1.1c-0.1,0.1-0.2,0.3-0.4,0.3
				C178.8,177.9,178.7,177.6,178.7,177.3z"/>
			<path fill="#FCFAF7" d="M188.1,172.5c-0.2-0.2-0.5-0.5-0.7-0.7c0.2-0.4,0.5-0.7,0.9-0.4C188.7,171.7,188.5,172.2,188.1,172.5z"/>
			<path fill="#F6F0E8" d="M190.4,175.2c-0.9,0-1-0.4-0.6-1.4c0.3,0,0.4,0.3,0.6,0.5C190.7,174.6,190.4,174.9,190.4,175.2z"/>
			<path fill="#F9F7F2" d="M179.7,178.4c-0.1,0.2-0.1,0.5-0.2,0.7c-0.2,0.1-0.6,0.3-0.7,0.3c-0.4-0.3,0.2-0.6,0-0.9
				C179.1,177.8,179.4,177.9,179.7,178.4z"/>
			<path fill="#F8F4EE" d="M177.9,173.7c-0.2-0.2-0.5-0.5-0.2-0.8c0.2-0.2,0.6-0.2,0.7-0.1c0.1,0.2,0,0.5,0,0.8
				C178.3,173.9,178.1,173.8,177.9,173.7z"/>
			<path fill="#F8F5EF" d="M174,173.6c-0.2,0.1-0.4,0.2-0.6,0c-0.3-0.2-0.4-0.5-0.2-0.8c0.2-0.2,0.4-0.1,0.5,0
				C174,173,174.1,173.3,174,173.6z"/>
			<path fill="#F2EBDE" d="M184.5,172.3c-0.3,0.4,0.1,1-0.5,1.2c-0.1,0-0.2-0.1-0.3-0.2C183.7,172.8,183.9,172.4,184.5,172.3z"/>
			<path fill="#F1E9DB" d="M179.4,177.4c0.3-0.3,0.4-0.6,0.3-1c0.5,0.2,0.9,0.3,1.4,0.5c-0.2,0.2-0.2,0.6-0.6,0.3
				c-0.4-0.3-0.5,0.1-0.7,0.4C179.6,177.7,179.5,177.6,179.4,177.4z"/>
			<path fill="#F2EBDF" d="M183.1,170.2c0.4,0.5,0.8,0.8,0.6,1.3c0,0-0.2,0.1-0.3,0.1C183.1,171.2,183,170.8,183.1,170.2z"/>
			<path fill="#F3ECE0" d="M168.9,171.4c-0.2,0.5,0.1,1-0.5,1.2c-0.1,0-0.3-0.1-0.3-0.2C168,172,168.3,171.6,168.9,171.4z"/>
			<path fill="#F0E8D8" d="M180.8,168.9c-0.3-0.1-0.5-0.2-0.4-0.6c0.1-0.4,0.4-0.1,0.5,0c0.1,0.2,0.7,0.1,0.5,0.6
				C181.3,169.4,181,169.1,180.8,168.9z"/>
			<path fill="#F1E9DB" d="M170.3,177.5c-0.5,0.5,0.7,0.8,0.2,1.2c-0.2,0.1-0.3,0-0.5-0.2C169.6,178.1,169.9,177.8,170.3,177.5z"/>
			<path fill="#FAF8F3" d="M187,162.1c0.3,0.2,0.2,0.5,0.2,0.8c-0.1,0.2-0.3,0.2-0.5,0.1c-0.2-0.1-0.4-0.3-0.2-0.5
				C186.6,162.3,186.7,162,187,162.1z"/>
			<path fill="#F0E8D9" d="M184.4,169.2c-0.4,0.1-0.8,0.2-1-0.2c-0.1-0.3,0-0.6,0.4-0.7C184.4,168.4,184.4,168.8,184.4,169.2z"/>
			<path fill="#F3ECE0" d="M175.9,174c-0.5,0-0.9,0.1-1.3,0.4C175,173.3,175,173.3,175.9,174z"/>
			<path fill="#FBF9F5" d="M190.4,175.2c0-0.3,0-0.6-0.1-0.9c0-0.3,0.3-0.2,0.4-0.3c0.3,0,0.7,0.1,0.3,0.5
				C190.9,174.8,190.6,175,190.4,175.2z"/>
			<path fill="#F8F4ED" d="M175.2,178.2c-0.1-0.4,0-0.8,0.3-1.1c0.1,0,0.3,0,0.3,0C175.9,177.6,175.3,177.8,175.2,178.2z"/>
			<path fill="#F8F5F0" d="M177.6,181.6c0-0.2,0-0.4,0-0.5c0.4,0,0.8-0.1,0.8,0.5c-0.1,0.2-0.1,0.4,0,0.5c0,0.1,0,0.2-0.1,0.2
				c-0.1,0-0.1,0.1-0.1,0.1C177.8,182.3,177.7,182,177.6,181.6z"/>
			<path fill="#FBF9F6" d="M167.2,168.9c0.2,0.1,0.2,0.3,0.1,0.5c-0.2,0.2-0.4,0.2-0.6,0.1c-0.2-0.1-0.2-0.3-0.1-0.5
				C166.8,168.8,167,168.8,167.2,168.9z"/>
			<path fill="#FAF7F3" d="M167.7,180.1c-0.2,0.2-0.3,0.5-0.7,0.3c-0.2-0.1-0.2-0.2-0.1-0.4c0.1-0.3,0.4-0.4,0.7-0.3
				C167.7,179.8,167.8,179.9,167.7,180.1z"/>
			<path fill="#FCFBF9" d="M187.7,178.9c-0.2,0.2-0.3,0.7-0.5,0.3c-0.2-0.3-0.2-0.8,0.4-1C187.6,178.5,187.7,178.7,187.7,178.9z"/>
			<path fill="#F3ECE0" d="M173,178.1c0.5-0.3,0.8-0.4,1.1-0.1c0,0,0,0.2-0.1,0.3C173.7,178.6,173.4,178.4,173,178.1z"/>
			<path fill="#F5F0E7" d="M189.3,165c-0.2-0.3-0.5-0.5-0.7-0.7C189.4,164,189.4,164,189.3,165z"/>
			<path fill="#F7F3EC" d="M174.8,167.5c0.2,0.6-0.1,0.9-0.6,1C174.1,167.9,174.2,167.6,174.8,167.5z"/>
			<path fill="#FBF9F6" d="M183.8,163.4c-0.2,0.2-0.4,0.2-0.6,0.1c-0.2-0.1-0.2-0.3,0-0.4c0.2-0.1,0.4-0.4,0.6-0.2
				C183.9,163,183.8,163.2,183.8,163.4z"/>
			<path fill="#F3ECDF" d="M191.3,168.4c0.3,0.4,0.4,0.7,0.1,1c0,0.1-0.3,0.1-0.3,0C190.9,169.1,191.1,168.8,191.3,168.4z"/>
			<path fill="#F3ECE0" d="M186.1,172.1c0,0.5-0.1,0.8-0.6,1C185.4,172.7,185.6,172.4,186.1,172.1z"/>
			<path fill="#FDFCFA" d="M181.8,179.1c-0.1-0.3-0.4-0.6-0.1-0.8c0.3-0.1,0.6,0.3,0.8,0.6C182.2,179,182,179,181.8,179.1z"/>
			<path fill="#F8F5F0" d="M170.2,171.9c0,0.1,0.1,0.3,0.1,0.4c-0.1,0.2-0.4,0.3-0.6,0.2c-0.1,0-0.2-0.3-0.1-0.4
				C169.7,171.8,169.9,171.8,170.2,171.9z"/>
			<path fill="#F0E8DA" d="M165.2,177.7c0.3,0.2,0.5,0.5,0.5,0.8c0,0.1-0.2,0.2-0.2,0.1C165.1,178.4,164.9,178.2,165.2,177.7z"/>
			<path fill="#F1E9DB" d="M179.7,178.4c-0.3-0.2-0.6-0.4-0.9,0.1c-0.1-0.4-0.3-0.8-0.1-1.2c0.2,0.2,0.3,0.4,0.5,0.6
				c0.3-0.1,0.5,0,0.7,0.2c0.2,0.1,0.2,0.3,0.1,0.5C179.8,178.5,179.8,178.4,179.7,178.4z"/>
			<path fill="#F7F3EC" d="M171.3,173c0.1,0.4,0.1,0.7-0.4,0.8c-0.1,0-0.3-0.1-0.3-0.1C170.6,173.2,171,173.1,171.3,173z"/>
			<path fill="#F6F1E8" d="M194,165.2c0.5,0.3,0.7,0.5,0.7,1C194.1,166,193.9,165.8,194,165.2z"/>
			<path fill="#FCFCF9" d="M191,172.5c-0.1,0.2-0.3,0.1-0.5,0.1c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.2,0.3-0.2,0.5-0.2
				C190.9,172.2,191,172.3,191,172.5z"/>
			<path fill="#F8F3EC" d="M173.2,181.1c0.1-0.2,0.2-0.4,0.5-0.3c0.2,0.1,0.2,0.3,0.1,0.4c-0.1,0.1-0.3,0.3-0.5,0.2
				C173.1,181.5,173.1,181.2,173.2,181.1z"/>
			<path fill="#F6F2E9" d="M168,176.5c0.2,0.1,0.3,0.3,0.2,0.5c-0.1,0.2-0.2,0.2-0.4,0.1c-0.1-0.1-0.4-0.3-0.3-0.4
				C167.6,176.4,167.8,176.4,168,176.5z"/>
			<path fill="#F3ECE0" d="M182.6,161.4c0.2,0,0.3,0.2,0.2,0.3c-0.1,0.2-0.3,0.3-0.5,0.2c-0.1-0.1-0.2-0.3-0.2-0.4
				C182.2,161.3,182.5,161.4,182.6,161.4z"/>
			<path fill="#FBF8F3" d="M169.8,169.4c0.3-0.4,0.6-0.4,1-0.3C170.6,169.6,170.3,169.6,169.8,169.4z"/>
			<path fill="#F3EDE1" d="M184.4,169.2c-0.2-0.3-0.4-0.6-0.6-0.9c0.3-0.2,0.5,0,0.7,0.2c0,0.3,0.4,0.4,0.5,0.6
				C184.9,169.3,184.7,169.4,184.4,169.2z"/>
			<path fill="#FBF7F3" d="M166.2,171.7c-0.2-0.5,0-0.7,0.3-1C166.6,171.1,166.6,171.4,166.2,171.7z"/>
			<path fill="#FDFDFC" d="M190.8,174c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2-0.2-0.3-0.4-0.5-0.6C190.4,173.1,190.5,174,190.8,174z"/>
			<path fill="#FAF8F3" d="M192.2,171.1c0,0.1,0.1,0.2,0,0.3c-0.1,0.2-0.4,0.3-0.6,0.2c-0.1,0-0.1-0.2,0-0.3
				C191.7,171.1,191.9,171,192.2,171.1z"/>
			<path fill="#F1E8D8" d="M178.4,170.4c0.1,0.2,0.1,0.3,0,0.4c-0.2,0.1-0.4,0.2-0.5,0c0,0,0-0.2,0.1-0.3
				C178.1,170.5,178.2,170.3,178.4,170.4z"/>
			<path fill="#FCFAF8" d="M182.2,181.3c-0.7-0.7,0.2-0.4,0.4-0.6c0.1,0,0.1,0.1,0.2,0.1C182.8,181.1,182.6,181.3,182.2,181.3z"/>
			<path fill="#F0E7D8" d="M168.5,180.4c0.5-0.1,0.8,0.1,1,0.3C169.2,180.9,168.9,180.8,168.5,180.4z"/>
			<path fill="#F7F2EB" d="M169.3,173.7c-0.1,0.2-0.3,0.3-0.4,0.2c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.2,0.2-0.1,0.3-0.1
				C169.1,173.4,169.3,173.5,169.3,173.7z"/>
			<path fill="#F2EBDD" d="M172.4,176c-0.1,0-0.3,0.1-0.4,0c-0.2-0.1-0.2-0.3,0-0.5c0.1-0.1,0.3-0.2,0.4,0
				C172.5,175.6,172.5,175.8,172.4,176z"/>
			<path fill="#F0E8D8" d="M190.3,171c-0.2,0-0.3,0-0.4,0c-0.1-0.1-0.1-0.3-0.1-0.4c0.1-0.2,0.2-0.2,0.3,0
				C190.3,170.6,190.3,170.8,190.3,171z"/>
			<path fill="#FAF8F3" d="M175.1,179.3c-0.1,0.3-0.4,0.3-0.6,0.3c-0.1,0-0.1-0.2-0.1-0.3C174.7,179.1,174.9,179.1,175.1,179.3z"/>
			<path fill="#F9F5EF" d="M182.3,168c0.3-0.6,0.5-0.9,1-0.8C183.1,167.4,182.8,167.6,182.3,168z"/>
			<path fill="#F8F5EF" d="M185.3,171.3c0,0.3,0,0.6-0.3,0.7c-0.1,0-0.2-0.1-0.2-0.2C184.7,171.4,185,171.4,185.3,171.3z"/>
			<path fill="#F8F4EE" d="M178,175.8c0.1,0.1,0.2,0.2,0.1,0.4c-0.1,0.1-0.3,0.2-0.4,0.1c-0.1-0.1-0.1-0.2-0.1-0.3
				C177.7,175.8,177.8,175.7,178,175.8z"/>
			<path fill="#F3ECE1" d="M186,159.2c-0.1-0.2,0-0.4,0.1-0.5c0.1-0.1,0.3-0.2,0.4,0c0.1,0.2,0.1,0.3-0.1,0.4
				C186.3,159.2,186.1,159.2,186,159.2z"/>
			<path fill="#F2ECDF" d="M199,159.9c0.1,0.2,0.1,0.3,0.1,0.5c0,0.2-0.1,0.3-0.3,0.2c-0.1-0.1-0.1-0.3-0.1-0.4
				C198.7,160,198.8,160,199,159.9z"/>
			<path fill="#F6F1E9" d="M177,174.4c0.2,0.1,0.3,0.2,0.3,0.4c-0.1,0.2-0.3,0.1-0.4,0c-0.1-0.1-0.2-0.2-0.1-0.4
				C176.7,174.3,176.9,174.4,177,174.4z"/>
			<path fill="#F2EADD" d="M173.7,171.8c-0.1,0.1-0.2,0.2-0.3,0.1c-0.2,0-0.2-0.2-0.2-0.4c0-0.2,0.2-0.3,0.3-0.2
				C173.6,171.4,173.6,171.6,173.7,171.8z"/>
			<path fill="#F6F0E8" d="M181.8,176.5c-0.1-0.1-0.3-0.2-0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.1c0.1,0,0.1,0.2,0.1,0.3
				C182.1,176.5,182,176.6,181.8,176.5z"/>
			<path fill="#FDFDFC" d="M189.4,169.3c-0.3,0.1-0.3-0.1-0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4C189.7,168.8,189.4,169.1,189.4,169.3z"/>
			<path fill="#F7F3EC" d="M194.1,160.6c0.2,0,0.2,0.2,0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1-0.1-0.2-0.1-0.3
				C193.8,160.7,193.9,160.6,194.1,160.6z"/>
			<path fill="#FDFAF8" d="M181.1,165.8c-0.2,0-0.3,0-0.5,0c0,0-0.1-0.3-0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.1
				C181,165.5,181,165.6,181.1,165.8z"/>
			<path fill="#FBFAF6" d="M186.4,169.2c0-0.1-0.1-0.3-0.1-0.3c0-0.1,0.2-0.2,0.2-0.2c0.2,0,0.2,0.2,0.1,0.3
				C186.7,169.1,186.5,169.1,186.4,169.2z"/>
			<path fill="#F5F0E8" d="M196.8,165c-0.2,0-0.3,0.1-0.4,0c-0.1-0.1-0.2-0.2-0.1-0.3c0,0,0.2,0,0.3,0
				C196.7,164.7,196.7,164.9,196.8,165z"/>
			<path fill="#F3EDE2" d="M185.5,177.5c0-0.1-0.1-0.3-0.1-0.3c0-0.1,0.2-0.2,0.2-0.2c0.2,0,0.2,0.2,0.1,0.3
				C185.7,177.4,185.6,177.5,185.5,177.5z"/>
			<path fill="#F6F2EA" d="M192.1,172.7c0,0.1,0,0.2,0,0.3c-0.1,0.2-0.3,0.1-0.4,0c-0.1-0.1-0.1-0.2,0-0.3
				C191.7,172.7,191.8,172.8,192.1,172.7z"/>
			<path fill="#F3EDE1" d="M187.2,177.5c-0.6,0.1-0.7-0.4-0.9-0.8c0,0,0,0,0,0C186.7,176.7,187.1,176.9,187.2,177.5z"/>
			<path fill="#F8F5EF" d="M179.8,168.3c-0.1,0.2-0.3,0.2-0.4,0.2c-0.1,0-0.1-0.2-0.1-0.3c0.1-0.1,0.2-0.1,0.4-0.2
				C179.7,168,179.8,168.2,179.8,168.3z"/>
			<path fill="#FCFBF8" d="M187.2,177.5c-0.2-0.4-0.5-0.6-0.9-0.8c0.5-0.2,0.8,0.2,1.2,0.4C187.3,177.2,187.2,177.3,187.2,177.5z"/>
			<path fill="#FBF9F5" d="M187.4,176.5c-0.1,0-0.1-0.1-0.2-0.1c0.1-0.2,0.3-0.4,0.6-0.3c0.1,0,0.2,0.2,0.1,0.3
				C187.8,176.5,187.6,176.5,187.4,176.5z"/>
			<path fill="#F4EEE3" d="M186.4,178.3c-0.1,0-0.2,0.1-0.3,0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.1,0.2-0.1
				C186.3,178.1,186.3,178.2,186.4,178.3z"/>
			<path fill="#F3ECE1" d="M181,175.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.1
				C181.1,175.2,181.1,175.3,181,175.4z"/>
			<path fill="#FDFCFB" d="M184.9,178.9c-0.1-0.1-0.3-0.2-0.4-0.2c0-0.7,0.4-0.3,0.6-0.2C185.1,178.7,185,178.8,184.9,178.9z"/>
			<path fill="#F5F0E7" d="M186.2,166.5c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.1,0.3-0.1
				C186.2,166.3,186.2,166.4,186.2,166.5z"/>
			<path fill="#F5EFE6" d="M183.3,164.9c0,0.1,0,0.2,0,0.3c-0.1,0.1-0.2,0.1-0.3,0c0-0.1,0-0.2,0.1-0.3
				C183.1,164.9,183.2,164.9,183.3,164.9z"/>
			<path fill="#F2ECDF" d="M188.6,177.6c0-0.1,0-0.3,0-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0,0.1,0.1,0.2,0,0.3
				C188.9,177.6,188.8,177.6,188.6,177.6z"/>
			<path fill="#F9F6F1" d="M183,173.8c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.1c-0.1-0.1-0.2-0.2-0.2-0.2
				C182.7,173.8,182.8,173.7,183,173.8z"/>
			<path fill="#F1E9DB" d="M164.5,175.4c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.3c0,0,0.1-0.1,0.2-0.1
				C164.6,175.1,164.6,175.2,164.5,175.4z"/>
			<path fill="#F6F1E8" d="M180.7,163.8c0.1,0.2,0.2,0.3,0.1,0.3c-0.1,0.2-0.3,0.1-0.4,0.1c-0.1,0-0.1-0.1-0.1-0.2
				C180.4,164,180.5,163.9,180.7,163.8z"/>
			<path fill="#FDFBF8" d="M185.6,173.8c0,0.1,0.1,0.2,0.1,0.2c-0.1,0.1-0.2,0.1-0.4,0.2c0-0.1,0-0.2,0-0.3
				C185.3,173.7,185.4,173.7,185.6,173.8z"/>
			<path fill="#F7F2EB" d="M180,165.2c0-0.1,0-0.3,0-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0,0.1,0.1,0.2,0,0.3
				C180.3,165.1,180.2,165.1,180,165.2z"/>
			<path fill="#F8F5EF" d="M176.2,175.9c0,0.2,0.1,0.3,0,0.4c0,0.1-0.2,0.1-0.2,0.1c0-0.1-0.1-0.2-0.1-0.3
				C175.9,176,176,175.9,176.2,175.9z"/>
			<path fill="#F0E8D8" d="M167.4,172c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.2,0.2-0.3,0.1c-0.1,0-0.1-0.2-0.1-0.3
				C167.1,172.1,167.3,172,167.4,172z"/>
			<path fill="#FCFBF9" d="M185.1,169.1c-0.3,0-0.8,0-0.5-0.6C184.8,168.6,185.1,168.7,185.1,169.1z"/>
			<path fill="#FEFEFE" d="M173.3,176.6c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0,0.3,0c0,0,0.1,0.1,0.1,0.2
				C173.6,176.7,173.4,176.7,173.3,176.6z"/>
			<path fill="#F5F0E7" d="M187.8,162.4c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.2,0.1c-0.1-0.1-0.2-0.2-0.2-0.3
				C187.6,162.5,187.7,162.4,187.8,162.4z"/>
			<path fill="#EDE2CF" d="M186,181.1c-0.1,0.1-0.3,0.2-0.4,0.1c-0.1-0.1,0-0.3,0-0.3c0.1-0.1,0.3-0.1,0.4-0.1
				C186.1,180.8,186.1,181,186,181.1z"/>
			<path fill="#FCFBF8" d="M180,183.6c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.2,0.3,0.4-0.1,0.6c-0.1,0.1-0.3,0-0.4,0
				C179.9,183.9,180,183.8,180,183.6z"/>
			<path fill="#EDE2CF" d="M177.6,181.6c0.2,0.2,0.5,0.5,0.7,0.7c0.1,0.3-0.1,0.5-0.3,0.6c-0.2-0.3-0.4-0.6-0.6-0.9
				C177.4,181.9,177.5,181.8,177.6,181.6z"/>
			<path fill="#FAF8F4" d="M177.9,182.9c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.1,0.1-0.2C178.6,182.6,178.8,183,177.9,182.9z"/>
			<path fill="#EDE2CF" d="M179.9,178.5c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1,0-0.2c0,0,0.1,0,0.1,0c0.4,0.3,0.8,0.4,1.2-0.1
				c0.1,0.6-0.2,0.9-0.8,1.1C179.9,179.1,179.9,178.8,179.9,178.5z"/>
			<path fill="#EDE2CF" d="M179.9,178c0,0.1-0.1,0.1-0.1,0.2c-0.2-0.1-0.4-0.2-0.6-0.4c0.1-0.1,0.2-0.3,0.2-0.4
				c0.1,0.1,0.3,0.2,0.4,0.2C179.9,177.8,179.9,177.9,179.9,178z"/>
		</g>
	</g>
</g>
<g id="body">
	<g id="w0M4oC_1_">
		<g>
			<path fill="#615952" d="M115.4,559.7c3.2,2.5,6.6,4.5,10.3,6.1c2.9,1.2,5.8,2.3,8.7,3.2c1.7,0.6,2.6,1.7,2.5,3.3
				c0,1-0.5,1.5-1.5,1.4c-1.2-0.1-2.5-0.1-3.7-0.2c-0.7-0.1-0.9,0.1-0.7,0.8c0.2,0.8,0.1,1.6-0.6,2.3c-0.8,0-1.6,0-2.4,0
				c-0.4-0.2-0.8-0.2-1.2,0c-0.2,0-0.5,0-0.7,0c-0.5-0.2-1-0.2-1.4,0c-0.2,0-0.3,0-0.5,0c-0.6-0.2-1.3-0.2-1.9,0c-0.6,0-1.3,0-1.9,0
				c-1-0.2-1.9-0.2-2.9,0c-0.5,0-1,0-1.4,0c-1.8-0.3-3.7-0.1-5.5-0.6c-2.1-0.5-4.1-1-6-2c-1.3-0.7-1.4-0.6-1.6,0.9
				c-0.1,0.7,0,1.5-1.1,1.5c-1.7-0.1-3.4,0.1-5-0.1c-2.1,0-4.2-0.1-6.3-0.1c-0.9,0-1.3-0.3-1.4-1.2c-0.7-4.7-0.9-9.3,1.5-13.6
				c0.8-0.3,1.4,0.1,2,0.4c5,1.7,9.9,1.9,14.9-0.1c0.7-0.3,1.4-0.4,2.1-0.5c1.5-0.2,2.9-0.7,4.2-1.4
				C114.4,559.7,114.8,559.2,115.4,559.7z"/>
			<path fill="#010101" d="M145.7,226.9c-0.9,3.7,0,7.2,1,10.7c1.2,4.2,2.6,8.4,3.8,12.6c1.7,5.8,3.5,11.5,5,17.4
				c1.1,4.1,2.5,8.2,3.5,12.3c1.3,5,2.5,10,3.6,15c1.2,5.2,2.5,10.4,3.5,15.6c1,5.4,1.9,10.8,2.7,16.1c0.6,3.5,1.3,7,1.6,10.5
				c0.4,4.5,0.7,9,1.1,13.5c0.3,3.6,0.6,7.3,0.6,10.9c0.1,6.2,0.4,12.3-0.1,18.5c-0.5,5.6-0.9,11.2-1.7,16.8
				c-0.9,6.1-1.5,12.2-2.8,18.3c-1.5,7-3.1,14-4.6,21c-0.5,2.4-1.3,4.8-1.8,7.3c-1.2,5.4-3.3,10.6-4.9,16c-0.5,1.7-1.2,3.3-1.9,4.9
				c-0.6,1.3-0.8,2.7-1.4,4.1c-2.1,5.6-4.2,11.3-6.3,16.9c-0.9,2.3-2,4.5-2.8,6.8c-1.6,4.3-3.5,8.5-5.4,12.7
				c-2.2,4.9-4.6,9.7-7,14.5c-2.7,5.3-5.2,10.6-7.8,16c-0.4,0.4-0.8,0.2-1.2,0.1c-4.4-1.4-8.9-2.7-13-4.8c-0.9-0.5-2.2-0.5-2.7-1.6
				c-0.1-1.7,0.4-3.3,0.8-4.9c1.4-5.8,2.8-11.6,4.2-17.5c1.2-5.1,2.5-10.3,3.8-15.4c1.3-5.3,2.5-10.7,3.7-16
				c1.5-6.8,2.9-13.7,4.6-20.4c0.7-2.7,1.1-5.5,1.7-8.2c0.9-4.1,1.7-8.1,2.6-12.2c1.3-6.1,2.3-12.2,3.1-18.3c0.5-3.8,1-7.6,1.3-11.4
				c0.2-2.1,0.3-4.1,0.3-6.2c0.1-3,0-6.1,0.3-9.1c0.2-2.8-0.2-5.6-0.6-8.4c-0.5-4.2-0.7-8.5-1.6-12.6c-1.3-6.2-2.4-12.5-3.9-18.7
				c-1.1-4.5-2.1-8.9-3.4-13.4c-1.9-6.4-3.6-12.9-5.9-19.3c-1.3-3.7-2.3-7.5-3.7-11.1c-0.2-0.6-0.5-1.2-0.9-1.7
				c-0.4-0.5-0.8-0.5-1.1,0.1c-0.4,0.7-0.5,1.5-0.6,2.3c-0.1,5-0.1,10-0.3,15c-0.2,4.9-0.3,9.8-0.6,14.7c-0.1,2.8-0.4,5.5-0.4,8.3
				c0,2.8-0.3,5.5-0.5,8.3c-0.2,2.7-0.2,5.5-0.4,8.3c-0.4,4.7-0.3,9.3-0.9,14c0,0.2,0,0.4,0,0.6c0.2,7-0.7,14-0.8,21
				c-0.1,6.5-0.7,12.9-1.2,19.4c-0.3,4-0.8,7.9-0.9,11.9c-0.2,4.2-0.4,8.4-0.8,12.6c-0.3,3.3-0.5,6.5-0.4,9.8c0,1,0,2-0.2,3
				c-0.4,2-0.5,4-0.5,6c0,3.4-0.5,6.8-0.6,10.2c-0.2,4.4-0.4,8.8-0.3,13.2c0.1,2.4,0,4.8-0.1,7.2c-0.1,2.8-0.4,5.5-0.4,8.3
				c0,5.6-0.1,11.2-0.2,16.8c-0.1,4.5,0.3,9,0.2,13.5c0,0.6,0.1,1.2-0.2,1.8c-0.3,0.6-0.8,0.8-1.4,0.8c-5.5,0.6-11,1.8-16.6,1.8
				c-0.6,0-1.1,0-1.6-0.4c-1.4-4.3-2.6-8.7-3.8-13.1c-0.8-3.1-1.6-6.2-2.4-9.3c-0.8-3.3-1.5-6.5-2.5-9.8c-0.8-2.8-1.3-5.7-1.9-8.6
				c-0.8-4-1.7-7.9-2.4-11.9c-0.5-2.8-1.2-5.5-1.5-8.3c-0.4-4.1-0.9-8.1-1.2-12.2c-0.2-3.3-0.4-6.5-0.4-9.8c0-2.7,0-5.4,0-8.1
				c0.1-4.8,0.5-9.5,0.9-14.2c0.3-3.7,0.5-7.4,1-11c0.5-4.3,1.1-8.6,1.6-13c0.2-1.6,0.3-3.3,0.4-4.9c0.5-7.1-0.2-14.1-1.4-21.1
				c-0.7-4.2-1.3-8.4-1.9-12.5c-0.7-4.1-1.4-8.2-2.3-12.2c-0.7-3-1.1-6-1.8-9c-0.7-3.1-1.1-6.3-1.9-9.4c-0.6-2.5-1-5.1-1.6-7.6
				c-1-4.3-2-8.5-2.9-12.8c-0.6-3.1-1-6.3-1.5-9.4c-0.5-3-0.9-5.9-1-8.9c-0.1-3.6-0.1-7.3,0-11c0.2-4.2,0.9-8.3,1.6-12.4
				c0.7-4.1,2-8.1,3.3-12.1c1-3,1.9-5.9,3.1-8.8c0.1-0.2,0.1-0.5,0.1-0.7c0.3-0.3,0.5,0,0.7,0.2c0.7,1.2,1.8,1.4,2.9,1.1
				c1.4-0.3,2.9-0.3,4.3-0.7c2.5-0.8,4.1-2.6,5.6-4.5c2.4-3,4.8-6,6.7-9.3c1.7-2.9,2.6-6,2.2-9.4c-0.1-0.9,0.1-1.4,1.1-1.4
				c2.9,0.1,5.7,0.5,8.6,0.6c4.7,0.2,9.4,0.8,14.1,0.5c1.6-0.1,3.2-0.1,4.8,0.1c3.3,0.3,6.5,0.1,9.8,0.1c4.8-0.1,9.6,0.1,14.5-0.4
				c2.4-0.2,4.9-0.2,7.4-0.3C145.2,226.5,145.5,226.5,145.7,226.9z"/>
			<path fill="#EDE2CF" d="M145.7,226.9c-1.9,0.2-3.9,0.3-5.8,0.3c-2.7,0-5.4,0.3-8.2,0.3c-5.2,0-10.4,0.1-15.6,0.1
				c-2.4,0-4.9-0.4-7.3-0.2c-4.6,0.4-9.1-0.4-13.6-0.4c-3.2,0-6.5-0.4-9.7-0.7c-0.8-0.1-1-0.5-1.2-1.2c-0.9-3.7-2.7-7-5.8-9.3
				c-2.2-1.6-4.7-2.7-7.3-3.3c-0.5-0.1-1.2-0.1-1.3-0.8c0.2-0.5,0.2-1.1,0.1-1.7c-0.5-4.3-1.2-8.5-2-12.8
				c-0.9-5.2-1.7-10.5-2.5-15.7c-0.3-2.2-0.9-4.4-1.3-6.6c-0.6-3.1-1.1-6.3-1.9-9.4c-1-4.3-1.8-8.6-3.3-12.7
				c-0.7-1.8-1.3-1.9-2.6-0.5c-4,4.4-7.6,9.1-11.1,14c-3,4.1-5.5,8.5-8.1,12.7c-1.8,2.8-3.5,5.6-5.1,8.5c-0.9,1.7-1.8,3.5-2.1,5.5
				c-0.4,3.1,0.9,5.3,3.2,7.2c2.6,2.1,5.8,3.2,9,4.2c5.9,1.8,11.8,3.1,17.7,4.6c0.3,0.1,0.6,0.1,0.7,0.5c-0.9,4.1-2.5,8-3.3,12.1
				c-0.1,0.4-0.2,0.7-0.6,0.9c-1.5-0.3-2.9-0.7-4.4-1c-0.6-0.1-1,0.3-1.3,0.6c-1.5,1.4-2.9,2.9-4.6,4.2c-1.5,1.1-3.1,1.9-4.8,2.6
				c-1.6,0.6-3.2,1.2-4.9,1.1c-3-0.2-6-0.3-9-1.1c-5.1-1.2-9.8-3.4-14-6.3c-2.9-2-5.2-4.7-7.4-7.4c-2.4-3-4.2-6.3-5.3-9.9
				c-1.1-3.6-1.2-7.2-0.3-10.9c1.1-4.8,2.5-9.5,4.5-14c1.7-4,3.9-7.7,6-11.5c0.5-0.1,1.1-0.2,1.6-0.2c-0.8,0.1-1.1-0.2-0.9-1
				c0-0.2,0-0.4,0.2-0.5c0.8-0.1,1.5-0.2,2-0.9c-0.4,0.4-0.9,0.6-1.4,0.8c-0.2,0.1-0.5,0-0.4-0.3c0.8-1.4,1.6-2.7,2.4-4.1
				c0.4-0.1,0.4,0.2,0.5,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.4,0,0.7,0,1.1c0.1,0.5,0.1,1.1,0.7,1.4c0.3,0.4,0.3,0.8-0.1,1.2
				c-0.3,0.3-0.5,0.7-0.7,1.1c1.2-1.3,1.2-1.3,0.8-2.4c0.2-0.7-0.6-0.8-0.7-1.3c0.1-0.9,0.6-1.3,1.4-1.2c-0.3-0.4-1.5-0.2-1.3-1.3
				c0.8-1.4,1.6-2.9,2.4-4.3c0.5-0.2,0.5,0.4,0.8,0.5c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0.1,0.5,0.1,0.7,0.3c0.1,0.1,0.2,0.1,0.2,0.2
				c0.2,0.2,0.1,0.4,0.3,0.6c0.2-0.3-0.2-0.4-0.3-0.6c-0.4-1-0.1-2.1,0.8-2.6c0.1,0,0.2,0,0.3,0.1c0.8,1,1.7,2,1.7,3.4
				c0-1.2-0.5-2.2-1.1-3.2c-0.1-0.7,0-1.5-1.1-1c-0.1,0-0.2,0.1-0.2,0.1c-0.2,0.4-0.1,1.2-0.9,1c-0.7-0.2-0.4-0.8-0.5-1.3
				c0-0.2,0-0.3,0.1-0.4c0.2-0.5,0.2-1,1.1-0.7c0.9,0.3,0.6-0.5,0.6-0.9l0,0c0.5,0.1,0.6-0.3,0.8-0.6c0.3-0.7-0.7-0.3-0.5-0.7
				c0.1-0.3,0.4-0.1,0.6,0c0.8,0.1,1.1-0.3,0.7-1c-0.2-0.3-0.4-0.5-0.1-0.8c0.7-0.9,1.1-2,1.9-2.7c0.4,0.3,0.4,0.8,0.4,1.2
				c0.9-0.7,0.1-0.9-0.4-1.2c0.1-1.4,1.4-1.7,2.4-2.3c0.2-0.1,0.5-0.2,0.3-0.4c-0.8-1,0-1.7,0.5-2.4c0.1-0.1,0.2-0.2,0.3-0.4
				c0.3-1.1,1.2-1,2.1-1c0.4,0.4,0.6,0.8,1,1.2c0.1,0.2-0.1,0.5-0.2,0.4c-0.6-0.3-1,0.2-1.5,0.4c-0.3,0-0.5-0.2-0.8,0
				c-0.3,0.2-0.3,0.4-0.2,0.8c0.2,0.6,0.4,0.1,0.5,0c0.3-0.2,0.9-0.1,1-0.7c0.2-0.1,0.5-0.2,0.7,0c0.5,0.5,1,0.3,1.2-0.1
				c0.3-0.5-0.5-0.4-0.6-0.8c-0.2-0.5-1-0.8-0.3-1.5c0-0.1,0-0.2,0-0.2c0.4-0.3,0.9,0,1.4,0c-0.1-0.4-0.3-0.8-0.5-1.3
				c-0.3,0.5-0.1,1.3-0.9,1.3c-0.2-0.1-0.4-0.2-0.7,0c-0.3,0.2-0.7,0.3-1,0c-0.3-0.4,0.2-0.9,0.3-1c1-0.6,1-1.8,1.8-2.7
				c0.7-0.7,1.1-1.7,1.8-2.5c0.6-0.7,1.1-1.7,1.6-2.5c1-1.7,1-1.7,2.8-0.9c0.1-0.5-0.2-0.5-0.5-0.7c-0.6-0.3-1.1-0.6-0.4-1.4
				c0.8-0.9,1.3-2,2-3c0.3-0.3,0.7-0.2,0.9-0.1c0.6,0.8,1.1,0.1,1.7,0c-0.8,0.1-1.6,0.5-2.1-0.4c0.7-0.9,1.1-1.9,1.9-2.6
				c0.7-0.2,0.7,0.2,0.7,0.7c0,0.2-0.2,0.3-0.1,0.4c0-0.1,0.1-0.2,0.2-0.3c0.2-0.2,0.6-0.2,0.2-0.6c-0.4-0.4-0.8-1,0.2-1.3
				c0.1,0,0.2,0.2,0.3,0.2c0-0.2-0.1-0.2-0.2-0.3c-0.2-0.1-0.6,0.2-0.5-0.3c1.7-2.5,3.5-5,5.4-7.4c0.1-0.1,0.2-0.2,0.2-0.3
				c0.4-1.1,1-1.9,2.4-1.6c0.2,0,0.6,0.2,0.8-0.1c-0.1-0.6-0.6-0.9-1.2-1.1c1.5-1.8,2.9-3.6,4.5-5.2c3.2-3.3,6.6-6.3,10.4-8.8
				c0.3-0.2,0.6-0.3,0.6-0.7c3.4-2.2,7.1-3.7,11.1-4.7c4.3-1.1,8.6-1.8,12.9-2.6c1.3-0.2,2.6-0.7,3.9-1.1c0.8,0,0.9,0.5,1.1,1.1
				c0.6,2,1.4,4,2.2,5.9c1.7,3.9,4.3,7.1,7,10.2c2.6,3,5.6,5.5,9,7.7c1.9,1.3,2.1,1.3,4,0c2.8-2,5.2-4.3,7-7.1
				c0.2-0.3,0.4-0.6,0.8-0.7c1.6,0.4,2.8,1.6,4.3,2.1c0.2,0.1,0.5,0.2,0.8,0.3c0.5,0.3,1,0.4,1.6,0.4c0.1,0,0.2,0,0.3,0.1
				c0.7,0.2,1.5,0.3,2.3,0.4c0.6,0.2,1.2,0.2,1.8,0.2c1.1,0,2.1-0.2,3.2-0.3c0.4-0.1,0.8-0.1,1.2-0.2c0.2-0.1,0.4-0.2,0.6-0.2
				c0.1,0,0.2-0.1,0.3-0.1c0.9-0.1,1.5-0.6,2.2-1c0.1-0.1,0.2-0.1,0.3-0.2c0.5-0.3,0.8-0.7,1.2-1c0.2-0.2,0.4-0.4,0.5-0.6
				c0.5-0.8,1.2-1.4,1.4-2.3c0.4-0.9,0.4-1.9,0.8-2.9c0,0,0.1-0.1,0.1-0.1c0.3,0.2,0.6,0.3,0.7,0.6c0.9,2.2,2.2,4.1,2.8,6.4
				c0.2,0.9,0.5,1.8,1,2.7c0.3,0.5,0.5,1.1,0.4,1.6c-0.1,2.4,0.3,4.8,0.5,7.3c0.1,0.7,0.3,1.2,0.4,1.9c0.5,2.5,0.8,5,1.1,7.5
				c0.1,1.4,0.1,2.9,0,4.3c-0.1,2.2,0.1,4.5,0.2,6.7c0.1,1.7,0,3.4,0.2,5.1c0.2,1.9,0.1,3.8,0,5.6c-0.2,4.3-0.3,8.6-0.9,12.8
				c-0.2,1.5-0.2,3-0.3,4.5c-0.1,3.8-0.6,7.6-1.1,11.4c-0.3,1.9-0.4,3.8-0.5,5.7c-0.2,3.2-0.7,6.4-1.1,9.6c-0.3,2.5-0.6,5-0.9,7.5
				c-0.4,3-0.7,5.9-1.1,8.9c-0.3,1.9-0.5,3.8-0.6,5.7c-0.2,2.7-0.7,5.3-1.1,8C146.1,223.9,145.9,225.4,145.7,226.9z"/>
			<path fill="#030303" d="M92.7,87.3c-3.2,1.1-6.5,1.6-9.9,2.2c-6,1.1-12,2.6-17.3,5.8c-0.3,0.2-0.6,0.3-0.9,0.2
				c-1.8-1.2-3.7-2.3-4.8-4.3c-1.2-2.1-1.8-4.3-1.8-6.7c0-2,0.6-3.8,1.1-5.7c0.4-1.9-0.1-3.7-1-5.3c-2.9-5.1-1.3-10.2,2.3-14.1
				c2.2-2.4,4.9-4.4,7-6.9c1.3-1.5,2.2-3.3,3.3-4.9c0.9,0.3,1-1.1,1.8-1c0.2,0,0-0.3,0-0.5c-0.1-0.4-0.5-0.7-0.4-1.2
				c0.1-0.5,0.2-1,0.5-1.3c0.6,0,0.3,0.6,0.7,0.8c-0.1-0.6-0.8-0.9-0.5-1.5c0.1-0.2,0.2-0.5,0.2-0.7c0.1-0.1,0.3-0.1,0.4-0.1
				c0.3,0,0.6-0.1,0.8,0.2c0.1,0.3-0.3,0.4-0.4,0.7c0.9,0.1,1.2-0.6,1.7-1.1c0.1-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.1,0.7-0.1
				c0.4,0.3,0.7,0.7,1.2,0.9c0.1-0.2-0.2-0.5,0.1-0.7c0.3-0.4,0.9-0.3,1.4-0.4c0.1,0-0.1-0.1-0.1-0.1c-0.3,0.3-0.9-0.1-1.2,0.4
				c-0.2,0.3-0.5,0.3-0.8,0.1c-0.3-0.2-0.6-0.4-0.9-0.2c-0.3,0.2-0.6,0.3-0.9,0.4c-0.6,0.6-1.3-0.1-2,0.1c-0.3,0.1-0.2-0.5-0.1-0.8
				c0.1-0.4,0.2-0.8,0.3-1.2c1-0.6,0.7-1.5,0.6-2.4c0.2-0.3,0.5-0.4,0.9-0.4c0.1,0,0.2,0.1,0.2,0.2c0.1,0.3-0.1,0.6,0.1,0.9
				c0,0.1,0.1-0.2,0-0.1c-0.2-0.3,0-0.5,0-0.8c0-0.1,0-0.1,0-0.2c-0.1-1,0.5-1.7,0.9-2.4c0-0.1,0.1-0.2,0.2-0.2c0.6-0.1,0.8-0.5,1-1
				c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,0.2,0.5,0.6,0.2c0.1-0.1,0.3-0.1,0.4-0.1c0.2-0.6,0.1-0.6-0.7-0.4c-0.3,0.1-0.7,0.2-0.7-0.3
				c-0.1-0.5,0-1.1,0.5-1.4c0.5-0.3,0.9-0.6,1.4-0.9c0.3-0.1,0.6,0,0.8,0.2c0.1,0.1,0.1,0.2,0.2,0.3c-0.7,0.8,0.1,1.2,0.4,1.7
				c-0.1,0.5-0.3,0.9-0.3,1.4c-0.1,0.3-0.4,0.4-0.4,0.8c0,0.3,0.1,0.6,0.3,0.8c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.3,0.4,0.4
				c0.1,0.1,0.1,0.2,0.1,0.2c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.2,0.5,0.1c0.1-0.1,0.3-0.2,0.4-0.4c0.3-0.4,0-0.7-0.1-1
				c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.3-0.2-0.6,0.2-0.8c0.3-0.1,0.6,0,0.9,0.2c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.1-0.1-0.1
				c0-0.1-0.1-0.2-0.1-0.3c0-0.4-0.3-0.7-0.2-1.1c0.6-0.4-0.1-0.7-0.1-1.1c-0.1-0.4,0-0.8-0.4-1.1c-0.7-0.5-1.4,0.4-2-0.1
				c-0.1-0.5,0.1-1,0.5-1.4c0.2-0.1,0.4-0.1,0.6,0c0.1,0,0.1,0.1,0.2,0c-0.3-0.1-0.6-0.2-0.6-0.6c0.1-0.2,0.2-0.4,0.5-0.3
				c-0.2-0.1-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.3c-0.8-0.8-0.9,0.2-1.3,0.5c-0.5,0.2-0.8-0.1-1.2-0.2c-0.1-0.1-0.2-0.1-0.2-0.2
				c-0.5-0.6-0.2-1.1,0-1.7c0.2-0.5,0.6-0.4,1-0.3c0.3,0.1,0.5,0.2,0.7,0.3c0-0.3-0.3-0.5-0.1-0.7c0-0.1,0.1-0.2,0.2-0.2
				c0.2,0,0.5,0.1,0.6-0.2c0.2-0.1,0.5,0,0.7,0c0.3-0.1,0.5-0.2,0.8-0.4c0.4-0.2,0.5,0.2,0.8,0.3c0.2,0.2,0.5,0.1,0.6-0.1
				c0.2-0.2,0-0.4-0.1-0.6c-0.2-0.3-0.5-0.5-0.6-0.9c0-0.1,0-0.3,0-0.3c0.9-0.3,0.3-0.6,0-0.9c-0.1-0.2-0.1-0.4,0-0.6
				c0.6-0.6,1.4-0.3,2.1-0.3c0.1,0,0.3-0.1,0.4-0.2c0-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0.4-0.4,0.2-0.9,0.3-1.3
				c0.3-0.2,0.6-0.3,0.9-0.3c0.3,0,0.5,0.2,0.7,0.3c0.7,0.1,1.1-0.3,1.6-0.7c-0.3-0.3-0.6-0.7-1.2-0.5c-0.3,0.2-0.5,0.1-0.8,0
				c-0.4-0.2-0.7-0.4-0.8-0.9c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.7-0.1,0.4-1,1-1.2c0.1-0.1,0.3-0.2,0.4-0.3
				c0.3-0.4,1-0.4,0.9-1.2c-0.4-0.8-1.4-1.3-1.2-2.4c0.4-0.6,0.8-0.2,1.3-0.1c0.3,0.1,0.4,0,0.5-0.3c0.1-0.3,0.2-0.5,0.2-0.7
				c-0.4-0.5-0.6,0.1-0.9,0.2c-0.3,0.1-0.6,0.1-0.9,0.3c-0.2,0-0.4,0-0.5-0.3c-0.1-0.3,0-0.7-0.4-0.7c-0.3,0-0.5,0.1-0.7-0.2
				c-0.1-0.2-0.1-0.4,0-0.6c0.2-0.4,1.3-0.5,0.3-1.2C85,8.3,84.8,8.1,84.6,8c-0.3-0.1-0.6,0.1-0.9-0.1c-0.2-0.4-0.4-0.7-0.4-1.2
				c0.6-0.3,1-0.9,1.6-1c0.1-0.1,0.2-0.1,0.3,0c0.5,0.4-0.2,0.9,0.1,1.4c0.2,0.1,0.5,0.2,0.7,0.3c0.3,0.4,1,0.4,1.1,1
				c0,0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2C88,7.9,88.4,8,88.1,7.7c-0.1-0.1-0.3-0.4-0.4,0.1c0,0.2-0.2,0.2-0.4,0.2
				c-0.7-0.2-0.7-0.7-0.4-1.3c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2,0.5-0.3,0.4-0.6c-0.2-0.4-0.5,0-0.7,0c-0.3,0.1-0.6,0.3-1,0
				C85.7,5.4,86,5.2,86.2,5c0.4-0.2,0.8-0.5,1.2-0.7c0.6-0.2,1.1-0.5,1.7,0.1c0.3,0.3,0.7-0.2,1.1-0.2c0.3-0.1,0.6-0.1,0.9,0
				c0.3,0.1,0.5,0.2,0.6,0.5c0.4,0.6,1.5-0.1,1.5,1.1c0.3-0.6,0.1-1,0-1.3c0-0.2,0.1-0.3,0.2-0.4c0.4-0.1,0.6-0.4,0.9-0.5
				c0.2-0.1,0.4-0.1,0.6,0c0.4,0,0.2-0.3,0.1-0.4c-0.1-0.2-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.3,0c-0.3-0.2-0.5-0.4-0.6-0.8
				c0-0.2,0.1-0.3,0.2-0.5c0.2-0.2,0.4-0.3,0.7-0.2c0.9,0.6,1.5-0.2,2.2-0.5l0,0c0.6-0.1,0.7,0.4,0.8,0.8c0.1-0.8,0.8-1,1.4-1.2
				c2.6-0.4,5.3-0.6,7.9-0.3c3.5,0.4,7,1,10.3,2c3.1,0.9,6.1,2,8.8,3.8c0.7,0.5,1.1,1,0.8,1.9c0.1,1.3-0.5,2.4-1.1,3.5
				c-2.9,5.9-6.1,11.8-10.3,17c-2.5,3.1-5.1,6.2-7.8,9.1c-2.6,2.9-5.4,5.6-8.6,7.8c-1.8,1.3-2.6,2.5-1.9,4.9c1,3.4,1.7,6.9,1.4,10.5
				c-0.3,4.1-0.4,8.2-0.9,12.3c-0.5,3.7-0.6,7.4-1.3,11.1c-0.2,1.2-0.8,2.1-1.9,2.8C93.7,87.2,93.2,87.2,92.7,87.3z"/>
			<path fill="#C1A790" d="M92.7,87.3c0.2-0.1,0.4-0.3,0.6-0.3c1.6-0.6,2.4-1.8,2.6-3.5c0.4-2.7,0.7-5.4,1-8.1
				c0.5-4.8,0.9-9.6,1.1-14.4c0.2-4.4-0.9-8.5-1.8-12.6c-0.2-0.8,0-1.2,0.5-1.7c3-2.4,5.9-4.8,8.6-7.5c3.3-3.3,6.3-6.8,9.2-10.4
				c4.1-5,7.2-10.5,10.1-16.3c0.7-1.5,1.3-3,1.9-4.5c1.7,1.8,3.3,3.6,4.5,5.8c1.6,3,2.3,6.2,3.2,9.4c1.6,5.6,2.8,11.4,5.7,16.6
				c0.7,1.3,0.6,1.4-0.8,1.9c-0.3,0.1-0.7,0.3-1,0.4c-2.4,0.2-2.6,1.9-2.7,3.8c-0.1,1.9-0.2,1.9-2.1,1.6c-1.3-0.2-2.6-0.4-3.9-0.7
				c-0.3-0.1-0.7-0.2-0.9,0.1c-0.2,0.3,0,0.6,0.1,0.9c1,2.1,2.6,3.7,4.9,4.5c0.9,0.3,1.2,0.6,1,1.6c-0.4,1.9-0.7,3.9-1.5,5.8
				c-0.3,0.6-0.9,1-1,1.7c-1.4,1.2-3.1,1.9-4.9,2.3c-2.6,0.5-5.2,0.6-7.8,1c-0.3,0-0.6,0-0.8,0.1c-1.6,0.2-1.7,0.3-1.7,1.9
				c-0.3,7.2-0.3,14.4,0,21.5c0.1,2.3,0.2,4.5,0.8,6.8c0.6,2.5,1.7,4.8,3.3,6.9c0.5,0.6,1,1.1,1.7,1.6c0.4,0.4,1.3,0.4,1.2,1.3
				c-1.3,1.8-2.6,3.5-4.2,5c-1.3,1.3-2.8,2.4-4.3,3.5c-0.9,0.6-1.7,0.6-2.6,0c-5-3-9-7-12.6-11.5c-3.2-4.1-5.3-8.7-6.8-13.6
				C93.1,87.6,93,87.4,92.7,87.3z"/>
			<path fill="#C1A790" d="M82.4,532.3c2.4,0.3,4.8,0,7.1-0.3c3.5-0.4,7-0.9,10.4-1.4c0.6-0.1,1.4,0,1.8-0.7c0.4,0,0.4,0.3,0.4,0.6
				c-0.2,4.1,0.4,8.2,0.2,12.3c0,0.5-0.1,1.1-0.3,1.6c0.4-1.6,0.9-3.2,1.4-4.8c0.9-3.2,1.9-6.3,2.6-9.6c0.1-0.5,0.2-0.9,0.8-1.1
				c1.4,0.7,2.7,1.4,4.2,2c4.1,1.6,8.2,3.2,12.5,4.2c-1.4,2.8-2.9,5.7-4.3,8.5c-1.8,3.6-3.5,7.2-5.3,10.8c-0.9,1.9-0.4,3.4,1.1,4.7
				c0.2,0.2,0.3,0.4,0.4,0.6c-0.4,0-0.8,0.1-1.1,0.3c-1.6,0.9-3.2,1.6-5.1,1.6c-1.2,0-2.4,0.8-3.6,1.2c-3.6,1.1-7.2,0.9-10.8,0
				c-1.4-0.3-2.7-0.9-4.1-1.3c0.1-2.7-0.5-5.4-1.4-7.9c-2.1-5.9-4.3-11.8-5.9-17.9C83.1,534.6,82.6,533.5,82.4,532.3z"/>
			<path fill="#030303" d="M122.4,103.4c-2.1-1.4-3.2-3.5-4.2-5.7c-1.9-4-1.9-8.3-1.9-12.5c-0.1-5.8,0-11.5-0.1-17.3
				c0-0.4,0.1-0.7,0.1-1.1c0.3-2.7,0.3-2.6,3-2.9c2.6-0.3,5.2-0.5,7.9-1c1.8-0.3,3.2-1.4,4.9-2c1.4,3.5,3.6,6.5,6.4,9
				c2.2,2,4.6,4,6.9,6c1.9,1.7,3.1,3.7,3.7,6.2c-0.5,0.5-0.5,0.5-1.3,0.7c0,0.3,0.3,0.4,0.4,0.7c0.1,0.3-0.2,0.5-0.3,0.8
				c0.2,0,0.1-0.3,0.3-0.4c0.4-0.7,0.6-0.2,0.8,0.1c0,0.5,0.3,0.8,0.8,0.8c0.3,0.5,0.3,1.1,0,1.7c-0.1,0.1-0.3,0.2-0.5,0.1
				c-0.4-0.2-0.5-0.7-1-0.9c0.3,1.1-1.2,0.8-1.2,1.7c-0.2,0.4,0,0.8-0.1,1.2c-0.3,0.4-0.2,0.8,0.1,1.2c0.3,0.9,0.5,1.8-0.8,2.1
				c-0.3,0.1-0.2,0.5-0.4,0.7c-0.2,0.2-0.4,0.4-0.7,0.5c-0.3,0.1-0.5-0.1-0.7-0.4c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.1,0-0.2,0-0.3
				c0-0.2,0-0.3,0.1-0.5c0.4-0.7,0.2-1.2-0.5-1.5c-0.3-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.4,0-0.5,0.1c-0.2,0.3-0.1,0.7-0.3,1
				c-0.3,0.2-0.5,0.1-0.7-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.4-0.1-0.7-0.3-1.1c-0.1-0.4-0.6-0.5-0.8-0.9c-0.4-0.5-0.3,0.2-0.5,0.2
				c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3,0,0.4,0.2,0.5c0.4,0.2,0.4-0.2,0.6-0.4c0.5-0.5,0.8-0.1,1.1,0.2c0.1,0.1,0.1,0.2,0.1,0.3
				c0,0.1,0,0.1,0,0.2c-0.2,0.4-0.4,0.8-0.6,1.1c-0.2,0.5-0.2,1.1-0.8,1.4c-0.4,0.3-1.2,0.5-0.3,1.1c-0.1,0.9,0.8,1.7,0,2.6
				c-0.5,0.2-1,0.2-1.4,0.4c-0.3,0-0.7-0.1-0.7-0.6c0.4-0.3,0.4-0.7,0.3-1.1c0-0.1,0-0.2,0.1-0.2c0-0.1-0.1,0-0.1,0
				c-0.1,0-0.3,0-0.4-0.1c-0.1-0.9-0.7-1.8,0.2-2.5c0.5-0.4,1.3,0,1.8-0.7c-0.3-0.2-0.7-0.2-1-0.3c-0.3,0-0.4,0.3-0.5,0.5
				c0,0.1-0.1,0.2-0.2,0.3c-1.4,0.7-1.5,1.5-0.4,2.7c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0,0.4,0,0.6,0.1c0.3,0.4-0.1,0.8-0.2,1.1
				c-0.2,0.3-0.3,0.6-0.7,0.5c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.1-0.2-0.1c0,0.1,0.1,0.1,0.1,0.1c0.2,0.2,0.3,0.5,0.7,0.3
				c0.5-0.2,0.9,0.1,1.2,0.4c0,0.1,0,0.2,0,0.3c-0.2,0.3-0.6,0.6-1,0.6c-0.7,0-1.5,0.2-2.2,0.2c-0.6,0-0.9-0.1-0.8-0.8
				c0.1-0.2,0.3-0.3,0.4-0.5c0.4-0.3,0.1-0.6,0-0.9c-0.3-0.5-0.5-0.9-1.1-0.4c-0.2,0.3,0,0.7-0.5,0.9c-0.2,0.1-0.4,0.2-0.6,0.2
				c-0.2,0-0.3,0-0.5,0c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.3-0.2-0.2c-0.2,0.2-0.1,0.4-0.2,0.6
				c-0.3,0.4-0.7-0.2-1.1,0c0.3,0.8,0.7,0.8,1.3,0.4c0.8-0.2,0.9,0.3,0.9,1c-0.1,0.3,0.1,0.6,0,0.9c-0.1,0.2-0.3,0.4-0.5,0.6
				c-0.2,0.2-0.4,0.4-0.6,0.4c-0.4,0-0.7-0.2-1-0.4c-0.6-0.8-1.3-0.2-1.9-0.1c-0.2,0.1-0.5-0.2-0.6,0.1c-0.6,0.3-0.7,1-1.1,1.4
				c-0.8,0.4-0.7-0.4-1-0.8c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.4-0.2-0.5-0.5-0.3c-0.4-0.3-0.9-0.4-1.5-0.6c0.1,0.5,0.4,0.7,0.6,0.9
				c0.3,0,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.1,0.4,0c0.2,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.2,0.6,0,0.9c-0.2,0.3-0.5,0.4-0.7,0.7
				c-0.2,0.2-0.2,0.6-0.4,0.8c-0.5,0-1.1,0-0.8,0.8C123.3,103.5,123,103.7,122.4,103.4z"/>
			<path fill="#C1A790" d="M56.7,222.4c0.8-4.2,2.5-8.2,3.3-12.4c0.1-0.2,0.2-0.4,0.4-0.5c0.9-0.4,1.8,0,2.7,0.1
				c2,0.4,3.9,1,5.9,1.5c0.3,0.1,0.6,0.2,0.8,0.5c2.1,0.8,4.2,1.3,6.2,2.3c4.8,2.4,7.5,6.3,8.5,11.4c0.1,0.6,0.6,0.5,0.9,0.9
				c-0.3,0-0.7,0-0.6,0.4c1,6.2-2.2,10.9-5.7,15.6c-1.8,2.5-3.6,5-6,7.1c-1.3,1.2-3,1.9-4.8,1.9c-1.2,0-2.3,0.4-3.4,0.4
				c-1.1,0-2-0.2-2.3-1.5c0-0.2-0.3-0.1-0.4-0.1c-0.7-2,0.2-3.7,1.1-5.3c1.9-3.6,3.9-7.2,5.9-10.8c0.6-1.1,1.4-2.2,1.9-3.4
				c1-2.3,0.3-4-2-4.9c-3.6-1.4-7.4-2.1-11.2-2.9C57.5,222.6,57.1,222.5,56.7,222.4z"/>
			<path fill="#0C0C0C" d="M77.7,12.6c0.1-0.4,0.4-0.6,0.7-0.9c0.3-0.1,0.5,0.1,0.7,0.3c0.1,0.1,0.3,0.3,0.5,0.4
				c0.6,0.3,0.3-0.4,0.5-0.5c0.1-0.2,0.3-0.3,0.6-0.2c0.3,0.2,0.5,0.5,0.7,0.7c0.4,0.5,0.9,0.6,1.4,0.4c0.2-0.1,0.5-0.2,0.7-0.2
				c0.2,0.1,0.5,0,0.7,0c0.1,0,0.2,0.1,0.3,0.1c0.2,0.2,0.4,0.3,0.5,0.6c0,0.3-0.2,0.4-0.5,0.6c-0.7,0.5-0.6,1.2-0.6,1.9
				c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0-0.3,0-0.4-0.1c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.2-0.2-0.4-0.2-0.6
				c-0.1-0.5,0.6-0.6,0.5-1.1c0-0.1,0-0.2,0-0.2c-0.2,0-0.2,0.1-0.4,0.1c-0.3,0.2-0.8-0.3-1.1,0.2c-0.1,0.2-0.2,0.4-0.4,0.5
				c-0.1,0-0.2,0-0.3,0c-0.5-0.9-1.3-1.5-2.2-1.9C77.7,12.9,77.6,12.8,77.7,12.6z"/>
			<path fill="#0F0F0E" d="M77.3,28.1c0,0.1,0,0.2,0,0.3c0.3,0.7,0.2,1.5-0.1,2.2c-0.1,0.2-0.2,0.5-0.4,0.5
				c-0.7,0.2-1.4,0.4-2.1,0.8c-0.4,0.3-0.9,0-0.9-0.5c0-0.3,0.1-0.6-0.1-0.9c0-0.7-0.3-1.4,0-2.2c0.1-0.1,0.2-0.1,0.2-0.2
				c0.3-0.1,0.6-0.3,0.9-0.4c0.2,0,0.4-0.1,0.4-0.3c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.2
				c0.3,0.3,0.5,0.7,0.3,1.1C76.6,28.2,76.9,27.9,77.3,28.1z"/>
			<path fill="#504A45" d="M73.7,30.5c0.3,0.2,0.3,0.5,0.3,0.7c0,0.2,0,0.5,0.3,0.6c0.2,0,0.5,0.1,0.6-0.3c0.1-0.3,0.5-0.5,0.8-0.5
				c1.6,0.1,1.6-0.8,1.5-2c0-0.2,0.1-0.4,0.2-0.7c0.5-0.6,0.8,0.2,1.2,0.2c0,0,0.1,0.1,0.1,0.1c-0.3,1-0.3,1,0.6,1.3
				c0.1,0.2,0.1,0.3,0,0.5c-0.3,0.4-0.6,0.8-1.1,0.9c-0.9,0.2-0.5,0.9-0.6,1.4c0,0.1,0.3-0.1,0.5-0.2c0.6,0.1,1.1,0.3,0.7,1
				c-0.2,0-0.3,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.1,0-0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3-0.2-0.4-0.4-0.5-0.7
				c-0.1-0.5-0.4-0.4-0.7-0.2c-0.4,0.2-0.8,0.3-1.2,0.2c-0.4-0.2-0.5,0.1-0.7,0.3c-0.1,0-0.2-0.1-0.3-0.2c0-0.2,0-0.3,0.1-0.5
				C73.7,31.7,73.8,31.1,73.7,30.5z"/>
			<path fill="#0C0C0C" d="M146.4,97.2c0,0.2,0,0.3,0,0.5c-0.5,0.4-1,0.4-1.6,0.4c-0.3-0.1-0.5-0.3-0.9-0.1
				c-0.3,0.1-0.6,0.2-0.8-0.2c-0.1-0.6,0.8-1,0.2-1.7c-0.5-0.1-0.4-0.7-0.7-0.9c-0.2-0.1-0.4-0.2-0.4-0.4c0-0.2-0.1-0.4-0.3-0.7
				c0-0.1,0-0.2,0-0.3c0-0.7,0.4-1.1,1.1-1c0.3-0.1,0.5-0.5,1-0.4c0.3,0.1,0.4,0.3,0.5,0.6c0,0.4-0.2,0.8,0.4,0.9
				c0.3,0.3,0.2,0.7,0.3,1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.2,0.4,0.2,0.6c-0.1,0.4-0.3,0.7-0.3,1.1c0.1,0.4,0.2-0.1,0.2,0
				C145.9,96.7,146.2,96.9,146.4,97.2z"/>
			<path fill="#100F0F" d="M84.7,7.7c0.2,0.2,0.4,0.3,0.6,0.5c0.6,0.7-0.4,0.9-0.4,1.4c-0.1,0.6-0.6,0.9-0.9,1.4
				c-0.2,0.2-0.4,0.4-0.6,0.6c-0.4,0.4-0.9,0.6-1.5,0.4c-0.4-0.3-0.8-0.7-0.9-1.3c0.3-0.6-0.4-1.2-0.1-1.8c0.2-0.2,0.3-0.3,0.5-0.5
				c0.4-0.1,0.5,0.3,0.7,0.5c0.1,0.3,0.4,0.2,0.6,0.2c0.6,0,1.1-0.1,1.4-0.7C84.2,8.1,84.5,7.9,84.7,7.7z"/>
			<path fill="#0C0C0C" d="M94.8,3.6c-0.2,0-0.4,0-0.7,0c-0.3,0.1-0.3,0.6-0.7,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.6,0.2-1,0.6-1.7,0.4
				c-0.2-0.1-0.3-0.3-0.5-0.4c-0.4-0.6-0.4-1.1,0-1.7c0.1-0.2,0.3-0.2,0.5-0.2c0.4-0.2,1,0.2,1.2-0.4c0.3-0.1,0.6-0.2,0.9-0.3
				c0,0.2,0,0.3,0,0.5c-0.2,0.5,0.3,0.5,0.5,0.7l0,0C94.3,3.1,95,3,94.8,3.6z"/>
			<path fill="#F1E9DA" d="M19.2,158.1c-0.2-0.1-0.5-0.2-0.7-0.2c-0.3-0.3-0.3-0.6-0.5-1c0.2-0.5,0.3-1.1,1-1.2
				c0.4-0.1-0.1-0.9,0.4-1.2c0,0.2,0,0.3,0,0.5c0,0.4-0.1,0.8,0.3,1.1c0.1,0.1,0.3,0,0.4-0.1c0.4-0.4-0.2-0.7-0.1-1.1
				c0.3,0.1,0.5,0.1,0.8,0.2c0.3,0.3,0.4,0.6,0.2,1c-0.8,0.6-0.8,1.5-0.7,2.4c-0.1,0.1-0.2,0.1-0.2,0c-0.1,0-0.2,0-0.3,0
				c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.3-0.3-0.3C19.2,157.7,19.4,158,19.2,158.1z"/>
			<path fill="#0C0C0C" d="M76.3,15.1c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.4,0.5-0.3,0.8-0.1c0.1,0.1,0.2,0.3,0.5,0.2
				c0.1-0.1,0.2-0.3,0.3-0.5c0.5-0.4,0.9-0.4,0.9,0.4c0,0.3-0.1,0.5,0,0.8c0,0.1,0,0.2,0,0.3c-0.3,0.6-1.2,0.6-1.2,1.4
				c-0.4,0.5-0.8,0.7-1.4,0.3c-0.3-0.3-0.7-0.6-0.7-1.1C76.7,16.2,76.1,15.4,76.3,15.1z"/>
			<path fill="#090909" d="M74,32.9c0.1-0.1,0.1-0.1,0.2-0.1c0.2-0.1,0.4-0.2,0.6-0.2c0.5,0.2,0.9,0.1,1.1-0.5
				c1.2-0.5,1.1,0.6,1.3,1.2c-0.6,0.6-1.4,0.1-2,0.5c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.2,0.3,0.1,0.5l0,0
				c-0.2,0.3-0.5,0.5-0.9,0.6c-0.3-0.2-0.4,0.4-0.7,0.2c-0.5-0.4-0.5-1-0.9-1.5c-0.1-0.1-0.1-0.1,0-0.2C73.8,33.4,73.7,33.1,74,32.9
				z"/>
			<path fill="#171716" d="M81.1,10.5c0.1,0.5,0.6,0.7,0.7,1.2c-0.4,0.7-0.8,0.3-1.2,0c-0.2,0-0.4,0.1-0.5,0.3
				c-0.2,0.2-0.3,0.7-0.7,0.4c-0.2,0-0.4-0.1-0.5-0.2c0.2-0.4-0.4-0.8,0-1.2c0.3-0.7,0.9-1.2,1.5-1.7c0.8,0.1,0.7,0.5,0.4,1.1
				c0,0.1-0.1,0.1-0.1,0.2C80.9,10.5,81,10.5,81.1,10.5z"/>
			<path fill="#59534D" d="M145.7,92.6c0.2-0.4,0.1-0.8,0.9-0.9c0.9-0.1,0.2-1.1,0.3-1.7c0.6,0,0.7,0.5,0.8,0.9c0,0.5,0.1,1,0.4,1.5
				c-0.1,0.4-0.4,0.7-0.7,1c-0.5,0.3-1,0.4-1.6,0.1C145.6,93.2,145.5,93,145.7,92.6z"/>
			<path fill="#4F4944" d="M74.4,20.3c0-0.3,0-0.7,0.2-1c0.6-0.2,1,0.1,1.3,0.5c-0.4-0.5-0.8-1.1,0.2-1.3c0.2,0,0.4,0.2,0.6,0.2
				c0.2,0,0.4,0,0.6,0c0.5,0.2,0.5,0.5,0.4,0.9c-0.2,0.7-0.9,0.7-1.3,1.1c-0.3,0.2-0.4,0.6-0.8,0.7c-0.5-0.1-0.6-0.7-1.1-0.9
				C74.5,20.5,74.4,20.4,74.4,20.3z"/>
			<path fill="#4F4A44" d="M75.6,41.8c-0.3,0.5-0.5,1.2-1.3,1.2c-0.3,0-0.7,0.8-1,0c-0.3-0.6,0.3-0.7,0.8-0.8
				c-0.4-0.3-0.8-0.1-1.1-0.2c-0.2-0.4,0-0.7,0.2-1c0,0.2,0.1,0.4,0.1,0.6c0.3,0.4,0.4-1.8,0.7,0c0.1,0.3,0.4,0.2,0.6,0.1
				c0.2-0.2,0.5-0.3,0.7-0.1C75.6,41.4,75.7,41.5,75.6,41.8z"/>
			<path fill="#504A45" d="M75.4,35c0.3-0.1,0.6-0.5,1-0.4c0.1,0.2,0.3,0.5,0,0.7c-0.6,0.5-0.6,1.2-0.7,1.9c-0.2,0.1-0.3,0.1-0.5,0
				l0,0C75,37,74.8,37.1,74.6,37c-0.2,0-0.4-0.3-0.5,0c0,0.1-0.1,0.1-0.2,0.2c-0.2-0.8-0.3-1.5,0-2.3c0.3,0.2,0.6,0.3,1,0.4
				C75.7,36,75.1,35,75.4,35z"/>
			<path fill="#57514C" d="M90.1,4.4c-0.5,0.1-1,1.4-1.5-0.1c-0.2-0.6-0.8,0.5-1.2,0c1-0.7,2-1.1,3.2-1.4
				C90.7,3.4,90.4,3.9,90.1,4.4z"/>
			<path fill="#1F1F1F" d="M72,44.8c0.2,0.3,0.5,0.5,0.6,0.8c0.3,0.5,0.1,0.9-0.4,1.2c-0.1,0-1.1,0.1-0.3,0.7c0,0-0.1,0.4-0.2,0.5
				c-0.2,0.2-0.4,0-0.4-0.2c-0.1-0.3-0.4-0.3-0.7-0.3c0.2-0.4,0.5-0.8,0.7-1.2c0.4-0.1,0.3-0.3,0.2-0.5C71.6,45.5,71.8,45.1,72,44.8
				z"/>
			<path fill="#504B46" d="M84.3,8.4c-0.2,0.9-0.9,1-1.7,1c-0.2-0.2-0.5,0-0.7-0.2c-0.2-0.2-0.2-0.6-0.5-0.7
				c0.2-0.3,0.4-0.6,0.7-0.7c0.4,0.2,0.8,0.4,1.3,0.3c0.1,0,0.2,0,0.3,0C83.9,8.1,84.1,8.2,84.3,8.4z"/>
			<path fill="#524E49" d="M145.5,96.9c0,0.2,0.2,0.7-0.2,0.3c-0.5-0.5-0.4-1.2,0.2-1.7c-0.1-0.4,0.5-0.5,0.4-0.9
				c0.2-0.3,0.5-0.6,0.9-0.2c-0.5,0.7-0.3,1.6-0.3,2.4C146.1,96.9,145.7,96.6,145.5,96.9z"/>
			<path fill="#5D5750" d="M75.9,16.3c0.2,0.3,0.5,0.6,0.7,1c0.1,0.1,0.1,0.3,0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.3
				c-0.2,0.1-0.3,0.4-0.5,0.6c-0.3,0.3-0.6,0.8-1.1,0.3c0.3-0.9,0.6-1.8,1-2.6C75.7,16.2,75.8,16.2,75.9,16.3z"/>
			<path fill="#FAF7F3" d="M38.9,124.8c0.9,0.8,1.6,0.1,2.4-0.2c0.1,0.4,0.1,0.8,0.2,1.3c-0.8-0.4-1.4-0.4-1.7,0.7
				c-0.4-0.9-0.6-1.6-1.4-1.4C38.5,125,38.7,124.9,38.9,124.8z"/>
			<path fill="#F6F2EA" d="M15.4,161.5c0.1,0.8,1.1,0.6,1.4,1.4c-0.7,0.2-1.3,0.4-1.4,1.3c-0.1,0.1-0.2,0.1-0.3,0
				c0-0.4-0.3-0.8,0-1.2l0.1,0l-0.1,0c-0.2-0.2-0.3-0.3-0.5-0.5C14.7,162,15,161.7,15.4,161.5z"/>
			<path fill="#534E49" d="M74.2,37.4c0.9,0.9,0.2,1.7-0.2,2.6c-0.2,0.4-0.3-0.1-0.5-0.1c0-0.8,0.1-1.7,0.4-2.4
				C74,37.3,74.1,37.3,74.2,37.4z"/>
			<path fill="#171614" d="M146.4,98.4c0,0.1,0,0.2,0,0.2l-0.1,0c-0.3,0.5,0.1,1.7-1.2,1.3c-0.4-0.2-0.7-0.4-0.8-0.9
				c0-0.3,0.1-0.6,0.5-0.6c0.1-0.1,0.1,0.3,0.3,0C145.5,97.9,145.9,97.8,146.4,98.4z"/>
			<path fill="#58534D" d="M74.9,27.8c-0.3,0.3-0.5,0.5-0.9,0.5c-0.2-0.3-0.1-0.7-0.4-0.9c0.1-0.6,0.1-1.1,0.2-1.7
				c0.4-0.3,0.7-0.2,0.8,0.3c0.1,0.3,0.1,0.7,0.3,1C74.8,27.2,75,27.5,74.9,27.8z"/>
			<path fill="#3F3C39" d="M73.7,25.2c0-0.8-0.1-1.5,0.2-2.2c0.2,0.2,0.2,0.8,0.7,0.5c0.1,0.1,0.2,0.2,0.3,0.4
				c0.1,0.5,0.1,1.1,0,1.6c-0.1,0.1-0.2,0.1-0.3,0.1C74.3,25.5,74,25.4,73.7,25.2z"/>
			<path fill="#1C1C1B" d="M148.8,84.2c-0.2,0-0.4-0.7-0.5,0c-0.2,0.1-0.4,0.4-0.7,0.2c-0.1-0.4,0.4-0.5,0.5-0.8
				c-0.2-1.2,0.9-0.6,1.3-1c0.3,0.4,0.2,0.8,0.2,1.2c0.1,0.2,0.1,0.4,0,0.5C149.2,84.6,149,84.4,148.8,84.2z"/>
			<path fill="#F4EEE4" d="M12.3,166.5c0.5,0,1,0.1,1.3-0.6c0.3-0.7,0.8,0.2,1.2,0c-0.8,0.7-1.5,1.5-2.7,1.1
				C12,166.8,12.1,166.6,12.3,166.5z"/>
			<path fill="#1B1A19" d="M149.3,89.6c0.1,0.6-0.3,1.1-0.5,1.6c-0.3,0.2-0.6,0-0.9-0.1c-0.1-0.1-0.1-0.2-0.2-0.3
				c-0.1-0.4,0-0.7,0.3-0.9c0.4-0.2,0.8-0.3,0.9-0.9c0.1,0,0.2,0,0.3,0C149.5,89.1,149.5,89.3,149.3,89.6z"/>
			<path fill="#F1E8DA" d="M41.3,123.1c0-0.4,0.2-0.9-0.5-1c0.5-0.3,0.4-1,0.7-1.4c0.2,0.3,0.3,0,0.5,0c0.1,0.2,0.1,0.3,0,0.5
				c-0.9,0.3-0.3,0.7-0.1,1.1c0.1,0.3,0.7-0.4,0.6,0.2c-0.1,0.5-0.5,0.7-1,0.6C41.5,123.2,41.4,123.2,41.3,123.1z"/>
			<path fill="#F4EEE4" d="M11.8,167.5c0.1,0.4,0.1,0.3,0.7,0.4c0.3,0,0.6,0.3,0.7,0.9c-0.8-0.5-1.4,0.6-2.2-0.1
				C11.1,168.2,11.5,167.9,11.8,167.5z"/>
			<path fill="#534D48" d="M149.4,82.8c-0.2,0.6-1.3,0.1-1.3,1c-0.3-0.1-0.6-0.4-0.8-0.7c-0.1-0.3-0.2-0.8,0.5-0.5
				c0.5,0.3,0.9,0,1.3-0.2C149.3,82.4,149.3,82.6,149.4,82.8z"/>
			<path fill="#1A1918" d="M74.7,19c0.5,0,0.8-0.3,1.2-0.5c0.3-0.1,0.5-0.2,0.5,0.2c-0.3,0.2-1.2,0.2-0.2,0.8
				c0.4,0.2,0.3,0.3-0.2,0.9c-0.2-0.6-0.7-0.9-1.3-1C74.6,19.2,74.7,19.1,74.7,19z"/>
			<path fill="#141414" d="M146.7,94.3c-0.4-0.1-0.6,0.1-0.8,0.4c-0.1,0.1-0.2,0.1-0.3,0c-0.2-0.3-0.2-0.6-0.2-0.9
				c0.1-0.3,0.1-0.5,0.5-0.5c0.5,0.1,1,0.2,1.5,0.1C147.2,93.7,147,94,146.7,94.3z"/>
			<path fill="#262524" d="M149.3,89.6c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.2-0.3-0.3-0.4-0.6c0-0.5,0.4-0.9,0.4-1.4
				c0-0.2,0.1-0.3,0.3-0.4c0.2,0,0.3,0,0.5,0C149.8,87.6,149.7,88.6,149.3,89.6z"/>
			<path fill="#4E4843" d="M72.7,42.7c0.1,0.5,0.3,0.9,0.8,1.1c0.3,0.1,0.5,0.3,0.3,0.6c-0.1,0.2-0.4,0.3-0.6,0.3
				c-0.4,0.1-0.5-0.2-0.5-0.5c0-0.3,0.1-0.6-0.2-0.8C72.5,43.2,72.5,42.9,72.7,42.7z"/>
			<path fill="#1A1919" d="M96.5,0.9c-0.4,0.3-0.4,0.9-1.1,1c-0.6,0.1-0.9,0-1.1-0.5C95,1.2,95.7,0.9,96.5,0.9z"/>
			<path fill="#544F4B" d="M98.7,0.5c-0.2,0.1-0.4,0.2-0.5,0.4c-0.6,0.8-1.1,0.9-1.7,0C97.2,0.6,97.9,0.5,98.7,0.5z"/>
			<path fill="#21211F" d="M74.9,35.5c-0.5,0.1-1,0.4-1-0.5c0-0.4,0-0.8,0-1.1c0.6,0.2,0.7,0.9,1,1.4C75,35.3,75,35.4,74.9,35.5
				C74.9,35.5,74.9,35.5,74.9,35.5z"/>
			<path fill="#151515" d="M73.7,25.2c0.3,0.1,0.6,0.2,1,0.2c0.3,0.6,1,1.1,0.7,1.9l0,0c-0.3,0-0.6,0.3-0.8-0.2
				c-0.2-0.3-0.6-0.6-0.4-1.1c0.1-0.5-0.3-0.3-0.5-0.4C73.7,25.5,73.7,25.3,73.7,25.2z"/>
			<path fill="#1D1D1C" d="M77.3,14.6c-0.2-0.2-0.5-0.1-0.7,0c0.1-0.5,0.3-0.9,0.7-1.2c0.1,0,0.1,0,0.2,0c0.3,0.1,0.5,0.2,0.7,0.4
				c0.1,0.2,0,0.4,0.2,0.6c0,0.2-0.1,0.4-0.3,0.5C77.7,15,77.5,14.7,77.3,14.6z"/>
			<path fill="#494440" d="M90.1,4.4c0.1-0.5,0.3-1,0.4-1.5c0.2-0.1,0.3-0.2,0.5-0.3c0,0.6,0,1.1,0,1.7C90.7,4.3,90.4,4.3,90.1,4.4z
				"/>
			<path fill="#5E5852" d="M146.4,98.4c-0.6-0.4-1,0.1-1.5,0.3c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.1,0-0.2,0.1-0.3c0.6,0,1.1-0.5,1.7-0.3
				C146.7,97.9,146.5,98.1,146.4,98.4z"/>
			<path fill="#534E48" d="M81.1,10.5c-0.3,0.2-0.3,0.7-0.7,0.9c-0.5-0.7,1.2-1.3,0-2c0.1-0.3,0.2-0.4,0.4-0.5
				C80.9,9.4,81.8,9.9,81.1,10.5z"/>
			<path fill="#474440" d="M74,22.1c0.1-0.6,0.1-1.2,0.4-1.7c0,0,0.1,0.1,0.1,0.1c0.9,0.5,0.9,1.1,0.1,1.7
				C74.4,22.3,74.2,22.3,74,22.1z"/>
			<path fill="#262624" d="M83.8,8.2c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.9-0.3-1-0.8c0.2-0.3,0.4-0.6,0.8-0.7c0.5,0.3,0.5,0.7,0.5,1.2
				C83.8,8,83.9,8,83.8,8.2C83.8,8.1,83.8,8.2,83.8,8.2z"/>
			<path fill="#474441" d="M148.8,84.2c0.3-0.1,0.7,0.4,0.7-0.3c0.4,0.3,0.1,0.7,0.2,1c-0.3,0.1-0.5,0.5-0.8,0.2
				C148.5,85,148.9,84.6,148.8,84.2z"/>
			<path fill="#070707" d="M82.6,7.4c0.4,0.2,0.8,0.3,1,0.8c-0.7,0.3-1.2,0.4-1.5-0.5C82.3,7.6,82.4,7.5,82.6,7.4z"/>
			<path fill="#030303" d="M148.1,92.4c-0.7-0.3-0.9-0.8-0.5-1.4c0.1-0.1,0.2-0.1,0.2,0l0,0c0.4,0.2,0.5,0.6,0.6,1
				C148.3,92.1,148.2,92.2,148.1,92.4z"/>
			<path fill="#FBF9F5" d="M30.7,140.1c-0.3,0.6,0.3,0.9,0.2,1.4l0,0c-0.5-0.2-1-0.5-1-1.2C30.3,140.3,30.5,140.2,30.7,140.1z"/>
			<path fill="#5E5A55" d="M92.7,1.9c0.1,1.6-0.8,0.5-1.2,0.4C91.8,2,92.2,1.9,92.7,1.9z"/>
			<path fill="#5C5A57" d="M75.9,16.3c-0.1,0-0.1,0-0.2,0c0.2-0.4,0.4-0.8,0.6-1.2c0.1,0.2,0.1,0.5,0.2,0.7
				C76.8,16.5,76.3,16.4,75.9,16.3z"/>
			<path fill="#2D2B29" d="M74,22.1c0.2,0,0.4,0,0.6,0c0.2,0.1,0.3,0.2,0.4,0.4c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.1,0.2,0.1,0.3
				c0,0.2-0.1,0.3-0.3,0.4c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.3-0.5-0.5-0.7
				C73.9,22.2,73.9,22.1,74,22.1z"/>
			<path fill="#4B4844" d="M79,11.1c-0.3,0.4,0.8,0.8,0,1.2c-0.2-0.2-0.4-0.4-0.5-0.5C78.5,11.4,78.7,11.2,79,11.1z"/>
			<path fill="#2B2A29" d="M85.2,5.7c-0.1,0-0.2,0-0.3,0c0.3-0.5,0.8-0.6,1.2-0.7C86.1,5.2,86,5.5,86,5.7c0.1,0.3-0.1,0.3-0.4,0.4
				C85.4,6,85.3,5.9,85.2,5.7z"/>
			<path fill="#393734" d="M148.4,91.9c-0.4-0.2-0.4-0.7-0.6-1c0.3,0,0.6,0.1,0.9,0.2C148.8,91.5,148.7,91.7,148.4,91.9z"/>
			<path fill="#030303" d="M71.5,45.8c0.2,0,0.4-0.2,0.5,0.1c0.1,0.3-0.1,0.5-0.3,0.7c-0.3,0.2-0.3-0.1-0.3-0.2
				C71.3,46.1,71.3,45.9,71.5,45.8z"/>
			<path fill="#2B2B2B" d="M74.7,23.5c-0.7,0.4-0.8,0-0.8-0.6c0-0.1,0-0.1,0-0.2c0.3-0.2,0.5,0.1,0.7,0.2l0,0
				C74.8,23.2,74.8,23.3,74.7,23.5z"/>
			<path fill="#2B2B29" d="M73.5,27.4c1-0.1,0.3,0.6,0.4,0.9c-0.1,0-0.2,0-0.2,0C73.7,28,73.6,27.7,73.5,27.4z"/>
			<path fill="#030303" d="M145.5,96.9c0.2-0.6,0.5-0.5,0.9-0.2c0,0.2,0,0.3,0,0.5C146.1,97.1,145.8,97,145.5,96.9z"/>
			<path fill="#4B4641" d="M74.2,37.4c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.1,0-0.2c0,0,0.1-0.1,0.1-0.1c0.2-0.3,0.3-0.4,0.6-0.2
				c0.2,0,0.4,0.1,0.5,0.3C74.8,37.3,74.5,37.3,74.2,37.4z"/>
			<path fill="#FDFCFA" d="M18,156.9c0.3,0.2,0.4,0.6,0.5,1c-0.5,0.1-0.4-0.6-0.7-0.7C17.9,157.1,18,157,18,156.9z"/>
			<path fill="#F3EDE2" d="M21.2,152.9c-0.2-0.1-0.4-0.2-0.3-0.5c0.1-0.1,0.2-0.2,0.4-0.1C21.5,152.5,21.2,152.7,21.2,152.9
				C21.2,152.9,21.2,152.9,21.2,152.9z"/>
			<path fill="#4C4743" d="M74.7,23c-0.2-0.1-0.5-0.2-0.7-0.2c0-0.2,0-0.3,0-0.5C74.4,22.3,74.5,22.7,74.7,23z"/>
			<path fill="#1D1D1C" d="M77.7,12.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0,0.3c-0.2,0.2-0.4,0.3-0.7,0.2c0,0-0.1-0.1-0.1-0.1
				C77.3,13,77.4,12.8,77.7,12.6z"/>
			<path fill="#58514B" d="M74,32.9c0,0.2-0.1,0.5-0.1,0.7c-0.1-0.5-0.2-0.9,0-1.4C73.9,32.5,74,32.7,74,32.9z"/>
			<path fill="#060606" d="M127.7,99.2c0.1-0.3,0.3-0.2,0.4-0.1c0.4-0.1,0.9-0.3,1.1,0.3c0.1,0.4,0.5,0.2,0.7,0.3
				c0.4,0.8,1.4,0.7,1.9,1.3c-0.1,0.3-0.4,0.4-0.5,0.6c-0.3,0.4-0.6,0.7-1.1,0.8c-0.1,0,0-0.2-0.1,0c0,0.1,0.1,0.1,0.1,0
				c0.4,0,0.6,0.3,0.6,0.7c-0.3,0.3,0.2,0.4,0.2,0.7c0,0.4-0.4,0.4-0.5,0.7c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.2-0.4,0.2-0.6,0
				c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.3-0.1-0.2-0.2,0c-0.1,0.4-0.1,0.8-0.2,1.2c-0.3,0.6-0.6,1.1-1.4,0.7c-1.6-0.5-2.9-1.5-4.3-2.2
				c-0.2-0.5-0.9-0.6-1.2-1c0.6,0.1,0.8-0.3,1-0.7c-0.1-0.3,0.1-0.3,0.3-0.3c0.3,0,0.6-0.1,0.7-0.4c-0.2-0.3-0.4-0.7,0.3-0.7
				c0.4,0,0.7-0.2,0.7-0.7c0.1-0.3,0.2-0.5,0-0.8c0.1-0.1,0.3-0.1,0.5,0c0.3,0.2,0,1,0.8,0.8C127.9,100.9,127.4,99.7,127.7,99.2z"/>
			<path fill="#FEFEFE" d="M117.3,145.9c1.4,0,2.5,1.2,2.5,2.7c0,1.4-1.2,2.6-2.7,2.6c-1.3,0-2.6-1.3-2.6-2.6
				C114.5,147.1,115.8,145.9,117.3,145.9z"/>
			<path fill="#FEFEFE" d="M117.9,122.7c0,1.5-1.1,2.6-2.6,2.6c-1.4,0-2.7-1.2-2.7-2.6c0-1.2,1.4-2.6,2.7-2.6
				C116.8,120,117.9,121.1,117.9,122.7z"/>
			<path fill="#FEFEFE" d="M120.5,176.9c0,1.4-1.1,2.6-2.5,2.6c-1.4,0-2.7-1.3-2.7-2.7c0-1.3,1.4-2.6,2.7-2.6
				C119.4,174.2,120.5,175.4,120.5,176.9z"/>
			<path fill="#5D564F" d="M144.5,99.1c0.2,0.2,0.5,0.5,0.7,0.7c0,0.1,0,0.1,0,0.2c0,0.5-0.4,0.6-0.6,1c-0.4,0.6-0.1,0.7,0.5,0.6
				c0.2,0,0.5,0,0.6,0.2c-0.2,1-0.8,1.7-1.4,2.4c-0.3,0-0.4-0.3-0.5-0.6c0-0.3,0.2-0.5,0.2-0.8c0-0.2-0.1-0.1-0.1,0
				c0.2,0.3-0.2,0.6,0,0.9c-0.3,0.3-0.4,0.6,0,0.9c-0.4,0.4-0.8,0.8-1.3,1.1c-0.2-0.1-0.2-0.4-0.5-0.3c-0.1,0.7-0.5,0.8-1.1,0.4
				c-0.5-0.1-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3-0.2-0.4-0.6-0.7-0.8c-0.3-0.2-0.5-0.4-0.3-0.8c0-0.1,0.1-0.2,0.2-0.2
				c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.5-0.1,0.7-0.2c0.1,0,0.2,0,0.3-0.1c0.5-0.2,0.5-0.7,0.6-1.1c0.4-0.3,0-0.7,0.1-1
				c0.2-0.4,0.6-0.6,1-0.6c0.2,0,0.4,0.1,0.6,0.3c0.1,0.2,0.2,0.5,0.3,0.8c0.2-0.3-0.1-0.6,0.1-0.9c0-0.2,0.1-0.4,0.3-0.6
				c0.7-0.6,0.7-1.4,0.9-2.2C143.8,99,144.1,98.8,144.5,99.1z"/>
			<path fill="#56504A" d="M138.1,107.5c-1.1,0.5-2.3,0.4-3.4,0.4c-0.2-0.3-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.2-0.8
				c0.2-0.2,0.4-0.1,0.6-0.1c0.9,0.2,0.8-0.3,0.6-0.9c0-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.1-0.4-0.1-0.6c0.3-0.6,0.7-0.8,1.2-0.2
				c0.3,0.4,0.8,0.5,1,0.9c0.2,0.6,0,1.1-0.4,1.6c-0.4,0.6,0,0.7,0.3,0.9C138,107.3,138,107.4,138.1,107.5z"/>
			<path fill="#F2EBDF" d="M16.3,169.9c0.7-0.3,1.2,0,1.5,0.7c0.2,0.4,0.2,0.9,0.7,1.1c0.4,0.2,0.2,0.8-0.1,1.1
				c-0.2,0.2-0.6,0.4-0.9-0.2c-0.2-0.3-0.6-0.1-1-0.1c-0.1-0.3,0-0.5,0.2-0.7c0-0.2-0.2-0.4-0.1-0.6c0.2-0.6,0.8-1.3-0.5-0.9
				c0-0.2,0-0.3-0.1-0.5C16.2,169.8,16.3,169.8,16.3,169.9z"/>
			<path fill="#FBF8F5" d="M37.6,131.4c1,0.5,1.7,0.2,2.4-0.6c0.2-0.3,0.5-0.2,0.7,0c0.2,0.3-0.2,0.4-0.3,0.5
				c-0.6,0.5-0.7,1.5-1.4,1.9c-0.3,0.2-0.4,0.6-0.9,0.4c-0.4-0.3-0.4-0.8-0.3-1.1C38.1,132.2,37.9,131.8,37.6,131.4z"/>
			<path fill="#F7F2EB" d="M34.3,147.9c-0.2,0.5-0.6,0.5-0.7,0.9c-0.1,0.6-0.4,0.9-1.1,0.4c-0.5-0.4-1.1,0-1.2,0.7
				c-0.1,0.4-0.1,0.8-0.6,0.9c0,0-0.1-0.2-0.2-0.3c0.5-0.4-0.5-1,0.3-1.5c0.7-0.4,1.5-0.5,2-1.3C33.1,147.3,33.7,147.8,34.3,147.9z"
				/>
			<path fill="#F8F4EE" d="M49.2,127.9c-1,0-1.9,0.3-2.8,0.5c-0.5,0.1-0.6,0.6-0.3,0.7c1,0.2,0.2,0.6,0.1,0.7
				c-0.4,0.4-0.6,0.2-0.7-0.3c0-0.4-0.3-0.2-0.5-0.2c-0.4,0-0.3-0.3-0.2-0.4c0.6-0.5,1.1-1.2,2.1-1.1c0.5,0,0.8-0.2,1-0.5
				c0.6-0.7,1,0.3,1.5,0C49.4,127.5,49.3,127.7,49.2,127.9L49.2,127.9z"/>
			<path fill="#F6F1E8" d="M32.2,157.5c0,0.1,0,0.4-0.1,0.4c-0.4,0.2,0.1,0.9-0.4,0.8c-0.4,0-0.9-0.4-1.2-0.7
				c-0.3-0.4,0.1-0.6,0.4-0.6c0.5-0.1,0.9-0.4,1.1-0.9c0.2-0.3,0.5-0.6,0.9-0.6c0.1,0,0.3,0.1,0.3,0.3c-0.1,0.5-0.7,0.2-1,0.5
				c0.3,0.6,0.8,0.5,1.3,0.3C33.2,158.1,33.2,158.1,32.2,157.5z"/>
			<path fill="#262524" d="M127.9,106.6c0.8,0,1-0.6,1.2-1.2c0.1-0.2,0-0.4,0-0.6c0.1-0.3,0.1-0.8,0.6-0.8c0.5,0.1-0.1,0.6,0.2,0.9
				c0,0-0.1,0.1-0.1,0.1c-0.3,0.3-0.1,0.5,0.2,0.6c0.4,0,0.9,0.3,1.3,0c0.1,0,0.2,0,0.3,0c0.2,0.1,0.4,0.2,0.5,0.4
				c0,0.8-0.7,0.9-1.1,1.3c-0.2,0.1-0.4,0.2-0.6,0.1l0,0c-0.4-0.6-1.1-0.3-1.7-0.5C128.4,106.8,128.1,106.8,127.9,106.6z"/>
			<path fill="#F9F5F0" d="M22.1,156c1,1,1.2,2.2,1.5,3.5c0.1,0.3-0.2,0.4-0.4,0.5c-0.4,0.1-0.3-0.2-0.4-0.4
				c-0.2-0.3,0.2-0.9-0.4-0.9c0.8-1.2-1-1.5-1-2.5c0-0.1,0-0.2,0-0.2C21.6,155.8,21.9,155.8,22.1,156z"/>
			<path fill="#F5EFE5" d="M27.4,162.2c0.4,0,0.8,0,0.9,0.4c0.1,0.4-0.4,0.4-0.6,0.6c-0.6,0.3-1,0.8-1.5,1.1
				c-0.7,0.4-1.2,0.6-1.6-0.3c-0.1-0.2-0.4-0.2,0-0.4c0.2-0.1,0.4-0.4,0.5,0c0.2,0.7,0.5,0.4,0.9,0.2
				C26.5,163.3,27,162.9,27.4,162.2L27.4,162.2z"/>
			<path fill="#F0E8D8" d="M18,167.8c0-0.2,0-0.5,0-0.7c-0.8-1,0.1-1.3,0.7-1.7c0.2,0.2,0.5,0.3,0.6,0.5c0.7,1,0.5,1.7-0.8,2.1
				C18.3,168,18.5,167.2,18,167.8z"/>
			<path fill="#FBF9F5" d="M29.3,142.3c0,0,0.1,0,0.1,0c0.7-0.4-0.8-0.7,0-1c0.5-0.2,0.7,0.6,1.2,0.7c0.2,0,0.3-0.2,0.4-0.3
				c0,0,0,0,0,0c0.4,0.2,1-0.1,1,0.8c0,0.8-1.1,0.2-1,1c-0.3-0.4-0.6-0.8-1.2-0.8C29.5,142.7,29.3,142.7,29.3,142.3z"/>
			<path fill="#FDFCFA" d="M29.3,142.3c0.1,0.2,0.3,0.2,0.5,0.2c0.1,0.6,0.1,0.6-0.4,0.7c-0.2,0-0.4,0.2-0.6,0.4
				c-0.2,0.1-0.2,0.8-0.6,0.5c-0.3-0.3-0.4-0.8-0.6-1.3C27.5,142.4,28.8,142,29.3,142.3z"/>
			<path fill="#F9F5F0" d="M33.9,142.5c0.5-0.7,1.1-1.3,2-0.5c0.1,0.1,0.4,0.1,0.6,0.1c0.3,0,0.8-0.2,0.7,0.3
				c-0.1,0.5-0.9,0.7-1,0.5c-0.7-0.8-1.2-0.3-1.7,0.2c-0.1,0.1-0.4-0.1-0.5-0.1C33.7,142.9,33.4,142.7,33.9,142.5z"/>
			<path fill="#FCFBF9" d="M43,127.6c0,0.8-0.9,1.8-1.6,1.8c-0.4,0-0.6-0.1-0.4-0.5c0.3-0.6,0.7-1.1,1.1-1.7
				c0.1-0.2,0.3-0.4,0.6-0.3C43,127.1,43,127.4,43,127.6z"/>
			<path fill="#F8F4ED" d="M18.7,162.6c-0.1,0.8-0.1,1.5,0.3,2.5c-0.7-0.6-1.2-1.3-2-0.7c-0.2,0.1-0.7-0.5-0.6-0.7
				c0.2-0.4,0.9-0.8,1.1-0.6C18.3,163.7,18.2,162.7,18.7,162.6z"/>
			<path fill="#F7F3ED" d="M42.5,144.9c-0.7-0.4-1.2-0.7-1.9,0.2c-0.2,0.3-1.1,0.3-1.6,0.3c-0.1,0-0.5-0.2-0.3-0.7
				c0.2-0.4,0.2-0.4,0.7-0.3c0.5,0.2,1.1,0.3,1.7-0.2C41.8,143.6,42.3,143.9,42.5,144.9L42.5,144.9z"/>
			<path fill="#F8F4EE" d="M37.9,144.7c-0.2,0.3-0.4,0.3-0.6,0.2c-0.2-0.1-0.3,0-0.5,0.2c0.4,0.3,0.8,0.5,1.1,0.9
				c0.3,0.4,0,0.6-0.2,0.6c-0.5-0.3-1.2,0.1-1.4-0.5c-0.2-0.5-0.3-1.2,0.2-1.6C37,144,37.5,144.4,37.9,144.7z"/>
			<path fill="#FBF8F5" d="M16.1,165.3c0.5,0.7,0.9,1.3-0.2,1.8c-0.2,0.1-0.2,0.3-0.1,0.5c0.2,0.4-0.2,0.6-0.4,0.6
				c-0.2,0.1-0.6,0-0.7-0.3c-0.1-0.3,0-0.5,0.2-0.7c0.3-0.6,1.4-0.8,0.9-1.7C15.8,165.4,15.9,165.3,16.1,165.3z"/>
			<path fill="#F5EFE5" d="M23.6,155c0.5,0.3,0.7,0.1,0.6-0.4c-0.1-0.6,0.3-0.4,0.6-0.3l0,0c0,0.2,0.1,0.6,0.3,0.6
				c0.8-0.2,1.3,0.4,1.9,0.6c-0.3,0.3-0.5,0.3-0.8,0c-0.3-0.3-0.9-0.3-1-0.2c-0.4,1-1.2,0.1-1.8,0.4c-0.2,0.1-0.3,0-0.1-0.7
				C23.4,154.9,23.5,154.9,23.6,155z"/>
			<path fill="#F8F4EE" d="M50,121.9c-0.4,0.5-0.8,1.1-1.3,1.4c-0.3,0.2-0.5-0.3-0.4-0.7c0.1-0.3,0.3-0.7,0.4-1
				c0.1-0.3-0.2-1,0.4-0.9C49.6,120.7,49.5,121.5,50,121.9z"/>
			<path fill="#F3ECE0" d="M20.9,168c0,0.4,0.3,1,0.1,1c-0.6,0-0.9,0.6-1.5,0.6c-0.2,0-0.5-0.2-0.4-0.3c0.5-0.3-0.3-0.7,0.2-1.1
				c0.5-0.3,1-0.2,1.5-0.4C21,167.8,21,167.9,20.9,168C21,168,20.9,168,20.9,168z"/>
			<path fill="#F9F5EF" d="M55.9,129.1c0-0.8,0-0.8-0.8-1.4c0.5-0.5,1.3-0.4,1.7-0.9c0.2-0.2,0.3-0.1,0.5,0.1
				c0.1,0.1,0.3,0.3,0.2,0.3C56.4,127.4,56.7,128.6,55.9,129.1z"/>
			<path fill="#FAF7F3" d="M28.8,145.4c0.3,0,0.7,0.1,0.5,0.6c-0.2,0.6-0.6,0.9-1.3,0.9c-0.4,0-0.8,0.3-0.9-0.2
				c-0.1-0.4,0.1-1,0.7-0.9C28.4,145.8,28.6,145.8,28.8,145.4L28.8,145.4z"/>
			<path fill="#57514B" d="M139.2,106.5c0.3-0.2,0.6-0.3,0.9-0.2c0.5-0.1,0.6-0.6,1-0.7c1.1,1.1,0.3-0.6,0.7-0.5
				c0.4,0.1,0.5,0.4,0.5,0.8c-0.7,0.6-1.5,1.1-2.5,1.1c-0.1-0.1-0.2-0.1-0.4-0.1c0.1,0.1,0.2,0.1,0.2,0.2c-0.1,0.2-0.3,0.2-0.5,0.2
				c-0.5,0.2-0.7,0-0.7-0.5C138.7,106.5,138.9,106.3,139.2,106.5z"/>
			<path fill="#FEFEFE" d="M30.8,139.9c0.3-0.3,0.4-0.6,0.3-1c0-0.3,0.2-0.7,0.6-0.7c0.6,0,0.2,0.5,0.3,0.7c0.2,0.4,0.4,0.8,0.7,1.3
				C31.9,140.6,31.4,139.8,30.8,139.9C30.8,139.9,30.8,139.9,30.8,139.9z"/>
			<path fill="#F7F3EC" d="M30.5,167.5c-0.3-0.5-0.3-1,0.3-1.2c0.3-0.1,0.5-0.5,0.8-0.1c0.4,0.4,0.4,0.9,0.2,1.3
				C31.5,168.2,30.9,167.2,30.5,167.5L30.5,167.5z"/>
			<path fill="#F5EFE5" d="M27.4,162.2c-0.1,0-0.3,0-0.3-0.1c-0.2-0.3-0.3-0.7-0.8-0.6c-0.6,0.1,0.1,0.7-0.5,0.9
				c-0.1-0.8-1.2-0.3-1.6-1.2c0.7,0.2,1.3,0.2,2,0.1C27.4,160.9,27.5,161,27.4,162.2C27.4,162.2,27.4,162.2,27.4,162.2z"/>
			<path fill="#5A544D" d="M145.7,101.7c-0.2,0-0.5,0-0.7,0c-0.3-0.7,0.3-1.3,0.2-1.9c0,0,0,0,0,0c1,0.2,0.6-0.9,1.2-1.2
				C146.5,99.7,146.2,100.8,145.7,101.7z"/>
			<path fill="#FAF8F3" d="M30.7,159.5c-0.3,0.3-0.6,0.9-1,1.2c-0.2,0.1-0.6-0.3-0.6-0.6c0.1-0.6,0.4-1.1,1.1-1.3
				C30.5,158.7,30.7,159,30.7,159.5z"/>
			<path fill="#F6F1E9" d="M22.2,173.9c0.2-0.9,1-1.3,0.6-2.1c-0.1-0.3,0.3-0.4,0.6-0.3c0.3,0.1,0.8-0.3,0.7,0.4
				c0,0.2,0.2,0.6-0.4,0.4c-0.6-0.1-0.1,0.5-0.3,0.6C23.1,173.3,22.8,173.5,22.2,173.9z"/>
			<path fill="#F4EDE3" d="M23.1,177.4c-0.4,1.4,0.9,1.8,1.4,2.6c0.2,0.3,0.4,0.6,0,0.8c-0.4,0.2-0.4-0.2-0.5-0.5
				c-0.1-0.7-1-1-1.2-1.7C22.7,178.2,22.5,177.9,23.1,177.4z"/>
			<path fill="#FAF7F3" d="M18.8,165.3c0,0.6-0.6,0.6-0.9,0.9c-0.3,0.4,0.8,0.4,0.1,0.8c-0.4,0-1,0.2-1.1-0.4
				c-0.1-0.5,0.1-1,0.8-1.1C18.1,165.5,18.4,165.1,18.8,165.3z"/>
			<path fill="#F9F6F1" d="M45.2,141.6c0.4,0.3,0.9,0.7,1.2,1.2c0.1,0.2-0.3,0.5-0.5,0.4c-0.5-0.3-1.2-0.4-1.4-1.1
				C44.5,141.9,44.7,141.6,45.2,141.6z"/>
			<path fill="#FCFAF8" d="M42.1,140.1c0.3-0.2,0.7,0,0.9-0.4c0.1-0.3,0.3-0.7,0.7-0.3c0.5,0.6,0.1,1.1-0.3,1.5
				c-0.5,0.5-0.5,0-0.6-0.3C42.7,140.4,42.2,140.5,42.1,140.1z"/>
			<path fill="#F8F5EF" d="M24.1,162.6c-0.1,0.3,0.4,0.7-0.2,0.8c-0.3,0.1-0.4-0.3-0.5-0.5c0-0.1,0-0.2,0-0.2
				c-0.4-0.2-0.7-0.5-0.5-1c0.2-0.5,0.6,0,0.8,0C24.3,161.7,24.3,162.2,24.1,162.6z"/>
			<path fill="#FBF9F6" d="M44.3,123.8c-0.8-0.3-0.8-0.8-0.9-1.2c-0.1-0.3,0-0.6,0.4-0.8c0.3-0.1,0.7-0.2,1,0.2
				c0.3,0.4,0,0.6-0.3,0.7C44,123,44,123.3,44.3,123.8z"/>
			<path fill="#FDFDFC" d="M22.1,156c-0.2,0-0.5,0-0.7,0c-0.1,0-0.2,0-0.2,0c-0.2-0.3-0.3-0.6-0.2-1c0.1-0.4,0.5-0.8,0.7-0.4
				C21.9,154.9,23,155.2,22.1,156z"/>
			<path fill="#FCFAF8" d="M44.8,120.5c0.7-0.4,0.9-0.8,0.9-1.4c0-0.3,0.1-0.6,0.5-0.5c0.3,0.1,0.4,0.3,0.4,0.6
				C46.6,120.3,46,120.5,44.8,120.5z"/>
			<path fill="#F9F5F0" d="M27.6,158.4c-0.6,0.6,0.3,0.8,0.4,1.2c-0.4,0.2-1.1-1-1.4,0.3c0,0.2-0.5-0.4-0.9-0.3l0,0
				c0.6-0.5,0.9-1.2,1.7-1.4C27.6,158,27.7,158.2,27.6,158.4z"/>
			<path fill="#F5EFE5" d="M29.8,163.2c-0.1,0.3,0,0.9-0.4,0.9c-0.3,0-0.8-0.2-0.8-0.8c0-0.4,0.1-0.9,0.6-0.9
				C29.6,162.3,29.7,162.8,29.8,163.2z"/>
			<path fill="#1D1D1B" d="M134.9,107.3c-0.1,0.2-0.2,0.5-0.2,0.7c-0.6-0.1-1.3,0.1-1.9-0.2c-0.2-0.2-0.4-0.4-0.3-0.8
				c0.7,0.5,0.6-0.4,0.9-0.5c0.2-0.1,0.3-0.1,0.5,0C134.1,106.8,134.4,107.2,134.9,107.3z"/>
			<path fill="#FBF9F6" d="M40.5,141.3c-0.6,0.1-0.8-0.3-0.8-0.7c-0.1-0.4,0.2-0.7,0.6-0.8c0.6,0,0.8,0.5,0.9,0.9
				C41.2,141.3,40.8,141.2,40.5,141.3z"/>
			<path fill="#F7F3EC" d="M30.7,168.8c-0.4,0.4-0.8,0.7-1.2,1.1c-0.2-0.5-0.3-1-0.7-1.4c-0.2-0.2-0.3-0.3-0.1-0.5
				c0.2-0.1,0.4-0.3,0.5,0C29.5,168.8,30.2,168.4,30.7,168.8z"/>
			<path fill="#F8F4EE" d="M19.8,161.5c0.3,0,0.7,0.3,0.6,0.4c-0.1,0.6,0.4,1,0.2,1.5c0,0.2-0.3,0.2-0.4,0.1
				c-0.3-0.6-0.7-1.1-0.9-1.7C19.3,161.7,19.6,161.5,19.8,161.5z"/>
			<path fill="#FBF9F5" d="M34.3,137.8c0.6,0.8,0.8,1.3,0.1,1.6c-0.7,0.3-1-0.6-1.6-0.5c0-0.1,0-0.2,0-0.2c0.1-0.3,0.3-0.2,0.5-0.1
				C33.8,138.8,34.2,139,34.3,137.8z"/>
			<path fill="#FCFBF8" d="M24.5,146.9c1.3,0,1.3,0,0.5,1.8C24.3,148.2,24.7,147.4,24.5,146.9C24.5,146.9,24.5,146.9,24.5,146.9z"/>
			<path fill="#F7F2EB" d="M20.9,168c0-0.1,0-0.2,0-0.2c0-0.8,1.4-0.7,1.1-1.7c0,0,0.1-0.1,0.2-0.1c0.2,0,0.3,0.1,0.3,0.3
				c-0.1,0.4,0.1,0.6,0.4,0.8l0,0C22.1,167.2,21.8,168.2,20.9,168z"/>
			<path fill="#F5F0E6" d="M27.4,169.4c-0.4,1.1-0.6,1.9,0.8,2.2c-0.9,0.2-1.5-0.1-1.7-0.6C26.1,170.3,26.6,169.9,27.4,169.4z"/>
			<path fill="#F8F4EE" d="M42.3,131.6c0.3-0.3,0.8-0.3,0.9-0.8c0-0.3,0.6-0.2,0.8,0c0.2,0.2,0.5,0.5,0.5,0.7
				c-0.1,0.4-0.4,0.1-0.7,0.1C43.3,131.5,42.9,131.6,42.3,131.6z"/>
			<path fill="#FBF9F6" d="M31.1,146c0,0.4-0.3,0.5-0.5,0.6c-0.7,0.2-0.6-0.5-0.7-0.8c-0.1-0.3,0-0.7,0.3-0.6
				C30.7,145.1,30.8,145.7,31.1,146z"/>
			<path fill="#F4EEE3" d="M33.9,142.5c-0.3,0.2,0,0.3,0,0.5c-0.5,0-1.1-0.1-1.2-0.5c-0.1-0.5,0.7-0.8,0.8-1.4c0,0,0.3-0.1,0.4,0
				c0.2,0.1,0,0.3-0.1,0.4C33.3,141.9,33.7,142.2,33.9,142.5z"/>
			<path fill="#F5F0E7" d="M37.8,153.9c-0.1-0.1-0.2-0.2-0.2-0.2c0.5-0.5,0.3-1.2,0.5-1.8c0-0.1,0.3-0.2,0.4-0.1
				c0.2,0.3,0.6,0.7,0.5,0.9C38.6,153.2,38.2,153.5,37.8,153.9z"/>
			<path fill="#F5F0E7" d="M51.3,120.3c0.4,0,0.7,0.1,0.5,0.4c-0.3,0.4-0.4,1.1-0.9,1.1c-0.5,0.1-0.4-0.7-0.5-1
				C50.2,120.2,51.2,120.7,51.3,120.3z"/>
			<path fill="#FDFCFA" d="M16.1,165.3c-0.1,0.1-0.2,0.2-0.2,0.2c-0.8,0.1-0.9-0.1-0.7-1.4c0.1,0,0.2,0,0.3,0
				C15.9,164.4,16.4,164.6,16.1,165.3z"/>
			<path fill="#FCFAF8" d="M23.6,155c-0.1,0-0.2,0-0.2,0c-1.1-1-1-1.1,0.7-1.7l0,0C24,153.9,23.3,154.4,23.6,155z"/>
			<path fill="#F4EEE3" d="M27.6,158.4c-0.1-0.1-0.2-0.2-0.2-0.2c0.4-0.3,0.4-0.9,0.8-1.2c0.2-0.1,0.3-0.1,0.5,0
				c0.2,0.1,0.2,0.5,0,0.5C28.2,157.5,28.7,158.9,27.6,158.4z"/>
			<path fill="#F4EEE4" d="M17.9,160.7c0.2,0.5-0.1,0.7-0.4,0.7c-0.3,0-0.8-0.3-0.7-0.6c0-0.3,0.2-0.8,0.8-0.7
				C18,160.1,18.1,160.3,17.9,160.7z"/>
			<path fill="#FAF7F3" d="M56.3,124.5c-0.5-0.3-1.3-0.2-1.2-0.9c0.2-0.8,0.8-0.7,1.2-0.2C56.4,123.5,55.8,124,56.3,124.5z"/>
			<path fill="#F4EFE5" d="M47.8,116.9c0.4-0.2,0.6-0.3,0.9-0.5c0.3-0.2,0.7-0.4,0.9,0.1c0.3,0.7-0.5,0.3-0.5,0.4
				C48.6,117.8,48.2,117.2,47.8,116.9z"/>
			<path fill="#F5F0E7" d="M40,157.6c0-0.5-0.5-1,0-1.2c0.2-0.1,0.8,0,1.1,0.2c0.2,0.1,0.3,0.3,0.2,0.5c-0.1,0.2-0.3,0.2-0.5,0.1
				C40.3,156.9,40.4,157.5,40,157.6z"/>
			<path fill="#FBF9F6" d="M47.8,124.1c0.3,0,0.7,0,1,0c0.4-0.1,0.7-0.1,0.9,0.3c0.1,0.2,0,0.3-0.1,0.4c-0.3,0.2-0.7,0.2-1,0.1
				c-0.3-0.1-0.5-0.4-0.9-0.4C47.7,124.3,47.7,124.2,47.8,124.1z"/>
			<path fill="#403D39" d="M132.5,107c0.1,0.2,0.1,0.5,0.2,0.7c-0.8,0.1-1.7-0.1-2.4-0.4c0.2-0.1,0.3-0.2,0.5-0.3
				C131.4,107,132,106.5,132.5,107z"/>
			<path fill="#F8F5EF" d="M26.3,152.8c0.5,0.5,1.3,0.4,1.9,0.7C26.8,154.2,26.8,154.2,26.3,152.8z"/>
			<path fill="#FAF8F4" d="M38,143.6c0.2-0.5-0.6-1,0.1-1.4c0.3-0.1,0.7,0,0.8,0.3C39.2,143.3,38.3,143.2,38,143.6z"/>
			<path fill="#F2EBDE" d="M14.1,186.6c-0.4,1.2-1.1,0.4-1.7,0.4c-0.1,0-0.1-0.3-0.1-0.4c0.1-0.3,0.3-0.4,0.7-0.3
				C13.4,186.5,13.5,187.2,14.1,186.6z"/>
			<path fill="#F3ECE0" d="M15.6,170.6c-0.2,0.2-0.3,0.5-0.3,0.5c-0.5-0.2-0.7-0.7-1-1.2c-0.1-0.2,0.2-0.3,0.4-0.3
				C15.5,169.6,15.1,170.5,15.6,170.6z"/>
			<path fill="#F4EEE3" d="M34.5,167c-0.2,0.3-0.4,0.6-0.7,0.7c-0.3,0-0.5-0.3-0.5-0.6c0-0.4,0.3-0.7,0.7-0.7
				C34.3,166.5,34.5,166.7,34.5,167z"/>
			<path fill="#5D564F" d="M138.8,106.8c0,0.4,0.2,0.5,0.5,0.5c-0.4,0.3-0.8,0.2-1.2,0.3c0,0-0.1-0.1-0.1-0.1
				c-0.3-0.5-0.7-1-0.1-1.5c0.2-0.1,0.5-0.1,0.7,0C139.1,106.1,138.6,106.5,138.8,106.8z"/>
			<path fill="#F5F0E7" d="M29.8,174.2c0.2,0.4,0.4,0.5,0.7,0.1c0.2-0.2,0.4-0.5,0.6-0.2c0.2,0.3,0.1,0.7-0.2,1
				c-0.3,0.2-0.6-0.2-0.9-0.3C29.7,174.8,29.4,174.6,29.8,174.2L29.8,174.2z"/>
			<path fill="#F5F0E7" d="M53.4,112.3c-0.2,0.5-0.1,1.1-0.8,1.2c0-0.3-0.6,0.2-0.5-0.4C52.2,112.4,52.6,112.2,53.4,112.3z"/>
			<path fill="#F7F2EB" d="M43.7,126.2c-0.6,0.1-1.1,0-1.3-0.5c-0.2-0.4,0.3-0.5,0.6-0.6c0.3-0.1,0.8-0.2,0.3,0.4
				C43,125.9,43.8,125.7,43.7,126.2z"/>
			<path fill="#F7F3EB" d="M38.8,128.2c-0.1-0.3,0.1-0.4,0.2-0.6c0.2-0.3,0.4-0.8,0.9-0.7c0.1,0,0.2,0.2,0.1,0.2
				c-0.4,0.3-0.2,1.1-0.9,1.1C39.1,128.2,39,128.4,38.8,128.2z"/>
			<path fill="#F5EFE6" d="M25.7,156.7c-0.3,0.7-0.9,0.7-1.4,0.9c-0.3,0.1-0.5,0-0.5-0.3c0-0.3,0.1-0.5,0.4-0.4
				C24.7,157,25.1,156.6,25.7,156.7z"/>
			<path fill="#F2EBDE" d="M48.1,141.2c-0.4-0.2-1.1-0.4-1-0.9c0.1-0.4,0.7-0.5,0.8-1C48.4,139.9,47.5,140.6,48.1,141.2z"/>
			<path fill="#F4EEE4" d="M31.7,154.8c0.4,1.5-1,0.9-1.6,1.6C30.3,155.1,31.3,155.3,31.7,154.8L31.7,154.8z"/>
			<path fill="#F6F2E9" d="M23.9,150.8c-0.5,0.4,0.3,1.3-0.6,1.5c-0.2,0-0.4-0.2-0.3-0.4c0.3-0.4,0-1.2,0.7-1.4
				C23.9,150.5,24,150.7,23.9,150.8z"/>
			<path fill="#F9F5F0" d="M25.7,159.6c-0.1,0.3,0.2,0.7-0.2,0.8c-0.3,0.1-0.6-0.3-0.7-0.5c-0.3-0.4,0.2-0.5,0.4-0.7
				C25.9,158.4,25.3,159.8,25.7,159.6C25.7,159.6,25.7,159.6,25.7,159.6z"/>
			<path fill="#F6F2EA" d="M52.1,132.4c0.2-0.2,0.6-0.3,0.5-0.7c0-0.3,0.1-0.6,0.5-0.5c0.3,0.1,0.3,0.5,0.2,0.6
				c-0.3,0.2,0.4,0.8-0.3,0.8C52.6,132.5,52.4,132.4,52.1,132.4z"/>
			<path fill="#FAF7F3" d="M49.2,127.9c0.3,0.2,0.7,0.1,0.9,0.6c-0.5,0.2-0.9,0.9-1.3,0.6C48.4,128.7,49.1,128.3,49.2,127.9
				C49.2,127.9,49.2,127.9,49.2,127.9z"/>
			<path fill="#F7F3EB" d="M44.1,133.8c-0.7,0-1-0.7-1.7-0.6c-0.2,0-0.1-0.3-0.1-0.5c0-0.2,0.2-0.3,0.4-0.1
				C43.1,133,43.9,133,44.1,133.8z"/>
			<path fill="#F4EEE4" d="M54.7,126.3c-1.2,0.3-1.6,0.1-1.9-1.3C53.4,125.6,53.8,126.4,54.7,126.3z"/>
			<path fill="#FCFBF9" d="M40.4,137.3c-0.8-0.9-0.8-0.9,0.3-1.7C41,136.3,40.1,136.7,40.4,137.3z"/>
			<path fill="#FAF7F3" d="M18,167.8c0.1-0.1,0.3-0.3,0.4-0.3c0.2,0.1,0.1,0.3,0.1,0.5c-0.1,0-0.1,0.1-0.2,0.2
				c-0.3,0.3-0.3,0.8-0.8,0.9c-0.1,0-0.2-0.1-0.2-0.2C17.1,168.1,17.7,168,18,167.8z"/>
			<path fill="#F5F0E8" d="M36,133.6c-0.2-0.1-0.6-0.1-0.5-0.4c0.1-0.4,0.5-0.6,0.9-0.6c0.2,0,0.5,0.2,0.4,0.5
				C36.8,133.5,36.3,133.5,36,133.6z"/>
			<path fill="#F8F4EE" d="M33.7,145c-0.9,0-1,0.5-0.8,1.3c-0.7-0.4-0.8-0.7-0.6-1.2C32.7,144.4,33.2,145.1,33.7,145z"/>
			<path fill="#F4EEE3" d="M28.8,173.1c-0.2-0.2-0.7,0.2-0.6-0.2c0.1-0.6,0.7-0.5,1.1-0.6c0.2-0.1,0.4,0.1,0.3,0.3
				C29.5,173,29.3,173.3,28.8,173.1z"/>
			<path fill="#FAF7F3" d="M32.2,172.2c-0.3-0.6-0.7-1.3,0.3-1.7C32.7,171.1,32.4,171.6,32.2,172.2z"/>
			<path fill="#F7F3EC" d="M40.8,154.2c-0.3,0.5-0.4,0.8-0.6,1.1c-0.1,0.3-0.2,0.6-0.6,0.6c-0.2,0-0.3-0.2-0.3-0.3
				c0.4-0.2,0-0.8,0.5-0.9C40.1,154.7,40.3,154.5,40.8,154.2z"/>
			<path fill="#F4EEE3" d="M27.9,151.9c0.9,0.2,1.6-0.6,2.7-0.5c-0.9,0.7-1.8,0.8-2.7,1C27.7,152.2,27.7,152.1,27.9,151.9z"/>
			<path fill="#F2EADD" d="M36.5,169.9c-1.2-1,0.4-0.8,0.4-1.6C37.4,169.3,37.1,169.7,36.5,169.9L36.5,169.9z"/>
			<path fill="#F1EADD" d="M50.7,140.1c0,0.7-0.3,1.1-0.8,1.3c-0.1,0.1-0.4-0.2-0.4-0.4C49.5,140.3,50.1,140.3,50.7,140.1z"/>
			<path fill="#F5EFE5" d="M46.1,125.5c-0.3,0.3-0.7-0.1-1,0.2c-0.1,0.2-0.2,0.5-0.5,0.2c-0.1-0.1-0.2-0.3-0.1-0.4
				c0-0.3,0.2-0.4,0.5-0.4C45.4,125,45.8,125.2,46.1,125.5L46.1,125.5z"/>
			<path fill="#F6F1E8" d="M22.4,169.4c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.3,0.3-0.5,0.7-0.3c0.3,0.1,0.2,0.3,0.2,0.6
				c-0.1,0.4-0.3,0.5-0.7,0.4C22.8,169.4,22.5,169.4,22.4,169.4L22.4,169.4z"/>
			<path fill="#F9F6F1" d="M66.5,105.2c-0.4,0.6-0.7,1-1.3,0.9c-0.2,0-0.1-0.2-0.1-0.4C65.3,105,65.8,105.1,66.5,105.2z"/>
			<path fill="#F4EEE3" d="M45.5,144.1c0,0.9,0.9,1,1.3,1.7C45.4,145.4,45.2,145.1,45.5,144.1z"/>
			<path fill="#F9F7F2" d="M38.6,138.6c0.5,0,1.1-0.4,1.5,0.1c0.1,0.1,0,0.4-0.1,0.4C39.5,139.3,39.1,138.9,38.6,138.6z"/>
			<path fill="#FDFCFC" d="M42,137.7c0,0,0,0.1,0,0.1c-0.3,0.3,0.3,1-0.4,1.2c-0.2,0-0.7,0-0.7-0.3C41,138.1,41.6,138,42,137.7
				L42,137.7z"/>
			<path fill="#F2EBDE" d="M58.7,118.7c0.5,0,0.9-0.4,1.4-0.1c0.1,0.1,0.1,0.3-0.1,0.4C59.5,119.5,59.1,119.2,58.7,118.7z"/>
			<path fill="#F7F3EC" d="M34.1,161.5c0,0.3-0.1,0.4-0.4,0.3c-0.4-0.1-0.6-0.3-0.5-0.7c0-0.2,0.1-0.5,0.3-0.4
				C33.9,160.7,33.9,161.2,34.1,161.5z"/>
			<path fill="#FAF7F3" d="M23.8,167.3c0.3-0.1,0.6-0.2,1.1-0.4c-0.5,0.5-0.1,1.4-0.9,1.3C23.2,168.1,23.9,167.6,23.8,167.3
				C23.8,167.3,23.8,167.3,23.8,167.3z"/>
			<path fill="#F7F2EB" d="M18.9,171.8c1-0.8,1.3-0.7,1.6,0.5C20,171.9,19.5,171.8,18.9,171.8z"/>
			<path fill="#FAF8F5" d="M19.2,158.1c-0.1-0.3-0.6-0.5-0.2-0.9c0.1-0.1,0.3-0.2,0.4-0.2c0.4,0.1,0.3,0.5,0.4,0.8
				c0.1,0.2,0.1,0.4,0.1,0.6C19.7,158.3,19.5,158.2,19.2,158.1z"/>
			<path fill="#F3ECE1" d="M51.6,109.9c0.1-0.3,0.2-0.5,0.2-0.8c0.1-0.3,0-0.6,0.4-0.4c0.3,0.2,0.7,0.3,0.8,0.7
				c0,0.1-0.1,0.2-0.2,0.3c-0.3,0.2-0.3-0.1-0.4-0.3c-0.1-0.1-0.1-0.4-0.4-0.2c-0.1,0.1,0,0.3-0.1,0.5C52,109.9,51.9,110,51.6,109.9
				z"/>
			<path fill="#F6F1E9" d="M49.4,118.3c0.3,0.7,1,0.7,0.8,1.4c0,0.1-0.3,0.2-0.4,0.2C49.3,119.6,49.3,119.2,49.4,118.3z"/>
			<path fill="#F7F3EC" d="M62.9,124.5c-0.1,0.3,0.4,0.7,0,0.8c-0.4,0.2-0.8-0.1-1.2-0.3C62.1,124.6,62.5,124.6,62.9,124.5
				L62.9,124.5z"/>
			<path fill="#F9F6F1" d="M28.2,150.7c-0.5,0.2-1,0.4-1.4,0c0,0,0-0.3,0-0.3C27.3,150.1,27.8,150.5,28.2,150.7z"/>
			<path fill="#F2EADC" d="M34.5,157.8c-0.1,0.6,1.1,0.3,0.8,0.9c-0.1,0.2-0.3,0.4-0.6,0.3C34.2,158.7,34.8,158.2,34.5,157.8z"/>
			<path fill="#F7F3EC" d="M54,119c-0.8,0.9-1.3,0.2-2.1,0.1C52.7,118.5,53.2,119.2,54,119z"/>
			<path fill="#F3EDE1" d="M17.7,174.8c0.8-0.1,1.2-0.2,1.4,0.3c0.1,0.2,0,0.4-0.2,0.5C18.3,175.7,18.3,175.1,17.7,174.8z"/>
			<path fill="#F7F3EC" d="M57.5,117.8c0,0.3,0,0.3,0,0.3c-0.4,0.3-0.9,0.6-1.4,0.5c0,0-0.1-0.2-0.1-0.2
				C56.4,117.9,57,118.1,57.5,117.8z"/>
			<path fill="#F4EEE4" d="M31.3,154.2c-0.7-0.1-1,0.5-1.5,0.7C30.1,153.8,30.4,153.6,31.3,154.2z"/>
			<path fill="#F6F1E9" d="M20.2,158.4c0.1,0,0.2,0,0.2,0c0.2,0,0.6-0.1,0.6,0.3c0,0.5-0.4,0.7-0.9,0.8
				C20,159.1,20.4,158.8,20.2,158.4z"/>
			<path fill="#F6F1E9" d="M32.3,151.5c0.6-0.4,1-0.7,1.4-1C33.6,151.2,33.4,151.8,32.3,151.5z"/>
			<path fill="#F4EDE3" d="M20.9,177.6c0.5,0.2,0.9-0.6,1.4,0c0.1,0.1,0.2,0.4-0.1,0.5C21.7,178.3,21.3,178.4,20.9,177.6z"/>
			<path fill="#F9F6F1" d="M23.9,149.6c0.5-0.4,0.9-0.4,1.4-0.1c0.1,0.1,0.1,0.3-0.1,0.4C24.7,150.1,24.3,149.9,23.9,149.6z"/>
			<path fill="#F6F1E9" d="M57.7,120.3c-0.2,0.8-0.8,0.6-1.3,0.7c0,0-0.1-0.2-0.1-0.3C56.6,120.3,57.1,120.2,57.7,120.3z"/>
			<path fill="#58524C" d="M128.6,106.8c0.6-0.1,1.4-0.7,1.7,0.5C129.7,107.2,129.1,107.3,128.6,106.8z"/>
			<path fill="#FCFBFA" d="M46.5,115.9c-0.1,0.3-0.4,0.4-0.7,0.4c-0.2,0-0.4-0.1-0.3-0.4c0.1-0.3,0.4-0.5,0.7-0.5
				C46.3,115.5,46.5,115.7,46.5,115.9z"/>
			<path fill="#F3EDE2" d="M33.9,135.1c0,0,0,0.1,0,0.1c-0.1,0.3,0.4,0.8-0.2,1c-0.3,0.1-0.5,0-0.5-0.3
				C33.1,135.4,33.3,135.1,33.9,135.1L33.9,135.1z"/>
			<path fill="#F6F1E8" d="M25.7,151.4c0.6,0.7-0.6,1.1-0.4,1.7C24.6,152.4,25.4,151.9,25.7,151.4L25.7,151.4z"/>
			<path fill="#F9F5EF" d="M25.7,151.4c-0.3,0-0.7-0.1-0.5-0.4c0.2-0.4,0.5-0.9,1.2-0.7C26.3,150.8,25.6,150.9,25.7,151.4
				C25.7,151.4,25.7,151.4,25.7,151.4z"/>
			<path fill="#F7F3ED" d="M46.3,131.3c0.6-1.1,1-0.5,1.4,0.3C47.1,131.5,46.7,131.2,46.3,131.3z"/>
			<path fill="#F4EEE4" d="M35.9,172.5c-0.5-0.1-0.8-0.5-1.1-1.1C35.6,171.5,36.1,171.5,35.9,172.5z"/>
			<path fill="#FCFAF8" d="M36.4,148.5c-0.8-0.5-0.9-1-0.9-1.7C36.2,147.2,36.2,147.7,36.4,148.5z"/>
			<path fill="#F2EBDE" d="M42.3,135.3c0.4-0.3,0.6-0.9,1.1-0.6c0.4,0.2,0.2,0.6-0.3,0.6C42.8,135.3,42.6,135.3,42.3,135.3z"/>
			<path fill="#F9F5F0" d="M16.3,169.9l-0.1,0c0,0-0.1,0-0.1,0c-0.2-0.4-0.5-0.8-1.1-0.9C16.1,168.8,16.5,169.1,16.3,169.9z"/>
			<path fill="#232120" d="M143.9,104.7c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.4,0.6-0.3,0.8-0.6l0,0
				c0.3,0.1,0.4,0.3,0.5,0.5C144.1,104.3,144,104.5,143.9,104.7z"/>
			<path fill="#FAF8F4" d="M19.8,160c-0.3,0.6-0.1,1.2-0.8,1.2c-0.2,0-0.4,0-0.4-0.2C18.7,160.5,19.1,160.3,19.8,160z"/>
			<path fill="#F7F3EC" d="M19.3,164.8c0.1-0.1,0.2-0.2,0.2-0.2c0.4,0.1,0.4-0.7,0.9-0.5c0.1,0,0.1,0.2,0.1,0.3
				C20.3,164.9,19.9,165.1,19.3,164.8z"/>
			<path fill="#F3EDE2" d="M33.9,135.1c0.2-0.6,0.1-1.3,1.3-1.3C34.4,134.2,34.8,135.1,33.9,135.1L33.9,135.1z"/>
			<path fill="#F9F5F0" d="M48.6,125.6c0.5,0.1,0.9,0.3,1.3,0.9C49.1,126.6,48.8,126.2,48.6,125.6z"/>
			<path fill="#F6F1E8" d="M22.4,169.4c-0.3,0.2,0.2,0.8-0.3,0.9c-0.2,0-0.4-0.1-0.7-0.2c0.3-0.2,0.2-0.7,0.4-0.9
				C22.1,168.9,22.1,169.4,22.4,169.4L22.4,169.4z"/>
			<path fill="#F5F0E7" d="M38,166.1c0.2,0.3,0.8-0.4,0.7,0.3c0,0.3-0.3,0.4-0.5,0.2c-0.3-0.1-0.7-0.1-0.6-0.5
				C37.6,165.8,37.8,165.9,38,166.1z"/>
			<path fill="#F3ECE0" d="M36.9,157.5c-0.2,0.2-0.3,0.5-0.5,0.7c-0.2,0.1-0.5-0.2-0.4-0.4c0-0.3,0.3-0.5,0.6-0.6
				C36.7,157.2,36.9,157.3,36.9,157.5z"/>
			<path fill="#F4EEE5" d="M8.4,186c0,0.2-0.1,0.4-0.3,0.4c-0.4,0-0.2-0.7-0.6-0.7c0,0,0.1-0.4,0.3-0.3C8.1,185.4,8.4,185.6,8.4,186
				z"/>
			<path fill="#FBF9F5" d="M49.3,144.4c-0.2,0.3-0.4,0.5-0.6,0.5c-0.3,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.5,0.5-0.4
				C49,144.1,49.2,144.3,49.3,144.4z"/>
			<path fill="#FBF9F6" d="M12.9,170.8c0,0.4-0.2,0.5-0.5,0.5c-0.2,0-0.5,0-0.5-0.3c0-0.3,0.2-0.4,0.4-0.4
				C12.5,170.6,12.8,170.6,12.9,170.8z"/>
			<path fill="#F5F0E7" d="M52,116.4c0,0.2-0.1,0.4-0.3,0.4c-0.4,0-0.5-0.4-0.6-0.6c-0.1-0.2,0.1-0.4,0.3-0.3
				C51.7,115.9,52,116,52,116.4z"/>
			<path fill="#F3EDE1" d="M54.6,139.6c0.3-0.1,0.3,0.1,0.3,0.3c0,0.3-0.1,0.6-0.4,0.6c-0.3,0-0.4-0.3-0.4-0.5
				C54,139.6,54.4,139.7,54.6,139.6z"/>
			<path fill="#F9F6F0" d="M34.2,143.9c0.1-0.2,0.3-0.4,0.6-0.3c0.2,0.1,0.6,0,0.6,0.4c0,0.3-0.3,0.3-0.5,0.2
				C34.6,144.2,34.2,144.3,34.2,143.9z"/>
			<path fill="#F9F5F0" d="M65.3,113.3c0.8,0.5,0.8,0.9,0.3,1.3C65.1,114.3,65.4,113.9,65.3,113.3z"/>
			<path fill="#F6F2EA" d="M14.9,173.7c0.3-0.3,0.5-0.7,1-0.6c0.2,0.1,0.2,0.3,0.1,0.4C15.7,174,15.3,173.6,14.9,173.7z"/>
			<path fill="#F8F4EE" d="M42,137.8c-0.5-0.8,0-0.9,0.6-1.1C42.9,137.3,42.4,137.5,42,137.8C42,137.7,42,137.8,42,137.8z"/>
			<path fill="#F3ECE0" d="M48.5,146c0,0.3-0.1,0.4-0.4,0.4c-0.2,0-0.5,0-0.5-0.3c0-0.3,0.1-0.5,0.4-0.5
				C48.2,145.6,48.4,145.7,48.5,146z"/>
			<path fill="#F3ECE0" d="M59.6,133.2c0.5-0.6,0.8-1,1.2-0.8c0.1,0,0.1,0.3,0.1,0.4C60.6,133.2,60.2,132.8,59.6,133.2z"/>
			<path fill="#F3EDE2" d="M36.6,150.8c-0.3,0.1-0.4-0.2-0.5-0.4c-0.1-0.4,0.3-0.3,0.4-0.3c0.3-0.1,0.6,0,0.6,0.3
				C37.2,150.8,36.8,150.7,36.6,150.8z"/>
			<path fill="#FAF7F3" d="M35.5,132.3c-0.3-0.3-0.7-0.5-0.6-1c0-0.1,0.2-0.3,0.4-0.2C35.7,131.4,35.5,131.9,35.5,132.3z"/>
			<path fill="#FBFAF7" d="M27.9,151.9c0,0.2,0,0.3,0,0.5c-0.4,0.2-0.7,0.1-0.9-0.2c-0.1-0.2-0.1-0.4,0.1-0.5
				C27.4,151.4,27.5,152,27.9,151.9z"/>
			<path fill="#F4EEE4" d="M33.4,154.3c-0.3-0.1-0.9,0-0.3-0.6c0.2-0.2,0.1-0.7,0.5-0.4C34,153.6,33.6,154,33.4,154.3L33.4,154.3z"
				/>
			<path fill="#F7F3EC" d="M53.2,116.9c0.2,0,0.3,0.2,0.3,0.4c0,0.2,0,0.4-0.3,0.4c-0.3,0-0.4-0.2-0.4-0.4
				C52.9,117.1,53,116.9,53.2,116.9z"/>
			<path fill="#F5EFE5" d="M51.3,137c-0.2,0.4-0.5,0.6-0.9,0.7C50.5,137,50.5,137,51.3,137z"/>
			<path fill="#FEFEFE" d="M44.8,116.9c0.4-0.4,0.8-0.3,1.1-0.1c0.1,0,0.1,0.3,0,0.3C45.4,117.5,45.2,116.8,44.8,116.9z"/>
			<path fill="#F2EBDF" d="M55.7,115.6c0.4,0,0.7-0.3,1.1-0.1c0.2,0.1,0.1,0.3-0.1,0.4C56.3,116.2,56,116.2,55.7,115.6z"/>
			<path fill="#F8F4EC" d="M51.6,109.9c0.2-0.1,0.3-0.2,0.5-0.2c-0.1,1-0.8,0.6-1.4,0.5C51,110.1,51.3,110,51.6,109.9z"/>
			<path fill="#FBF9F5" d="M28.8,145.4c-0.4,0-0.9,0-1.3-0.4C28.2,144.6,28.6,144.7,28.8,145.4C28.8,145.4,28.8,145.4,28.8,145.4z"
				/>
			<path fill="#FCFBF9" d="M38.3,164.5c-0.5-0.1-1,0.1-1.2-0.4c0-0.1,0-0.3,0.1-0.3C37.6,163.7,37.9,163.9,38.3,164.5z"/>
			<path fill="#F3ECE0" d="M15.4,172.1c-0.2,0.2-0.2,0.6-0.6,0.6c-0.2,0-0.3-0.2-0.2-0.3c0.2-0.2,0.2-0.6,0.6-0.6
				C15.3,171.8,15.5,171.9,15.4,172.1z"/>
			<path fill="#FDFCFB" d="M23.9,164.4c-0.3,0.3-0.4,0.8-1,0.6c-0.1,0-0.2-0.2-0.1-0.3C23,164.3,23.4,164.3,23.9,164.4z"/>
			<path fill="#F9F6F1" d="M16.8,171.8c-0.1,0.2-0.2,0.5-0.2,0.7c-0.4-0.2-0.6-0.6-0.6-1C16.4,171.3,16.5,171.8,16.8,171.8z"/>
			<path fill="#F9F6F1" d="M19.2,173.5c0.6,0.2,0.9,0.4,0.8,0.9c0,0.1-0.2,0.3-0.4,0.2C19.3,174.4,19.6,173.9,19.2,173.5z"/>
			<path fill="#F9F7F2" d="M33.6,147c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.4,0.1,0.4,0.4
				C33.9,146.8,33.8,147,33.6,147z"/>
			<path fill="#F3EDE1" d="M20.5,188.9c0.2,0.4,0.4,0.6,0.2,1c0,0.1-0.2,0.1-0.3,0C20.1,189.7,20.2,189.4,20.5,188.9z"/>
			<path fill="#FCFBF9" d="M35,145.1c0.2,0.5,0,0.8-0.4,0.9c-0.2,0.1-0.4,0-0.3-0.2C34.3,145.4,34.6,145.3,35,145.1z"/>
			<path fill="#F1EADD" d="M50.2,142.5c-0.4-0.1-0.8,0.5-1.1-0.1c0-0.1,0-0.3,0-0.3C49.6,142,49.9,142.3,50.2,142.5z"/>
			<path fill="#F2EADD" d="M36.5,169.9c0.1,0.3,0.7,0.2,0.5,0.7c-0.1,0.3-0.5,0.3-0.7,0C35.9,170.2,36.3,170.1,36.5,169.9
				C36.5,169.9,36.5,169.9,36.5,169.9z"/>
			<path fill="#FAF8F3" d="M35.3,136.8c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.4-0.1-0.4-0.3
				C34.9,136.9,35.1,136.8,35.3,136.8z"/>
			<path fill="#FCFBF9" d="M51.8,123.7c0.2,0,0.3,0.1,0.3,0.3c0,0.3-0.1,0.5-0.4,0.7c0,0-0.3-0.2-0.3-0.3
				C51.4,124,51.5,123.8,51.8,123.7z"/>
			<path fill="#F9F5F0" d="M67.2,116.8c-0.1,0.3-0.2,0.5-0.4,0.4c-0.2-0.1-0.5-0.1-0.4-0.5c0-0.1,0.2-0.3,0.2-0.4
				C66.8,116.5,67,116.7,67.2,116.8z"/>
			<path fill="#F9F6F1" d="M19.3,170.2c0.2,0,0.3,0.1,0.4,0.3c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.3,0-0.4-0.2
				C19,170.5,19.2,170.4,19.3,170.2z"/>
			<path fill="#F4EEE4" d="M31.7,154.8c0-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0-0.5,0.3-0.5c0.1,0,0.2,0.2,0.3,0.3c0.1,0.4-0.1,0.5-0.4,0.5
				C31.9,154.8,31.8,154.8,31.7,154.8C31.7,154.8,31.7,154.8,31.7,154.8z"/>
			<path fill="#F5F0E7" d="M49.5,113.5c-0.2-0.3-0.4-0.5-0.8-0.9C49.7,112.6,49.8,112.9,49.5,113.5L49.5,113.5z"/>
			<path fill="#F6F2EA" d="M28.5,167.6c-0.1,0.3-0.3,0.5-0.5,0.5c-0.2,0-0.4-0.1-0.4-0.2c0-0.3,0.2-0.5,0.5-0.5
				C28.2,167.4,28.3,167.6,28.5,167.6z"/>
			<path fill="#F7F3EC" d="M55.8,113.7c0.1,0.2-0.1,0.3-0.2,0.3c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0.2-0.3,0.3-0.3
				C55.6,113.2,55.7,113.5,55.8,113.7z"/>
			<path fill="#F5EFE5" d="M55.1,130.2c0.4-0.1,0.8-0.3,1,0.1c0,0.1,0,0.3-0.1,0.3C55.6,130.7,55.4,130.5,55.1,130.2z"/>
			<path fill="#F6F2EA" d="M56.1,135.1c0,0.2-0.2,0.4-0.4,0.4c-0.3,0-0.3-0.2-0.2-0.4c0.1-0.2,0.2-0.4,0.4-0.3
				C56.1,134.7,56.2,134.9,56.1,135.1z"/>
			<path fill="#F3EDE2" d="M53.5,142.3c0,0.6-0.2,0.9-0.7,1.3C52.8,143,53,142.6,53.5,142.3z"/>
			<path fill="#F5EFE5" d="M48.8,133.7c0-0.5,0.2-0.8,0.7-1C49.5,133.2,49.4,133.5,48.8,133.7z"/>
			<path fill="#F7F2EA" d="M38.3,139.8c0,0.3-0.3,0.4-0.5,0.4c-0.1,0-0.4,0.1-0.3-0.2c0.1-0.2,0-0.5,0.4-0.5
				C38,139.4,38.2,139.5,38.3,139.8z"/>
			<path fill="#F5EFE5" d="M43.5,124.6c0.4-0.4,0.7-0.3,1,0c0,0,0,0.3,0,0.3C44.1,125,43.8,124.8,43.5,124.6z"/>
			<path fill="#F5F1E8" d="M16.3,178c0,0.2-0.2,0.2-0.4,0.2c-0.2,0-0.4-0.2-0.4-0.4c0-0.3,0.2-0.3,0.4-0.3
				C16.1,177.7,16.3,177.8,16.3,178z"/>
			<path fill="#FAF7F3" d="M45,119.4c0.1,0.4-0.2,0.6-0.5,0.7c-0.1,0-0.3-0.2-0.3-0.3C44.4,119.4,44.7,119.3,45,119.4z"/>
			<path fill="#F8F4ED" d="M28.4,149.8c-0.1,0-0.2,0-0.2-0.1c-0.2-0.3-0.7,0.2-0.7-0.3c0-0.1,0.1-0.2,0.1-0.3
				c0.4-0.3,0.5,0.2,0.8,0.2C28.5,149.4,28.5,149.6,28.4,149.8z"/>
			<path fill="#FAF8F4" d="M42,121.2c0-0.2,0-0.3,0-0.5c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0,0.5-0.2,0.6
				C42,121.8,42.1,121.4,42,121.2z"/>
			<path fill="#FCFBF8" d="M46,122.1c0.4,0,0.7,0.1,0.8,0.6c0,0.1-0.2,0.2-0.2,0.2C46,122.8,46,122.5,46,122.1z"/>
			<path fill="#FAF7F3" d="M28,156.1c0.2-0.3,0.4-0.6,0.8-0.5c0.1,0,0.2,0.2,0.1,0.3C28.7,156.1,28.4,156.3,28,156.1z"/>
			<path fill="#F6F1E9" d="M24.8,154.3c0.4-0.4,0.4-0.4,1.2-0.3C25.6,154.5,25.1,154.3,24.8,154.3C24.8,154.3,24.8,154.3,24.8,154.3
				z"/>
			<path fill="#F5F0E6" d="M32.3,160.4c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0-0.3-0.1-0.3-0.3c0.1-0.2,0.2-0.4,0.4-0.4
				C32.2,160.1,32.3,160.2,32.3,160.4z"/>
			<path fill="#F5EFE5" d="M51.8,136.1c0.3,0.1,0.3,0.3,0.2,0.5c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1-0.2-0.2-0.3-0.2-0.5
				C51.5,136.3,51.7,136.2,51.8,136.1z"/>
			<path fill="#F7F2EB" d="M38.8,136.1c-0.1-0.6,0.2-0.8,0.6-1C39.5,135.6,39.3,135.8,38.8,136.1z"/>
			<path fill="#F6F1E9" d="M49.4,114.8c0,0.2,0,0.3-0.3,0.3c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.1-0.3,0.3-0.3
				C49.3,114.4,49.4,114.6,49.4,114.8z"/>
			<path fill="#F5F0E6" d="M61.8,111c-0.3,0.2-0.7,0.2-0.9-0.1c-0.2-0.2,0.2-0.4,0.4-0.1C61.5,110.9,61.8,110.6,61.8,111z"/>
			<path fill="#F4EEE4" d="M53.6,107.3c-0.5,0.2-0.8,0.4-1.1,0.5C52.6,107.4,52.7,106.9,53.6,107.3z"/>
			<path fill="#FDFCFB" d="M52.9,140.8c-0.3,0.1-0.6,0.2-0.8-0.1c-0.1-0.2,0.1-0.3,0.2-0.3C52.7,140.2,52.8,140.4,52.9,140.8z"/>
			<path fill="#F7F3ED" d="M42.5,144.9c0.3,0,0.5,0.2,0.6,0.5c0.1,0.2,0,0.4-0.2,0.5c-0.1,0-0.2,0.1-0.2,0.1c-0.3-0.1,0-0.3,0-0.5
				C42.7,145.3,42.6,145.1,42.5,144.9L42.5,144.9z"/>
			<path fill="#FBF9F6" d="M41.1,147.4c0.2,0,0.4,0,0.3,0.2c0,0.3-0.3,0.3-0.5,0.4c-0.1,0-0.2-0.1-0.2-0.2
				C40.7,147.5,40.9,147.4,41.1,147.4z"/>
			<path fill="#FAF7F3" d="M39.7,150.6c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.3,0.2-0.3,0.4-0.3c0.2,0,0.3,0.1,0.3,0.3
				C40.1,150.4,40,150.6,39.7,150.6z"/>
			<path fill="#F4EEE3" d="M60.3,123c0.2,0.1,0.2,0.4,0.3,0.6c0,0.2-0.2,0.2-0.3,0.2c-0.2-0.1-0.2-0.3-0.2-0.5
				C60.1,123.1,60.1,123,60.3,123z"/>
			<path fill="#FBF9F6" d="M40.1,161.4c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.4-0.2-0.3-0.4c0-0.1,0.2-0.3,0.3-0.3
				C40,161,40.1,161.2,40.1,161.4z"/>
			<path fill="#F4EFE5" d="M44.1,151.4c0,0.2,0,0.5-0.2,0.4c-0.3,0-0.4-0.3-0.4-0.5c0-0.2,0.1-0.3,0.3-0.3
				C44,151.1,44.1,151.3,44.1,151.4z"/>
			<path fill="#FCFBF9" d="M21.1,170.5c-0.2,0.1-0.4,0.3-0.6,0.3c-0.3,0.1-0.3-0.1-0.3-0.3c0-0.2,0.2-0.3,0.4-0.3
				C20.8,170.2,20.9,170.3,21.1,170.5z"/>
			<path fill="#F6F1E8" d="M7.8,178c0,0.2-0.1,0.3-0.2,0.3c-0.2,0-0.4,0-0.4-0.3c0-0.1,0.1-0.3,0.3-0.3C7.6,177.6,7.7,177.8,7.8,178
				z"/>
			<path fill="#F6F1EA" d="M34.5,154.4c0.2,0.4,0.4,0.6,0.2,0.9c-0.1,0.1-0.3,0.1-0.3,0.1C34.1,155,34.4,154.8,34.5,154.4z"/>
			<path fill="#F6F1E9" d="M60.7,115.6c0,0.2-0.1,0.4-0.3,0.3c-0.1-0.1-0.2-0.3-0.3-0.5c0,0,0.1-0.2,0.2-0.2
				C60.5,115.3,60.6,115.4,60.7,115.6z"/>
			<path fill="#F9F6F1" d="M35.2,150.6c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.2,0.1-0.4,0.2-0.3c0.1,0.1,0.3,0.3,0.3,0.4
				C35.5,150.5,35.5,150.6,35.2,150.6z"/>
			<path fill="#F9F5F0" d="M25.4,166.5c-0.5-0.3-0.7-0.6-0.2-1C25.5,165.8,25.3,166.1,25.4,166.5z"/>
			<path fill="#F4EEE3" d="M29.8,174.2c-0.1-0.3-0.4-0.5-0.1-0.8c0,0,0.3,0,0.3,0.1C30.3,173.8,30,174,29.8,174.2
				C29.8,174.2,29.8,174.2,29.8,174.2z"/>
			<path fill="#F5EFE5" d="M46.1,125.5c0.2,0,0.2-0.5,0.5-0.2c0.1,0.1,0.1,0.6,0,0.6C46.3,126.1,46.1,125.8,46.1,125.5L46.1,125.5z"
				/>
			<path fill="#FAF7F2" d="M54.5,122.3c0.2,0.1,0.4,0.2,0.3,0.4c0,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.3-0.1-0.3-0.3
				C54.1,122.4,54.3,122.3,54.5,122.3z"/>
			<path fill="#F8F5EF" d="M28.7,154.7c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3
				C28.6,154.3,28.7,154.4,28.7,154.7z"/>
			<path fill="#F4EEE4" d="M33.4,154.3c0.2,0.1,0.6,0,0.5,0.4c0,0.1-0.3,0.3-0.5,0.2C33,154.8,33.4,154.5,33.4,154.3L33.4,154.3z"/>
			<path fill="#F4EFE4" d="M40.9,142.8c-0.1,0.2-0.3,0.3-0.5,0.2c-0.2,0-0.3-0.2-0.2-0.3c0-0.2,0.2-0.2,0.3-0.2
				C40.6,142.6,40.9,142.5,40.9,142.8z"/>
			<path fill="#F6F1E9" d="M43.4,144.1c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3,0-0.3-0.2c0-0.1,0.1-0.3,0.3-0.3
				C43.3,143.8,43.4,143.9,43.4,144.1z"/>
			<path fill="#F6F1E9" d="M21.7,160.5c-0.1,0.2-0.2,0.3-0.4,0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.1,0.2-0.1,0.3-0.1
				C21.6,160.1,21.7,160.2,21.7,160.5z"/>
			<path fill="#F4EFE4" d="M35.5,177.9c0.3,0.1,0.4,0.3,0.4,0.5c0,0.1-0.1,0.2-0.2,0.2c-0.2-0.1-0.4-0.2-0.4-0.5
				C35.4,178.1,35.5,178,35.5,177.9z"/>
			<path fill="#F8F5F0" d="M34.8,140.1c0,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.4,0.3-0.4
				C34.6,139.8,34.7,139.9,34.8,140.1z"/>
			<path fill="#F6F2EA" d="M10.5,189.8c0,0.2-0.1,0.4-0.4,0.4c-0.1,0-0.2-0.2-0.2-0.2c0.1-0.2,0.1-0.4,0.3-0.5
				C10.4,189.5,10.5,189.7,10.5,189.8z"/>
			<path fill="#242322" d="M142.4,105.9c-0.3-0.2-0.4-0.5-0.5-0.8c0.9-0.4,0.6,0.3,0.7,0.7C142.5,105.9,142.5,105.9,142.4,105.9z"/>
			<path fill="#FAF8F4" d="M38.2,147.6c-0.2,0.1-0.3,0.5-0.7,0.3c-0.1-0.1-0.2-0.2-0.1-0.3c0.2-0.3,0.5-0.2,0.8-0.2
				C38.2,147.4,38.3,147.4,38.2,147.6C38.2,147.5,38.2,147.6,38.2,147.6z"/>
			<path fill="#F9F5F0" d="M32,163.5c0.4,0,0.8,0,1.2,0C32.8,163.8,32.4,163.6,32,163.5z"/>
			<path fill="#FBF9F6" d="M38.2,147.6c0-0.1,0-0.2,0-0.2c0.2-0.3,0.4-0.2,0.6,0c0.1,0.1,0.1,0.3,0.1,0.3
				C38.6,147.9,38.4,147.6,38.2,147.6z"/>
			<path fill="#FAF7F2" d="M31.7,147.5c0,0.1-0.1,0.2-0.1,0.2c-0.3,0.1-0.3-0.2-0.4-0.3c0-0.2,0.1-0.2,0.2-0.2
				C31.5,147.2,31.7,147.2,31.7,147.5z"/>
			<path fill="#F6F1E8" d="M32.9,136.3c0,0.3-0.1,0.3-0.3,0.3c-0.2-0.1-0.3-0.2-0.2-0.3c0.1-0.1,0.1-0.4,0.3-0.2
				C32.8,136.1,32.8,136.3,32.9,136.3z"/>
			<path fill="#F3EDE2" d="M33.9,132.9c0.3-0.2,0.7,0,0.7,0.2c0,0.4-0.3,0.2-0.5,0.1C34.1,133.1,34,133,33.9,132.9z"/>
			<path fill="#F3EDE1" d="M16.9,174.9c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2-0.2,0.2-0.3,0.2c-0.1,0-0.4,0-0.3-0.2
				C16.6,175.1,16.8,175,16.9,174.9z"/>
			<path fill="#F5F0E7" d="M24.5,175.3c0,0.2-0.1,0.3-0.3,0.4c-0.1,0-0.2,0-0.2-0.2c0-0.2,0.1-0.4,0.3-0.4
				C24.4,175.1,24.4,175.2,24.5,175.3z"/>
			<path fill="#F3EDE1" d="M10.7,176.4c0,0.2,0,0.3-0.1,0.3c-0.2,0-0.4-0.1-0.4-0.3c0-0.1-0.1-0.4,0.2-0.3
				C10.5,176.2,10.6,176.3,10.7,176.4z"/>
			<path fill="#F3EDE1" d="M53.7,139.9c-0.3,0.3-0.5,0.1-0.8,0C53.2,139.5,53.4,139.6,53.7,139.9z"/>
			<path fill="#F6F1E8" d="M63.8,113.7c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0-0.3,0.2-0.4
				C63.6,113.4,63.7,113.5,63.8,113.7z"/>
			<path fill="#F5F0E7" d="M39,156.3c0.2,0.1,0.3,0.2,0.3,0.3c0,0.2-0.1,0.3-0.3,0.2c-0.1-0.1-0.2-0.2-0.2-0.4
				C38.8,156.4,39,156.3,39,156.3z"/>
			<path fill="#F6F2EA" d="M55,108.2c-0.1,0-0.2-0.1-0.2-0.3c0-0.2,0.1-0.3,0.3-0.2c0.1,0.1,0.2,0.2,0.2,0.4
				C55.3,108.1,55.2,108.2,55,108.2z"/>
			<path fill="#F5EFE5" d="M22.3,159.2c-0.1,0.1-0.2,0.3-0.2,0.2c-0.1,0-0.3-0.1-0.3-0.2c-0.1-0.1,0-0.3,0.2-0.3
				C22.1,159,22.2,159.1,22.3,159.2z"/>
			<path fill="#FBFAF6" d="M58.2,123.5c0.1,0.1,0.3,0.3,0.3,0.4c0,0.2-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.2-0.4
				C57.9,123.6,58.1,123.5,58.2,123.5z"/>
			<path fill="#F7F3EC" d="M39.3,154.5c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.1-0.3c0.2-0.1,0.3,0,0.4,0.2
				C39.5,154.3,39.4,154.4,39.3,154.5z"/>
			<path fill="#F7F3EC" d="M24,153.3c0-0.2,0.1-0.5,0.4-0.4c0.1,0,0.2,0.2,0.2,0.2C24.5,153.4,24.2,153.3,24,153.3
				C24,153.3,24,153.3,24,153.3z"/>
			<path fill="#F9F7F3" d="M24.9,187.9c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.2-0.3c0-0.1,0.2-0.1,0.3-0.1
				C24.8,187.7,24.8,187.8,24.9,187.9z"/>
			<path fill="#FDFCFB" d="M25.5,146.4c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0.1-0.3,0.2-0.4c0.2-0.1,0.3,0.1,0.2,0.2
				C25.7,146.2,25.6,146.3,25.5,146.4z"/>
			<path fill="#F5F0E7" d="M15.1,189.2c0.2,0.3,0.3,0.4,0.4,0.5c0,0.1-0.1,0.2-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
				C15,189.5,15.1,189.4,15.1,189.2z"/>
			<path fill="#F7F2EB" d="M47.4,148.8c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0.1-0.2,0.2-0.1
				C47.2,148.5,47.3,148.7,47.4,148.8z"/>
			<path fill="#F5EFE5" d="M46.1,123.7c0.2,0.3,0.2,0.5,0.3,0.6c0.1,0.1,0,0.2-0.1,0.2c-0.2,0-0.3-0.1-0.3-0.3
				C45.9,124.2,46,124.1,46.1,123.7z"/>
			<path fill="#FBF9F6" d="M41.3,123.1c0.1,0,0.2,0,0.2,0c0,0.3-0.1,0.5-0.4,0.6c-0.1,0-0.2,0-0.2-0.1
				C41,123.4,41.1,123.2,41.3,123.1z"/>
			<path fill="#F6F1E9" d="M48.7,119.5c-0.2-0.4-0.3-0.5-0.3-0.5c0-0.2,0.1-0.3,0.3-0.2c0.1,0,0.2,0.2,0.2,0.2
				C48.8,119.1,48.8,119.2,48.7,119.5z"/>
			<path fill="#FCFAF8" d="M65.5,109.1c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.3,0.2-0.2,0.3-0.2c0.1,0,0.3,0.1,0.3,0.1
				C65.9,109,65.7,109.1,65.5,109.1z"/>
			<path fill="#F7F2EB" d="M55,109.4c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0,0.3,0.1
				C55.2,109.2,55.2,109.3,55,109.4z"/>
			<path fill="#F7F2EA" d="M25.4,169.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.1,0.3-0.1
				C25.2,169,25.3,169.1,25.4,169.2z"/>
			<path fill="#F6F1E9" d="M23.8,167.3c-0.4,0-0.7-0.1-1-0.2c0,0,0,0,0,0C23.3,166.6,23.5,166.9,23.8,167.3
				C23.8,167.3,23.8,167.3,23.8,167.3z"/>
			<path fill="#F3ECE0" d="M12,184.5c0,0.2,0.1,0.5-0.2,0.6c0,0-0.2-0.1-0.2-0.2C11.6,184.6,11.8,184.6,12,184.5L12,184.5z"/>
			<path fill="#F3ECE0" d="M12,184.5c-0.1-0.2-0.3-0.4,0.1-0.6c0.1,0,0.2,0.1,0.3,0.2C12.5,184.5,12.3,184.5,12,184.5
				C12,184.5,12,184.5,12,184.5z"/>
			<path fill="#FAF7F3" d="M47.3,143.6c-0.1,0.1-0.1,0.3-0.2,0.3c-0.2,0.1-0.3-0.1-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2
				C47.1,143.4,47.2,143.5,47.3,143.6z"/>
			<path fill="#F4EDE3" d="M23.7,181.5c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.2,0.2,0.2,0.3c0,0.1-0.1,0.1-0.2,0.2
				C23.9,181.7,23.9,181.6,23.7,181.5z"/>
			<path fill="#FBF9F6" d="M38.4,141.2c-0.4,0.2-0.5,0.3-0.6,0.3c-0.2,0-0.3-0.1-0.2-0.3c0-0.1,0.2-0.2,0.2-0.2
				C38,141,38.2,141.1,38.4,141.2z"/>
			<path fill="#F8F5F0" d="M35.8,140.9c-0.1,0.2-0.2,0.3-0.4,0.4c-0.1,0.1-0.2,0-0.2-0.2c0-0.1,0.1-0.3,0.2-0.4
				C35.5,140.7,35.7,140.8,35.8,140.9z"/>
			<path fill="#FAF8F4" d="M54.7,138.4c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.2,0.2-0.3,0.2c-0.1,0-0.1-0.1-0.2-0.2
				C54.5,138.6,54.5,138.5,54.7,138.4z"/>
			<path fill="#F6F1E9" d="M58.5,125.6c-0.1-0.4,0.2-0.5,0.3-0.7C58.9,125.2,58.8,125.4,58.5,125.6z"/>
			<path fill="#F6F1EA" d="M44.9,132.6c-0.1-0.3,0.1-0.3,0.2-0.4c0.1,0,0.3,0,0.2,0.1c0,0.2-0.2,0.3-0.3,0.5
				C45,132.7,44.9,132.6,44.9,132.6z"/>
			<path fill="#F7F3EC" d="M22,175.4c-0.2-0.4-0.3-0.5-0.3-0.6c0-0.2,0.1-0.3,0.3-0.2c0.1,0,0.2,0.2,0.2,0.2
				C22.2,175,22.1,175.1,22,175.4z"/>
			<path fill="#FBF9F6" d="M46.3,132.9c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.3-0.1-0.2-0.2c0-0.1,0.1-0.3,0.2-0.3
				C46.3,132.6,46.3,132.7,46.3,132.9z"/>
			<path fill="#F6F1E9" d="M20.2,180.8c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.2-0.1c0.1,0.1,0.1,0.2,0.1,0.3
				C20.4,180.7,20.3,180.7,20.2,180.8z"/>
			<path fill="#F7F3EC" d="M30.5,167.5c0,0.2,0.1,0.5-0.2,0.6c0,0-0.2,0-0.2-0.1C30.1,167.7,30.3,167.6,30.5,167.5
				C30.5,167.5,30.5,167.5,30.5,167.5z"/>
			<path fill="#F7F2EB" d="M26.1,186.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1-0.2-0.2-0.2c0.1,0,0.2-0.1,0.3-0.1
				C26,185.8,26.1,185.9,26.1,186.1z"/>
			<path fill="#F5F1E9" d="M26.9,174.4c-0.2-0.3-0.3-0.4-0.3-0.4c0.1-0.1,0.2-0.3,0.3-0.3c0.2-0.1,0.2,0.1,0.1,0.2
				C27,174,26.9,174.2,26.9,174.4z"/>
			<path fill="#F8F4ED" d="M17.7,165c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C17.6,164.7,17.7,164.8,17.7,165z"/>
			<path fill="#F7F3EB" d="M36.1,166.7c0.2,0.1,0.4,0.4,0.2,0.6c-0.2,0.2-0.3-0.1-0.3-0.3C36,166.9,36.1,166.8,36.1,166.7z"/>
			<path fill="#F6F1E8" d="M26.1,168.6c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C26,168.3,26.1,168.4,26.1,168.6z"/>
			<path fill="#141413" d="M139.8,107.1c-0.2,0-0.4-0.1-0.5-0.1c0.1-0.1,0.1-0.2,0.2-0.2c0.2,0,0.5-0.2,0.6,0.2
				C139.9,107.1,139.8,107.1,139.8,107.1z"/>
			<path fill="#FAF8F3" d="M55.8,110.4c-0.2,0-0.3-0.1-0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0.1,0.1,0.2
				C56,110.3,55.9,110.3,55.8,110.4z"/>
			<path fill="#FAF7F3" d="M57,113.1c-0.1-0.1-0.1-0.2-0.2-0.4c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.2,0.2,0.2
				C57.3,112.8,57.1,112.8,57,113.1z"/>
			<path fill="#F9F5EF" d="M49.5,113.5c0.2,0,0.4,0.1,0.5,0.1c0,0.1-0.1,0.2-0.1,0.3C49.5,114,49.5,113.7,49.5,113.5
				C49.5,113.5,49.5,113.5,49.5,113.5z"/>
			<path fill="#F5F0E6" d="M66,120.3c-0.1-0.3-0.1-0.4-0.1-0.5c0-0.1,0.1-0.1,0.2-0.2c0,0.1,0.1,0.2,0.1,0.3
				C66.2,120,66.1,120,66,120.3z"/>
			<path fill="#FDFDFC" d="M47,121.6c0,0.2,0,0.3-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1,0.1-0.2,0.2-0.2
				C46.8,121.5,46.9,121.5,47,121.6z"/>
			<path fill="#F9F6F1" d="M62.9,124.5c0-0.2,0.1-0.5,0.4-0.4c0.1,0,0.1,0.1,0.1,0.1C63.4,124.5,63.1,124.5,62.9,124.5
				C62.9,124.5,62.9,124.5,62.9,124.5z"/>
			<path fill="#FCFAF8" d="M43.7,128.2c0.1,0.1,0.3,0.1,0.3,0.2c0.1,0.1,0,0.2-0.1,0.2c-0.1,0-0.2-0.1-0.3-0.1
				C43.5,128.4,43.6,128.3,43.7,128.2z"/>
			<path fill="#F7F3EC" d="M45.7,130.8c0.1,0.2,0.1,0.3,0.1,0.4c0,0.1-0.2,0.1-0.3,0.2c0-0.1-0.1-0.2-0.1-0.2
				C45.5,131,45.5,130.9,45.7,130.8z"/>
			<path fill="#F8F3ED" d="M50.8,132.8c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.1
				C50.6,132.6,50.7,132.7,50.8,132.8z"/>
			<path fill="#F6F2EA" d="M49,137.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.2
				C48.8,137.3,48.8,137.4,49,137.5z"/>
			<path fill="#F9F5F0" d="M28.4,149.8c0-0.2,0-0.3,0-0.5c0.2,0,0.4,0,0.4,0.3C28.7,149.8,28.6,150,28.4,149.8z"/>
			<path fill="#FBFAF7" d="M39.2,158.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.1-0.2
				C39,158.3,39.1,158.4,39.2,158.4z"/>
			<path fill="#0A0A0A" d="M84.1,21.8c-0.7,0-1.5,0-2.2,0c-1,0.3-0.3,0.7-0.1,1.1c0,0.3-0.2,0.5-0.5,0.6c-0.2,0.1-0.4,0.1-0.7,0.2
				c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.2-0.3-0.3-0.2c-0.1,0.1,0.1,0.3,0.1,0.4c0,0.3,0,0.5,0,0.8c-0.2,0.4-0.4,0.8-0.9,0.9
				c-1-0.1-0.7-0.8-0.7-1.4c0-0.2,0-0.4-0.2-0.5c-0.2-0.2-0.4-0.2-0.7-0.3c-0.3-0.7-0.5-1.5-0.3-2.3c0.1-0.1,0.2-0.1,0.3-0.1
				c0.3,0.1,0.6,0.1,0.9,0c0.1,0,0.2,0,0.3,0c0.2,0.2,0.1,0,0.2-0.1c0.1-0.2,0.3-0.4,0.6-0.3c0.5,0.8,0.9,0.2,1.3,0
				c0.1-0.2,0.2-0.4,0.3-0.5c0.8-0.5,1.5-0.1,2.1,0.5c0.2,0.2,0.4,0.6,0.8,0.7c0.1,0,0.2,0.1,0.3,0.2l0,0
				C84.3,21.3,84.2,21.6,84.1,21.8z"/>
			<path fill="#0C0C0C" d="M75.2,23.5c0.1,0,0.1,0,0.2,0c0.4,0.1,0.6,0.3,0.7,0.7c0,0.1,0,0.2,0,0.3c-0.1,0.4-0.1,0.7,0.4,0.4
				c0.2-0.1,0.3-0.2,0.5-0.4c0.1-0.1,0.2-0.1,0.3-0.1c1.1-0.2,0.8,0.6,0.7,1.2c0.1,0.2,0.3,0.5,0,0.7c-0.3,0.1-0.7-0.4-0.7,0.3
				c-0.5,0.3-0.7,0.9-1.4,0.7l0,0c-0.1-0.7-0.6-1.3-1-1.9c-0.4-0.4,0-1-0.2-1.4C74.8,23.8,74.9,23.5,75.2,23.5z"/>
			<path fill="#0F0F0F" d="M82.6,31.9c0.2,0.4,0.7,0.8,0,1.2c-0.3,0.5,0,0.8,0.2,1.2l0,0c0.2,0.2,0.2,0.5,0,0.7
				c-0.3-0.3-0.5-0.6-1-0.5c-0.3-0.2-0.4-0.5-0.6-0.7c-0.4-0.6-0.6-0.2-0.9,0.1c-0.1,0.1-0.2,0.2-0.4,0.1c0-0.5-0.1-1.1,0.5-1.4
				c0.4-0.5,0.2-1.1,0.5-1.5C81,31,81.2,31,81.4,31C81.9,31.1,82.2,31.5,82.6,31.9z"/>
			<path fill="#151514" d="M86.7,15.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.3-0.7,0-1,0c-0.2,0-0.4,0-0.6,0c-0.3-0.1-0.4-0.3-0.5-0.6
				c0-0.5,0-1,0.4-1.4c0.2-0.1,0.3-0.2,0.6-0.3c0.5,0,0.9-0.3,1.4-0.2c0.5,0.2,0.5,0.6,0.3,1.1C86.8,15,86.9,15.4,86.7,15.8z"/>
			<path fill="#4E4944" d="M80.7,23.5c0.1-0.2,0.3-0.2,0.5-0.2c0.3,0.1,0.6,0.1,0.7,0.4c0.2,0.2,0.3,0.4,0.5,0.6
				c0.2,0.3,0.8,0.3,0.6,0.9c-0.1,0.4-0.5,0.4-0.9,0.5c-0.6,0.2-0.7-0.1-0.8-0.6c0,0-0.3,0-0.4,0c-0.4,0-0.9,0-0.8-0.7
				c0.4-0.3,0.9-0.1,1.3,0C81.3,24.1,80.3,24.3,80.7,23.5z"/>
			<path fill="#0A0A09" d="M85,10.3c0.2-0.1,0.5-0.1,0.7-0.2c0.2,0.3,0.1,0.8,0.4,1c0.1,0.1,0.3,0.1,0.4,0.2c0,0,0.1,0.1,0.1,0.1
				c-0.1,0.4-0.6,0.3-0.6,0.8c0,0.2-0.1,0.4-0.4,0.4c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.3-0.3,0c0,0.1-0.1,0.3-0.3,0.3
				c-0.2,0.1-0.4,0-0.6-0.1l0,0c-0.3-0.2-0.7-0.3-0.8-0.7c0-0.1,0-0.2,0-0.3c0.1-0.3,0.3-0.5,0.6-0.7c0.1,0,0.2,0,0.3,0
				c0.5,0.2,0.2-0.4,0.4-0.5C84.9,10.4,84.9,10.3,85,10.3z"/>
			<path fill="#3C3935" d="M85.3,23.7c0.4,0.1,0.7,0.4,0.9,0.7c-0.3,0.4-0.5-0.1-0.8,0c0.1,0.8,0.1,0.8-1.3,1.6
				c0-0.5-0.1-0.9,0.2-1.3c0.1-0.1,0.2-0.4,0-0.4c-1-0.1,0-0.8-0.3-1.1C84.6,23.2,84.9,23.6,85.3,23.7z"/>
			<path fill="#131312" d="M79.9,34.1c0.1-0.1,0.2-0.1,0.2-0.2c0.7-0.1,1.4-0.1,1.4,0.9c0,0.2,0.2,0.3,0.3,0.5l0,0
				c0.3,0.5,0.5,0.9,0.2,1.5c-0.1,0.1-0.3,0.2-0.4,0.2c-0.4-0.2-0.5-0.7-0.8-1c-0.2-0.1-0.5-0.1-0.7-0.3l0,0c-0.2-0.4-0.2-0.9-0.7-1
				C79.4,34.4,79.6,34.2,79.9,34.1z"/>
			<path fill="#5B544D" d="M85.5,6c0.2,0,0.4,0,0.5-0.2c0.3-0.1,0.7-0.2,1-0.4c0.3-0.1,0.7-0.5,0.9-0.1c0.2,0.3,0.3,0.9-0.4,1.1
				c-0.1,0-0.2,0.2-0.3,0.3C87,6.8,86.9,7.1,86.7,7c-0.4-0.3-0.9-0.3-1.3-0.6C85.4,6.3,85.3,6.1,85.5,6z"/>
			<path fill="#2F2D2B" d="M77.7,25.7c-1.5-0.6,0.6-0.4,0.2-0.7c-0.2-0.4-0.6-0.2-0.9-0.3c-0.3-0.4,0.2-0.7,0.2-1.1
				c0.1-0.2,0.1-0.4,0.3-0.6c0.3,0,0.5,0,0.7,0.2c-0.1,0.2,0.1,0.3,0.2,0.5c0.3,0.4-0.7,1.3,0.5,1.4c0.2,0.1,0.2,0.3,0,0.5
				c-0.1,0-0.2,0-0.3,0C78.4,26,78.1,25.7,77.7,25.7z"/>
			<path fill="#504A45" d="M76.3,34.6c-0.3-0.6-1.3,0-1.4-0.9c0.7-0.3,1.5-0.5,2.3-0.4c0.1,0,0.2,0,0.3,0
				C77.6,34.2,77.2,34.6,76.3,34.6z"/>
			<path fill="#56504A" d="M80.9,31.2c-0.1,0.5,0.8,1.3-0.5,1.5c-0.5-0.3-0.9-0.6-1.4-0.9c0.4-0.3,0.7-0.5,1-0.8
				C80.3,30.7,80.7,30.7,80.9,31.2z"/>
			<path fill="#4F4944" d="M91.5,4.7c0.6,0,1-0.8,1.7-0.4c0.8,0.4,0.1,1.1,0.3,1.7c-0.7-0.5-1.1-1.5-2.2-0.8
				C91.1,5.4,91.4,4.9,91.5,4.7z"/>
			<path fill="#21211F" d="M85.7,12.5c0.2-0.1,0.2-0.3,0.2-0.5c0.2-0.2,0.3-0.1,0.5,0c0.1,1,1.2,1.5,1.4,2.4
				c-0.4,0.2-0.7,0.8-1.2,0.2c0.2-0.4,0.3-0.8-0.3-1c-0.3-0.3-0.8-0.3-0.9-0.7C85.5,12.8,85.6,12.6,85.7,12.5z"/>
			<path fill="#48443F" d="M98.9,3.3c-0.3,0-0.6,0.1-0.9,0c-0.3-0.1-0.5-0.4-0.5-0.7c0.1-0.5,0.4-0.1,0.6,0c0.2,0.1,0.4,0.1,0.6,0
				c0.3-0.3,0.7-0.5,1-0.1c0.2,0.2,0.3,0.6,0.2,0.7C99.7,3.5,99.3,3.2,98.9,3.3z"/>
			<path fill="#48443F" d="M75.9,27.3c0.6,0,0.8-0.9,1.4-0.7c0,0.5-0.5,0.9,0,1.4c-0.4,0.2-0.8,0.4-0.7,0.9c0,0.2-0.1,0.5-0.4,0.4
				C76,29.3,75.8,29,76,28.9C76.7,28.3,76,27.8,75.9,27.3z"/>
			<path fill="#49443F" d="M82.8,30c0.3-0.8,1.2-0.6,1.5-0.4c0.9,0.5-0.4,0.9-0.3,1.5c0.1,0.3-0.4-0.3-0.7-0.4
				C83.3,30.4,82.7,30.4,82.8,30z"/>
			<path fill="#2B2A28" d="M78,41.5c-0.1,0.5,0.3,1,0,1.9c-0.1-1.2-1.3-1-1.4-1.9c-0.1-0.1-0.3-0.2-0.4-0.4c0,0,0-0.1-0.1-0.1
				c0.5-0.3,1-0.3,1.3,0.3c0.1,0.2,0.3,0.2,0.4,0C78,41.2,78.1,41.3,78,41.5z"/>
			<path fill="#534D47" d="M75.4,45.8c-0.6,0.6-0.9,1.2-0.2,1.9c-0.6,0.4-0.9-0.1-1.2-0.5c-0.5-0.7-0.2-1.3,0.5-1.7
				c0.2-0.1,0.3-0.1,0.5,0c0.1,0,0.3,0.1,0.4,0.2C75.3,45.8,75.4,45.8,75.4,45.8z"/>
			<path fill="#504A45" d="M77.3,39.1c-0.5,0-0.7,0.6-1.2,0.7l0,0c-0.3-1.1-0.1-1.8,1.2-1.9c0,0,0,0,0,0c0.1,0.4,0.1,0.8,0.2,1.2
				C77.4,39.2,77.4,39.2,77.3,39.1z"/>
			<path fill="#534D47" d="M92,19.7c-0.7,0.3-1.1,0.6-1.6,0.8c-0.2,0.1-0.7,0.3-0.8-0.3c0-0.3-0.4-0.6,0-0.8
				c0.3-0.2,0.6,0.1,0.8,0.3C90.8,20.1,91.3,19.3,92,19.7z"/>
			<path fill="#4A4540" d="M94.4,6.2c0-0.6,0.3-0.5,0.7-0.5c0.2-0.2,0.7-0.2,0.4-0.7l-0.1,0l-0.1,0c-0.1-0.2-0.1-0.3,0-0.4
				c0.7,0.2,1.5,0.4,1,1.4c-0.2,0.3-0.4,0.7-1,0.6C95,6.6,94.7,6.4,94.4,6.2z"/>
			<path fill="#514B45" d="M81.9,35.3c-0.2-0.1-0.5-0.2-0.5-0.2c0.2-1-0.7-0.9-1.2-1.2c0.1-0.5,0.3-0.8,0.8-0.9
				c0.4,0,0.3,0.5,0.5,0.7c0.1,0.3,0.5,0.4,0.4,0.8C81.6,34.8,82,35,81.9,35.3z"/>
			<path fill="#232220" d="M83.3,27.6c0.8-0.5,1.6-0.9,2.3-1.4c0.2-0.1,0.3-0.3,0.5-0.1c0.1,0.1,0.2,0.3,0.2,0.4
				c0,0.4-0.3,0.3-0.5,0.4c-0.7,0.1-1.3,0.4-1.8,1C83.7,28,83.4,28.1,83.3,27.6z"/>
			<path fill="#3B3835" d="M95.3,6.5c0.4,0,0.6-0.4,1-0.5c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.3-0.4,0.7-0.6,1c-0.1-0.6-1-0.5-1-1.1
				C94.7,7,95.2,6.9,95.3,6.5z"/>
			<path fill="#4F4944" d="M78.5,28.6c0.2-0.4,0.1-1.1,1-1.1c0.5,0,0.4,0.3,0.5,0.6c0,0.3-0.2,0.6-0.3,0.9c-0.1,0.2-0.1,0.3-0.3,0.4
				C78.7,29.5,78.7,28.9,78.5,28.6L78.5,28.6z"/>
			<path fill="#534D47" d="M83.3,27.6c0.2,0.2,0.5,0.2,0.7,0.2c0,0.5,0.1,1-0.5,1.1c-0.6,0.1-0.9-0.6-1-0.9
				C82.3,27.6,82.9,27.6,83.3,27.6z"/>
			<path fill="#524C47" d="M74.7,44.8c-0.3-0.7-0.1-1.2,0.6-1.1c0.4,0,0.7-0.5,1.1-0.2c-0.1,0.6-0.9,0.7-1,1.3
				C75.2,45.1,74.9,45.1,74.7,44.8z"/>
			<path fill="#534D47" d="M86.7,6.9c0.2-0.1,0.3-0.2,0.5-0.2c-0.1,0.4-0.5,0.9,0.2,1.2c0.2,0.1,0.4,0.2,0.2,0.5
				c-0.3,0.1-0.1,0.7-0.5,0.7c-0.4-0.1-0.2-0.4-0.2-0.7c0-0.6-1.1-0.2-0.9-0.9C86.1,7.2,86.4,7,86.7,6.9z"/>
			<path fill="#131312" d="M73.9,47.3c0.4,0.2,0.8,0.3,1.2,0.5c0.8,1.5-0.6,1-1.2,1.2c-0.3-0.4,0-0.8,0-1.3
				C73.9,47.6,73.9,47.4,73.9,47.3z"/>
			<path fill="#5B544D" d="M79.5,29.3c0-0.2,0-0.3,0-0.5c0.6-0.1,0.9,0.1,1,0.7c-0.3,0.4-0.1,0.9-0.5,1.2l0,0
				c-0.3,0-0.6,0.1-0.7-0.3c0-0.2,0-0.3,0-0.5C79.1,29.7,79.3,29.5,79.5,29.3z"/>
			<path fill="#46423D" d="M94.6,12.2c0.6,0.1,1.2-0.5,1.8,0.2c-0.6,0.2-1.2,0.3-1.5,1c-0.1,0.3-0.6,0-0.6-0.5
				C94.5,12.7,94.3,12.4,94.6,12.2z"/>
			<path fill="#353330" d="M91.7,13.4c0.3-0.1,0.5-0.6,1-0.3c0.2,0,0.3,0,0.2,0.3c-0.7,0.4-1.3,1.6-2.1,0.2c0-0.2,0.1-0.5,0-0.7
				c0.6-0.5,0.4,0.3,0.7,0.4C91.5,13.6,91.6,13.6,91.7,13.4z"/>
			<path fill="#5A534D" d="M79,25.6c0-0.2,0-0.3,0-0.5c0.3-0.3,0.5-0.6,0.8-0.9c0.3-0.2,0.4-0.1,0.5,0.2c-0.1,0.6,0.5,0.4,0.7,0.8
				c-0.2,0.2-0.4,0.4-0.7,0.4c-0.2-0.1-0.4-0.4-0.7-0.2C79.5,25.8,79.3,25.8,79,25.6z"/>
			<path fill="#3A3733" d="M83.8,46.5c0.5-0.7,0.1-1.7,0.7-2.4c0.1-0.1,0.2-0.1,0.2,0c0.3,0.5,0.3,0.9-0.1,1.4
				c-0.3,0.3-0.4,0.8-0.4,1.2C84,46.9,83.8,47,83.8,46.5z"/>
			<path fill="#292826" d="M85,17.3c0,0.1,0,0.2,0,0.2c-0.2,0.2-0.2,0.5-0.5,0.7c-0.3,0.2-0.6,0.3-1,0.1c-0.4-0.3-0.4-0.7-0.1-1.1
				c0.2-0.2,0.6-0.3,0.8-0.6C84.9,16.3,85.1,16.5,85,17.3z"/>
			<path fill="#56504A" d="M89,37.2c-0.3-0.9,0.3-1.6,0.1-2.6c0.5,0.4,0.8,0.7,1.2,1.1C89.7,36.2,88.8,36.2,89,37.2z"/>
			<path fill="#504A45" d="M94.7,34.5c-0.1,0.4-0.3,0.6-0.6,0.6c-0.3,0-0.7-0.3-0.7-0.7c0-0.4,0.2-0.6,0.6-0.6
				C94.4,33.9,94.6,34.2,94.7,34.5z"/>
			<path fill="#514B46" d="M84.8,9.6c-0.1-0.6-0.1-0.5,0.3-0.7c0.3-0.1,0.1-0.5,0.2-0.7C85.6,8.4,86.1,8.5,86,9
				c-0.1,0.5-0.6,0.5-1,0.7C84.9,9.7,84.9,9.7,84.8,9.6z"/>
			<path fill="#060606" d="M85,17.3c-0.2-0.2-0.1-0.9-0.7-0.5c-0.2,0-0.4-0.1-0.6-0.3c-0.1-0.2,0-0.4,0-0.7c0.1-0.2,0.2-0.6,0.6-0.5
				c0.2,0.2,0.4,0.3,0.5,0.5c0.3,0.3,0.6,0.5,0.8,1c0,0.3-0.2,0.4-0.4,0.5C85.1,17.3,85.1,17.3,85,17.3z"/>
			<path fill="#5C554E" d="M87.7,19c-0.4,0.8-1.2,0.9-2,0.9c-0.1-1,0.6-1.1,1.3-1.4C87.2,18.7,87.7,18.4,87.7,19z"/>
			<path fill="#312F2C" d="M80.4,29.5c-0.3-0.2-0.6-0.5-1-0.7c0-0.4,0.2-0.6,0.5-0.7c0.2,0,0.4,0,0.5,0.2c0.1,0.2,0.2,0.3,0.3,0.5
				c0.3,0.2,0.6,0.4,1,0.7c-0.2,0.2-0.4,0.2-0.6,0C80.9,29.5,80.7,29.5,80.4,29.5z"/>
			<path fill="#4B4641" d="M77.7,25.7c0.3-0.3,0.7,0.1,1,0c0,0,0,0.1,0,0.1c-0.1,0.5,1,0.6,0.5,1.1c-0.4,0.4-0.8-0.4-1.2-0.5
				C77.9,26.2,77.8,25.9,77.7,25.7z"/>
			<path fill="#58514B" d="M93.3,6.3c0.5,1,0.5,1.6-0.4,2C92.4,7.6,93,7.1,93.3,6.3z"/>
			<path fill="#4E4944" d="M94.3,16.3c-0.1,0.3-0.3,0.6-0.6,0.4c-0.3-0.1-0.9,0-0.9-0.5c0-0.2,0-0.7,0.5-0.5
				C93.7,15.8,94.2,15.8,94.3,16.3z"/>
			<path fill="#20201E" d="M86.3,33.5c0.1-0.5-0.2-1.2,0.6-1.4c0.2,0,0.5,0,0.6,0.2C86.9,32.5,87.3,33.6,86.3,33.5z"/>
			<path fill="#5B544D" d="M85.9,29.7c0.1-0.3,1-0.5,0-0.8c-0.3-0.7,0.3-0.7,0.7-0.9c0,0,0.1,0,0.1,0c0.2,0.4,0.6,0.8,0.3,1.4
				c-0.1,0.1-0.3,0.2-0.4,0.3C86.5,30,86.2,30,85.9,29.7z"/>
			<path fill="#252423" d="M82.8,30c0.1,0.3,0.5,0.3,0.5,0.7c-0.4,1,0.6,0.7,1,1.1c-1,0.4-1.5,0.2-1.4-0.8
				C82.9,30.6,82.3,30.3,82.8,30z"/>
			<path fill="#1F1E1C" d="M86.6,11.3c0.1-0.5,0.4-0.4,0.7-0.2c0.4-0.1,0.7-0.6,1.2-0.2c0,0.4-0.1,0.7-0.3,1
				c-0.2,0.1-0.5,0.1-0.8,0.1C87.1,11.9,86.9,11.6,86.6,11.3L86.6,11.3z"/>
			<path fill="#4E4943" d="M96.2,9c-0.2,0.6-0.7,0.5-1,0.8c-0.2,0.2-0.4,0.3-0.6,0.1c-0.1-0.1-0.1-0.3-0.1-0.4
				c0-0.3,0.1-0.4,0.4-0.5C95.3,9,95.7,9,96.2,9z"/>
			<path fill="#5B544D" d="M86.7,14.7c0.4,0.2,0.7-0.5,1.2-0.2c0.3,1-0.6,1-1.1,1.3C86.8,15.4,86.2,15,86.7,14.7z"/>
			<path fill="#4E4843" d="M98.7,6.9c-0.1,0.5-0.2,0.9-0.2,1.5c-0.5-0.2-0.9-0.6-0.8-1.1C97.7,6.6,98.4,7.1,98.7,6.9z"/>
			<path fill="#0D0D0C" d="M86.7,6.9c-0.1,0.5-0.4,0.5-0.8,0.5c-0.3,0-0.6,0-0.7-0.3c-0.1-0.3,0-0.5,0.2-0.7
				C86,6.3,86.5,6.4,86.7,6.9z"/>
			<path fill="#5B544D" d="M85.3,23.7c-0.6,0.4-0.8-0.3-1.3-0.5c0,0,0,0,0,0c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.5,0.4-0.7,0.8-0.2
				C85,22.9,85.1,23.3,85.3,23.7z"/>
			<path fill="#47433E" d="M73.9,47.7c0.5,0.4,0.1,0.8,0,1.3c0.1,0.2,0.1,0.5,0.2,0.7c-0.3,0.3-0.6,0.3-0.9,0
				C73.6,49,73.2,48.2,73.9,47.7z"/>
			<path fill="#4B4741" d="M79.2,45.3c0.5-0.3,0.9-0.8,1.5-0.3c0.2,0.2,0.2,0.4,0,0.6C80.2,46.1,79.8,45.5,79.2,45.3z"/>
			<path fill="#423F3A" d="M94,28.6c0.2,0.3,0.8-0.1,0.7,0.4c0,0.3-0.1,0.8-0.6,0.7c-0.3,0-0.6-0.3-0.7-0.6
				C93.5,28.9,93.6,28.6,94,28.6z"/>
			<path fill="#44413C" d="M88.6,26.9c-0.1-0.1-0.1-0.1-0.2-0.2c0.3-0.1,0.6-0.3,0.7-0.8c0-0.1,0-0.1,0-0.2c0.4-0.3,0.7,0,0.9,0.3
				c-0.3,0.6-0.6,1.1-0.9,1.7C88.8,27.4,88.8,27.1,88.6,26.9z"/>
			<path fill="#34312F" d="M81.9,23.8c-0.2-0.1-0.5-0.3-0.7-0.4c0.3,0,0.5-0.2,0.5-0.5c0-0.2,0.1-0.3,0.3-0.3
				c0.3,0.4,1.3,0.2,1.1,1.1C82.6,23.8,82.1,22.6,81.9,23.8z"/>
			<path fill="#4D4843" d="M104.7,2.9c-0.3-0.2-0.6-0.4-0.7-1c0.6,0.7,1.3,0.3,1.9,0.3c0.2,0.2,0.1,0.3,0,0.5
				c-0.2,0.3-0.5,0.2-0.7,0.2C105,3,104.8,3,104.7,2.9z"/>
			<path fill="#141514" d="M88.6,13.7c-0.4-0.4-0.1-0.7,0.2-1c0.3-0.2,0.6-0.7,1-0.2c-0.1,0.3,0.4,0.6,0,0.9
				C89.4,13.6,89,13.5,88.6,13.7z"/>
			<path fill="#524C46" d="M91,9.6c0.7,0.2,0.2,0.9,0.6,1.2C91.3,10.9,91,11,90.8,11c-0.3,0-0.2-0.3-0.2-0.5
				C90.9,10.3,90.3,9.6,91,9.6z"/>
			<path fill="#4C4742" d="M78,41.5c-0.1-0.1-0.1-0.2-0.2-0.2c0.3-0.3,0.4-0.9,1-0.4c0.5,0.4,0.5-0.4,0.9-0.3
				C79.4,41.4,78.7,41.4,78,41.5z"/>
			<path fill="#413E39" d="M97.7,16.6c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.2-0.3,0.7-0.7,0.5c-0.3-0.1-0.3-0.4-0.3-0.7
				c0.1-0.6,0.6-0.2,0.8-0.3C97.5,16.2,97.6,16.3,97.7,16.6z"/>
			<path fill="#3A3733" d="M95,21.4c-0.7,0.3-1.2,0.5-1.9-0.4C93.8,21.1,94.4,20.6,95,21.4z"/>
			<path fill="#171716" d="M73.2,61.7c0.5,0,0.5,0.4,0.5,0.8c0,0.2,0.1,0.5-0.3,0.5c-0.6,0-0.4-0.5-0.5-0.8
				C72.8,61.8,73,61.7,73.2,61.7z"/>
			<path fill="#393733" d="M108.8,3.5c-0.6,0-1.1-0.2-1.6,0c-0.1,0.1-0.3,0-0.3-0.2c0-0.1,0.1-0.3,0.1-0.3
				C107.6,2.9,108.3,2.6,108.8,3.5z"/>
			<path fill="#34322F" d="M87.6,8.4c-0.1-0.2-0.2-0.3-0.2-0.5c0.4-0.2,0-0.7,0.4-0.9C88,7,87.9,7.2,88,7.3
				C89.1,8,88.6,8.3,87.6,8.4z"/>
			<path fill="#121211" d="M86.9,18.7c-0.4,0.4-1.1,0.6-1.2,1.2c-0.2-0.2-0.3-0.3-0.5-0.5c0-0.1,0-0.2,0-0.3c0-0.2,0.6,0,0.4-0.5
				c0-0.2,0-0.3,0.1-0.4c0.2,0,0.3,0.3,0.5,0C86.7,18,86.9,18.2,86.9,18.7z"/>
			<path fill="#3D3A36" d="M86.9,18.7c-0.1-0.3-0.4-0.5-0.7-0.5c0-0.4,0.3-0.6,0.6-0.3c0.4,0.3,0.7,0.6,1.2,0.6
				c0.2,0,0.4,0.2,0.3,0.4c-0.1,0.4-0.4,0.2-0.7,0.1C87.5,18.7,87.2,18.7,86.9,18.7z"/>
			<path fill="#47423E" d="M80.9,31.2c-0.3-0.2-0.6-0.3-0.9-0.2c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0.5-0.1,1-0.1,1.4-0.4
				c0.4-0.2,0.7-0.1,0.7,0.4C82,31,81.7,31,81.4,31.2C81.2,31.2,81.1,31.2,80.9,31.2z"/>
			<path fill="#534D47" d="M93.3,24.1c0.6,0.6,0,1.2,0.1,1.7c0,0-0.2,0.2-0.3,0.2c-0.2,0.1-0.3-0.1-0.3-0.3
				C92.9,25.1,93,24.6,93.3,24.1z"/>
			<path fill="#1D1D1C" d="M81.4,31.2c0.1-0.3,0.4-0.5,0.7-0.5c0.8,0.2,0.4,0.8,0.5,1.2C82,31.9,81.8,31.4,81.4,31.2z"/>
			<path fill="#4E4843" d="M94.8,3.6c-0.1-0.3-0.8-0.2-0.7-0.7c0.4-0.1,0.7-0.8,1.1-0.3C95.4,2.9,95.3,3.4,94.8,3.6z"/>
			<path fill="#5D554E" d="M85.2,19.2c0,0.1,0,0.1,0,0.2c-0.3,0.2-0.6,0.3-0.9,0.3c-0.1-0.1-0.2-0.1-0.2-0.2
				c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.5-0.6-0.4-1c0.3,0,0.7-0.1,1-0.2c0,0,0.1,0.1,0.1,0.1C84.8,18.5,84.9,18.9,85.2,19.2z"/>
			<path fill="#262523" d="M105.3,5.4c-0.4,0.2-0.3,0.9-0.9,0.7c-0.2-0.1-0.5-0.3-0.3-0.5C104.3,5,104.8,5.3,105.3,5.4z"/>
			<path fill="#4D4843" d="M97.7,16.6c-0.2-0.1-0.3-0.2-0.5-0.2C97,16,97,15.6,97.3,15.4c0.2-0.2,0.5,0,0.6,0.2
				C98.2,15.9,98.1,16.3,97.7,16.6z"/>
			<path fill="#111110" d="M95.3,6.5c0.5,1.2-0.5,0.7-1,0.8c0.2-0.3,0.2-0.7,0-1C94.7,6.3,95.1,6.1,95.3,6.5z"/>
			<path fill="#3D3936" d="M96.3,21.8c0.3,0.3,0.8,0.2,1,0.6c-0.5,0.3-1.1-0.1-1.5,0.4c-0.2-0.5,0.3-0.6,0.3-1
				C96.1,21.7,96.2,21.7,96.3,21.8z"/>
			<path fill="#504B45" d="M91.7,21.6c-0.4,0-0.7-0.2-0.7-0.6c0-0.2,0.2-0.4,0.4-0.3c0.3,0.1,0.9-0.2,0.8,0.5
				C92,21.2,92,21.6,91.7,21.6z"/>
			<path fill="#3A3834" d="M84.3,19.4c0,0.1,0,0.2,0,0.3c0.5,0.6-0.1,0.9-0.2,1.4c-0.1,0-0.2,0-0.3-0.1c-0.3-0.2-0.5-0.3-0.5-0.7
				c0.1-0.3,0.3-0.5,0.5-0.8C83.9,19.4,84,19.3,84.3,19.4z"/>
			<path fill="#4E4943" d="M84.1,40.6c0.5,0,0.7-0.8,1.2-0.4c0.1,0.1,0,0.5-0.1,0.7C84.8,41.3,84.5,40.8,84.1,40.6z"/>
			<path fill="#46423D" d="M80.9,35.7c0.4,0.3,0.6,0.7,0.8,1.2c-0.1,0.2-0.1,0.6-0.2,0.6c-0.4,0.1-0.2-0.5-0.5-0.6
				c-0.3-0.1-0.1-0.5-0.3-0.7l0-0.1C80.6,35.9,80.7,35.8,80.9,35.7z"/>
			<path fill="#292826" d="M91,9.6c-0.4,0.2-0.1,0.8-0.5,1c-0.1-0.4,0.3-1.1-0.6-1.2c-0.4-0.1-0.3-0.2,0.3-0.7
				c0.1-0.1,0.2-0.1,0.3,0l0,0c0.2,0.2,0.3,0.4,0.5,0.7C91,9.4,91,9.5,91,9.6z"/>
			<path fill="#5D564F" d="M85.9,29.7c0.3,0,0.5,0,0.8,0c0.1,0.3,0.4,0.6,0,0.9c-0.3,0.2-0.5,0-0.7-0.1C85.6,30.3,85.7,30,85.9,29.7
				z"/>
			<path fill="#59534C" d="M100.4,10.7c0.4,0.3,1-0.4,1.3,0.3c0.1,0.3-0.3,0.3-0.5,0.4C100.6,11.7,100.6,11.1,100.4,10.7z"/>
			<path fill="#4E4843" d="M81.9,22.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.2-0.9-0.3-0.8-0.8c0.1-0.4,0.6-0.4,1-0.2
				C81.9,22.1,81.9,22.3,81.9,22.5z"/>
			<path fill="#544E48" d="M82.3,50.7c0.4,0.1,0.7,0.4,0.8,0.7c0.2,0.4-0.3,0.3-0.5,0.4c-0.5,0.1-0.3-0.4-0.4-0.6
				C82,51,82.2,50.8,82.3,50.7z"/>
			<path fill="#43403B" d="M81.4,42.2c0-0.8,0.7-1,0.9-1.7c0.7,1.1-0.5,1.1-0.7,1.7C81.5,42.3,81.5,42.3,81.4,42.2z"/>
			<path fill="#45413D" d="M74.2,50.1c-0.3,0.2-0.6,0.4-0.8,0.7c-0.5,0.7-0.7,0.3-0.9-0.2c-0.1-0.1-0.1-0.1-0.1-0.1
				c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2-0.1C73.2,50.1,73.6,49.7,74.2,50.1z"/>
			<path fill="#4A4540" d="M93.5,10.4c-0.1,0.2-0.1,0.6-0.3,0.6c-0.6-0.2,0.1-1-0.4-1.3c-0.1,0,0.1-0.3,0.3-0.2
				C93.7,9.5,93.2,10.1,93.5,10.4z"/>
			<path fill="#514B46" d="M92.9,13.5c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.5,0.4-0.7,0.7-0.5c0.4,0.2,0.2,0.6,0,0.9
				C93.1,14,93,13.7,92.9,13.5z"/>
			<path fill="#524C46" d="M78.7,33.7c0.1-0.6-0.5-0.7-0.7-1c0.5-0.4,0.9-0.1,1.1,0.3C79.3,33.2,79.3,33.7,78.7,33.7z"/>
			<path fill="#4F4944" d="M82.5,27.5c-0.6,0-0.8,0-1.1,0c-0.2,0-0.5,0.1-0.6-0.1c-0.1-0.1-0.1-0.3,0.1-0.4c0.3,0,0.5-0.5,0.8-0.1
				C81.8,27.1,82,27.2,82.5,27.5z"/>
			<path fill="#48433E" d="M101.8,1c0.4-0.2,0.6-0.1,0.8,0c0.3,0.1,0.7,0.1,0.8,0.6c0,0.1-0.1,0.2-0.1,0.3c-0.4,0.2-0.5-0.3-0.7-0.4
				C102.3,1.3,102.1,1.1,101.8,1z"/>
			<path fill="#57504A" d="M86.8,20.7c-0.1,0.2-0.1,0.4-0.3,0.5c-0.4,0.2,0,0.8-0.5,0.8c-0.3,0-0.2-0.3-0.2-0.6
				C85.8,20.7,86.2,20.6,86.8,20.7z"/>
			<path fill="#201F1E" d="M93.7,20.3c-0.1,0-0.2,0-0.2,0c-0.4-0.1-0.8-0.2-0.5-0.8c0.1-0.3,0.2-0.6,0.5-0.5
				c0.5,0.1-0.1,0.6,0.2,0.9C93.8,20.1,93.8,20.2,93.7,20.3z"/>
			<path fill="#504A45" d="M103.6,14.7c0.4,0.1,0.6,0.3,0.7,0.7c0,0.2-0.2,0.3-0.4,0.4c-0.5,0-0.6-0.3-0.6-0.7
				C103.2,14.9,103.4,14.8,103.6,14.7z"/>
			<path fill="#4A4540" d="M87.6,24.5c0,0.2,0,0.3,0,0.5c0.1,0.1,0.1,0.1,0,0.2c0,0,0,0.1-0.1,0.1c-0.2,0.1-0.3,0.2-0.5,0.3
				c-0.1,0.1-0.2,0.4-0.5,0.2C86.1,24.6,87.3,24.8,87.6,24.5L87.6,24.5z"/>
			<path fill="#1C1C1A" d="M87.9,48.2c0.7-0.1-0.1-0.9,0.5-1c0.6,1,0.6,1-0.5,1.5C87.7,48.5,87.7,48.4,87.9,48.2z"/>
			<path fill="#3A3733" d="M85.2,17.3c0.1-0.2,0.2-0.3,0.3-0.5c0-0.3-0.2-0.6,0-0.9c0.4-0.2,0.7-0.4,0.9,0.2c-0.2,0-0.5,0.1-0.4,0.4
				C86.3,17.3,85.7,17.3,85.2,17.3z"/>
			<path fill="#4A4540" d="M64.6,59.1c-0.1,0.5-0.4,0.3-0.7,0.3c-0.3,0.1-0.3-0.3-0.3-0.5c0-0.3,0.3-0.5,0.5-0.5
				C64.4,58.5,64.5,58.9,64.6,59.1z"/>
			<path fill="#514B45" d="M88.6,26.9c0.5,0,0.5,0.4,0.5,0.7c-0.5,0.4-0.9,0-1.4-0.2C87.8,26.9,88.5,27.5,88.6,26.9z"/>
			<path fill="#4C4742" d="M84.3,36.9c-0.9,0.3-0.9-0.5-1.2-0.9c0.5-0.4,0.6,0.2,0.9,0.3C84.2,36.4,84.4,36.6,84.3,36.9z"/>
			<path fill="#272624" d="M101.4,18.5c0.2-0.8,0.8-0.8,1.4-0.9c0,0,0.2,0.2,0.1,0.3C102.6,18.5,102,18.1,101.4,18.5z"/>
			<path fill="#403D38" d="M82.8,34.3c-0.2,0-0.7,0.1-0.6-0.2c0.1-0.3-0.4-0.9,0.4-1C82.9,33.5,83.2,33.8,82.8,34.3z"/>
			<path fill="#47433E" d="M96.9,4.8c0.2-0.3-0.7-1.2,0.5-1c0.2,0,0.4,0.5,0.7,0.8C97.8,4.8,97.4,5,96.9,4.8z"/>
			<path fill="#47433E" d="M88.5,40.9c0.3,0.1,0.7-0.3,0.8,0.2c0,0.6-0.5,0.3-0.8,0.4c-0.2,0-0.5,0-0.4-0.3
				C88.1,40.9,88.1,40.6,88.5,40.9z"/>
			<path fill="#4E4944" d="M72.5,53.6c-0.4,0.3-0.7,0.3-0.7-0.2c0-0.5,0-0.9,0.7-0.7C72.8,52.9,72.7,53.2,72.5,53.6z"/>
			<path fill="#554F49" d="M79.5,34.8c1.1-0.3,1.1,0.2,0.7,1C79.4,35.8,79.6,35.2,79.5,34.8z"/>
			<path fill="#1A1918" d="M76.2,52.8c0.2-0.1,0.3-0.2,0.4,0c0.1,0.4,0.8,0.4,0.6,1c0,0.1-0.1,0.1-0.2,0.1
				C76.4,53.7,76.3,53.2,76.2,52.8z"/>
			<path fill="#1D1C1B" d="M94.6,12.2c0,0.3,0.1,0.6-0.2,0.7c-0.3-0.3-0.5-0.7-0.8-1C94.1,11.3,94.3,11.9,94.6,12.2z"/>
			<path fill="#4F4A45" d="M76.3,50.4c0,0.3-0.1,0.4-0.4,0.5c-0.5,0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.2-0.5,0.2-0.5
				C76.1,49.9,76.3,50.1,76.3,50.4z"/>
			<path fill="#48443F" d="M86.7,11.3c0.3,0.1,0.5,0.5,1,0.5c0.1,0.2,0.1,0.3,0,0.5c-0.4-0.2-0.7-0.6-1.2-0.3c-0.2,0-0.3,0-0.5,0
				C85.8,11.3,86.6,11.7,86.7,11.3z"/>
			<path fill="#171716" d="M90.5,8.6c-0.1,0-0.2,0-0.3,0c-0.3-0.5,0.3-0.8,0.2-1.2l0,0C92.4,7.8,90.5,8.2,90.5,8.6z"/>
			<path fill="#57504A" d="M91,30.7c-0.4,0.3-0.7,0.7-1.2,0.4c-0.1-0.1-0.2-0.3,0-0.4C90.1,30.4,90.5,30.3,91,30.7z"/>
			<path fill="#4A4540" d="M89.5,17.1c-0.4-0.1-0.6,0.5-1.1,0.3c0.5-1,0.7-1.1,1.4-0.4C89.7,17.1,89.6,17.1,89.5,17.1z"/>
			<path fill="#46413D" d="M91.9,15.3c-0.9,0.2-0.9,0.2-1.3-0.6c0.4,0.1,0.9,0.1,1.3,0.2C92.1,15,92.1,15.2,91.9,15.3z"/>
			<path fill="#1D1C1B" d="M91.8,27.1c0.3,0,0.5,0.1,0.5,0.4c0,0.3-0.3,0.4-0.5,0.4c-0.2,0-0.5-0.1-0.5-0.4
				C91.3,27.3,91.6,27.2,91.8,27.1z"/>
			<path fill="#4B4641" d="M93,27.4c0.7,0,1-0.4,1.3,0c0.1,0.2-0.1,0.6-0.3,0.7C93.4,28.3,93.6,27.7,93,27.4z"/>
			<path fill="#4A4540" d="M84.8,9.6c0.1,0,0.2,0,0.3,0c0,0.2,0,0.5,0,0.7c0,0.1,0,0.2-0.1,0.2c-0.2,0.2-0.5,0.3-0.7,0.5l0,0
				c-0.3,0.1-0.4-0.1-0.5-0.3C84.1,10.4,84.5,10,84.8,9.6z"/>
			<path fill="#413D39" d="M80.8,43.8c0.3-0.1,0.5,0.1,0.5,0.3c0,0.3-0.2,0.4-0.5,0.4c-0.3,0-0.6-0.1-0.6-0.4
				C80.3,43.8,80.6,43.9,80.8,43.8z"/>
			<path fill="#433F3B" d="M89.5,31.8c-0.2,0.1-0.3,0.2-0.3,0.1c-0.4-0.3-1,0.1-1.3-0.4c0-0.1,0.1-0.3,0.1-0.3
				C88.6,31.1,89,31.4,89.5,31.8z"/>
			<path fill="#4C4742" d="M86.2,11.1c-0.2,0-0.5,0.3-0.6,0c-0.3-0.4,0.2-0.7,0.2-1C86.5,10.2,86.5,10.5,86.2,11.1z"/>
			<path fill="#413E3A" d="M89.8,12.5c-0.4-0.2-0.6,0.4-1,0.2c0.1-0.4,0.2-0.8,0.3-1.2c0.4-0.2,0.4,0.5,0.8,0.4
				c0.2,0.1,0.3,0.1,0.5,0.2C90.2,12.2,90,12.4,89.8,12.5z"/>
			<path fill="#242322" d="M98.2,4.8c0.4,0.1,0.5-0.5,0.9-0.4C99,5.2,99,5.2,97.7,5C97.7,4.6,97.9,4.6,98.2,4.8z"/>
			<path fill="#4C4742" d="M87.2,44.4c0.2,0.2,0.4,0.4,0.3,0.7c0,0.1-0.2,0.2-0.2,0.2c-0.4-0.3-0.9-0.4-0.8-1.1
				C86.8,43.9,87,44.1,87.2,44.4z"/>
			<path fill="#1B1B1A" d="M87.6,25.2c0-0.1,0-0.2,0-0.2c0.3,0.1,0.8-0.2,0.9,0.3c0.1,0.3-0.2,0.4-0.5,0.4
				C87.5,25.9,87.7,25.4,87.6,25.2z"/>
			<path fill="#232321" d="M81.7,54.2c-0.2,0.3-0.4,0.5-0.7,0.5c-0.2,0-0.3-0.2-0.2-0.3c0.1-0.3,0.3-0.5,0.7-0.5
				C81.6,53.8,81.7,54,81.7,54.2z"/>
			<path fill="#292826" d="M86.7,34.7c-0.5,0-0.9,0.1-1.1-0.2c-0.1-0.1,0.1-0.4,0.3-0.4C86.4,33.8,86.5,34.2,86.7,34.7z"/>
			<path fill="#48443F" d="M99.7,7.5c-0.1,0.2-0.2,0.6-0.4,0.4c-0.3-0.3-0.3-0.8-0.1-1.2c0.2-0.4,0.3,0,0.4,0.2
				C99.8,7.1,99.8,7.3,99.7,7.5z"/>
			<path fill="#3C3935" d="M99.7,7.5c0-0.2,0-0.4,0-0.5C99.7,7,99.8,7,99.8,7c0.3,0.2,0.9,0.1,0.9,0.6c0,0.3-0.2,0.6-0.6,0.2
				C100,7.7,99.9,7.4,99.7,7.5z"/>
			<path fill="#1F1E1D" d="M87.6,24.5c-0.2-0.7,0.5-0.5,0.8-0.8c0,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.4-0.2,0.4
				C88.2,24.4,87.9,24.4,87.6,24.5C87.6,24.5,87.6,24.5,87.6,24.5z"/>
			<path fill="#3A3834" d="M84.3,36.9c-0.1-0.2-0.2-0.5-0.2-0.7c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.5-0.5,0.8-0.2
				c0.3,0.4-0.3,0.3-0.4,0.5C84.5,36.6,84.4,36.8,84.3,36.9z"/>
			<path fill="#2F2D2B" d="M96.3,21.8c-0.1,0-0.2,0-0.2,0c-0.2-0.3-0.5-0.5-0.7-0.8C95.7,21.3,97,20.4,96.3,21.8z"/>
			<path fill="#44403C" d="M99.6,10.9c-0.5,0.1-0.9,0.2-1.2-0.2c0-0.1,0-0.3,0-0.3C98.8,10.4,99.2,10.5,99.6,10.9z"/>
			<path fill="#413E3A" d="M95.3,4.6c0,0.1,0,0.3,0,0.4c-0.2,0.1-0.4,0.1-0.6,0.2c-0.3,0.1-0.5-0.1-0.5-0.3c0-0.1-0.1-0.4,0-0.4
				c0.3-0.3,0.3,0.2,0.5,0.2C94.9,4.7,95.1,4.6,95.3,4.6z"/>
			<path fill="#383633" d="M88.6,10.8c-0.5-0.2-0.8,0.5-1.2,0.2c0.2-0.7,0.7-0.7,1.2-0.6C88.9,10.4,88.7,10.7,88.6,10.8z"/>
			<path fill="#3E3B37" d="M81.1,39.1c0-0.9-0.8,0.2-1-0.5c0.5-0.1,1.1-0.6,1.5,0.3C81.5,39.1,81.4,39.2,81.1,39.1z"/>
			<path fill="#302F2C" d="M95.5,17.2c-0.3,0.5-0.9,0.5-1.4,0.7c0-0.8,0.5-0.7,0.9-0.7C95.2,17.1,95.3,17.1,95.5,17.2z"/>
			<path fill="#1B1B1A" d="M66,54.8c0.2,0.4,0.2,0.8-0.1,1.3C65.3,55.5,65.7,55.2,66,54.8z"/>
			<path fill="#2B2A27" d="M76,61.7c0.1,0.5-0.3,0.5-0.5,0.6c-0.1,0.1-0.3,0-0.3-0.2c0.1-0.3,0.1-0.6,0.5-0.7
				C75.8,61.4,75.9,61.6,76,61.7z"/>
			<path fill="#3D3A36" d="M74.8,52.1c0.3,0.1,0.3,0.4,0.3,0.7c0,0.2-0.1,0.5-0.3,0.4c-0.3-0.1-0.3-0.4-0.3-0.7
				C74.6,52.3,74.5,52.1,74.8,52.1z"/>
			<path fill="#1D1D1C" d="M74.2,50.1c-0.5,0.1-1,0.2-1.5,0.2c0.2-0.2,0.3-0.5,0.5-0.7c0.3,0,0.6,0,0.9,0
				C74.5,49.8,74.2,50,74.2,50.1z"/>
			<path fill="#59524C" d="M89.8,11.9c-0.7,0.6-0.4-0.5-0.8-0.4c0-0.3,0.1-0.5,0.4-0.4C90,11.2,89.8,11.6,89.8,11.9z"/>
			<path fill="#353330" d="M87.9,48.2c0,0.2,0,0.3,0,0.5c-0.3,0-0.7,0.2-0.8-0.2c-0.1-0.2,0.1-0.4,0.2-0.5
				C87.6,47.7,87.7,48,87.9,48.2z"/>
			<path fill="#3B3835" d="M91.5,38.6c-0.2,0.4,0.1,1.1-0.7,1c-0.1-0.1-0.4-0.2-0.2-0.5c0.4,0,0.5-0.3,0.7-0.5
				C91.3,38.5,91.4,38.5,91.5,38.6z"/>
			<path fill="#5B544E" d="M81.1,39.1c0.2-0.1,0.3-0.1,0.5-0.2c0.3,0.5-0.1,0.6-0.5,0.8c-0.2,0.1-0.4,0.2-0.4-0.1
				C80.7,39.3,80.9,39.2,81.1,39.1z"/>
			<path fill="#1B1B1A" d="M90,25.9c-0.3-0.1-0.6-0.2-0.9-0.3c0.2-0.7,0.8-0.2,1.2-0.6C90.5,25.6,90,25.7,90,25.9z"/>
			<path fill="#45413D" d="M81.4,42.2c0.1,0,0.1,0,0.2,0c0.8-0.4,0.8,0.2,0.9,0.7c-0.6,0.2-0.7-0.6-1.2-0.5l0,0
				C81.4,42.4,81.4,42.3,81.4,42.2z"/>
			<path fill="#44403C" d="M72.5,53.6c0-0.3,0-0.7,0-1c0.1,0,0.2,0.1,0.3,0.1c0.3,0.3,0.5-0.5,0.6-0.1c0.1,0.3-0.2,0.5-0.4,0.6
				C72.9,53.3,72.7,53.5,72.5,53.6z"/>
			<path fill="#1E1E1C" d="M102.3,15.3c-0.4,0.1-0.9,0.1-1.1-0.3c0,0,0-0.2,0.1-0.2C101.8,14.7,102,15,102.3,15.3z"/>
			<path fill="#2A2927" d="M78,43.8c-0.1,0.3-0.2,0.3-0.4,0.3c-0.3,0-0.4-0.2-0.4-0.4c0-0.1,0.2-0.3,0.2-0.3
				C77.6,43.5,77.8,43.7,78,43.8z"/>
			<path fill="#524C47" d="M80.7,28.8c-0.1-0.1-0.6-0.2-0.3-0.5c0.4-0.2,0.9,0.2,1.3,0.1C81.4,28.5,81.1,28.7,80.7,28.8z"/>
			<path fill="#4A4540" d="M82.1,36.7c-0.1-0.5-0.2-1-0.2-1.5C82.3,35.7,83.1,36.1,82.1,36.7z"/>
			<path fill="#47433E" d="M92.3,18.6c-0.2-0.3-0.3-0.7,0.1-0.9c0.1-0.1,0.3,0,0.4,0.2C93,18.2,92.7,18.4,92.3,18.6z"/>
			<path fill="#4F4A44" d="M69.6,55c-0.3-0.1-0.7,0.4-1,0c0.3-0.6,0.8-0.5,1.2-0.2C69.9,54.9,69.9,55,69.6,55z"/>
			<path fill="#3A3733" d="M85.7,18.2c0,0.2,0,0.3,0,0.5c-0.1,0.1-0.3,0.2-0.4,0.1c-0.2-0.1-0.4-0.3-0.6-0.5
				c-0.1-0.1-0.1-0.3-0.2-0.4c0,0,0,0,0,0c0.1-0.2,0.3-0.4,0.5-0.5C85,18,85.4,18.1,85.7,18.2z"/>
			<path fill="#373532" d="M96.5,36.5c0,0.8-0.3,1-0.8,1.2C95.6,37,96,36.9,96.5,36.5z"/>
			<path fill="#21211F" d="M86.3,48.7c-0.1,0.3-0.3,0.3-0.5,0.4c-0.3,0.1-0.3-0.2-0.2-0.4c0.1-0.1-0.1-0.5,0.2-0.4
				C86,48.3,86.3,48.4,86.3,48.7z"/>
			<path fill="#5C554E" d="M100.9,14.7c-0.4-0.5-0.7-0.8-0.4-1.2c0.1-0.1,0.3-0.2,0.4,0C101.3,13.9,100.8,14.1,100.9,14.7z"/>
			<path fill="#5E564F" d="M87.6,12.3c0-0.2,0-0.3,0-0.5c0.2-0.4,0.5-0.1,0.7,0C88.3,12.3,88.3,12.8,87.6,12.3z"/>
			<path fill="#3A3734" d="M76.8,63.5c0.5-0.5,0.9-0.5,1.4-0.2C77.8,63.7,77.4,63.5,76.8,63.5z"/>
			<path fill="#403C39" d="M78.6,46.8c-1-0.1-1.1-0.5-0.9-1.2C77.8,46.1,78.2,46.3,78.6,46.8z"/>
			<path fill="#1D1C1B" d="M76.4,45.6c0.1,0.1,0.2,0.2,0.2,0.3c-0.1,0.3,0.6,0.4,0.1,0.8c-0.1,0.1-0.3,0.3-0.4,0.3
				c-0.4-0.2,0-0.6-0.2-0.8C76.1,45.9,76.2,45.7,76.4,45.6z"/>
			<path fill="#3D3A36" d="M77.8,55.9c0.1,0.5,0.2,0.9-0.2,1.1c0,0-0.2,0-0.2-0.1C77.2,56.5,77.3,56.2,77.8,55.9z"/>
			<path fill="#141414" d="M86.7,28.1C86.5,28.5,86,28.5,86,29c-0.1,0.2-0.1,0.2-0.3,0c-0.3-0.5,0-0.8,0.2-1.2
				c0.2-0.1,0.3-0.1,0.5,0C86.5,27.9,86.6,28,86.7,28.1z"/>
			<path fill="#433F3B" d="M63.1,62c0,0.2-0.1,0.4-0.3,0.4c-0.4,0-0.3-0.3-0.4-0.6c0-0.2,0-0.5,0.3-0.4C62.9,61.5,63,61.8,63.1,62z"
				/>
			<path fill="#5A534D" d="M98.2,4.8c-0.2,0-0.4,0-0.5,0.3c-0.3,0.1-0.5,0.4-0.8,0.1c-0.1-0.1-0.1-0.3,0.1-0.4
				c0.4-0.2,0.8-0.3,1.2-0.2C98.2,4.6,98.2,4.7,98.2,4.8z"/>
			<path fill="#0E0E0E" d="M87.2,44.4c-0.2-0.1-0.5-0.1-0.7-0.2c0-0.3,0.1-0.5,0.1-0.9C86.9,43.6,87.6,43.6,87.2,44.4z"/>
			<path fill="#2F2E2B" d="M78,52.2c0.3-0.2,0.5-0.5,0.9-0.2c0.1,0.1,0.1,0.3,0,0.4C78.5,52.7,78.2,52.4,78,52.2z"/>
			<path fill="#1F1E1D" d="M92.8,47.9c-0.4,0-0.7-0.2-0.7-0.6c0-0.1,0.2-0.2,0.2-0.2C92.8,47.2,92.8,47.5,92.8,47.9z"/>
			<path fill="#4C4742" d="M76.4,45.6c0,0.2,0,0.4-0.3,0.5c-0.2-0.1-0.5-0.2-0.7-0.3c0,0,0,0,0,0c-0.1-0.1-0.2-0.3-0.1-0.5
				c0.1-0.1,0.2-0.1,0.3,0C75.9,45.3,76.2,45.3,76.4,45.6z"/>
			<path fill="#4F4A45" d="M85.8,38.4c0-0.5,0-0.8,0.3-1.1C86.6,37.8,86.5,38.1,85.8,38.4z"/>
			<path fill="#1A1A19" d="M82.8,45.9c0,0.2,0,0.4-0.2,0.3c-0.2,0-0.3-0.2-0.4-0.4c0-0.2,0-0.4,0.3-0.4
				C82.7,45.5,82.8,45.7,82.8,45.9z"/>
			<path fill="#252523" d="M100.1,16.2c-0.2-0.1-0.3-0.3-0.4-0.5c0-0.2,0.1-0.3,0.3-0.3c0.3,0,0.4,0.3,0.3,0.5
				C100.3,16,100.3,16.2,100.1,16.2z"/>
			<path fill="#5A534C" d="M83.8,8.2c0-0.1,0-0.2,0-0.2c0.3-0.2,0.6-0.3,0.9-0.2c0.1,0.4-0.1,0.6-0.4,0.7
				C84.1,8.4,83.9,8.3,83.8,8.2z"/>
			<path fill="#32302D" d="M85.5,6.4c-0.1,0.2-0.2,0.5-0.2,0.7c-0.2,0-0.5,0.3-0.3-0.2c0.2-0.4,0.4-0.8,0.4-1.2
				c0.1,0.1,0.2,0.2,0.2,0.2C85.5,6.1,85.5,6.3,85.5,6.4z"/>
			<path fill="#4B4641" d="M90.3,22.8c-0.3-0.1-0.3-0.3-0.2-0.4c0.1-0.2,0.1-0.4,0.4-0.4c0.2,0.1,0.2,0.3,0.2,0.5
				C90.7,22.6,90.6,22.8,90.3,22.8z"/>
			<path fill="#4E4944" d="M98.8,9.6c-0.3,0.1-0.4-0.1-0.4-0.3c0-0.2,0.1-0.4,0.4-0.3c0.2,0,0.5,0.2,0.5,0.3
				C99.3,9.5,98.9,9.5,98.8,9.6z"/>
			<path fill="#353330" d="M83.3,39.3c0.4,0.4,0.3,0.8,0.5,1.4C83.3,40.2,82.9,39.9,83.3,39.3z"/>
			<path fill="#2B2927" d="M97.7,11.7c0.2-0.4,0.6-0.4,0.8-0.1c0.3,0.4,0.1,0.6-0.4,0.6C97.9,12.1,97.7,12,97.7,11.7z"/>
			<path fill="#161615" d="M85.4,43.7c-0.2,0.1-0.3,0.2-0.5,0.2c-0.3-0.2-0.5-0.3-0.3-0.7c0-0.2,0-0.4,0.3-0.4
				c0.2,0,0.2,0.2,0.2,0.3C85.3,43.3,85.7,43.4,85.4,43.7z"/>
			<path fill="#48443F" d="M84.1,21.8c0-0.2,0-0.5,0-0.7c0.3-0.1,0.7-0.4,0.8,0C84.9,21.6,84.3,21.6,84.1,21.8z"/>
			<path fill="#3F3B38" d="M88.6,13.7c0.3-0.6,0.8-0.2,1.3-0.2C89.5,13.9,89,13.9,88.6,13.7z"/>
			<path fill="#1F1F1D" d="M75.7,45.3c-0.1,0-0.2,0-0.2,0.1c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2-0.1-0.3-0.1-0.5-0.2
				c0.1-0.3,0.2-0.5,0.3-0.8c0.2,0,0.5,0,0.7,0C75.5,45,75.6,45.2,75.7,45.3z"/>
			<path fill="#1C1C1B" d="M113.4,4.1c-0.4,0.1-0.7,0-1.1-0.3C112.9,3.5,113.2,3.8,113.4,4.1z"/>
			<path fill="#554F49" d="M86,27.8c-0.1,0.4-0.2,0.8-0.3,1.2c-0.6,0-0.4-0.4-0.3-0.7c-0.1-0.2-0.1-0.3,0-0.5c0.1,0,0.1-0.1,0.2-0.1
				C85.8,27.7,85.9,27.7,86,27.8z"/>
			<path fill="#262523" d="M88.1,20.9c0.3-0.1,0.6-0.2,0.9-0.3c0.1,0.6-0.2,0.8-0.7,0.8C88.2,21.2,88,21.1,88.1,20.9z"/>
			<path fill="#433F3B" d="M97,33.2c0-0.4-0.2-0.8,0.2-1c0.1,0,0.3,0.1,0.3,0.1C97.5,32.6,97.4,32.9,97,33.2z"/>
			<path fill="#2D2B29" d="M72.2,55.7c0,0.5-0.2,0.7-0.5,0.8c0,0-0.2-0.1-0.2-0.2C71.5,55.9,71.8,55.8,72.2,55.7z"/>
			<path fill="#5B534D" d="M76,55.2c-0.5-0.5-0.2-0.8-0.2-1.3C76.3,54.3,76.1,54.7,76,55.2z"/>
			<path fill="#20201E" d="M85.7,53.8c0,0.5-0.5,0.7-0.4,1.3c-0.6-0.5,0-0.9-0.1-1.2C85.4,53.6,85.5,53.6,85.7,53.8z"/>
			<path fill="#1D1D1C" d="M104.7,2.9c0.2,0,0.3,0,0.5,0c0.1,0.3,0.4,0.7,0.1,0.8C104.7,3.8,104.8,3.2,104.7,2.9z"/>
			<path fill="#191918" d="M79.5,47.3c0.5,0.2,0.8,0.4,0.8,0.8c0,0-0.1,0.1-0.2,0.1C79.7,48.2,79.6,47.8,79.5,47.3z"/>
			<path fill="#262524" d="M78.8,41.7c0.6,0.5,0.5,0.9,0.1,1.4C78.9,42.7,78.8,42.3,78.8,41.7z"/>
			<path fill="#262523" d="M93.6,40.3c0.3,0.4,0.3,0.7,0.1,1c-0.1,0-0.1,0.1-0.1,0C93.2,41,93.4,40.7,93.6,40.3z"/>
			<path fill="#161615" d="M91.5,38.6c-0.1,0-0.2,0-0.2,0c0-0.6,0.1-1,1.1-0.9C91.8,38,91.6,38.3,91.5,38.6z"/>
			<path fill="#20201E" d="M96.8,34.4c-0.3,0.3-0.3,0.7-0.6,0.8c0,0-0.2-0.2-0.2-0.3C95.9,34.4,96.3,34.4,96.8,34.4z"/>
			<path fill="#181817" d="M84,22.8c0,0.2,0,0.3,0,0.5c-0.2,0-0.4,0.3-0.5,0.1c-0.1-0.2-0.3-0.5,0-0.7C83.6,22.6,83.8,22.7,84,22.8z
				"/>
			<path fill="#504B45" d="M91.9,33.8c0,0.2-0.2,0.4-0.4,0.4c-0.3,0-0.2-0.3-0.2-0.4c0-0.2-0.1-0.5,0.2-0.4
				C91.8,33.4,91.9,33.6,91.9,33.8z"/>
			<path fill="#45413C" d="M87.5,16.4c0.1,0.3,0,0.4-0.2,0.4c-0.2,0-0.5-0.1-0.4-0.4c0-0.1,0.3-0.2,0.4-0.4
				C87.3,16.2,87.4,16.3,87.5,16.4z"/>
			<path fill="#4B4641" d="M94.7,25.4c0,0.2-0.2,0.2-0.3,0.2c-0.2,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.3,0.3-0.2
				C94.5,25.1,94.7,25.2,94.7,25.4z"/>
			<path fill="#1B1A19" d="M85.2,35.3c-0.3,0.1-0.6,0-0.8-0.2c0,0,0.1-0.2,0.1-0.3C84.8,34.8,85,34.9,85.2,35.3z"/>
			<path fill="#1D1D1B" d="M78.7,49.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.3,0-0.3-0.2c0-0.2,0.1-0.3,0.3-0.3
				C78.5,49,78.6,49,78.7,49.3z"/>
			<path fill="#21201F" d="M82.5,48.9c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.2,0.2-0.4,0.3-0.5
				C82.4,48.6,82.5,48.8,82.5,48.9z"/>
			<path fill="#3A3734" d="M88.2,38.3c0.2,0.1,0.4,0.1,0.4,0.4c0,0.1-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.2-0.3-0.4
				C87.9,38.4,88,38.3,88.2,38.3z"/>
			<path fill="#4A4540" d="M101.9,7.5c0.6-0.4,0.9-0.7,1.2-0.3C102.9,7.7,102.5,7.4,101.9,7.5z"/>
			<path fill="#2F2E2B" d="M94.9,23.8C94.8,24,94.7,24,94.5,24c-0.2,0-0.3-0.2-0.2-0.3c0.1-0.1,0.3-0.1,0.4-0.1
				C94.8,23.5,95,23.6,94.9,23.8z"/>
			<path fill="#373431" d="M104.7,13c0.4-0.6,0.7-0.3,1.1-0.3C105.5,13.1,105.2,12.9,104.7,13z"/>
			<path fill="#373531" d="M92,36.9c-0.4-0.2-0.5-0.4-0.4-0.7c0,0,0.2-0.1,0.3-0.1C92,36.3,92.2,36.5,92,36.9z"/>
			<path fill="#5B544D" d="M72.2,60.4c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0,0.2,0.2,0.2,0.3
				C72.5,60.3,72.3,60.4,72.2,60.4z"/>
			<path fill="#191918" d="M91.4,29.1c-0.1,0.1-0.1,0.3-0.3,0.4c-0.1,0.1-0.3-0.1-0.3-0.2c0-0.2,0.1-0.4,0.3-0.4
				C91.3,28.8,91.4,28.9,91.4,29.1z"/>
			<path fill="#252422" d="M69.6,55c0.1-0.1,0.2-0.2,0.2-0.3c0.3,0.2,0.8,0.4,0.5,0.7C70,55.8,69.9,55.2,69.6,55z"/>
			<path fill="#4A4641" d="M101.4,20.7c-0.1,0.3-0.2,0.4-0.4,0.5c-0.2,0-0.2-0.2-0.2-0.3c0-0.2,0-0.4,0.3-0.4
				C101.3,20.5,101.3,20.6,101.4,20.7z"/>
			<path fill="#363330" d="M99.5,6.2c-0.1-0.2-0.2-0.4-0.2-0.5c0-0.2,0.2-0.3,0.4-0.4c0.2,0,0.3,0.1,0.2,0.3
				C99.7,5.8,99.6,5.9,99.5,6.2z"/>
			<path fill="#47423E" d="M77.3,37.9c0-0.2-0.4-0.5,0-0.7c0.1,0,0.3,0.1,0.4,0.2C77.9,37.7,77.7,37.9,77.3,37.9
				C77.3,37.9,77.3,37.9,77.3,37.9z"/>
			<path fill="#363431" d="M85,53.7c-0.3-0.2-0.8-0.3-0.5-0.7c0.3-0.4,0.5,0.2,0.8,0.3C85.3,53.5,85.2,53.6,85,53.7z"/>
			<path fill="#48443F" d="M86.1,22.9c0.3-0.1,0.6-0.4,0.9-0.1c0,0,0,0.2,0,0.3C86.6,23.3,86.4,23.1,86.1,22.9z"/>
			<path fill="#191918" d="M87.9,34.3c-0.2,0-0.3,0-0.3-0.2c0-0.2,0.2-0.2,0.3-0.2c0.2,0,0.3,0,0.3,0.2C88.2,34.3,88,34.3,87.9,34.3
				z"/>
			<path fill="#57504A" d="M90,7c-0.4-0.2-0.5-0.6-0.1-0.8c0.3-0.2,0.4,0.2,0.4,0.5C90.4,6.9,90.3,7,90,7z"/>
			<path fill="#393633" d="M92.2,25.5c0,0.4-0.1,0.7-0.4,0.8c0,0-0.2-0.1-0.2-0.2C91.6,25.7,91.9,25.6,92.2,25.5z"/>
			<path fill="#554E48" d="M81.4,42.5c-0.1,0.2,0.3,0.5,0,0.6c-0.3,0.1-0.7-0.2-0.6-0.6C80.9,42.2,81.2,42.5,81.4,42.5
				C81.4,42.5,81.4,42.5,81.4,42.5z"/>
			<path fill="#222220" d="M103,4.1c-0.4,0.2-0.7,0.4-1,0.3c0,0,0-0.2,0-0.3C102.2,3.8,102.5,3.9,103,4.1z"/>
			<path fill="#514B46" d="M76.1,39.8c-0.5,0.5-0.5,0.4-0.9-0.4C75.5,39.6,75.8,39.7,76.1,39.8C76.1,39.8,76.1,39.8,76.1,39.8z"/>
			<path fill="#141414" d="M87.1,29.5c0.2-0.5-0.3-0.9-0.3-1.4C87.3,28.5,87.7,28.9,87.1,29.5z"/>
			<path fill="#514C46" d="M86,27.8c-0.1,0-0.2,0-0.3,0c0.2-0.2,0.1-0.7,0.5-0.6c0.3,0,0.2,0.4,0.2,0.6C86.3,27.8,86.1,27.8,86,27.8
				z"/>
			<path fill="#151514" d="M92.6,11.6c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.2,0-0.3,0.2-0.4
				C92.4,11.4,92.5,11.5,92.6,11.6z"/>
			<path fill="#2A2927" d="M89.8,41.8c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.1,0.3,0,0.3,0.2c0,0.1-0.2,0.2-0.3,0.3
				C90.1,41.9,89.9,41.8,89.8,41.8z"/>
			<path fill="#20201F" d="M99.8,21.7c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.2-0.1,0.3-0.3,0.2c-0.1-0.1-0.2-0.2-0.3-0.3
				C99.4,21.7,99.5,21.7,99.8,21.7z"/>
			<path fill="#3F3C37" d="M77.3,39.1c0.1,0,0.1,0,0.2,0c0,0.3,0.4,0.6,0,0.8c-0.1,0-0.2,0.1-0.2,0C77,39.7,77.4,39.4,77.3,39.1z"/>
			<path fill="#1F1E1D" d="M63.8,60.5c0,0.2,0.1,0.3-0.1,0.3c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0.1-0.3,0.2-0.3
				C63.7,60.1,63.6,60.4,63.8,60.5z"/>
			<path fill="#44413C" d="M64.8,61.7c0.1,0,0.3,0,0.3,0.2c0,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.3-0.1-0.3-0.1
				C64.4,61.7,64.6,61.7,64.8,61.7z"/>
			<path fill="#4C4742" d="M71.3,49.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.2-0.3c0-0.1,0.2-0.2,0.2-0.2
				C71.2,49,71.3,49.1,71.3,49.3z"/>
			<path fill="#1A1A19" d="M95.9,10.5c0.1,0.4-0.1,0.7-0.3,0.6c-0.5-0.1-0.1-0.3,0.1-0.5C95.7,10.6,95.8,10.6,95.9,10.5z"/>
			<path fill="#433F3B" d="M94.1,2.9c-0.4,0-0.8-0.1-0.9-0.5c0,0,0.2-0.1,0.4-0.2C93.8,2.4,94,2.6,94.1,2.9z"/>
			<path fill="#1E1E1D" d="M70.6,50.1c0,0.1,0,0.3-0.2,0.3c-0.1,0-0.2-0.2-0.2-0.3c0-0.1,0-0.3,0.2-0.3
				C70.6,49.8,70.6,49.9,70.6,50.1z"/>
			<path fill="#1E1D1C" d="M91.9,15.3c0-0.1,0-0.3,0-0.4c0.2-0.2,0.5-0.3,1-0.7C92.6,14.9,92.3,15.2,91.9,15.3z"/>
			<path fill="#1D1B1A" d="M109.7,5.6c-0.1,0.4-0.3,0.6-0.7,0.7C109.1,5.8,109.4,5.7,109.7,5.6z"/>
			<path fill="#21201F" d="M87.2,42.6c0,0.2,0.1,0.4-0.2,0.3c-0.1,0-0.2-0.2-0.3-0.3c-0.1-0.2,0-0.3,0.2-0.4
				C87.2,42.1,87.1,42.4,87.2,42.6z"/>
			<path fill="#4F4A45" d="M84.7,43.2c0.1,0.2,0.2,0.4,0.3,0.7c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.2,0-0.2,0
				C84.5,43.8,84.1,43.4,84.7,43.2z"/>
			<path fill="#524C47" d="M68.8,53.8c0,0.3-0.1,0.4-0.3,0.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.2,0.3-0.3
				C68.7,53.7,68.8,53.8,68.8,53.8z"/>
			<path fill="#4C4742" d="M85.5,27.9c0,0.2,0,0.3,0,0.5c-0.2-0.1-0.6,0.3-0.6-0.2C84.8,27.7,85.2,27.8,85.5,27.9z"/>
			<path fill="#20201F" d="M97.9,12.4c0.1,0.2,0.3,0.5,0,0.6c-0.5,0.1-0.4-0.4-0.5-0.8C97.7,12.1,97.8,12.2,97.9,12.4z"/>
			<path fill="#4E4944" d="M97,18.5c-0.1,0.4-0.2,0.5-0.4,0.5c-0.2,0-0.3-0.1-0.2-0.3c0-0.2,0.1-0.3,0.3-0.3
				C96.8,18.4,97,18.5,97,18.5z"/>
			<path fill="#3D3A36" d="M86.7,25.7c0.2-0.1,0.3-0.2,0.5-0.2c0,0.3,0,0.6,0,0.9C86.7,26.3,86.7,26,86.7,25.7z"/>
			<path fill="#504B45" d="M90.2,24.5c-0.2-0.3-0.3-0.5,0-0.8c0,0,0.2,0,0.3,0.1C90.6,24.1,90.3,24.3,90.2,24.5z"/>
			<path fill="#1E1E1D" d="M97.6,26.5c-0.1,0.1-0.1,0.3-0.2,0.3c-0.2,0.1-0.2-0.2-0.3-0.3c0-0.2,0.1-0.3,0.2-0.4
				C97.6,26.2,97.7,26.3,97.6,26.5z"/>
			<path fill="#363431" d="M100,4.2c0.3,0,0.4,0,0.3,0.2c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3-0.2-0.2-0.3
				C99.7,4.2,99.9,4.2,100,4.2z"/>
			<path fill="#4C4741" d="M92,24.6c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.2-0.2-0.2-0.3c0-0.2,0.1-0.3,0.3-0.3C92,24.3,92,24.5,92,24.6z"
				/>
			<path fill="#1B1B1A" d="M94,30.9c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.2-0.3-0.3c0,0,0.1-0.2,0.2-0.2C93.8,30.7,94,30.7,94,30.9z"
				/>
			<path fill="#33312E" d="M92.2,7.9c0,0.1,0,0.3-0.2,0.3c-0.1,0-0.2-0.2-0.2-0.3c0-0.1,0-0.3,0.2-0.3C92.1,7.6,92.2,7.7,92.2,7.9z"
				/>
			<path fill="#302E2C" d="M101.6,5.3c-0.1,0.1-0.1,0.4-0.3,0.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.4,0.3-0.3
				C101.6,5.1,101.7,5.1,101.6,5.3z"/>
			<path fill="#292825" d="M86.3,36.4c-0.2-0.5,0.1-0.7,0.4-0.9C86.7,35.9,86.7,36.2,86.3,36.4z"/>
			<path fill="#58514B" d="M80.9,35.7c-0.1,0.2-0.2,0.3-0.2,0.5c-0.4,0.1-0.5-0.1-0.5-0.4C80.4,35.7,80.7,35.3,80.9,35.7z"/>
			<path fill="#4B4641" d="M79,35.5c0,0.3-0.1,0.7-0.5,0.5c-0.3-0.1,0-0.4,0.1-0.6C78.7,35.4,78.8,35.4,79,35.5z"/>
			<path fill="#4E4843" d="M97.9,12.4c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.3,0.1-0.4,0.2-0.5c0.2,0.1,0.4,0.3,0.5,0.5
				C98.1,12.3,98,12.4,97.9,12.4z"/>
			<path fill="#1B1B1A" d="M94.8,22.5c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.2-0.1-0.2-0.3c0-0.1,0.1-0.3,0.2-0.4
				C94.7,22.1,94.8,22.3,94.8,22.5z"/>
			<path fill="#1D1C1B" d="M87.6,21.3c-0.3-0.1-0.5-0.3-0.4-0.7c0-0.1,0.2-0.1,0.2-0.2c0.1,0.1,0.1,0.3,0.2,0.4
				C87.8,21,87.8,21.2,87.6,21.3z"/>
			<path fill="#302E2B" d="M88.7,19.8c-0.2,0.4-0.4,0.8-0.6,0.7c-0.4-0.1-0.1-0.4,0.1-0.5C88.3,19.9,88.4,19.9,88.7,19.8z"/>
			<path fill="#34322F" d="M101.8,16.9c0,0.1-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.2-0.2-0.2c0-0.2,0.1-0.4,0.3-0.3
				C101.8,16.6,101.9,16.6,101.8,16.9z"/>
			<path fill="#47433E" d="M93.7,20.3c0-0.1,0-0.3,0-0.4c0.3-0.2,0.7-0.2,0.7,0C94.4,20.3,94,20.3,93.7,20.3z"/>
			<path fill="#222220" d="M77,36.3c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0.1-0.2,0.2-0.1C76.8,36,77,36,77,36.3z
				"/>
			<path fill="#131312" d="M80.6,36.3c0.7,0,0.2,0.5,0.3,0.7C80.3,36.9,80.5,36.6,80.6,36.3z"/>
			<path fill="#524C46" d="M77.8,33.4c0.4-0.4,0.5-0.2,0.5,0.2c0,0.1-0.1,0.2-0.1,0.3C78,33.7,77.9,33.6,77.8,33.4z"/>
			<path fill="#21201F" d="M86.7,40.3c0.3,0,0.6,0,0.8,0.3C87.1,40.9,86.9,40.6,86.7,40.3L86.7,40.3z"/>
			<path fill="#2D2C2A" d="M96,36.3c-0.4,0.2-0.6,0.4-0.8,0.1c-0.2-0.5,0.2-0.2,0.3-0.2C95.7,36.2,95.8,36.3,96,36.3z"/>
			<path fill="#2B2927" d="M84.3,33.6c0.1,0.1,0.3,0.3,0,0.4c-0.3,0.1-0.5-0.1-0.5-0.4C84,33.4,84.1,33.5,84.3,33.6z"/>
			<path fill="#2B2A28" d="M75.6,41.8c-0.1-0.1-0.2-0.2-0.3-0.3c0.2-0.2,0.5-0.3,0.7-0.5c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.1,0.4,0,0.7
				C76,41.7,75.8,41.7,75.6,41.8z"/>
			<path fill="#2B2A28" d="M106.5,6.6c-0.4,0.1-0.7,0-0.7-0.2c0-0.2,0.2-0.2,0.4-0.1C106.3,6.3,106.4,6.4,106.5,6.6z"/>
			<path fill="#232221" d="M86.7,40.3c-0.2,0-0.5-0.1-0.4-0.4c0-0.1,0.2-0.2,0.2-0.2C86.8,39.8,86.6,40.1,86.7,40.3
				C86.7,40.3,86.7,40.3,86.7,40.3z"/>
			<path fill="#44403C" d="M82.8,35c0-0.2,0-0.5,0-0.7c0.2,0.1,0.5-0.1,0.5,0.2C83.4,34.8,83.1,34.9,82.8,35z"/>
			<path fill="#232321" d="M90.5,39.1c0.1,0.2,0.1,0.3,0.2,0.5c-0.2-0.1-0.7,0.2-0.7-0.2C90,39.1,90.3,39.1,90.5,39.1z"/>
			<path fill="#393733" d="M84.7,38.3c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.3,0.1-0.3-0.1c0-0.1,0.2-0.1,0.3-0.2
				C84.4,38.2,84.5,38.2,84.7,38.3z"/>
			<path fill="#131313" d="M79.2,37.3c0,0.1,0,0.2-0.2,0.2c-0.1,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.2,0.2-0.2
				C79.1,37,79.2,37.1,79.2,37.3z"/>
			<path fill="#232221" d="M79,35.5c-0.1,0-0.3,0-0.4,0c0-0.2-0.1-0.6,0.2-0.6C79.1,34.9,78.9,35.3,79,35.5z"/>
			<path fill="#3B3835" d="M75.1,37.2c0.2,0,0.3,0,0.5,0c-0.1,0.3-0.2,0.6-0.3,1C75,37.9,75.2,37.5,75.1,37.2z"/>
			<path fill="#1E1E1D" d="M85.8,61.9c0.1,0,0.3,0,0.2,0.2c-0.1,0.1-0.2,0.3-0.3,0.3c-0.2,0.1-0.2-0.1-0.2-0.2
				C85.5,62.1,85.6,62,85.8,61.9z"/>
			<path fill="#2D2B29" d="M63.1,65.8c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.1-0.4-0.3c0,0,0.2-0.1,0.3-0.1
				C62.9,65.5,63.1,65.5,63.1,65.8z"/>
			<path fill="#48433F" d="M96.4,14c-0.1,0.1-0.1,0.2-0.3,0.3c-0.2,0.1-0.3-0.1-0.3-0.3c0-0.1,0.2-0.2,0.3-0.3
				C96.4,13.7,96.4,13.8,96.4,14z"/>
			<path fill="#413E3A" d="M95.5,5c0.2,0.1,0.3,0.2,0.3,0.4c-0.1,0.3-0.4,0.2-0.7,0.3C95.2,5.5,95.3,5.3,95.5,5z"/>
			<path fill="#171716" d="M108.4,4.7c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0-0.2-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2
				C108.4,4.4,108.4,4.5,108.4,4.7z"/>
			<path fill="#191918" d="M88.8,18.2c-0.1-0.1-0.3-0.1-0.3-0.2c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.2
				C89,18.1,88.8,18.1,88.8,18.2z"/>
			<path fill="#292826" d="M89.5,17.1c0.1,0,0.2,0,0.3,0c0.3,0.2,0.2,0.4,0.2,0.8C89.6,17.6,89.6,17.3,89.5,17.1z"/>
			<path fill="#47423E" d="M81.1,29.5c0.2,0,0.4,0,0.6,0c0.1,0.2,0.2,0.4,0,0.5C81.3,30.1,81.2,29.8,81.1,29.5z"/>
			<path fill="#514B45" d="M103.6,3.9c0.2,0,0.3,0.2,0.2,0.3c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.2-0.2-0.2-0.4
				C103.4,3.9,103.6,3.9,103.6,3.9z"/>
			<path fill="#393633" d="M76.4,42.4c0,0.2-0.1,0.3-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0.1-0.2,0.2-0.3
				C76.2,42.2,76.4,42.2,76.4,42.4z"/>
			<path fill="#413E3A" d="M89.8,45.5c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.3,0,0.3,0.2
				C89.9,45.4,89.8,45.5,89.8,45.5z"/>
			<path fill="#3C3936" d="M83.8,46.5c0.1,0.2,0.3,0.2,0.5,0.2c-0.3,0.4-0.6,0.4-0.8,0C83.3,46.6,83.6,46.6,83.8,46.5z"/>
			<path fill="#2E2D2B" d="M105.9,2.6c0-0.2,0-0.3,0-0.5c0.2-0.1,0.5-0.4,0.6-0.1C106.6,2.5,106.1,2.4,105.9,2.6z"/>
			<path fill="#504A45" d="M62.6,60.3c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0.1-0.3,0-0.4-0.2c0-0.1,0.1-0.2,0.2-0.2
				C62.4,60,62.5,60,62.6,60.3z"/>
			<path fill="#544E48" d="M65.1,58.2c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.2,0.3-0.3,0.2c-0.1,0-0.2-0.2-0.2-0.2
				C64.9,58.4,65,58.2,65.1,58.2z"/>
			<path fill="#373431" d="M83.5,24.8c0.1,0.2,0.2,0.3,0.3,0.4c0,0.1,0,0.3-0.1,0.3c-0.2,0-0.3-0.1-0.3-0.3
				C83.3,25,83.4,25,83.5,24.8z"/>
			<path fill="#31302D" d="M74.7,53.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.1,0.3-0.1
				C74.4,53.5,74.5,53.7,74.7,53.8z"/>
			<path fill="#59524B" d="M85,53.7c0.1-0.1,0.1-0.3,0.2-0.4c0.4-0.1,0.7-0.1,0.5,0.5c-0.2,0-0.3,0-0.5,0
				C85.1,53.8,85.1,53.7,85,53.7z"/>
			<path fill="#2E2C2A" d="M90.6,48.2c0.1,0.2,0.2,0.3,0.3,0.4c0,0.1-0.1,0.2-0.2,0.1c-0.1-0.1-0.3-0.1-0.3-0.3
				C90.4,48.4,90.5,48.3,90.6,48.2z"/>
			<path fill="#222120" d="M80.6,52.9c0,0.2-0.1,0.2-0.2,0.2C80.2,53,80,53,80.1,52.8c0-0.1,0.2-0.2,0.2-0.1
				C80.4,52.7,80.5,52.8,80.6,52.9z"/>
			<path fill="#32302D" d="M93.4,4.1c0.2-0.2,0.3-0.5,0.7-0.5C93.9,3.7,94,4.5,93.4,4.1z"/>
			<path fill="#423E3A" d="M107.6,3.8c0.2,0,0.3,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0-0.2-0.2
				C107.4,3.9,107.5,3.9,107.6,3.8z"/>
			<path fill="#1D1C1B" d="M110.2,1.4c-0.1,0.2-0.2,0.2-0.3,0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2-0.1,0.3-0.1
				C110.1,1.2,110.1,1.3,110.2,1.4z"/>
			<path fill="#353330" d="M111.9,1.7c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1-0.1-0.2-0.2-0.2-0.3
				C111.7,1.8,111.7,1.7,111.9,1.7z"/>
			<path fill="#615952" d="M100.5,1.8c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.3,0.1
				C100.6,1.6,100.5,1.7,100.5,1.8z"/>
			<path fill="#181817" d="M102.9,2.9c-0.2,0.2-0.3,0.3-0.4,0.3c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C102.6,2.8,102.7,2.9,102.9,2.9z"/>
			<path fill="#2C2B29" d="M91.7,21.6c0.1-0.2,0.3-0.4,0.5-0.4C92.1,21.3,92.4,22,91.7,21.6z"/>
			<path fill="#1A1A19" d="M79.5,43.4c0,0.2-0.1,0.4-0.1,0.5c-0.1,0-0.2-0.1-0.3-0.1C79,43.5,79.3,43.5,79.5,43.4L79.5,43.4z"/>
			<path fill="#1E1D1C" d="M93.7,45.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.1-0.2-0.2-0.2-0.3c0.1,0,0.1-0.1,0.2-0.1
				C93.5,45.1,93.7,45.1,93.7,45.3z"/>
			<path fill="#383632" d="M81.5,45.6c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.2,0.1-0.2,0.2c-0.1-0.1-0.2-0.2-0.2-0.3
				C81.2,45.7,81.3,45.7,81.5,45.6z"/>
			<path fill="#47433E" d="M80.4,46.9c0-0.1-0.1-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.2,0.2,0.3
				C80.7,46.8,80.6,46.9,80.4,46.9z"/>
			<path fill="#262523" d="M81.9,46.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.2
				C81.6,46.6,81.7,46.7,81.9,46.8z"/>
			<path fill="#292825" d="M82.5,47.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.1-0.3
				C82.3,47.2,82.4,47.3,82.5,47.4z"/>
			<path fill="#403D39" d="M87.6,21.3c0-0.2,0-0.3,0-0.5c0.2,0,0.3,0,0.5,0c0.1,0.2,0.2,0.3,0.2,0.5C88.1,21.3,87.9,21.3,87.6,21.3z
				"/>
			<path fill="#59524C" d="M86.6,50.4c-0.1-0.1-0.3-0.1-0.3-0.1c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.2
				C86.8,50.3,86.7,50.3,86.6,50.4z"/>
			<path fill="#20201E" d="M72.5,50.4c0,0.1,0,0.1,0,0.2c-0.2,0-0.4,0-0.7,0c0-0.1,0-0.2,0-0.3C72.1,50.4,72.3,50.4,72.5,50.4z"/>
			<path fill="#1F1E1D" d="M69.7,52c0-0.1,0.1-0.3,0.2-0.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.2,0.2
				C69.7,52.2,69.7,52,69.7,52z"/>
			<path fill="#222220" d="M63.7,57.4c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.1,0.1,0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.2
				C64,57.6,63.9,57.5,63.7,57.4z"/>
			<path fill="#1C1C1B" d="M70.9,57.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.1,0.1,0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.2
				C71.1,57.5,71,57.4,70.9,57.3z"/>
			<path fill="#161715" d="M71,64.7c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.1,0.1-0.2,0.2-0.2
				C70.7,64.6,70.8,64.6,71,64.7z"/>
			<path fill="#343230" d="M61.5,65.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C61.3,64.9,61.4,65,61.5,65.1z"/>
			<path fill="#1A1A19" d="M79.1,66.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.2-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3
				C79,66.8,79,66.9,79.1,66.9z"/>
			<path fill="#1B1B1A" d="M89,25.9c0.1,0.6-0.1,0.8-0.7,0.8C88.6,26.4,88.8,26.1,89,25.9z"/>
			<path fill="#403D39" d="M79.5,25.4c0.4-0.4,0.6-0.3,0.7,0.2C80,25.6,79.7,25.5,79.5,25.4z"/>
			<path fill="#2D2C29" d="M96,29.4c0,0-0.1,0.1-0.1,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2C95.9,29.1,96,29.2,96,29.4
				z"/>
			<path fill="#47423E" d="M89,30.6c-0.2,0-0.3,0.1-0.3,0c-0.1-0.1-0.3-0.2-0.2-0.4c0-0.1,0.2-0.1,0.3-0.1
				C88.9,30.3,88.9,30.4,89,30.6z"/>
			<path fill="#2B2927" d="M100.2,23.6c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.2-0.2-0.3-0.3c0-0.1,0-0.2,0.1-0.2
				C100.1,23.3,100.2,23.4,100.2,23.6z"/>
			<path fill="#1B1B1A" d="M84.3,33.6c-0.2,0-0.3,0-0.5,0c-0.1-0.2-0.1-0.3-0.2-0.5C84,33,84.1,33.4,84.3,33.6z"/>
			<path fill="#5C554E" d="M88.5,22.3c0.2,0,0.3,0.1,0.2,0.2c0,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.3-0.1-0.2-0.2
				C88.4,22.4,88.5,22.4,88.5,22.3z"/>
			<path fill="#5B544D" d="M77.7,35.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0.1-0.2-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3
				C77.6,35,77.7,35.1,77.7,35.3z"/>
			<path fill="#494440" d="M92.7,22.2c0.1,0.1,0.2,0.2,0.3,0.4c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
				C92.4,22.3,92.5,22.2,92.7,22.2z"/>
			<path fill="#3E3B37" d="M87,37.2c-0.1-0.2-0.2-0.3-0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.2,0.1,0.1,0.2
				C87.2,36.9,87.1,37,87,37.2z"/>
			<path fill="#423F3A" d="M78.6,38c0.1,0.1,0.1,0.2,0.2,0.3c0,0.2-0.1,0.3-0.3,0.2c-0.1,0-0.1-0.2-0.1-0.3
				C78.4,38.2,78.5,38.1,78.6,38z"/>
			<path fill="#4F4A44" d="M102,21.8c-0.3,0.2-0.4,0.2-0.5,0.2c-0.2,0-0.3-0.1-0.2-0.3c0-0.1,0.2-0.1,0.3-0.1
				C101.6,21.6,101.7,21.7,102,21.8z"/>
			<path fill="#544E48" d="M76.1,41.7c0-0.2,0-0.4,0-0.7c0.3,0,0.5,0.1,0.4,0.4C76.4,41.6,76.3,41.6,76.1,41.7z"/>
			<path fill="#45413D" d="M90,21.9c-0.1,0.1-0.2,0.2-0.2,0.2c-0.2,0-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2
				C89.9,21.7,90,21.8,90,21.9z"/>
			<path fill="#20201E" d="M79.5,43.4c0-0.2,0-0.5,0.4-0.4c0.1,0,0.1,0.2,0.2,0.3C79.8,43.4,79.7,43.4,79.5,43.4
				C79.5,43.4,79.5,43.4,79.5,43.4z"/>
			<path fill="#504B46" d="M85.4,43.7c-0.1-0.2-0.1-0.4-0.2-0.5c0.2,0.1,0.6-0.1,0.6,0.3C85.9,43.7,85.6,43.7,85.4,43.7z"/>
			<path fill="#242322" d="M109.3,13.2c0.2,0.2,0.2,0.5,0.1,0.7C109.1,13.7,109.3,13.5,109.3,13.2z"/>
			<path fill="#534D48" d="M90.8,13c0.3,0.3,0.3,0.5,0,0.7C90.4,13.4,90.6,13.2,90.8,13z"/>
			<path fill="#33312E" d="M99.2,13c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.1-0.2c0-0.1,0.2-0.1,0.3-0.2
				C99.2,12.7,99.3,12.8,99.2,13z"/>
			<path fill="#565049" d="M105.7,11.7c0,0.1,0.1,0.2,0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.2c0-0.1-0.1-0.1-0.1-0.2
				C105.4,11.8,105.4,11.6,105.7,11.7z"/>
			<path fill="#21201F" d="M106.4,9.8c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2
				C106.3,9.6,106.4,9.6,106.4,9.8z"/>
			<path fill="#2C2B29" d="M89.6,9.9c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.1-0.2
				C89.4,9.7,89.5,9.8,89.6,9.9z"/>
			<path fill="#514C46" d="M91,9.3c-0.3-0.1-0.5-0.4-0.5-0.7C90.7,8.8,91.4,8.7,91,9.3z"/>
			<path fill="#3A3734" d="M90,7c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.2,0.2,0.5,0.2,0.8c0,0,0,0,0,0C90.2,7.4,90,7.3,90,7z"/>
			<path fill="#282725" d="M91,17.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C90.8,17.7,90.9,17.7,91,17.8z"/>
			<path fill="#504B45" d="M95.5,17.2c-0.2,0-0.3,0-0.5,0c0.1-0.2-0.1-0.6,0.3-0.5C95.5,16.8,95.6,17,95.5,17.2z"/>
			<path fill="#423E3A" d="M92.2,15.9c0,0.2,0,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.2
				C92,15.8,92.1,15.9,92.2,15.9z"/>
			<path fill="#2A2927" d="M99.1,15.2c-0.1,0-0.3,0.1-0.3,0c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.2-0.1,0.2-0.1
				C99,14.9,99.1,15,99.1,15.2z"/>
			<path fill="#5D564E" d="M91.7,13.4c0.1,0.2,0.2,0.3,0,0.5c0,0-0.2,0.1-0.2,0c-0.1-0.2-0.3-0.3-0.1-0.6
				C91.6,13.4,91.6,13.4,91.7,13.4z"/>
			<path fill="#DCB6A7" d="M122,30.2c0.6,0.6,1.3-0.7,1.9,0c1.2,0.2,2.8,1.5,2.9,2.5c0.1,0.5,0.4,0.8,0.6,1.2c0.1,0.4,0,0.7-0.3,1
				c0,0-0.3-0.1-0.1,0.1c0,0.2,0,0.4,0.2,0.5c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.2,0.7,0.3,0.8,0.7c0,1-0.8,1.2-1.4,1.6
				c-0.1,0-0.2-0.2-0.3-0.2c0,0.1,0.2,0.1,0.2,0.2c0,0.1,0,0.3-0.1,0.3c-0.9,0-1.3,1-2.2,1c-0.2,0.1-0.4,0.3-0.6,0.4
				c-0.1,0.1-0.3,0.1-0.5,0.1c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.4-0.2-0.5-0.5c-0.1-0.4-0.4-0.1-0.5-0.2c-0.7-0.6-1.1-1.3-1.9-1.9
				c-0.2-0.2-0.3-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.2c-0.1,0-0.2,0.1-0.3,0.2c-0.8,0.1-1-0.1-0.4-0.8c0.8-1,0.9-1.5,0.2-2.5
				c-0.2-0.3-0.3-0.6-0.1-1c0.3-0.4,0.6-0.4,0.9-0.1c0.2,0.2,0.3,0.3,0.5,0c0.4-0.5,0.5-0.4,1.4-0.6c-0.1-0.1-0.2,0-0.3,0
				c-0.4-0.1-1-0.1-1.1-0.5C121,30.6,121.5,30.4,122,30.2z"/>
			<path fill="#D1AF9D" d="M122.4,39.1c0.2,0.2,0.3,0.3,0.5,0.5c-0.1,0.1-0.1,0.1-0.1,0.1c0.2,0.1,0.3-0.1,0.4-0.1
				c0.2,0,0.3,0,0.5,0c0.2,0.3,0.5,0.3,0.8,0.5c0,0.1,0,0.2,0,0.3c-0.5,1-1.6,0.2-2.2,0.7c-0.5,0.5-0.9,0.9-1.1-0.2
				c-0.2-1.2-1.1-2-1.8-2.8c-0.8-0.9-1.1-1.9-1.7-3c0.4-0.1,0.7,0.7,0.9,0c0.2-0.7,0.8-1.4,0.6-1.8c-0.5-1.4,0.8-1.8,1.2-2.7
				c0.3-0.7,1-0.6,1.7-0.3c-0.1,0.4-0.5,0.6-0.9,0.7c0.7,0.7,1.7,0.2,2.4,1.1c-1.1-0.2-2.2-0.4-2.5,1c-0.2-0.2-0.4-0.4-0.6-0.6
				c-0.2-0.2-0.4-0.3-0.6,0c-0.1,0.2-0.3,0.4,0,0.7c1.1,1.3,1,2.1-0.4,3.1c0.2,0.1,0.4,0.2,0.6,0.2l0,0c0,0.1-0.1,0.4,0,0.4
				c0.7,0.6,1.5,1.1,2,2C122.1,39,122.3,39,122.4,39.1z"/>
			<path fill="#D4B1A0" d="M124.4,40.3c0-0.1,0-0.2,0-0.2c-0.4-0.2-0.6-0.5-0.2-1c0.1,0,0.1-0.1,0.2-0.1c0.9,0,1.1-0.4,0.5-1.2
				c0.6-0.2,1.1,1.1,1.7,0.1l0,0c0.4-0.6,1.3-0.8,1.4-1.7c0,0,0.1,0,0.1,0c0.3,0.5,0.9,0.5,1.5-0.1c0.3-0.3,0.4-0.7,0.8-0.1
				c0.1,1.3-0.6,2.4-1.1,3.5c-0.1,0.3-0.7,1.3-1.4,0.3c-0.1-0.1-0.3-0.2-0.4-0.1c-0.1,0.1-0.3,0.3-0.2,0.5c0.8,1.3-0.4,0.9-0.9,1
				c-0.2-0.1,0.3-0.8-0.5-0.5C125.3,40.9,124.6,41.1,124.4,40.3z"/>
			<path fill="#CCAD99" d="M127.2,30.5c1.3,0.3,2.6,1.5,2.8,2.7c0.1,0.6-0.2,1-0.9,1.1c-0.4-0.3-0.4-0.8-0.8-1.1
				c-0.1-0.1-0.1-0.2-0.2-0.3c-0.6-0.5-0.9-1.4-1.7-1.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.3,0.1-0.4,0.3-0.5l0,0
				C126.8,30.3,127,30.7,127.2,30.5z"/>
			<path fill="#C9AB96" d="M124.4,40.3c0.4,0.4,1,0.3,1.5,0.2c1.1-0.2,0.6,0.4,0.5,0.8c-0.7,0.7-1.7,0.4-2.5,0.6
				c-0.2,0-0.5-0.3-1-0.1c-0.4,0.2-0.4-0.4-0.6-0.7c0.4-0.3,0.7-0.8,1.4-0.4C123.9,40.9,124.1,40.4,124.4,40.3z"/>
			<path fill="#CCAD99" d="M130.4,36c-0.2,0-0.4-0.2-0.5,0.1c-0.1,0.6-0.3,1-1,0.7c-0.6-0.2-0.3,0.7-0.8,0.7
				c-0.5-0.4,0.3-0.9-0.2-1.3c-0.4,0-0.7-0.2-1-0.5c0.8-0.3,1.5-1,2.4-1c0.1,0,0.2,0,0.2,0c0.2,0.2,0.5,0.1,0.7,0.2
				C131.3,35.3,130.1,35.7,130.4,36z"/>
			<path fill="#DCB6A7" d="M129.4,34.8c-0.1,0.1-0.1,0.3-0.2,0.4c-0.3,0.1-0.9-0.5-0.6,0.4c-0.5,0.1-1.1,0.1-1.6,0.2l0,0
				c0.5-0.9,0.2-2.2,1.4-2.6c0.7,0.2,0.6,0.7,0.7,1.2C129.2,34.5,129.2,34.7,129.4,34.8z"/>
			<path fill="#D1AF9D" d="M127.2,33.8c-0.5-0.3-0.9-0.5-0.7-1.2c0-0.1,0-0.8-0.5-1c-0.8-0.3-1.5-0.9-2.2-1.4
				c0.2-0.3,0.5-0.2,0.8-0.1c0.4,0.1,0.8,0.2,0.9-0.4l0,0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0.1,0.2,0.2c-0.1,0.2-0.2,0.3-0.2,0.5
				c-0.1,0.2-0.4,0-0.5,0.2c0.3,0.1,0.7,0.1,1,0.3v0c1.3,0.5,1.3,1.6,1.1,2.8C127.3,33.7,127.3,33.8,127.2,33.8z"/>
			<path fill="#DCB6A7" d="M126.3,30.9c-0.5,0.2-0.9-0.1-1.3-0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.7,0
				C126.4,30.6,126.4,30.8,126.3,30.9z"/>
			<path fill="#D2B09E" d="M125.6,29.7c-0.4,0-0.8,0.1-1.1-0.4C125,29.3,125.4,29.1,125.6,29.7C125.6,29.7,125.6,29.7,125.6,29.7z"
				/>
			<path fill="#DCB6A7" d="M130.3,35c-0.3,0.1-0.7,0.4-0.7-0.2C129.9,34.7,130.3,34.4,130.3,35z"/>
			<path fill="#DCB6A7" d="M126,30c-0.1-0.1-0.2-0.2-0.2-0.2c0.2-0.3,0.4-0.2,0.5,0C126.4,30,126.2,30,126,30z"/>
			<path fill="#DCB6A7" d="M127.2,30.5c-0.2,0.4-0.5,0.1-0.7,0C126.8,29.9,127,30.3,127.2,30.5z"/>
			<path fill="#0A0A0A" d="M131.8,101c-0.8-0.2-1.7-0.2-2-1.2c0-0.2,0.1-0.3,0.3-0.3c0.4,0,0.5,0.4,0.8,0.5c0.1,0,0.3,0.3,0.4,0
				c0-0.1-0.1-0.3,0-0.4c0-0.3,0.1-0.5,0.5-0.5c0.3,0,0.7-0.1,1,0.1c1,0.6,0,1.4,0.1,2c0.2,0.1,0.2,0,0.3-0.1
				c0.9-0.2,1.3,0.4,1.7,0.9c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0.1,0.4,0.1,0.5c0,0.4-0.1,0.7-0.5,0.8c-0.4,0.2-0.7-0.4-1.2-0.2
				c-0.2,0.1-0.5,0.1-0.7,0.3c-0.9,0.8-1,0.8-1.6-0.2c-0.2-0.2,0.3-0.7-0.4-0.6c-0.2-0.3-0.3-0.7-0.7-0.7l0,0c0.2-0.6,0.8-0.7,1-1.2
				C131.5,101.7,131.6,101.2,131.8,101z"/>
			<path fill="#161615" d="M144,92.4c-0.2,0.2-0.5,0.5-0.7,0.7c-0.3-0.4-0.4-0.9-0.5-1.5c-0.1-0.1-0.1-0.3-0.2-0.4
				c-0.2-0.2-0.4-0.5-0.5-0.8c0.3-0.3-0.2-0.7,0.2-1c0.2-0.1,0.4,0,0.6,0.2c0.4,0.6,0.7,0.4,1-0.1c0.7,0.5,1.7,0.9,0.6,1.9
				c-0.2,0.1-0.2,0.3,0,0.5c0,0.1,0,0.2,0,0.3C144.4,92.3,144.3,92.5,144,92.4z"/>
			<path fill="#4F4A44" d="M137.3,97.7c0.4,0.1,0.5-0.6,0.9-0.5c0.6-0.5,1-0.1,1.4,0.4c0.3,0.2,0.7,0.4,0.9,0.8
				c0.1,0.3,0,0.9-0.2,0.8c-0.8-0.5-0.9,0.6-1.5,0.6c-0.6-0.1-0.7-0.9-1.3-0.9c-0.3-0.1-0.6-0.2-0.7-0.5
				C136.9,98,136.8,97.6,137.3,97.7z"/>
			<path fill="#59524B" d="M139.5,96.2c0.3-0.4,0.3-0.9,0-1.3c-0.2-0.4-0.3-0.9,0-1.3c0.1-0.1,0.3-0.1,0.5-0.1
				c0.4,0.7,0.9-0.3,1.3,0c0.1,0.1,0.2,0.3,0.2,0.5c0,0.4-0.2,0.8-0.4,1.2c-0.1,0.2-0.1,0.3,0.2,0.4c0.2,0,0.3,0.1,0.4,0.3
				c0.2,0.5-0.1,0.8-0.5,1.1c-0.3,0.2-0.7,0.3-1.1,0.2C139.5,96.9,139.2,96.7,139.5,96.2z"/>
			<path fill="#4E4943" d="M137.3,97.7c-0.3,0.2-0.3,0.5-0.3,0.7c-0.4,0.3-0.8,0.7-1.3,0.9c-0.2,0.1-0.4,0.1-0.6,0.1
				c-0.3-0.2-0.7-0.1-1-0.3c-0.4-0.3-0.7-0.7-0.5-1.3c0-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.3-0.3,0.6-0.2c0,0.4,0.1,0.7,0.7,0.6
				C135.8,97.8,136.6,97.5,137.3,97.7z"/>
			<path fill="#0B0B0A" d="M141.1,93.6c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.2-0.1,0.6-0.5,0.6c-0.4-0.1-0.3-0.5-0.4-0.8
				c-0.5-0.3-0.1-0.9-0.2-1.3c0.7-0.2,0.3-1,0.7-1.4c0.3,0.6,1.1,0.4,1.5,0.9c0,0.1,0,0.2,0,0.3C141.6,92.6,142.5,93.7,141.1,93.6z"
				/>
			<path fill="#3B3834" d="M137.8,92.2c-0.8,0.7,0.2,1.6-0.2,2.4c-0.1,0.1-0.2,0.1-0.3,0c-0.4-0.4-1.4-0.2-1.1-1.1
				c0.3-0.8,0.1-2,1.6-1.8C137.9,91.8,137.9,92,137.8,92.2z"/>
			<path fill="#544E48" d="M149.3,86.6c0,0.2,0,0.3,0,0.5c-0.2,0.4-0.7,0.5-1.1,0.7c-0.5-0.1-1,0.5-1.4-0.2c0-0.5,0-0.9,0.7-1
				c0.8-0.1,0.8-0.3,0.2-1C148.6,85.6,149.1,85.8,149.3,86.6z"/>
			<path fill="#262524" d="M146.9,87.6c0.4,0.4,0.8-0.4,1.2,0c0.6,0,0.5,0.4,0.6,0.8c0.1,0.2-0.4,0.5,0.1,0.7
				c0.2,0.7-0.2,0.9-0.8,0.9c-0.2-0.6-0.6-1-1.2-1.2C146.7,88.4,146.5,88,146.9,87.6z"/>
			<path fill="#5C554E" d="M146.9,88.8c1.1-0.3,1.2,0.4,1.2,1.2c-0.3,0.3-0.2,0.6-0.2,1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0
				c-0.3-0.3-0.5-0.6-0.8-1C146.4,89.6,145.9,89.2,146.9,88.8z"/>
			<path fill="#504B45" d="M137.8,92.2c0-0.2,0-0.3,0-0.5c0-0.1,0-0.1,0.1-0.2c0.2-0.2,0.3-0.5,0.4-0.7c0.5-0.2,0.8,0.6,1.3,0.2
				c0.2-0.1,0.2,0.2,0.2,0.4c-0.1,0.5-0.7,1-0.7,0.9C138.6,91.7,138.2,92.5,137.8,92.2z"/>
			<path fill="#504A45" d="M130.1,99.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2-0.2-0.7,0.8-0.7-0.2c0-0.7-0.7-0.2-1-0.5
				c0.8-0.3,1.5-1.5,2.3,0C130.5,99.4,130.3,99.5,130.1,99.6z"/>
			<path fill="#46413D" d="M131.6,97c-0.8,0.9-1.2,0.1-1.7-0.3c-0.2-0.2,0-0.3,0.1-0.5c0.2-0.2,0.3-0.4,0.6-0.2
				c0.2,0.2,0.4,0.5,0.8,0.3c0.3-0.2,0.5,0,0.7,0.2l0,0C132.2,96.9,131.6,96.7,131.6,97z"/>
			<path fill="#5A534D" d="M127.7,99.2c0.1,0.5,0.5,1.1-0.1,1.6c-0.5,0.4-0.8,0.1-1.1-0.2C126.6,99.8,126.8,99.2,127.7,99.2z"/>
			<path fill="#524E49" d="M145.7,93.8c0,0.3,0,0.6,0.1,1c0,0.4-0.2,0.4-0.5,0.3l0,0c-0.3-0.3,0.1-0.9-0.3-1.2
				c-0.3,0.2-0.6,0.4-0.8,0.1c-0.3-0.4,0.2-0.7,0.4-1c-0.1-0.3,0-0.3,0.3-0.3c0,0.3,0.1,0.5,0.4,0.4C145.9,93,145.4,93.6,145.7,93.8
				z"/>
			<path fill="#0D0D0C" d="M134.4,97.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.4,0.2-0.6-0.2-0.8-0.4c-0.2-0.2-0.3-0.5-0.2-0.8
				c0.1-0.1,0.5,0,0.2-0.3c-0.2-0.3,0-0.4,0.2-0.4c0.3,0,0.5,0.3,0.7,0.5c0.2,0.2,0.4,0.3,0.6,0.5c-0.1,0.2-0.1,0.4,0.2,0.5
				C134.7,97.1,134.6,97.1,134.4,97.2z"/>
			<path fill="#1E1D1C" d="M133.2,96.9c0.3,0.2,0.5,0.3,0.8,0.5c0,0.2,0,0.3-0.1,0.5c-0.2,0.2-0.9-0.4-0.7,0.5
				c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0.1-0.3,0c-0.2-0.2-0.2-0.6-0.5-0.8c-0.2-0.3,0.2-1.1-0.7-0.9c0.1-0.2,0.3-0.3,0.4-0.5
				c0.2,0,0.3,0,0.5,0C132.6,96.8,132.8,97,133.2,96.9z"/>
			<path fill="#272725" d="M134.2,88.9c0.2-0.6,0.3-1.1,0.4-1.6c0.1-0.6,0.6-0.3,0.9-0.3c0.4-0.1,0.2,0.3,0.2,0.5
				c0.1,0.4,0,0.6-0.4,0.5C134.5,87.8,134.8,88.8,134.2,88.9z"/>
			<path fill="#413D39" d="M139.5,96.2c0,0.4,0.2,0.6,0.5,0.8c0.1,0.4-0.2,0.6-0.5,0.9c-0.3-0.4-0.6-0.9-1.3-0.7c0-0.1,0-0.2,0-0.2
				c-0.1-0.2-0.1-0.3,0-0.5C138.6,96,139,96.1,139.5,96.2z"/>
			<path fill="#4C4641" d="M146.1,80.2c0.5,0.1,0.5,0.4,0.5,0.7c0,0.3-0.1,0.7-0.5,0.5c-0.4-0.2-1.2,0.1-1.2-0.7
				C145.1,80,146.1,81,146.1,80.2z"/>
			<path fill="#363431" d="M123.8,99.9c-0.6-0.1-0.4-0.9-1-1.1c-0.2-0.1,0-0.3,0.3-0.4c0.8-0.2,1.3,0.2,1.7,0.8l0,0
				c-0.2,0-0.3,0-0.5,0C124.1,99.3,124.1,99.6,123.8,99.9z"/>
			<path fill="#171716" d="M141.1,89.8c-0.4,0.2-0.5-0.8-1-0.2c-0.1,0-0.2,0-0.3,0c-0.4-0.3-0.2-0.7,0-1.1c0.2-0.2,0.2-0.6,0.6-0.4
				c0.2,0.4,1,0.3,0.9,0.9C141.2,89.3,141.3,89.6,141.1,89.8z"/>
			<path fill="#5E564F" d="M134,96.2c-0.2-0.2-0.4-0.5-0.6-0.7c0.2-0.6,0.7-0.5,1.1-0.4c0.6,0,0.4,0.6,0.5,0.9c0,0.1,0,0.1-0.1,0.2
				C134.6,96.4,134.3,96.4,134,96.2z"/>
			<path fill="#3B3835" d="M139.7,81.4c0.3,0.5,0.4,1.3,1.3,1.1c0,0,0.1,0.2,0.1,0.3c0,0.1-0.2,0.2-0.3,0.2c-0.4,0-0.9,0.1-1-0.1
				C139.4,82.6,139.2,82,139.7,81.4z"/>
			<path fill="#3D3A37" d="M142.2,85.1c0.4-0.2,0.7,0.2,1-0.2c0.2-0.2,0.4-0.3,0.6-0.1c0.1,0.1,0.2,0.3,0.1,0.4
				c-0.2,0.2-0.4,0.5-0.6,0.5C142.9,85.7,142.4,85.8,142.2,85.1z"/>
			<path fill="#1B1B1A" d="M131,90.5c-0.2-0.4-0.5-0.7-1-1c0.9-0.3,1.5,0,2.1,0.3c0,0-0.3,0.4-0.6,0.5
				C131.4,90.4,131.2,90.5,131,90.5z"/>
			<path fill="#57504A" d="M140,88.6c-0.1,0.3-0.2,0.6-0.1,1c-0.1,0.2-0.2,0.4-0.4,0.5c-0.5,0-1.1-0.1-0.4-0.8
				c0.2-0.3,0.3-0.7,0.6-0.9C139.9,88.2,140,88.3,140,88.6z"/>
			<path fill="#565049" d="M138.3,85.7c0,0.4-0.1,0.6-0.4,0.4c-0.3-0.2-1-0.1-0.9-0.7c0-0.2,0.2-0.6,0.6-0.4
				C137.9,85.2,138.3,85.4,138.3,85.7z"/>
			<path fill="#4C4742" d="M141.9,92.1c0-0.1,0-0.1,0-0.2c-0.1-0.3-0.5-0.5-0.6-0.8c0-0.3-0.1-0.6,0.4-0.6c0.2,0,0.3,0.3,0.5,0
				c0.3,0.1,0.6,0.4,0.7,0.7c-0.3,0.4-0.2,1-0.8,1C142,92.2,141.9,92.2,141.9,92.1z"/>
			<path fill="#2B2A28" d="M141.4,91.2c0.5,0.1,0.4,0.4,0.4,0.8c-0.4-0.4-1.6,0.2-1.4-1c0.4-0.2,0.2-0.8,0.7-1
				C141.5,90.3,140.9,90.9,141.4,91.2z"/>
			<path fill="#554F49" d="M139.7,92.3c1,0.3-0.3,0.9,0.2,1.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.5,0-0.6-0.4c-0.1-0.5,0.2-0.4,0.4-0.5
				C139.4,92.6,139.6,92.4,139.7,92.3z"/>
			<path fill="#4F4944" d="M130.1,99.6c0.1-0.2,0.2-0.3,0.2-0.5c0.3,0.2,0.6,0.4,0.9,0.5c0,0.4,0.4,0.4,0.6,0.5
				C130.7,100.5,130.7,100.5,130.1,99.6z"/>
			<path fill="#48443F" d="M124.4,102c0.7,1.5-0.7,0.4-1,0.7c-0.3-0.1-0.6-0.2-0.3-0.5c0.1-0.2,0.2-0.7,0.6-0.4
				C123.9,102,124.1,102,124.4,102z"/>
			<path fill="#534D47" d="M144,89.5c0.1,0.5,0,0.9-0.5,1c-0.5,0.1-0.5-0.5-0.7-0.8c-0.1-0.3,0-0.4,0.3-0.5
				C143.4,89.4,143.6,89.6,144,89.5z"/>
			<path fill="#4F4944" d="M132.6,95.3c0-0.7-0.7-1-0.5-1.5c0.2-0.3,0.8-0.1,1.3,0C132.4,93.9,133.2,94.7,132.6,95.3z"/>
			<path fill="#48443F" d="M143.4,76.8c-0.8-0.2-1.1-0.6-0.9-1.1c0-0.2,0.4-0.4,0.6-0.3C143.5,75.8,143.3,76.3,143.4,76.8z"/>
			<path fill="#47433E" d="M129.9,86.6c0.5-0.5,0.6-1.1,1.3-1.4C131.1,86,131.1,86.7,129.9,86.6z"/>
			<path fill="#524C46" d="M127.1,95.2c0.5,0.7,0.4,1.2-0.1,1.7c-0.1,0.1-0.4,0.2-0.5,0.1c-0.2-0.2-0.1-0.4,0.1-0.5
				C127,96.2,127,95.7,127.1,95.2z"/>
			<path fill="#514B45" d="M138.9,86.5c-0.5-0.6-0.5-1-0.1-1.3c0.1-0.1,0.4-0.2,0.5,0C139.7,85.7,139,85.9,138.9,86.5z"/>
			<path fill="#4A4540" d="M133,88.9c0.3,0,0.4,0.2,0.4,0.5c0,0.3,0.1,0.6-0.3,0.6c-0.3,0-0.5,0-0.5-0.3
				C132.6,89.3,132.7,89,133,88.9z"/>
			<path fill="#524C47" d="M134.7,90.9c0-0.3-0.1-0.7,0.4-0.7c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2-0.1,0.3-0.3,0.4
				C135.3,91.4,135,91.5,134.7,90.9z"/>
			<path fill="#4A4540" d="M144.5,83.6c0.5-0.1,0.6-0.8,1.2-0.6c0.2,0.1,0.2,0.4,0,0.6C145.4,84.1,145.1,83.9,144.5,83.6z"/>
			<path fill="#403C38" d="M132.8,91.6c0,0.4,0.5,0.9,0,1.2c-0.2,0.1-0.7,0-0.7-0.1C131.9,92.1,132.7,92,132.8,91.6z"/>
			<path fill="#544E48" d="M130.1,92.9c0.1,0.5-0.2,0.7-0.6,0.7c-0.4-0.1-0.6-0.5-0.5-0.8c0.1-0.5,0.4-0.1,0.6-0.1
				C129.8,92.6,130,92.7,130.1,92.9z"/>
			<path fill="#1B1B1A" d="M134.7,90.9c0.3,0.2,0.6,0.2,1,0c-0.1,0.3,0.4,0.6,0,0.9c-0.3,0.2-0.6,0-0.9-0.1
				C134.3,91.5,134.6,91.2,134.7,90.9z"/>
			<path fill="#242322" d="M132.3,87.5c0.2,0.1,0.6,0,0.6,0.4c0,0.3-0.3,0.4-0.6,0.4c-0.3,0-0.5-0.1-0.5-0.4
				C131.8,87.7,131.9,87.5,132.3,87.5z"/>
			<path fill="#554F48" d="M125.8,99.8c-0.2,0-0.3,0-0.5,0c-0.2-0.2-0.6-0.3-0.5-0.7c0,0,0,0,0,0c0.5,0.1,0.1-0.9,0.7-0.6
				c0.7,0.3,0,0.6,0,0.9C125.5,99.5,125.7,99.7,125.8,99.8z"/>
			<path fill="#4B4641" d="M131,93.3c-0.3,0-0.4-0.1-0.4-0.3c0-0.3,0.2-0.6,0.5-0.5c0.2,0,0.5,0.2,0.5,0.3
				C131.7,93.2,131.3,93.2,131,93.3z"/>
			<path fill="#4E4944" d="M127,94.7c-0.2-0.1-0.6-0.1-0.5-0.4c0.1-0.2,0.2-0.7,0.6-0.6c0.2,0,0.5,0.3,0.4,0.5
				C127.5,94.5,127.3,94.7,127,94.7z"/>
			<path fill="#49453F" d="M125.3,100.6c0.2,0.3,0.5,0.6,0.2,0.9c-0.4,0.4-0.5-0.2-0.9-0.3C124.6,100.8,125,100.7,125.3,100.6z"/>
			<path fill="#544E48" d="M129.4,95.5c0.1,0.4,0,0.5-0.3,0.4c-0.2-0.1-0.6,0.1-0.5-0.4c0-0.2,0.1-0.6,0.5-0.6
				C129.4,95,129.4,95.3,129.4,95.5z"/>
			<path fill="#302E2C" d="M145.3,85.2c0.3-0.2,0.6-0.2,0.6,0.3c0,0.2-0.1,0.4-0.4,0.3c-0.2-0.1-0.7,0-0.7-0.4
				C144.8,85,145.1,85,145.3,85.2z"/>
			<path fill="#1F1F1E" d="M138.2,96.4c0,0.2,0,0.3,0,0.5c-0.4-0.2-0.7-0.5-1.2-0.3c-0.3,0.1-0.6,0-0.7-0.4c0.3-0.2,0.4,0,0.5,0.2
				c0.2-0.2,0.5-0.3,0.7-0.5l0,0C137.7,96.3,137.9,96.5,138.2,96.4z"/>
			<path fill="#565049" d="M132.8,98.6c0.1,0,0.1,0,0.2,0c0.3,0.4,0.1,0.5-0.3,0.7c-0.3-0.1-0.7,0.1-1-0.2c0.2-0.2,0.3-0.3,0.5-0.5
				C132.4,98.5,132.6,98.5,132.8,98.6z"/>
			<path fill="#423E3A" d="M140,88.6c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.4,0.6-0.2
				c0.2,0.2,0.1,0.4-0.1,0.6C140.3,88.3,140.1,88.4,140,88.6z"/>
			<path fill="#33312E" d="M143.1,87.8c0.3-0.2,0.6-0.2,0.9,0c0.2,0.2-0.1,0.5-0.1,0.7l0,0c-0.2,0-0.3,0-0.5,0
				C143.1,88.4,142.8,88.2,143.1,87.8z"/>
			<path fill="#1B1B1A" d="M138,94.8c-0.3,0.3,0.6,1.2-0.5,1.2c0,0,0,0,0,0c-0.3-0.4-0.1-0.7,0.2-0.9
				C137.7,94.8,137.8,94.7,138,94.8z"/>
			<path fill="#3A3733" d="M138,94.8c-0.1,0.1-0.2,0.2-0.2,0.2c-0.4,0-0.8,0-1.2,0c0.1-0.4,0.4-0.4,0.7-0.5c0.1,0,0.2,0,0.3,0
				c0.1,0,0.3,0,0.4,0l0,0C138,94.6,138,94.7,138,94.8z"/>
			<path fill="#2C2B28" d="M135.4,89c-0.1,0.5-0.4,0.7-0.8,0.9c-0.2-0.6,0.1-0.9,0.6-0.9C135.2,88.9,135.3,88.9,135.4,89z"/>
			<path fill="#494540" d="M139.5,90c0-0.3,0.1-0.5,0.4-0.5c0.1,0,0.2,0,0.3,0c0,0.3,0,0.7-0.4,0.8C139.5,90.4,139.5,90.2,139.5,90z
				"/>
			<path fill="#21211F" d="M132.9,85.1c0.3,0.1,0.5,0.2,0.5,0.5c0,0.2-0.2,0.3-0.3,0.2c-0.2-0.1-0.5-0.1-0.5-0.4
				C132.6,85.2,132.8,85.1,132.9,85.1z"/>
			<path fill="#59524C" d="M126.7,90.9c0.5-0.2,0.7,0.2,0.6,0.5c-0.1,0.3-0.4,0.7-0.8,0.2C126.4,91.4,126.7,91.2,126.7,90.9z"/>
			<path fill="#312F2D" d="M133.7,85c-0.2-0.1-0.4-0.3-0.4-0.5c0-0.2,0.3-0.2,0.5-0.2c0.3,0,0.3,0.2,0.2,0.4
				C133.9,84.8,134,85,133.7,85z"/>
			<path fill="#504A45" d="M134.9,97c-0.4,0-0.4-0.2-0.3-0.5c0.1-0.2,0.1-0.3,0.3-0.5c0,0,0,0,0,0C135.5,96.3,135.7,96.7,134.9,97z"
				/>
			<path fill="#292826" d="M122.9,96.5c0.4,0.1,0.7,0.3,0.5,0.7c-0.2,0.3-0.6,0.5-0.7-0.2C122.7,96.8,122.7,96.6,122.9,96.5z"/>
			<path fill="#3D3A37" d="M125.8,91.7c-0.4,0.2-0.2,0.8-0.7,1c-0.1,0.1-0.2,0.1-0.2,0c0-0.5,0.5-0.8,0.7-1.2
				C125.8,91.3,125.9,91.4,125.8,91.7z"/>
			<path fill="#59534C" d="M147.6,84.5c0.2-0.1,0.4-0.3,0.7-0.2c-0.2,0.2,0.2,0.8-0.3,0.8C147.6,85.2,147.6,84.8,147.6,84.5z"/>
			<path fill="#2E2D2A" d="M130.1,92.9c-0.2,0-0.4-0.1-0.4-0.3c0-0.4,0.2-0.7,0.8-0.6C130.3,92.3,130.2,92.6,130.1,92.9z"/>
			<path fill="#46413D" d="M131.3,95.5c0.3-0.1,0.7-0.4,0.8-0.3c0.3,0.2-0.1,0.5-0.1,0.7C131.5,96.2,131.3,96,131.3,95.5z"/>
			<path fill="#272624" d="M128.2,94.4c0.1,0.4,0,0.7-0.4,1.2C127.8,95,128,94.7,128.2,94.4z"/>
			<path fill="#1E1D1C" d="M125,94.3c-0.3-0.1-0.5,0.3-0.8,0.1c0.1-0.4,0.4-0.5,0.6-0.8l0,0c0.1,0.1,0.2,0.2,0.2,0.2
				C125.2,94,125.2,94.2,125,94.3z"/>
			<path fill="#1E1D1C" d="M124.8,92.6c0.1,0,0.2,0,0.2,0c0,0.3,0.3,0.8-0.3,0.9c0,0,0,0,0,0C124.5,93.3,124.4,92.9,124.8,92.6z"/>
			<path fill="#0B0B0B" d="M141.4,91.2c-0.1,0.1-0.3,0.4-0.4,0c-0.1-0.4,0-0.8,0.1-1.2c0,0,0,0,0,0c0.3-0.2,0.3,0.1,0.5,0.2
				c0,0.1,0,0.2,0,0.2C141.3,90.6,141.4,90.9,141.4,91.2z"/>
			<path fill="#2B2A28" d="M141.6,90.2c-0.2-0.1-0.3-0.2-0.5-0.2c0-0.1,0-0.2,0-0.2c-0.2-0.3-0.2-0.6,0.2-0.7
				C141.6,89.4,141.7,89.8,141.6,90.2z"/>
			<path fill="#3E3A37" d="M145.3,88.8c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.3,0.2-0.3
				C145.1,88.6,145.3,88.6,145.3,88.8z"/>
			<path fill="#2B2A28" d="M138,87.6c0.3,0.5,0.3,0.9,0,1.4c-0.4-0.2-0.1-0.7-0.3-0.9C137.8,87.9,137.8,87.7,138,87.6z"/>
			<path fill="#504B45" d="M123.8,99.9c-0.2-0.5,0-0.8,0.5-0.8C124.1,99.3,124.8,100.2,123.8,99.9z"/>
			<path fill="#3F3C38" d="M124,92.7c-0.1,0.2-0.1,0.4-0.4,0.4c-0.1,0-0.4,0-0.3-0.2c0.1-0.2,0.1-0.4,0.4-0.4
				C123.9,92.4,124,92.5,124,92.7z"/>
			<path fill="#47433E" d="M128.8,90.8c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.3-0.2-0.3-0.3
				C128.5,90.9,128.6,90.8,128.8,90.8z"/>
			<path fill="#534D47" d="M125.8,91.7c-0.1-0.1-0.2-0.2-0.2-0.3c0.3-0.3,0.9-0.1,1.2-0.5c-0.1,0.3-0.2,0.5-0.2,0.8
				C126.3,91.7,126,91.7,125.8,91.7z"/>
			<path fill="#49443F" d="M145.1,87.2c-0.1,0.3-0.3,0.6-0.6,0.8c-0.4-0.2-0.1-0.4,0-0.6C144.7,87.3,144.8,87.1,145.1,87.2z"/>
			<path fill="#45413C" d="M138,87.6c0,0.2-0.1,0.4-0.3,0.5c-0.2-0.2-0.5-0.3-0.3-0.6C137.6,87.2,137.9,87.4,138,87.6z"/>
			<path fill="#1C1C1B" d="M143.1,87.8c0.1,0.2,0.2,0.5,0.2,0.7c-0.3-0.1-0.7,0.2-0.8-0.2C142.4,88,142.9,88,143.1,87.8z"/>
			<path fill="#1E1D1C" d="M131.3,95.5c0.1,0.3,0.3,0.5,0.7,0.5c0,0.2,0,0.3,0,0.5c-0.2-0.1-0.5-0.2-0.7-0.2
				C131.3,96,130.9,95.8,131.3,95.5z"/>
			<path fill="#4F4A44" d="M136.8,96.5c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2,0.1-0.2,0.2-0.3
				C136.7,95.6,136.7,96,136.8,96.5z"/>
			<path fill="#524C47" d="M133.2,96.9c-0.5,0.2-0.8,0.1-0.7-0.5c0.2-0.1,0.3-0.1,0.5-0.2C133.1,96.5,133.3,96.6,133.2,96.9z"/>
			<path fill="#2E2D2B" d="M132.8,98.6c-0.2,0-0.3,0-0.5,0c0.1-0.2-0.3-0.5,0-0.7C132.7,97.9,132.8,98.3,132.8,98.6z"/>
			<path fill="#4C4742" d="M145.5,89.4c0.5,0.2,0.7,0.4,0.7,0.8C145.6,90.1,145.6,89.8,145.5,89.4z"/>
			<path fill="#151515" d="M127.2,89.7c0.2-0.1,0.3,0.1,0.3,0.3c0.1,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.3-0.1-0.4-0.2
				C126.9,89.8,127.1,89.7,127.2,89.7z"/>
			<path fill="#161615" d="M145.2,91.5c0.2,0.1,0.3,0.2,0.2,0.4c0,0.1-0.2,0.2-0.2,0.2c-0.2,0-0.3-0.1-0.3-0.3
				C144.9,91.6,145.1,91.5,145.2,91.5z"/>
			<path fill="#504A45" d="M145.7,93.8c-0.2-0.2-0.3-0.5-0.5-0.7c0.1-0.2,0.3-0.4,0.5-0.4c0.1,0.2,0.2,0.4,0.2,0.7
				C145.7,93.4,145.7,93.6,145.7,93.8z"/>
			<path fill="#44403C" d="M125,96.8c-0.4-0.2-0.5-0.4-0.5-0.8C124.9,96.1,125.1,96.3,125,96.8z"/>
			<path fill="#3C3936" d="M145.1,77.3c0.2,0.1,0.4,0.2,0.3,0.4c0,0.1-0.1,0.2-0.2,0.1c-0.2-0.1-0.4-0.1-0.3-0.4
				C144.9,77.3,145,77.3,145.1,77.3z"/>
			<path fill="#161616" d="M143.9,79.6c-0.3,0-0.4-0.2-0.4-0.3c-0.1-0.1,0-0.2,0.1-0.2c0.2,0,0.5,0.1,0.5,0.3
				C144.1,79.4,144,79.5,143.9,79.6z"/>
			<path fill="#4A4641" d="M140.6,84.8c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0-0.3,0.2-0.3c0.2,0,0.3,0.1,0.3,0.2
				C140.8,84.7,140.8,84.8,140.6,84.8z"/>
			<path fill="#201F1E" d="M141.8,86.7c0.4-0.1,0.5,0.2,0.6,0.4c0.1,0.1-0.1,0.2-0.2,0.2C141.9,87.2,141.8,87,141.8,86.7z"/>
			<path fill="#554E49" d="M135.4,89c-0.1,0-0.2,0-0.3,0c0-0.2-0.1-0.5,0-0.7c0-0.1,0.3-0.1,0.4,0.1C135.7,88.7,135.6,88.9,135.4,89
				z"/>
			<path fill="#2A2927" d="M138.2,77.2c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.3,0-0.3-0.1c0-0.2,0.2-0.3,0.3-0.3
				C137.9,77,138,77.1,138.2,77.2z"/>
			<path fill="#3D3A37" d="M131.5,81.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.1,0.3-0.1
				C131.3,81.6,131.4,81.7,131.5,81.8z"/>
			<path fill="#2A2927" d="M132.1,83.1c0.2,0,0.3,0,0.3,0.2c-0.1,0.1-0.2,0.3-0.3,0.3c-0.1,0.1-0.2-0.1-0.2-0.2
				C131.9,83.3,132,83.2,132.1,83.1z"/>
			<path fill="#232221" d="M145.6,86.5c0,0.1,0.1,0.4-0.2,0.3c-0.1,0-0.2-0.2-0.3-0.3c0,0,0.1-0.2,0.2-0.3
				C145.5,86.1,145.6,86.3,145.6,86.5z"/>
			<path fill="#1F1E1D" d="M143.8,88.5c0.2,0,0.5,0.1,0.4,0.4c0,0.1-0.2,0.2-0.2,0.2C143.7,89,143.8,88.8,143.8,88.5
				C143.8,88.5,143.8,88.5,143.8,88.5z"/>
			<path fill="#3E3A37" d="M143.1,89.2c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2-0.1-0.3-0.2-0.5-0.3C142.5,89.2,142.7,88.9,143.1,89.2z"/>
			<path fill="#232321" d="M125.8,96c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0.1-0.2,0.2-0.1
				C125.7,95.7,125.8,95.9,125.8,96z"/>
			<path fill="#2B2A28" d="M133.9,80.6c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.3,0.2
				C134.2,80.5,134.1,80.5,133.9,80.6z"/>
			<path fill="#44403C" d="M136,81.2c-0.3-0.3-0.3-0.6-0.1-0.7c0.3-0.1,0.1,0.2,0.2,0.4C136.1,80.9,136.1,81,136,81.2z"/>
			<path fill="#282725" d="M142.7,80.5c0.3-0.2,0.6-0.1,0.6,0.1c0.1,0.2-0.1,0.2-0.3,0.1C143,80.7,142.9,80.6,142.7,80.5z"/>
			<path fill="#1B1B1A" d="M139.8,84.3c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0,0,0.1-0.2,0.1-0.2
				C139.8,83.9,139.6,84.1,139.8,84.3z"/>
			<path fill="#151615" d="M134.4,86.5c0,0.1-0.1,0.2-0.1,0.3c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0.1-0.2,0.1-0.3
				C134.3,86.2,134.4,86.3,134.4,86.5z"/>
			<path fill="#191918" d="M136.6,87.1c0.2,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C136.5,87.2,136.5,87.1,136.6,87.1z"/>
			<path fill="#45403C" d="M131,90.5c0.1-0.2,0.3-0.3,0.5-0.3c0,0.2,0,0.5-0.1,0.5C131.3,90.9,131.2,90.6,131,90.5z"/>
			<path fill="#3F3B38" d="M138.3,90.7c0,0.3,0,0.7-0.4,0.7C137.8,91.1,138,90.9,138.3,90.7z"/>
			<path fill="#544D47" d="M144.5,91.9c-0.3-0.1-0.6-0.3-0.5-0.5c0.1-0.3,0.4,0.1,0.6,0.1C144.5,91.6,144.5,91.7,144.5,91.9z"/>
			<path fill="#494440" d="M128.7,92c-0.2,0.1-0.3,0.2-0.4,0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0.2-0.2,0.2-0.2
				C128.5,91.8,128.6,91.9,128.7,92z"/>
			<path fill="#504A45" d="M144.8,92.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2-0.2-0.3-0.3-0.5-0.5c0.2-0.1,0.3-0.2,0.5-0.2
				C144.6,92.3,144.7,92.5,144.8,92.6z"/>
			<path fill="#49443F" d="M125,94.3c0-0.2,0-0.3,0-0.5c0.2,0,0.5,0.1,0.4,0.4C125.4,94.4,125.2,94.5,125,94.3z"/>
			<path fill="#47433E" d="M138,94.6c0-0.2,0-0.5,0.3-0.6c0,0,0.1,0.1,0.1,0.1C138.5,94.5,138.3,94.5,138,94.6
				C138,94.5,138,94.6,138,94.6z"/>
			<path fill="#4F4A45" d="M122.9,96.5c0,0.2-0.1,0.4-0.3,0.5c-0.2-0.1-0.2-0.3-0.1-0.5C122.7,96.4,122.8,96.3,122.9,96.5z"/>
			<path fill="#554E48" d="M82.6,16.4c0.1,0.1,0.2,0.1,0.3,0.2c0.5,0.5,0.2,1,0.2,1.6c-0.1,0.2-0.3,0.3-0.5,0.3
				c-0.6,0-0.9-0.5-1.1-0.9c0.3,0.8,0.1,1.5-0.8,1.6c-1,0.1-1.4-0.5-1.5-1.4c0-0.2,0.2-0.3,0.3-0.4c0.5,0.2,0.6-0.2,0.8-0.5
				C80.9,16,81.7,16.3,82.6,16.4z"/>
			<path fill="#554F49" d="M78.1,13.2c0-0.1,0-0.1,0-0.2c0.2-0.1,0.4-0.1,0.5-0.2c0,0.9,0.5,0.8,1.2,0.7c0.4-0.1,0.4,0.3,0.2,0.5
				c-0.4,0.4,0.1,0.6,0.2,0.9c0.1,0.2,0,0.3-0.1,0.4c-0.3,0.2-0.7,0.2-1,0.3c-0.6-0.2-0.4-0.6-0.2-1c0.1-0.3,0.1-0.4-0.5-0.2
				C78,14.3,78,14.1,78,13.9C77.9,13.6,77.8,13.4,78.1,13.2z"/>
			<path fill="#0C0C0C" d="M79,15.6c0-0.8,0.7-0.2,0.9-0.5c0.7,0.4,1.6,0.2,2.2,0.9c0.2,0,0.4,0.1,0.5,0.3c-0.7,0.6-1.6-0.1-2.4,0.3
				c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2c-0.3,0-0.6,0-0.9-0.3C79.1,15.9,79.1,15.7,79,15.6L79,15.6z"/>
			<path fill="#554E49" d="M80.7,11.7c0.4-0.1,0.8,0.3,1.2,0c0.6,0.2,1-0.1,1.4-0.5c0.4,0,0.4,0.2,0.2,0.5l0,0c-0.4,0.3,0.4,0.7,0,1
				c-0.2,0.1-0.5,0.2-0.7,0.2c-0.6-0.2-1.2-0.2-1.7-0.5C80.8,12.3,80.7,12.1,80.7,11.7z"/>
			<path fill="#4F4A44" d="M84.3,15.3c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.1-0.2,0.1-0.3,0c-0.4-0.8,0.1-1.5,0.3-2.3
				c0-0.1,0.3,0,0.4,0.1c0.2,0.2,0.6,0,0.5,0.5C84.4,14.5,84.4,14.9,84.3,15.3z"/>
			<path fill="#4A4540" d="M80.7,14.4c-0.2-0.2-0.3-0.4-0.1-0.6c0.2-0.2,0.3-0.1,0.5,0.1c0.2,0.2,0.5,0.2,0.8,0.3
				c0.1,0.1,0.4,0,0.5,0.2c0,0.2,1.1,0.7-0.1,0.6c-0.1,0-0.2,0.4-0.3,0.5c-0.4,0.2-0.6-0.1-0.8-0.3C81,14.9,80.9,14.6,80.7,14.4z"/>
			<path fill="#1C1C1A" d="M82.8,18.2c0-0.6,0-1.1,0-1.7c0-0.2,0-0.4,0.3-0.5c0.1,0.2,0.4,0.2,0.5,0.5c0,0.2-0.1,0.5-0.1,0.7
				c-0.2,0.3-0.2,0.6,0,1c0.2,0.3,0.5,0.6,0.7,0.9c-0.4,0.4-0.7,0.1-1-0.2c-0.2-0.1-0.3-0.3-0.5-0.5C82.7,18.4,82.8,18.3,82.8,18.2z
				"/>
			<path fill="#5C554E" d="M85.7,13c0.2,0.3,0.8,0.1,0.7,0.7c-0.4,0.1-0.7,0.7-1.2,0.2c-0.4-0.1-0.4-0.4-0.5-0.7
				c-0.3,0-0.5-0.2-0.5-0.5c0.2,0,0.5,0,0.7,0C85.3,12.7,85.5,12.7,85.7,13z"/>
			<path fill="#3C3935" d="M81.1,12.5c0.6,0,1.3-0.3,1.7,0.5C82.2,13.2,81.5,13.5,81.1,12.5z"/>
			<path fill="#524C47" d="M81.2,15.1c0.2,0.3,0.5,0.4,0.8,0.5c0.2,0.1,0.2,0.3,0.2,0.5c-0.7-0.4-1.7-0.1-2.2-0.9
				c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0,0.2,0,0.2,0C80.7,14.9,80.9,14.9,81.2,15.1z"/>
			<path fill="#2F2E2B" d="M83.6,12.7c-0.1-0.3-1.1-0.7,0-1c0.2,0.3,0.5,0.6,0.7,1C84,12.6,83.8,13,83.6,12.7z"/>
			<path fill="#34322F" d="M81.2,15.1c-0.3,0.1-0.7,0.4-0.7-0.2c0.1-0.2,0.2-0.3,0.2-0.5C81.1,14.5,81.4,14.6,81.2,15.1z"/>
			<path fill="#3F3C38" d="M79.5,12.5c0.2-0.1,0.5-0.3,0.7-0.4c0,0.4,0.1,0.9-0.3,1C79.6,13.1,79.5,12.7,79.5,12.5z"/>
			<path fill="#2F2E2B" d="M84.7,13.2c0.2,0.2,0.3,0.5,0.5,0.7c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2-0.2-0.3-0.3-0.5-0.5
				C84.5,13.6,84.7,13.4,84.7,13.2z"/>
			<path fill="#3E3B37" d="M82.4,14.4c-0.2,0-0.4,0-0.5-0.2c0.3,0,0.1-0.7,0.5-0.5C82.7,13.8,82.7,14.1,82.4,14.4z"/>
			<path fill="#49443F" d="M83.6,16.6c-0.3,0-0.5-0.1-0.5-0.5c0.2,0,0.4-0.1,0.5-0.3c0.1,0,0.2,0,0.3,0c0,0.2,0,0.5,0,0.7
				C83.7,16.6,83.6,16.6,83.6,16.6z"/>
			<path fill="#3E3B37" d="M83.2,14.2c-0.1-0.1-0.3-0.1-0.3-0.2c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.2
				C83.4,14,83.3,14.1,83.2,14.2z"/>
			<path fill="#524C46" d="M74.9,25.4c1.1,0.3,1.2,1,1,1.9c-0.2,0-0.3,0-0.5,0c-0.1-0.7-1-1.1-0.7-1.9
				C74.7,25.4,74.8,25.4,74.9,25.4z"/>
			<path fill="#4A4540" d="M74.4,30c0.4-0.1,0.7-0.4,0.9,0c0.1,0.1,0,0.5-0.1,0.5C74.8,30.7,74.7,30.3,74.4,30z"/>
			<path fill="#4B4641" d="M74.6,27.1c0.3,0.1,0.5,0.2,0.8,0.2c0,0.3,0.1,0.8-0.5,0.5C74.8,27.6,74.2,27.5,74.6,27.1z"/>
			<path fill="#20201E" d="M76.3,30.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C76.2,30.1,76.3,30.1,76.3,30.3z"/>
			<path fill="#0D0D0D" d="M79.5,29.3c-0.1,0.2-0.2,0.5-0.2,0.7c-1.2,0.9-1-0.7-1.7-0.9c0.5-0.1,1.2,0.4,1-0.5
				C78.8,28.8,78.9,29.3,79.5,29.3z"/>
			<path fill="#3B3835" d="M75.9,32.2c0.1,0.9-0.4,0.9-1,0.7c-0.1-0.2-0.1-0.3,0-0.5C75.3,32.5,75.6,32.4,75.9,32.2z"/>
			<path fill="#58514B" d="M74.9,32.4c0,0.2,0,0.3,0,0.5c-0.2,0-0.5,0-0.7,0C74.1,32.1,74.6,32.3,74.9,32.4z"/>
			<path fill="#111110" d="M141.4,95.8c-0.2,0-0.3,0-0.5,0c-0.1-0.3,0.2-0.4,0.4-0.5c0.3-0.1,0.6-0.2,1-0.2c0.2,0,0.4,0.2,0.6,0
				c0.1-0.1,0.1-0.2,0.2-0.3c0.3-0.1,0.2,0.1,0.1,0.3c-0.2,0.4-0.1,0.7,0.4,0.8c-0.1,0.7-0.3,1.5-0.9,1.9c-0.3-0.1-0.4-0.4-0.5-0.6
				c-0.2-0.3-0.5-0.4-0.6-0.7C141.5,96.2,141.6,95.9,141.4,95.8z"/>
			<path fill="#4B4640" d="M141.1,93.6c0.2-0.2,0.8-0.2,0.3-0.8c-0.2-0.3,0-0.6,0.4-0.6c0,0,0,0,0,0c0.3,0.2,0.5,0.1,0.6-0.2
				c0.1-0.3,0.2-0.6,0.6-0.5c0.1,0.6,0.1,1.1,0.2,1.7c-0.8-0.5-0.9,0.2-1.2,0.7c-0.2,0.4-0.5,0.6-0.9,0.3
				C141.2,93.9,141.2,93.7,141.1,93.6z"/>
			<path fill="#544D48" d="M142.6,97.6c0.5-0.5,0.4-1.4,1-1.9c1.1,0.8-0.1,1.3-0.2,1.9c-0.2,0.4,0.2,0.5,0.2,0.8
				c0.1,0.2,0.1,0.3,0.1,0.5c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.3,0.1-0.5,0.1c-0.5-0.1-0.4-0.7-0.8-0.9c0-0.2,0-0.3,0-0.5
				C142.3,97.8,142.4,97.7,142.6,97.6z"/>
			<path fill="#0D0D0C" d="M141.2,94.1c0.3-0.1,0.6-0.2,0.9-0.3c0,0.1,0,0.2,0,0.3c0.1,0.2-0.1,0.5,0.1,0.7c0,0.1,0,0.2-0.1,0.3
				c-0.3,0.1-0.5,0.3-0.8,0.4c-0.2,0-0.4,0-0.5,0.2c-0.2,0-0.4,0.2-0.5,0.1c-0.2-0.1,0-0.3,0-0.5C140.7,94.9,140.9,94.5,141.2,94.1z
				"/>
			<path fill="#302E2C" d="M143.4,98.6c0.1-0.3-0.6-0.6,0-1c0.2,0.4,0.4,0,0.7,0c0.3,0,0.5,0.2,0.8,0.5c0.1,0.2,0.1,0.4,0.2,0.5
				c-0.3,0-0.5,0.2-0.5,0.5c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.1l0,0
				C143.7,98.9,143.8,98.7,143.4,98.6z"/>
			<path fill="#4E4843" d="M144.2,95.6c-0.3-0.1-0.3-0.4-0.4-0.6c-0.1-0.2,0.1-0.4,0.3-0.4c0.3,0,0.5,0.1,0.6,0.5
				C144.7,95.3,144.5,95.5,144.2,95.6z"/>
			<path fill="#242322" d="M142.1,94.8c-0.5-0.3-0.2-0.5,0-0.7c0.4,0,0.6,0.1,0.2,0.5C142.3,94.7,142.2,94.8,142.1,94.8z"/>
			<path fill="#2C2B29" d="M145.2,95c0.2,0,0.4-0.1,0.5-0.3c0.1,0,0.1,0,0.2,0c0.6,0.7-0.4,0.4-0.5,0.7
				C145.3,95.4,145.2,95.2,145.2,95z"/>
			<path fill="#57504A" d="M144.8,98.1c-0.3-0.1-0.6-0.2-0.8-0.5c0.5-0.5,0.6,0,0.7,0.3C144.8,98,144.8,98.1,144.8,98.1z"/>
			<path fill="#504A45" d="M144.9,93.8c0.5,0.3,0.2,0.8,0.3,1.2C144.8,94.7,145.1,94.2,144.9,93.8z"/>
			<path fill="#47433F" d="M142.1,94.8c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.2,0.3,0.4,0.5,0.5c-0.1,0.2-0.3,0.2-0.5,0.2
				c-0.1-0.1-0.2-0.1-0.3-0.2C142.1,95,142.1,94.9,142.1,94.8z"/>
			<path fill="#373532" d="M83.6,11.7c0-0.2,0-0.4-0.2-0.5c0.2-0.2,0.3-0.3,0.5-0.5c0.2,0.1,0.3,0.2,0.5,0.3
				C84,11.3,83.8,11.5,83.6,11.7z"/>
			<path fill="#5A534D" d="M83.5,10.3c-0.1,0-0.2,0.1-0.4,0.2c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.1,0.3-0.1,0.4-0.1
				C83.5,10.1,83.6,10.1,83.5,10.3z"/>
			<path fill="#272624" d="M81.9,9.1c0.3,0,0.5,0,0.7,0.2C82.3,9.5,81.9,9.7,81.9,9.1z"/>
			<path fill="#5B544D" d="M80.2,16.3c0,0.1,0,0.2,0,0.3c-0.2,0.2-0.5,0.5-0.8,0.7c-0.1,0.1-0.2,0.1-0.2,0.2
				c-0.4,0.1-0.8-0.1-1.3-0.1c-0.1-0.1-0.3-0.2-0.2-0.4c-0.2-1,0.9-0.9,1.2-1.4c0.1,0.1,0.2,0.2,0.2,0.2
				C79.4,16.3,79.9,16.1,80.2,16.3z"/>
			<path fill="#4F4944" d="M77.8,17c0.1,0.1,0.2,0.2,0.3,0.3c1.2,0.6,1.3,0.8,0.8,2c-0.7,0.3-1.2-0.1-1.5-0.7
				c-0.3-0.3-0.7-0.4-0.8-0.9c0-0.1,0-0.3,0-0.4C77.1,17.7,77.4,17.3,77.8,17z"/>
			<path fill="#46423E" d="M77.3,14.6c0.4-0.6,0.5,0.2,0.7,0.2c0,0.2,0,0.5-0.3,0.4C77.4,15.2,77.4,14.9,77.3,14.6z"/>
			<path fill="#4B4641" d="M74.9,35.5c0-0.1,0-0.2,0-0.3c0.2,0.1,0.2-0.5,0.5-0.2c0.3,0.5,0.1,1-0.2,1.4
				C74.6,36.2,75.3,35.7,74.9,35.5z"/>
			<path fill="#0D0D0C" d="M77.5,23.7c0.1,0.5-0.5,0.6-0.5,1c0,0,0,0,0,0c-0.3,0.2-0.6,0.3-0.9,0.2c-0.2-0.1-0.3-0.2-0.3-0.4l0,0
				c0.5-0.5-0.3-0.8-0.2-1.2c0-0.1,0-0.2,0-0.3c-0.1-0.3-0.5-0.3-0.7-0.5c-0.2-0.1-0.2-0.3-0.3-0.5c0.3-0.6,0.4-1.1-0.1-1.6
				c0.8-0.2,0.7,0.7,1.1,0.9c0.1,0.1,0.1,0.1,0.1,0.2c0.1,0.3,0.4,0,0.5,0.2c0.4,0.3,0.5,0.7,0.6,1.1C76.9,23.3,77.2,23.5,77.5,23.7
				z"/>
			<path fill="#2A2927" d="M77.6,18.5c0.3,0.5,0.7,0.7,1.2,0.8c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.5,0,0.7
				c-0.2,0.1-0.4,0.2-0.5,0.5c-0.1,0.1-0.2,0.1-0.3,0c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.3-0.6-0.6-0.8c-0.1,0-0.1-0.1-0.2-0.2
				c0-0.4,0.2-0.9-0.5-0.9C77,18.5,77.3,18.5,77.6,18.5z"/>
			<path fill="#0D0E0D" d="M77.6,18.5c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.1-0.3,0.1-0.4,0.2c0-0.5-0.7-0.8-0.5-1.4c0.2,0,0.3,0,0.5,0
				C77,17.9,77.4,18.1,77.6,18.5z"/>
			<path fill="#1D1C1B" d="M77.5,19.9c0.1,0,0.2,0,0.3,0c0.3,0.6-0.2,0.7-0.5,1l0,0c-0.3,0.1-0.6,0-0.9-0.1c0,0-0.1-0.1-0.1-0.1
				C76.5,20,77.3,20.4,77.5,19.9z"/>
			<path fill="#3A3734" d="M76.1,17.8c0.1,0.5,0.8,0.8,0.5,1.4c-0.3-0.1-0.3-0.2-0.3-0.5c-0.1-0.2-0.3-0.2-0.5-0.2
				C75.9,18.2,75.8,17.9,76.1,17.8z"/>
			<path fill="#121211" d="M75.6,21.6c0-0.1,0-0.1,0-0.2c0.2-0.2,0.5-0.5,0.7-0.7c0,0,0,0,0,0c0.3,0.4,0.1,0.7-0.2,1
				C76,21.7,75.8,21.7,75.6,21.6z"/>
			<path fill="#030303" d="M74.7,36.9C74.4,37,74.2,37,74,37.1c0-0.2,0-0.5,0-0.7C74.3,36.6,74.5,36.8,74.7,36.9z"/>
			<path fill="#EDE2CF" d="M15.2,162.9c0.1-0.2,0.2-0.4,0.5-0.4c0,0,0.1,0.1,0.1,0.2C15.6,163,15.4,162.9,15.2,162.9
				C15.2,162.9,15.2,162.9,15.2,162.9z"/>
			<path fill="#58514B" d="M75.2,23.5c-0.1,0.2-0.2,0.4-0.5,0.5c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.5C74.9,23.1,75.1,23.3,75.2,23.5
				z"/>
			<path fill="#4F4A44" d="M148.8,89c-0.2,0-0.4,0.2-0.5-0.1c-0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.1-0.1,0.2,0
				c0.2,0.1,0.3,0.3,0.2,0.5C149,89,148.9,89,148.8,89z"/>
			<path fill="#0D0D0C" d="M148.8,88.5l-0.1,0c0,0-0.1,0-0.1,0c-0.1-0.4-0.2-0.7-0.5-0.9c0.3-0.4,0.8-0.2,1.2-0.5
				C149.4,87.7,148.9,88,148.8,88.5z"/>
			<path fill="#0C0C0C" d="M78.1,13.2c0,0.2,0,0.5,0,0.7c-0.2-0.1-0.4-0.3-0.6-0.4C77.6,13.4,77.8,13.3,78.1,13.2z"/>
			<path fill="#494540" d="M75.6,23.3c0,0.4,1.3,0.6,0.2,1.2c0-0.4-0.3-0.6-0.5-1C75.5,23.4,75.5,23.4,75.6,23.3z"/>
			<path fill="#46423D" d="M74.9,22.5c0.3,0.1,0.9-0.4,0.7,0.5C75.3,23,75,22.9,74.9,22.5z"/>
			<path fill="#151514" d="M127.7,101.5c0.5-0.4,1-0.6,1.9-0.3c-1.7,0.4-1,1.6-1.2,2.5c-0.5,0.1,0,0.5-0.2,0.7
				c-0.7-0.5-1.5-0.4-2.2-0.2c-0.2-0.4-0.7-0.9,0.2-1.1c0.3,0,0.5,0.2,0.8,0.3c0.1,0,0.3,0.2,0.2-0.1c-0.1-0.2-0.3-0.3-0.4-0.5
				c0-0.3,0.6-0.4,0.3-0.9C127.3,101.5,127.6,101.6,127.7,101.5z"/>
			<path fill="#383632" d="M131.3,103.7c0.5,1,1,0.4,1.4,0c0.8-0.2,0.5,0.4,0.6,0.8c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.6-0.4-0.7,0.1
				c-0.2,0.3-0.3,0.6-0.8,0.5l0,0c-0.3-0.2-0.7-0.3-0.8-0.7c-0.1-0.3-0.1-0.6-0.4-0.8c0.2-0.2,0.5-0.3,0.7-0.5
				C131.1,103.6,131.2,103.6,131.3,103.7z"/>
			<path fill="#171615" d="M130.8,104.8c0.1,0.4,0.8,0.2,0.7,0.7c-0.4,1-1.1-0.1-1.6,0.2c-0.3-0.3-0.5-0.6,0-1c0,0,0.1,0,0.1,0
				c0.2,0,0.4-0.1,0.4-0.2C130.6,104.5,130.7,104.7,130.8,104.8z"/>
			<path fill="#524C46" d="M126.3,103.1c-0.8,0.2,0.2,0.8-0.3,1c-0.4,0.1-0.8,0.1-1.4,0.2c0.5-0.7,0.5-1.5,1.5-1.6c0,0,0.1,0,0.1,0
				c0.1,0,0.1,0.1,0.2,0.2C126.4,103,126.3,103,126.3,103.1z"/>
			<path fill="#4D4843" d="M126,102.7c0.2-0.4-0.7-0.9,0.2-1.2c0.2-0.1,0.5-0.1,0.8,0C126.9,102.1,127,102.8,126,102.7L126,102.7z"
				/>
			<path fill="#514C46" d="M131.8,101c0,0.1,0,0.2,0,0.4c-0.1,0.3-0.1,0.7-0.5,0.7c-0.4-0.1-0.2-0.5-0.2-0.8
				C131.3,101.2,131.6,101.1,131.8,101z"/>
			<path fill="#4C4742" d="M128.2,104.4c0.1-0.2-0.8-0.8,0.2-0.7c0.1,0,0.4,0,0.3,0.2C128.7,104.1,128.5,104.2,128.2,104.4z"/>
			<path fill="#4D4843" d="M131.3,103.7c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.5-0.2-0.2-0.5c0.2-0.1,0.4-0.2,0.6-0.4
				C131.4,103.1,131.4,103.4,131.3,103.7z"/>
			<path fill="#44403B" d="M127,101.5c-0.3,0-0.5,0-0.8,0c0.5-0.4,1-0.2,1.5,0c-0.1,0.2-0.3,0.2-0.5,0.2
				C127.1,101.7,127,101.6,127,101.5z"/>
			<path fill="#605851" d="M130.8,104.8c-0.1-0.1-0.3-0.2-0.4-0.2c0-0.2,0-0.3,0-0.5C130.9,104.1,131.2,104.2,130.8,104.8z"/>
			<path fill="#423E3A" d="M130.1,102.5c0,0.3-0.1,0.6-0.4,0.6c-0.1,0-0.2-0.1-0.2-0.2C129.5,102.6,129.8,102.5,130.1,102.5
				C130.1,102.5,130.1,102.5,130.1,102.5z"/>
			<path fill="#232321" d="M128.9,105.5c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.4-0.3
				C128.8,105.3,128.8,105.4,128.9,105.5z"/>
			<path fill="#090909" d="M143.5,99.1c0,0.1,0,0.2,0,0.3c0.3,0.9,0,1.6-0.7,2.1c-0.7,0.4-0.5-0.4-0.8-0.6c-0.3,0.1-0.3,0.5-0.6,0.6
				c-0.4,0-0.6-0.3-0.6-0.7c-0.1-0.5,0.6-0.5,0.5-1c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.3,0.5-0.4c0.5-0.5,0.8,0.6,1.3,0.2
				C143.3,99.1,143.4,99.1,143.5,99.1z"/>
			<path fill="#544E48" d="M140.4,102.5c-0.1,0.3,1.2,0.6,0,0.9c-0.3,0.5-0.6,0.8-1,1.2l0,0c-0.2,0.1-0.5,0-0.7,0.2l-0.1,0l-0.1,0
				c0,0-0.1-0.1-0.1-0.1c-0.4-0.5-0.3-1.1-0.6-1.6c-0.3-0.7,0.2-1,0.9-1.1c0.6-0.1,1.1,0.3,1.6,0.3
				C140.4,102.4,140.4,102.5,140.4,102.5z"/>
			<path fill="#4F4A44" d="M141.4,99.3c0,0.2,0,0.5,0,0.7c-0.2,0.2-0.7,0.2-0.5,0.7c0,0.3,0.2,0.6,0.5,0.7c0.1,0.2,0.1,0.3,0,0.5
				c-0.3,0.2-0.6,0.3-1,0.5c0,0,0,0,0,0c-0.7-0.7-1-1.6-1.1-2.5c0-0.9,0.7-0.7,1.3-0.8c0.2-0.1,0.4-0.1,0.6,0
				C141.3,99.2,141.4,99.2,141.4,99.3z"/>
			<path fill="#181817" d="M145.2,99.8c0.4,0.7,0,1.3-0.2,1.9c-0.3,0-0.7,0.5-0.7,0c0-0.3-0.4-1,0.2-1.1
				C145,100.5,144.9,100,145.2,99.8z"/>
			<path fill="#242322" d="M141.4,102c0-0.2,0-0.3,0-0.5c0-0.7,0.5-0.9,1.1-1c0.2,0.3-0.5,1,0.4,1c-0.1,0.2-0.2,0.3-0.3,0.5
				c-0.2,0.1-0.4,0.4-0.7,0.2C141.8,102,141.6,102,141.4,102z"/>
			<path fill="#131312" d="M138,106.1c-0.5,0.5,0,0.9,0,1.4c-0.4,0-0.6-0.5-1.1-0.1c0.4-0.8,0.9-1.6,1-2.5c0.2-0.2,0.4-0.2,0.6,0
				c0,0,0.1,0,0.1,0c0,0.1-0.1,0.1-0.1,0.2C138.1,105.2,138.3,105.8,138,106.1z"/>
			<path fill="#131312" d="M141.9,102.2c0.2-0.1,0.3-0.5,0.7-0.2c0,0,0,0.1,0,0.1c0,0.5,0.6,1.2-0.1,1.4
				C141.9,103.6,141.9,102.7,141.9,102.2z"/>
			<path fill="#0D0D0C" d="M139.5,104.6c-0.2-0.8,0.5-1,1-1.2c0.3,0.6-0.1,1.1-0.3,1.5C139.9,105.4,139.7,104.6,139.5,104.6z"/>
			<path fill="#0B0C0B" d="M143.8,103.7c-0.5-0.5,0.1-1.1-0.3-1.6C144.2,102.5,144.3,103,143.8,103.7
				C143.8,103.7,143.8,103.7,143.8,103.7z"/>
			<path fill="#272624" d="M141.1,105.6c-0.2,0.4-0.3,1-1,0.7C140.5,106.1,140.2,105,141.1,105.6z"/>
			<path fill="#31302D" d="M138.8,106.8c-0.4-0.2,0-0.5,0-0.7c-0.1-0.2-0.1-0.3,0-0.5c0.5,0.2,0.5,0.6,0.5,1
				C139,106.5,138.9,106.6,138.8,106.8z"/>
			<path fill="#56504A" d="M138.8,105.6c0,0.2,0,0.3,0,0.5c-0.2,0-0.5,0-0.7,0c0.1-0.4-0.1-0.9,0.5-1
				C138.5,105.3,138.3,105.6,138.8,105.6z"/>
			<path fill="#181817" d="M138.8,104.8c0-0.2,0-0.5,0.3-0.6c0.1,0,0.3,0.2,0.4,0.3C139.3,105,139.1,105.1,138.8,104.8z"/>
			<path fill="#0F0F0E" d="M138.5,104.9c-0.2,0-0.4,0-0.6,0c-0.3-0.3-0.9-0.1-1.1-0.8c-0.2-0.6-0.6-0.3-0.9,0
				c-0.1,0.1-0.2,0.3-0.4,0.5c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2-0.1-0.4-0.2-0.5-0.4c0-0.1,0-0.3,0-0.4c0.2-0.2,0.4-0.2,0.5-0.5
				c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.4-0.5,0.7-0.4c0.1,0,0.2,0,0.3,0.1c0.3,0.2,0.5,0.3,0.8,0.5c0.1,0.1,0.2,0.2,0.2,0.3
				c0.2-0.1,0-0.2,0-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.3-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5c0.1-0.2,0.7,0.2,0.5-0.4
				c0.3-0.3,0.7-0.6,1-1c0.2-0.3,0.4-0.2,0.7-0.1c0.3,0.8,0.7,1.5,1,2.3c-0.2,0.1-0.4,0.8-0.8,0.2c-0.3-0.4-0.7-0.2-1.1-0.3
				c-0.5-0.1-0.9,0.3-0.5,0.9C138.4,103.7,138.5,104.3,138.5,104.9z"/>
			<path fill="#1F1E1D" d="M134.9,104.4c0.2,0.1,0.3,0.2,0.5,0.3c-0.1,0.6,0.3,0.6,0.7,0.7c0.1,0.4,0.8,0.6,0.3,1.1
				c-0.5,0.5-1,0.3-1.5-0.1c0,0-0.1-0.1-0.1-0.1c-0.2-0.4-0.5-0.7-0.4-1.1C134.5,104.8,134.6,104.5,134.9,104.4z"/>
			<path fill="#494540" d="M136.1,105.4c-0.5-0.1-0.4,0.5-0.8,0.7c-0.4-0.5-0.4-0.9,0-1.4c0,0,0,0,0,0c0.2-0.2,0.5,0.2,0.7,0
				C136.5,104.9,136.2,105.1,136.1,105.4z"/>
			<path fill="#060606" d="M134.9,107.3c-0.7,0.3-1.1,0.3-0.9-0.7c0-0.1,0.1-0.1,0.2-0.1c0.2,0,0.3-0.1,0.5-0.2c0.1,0,0.2,0,0.2,0.1
				c0,0,0,0,0,0C134.9,106.6,134.9,106.9,134.9,107.3z"/>
			<path fill="#1F1E1D" d="M136.1,104.6c-0.2,0.4-0.5,0.1-0.7,0c0.1-0.2,0.3-0.4,0.5-0.5C135.9,104.3,135.9,104.5,136.1,104.6z"/>
			<path fill="#060606" d="M132.5,107c-0.6-0.1-1.1,0.3-1.7,0c0.4-0.4,1.1-0.5,1.2-1.2c0,0,0.1-0.1,0.1-0.1c0.6-0.2,0.9,0.3,1.3,0.5
				c0.1,0.1,0.1,0.2,0.1,0.3C133.3,106.9,133.2,107.5,132.5,107z"/>
			<path fill="#5A534D" d="M131.6,105.6c0.2-0.2,0.5-0.5,0.7-0.7c0.2-0.1,0.3,0,0.4,0.2c0,0.1,0,0.2,0,0.3c-0.1,0.3-0.4,0.3-0.6,0.4
				c0,0,0,0,0,0C131.8,105.8,131.6,105.8,131.6,105.6z"/>
			<path fill="#5C554E" d="M129.9,104.9c-0.2,0.3-0.1,0.6,0,1c-0.6,0-0.5-0.4-0.5-0.8C129.4,104.6,129.7,104.8,129.9,104.9z"/>
			<path fill="#56504A" d="M133.5,106.5c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0.1-0.1c0.2-0.3,0.3-0.6,0.5-0.9c0,0,0.1-0.1,0.1-0.1
				c0.1-0.1,0.2-0.1,0.3,0c0.2,0.4,0.6,0.7,0.5,1.2c-0.1,0-0.2,0-0.2,0c-0.2,0.1-0.2,0.4-0.5,0.2c-0.1,0-0.1,0-0.2,0
				C133.8,106.6,133.6,106.6,133.5,106.5z"/>
			<path fill="#0D0D0C" d="M79,19.7c-0.2-0.1-0.2-0.3-0.2-0.4c-0.3-0.5,0.9-1.7-0.8-1.4c-0.2,0,0.1-0.4,0.1-0.6
				c0.4-0.2,0.8-0.1,1.1,0.2c0.1,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.6,0.1,0.9-0.1c0.7-0.3,0.1-0.8,0.2-1.2c0.7-0.1,0.9,0.4,1.2,0.8
				c0.2,0.3-0.2,0.5-0.3,0.7c-0.4,0.3-1,0.4-1.1,1c-0.1,0.2-0.1,0.3-0.2,0.5C80,20.4,79.5,20,79,19.7z"/>
			<path fill="#57504A" d="M76.1,21.6c0.1-0.3,0.2-0.6,0.2-0.9c0.4-0.1,0.7,0.1,1,0.2c-0.1,0.2-0.7,0.5-0.1,0.7
				c1.1,0.3,0.7,0.6,0.1,1c-0.3,0.2,0,0.4,0.2,0.5c0,0.2,0,0.4,0,0.6c-0.6,0.2-1.1,0.5-0.9-0.5c0.1-0.5-0.3-0.9-0.5-1.3
				C76,21.7,76,21.7,76.1,21.6z"/>
			<path fill="#57514A" d="M80.9,19.9c-0.7-1.2,0.8-0.7,1-1.2c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0.3,0.6,0.6,0.9,0.9
				c0.1,0.3,0.2,0.5,0.1,0.8c0.1,0.3,0.5,0.3,0.4,0.7c-0.3,0.2-0.5,0.5-0.7-0.1C82.7,19.9,81.9,19.7,80.9,19.9z"/>
			<path fill="#171716" d="M80.2,24.4c-0.1-0.2-0.3-0.2-0.5-0.2c-0.3-0.1-0.1-0.3-0.1-0.5c0.2-0.2,0-0.6,0.5-0.6
				c0.1,0.2,0.3,0.3,0.5,0.3c0,0.1,0,0.3,0.1,0.3c0.3,0.2,1.1,0.2,0.9,0.7C81.4,25,80.7,24.8,80.2,24.4z"/>
			<path fill="#4B4641" d="M79,19.7c0.7-0.1,1.2,0.4,1.8,0.7c0,0.8-0.7,0.4-1,0.6c-0.4,0.2-0.5-0.2-0.5-0.5
				C79.1,20.2,79.1,19.9,79,19.7z"/>
			<path fill="#47433E" d="M77.3,20.9c0.2-0.3,0.3-0.7,0.5-1c0.7,0.1,0.2,0.8,0.5,1C78,21.3,77.6,21.2,77.3,20.9z"/>
			<path fill="#59524C" d="M80.2,23.2c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1-0.3-0.9-0.6-0.2-1C79.8,22.5,80.1,22.8,80.2,23.2z"/>
			<path fill="#58514B" d="M81.6,20.3c0.5,0.4,0.9,0.5,0.8,0.9c0,0.2-0.2,0.3-0.5,0.2C81.3,21.4,81.8,20.9,81.6,20.3z"/>
			<path fill="#44403C" d="M78.5,20.9c0.1,0,0.2,0,0.3,0c0.2,0.1,0.3,0.3,0.1,0.5c-0.1,0.1-0.2,0.1-0.3,0.1
				C78.6,21.2,78.5,21,78.5,20.9z"/>
			<path fill="#524C46" d="M78.5,23.7c-0.2,0-0.5,0-0.5-0.3c0-0.2,0.2-0.2,0.3-0.2C78.5,23.4,78.5,23.5,78.5,23.7z"/>
			<path fill="#45413C" d="M75.9,24.5c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0.2,0.6,0,0.5,0.5c-0.3,0.3-0.7,0.3-0.9,0.1
				C75.4,25.1,75.6,24.7,75.9,24.5z"/>
			<path fill="#2F2D2B" d="M76.6,25.2c-0.2-0.2-0.3-0.3-0.5-0.5c0.3,0,0.6,0,1,0C77,24.9,76.8,25.1,76.6,25.2z"/>
			<path fill="#4D4843" d="M82.2,32.6c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0-0.3-0.2-0.2-0.3c0-0.1,0.2-0.1,0.2-0.2
				C82,32.3,82.1,32.4,82.2,32.6z"/>
			<path fill="#5B544D" d="M85.5,15.9c0.2,0.3,0.4,0.6,0,0.9c-0.4-0.2-0.7-0.5-0.7-1C85,15.8,85.2,15.8,85.5,15.9z"/>
			<path fill="#373532" d="M84.3,11c0.1-0.3,0.4-0.5,0.7-0.5c-0.4,0.3,0.4,0.6-0.1,0.9C84.6,11.6,84.5,11.1,84.3,11z"/>
			<path fill="#2F2E2B" d="M85.7,13c-0.3,0-0.6,0-0.7-0.3c0-0.1,0-0.3,0-0.4c0.3-0.2,0.4,0.1,0.5,0.2c0.1,0,0.2,0,0.3,0
				C85.7,12.6,85.7,12.8,85.7,13z"/>
			<path fill="#524D47" d="M85.5,12.5c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.3-0.4,0-0.4C85.4,11.8,85.5,12.1,85.5,12.5z"/>
			<path fill="#090908" d="M74.9,45.8c0-0.3-0.1-0.8,0.5-0.4c0,0.2,0,0.3,0,0.5C75.2,45.8,75.1,45.8,74.9,45.8z"/>
			<path fill="#040404" d="M83.6,16.6c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0,0.5,0.2c0,0.5-0.4,0.5-0.7,0.5C83,17,83.3,16.8,83.6,16.6z"/>
			<path fill="#252523" d="M84.5,18c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0.2,0.2,0.5,0.5,0.5c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.1
				c-0.2,0.3-0.5,0.3-0.8,0.4C84.2,19.2,84.6,18.5,84.5,18z"/>
			<path fill="#3B3935" d="M83.3,18.7c0.3,0.2,0.5,0.6,0.9,0.5c0,0.1,0,0.2,0,0.3c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0.2-0.4,0.1-0.5,0
				C83.1,19.3,83,19,83.3,18.7z"/>
			<path fill="#050505" d="M83.3,19.6c0.2,0,0.3,0,0.5,0c0,0.3-0.1,0.6-0.5,0.7c-0.1-0.2-0.3-0.4-0.2-0.7
				C83.2,19.6,83.2,19.5,83.3,19.6z"/>
			<path fill="#040404" d="M85.3,18.7c-0.7,0.4-0.4-0.3-0.5-0.5C84.9,18.4,85.1,18.5,85.3,18.7z"/>
			<path fill="#D1AF9D" d="M128.4,33.1c-0.5,0.9-1,1.8-1.4,2.6c-0.8-0.1-0.1-0.6-0.2-1l0,0c0.4-0.2,0.5-0.6,0.5-1c0,0,0,0,0,0
				c0.3-0.5,0.2-1.3,1.2-1C128.4,32.9,128.4,33,128.4,33.1z"/>
			<path fill="#C9AB96" d="M124.1,39.1c0,0.3,0.1,0.7,0.2,1c-0.3-0.1-1.1,0.6-0.7-0.5C123.6,39.3,123.8,39.1,124.1,39.1z"/>
			<path fill="#C9AB96" d="M122.4,39.1c-0.3,0.1-0.5,0.2-0.5-0.2c0.2-0.1,0.5-0.4,0.7-0.2C122.9,38.9,122.6,39,122.4,39.1z"/>
			<path fill="#D4B1A0" d="M126.5,37.9c-0.3-0.1-0.6,0.1-0.9-0.4C126,37.6,126.4,37.3,126.5,37.9C126.5,37.9,126.5,37.9,126.5,37.9z
				"/>
			<path fill="#D1AF9D" d="M120,36.5c0.1-0.2,0.1-0.5,0.4-0.5c0.1,0,0.3,0.4,0.2,0.5C120.5,36.7,120.2,36.4,120,36.5L120,36.5z"/>
			<path fill="#C9AB96" d="M123.2,39.6c0,0.3-0.2,0.5-0.5,0.3c-0.1-0.1-0.2-0.1-0.3-0.2c0.2,0,0.4-0.1,0.5-0.1
				C123,39.6,123.1,39.6,123.2,39.6z"/>
			<path fill="#D1AF9D" d="M126.8,34.8c-0.2,0-0.4,0.2-0.4-0.1c0-0.1,0.1-0.3,0.1-0.3C126.7,34.4,126.7,34.6,126.8,34.8
				C126.8,34.8,126.8,34.8,126.8,34.8z"/>
			<path fill="#C1A790" d="M128,39.3c0-0.1-0.1-0.1-0.2-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0.1,0.2,0.1
				C128.3,39.3,128.2,39.3,128,39.3z"/>
			<path fill="#DCB6A7" d="M128.4,32.9c-0.7,0-0.8,0.6-1.2,1c0-1.1-0.1-2.1-1-2.9C127.6,30.9,127.7,32.3,128.4,32.9z"/>
			<path fill="#0C0C0C" d="M132.7,99.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.1,0-0.2,0-0.3c0.5-0.4,0.6,0.2,0.7,0.4c0.1,0.3,0.4,0.2,0.5,0.3
				c0.3,0.1,0.8-0.5,0.9,0.3c0.1,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.3,0.2,0.4,0.4c0.3,0.4,0.2,0.8-0.2,1.1c-0.2,0.1-0.3,0.1-0.5,0.1
				c-0.4,0-0.7-0.3-1-0.6c-0.6-0.6-1.1-0.5-1.4,0.4c-0.1,0.1-0.2,0.1-0.3,0c-0.2-0.1-0.5,0-0.3-0.4
				C132.8,100.4,133.2,99.9,132.7,99.3z"/>
			<path fill="#534D47" d="M133,101.3c0-0.4-0.1-0.8,0.4-1c0.4-0.1,0.8,0,1.1,0.3c0.3,0.2,0.4,0.7,0.9,0.7c0.1,0.1,0.2,0.3,0.1,0.5
				c-0.1,0.4-0.5,0.4-0.8,0.5c-0.4-0.5-0.7-1-1.4-1C133.1,101.3,133.1,101.4,133,101.3z"/>
			<path fill="#423E3A" d="M134.4,105.1c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.4-0.2-0.4-0.5c0-0.2,0.3-0.4-0.1-0.5
				c-0.2-0.1-0.4-0.3-0.3-0.7c0.5-0.5,0.6,0.3,0.9,0.3c0.2,0.1,0.4,0,0.5,0.2c0,0.2,0,0.3,0,0.5C134.9,104.7,134.8,105,134.4,105.1z
				"/>
			<path fill="#191918" d="M133.5,103.4c-0.1,0.5,1.2,0.5,0.4,1.2c-0.4,0.3-0.5,0-0.7-0.2c-0.4-0.1,0.1-0.8-0.5-0.7
				C132.9,103.4,133.2,103.4,133.5,103.4z"/>
			<path fill="#252523" d="M134.7,102.2c0.2-0.2,0.5-0.3,0.7-0.5c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.1,0.2,0.1,0.3
				c-0.1,0.2-0.3,0.3-0.6,0.3c-0.2,0-0.3-0.1-0.5-0.2C134.8,102.4,134.7,102.3,134.7,102.2z"/>
			<path fill="#1F1E1D" d="M134.9,103.9c-0.2-0.1-0.4-0.1-0.5-0.2c0.5,0,0.5-0.4,0.5-0.8c0.5-0.1,0.4,0.5,0.7,0.7
				C135.5,103.9,135.2,103.9,134.9,103.9z"/>
			<path fill="#56504A" d="M135.6,103.6c-0.6,0.1-0.4-0.5-0.7-0.7c0-0.2,0-0.3,0-0.5c0.1,0,0.3,0,0.4,0c0.4,0.2,0,0.7,0.2,1
				C135.6,103.5,135.6,103.6,135.6,103.6z"/>
			<path fill="#222220" d="M133,101.3c0.1,0,0.2,0,0.3,0c0,0.2,0.1,0.6-0.3,0.6c-0.3,0-0.2-0.4-0.2-0.6
				C132.8,101.3,132.9,101.3,133,101.3z"/>
			<path fill="#2B2A28" d="M143.1,91.4c-0.5,0.1-0.3,1.4-1.2,0.7c0.5-0.2,0.4-0.9,1-1C142.9,91.3,143,91.3,143.1,91.4z"/>
			<path fill="#242422" d="M135.9,101.3c0.2-0.3,0.2-0.6,0-0.9c0.6,0,0.3-0.6,0.6-0.9c0.2-0.1,0.5-0.2,0.7-0.3
				c0.2-0.2,0.7,0,0.6-0.5c0.2-0.2,0.3-0.3,0.5,0.1c0.1,0.3,0.3,0.6,0.5,0.9c0,0-0.1,0.1-0.1,0.1c-0.3,0.1-0.6,0.1-0.8,0.3
				c-0.5,0.6-1.1,1.1-1.9,1.3C136,101.3,135.9,101.3,135.9,101.3z"/>
			<path fill="#57504A" d="M137.8,98.6c0.3,0.5-0.2,0.6-0.4,0.7c-0.3-0.1-0.6,0-0.8,0.3c-0.2,0.3,0,0.9-0.7,0.8
				c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.3-0.2-0.6,0-1c0.6,0,0.7-1.1,1.4-0.7C137.3,98.5,137.5,98.5,137.8,98.6z"/>
			<path fill="#090908" d="M139.5,97.9c-0.1-0.4,0.3-0.6,0.5-0.9c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.5-0.1,0.6,0.3
				c-0.1,0.4-0.3,0.8-0.3,1.2c-0.1,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3,0-0.5-0.1C140.1,98.2,139.8,98,139.5,97.9z"/>
			<path fill="#1E1D1C" d="M140.4,98.4c0.2,0,0.3,0,0.5,0c0.1,0.1,0.1,0.2,0.1,0.3c0,0.3-0.2,0.5-0.4,0.7c-0.5,0.1-1.2-0.1-1.2,0.8
				c-0.2,0.2-0.3-0.1-0.4-0.1c-0.4,0-0.4-0.2-0.3-0.5c0,0,0,0,0,0c0.3,0,0.4-0.1,0.5-0.4c0.1-0.6,0.1-0.6,0.7-0.3
				C140.6,99.3,140.4,98.7,140.4,98.4z"/>
			<path fill="#242322" d="M141.4,97.2c0-0.3-0.2-0.5-0.5-0.5c0.3-0.3,0.6-0.5,0.5-0.9c0.3,0.2,0.3,0.4,0.3,0.7
				c0.2,0.2,0.4,0.3,0.6,0.5c0,0.2-0.1,0.4-0.3,0.5C141.6,97.6,141.4,97.6,141.4,97.2z"/>
			<path fill="#060606" d="M134.2,99.1c-0.2,0-0.4,0.7-0.6,0c-0.1-0.2-0.2-0.7-0.6-0.7c-0.1-1,0.3-0.9,0.9-0.5
				C133.5,98.4,134.2,98.7,134.2,99.1z"/>
			<path fill="#605851" d="M135.6,99.1c0,0.3,0,0.6,0,1c-0.4-0.1-0.8-0.2-0.4-0.7C135.3,99.3,135.5,99.2,135.6,99.1z"/>
			<path fill="#3A3734" d="M134.9,96c0,0.2-0.1,0.4-0.3,0.5c-0.3,0-0.5,0-0.7-0.3C134.3,96.3,134.6,96.2,134.9,96z"/>
			<path fill="#21201F" d="M79.5,17.3c0-0.5,0.4-0.6,0.8-0.7c0,0,0,0.1,0,0.1C80.1,17.1,80.4,17.9,79.5,17.3z"/>
			<path fill="#3A3834" d="M82.4,18.7c-0.2,0-0.3,0-0.5,0c0.1-0.2,0.3-0.3,0.4-0.5c0.2,0,0.3,0,0.5,0c0,0.1,0,0.2,0,0.2
				C82.7,18.7,82.6,18.8,82.4,18.7z"/>
			<path fill="#554E48" d="M80.2,16.3c-0.4-0.1-0.9,0.2-1-0.5c0.3,0.3,0.8,0.2,1.2,0.3c0.2,0,0.1,0.2,0,0.1
				C80.4,16.2,80.3,16.3,80.2,16.3z"/>
			<path fill="#1E1E1C" d="M82.4,18.7c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0.1,0.3,0.2,0.5,0.3c0,0.3,0,0.6,0,0.9c-0.1,0-0.1,0-0.2,0
				C82.8,19.4,82.1,19.4,82.4,18.7z"/>
			<path fill="#0A0A0A" d="M141.8,97.5c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.1-0.1,0.2,0c0.3,0.2,0.3,0.4,0.3,0.7
				c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.4,0-0.5-0.2C141.8,97.6,141.8,97.6,141.8,97.5z"/>
			<path fill="#242322" d="M142.1,95c0.1,0.1,0.2,0.1,0.3,0.2c-0.3,0.1-0.7,0.2-1,0.3C141.5,95.2,141.7,95,142.1,95z"/>
			<path fill="#48443F" d="M142.3,96.9c-0.1,0-0.1,0-0.2,0c-0.3,0-0.5-0.2-0.5-0.5C142,96.5,142.2,96.6,142.3,96.9z"/>
			<path fill="#242322" d="M141.9,97.7c0.2,0.1,0.3,0.2,0.5,0.2c0,0.1,0,0.3,0,0.4c-0.1,0.3-0.4,0.4-0.5,0.7
				c-0.1,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.2,0-0.3,0c-0.2-0.2-0.1-0.5-0.2-0.7c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.2,0.2-0.2
				C141.4,98.1,141.4,97.5,141.9,97.7z"/>
			<path fill="#4F4A44" d="M141.9,99.1c-0.3-0.6,0.1-0.6,0.5-0.7c0.4,0.1,0.5,0.5,0.8,0.8C142.7,99.8,142.3,98.7,141.9,99.1z"/>
			<path fill="#090909" d="M143.4,98.6c0.2-0.1,0.4-0.3,0.8-0.2c-0.2,0.2-0.4,0.4-0.6,0.6C143.5,99,143.4,98.8,143.4,98.6z"/>
			<path fill="#4D4842" d="M76.1,21.6c0,0.1,0,0.2,0,0.3c-0.3,0.1-0.6,0.5-0.9,0.2c-0.2-0.3,0.1-0.4,0.4-0.5
				C75.8,21.6,75.9,21.6,76.1,21.6z"/>
			<path fill="#0C0C0C" d="M127,101.5c0.1,0.1,0.2,0.1,0.2,0.2c0.7,0.5,0,0.7-0.2,1c0,0.5-0.4,0.4-0.7,0.3c-0.1-0.1-0.2-0.2-0.2-0.3
				C126.7,102.6,126.6,101.8,127,101.5z"/>
			<path fill="#4F4A44" d="M126.3,103c0.2-0.1,0.6-0.1,0.7-0.3c0.3,0,0.5,0.2,0.5,0.5c-0.1,0.2-0.3,0.3-0.5,0.5
				C126.7,103.5,126.5,103.3,126.3,103C126.3,103.1,126.3,103,126.3,103z"/>
			<path fill="#060606" d="M127,103.7c0.1-0.2,0.3-0.4,0.5-0.5c0.1,0,0.2,0.1,0.3,0.1C127.6,103.6,127.3,103.6,127,103.7z"/>
			<path fill="#080807" d="M133.2,104.4c0.2,0.1,0.5,0.2,0.7,0.2c0.1,0.2,0.2,0.3,0.3,0.5c0,0,0,0,0,0c-0.2,0.3-0.3,0.7-0.7,0.8
				c-0.4-0.1-0.7-0.3-0.9-0.6c0-0.1,0-0.2,0-0.3c0.1-0.2,0.3-0.3,0.5-0.2C133.2,104.8,133.2,104.6,133.2,104.4z"/>
			<path fill="#534D47" d="M133,104.9c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.3,0.1-0.3,0.2-0.3
				C132.8,104.4,132.9,104.7,133,104.9z"/>
			<path fill="#242322" d="M140.9,100.8c-0.4-0.7,0-0.7,0.5-0.7C141.5,100.5,140.9,100.4,140.9,100.8z"/>
			<path fill="#080808" d="M140.9,98.6c0.4,0.1,0.4,0.4,0.2,0.7c-0.2,0-0.3,0-0.5,0C140.8,99.1,140.8,98.9,140.9,98.6z"/>
			<path fill="#5A534C" d="M135.8,102.3c-0.1,0-0.1,0-0.2,0c-0.1-0.3,0-0.6,0.2-0.8c0.3-0.1,0.7-0.1,1-0.4c0.3-0.3,0.6-0.3,0.8,0.1
				c0,0.3-0.2,0.4-0.3,0.6c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.3-0.1,0.9-0.7,0.7l0,0C136,102.8,136,102.5,135.8,102.3z"/>
			<path fill="#514B45" d="M138.8,99.6c0,0.2,0.1,0.4,0.3,0.5c0,0.8-0.5,1.1-1.2,1.2c0,0-0.1-0.1-0.1-0.1c-0.3-0.5,0.2-0.9,0.1-1.3
				C137.9,98.9,138.5,99.7,138.8,99.6z"/>
			<path fill="#4D4843" d="M137.5,102.7c-0.1,0.5,0.5,1,0,1.5c-0.5-0.1-0.1-0.7-0.5-0.8C136.8,102.9,137,102.7,137.5,102.7z"/>
			<path fill="#33312E" d="M137.5,102.7c-0.3,0.1-0.5,0.4-0.5,0.7c-0.3,0-0.6-0.2-0.7-0.5c0.4-0.1,0.4-0.5,0.7-0.7
				C137.2,102.4,137.4,102.6,137.5,102.7z"/>
			<path fill="#33312E" d="M135.8,102.3c0.3,0.1,0.4,0.4,0.5,0.7c-0.4,0-0.7,0-0.7,0.5c-0.3-0.3-0.2-0.6-0.2-1
				C135.5,102.4,135.7,102.3,135.8,102.3z"/>
			<path fill="#3E3B37" d="M137.3,101.8c0.1-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.2-0.1,0.2,0c0,0,0,0,0,0c0.2,0.1,0.4,0.3,0.6,0.4
				C138,102,137.7,101.6,137.3,101.8z"/>
			<path fill="#1D1D1B" d="M134.2,106.6c0.2-0.1,0.3-0.2,0.5-0.2c0,0.2,0.1,0.5-0.2,0.6C134.2,107,134.2,106.7,134.2,106.6z"/>
			<path fill="#413D39" d="M132.5,105.3c0.4-0.1,0.7,0.2,1,0.5c0.1,0.2,0.1,0.3,0,0.5c0,0,0,0,0,0c-0.6,0.2-0.9-0.6-1.5-0.5
				C132.2,105.6,132.3,105.5,132.5,105.3z"/>
			<path fill="#232321" d="M133.5,106.3c0-0.2,0-0.3,0-0.5c0.1-0.3,0.4-0.6,0.7-0.7C134,105.5,134.3,106.3,133.5,106.3z"/>
			<path fill="#10100F" d="M135.9,101.5c-0.1,0.2-0.2,0.5-0.2,0.7c-0.2-0.1-0.2-0.3-0.3-0.5c0-0.1,0-0.3,0.1-0.4c0.2,0,0.3,0,0.5,0
				v0C136,101.3,136,101.4,135.9,101.5C135.9,101.5,135.9,101.5,135.9,101.5z"/>
			<path fill="#050505" d="M135.9,101.5c0-0.1,0-0.1,0-0.2c0.8-0.3,1.3-0.9,1.9-1.5c0.7,0.5-0.3,1,0,1.5c-0.1,0-0.2,0-0.2,0
				C136.9,100.9,136.5,101.9,135.9,101.5z"/>
			<path fill="#121211" d="M136.5,99.6c0-0.2-0.3-0.5,0.1-0.6c0.3-0.1,0.7-0.2,0.7,0.3C137.1,99.6,136.8,99.6,136.5,99.6z"/>
			<path fill="#524C47" d="M141.9,97.7c-0.4,0,0,1-0.7,0.5c-0.1-0.4-0.6-0.8,0.3-0.9c0.2,0.1,0.3,0.2,0.5,0.2
				C141.9,97.5,141.9,97.6,141.9,97.7z"/>
		</g>
	</g>
</g>
</svg>
        )
    }
}

export default Character1Mailbox;