import React from "react"

class Character1Airplane extends React.Component {
    render(){
        return(
<svg version="1.1" id="character-1" className="character-animated character-animated--airplane" x="0px" y="0px" viewBox="0 0 452.3 576.7" enable-background="new 0 0 452.3 576.7">
<g id="glide">
	<g id="_x37_aV8Vf_1_">
		<g>
			<path fill="#F9F9F8" d="M226.1,98.2c0.5-0.2,0.7-0.3,0.9-0.4c0,0.2-0.1,0.4-0.4,0.5C226.6,98.3,226.4,98.2,226.1,98.2z"/>
			<path fill="#FEFEFE" d="M27.9,39.9c-1.2-1.5-2.4-3-3.6-4.5c-1.7-2.2-3.3-4.3-4.9-6.5c-1.3-1.7-2.5-3.4-3.7-5
				c-0.2-0.4-0.5-0.6-0.7-1c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.9-0.7-1.2-1.2-1.7l0,0c-0.2-0.2-0.1-0.7-0.5-0.7c-0.2-0.1-0.3-0.2-0.5-0.3
				c-0.1-0.4-0.4-0.7-0.7-1c-0.4-1.1-1.4-1.8-2-2.7c-0.1-0.2-0.3-0.2-0.5-0.1c-0.3,0.1-0.6,0.2-0.6-0.2c0-0.1,0.3-0.3,0.5-0.4
				c0.3-0.8,0-1.3-0.7-1.7c-0.7-0.4-0.8-1.1-0.8-1.9c0-0.8-0.3-1.3-1.1-1.4c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.1,0-1-0.9-0.9
				C4.8,9.7,4.4,9,4.1,8.5l0,0C3.6,7.4,3.7,6.2,3.8,5c0-0.1,0.1-0.2,0.2-0.4C4.3,4.3,4.5,3.7,5.2,4c0.2,0.2,0.4,0.3,0.7,0.1
				c0.6-0.4,1.5-0.2,2,0.3c-0.2,0.2-0.5,0-0.7,0.1c0.3,0,0.7,0,0.9-0.2c0.4,0,0.7-0.1,1.1,0.1c0.3,0,0.5,0,0.7,0.2
				c0.3,0.4,0.6,0.8,0.9,1.2c0.2,0.2,0,0.5,0.5,0.4c1-0.1,2-0.2,3-0.3c1.4-0.2,2.6,0.4,4,0.6c0.1,0,0.2,0,0.2,0
				c0.1,0.1,0.2,0.3,0.1,0.4c-0.6,1.3,0.3,1.2,1.1,1.2c1.4,0,2.8,0,4.1,0c0.5,0,1-0.1,1.4,0.3c1.3,0.3,2.6,0.2,3.8,0.8l0,0
				c-0.1,0.7-0.7,0.4-1.2,0.6c1,0.4,1.9,0.4,2.9,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.5-0.1,0.8,0c1,0.5,2,0.4,3.1,0.6l0,0
				c0.7,1.2,1.7,1.6,3.1,1.5c1.8,0,3.6,0,5.3,0.4c0.1,0,0.2,0,0.2,0c0.3,0.3,0.8,0.3,1.2,0.5c0.3,0.1,1.1-0.1,0.5,0.8
				c-0.2,0.3,0.2,0.2,0.3,0.2c1,0.1,1.9,0.2,3,0c0.9-0.2,1.9,0.3,2.9,0.5c1.1,0.2,2.1,0.3,3.2,0.6c3.3,0.9,6.8,1.4,10.1,2.4
				c1.2,0.4,2.5,0.2,3.6,0.8c0.2,0.2,0.3,0.5,0.4,0.7c0.2,0.7,0.6,1,1.2,0.8c0.6-0.2,1.2-0.1,1.9,0c1.2,0.3,2.4-0.4,3.5,0.1
				c2.6,0.3,5.2,0.8,7.7,1.7c0.1,0.4-0.3,0.5-0.5,0.7c-0.4,0.3-0.9,0.7-0.8,1.1c0.1,1-0.4,1.1-1.2,1.1c-0.7,0-1.1,0.2-1,1
				c0,0.2-0.2,0.3-0.3,0.5c-0.4,0.9-1.4,1-2.1,1.6c-1.1,1-2.4,1.8-3.7,2.7c-0.7,0.4-1.3,0.7-1.9,1.2c-0.1,0.1-0.2,0.2-0.2,0.2
				c-0.2,0.2-0.5,0.2-0.7,0.5l0,0c-0.5,0.5-0.9,0.2-1.3-0.4c0,0.6,0.8,0.6,0.4,1.1l0,0c-0.4,0.1-0.8,0.3-1,0.7c-0.1,0-0.2,0-0.2,0
				c-0.7-0.4-0.3,0.3-0.5,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.7-0.5-1.6-0.4-2.4-0.4c-0.4,0-0.9,0.1-1.3,0.1c-0.1,0-0.2-0.1-0.3-0.2
				c-0.2-0.8-0.8-0.9-1.4-0.8c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.5,0.4-0.4,0.6-0.6c-0.4,0.1-0.7,0.3-0.9,0.6c0,0-0.1,0-0.1,0
				c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.4-0.4-0.5-0.8-0.3c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.3-0.7-0.7-0.4c-0.1,0-0.2,0-0.3-0.1
				c-0.3-0.2-0.5-0.3-0.8-0.4c-0.1-0.1-0.1-0.2-0.1-0.2c-0.2-0.7-0.2-0.7-1.2-0.9c-0.7-0.1-1.4,0-2-0.1c-1.7-0.7-3.5-1.1-5-2.3
				c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.4,0-1-0.6-0.4c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.2,0-0.6-0.6-0.6c-0.7,0-1.4-0.1-2.1-0.3
				c-0.6-0.1-1.3-0.3-1.6-0.9c0-0.9-0.9-0.7-1.3-1c-0.3-0.2-0.8-0.2-1.3-0.1c-0.3,0-0.6-0.1-0.8-0.3c-0.5-0.9-0.4-1.2,0.5-1.3
				c-1-0.1-2-0.1-3,0c-2-0.4-3.7-1.4-5.4-2.4c-0.3-0.4-0.6-1-1.3-0.7c-0.1,0-0.2,0-0.3-0.1c-0.2-1.2-1.1-0.9-1.9-0.8
				c-0.5,0-0.9-0.1-1.2-0.5c-0.5-0.7-1.2-1-2-1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.3-0.8-0.6-1.3-0.3c-0.1,0.1-0.1,0.1-0.2,0.2
				c0,0.5,0.5,0.4,0.7,0.5c0.2,0.1,0.4,0.3,0.6,0.5c0.3,0.3,0.6,0.4,0.9,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.3,0.6,0.4,0.8,0.7
				c0.3,0.5,0.3-0.3,0.5-0.1c0.4,0,0.7,0.2,0.9,0.5c0.4,1.7,0.4,1.7,2.4,1.7c0.2,0,0.3,0.1,0.5,0.2c0.7,0.3,0.8,1.2,1.6,1.5
				c0.3,0.1,0.5,0,0.8-0.1c0.3,0,0.6,0.1,0.9,0.3c1.8,1.2,3.7,2.3,5.5,3.5c0.2,0.1,0.3,0.3,0.4,0.4c0.3,0.1-0.6,0.6,0.3,0.6
				c0.3,0,0.7,0.5,1.1,0.8c0.4,0.3,0.7,0.1,1,0.1c1,0.6,2.3,0.7,3,1.9c-0.3,1.2,0.8,1.1,1.4,1.4c0.5,0.2,1.2,0.1,1.4,0.8
				c0,0.1-0.2,0.1-0.1,0.2c0-0.1,0.1-0.1,0.2-0.2c0.2,0.3,0.4,0,0.6,0c0.3,0,0.6,0.2,0.8,0.4c0.3,0.3,0.8,0.4,1.2,0.7
				c0.3,0.3,0.8,0.4,0.9,0.8c0,0.1-0.2,0.1-0.2,0.3c0,0.1,0.2,0,0.3,0c1.2,0.4,2,1.4,3.3,1.4c1.2,0.5,2.3,1.3,3.4,2
				c0.6,0.4,0.8,1.1,0.8,1.8c-0.1,1.1,0.1,2.2-0.3,3.3c-0.2,0.5,0,0.9,0.5,1.2c1.1,0.7,1.4,1.8,1.5,3.1c0,0.6-0.2,1.3,0.4,1.9
				c0.2,0.2,0.1,0.9-0.6,0.7l0,0c-0.3-0.5-0.9-0.7-1.4-1c-2.3-1.1-4.3-2.7-6.5-4.1c-0.2-0.2-0.5-0.3-0.7-0.5l0,0
				c-0.4-0.3-0.8-0.4-1.2,0l0,0c-0.8,0.2-1.3,0.7-1.9,1.2l0,0c-0.5,0.1-0.8,0.4-1.2,0.7l0,0c-0.6-0.1-0.4-0.4-0.3-0.8
				c0.1-0.3,0.5-0.4,0.6-0.8c-0.7,0-0.7,0.5-0.7,0.8c-0.1,0.6-0.4,0.9-1,1c-1.2,0.1-1.9,0.8-2.2,1.9c-2.1,1.4-4.2,2.9-6.2,4.3
				c-0.5,0.3-0.5-0.1-0.6-0.4c-0.3-0.9-0.6-1.8-1.7-2.1c-0.6-0.2-0.4-0.9-0.4-1.4c-0.3,0.6-0.6,1-1,0.2c-0.1-0.3-0.4-0.4-0.6-0.6
				c-0.9-0.9-1.5-1.9-2.2-2.9c-0.4-0.9,0.1-1.9-0.5-3.1c-0.1,0.6,0.4,1.2-0.1,1.4c-0.5,0.2-0.7-0.3-1.1-0.5c0-0.1,0-0.2,0-0.2
				c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.8,0-1.6-1.2-1.5c-0.3,0-0.6-0.3-0.6-0.6C28.9,40,28.4,40,27.9,39.9z"/>
			<path fill="#98989C" d="M50.7,33.5c-0.2-0.2-0.5-0.3-0.7-0.5c-1.2-0.8-2.6-1.1-3.6-2.2c-0.8-1-2.1-1-3-1.9
				c-0.9-0.3-1.7-0.8-2.3-1.5c-2.2-1.4-4.4-2.9-6.7-4.1c-1-0.2-1.6-1.1-2.5-1.4c-0.1-0.1-0.2-0.2-0.2-0.2c-1.1-0.3-1.9-0.9-2.6-1.7
				c-0.2-0.2-0.5-0.3-0.7-0.5c-0.5-0.3-1-0.3-1.4-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3-0.2-0.7-0.2-0.9-0.5c-0.2-0.2-0.4-0.3-0.6-0.5
				c-0.3-0.3-1.2-0.1-0.9-1l0,0c0.6-0.4,1.1-0.2,1.7,0.2c0.1,0,0.1,0.1,0.2,0c0.8,0.1,1.5,0.6,2.2,1c0.3,0.3,0.7,0.4,1.2,0.5
				c0.8,0.1,1.3,0.6,2,1l0,0c0.5,0.1,1.1,0.2,1.5,0.7c1.9,0.8,3.6,1.8,5.5,2.4c1,0.1,1.9,0.6,2.6,1.3c0.2,0.2,0.4,0.2,0.7,0.2
				c0.9,0.4,1.9,0.6,2.7,1.2c0.5,0.4,1,0.6,1.6,0.8c0.8,0.5,1.9,0.5,2.7,1.2l0,0c0.3,0.2,0.8,0.1,0.9,0.5c1.6,0.8,3.3,1.6,5,2.1
				c1,0.5,2.3,0.5,3.1,1.5l0,0c0.3,0.1,0.7,0.2,1,0.5l0,0c0.3,0.2,0.7,0.2,1,0.5c0.1,0,0.1,0.1,0.2,0c0.4,0.1,0.8,0.1,1,0.5l0,0
				c0.2,0.2,0.6,0,0.7,0.3l0,0c0.6,0.2,1.4,0.2,1.7,0.9l0,0c0.9,0.3,2,0.3,2.6,1.2c0.1,2.4-0.6,4.6-1,7c-0.4,2.8-0.9,5.6-1.4,8.6
				c-0.2-0.3-0.4-0.5-0.5-0.7c-0.7-0.5-0.7-1.2-0.9-1.9c-0.4-1.7-1.1-3.3-1.6-5c-0.2-0.8-0.4-1.6-0.4-2.4c0-1.1-0.6-1.7-1.5-2.2
				c-0.9-0.4-1.7-0.9-2.6-1.4c-1.1-0.5-2.1-1.1-3.1-1.7c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3-0.2-0.7-0.5-1-0.7
				C51.5,33.9,51.1,33.6,50.7,33.5z"/>
			<path fill="#6D6E72" d="M170.2,76.3c0.5,1.4,1.4,1.1,2.4,0.6c0.5-0.2,1.3-0.7,1.3,0.3c0.1,0.7,1.3,1.4-0.3,2.2
				c-3.5,1.8-7.2,2.8-10.9,3.6c-0.5,0.1-1.1,0.4-1.4-0.2c-0.3-0.7-0.7-1.5-0.6-2.3c0-0.4,0.5-0.3,0.8-0.3c1.7-0.4,3.5-0.8,5.2-1.2
				c0.8-0.2,0.9-0.4,0.3-1.1c-1.7-1.9-2.8-4.2-4.1-6.3c-0.7-1.1-0.5-1.4,0.8-1.8c2-0.7,2-0.7,3,1.3c0.6,1.1,1.3,2.2,2,3.3
				C169.3,74.8,169.8,75.5,170.2,76.3z"/>
			<path fill="#6E6E72" d="M161.1,51.6c1.1,0.4,2.3,0.5,3.2,1.2c0.3,0.2,0.2,0.5,0.1,0.8c-1,3.1-1,6.2-0.5,9.3
				c0.1,0.8-0.3,1.1-0.9,1.4c-2.3,1-2.4,1-2.9-1.3c-0.5-2.2-0.6-4.4-0.2-6.6c0.2-1.4,0.3-2.8,0.9-4.2
				C160.9,51.9,160.8,51.6,161.1,51.6z"/>
			<path fill="#6E6E73" d="M78.7,56.5c1.2,0.7,2.1,1.7,3.4,2.2c0.4,0.1,0.5,0.4,0.4,0.8c-0.8,2.2-1.2,2.4-3.1,1.2
				c-3.6-2.3-6.9-5-10.3-7.6c-0.6-0.4-0.6-0.8-0.1-1.2c1-1,1.8-1.1,2.9-0.3c1.9,1.4,3.7,3,5.8,4.2C78.1,56,78.4,56.3,78.7,56.5z"/>
			<path fill="#6E6E73" d="M88.1,62.3c0.6,0.3,1.2,0.6,1.8,1c2.9,2.1,6.1,3.5,9.4,5c0.6,0.3,0.7,0.5,0.4,1c-1.2,2.6-1.1,2.5-3.6,1.2
				c-2.5-1.2-4.9-2.7-7.3-4.1C88,66,87.4,65.5,86.8,65c-0.1-0.1-0.3-0.2-0.3-0.3C86.3,64.3,87.6,62.3,88.1,62.3z"/>
			<path fill="#6E6E73" d="M136.8,80.9c-0.1,0.6-0.3,1.2-0.4,1.7c-0.1,0.3-0.3,0.4-0.6,0.3c-4.2-0.5-8.5-1-12.5-2.2
				c-0.7-0.2-0.9-0.4-0.4-1c0.1-0.1,0.2-0.3,0.2-0.4c0.8-1.9,0.9-2,2.8-1.3c2.1,0.7,4.3,1,6.4,1.2c1.2,0.1,2.4,0.5,3.6,0.6
				C136.5,79.9,136.9,80.2,136.8,80.9z"/>
			<path fill="#6E6E73" d="M117.8,76.3c-0.3,1-0.5,1.9-0.9,2.7c-0.1,0.4-0.4,0.2-0.7,0.1c-4-1.2-7.8-2.8-11.6-4.5
				c-0.1,0-0.3-0.1-0.3-0.2c-0.2-0.5,0-1.1,0.4-1.3c0.7-0.4,0.3-2,1.7-1.4c2.4,1,4.8,2,7.1,3.1c1.1,0.5,2.3,0.9,3.5,1.2
				C117.4,76,117.8,75.9,117.8,76.3z"/>
			<path fill="#6E6E73" d="M194.9,54.1c0.1,1.4-0.4,2.7-0.3,4c-0.4,1.3-1,2.7-1.3,4c-0.2,0.8-0.5,0.9-1.2,0.6
				c-0.3-0.1-0.6-0.2-0.9-0.3c-0.8-0.3-1.2-0.8-0.8-1.8c0.6-1.4,0.7-2.9,0.9-4.4l0,0c0.5-1.6,0.1-3.1-0.3-4.7
				c-0.2-0.8,0.1-1.2,0.7-1.6c0.8-0.4,1.5-0.8,2.4-0.9c0.4,0.3,0.4,0.8,0.5,1.2c0,0.1,0.1,0.2,0.1,0.4
				C194.9,51.8,195.3,52.9,194.9,54.1z"/>
			<path fill="#6D6E73" d="M189.1,69c-0.5,0.6-1.1,1.1-1.5,1.9c0,0.1-0.1,0.2-0.2,0.1c-1.1,0.7-2.1,1.6-2.9,2.6
				c-0.1,0.4-0.4,0.5-0.7,0.5c-1.3,0.4-2.2,1.5-3.4,2.1c-0.8,0.4-1.2,0.1-1.5-0.6c-0.4-1-0.8-1.8,0.7-2.4c1-0.5,1.9-1.4,2.8-2.1
				c0.2-0.7,0.8-1,1.4-1.2c0.3-0.2,0.5-0.5,0.8-0.7c0.2-0.5,0.5-1,1.1-1c0.4-0.1,0.6-0.5,0.9-0.8c0.4-0.6,0.8-0.8,1.5-0.6
				c0,0,0,0,0,0c0.3,0.2,0.8,0.1,1,0.4C190.5,67.7,189.5,68.3,189.1,69L189.1,69z"/>
			<path fill="#6E6E73" d="M150.2,84.3c-2-0.3-4.3-0.1-6.4-0.4c-0.6-0.1-0.8-0.2-0.7-0.8c0.1-0.6,0.2-1.2,0.3-1.8
				c0.1-0.4,0.3-0.7,0.8-0.6c3.5,0.3,6.9,0.4,10.4,0.2c0.4,0,0.6,0.2,0.7,0.6c0.8,2.4,0.8,2.4-1.8,2.6
				C152.5,84.2,151.4,84.3,150.2,84.3z"/>
			<path fill="#6E6E73" d="M187,44.2c-1.6-1.8-3.6-2.9-6-3.3c-1.1-0.2-1.9-1.9-1.5-2.9c0.1-0.2,0.3-0.2,0.5-0.2
				c2.9,0,5.5,0.8,7.8,2.6c1.2,0.9,2.1,2.1,3.1,3.1c0.4,0.4,0.3,0.7-0.2,0.9c-0.3,0.1-0.6,0.2-0.8,0.3c-1,0.8-1.9,1-2.7-0.3
				C187.1,44.4,187.1,44.4,187,44.2z"/>
			<path fill="#6E6E72" d="M174.2,41.1c0.1,0.5-0.2,0.7-0.6,0.9c-2.6,1.1-4.8,2.9-6.5,5.2c-0.4,0.5-0.8,0.4-1.3,0.3
				c-2.7-0.6-2.8-0.7-1-2.8c2.3-2.7,5.2-4.6,8.5-5.8c0.5-0.2,0.7,0.1,0.7,0.5C174.1,39.9,174.2,40.5,174.2,41.1z"/>
			<path fill="#6E6E72" d="M176.2,82.2c2,2,4.5,3.3,6.9,4.6c1.9,1,1.7,0.9,0.8,2.8c-0.8,1.8-1.6,0.3-2.4,0.3l0,0
				c-1.8-1.2-3.7-2.3-5.5-3.5c-0.1-0.1-0.3-0.1-0.4-0.2c-0.5-0.5-1.6-0.8-1.5-1.6c0.1-1,0.9-1.7,1.7-2.4
				C175.8,82.1,176,82.2,176.2,82.2L176.2,82.2z"/>
			<path fill="#6E6E73" d="M214.8,94.9c0,0.6-0.4,1.1-0.3,1.6c-0.1,0.2-0.1,0.5-0.1,0.7c0.1,0.9-0.3,1-1.1,0.8c-0.3,0-0.6,0-0.9-0.1
				c-1.8-0.4-3.6,0-5.3-0.4c-0.9-0.2-1.7-0.2-2.6-0.3c-0.7-0.1-0.9-0.4-0.7-1.1c0.6-1.9,0.5-1.9,2.7-1.8c1.7,0.1,3.4,0.2,5.1,0.3
				c0.3,0.3,0.5-0.1,0.7,0C213.2,94.9,214,94.6,214.8,94.9z"/>
			<path fill="#6E6E73" d="M190.4,93.7c-0.1-0.1-0.3-0.2-0.4-0.3c-0.5-0.4-1.7-0.2-1.7-0.9c0-0.8,0.7-1.6,1.1-2.3
				c0.1-0.3,0.3-0.2,0.6-0.1c1.1,0.4,2.2,0.7,3.2,1.2c1.1,0.5,2.3,0.7,3.5,1.1c0.2-0.1,0.4,0,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.1
				c0.6,0.1,1.4,0,1.6,0.6c0.2,0.8-0.1,1.7-0.5,2.5c-0.3,0.5-0.6,0.2-0.9,0.1C195.2,95.1,192.8,94.4,190.4,93.7L190.4,93.7z"/>
			<path fill="#FCFCFB" d="M4.1,4.7c0,1.3,0,2.6,0,3.8C2.6,7,1.6,5.1,0.3,3.5C0.1,3.2-0.1,2.8,0.1,2.4c0.3-0.5,0.7-0.1,1.1,0
				c1,0.3,2,0.4,2.9,0.9C4.5,3.7,4.4,4.2,4.1,4.7z"/>
			<path fill="#F9F8F6" d="M60.3,40.4c1,3.1,1.9,6.2,2.9,9.3c0,0.9-0.3,1.3-1.2,0.7c0.4,0,0.6-0.5,0.4-0.6c-1.1-0.7-0.5-1.8-0.5-2.6
				c0-1.1-0.8-1.8-1.3-2.1c-0.6-0.4-0.8-0.8-0.6-1.3C60.3,42.8,60,41.6,60.3,40.4z"/>
			<path fill="#FCFCFB" d="M25.2,8.5c-0.4-0.2-0.8-0.2-1.3-0.1c-2.1,0.3-4.2,0.1-6.5,0.1c0.6-0.7,1-1.3,1.1-1.9
				c2.3,0.5,4.5,0.9,6.7,1.7C25.3,8.3,25.3,8.4,25.2,8.5C25.3,8.5,25.2,8.5,25.2,8.5z"/>
			<path fill="#FCFBF9" d="M4.1,8.5c0.4,0.3,0.8,0.7,1.2,1c0.4-0.1,0.2-0.6,0.6-0.8c0,0,0.1,0.1,0.1,0.1c0,0.8-0.1,1.6,1.2,1.7
				c0.7,0,0.9,0.9,0.8,1.6c-0.2,1.2,0.6,1.6,1.4,2c0.4,0.2,0.3,0.5,0.4,0.7c0.2,0.5-0.2,0.4-0.5,0.4c-0.1,0-0.1,0.2-0.2,0.4
				c-1-0.7-1.2-1.8-1.8-2.7c-0.9-1.3-2-2.6-2.9-3.8C4.3,9,4.2,8.7,4.1,8.5z"/>
			<path fill="#FBFAF9" d="M79,25.8c0.1-1.8,0.1-1.8,2.1-1.8c0,0,0.1,0,0.1-0.1c-0.4-1,0-1.6,1.6-2.5c0.5,0.1,1.1-0.2,1.3,0.3
				c0.2,0.6-0.4,0.8-0.7,1.1C82,23.9,80.7,25.1,79,25.8z"/>
			<path fill="#FCFBFA" d="M18.3,6.6c-0.8,0-1.7,0.1-2.4-0.4c-0.4-0.2-0.8-0.1-1.2,0c-1.5,0.4-3,0.4-4.6,0.3c0-0.6,0.5-0.1,0.6-0.5
				c-0.6-0.2-1-0.7-0.8-1.4C12,4.9,14,5.6,16.1,6C16.9,6.1,17.7,6,18.3,6.6z"/>
			<path fill="#FCFCFB" d="M43.2,12.3c-2.1,0-4.2,0-6.3,0c-1.1,0-2-0.8-2.1-1.9c1.9,0.3,3.8,0.7,5.7,1.3
				C41.4,12,42.4,11.7,43.2,12.3z"/>
			<path fill="#FCFBF9" d="M75.1,19.8c-1.5-0.4-3.1,0.8-4.6-0.1c-0.1,0-0.2,0-0.2,0c-2,0.4-2.1,0.3-2.2-1.6
				C70.6,18.5,72.9,19,75.1,19.8z"/>
			<path fill="#FBFBF9" d="M34.8,49.1c0.6-0.1,0.6,0.8,1.1,0.8c0.4-0.3-0.2-0.7,0.1-1c0.1,0,0.4,0.1,0.4,0.1
				c0.1,1.7,2.1,2.2,2.5,3.7c0,0.2-0.5,0.8,0.2,0.7c-0.8,0.9-1.4,0.2-1.7-0.4C36.8,51.5,35.6,50.4,34.8,49.1z"/>
			<path fill="#FDFCFA" d="M51.4,14.3c-0.6,0-1.2,0.1-1.7-0.2c-0.6-0.3-1.1,0.1-1.7,0.2c-1.1,0.2-2.1-0.3-3.2-0.1
				c-0.2,0-0.3-0.1-0.4-0.4c0-0.5,0.4-0.4,0.7-0.6c-0.5-0.5-1.5,0.2-1.7-0.9C46.1,12.9,48.8,13.4,51.4,14.3z"/>
			<path fill="#FCFBFA" d="M45.4,49.1c-0.3-0.9,0.6-2.2,1.7-2.1c1,0,1.3-0.4,1.3-1.2c0-1.2,0.8-0.8,1.3-0.7c0.6,0.2,0.2,0.5-0.1,0.7
				c-0.4,0.3-1.1,0.4-0.5,1.1C47.9,47.8,46.7,48.5,45.4,49.1z"/>
			<path fill="#FCFBFA" d="M34.8,10.4c-1.1-0.1-2.3,0.3-3.3-0.5c-0.3-0.2-0.4,0.2-0.6,0.3c-0.5-0.3-1,0-1.2-0.1
				c0.2,0,0.8-0.2,1.2,0.1c-1.1,0.4-2.2,0.5-3.3-0.2c-0.2-0.1-0.6-0.1-0.5-0.5c0.1-0.3,0.4-0.1,0.6-0.1c0.4,0,0.9,0.2,1.2-0.2
				C31.1,9.1,32.9,10,34.8,10.4z"/>
			<path fill="#FCFBF9" d="M27.9,39.9c0.3,0,0.4-0.2,0.6-0.4c0.4-0.4,0.6-0.3,0.6,0.2c-0.1,1,0.1,1.6,1.3,1.3
				c0.4-0.1,0.6,0.1,0.6,0.6c-0.1,0.7,0,1.3,0.1,2C29.6,42.8,28.8,41.3,27.9,39.9z"/>
			<path fill="#FCFBF9" d="M31,44c0.4-0.1,0.6,0.5,1.1,0.3c0.2-0.6-0.5-0.7-0.6-1.1c1.2-0.3,1.1-0.3,1.3,0.9
				c0.1,0.7,0.1,1.4-0.1,2.1C31.8,45.7,31.5,44.8,31,44z"/>
			<path fill="#FBFAF9" d="M4.1,4.7c0-0.5,0-1,0-1.4c1.7,0.4,3.4,0.6,5,1.2c-0.4,0-0.8,0-1.2,0l0,0c-0.6,0-1.3-1-1.9,0.1
				C5.9,4.8,5.2,4.8,5.1,4.2C5.2,4,5.3,3.8,4.9,3.9C4.5,3.9,4.5,4.4,4.3,4.7C4.3,4.7,4.2,4.7,4.1,4.7z"/>
			<path fill="#FCFBFA" d="M25.2,8.5c0-0.1,0-0.2,0-0.2c1.3,0.3,2.7,0,3.8,1C27.8,9,26.5,8.7,25.2,8.5z"/>
			<path fill="#FBF9F6" d="M13.5,21c0.8,0.2,1.8,0.4,1.2,1.7C14.1,22.2,13.6,21.8,13.5,21z"/>
			<path fill="#FCFBFA" d="M50.2,46.2c-0.2-0.3,0.9-0.5,0.1-0.9c-0.1,0,0.2-0.3,0.3-0.2c0.5,0.3,1-0.1,1.5,0
				C51.5,45.5,51,46.1,50.2,46.2z"/>
			<path fill="#F4EDE2" d="M71.3,31.3c0.5-0.7,1.1-1.1,1.9-1.2C72.7,30.7,72,31,71.3,31.3z"/>
			<path fill="#F9F8F6" d="M60.5,49.5c0.6-0.1,1,0.5,1.6,0.5c0.1,0-0.1,0.3-0.1,0.5C61.4,50.3,60.9,50,60.5,49.5z"/>
			<path fill="#F6F0E8" d="M52.1,45c0.4-0.9,0.8-0.5,1.2,0C52.9,45,52.5,45,52.1,45z"/>
			<path fill="#FCFBFA" d="M49,46.9c0.2-0.5,0.6-0.8,1.2-0.7C49.8,46.5,49.5,46.9,49,46.9z"/>
			<path fill="#FBF9F6" d="M13,20.3c0.2,0,0.4-0.2,0.5-0.1c0.3,0.3-0.1,0.6,0,0.8C13.3,20.8,13.1,20.5,13,20.3z"/>
			<path fill="#F5EEE3" d="M11.8,19.1c0.6,0,0.7,0.4,0.7,1C12.2,19.7,11.8,19.5,11.8,19.1z"/>
			<path fill="#FBF9F6" d="M14.9,22.9c0.4,0.2,0.7,0.5,0.7,1C15.2,23.7,14.9,23.4,14.9,22.9z"/>
			<path fill="#DFD2B9" d="M78.7,56.5c-0.5-0.1-0.7-0.4-1-0.7C78.3,55.8,78.5,56.1,78.7,56.5z"/>
			<path fill="#F4EDE2" d="M53.3,45c0.4,0,0.7,0,0.7,0.5C53.7,45.5,53.4,45.3,53.3,45z"/>
			<path fill="#6E6F73" d="M230.6,96.8c-1.9,0.3-3.9,0.7-5.8,0.9c-1.7,0.2-3.4,0.2-5.1,0.3c-0.5,0-0.7-0.2-0.6-0.7
				c0.1-0.5,0.1-1.1,0.1-1.7c0.2-0.4,0.3-0.7,0.8-0.7c3.3,0,6.6-0.2,9.9-0.7c0.5-0.1,0.8,0.1,0.9,0.5
				C230.9,95.5,231.7,96.2,230.6,96.8z"/>
			<path fill="#F9F9F9" d="M57.8,31.1c-1.1-0.5-2.2-0.7-3.1-1.5c1.3-0.2,2.6,0.5,3.9,0c0.1,0.5-1,0.6-0.3,1.2
				C58.1,30.9,58,31,57.8,31.1z"/>
			<path fill="#F8F8F8" d="M46.4,30.8c1.3,0.6,2.5,1.3,3.6,2.2c-0.2,0.4-0.5,0.3-0.7,0c-0.6-0.9-1.8-0.4-2.6-1.1
				C46.1,31.4,45.9,31.3,46.4,30.8z"/>
			<path fill="#FAFAFA" d="M41,23.7c-0.9-0.4-1.9-0.6-2.6-1.3c0.4-0.2,0.9-0.9,1.3,0c0.1,0.1,0.2,0.2,0.3,0c0.4-0.9,0.8-0.5,1.2,0
				c0.1,0.1,0.4,0,0.6,0.1C41,22.6,40.3,22.7,41,23.7z"/>
			<path fill="#FAF9F9" d="M29.1,20c1,0.4,1.8,1,2.6,1.7c-0.3,0.3-0.4,1.1-0.9,0.1c-0.2-0.4-0.7,0.4-1.2-0.1
				C29.1,21.2,29.2,20.6,29.1,20z"/>
			<path fill="#F6F5F5" d="M41.1,27.4c0.8,0.5,1.7,0.7,2.3,1.5c-0.8,0.8-1.6-0.1-1.8-0.4c-0.4-0.8-0.6-0.3-0.9,0
				C40.5,28,41,27.8,41.1,27.4z"/>
			<path fill="#F7F7F6" d="M48.7,27c-1-0.2-1.9-0.5-2.7-1.2c0.7-0.1,1.4,0.4,2.3,0.1C48.7,25.8,48.9,26.5,48.7,27z"/>
			<path fill="#FAFAFA" d="M44.4,25.1c-1-0.1-1.9-0.6-2.7-1.2c1.1-0.4,2,0.4,2.9,0.7C44.8,24.7,44.4,24.9,44.4,25.1z"/>
			<path fill="#F9F9F9" d="M53.1,34.9c1.1,0.5,2.2,1.1,3.2,1.9c-1.3,0-2.2-1.1-3.4-1.4C52.7,35.1,52.9,35,53.1,34.9z"/>
			<path fill="#FAF9F9" d="M31.9,21.9c1,0.1,1.7,0.9,2.5,1.4c-0.5,0.4-1.1,0.3-1.4-0.3C32.7,22.6,32.3,22.3,31.9,21.9z"/>
			<path fill="#F9F9F8" d="M31.5,19.3c-0.8-0.1-1.4-0.4-2-1c0.3-0.3,0.7-0.1,1-0.1c0.4,0,1.4-0.4,0.7,0.7
				C31.2,19,31.4,19.2,31.5,19.3z"/>
			<path fill="#F7F7F7" d="M63.4,33.7c-0.5-0.4-1.4-0.2-1.7-0.9C62.9,32.3,63.3,32.5,63.4,33.7z"/>
			<path fill="#F6F5F5" d="M28.3,17.9c-0.8-0.2-1.6-0.5-2.2-1C27.1,16.7,27.8,17.1,28.3,17.9z"/>
			<path fill="#F9F9F8" d="M32.9,20c-0.6-0.1-1-0.3-1.5-0.7C32.2,19.1,32.9,18.8,32.9,20z"/>
			<path fill="#F8F7F7" d="M26.9,18.8c0.5,0.1,1,0.3,1.4,0.7C27.8,20,27.2,19.7,26.9,18.8z"/>
			<path fill="#F7F7F6" d="M49.7,27.5c-0.3-0.1-0.8-0.1-0.9-0.5c0.1,0,0.2,0,0.2-0.1c0.3-0.2,0.5-0.6,1-0.4
				C49.5,26.8,49.9,27.2,49.7,27.5z"/>
			<path fill="#F7F7F7" d="M61.7,32.8c-0.3,0-0.5,0-0.7-0.3c0.5-0.4,1.1-0.8,1.9-0.6C62.5,32.3,61.7,32.1,61.7,32.8z"/>
			<path fill="#F9F9F9" d="M61,32.5c-0.4,0-0.7-0.2-1-0.5c0.3-0.1,0.4-0.5,0.8-0.4C61.2,31.9,60.9,32.2,61,32.5z"/>
			<path fill="#F6F5F5" d="M25.9,16.9c-0.6-0.1-1.1-0.4-1.7-0.2C24.9,16,25.5,16.2,25.9,16.9z"/>
			<path fill="#FCFCFB" d="M4.3,4.7c0-0.9,0.5-1.1,1.2-0.9c0,0.3-0.3,0.3-0.5,0.4C4.5,3.9,4.7,4.6,4.3,4.7z"/>
			<path fill="#F8F7F7" d="M24.3,16.7c0,0.6,0.7,0.5,0.9,1C24,17.5,24,17.5,24.3,16.7z"/>
			<path fill="#F9F9F9" d="M59.8,32c-0.4,0-0.7-0.2-1-0.5C59.5,30.9,59.6,31.6,59.8,32z"/>
			<path fill="#F0F0F0" d="M50.7,33.5c0.6-0.1,0.9,0.2,1.2,0.7C51.4,34,50.9,34,50.7,33.5z"/>
			<path fill="#F5F4F3" d="M53.1,34.9c-0.1,0.2-0.2,0.3-0.2,0.5c-0.2-0.2-0.6,0-0.8-0.3c0.3-0.3,0.6-0.1,0.9-0.2
				C52.9,34.9,53,34.9,53.1,34.9z"/>
			<path fill="#FAF9F9" d="M31.8,23.2c0.4-0.1,0.8-0.1,0.8,0.2c0,0.5-0.3,0.1-0.4,0C32.1,23.5,32,23.4,31.8,23.2z"/>
			<path fill="#F8F8F8" d="M49.3,33c-0.4,0.3-0.6,0.5-1.1,0.3C48.6,32.9,48.9,33,49.3,33z"/>
			<path fill="#F9F9F9" d="M58.8,31.6c-0.4-0.1-0.7-0.1-1-0.5C58.3,31,58.6,31.1,58.8,31.6z"/>
			<path fill="#F8F7F7" d="M26.4,19.6c-0.1,0.1-0.2,0.2-0.3,0.1c-0.1,0-0.1-0.1-0.2-0.2c0.1,0,0.2-0.1,0.2-0.1
				C26.2,19.4,26.3,19.5,26.4,19.6z"/>
			<path fill="#F8F7F7" d="M25.7,18.1c0.4,0,0.7,0.1,0.9,0.5C26.2,18.6,25.9,18.5,25.7,18.1z"/>
			<path fill="#FBFAF9" d="M7.9,4.4C7.7,4.7,7.3,4.7,7,4.6C7.3,4.3,7.6,4.5,7.9,4.4L7.9,4.4z"/>
			<path fill="#FEFEFE" d="M35,10.9c0.6-0.4,1-0.1,1.4,0.1C35.9,11.2,35.5,11.2,35,10.9z"/>
			<path fill="#FEFEFE" d="M31,10.2c-0.5,0-1.1,0-1.6,0C29.9,10,30.5,9.7,31,10.2L31,10.2z"/>
		</g>
	</g>
</g>
<g id="arm_and_plane">
	<g id="plane">
		<g id="_x37_aV8Vf_2_">
			<g>
				<path fill="#FEFEFE" d="M224.3,73.8c-1.6-1.1-3.2-2.1-4.8-3.2c-2.3-1.5-4.5-3.1-6.7-4.6c-1.7-1.2-3.4-2.4-5.1-3.6
					c-0.3-0.3-0.7-0.5-1-0.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.8-1-0.9-1.7-1.2l0,0c-0.2-0.2-0.3-0.6-0.7-0.5c-0.2,0-0.3-0.1-0.5-0.1
					c-0.2-0.4-0.6-0.6-1-0.7c-0.7-0.9-1.8-1.3-2.7-1.9c-0.2-0.1-0.4-0.1-0.5,0.1c-0.2,0.2-0.5,0.4-0.7,0c0-0.1,0.2-0.3,0.4-0.5
					c0-0.8-0.5-1.2-1.2-1.4c-0.8-0.2-1.1-0.8-1.3-1.5c-0.2-0.7-0.7-1.2-1.5-1c-0.2,0-0.3,0-0.5,0c-0.5,0-0.3-0.9-1.1-0.6
					c-0.4,0.2-1-0.4-1.4-0.7l0,0c-0.8-0.9-1.1-2.1-1.3-3.3c0-0.1,0-0.3,0-0.4c0.2-0.4,0.2-1,0.9-0.9c0.3,0.1,0.4,0.2,0.7-0.1
					c0.4-0.5,1.4-0.6,2-0.3c-0.2,0.3-0.5,0.2-0.7,0.3c0.3-0.1,0.6-0.2,0.8-0.5c0.3-0.1,0.7-0.3,1-0.2c0.3-0.1,0.5-0.2,0.8,0
					c0.4,0.3,0.8,0.6,1.3,0.9c0.2,0.1,0.2,0.5,0.6,0.3c0.9-0.4,1.8-0.8,2.7-1.2c1.2-0.6,2.6-0.4,3.9-0.7c0.1,0,0.1,0,0.2-0.1
					c0.1,0.1,0.3,0.2,0.3,0.4c-0.1,1.4,0.6,1,1.4,0.8c1.3-0.5,2.6-0.8,3.9-1.3c0.4-0.2,0.9-0.3,1.4-0.2c1.3-0.1,2.6-0.6,3.9-0.4l0,0
					c0.1,0.7-0.5,0.6-1,0.9c1.1,0.1,2-0.2,2.8-0.7c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.3,0.7-0.2c1.1,0.2,2.1-0.2,3.1-0.4l0,0
					c1,0.9,2.1,1,3.4,0.5c1.7-0.6,3.4-1.2,5.2-1.3c0.1,0,0.1-0.1,0.2-0.1c0.4,0.2,0.9,0,1.3,0.1c0.3,0,1-0.4,0.7,0.6
					c-0.1,0.3,0.3,0.1,0.4,0.1c1-0.2,1.9-0.4,2.8-1c0.8-0.5,1.9-0.4,2.9-0.4c1.1-0.2,2.1-0.4,3.2-0.5c3.5-0.1,6.9-0.8,10.3-0.8
					c1.2,0,2.4-0.6,3.7-0.3c0.3,0.1,0.4,0.4,0.6,0.6c0.4,0.6,0.9,0.7,1.4,0.3c0.5-0.4,1.1-0.5,1.8-0.6c1.2-0.1,2.1-1.2,3.4-1
					c2.6-0.6,5.2-0.9,7.8-0.8c0.2,0.4-0.2,0.6-0.3,0.8c-0.3,0.4-0.6,0.9-0.4,1.3c0.5,0.9-0.1,1.2-0.8,1.4c-0.7,0.2-1,0.5-0.7,1.3
					c0.1,0.2-0.1,0.4-0.1,0.5c-0.1,1-1.1,1.4-1.5,2.2c-0.8,1.3-1.7,2.5-2.6,3.7c-0.5,0.5-1.1,1.1-1.4,1.7c-0.1,0.1-0.1,0.2-0.2,0.3
					c-0.2,0.2-0.4,0.4-0.5,0.7l0,0c-0.3,0.6-0.8,0.4-1.4,0c0.2,0.6,1,0.3,0.7,0.9l0,0c-0.3,0.3-0.7,0.5-0.7,1
					c-0.1,0-0.1,0.1-0.2,0.1c-0.7-0.1-0.2,0.4-0.3,0.6c-0.1,0.1-0.2,0.3-0.4,0.4c-0.9-0.3-1.6,0.1-2.4,0.3c-0.4,0.1-0.8,0.4-1.2,0.5
					c-0.1,0-0.2,0-0.3-0.1c-0.4-0.7-1-0.6-1.6-0.3c-0.1,0-0.2,0-0.3-0.1c-0.2-0.5,0.2-0.5,0.4-0.8c-0.3,0.2-0.6,0.5-0.7,0.8
					c0,0-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.3-0.1c-0.3-0.4-0.6-0.4-0.9,0c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.5-0.8-0.2
					c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1,0-0.2-0.1-0.2-0.2c-0.4-0.6-0.4-0.6-1.4-0.5c-0.7,0.1-1.3,0.5-2,0.5
					c-1.8-0.1-3.7,0-5.4-0.6c-0.1-0.1-0.1-0.1-0.1-0.2c-0.2-0.3-0.3-0.9-0.7-0.1c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.2-0.6-0.8-0.4
					c-0.6,0.2-1.4,0.3-2,0.4c-0.6,0.1-1.3,0.1-1.8-0.3c-0.3-0.9-1-0.4-1.6-0.5c-0.4-0.1-0.9,0.1-1.2,0.3c-0.3,0.1-0.6,0.1-0.8,0
					c-0.8-0.7-0.8-1,0-1.4c-1,0.2-2,0.6-2.9,1c-2,0.2-4-0.1-5.9-0.6c-0.4-0.3-0.9-0.8-1.5-0.3c-0.1,0-0.2,0-0.3,0
					c-0.6-1-1.3-0.5-2-0.2c-0.4,0.2-0.9,0.1-1.3-0.1c-0.7-0.5-1.4-0.6-2.2-0.3c-0.1,0-0.2,0-0.3,0c-0.5-0.1-0.9-0.3-1.3,0.1
					c0,0.1-0.1,0.2-0.1,0.3c0.2,0.4,0.6,0.2,0.9,0.3c0.3,0,0.5,0.2,0.7,0.3c0.3,0.2,0.7,0.2,1,0.2c0.2,0,0.3,0.1,0.4,0.2
					c0.3,0.3,0.7,0.2,0.9,0.4c0.4,0.4,0.2-0.4,0.5-0.2c0.4-0.1,0.7,0,1,0.2c0.9,1.4,0.9,1.4,2.8,0.9c0.2,0,0.3,0,0.5,0
					c0.7,0.1,1.2,0.8,1.9,0.9c0.4,0,0.5-0.2,0.7-0.3c0.3-0.1,0.6-0.1,0.9,0c2.1,0.5,4.2,1,6.3,1.6c0.2,0.1,0.4,0.1,0.5,0.3
					c0.3,0-0.4,0.8,0.4,0.5c0.3-0.1,0.8,0.3,1.3,0.4c0.5,0.1,0.7-0.1,1-0.2c1.2,0.2,2.4,0,3.4,0.9c0.1,1.2,1.1,0.8,1.7,0.9
					c0.6,0.1,1.2-0.3,1.6,0.3c0.1,0.1-0.2,0.1-0.1,0.2c0-0.1,0-0.1,0.1-0.2c0.3,0.2,0.4-0.1,0.6-0.2c0.3-0.1,0.6,0,0.9,0.2
					c0.4,0.2,0.9,0.1,1.4,0.2c0.4,0.2,0.8,0.1,1.1,0.5c0,0.1-0.2,0.2-0.1,0.3c0,0,0.2,0,0.3-0.1c1.2,0,2.4,0.7,3.6,0.3
					c1.3,0.1,2.6,0.5,3.8,0.8c0.7,0.2,1.1,0.8,1.3,1.5c0.2,1.1,0.8,2.1,0.8,3.2c0,0.5,0.3,0.9,0.8,1c1.3,0.4,1.9,1.3,2.4,2.5
					c0.2,0.6,0.2,1.3,1,1.6c0.3,0.1,0.4,0.8-0.4,0.8l0,0c-0.5-0.4-1.1-0.4-1.7-0.5c-2.5-0.4-4.9-1.3-7.4-1.9
					c-0.3-0.2-0.6-0.1-0.8-0.2l0,0c-0.5-0.1-0.9-0.1-1.1,0.4l0,0c-0.7,0.4-1,1.1-1.4,1.7l0,0c-0.4,0.3-0.7,0.7-0.9,1.1l0,0
					c-0.6,0.1-0.5-0.3-0.5-0.6c0-0.3,0.3-0.5,0.3-0.9c-0.7,0.2-0.5,0.7-0.4,1c0.1,0.6,0,1-0.6,1.2c-1.1,0.5-1.5,1.3-1.5,2.5
					c-1.5,2-3.1,4-4.6,6c-0.4,0.4-0.5,0-0.7-0.2c-0.6-0.7-1.1-1.5-2.2-1.5c-0.6,0-0.6-0.7-0.9-1.2c-0.1,0.7-0.2,1.2-0.9,0.5
					c-0.2-0.2-0.5-0.3-0.7-0.3c-1.1-0.5-2-1.3-3-2c-0.6-0.7-0.5-1.8-1.4-2.8c0.1,0.6,0.8,1,0.3,1.4c-0.4,0.4-0.8-0.1-1.2-0.2
					c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.2-0.4-0.4-0.6-0.7c-0.3-0.8-0.5-1.5-1.6-1c-0.3,0.1-0.7-0.1-0.8-0.4
					C225.3,73.6,224.8,73.7,224.3,73.8z"/>
				<path fill="#98989C" d="M243.9,60.5c-0.3-0.1-0.6-0.2-0.9-0.2c-1.3-0.4-2.8-0.2-4.1-1c-1-0.7-2.3-0.3-3.4-0.8
					c-1,0-1.8-0.2-2.7-0.7c-2.5-0.6-5-1.3-7.6-1.8c-1,0.2-1.8-0.5-2.8-0.6c-0.1,0-0.2-0.1-0.3-0.1c-1.1,0.1-2.1-0.2-3-0.8
					c-0.3-0.1-0.6-0.1-0.9-0.2c-0.5-0.1-1.1,0-1.6-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.7,0-1-0.2c-0.2-0.1-0.4-0.2-0.7-0.3
					c-0.4-0.2-1.2,0.3-1.1-0.6l0,0c0.5-0.5,1-0.5,1.6-0.3c0.1,0,0.1,0,0.2,0c0.8-0.1,1.6,0.1,2.4,0.2c0.4,0.2,0.8,0.2,1.2,0.1
					c0.8-0.1,1.4,0.2,2.2,0.3l0,0c0.5,0,1.1-0.1,1.6,0.2c2,0.1,4,0.6,6,0.6c1-0.2,2,0,2.9,0.4c0.2,0.2,0.5,0.1,0.7,0
					c1,0.1,2-0.1,2.9,0.3c0.6,0.3,1.2,0.3,1.8,0.2c1,0.3,2-0.2,2.9,0.3l0,0c0.3,0.1,0.8-0.2,1.1,0.2c1.8,0.2,3.6,0.5,5.4,0.5
					c1.1,0.2,2.4-0.3,3.4,0.4l0,0c0.4,0,0.7,0,1.1,0.1l0,0c0.4,0,0.7,0,1.1,0.1c0.1,0,0.1,0,0.2,0c0.4-0.1,0.8-0.2,1.1,0.1l0,0
					c0.2,0.2,0.5-0.2,0.7,0l0,0c0.7,0,1.4-0.2,1.9,0.3l0,0c1,0,2-0.3,2.9,0.3c0.8,2.2,0.8,4.6,1.3,6.9c0.5,2.8,0.9,5.6,1.3,8.6
					c-0.3-0.2-0.5-0.4-0.7-0.5c-0.8-0.2-1.1-0.9-1.4-1.5c-0.9-1.5-2-2.8-3.1-4.3c-0.5-0.7-0.9-1.4-1.2-2.2c-0.3-1-1.1-1.5-2.1-1.6
					c-1-0.1-1.9-0.4-2.9-0.6c-1.2-0.2-2.3-0.4-3.5-0.6c-0.1,0-0.2-0.1-0.3-0.2c-0.4-0.1-0.8-0.2-1.2-0.4
					C244.9,60.7,244.4,60.5,243.9,60.5z"/>
				<path fill="#FCFCFB" d="M190.7,47.7c0.4,1.2,0.8,2.4,1.2,3.6c-1.9-1-3.4-2.4-5.2-3.6c-0.3-0.2-0.6-0.6-0.5-1
					c0.1-0.6,0.6-0.4,1-0.4c1,0,2-0.3,3.1-0.1C190.8,46.7,190.9,47.1,190.7,47.7z"/>
				<path fill="#F9F8F6" d="M255.3,64.1c1.9,2.7,3.8,5.3,5.6,8c0.3,0.9,0.1,1.3-0.9,1.1c0.4-0.2,0.4-0.7,0.2-0.7
					c-1.3-0.3-1.1-1.6-1.3-2.3c-0.4-1.1-1.3-1.4-1.9-1.6c-0.7-0.2-1-0.5-1-1C256,66.3,255.3,65.3,255.3,64.1z"/>
				<path fill="#FCFCFB" d="M212,44.7c-0.5-0.1-0.8,0.1-1.2,0.3c-1.9,0.9-3.9,1.4-6.2,2.2c0.4-0.9,0.6-1.5,0.4-2.2
					c2.3-0.3,4.6-0.6,6.9-0.5C212,44.5,212,44.6,212,44.7C212,44.7,212,44.7,212,44.7z"/>
				<path fill="#FCFBF9" d="M191.9,51.3c0.5,0.2,1,0.4,1.5,0.6c0.4-0.2,0-0.7,0.3-0.9c0,0,0.2,0,0.2,0.1c0.3,0.8,0.4,1.6,1.6,1.2
					c0.7-0.2,1.1,0.6,1.2,1.3c0.2,1.2,1.1,1.4,2,1.4c0.4,0,0.5,0.4,0.6,0.6c0.3,0.4-0.1,0.4-0.3,0.5c-0.1,0-0.1,0.3-0.1,0.4
					c-1.1-0.3-1.7-1.4-2.6-2c-1.3-1-2.7-1.8-4-2.7C192.2,51.7,192.1,51.5,191.9,51.3z"/>
				<path fill="#FBFAF9" d="M268.5,44.4c-0.5-1.8-0.5-1.8,1.5-2.4c0,0,0-0.1,0.1-0.1c-0.7-0.8-0.5-1.5,0.7-2.9
					c0.4-0.1,1-0.5,1.3-0.1c0.4,0.5-0.1,0.9-0.3,1.3C270.7,41.7,269.8,43.2,268.5,44.4z"/>
				<path fill="#FCFBFA" d="M204.8,45.1c-0.8,0.2-1.6,0.6-2.4,0.4c-0.4-0.1-0.8,0.1-1.1,0.4c-1.3,0.9-2.8,1.4-4.2,1.7
					c-0.2-0.6,0.4-0.2,0.4-0.6c-0.7,0-1.1-0.3-1.2-1.1c2.1-0.5,4.3-0.4,6.4-0.7C203.3,45.1,204,44.8,204.8,45.1z"/>
				<path fill="#FCFCFB" d="M230.3,42.8c-2,0.7-4,1.3-6,2c-1.1,0.3-2.1-0.1-2.6-1.2c1.9-0.3,3.9-0.6,5.8-0.6
					C228.5,43,229.3,42.4,230.3,42.8z"/>
				<path fill="#FCFBF9" d="M262.9,39.9c-1.6,0.1-2.7,1.7-4.4,1.3c-0.1,0-0.1,0-0.2,0.1c-1.7,1-1.9,0.9-2.6-0.9
					C258.2,40,260.5,39.8,262.9,39.9z"/>
				<path fill="#FBFBF9" d="M233.8,80.3c0.6-0.3,0.8,0.5,1.3,0.4c0.3-0.5-0.4-0.6-0.3-1c0.1,0,0.4-0.1,0.4,0
					c0.6,1.6,2.7,1.4,3.5,2.8c0.1,0.2-0.2,0.9,0.5,0.6c-0.5,1.1-1.3,0.6-1.7,0.1C236.4,82,234.9,81.3,233.8,80.3z"/>
				<path fill="#FDFCFA" d="M238.6,42c-0.6,0.2-1.1,0.4-1.7,0.3c-0.7-0.1-1,0.5-1.5,0.7c-0.9,0.5-2.1,0.4-3,0.9
					c-0.2,0.1-0.4,0-0.5-0.2c-0.2-0.5,0.3-0.5,0.5-0.8c-0.6-0.4-1.4,0.7-1.9-0.3C233.2,42.4,235.9,42.1,238.6,42z"/>
				<path fill="#FCFBFA" d="M243.8,77c-0.6-0.8-0.1-2.3,0.9-2.6c0.9-0.3,1.1-0.8,0.8-1.5c-0.4-1.1,0.5-1,1-1c0.6,0,0.3,0.4,0.1,0.7
					c-0.3,0.4-0.9,0.7-0.1,1.2C245.8,75,244.9,76.1,243.8,77z"/>
				<path fill="#FCFBFA" d="M221.7,43.6c-1.1,0.2-2.1,1-3.3,0.6c-0.3-0.1-0.3,0.3-0.4,0.4c-0.5-0.1-0.9,0.3-1.2,0.3
					c0.3,0,0.7-0.4,1.2-0.3c-0.9,0.7-2,1.1-3.2,0.9c-0.3-0.1-0.6,0.1-0.6-0.4c0-0.3,0.4-0.2,0.6-0.2c0.4-0.1,0.9-0.1,1.1-0.6
					C217.7,43.4,219.8,43.7,221.7,43.6z"/>
				<path fill="#FCFBF9" d="M224.3,73.8c0.2-0.1,0.3-0.3,0.4-0.5c0.2-0.5,0.5-0.5,0.7,0c0.2,0.9,0.6,1.5,1.7,0.9
					c0.4-0.2,0.6-0.1,0.7,0.4c0.2,0.6,0.4,1.3,0.7,1.9C226.9,75.9,225.6,74.8,224.3,73.8z"/>
				<path fill="#FCFBF9" d="M228.5,76.7c0.4-0.2,0.7,0.3,1.1,0c0-0.6-0.7-0.6-1-0.9c1-0.6,1-0.6,1.6,0.4c0.3,0.6,0.6,1.3,0.6,2
					C229.9,78,229.3,77.3,228.5,76.7z"/>
				<path fill="#FBFAF9" d="M190.7,47.7c-0.1-0.5-0.3-0.9-0.4-1.4c1.7-0.2,3.4-0.5,5.2-0.4c-0.4,0.1-0.8,0.3-1.1,0.4l0,0
					c-0.6,0.2-1.6-0.6-1.8,0.7c0,0.3-0.7,0.5-1-0.1c0-0.2,0.1-0.4-0.2-0.2c-0.4,0.2-0.2,0.6-0.3,0.9
					C190.9,47.6,190.8,47.7,190.7,47.7z"/>
				<path fill="#FCFBFA" d="M212,44.7c0-0.1,0-0.2-0.1-0.2c1.3-0.1,2.6-0.8,4-0.3C214.6,44.4,213.3,44.6,212,44.7z"/>
				<path fill="#FBF9F6" d="M204.7,60.3c0.9,0,1.8-0.1,1.7,1.2C205.7,61.2,205,61,204.7,60.3z"/>
				<path fill="#FCFBFA" d="M247.5,72.7c-0.3-0.3,0.7-0.8-0.2-0.9c-0.1,0,0.1-0.3,0.2-0.3c0.6,0.1,0.9-0.4,1.4-0.5
					C248.5,71.6,248.2,72.4,247.5,72.7z"/>
				<path fill="#F4EDE2" d="M262.9,52c0.2-0.8,0.7-1.4,1.4-1.7C264.1,51,263.5,51.5,262.9,52z"/>
				<path fill="#F9F8F6" d="M258.3,72.7c0.5-0.3,1.1,0.2,1.6-0.1c0.1,0,0,0.3,0.1,0.5C259.4,73.1,258.8,73,258.3,72.7z"/>
				<path fill="#F6F0E8" d="M248.9,71c0.1-1,0.6-0.7,1.1-0.4C249.7,70.8,249.3,70.9,248.9,71z"/>
				<path fill="#FCFBFA" d="M246.5,73.8c0.1-0.5,0.3-0.9,0.9-1.1C247.2,73.2,247,73.6,246.5,73.8z"/>
				<path fill="#FBF9F6" d="M204,59.7c0.2-0.1,0.4-0.3,0.5-0.3c0.4,0.2,0.1,0.6,0.2,0.8C204.5,60.1,204.3,59.9,204,59.7z"/>
				<path fill="#F5EEE3" d="M202.5,59c0.6-0.1,0.8,0.2,1,0.7C203.2,59.5,202.7,59.4,202.5,59z"/>
				<path fill="#FBF9F6" d="M206.7,61.7c0.4,0.1,0.8,0.2,1,0.7C207.2,62.2,206.9,62.1,206.7,61.7z"/>
				<path fill="#F4EDE2" d="M250,70.6c0.3-0.1,0.7-0.2,0.8,0.2C250.6,71,250.3,70.9,250,70.6z"/>
				<path fill="#F9F9F9" d="M250,56c-1.1-0.1-2.3,0.1-3.4-0.4c1.2-0.6,2.6-0.3,3.7-1.2c0.3,0.4-0.8,0.9,0.1,1.2
					C250.2,55.7,250.1,55.9,250,56z"/>
				<path fill="#F8F8F8" d="M239,59.3c1.4,0.2,2.8,0.4,4.1,1c-0.1,0.4-0.4,0.4-0.7,0.2c-0.9-0.6-1.9,0.2-2.9-0.2
					C239,60,238.8,59.9,239,59.3z"/>
				<path fill="#FAFAFA" d="M231.8,54.2c-1-0.1-2,0-2.9-0.4c0.4-0.3,0.5-1.1,1.3-0.5c0.1,0.1,0.2,0.1,0.3-0.1c0.1-1,0.6-0.7,1.1-0.4
					c0.1,0.1,0.4-0.1,0.6-0.1C231.4,53.2,230.8,53.5,231.8,54.2z"/>
				<path fill="#FAF9F9" d="M219.2,54.5c1,0.1,2,0.4,3,0.8c-0.2,0.4-0.1,1.2-0.9,0.4c-0.3-0.3-0.5,0.6-1.1,0.3
					C219.6,55.6,219.5,55,219.2,54.5z"/>
				<path fill="#F6F5F5" d="M233,57.8c0.9,0.2,1.9,0.2,2.7,0.7c-0.5,1-1.5,0.4-1.9,0.1c-0.7-0.6-0.7-0.1-0.9,0.3
					C232.5,58.5,233,58.1,233,57.8z"/>
				<path fill="#F7F7F6" d="M240.1,55c-1,0.1-2,0.2-2.9-0.3c0.7-0.3,1.5-0.1,2.2-0.6C239.7,53.9,240.1,54.5,240.1,55z"/>
				<path fill="#FAFAFA" d="M235.4,54.5c-1,0.2-2,0.1-2.9-0.3c1-0.7,2-0.2,3-0.2C235.7,54,235.4,54.3,235.4,54.5z"/>
				<path fill="#F9F9F9" d="M246.7,61.1c1.2,0.1,2.4,0.4,3.6,0.8c-1.3,0.4-2.4-0.3-3.7-0.3C246.4,61.4,246.6,61.3,246.7,61.1z"/>
				<path fill="#FAF9F9" d="M222.6,55.4c1-0.2,1.9,0.3,2.8,0.6c-0.4,0.6-0.9,0.6-1.4,0.2C223.5,55.8,223,55.7,222.6,55.4z"/>
				<path fill="#F9F9F8" d="M221.3,53.1c-0.8,0.1-1.5,0.1-2.2-0.3c0.2-0.3,0.6-0.3,0.9-0.4c0.4-0.1,1.2-0.8,0.9,0.5
					C220.9,52.9,221.2,53,221.3,53.1z"/>
				<path fill="#F7F7F7" d="M256.1,56.8c-0.6-0.2-1.4,0.2-1.9-0.3C255.2,55.6,255.6,55.7,256.1,56.8z"/>
				<path fill="#F6F5F5" d="M217.9,52.7c-0.8,0.1-1.6,0-2.4-0.2C216.3,51.9,217.1,52.1,217.9,52.7z"/>
				<path fill="#F9F9F8" d="M222.9,53.3c-0.6,0.1-1.1,0-1.6-0.2C221.9,52.6,222.5,52.1,222.9,53.3z"/>
				<path fill="#F8F7F7" d="M216.8,54c0.5-0.1,1.1,0,1.6,0.3C218,54.9,217.3,54.7,216.8,54z"/>
				<path fill="#F7F7F6" d="M241.1,55.2c-0.4,0-0.8,0.1-1.1-0.2c0.1,0,0.1-0.1,0.2-0.1c0.3-0.3,0.3-0.7,0.8-0.7
					C240.8,54.5,241.3,54.8,241.1,55.2z"/>
				<path fill="#F7F7F7" d="M254.2,56.4c-0.3,0.1-0.5,0.1-0.7,0c0.4-0.5,0.8-1.1,1.7-1.2C254.9,55.8,254,55.7,254.2,56.4z"/>
				<path fill="#F9F9F9" d="M253.4,56.4c-0.4,0.1-0.7,0-1.1-0.1c0.2-0.2,0.2-0.6,0.6-0.6C253.4,55.7,253.3,56.2,253.4,56.4z"/>
				<path fill="#F6F5F5" d="M215.3,52.5c-0.5,0.1-1.1,0-1.6,0.3C214,51.9,214.6,52,215.3,52.5z"/>
				<path fill="#FCFCFB" d="M191,47.6c-0.3-0.8,0.1-1.1,0.9-1.2c0.1,0.3-0.2,0.4-0.3,0.6C190.9,46.8,191.3,47.5,191,47.6z"/>
				<path fill="#F8F7F7" d="M213.7,52.8c0.2,0.6,0.8,0.3,1.1,0.6C213.6,53.7,213.6,53.7,213.7,52.8z"/>
				<path fill="#F9F9F9" d="M252.2,56.3c-0.4,0.1-0.7,0-1.1-0.1C251.6,55.3,251.8,56,252.2,56.3z"/>
				<path fill="#F0F0F0" d="M243.9,60.5c0.5-0.3,1-0.1,1.4,0.3C244.8,60.8,244.3,61,243.9,60.5z"/>
				<path fill="#F5F4F3" d="M246.7,61.1c0,0.2-0.1,0.4-0.1,0.5c-0.3-0.1-0.6,0.2-0.9-0.1c0.2-0.3,0.5-0.3,0.8-0.4
					C246.6,61.1,246.6,61.1,246.7,61.1z"/>
				<path fill="#FAF9F9" d="M222.8,56.7c0.4-0.2,0.7-0.4,0.8,0c0.1,0.5-0.2,0.2-0.4,0.2C223.2,56.8,223.1,56.7,222.8,56.7z"/>
				<path fill="#F8F8F8" d="M242.5,60.5c-0.3,0.4-0.4,0.7-0.9,0.6C241.8,60.6,242.2,60.6,242.5,60.5z"/>
				<path fill="#F9F9F9" d="M251.1,56.2c-0.4,0.1-0.7,0.1-1.1-0.1C250.4,55.8,250.8,55.8,251.1,56.2z"/>
				<path fill="#F8F7F7" d="M216.6,54.9c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.2
					C216.3,54.7,216.4,54.8,216.6,54.9z"/>
				<path fill="#F8F7F7" d="M215.4,53.7c0.4-0.1,0.7-0.1,1,0.2C216.1,54,215.7,54.1,215.4,53.7z"/>
				<path fill="#FBFAF9" d="M194.3,46.3c-0.2,0.4-0.5,0.5-0.9,0.4C193.6,46.4,194,46.4,194.3,46.3L194.3,46.3z"/>
				<path fill="#FEFEFE" d="M222,44c0.5-0.6,0.9-0.4,1.4-0.3C223,44,222.6,44.1,222,44z"/>
				<path fill="#FEFEFE" d="M218,44.5c-0.5,0.2-1,0.3-1.5,0.5C216.9,44.7,217.3,44.3,218,44.5L218,44.5z"/>
			</g>
		</g>
	</g>
	<g id="arm">
		<g id="ftxyMM_1_">
			<g>
				<path fill="#EDE2CF" d="M260.9,175c0.5,0.2,1,0.5,0.8,1c-0.3,0.9,0.3,1.3,0.7,1.9c0.5-0.6-0.4-0.8-0.2-1.4c0.7,0.4,1.2,1,1,1.6
					c-0.2,0.8,0.2,1.3,0.5,1.9c1.3,0,1.2,1.1,1.6,1.8c0.3,0.8,1,1.1,1.7,1.5c0.5,0.4,0.9,0.5,1-0.3c0-0.2,0-0.4,0.2-0.5
					c0.4,0.3,0.2,0.6,0,0.9c-0.3,0.5-0.2,0.9,0.1,1.3l0,0c0.3,0.1,0.6,0.2,1,0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.5-0.5,1-1.1,1-1.9
					c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0,0.2,0,0.3c-0.1,0.4,0,0.7,0.4,0.9c0.7,0.1,0.8,1.1,1.5,1.2c0.1,0,0.2,0,0.3-0.1
					c0.3-0.1,0.5-0.3,0.6-0.6c0.2-0.3,0.3-0.7,0.2-1.1c-0.1-0.2-0.4-0.3-0.4-0.5c0-0.3,0.4-0.4,0.5-0.6c0.2-0.3,0.5-0.5,0.7-0.7
					c0.4-0.2,0.7-0.3,1.1-0.4c0.3,0.2,0.8-0.1,0.8,0.4c0,0.4-0.1,0.7-0.2,1.1c0,0.3-0.1,0.6-0.5,0.6c-0.3,0.2-0.7,0.3-1.1,0.3
					c-0.5,0-0.8,0.4-0.9,0.9c0,0.5,0.2,0.8,0.8,0.9c0.5,0.1,1,0.2,1.4,0.6c0.4,0.3,0.8,0.5,1.1,0c-0.2-0.7,0.6-0.3,0.7-0.7
					c0-0.1,0-0.4,0.1-0.4c0.2-0.1,0.2,0.1,0.3,0.2c0.1,0.3,0,0.7,0.2,1c0.1,0.1,0.3,0.2,0.4,0.2c0.6-0.2,0.6-1,1.3-1.3
					c-0.1,0.5-0.9,0.6-0.6,1.2c0.1,0.2,0.2,0.3,0.3,0.4c0.4,0.3-0.5,1.2,0.4,1.3c-0.9-0.2,0-1-0.5-1.4c-0.4-0.3-0.8,0.3-1.3-0.1
					c-0.2-0.1-0.4,0.3-0.4,0.7c0.3,0.6-0.5,1.2,0,1.9c2.1,0.1,4.1-0.1,6-1c0.1-0.1,0.1-0.3,0.1-0.3c0.2,0-0.5-0.9,0.3-0.3
					c0.1,0.1,0.3,0.1,0.5,0.1c0.5-0.2,0.9-0.6,0.8-1.4c1.1,0.7,1.5,0.2,2.3-0.4c0.8-0.6,1.5-1.4,2.4-1.8c1-0.5,1.6-1.3,2.2-2
					c1.8-2.1,3.3-4.4,4.7-6.9c3-5.4,5.8-11,8.6-16.5c2.6-5.2,5.4-10.3,8-15.5c1.1-2.1,2.1-4.2,3.6-6.2c3.1-4.3,4.8-9.1,5-14.3
					c0.1-1.3,0.4-2.6,0.3-3.9c-0.1-2.2-0.4-4.4-0.7-6.5c-0.2-2.2-1-4.3-2-6.2c-1.6-3-3-6.1-5.4-8.6c-4.5-4.7-12.7-3.5-15.5,2.3
					c-2.2,4.6-4.4,9.1-5.9,14.1c-1.5,5.1-3.3,10.1-5.1,15.1c-2.6,7.1-4.9,14.4-7.8,21.4c-0.6,1.4-0.9,3-1.5,4.4
					c-0.1,0.2,0.1,0.6-0.3,0.7c-0.4,0-0.7-0.2-0.8-0.6c-0.3-1-0.9-1.9-1.3-2.8c-1.4-3.3-2.7-6.6-4.3-9.8c-0.8-1.7-1.3-3.5-2.1-5.2
					c-1.7-3.9-3.5-7.8-7.4-10c-1.7-0.9-1.8-2.7-2.5-4.2c-1-2.3-1.7-4.6-2.7-6.8c-0.8-0.2-1.6,0.2-2.3,0.4c-3,0.9-6,2-9.1,2.9
					c0.2,2,1,3.9,1.5,5.9c0.3,1,0.6,2.1,0.8,3.1c0.2,0.9,0.1,1.8-0.3,2.7c-1,2.2-1.4,4.6-1.1,7.1c0.4,3.7,1.7,7.1,3.2,10.6
					c0.5,1.3,1,2.6,1.4,3.9c0.9,3,2.1,5.8,3.1,8.7c0.9,2.5,1.7,5,2.6,7.5c0.1,0.4,0.2,0.8,0.8,1c0.7,0.2,0.2,0.8,0.1,1.2
					c0.4,0.7,0.3,1.6,1.4,1.8c0.3,0,0.1,0.2,0,0.3C259.5,173.5,260,174.7,260.9,175z"/>
				<path fill="#C1A790" d="M246.2,118.9c0.2,0.1,0.4,0.2,0.7,0.1c3.6-1.1,7.1-2.3,10.7-3.4c-1.1-3.4-2.2-6.8-3.4-10.2
					c-0.3-0.8-0.4-1.7-0.8-2.5c-0.5-1.1-0.4-2.1,0.4-3.3c1.1-1.7,1.5-3.8,1.8-5.9c0.4-3.2,1-6.4,1.2-9.7c0.1-1.3,0.5-2.5,0.6-3.8
					c0.1-0.5,0.5-1.2-0.3-1.5c-0.6-0.2-1.4,0-1.8,0.6c-0.8,1-1.6,2.1-1.9,3.4c-0.3,1.1-0.6,2.2-0.9,3.4c-0.1,0.6-0.5,1.1-1,1.2
					c-0.6,0.1-0.7-0.6-0.9-1.1c-2.1-4.4-3.9-8.9-6.3-13.1c-0.6-1-1.4-1.6-2.6-1.4c-1,0.2-1.6-0.5-2.4-0.8c-1.6-0.6-2.3-0.4-3.3,1
					c-0.7,1-1.1,2.2-1.9,3.2c-0.6,0.8-0.3,1.8-0.2,2.7c0.1,1.3,0.1,2.5-0.1,3.8c-0.2,1.2,0.1,2.3,0.6,3.4c2,5,3.8,10.1,6,15
					c0.8,1.9,2.3,3.4,2.7,5.5c0.9,3.8,1.8,7.7,2.7,11.5C245.9,117.7,246.1,118.3,246.2,118.9z"/>
				<path fill="#EFE5D4" d="M269.5,184.7c-0.4,0-0.7,0-1.1,0.1c0.6,0.6,1.2,1.2,2,1.6c0.6,0.3,0.8,0.1,0.8-0.5
					c0.1-0.3,0.4-0.6,0.3-0.9c-0.5-0.6-0.9-1.2-0.8-2.1c0-0.2-0.1-0.3-0.3-0.4C270.4,183.4,269.8,184,269.5,184.7z"/>
				<path fill="#EFE6D6" d="M322.3,111.9c-0.2,1.4-0.1,2.8,0.2,4.1c0.2,0.8,0,1.7,0.5,2.4C323.6,116.1,323.2,114,322.3,111.9z"/>
				<path fill="#F2EBDF" d="M273.6,187.9c1,0.5,2.2,0.7,3.3,0.9c0.4-0.1,0.5-0.5,0.4-0.8c-0.4-0.5-1.1-0.5-1.7-0.5
					c-0.2,0.5-0.2-0.1-0.4-0.1c-0.3-0.2-0.6-0.5-1-0.5C273.9,187.1,273.4,187.3,273.6,187.9z"/>
				<path fill="#FBF8F4" d="M260.9,175c-0.7-0.2-0.8-1-1.1-1.5c-0.3-0.5,0.2-0.5,0.4-0.6c0.4-0.2,0.3-0.5,0.1-0.8
					c-0.1-0.2-0.4-0.5-0.5-0.2c-0.6,0.8-0.6-0.1-0.8-0.3c-0.2-0.3-0.4-0.6-0.5-0.9c0.5,1.6,1.1,3.1,2,4.5
					C260.6,175.4,260.8,175.4,260.9,175z"/>
				<path fill="#F4EEE4" d="M267.9,182.9c-0.1,0.5-0.3,0.7-0.8,0.5c0.2,0.7,0.6,1.2,1.4,1.4c0-0.5-0.2-0.9,0.3-1.4
					c0.4-0.3,0.1-0.9-0.2-1.3c-0.2-0.3-0.4-0.2-0.5,0.1C268.2,182.5,268.4,182.8,267.9,182.9z"/>
				<path fill="#F7F2EA" d="M265.4,181.9c0.1-0.4,0-0.8-0.1-1.1c-0.2-0.7-0.6-1.4-1.5-0.7C264.2,180.8,264.6,181.5,265.4,181.9z"/>
				<path fill="#F0E7D8" d="M271.4,185c0,0.3,0,0.6-0.1,0.9c0.6-0.2,0.3,0.8,0.8,0.8c-0.2,0.7,0.5,0.8,0.8,1.1
					c1-0.2-0.1-0.8,0.2-1.2c-0.4-0.3-0.7-0.7-0.7-1.3C272.1,185,271.8,184.7,271.4,185z"/>
				<path fill="#FAF8F4" d="M277.3,188c-0.1,0.3-0.3,0.5-0.4,0.8c0.5,0.3,0.9,0.2,1.4,0c-0.2-0.7,0.7-1.1,0.3-1.8
					C277.5,186.5,277.8,187.8,277.3,188z"/>
				<path fill="#FDFDFC" d="M273.1,186.6c-0.1,0.2-0.8,0.2-0.1,0.6c0.4,0.2,0.3,0.4,0,0.6c0.2,0.2,0.4,0.2,0.7,0.2
					c0.1-0.5,0.7-0.6,0.8-1c0-0.1,0-0.2,0-0.3c-0.2-0.2-0.4-0.2-0.7-0.3C273.5,186.3,273.3,186.4,273.1,186.6z"/>
				<path fill="#FCFCFA" d="M285.2,187.3c-0.6-0.1-0.6-1.2-1.2-0.8c-0.7,0.5,0.7,0.7,0.3,1.3C284.6,187.7,284.9,187.5,285.2,187.3z"
					/>
				<path fill="#EEE3D1" d="M274.3,186.7c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.3,0.8,0.9,0.6c0.1,0.1,0.2,0.1,0.3,0.1
					c0.5,0.2,1.1,0.3,1.6,0.5c0.5,0.3,0.7,0.1,0.6-0.4c0-0.5,0.3-0.7,0.7-0.7c-0.1-0.3,0.1-0.7,0.2-0.6c0.4,0.7,0.8-0.3,1.2,0.1
					c0.1,0,0.2,0,0.2-0.1c-0.1-0.1-0.2-0.3-0.2-0.4c-0.3-0.1-0.6,0-0.9-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.3-0.5,0.2
					c-0.3,0.1-0.4,0.4-0.6,0.5c-0.3-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.4-0.3-0.5c-0.3,0.5-0.6,0.1-0.9-0.1c-0.2,0.6-0.2,0.5-0.9,0.5
					C275,186.1,274.1,185.5,274.3,186.7z"/>
				<path fill="#FBF9F6" d="M274.3,186.7c0-0.5,0.2-0.6,0.7-0.5c0.5,0.1,1,0.1,1.5,0.2c0.2-0.4-0.5-0.6-0.2-0.9
					c-0.4-0.5-0.9-0.5-1.4-0.6c-0.4,0-0.7,0.1-1.1,0.4c-0.4,0.3-0.3,0.8-0.2,1.2C273.9,186.6,274.1,186.6,274.3,186.7z"/>
				<path fill="#EFE5D4" d="M273.6,186.5c0.5-0.2,0.1-0.7,0.3-1c0-0.3-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.4
					c-0.8-0.1-0.6-1.4-1.6-1.3c-0.3,0.4-0.1,0.8,0.3,1c0.3,0.2,0.6,0.5,0.8,0.8c-0.5,0.8,0,1.2,0.7,1.3
					C273.3,186.6,273.5,186.5,273.6,186.5z"/>
				<path fill="#F3EDE2" d="M274.2,184.2c0.1-0.4,0.3-0.8,0.9-0.6c0.4,0.1,0.8,0,1-0.5c0.2-0.3,0.2-0.5-0.1-0.7c-0.1,0-0.2,0-0.3,0
					c-0.8,0.5-0.9-0.4-1.2-0.7c-0.2-0.1-0.4-0.1-0.5,0.2c-0.3,0.3-1.1,0.3-0.8,1c0.2,0,0.5-0.3,0.7,0.2c0,0.4-0.1,0.7-0.3,1
					C273.7,184.4,273.9,184.5,274.2,184.2z"/>
				<path fill="#FCFAF7" d="M272.4,185.3c0-0.4,0-0.9-0.6-1c-0.5-0.1-0.4-0.5-0.5-0.8c-0.6-0.1,0-0.5-0.2-0.7c-0.2,0-0.3,0-0.4,0.2
					c0.1,0.6-0.4,1-0.4,1.4c0,0.6,1.2-0.3,1.1,0.7C271.7,185.1,272,185.2,272.4,185.3z"/>
				<path fill="#FBF9F5" d="M279.1,185.9c0.3,0.4,0.6,0.2,0.9,0.1c-0.1-0.4,0.1-0.5,0.5-0.5c0.4,0,0.3-0.4,0.3-0.7
					c0-0.3-0.2-0.4-0.4-0.5c-0.3-0.1-0.4,0.1-0.5,0.3C279.6,185,279.3,185.4,279.1,185.9z"/>
				<path fill="#F3ECE0" d="M262.5,173c-0.1,0.2-0.3,0.2-0.6,0.2c-0.4,0-0.5,0.2-0.2,0.6c0.9,1,0.9,1,1.7-0.2
					C263.3,173.2,262.9,173,262.5,173z"/>
				<path fill="#FDFCFB" d="M279.4,181.8c-0.4,0.3-1.1,0.5-0.8,1.3c0.1,0.5,0.4,0.7,0.8,0.6c0.7-0.2,0.2-0.4,0-0.7
					C279,182.7,280,182.3,279.4,181.8z"/>
				<path fill="#F9F6F1" d="M278.3,186c0.3,0.2,0.3-0.1,0.4-0.2c0.1-0.3,0.2-0.9,0.1-1c-0.4-0.2-0.2-1-0.7-0.8
					c-0.6,0.2-0.3,0.7-0.1,1.1c-0.1,0.2-0.2,0.4-0.1,0.6C278,185.9,278.1,186,278.3,186z"/>
				<path fill="#F6F1E9" d="M267.5,173.5c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.3,0.3-0.5,0-0.8c-0.3-0.3-0.7-0.3-0.9,0
					c-0.2,0.2-0.2,0.5-0.5,0.5c-0.1,0.2,0,0.3,0.1,0.4C266.9,173.3,267.2,173.4,267.5,173.5z"/>
				<path fill="#F9F7F3" d="M275.3,175.2c0.4-0.4,1.1-0.6,0.9-1.3c-0.1-0.4-0.6-0.1-0.9,0C274.5,174.3,275.3,174.7,275.3,175.2z"/>
				<path fill="#FAF7F3" d="M274,177.1c-0.4,0.1-0.4,0.4-0.2,0.5c0.3,0.2,0.6,0.7,0.9,0.4c0.3-0.2,0.6-0.7,0.4-1
					C274.8,176.5,274.3,177.1,274,177.1z"/>
				<path fill="#F0E7D7" d="M267.5,176.5c0.3,0.4-0.7,0.8-0.1,1.2c0.2,0.1,0.3-0.1,0.5-0.2c0.8-0.3,0.4-0.8,0.3-1.2
					c0-0.2,0-0.3-0.1-0.5C267.8,175.7,267.6,176.1,267.5,176.5z"/>
				<path fill="#FBFAF6" d="M271.2,179.5c-0.2,0.2-0.1,0.4,0,0.5c0.2,0.3-0.3,0.9,0.4,1.1c0.1,0,0.4,0,0.4-0.1
					c0.2-0.5-0.2-0.9-0.4-1.3C271.7,179.5,271.4,179.4,271.2,179.5z"/>
				<path fill="#F4EEE3" d="M280.2,186.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.7-0.2,1.3,0.7,1.7c0.2,0.1,0.3,0.3,0.5,0
					c0.2-0.2,0.1-0.6-0.1-0.6c-0.8-0.1-0.9-0.3-0.5-1C280.4,186.5,280.3,186.4,280.2,186.3z"/>
				<path fill="#FBFAF6" d="M283.4,184.4c0.3-0.2,0.3-0.4,0.3-0.4c-0.6,0.1-0.4-1.1-1.2-0.7c-0.2,0.1-0.5,0.3-0.3,0.5
					C282.6,184.1,283.1,184.3,283.4,184.4z"/>
				<path fill="#FAF7F3" d="M274,181.9c0.2-0.1,0.4-0.1,0.5,0c0.2,0.8,0.7,0.7,1.2,0.3c0.1-0.1,0.2-0.3,0.2-0.5c0-1-0.9-0.5-1.3-0.7
					C274.4,181.3,274.2,181.6,274,181.9z"/>
				<path fill="#F3EDE2" d="M270.9,167.5c-0.6,0.6-0.6,0.6-0.2,1.7C271.9,168.7,270.7,168.1,270.9,167.5z"/>
				<path fill="#F0E7D7" d="M280.8,185.7c0.2,0.6,0.4,1.1,0.6,1.5c0.1,0.2,0.2,0.4,0.4,0.3c0.3-0.1,0.1-0.3,0.1-0.5
					C281.8,186.4,281.6,185.9,280.8,185.7z"/>
				<path fill="#F1EADC" d="M274.2,184.2c-0.2,0-0.5,0-0.7-0.2c-0.2,0.2-0.3,0.4-0.5,0.6c0,0.3,0.3,0.4,0.4,0.6
					c0.1,0.1,0.3,0.2,0.4,0.3c0.3-0.2,0.7-0.4,1-0.6C274.5,184.9,274.3,184.6,274.2,184.2z"/>
				<path fill="#FBF8F5" d="M263.3,169.6c-0.2-0.3-0.5-0.4-0.5-0.3c-0.2,0.4-0.3,0.8-0.3,1.3c0,0.1,0.3,0.3,0.5,0.1
					C263.2,170.4,263.5,170,263.3,169.6z"/>
				<path fill="#FDFCFB" d="M267.5,173.5c-0.2-0.3-0.3-0.6-0.8-0.4c-0.2-0.1-0.3,0.2-0.4,0.2c-0.2,0.3-0.3,0.5,0.1,0.6
					C266.9,174.2,267.2,173.9,267.5,173.5z"/>
				<path fill="#F3EDE1" d="M269.4,180.7c0.1-0.4,0.2-0.8,0.4-1.2c-0.9-0.2-0.7,1-1.4,0.9c-0.1,0-0.2,0-0.2,0.1c0,0.2,0,0.4,0,0.5
					c0.4,0.1,0.7-0.2,1-0.3C269.3,180.7,269.4,180.7,269.4,180.7z"/>
				<path fill="#FDFBFA" d="M262.5,173c0.2,0.4,0.6,0.4,0.9,0.6c0-0.8-0.3-1.2-1.2-1.4C262.3,172.5,262.4,172.7,262.5,173z"/>
				<path fill="#F4EEE3" d="M267.2,178.5c0.3,0.4-0.6,0.7-0.2,1.1c0.1,0.1,0.4,0.2,0.5,0c0.3-0.3,0.4-0.7,0.1-1.1
					C267.5,178.5,267.4,178.4,267.2,178.5z"/>
				<path fill="#FAF7F2" d="M277,181.3c0.2-0.9-0.8-0.4-1-0.8c-0.5,0.4-0.1,0.8,0.1,1.1c0.1,0.1,0.2,0.3,0.4,0.3
					C276.9,181.9,276.9,181.6,277,181.3z"/>
				<path fill="#FCFAF7" d="M267.5,176.5c0.2-0.2,0.5-0.5,0.7-0.7c-0.2-0.4-0.5-0.7-0.9-0.4C267,175.7,267.1,176.2,267.5,176.5z"/>
				<path fill="#F6F0E8" d="M265.2,179.2c0.9,0,1-0.4,0.6-1.4c-0.3,0-0.4,0.3-0.6,0.5C265,178.6,265.3,178.9,265.2,179.2z"/>
				<path fill="#F9F7F2" d="M276,182.4c0.1,0.2,0.1,0.5,0.2,0.7c0.2,0.1,0.6,0.3,0.7,0.3c0.4-0.3-0.2-0.6,0-0.9
					C276.6,181.8,276.3,181.9,276,182.4z"/>
				<path fill="#F8F4EE" d="M277.8,177.7c0.2-0.2,0.5-0.5,0.2-0.8c-0.2-0.2-0.6-0.2-0.7-0.1c-0.1,0.2,0,0.5,0,0.8
					C277.4,177.9,277.6,177.8,277.8,177.7z"/>
				<path fill="#F8F5EF" d="M281.7,177.6c0.2,0.1,0.4,0.2,0.6,0c0.3-0.2,0.4-0.5,0.2-0.8c-0.2-0.2-0.4-0.1-0.5,0
					C281.7,177,281.6,177.3,281.7,177.6z"/>
				<path fill="#F2EBDE" d="M271.2,176.3c0.3,0.4-0.1,1,0.5,1.2c0.1,0,0.2-0.1,0.3-0.2C272,176.8,271.8,176.4,271.2,176.3z"/>
				<path fill="#F1E9DB" d="M276.2,181.4c-0.3-0.3-0.4-0.6-0.3-1c-0.5,0.2-0.9,0.3-1.4,0.5c0.2,0.2,0.2,0.6,0.6,0.3
					c0.4-0.3,0.5,0.1,0.7,0.4C276,181.7,276.2,181.6,276.2,181.4z"/>
				<path fill="#F2EBDF" d="M272.5,174.2c-0.4,0.5-0.8,0.8-0.6,1.3c0,0,0.2,0.1,0.3,0.1C272.6,175.2,272.6,174.8,272.5,174.2z"/>
				<path fill="#F3ECE0" d="M286.7,175.4c0.2,0.5-0.1,1,0.5,1.2c0.1,0,0.3-0.1,0.3-0.2C287.6,176,287.4,175.6,286.7,175.4z"/>
				<path fill="#F0E8D8" d="M274.8,172.9c0.3-0.1,0.5-0.2,0.4-0.6c-0.1-0.4-0.4-0.1-0.5,0c-0.1,0.2-0.7,0.1-0.5,0.6
					C274.4,173.4,274.6,173.1,274.8,172.9z"/>
				<path fill="#F1E9DB" d="M285.4,181.5c0.5,0.5-0.7,0.8-0.2,1.2c0.2,0.1,0.3,0,0.5-0.2C286.1,182.1,285.8,181.8,285.4,181.5z"/>
				<path fill="#FAF8F3" d="M268.7,166.1c-0.3,0.2-0.2,0.5-0.2,0.8c0.1,0.2,0.3,0.2,0.5,0.1c0.2-0.1,0.4-0.3,0.2-0.5
					C269,166.3,269,166,268.7,166.1z"/>
				<path fill="#F0E8D9" d="M271.2,173.2c0.4,0.1,0.8,0.2,1-0.2c0.1-0.3,0-0.6-0.4-0.7C271.2,172.4,271.2,172.8,271.2,173.2z"/>
				<path fill="#F3ECE0" d="M279.8,178c0.5,0,0.9,0.1,1.3,0.4C280.7,177.3,280.7,177.3,279.8,178z"/>
				<path fill="#FBF9F5" d="M265.2,179.2c0-0.3,0-0.6,0.1-0.9c0-0.3-0.3-0.2-0.4-0.3c-0.3,0-0.7,0.1-0.3,0.5
					C264.7,178.8,265,179,265.2,179.2z"/>
				<path fill="#F8F4ED" d="M280.5,182.2c0.1-0.4,0-0.8-0.3-1.1c-0.1,0-0.3,0-0.3,0C279.7,181.6,280.3,181.8,280.5,182.2z"/>
				<path fill="#F8F5F0" d="M278.1,185.6c0-0.2,0-0.4,0-0.5c-0.4,0-0.8-0.1-0.8,0.5c0.1,0.2,0.1,0.4,0,0.5c0,0.1,0,0.2,0.1,0.2
					c0.1,0,0.1,0.1,0.1,0.1C277.9,186.3,278,186,278.1,185.6z"/>
				<path fill="#FBF9F6" d="M288.4,172.9c-0.2,0.1-0.2,0.3-0.1,0.5c0.2,0.2,0.4,0.2,0.6,0.1c0.2-0.1,0.2-0.3,0.1-0.5
					C288.9,172.8,288.6,172.8,288.4,172.9z"/>
				<path fill="#FAF7F3" d="M288,184.1c0.2,0.2,0.3,0.5,0.7,0.3c0.2-0.1,0.2-0.2,0.1-0.4c-0.1-0.3-0.4-0.4-0.7-0.3
					C287.9,183.8,287.8,183.9,288,184.1z"/>
				<path fill="#FCFBF9" d="M267.9,182.9c0.2,0.2,0.3,0.7,0.5,0.3c0.2-0.3,0.2-0.8-0.4-1C268,182.5,268,182.7,267.9,182.9z"/>
				<path fill="#F3ECE0" d="M282.6,182.1c-0.5-0.3-0.8-0.4-1.1-0.1c0,0,0,0.2,0.1,0.3C282,182.6,282.2,182.4,282.6,182.1z"/>
				<path fill="#F5F0E7" d="M266.4,169c0.2-0.3,0.5-0.5,0.7-0.7C266.2,168,266.2,168,266.4,169z"/>
				<path fill="#F7F3EC" d="M280.9,171.5c-0.2,0.6,0.1,0.9,0.6,1C281.6,171.9,281.4,171.6,280.9,171.5z"/>
				<path fill="#FBF9F6" d="M271.9,167.4c0.2,0.2,0.4,0.2,0.6,0.1c0.2-0.1,0.2-0.3,0-0.4c-0.2-0.1-0.4-0.4-0.6-0.2
					C271.8,167,271.8,167.2,271.9,167.4z"/>
				<path fill="#F3ECDF" d="M264.3,172.4c-0.3,0.4-0.4,0.7-0.1,1c0,0.1,0.3,0.1,0.3,0C264.8,173.1,264.6,172.8,264.3,172.4z"/>
				<path fill="#F3ECE0" d="M269.6,176.1c0,0.5,0.1,0.8,0.6,1C270.2,176.7,270.1,176.4,269.6,176.1z"/>
				<path fill="#FDFCFA" d="M273.9,183.1c0.1-0.3,0.4-0.6,0.1-0.8c-0.3-0.1-0.6,0.3-0.8,0.6C273.4,183,273.6,183,273.9,183.1z"/>
				<path fill="#F8F5F0" d="M285.4,175.9c0,0.1-0.1,0.3-0.1,0.4c0.1,0.2,0.4,0.3,0.6,0.2c0.1,0,0.2-0.3,0.1-0.4
					C286,175.8,285.7,175.8,285.4,175.9z"/>
				<path fill="#F0E8DA" d="M290.5,181.7c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.1,0.2,0.2,0.2,0.1C290.5,182.4,290.8,182.2,290.5,181.7z"/>
				<path fill="#F1E9DB" d="M276,182.4c0.3-0.2,0.6-0.4,0.9,0.1c0.1-0.4,0.3-0.8,0.1-1.2c-0.2,0.2-0.3,0.4-0.5,0.6
					c-0.3-0.1-0.5,0-0.7,0.2c-0.2,0.1-0.2,0.3-0.1,0.5C275.8,182.5,275.9,182.4,276,182.4z"/>
				<path fill="#F7F3EC" d="M284.3,177c-0.1,0.4-0.1,0.7,0.4,0.8c0.1,0,0.3-0.1,0.3-0.1C285,177.2,284.7,177.1,284.3,177z"/>
				<path fill="#F6F1E8" d="M261.7,169.2c-0.5,0.3-0.7,0.5-0.7,1C261.5,170,261.8,169.8,261.7,169.2z"/>
				<path fill="#FCFCF9" d="M264.6,176.5c0.1,0.2,0.3,0.1,0.5,0.1c0.2,0,0.3-0.1,0.2-0.3c-0.1-0.2-0.3-0.2-0.5-0.2
					C264.7,176.2,264.6,176.3,264.6,176.5z"/>
				<path fill="#F8F3EC" d="M282.5,185.1c-0.1-0.2-0.2-0.4-0.5-0.3c-0.2,0.1-0.2,0.3-0.1,0.4c0.1,0.1,0.3,0.3,0.5,0.2
					C282.6,185.5,282.6,185.2,282.5,185.1z"/>
				<path fill="#F6F2E9" d="M287.6,180.5c-0.2,0.1-0.3,0.3-0.2,0.5c0.1,0.2,0.2,0.2,0.4,0.1c0.1-0.1,0.4-0.3,0.3-0.4
					C288.1,180.4,287.8,180.4,287.6,180.5z"/>
				<path fill="#F3ECE0" d="M273,165.4c-0.2,0-0.3,0.2-0.2,0.3c0.1,0.2,0.3,0.3,0.5,0.2c0.1-0.1,0.2-0.3,0.2-0.4
					C273.4,165.3,273.2,165.4,273,165.4z"/>
				<path fill="#FBF8F3" d="M285.8,173.4c-0.3-0.4-0.6-0.4-1-0.3C285,173.6,285.3,173.6,285.8,173.4z"/>
				<path fill="#F3EDE1" d="M271.2,173.2c0.2-0.3,0.4-0.6,0.6-0.9c-0.3-0.2-0.5,0-0.7,0.2c0,0.3-0.4,0.4-0.5,0.6
					C270.7,173.3,270.9,173.4,271.2,173.2z"/>
				<path fill="#FBF7F3" d="M289.5,175.7c0.2-0.5,0-0.7-0.3-1C289,175.1,289,175.4,289.5,175.7z"/>
				<path fill="#FDFDFC" d="M264.8,178c0.1,0.1,0.3,0.2,0.4,0.3c0.2-0.2,0.3-0.4,0.5-0.6C265.3,177.1,265.2,178,264.8,178z"/>
				<path fill="#FAF8F3" d="M263.4,175.1c0,0.1-0.1,0.2,0,0.3c0.1,0.2,0.4,0.3,0.6,0.2c0.1,0,0.1-0.2,0-0.3
					C263.9,175.1,263.7,175,263.4,175.1z"/>
				<path fill="#F1E8D8" d="M277.2,174.4c-0.1,0.2-0.1,0.3,0,0.4c0.2,0.1,0.4,0.2,0.5,0c0,0,0-0.2-0.1-0.3
					C277.6,174.5,277.4,174.3,277.2,174.4z"/>
				<path fill="#FCFAF8" d="M273.4,185.3c0.7-0.7-0.2-0.4-0.4-0.6c-0.1,0-0.1,0.1-0.2,0.1C272.9,185.1,273,185.3,273.4,185.3z"/>
				<path fill="#F0E7D8" d="M287.1,184.4c-0.5-0.1-0.8,0.1-1,0.3C286.5,184.9,286.8,184.8,287.1,184.4z"/>
				<path fill="#F7F2EB" d="M286.4,177.7c0.1,0.2,0.3,0.3,0.4,0.2c0.2-0.1,0.2-0.3,0.2-0.5c0-0.2-0.2-0.1-0.3-0.1
					C286.5,177.4,286.4,177.5,286.4,177.7z"/>
				<path fill="#F2EBDD" d="M283.2,180c0.1,0,0.3,0.1,0.4,0c0.2-0.1,0.2-0.3,0-0.5c-0.1-0.1-0.3-0.2-0.4,0
					C283.1,179.6,283.1,179.8,283.2,180z"/>
				<path fill="#F0E8D8" d="M265.3,175c0.2,0,0.3,0,0.4,0c0.1-0.1,0.1-0.3,0.1-0.4c-0.1-0.2-0.2-0.2-0.3,0
					C265.3,174.6,265.3,174.8,265.3,175z"/>
				<path fill="#FAF8F3" d="M280.5,183.3c0.1,0.3,0.4,0.3,0.6,0.3c0.1,0,0.1-0.2,0.1-0.3C281,183.1,280.8,183.1,280.5,183.3z"/>
				<path fill="#F9F5EF" d="M273.3,172c-0.3-0.6-0.5-0.9-1-0.8C272.6,171.4,272.8,171.6,273.3,172z"/>
				<path fill="#F8F5EF" d="M270.3,175.3c0,0.3,0,0.6,0.3,0.7c0.1,0,0.2-0.1,0.2-0.2C270.9,175.4,270.6,175.4,270.3,175.3z"/>
				<path fill="#F8F4EE" d="M277.7,179.8c-0.1,0.1-0.2,0.2-0.1,0.4c0.1,0.1,0.3,0.2,0.4,0.1c0.1-0.1,0.1-0.2,0.1-0.3
					C278,179.8,277.8,179.7,277.7,179.8z"/>
				<path fill="#F3ECE1" d="M269.6,163.2c0.1-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.3-0.2-0.4,0c-0.1,0.2-0.1,0.3,0.1,0.4
					C269.4,163.2,269.5,163.2,269.6,163.2z"/>
				<path fill="#F2ECDF" d="M256.7,163.9c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.2,0.1,0.3,0.3,0.2c0.1-0.1,0.1-0.3,0.1-0.4
					C257,164,256.8,164,256.7,163.9z"/>
				<path fill="#F6F1E9" d="M278.7,178.4c-0.2,0.1-0.3,0.2-0.3,0.4c0.1,0.2,0.3,0.1,0.4,0c0.1-0.1,0.2-0.2,0.1-0.4
					C278.9,178.3,278.7,178.4,278.7,178.4z"/>
				<path fill="#F2EADD" d="M282,175.8c0.1,0.1,0.2,0.2,0.3,0.1c0.2,0,0.2-0.2,0.2-0.4c0-0.2-0.2-0.3-0.3-0.2
					C282.1,175.4,282,175.6,282,175.8z"/>
				<path fill="#F6F0E8" d="M273.8,180.5c0.1-0.1,0.3-0.2,0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.1c-0.1,0-0.1,0.2-0.1,0.3
					C273.5,180.5,273.7,180.6,273.8,180.5z"/>
				<path fill="#FDFDFC" d="M266.3,173.3c0.3,0.1,0.3-0.1,0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4C265.9,172.8,266.2,173.1,266.3,173.3z"
					/>
				<path fill="#F7F3EC" d="M261.5,164.6c-0.2,0-0.2,0.2-0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0.1-0.2,0.1-0.3
					C261.8,164.7,261.7,164.6,261.5,164.6z"/>
				<path fill="#FDFAF8" d="M274.6,169.8c0.2,0,0.3,0,0.5,0c0,0,0.1-0.3,0.1-0.3c-0.1-0.1-0.3-0.1-0.4-0.1
					C274.7,169.5,274.6,169.6,274.6,169.8z"/>
				<path fill="#FBFAF6" d="M269.2,173.2c0-0.1,0.1-0.3,0.1-0.3c0-0.1-0.2-0.2-0.2-0.2c-0.2,0-0.2,0.2-0.1,0.3
					C269,173.1,269.1,173.1,269.2,173.2z"/>
				<path fill="#F5F0E8" d="M258.8,169c0.2,0,0.3,0.1,0.4,0c0.1-0.1,0.2-0.2,0.1-0.3c0,0-0.2,0-0.3,0
					C258.9,168.7,258.9,168.9,258.8,169z"/>
				<path fill="#F3EDE2" d="M270.2,181.5c0-0.1,0.1-0.3,0.1-0.3c0-0.1-0.2-0.2-0.2-0.2c-0.2,0-0.2,0.2-0.1,0.3
					C269.9,181.4,270,181.5,270.2,181.5z"/>
				<path fill="#F6F2EA" d="M263.6,176.7c0,0.1,0,0.2,0,0.3c0.1,0.2,0.3,0.1,0.4,0c0.1-0.1,0.1-0.2,0-0.3
					C263.9,176.7,263.8,176.8,263.6,176.7z"/>
				<path fill="#F3EDE1" d="M268.5,181.5c0.6,0.1,0.7-0.4,0.9-0.8c0,0,0,0,0,0C268.9,180.7,268.5,180.9,268.5,181.5z"/>
				<path fill="#F8F5EF" d="M275.8,172.3c0.1,0.2,0.3,0.2,0.4,0.2c0.1,0,0.1-0.2,0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.2
					C275.9,172,275.9,172.2,275.8,172.3z"/>
				<path fill="#FCFBF8" d="M268.5,181.5c0.2-0.4,0.5-0.6,0.9-0.8c-0.5-0.2-0.8,0.2-1.2,0.4C268.3,181.2,268.4,181.3,268.5,181.5z"
					/>
				<path fill="#FBF9F5" d="M268.2,180.5c0.1,0,0.1-0.1,0.2-0.1c-0.1-0.2-0.3-0.4-0.6-0.3c-0.1,0-0.2,0.2-0.1,0.3
					C267.8,180.5,268,180.5,268.2,180.5z"/>
				<path fill="#F4EEE3" d="M269.3,182.3c0.1,0,0.2,0.1,0.3,0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.1-0.2-0.1
					C269.4,182.1,269.3,182.2,269.3,182.3z"/>
				<path fill="#F3ECE1" d="M274.6,179.4c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1-0.2,0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.1
					C274.5,179.2,274.5,179.3,274.6,179.4z"/>
				<path fill="#FDFCFB" d="M270.7,182.9c0.1-0.1,0.3-0.2,0.4-0.2c0-0.7-0.4-0.3-0.6-0.2C270.6,182.7,270.7,182.8,270.7,182.9z"/>
				<path fill="#F5F0E7" d="M269.4,170.5c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
					C269.4,170.3,269.4,170.4,269.4,170.5z"/>
				<path fill="#F5EFE6" d="M272.3,168.9c0,0.1,0,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0-0.1,0-0.2-0.1-0.3
					C272.5,168.9,272.4,168.9,272.3,168.9z"/>
				<path fill="#F2ECDF" d="M267,181.6c0-0.1,0-0.3,0-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c0,0.1-0.1,0.2,0,0.3
					C266.7,181.6,266.8,181.6,267,181.6z"/>
				<path fill="#F9F6F1" d="M272.7,177.8c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.1c0.1-0.1,0.2-0.2,0.2-0.2
					C272.9,177.8,272.8,177.7,272.7,177.8z"/>
				<path fill="#F1E9DB" d="M291.2,179.4c0.1,0,0.2,0,0.3,0c0-0.1,0-0.2,0-0.3c0,0-0.1-0.1-0.2-0.1
					C291,179.1,291.1,179.2,291.2,179.4z"/>
				<path fill="#F6F1E8" d="M275,167.8c-0.1,0.2-0.2,0.3-0.1,0.3c0.1,0.2,0.3,0.1,0.4,0.1c0.1,0,0.1-0.1,0.1-0.2
					C275.3,168,275.1,167.9,275,167.8z"/>
				<path fill="#FDFBF8" d="M270.1,177.8c0,0.1-0.1,0.2-0.1,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0-0.1,0-0.2,0-0.3
					C270.4,177.7,270.3,177.7,270.1,177.8z"/>
				<path fill="#F7F2EB" d="M275.6,169.2c0-0.1,0-0.3,0-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c0,0.1-0.1,0.2,0,0.3
					C275.3,169.1,275.5,169.1,275.6,169.2z"/>
				<path fill="#F8F5EF" d="M279.5,179.9c0,0.2-0.1,0.3,0,0.4c0,0.1,0.2,0.1,0.2,0.1c0-0.1,0.1-0.2,0.1-0.3
					C279.7,180,279.6,179.9,279.5,179.9z"/>
				<path fill="#F0E8D8" d="M288.2,176c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0.2,0.2,0.3,0.1c0.1,0,0.1-0.2,0.1-0.3
					C288.5,176.1,288.4,176,288.2,176z"/>
				<path fill="#FCFBF9" d="M270.6,173.1c0.3,0,0.8,0,0.5-0.6C270.8,172.6,270.6,172.7,270.6,173.1z"/>
				<path fill="#FEFEFE" d="M282.4,180.6c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0-0.3,0c0,0-0.1,0.1-0.1,0.2
					C282.1,180.7,282.2,180.7,282.4,180.6z"/>
				<path fill="#F5F0E7" d="M267.8,166.4c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.2,0.2,0.1c0.1-0.1,0.2-0.2,0.2-0.3
					C268.1,166.5,267.9,166.4,267.8,166.4z"/>
				<path fill="#EDE2CF" d="M269.6,185.1c0.1,0.1,0.3,0.2,0.4,0.1c0.1-0.1,0-0.3,0-0.3c-0.1-0.1-0.3-0.1-0.4-0.1
					C269.6,184.8,269.6,185,269.6,185.1z"/>
				<path fill="#FCFBF8" d="M275.6,187.6c-0.1,0-0.2-0.1-0.3-0.1c0.1,0.2-0.3,0.4,0.1,0.6c0.1,0.1,0.3,0,0.4,0
					C275.7,187.9,275.7,187.8,275.6,187.6z"/>
				<path fill="#EDE2CF" d="M278.1,185.6c-0.2,0.2-0.5,0.5-0.7,0.7c-0.1,0.3,0.1,0.5,0.3,0.6c0.2-0.3,0.4-0.6,0.6-0.9
					C278.3,185.9,278.2,185.8,278.1,185.6z"/>
				<path fill="#FAF8F4" d="M277.7,186.9c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1-0.1-0.1-0.1-0.2C277,186.6,276.9,187,277.7,186.9z"/>
				<path fill="#EDE2CF" d="M275.8,182.5c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2c0,0-0.1,0-0.1,0c-0.4,0.3-0.8,0.4-1.2-0.1
					c-0.1,0.6,0.2,0.9,0.8,1.1C275.7,183.1,275.8,182.8,275.8,182.5z"/>
				<path fill="#EDE2CF" d="M275.7,182c0,0.1,0.1,0.1,0.1,0.2c0.2-0.1,0.4-0.2,0.6-0.4c-0.1-0.1-0.2-0.3-0.2-0.4
					c-0.1,0.1-0.3,0.2-0.4,0.2C275.8,181.8,275.7,181.9,275.7,182z"/>
			</g>
		</g>
	</g>
</g>
<g id="body">
	<g id="w0M4oC_2_">
		<g>
			<path fill="#615952" d="M336.9,559.7c-3.2,2.5-6.6,4.5-10.3,6.1c-2.9,1.2-5.8,2.3-8.7,3.2c-1.7,0.6-2.6,1.7-2.5,3.3
				c0,1,0.5,1.5,1.5,1.4c1.2-0.1,2.5-0.1,3.7-0.2c0.7-0.1,0.9,0.1,0.7,0.8c-0.2,0.8-0.1,1.6,0.6,2.3c0.8,0,1.6,0,2.4,0
				c0.4-0.2,0.8-0.2,1.2,0c0.2,0,0.5,0,0.7,0c0.5-0.2,1-0.2,1.4,0c0.2,0,0.3,0,0.5,0c0.6-0.2,1.3-0.2,1.9,0c0.6,0,1.3,0,1.9,0
				c1-0.2,1.9-0.2,2.9,0c0.5,0,1,0,1.4,0c1.8-0.3,3.7-0.1,5.5-0.6c2.1-0.5,4.1-1,6-2c1.3-0.7,1.4-0.6,1.6,0.9c0.1,0.7,0,1.5,1.1,1.5
				c1.7-0.1,3.4,0.1,5-0.1c2.1,0,4.2-0.1,6.3-0.1c0.9,0,1.3-0.3,1.4-1.2c0.7-4.7,0.9-9.3-1.5-13.6c-0.8-0.3-1.4,0.1-2,0.4
				c-5,1.7-9.9,1.9-14.9-0.1c-0.7-0.3-1.4-0.4-2.1-0.5c-1.5-0.2-2.9-0.7-4.2-1.4C337.9,559.7,337.5,559.2,336.9,559.7z"/>
			<path fill="#010101" d="M306.6,226.9c0.9,3.7,0,7.2-1,10.7c-1.2,4.2-2.6,8.4-3.8,12.6c-1.7,5.8-3.5,11.5-5,17.4
				c-1.1,4.1-2.5,8.2-3.5,12.3c-1.3,5-2.5,10-3.6,15c-1.2,5.2-2.5,10.4-3.5,15.6c-1,5.4-1.9,10.8-2.7,16.1c-0.6,3.5-1.3,7-1.6,10.5
				c-0.4,4.5-0.7,9-1.1,13.5c-0.3,3.6-0.6,7.3-0.6,10.9c-0.1,6.2-0.4,12.3,0.1,18.5c0.5,5.6,0.9,11.2,1.7,16.8
				c0.9,6.1,1.5,12.2,2.8,18.3c1.5,7,3.1,14,4.6,21c0.5,2.4,1.3,4.8,1.8,7.3c1.2,5.4,3.3,10.6,4.9,16c0.5,1.7,1.2,3.3,1.9,4.9
				c0.6,1.3,0.8,2.7,1.4,4.1c2.1,5.6,4.2,11.3,6.3,16.9c0.9,2.3,2,4.5,2.8,6.8c1.6,4.3,3.5,8.5,5.4,12.7c2.2,4.9,4.6,9.7,7,14.5
				c2.7,5.3,5.2,10.6,7.8,16c0.4,0.4,0.8,0.2,1.2,0.1c4.4-1.4,8.9-2.7,13-4.8c0.9-0.5,2.2-0.5,2.7-1.6c0.1-1.7-0.4-3.3-0.8-4.9
				c-1.4-5.8-2.8-11.6-4.2-17.5c-1.2-5.1-2.5-10.3-3.8-15.4c-1.3-5.3-2.5-10.7-3.7-16c-1.5-6.8-2.9-13.7-4.6-20.4
				c-0.7-2.7-1.1-5.5-1.7-8.2c-0.9-4.1-1.7-8.1-2.6-12.2c-1.3-6.1-2.3-12.2-3.1-18.3c-0.5-3.8-1-7.6-1.3-11.4
				c-0.2-2.1-0.3-4.1-0.3-6.2c-0.1-3,0-6.1-0.3-9.1c-0.2-2.8,0.2-5.6,0.6-8.4c0.5-4.2,0.7-8.5,1.6-12.6c1.3-6.2,2.4-12.5,3.9-18.7
				c1.1-4.5,2.1-8.9,3.4-13.4c1.9-6.4,3.6-12.9,5.9-19.3c1.3-3.7,2.3-7.5,3.7-11.1c0.2-0.6,0.5-1.2,0.9-1.7c0.4-0.5,0.8-0.5,1.1,0.1
				c0.4,0.7,0.5,1.5,0.6,2.3c0.1,5,0.1,10,0.3,15c0.2,4.9,0.3,9.8,0.6,14.7c0.1,2.8,0.4,5.5,0.4,8.3c0,2.8,0.3,5.5,0.5,8.3
				c0.2,2.7,0.2,5.5,0.4,8.3c0.4,4.7,0.3,9.3,0.9,14c0,0.2,0,0.4,0,0.6c-0.2,7,0.7,14,0.8,21c0.1,6.5,0.7,12.9,1.2,19.4
				c0.3,4,0.8,7.9,0.9,11.9c0.2,4.2,0.4,8.4,0.8,12.6c0.3,3.3,0.5,6.5,0.4,9.8c0,1,0,2,0.2,3c0.4,2,0.5,4,0.5,6
				c0,3.4,0.5,6.8,0.6,10.2c0.2,4.4,0.4,8.8,0.3,13.2c-0.1,2.4,0,4.8,0.1,7.2c0.1,2.8,0.4,5.5,0.4,8.3c0,5.6,0.1,11.2,0.2,16.8
				c0.1,4.5-0.3,9-0.2,13.5c0,0.6-0.1,1.2,0.2,1.8c0.3,0.6,0.8,0.8,1.4,0.8c5.5,0.6,11,1.8,16.6,1.8c0.6,0,1.1,0,1.6-0.4
				c1.4-4.3,2.6-8.7,3.8-13.1c0.8-3.1,1.6-6.2,2.4-9.3c0.8-3.3,1.5-6.5,2.5-9.8c0.8-2.8,1.3-5.7,1.9-8.6c0.8-4,1.7-7.9,2.4-11.9
				c0.5-2.8,1.2-5.5,1.5-8.3c0.4-4.1,0.9-8.1,1.2-12.2c0.2-3.3,0.4-6.5,0.4-9.8c0-2.7,0-5.4,0-8.1c-0.1-4.8-0.5-9.5-0.9-14.2
				c-0.3-3.7-0.5-7.4-1-11c-0.5-4.3-1.1-8.6-1.6-13c-0.2-1.6-0.3-3.3-0.4-4.9c-0.5-7.1,0.2-14.1,1.4-21.1c0.7-4.2,1.3-8.4,1.9-12.5
				c0.7-4.1,1.4-8.2,2.3-12.2c0.7-3,1.1-6,1.8-9c0.7-3.1,1.1-6.3,1.9-9.4c0.6-2.5,1-5.1,1.6-7.6c1-4.3,2-8.5,2.9-12.8
				c0.6-3.1,1-6.3,1.5-9.4c0.5-3,0.9-5.9,1-8.9c0.1-3.6,0.1-7.3,0-11c-0.2-4.2-0.9-8.3-1.6-12.4c-0.7-4.1-2-8.1-3.3-12.1
				c-1-3-1.9-5.9-3.1-8.8c-0.1-0.2-0.1-0.5-0.1-0.7c-0.3-0.3-0.5,0-0.7,0.2c-0.7,1.2-1.8,1.4-2.9,1.1c-1.4-0.3-2.9-0.3-4.3-0.7
				c-2.5-0.8-4.1-2.6-5.6-4.5c-2.4-3-4.8-6-6.7-9.3c-1.7-2.9-2.6-6-2.2-9.4c0.1-0.9-0.1-1.4-1.1-1.4c-2.9,0.1-5.7,0.5-8.6,0.6
				c-4.7,0.2-9.4,0.8-14.1,0.5c-1.6-0.1-3.2-0.1-4.8,0.1c-3.3,0.3-6.5,0.1-9.8,0.1c-4.8-0.1-9.6,0.1-14.5-0.4
				c-2.4-0.2-4.9-0.2-7.4-0.3C307.1,226.5,306.8,226.5,306.6,226.9z"/>
			<path fill="#EDE2CF" d="M306.6,226.9c1.9,0.2,3.9,0.3,5.8,0.3c2.7,0,5.4,0.3,8.2,0.3c5.2,0,10.4,0.1,15.6,0.1
				c2.4,0,4.9-0.4,7.3-0.2c4.6,0.4,9.1-0.4,13.6-0.4c3.2,0,6.5-0.4,9.7-0.7c0.8-0.1,1-0.5,1.2-1.2c0.9-3.7,2.7-7,5.8-9.3
				c2.2-1.6,4.7-2.7,7.3-3.3c0.5-0.1,1.2-0.1,1.3-0.8c-0.2-0.5-0.2-1.1-0.1-1.7c0.5-4.3,1.2-8.5,2-12.8c0.9-5.2,1.7-10.5,2.5-15.7
				c0.3-2.2,0.9-4.4,1.3-6.6c0.6-3.1,1.1-6.3,1.9-9.4c1-4.3,1.8-8.6,3.3-12.7c0.7-1.8,1.3-1.9,2.6-0.5c4,4.4,7.6,9.1,11.1,14
				c3,4.1,5.5,8.5,8.1,12.7c1.8,2.8,3.5,5.6,5.1,8.5c0.9,1.7,1.8,3.5,2.1,5.5c0.4,3.1-0.9,5.3-3.2,7.2c-2.6,2.1-5.8,3.2-9,4.2
				c-5.9,1.8-11.8,3.1-17.7,4.6c-0.3,0.1-0.6,0.1-0.7,0.5c0.9,4.1,2.5,8,3.3,12.1c0.1,0.4,0.2,0.7,0.6,0.9c1.5-0.3,2.9-0.7,4.4-1
				c0.6-0.1,1,0.3,1.3,0.6c1.5,1.4,2.9,2.9,4.6,4.2c1.5,1.1,3.1,1.9,4.8,2.6c1.6,0.6,3.2,1.2,4.9,1.1c3-0.2,6-0.3,9-1.1
				c5.1-1.2,9.8-3.4,14-6.3c2.9-2,5.2-4.7,7.4-7.4c2.4-3,4.2-6.3,5.3-9.9c1.1-3.6,1.2-7.2,0.3-10.9c-1.1-4.8-2.5-9.5-4.5-14
				c-1.7-4-3.9-7.7-6-11.5c-0.5-0.1-1.1-0.2-1.6-0.2c0.8,0.1,1.1-0.2,0.9-1c0-0.2,0-0.4-0.2-0.5c-0.8-0.1-1.5-0.2-2-0.9
				c0.4,0.4,0.9,0.6,1.4,0.8c0.2,0.1,0.5,0,0.4-0.3c-0.8-1.4-1.6-2.7-2.4-4.1c-0.4-0.1-0.4,0.2-0.5,0.4c0,0.1-0.1,0.2-0.1,0.3
				c0,0.4,0,0.7,0,1.1c-0.1,0.5-0.1,1.1-0.7,1.4c-0.3,0.4-0.3,0.8,0.1,1.2c0.3,0.3,0.5,0.7,0.7,1.1c-1.2-1.3-1.2-1.3-0.8-2.4
				c-0.2-0.7,0.6-0.8,0.7-1.3c-0.1-0.9-0.6-1.3-1.4-1.2c0.3-0.4,1.5-0.2,1.3-1.3c-0.8-1.4-1.6-2.9-2.4-4.3c-0.5-0.2-0.5,0.4-0.8,0.5
				c-0.2,0.1-0.5,0.2-0.7,0.2c-0.3,0.1-0.5,0.1-0.7,0.3c-0.1,0.1-0.2,0.1-0.2,0.2c-0.2,0.2-0.1,0.4-0.3,0.6
				c-0.2-0.3,0.2-0.4,0.3-0.6c0.4-1,0.1-2.1-0.8-2.6c-0.1,0-0.2,0-0.3,0.1c-0.8,1-1.7,2-1.7,3.4c0-1.2,0.5-2.2,1.1-3.2
				c0.1-0.7,0-1.5,1.1-1c0.1,0,0.2,0.1,0.2,0.1c0.2,0.4,0.1,1.2,0.9,1c0.7-0.2,0.4-0.8,0.5-1.3c0-0.2,0-0.3-0.1-0.4
				c-0.2-0.5-0.2-1-1.1-0.7c-0.9,0.3-0.6-0.5-0.6-0.9l0,0c-0.5,0.1-0.6-0.3-0.8-0.6c-0.3-0.7,0.7-0.3,0.5-0.7
				c-0.1-0.3-0.4-0.1-0.6,0c-0.8,0.1-1.1-0.3-0.7-1c0.2-0.3,0.4-0.5,0.1-0.8c-0.7-0.9-1.1-2-1.9-2.7c-0.4,0.3-0.4,0.8-0.4,1.2
				c-0.9-0.7-0.1-0.9,0.4-1.2c-0.1-1.4-1.4-1.7-2.4-2.3c-0.2-0.1-0.5-0.2-0.3-0.4c0.8-1,0-1.7-0.5-2.4c-0.1-0.1-0.2-0.2-0.3-0.4
				c-0.3-1.1-1.2-1-2.1-1c-0.4,0.4-0.6,0.8-1,1.2c-0.1,0.2,0.1,0.5,0.2,0.4c0.6-0.3,1,0.2,1.5,0.4c0.3,0,0.5-0.2,0.8,0
				c0.3,0.2,0.3,0.4,0.2,0.8c-0.2,0.6-0.4,0.1-0.5,0c-0.3-0.2-0.9-0.1-1-0.7c-0.2-0.1-0.5-0.2-0.7,0c-0.5,0.5-1,0.3-1.2-0.1
				c-0.3-0.5,0.5-0.4,0.6-0.8c0.2-0.5,1-0.8,0.2-1.5c0-0.1,0-0.2,0-0.2c-0.4-0.3-0.9,0-1.4,0c0.1-0.4,0.3-0.8,0.5-1.3
				c0.3,0.5,0.1,1.3,0.9,1.3c0.2-0.1,0.4-0.2,0.7,0c0.3,0.2,0.7,0.3,1,0c0.3-0.4-0.2-0.9-0.3-1c-1-0.6-1-1.8-1.8-2.7
				c-0.7-0.7-1.1-1.7-1.8-2.5c-0.6-0.7-1.1-1.7-1.6-2.5c-1-1.7-1-1.7-2.8-0.9c-0.1-0.5,0.2-0.5,0.5-0.7c0.6-0.3,1.1-0.6,0.4-1.4
				c-0.8-0.9-1.3-2-2-3c-0.3-0.3-0.7-0.2-0.9-0.1c-0.6,0.8-1.1,0.1-1.7,0c0.8,0.1,1.6,0.5,2.1-0.4c-0.7-0.9-1.1-1.9-1.9-2.6
				c-0.7-0.2-0.7,0.2-0.7,0.7c0,0.2,0.2,0.3,0.1,0.4c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.6-0.2-0.2-0.6c0.4-0.4,0.8-1-0.2-1.3
				c-0.1,0-0.2,0.2-0.3,0.2c0-0.2,0.1-0.2,0.2-0.3c0.2-0.1,0.6,0.2,0.5-0.3c-1.7-2.5-3.5-5-5.4-7.4c-0.1-0.1-0.2-0.2-0.2-0.3
				c-0.4-1.1-1-1.9-2.4-1.6c-0.2,0-0.6,0.2-0.8-0.1c0.1-0.6,0.6-0.9,1.2-1.1c-1.5-1.8-2.9-3.6-4.5-5.2c-3.2-3.3-6.6-6.3-10.4-8.8
				c-0.3-0.2-0.6-0.3-0.6-0.7c-3.4-2.2-7.1-3.7-11.1-4.7c-4.3-1.1-8.6-1.8-12.9-2.6c-1.3-0.2-2.6-0.7-3.9-1.1
				c-0.8,0-0.9,0.5-1.1,1.1c-0.6,2-1.4,4-2.2,5.9c-1.7,3.9-4.3,7.1-7,10.2c-2.6,3-5.6,5.5-9,7.7c-1.9,1.3-2.1,1.3-4,0
				c-2.8-2-5.2-4.3-7-7.1c-0.2-0.3-0.4-0.6-0.8-0.7c-1.6,0.4-2.8,1.6-4.3,2.1c-0.2,0.1-0.5,0.2-0.8,0.3c-0.5,0.3-1,0.4-1.6,0.4
				c-0.1,0-0.2,0-0.3,0.1c-0.7,0.2-1.5,0.3-2.3,0.4c-0.6,0.2-1.2,0.2-1.8,0.2c-1.1,0-2.1-0.2-3.2-0.3c-0.4-0.1-0.8-0.1-1.2-0.2
				c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.9-0.1-1.5-0.6-2.2-1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-0.3-0.8-0.7-1.2-1
				c-0.2-0.2-0.4-0.4-0.5-0.6c-0.5-0.8-1.2-1.4-1.4-2.3c-0.4-0.9-0.4-1.9-0.8-2.9c0,0-0.1-0.1-0.1-0.1c-0.3,0.2-0.6,0.3-0.7,0.6
				c-0.9,2.2-2.2,4.1-2.8,6.4c-0.2,0.9-0.5,1.8-1,2.7c-0.3,0.5-0.5,1.1-0.4,1.6c0.1,2.4-0.3,4.8-0.5,7.3c-0.1,0.7-0.3,1.2-0.4,1.9
				c-0.5,2.5-0.8,5-1.1,7.5c-0.1,1.4-0.1,2.9,0,4.3c0.1,2.2-0.1,4.5-0.2,6.7c-0.1,1.7,0,3.4-0.2,5.1c-0.2,1.9-0.1,3.8,0,5.6
				c0.2,4.3,0.3,8.6,0.9,12.8c0.2,1.5,0.2,3,0.3,4.5c0.1,3.8,0.6,7.6,1.1,11.4c0.3,1.9,0.4,3.8,0.5,5.7c0.2,3.2,0.7,6.4,1.1,9.6
				c0.3,2.5,0.6,5,0.9,7.5c0.4,3,0.7,5.9,1.1,8.9c0.3,1.9,0.5,3.8,0.6,5.7c0.2,2.7,0.7,5.3,1.1,8
				C306.2,223.9,306.4,225.4,306.6,226.9z"/>
			<path fill="#030303" d="M359.6,87.3c3.2,1.1,6.5,1.6,9.9,2.2c6,1.1,12,2.6,17.3,5.8c0.3,0.2,0.6,0.3,0.9,0.2
				c1.8-1.2,3.7-2.3,4.8-4.3c1.2-2.1,1.8-4.3,1.8-6.7c0-2-0.6-3.8-1.1-5.7c-0.4-1.9,0.1-3.7,1-5.3c2.9-5.1,1.3-10.2-2.3-14.1
				c-2.2-2.4-4.9-4.4-7-6.9c-1.3-1.5-2.2-3.3-3.3-4.9c-0.9,0.3-1-1.1-1.8-1c-0.2,0,0-0.3,0-0.5c0.1-0.4,0.5-0.7,0.4-1.2
				c-0.1-0.5-0.2-1-0.5-1.3c-0.6,0-0.3,0.6-0.7,0.8c0.1-0.6,0.8-0.9,0.5-1.5c-0.1-0.2-0.2-0.5-0.2-0.7c-0.1-0.1-0.3-0.1-0.4-0.1
				c-0.3,0-0.6-0.1-0.8,0.2c-0.1,0.3,0.3,0.4,0.4,0.7c-0.9,0.1-1.2-0.6-1.7-1.1c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2-0.1-0.4-0.1-0.7-0.1
				c-0.4,0.3-0.7,0.7-1.2,0.9c-0.1-0.2,0.2-0.5-0.1-0.7c-0.3-0.4-0.9-0.3-1.4-0.4c-0.1,0,0.1-0.1,0.1-0.1c0.3,0.3,0.9-0.1,1.2,0.4
				c0.2,0.3,0.5,0.3,0.8,0.1c0.3-0.2,0.6-0.4,0.9-0.2c0.3,0.2,0.6,0.3,0.9,0.4c0.6,0.6,1.3-0.1,2,0.1c0.3,0.1,0.2-0.5,0.1-0.8
				c-0.1-0.4-0.2-0.8-0.3-1.2c-1-0.6-0.7-1.5-0.6-2.4c-0.2-0.3-0.5-0.4-0.9-0.4c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.3,0.1,0.6-0.1,0.9
				c0,0.1-0.1-0.2,0-0.1c0.2-0.3,0-0.5,0-0.8c0-0.1,0-0.1,0-0.2c0.1-1-0.5-1.7-0.9-2.4c0-0.1-0.1-0.2-0.2-0.2c-0.6-0.1-0.8-0.5-1-1
				c-0.1-0.1-0.2-0.1-0.3-0.1c-0.3-0.1-0.2,0.5-0.6,0.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2-0.6-0.1-0.6,0.7-0.4c0.3,0.1,0.7,0.2,0.7-0.3
				c0.1-0.5,0-1.1-0.5-1.4c-0.5-0.3-0.9-0.6-1.4-0.9c-0.3-0.1-0.6,0-0.8,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c0.7,0.8-0.1,1.2-0.4,1.7
				c0.1,0.5,0.3,0.9,0.3,1.4c0.1,0.3,0.4,0.4,0.4,0.8c0,0.3-0.1,0.6-0.3,0.8c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.4
				c-0.1,0.1-0.1,0.2-0.1,0.2c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.5,0.1c-0.1-0.1-0.3-0.2-0.4-0.4c-0.3-0.4,0-0.7,0.1-1
				c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.3,0.2-0.6-0.2-0.8c-0.3-0.1-0.6,0-0.9,0.2c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.1
				c0-0.1,0.1-0.2,0.1-0.3c0-0.4,0.3-0.7,0.2-1.1c-0.6-0.4,0.1-0.7,0.1-1.1c0.1-0.4,0-0.8,0.4-1.1c0.7-0.5,1.4,0.4,2-0.1
				c0.1-0.5-0.1-1-0.5-1.4c-0.2-0.1-0.4-0.1-0.6,0c-0.1,0-0.1,0.1-0.2,0c0.3-0.1,0.6-0.2,0.6-0.6c-0.1-0.2-0.2-0.4-0.5-0.3
				c0.2-0.1,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.8-0.8,0.9,0.2,1.3,0.5c0.5,0.2,0.8-0.1,1.2-0.2c0.1-0.1,0.2-0.1,0.2-0.2
				c0.5-0.6,0.2-1.1,0-1.7c-0.2-0.5-0.6-0.4-1-0.3c-0.3,0.1-0.5,0.2-0.7,0.3c0-0.3,0.3-0.5,0.1-0.7c0-0.1-0.1-0.2-0.2-0.2
				c-0.2,0-0.5,0.1-0.6-0.2c-0.2-0.1-0.5,0-0.7,0c-0.3-0.1-0.5-0.2-0.8-0.4c-0.4-0.2-0.5,0.2-0.8,0.3c-0.2,0.2-0.5,0.1-0.6-0.1
				c-0.2-0.2,0-0.4,0.1-0.6c0.2-0.3,0.5-0.5,0.6-0.9c0-0.1,0-0.3,0-0.3c-0.9-0.3-0.3-0.6,0-0.9c0.1-0.2,0.1-0.4,0-0.6
				c-0.6-0.6-1.4-0.3-2.1-0.3c-0.1,0-0.3-0.1-0.4-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c-0.4-0.4-0.2-0.9-0.3-1.3
				c-0.3-0.2-0.6-0.3-0.9-0.3c-0.3,0-0.5,0.2-0.7,0.3c-0.7,0.1-1.1-0.3-1.6-0.7c0.3-0.3,0.6-0.7,1.2-0.5c0.3,0.2,0.5,0.1,0.8,0
				c0.4-0.2,0.7-0.4,0.8-0.9c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.7-0.1-0.4-1-1-1.2c-0.1-0.1-0.3-0.2-0.4-0.3
				c-0.3-0.4-1-0.4-0.9-1.2c0.4-0.8,1.4-1.3,1.2-2.4c-0.4-0.6-0.8-0.2-1.3-0.1c-0.3,0.1-0.4,0-0.5-0.3c-0.1-0.3-0.2-0.5-0.2-0.7
				c0.4-0.5,0.6,0.1,0.9,0.2c0.3,0.1,0.6,0.1,0.9,0.3c0.2,0,0.4,0,0.5-0.3c0.1-0.3,0-0.7,0.4-0.7c0.3,0,0.5,0.1,0.7-0.2
				c0.1-0.2,0.1-0.4,0-0.6c-0.2-0.4-1.3-0.5-0.3-1.2c0.2-0.1,0.4-0.3,0.6-0.4c0.3-0.1,0.6,0.1,0.9-0.1c0.2-0.4,0.4-0.7,0.4-1.2
				c-0.6-0.3-1-0.9-1.6-1c-0.1-0.1-0.2-0.1-0.3,0c-0.5,0.4,0.2,0.9-0.1,1.4c-0.2,0.1-0.5,0.2-0.7,0.3c-0.3,0.4-1,0.4-1.1,1
				c0,0.1-0.2-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.5,0.1-0.2-0.2c0.1-0.1,0.3-0.4,0.4,0.1c0,0.2,0.2,0.2,0.4,0.2
				c0.7-0.2,0.7-0.7,0.4-1.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.2-0.5-0.3-0.4-0.6c0.2-0.4,0.5,0,0.7,0c0.3,0.1,0.6,0.3,1,0
				c0.2-0.4-0.1-0.5-0.3-0.7c-0.4-0.2-0.8-0.5-1.2-0.7c-0.6-0.2-1.1-0.5-1.7,0.1c-0.3,0.3-0.7-0.2-1.1-0.2c-0.3-0.1-0.6-0.1-0.9,0
				c-0.3,0.1-0.5,0.2-0.6,0.5c-0.4,0.6-1.5-0.1-1.5,1.1c-0.3-0.6-0.1-1,0-1.3c0-0.2-0.1-0.3-0.2-0.4c-0.4-0.1-0.6-0.4-0.9-0.5
				c-0.2-0.1-0.4-0.1-0.6,0c-0.4,0-0.2-0.3-0.1-0.4c0.1-0.2,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.3,0c0.3-0.2,0.5-0.4,0.6-0.8
				c0-0.2-0.1-0.3-0.2-0.5c-0.2-0.2-0.4-0.3-0.7-0.2c-0.9,0.6-1.5-0.2-2.2-0.5l0,0c-0.6-0.1-0.7,0.4-0.8,0.8c-0.1-0.8-0.8-1-1.4-1.2
				c-2.6-0.4-5.3-0.6-7.9-0.3c-3.5,0.4-7,1-10.3,2c-3.1,0.9-6.1,2-8.8,3.8c-0.7,0.5-1.1,1-0.8,1.9c-0.1,1.3,0.5,2.4,1.1,3.5
				c2.9,5.9,6.1,11.8,10.3,17c2.5,3.1,5.1,6.2,7.8,9.1c2.6,2.9,5.4,5.6,8.6,7.8c1.8,1.3,2.6,2.5,1.9,4.9c-1,3.4-1.7,6.9-1.4,10.5
				c0.3,4.1,0.4,8.2,0.9,12.3c0.5,3.7,0.6,7.4,1.3,11.1c0.2,1.2,0.8,2.1,1.9,2.8C358.6,87.2,359.1,87.2,359.6,87.3z"/>
			<path fill="#C1A790" d="M359.6,87.3c-0.2-0.1-0.4-0.3-0.6-0.3c-1.6-0.6-2.4-1.8-2.6-3.5c-0.4-2.7-0.7-5.4-1-8.1
				c-0.5-4.8-0.9-9.6-1.1-14.4c-0.2-4.4,0.9-8.5,1.8-12.6c0.2-0.8,0-1.2-0.5-1.7c-3-2.4-5.9-4.8-8.6-7.5c-3.3-3.3-6.3-6.8-9.2-10.4
				c-4.1-5-7.2-10.5-10.1-16.3c-0.7-1.5-1.3-3-1.9-4.5c-1.7,1.8-3.3,3.6-4.5,5.8c-1.6,3-2.3,6.2-3.2,9.4c-1.6,5.6-2.8,11.4-5.7,16.6
				c-0.7,1.3-0.6,1.4,0.8,1.9c0.3,0.1,0.7,0.3,1,0.4c2.4,0.2,2.6,1.9,2.7,3.8c0.1,1.9,0.2,1.9,2.1,1.6c1.3-0.2,2.6-0.4,3.9-0.7
				c0.3-0.1,0.7-0.2,0.9,0.1c0.2,0.3,0,0.6-0.1,0.9c-1,2.1-2.6,3.7-4.9,4.5c-0.9,0.3-1.2,0.6-1,1.6c0.4,1.9,0.7,3.9,1.5,5.8
				c0.3,0.6,0.9,1,1,1.7c1.4,1.2,3.1,1.9,4.9,2.3c2.6,0.5,5.2,0.6,7.8,1c0.3,0,0.6,0,0.8,0.1c1.6,0.2,1.7,0.3,1.7,1.9
				c0.3,7.2,0.3,14.4,0,21.5c-0.1,2.3-0.2,4.5-0.8,6.8c-0.6,2.5-1.7,4.8-3.3,6.9c-0.5,0.6-1,1.1-1.7,1.6c-0.4,0.4-1.3,0.4-1.2,1.3
				c1.3,1.8,2.6,3.5,4.2,5c1.3,1.3,2.8,2.4,4.3,3.5c0.9,0.6,1.7,0.6,2.6,0c5-3,9-7,12.6-11.5c3.2-4.1,5.3-8.7,6.8-13.6
				C359.2,87.6,359.3,87.4,359.6,87.3z"/>
			<path fill="#C1A790" d="M369.9,532.3c-2.4,0.3-4.8,0-7.1-0.3c-3.5-0.4-7-0.9-10.4-1.4c-0.6-0.1-1.4,0-1.8-0.7
				c-0.4,0-0.4,0.3-0.4,0.6c0.2,4.1-0.4,8.2-0.2,12.3c0,0.5,0.1,1.1,0.3,1.6c-0.4-1.6-0.9-3.2-1.4-4.8c-0.9-3.2-1.9-6.3-2.6-9.6
				c-0.1-0.5-0.2-0.9-0.8-1.1c-1.4,0.7-2.7,1.4-4.2,2c-4.1,1.6-8.2,3.2-12.5,4.2c1.4,2.8,2.9,5.7,4.3,8.5c1.8,3.6,3.5,7.2,5.3,10.8
				c0.9,1.9,0.4,3.4-1.1,4.7c-0.2,0.2-0.3,0.4-0.4,0.6c0.4,0,0.8,0.1,1.1,0.3c1.6,0.9,3.2,1.6,5.1,1.6c1.2,0,2.4,0.8,3.6,1.2
				c3.6,1.1,7.2,0.9,10.8,0c1.4-0.3,2.7-0.9,4.1-1.3c-0.1-2.7,0.5-5.4,1.4-7.9c2.1-5.9,4.3-11.8,5.9-17.9
				C369.2,534.6,369.7,533.5,369.9,532.3z"/>
			<path fill="#030303" d="M329.9,103.4c2.1-1.4,3.2-3.5,4.2-5.7c1.9-4,1.9-8.3,1.9-12.5c0.1-5.8,0-11.5,0.1-17.3
				c0-0.4-0.1-0.7-0.1-1.1c-0.3-2.7-0.3-2.6-3-2.9c-2.6-0.3-5.2-0.5-7.9-1c-1.8-0.3-3.2-1.4-4.9-2c-1.4,3.5-3.6,6.5-6.4,9
				c-2.2,2-4.6,4-6.9,6c-1.9,1.7-3.1,3.7-3.7,6.2c0.5,0.5,0.5,0.5,1.3,0.7c0,0.3-0.3,0.4-0.4,0.7c-0.1,0.3,0.2,0.5,0.3,0.8
				c-0.2,0-0.1-0.3-0.3-0.4c-0.4-0.7-0.6-0.2-0.8,0.1c0,0.5-0.3,0.8-0.8,0.8c-0.3,0.5-0.3,1.1,0,1.7c0.1,0.1,0.3,0.2,0.5,0.1
				c0.4-0.2,0.5-0.7,1-0.9c-0.3,1.1,1.2,0.8,1.2,1.7c0.2,0.4,0,0.8,0.1,1.2c0.3,0.4,0.2,0.8-0.1,1.2c-0.3,0.9-0.5,1.8,0.8,2.1
				c0.3,0.1,0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.5-0.1,0.7-0.4c0.1-0.2,0.2-0.4,0.2-0.6c0-0.1,0-0.2,0-0.3
				c0-0.2,0-0.3-0.1-0.5c-0.4-0.7-0.2-1.2,0.5-1.5c0.3-0.1,0.6-0.2,0.8-0.3c0.2-0.1,0.4,0,0.5,0.1c0.2,0.3,0.1,0.7,0.3,1
				c0.3,0.2,0.5,0.1,0.7-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.4,0.1-0.7,0.3-1.1c0.1-0.4,0.6-0.5,0.8-0.9c0.4-0.5,0.3,0.2,0.5,0.2
				c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.3,0,0.4-0.2,0.5c-0.4,0.2-0.4-0.2-0.6-0.4c-0.5-0.5-0.8-0.1-1.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
				c0,0.1,0,0.1,0,0.2c0.2,0.4,0.4,0.8,0.6,1.1c0.2,0.5,0.2,1.1,0.8,1.4c0.4,0.3,1.2,0.5,0.3,1.1c0.1,0.9-0.8,1.7,0,2.6
				c0.5,0.2,1,0.2,1.4,0.4c0.3,0,0.7-0.1,0.7-0.6c-0.4-0.3-0.4-0.7-0.3-1.1c0-0.1,0-0.2-0.1-0.2c0-0.1,0.1,0,0.1,0
				c0.1,0,0.3,0,0.4-0.1c0.1-0.9,0.7-1.8-0.2-2.5c-0.5-0.4-1.3,0-1.8-0.7c0.3-0.2,0.7-0.2,1-0.3c0.3,0,0.4,0.3,0.5,0.5
				c0,0.1,0.1,0.2,0.2,0.3c1.4,0.7,1.5,1.5,0.4,2.7c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0-0.4,0-0.6,0.1c-0.3,0.4,0.1,0.8,0.2,1.1
				c0.2,0.3,0.3,0.6,0.7,0.5c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0.1-0.1,0.2-0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0.2-0.3,0.5-0.7,0.3
				c-0.5-0.2-0.9,0.1-1.2,0.4c0,0.1,0,0.2,0,0.3c0.2,0.3,0.6,0.6,1,0.6c0.7,0,1.5,0.2,2.2,0.2c0.6,0,0.9-0.1,0.8-0.8
				c-0.1-0.2-0.3-0.3-0.4-0.5c-0.4-0.3-0.1-0.6,0-0.9c0.3-0.5,0.5-0.9,1.1-0.4c0.2,0.3,0,0.7,0.5,0.9c0.2,0.1,0.4,0.2,0.6,0.2
				c0.2,0,0.3,0,0.5,0c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3,0.2-0.2c0.2,0.2,0.1,0.4,0.2,0.6
				c0.3,0.4,0.7-0.2,1.1,0c-0.3,0.8-0.7,0.8-1.3,0.4c-0.8-0.2-0.9,0.3-0.9,1c0.1,0.3-0.1,0.6,0,0.9c0.1,0.2,0.3,0.4,0.5,0.6
				c0.2,0.2,0.4,0.4,0.6,0.4c0.4,0,0.7-0.2,1-0.4c0.6-0.8,1.3-0.2,1.9-0.1c0.2,0.1,0.5-0.2,0.6,0.1c0.6,0.3,0.7,1,1.1,1.4
				c0.8,0.4,0.7-0.4,1-0.8c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.4,0.2-0.5,0.5-0.3c0.4-0.3,0.9-0.4,1.5-0.6c-0.1,0.5-0.4,0.7-0.6,0.9
				c-0.3,0-0.3-0.3-0.5-0.4c-0.2-0.1-0.3-0.1-0.4,0c-0.2,0.2-0.5,0.4-0.7,0.7c-0.2,0.3-0.2,0.6,0,0.9c0.2,0.3,0.5,0.4,0.7,0.7
				c0.2,0.2,0.2,0.6,0.4,0.8c0.5,0,1.1,0,0.8,0.8C329,103.5,329.3,103.7,329.9,103.4z"/>
			<path fill="#C1A790" d="M395.6,222.4c-0.8-4.2-2.5-8.2-3.3-12.4c-0.1-0.2-0.2-0.4-0.4-0.5c-0.9-0.4-1.8,0-2.7,0.1
				c-2,0.4-3.9,1-5.9,1.5c-0.3,0.1-0.6,0.2-0.8,0.5c-2.1,0.8-4.2,1.3-6.2,2.3c-4.8,2.4-7.5,6.3-8.5,11.4c-0.1,0.6-0.6,0.5-0.9,0.9
				c0.3,0,0.7,0,0.6,0.4c-1,6.2,2.2,10.9,5.7,15.6c1.8,2.5,3.6,5,6,7.1c1.3,1.2,3,1.9,4.8,1.9c1.2,0,2.3,0.4,3.4,0.4
				c1.1,0,2-0.2,2.3-1.5c0-0.2,0.3-0.1,0.4-0.1c0.7-2-0.2-3.7-1.1-5.3c-1.9-3.6-3.9-7.2-5.9-10.8c-0.6-1.1-1.4-2.2-1.9-3.4
				c-1-2.3-0.3-4,2-4.9c3.6-1.4,7.4-2.1,11.2-2.9C394.8,222.6,395.2,222.5,395.6,222.4z"/>
			<path fill="#0C0C0C" d="M374.6,12.6c-0.1-0.4-0.4-0.6-0.7-0.9c-0.3-0.1-0.5,0.1-0.7,0.3c-0.1,0.1-0.3,0.3-0.5,0.4
				c-0.6,0.3-0.3-0.4-0.5-0.5c-0.1-0.2-0.3-0.3-0.6-0.2c-0.3,0.2-0.5,0.5-0.7,0.7c-0.4,0.5-0.9,0.6-1.4,0.4
				c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2,0.1-0.5,0-0.7,0c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.2-0.4,0.3-0.5,0.6c0,0.3,0.2,0.4,0.5,0.6
				c0.7,0.5,0.6,1.2,0.6,1.9c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0,0.3,0,0.4-0.1c0.2-0.1,0.3-0.3,0.5-0.4
				c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.5-0.6-0.6-0.5-1.1c0-0.1,0-0.2,0-0.2c0.2,0,0.2,0.1,0.4,0.1c0.3,0.2,0.8-0.3,1.1,0.2
				c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0,0.2,0,0.3,0c0.5-0.9,1.3-1.5,2.2-1.9C374.6,12.9,374.7,12.8,374.6,12.6z"/>
			<path fill="#0F0F0E" d="M375,28.1c0,0.1,0,0.2,0,0.3c-0.3,0.7-0.2,1.5,0.1,2.2c0.1,0.2,0.2,0.5,0.4,0.5c0.7,0.2,1.4,0.4,2.1,0.8
				c0.4,0.3,0.9,0,0.9-0.5c0-0.3-0.1-0.6,0.1-0.9c0-0.7,0.3-1.4,0-2.2c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3-0.1-0.6-0.3-0.9-0.4
				c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.3-0.5,0.7-0.3,1.1
				C375.7,28.2,375.4,27.9,375,28.1z"/>
			<path fill="#504A45" d="M378.6,30.5c-0.3,0.2-0.3,0.5-0.3,0.7c0,0.2,0,0.5-0.3,0.6c-0.2,0-0.5,0.1-0.6-0.3
				c-0.1-0.3-0.5-0.5-0.8-0.5c-1.6,0.1-1.6-0.8-1.5-2c0-0.2-0.1-0.4-0.2-0.7c-0.5-0.6-0.8,0.2-1.2,0.2c0,0-0.1,0.1-0.1,0.1
				c0.3,1,0.3,1-0.6,1.3c-0.1,0.2-0.1,0.3,0,0.5c0.3,0.4,0.6,0.8,1.1,0.9c0.9,0.2,0.5,0.9,0.6,1.4c0,0.1-0.3-0.1-0.5-0.2
				c-0.6,0.1-1.1,0.3-0.7,1c0.2,0,0.3,0,0.5-0.1c0.2-0.1,0.3-0.2,0.5-0.2c0.1,0,0.1,0,0.2,0c0.1,0.1,0.2,0.1,0.3,0.1
				c0.3-0.2,0.4-0.4,0.5-0.7c0.1-0.5,0.4-0.4,0.7-0.2c0.4,0.2,0.8,0.3,1.2,0.2c0.4-0.2,0.5,0.1,0.7,0.3c0.1,0,0.2-0.1,0.3-0.2
				c0-0.2,0-0.3-0.1-0.5C378.6,31.7,378.5,31.1,378.6,30.5z"/>
			<path fill="#0C0C0C" d="M305.9,97.2c0,0.2,0,0.3,0,0.5c0.5,0.4,1,0.4,1.6,0.4c0.3-0.1,0.5-0.3,0.9-0.1c0.3,0.1,0.6,0.2,0.8-0.2
				c0.1-0.6-0.8-1-0.2-1.7c0.5-0.1,0.4-0.7,0.7-0.9c0.2-0.1,0.4-0.2,0.4-0.4c0-0.2,0.1-0.4,0.3-0.7c0-0.1,0-0.2,0-0.3
				c0-0.7-0.4-1.1-1.1-1c-0.3-0.1-0.5-0.5-1-0.4c-0.3,0.1-0.4,0.3-0.5,0.6c0,0.4,0.2,0.8-0.4,0.9c-0.3,0.3-0.2,0.7-0.3,1
				c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.4-0.2,0.6c0.1,0.4,0.3,0.7,0.3,1.1c-0.1,0.4-0.2-0.1-0.2,0
				C306.4,96.7,306.1,96.9,305.9,97.2z"/>
			<path fill="#100F0F" d="M367.6,7.7c-0.2,0.2-0.4,0.3-0.6,0.5c-0.6,0.7,0.4,0.9,0.4,1.4c0.1,0.6,0.6,0.9,0.9,1.4
				c0.2,0.2,0.4,0.4,0.6,0.6c0.4,0.4,0.9,0.6,1.5,0.4c0.4-0.3,0.8-0.7,0.9-1.3c-0.3-0.6,0.4-1.2,0.1-1.8c-0.2-0.2-0.3-0.3-0.5-0.5
				c-0.4-0.1-0.5,0.3-0.7,0.5c-0.1,0.3-0.4,0.2-0.6,0.2c-0.6,0-1.1-0.1-1.4-0.7C368.1,8.1,367.8,7.9,367.6,7.7z"/>
			<path fill="#0C0C0C" d="M357.5,3.6c0.2,0,0.4,0,0.7,0c0.3,0.1,0.3,0.6,0.7,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.6,0.2,1,0.6,1.7,0.4
				c0.2-0.1,0.3-0.3,0.5-0.4c0.4-0.6,0.4-1.1,0-1.7c-0.1-0.2-0.3-0.2-0.5-0.2c-0.4-0.2-1,0.2-1.2-0.4c-0.3-0.1-0.6-0.2-0.9-0.3
				c0,0.2,0,0.3,0,0.5c0.2,0.5-0.3,0.5-0.5,0.7l0,0C358,3.1,357.3,3,357.5,3.6z"/>
			<path fill="#F1E9DA" d="M433.1,158.1c0.2-0.1,0.5-0.2,0.7-0.2c0.3-0.3,0.3-0.6,0.5-1c-0.2-0.5-0.3-1.1-1-1.2
				c-0.4-0.1,0.1-0.9-0.4-1.2c0,0.2,0,0.3,0,0.5c0,0.4,0.1,0.8-0.3,1.1c-0.1,0.1-0.3,0-0.4-0.1c-0.4-0.4,0.2-0.7,0.1-1.1
				c-0.3,0.1-0.5,0.1-0.8,0.2c-0.3,0.3-0.4,0.6-0.2,1c0.8,0.6,0.8,1.5,0.7,2.4c0.1,0.1,0.2,0.1,0.2,0c0.1,0,0.2,0,0.3,0
				c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.3C433.1,157.7,432.9,158,433.1,158.1z"/>
			<path fill="#0C0C0C" d="M376,15.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.4-0.5-0.3-0.8-0.1c-0.1,0.1-0.2,0.3-0.5,0.2
				c-0.1-0.1-0.2-0.3-0.3-0.5c-0.5-0.4-0.9-0.4-0.9,0.4c0,0.3,0.1,0.5,0,0.8c0,0.1,0,0.2,0,0.3c0.3,0.6,1.2,0.6,1.2,1.4
				c0.4,0.5,0.8,0.7,1.4,0.3c0.3-0.3,0.7-0.6,0.7-1.1C375.6,16.2,376.2,15.4,376,15.1z"/>
			<path fill="#090909" d="M378.3,32.9c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.5,0.2-0.9,0.1-1.1-0.5
				c-1.2-0.5-1.1,0.6-1.3,1.2c0.6,0.6,1.4,0.1,2,0.5c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.2,0.3-0.1,0.5l0,0
				c0.2,0.3,0.5,0.5,0.9,0.6c0.3-0.2,0.4,0.4,0.7,0.2c0.5-0.4,0.5-1,0.9-1.5c0.1-0.1,0.1-0.1,0-0.2
				C378.5,33.4,378.6,33.1,378.3,32.9z"/>
			<path fill="#171716" d="M371.2,10.5c-0.1,0.5-0.6,0.7-0.7,1.2c0.4,0.7,0.8,0.3,1.2,0c0.2,0,0.4,0.1,0.5,0.3
				c0.2,0.2,0.3,0.7,0.7,0.4c0.2,0,0.4-0.1,0.5-0.2c-0.2-0.4,0.4-0.8,0-1.2c-0.3-0.7-0.9-1.2-1.5-1.7c-0.8,0.1-0.7,0.5-0.4,1.1
				c0,0.1,0.1,0.1,0.1,0.2C371.4,10.5,371.3,10.5,371.2,10.5z"/>
			<path fill="#59534D" d="M306.6,92.6c-0.2-0.4-0.1-0.8-0.9-0.9c-0.9-0.1-0.2-1.1-0.3-1.7c-0.6,0-0.7,0.5-0.8,0.9
				c0,0.5-0.1,1-0.4,1.5c0.1,0.4,0.4,0.7,0.7,1c0.5,0.3,1,0.4,1.6,0.1C306.7,93.2,306.8,93,306.6,92.6z"/>
			<path fill="#4F4944" d="M377.9,20.3c0-0.3,0-0.7-0.2-1c-0.6-0.2-1,0.1-1.3,0.5c0.4-0.5,0.8-1.1-0.2-1.3c-0.2,0-0.4,0.2-0.6,0.2
				c-0.2,0-0.4,0-0.6,0c-0.5,0.2-0.5,0.5-0.4,0.9c0.2,0.7,0.9,0.7,1.3,1.1c0.3,0.2,0.4,0.6,0.8,0.7c0.5-0.1,0.6-0.7,1.1-0.9
				C377.8,20.5,377.9,20.4,377.9,20.3z"/>
			<path fill="#4F4A44" d="M376.7,41.8c0.3,0.5,0.5,1.2,1.3,1.2c0.3,0,0.7,0.8,1,0c0.3-0.6-0.3-0.7-0.8-0.8c0.4-0.3,0.8-0.1,1.1-0.2
				c0.2-0.4,0-0.7-0.2-1c0,0.2-0.1,0.4-0.1,0.6c-0.3,0.4-0.4-1.8-0.7,0c-0.1,0.3-0.4,0.2-0.6,0.1c-0.2-0.2-0.5-0.3-0.7-0.1
				C376.7,41.4,376.6,41.5,376.7,41.8z"/>
			<path fill="#504A45" d="M376.9,35c-0.3-0.1-0.6-0.5-1-0.4c-0.1,0.2-0.3,0.5,0,0.7c0.6,0.5,0.6,1.2,0.7,1.9c0.2,0.1,0.3,0.1,0.5,0
				l0,0c0.1-0.2,0.4-0.1,0.5-0.2c0.2,0,0.4-0.3,0.5,0c0,0.1,0.1,0.1,0.2,0.2c0.2-0.8,0.3-1.5,0-2.3c-0.3,0.2-0.6,0.3-1,0.4
				C376.6,36,377.2,35,376.9,35z"/>
			<path fill="#57514C" d="M362.2,4.4c0.5,0.1,1,1.4,1.5-0.1c0.2-0.6,0.8,0.5,1.2,0c-1-0.7-2-1.1-3.2-1.4
				C361.6,3.4,361.9,3.9,362.2,4.4z"/>
			<path fill="#1F1F1F" d="M380.3,44.8c-0.2,0.3-0.5,0.5-0.6,0.8c-0.3,0.5-0.1,0.9,0.4,1.2c0.1,0,1.1,0.1,0.3,0.7
				c0,0,0.1,0.4,0.2,0.5c0.2,0.2,0.4,0,0.4-0.2c0.1-0.3,0.4-0.3,0.7-0.3c-0.2-0.4-0.5-0.8-0.7-1.2c-0.4-0.1-0.3-0.3-0.2-0.5
				C380.7,45.5,380.5,45.1,380.3,44.8z"/>
			<path fill="#504B46" d="M368,8.4c0.2,0.9,0.9,1,1.7,1c0.2-0.2,0.5,0,0.7-0.2c0.2-0.2,0.2-0.6,0.5-0.7c-0.2-0.3-0.4-0.6-0.7-0.7
				c-0.4,0.2-0.8,0.4-1.3,0.3c-0.1,0-0.2,0-0.3,0C368.4,8.1,368.2,8.2,368,8.4z"/>
			<path fill="#524E49" d="M306.8,96.9c0,0.2-0.2,0.7,0.2,0.3c0.5-0.5,0.4-1.2-0.2-1.7c0.1-0.4-0.5-0.5-0.4-0.9
				c-0.2-0.3-0.5-0.6-0.9-0.2c0.5,0.7,0.3,1.6,0.3,2.4C306.2,96.9,306.6,96.6,306.8,96.9z"/>
			<path fill="#5D5750" d="M376.4,16.3c-0.2,0.3-0.5,0.6-0.7,1c-0.1,0.1-0.1,0.3-0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3
				c0.2,0.1,0.3,0.4,0.5,0.6c0.3,0.3,0.6,0.8,1.1,0.3c-0.3-0.9-0.6-1.8-1-2.6C376.6,16.2,376.5,16.2,376.4,16.3z"/>
			<path fill="#FAF7F3" d="M413.4,124.8c-0.9,0.8-1.6,0.1-2.4-0.2c-0.1,0.4-0.1,0.8-0.2,1.3c0.8-0.4,1.4-0.4,1.7,0.7
				c0.4-0.9,0.6-1.6,1.4-1.4C413.8,125,413.6,124.9,413.4,124.8z"/>
			<path fill="#F6F2EA" d="M436.9,161.5c-0.1,0.8-1.1,0.6-1.4,1.4c0.7,0.2,1.3,0.4,1.4,1.3c0.1,0.1,0.2,0.1,0.3,0
				c0-0.4,0.3-0.8,0-1.2l-0.1,0l0.1,0c0.2-0.2,0.3-0.3,0.5-0.5C437.6,162,437.3,161.7,436.9,161.5z"/>
			<path fill="#534E49" d="M378.1,37.4c-0.9,0.9-0.2,1.7,0.2,2.6c0.2,0.4,0.3-0.1,0.5-0.1c0-0.8-0.1-1.7-0.4-2.4
				C378.3,37.3,378.2,37.3,378.1,37.4z"/>
			<path fill="#171614" d="M305.9,98.4c0,0.1,0,0.2,0,0.2l0.1,0c0.3,0.5-0.1,1.7,1.2,1.3c0.4-0.2,0.7-0.4,0.8-0.9
				c0-0.3-0.1-0.6-0.5-0.6c-0.1-0.1-0.1,0.3-0.3,0C306.8,97.9,306.4,97.8,305.9,98.4z"/>
			<path fill="#58534D" d="M377.4,27.8c0.3,0.3,0.5,0.5,0.9,0.5c0.2-0.3,0.1-0.7,0.4-0.9c-0.1-0.6-0.1-1.1-0.2-1.7
				c-0.4-0.3-0.7-0.2-0.8,0.3c-0.1,0.3-0.1,0.7-0.3,1C377.5,27.2,377.3,27.5,377.4,27.8z"/>
			<path fill="#3F3C39" d="M378.6,25.2c0-0.8,0.1-1.5-0.2-2.2c-0.2,0.2-0.2,0.8-0.7,0.5c-0.1,0.1-0.2,0.2-0.3,0.4
				c-0.1,0.5-0.1,1.1,0,1.6c0.1,0.1,0.2,0.1,0.3,0.1C378,25.5,378.3,25.4,378.6,25.2z"/>
			<path fill="#1C1C1B" d="M303.5,84.2c0.2,0,0.4-0.7,0.5,0c0.2,0.1,0.4,0.4,0.7,0.2c0.1-0.4-0.4-0.5-0.5-0.8c0.2-1.2-0.9-0.6-1.3-1
				c-0.3,0.4-0.2,0.8-0.2,1.2c-0.1,0.2-0.1,0.4,0,0.5C303.1,84.6,303.3,84.4,303.5,84.2z"/>
			<path fill="#F4EEE4" d="M440,166.5c-0.5,0-1,0.1-1.3-0.6c-0.3-0.7-0.8,0.2-1.2,0c0.8,0.7,1.5,1.5,2.7,1.1
				C440.3,166.8,440.2,166.6,440,166.5z"/>
			<path fill="#1B1A19" d="M303,89.6c-0.1,0.6,0.3,1.1,0.5,1.6c0.3,0.2,0.6,0,0.9-0.1c0.1-0.1,0.1-0.2,0.2-0.3
				c0.1-0.4,0-0.7-0.3-0.9c-0.4-0.2-0.8-0.3-0.9-0.9c-0.1,0-0.2,0-0.3,0C302.8,89.1,302.8,89.3,303,89.6z"/>
			<path fill="#F1E8DA" d="M411,123.1c0-0.4-0.2-0.9,0.5-1c-0.5-0.3-0.4-1-0.7-1.4c-0.2,0.3-0.3,0-0.5,0c-0.1,0.2-0.1,0.3,0,0.5
				c0.9,0.3,0.3,0.7,0.1,1.1c-0.1,0.3-0.7-0.4-0.6,0.2c0.1,0.5,0.5,0.7,1,0.6C410.8,123.2,410.9,123.2,411,123.1z"/>
			<path fill="#F4EEE4" d="M440.5,167.5c-0.1,0.4-0.1,0.3-0.7,0.4c-0.3,0-0.6,0.3-0.7,0.9c0.8-0.5,1.4,0.6,2.2-0.1
				C441.2,168.2,440.8,167.9,440.5,167.5z"/>
			<path fill="#534D48" d="M302.9,82.8c0.2,0.6,1.3,0.1,1.3,1c0.3-0.1,0.6-0.4,0.8-0.7c0.1-0.3,0.2-0.8-0.5-0.5
				c-0.5,0.3-0.9,0-1.3-0.2C303,82.4,303,82.6,302.9,82.8z"/>
			<path fill="#1A1918" d="M377.6,19c-0.5,0-0.8-0.3-1.2-0.5c-0.3-0.1-0.5-0.2-0.5,0.2c0.3,0.2,1.2,0.2,0.2,0.8
				c-0.4,0.2-0.3,0.3,0.2,0.9c0.2-0.6,0.7-0.9,1.3-1C377.7,19.2,377.6,19.1,377.6,19z"/>
			<path fill="#141414" d="M305.6,94.3c0.4-0.1,0.6,0.1,0.8,0.4c0.1,0.1,0.2,0.1,0.3,0c0.2-0.3,0.2-0.6,0.2-0.9
				c-0.1-0.3-0.1-0.5-0.5-0.5c-0.5,0.1-1,0.2-1.5,0.1C305.1,93.7,305.3,94,305.6,94.3z"/>
			<path fill="#262524" d="M303,89.6c0-0.2,0.1-0.4,0.3-0.5c0.2-0.2,0.3-0.3,0.4-0.6c0-0.5-0.4-0.9-0.4-1.4c0-0.2-0.1-0.3-0.3-0.4
				c-0.2,0-0.3,0-0.5,0C302.5,87.6,302.6,88.6,303,89.6z"/>
			<path fill="#4E4843" d="M379.6,42.7c-0.1,0.5-0.3,0.9-0.8,1.1c-0.3,0.1-0.5,0.3-0.3,0.6c0.1,0.2,0.4,0.3,0.6,0.3
				c0.4,0.1,0.5-0.2,0.5-0.5c0-0.3-0.1-0.6,0.2-0.8C379.8,43.2,379.8,42.9,379.6,42.7z"/>
			<path fill="#1A1919" d="M355.8,0.9c0.4,0.3,0.4,0.9,1.1,1c0.6,0.1,0.9,0,1.1-0.5C357.3,1.2,356.6,0.9,355.8,0.9z"/>
			<path fill="#544F4B" d="M353.6,0.5c0.2,0.1,0.4,0.2,0.5,0.4c0.6,0.8,1.1,0.9,1.7,0C355.1,0.6,354.4,0.5,353.6,0.5z"/>
			<path fill="#21211F" d="M377.4,35.5c0.5,0.1,1,0.4,1-0.5c0-0.4,0-0.8,0-1.1c-0.6,0.2-0.7,0.9-1,1.4
				C377.3,35.3,377.3,35.4,377.4,35.5C377.4,35.5,377.4,35.5,377.4,35.5z"/>
			<path fill="#151515" d="M378.6,25.2c-0.3,0.1-0.6,0.2-1,0.2c-0.3,0.6-1,1.1-0.7,1.9l0,0c0.3,0,0.6,0.3,0.8-0.2
				c0.2-0.3,0.6-0.6,0.4-1.1c-0.1-0.5,0.3-0.3,0.5-0.4C378.6,25.5,378.6,25.3,378.6,25.2z"/>
			<path fill="#1D1D1C" d="M375,14.6c0.2-0.2,0.5-0.1,0.7,0c-0.1-0.5-0.3-0.9-0.7-1.2c-0.1,0-0.1,0-0.2,0c-0.3,0.1-0.5,0.2-0.7,0.4
				c-0.1,0.2,0,0.4-0.2,0.6c0,0.2,0.1,0.4,0.3,0.5C374.6,15,374.8,14.7,375,14.6z"/>
			<path fill="#494440" d="M362.2,4.4c-0.1-0.5-0.3-1-0.4-1.5c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.6,0,1.1,0,1.7
				C361.6,4.3,361.9,4.3,362.2,4.4z"/>
			<path fill="#5E5852" d="M305.9,98.4c0.6-0.4,1,0.1,1.5,0.3c0.1-0.1,0.3-0.2,0.3-0.4c0-0.1,0-0.2-0.1-0.3c-0.6,0-1.1-0.5-1.7-0.3
				C305.6,97.9,305.8,98.1,305.9,98.4z"/>
			<path fill="#534E48" d="M371.2,10.5c0.3,0.2,0.3,0.7,0.7,0.9c0.5-0.7-1.2-1.3,0-2c-0.1-0.3-0.2-0.4-0.4-0.5
				C371.4,9.4,370.5,9.9,371.2,10.5z"/>
			<path fill="#474440" d="M378.3,22.1c-0.1-0.6-0.1-1.2-0.4-1.7c0,0-0.1,0.1-0.1,0.1c-0.9,0.5-0.9,1.1-0.1,1.7
				C377.9,22.3,378.1,22.3,378.3,22.1z"/>
			<path fill="#262624" d="M368.5,8.2c0.1,0,0.1,0,0.2,0c0.4-0.1,0.9-0.3,1-0.8c-0.2-0.3-0.4-0.6-0.8-0.7c-0.5,0.3-0.5,0.7-0.5,1.2
				C368.5,8,368.4,8,368.5,8.2C368.5,8.1,368.5,8.2,368.5,8.2z"/>
			<path fill="#474441" d="M303.5,84.2c-0.3-0.1-0.7,0.4-0.7-0.3c-0.4,0.3-0.1,0.7-0.2,1c0.3,0.1,0.5,0.5,0.8,0.2
				C303.8,85,303.4,84.6,303.5,84.2z"/>
			<path fill="#070707" d="M369.7,7.4c-0.4,0.2-0.8,0.3-1,0.8c0.7,0.3,1.2,0.4,1.5-0.5C370,7.6,369.9,7.5,369.7,7.4z"/>
			<path fill="#030303" d="M304.2,92.4c0.7-0.3,0.9-0.8,0.5-1.4c-0.1-0.1-0.2-0.1-0.2,0l0,0c-0.4,0.2-0.5,0.6-0.6,1
				C304,92.1,304.1,92.2,304.2,92.4z"/>
			<path fill="#FBF9F5" d="M421.6,140.1c0.3,0.6-0.3,0.9-0.2,1.4l0,0c0.5-0.2,1-0.5,1-1.2C422,140.3,421.8,140.2,421.6,140.1z"/>
			<path fill="#5E5A55" d="M359.6,1.9c-0.1,1.6,0.8,0.5,1.2,0.4C360.5,2,360.1,1.9,359.6,1.9z"/>
			<path fill="#5C5A57" d="M376.4,16.3c0.1,0,0.1,0,0.2,0c-0.2-0.4-0.4-0.8-0.6-1.2c-0.1,0.2-0.1,0.5-0.2,0.7
				C375.5,16.5,376,16.4,376.4,16.3z"/>
			<path fill="#2D2B29" d="M378.3,22.1c-0.2,0-0.4,0-0.6,0c-0.2,0.1-0.3,0.2-0.4,0.4c-0.2,0.2-0.4,0.3-0.6,0.5
				c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.3,0.3,0.4c0.1,0,0.2,0,0.3,0c0.2-0.1,0.3-0.2,0.5-0.4c0.1-0.1,0.1-0.1,0.2-0.2
				c0.2-0.2,0.3-0.5,0.5-0.7C378.4,22.2,378.4,22.1,378.3,22.1z"/>
			<path fill="#4B4844" d="M373.3,11.1c0.3,0.4-0.8,0.8,0,1.2c0.2-0.2,0.4-0.4,0.5-0.5C373.8,11.4,373.6,11.2,373.3,11.1z"/>
			<path fill="#2B2A29" d="M367.1,5.7c0.1,0,0.2,0,0.3,0c-0.3-0.5-0.8-0.6-1.2-0.7c0.1,0.2,0.2,0.5,0.2,0.7
				c-0.1,0.3,0.1,0.3,0.4,0.4C366.9,6,367,5.9,367.1,5.7z"/>
			<path fill="#393734" d="M303.9,91.9c0.4-0.2,0.4-0.7,0.6-1c-0.3,0-0.6,0.1-0.9,0.2C303.5,91.5,303.6,91.7,303.9,91.9z"/>
			<path fill="#030303" d="M380.8,45.8c-0.2,0-0.4-0.2-0.5,0.1c-0.1,0.3,0.1,0.5,0.3,0.7c0.3,0.2,0.3-0.1,0.3-0.2
				C381,46.1,381,45.9,380.8,45.8z"/>
			<path fill="#2B2B2B" d="M377.6,23.5c0.7,0.4,0.8,0,0.8-0.6c0-0.1,0-0.1,0-0.2c-0.3-0.2-0.5,0.1-0.7,0.2l0,0
				C377.5,23.2,377.5,23.3,377.6,23.5z"/>
			<path fill="#2B2B29" d="M378.8,27.4c-1-0.1-0.3,0.6-0.4,0.9c0.1,0,0.2,0,0.2,0C378.6,28,378.7,27.7,378.8,27.4z"/>
			<path fill="#030303" d="M306.8,96.9c-0.2-0.6-0.5-0.5-0.9-0.2c0,0.2,0,0.3,0,0.5C306.2,97.1,306.5,97,306.8,96.9z"/>
			<path fill="#4B4641" d="M378.1,37.4c0.1,0,0.2,0,0.3,0c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c-0.2-0.3-0.3-0.4-0.6-0.2
				c-0.2,0-0.4,0.1-0.5,0.3C377.5,37.3,377.8,37.3,378.1,37.4z"/>
			<path fill="#FDFCFA" d="M434.3,156.9c-0.3,0.2-0.4,0.6-0.5,1c0.5,0.1,0.4-0.6,0.7-0.7C434.4,157.1,434.3,157,434.3,156.9z"/>
			<path fill="#F3EDE2" d="M431.1,152.9c0.2-0.1,0.4-0.2,0.3-0.5c-0.1-0.1-0.2-0.2-0.4-0.1C430.8,152.5,431.1,152.7,431.1,152.9
				C431.1,152.9,431.1,152.9,431.1,152.9z"/>
			<path fill="#4C4743" d="M377.6,23c0.2-0.1,0.5-0.2,0.7-0.2c0-0.2,0-0.3,0-0.5C377.9,22.3,377.8,22.7,377.6,23z"/>
			<path fill="#1D1D1C" d="M374.6,12.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.2,0,0.3c0.2,0.2,0.4,0.3,0.7,0.2
				c0,0,0.1-0.1,0.1-0.1C375,13,374.9,12.8,374.6,12.6z"/>
			<path fill="#58514B" d="M378.3,32.9c0,0.2,0.1,0.5,0.1,0.7c0.1-0.5,0.2-0.9,0-1.4C378.4,32.5,378.3,32.7,378.3,32.9z"/>
			<path fill="#060606" d="M324.6,99.2c-0.1-0.3-0.3-0.2-0.4-0.1c-0.4-0.1-0.9-0.3-1.1,0.3c-0.1,0.4-0.5,0.2-0.7,0.3
				c-0.4,0.8-1.4,0.7-1.9,1.3c0.1,0.3,0.4,0.4,0.5,0.6c0.3,0.4,0.6,0.7,1.1,0.8c0.1,0,0-0.2,0.1,0c0,0.1-0.1,0.1-0.1,0
				c-0.4,0-0.6,0.3-0.6,0.7c0.3,0.3-0.2,0.4-0.2,0.7c0,0.4,0.4,0.4,0.5,0.7c0.1,0.1,0.1,0.3,0.2,0.4c0.2,0.2,0.4,0.2,0.6,0
				c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.3,0.1-0.2,0.2,0c0.1,0.4,0.1,0.8,0.2,1.2c0.3,0.6,0.6,1.1,1.4,0.7c1.6-0.5,2.9-1.5,4.3-2.2
				c0.2-0.5,0.9-0.6,1.2-1c-0.6,0.1-0.8-0.3-1-0.7c0.1-0.3-0.1-0.3-0.3-0.3c-0.3,0-0.6-0.1-0.7-0.4c0.2-0.3,0.4-0.7-0.3-0.7
				c-0.4,0-0.7-0.2-0.7-0.7c-0.1-0.3-0.2-0.5,0-0.8c-0.1-0.1-0.3-0.1-0.5,0c-0.3,0.2,0,1-0.8,0.8C324.4,100.9,324.9,99.7,324.6,99.2
				z"/>
			<path fill="#FEFEFE" d="M335,145.9c-1.4,0-2.5,1.2-2.5,2.7c0,1.4,1.2,2.6,2.7,2.6c1.3,0,2.6-1.3,2.6-2.6
				C337.8,147.1,336.5,145.9,335,145.9z"/>
			<path fill="#FEFEFE" d="M334.4,122.7c0,1.5,1.1,2.6,2.6,2.6c1.4,0,2.7-1.2,2.7-2.6c0-1.2-1.4-2.6-2.7-2.6
				C335.5,120,334.4,121.1,334.4,122.7z"/>
			<path fill="#FEFEFE" d="M331.8,176.9c0,1.4,1.1,2.6,2.5,2.6c1.4,0,2.7-1.3,2.7-2.7c0-1.3-1.4-2.6-2.7-2.6
				C332.9,174.2,331.8,175.4,331.8,176.9z"/>
			<path fill="#5D564F" d="M307.8,99.1c-0.2,0.2-0.5,0.5-0.7,0.7c0,0.1,0,0.1,0,0.2c0,0.5,0.4,0.6,0.6,1c0.4,0.6,0.1,0.7-0.5,0.6
				c-0.2,0-0.5,0-0.6,0.2c0.2,1,0.8,1.7,1.4,2.4c0.3,0,0.4-0.3,0.5-0.6c0-0.3-0.2-0.5-0.2-0.8c0-0.2,0.1-0.1,0.1,0
				c-0.2,0.3,0.2,0.6,0,0.9c0.3,0.3,0.4,0.6,0,0.9c0.4,0.4,0.8,0.8,1.3,1.1c0.2-0.1,0.2-0.4,0.5-0.3c0.1,0.7,0.5,0.8,1.1,0.4
				c0.5-0.1,0.5,0.4,0.8,0.5c0.3,0.1,0.6,0.2,0.9,0.2c0.3-0.2,0.4-0.6,0.7-0.8c0.3-0.2,0.5-0.4,0.3-0.8c0-0.1-0.1-0.2-0.2-0.2
				c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.5-0.1-0.7-0.2c-0.1,0-0.2,0-0.3-0.1c-0.5-0.2-0.5-0.7-0.6-1.1c-0.4-0.3,0-0.7-0.1-1
				c-0.2-0.4-0.6-0.6-1-0.6c-0.2,0-0.4,0.1-0.6,0.3c-0.1,0.2-0.2,0.5-0.3,0.8c-0.2-0.3,0.1-0.6-0.1-0.9c0-0.2-0.1-0.4-0.3-0.6
				c-0.7-0.6-0.7-1.4-0.9-2.2C308.5,99,308.2,98.8,307.8,99.1z"/>
			<path fill="#56504A" d="M314.2,107.5c1.1,0.5,2.3,0.4,3.4,0.4c0.2-0.3,0.1-0.5,0.1-0.8c0-0.3,0-0.6-0.2-0.8
				c-0.2-0.2-0.4-0.1-0.6-0.1c-0.9,0.2-0.8-0.3-0.6-0.9c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.1-0.4,0.1-0.6c-0.3-0.6-0.7-0.8-1.2-0.2
				c-0.3,0.4-0.8,0.5-1,0.9c-0.2,0.6,0,1.1,0.4,1.6c0.4,0.6,0,0.7-0.3,0.9C314.3,107.3,314.3,107.4,314.2,107.5z"/>
			<path fill="#F2EBDF" d="M436,169.9c-0.7-0.3-1.2,0-1.5,0.7c-0.2,0.4-0.2,0.9-0.7,1.1c-0.4,0.2-0.2,0.8,0.1,1.1
				c0.2,0.2,0.6,0.4,0.9-0.2c0.2-0.3,0.6-0.1,1-0.1c0.1-0.3,0-0.5-0.2-0.7c0-0.2,0.2-0.4,0.1-0.6c-0.2-0.6-0.8-1.3,0.5-0.9
				c0-0.2,0-0.3,0.1-0.5C436.1,169.8,436,169.8,436,169.9z"/>
			<path fill="#FBF8F5" d="M414.7,131.4c-1,0.5-1.7,0.2-2.4-0.6c-0.2-0.3-0.5-0.2-0.7,0c-0.2,0.3,0.2,0.4,0.3,0.5
				c0.6,0.5,0.7,1.5,1.4,1.9c0.3,0.2,0.4,0.6,0.9,0.4c0.4-0.3,0.4-0.8,0.3-1.1C414.2,132.2,414.4,131.8,414.7,131.4z"/>
			<path fill="#F7F2EB" d="M418,147.9c0.2,0.5,0.6,0.5,0.7,0.9c0.1,0.6,0.4,0.9,1.1,0.4c0.5-0.4,1.1,0,1.2,0.7
				c0.1,0.4,0.1,0.8,0.6,0.9c0,0,0.1-0.2,0.2-0.3c-0.5-0.4,0.5-1-0.3-1.5c-0.7-0.4-1.5-0.5-2-1.3C419.2,147.3,418.6,147.8,418,147.9
				z"/>
			<path fill="#F8F4EE" d="M403.1,127.9c1,0,1.9,0.3,2.8,0.5c0.5,0.1,0.6,0.6,0.3,0.7c-1,0.2-0.2,0.6-0.1,0.7
				c0.4,0.4,0.6,0.2,0.7-0.3c0-0.4,0.3-0.2,0.5-0.2c0.4,0,0.3-0.3,0.2-0.4c-0.6-0.5-1.1-1.2-2.1-1.1c-0.5,0-0.8-0.2-1-0.5
				c-0.6-0.7-1,0.3-1.5,0C402.9,127.5,403,127.7,403.1,127.9L403.1,127.9z"/>
			<path fill="#F6F1E8" d="M420.1,157.5c0,0.1,0,0.4,0.1,0.4c0.4,0.2-0.1,0.9,0.4,0.8c0.4,0,0.9-0.4,1.2-0.7
				c0.3-0.4-0.1-0.6-0.4-0.6c-0.5-0.1-0.9-0.4-1.1-0.9c-0.2-0.3-0.5-0.6-0.9-0.6c-0.1,0-0.3,0.1-0.3,0.3c0.1,0.5,0.7,0.2,1,0.5
				c-0.3,0.6-0.8,0.5-1.3,0.3C419.1,158.1,419.1,158.1,420.1,157.5z"/>
			<path fill="#262524" d="M324.4,106.6c-0.8,0-1-0.6-1.2-1.2c-0.1-0.2,0-0.4,0-0.6c-0.1-0.3-0.1-0.8-0.6-0.8
				c-0.5,0.1,0.1,0.6-0.2,0.9c0,0,0.1,0.1,0.1,0.1c0.3,0.3,0.1,0.5-0.2,0.6c-0.4,0-0.9,0.3-1.3,0c-0.1,0-0.2,0-0.3,0
				c-0.2,0.1-0.4,0.2-0.5,0.4c0,0.8,0.7,0.9,1.1,1.3c0.2,0.1,0.4,0.2,0.6,0.1l0,0c0.4-0.6,1.1-0.3,1.7-0.5
				C323.9,106.8,324.2,106.8,324.4,106.6z"/>
			<path fill="#F9F5F0" d="M430.2,156c-1,1-1.2,2.2-1.5,3.5c-0.1,0.3,0.2,0.4,0.4,0.5c0.4,0.1,0.3-0.2,0.4-0.4
				c0.2-0.3-0.2-0.9,0.4-0.9c-0.8-1.2,1-1.5,1-2.5c0-0.1,0-0.2,0-0.2C430.7,155.8,430.4,155.8,430.2,156z"/>
			<path fill="#F5EFE5" d="M424.9,162.2c-0.4,0-0.8,0-0.9,0.4c-0.1,0.4,0.4,0.4,0.6,0.6c0.6,0.3,1,0.8,1.5,1.1
				c0.7,0.4,1.2,0.6,1.6-0.3c0.1-0.2,0.4-0.2,0-0.4c-0.2-0.1-0.4-0.4-0.5,0c-0.2,0.7-0.5,0.4-0.9,0.2
				C425.8,163.3,425.3,162.9,424.9,162.2L424.9,162.2z"/>
			<path fill="#F0E8D8" d="M434.3,167.8c0-0.2,0-0.5,0-0.7c0.8-1-0.1-1.3-0.7-1.7c-0.2,0.2-0.5,0.3-0.6,0.5c-0.7,1-0.5,1.7,0.8,2.1
				C434,168,433.8,167.2,434.3,167.8z"/>
			<path fill="#FBF9F5" d="M423,142.3c0,0-0.1,0-0.1,0c-0.7-0.4,0.8-0.7,0-1c-0.5-0.2-0.7,0.6-1.2,0.7c-0.2,0-0.3-0.2-0.4-0.3
				c0,0,0,0,0,0c-0.4,0.2-1-0.1-1,0.8c0,0.8,1.1,0.2,1,1c0.3-0.4,0.6-0.8,1.2-0.8C422.8,142.7,423,142.7,423,142.3z"/>
			<path fill="#FDFCFA" d="M423,142.3c-0.1,0.2-0.3,0.2-0.5,0.2c-0.1,0.6-0.1,0.6,0.4,0.7c0.2,0,0.4,0.2,0.6,0.4
				c0.2,0.1,0.2,0.8,0.6,0.5c0.3-0.3,0.4-0.8,0.6-1.3C424.8,142.4,423.5,142,423,142.3z"/>
			<path fill="#F9F5F0" d="M418.4,142.5c-0.5-0.7-1.1-1.3-2-0.5c-0.1,0.1-0.4,0.1-0.6,0.1c-0.3,0-0.8-0.2-0.7,0.3
				c0.1,0.5,0.9,0.7,1,0.5c0.7-0.8,1.2-0.3,1.7,0.2c0.1,0.1,0.4-0.1,0.5-0.1C418.6,142.9,418.9,142.7,418.4,142.5z"/>
			<path fill="#FCFBF9" d="M409.3,127.6c0,0.8,0.9,1.8,1.6,1.8c0.4,0,0.6-0.1,0.4-0.5c-0.3-0.6-0.7-1.1-1.1-1.7
				c-0.1-0.2-0.3-0.4-0.6-0.3C409.3,127.1,409.3,127.4,409.3,127.6z"/>
			<path fill="#F8F4ED" d="M433.6,162.6c0.1,0.8,0.1,1.5-0.3,2.5c0.7-0.6,1.2-1.3,2-0.7c0.2,0.1,0.7-0.5,0.6-0.7
				c-0.2-0.4-0.9-0.8-1.1-0.6C434,163.7,434.1,162.7,433.6,162.6z"/>
			<path fill="#F7F3ED" d="M409.8,144.9c0.7-0.4,1.2-0.7,1.9,0.2c0.2,0.3,1.1,0.3,1.6,0.3c0.1,0,0.5-0.2,0.3-0.7
				c-0.2-0.4-0.2-0.4-0.7-0.3c-0.5,0.2-1.1,0.3-1.7-0.2C410.5,143.6,410,143.9,409.8,144.9L409.8,144.9z"/>
			<path fill="#F8F4EE" d="M414.4,144.7c0.2,0.3,0.4,0.3,0.6,0.2c0.2-0.1,0.3,0,0.5,0.2c-0.4,0.3-0.8,0.5-1.1,0.9
				c-0.3,0.4,0,0.6,0.2,0.6c0.5-0.3,1.2,0.1,1.4-0.5c0.2-0.5,0.3-1.2-0.2-1.6C415.3,144,414.8,144.4,414.4,144.7z"/>
			<path fill="#FBF8F5" d="M436.2,165.3c-0.5,0.7-0.9,1.3,0.2,1.8c0.2,0.1,0.2,0.3,0.1,0.5c-0.2,0.4,0.2,0.6,0.4,0.6
				c0.2,0.1,0.6,0,0.7-0.3c0.1-0.3,0-0.5-0.2-0.7c-0.3-0.6-1.4-0.8-0.9-1.7C436.5,165.4,436.4,165.3,436.2,165.3z"/>
			<path fill="#F5EFE5" d="M428.7,155c-0.5,0.3-0.7,0.1-0.6-0.4c0.1-0.6-0.3-0.4-0.6-0.3l0,0c0,0.2-0.1,0.6-0.3,0.6
				c-0.8-0.2-1.3,0.4-1.9,0.6c0.3,0.3,0.5,0.3,0.8,0c0.3-0.3,0.9-0.3,1-0.2c0.4,1,1.2,0.1,1.8,0.4c0.2,0.1,0.3,0,0.1-0.7
				C428.9,154.9,428.8,154.9,428.7,155z"/>
			<path fill="#F8F4EE" d="M402.3,121.9c0.4,0.5,0.8,1.1,1.3,1.4c0.3,0.2,0.5-0.3,0.4-0.7c-0.1-0.3-0.3-0.7-0.4-1
				c-0.1-0.3,0.2-1-0.4-0.9C402.7,120.7,402.8,121.5,402.3,121.9z"/>
			<path fill="#F3ECE0" d="M431.4,168c0,0.4-0.3,1-0.1,1c0.6,0,0.9,0.6,1.5,0.6c0.2,0,0.5-0.2,0.4-0.3c-0.5-0.3,0.3-0.7-0.2-1.1
				c-0.5-0.3-1-0.2-1.5-0.4C431.3,167.8,431.3,167.9,431.4,168C431.3,168,431.4,168,431.4,168z"/>
			<path fill="#F9F5EF" d="M396.4,129.1c0-0.8,0-0.8,0.8-1.4c-0.5-0.5-1.3-0.4-1.7-0.9c-0.2-0.2-0.3-0.1-0.5,0.1
				c-0.1,0.1-0.3,0.3-0.2,0.3C395.9,127.4,395.6,128.6,396.4,129.1z"/>
			<path fill="#FAF7F3" d="M423.5,145.4c-0.3,0-0.7,0.1-0.5,0.6c0.2,0.6,0.6,0.9,1.3,0.9c0.4,0,0.8,0.3,0.9-0.2
				c0.1-0.4-0.1-1-0.7-0.9C423.9,145.8,423.7,145.8,423.5,145.4L423.5,145.4z"/>
			<path fill="#57514B" d="M313.1,106.5c-0.3-0.2-0.6-0.3-0.9-0.2c-0.5-0.1-0.6-0.6-1-0.7c-1.1,1.1-0.3-0.6-0.7-0.5
				c-0.4,0.1-0.5,0.4-0.5,0.8c0.7,0.6,1.5,1.1,2.5,1.1c0.1-0.1,0.2-0.1,0.4-0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0.1,0.2,0.3,0.2,0.5,0.2
				c0.5,0.2,0.7,0,0.7-0.5C313.6,106.5,313.4,106.3,313.1,106.5z"/>
			<path fill="#FEFEFE" d="M421.5,139.9c-0.3-0.3-0.4-0.6-0.3-1c0-0.3-0.2-0.7-0.6-0.7c-0.6,0-0.2,0.5-0.3,0.7
				c-0.2,0.4-0.4,0.8-0.7,1.3C420.4,140.6,420.9,139.8,421.5,139.9C421.5,139.9,421.5,139.9,421.5,139.9z"/>
			<path fill="#F7F3EC" d="M421.8,167.5c0.3-0.5,0.3-1-0.3-1.2c-0.3-0.1-0.5-0.5-0.8-0.1c-0.4,0.4-0.4,0.9-0.2,1.3
				C420.8,168.2,421.4,167.2,421.8,167.5L421.8,167.5z"/>
			<path fill="#F5EFE5" d="M424.9,162.2c0.1,0,0.3,0,0.3-0.1c0.2-0.3,0.3-0.7,0.8-0.6c0.6,0.1-0.1,0.7,0.5,0.9
				c0.1-0.8,1.2-0.3,1.6-1.2c-0.7,0.2-1.3,0.2-2,0.1C424.9,160.9,424.8,161,424.9,162.2C424.9,162.2,424.9,162.2,424.9,162.2z"/>
			<path fill="#5A544D" d="M306.6,101.7c0.2,0,0.5,0,0.7,0c0.3-0.7-0.3-1.3-0.2-1.9c0,0,0,0,0,0c-1,0.2-0.6-0.9-1.2-1.2
				C305.8,99.7,306.1,100.8,306.6,101.7z"/>
			<path fill="#FAF8F3" d="M421.6,159.5c0.3,0.3,0.6,0.9,1,1.2c0.2,0.1,0.6-0.3,0.6-0.6c-0.1-0.6-0.4-1.1-1.1-1.3
				C421.8,158.7,421.6,159,421.6,159.5z"/>
			<path fill="#F6F1E9" d="M430.1,173.9c-0.2-0.9-1-1.3-0.6-2.1c0.1-0.3-0.3-0.4-0.6-0.3c-0.3,0.1-0.8-0.3-0.7,0.4
				c0,0.2-0.2,0.6,0.4,0.4c0.6-0.1,0.1,0.5,0.3,0.6C429.2,173.3,429.5,173.5,430.1,173.9z"/>
			<path fill="#F4EDE3" d="M429.2,177.4c0.4,1.4-0.9,1.8-1.4,2.6c-0.2,0.3-0.4,0.6,0,0.8c0.4,0.2,0.4-0.2,0.5-0.5
				c0.1-0.7,1-1,1.2-1.7C429.6,178.2,429.8,177.9,429.2,177.4z"/>
			<path fill="#FAF7F3" d="M433.5,165.3c0,0.6,0.6,0.6,0.9,0.9c0.3,0.4-0.8,0.4-0.1,0.8c0.4,0,1,0.2,1.1-0.4c0.1-0.5-0.1-1-0.8-1.1
				C434.2,165.5,433.9,165.1,433.5,165.3z"/>
			<path fill="#F9F6F1" d="M407.1,141.6c-0.4,0.3-0.9,0.7-1.2,1.2c-0.1,0.2,0.3,0.5,0.5,0.4c0.5-0.3,1.2-0.4,1.4-1.1
				C407.8,141.9,407.6,141.6,407.1,141.6z"/>
			<path fill="#FCFAF8" d="M410.2,140.1c-0.3-0.2-0.7,0-0.9-0.4c-0.1-0.3-0.3-0.7-0.7-0.3c-0.5,0.6-0.1,1.1,0.3,1.5
				c0.5,0.5,0.5,0,0.6-0.3C409.6,140.4,410.1,140.5,410.2,140.1z"/>
			<path fill="#F8F5EF" d="M428.2,162.6c0.1,0.3-0.4,0.7,0.2,0.8c0.3,0.1,0.4-0.3,0.5-0.5c0-0.1,0-0.2,0-0.2c0.4-0.2,0.7-0.5,0.5-1
				c-0.2-0.5-0.6,0-0.8,0C428,161.7,428,162.2,428.2,162.6z"/>
			<path fill="#FBF9F6" d="M408,123.8c0.8-0.3,0.8-0.8,0.9-1.2c0.1-0.3,0-0.6-0.4-0.8c-0.3-0.1-0.7-0.2-1,0.2
				c-0.3,0.4,0,0.6,0.3,0.7C408.3,123,408.3,123.3,408,123.8z"/>
			<path fill="#FDFDFC" d="M430.2,156c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0,0.2,0c0.2-0.3,0.3-0.6,0.2-1c-0.1-0.4-0.5-0.8-0.7-0.4
				C430.4,154.9,429.3,155.2,430.2,156z"/>
			<path fill="#FCFAF8" d="M407.5,120.5c-0.7-0.4-0.9-0.8-0.9-1.4c0-0.3-0.1-0.6-0.5-0.5c-0.3,0.1-0.4,0.3-0.4,0.6
				C405.7,120.3,406.3,120.5,407.5,120.5z"/>
			<path fill="#F9F5F0" d="M424.7,158.4c0.6,0.6-0.3,0.8-0.4,1.2c0.4,0.2,1.1-1,1.4,0.3c0,0.2,0.5-0.4,0.9-0.3l0,0
				c-0.6-0.5-0.9-1.2-1.7-1.4C424.7,158,424.6,158.2,424.7,158.4z"/>
			<path fill="#F5EFE5" d="M422.5,163.2c0.1,0.3,0,0.9,0.4,0.9c0.3,0,0.8-0.2,0.8-0.8c0-0.4-0.1-0.9-0.6-0.9
				C422.7,162.3,422.6,162.8,422.5,163.2z"/>
			<path fill="#1D1D1B" d="M317.4,107.3c0.1,0.2,0.2,0.5,0.2,0.7c0.6-0.1,1.3,0.1,1.9-0.2c0.2-0.2,0.4-0.4,0.3-0.8
				c-0.7,0.5-0.6-0.4-0.9-0.5c-0.2-0.1-0.3-0.1-0.5,0C318.2,106.8,317.9,107.2,317.4,107.3z"/>
			<path fill="#FBF9F6" d="M411.8,141.3c0.6,0.1,0.8-0.3,0.8-0.7c0.1-0.4-0.2-0.7-0.6-0.8c-0.6,0-0.8,0.5-0.9,0.9
				C411.1,141.3,411.5,141.2,411.8,141.3z"/>
			<path fill="#F7F3EC" d="M421.6,168.8c0.4,0.4,0.8,0.7,1.2,1.1c0.2-0.5,0.3-1,0.7-1.4c0.2-0.2,0.3-0.3,0.1-0.5
				c-0.2-0.1-0.4-0.3-0.5,0C422.8,168.8,422.1,168.4,421.6,168.8z"/>
			<path fill="#F8F4EE" d="M432.5,161.5c-0.3,0-0.7,0.3-0.6,0.4c0.1,0.6-0.4,1-0.2,1.5c0,0.2,0.3,0.2,0.4,0.1
				c0.3-0.6,0.7-1.1,0.9-1.7C433,161.7,432.7,161.5,432.5,161.5z"/>
			<path fill="#FBF9F5" d="M418,137.8c-0.6,0.8-0.8,1.3-0.1,1.6c0.7,0.3,1-0.6,1.6-0.5c0-0.1,0-0.2,0-0.2c-0.1-0.3-0.3-0.2-0.5-0.1
				C418.5,138.8,418.1,139,418,137.8z"/>
			<path fill="#F7F2EB" d="M431.4,168c0-0.1,0-0.2,0-0.2c0-0.8-1.4-0.7-1.1-1.7c0,0-0.1-0.1-0.2-0.1c-0.2,0-0.3,0.1-0.3,0.3
				c0.1,0.4-0.1,0.6-0.4,0.8l0,0C430.2,167.2,430.5,168.2,431.4,168z"/>
			<path fill="#F5F0E6" d="M424.9,169.4c0.4,1.1,0.6,1.9-0.8,2.2c0.9,0.2,1.5-0.1,1.7-0.6C426.2,170.3,425.7,169.9,424.9,169.4z"/>
			<path fill="#F8F4EE" d="M410,131.6c-0.3-0.3-0.8-0.3-0.9-0.8c0-0.3-0.6-0.2-0.8,0c-0.2,0.2-0.5,0.5-0.5,0.7
				c0.1,0.4,0.4,0.1,0.7,0.1C409,131.5,409.4,131.6,410,131.6z"/>
			<path fill="#FBF9F6" d="M421.2,146c0,0.4,0.3,0.5,0.5,0.6c0.7,0.2,0.6-0.5,0.7-0.8c0.1-0.3,0-0.7-0.3-0.6
				C421.6,145.1,421.5,145.7,421.2,146z"/>
			<path fill="#F4EEE3" d="M418.4,142.5c0.3,0.2,0,0.3,0,0.5c0.5,0,1.1-0.1,1.2-0.5c0.1-0.5-0.7-0.8-0.8-1.4c0,0-0.3-0.1-0.4,0
				c-0.2,0.1,0,0.3,0.1,0.4C419,141.9,418.6,142.2,418.4,142.5z"/>
			<path fill="#F5F0E7" d="M414.5,153.9c0.1-0.1,0.2-0.2,0.2-0.2c-0.5-0.5-0.3-1.2-0.5-1.8c0-0.1-0.3-0.2-0.4-0.1
				c-0.2,0.3-0.6,0.7-0.5,0.9C413.7,153.2,414.1,153.5,414.5,153.9z"/>
			<path fill="#F5F0E7" d="M401,120.3c-0.4,0-0.7,0.1-0.5,0.4c0.3,0.4,0.4,1.1,0.9,1.1c0.5,0.1,0.4-0.7,0.5-1
				C402.1,120.2,401.1,120.7,401,120.3z"/>
			<path fill="#FDFCFA" d="M436.2,165.3c0.1,0.1,0.2,0.2,0.2,0.2c0.8,0.1,0.9-0.1,0.7-1.4c-0.1,0-0.2,0-0.3,0
				C436.4,164.4,435.9,164.6,436.2,165.3z"/>
			<path fill="#FCFAF8" d="M428.7,155c0.1,0,0.2,0,0.2,0c1.1-1,1-1.1-0.7-1.7l0,0C428.3,153.9,429,154.4,428.7,155z"/>
			<path fill="#F4EEE3" d="M424.7,158.4c0.1-0.1,0.2-0.2,0.2-0.2c-0.4-0.3-0.4-0.9-0.8-1.2c-0.2-0.1-0.3-0.1-0.5,0
				c-0.2,0.1-0.2,0.5,0,0.5C424.1,157.5,423.6,158.9,424.7,158.4z"/>
			<path fill="#F4EEE4" d="M434.4,160.7c-0.2,0.5,0.1,0.7,0.4,0.7c0.3,0,0.8-0.3,0.7-0.6c0-0.3-0.2-0.8-0.8-0.7
				C434.3,160.1,434.2,160.3,434.4,160.7z"/>
			<path fill="#FAF7F3" d="M396,124.5c0.5-0.3,1.3-0.2,1.2-0.9c-0.2-0.8-0.8-0.7-1.2-0.2C395.9,123.5,396.5,124,396,124.5z"/>
			<path fill="#F4EFE5" d="M404.5,116.9c-0.4-0.2-0.6-0.3-0.9-0.5c-0.3-0.2-0.7-0.4-0.9,0.1c-0.3,0.7,0.5,0.3,0.5,0.4
				C403.7,117.8,404.1,117.2,404.5,116.9z"/>
			<path fill="#F5F0E7" d="M412.3,157.6c0-0.5,0.5-1,0-1.2c-0.2-0.1-0.8,0-1.1,0.2c-0.2,0.1-0.3,0.3-0.2,0.5
				c0.1,0.2,0.3,0.2,0.5,0.1C412,156.9,411.9,157.5,412.3,157.6z"/>
			<path fill="#FBF9F6" d="M404.5,124.1c-0.3,0-0.7,0-1,0c-0.4-0.1-0.7-0.1-0.9,0.3c-0.1,0.2,0,0.3,0.1,0.4c0.3,0.2,0.7,0.2,1,0.1
				c0.3-0.1,0.5-0.4,0.9-0.4C404.6,124.3,404.6,124.2,404.5,124.1z"/>
			<path fill="#403D39" d="M319.8,107c-0.1,0.2-0.1,0.5-0.2,0.7c0.8,0.1,1.7-0.1,2.4-0.4c-0.2-0.1-0.3-0.2-0.5-0.3
				C320.9,107,320.3,106.5,319.8,107z"/>
			<path fill="#F8F5EF" d="M426,152.8c-0.5,0.5-1.3,0.4-1.9,0.7C425.5,154.2,425.5,154.2,426,152.8z"/>
			<path fill="#FAF8F4" d="M414.3,143.6c-0.2-0.5,0.6-1-0.1-1.4c-0.3-0.1-0.7,0-0.8,0.3C413.1,143.3,414,143.2,414.3,143.6z"/>
			<path fill="#F2EBDE" d="M438.2,186.6c0.4,1.2,1.1,0.4,1.7,0.4c0.1,0,0.1-0.3,0.1-0.4c-0.1-0.3-0.3-0.4-0.7-0.3
				C438.9,186.5,438.8,187.2,438.2,186.6z"/>
			<path fill="#F3ECE0" d="M436.7,170.6c0.2,0.2,0.3,0.5,0.3,0.5c0.5-0.2,0.7-0.7,1-1.2c0.1-0.2-0.2-0.3-0.4-0.3
				C436.8,169.6,437.2,170.5,436.7,170.6z"/>
			<path fill="#F4EEE3" d="M417.8,167c0.2,0.3,0.4,0.6,0.7,0.7c0.3,0,0.5-0.3,0.5-0.6c0-0.4-0.3-0.7-0.7-0.7
				C418,166.5,417.8,166.7,417.8,167z"/>
			<path fill="#5D564F" d="M313.5,106.8c0,0.4-0.2,0.5-0.5,0.5c0.4,0.3,0.8,0.2,1.2,0.3c0,0,0.1-0.1,0.1-0.1c0.3-0.5,0.7-1,0.1-1.5
				c-0.2-0.1-0.5-0.1-0.7,0C313.2,106.1,313.7,106.5,313.5,106.8z"/>
			<path fill="#F5F0E7" d="M422.5,174.2c-0.2,0.4-0.4,0.5-0.7,0.1c-0.2-0.2-0.4-0.5-0.6-0.2c-0.2,0.3-0.1,0.7,0.2,1
				c0.3,0.2,0.6-0.2,0.9-0.3C422.6,174.8,422.9,174.6,422.5,174.2L422.5,174.2z"/>
			<path fill="#F5F0E7" d="M398.9,112.3c0.2,0.5,0.1,1.1,0.8,1.2c0-0.3,0.6,0.2,0.5-0.4C400.1,112.4,399.7,112.2,398.9,112.3z"/>
			<path fill="#F7F2EB" d="M408.6,126.2c0.6,0.1,1.1,0,1.3-0.5c0.2-0.4-0.3-0.5-0.6-0.6c-0.3-0.1-0.8-0.2-0.3,0.4
				C409.3,125.9,408.5,125.7,408.6,126.2z"/>
			<path fill="#F7F3EB" d="M413.5,128.2c0.1-0.3-0.1-0.4-0.2-0.6c-0.2-0.3-0.4-0.8-0.9-0.7c-0.1,0-0.2,0.2-0.1,0.2
				c0.4,0.3,0.2,1.1,0.9,1.1C413.2,128.2,413.3,128.4,413.5,128.2z"/>
			<path fill="#F5EFE6" d="M426.6,156.7c0.3,0.7,0.9,0.7,1.4,0.9c0.3,0.1,0.5,0,0.5-0.3c0-0.3-0.1-0.5-0.4-0.4
				C427.6,157,427.2,156.6,426.6,156.7z"/>
			<path fill="#F2EBDE" d="M404.2,141.2c0.4-0.2,1.1-0.4,1-0.9c-0.1-0.4-0.7-0.5-0.8-1C403.9,139.9,404.8,140.6,404.2,141.2z"/>
			<path fill="#F4EEE4" d="M420.6,154.8c-0.4,1.5,1,0.9,1.6,1.6C422,155.1,421,155.3,420.6,154.8L420.6,154.8z"/>
			<path fill="#F6F2E9" d="M428.4,150.8c0.5,0.4-0.3,1.3,0.6,1.5c0.2,0,0.4-0.2,0.3-0.4c-0.3-0.4,0-1.2-0.7-1.4
				C428.4,150.5,428.3,150.7,428.4,150.8z"/>
			<path fill="#F9F5F0" d="M426.6,159.6c0.1,0.3-0.2,0.7,0.2,0.8c0.3,0.1,0.6-0.3,0.7-0.5c0.3-0.4-0.2-0.5-0.4-0.7
				C426.4,158.4,427,159.8,426.6,159.6C426.6,159.6,426.6,159.6,426.6,159.6z"/>
			<path fill="#F6F2EA" d="M400.2,132.4c-0.2-0.2-0.6-0.3-0.5-0.7c0-0.3-0.1-0.6-0.5-0.5c-0.3,0.1-0.3,0.5-0.2,0.6
				c0.3,0.2-0.4,0.8,0.3,0.8C399.7,132.5,399.9,132.4,400.2,132.4z"/>
			<path fill="#FAF7F3" d="M403.1,127.9c-0.3,0.2-0.7,0.1-0.9,0.6c0.5,0.2,0.9,0.9,1.3,0.6C403.9,128.7,403.2,128.3,403.1,127.9
				C403.1,127.9,403.1,127.9,403.1,127.9z"/>
			<path fill="#F7F3EB" d="M408.2,133.8c0.7,0,1-0.7,1.7-0.6c0.2,0,0.1-0.3,0.1-0.5c0-0.2-0.2-0.3-0.4-0.1
				C409.2,133,408.4,133,408.2,133.8z"/>
			<path fill="#F4EEE4" d="M397.6,126.3c1.2,0.3,1.6,0.1,1.9-1.3C398.9,125.6,398.5,126.4,397.6,126.3z"/>
			<path fill="#FCFBF9" d="M411.9,137.3c0.8-0.9,0.8-0.9-0.3-1.7C411.3,136.3,412.2,136.7,411.9,137.3z"/>
			<path fill="#FAF7F3" d="M434.3,167.8c-0.1-0.1-0.3-0.3-0.4-0.3c-0.2,0.1-0.1,0.3-0.1,0.5c0.1,0,0.1,0.1,0.2,0.2
				c0.3,0.3,0.3,0.8,0.8,0.9c0.1,0,0.2-0.1,0.2-0.2C435.2,168.1,434.6,168,434.3,167.8z"/>
			<path fill="#F5F0E8" d="M416.3,133.6c0.2-0.1,0.6-0.1,0.5-0.4c-0.1-0.4-0.5-0.6-0.9-0.6c-0.2,0-0.5,0.2-0.4,0.5
				C415.5,133.5,416,133.5,416.3,133.6z"/>
			<path fill="#F8F4EE" d="M418.6,145c0.9,0,1,0.5,0.8,1.3c0.7-0.4,0.8-0.7,0.6-1.2C419.6,144.4,419.1,145.1,418.6,145z"/>
			<path fill="#F4EEE3" d="M423.5,173.1c0.2-0.2,0.7,0.2,0.6-0.2c-0.1-0.6-0.7-0.5-1.1-0.6c-0.2-0.1-0.4,0.1-0.3,0.3
				C422.8,173,423,173.3,423.5,173.1z"/>
			<path fill="#FAF7F3" d="M420.1,172.2c0.3-0.6,0.7-1.3-0.3-1.7C419.6,171.1,419.9,171.6,420.1,172.2z"/>
			<path fill="#F7F3EC" d="M411.5,154.2c0.3,0.5,0.4,0.8,0.6,1.1c0.1,0.3,0.2,0.6,0.6,0.6c0.2,0,0.3-0.2,0.3-0.3
				c-0.4-0.2,0-0.8-0.5-0.9C412.2,154.7,412,154.5,411.5,154.2z"/>
			<path fill="#F4EEE3" d="M424.4,151.9c-0.9,0.2-1.6-0.6-2.7-0.5c0.9,0.7,1.8,0.8,2.7,1C424.6,152.2,424.6,152.1,424.4,151.9z"/>
			<path fill="#F2EADD" d="M415.8,169.9c1.2-1-0.4-0.8-0.4-1.6C414.9,169.3,415.2,169.7,415.8,169.9L415.8,169.9z"/>
			<path fill="#F1EADD" d="M401.6,140.1c0,0.7,0.3,1.1,0.8,1.3c0.1,0.1,0.4-0.2,0.4-0.4C402.8,140.3,402.2,140.3,401.6,140.1z"/>
			<path fill="#F5EFE5" d="M406.2,125.5c0.3,0.3,0.7-0.1,1,0.2c0.1,0.2,0.2,0.5,0.5,0.2c0.1-0.1,0.2-0.3,0.1-0.4
				c0-0.3-0.2-0.4-0.5-0.4C406.9,125,406.5,125.2,406.2,125.5L406.2,125.5z"/>
			<path fill="#F6F1E8" d="M429.9,169.4c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.3-0.3-0.5-0.7-0.3c-0.3,0.1-0.2,0.3-0.2,0.6
				c0.1,0.4,0.3,0.5,0.7,0.4C429.5,169.4,429.8,169.4,429.9,169.4L429.9,169.4z"/>
			<path fill="#F9F6F1" d="M385.8,105.2c0.4,0.6,0.7,1,1.3,0.9c0.2,0,0.1-0.2,0.1-0.4C387,105,386.5,105.1,385.8,105.2z"/>
			<path fill="#F4EEE3" d="M406.8,144.1c0,0.9-0.9,1-1.3,1.7C406.9,145.4,407.1,145.1,406.8,144.1z"/>
			<path fill="#F9F7F2" d="M413.7,138.6c-0.5,0-1.1-0.4-1.5,0.1c-0.1,0.1,0,0.4,0.1,0.4C412.8,139.3,413.2,138.9,413.7,138.6z"/>
			<path fill="#FDFCFC" d="M410.3,137.7c0,0,0,0.1,0,0.1c0.3,0.3-0.3,1,0.4,1.2c0.2,0,0.7,0,0.7-0.3
				C411.3,138.1,410.7,138,410.3,137.7L410.3,137.7z"/>
			<path fill="#F2EBDE" d="M393.6,118.7c-0.5,0-0.9-0.4-1.4-0.1c-0.1,0.1-0.1,0.3,0.1,0.4C392.8,119.5,393.2,119.2,393.6,118.7z"/>
			<path fill="#F7F3EC" d="M418.2,161.5c0,0.3,0.1,0.4,0.4,0.3c0.4-0.1,0.6-0.3,0.5-0.7c0-0.2-0.1-0.5-0.3-0.4
				C418.4,160.7,418.4,161.2,418.2,161.5z"/>
			<path fill="#FAF7F3" d="M428.5,167.3c-0.3-0.1-0.6-0.2-1.1-0.4c0.5,0.5,0.1,1.4,0.9,1.3C429.1,168.1,428.4,167.6,428.5,167.3
				L428.5,167.3z"/>
			<path fill="#F7F2EB" d="M433.4,171.8c-1-0.8-1.3-0.7-1.6,0.5C432.3,171.9,432.8,171.8,433.4,171.8z"/>
			<path fill="#FAF8F5" d="M433.1,158.1c0.1-0.3,0.6-0.5,0.2-0.9c-0.1-0.1-0.3-0.2-0.4-0.2c-0.4,0.1-0.3,0.5-0.4,0.8
				c-0.1,0.2-0.1,0.4-0.1,0.6C432.6,158.3,432.8,158.2,433.1,158.1z"/>
			<path fill="#F3ECE1" d="M400.7,109.9c-0.1-0.3-0.2-0.5-0.2-0.8c-0.1-0.3,0-0.6-0.4-0.4c-0.3,0.2-0.7,0.3-0.8,0.7
				c0,0.1,0.1,0.2,0.2,0.3c0.3,0.2,0.3-0.1,0.4-0.3c0.1-0.1,0.1-0.4,0.4-0.2c0.1,0.1,0,0.3,0.1,0.5
				C400.3,109.9,400.4,110,400.7,109.9z"/>
			<path fill="#F6F1E9" d="M402.9,118.3c-0.3,0.7-1,0.7-0.8,1.4c0,0.1,0.3,0.2,0.4,0.2C403,119.6,403,119.2,402.9,118.3z"/>
			<path fill="#F7F3EC" d="M389.4,124.5c0.1,0.3-0.4,0.7,0,0.8c0.4,0.2,0.8-0.1,1.2-0.3C390.2,124.6,389.8,124.6,389.4,124.5
				L389.4,124.5z"/>
			<path fill="#F9F6F1" d="M424.1,150.7c0.5,0.2,1,0.4,1.4,0c0,0,0-0.3,0-0.3C425,150.1,424.5,150.5,424.1,150.7z"/>
			<path fill="#F2EADC" d="M417.8,157.8c0.1,0.6-1.1,0.3-0.8,0.9c0.1,0.2,0.3,0.4,0.6,0.3C418.1,158.7,417.5,158.2,417.8,157.8z"/>
			<path fill="#F7F3EC" d="M398.3,119c0.8,0.9,1.3,0.2,2.1,0.1C399.6,118.5,399.1,119.2,398.3,119z"/>
			<path fill="#F3EDE1" d="M434.6,174.8c-0.8-0.1-1.2-0.2-1.4,0.3c-0.1,0.2,0,0.4,0.2,0.5C434,175.7,434,175.1,434.6,174.8z"/>
			<path fill="#F7F3EC" d="M394.8,117.8c0,0.3,0,0.3,0,0.3c0.4,0.3,0.9,0.6,1.4,0.5c0,0,0.1-0.2,0.1-0.2
				C395.9,117.9,395.3,118.1,394.8,117.8z"/>
			<path fill="#F4EEE4" d="M421,154.2c0.7-0.1,1,0.5,1.5,0.7C422.2,153.8,421.9,153.6,421,154.2z"/>
			<path fill="#F6F1E9" d="M432.1,158.4c-0.1,0-0.2,0-0.2,0c-0.2,0-0.6-0.1-0.6,0.3c0,0.5,0.4,0.7,0.9,0.8
				C432.3,159.1,431.9,158.8,432.1,158.4z"/>
			<path fill="#F6F1E9" d="M420,151.5c-0.6-0.4-1-0.7-1.4-1C418.7,151.2,418.9,151.8,420,151.5z"/>
			<path fill="#F4EDE3" d="M431.4,177.6c-0.5,0.2-0.9-0.6-1.4,0c-0.1,0.1-0.2,0.4,0.1,0.5C430.6,178.3,431,178.4,431.4,177.6z"/>
			<path fill="#F9F6F1" d="M428.4,149.6c-0.5-0.4-0.9-0.4-1.4-0.1c-0.1,0.1-0.1,0.3,0.1,0.4C427.6,150.1,428,149.9,428.4,149.6z"/>
			<path fill="#F6F1E9" d="M394.6,120.3c0.2,0.8,0.8,0.6,1.3,0.7c0,0,0.1-0.2,0.1-0.3C395.7,120.3,395.2,120.2,394.6,120.3z"/>
			<path fill="#58524C" d="M323.7,106.8c-0.6-0.1-1.4-0.7-1.7,0.5C322.6,107.2,323.2,107.3,323.7,106.8z"/>
			<path fill="#FCFBFA" d="M405.8,115.9c0.1,0.3,0.4,0.4,0.7,0.4c0.2,0,0.4-0.1,0.3-0.4c-0.1-0.3-0.4-0.5-0.7-0.5
				C406,115.5,405.8,115.7,405.8,115.9z"/>
			<path fill="#F3EDE2" d="M418.4,135.1c0,0,0,0.1,0,0.1c0.1,0.3-0.4,0.8,0.2,1c0.3,0.1,0.5,0,0.5-0.3
				C419.2,135.4,419,135.1,418.4,135.1L418.4,135.1z"/>
			<path fill="#F6F1E8" d="M426.6,151.4c-0.6,0.7,0.6,1.1,0.4,1.7C427.7,152.4,426.9,151.9,426.6,151.4L426.6,151.4z"/>
			<path fill="#F9F5EF" d="M426.6,151.4c0.3,0,0.7-0.1,0.5-0.4c-0.2-0.4-0.5-0.9-1.2-0.7C426,150.8,426.7,150.9,426.6,151.4
				C426.6,151.4,426.6,151.4,426.6,151.4z"/>
			<path fill="#F7F3ED" d="M406,131.3c-0.6-1.1-1-0.5-1.4,0.3C405.2,131.5,405.6,131.2,406,131.3z"/>
			<path fill="#F4EEE4" d="M416.4,172.5c0.5-0.1,0.8-0.5,1.1-1.1C416.7,171.5,416.2,171.5,416.4,172.5z"/>
			<path fill="#FCFAF8" d="M415.9,148.5c0.8-0.5,0.9-1,0.9-1.7C416.1,147.2,416.1,147.7,415.9,148.5z"/>
			<path fill="#F2EBDE" d="M410,135.3c-0.4-0.3-0.6-0.9-1.1-0.6c-0.4,0.2-0.2,0.6,0.3,0.6C409.5,135.3,409.7,135.3,410,135.3z"/>
			<path fill="#F9F5F0" d="M436,169.9l0.1,0c0,0,0.1,0,0.1,0c0.2-0.4,0.5-0.8,1.1-0.9C436.2,168.8,435.8,169.1,436,169.9z"/>
			<path fill="#232120" d="M308.4,104.7c0.2-0.1,0.4-0.1,0.6-0.2c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.4-0.6-0.3-0.8-0.6l0,0
				c-0.3,0.1-0.4,0.3-0.5,0.5C308.2,104.3,308.3,104.5,308.4,104.7z"/>
			<path fill="#FAF8F4" d="M432.5,160c0.3,0.6,0.1,1.2,0.8,1.2c0.2,0,0.4,0,0.4-0.2C433.6,160.5,433.2,160.3,432.5,160z"/>
			<path fill="#F7F3EC" d="M433,164.8c-0.1-0.1-0.2-0.2-0.2-0.2c-0.4,0.1-0.4-0.7-0.9-0.5c-0.1,0-0.1,0.2-0.1,0.3
				C432,164.9,432.4,165.1,433,164.8z"/>
			<path fill="#F3EDE2" d="M418.4,135.1c-0.2-0.6-0.1-1.3-1.3-1.3C417.9,134.2,417.5,135.1,418.4,135.1L418.4,135.1z"/>
			<path fill="#F9F5F0" d="M403.7,125.6c-0.5,0.1-0.9,0.3-1.3,0.9C403.2,126.6,403.5,126.2,403.7,125.6z"/>
			<path fill="#F6F1E8" d="M429.9,169.4c0.3,0.2-0.2,0.8,0.3,0.9c0.2,0,0.4-0.1,0.7-0.2c-0.3-0.2-0.2-0.7-0.4-0.9
				C430.2,168.9,430.2,169.4,429.9,169.4L429.9,169.4z"/>
			<path fill="#F5F0E7" d="M414.3,166.1c-0.2,0.3-0.8-0.4-0.7,0.3c0,0.3,0.3,0.4,0.5,0.2c0.3-0.1,0.7-0.1,0.6-0.5
				C414.7,165.8,414.5,165.9,414.3,166.1z"/>
			<path fill="#F3ECE0" d="M415.4,157.5c0.2,0.2,0.3,0.5,0.5,0.7c0.2,0.1,0.5-0.2,0.4-0.4c0-0.3-0.3-0.5-0.6-0.6
				C415.6,157.2,415.4,157.3,415.4,157.5z"/>
			<path fill="#F4EEE5" d="M443.9,186c0,0.2,0.1,0.4,0.3,0.4c0.4,0,0.2-0.7,0.6-0.7c0,0-0.1-0.4-0.3-0.3
				C444.2,185.4,443.9,185.6,443.9,186z"/>
			<path fill="#FBF9F5" d="M403,144.4c0.2,0.3,0.4,0.5,0.6,0.5c0.3,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.5-0.5-0.4
				C403.3,144.1,403.1,144.3,403,144.4z"/>
			<path fill="#FBF9F6" d="M439.4,170.8c0,0.4,0.2,0.5,0.5,0.5c0.2,0,0.5,0,0.5-0.3c0-0.3-0.2-0.4-0.4-0.4
				C439.8,170.6,439.5,170.6,439.4,170.8z"/>
			<path fill="#F5F0E7" d="M400.3,116.4c0,0.2,0.1,0.4,0.3,0.4c0.4,0,0.5-0.4,0.6-0.6c0.1-0.2-0.1-0.4-0.3-0.3
				C400.6,115.9,400.3,116,400.3,116.4z"/>
			<path fill="#F3EDE1" d="M397.7,139.6c-0.3-0.1-0.3,0.1-0.3,0.3c0,0.3,0.1,0.6,0.4,0.6c0.3,0,0.4-0.3,0.4-0.5
				C398.3,139.6,397.9,139.7,397.7,139.6z"/>
			<path fill="#F9F6F0" d="M418.1,143.9c-0.1-0.2-0.3-0.4-0.6-0.3c-0.2,0.1-0.6,0-0.6,0.4c0,0.3,0.3,0.3,0.5,0.2
				C417.7,144.2,418.1,144.3,418.1,143.9z"/>
			<path fill="#F9F5F0" d="M387,113.3c-0.8,0.5-0.8,0.9-0.3,1.3C387.2,114.3,386.9,113.9,387,113.3z"/>
			<path fill="#F6F2EA" d="M437.4,173.7c-0.3-0.3-0.5-0.7-1-0.6c-0.2,0.1-0.2,0.3-0.1,0.4C436.6,174,437,173.6,437.4,173.7z"/>
			<path fill="#F8F4EE" d="M410.3,137.8c0.5-0.8,0-0.9-0.6-1.1C409.4,137.3,409.9,137.5,410.3,137.8
				C410.3,137.7,410.3,137.8,410.3,137.8z"/>
			<path fill="#F3ECE0" d="M403.8,146c0,0.3,0.1,0.4,0.4,0.4c0.2,0,0.5,0,0.5-0.3c0-0.3-0.1-0.5-0.4-0.5
				C404.1,145.6,403.9,145.7,403.8,146z"/>
			<path fill="#F3ECE0" d="M392.7,133.2c-0.5-0.6-0.8-1-1.2-0.8c-0.1,0-0.1,0.3-0.1,0.4C391.7,133.2,392.1,132.8,392.7,133.2z"/>
			<path fill="#F3EDE2" d="M415.7,150.8c0.3,0.1,0.4-0.2,0.5-0.4c0.1-0.4-0.3-0.3-0.4-0.3c-0.3-0.1-0.6,0-0.6,0.3
				C415.1,150.8,415.5,150.7,415.7,150.8z"/>
			<path fill="#FAF7F3" d="M416.8,132.3c0.3-0.3,0.7-0.5,0.6-1c0-0.1-0.2-0.3-0.4-0.2C416.6,131.4,416.8,131.9,416.8,132.3z"/>
			<path fill="#FBFAF7" d="M424.4,151.9c0,0.2,0,0.3,0,0.5c0.4,0.2,0.7,0.1,0.9-0.2c0.1-0.2,0.1-0.4-0.1-0.5
				C424.9,151.4,424.8,152,424.4,151.9z"/>
			<path fill="#F4EEE4" d="M418.9,154.3c0.3-0.1,0.9,0,0.3-0.6c-0.2-0.2-0.1-0.7-0.5-0.4C418.3,153.6,418.7,154,418.9,154.3
				L418.9,154.3z"/>
			<path fill="#F7F3EC" d="M399.1,116.9c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0,0.4,0.3,0.4c0.3,0,0.4-0.2,0.4-0.4
				C399.4,117.1,399.3,116.9,399.1,116.9z"/>
			<path fill="#F5EFE5" d="M401,137c0.2,0.4,0.5,0.6,0.9,0.7C401.8,137,401.8,137,401,137z"/>
			<path fill="#FEFEFE" d="M407.5,116.9c-0.4-0.4-0.8-0.3-1.1-0.1c-0.1,0-0.1,0.3,0,0.3C406.9,117.5,407.1,116.8,407.5,116.9z"/>
			<path fill="#F2EBDF" d="M396.6,115.6c-0.4,0-0.7-0.3-1.1-0.1c-0.2,0.1-0.1,0.3,0.1,0.4C396,116.2,396.3,116.2,396.6,115.6z"/>
			<path fill="#F8F4EC" d="M400.7,109.9c-0.2-0.1-0.3-0.2-0.5-0.2c0.1,1,0.8,0.6,1.4,0.5C401.3,110.1,401,110,400.7,109.9z"/>
			<path fill="#FBF9F5" d="M423.5,145.4c0.4,0,0.9,0,1.3-0.4C424.1,144.6,423.7,144.7,423.5,145.4
				C423.5,145.4,423.5,145.4,423.5,145.4z"/>
			<path fill="#FCFBF9" d="M414,164.5c0.5-0.1,1,0.1,1.2-0.4c0-0.1,0-0.3-0.1-0.3C414.7,163.7,414.4,163.9,414,164.5z"/>
			<path fill="#F3ECE0" d="M436.9,172.1c0.2,0.2,0.2,0.6,0.6,0.6c0.2,0,0.3-0.2,0.2-0.3c-0.2-0.2-0.2-0.6-0.6-0.6
				C437,171.8,436.8,171.9,436.9,172.1z"/>
			<path fill="#FDFCFB" d="M428.4,164.4c0.3,0.3,0.4,0.8,1,0.6c0.1,0,0.2-0.2,0.1-0.3C429.3,164.3,428.9,164.3,428.4,164.4z"/>
			<path fill="#F9F6F1" d="M435.5,171.8c0.1,0.2,0.2,0.5,0.2,0.7c0.4-0.2,0.6-0.6,0.6-1C435.9,171.3,435.8,171.8,435.5,171.8z"/>
			<path fill="#F9F6F1" d="M433.1,173.5c-0.6,0.2-0.9,0.4-0.8,0.9c0,0.1,0.2,0.3,0.4,0.2C433,174.4,432.7,173.9,433.1,173.5z"/>
			<path fill="#F9F7F2" d="M418.7,147c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.1-0.4-0.3-0.4c-0.2,0-0.4,0.1-0.4,0.4
				C418.4,146.8,418.5,147,418.7,147z"/>
			<path fill="#F3EDE1" d="M431.8,188.9c-0.2,0.4-0.4,0.6-0.2,1c0,0.1,0.2,0.1,0.3,0C432.2,189.7,432.1,189.4,431.8,188.9z"/>
			<path fill="#FCFBF9" d="M417.3,145.1c-0.2,0.5,0,0.8,0.4,0.9c0.2,0.1,0.4,0,0.3-0.2C418,145.4,417.7,145.3,417.3,145.1z"/>
			<path fill="#F1EADD" d="M402.1,142.5c0.4-0.1,0.8,0.5,1.1-0.1c0-0.1,0-0.3,0-0.3C402.7,142,402.4,142.3,402.1,142.5z"/>
			<path fill="#F2EADD" d="M415.8,169.9c-0.1,0.3-0.7,0.2-0.5,0.7c0.1,0.3,0.5,0.3,0.7,0C416.4,170.2,416,170.1,415.8,169.9
				C415.8,169.9,415.8,169.9,415.8,169.9z"/>
			<path fill="#FAF8F3" d="M417,136.8c-0.2,0-0.4,0.1-0.4,0.3c0,0.2,0.2,0.3,0.4,0.3c0.2,0,0.4-0.1,0.4-0.3
				C417.4,136.9,417.2,136.8,417,136.8z"/>
			<path fill="#FCFBF9" d="M400.5,123.7c-0.2,0-0.3,0.1-0.3,0.3c0,0.3,0.1,0.5,0.4,0.7c0,0,0.3-0.2,0.3-0.3
				C400.9,124,400.8,123.8,400.5,123.7z"/>
			<path fill="#F9F5F0" d="M385.1,116.8c0.1,0.3,0.2,0.5,0.4,0.4c0.2-0.1,0.5-0.1,0.4-0.5c0-0.1-0.2-0.3-0.2-0.4
				C385.5,116.5,385.3,116.7,385.1,116.8z"/>
			<path fill="#F9F6F1" d="M433,170.2c-0.2,0-0.3,0.1-0.4,0.3c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.3,0,0.4-0.2
				C433.3,170.5,433.1,170.4,433,170.2z"/>
			<path fill="#F4EEE4" d="M420.6,154.8c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.2,0-0.5-0.3-0.5c-0.1,0-0.2,0.2-0.3,0.3
				c-0.1,0.4,0.1,0.5,0.4,0.5C420.4,154.8,420.5,154.8,420.6,154.8C420.6,154.8,420.6,154.8,420.6,154.8z"/>
			<path fill="#F5F0E7" d="M402.8,113.5c0.2-0.3,0.4-0.5,0.8-0.9C402.6,112.6,402.5,112.9,402.8,113.5L402.8,113.5z"/>
			<path fill="#F6F2EA" d="M423.8,167.6c0.1,0.3,0.3,0.5,0.5,0.5c0.2,0,0.4-0.1,0.4-0.2c0-0.3-0.2-0.5-0.5-0.5
				C424.1,167.4,424,167.6,423.8,167.6z"/>
			<path fill="#F7F3EC" d="M396.5,113.7c-0.1,0.2,0.1,0.3,0.2,0.3c0.3,0,0.5-0.2,0.5-0.5c0-0.1-0.2-0.3-0.3-0.3
				C396.7,113.2,396.6,113.5,396.5,113.7z"/>
			<path fill="#F5EFE5" d="M397.2,130.2c-0.4-0.1-0.8-0.3-1,0.1c0,0.1,0,0.3,0.1,0.3C396.7,130.7,396.9,130.5,397.2,130.2z"/>
			<path fill="#F6F2EA" d="M396.2,135.1c0,0.2,0.2,0.4,0.4,0.4c0.3,0,0.3-0.2,0.2-0.4c-0.1-0.2-0.2-0.4-0.4-0.3
				C396.2,134.7,396.1,134.9,396.2,135.1z"/>
			<path fill="#F3EDE2" d="M398.8,142.3c0,0.6,0.2,0.9,0.7,1.3C399.5,143,399.3,142.6,398.8,142.3z"/>
			<path fill="#F5EFE5" d="M403.5,133.7c0-0.5-0.2-0.8-0.7-1C402.8,133.2,402.9,133.5,403.5,133.7z"/>
			<path fill="#F7F2EA" d="M414,139.8c0,0.3,0.3,0.4,0.5,0.4c0.1,0,0.4,0.1,0.3-0.2c-0.1-0.2,0-0.5-0.4-0.5
				C414.3,139.4,414.1,139.5,414,139.8z"/>
			<path fill="#F5EFE5" d="M408.8,124.6c-0.4-0.4-0.7-0.3-1,0c0,0,0,0.3,0,0.3C408.2,125,408.5,124.8,408.8,124.6z"/>
			<path fill="#F5F1E8" d="M436,178c0,0.2,0.2,0.2,0.4,0.2c0.2,0,0.4-0.2,0.4-0.4c0-0.3-0.2-0.3-0.4-0.3
				C436.2,177.7,436,177.8,436,178z"/>
			<path fill="#FAF7F3" d="M407.3,119.4c-0.1,0.4,0.2,0.6,0.5,0.7c0.1,0,0.3-0.2,0.3-0.3C407.9,119.4,407.6,119.3,407.3,119.4z"/>
			<path fill="#F8F4ED" d="M423.9,149.8c0.1,0,0.2,0,0.2-0.1c0.2-0.3,0.7,0.2,0.7-0.3c0-0.1-0.1-0.2-0.1-0.3
				c-0.4-0.3-0.5,0.2-0.8,0.2C423.8,149.4,423.8,149.6,423.9,149.8z"/>
			<path fill="#FAF8F4" d="M410.3,121.2c0-0.2,0-0.3,0-0.5c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.2,0,0.5,0.2,0.6
				C410.3,121.8,410.2,121.4,410.3,121.2z"/>
			<path fill="#FAF7F3" d="M424.3,156.1c-0.2-0.3-0.4-0.6-0.8-0.5c-0.1,0-0.2,0.2-0.1,0.3C423.6,156.1,423.9,156.3,424.3,156.1z"/>
			<path fill="#F6F1E9" d="M427.5,154.3c-0.4-0.4-0.4-0.4-1.2-0.3C426.7,154.5,427.2,154.3,427.5,154.3
				C427.5,154.3,427.5,154.3,427.5,154.3z"/>
			<path fill="#F5F0E6" d="M420,160.4c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0,0.3-0.1,0.3-0.3c-0.1-0.2-0.2-0.4-0.4-0.4
				C420.1,160.1,420,160.2,420,160.4z"/>
			<path fill="#F5EFE5" d="M400.5,136.1c-0.3,0.1-0.3,0.3-0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1-0.2,0.2-0.3,0.2-0.5
				C400.8,136.3,400.6,136.2,400.5,136.1z"/>
			<path fill="#F7F2EB" d="M413.5,136.1c0.1-0.6-0.2-0.8-0.6-1C412.8,135.6,413,135.8,413.5,136.1z"/>
			<path fill="#F6F1E9" d="M402.9,114.8c0,0.2,0,0.3,0.3,0.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.1-0.3-0.3-0.3
				C403,114.4,402.9,114.6,402.9,114.8z"/>
			<path fill="#F5F0E6" d="M390.5,111c0.3,0.2,0.7,0.2,0.9-0.1c0.2-0.2-0.2-0.4-0.4-0.1C390.8,110.9,390.5,110.6,390.5,111z"/>
			<path fill="#F4EEE4" d="M398.7,107.3c0.5,0.2,0.8,0.4,1.1,0.5C399.7,107.4,399.6,106.9,398.7,107.3z"/>
			<path fill="#FDFCFB" d="M399.4,140.8c0.3,0.1,0.6,0.2,0.8-0.1c0.1-0.2-0.1-0.3-0.2-0.3C399.6,140.2,399.5,140.4,399.4,140.8z"/>
			<path fill="#F7F3ED" d="M409.8,144.9c-0.3,0-0.5,0.2-0.6,0.5c-0.1,0.2,0,0.4,0.2,0.5c0.1,0,0.2,0.1,0.2,0.1c0.3-0.1,0-0.3,0-0.5
				C409.6,145.3,409.7,145.1,409.8,144.9L409.8,144.9z"/>
			<path fill="#FBF9F6" d="M411.2,147.4c-0.2,0-0.4,0-0.3,0.2c0,0.3,0.3,0.3,0.5,0.4c0.1,0,0.2-0.1,0.2-0.2
				C411.6,147.5,411.4,147.4,411.2,147.4z"/>
			<path fill="#FAF7F3" d="M412.6,150.6c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3-0.2-0.3-0.4-0.3c-0.2,0-0.3,0.1-0.3,0.3
				C412.2,150.4,412.3,150.6,412.6,150.6z"/>
			<path fill="#F4EEE3" d="M392,123c-0.2,0.1-0.2,0.4-0.3,0.6c0,0.2,0.2,0.2,0.3,0.2c0.2-0.1,0.2-0.3,0.2-0.5
				C392.2,123.1,392.2,123,392,123z"/>
			<path fill="#FBF9F6" d="M412.2,161.4c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.4-0.2,0.3-0.4c0-0.1-0.2-0.3-0.3-0.3
				C412.3,161,412.2,161.2,412.2,161.4z"/>
			<path fill="#F4EFE5" d="M408.2,151.4c0,0.2,0,0.5,0.2,0.4c0.3,0,0.4-0.3,0.4-0.5c0-0.2-0.1-0.3-0.3-0.3
				C408.3,151.1,408.2,151.3,408.2,151.4z"/>
			<path fill="#FCFBF9" d="M431.2,170.5c0.2,0.1,0.4,0.3,0.6,0.3c0.3,0.1,0.3-0.1,0.3-0.3c0-0.2-0.2-0.3-0.4-0.3
				C431.5,170.2,431.4,170.3,431.2,170.5z"/>
			<path fill="#F6F1E8" d="M444.5,178c0,0.2,0.1,0.3,0.2,0.3c0.2,0,0.4,0,0.4-0.3c0-0.1-0.1-0.3-0.3-0.3
				C444.7,177.6,444.6,177.8,444.5,178z"/>
			<path fill="#F6F1EA" d="M417.8,154.4c-0.2,0.4-0.4,0.6-0.2,0.9c0.1,0.1,0.3,0.1,0.3,0.1C418.2,155,417.9,154.8,417.8,154.4z"/>
			<path fill="#F6F1E9" d="M391.6,115.6c0,0.2,0.1,0.4,0.3,0.3c0.1-0.1,0.2-0.3,0.3-0.5c0,0-0.1-0.2-0.2-0.2
				C391.8,115.3,391.7,115.4,391.6,115.6z"/>
			<path fill="#F9F6F1" d="M417.1,150.6c0.2-0.1,0.3-0.2,0.3-0.4c0-0.2-0.1-0.4-0.2-0.3c-0.1,0.1-0.3,0.3-0.3,0.4
				C416.8,150.5,416.8,150.6,417.1,150.6z"/>
			<path fill="#F9F5F0" d="M426.9,166.5c0.5-0.3,0.7-0.6,0.2-1C426.8,165.8,427,166.1,426.9,166.5z"/>
			<path fill="#F4EEE3" d="M422.5,174.2c0.1-0.3,0.4-0.5,0.1-0.8c0,0-0.3,0-0.3,0.1C422,173.8,422.3,174,422.5,174.2
				C422.5,174.2,422.5,174.2,422.5,174.2z"/>
			<path fill="#F5EFE5" d="M406.2,125.5c-0.2,0-0.2-0.5-0.5-0.2c-0.1,0.1-0.1,0.6,0,0.6C406,126.1,406.2,125.8,406.2,125.5
				L406.2,125.5z"/>
			<path fill="#FAF7F2" d="M397.8,122.3c-0.2,0.1-0.4,0.2-0.3,0.4c0,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3-0.1,0.3-0.3
				C398.2,122.4,398,122.3,397.8,122.3z"/>
			<path fill="#F8F5EF" d="M423.6,154.7c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3
				S423.6,154.4,423.6,154.7z"/>
			<path fill="#F4EEE4" d="M418.9,154.3c-0.2,0.1-0.6,0-0.5,0.4c0,0.1,0.3,0.3,0.5,0.2C419.3,154.8,418.9,154.5,418.9,154.3
				L418.9,154.3z"/>
			<path fill="#F4EFE4" d="M411.4,142.8c0.1,0.2,0.3,0.3,0.5,0.2c0.2,0,0.3-0.2,0.2-0.3c0-0.2-0.2-0.2-0.3-0.2
				C411.7,142.6,411.4,142.5,411.4,142.8z"/>
			<path fill="#F6F1E9" d="M408.9,144.1c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3,0,0.3-0.2c0-0.1-0.1-0.3-0.3-0.3
				C409,143.8,408.9,143.9,408.9,144.1z"/>
			<path fill="#F6F1E9" d="M430.6,160.5c0.1,0.2,0.2,0.3,0.4,0.2c0.2-0.1,0.3-0.3,0.3-0.5c0-0.1-0.2-0.1-0.3-0.1
				C430.7,160.1,430.6,160.2,430.6,160.5z"/>
			<path fill="#F4EFE4" d="M416.8,177.9c-0.3,0.1-0.4,0.3-0.4,0.5c0,0.1,0.1,0.2,0.2,0.2c0.2-0.1,0.4-0.2,0.4-0.5
				C416.9,178.1,416.8,178,416.8,177.9z"/>
			<path fill="#F8F5F0" d="M417.5,140.1c0,0.2,0.2,0.3,0.4,0.3c0.1,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.4-0.3-0.4
				C417.7,139.8,417.6,139.9,417.5,140.1z"/>
			<path fill="#F6F2EA" d="M441.8,189.8c0,0.2,0.1,0.4,0.4,0.4c0.1,0,0.2-0.2,0.2-0.2c-0.1-0.2-0.1-0.4-0.3-0.5
				C441.9,189.5,441.8,189.7,441.8,189.8z"/>
			<path fill="#242322" d="M309.9,105.9c0.3-0.2,0.4-0.5,0.5-0.8c-0.9-0.4-0.6,0.3-0.7,0.7C309.8,105.9,309.8,105.9,309.9,105.9z"/>
			<path fill="#FAF8F4" d="M414.1,147.6c0.2,0.1,0.3,0.5,0.7,0.3c0.1-0.1,0.2-0.2,0.1-0.3c-0.2-0.3-0.5-0.2-0.8-0.2
				C414.1,147.4,414,147.4,414.1,147.6C414.1,147.5,414.1,147.6,414.1,147.6z"/>
			<path fill="#F9F5F0" d="M420.3,163.5c-0.4,0-0.8,0-1.2,0C419.5,163.8,419.9,163.6,420.3,163.5z"/>
			<path fill="#FBF9F6" d="M414.1,147.6c0-0.1,0-0.2,0-0.2c-0.2-0.3-0.4-0.2-0.6,0c-0.1,0.1-0.1,0.3-0.1,0.3
				C413.7,147.9,413.9,147.6,414.1,147.6z"/>
			<path fill="#FAF7F2" d="M420.6,147.5c0,0.1,0.1,0.2,0.1,0.2c0.3,0.1,0.3-0.2,0.4-0.3c0-0.2-0.1-0.2-0.2-0.2
				C420.8,147.2,420.6,147.2,420.6,147.5z"/>
			<path fill="#F6F1E8" d="M419.4,136.3c0,0.3,0.1,0.3,0.3,0.3c0.2-0.1,0.3-0.2,0.2-0.3c-0.1-0.1-0.1-0.4-0.3-0.2
				C419.5,136.1,419.5,136.3,419.4,136.3z"/>
			<path fill="#F3EDE2" d="M418.4,132.9c-0.3-0.2-0.7,0-0.7,0.2c0,0.4,0.3,0.2,0.5,0.1C418.2,133.1,418.3,133,418.4,132.9z"/>
			<path fill="#F3EDE1" d="M435.4,174.9c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.2,0.2,0.2,0.3,0.2c0.1,0,0.4,0,0.3-0.2
				C435.7,175.1,435.5,175,435.4,174.9z"/>
			<path fill="#F5F0E7" d="M427.8,175.3c0,0.2,0.1,0.3,0.3,0.4c0.1,0,0.2,0,0.2-0.2c0-0.2-0.1-0.4-0.3-0.4
				C427.9,175.1,427.9,175.2,427.8,175.3z"/>
			<path fill="#F3EDE1" d="M441.6,176.4c0,0.2,0,0.3,0.1,0.3c0.2,0,0.4-0.1,0.4-0.3c0-0.1,0.1-0.4-0.2-0.3
				C441.8,176.2,441.7,176.3,441.6,176.4z"/>
			<path fill="#F3EDE1" d="M398.6,139.9c0.3,0.3,0.5,0.1,0.8,0C399.1,139.5,398.9,139.6,398.6,139.9z"/>
			<path fill="#F6F1E8" d="M388.5,113.7c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0-0.3-0.2-0.4
				C388.7,113.4,388.6,113.5,388.5,113.7z"/>
			<path fill="#F5F0E7" d="M413.3,156.3c-0.2,0.1-0.3,0.2-0.3,0.3c0,0.2,0.1,0.3,0.3,0.2c0.1-0.1,0.2-0.2,0.2-0.4
				C413.5,156.4,413.3,156.3,413.3,156.3z"/>
			<path fill="#F6F2EA" d="M397.3,108.2c0.1,0,0.2-0.1,0.2-0.3c0-0.2-0.1-0.3-0.3-0.2c-0.1,0.1-0.2,0.2-0.2,0.4
				C397,108.1,397.1,108.2,397.3,108.2z"/>
			<path fill="#F5EFE5" d="M430,159.2c0.1,0.1,0.2,0.3,0.2,0.2c0.1,0,0.3-0.1,0.3-0.2c0.1-0.1,0-0.3-0.2-0.3
				C430.2,159,430.1,159.1,430,159.2z"/>
			<path fill="#FBFAF6" d="M394.1,123.5c-0.1,0.1-0.3,0.3-0.3,0.4c0,0.2,0.2,0.2,0.3,0.2c0.2,0,0.3-0.2,0.2-0.4
				C394.4,123.6,394.2,123.5,394.1,123.5z"/>
			<path fill="#F7F3EC" d="M413,154.5c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.1-0.3,0-0.4,0.2
				C412.8,154.3,412.9,154.4,413,154.5z"/>
			<path fill="#F7F3EC" d="M428.3,153.3c0-0.2-0.1-0.5-0.4-0.4c-0.1,0-0.2,0.2-0.2,0.2C427.8,153.4,428.1,153.3,428.3,153.3
				C428.3,153.3,428.3,153.3,428.3,153.3z"/>
			<path fill="#F9F7F3" d="M427.4,187.9c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.2-0.3c0-0.1-0.2-0.1-0.3-0.1
				C427.5,187.7,427.5,187.8,427.4,187.9z"/>
			<path fill="#FDFCFB" d="M426.8,146.4c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.3-0.2-0.4c-0.2-0.1-0.3,0.1-0.2,0.2
				C426.6,146.2,426.7,146.3,426.8,146.4z"/>
			<path fill="#F5F0E7" d="M437.2,189.2c-0.2,0.3-0.3,0.4-0.4,0.5c0,0.1,0.1,0.2,0.2,0.1c0.1-0.1,0.2-0.2,0.3-0.3
				C437.3,189.5,437.2,189.4,437.2,189.2z"/>
			<path fill="#F7F2EB" d="M404.9,148.8c0.1,0.1,0.1,0.2,0.2,0.2c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1-0.1-0.2-0.2-0.1
				C405.1,148.5,405,148.7,404.9,148.8z"/>
			<path fill="#F5EFE5" d="M406.2,123.7c-0.2,0.3-0.2,0.5-0.3,0.6c-0.1,0.1,0,0.2,0.1,0.2c0.2,0,0.3-0.1,0.3-0.3
				C406.4,124.2,406.3,124.1,406.2,123.7z"/>
			<path fill="#FBF9F6" d="M411,123.1c-0.1,0-0.2,0-0.2,0c0,0.3,0.1,0.5,0.4,0.6c0.1,0,0.2,0,0.2-0.1
				C411.3,123.4,411.2,123.2,411,123.1z"/>
			<path fill="#F6F1E9" d="M403.7,119.5c0.2-0.4,0.3-0.5,0.3-0.5c0-0.2-0.1-0.3-0.3-0.2c-0.1,0-0.2,0.2-0.2,0.2
				C403.5,119.1,403.5,119.2,403.7,119.5z"/>
			<path fill="#FCFAF8" d="M386.8,109.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.3-0.2-0.2-0.3-0.2c-0.1,0-0.3,0.1-0.3,0.1
				C386.4,109,386.6,109.1,386.8,109.1z"/>
			<path fill="#F7F2EB" d="M397.3,109.4c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2-0.1-0.3-0.2-0.3c-0.1,0-0.2,0-0.3,0.1
				C397.1,109.2,397.1,109.3,397.3,109.4z"/>
			<path fill="#F7F2EA" d="M426.9,169.2c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.1-0.3-0.1
				C427.1,169,427,169.1,426.9,169.2z"/>
			<path fill="#F6F1E9" d="M428.5,167.3c0.4,0,0.7-0.1,1-0.2c0,0,0,0,0,0C429,166.6,428.8,166.9,428.5,167.3
				C428.5,167.3,428.5,167.3,428.5,167.3z"/>
			<path fill="#F3ECE0" d="M440.3,184.5c0,0.2-0.1,0.5,0.2,0.6c0,0,0.2-0.1,0.2-0.2C440.7,184.6,440.5,184.6,440.3,184.5
				L440.3,184.5z"/>
			<path fill="#F3ECE0" d="M440.3,184.5c0.1-0.2,0.3-0.4-0.1-0.6c-0.1,0-0.2,0.1-0.3,0.2C439.8,184.5,440,184.5,440.3,184.5
				C440.3,184.5,440.3,184.5,440.3,184.5z"/>
			<path fill="#FAF7F3" d="M405,143.6c0.1,0.1,0.1,0.3,0.2,0.3c0.2,0.1,0.3-0.1,0.2-0.3c0-0.1-0.1-0.2-0.2-0.2
				C405.2,143.4,405.1,143.5,405,143.6z"/>
			<path fill="#F4EDE3" d="M428.6,181.5c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.2,0.2-0.2,0.3c0,0.1,0.1,0.1,0.2,0.2
				C428.4,181.7,428.4,181.6,428.6,181.5z"/>
			<path fill="#FBF9F6" d="M413.9,141.2c0.4,0.2,0.5,0.3,0.6,0.3c0.2,0,0.3-0.1,0.2-0.3c0-0.1-0.2-0.2-0.2-0.2
				C414.3,141,414.1,141.1,413.9,141.2z"/>
			<path fill="#F8F5F0" d="M416.5,140.9c0.1,0.2,0.2,0.3,0.4,0.4c0.1,0.1,0.2,0,0.2-0.2c0-0.1-0.1-0.3-0.2-0.4
				C416.8,140.7,416.6,140.8,416.5,140.9z"/>
			<path fill="#FAF8F4" d="M397.6,138.4c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0.2,0.2,0.3,0.2c0.1,0,0.1-0.1,0.2-0.2
				C397.8,138.6,397.8,138.5,397.6,138.4z"/>
			<path fill="#F6F1E9" d="M393.8,125.6c0.1-0.4-0.2-0.5-0.3-0.7C393.4,125.2,393.5,125.4,393.8,125.6z"/>
			<path fill="#F6F1EA" d="M407.4,132.6c0.1-0.3-0.1-0.3-0.2-0.4c-0.1,0-0.3,0-0.2,0.1c0,0.2,0.2,0.3,0.3,0.5
				C407.3,132.7,407.4,132.6,407.4,132.6z"/>
			<path fill="#F7F3EC" d="M430.3,175.4c0.2-0.4,0.3-0.5,0.3-0.6c0-0.2-0.1-0.3-0.3-0.2c-0.1,0-0.2,0.2-0.2,0.2
				C430.1,175,430.2,175.1,430.3,175.4z"/>
			<path fill="#FBF9F6" d="M406,132.9c0,0.1,0.1,0.2,0.2,0.3c0.2,0.1,0.3-0.1,0.2-0.2c0-0.1-0.1-0.3-0.2-0.3
				C406,132.6,406,132.7,406,132.9z"/>
			<path fill="#F6F1E9" d="M432.1,180.8c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.2-0.1c-0.1,0.1-0.1,0.2-0.1,0.3
				C431.9,180.7,432,180.7,432.1,180.8z"/>
			<path fill="#F7F3EC" d="M421.8,167.5c0,0.2-0.1,0.5,0.2,0.6c0,0,0.2,0,0.2-0.1C422.2,167.7,422,167.6,421.8,167.5
				C421.8,167.5,421.8,167.5,421.8,167.5z"/>
			<path fill="#F7F2EB" d="M426.2,186.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1-0.2,0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1
				C426.3,185.8,426.2,185.9,426.2,186.1z"/>
			<path fill="#F5F1E9" d="M425.4,174.4c0.2-0.3,0.3-0.4,0.3-0.4c-0.1-0.1-0.2-0.3-0.3-0.3c-0.2-0.1-0.2,0.1-0.1,0.2
				C425.3,174,425.4,174.2,425.4,174.4z"/>
			<path fill="#F8F4ED" d="M434.6,165c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C434.7,164.7,434.6,164.8,434.6,165z"/>
			<path fill="#F7F3EB" d="M416.2,166.7c-0.2,0.1-0.4,0.4-0.2,0.6c0.2,0.2,0.3-0.1,0.3-0.3C416.3,166.9,416.2,166.8,416.2,166.7z"/>
			<path fill="#F6F1E8" d="M426.2,168.6c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C426.3,168.3,426.2,168.4,426.2,168.6z"/>
			<path fill="#141413" d="M312.5,107.1c0.2,0,0.4-0.1,0.5-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.5-0.2-0.6,0.2
				C312.4,107.1,312.5,107.1,312.5,107.1z"/>
			<path fill="#FAF8F3" d="M396.5,110.4c0.2,0,0.3-0.1,0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c0,0-0.1,0.1-0.1,0.2
				C396.3,110.3,396.4,110.3,396.5,110.4z"/>
			<path fill="#FAF7F3" d="M395.3,113.1c0.1-0.1,0.1-0.2,0.2-0.4c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.2-0.2,0.2
				C395,112.8,395.2,112.8,395.3,113.1z"/>
			<path fill="#F9F5EF" d="M402.8,113.5c-0.2,0-0.4,0.1-0.5,0.1c0,0.1,0.1,0.2,0.1,0.3C402.8,114,402.8,113.7,402.8,113.5
				C402.8,113.5,402.8,113.5,402.8,113.5z"/>
			<path fill="#F5F0E6" d="M386.3,120.3c0.1-0.3,0.1-0.4,0.1-0.5c0-0.1-0.1-0.1-0.2-0.2c0,0.1-0.1,0.2-0.1,0.3
				C386.1,120,386.2,120,386.3,120.3z"/>
			<path fill="#FDFDFC" d="M405.3,121.6c0,0.2,0,0.3,0.2,0.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1-0.1-0.2-0.2-0.2
				C405.5,121.5,405.4,121.5,405.3,121.6z"/>
			<path fill="#F9F6F1" d="M389.4,124.5c0-0.2-0.1-0.5-0.4-0.4c-0.1,0-0.1,0.1-0.1,0.1C388.9,124.5,389.2,124.5,389.4,124.5
				C389.4,124.5,389.4,124.5,389.4,124.5z"/>
			<path fill="#FCFAF8" d="M408.6,128.2c-0.1,0.1-0.3,0.1-0.3,0.2c-0.1,0.1,0,0.2,0.1,0.2c0.1,0,0.2-0.1,0.3-0.1
				C408.8,128.4,408.7,128.3,408.6,128.2z"/>
			<path fill="#F7F3EC" d="M406.6,130.8c-0.1,0.2-0.1,0.3-0.1,0.4c0,0.1,0.2,0.1,0.3,0.2c0-0.1,0.1-0.2,0.1-0.2
				C406.8,131,406.8,130.9,406.6,130.8z"/>
			<path fill="#F8F3ED" d="M401.5,132.8c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1-0.1,0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.1
				C401.7,132.6,401.6,132.7,401.5,132.8z"/>
			<path fill="#F6F2EA" d="M403.3,137.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
				C403.5,137.3,403.5,137.4,403.3,137.5z"/>
			<path fill="#F9F5F0" d="M423.9,149.8c0-0.2,0-0.3,0-0.5c-0.2,0-0.4,0-0.4,0.3C423.6,149.8,423.7,150,423.9,149.8z"/>
			<path fill="#FBFAF7" d="M413.1,158.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.1-0.2
				C413.3,158.3,413.2,158.4,413.1,158.4z"/>
			<path fill="#0A0A0A" d="M368.2,21.8c0.7,0,1.5,0,2.2,0c1,0.3,0.3,0.7,0.1,1.1c0,0.3,0.2,0.5,0.5,0.6c0.2,0.1,0.4,0.1,0.7,0.2
				c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.3-0.2c0.1,0.1-0.1,0.3-0.1,0.4c0,0.3,0,0.5,0,0.8c0.2,0.4,0.4,0.8,0.9,0.9
				c1-0.1,0.7-0.8,0.7-1.4c0-0.2,0-0.4,0.2-0.5c0.2-0.2,0.4-0.2,0.7-0.3c0.3-0.7,0.5-1.5,0.3-2.3c-0.1-0.1-0.2-0.1-0.3-0.1
				c-0.3,0.1-0.6,0.1-0.9,0c-0.1,0-0.2,0-0.3,0c-0.2,0.2-0.1,0-0.2-0.1c-0.1-0.2-0.3-0.4-0.6-0.3c-0.5,0.8-0.9,0.2-1.3,0
				c-0.1-0.2-0.2-0.4-0.3-0.5c-0.8-0.5-1.5-0.1-2.1,0.5c-0.2,0.2-0.4,0.6-0.8,0.7c-0.1,0-0.2,0.1-0.3,0.2l0,0
				C368,21.3,368.1,21.6,368.2,21.8z"/>
			<path fill="#0C0C0C" d="M377.1,23.5c-0.1,0-0.1,0-0.2,0c-0.4,0.1-0.6,0.3-0.7,0.7c0,0.1,0,0.2,0,0.3c0.1,0.4,0.1,0.7-0.4,0.4
				c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-1.1-0.2-0.8,0.6-0.7,1.2c-0.1,0.2-0.3,0.5,0,0.7c0.3,0.1,0.7-0.4,0.7,0.3
				c0.5,0.3,0.7,0.9,1.4,0.7l0,0c0.1-0.7,0.6-1.3,1-1.9c0.4-0.4,0-1,0.2-1.4C377.5,23.8,377.4,23.5,377.1,23.5z"/>
			<path fill="#0F0F0F" d="M369.7,31.9c-0.2,0.4-0.7,0.8,0,1.2c0.3,0.5,0,0.8-0.2,1.2l0,0c-0.2,0.2-0.2,0.5,0,0.7
				c0.3-0.3,0.5-0.6,1-0.5c0.3-0.2,0.4-0.5,0.6-0.7c0.4-0.6,0.6-0.2,0.9,0.1c0.1,0.1,0.2,0.2,0.4,0.1c0-0.5,0.1-1.1-0.5-1.4
				c-0.4-0.5-0.2-1.1-0.5-1.5c-0.1-0.1-0.3-0.1-0.5-0.1C370.4,31.1,370.1,31.5,369.7,31.9z"/>
			<path fill="#151514" d="M365.6,15.8c0.1,0.1,0.2,0.2,0.3,0.3c0.3-0.3,0.7,0,1,0c0.2,0,0.4,0,0.6,0c0.3-0.1,0.4-0.3,0.5-0.6
				c0-0.5,0-1-0.4-1.4c-0.2-0.1-0.3-0.2-0.6-0.3c-0.5,0-0.9-0.3-1.4-0.2c-0.5,0.2-0.5,0.6-0.3,1.1C365.5,15,365.4,15.4,365.6,15.8z"
				/>
			<path fill="#4E4944" d="M371.6,23.5c-0.1-0.2-0.3-0.2-0.5-0.2c-0.3,0.1-0.6,0.1-0.7,0.4c-0.2,0.2-0.3,0.4-0.5,0.6
				c-0.2,0.3-0.8,0.3-0.6,0.9c0.1,0.4,0.5,0.4,0.9,0.5c0.6,0.2,0.7-0.1,0.8-0.6c0,0,0.3,0,0.4,0c0.4,0,0.9,0,0.8-0.7
				c-0.4-0.3-0.9-0.1-1.3,0C371,24.1,372,24.3,371.6,23.5z"/>
			<path fill="#0A0A09" d="M367.3,10.3c-0.2-0.1-0.5-0.1-0.7-0.2c-0.2,0.3-0.1,0.8-0.4,1c-0.1,0.1-0.3,0.1-0.4,0.2
				c0,0-0.1,0.1-0.1,0.1c0.1,0.4,0.6,0.3,0.6,0.8c0,0.2,0.1,0.4,0.4,0.4c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.3,0.3,0
				c0,0.1,0.1,0.3,0.3,0.3c0.2,0.1,0.4,0,0.6-0.1l0,0c0.3-0.2,0.7-0.3,0.8-0.7c0-0.1,0-0.2,0-0.3c-0.1-0.3-0.3-0.5-0.6-0.7
				c-0.1,0-0.2,0-0.3,0c-0.5,0.2-0.2-0.4-0.4-0.5C367.4,10.4,367.4,10.3,367.3,10.3z"/>
			<path fill="#3C3935" d="M367,23.7c-0.4,0.1-0.7,0.4-0.9,0.7c0.3,0.4,0.5-0.1,0.8,0c-0.1,0.8-0.1,0.8,1.3,1.6
				c0-0.5,0.1-0.9-0.2-1.3c-0.1-0.1-0.2-0.4,0-0.4c1-0.1,0-0.8,0.3-1.1C367.7,23.2,367.4,23.6,367,23.7z"/>
			<path fill="#131312" d="M372.4,34.1c-0.1-0.1-0.2-0.1-0.2-0.2c-0.7-0.1-1.4-0.1-1.4,0.9c0,0.2-0.2,0.3-0.3,0.5l0,0
				c-0.3,0.5-0.5,0.9-0.2,1.5c0.1,0.1,0.3,0.2,0.4,0.2c0.4-0.2,0.5-0.7,0.8-1c0.2-0.1,0.5-0.1,0.7-0.3l0,0c0.2-0.4,0.2-0.9,0.7-1
				C372.9,34.4,372.7,34.2,372.4,34.1z"/>
			<path fill="#5B544D" d="M366.8,6c-0.2,0-0.4,0-0.5-0.2c-0.3-0.1-0.7-0.2-1-0.4c-0.3-0.1-0.7-0.5-0.9-0.1
				c-0.2,0.3-0.3,0.9,0.4,1.1c0.1,0,0.2,0.2,0.3,0.3c0.2,0.1,0.3,0.4,0.5,0.3c0.4-0.3,0.9-0.3,1.3-0.6C366.9,6.3,367,6.1,366.8,6z"
				/>
			<path fill="#2F2D2B" d="M374.6,25.7c1.5-0.6-0.6-0.4-0.2-0.7c0.2-0.4,0.6-0.2,0.9-0.3c0.3-0.4-0.2-0.7-0.2-1.1
				c-0.1-0.2-0.1-0.4-0.3-0.6c-0.3,0-0.5,0-0.7,0.2c0.1,0.2-0.1,0.3-0.2,0.5c-0.3,0.4,0.7,1.3-0.5,1.4c-0.2,0.1-0.2,0.3,0,0.5
				c0.1,0,0.2,0,0.3,0C373.9,26,374.2,25.7,374.6,25.7z"/>
			<path fill="#504A45" d="M376,34.6c0.3-0.6,1.3,0,1.4-0.9c-0.7-0.3-1.5-0.5-2.3-0.4c-0.1,0-0.2,0-0.3,0
				C374.7,34.2,375.1,34.6,376,34.6z"/>
			<path fill="#56504A" d="M371.4,31.2c0.1,0.5-0.8,1.3,0.5,1.5c0.5-0.3,0.9-0.6,1.4-0.9c-0.4-0.3-0.7-0.5-1-0.8
				C372,30.7,371.6,30.7,371.4,31.2z"/>
			<path fill="#4F4944" d="M360.8,4.7c-0.6,0-1-0.8-1.7-0.4c-0.8,0.4-0.1,1.1-0.3,1.7c0.7-0.5,1.1-1.5,2.2-0.8
				C361.2,5.4,360.9,4.9,360.8,4.7z"/>
			<path fill="#21211F" d="M366.6,12.5c-0.2-0.1-0.2-0.3-0.2-0.5c-0.2-0.2-0.3-0.1-0.5,0c-0.1,1-1.2,1.5-1.4,2.4
				c0.4,0.2,0.7,0.8,1.2,0.2c-0.2-0.4-0.3-0.8,0.3-1c0.3-0.3,0.8-0.3,0.9-0.7C366.8,12.8,366.7,12.6,366.6,12.5z"/>
			<path fill="#48443F" d="M353.4,3.3c0.3,0,0.6,0.1,0.9,0c0.3-0.1,0.5-0.4,0.5-0.7c-0.1-0.5-0.4-0.1-0.6,0c-0.2,0.1-0.4,0.1-0.6,0
				c-0.3-0.3-0.7-0.5-1-0.1c-0.2,0.2-0.3,0.6-0.2,0.7C352.6,3.5,353,3.2,353.4,3.3z"/>
			<path fill="#48443F" d="M376.4,27.3c-0.6,0-0.8-0.9-1.4-0.7c0,0.5,0.5,0.9,0,1.4c0.4,0.2,0.8,0.4,0.7,0.9c0,0.2,0.1,0.5,0.4,0.4
				c0.2-0.1,0.3-0.3,0.2-0.5C375.6,28.3,376.3,27.8,376.4,27.3z"/>
			<path fill="#49443F" d="M369.5,30c-0.3-0.8-1.2-0.6-1.5-0.4c-0.9,0.5,0.4,0.9,0.3,1.5c-0.1,0.3,0.4-0.3,0.7-0.4
				C369,30.4,369.6,30.4,369.5,30z"/>
			<path fill="#2B2A28" d="M374.3,41.5c0.1,0.5-0.3,1,0,1.9c0.1-1.2,1.3-1,1.4-1.9c0.1-0.1,0.3-0.2,0.4-0.4c0,0,0-0.1,0.1-0.1
				c-0.5-0.3-1-0.3-1.3,0.3c-0.1,0.2-0.3,0.2-0.4,0C374.3,41.2,374.2,41.3,374.3,41.5z"/>
			<path fill="#534D47" d="M376.9,45.8c0.6,0.6,0.9,1.2,0.2,1.9c0.6,0.4,0.9-0.1,1.2-0.5c0.5-0.7,0.2-1.3-0.5-1.7
				c-0.2-0.1-0.3-0.1-0.5,0c-0.1,0-0.3,0.1-0.4,0.2C377,45.8,376.9,45.8,376.9,45.8z"/>
			<path fill="#504A45" d="M375,39.1c0.5,0,0.7,0.6,1.2,0.7l0,0c0.3-1.1,0.1-1.8-1.2-1.9c0,0,0,0,0,0c-0.1,0.4-0.1,0.8-0.2,1.2
				C374.9,39.2,374.9,39.2,375,39.1z"/>
			<path fill="#534D47" d="M360.3,19.7c0.7,0.3,1.1,0.6,1.6,0.8c0.2,0.1,0.7,0.3,0.8-0.3c0-0.3,0.4-0.6,0-0.8
				c-0.3-0.2-0.6,0.1-0.8,0.3C361.5,20.1,361,19.3,360.3,19.7z"/>
			<path fill="#4A4540" d="M357.9,6.2c0-0.6-0.3-0.5-0.7-0.5c-0.2-0.2-0.7-0.2-0.4-0.7l0.1,0l0.1,0c0.1-0.2,0.1-0.3,0-0.4
				c-0.7,0.2-1.5,0.4-1,1.4c0.2,0.3,0.4,0.7,1,0.6C357.3,6.6,357.7,6.4,357.9,6.2z"/>
			<path fill="#514B45" d="M370.4,35.3c0.2-0.1,0.5-0.2,0.5-0.2c-0.2-1,0.7-0.9,1.2-1.2c-0.1-0.5-0.3-0.8-0.8-0.9
				c-0.4,0-0.3,0.5-0.5,0.7c-0.1,0.3-0.5,0.4-0.4,0.8C370.7,34.8,370.3,35,370.4,35.3z"/>
			<path fill="#232220" d="M369,27.6c-0.8-0.5-1.6-0.9-2.3-1.4c-0.2-0.1-0.3-0.3-0.5-0.1c-0.1,0.1-0.2,0.3-0.2,0.4
				c0,0.4,0.3,0.3,0.5,0.4c0.7,0.1,1.3,0.4,1.8,1C368.6,28,368.9,28.1,369,27.6z"/>
			<path fill="#3B3835" d="M357,6.5c-0.4,0-0.6-0.4-1-0.5c0,0.5,0.1,0.9,0.3,1.3c0.2,0.3,0.4,0.7,0.6,1c0.1-0.6,1-0.5,1-1.1
				C357.6,7,357.1,6.9,357,6.5z"/>
			<path fill="#4F4944" d="M373.8,28.6c-0.2-0.4-0.1-1.1-1-1.1c-0.5,0-0.4,0.3-0.5,0.6c0,0.3,0.2,0.6,0.3,0.9
				c0.1,0.2,0.1,0.3,0.3,0.4C373.6,29.5,373.6,28.9,373.8,28.6L373.8,28.6z"/>
			<path fill="#534D47" d="M369,27.6c-0.2,0.2-0.5,0.2-0.7,0.2c0,0.5-0.1,1,0.5,1.1c0.6,0.1,0.9-0.6,1-0.9
				C370,27.6,369.4,27.6,369,27.6z"/>
			<path fill="#524C47" d="M377.6,44.8c0.3-0.7,0.1-1.2-0.6-1.1c-0.4,0-0.7-0.5-1.1-0.2c0.1,0.6,0.9,0.7,1,1.3
				C377.1,45.1,377.4,45.1,377.6,44.8z"/>
			<path fill="#534D47" d="M365.6,6.9c-0.2-0.1-0.3-0.2-0.5-0.2c0.1,0.4,0.5,0.9-0.2,1.2c-0.2,0.1-0.4,0.2-0.2,0.5
				c0.3,0.1,0.1,0.7,0.5,0.7c0.4-0.1,0.2-0.4,0.2-0.7c0-0.6,1.1-0.2,0.9-0.9C366.2,7.2,365.9,7,365.6,6.9z"/>
			<path fill="#131312" d="M378.4,47.3c-0.4,0.2-0.8,0.3-1.2,0.5c-0.8,1.5,0.6,1,1.2,1.2c0.3-0.4,0-0.8,0-1.3
				C378.4,47.6,378.4,47.4,378.4,47.3z"/>
			<path fill="#5B544D" d="M372.8,29.3c0-0.2,0-0.3,0-0.5c-0.6-0.1-0.9,0.1-1,0.7c0.3,0.4,0.1,0.9,0.5,1.2l0,0
				c0.3,0,0.6,0.1,0.7-0.3c0-0.2,0-0.3,0-0.5C373.2,29.7,373,29.5,372.8,29.3z"/>
			<path fill="#46423D" d="M357.7,12.2c-0.6,0.1-1.2-0.5-1.8,0.2c0.6,0.2,1.2,0.3,1.5,1c0.1,0.3,0.6,0,0.6-0.5
				C357.8,12.7,358,12.4,357.7,12.2z"/>
			<path fill="#353330" d="M360.6,13.4c-0.3-0.1-0.5-0.6-1-0.3c-0.2,0-0.3,0-0.2,0.3c0.7,0.4,1.3,1.6,2.1,0.2c0-0.2-0.1-0.5,0-0.7
				c-0.6-0.5-0.4,0.3-0.7,0.4C360.8,13.6,360.7,13.6,360.6,13.4z"/>
			<path fill="#5A534D" d="M373.3,25.6c0-0.2,0-0.3,0-0.5c-0.3-0.3-0.5-0.6-0.8-0.9c-0.3-0.2-0.4-0.1-0.5,0.2
				c0.1,0.6-0.5,0.4-0.7,0.8c0.2,0.2,0.4,0.4,0.7,0.4c0.2-0.1,0.4-0.4,0.7-0.2C372.8,25.8,373,25.8,373.3,25.6z"/>
			<path fill="#3A3733" d="M368.5,46.5c-0.5-0.7-0.1-1.7-0.7-2.4c-0.1-0.1-0.2-0.1-0.2,0c-0.3,0.5-0.3,0.9,0.1,1.4
				c0.3,0.3,0.4,0.8,0.4,1.2C368.3,46.9,368.5,47,368.5,46.5z"/>
			<path fill="#292826" d="M367.3,17.3c0,0.1,0,0.2,0,0.2c0.2,0.2,0.2,0.5,0.5,0.7c0.3,0.2,0.6,0.3,1,0.1c0.4-0.3,0.4-0.7,0.1-1.1
				c-0.2-0.2-0.6-0.3-0.8-0.6C367.4,16.3,367.2,16.5,367.3,17.3z"/>
			<path fill="#56504A" d="M363.3,37.2c0.3-0.9-0.3-1.6-0.1-2.6c-0.5,0.4-0.8,0.7-1.2,1.1C362.6,36.2,363.5,36.2,363.3,37.2z"/>
			<path fill="#504A45" d="M357.6,34.5c0.1,0.4,0.3,0.6,0.6,0.6c0.3,0,0.7-0.3,0.7-0.7c0-0.4-0.2-0.6-0.6-0.6
				C357.9,33.9,357.7,34.2,357.6,34.5z"/>
			<path fill="#514B46" d="M367.5,9.6c0.1-0.6,0.1-0.5-0.3-0.7c-0.3-0.1-0.1-0.5-0.2-0.7c-0.3,0.2-0.8,0.3-0.8,0.8
				c0.1,0.5,0.6,0.5,1,0.7C367.4,9.7,367.4,9.7,367.5,9.6z"/>
			<path fill="#060606" d="M367.3,17.3c0.2-0.2,0.1-0.9,0.7-0.5c0.2,0,0.4-0.1,0.6-0.3c0.1-0.2,0-0.4,0-0.7
				c-0.1-0.2-0.2-0.6-0.6-0.5c-0.2,0.2-0.4,0.3-0.5,0.5c-0.3,0.3-0.6,0.5-0.8,1c0,0.3,0.2,0.4,0.4,0.5
				C367.2,17.3,367.2,17.3,367.3,17.3z"/>
			<path fill="#5C554E" d="M364.6,19c0.4,0.8,1.2,0.9,2,0.9c0.1-1-0.6-1.1-1.3-1.4C365.1,18.7,364.6,18.4,364.6,19z"/>
			<path fill="#312F2C" d="M371.9,29.5c0.3-0.2,0.6-0.5,1-0.7c0-0.4-0.2-0.6-0.5-0.7c-0.2,0-0.4,0-0.5,0.2c-0.1,0.2-0.2,0.3-0.3,0.5
				c-0.3,0.2-0.6,0.4-1,0.7c0.2,0.2,0.4,0.2,0.6,0C371.4,29.5,371.6,29.5,371.9,29.5z"/>
			<path fill="#4B4641" d="M374.6,25.7c-0.3-0.3-0.7,0.1-1,0c0,0,0,0.1,0,0.1c0.1,0.5-1,0.6-0.5,1.1c0.4,0.4,0.8-0.4,1.2-0.5
				C374.4,26.2,374.5,25.9,374.6,25.7z"/>
			<path fill="#58514B" d="M359,6.3c-0.5,1-0.5,1.6,0.4,2C359.9,7.6,359.3,7.1,359,6.3z"/>
			<path fill="#4E4944" d="M358,16.3c0.1,0.3,0.3,0.6,0.6,0.4c0.3-0.1,0.9,0,0.9-0.5c0-0.2,0-0.7-0.5-0.5
				C358.6,15.8,358.1,15.8,358,16.3z"/>
			<path fill="#20201E" d="M366,33.5c-0.1-0.5,0.2-1.2-0.6-1.4c-0.2,0-0.5,0-0.6,0.2C365.4,32.5,365,33.6,366,33.5z"/>
			<path fill="#5B544D" d="M366.4,29.7c-0.1-0.3-1-0.5,0-0.8c0.3-0.7-0.3-0.7-0.7-0.9c0,0-0.1,0-0.1,0c-0.2,0.4-0.6,0.8-0.3,1.4
				c0.1,0.1,0.3,0.2,0.4,0.3C365.8,30,366.1,30,366.4,29.7z"/>
			<path fill="#252423" d="M369.5,30c-0.1,0.3-0.5,0.3-0.5,0.7c0.4,1-0.6,0.7-1,1.1c1,0.4,1.5,0.2,1.4-0.8
				C369.4,30.6,370,30.3,369.5,30z"/>
			<path fill="#1F1E1C" d="M365.7,11.3c-0.1-0.5-0.4-0.4-0.7-0.2c-0.4-0.1-0.7-0.6-1.2-0.2c0,0.4,0.1,0.7,0.3,1
				c0.2,0.1,0.5,0.1,0.8,0.1C365.2,11.9,365.4,11.6,365.7,11.3L365.7,11.3z"/>
			<path fill="#4E4943" d="M356.1,9c0.2,0.6,0.7,0.5,1,0.8c0.2,0.2,0.4,0.3,0.6,0.1c0.1-0.1,0.1-0.3,0.1-0.4c0-0.3-0.1-0.4-0.4-0.5
				C357,9,356.6,9,356.1,9z"/>
			<path fill="#5B544D" d="M365.6,14.7c-0.4,0.2-0.7-0.5-1.2-0.2c-0.3,1,0.6,1,1.1,1.3C365.5,15.4,366.1,15,365.6,14.7z"/>
			<path fill="#4E4843" d="M353.6,6.9c0.1,0.5,0.2,0.9,0.2,1.5c0.5-0.2,0.9-0.6,0.8-1.1C354.6,6.6,353.9,7.1,353.6,6.9z"/>
			<path fill="#0D0D0C" d="M365.6,6.9c0.1,0.5,0.4,0.5,0.8,0.5c0.3,0,0.6,0,0.7-0.3c0.1-0.3,0-0.5-0.2-0.7
				C366.3,6.3,365.8,6.4,365.6,6.9z"/>
			<path fill="#5B544D" d="M367,23.7c0.6,0.4,0.8-0.3,1.3-0.5c0,0,0,0,0,0c0.1-0.2,0.1-0.3,0-0.5c-0.2-0.5-0.4-0.7-0.8-0.2
				C367.3,22.9,367.2,23.3,367,23.7z"/>
			<path fill="#47433E" d="M378.4,47.7c-0.5,0.4-0.1,0.8,0,1.3c-0.1,0.2-0.1,0.5-0.2,0.7c0.3,0.3,0.6,0.3,0.9,0
				C378.7,49,379.1,48.2,378.4,47.7z"/>
			<path fill="#4B4741" d="M373.1,45.3c-0.5-0.3-0.9-0.8-1.5-0.3c-0.2,0.2-0.2,0.4,0,0.6C372.1,46.1,372.5,45.5,373.1,45.3z"/>
			<path fill="#423F3A" d="M358.3,28.6c-0.2,0.3-0.8-0.1-0.7,0.4c0,0.3,0.1,0.8,0.6,0.7c0.3,0,0.6-0.3,0.7-0.6
				C358.8,28.9,358.7,28.6,358.3,28.6z"/>
			<path fill="#44413C" d="M363.7,26.9c0.1-0.1,0.1-0.1,0.2-0.2c-0.3-0.1-0.6-0.3-0.7-0.8c0-0.1,0-0.1,0-0.2c-0.4-0.3-0.7,0-0.9,0.3
				c0.3,0.6,0.6,1.1,0.9,1.7C363.5,27.4,363.5,27.1,363.7,26.9z"/>
			<path fill="#34312F" d="M370.4,23.8c0.2-0.1,0.5-0.3,0.7-0.4c-0.3,0-0.5-0.2-0.5-0.5c0-0.2-0.1-0.3-0.3-0.3
				c-0.3,0.4-1.3,0.2-1.1,1.1C369.7,23.8,370.2,22.6,370.4,23.8z"/>
			<path fill="#4D4843" d="M347.6,2.9c0.3-0.2,0.6-0.4,0.7-1c-0.6,0.7-1.3,0.3-1.9,0.3c-0.2,0.2-0.1,0.3,0,0.5
				c0.2,0.3,0.5,0.2,0.7,0.2C347.3,3,347.5,3,347.6,2.9z"/>
			<path fill="#141514" d="M363.7,13.7c0.4-0.4,0.1-0.7-0.2-1c-0.3-0.2-0.6-0.7-1-0.2c0.1,0.3-0.4,0.6,0,0.9
				C362.9,13.6,363.3,13.5,363.7,13.7z"/>
			<path fill="#524C46" d="M361.3,9.6c-0.7,0.2-0.2,0.9-0.6,1.2c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0,0.2-0.3,0.2-0.5
				C361.4,10.3,362,9.6,361.3,9.6z"/>
			<path fill="#4C4742" d="M374.3,41.5c0.1-0.1,0.1-0.2,0.2-0.2c-0.3-0.3-0.4-0.9-1-0.4c-0.5,0.4-0.5-0.4-0.9-0.3
				C372.9,41.4,373.6,41.4,374.3,41.5z"/>
			<path fill="#413E39" d="M354.6,16.6c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.2,0.3,0.7,0.7,0.5c0.3-0.1,0.3-0.4,0.3-0.7
				c-0.1-0.6-0.6-0.2-0.8-0.3C354.8,16.2,354.7,16.3,354.6,16.6z"/>
			<path fill="#3A3733" d="M357.3,21.4c0.7,0.3,1.2,0.5,1.9-0.4C358.5,21.1,357.9,20.6,357.3,21.4z"/>
			<path fill="#171716" d="M379.1,61.7c-0.5,0-0.5,0.4-0.5,0.8c0,0.2-0.1,0.5,0.3,0.5c0.6,0,0.4-0.5,0.5-0.8
				C379.5,61.8,379.3,61.7,379.1,61.7z"/>
			<path fill="#393733" d="M343.5,3.5c0.6,0,1.1-0.2,1.6,0c0.1,0.1,0.3,0,0.3-0.2c0-0.1-0.1-0.3-0.1-0.3
				C344.7,2.9,344,2.6,343.5,3.5z"/>
			<path fill="#34322F" d="M364.7,8.4c0.1-0.2,0.2-0.3,0.2-0.5c-0.4-0.2,0-0.7-0.4-0.9c-0.2,0-0.1,0.2-0.2,0.2
				C363.2,8,363.7,8.3,364.7,8.4z"/>
			<path fill="#121211" d="M365.4,18.7c0.4,0.4,1.1,0.6,1.2,1.2c0.2-0.2,0.3-0.3,0.5-0.5c0-0.1,0-0.2,0-0.3c0-0.2-0.6,0-0.4-0.5
				c0-0.2,0-0.3-0.1-0.4c-0.2,0-0.3,0.3-0.5,0C365.6,18,365.4,18.2,365.4,18.7z"/>
			<path fill="#3D3A36" d="M365.4,18.7c0.1-0.3,0.4-0.5,0.7-0.5c0-0.4-0.3-0.6-0.6-0.3c-0.4,0.3-0.7,0.6-1.2,0.6
				c-0.2,0-0.4,0.2-0.3,0.4c0.1,0.4,0.4,0.2,0.7,0.1C364.8,18.7,365.1,18.7,365.4,18.7z"/>
			<path fill="#47423E" d="M371.4,31.2c0.3-0.2,0.6-0.3,0.9-0.2c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c-0.5-0.1-1-0.1-1.4-0.4
				c-0.4-0.2-0.7-0.1-0.7,0.4c0.1,0.3,0.5,0.3,0.7,0.5C371.1,31.2,371.2,31.2,371.4,31.2z"/>
			<path fill="#534D47" d="M359,24.1c-0.6,0.6,0,1.2-0.1,1.7c0,0,0.2,0.2,0.3,0.2c0.2,0.1,0.3-0.1,0.3-0.3
				C359.4,25.1,359.3,24.6,359,24.1z"/>
			<path fill="#1D1D1C" d="M370.9,31.2c-0.1-0.3-0.4-0.5-0.7-0.5c-0.8,0.2-0.4,0.8-0.5,1.2C370.3,31.9,370.5,31.4,370.9,31.2z"/>
			<path fill="#4E4843" d="M357.5,3.6c0.1-0.3,0.8-0.2,0.7-0.7c-0.4-0.1-0.7-0.8-1.1-0.3C356.9,2.9,357,3.4,357.5,3.6z"/>
			<path fill="#5D554E" d="M367.1,19.2c0,0.1,0,0.1,0,0.2c0.3,0.2,0.6,0.3,0.9,0.3c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3
				c0.1-0.3,0.5-0.6,0.4-1c-0.3,0-0.7-0.1-1-0.2c0,0-0.1,0.1-0.1,0.1C367.5,18.5,367.4,18.9,367.1,19.2z"/>
			<path fill="#262523" d="M347,5.4c0.4,0.2,0.3,0.9,0.9,0.7c0.2-0.1,0.5-0.3,0.3-0.5C348,5,347.5,5.3,347,5.4z"/>
			<path fill="#4D4843" d="M354.6,16.6c0.2-0.1,0.3-0.2,0.5-0.2c0.2-0.3,0.2-0.7-0.1-0.9c-0.2-0.2-0.5,0-0.6,0.2
				C354.1,15.9,354.2,16.3,354.6,16.6z"/>
			<path fill="#111110" d="M357,6.5c-0.5,1.2,0.5,0.7,1,0.8c-0.2-0.3-0.2-0.7,0-1C357.6,6.3,357.2,6.1,357,6.5z"/>
			<path fill="#3D3936" d="M356,21.8c-0.3,0.3-0.8,0.2-1,0.6c0.5,0.3,1.1-0.1,1.5,0.4c0.2-0.5-0.3-0.6-0.3-1
				C356.2,21.7,356.1,21.7,356,21.8z"/>
			<path fill="#504B45" d="M360.6,21.6c0.4,0,0.7-0.2,0.7-0.6c0-0.2-0.2-0.4-0.4-0.3c-0.3,0.1-0.9-0.2-0.8,0.5
				C360.3,21.2,360.3,21.6,360.6,21.6z"/>
			<path fill="#3A3834" d="M368,19.4c0,0.1,0,0.2,0,0.3c-0.5,0.6,0.1,0.9,0.2,1.4c0.1,0,0.2,0,0.3-0.1c0.3-0.2,0.5-0.3,0.5-0.7
				c-0.1-0.3-0.3-0.5-0.5-0.8C368.4,19.4,368.3,19.3,368,19.4z"/>
			<path fill="#4E4943" d="M368.2,40.6c-0.5,0-0.7-0.8-1.2-0.4c-0.1,0.1,0,0.5,0.1,0.7C367.5,41.3,367.8,40.8,368.2,40.6z"/>
			<path fill="#46423D" d="M371.4,35.7c-0.4,0.3-0.6,0.7-0.8,1.2c0.1,0.2,0.1,0.6,0.2,0.6c0.4,0.1,0.2-0.5,0.5-0.6
				c0.3-0.1,0.1-0.5,0.3-0.7l0-0.1C371.7,35.9,371.6,35.8,371.4,35.7z"/>
			<path fill="#292826" d="M361.3,9.6c0.4,0.2,0.1,0.8,0.5,1c0.1-0.4-0.3-1.1,0.6-1.2c0.4-0.1,0.3-0.2-0.3-0.7
				c-0.1-0.1-0.2-0.1-0.3,0l0,0c-0.2,0.2-0.3,0.4-0.5,0.7C361.3,9.4,361.3,9.5,361.3,9.6z"/>
			<path fill="#5D564F" d="M366.4,29.7c-0.3,0-0.5,0-0.8,0c-0.1,0.3-0.4,0.6,0,0.9c0.3,0.2,0.5,0,0.7-0.1
				C366.7,30.3,366.6,30,366.4,29.7z"/>
			<path fill="#59534C" d="M351.9,10.7c-0.4,0.3-1-0.4-1.3,0.3c-0.1,0.3,0.3,0.3,0.5,0.4C351.7,11.7,351.7,11.1,351.9,10.7z"/>
			<path fill="#4E4843" d="M370.4,22.5c0.1,0.1,0.2,0.2,0.3,0.3c0.3-0.2,0.9-0.3,0.8-0.8c-0.1-0.4-0.6-0.4-1-0.2
				C370.4,22.1,370.4,22.3,370.4,22.5z"/>
			<path fill="#544E48" d="M370,50.7c-0.4,0.1-0.7,0.4-0.8,0.7c-0.2,0.4,0.3,0.3,0.5,0.4c0.5,0.1,0.3-0.4,0.4-0.6
				C370.3,51,370.1,50.8,370,50.7z"/>
			<path fill="#43403B" d="M370.9,42.2c0-0.8-0.7-1-0.9-1.7c-0.7,1.1,0.5,1.1,0.7,1.7C370.8,42.3,370.8,42.3,370.9,42.2z"/>
			<path fill="#45413D" d="M378.1,50.1c0.3,0.2,0.6,0.4,0.8,0.7c0.5,0.7,0.7,0.3,0.9-0.2c0.1-0.1,0.1-0.1,0.1-0.1
				c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2-0.1C379.1,50.1,378.7,49.7,378.1,50.1z"/>
			<path fill="#4A4540" d="M358.8,10.4c0.1,0.2,0.1,0.6,0.3,0.6c0.6-0.2-0.1-1,0.4-1.3c0.1,0-0.1-0.3-0.3-0.2
				C358.6,9.5,359.1,10.1,358.8,10.4z"/>
			<path fill="#514B46" d="M359.4,13.5c0.1-0.1,0.1-0.2,0.2-0.3c0-0.5-0.4-0.7-0.7-0.5c-0.4,0.2-0.2,0.6,0,0.9
				C359.2,14,359.3,13.7,359.4,13.5z"/>
			<path fill="#524C46" d="M373.6,33.7c-0.1-0.6,0.5-0.7,0.7-1c-0.5-0.4-0.9-0.1-1.1,0.3C373,33.2,373,33.7,373.6,33.7z"/>
			<path fill="#4F4944" d="M369.8,27.5c0.6,0,0.8,0,1.1,0c0.2,0,0.5,0.1,0.6-0.1c0.1-0.1,0.1-0.3-0.1-0.4c-0.3,0-0.5-0.5-0.8-0.1
				C370.5,27.1,370.3,27.2,369.8,27.5z"/>
			<path fill="#48433E" d="M350.5,1c-0.4-0.2-0.6-0.1-0.8,0c-0.3,0.1-0.7,0.1-0.8,0.6c0,0.1,0.1,0.2,0.1,0.3
				c0.4,0.2,0.5-0.3,0.7-0.4C350,1.3,350.2,1.1,350.5,1z"/>
			<path fill="#57504A" d="M365.5,20.7c0.1,0.2,0.1,0.4,0.3,0.5c0.4,0.2,0,0.8,0.5,0.8c0.3,0,0.2-0.3,0.2-0.6
				C366.5,20.7,366.1,20.6,365.5,20.7z"/>
			<path fill="#201F1E" d="M358.6,20.3c0.1,0,0.2,0,0.2,0c0.4-0.1,0.8-0.2,0.5-0.8c-0.1-0.3-0.2-0.6-0.5-0.5
				c-0.5,0.1,0.1,0.6-0.2,0.9C358.5,20.1,358.5,20.2,358.6,20.3z"/>
			<path fill="#504A45" d="M348.7,14.7c-0.4,0.1-0.6,0.3-0.7,0.7c0,0.2,0.2,0.3,0.4,0.4c0.5,0,0.6-0.3,0.6-0.7
				C349.1,14.9,348.9,14.8,348.7,14.7z"/>
			<path fill="#4A4540" d="M364.7,24.5c0,0.2,0,0.3,0,0.5c-0.1,0.1-0.1,0.1,0,0.2c0,0,0,0.1,0.1,0.1c0.2,0.1,0.3,0.2,0.5,0.3
				c0.1,0.1,0.2,0.4,0.5,0.2C366.2,24.6,365,24.8,364.7,24.5L364.7,24.5z"/>
			<path fill="#1C1C1A" d="M364.4,48.2c-0.7-0.1,0.1-0.9-0.5-1c-0.6,1-0.6,1,0.5,1.5C364.6,48.5,364.6,48.4,364.4,48.2z"/>
			<path fill="#3A3733" d="M367.1,17.3c-0.1-0.2-0.2-0.3-0.3-0.5c0-0.3,0.2-0.6,0-0.9c-0.4-0.2-0.7-0.4-0.9,0.2
				c0.2,0,0.5,0.1,0.4,0.4C366,17.3,366.6,17.3,367.1,17.3z"/>
			<path fill="#4A4540" d="M387.7,59.1c0.1,0.5,0.4,0.3,0.7,0.3c0.3,0.1,0.3-0.3,0.3-0.5c0-0.3-0.3-0.5-0.5-0.5
				C387.9,58.5,387.8,58.9,387.7,59.1z"/>
			<path fill="#514B45" d="M363.7,26.9c-0.5,0-0.5,0.4-0.5,0.7c0.5,0.4,0.9,0,1.4-0.2C364.5,26.9,363.8,27.5,363.7,26.9z"/>
			<path fill="#4C4742" d="M368,36.9c0.9,0.3,0.9-0.5,1.2-0.9c-0.5-0.4-0.6,0.2-0.9,0.3C368.1,36.4,367.9,36.6,368,36.9z"/>
			<path fill="#272624" d="M350.9,18.5c-0.2-0.8-0.8-0.8-1.4-0.9c0,0-0.2,0.2-0.1,0.3C349.7,18.5,350.3,18.1,350.9,18.5z"/>
			<path fill="#403D38" d="M369.5,34.3c0.2,0,0.7,0.1,0.6-0.2c-0.1-0.3,0.4-0.9-0.4-1C369.4,33.5,369.1,33.8,369.5,34.3z"/>
			<path fill="#47433E" d="M355.4,4.8c-0.2-0.3,0.7-1.2-0.5-1c-0.2,0-0.4,0.5-0.7,0.8C354.5,4.8,354.9,5,355.4,4.8z"/>
			<path fill="#47433E" d="M363.8,40.9c-0.3,0.1-0.7-0.3-0.8,0.2c0,0.6,0.5,0.3,0.8,0.4c0.2,0,0.5,0,0.4-0.3
				C364.2,40.9,364.2,40.6,363.8,40.9z"/>
			<path fill="#4E4944" d="M379.8,53.6c0.4,0.3,0.7,0.3,0.7-0.2c0-0.5,0-0.9-0.7-0.7C379.5,52.9,379.6,53.2,379.8,53.6z"/>
			<path fill="#554F49" d="M372.8,34.8c-1.1-0.3-1.1,0.2-0.7,1C372.9,35.8,372.7,35.2,372.8,34.8z"/>
			<path fill="#1A1918" d="M376.1,52.8c-0.2-0.1-0.3-0.2-0.4,0c-0.1,0.4-0.8,0.4-0.6,1c0,0.1,0.1,0.1,0.2,0.1
				C375.9,53.7,376,53.2,376.1,52.8z"/>
			<path fill="#1D1C1B" d="M357.7,12.2c0,0.3-0.1,0.6,0.2,0.7c0.3-0.3,0.5-0.7,0.8-1C358.2,11.3,358,11.9,357.7,12.2z"/>
			<path fill="#4F4A45" d="M376,50.4c0,0.3,0.1,0.4,0.4,0.5c0.5,0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.5-0.2-0.5
				C376.2,49.9,376,50.1,376,50.4z"/>
			<path fill="#48443F" d="M365.6,11.3c-0.3,0.1-0.5,0.5-1,0.5c-0.1,0.2-0.1,0.3,0,0.5c0.4-0.2,0.7-0.6,1.2-0.3c0.2,0,0.3,0,0.5,0
				C366.5,11.3,365.7,11.7,365.6,11.3z"/>
			<path fill="#171716" d="M361.8,8.6c0.1,0,0.2,0,0.3,0c0.3-0.5-0.3-0.8-0.2-1.2l0,0C359.9,7.8,361.8,8.2,361.8,8.6z"/>
			<path fill="#57504A" d="M361.3,30.7c0.4,0.3,0.7,0.7,1.2,0.4c0.1-0.1,0.2-0.3,0-0.4C362.2,30.4,361.8,30.3,361.3,30.7z"/>
			<path fill="#4A4540" d="M362.8,17.1c0.4-0.1,0.6,0.5,1.1,0.3c-0.5-1-0.7-1.1-1.4-0.4C362.6,17.1,362.7,17.1,362.8,17.1z"/>
			<path fill="#46413D" d="M360.4,15.3c0.9,0.2,0.9,0.2,1.3-0.6c-0.4,0.1-0.9,0.1-1.3,0.2C360.2,15,360.2,15.2,360.4,15.3z"/>
			<path fill="#1D1C1B" d="M360.5,27.1c-0.3,0-0.5,0.1-0.5,0.4c0,0.3,0.3,0.4,0.5,0.4c0.2,0,0.5-0.1,0.5-0.4
				C361,27.3,360.7,27.2,360.5,27.1z"/>
			<path fill="#4B4641" d="M359.3,27.4c-0.7,0-1-0.4-1.3,0c-0.1,0.2,0.1,0.6,0.3,0.7C358.9,28.3,358.7,27.7,359.3,27.4z"/>
			<path fill="#4A4540" d="M367.5,9.6c-0.1,0-0.2,0-0.3,0c0,0.2,0,0.5,0,0.7c0,0.1,0,0.2,0.1,0.2c0.2,0.2,0.5,0.3,0.7,0.5l0,0
				c0.3,0.1,0.4-0.1,0.5-0.3C368.2,10.4,367.8,10,367.5,9.6z"/>
			<path fill="#413D39" d="M371.5,43.8c-0.3-0.1-0.5,0.1-0.5,0.3c0,0.3,0.2,0.4,0.5,0.4c0.3,0,0.6-0.1,0.6-0.4
				C372,43.8,371.7,43.9,371.5,43.8z"/>
			<path fill="#433F3B" d="M362.8,31.8c0.2,0.1,0.3,0.2,0.3,0.1c0.4-0.3,1,0.1,1.3-0.4c0-0.1-0.1-0.3-0.1-0.3
				C363.7,31.1,363.3,31.4,362.8,31.8z"/>
			<path fill="#4C4742" d="M366.1,11.1c0.2,0,0.5,0.3,0.6,0c0.3-0.4-0.2-0.7-0.2-1C365.8,10.2,365.8,10.5,366.1,11.1z"/>
			<path fill="#413E3A" d="M362.5,12.5c0.4-0.2,0.6,0.4,1,0.2c-0.1-0.4-0.2-0.8-0.3-1.2c-0.4-0.2-0.4,0.5-0.8,0.4
				c-0.2,0.1-0.3,0.1-0.5,0.2C362.1,12.2,362.3,12.4,362.5,12.5z"/>
			<path fill="#242322" d="M354.1,4.8c-0.4,0.1-0.5-0.5-0.9-0.4c0.1,0.9,0.1,0.9,1.4,0.7C354.6,4.6,354.4,4.6,354.1,4.8z"/>
			<path fill="#4C4742" d="M365.1,44.4c-0.2,0.2-0.4,0.4-0.3,0.7c0,0.1,0.2,0.2,0.2,0.2c0.4-0.3,0.9-0.4,0.8-1.1
				C365.5,43.9,365.3,44.1,365.1,44.4z"/>
			<path fill="#1B1B1A" d="M364.7,25.2c0-0.1,0-0.2,0-0.2c-0.3,0.1-0.8-0.2-0.9,0.3c-0.1,0.3,0.2,0.4,0.5,0.4
				C364.8,25.9,364.6,25.4,364.7,25.2z"/>
			<path fill="#232321" d="M370.6,54.2c0.2,0.3,0.4,0.5,0.7,0.5c0.2,0,0.3-0.2,0.2-0.3c-0.1-0.3-0.3-0.5-0.7-0.5
				C370.7,53.8,370.6,54,370.6,54.2z"/>
			<path fill="#292826" d="M365.6,34.7c0.5,0,0.9,0.1,1.1-0.2c0.1-0.1-0.1-0.4-0.3-0.4C365.9,33.8,365.8,34.2,365.6,34.7z"/>
			<path fill="#48443F" d="M352.6,7.5c0.1,0.2,0.2,0.6,0.4,0.4c0.3-0.3,0.3-0.8,0.1-1.2c-0.2-0.4-0.3,0-0.4,0.2
				C352.5,7.1,352.5,7.3,352.6,7.5z"/>
			<path fill="#3C3935" d="M352.6,7.5c0-0.2,0-0.4,0-0.5c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.2-0.9,0.1-0.9,0.6c0,0.3,0.2,0.6,0.6,0.2
				C352.3,7.7,352.4,7.4,352.6,7.5z"/>
			<path fill="#1F1E1D" d="M364.7,24.5c0.2-0.7-0.5-0.5-0.8-0.8c0,0-0.3,0.1-0.3,0.2c0,0.1,0.1,0.4,0.2,0.4
				C364.1,24.4,364.4,24.4,364.7,24.5C364.7,24.5,364.7,24.5,364.7,24.5z"/>
			<path fill="#3A3834" d="M368,36.9c0.1-0.2,0.2-0.5,0.2-0.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.2-0.5-0.5-0.8-0.2
				c-0.3,0.4,0.3,0.3,0.4,0.5C367.8,36.6,367.9,36.8,368,36.9z"/>
			<path fill="#2F2D2B" d="M356,21.8c0.1,0,0.2,0,0.2,0c0.2-0.3,0.5-0.5,0.7-0.8C356.6,21.3,355.3,20.4,356,21.8z"/>
			<path fill="#44403C" d="M352.7,10.9c0.5,0.1,0.9,0.2,1.2-0.2c0-0.1,0-0.3,0-0.3C353.5,10.4,353.1,10.5,352.7,10.9z"/>
			<path fill="#413E3A" d="M357,4.6c0,0.1,0,0.3,0,0.4c0.2,0.1,0.4,0.1,0.6,0.2c0.3,0.1,0.5-0.1,0.5-0.3c0-0.1,0.1-0.4,0-0.4
				c-0.3-0.3-0.3,0.2-0.5,0.2C357.4,4.7,357.2,4.6,357,4.6z"/>
			<path fill="#383633" d="M363.7,10.8c0.5-0.2,0.8,0.5,1.2,0.2c-0.2-0.7-0.7-0.7-1.2-0.6C363.4,10.4,363.6,10.7,363.7,10.8z"/>
			<path fill="#3E3B37" d="M371.2,39.1c0-0.9,0.8,0.2,1-0.5c-0.5-0.1-1.1-0.6-1.5,0.3C370.8,39.1,370.9,39.2,371.2,39.1z"/>
			<path fill="#302F2C" d="M356.8,17.2c0.3,0.5,0.9,0.5,1.4,0.7c0-0.8-0.5-0.7-0.9-0.7C357.1,17.1,357,17.1,356.8,17.2z"/>
			<path fill="#1B1B1A" d="M386.3,54.8c-0.2,0.4-0.2,0.8,0.1,1.3C387,55.5,386.6,55.2,386.3,54.8z"/>
			<path fill="#2B2A27" d="M376.3,61.7c-0.1,0.5,0.3,0.5,0.5,0.6c0.1,0.1,0.3,0,0.3-0.2c-0.1-0.3-0.1-0.6-0.5-0.7
				C376.5,61.4,376.4,61.6,376.3,61.7z"/>
			<path fill="#3D3A36" d="M377.5,52.1c-0.3,0.1-0.3,0.4-0.3,0.7c0,0.2,0.1,0.5,0.3,0.4c0.3-0.1,0.3-0.4,0.3-0.7
				C377.7,52.3,377.8,52.1,377.5,52.1z"/>
			<path fill="#1D1D1C" d="M378.1,50.1c0.5,0.1,1,0.2,1.5,0.2c-0.2-0.2-0.3-0.5-0.5-0.7c-0.3,0-0.6,0-0.9,0
				C377.8,49.8,378.1,50,378.1,50.1z"/>
			<path fill="#59524C" d="M362.5,11.9c0.7,0.6,0.4-0.5,0.8-0.4c0-0.3-0.1-0.5-0.4-0.4C362.3,11.2,362.6,11.6,362.5,11.9z"/>
			<path fill="#353330" d="M364.4,48.2c0,0.2,0,0.3,0,0.5c0.3,0,0.7,0.2,0.8-0.2c0.1-0.2-0.1-0.4-0.2-0.5
				C364.7,47.7,364.6,48,364.4,48.2z"/>
			<path fill="#3B3835" d="M360.8,38.6c0.2,0.4-0.1,1.1,0.7,1c0.1-0.1,0.4-0.2,0.2-0.5c-0.4,0-0.5-0.3-0.7-0.5
				C361,38.5,360.9,38.5,360.8,38.6z"/>
			<path fill="#5B544E" d="M371.2,39.1c-0.2-0.1-0.3-0.1-0.5-0.2c-0.3,0.5,0.1,0.6,0.5,0.8c0.2,0.1,0.4,0.2,0.4-0.1
				C371.6,39.3,371.4,39.2,371.2,39.1z"/>
			<path fill="#1B1B1A" d="M362.3,25.9c0.3-0.1,0.6-0.2,0.9-0.3c-0.2-0.7-0.8-0.2-1.2-0.6C361.8,25.6,362.3,25.7,362.3,25.9z"/>
			<path fill="#45413D" d="M370.9,42.2c-0.1,0-0.1,0-0.2,0c-0.8-0.4-0.8,0.2-0.9,0.7c0.6,0.2,0.7-0.6,1.2-0.5l0,0
				C370.9,42.4,370.9,42.3,370.9,42.2z"/>
			<path fill="#44403C" d="M379.8,53.6c0-0.3,0-0.7,0-1c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.3-0.5-0.5-0.6-0.1c-0.1,0.3,0.2,0.5,0.4,0.6
				C379.4,53.3,379.6,53.5,379.8,53.6z"/>
			<path fill="#1E1E1C" d="M350,15.3c0.4,0.1,0.9,0.1,1.1-0.3c0,0,0-0.2-0.1-0.2C350.5,14.7,350.3,15,350,15.3z"/>
			<path fill="#2A2927" d="M374.3,43.8c0.1,0.3,0.2,0.3,0.4,0.3c0.3,0,0.4-0.2,0.4-0.4c0-0.1-0.2-0.3-0.2-0.3
				C374.7,43.5,374.5,43.7,374.3,43.8z"/>
			<path fill="#524C47" d="M371.6,28.8c0.1-0.1,0.6-0.2,0.3-0.5c-0.4-0.2-0.9,0.2-1.3,0.1C370.9,28.5,371.2,28.7,371.6,28.8z"/>
			<path fill="#4A4540" d="M370.2,36.7c0.1-0.5,0.2-1,0.2-1.5C370,35.7,369.2,36.1,370.2,36.7z"/>
			<path fill="#47433E" d="M360,18.6c0.2-0.3,0.3-0.7-0.1-0.9c-0.1-0.1-0.3,0-0.4,0.2C359.3,18.2,359.6,18.4,360,18.6z"/>
			<path fill="#4F4A44" d="M382.7,55c0.3-0.1,0.7,0.4,1,0c-0.3-0.6-0.8-0.5-1.2-0.2C382.4,54.9,382.4,55,382.7,55z"/>
			<path fill="#3A3733" d="M366.6,18.2c0,0.2,0,0.3,0,0.5c0.1,0.1,0.3,0.2,0.4,0.1c0.2-0.1,0.4-0.3,0.6-0.5c0.1-0.1,0.1-0.3,0.2-0.4
				c0,0,0,0,0,0c-0.1-0.2-0.3-0.4-0.5-0.5C367.3,18,366.9,18.1,366.6,18.2z"/>
			<path fill="#373532" d="M355.8,36.5c0,0.8,0.3,1,0.8,1.2C356.7,37,356.3,36.9,355.8,36.5z"/>
			<path fill="#21211F" d="M366,48.7c0.1,0.3,0.3,0.3,0.5,0.4c0.3,0.1,0.3-0.2,0.2-0.4c-0.1-0.1,0.1-0.5-0.2-0.4
				C366.3,48.3,366,48.4,366,48.7z"/>
			<path fill="#5C554E" d="M351.4,14.7c0.4-0.5,0.7-0.8,0.4-1.2c-0.1-0.1-0.3-0.2-0.4,0C351,13.9,351.5,14.1,351.4,14.7z"/>
			<path fill="#5E564F" d="M364.7,12.3c0-0.2,0-0.3,0-0.5c-0.2-0.4-0.5-0.1-0.7,0C364,12.3,364,12.8,364.7,12.3z"/>
			<path fill="#3A3734" d="M375.5,63.5c-0.5-0.5-0.9-0.5-1.4-0.2C374.5,63.7,374.9,63.5,375.5,63.5z"/>
			<path fill="#403C39" d="M373.7,46.8c1-0.1,1.1-0.5,0.9-1.2C374.5,46.1,374.1,46.3,373.7,46.8z"/>
			<path fill="#1D1C1B" d="M375.9,45.6c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0.3-0.6,0.4-0.1,0.8c0.1,0.1,0.3,0.3,0.4,0.3
				c0.4-0.2,0-0.6,0.2-0.8C376.2,45.9,376.1,45.7,375.9,45.6z"/>
			<path fill="#3D3A36" d="M374.5,55.9c-0.1,0.5-0.2,0.9,0.2,1.1c0,0,0.2,0,0.2-0.1C375.1,56.5,375,56.2,374.5,55.9z"/>
			<path fill="#141414" d="M365.6,28.1c0.2,0.4,0.7,0.4,0.7,0.9c0.1,0.2,0.1,0.2,0.3,0c0.3-0.5,0-0.8-0.2-1.2
				c-0.2-0.1-0.3-0.1-0.5,0C365.8,27.9,365.7,28,365.6,28.1z"/>
			<path fill="#433F3B" d="M389.2,62c0,0.2,0.1,0.4,0.3,0.4c0.4,0,0.3-0.3,0.4-0.6c0-0.2,0-0.5-0.3-0.4
				C389.4,61.5,389.3,61.8,389.2,62z"/>
			<path fill="#5A534D" d="M354.1,4.8c0.2,0,0.4,0,0.5,0.3c0.3,0.1,0.5,0.4,0.8,0.1c0.1-0.1,0.1-0.3-0.1-0.4
				c-0.4-0.2-0.8-0.3-1.2-0.2C354.1,4.6,354.1,4.7,354.1,4.8z"/>
			<path fill="#0E0E0E" d="M365.1,44.4c0.2-0.1,0.5-0.1,0.7-0.2c0-0.3-0.1-0.5-0.1-0.9C365.4,43.6,364.7,43.6,365.1,44.4z"/>
			<path fill="#2F2E2B" d="M374.3,52.2c-0.3-0.2-0.5-0.5-0.9-0.2c-0.1,0.1-0.1,0.3,0,0.4C373.8,52.7,374.1,52.4,374.3,52.2z"/>
			<path fill="#1F1E1D" d="M359.5,47.9c0.4,0,0.7-0.2,0.7-0.6c0-0.1-0.2-0.2-0.2-0.2C359.5,47.2,359.5,47.5,359.5,47.9z"/>
			<path fill="#4C4742" d="M375.9,45.6c0,0.2,0,0.4,0.3,0.5c0.2-0.1,0.5-0.2,0.7-0.3c0,0,0,0,0,0c0.1-0.1,0.2-0.3,0.1-0.5
				c-0.1-0.1-0.2-0.1-0.3,0C376.4,45.3,376.1,45.3,375.9,45.6z"/>
			<path fill="#4F4A45" d="M366.5,38.4c0-0.5,0-0.8-0.3-1.1C365.7,37.8,365.8,38.1,366.5,38.4z"/>
			<path fill="#1A1A19" d="M369.5,45.9c0,0.2,0,0.4,0.2,0.3c0.2,0,0.3-0.2,0.4-0.4c0-0.2,0-0.4-0.3-0.4
				C369.6,45.5,369.5,45.7,369.5,45.9z"/>
			<path fill="#252523" d="M352.2,16.2c0.2-0.1,0.3-0.3,0.4-0.5c0-0.2-0.1-0.3-0.3-0.3c-0.3,0-0.4,0.3-0.3,0.5
				C352,16,352,16.2,352.2,16.2z"/>
			<path fill="#5A534C" d="M368.5,8.2c0-0.1,0-0.2,0-0.2c-0.3-0.2-0.6-0.3-0.9-0.2c-0.1,0.4,0.1,0.6,0.4,0.7
				C368.2,8.4,368.4,8.3,368.5,8.2z"/>
			<path fill="#32302D" d="M366.8,6.4c0.1,0.2,0.2,0.5,0.2,0.7c0.2,0,0.5,0.3,0.3-0.2c-0.2-0.4-0.4-0.8-0.4-1.2
				c-0.1,0.1-0.2,0.2-0.2,0.2C366.8,6.1,366.8,6.3,366.8,6.4z"/>
			<path fill="#4B4641" d="M362,22.8c0.3-0.1,0.3-0.3,0.2-0.4c-0.1-0.2-0.1-0.4-0.4-0.4c-0.2,0.1-0.2,0.3-0.2,0.5
				C361.6,22.6,361.7,22.8,362,22.8z"/>
			<path fill="#4E4944" d="M353.5,9.6c0.3,0.1,0.4-0.1,0.4-0.3c0-0.2-0.1-0.4-0.4-0.3c-0.2,0-0.5,0.2-0.5,0.3
				C353,9.5,353.4,9.5,353.5,9.6z"/>
			<path fill="#353330" d="M369,39.3c-0.4,0.4-0.3,0.8-0.5,1.4C369,40.2,369.4,39.9,369,39.3z"/>
			<path fill="#2B2927" d="M354.6,11.7c-0.2-0.4-0.6-0.4-0.8-0.1c-0.3,0.4-0.1,0.6,0.4,0.6C354.4,12.1,354.6,12,354.6,11.7z"/>
			<path fill="#161615" d="M366.9,43.7c0.2,0.1,0.3,0.2,0.5,0.2c0.3-0.2,0.5-0.3,0.3-0.7c0-0.2,0-0.4-0.3-0.4
				c-0.2,0-0.2,0.2-0.2,0.3C367,43.3,366.6,43.4,366.9,43.7z"/>
			<path fill="#48443F" d="M368.2,21.8c0-0.2,0-0.5,0-0.7c-0.3-0.1-0.7-0.4-0.8,0C367.4,21.6,368,21.6,368.2,21.8z"/>
			<path fill="#3F3B38" d="M363.7,13.7c-0.3-0.6-0.8-0.2-1.3-0.2C362.8,13.9,363.3,13.9,363.7,13.7z"/>
			<path fill="#1F1F1D" d="M376.6,45.3c0.1,0,0.2,0,0.2,0.1c0.2,0.1,0.3,0.3,0.5,0.4c0.2-0.1,0.3-0.1,0.5-0.2
				c-0.1-0.3-0.2-0.5-0.3-0.8c-0.2,0-0.5,0-0.7,0C376.8,45,376.7,45.2,376.6,45.3z"/>
			<path fill="#1C1C1B" d="M338.9,4.1c0.4,0.1,0.7,0,1.1-0.3C339.4,3.5,339.1,3.8,338.9,4.1z"/>
			<path fill="#554F49" d="M366.3,27.8c0.1,0.4,0.2,0.8,0.3,1.2c0.6,0,0.4-0.4,0.3-0.7c0.1-0.2,0.1-0.3,0-0.5
				c-0.1,0-0.1-0.1-0.2-0.1C366.5,27.7,366.4,27.7,366.3,27.8z"/>
			<path fill="#262523" d="M364.2,20.9c-0.3-0.1-0.6-0.2-0.9-0.3c-0.1,0.6,0.2,0.8,0.7,0.8C364.1,21.2,364.3,21.1,364.2,20.9z"/>
			<path fill="#433F3B" d="M355.3,33.2c0-0.4,0.2-0.8-0.2-1c-0.1,0-0.3,0.1-0.3,0.1C354.8,32.6,354.9,32.9,355.3,33.2z"/>
			<path fill="#2D2B29" d="M380.1,55.7c0,0.5,0.2,0.7,0.5,0.8c0,0,0.2-0.1,0.2-0.2C380.8,55.9,380.5,55.8,380.1,55.7z"/>
			<path fill="#5B534D" d="M376.3,55.2c0.5-0.5,0.2-0.8,0.2-1.3C376,54.3,376.2,54.7,376.3,55.2z"/>
			<path fill="#20201E" d="M366.6,53.8c0,0.5,0.5,0.7,0.4,1.3c0.6-0.5,0-0.9,0.1-1.2C366.9,53.6,366.8,53.6,366.6,53.8z"/>
			<path fill="#1D1D1C" d="M347.6,2.9c-0.2,0-0.3,0-0.5,0c-0.1,0.3-0.4,0.7-0.1,0.8C347.6,3.8,347.5,3.2,347.6,2.9z"/>
			<path fill="#191918" d="M372.8,47.3c-0.5,0.2-0.8,0.4-0.8,0.8c0,0,0.1,0.1,0.2,0.1C372.6,48.2,372.7,47.8,372.8,47.3z"/>
			<path fill="#262524" d="M373.5,41.7c-0.6,0.5-0.5,0.9-0.1,1.4C373.4,42.7,373.5,42.3,373.5,41.7z"/>
			<path fill="#262523" d="M358.7,40.3c-0.3,0.4-0.3,0.7-0.1,1c0.1,0,0.1,0.1,0.1,0C359.1,41,358.9,40.7,358.7,40.3z"/>
			<path fill="#161615" d="M360.8,38.6c0.1,0,0.2,0,0.2,0c0-0.6-0.1-1-1.1-0.9C360.5,38,360.7,38.3,360.8,38.6z"/>
			<path fill="#20201E" d="M355.5,34.4c0.3,0.3,0.3,0.7,0.6,0.8c0,0,0.2-0.2,0.2-0.3C356.4,34.4,356,34.4,355.5,34.4z"/>
			<path fill="#181817" d="M368.3,22.8c0,0.2,0,0.3,0,0.5c0.2,0,0.4,0.3,0.5,0.1c0.1-0.2,0.3-0.5,0-0.7
				C368.7,22.6,368.5,22.7,368.3,22.8z"/>
			<path fill="#504B45" d="M360.4,33.8c0,0.2,0.2,0.4,0.4,0.4c0.3,0,0.2-0.3,0.2-0.4c0-0.2,0.1-0.5-0.2-0.4
				C360.5,33.4,360.4,33.6,360.4,33.8z"/>
			<path fill="#45413C" d="M364.8,16.4c-0.1,0.3,0,0.4,0.2,0.4c0.2,0,0.5-0.1,0.4-0.4c0-0.1-0.3-0.2-0.4-0.4
				C365,16.2,364.9,16.3,364.8,16.4z"/>
			<path fill="#4B4641" d="M357.6,25.4c0,0.2,0.2,0.2,0.3,0.2c0.2,0,0.4-0.1,0.4-0.3c0-0.2-0.1-0.3-0.3-0.2
				C357.8,25.1,357.6,25.2,357.6,25.4z"/>
			<path fill="#1B1A19" d="M367.1,35.3c0.3,0.1,0.6,0,0.8-0.2c0,0-0.1-0.2-0.1-0.3C367.5,34.8,367.3,34.9,367.1,35.3z"/>
			<path fill="#1D1D1B" d="M373.6,49.3c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.3,0,0.3-0.2c0-0.2-0.1-0.3-0.3-0.3
				C373.8,49,373.7,49,373.6,49.3z"/>
			<path fill="#21201F" d="M369.8,48.9c0,0.2,0.2,0.4,0.4,0.4c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.2-0.2-0.4-0.3-0.5
				C369.9,48.6,369.8,48.8,369.8,48.9z"/>
			<path fill="#3A3734" d="M364.1,38.3c-0.2,0.1-0.4,0.1-0.4,0.4c0,0.1,0.2,0.3,0.3,0.3c0.2,0,0.3-0.2,0.3-0.4
				C364.4,38.4,364.3,38.3,364.1,38.3z"/>
			<path fill="#4A4540" d="M350.4,7.5c-0.6-0.4-0.9-0.7-1.2-0.3C349.4,7.7,349.8,7.4,350.4,7.5z"/>
			<path fill="#2F2E2B" d="M357.4,23.8c0.1,0.2,0.3,0.2,0.5,0.2c0.2,0,0.3-0.2,0.2-0.3c-0.1-0.1-0.3-0.1-0.4-0.1
				C357.5,23.5,357.3,23.6,357.4,23.8z"/>
			<path fill="#373431" d="M347.6,13c-0.4-0.6-0.7-0.3-1.1-0.3C346.8,13.1,347.1,12.9,347.6,13z"/>
			<path fill="#373531" d="M360.3,36.9c0.4-0.2,0.5-0.4,0.4-0.7c0,0-0.2-0.1-0.3-0.1C360.3,36.3,360.1,36.5,360.3,36.9z"/>
			<path fill="#5B544D" d="M380.1,60.4c0.2,0,0.3-0.1,0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0-0.2,0.2-0.2,0.3
				C379.8,60.3,380,60.4,380.1,60.4z"/>
			<path fill="#191918" d="M360.9,29.1c0.1,0.1,0.1,0.3,0.3,0.4c0.1,0.1,0.3-0.1,0.3-0.2c0-0.2-0.1-0.4-0.3-0.4
				C361,28.8,360.9,28.9,360.9,29.1z"/>
			<path fill="#252422" d="M382.7,55c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3,0.2-0.8,0.4-0.5,0.7C382.3,55.8,382.4,55.2,382.7,55z"/>
			<path fill="#4A4641" d="M350.9,20.7c0.1,0.3,0.2,0.4,0.4,0.5c0.2,0,0.2-0.2,0.2-0.3c0-0.2,0-0.4-0.3-0.4
				C351,20.5,351,20.6,350.9,20.7z"/>
			<path fill="#363330" d="M352.8,6.2c0.1-0.2,0.2-0.4,0.2-0.5c0-0.2-0.2-0.3-0.4-0.4c-0.2,0-0.3,0.1-0.2,0.3
				C352.6,5.8,352.7,5.9,352.8,6.2z"/>
			<path fill="#47423E" d="M375,37.9c0-0.2,0.4-0.5,0-0.7c-0.1,0-0.3,0.1-0.4,0.2C374.4,37.7,374.7,37.9,375,37.9
				C375,37.9,375,37.9,375,37.9z"/>
			<path fill="#363431" d="M367.3,53.7c0.3-0.2,0.8-0.3,0.5-0.7c-0.3-0.4-0.5,0.2-0.8,0.3C367,53.5,367.1,53.6,367.3,53.7z"/>
			<path fill="#48443F" d="M366.2,22.9c-0.3-0.1-0.6-0.4-0.9-0.1c0,0,0,0.2,0,0.3C365.7,23.3,365.9,23.1,366.2,22.9z"/>
			<path fill="#191918" d="M364.4,34.3c0.2,0,0.3,0,0.3-0.2c0-0.2-0.2-0.2-0.3-0.2c-0.2,0-0.3,0-0.3,0.2
				C364.1,34.3,364.3,34.3,364.4,34.3z"/>
			<path fill="#57504A" d="M362.3,7c0.4-0.2,0.5-0.6,0.1-0.8C362,6,362,6.4,362,6.7C361.9,6.9,362,7,362.3,7z"/>
			<path fill="#393633" d="M360.1,25.5c0,0.4,0.1,0.7,0.4,0.8c0,0,0.2-0.1,0.2-0.2C360.7,25.7,360.4,25.6,360.1,25.5z"/>
			<path fill="#554E48" d="M370.9,42.5c0.1,0.2-0.3,0.5,0,0.6c0.3,0.1,0.7-0.2,0.6-0.6C371.4,42.2,371.1,42.5,370.9,42.5
				C370.9,42.5,370.9,42.5,370.9,42.5z"/>
			<path fill="#222220" d="M349.3,4.1c0.4,0.2,0.7,0.4,1,0.3c0,0,0-0.2,0-0.3C350.1,3.8,349.8,3.9,349.3,4.1z"/>
			<path fill="#514B46" d="M376.2,39.8c0.5,0.5,0.5,0.4,0.9-0.4C376.8,39.6,376.5,39.7,376.2,39.8C376.2,39.8,376.2,39.8,376.2,39.8
				z"/>
			<path fill="#141414" d="M365.2,29.5c-0.2-0.5,0.3-0.9,0.3-1.4C365,28.5,364.6,28.9,365.2,29.5z"/>
			<path fill="#514C46" d="M366.3,27.8c0.1,0,0.2,0,0.3,0c-0.2-0.2-0.1-0.7-0.5-0.6c-0.3,0-0.2,0.4-0.2,0.6
				C366,27.8,366.2,27.8,366.3,27.8z"/>
			<path fill="#151514" d="M359.7,11.6c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.2-0.1,0.3-0.1c0.1-0.2,0-0.3-0.2-0.4
				C359.9,11.4,359.8,11.5,359.7,11.6z"/>
			<path fill="#2A2927" d="M362.5,41.8c-0.1-0.2-0.2-0.3-0.3-0.4c-0.2-0.1-0.3,0-0.3,0.2c0,0.1,0.2,0.2,0.3,0.3
				C362.2,41.9,362.4,41.8,362.5,41.8z"/>
			<path fill="#20201F" d="M352.5,21.7c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2,0.1,0.3,0.3,0.2c0.1-0.1,0.2-0.2,0.3-0.3
				C352.9,21.7,352.8,21.7,352.5,21.7z"/>
			<path fill="#3F3C37" d="M375,39.1c-0.1,0-0.1,0-0.2,0c0,0.3-0.4,0.6,0,0.8c0.1,0,0.2,0.1,0.2,0C375.3,39.7,374.9,39.4,375,39.1z"
				/>
			<path fill="#1F1E1D" d="M388.5,60.5c0,0.2-0.1,0.3,0.1,0.3c0.2,0,0.4-0.1,0.4-0.3c0-0.1-0.1-0.3-0.2-0.3
				C388.6,60.1,388.7,60.4,388.5,60.5z"/>
			<path fill="#44413C" d="M387.5,61.7c-0.1,0-0.3,0-0.3,0.2c0,0.2,0.2,0.3,0.4,0.3c0.1,0,0.3-0.1,0.3-0.1
				C387.9,61.7,387.7,61.7,387.5,61.7z"/>
			<path fill="#4C4742" d="M381,49.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.2-0.3c0-0.1-0.2-0.2-0.2-0.2
				C381.1,49,381,49.1,381,49.3z"/>
			<path fill="#1A1A19" d="M356.4,10.5c-0.1,0.4,0.1,0.7,0.3,0.6c0.5-0.1,0.1-0.3-0.1-0.5C356.6,10.6,356.5,10.6,356.4,10.5z"/>
			<path fill="#433F3B" d="M358.2,2.9c0.4,0,0.8-0.1,0.9-0.5c0,0-0.2-0.1-0.4-0.2C358.5,2.4,358.3,2.6,358.2,2.9z"/>
			<path fill="#1E1E1D" d="M381.7,50.1c0,0.1,0,0.3,0.2,0.3c0.1,0,0.2-0.2,0.2-0.3c0-0.1,0-0.3-0.2-0.3
				C381.7,49.8,381.7,49.9,381.7,50.1z"/>
			<path fill="#1E1D1C" d="M360.4,15.3c0-0.1,0-0.3,0-0.4c-0.2-0.2-0.5-0.3-1-0.7C359.7,14.9,360,15.2,360.4,15.3z"/>
			<path fill="#1D1B1A" d="M342.6,5.6c0.1,0.4,0.3,0.6,0.7,0.7C343.2,5.8,342.9,5.7,342.6,5.6z"/>
			<path fill="#21201F" d="M365.1,42.6c0,0.2-0.1,0.4,0.2,0.3c0.1,0,0.2-0.2,0.3-0.3c0.1-0.2,0-0.3-0.2-0.4
				C365.1,42.1,365.2,42.4,365.1,42.6z"/>
			<path fill="#4F4A45" d="M367.6,43.2c-0.1,0.2-0.2,0.4-0.3,0.7c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.2,0,0.2,0
				C367.8,43.8,368.2,43.4,367.6,43.2z"/>
			<path fill="#524C47" d="M383.5,53.8c0,0.3,0.1,0.4,0.3,0.4c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.2-0.3-0.3
				C383.6,53.7,383.5,53.8,383.5,53.8z"/>
			<path fill="#4C4742" d="M366.8,27.9c0,0.2,0,0.3,0,0.5c0.2-0.1,0.6,0.3,0.6-0.2C367.5,27.7,367.1,27.8,366.8,27.9z"/>
			<path fill="#20201F" d="M354.4,12.4c-0.1,0.2-0.3,0.5,0,0.6c0.5,0.1,0.4-0.4,0.5-0.8C354.6,12.1,354.5,12.2,354.4,12.4z"/>
			<path fill="#4E4944" d="M355.3,18.5c0.1,0.4,0.2,0.5,0.4,0.5c0.2,0,0.3-0.1,0.2-0.3c0-0.2-0.1-0.3-0.3-0.3
				C355.5,18.4,355.3,18.5,355.3,18.5z"/>
			<path fill="#3D3A36" d="M365.6,25.7c-0.2-0.1-0.3-0.2-0.5-0.2c0,0.3,0,0.6,0,0.9C365.6,26.3,365.6,26,365.6,25.7z"/>
			<path fill="#504B45" d="M362.1,24.5c0.2-0.3,0.3-0.5,0-0.8c0,0-0.2,0-0.3,0.1C361.7,24.1,362,24.3,362.1,24.5z"/>
			<path fill="#1E1E1D" d="M354.7,26.5c0.1,0.1,0.1,0.3,0.2,0.3c0.2,0.1,0.2-0.2,0.3-0.3c0-0.2-0.1-0.3-0.2-0.4
				C354.7,26.2,354.6,26.3,354.7,26.5z"/>
			<path fill="#363431" d="M352.3,4.2c-0.3,0-0.4,0-0.3,0.2c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.3-0.2,0.2-0.3
				C352.6,4.2,352.4,4.2,352.3,4.2z"/>
			<path fill="#4C4741" d="M360.3,24.6c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.2-0.2,0.2-0.3c0-0.2-0.1-0.3-0.3-0.3
				C360.3,24.3,360.3,24.5,360.3,24.6z"/>
			<path fill="#1B1B1A" d="M358.3,30.9c0,0.2,0.1,0.3,0.2,0.3c0.1,0,0.2-0.2,0.3-0.3c0,0-0.1-0.2-0.2-0.2
				C358.5,30.7,358.3,30.7,358.3,30.9z"/>
			<path fill="#33312E" d="M360.1,7.9c0,0.1,0,0.3,0.2,0.3c0.1,0,0.2-0.2,0.2-0.3c0-0.1,0-0.3-0.2-0.3
				C360.2,7.6,360.1,7.7,360.1,7.9z"/>
			<path fill="#302E2C" d="M350.7,5.3c0.1,0.1,0.1,0.4,0.3,0.3c0.1,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.4-0.3-0.3
				C350.7,5.1,350.6,5.1,350.7,5.3z"/>
			<path fill="#292825" d="M366,36.4c0.2-0.5-0.1-0.7-0.4-0.9C365.6,35.9,365.6,36.2,366,36.4z"/>
			<path fill="#58514B" d="M371.4,35.7c0.1,0.2,0.2,0.3,0.2,0.5c0.4,0.1,0.5-0.1,0.5-0.4C371.9,35.7,371.6,35.3,371.4,35.7z"/>
			<path fill="#4B4641" d="M373.3,35.5c0,0.3,0.1,0.7,0.5,0.5c0.3-0.1,0-0.4-0.1-0.6C373.6,35.4,373.5,35.4,373.3,35.5z"/>
			<path fill="#4E4843" d="M354.4,12.4c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.3-0.1-0.4-0.2-0.5c-0.2,0.1-0.4,0.3-0.5,0.5
				C354.2,12.3,354.3,12.4,354.4,12.4z"/>
			<path fill="#1B1B1A" d="M357.5,22.5c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.1,0.2-0.1,0.2-0.3c0-0.1-0.1-0.3-0.2-0.4
				C357.6,22.1,357.5,22.3,357.5,22.5z"/>
			<path fill="#1D1C1B" d="M364.7,21.3c0.3-0.1,0.5-0.3,0.4-0.7c0-0.1-0.2-0.1-0.2-0.2c-0.1,0.1-0.1,0.3-0.2,0.4
				C364.5,21,364.5,21.2,364.7,21.3z"/>
			<path fill="#302E2B" d="M363.6,19.8c0.2,0.4,0.4,0.8,0.6,0.7c0.4-0.1,0.1-0.4-0.1-0.5C364,19.9,363.9,19.9,363.6,19.8z"/>
			<path fill="#34322F" d="M350.5,16.9c0,0.1,0.1,0.3,0.3,0.3c0.1,0,0.2-0.2,0.2-0.2c0-0.2-0.1-0.4-0.3-0.3
				C350.5,16.6,350.4,16.6,350.5,16.9z"/>
			<path fill="#47433E" d="M358.6,20.3c0-0.1,0-0.3,0-0.4c-0.3-0.2-0.7-0.2-0.7,0C357.9,20.3,358.3,20.3,358.6,20.3z"/>
			<path fill="#222220" d="M375.3,36.3c0.1,0,0.2,0.1,0.2,0.1c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1-0.1-0.2-0.2-0.1
				C375.5,36,375.3,36,375.3,36.3z"/>
			<path fill="#131312" d="M371.7,36.3c-0.7,0-0.2,0.5-0.3,0.7C372,36.9,371.8,36.6,371.7,36.3z"/>
			<path fill="#524C46" d="M374.5,33.4c-0.4-0.4-0.5-0.2-0.5,0.2c0,0.1,0.1,0.2,0.1,0.3C374.3,33.7,374.4,33.6,374.5,33.4z"/>
			<path fill="#21201F" d="M365.6,40.3c-0.3,0-0.6,0-0.8,0.3C365.2,40.9,365.4,40.6,365.6,40.3L365.6,40.3z"/>
			<path fill="#2D2C2A" d="M356.3,36.3c0.4,0.2,0.6,0.4,0.8,0.1c0.2-0.5-0.2-0.2-0.3-0.2C356.6,36.2,356.5,36.3,356.3,36.3z"/>
			<path fill="#2B2927" d="M368,33.6c-0.1,0.1-0.3,0.3,0,0.4c0.3,0.1,0.5-0.1,0.5-0.4C368.3,33.4,368.2,33.5,368,33.6z"/>
			<path fill="#2B2A28" d="M376.7,41.8c0.1-0.1,0.2-0.2,0.3-0.3c-0.2-0.2-0.5-0.3-0.7-0.5c0,0-0.1,0.1-0.1,0.1
				c-0.2,0.2-0.1,0.4,0,0.7C376.3,41.7,376.5,41.7,376.7,41.8z"/>
			<path fill="#2B2A28" d="M345.8,6.6c0.4,0.1,0.7,0,0.7-0.2c0-0.2-0.2-0.2-0.4-0.1C346,6.3,345.9,6.4,345.8,6.6z"/>
			<path fill="#232221" d="M365.6,40.3c0.2,0,0.5-0.1,0.4-0.4c0-0.1-0.2-0.2-0.2-0.2C365.5,39.8,365.7,40.1,365.6,40.3
				C365.6,40.3,365.6,40.3,365.6,40.3z"/>
			<path fill="#44403C" d="M369.5,35c0-0.2,0-0.5,0-0.7c-0.2,0.1-0.5-0.1-0.5,0.2C368.9,34.8,369.2,34.9,369.5,35z"/>
			<path fill="#232321" d="M361.8,39.1c-0.1,0.2-0.1,0.3-0.2,0.5c0.2-0.1,0.7,0.2,0.7-0.2C362.3,39.1,362,39.1,361.8,39.1z"/>
			<path fill="#393733" d="M367.6,38.3c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.3,0.1,0.3-0.1c0-0.1-0.2-0.1-0.3-0.2
				C367.9,38.2,367.8,38.2,367.6,38.3z"/>
			<path fill="#131313" d="M373.1,37.3c0,0.1,0,0.2,0.2,0.2c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.2-0.2-0.2
				C373.2,37,373.1,37.1,373.1,37.3z"/>
			<path fill="#232221" d="M373.3,35.5c0.1,0,0.3,0,0.4,0c0-0.2,0.1-0.6-0.2-0.6C373.2,34.9,373.4,35.3,373.3,35.5z"/>
			<path fill="#3B3835" d="M377.2,37.2c-0.2,0-0.3,0-0.5,0c0.1,0.3,0.2,0.6,0.3,1C377.3,37.9,377.1,37.5,377.2,37.2z"/>
			<path fill="#1E1E1D" d="M366.5,61.9c-0.1,0-0.3,0-0.2,0.2c0.1,0.1,0.2,0.3,0.3,0.3c0.2,0.1,0.2-0.1,0.2-0.2
				C366.8,62.1,366.7,62,366.5,61.9z"/>
			<path fill="#2D2B29" d="M389.2,65.8c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.4-0.3c0,0-0.2-0.1-0.3-0.1
				C389.4,65.5,389.2,65.5,389.2,65.8z"/>
			<path fill="#48433F" d="M355.9,14c0.1,0.1,0.1,0.2,0.3,0.3c0.2,0.1,0.3-0.1,0.3-0.3c0-0.1-0.2-0.2-0.3-0.3
				C355.9,13.7,355.9,13.8,355.9,14z"/>
			<path fill="#413E3A" d="M356.8,5c-0.2,0.1-0.3,0.2-0.3,0.4c0.1,0.3,0.4,0.2,0.7,0.3C357.1,5.5,357,5.3,356.8,5z"/>
			<path fill="#171716" d="M343.9,4.7c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.2-0.1,0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.2
				C344,4.4,343.9,4.5,343.9,4.7z"/>
			<path fill="#191918" d="M363.5,18.2c0.1-0.1,0.3-0.1,0.3-0.2c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.2
				C363.3,18.1,363.5,18.1,363.5,18.2z"/>
			<path fill="#292826" d="M362.8,17.1c-0.1,0-0.2,0-0.3,0c-0.3,0.2-0.2,0.4-0.2,0.8C362.7,17.6,362.7,17.3,362.8,17.1z"/>
			<path fill="#47423E" d="M371.2,29.5c-0.2,0-0.4,0-0.6,0c-0.1,0.2-0.2,0.4,0,0.5C371,30.1,371.1,29.8,371.2,29.5z"/>
			<path fill="#514B45" d="M348.7,3.9c-0.2,0-0.3,0.2-0.2,0.3c0,0.1,0.2,0.2,0.3,0.2c0.2,0,0.2-0.2,0.2-0.4
				C348.9,3.9,348.7,3.9,348.7,3.9z"/>
			<path fill="#393633" d="M375.9,42.4c0,0.2,0.1,0.3,0.3,0.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2-0.1-0.2-0.2-0.3
				C376.1,42.2,375.9,42.2,375.9,42.4z"/>
			<path fill="#413E3A" d="M362.5,45.5c0.2,0,0.4,0,0.4-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.3,0-0.3,0.2
				C362.4,45.4,362.5,45.5,362.5,45.5z"/>
			<path fill="#3C3936" d="M368.5,46.5c-0.1,0.2-0.3,0.2-0.5,0.2c0.3,0.4,0.6,0.4,0.8,0C369,46.6,368.7,46.6,368.5,46.5z"/>
			<path fill="#2E2D2B" d="M346.4,2.6c0-0.2,0-0.3,0-0.5c-0.2-0.1-0.5-0.4-0.6-0.1C345.7,2.5,346.2,2.4,346.4,2.6z"/>
			<path fill="#504A45" d="M389.7,60.3c0.1,0.1,0.1,0.2,0.2,0.2c0.2,0.1,0.3,0,0.4-0.2c0-0.1-0.1-0.2-0.2-0.2
				C389.9,60,389.8,60,389.7,60.3z"/>
			<path fill="#544E48" d="M387.2,58.2c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.2,0.3,0.3,0.2c0.1,0,0.2-0.2,0.2-0.2
				C387.4,58.4,387.3,58.2,387.2,58.2z"/>
			<path fill="#373431" d="M368.8,24.8c-0.1,0.2-0.2,0.3-0.3,0.4c0,0.1,0,0.3,0.1,0.3c0.2,0,0.3-0.1,0.3-0.3
				C369,25,368.9,25,368.8,24.8z"/>
			<path fill="#31302D" d="M377.6,53.8c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.1-0.3-0.1
				C377.9,53.5,377.8,53.7,377.6,53.8z"/>
			<path fill="#59524B" d="M367.3,53.7c-0.1-0.1-0.1-0.3-0.2-0.4c-0.4-0.1-0.7-0.1-0.5,0.5c0.2,0,0.3,0,0.5,0
				C367.2,53.8,367.2,53.7,367.3,53.7z"/>
			<path fill="#2E2C2A" d="M361.7,48.2c-0.1,0.2-0.2,0.3-0.3,0.4c0,0.1,0.1,0.2,0.2,0.1c0.1-0.1,0.3-0.1,0.3-0.3
				C361.9,48.4,361.8,48.3,361.7,48.2z"/>
			<path fill="#222120" d="M371.7,52.9c0,0.2,0.1,0.2,0.2,0.2c0.1-0.1,0.3-0.1,0.3-0.3c0-0.1-0.2-0.2-0.2-0.1
				C371.9,52.7,371.8,52.8,371.7,52.9z"/>
			<path fill="#32302D" d="M358.9,4.1c-0.2-0.2-0.3-0.5-0.7-0.5C358.4,3.7,358.3,4.5,358.9,4.1z"/>
			<path fill="#423E3A" d="M344.7,3.8c-0.2,0-0.3,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.2-0.2
				C344.9,3.9,344.8,3.9,344.7,3.8z"/>
			<path fill="#1D1C1B" d="M342.1,1.4c0.1,0.2,0.2,0.2,0.3,0.1c0.1-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1
				C342.2,1.2,342.2,1.3,342.1,1.4z"/>
			<path fill="#353330" d="M340.4,1.7c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1-0.1,0.2-0.2,0.2-0.3
				C340.6,1.8,340.6,1.7,340.4,1.7z"/>
			<path fill="#615952" d="M351.8,1.8c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.3,0.1
				C351.7,1.6,351.8,1.7,351.8,1.8z"/>
			<path fill="#181817" d="M349.4,2.9c0.2,0.2,0.3,0.3,0.4,0.3c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C349.7,2.8,349.6,2.9,349.4,2.9z"/>
			<path fill="#2C2B29" d="M360.6,21.6c-0.1-0.2-0.3-0.4-0.5-0.4C360.2,21.3,359.9,22,360.6,21.6z"/>
			<path fill="#1A1A19" d="M372.8,43.4c0,0.2,0.1,0.4,0.1,0.5c0.1,0,0.2-0.1,0.3-0.1C373.3,43.5,373,43.5,372.8,43.4L372.8,43.4z"/>
			<path fill="#1E1D1C" d="M358.6,45.3c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0.2-0.2,0.2-0.3c-0.1,0-0.1-0.1-0.2-0.1
				C358.8,45.1,358.6,45.1,358.6,45.3z"/>
			<path fill="#383632" d="M370.8,45.6c-0.1,0-0.3,0.1-0.3,0.3c0,0.1,0.2,0.1,0.2,0.2c0.1-0.1,0.2-0.2,0.2-0.3
				C371.1,45.7,371,45.7,370.8,45.6z"/>
			<path fill="#47433E" d="M371.9,46.9c0-0.1,0.1-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.2-0.2,0.3
				C371.6,46.8,371.7,46.9,371.9,46.9z"/>
			<path fill="#262523" d="M370.4,46.8c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
				C370.7,46.6,370.6,46.7,370.4,46.8z"/>
			<path fill="#292825" d="M369.8,47.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.1-0.3
				C370,47.2,369.9,47.3,369.8,47.4z"/>
			<path fill="#403D39" d="M364.7,21.3c0-0.2,0-0.3,0-0.5c-0.2,0-0.3,0-0.5,0c-0.1,0.2-0.2,0.3-0.2,0.5
				C364.2,21.3,364.4,21.3,364.7,21.3z"/>
			<path fill="#59524C" d="M365.7,50.4c0.1-0.1,0.3-0.1,0.3-0.1c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.2
				C365.5,50.3,365.6,50.3,365.7,50.4z"/>
			<path fill="#20201E" d="M379.8,50.4c0,0.1,0,0.1,0,0.2c0.2,0,0.4,0,0.7,0c0-0.1,0-0.2,0-0.3C380.2,50.4,380,50.4,379.8,50.4z"/>
			<path fill="#1F1E1D" d="M382.6,52c0-0.1-0.1-0.3-0.2-0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0.1,0.2,0.2,0.2
				C382.6,52.2,382.6,52,382.6,52z"/>
			<path fill="#222220" d="M388.6,57.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1,0.1-0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2
				C388.3,57.6,388.4,57.5,388.6,57.4z"/>
			<path fill="#1C1C1B" d="M381.4,57.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1,0.1-0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2
				C381.2,57.5,381.3,57.4,381.4,57.3z"/>
			<path fill="#161715" d="M381.3,64.7c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
				C381.6,64.6,381.5,64.6,381.3,64.7z"/>
			<path fill="#343230" d="M390.8,65.1c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C391,64.9,390.9,65,390.8,65.1z"/>
			<path fill="#1A1A19" d="M373.2,66.9c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.1,0.2-0.1,0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
				C373.3,66.8,373.3,66.9,373.2,66.9z"/>
			<path fill="#1B1B1A" d="M363.3,25.9c-0.1,0.6,0.1,0.8,0.7,0.8C363.7,26.4,363.5,26.1,363.3,25.9z"/>
			<path fill="#403D39" d="M372.8,25.4c-0.4-0.4-0.6-0.3-0.7,0.2C372.3,25.6,372.6,25.5,372.8,25.4z"/>
			<path fill="#2D2C29" d="M356.3,29.4c0,0,0.1,0.1,0.1,0.2c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C356.4,29.1,356.3,29.2,356.3,29.4z"/>
			<path fill="#47423E" d="M363.3,30.6c0.2,0,0.3,0.1,0.3,0c0.1-0.1,0.3-0.2,0.2-0.4c0-0.1-0.2-0.1-0.3-0.1
				C363.4,30.3,363.4,30.4,363.3,30.6z"/>
			<path fill="#2B2927" d="M352.1,23.6c0.1,0.1,0.1,0.2,0.2,0.2c0.2,0,0.2-0.2,0.3-0.3c0-0.1,0-0.2-0.1-0.2
				C352.2,23.3,352.1,23.4,352.1,23.6z"/>
			<path fill="#1B1B1A" d="M368,33.6c0.2,0,0.3,0,0.5,0c0.1-0.2,0.1-0.3,0.2-0.5C368.3,33,368.2,33.4,368,33.6z"/>
			<path fill="#5C554E" d="M363.8,22.3c-0.2,0-0.3,0.1-0.2,0.2c0,0.1,0.2,0.1,0.3,0.2c0.1,0,0.3-0.1,0.2-0.2
				C363.9,22.4,363.8,22.4,363.8,22.3z"/>
			<path fill="#5B544D" d="M374.6,35.3c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.1,0.2-0.1,0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
				C374.7,35,374.6,35.1,374.6,35.3z"/>
			<path fill="#494440" d="M359.6,22.2c-0.1,0.1-0.2,0.2-0.3,0.4c0.1,0,0.2,0.1,0.2,0.1c0.1-0.1,0.2-0.2,0.3-0.3
				C359.9,22.3,359.8,22.2,359.6,22.2z"/>
			<path fill="#3E3B37" d="M365.3,37.2c0.1-0.2,0.2-0.3,0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.2,0.1-0.1,0.2
				C365.1,36.9,365.2,37,365.3,37.2z"/>
			<path fill="#423F3A" d="M373.7,38c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.2,0.1,0.3,0.3,0.2c0.1,0,0.1-0.2,0.1-0.3
				C373.9,38.2,373.8,38.1,373.7,38z"/>
			<path fill="#4F4A44" d="M350.3,21.8c0.3,0.2,0.4,0.2,0.5,0.2c0.2,0,0.3-0.1,0.2-0.3c0-0.1-0.2-0.1-0.3-0.1
				C350.7,21.6,350.6,21.7,350.3,21.8z"/>
			<path fill="#544E48" d="M376.2,41.7c0-0.2,0-0.4,0-0.7c-0.3,0-0.5,0.1-0.4,0.4C375.9,41.6,376,41.6,376.2,41.7z"/>
			<path fill="#45413D" d="M362.3,21.9c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0,0.2-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.2
				C362.4,21.7,362.3,21.8,362.3,21.9z"/>
			<path fill="#20201E" d="M372.8,43.4c0-0.2,0-0.5-0.4-0.4c-0.1,0-0.1,0.2-0.2,0.3C372.5,43.4,372.6,43.4,372.8,43.4
				C372.8,43.4,372.8,43.4,372.8,43.4z"/>
			<path fill="#504B46" d="M366.9,43.7c0.1-0.2,0.1-0.4,0.2-0.5c-0.2,0.1-0.6-0.1-0.6,0.3C366.4,43.7,366.7,43.7,366.9,43.7z"/>
			<path fill="#242322" d="M343,13.2c-0.2,0.2-0.2,0.5-0.1,0.7C343.2,13.7,343,13.5,343,13.2z"/>
			<path fill="#534D48" d="M361.5,13c-0.3,0.3-0.3,0.5,0,0.7C361.9,13.4,361.7,13.2,361.5,13z"/>
			<path fill="#33312E" d="M353.1,13c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.1-0.2c0-0.1-0.2-0.1-0.3-0.2
				C353.1,12.7,353,12.8,353.1,13z"/>
			<path fill="#565049" d="M346.6,11.7c0,0.1-0.1,0.2-0.1,0.2c0.1,0.1,0.2,0.2,0.4,0.2c0-0.1,0.1-0.1,0.1-0.2
				C346.9,11.8,346.9,11.6,346.6,11.7z"/>
			<path fill="#21201F" d="M345.9,9.8c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.1-0.2-0.2
				C346,9.6,345.9,9.6,345.9,9.8z"/>
			<path fill="#2C2B29" d="M362.7,9.9c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.1-0.2
				C362.9,9.7,362.8,9.8,362.7,9.9z"/>
			<path fill="#514C46" d="M361.3,9.3c0.3-0.1,0.5-0.4,0.5-0.7C361.6,8.8,360.9,8.7,361.3,9.3z"/>
			<path fill="#3A3734" d="M362.3,7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.2-0.2,0.5-0.2,0.8c0,0,0,0,0,0C362.1,7.4,362.3,7.3,362.3,7z"
				/>
			<path fill="#282725" d="M361.3,17.8c0.1,0.1,0.2,0.2,0.3,0.2c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C361.5,17.7,361.4,17.7,361.3,17.8z"/>
			<path fill="#504B45" d="M356.8,17.2c0.2,0,0.3,0,0.5,0c-0.1-0.2,0.1-0.6-0.3-0.5C356.8,16.8,356.7,17,356.8,17.2z"/>
			<path fill="#423E3A" d="M360.1,15.9c0,0.2,0,0.3,0.2,0.3c0.1,0,0.2-0.1,0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.2
				C360.3,15.8,360.2,15.9,360.1,15.9z"/>
			<path fill="#2A2927" d="M353.2,15.2c0.1,0,0.3,0.1,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.2-0.1-0.2-0.1
				C353.3,14.9,353.2,15,353.2,15.2z"/>
			<path fill="#5D564E" d="M360.6,13.4c-0.1,0.2-0.2,0.3,0,0.5c0,0,0.2,0.1,0.2,0c0.1-0.2,0.3-0.3,0.1-0.6
				C360.7,13.4,360.7,13.4,360.6,13.4z"/>
			<path fill="#DCB6A7" d="M330.3,30.2c-0.6,0.6-1.3-0.7-1.9,0c-1.2,0.2-2.8,1.5-2.9,2.5c-0.1,0.5-0.4,0.8-0.6,1.2
				c-0.1,0.4,0,0.7,0.3,1c0,0,0.3-0.1,0.1,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.2-0.7,0.3-0.8,0.7
				c0,1,0.8,1.2,1.4,1.6c0.1,0,0.2-0.2,0.3-0.2c0,0.1-0.2,0.1-0.2,0.2c0,0.1,0,0.3,0.1,0.3c0.9,0,1.3,1,2.2,1
				c0.2,0.1,0.4,0.3,0.6,0.4c0.1,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.4-0.2,0.5-0.5c0.1-0.4,0.4-0.1,0.5-0.2
				c0.7-0.6,1.1-1.3,1.9-1.9c0.2-0.2,0.3-0.5,0-0.7c-0.1-0.1-0.2-0.1-0.3-0.2c0.1,0,0.2,0.1,0.3,0.2c0.8,0.1,1-0.1,0.4-0.8
				c-0.8-1-0.9-1.5-0.2-2.5c0.2-0.3,0.3-0.6,0.1-1c-0.3-0.4-0.6-0.4-0.9-0.1c-0.2,0.2-0.3,0.3-0.5,0c-0.4-0.5-0.5-0.4-1.4-0.6
				c0.1-0.1,0.2,0,0.3,0c0.4-0.1,1-0.1,1.1-0.5C331.3,30.6,330.8,30.4,330.3,30.2z"/>
			<path fill="#D1AF9D" d="M329.9,39.1c-0.2,0.2-0.3,0.3-0.5,0.5c0.1,0.1,0.1,0.1,0.1,0.1c-0.2,0.1-0.3-0.1-0.4-0.1
				c-0.2,0-0.3,0-0.5,0c-0.2,0.3-0.5,0.3-0.8,0.5c0,0.1,0,0.2,0,0.3c0.5,1,1.6,0.2,2.2,0.7c0.5,0.5,0.9,0.9,1.1-0.2
				c0.2-1.2,1.1-2,1.8-2.8c0.8-0.9,1.1-1.9,1.7-3c-0.4-0.1-0.7,0.7-0.9,0c-0.2-0.7-0.8-1.4-0.6-1.8c0.5-1.4-0.8-1.8-1.2-2.7
				c-0.3-0.7-1-0.6-1.7-0.3c0.1,0.4,0.5,0.6,0.9,0.7c-0.7,0.7-1.7,0.2-2.4,1.1c1.1-0.2,2.2-0.4,2.5,1c0.2-0.2,0.4-0.4,0.6-0.6
				c0.2-0.2,0.4-0.3,0.6,0c0.1,0.2,0.3,0.4,0,0.7c-1.1,1.3-1,2.1,0.4,3.1c-0.2,0.1-0.4,0.2-0.6,0.2l0,0c0,0.1,0.1,0.4,0,0.4
				c-0.7,0.6-1.5,1.1-2,2C330.2,39,330,39,329.9,39.1z"/>
			<path fill="#D4B1A0" d="M327.9,40.3c0-0.1,0-0.2,0-0.2c0.4-0.2,0.6-0.5,0.2-1c-0.1,0-0.1-0.1-0.2-0.1c-0.9,0-1.1-0.4-0.5-1.2
				c-0.6-0.2-1.1,1.1-1.7,0.1l0,0c-0.4-0.6-1.3-0.8-1.4-1.7c0,0-0.1,0-0.1,0c-0.3,0.5-0.9,0.5-1.5-0.1c-0.3-0.3-0.4-0.7-0.8-0.1
				c-0.1,1.3,0.6,2.4,1.1,3.5c0.1,0.3,0.7,1.3,1.4,0.3c0.1-0.1,0.3-0.2,0.4-0.1c0.1,0.1,0.3,0.3,0.2,0.5c-0.8,1.3,0.4,0.9,0.9,1
				c0.2-0.1-0.3-0.8,0.5-0.5C327,40.9,327.7,41.1,327.9,40.3z"/>
			<path fill="#CCAD99" d="M325.1,30.5c-1.3,0.3-2.6,1.5-2.8,2.7c-0.1,0.6,0.2,1,0.9,1.1c0.4-0.3,0.4-0.8,0.8-1.1
				c0.1-0.1,0.1-0.2,0.2-0.3c0.6-0.5,0.9-1.4,1.7-1.7c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.3-0.1-0.4-0.3-0.5l0,0
				C325.6,30.3,325.3,30.7,325.1,30.5z"/>
			<path fill="#C9AB96" d="M327.9,40.3c-0.4,0.4-1,0.3-1.5,0.2c-1.1-0.2-0.6,0.4-0.5,0.8c0.7,0.7,1.7,0.4,2.5,0.6
				c0.2,0,0.5-0.3,1-0.1c0.4,0.2,0.4-0.4,0.6-0.7c-0.4-0.3-0.7-0.8-1.4-0.4C328.4,40.9,328.2,40.4,327.9,40.3z"/>
			<path fill="#CCAD99" d="M321.9,36c0.2,0,0.4-0.2,0.5,0.1c0.1,0.6,0.3,1,1,0.7c0.6-0.2,0.3,0.7,0.8,0.7c0.5-0.4-0.3-0.9,0.2-1.3
				c0.4,0,0.7-0.2,1-0.5c-0.8-0.3-1.5-1-2.4-1c-0.1,0-0.2,0-0.2,0c-0.2,0.2-0.5,0.1-0.7,0.2C321,35.3,322.2,35.7,321.9,36z"/>
			<path fill="#DCB6A7" d="M322.9,34.8c0.1,0.1,0.1,0.3,0.2,0.4c0.3,0.1,0.9-0.5,0.6,0.4c0.5,0.1,1.1,0.1,1.6,0.2l0,0
				c-0.5-0.9-0.2-2.2-1.4-2.6c-0.7,0.2-0.6,0.7-0.7,1.2C323.1,34.5,323.1,34.7,322.9,34.8z"/>
			<path fill="#D1AF9D" d="M325.1,33.8c0.5-0.3,0.9-0.5,0.7-1.2c0-0.1,0-0.8,0.5-1c0.8-0.3,1.5-0.9,2.2-1.4
				c-0.2-0.3-0.5-0.2-0.8-0.1c-0.4,0.1-0.8,0.2-0.9-0.4l0,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0.1-0.2,0.2c0.1,0.2,0.2,0.3,0.2,0.5
				c0.1,0.2,0.4,0,0.5,0.2c-0.3,0.1-0.7,0.1-1,0.3v0c-1.3,0.5-1.3,1.6-1.1,2.8C325,33.7,325,33.8,325.1,33.8z"/>
			<path fill="#DCB6A7" d="M326,30.9c0.5,0.2,0.9-0.1,1.3-0.4c-0.3,0-0.6,0-0.8,0c-0.2,0-0.5,0-0.7,0
				C325.9,30.6,325.9,30.8,326,30.9z"/>
			<path fill="#D2B09E" d="M326.7,29.7c0.4,0,0.8,0.1,1.1-0.4C327.3,29.3,326.9,29.1,326.7,29.7C326.7,29.7,326.7,29.7,326.7,29.7z"
				/>
			<path fill="#DCB6A7" d="M322,35c0.3,0.1,0.7,0.4,0.7-0.2C322.4,34.7,322,34.4,322,35z"/>
			<path fill="#DCB6A7" d="M326.3,30c0.1-0.1,0.2-0.2,0.2-0.2c-0.2-0.3-0.4-0.2-0.5,0C325.9,30,326.1,30,326.3,30z"/>
			<path fill="#DCB6A7" d="M325.1,30.5c0.2,0.4,0.5,0.1,0.7,0C325.5,29.9,325.3,30.3,325.1,30.5z"/>
			<path fill="#0A0A0A" d="M320.5,101c0.8-0.2,1.7-0.2,2-1.2c0-0.2-0.1-0.3-0.3-0.3c-0.4,0-0.5,0.4-0.8,0.5c-0.1,0-0.3,0.3-0.4,0
				c0-0.1,0.1-0.3,0-0.4c0-0.3-0.1-0.5-0.5-0.5c-0.3,0-0.7-0.1-1,0.1c-1,0.6,0,1.4-0.1,2c-0.2,0.1-0.2,0-0.3-0.1
				c-0.9-0.2-1.3,0.4-1.7,0.9c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2-0.1,0.4-0.1,0.5c0,0.4,0.1,0.7,0.5,0.8c0.4,0.2,0.7-0.4,1.2-0.2
				c0.2,0.1,0.5,0.1,0.7,0.3c0.9,0.8,1,0.8,1.6-0.2c0.2-0.2-0.3-0.7,0.4-0.6c0.2-0.3,0.3-0.7,0.7-0.7l0,0c-0.2-0.6-0.8-0.7-1-1.2
				C320.8,101.7,320.7,101.2,320.5,101z"/>
			<path fill="#161615" d="M308.3,92.4c0.2,0.2,0.5,0.5,0.7,0.7c0.3-0.4,0.4-0.9,0.5-1.5c0.1-0.1,0.1-0.3,0.2-0.4
				c0.2-0.2,0.4-0.5,0.5-0.8c-0.3-0.3,0.2-0.7-0.2-1c-0.2-0.1-0.4,0-0.6,0.2c-0.4,0.6-0.7,0.4-1-0.1c-0.7,0.5-1.7,0.9-0.6,1.9
				c0.2,0.1,0.2,0.3,0,0.5c0,0.1,0,0.2,0,0.3C307.9,92.3,308,92.5,308.3,92.4z"/>
			<path fill="#4F4A44" d="M315,97.7c-0.4,0.1-0.5-0.6-0.9-0.5c-0.6-0.5-1-0.1-1.4,0.4c-0.3,0.2-0.7,0.4-0.9,0.8
				c-0.1,0.3,0,0.9,0.2,0.8c0.8-0.5,0.9,0.6,1.5,0.6c0.6-0.1,0.7-0.9,1.3-0.9c0.3-0.1,0.6-0.2,0.7-0.5C315.4,98,315.5,97.6,315,97.7
				z"/>
			<path fill="#59524B" d="M312.8,96.2c-0.3-0.4-0.3-0.9,0-1.3c0.2-0.4,0.3-0.9,0-1.3c-0.1-0.1-0.3-0.1-0.5-0.1
				c-0.4,0.7-0.9-0.3-1.3,0c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.4,0.2,0.8,0.4,1.2c0.1,0.2,0.1,0.3-0.2,0.4c-0.2,0-0.3,0.1-0.4,0.3
				c-0.2,0.5,0.1,0.8,0.5,1.1c0.3,0.2,0.7,0.3,1.1,0.2C312.8,96.9,313.1,96.7,312.8,96.2z"/>
			<path fill="#4E4943" d="M315,97.7c0.3,0.2,0.3,0.5,0.3,0.7c0.4,0.3,0.8,0.7,1.3,0.9c0.2,0.1,0.4,0.1,0.6,0.1
				c0.3-0.2,0.7-0.1,1-0.3c0.4-0.3,0.7-0.7,0.5-1.3c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.3-0.3-0.6-0.2c0,0.4-0.1,0.7-0.7,0.6
				C316.5,97.8,315.7,97.5,315,97.7z"/>
			<path fill="#0B0B0A" d="M311.2,93.6c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.2,0.1,0.6,0.5,0.6c0.4-0.1,0.3-0.5,0.4-0.8
				c0.5-0.3,0.1-0.9,0.2-1.3c-0.7-0.2-0.3-1-0.7-1.4c-0.3,0.6-1.1,0.4-1.5,0.9c0,0.1,0,0.2,0,0.3C310.7,92.6,309.8,93.7,311.2,93.6z
				"/>
			<path fill="#3B3834" d="M314.5,92.2c0.8,0.7-0.2,1.6,0.2,2.4c0.1,0.1,0.2,0.1,0.3,0c0.4-0.4,1.4-0.2,1.1-1.1
				c-0.3-0.8-0.1-2-1.6-1.8C314.4,91.8,314.4,92,314.5,92.2z"/>
			<path fill="#544E48" d="M303,86.6c0,0.2,0,0.3,0,0.5c0.2,0.4,0.7,0.5,1.1,0.7c0.5-0.1,1,0.5,1.4-0.2c0-0.5,0-0.9-0.7-1
				c-0.8-0.1-0.8-0.3-0.2-1C303.7,85.6,303.2,85.8,303,86.6z"/>
			<path fill="#262524" d="M305.4,87.6c-0.4,0.4-0.8-0.4-1.2,0c-0.6,0-0.5,0.4-0.6,0.8c-0.1,0.2,0.4,0.5-0.1,0.7
				c-0.2,0.7,0.2,0.9,0.8,0.9c0.2-0.6,0.6-1,1.2-1.2C305.6,88.4,305.8,88,305.4,87.6z"/>
			<path fill="#5C554E" d="M305.4,88.8c-1.1-0.3-1.2,0.4-1.2,1.2c0.3,0.3,0.2,0.6,0.2,1c0,0,0.1,0,0.1,0s0.1,0,0.1,0
				c0.3-0.3,0.5-0.6,0.8-1C305.9,89.6,306.4,89.2,305.4,88.8z"/>
			<path fill="#504B45" d="M314.5,92.2c0-0.2,0-0.3,0-0.5c0-0.1,0-0.1-0.1-0.2c-0.2-0.2-0.3-0.5-0.4-0.7c-0.5-0.2-0.8,0.6-1.3,0.2
				c-0.2-0.1-0.2,0.2-0.2,0.4c0.1,0.5,0.7,1,0.7,0.9C313.7,91.7,314.1,92.5,314.5,92.2z"/>
			<path fill="#504A45" d="M322.2,99.6c0.1,0.1,0.2,0.2,0.3,0.2c0.2-0.2,0.7,0.8,0.7-0.2c0-0.7,0.7-0.2,1-0.5
				c-0.8-0.3-1.5-1.5-2.3,0C321.8,99.4,322,99.5,322.2,99.6z"/>
			<path fill="#46413D" d="M320.7,97c0.8,0.9,1.2,0.1,1.7-0.3c0.2-0.2,0-0.3-0.1-0.5c-0.2-0.2-0.3-0.4-0.6-0.2
				c-0.2,0.2-0.4,0.5-0.8,0.3c-0.3-0.2-0.5,0-0.7,0.2l0,0C320.1,96.9,320.7,96.7,320.7,97z"/>
			<path fill="#5A534D" d="M324.6,99.2c-0.1,0.5-0.5,1.1,0.1,1.6c0.5,0.4,0.8,0.1,1.1-0.2C325.7,99.8,325.5,99.2,324.6,99.2z"/>
			<path fill="#524E49" d="M306.6,93.8c0,0.3,0,0.6-0.1,1c0,0.4,0.2,0.4,0.5,0.3l0,0c0.3-0.3-0.1-0.9,0.3-1.2
				c0.3,0.2,0.6,0.4,0.8,0.1c0.3-0.4-0.2-0.7-0.4-1c0.1-0.3,0-0.3-0.3-0.3c0,0.3-0.1,0.5-0.4,0.4C306.4,93,306.9,93.6,306.6,93.8z"
				/>
			<path fill="#0D0D0C" d="M317.9,97.2c0.1,0.1,0.3,0.2,0.4,0.2c0.4,0.2,0.6-0.2,0.8-0.4c0.2-0.2,0.3-0.5,0.2-0.8
				c-0.1-0.1-0.5,0-0.2-0.3c0.2-0.3,0-0.4-0.2-0.4c-0.3,0-0.5,0.3-0.7,0.5c-0.2,0.2-0.4,0.3-0.6,0.5c0.1,0.2,0.1,0.4-0.2,0.5
				C317.6,97.1,317.7,97.1,317.9,97.2z"/>
			<path fill="#1E1D1C" d="M319.1,96.9c-0.3,0.2-0.5,0.3-0.8,0.5c0,0.2,0,0.3,0.1,0.5c0.2,0.2,0.9-0.4,0.7,0.5
				c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2,0.1,0.3,0c0.2-0.2,0.2-0.6,0.5-0.8c0.2-0.3-0.2-1.1,0.7-0.9c-0.1-0.2-0.3-0.3-0.4-0.5
				c-0.2,0-0.3,0-0.5,0C319.7,96.8,319.5,97,319.1,96.9z"/>
			<path fill="#272725" d="M318.1,88.9c-0.2-0.6-0.3-1.1-0.4-1.6c-0.1-0.6-0.6-0.3-0.9-0.3c-0.4-0.1-0.2,0.3-0.2,0.5
				c-0.1,0.4,0,0.6,0.4,0.5C317.8,87.8,317.5,88.8,318.1,88.9z"/>
			<path fill="#413D39" d="M312.8,96.2c0,0.4-0.2,0.6-0.5,0.8c-0.1,0.4,0.2,0.6,0.5,0.9c0.3-0.4,0.6-0.9,1.3-0.7c0-0.1,0-0.2,0-0.2
				c0.1-0.2,0.1-0.3,0-0.5C313.7,96,313.3,96.1,312.8,96.2z"/>
			<path fill="#4C4641" d="M306.2,80.2c-0.5,0.1-0.5,0.4-0.5,0.7c0,0.3,0.1,0.7,0.5,0.5c0.4-0.2,1.2,0.1,1.2-0.7
				C307.2,80,306.2,81,306.2,80.2z"/>
			<path fill="#363431" d="M328.5,99.9c0.6-0.1,0.4-0.9,1-1.1c0.2-0.1,0-0.3-0.3-0.4c-0.8-0.2-1.3,0.2-1.7,0.8l0,0
				c0.2,0,0.3,0,0.5,0C328.2,99.3,328.2,99.6,328.5,99.9z"/>
			<path fill="#171716" d="M311.2,89.8c0.4,0.2,0.5-0.8,1-0.2c0.1,0,0.2,0,0.3,0c0.4-0.3,0.2-0.7,0-1.1c-0.2-0.2-0.2-0.6-0.6-0.4
				c-0.2,0.4-1,0.3-0.9,0.9C311.1,89.3,311,89.6,311.2,89.8z"/>
			<path fill="#5E564F" d="M318.3,96.2c0.2-0.2,0.4-0.5,0.6-0.7c-0.2-0.6-0.7-0.5-1.1-0.4c-0.6,0-0.4,0.6-0.5,0.9
				c0,0.1,0,0.1,0.1,0.2C317.7,96.4,318,96.4,318.3,96.2z"/>
			<path fill="#3B3835" d="M312.6,81.4c-0.3,0.5-0.4,1.3-1.3,1.1c0,0-0.1,0.2-0.1,0.3c0,0.1,0.2,0.2,0.3,0.2c0.4,0,0.9,0.1,1-0.1
				C312.9,82.6,313.1,82,312.6,81.4z"/>
			<path fill="#3D3A37" d="M310.1,85.1c-0.4-0.2-0.7,0.2-1-0.2c-0.2-0.2-0.4-0.3-0.6-0.1c-0.1,0.1-0.2,0.3-0.1,0.4
				c0.2,0.2,0.4,0.5,0.6,0.5C309.4,85.7,309.9,85.8,310.1,85.1z"/>
			<path fill="#1B1B1A" d="M321.3,90.5c0.2-0.4,0.5-0.7,1-1c-0.9-0.3-1.5,0-2.1,0.3c0,0,0.3,0.4,0.6,0.5
				C320.9,90.4,321.1,90.5,321.3,90.5z"/>
			<path fill="#57504A" d="M312.3,88.6c0.1,0.3,0.2,0.6,0.1,1c0.1,0.2,0.2,0.4,0.4,0.5c0.5,0,1.1-0.1,0.4-0.8
				c-0.2-0.3-0.3-0.7-0.6-0.9C312.4,88.2,312.3,88.3,312.3,88.6z"/>
			<path fill="#565049" d="M314,85.7c0,0.4,0.1,0.6,0.4,0.4c0.3-0.2,1-0.1,0.9-0.7c0-0.2-0.2-0.6-0.6-0.4
				C314.4,85.2,314,85.4,314,85.7z"/>
			<path fill="#4C4742" d="M310.4,92.1c0-0.1,0-0.1,0-0.2c0.1-0.3,0.5-0.5,0.6-0.8c0-0.3,0.1-0.6-0.4-0.6c-0.2,0-0.3,0.3-0.5,0
				c-0.3,0.1-0.6,0.4-0.7,0.7c0.3,0.4,0.2,1,0.8,1C310.3,92.2,310.4,92.2,310.4,92.1z"/>
			<path fill="#2B2A28" d="M310.9,91.2c-0.5,0.1-0.4,0.4-0.4,0.8c0.4-0.4,1.6,0.2,1.4-1c-0.4-0.2-0.2-0.8-0.7-1
				C310.8,90.3,311.4,90.9,310.9,91.2z"/>
			<path fill="#554F49" d="M312.6,92.3c-1,0.3,0.3,0.9-0.2,1.3c0.1,0,0.3,0,0.4,0c0.3,0,0.5,0,0.6-0.4c0.1-0.5-0.2-0.4-0.4-0.5
				C312.9,92.6,312.7,92.4,312.6,92.3z"/>
			<path fill="#4F4944" d="M322.2,99.6c-0.1-0.2-0.2-0.3-0.2-0.5c-0.3,0.2-0.6,0.4-0.9,0.5c0,0.4-0.4,0.4-0.6,0.5
				C321.6,100.5,321.6,100.5,322.2,99.6z"/>
			<path fill="#48443F" d="M327.9,102c-0.7,1.5,0.7,0.4,1,0.7c0.3-0.1,0.6-0.2,0.3-0.5c-0.1-0.2-0.2-0.7-0.6-0.4
				C328.4,102,328.2,102,327.9,102z"/>
			<path fill="#534D47" d="M308.3,89.5c-0.1,0.5,0,0.9,0.5,1c0.5,0.1,0.5-0.5,0.7-0.8c0.1-0.3,0-0.4-0.3-0.5
				C308.9,89.4,308.7,89.6,308.3,89.5z"/>
			<path fill="#4F4944" d="M319.7,95.3c0-0.7,0.7-1,0.5-1.5c-0.2-0.3-0.8-0.1-1.3,0C319.9,93.9,319.1,94.7,319.7,95.3z"/>
			<path fill="#48443F" d="M308.9,76.8c0.8-0.2,1.1-0.6,0.9-1.1c0-0.2-0.4-0.4-0.6-0.3C308.8,75.8,309,76.3,308.9,76.8z"/>
			<path fill="#47433E" d="M322.4,86.6c-0.5-0.5-0.6-1.1-1.3-1.4C321.2,86,321.2,86.7,322.4,86.6z"/>
			<path fill="#524C46" d="M325.2,95.2c-0.5,0.7-0.4,1.2,0.1,1.7c0.1,0.1,0.4,0.2,0.5,0.1c0.2-0.2,0.1-0.4-0.1-0.5
				C325.3,96.2,325.3,95.7,325.2,95.2z"/>
			<path fill="#514B45" d="M313.4,86.5c0.5-0.6,0.5-1,0.1-1.3c-0.1-0.1-0.4-0.2-0.5,0C312.6,85.7,313.3,85.9,313.4,86.5z"/>
			<path fill="#4A4540" d="M319.3,88.9c-0.3,0-0.4,0.2-0.4,0.5c0,0.3-0.1,0.6,0.3,0.6c0.3,0,0.5,0,0.5-0.3
				C319.7,89.3,319.6,89,319.3,88.9z"/>
			<path fill="#524C47" d="M317.6,90.9c0-0.3,0.1-0.7-0.4-0.7c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2,0.1,0.3,0.3,0.4
				C317,91.4,317.3,91.5,317.6,90.9z"/>
			<path fill="#4A4540" d="M307.8,83.6c-0.5-0.1-0.6-0.8-1.2-0.6c-0.2,0.1-0.2,0.4,0,0.6C306.9,84.1,307.2,83.9,307.8,83.6z"/>
			<path fill="#403C38" d="M319.5,91.6c0,0.4-0.5,0.9,0,1.2c0.2,0.1,0.7,0,0.7-0.1C320.4,92.1,319.6,92,319.5,91.6z"/>
			<path fill="#544E48" d="M322.2,92.9c-0.1,0.5,0.2,0.7,0.6,0.7c0.4-0.1,0.6-0.5,0.5-0.8c-0.1-0.5-0.4-0.1-0.6-0.1
				C322.5,92.6,322.3,92.7,322.2,92.9z"/>
			<path fill="#1B1B1A" d="M317.6,90.9c-0.3,0.2-0.6,0.2-1,0c0.1,0.3-0.4,0.6,0,0.9c0.3,0.2,0.6,0,0.9-0.1
				C318,91.5,317.7,91.2,317.6,90.9z"/>
			<path fill="#242322" d="M320,87.5c-0.2,0.1-0.6,0-0.6,0.4c0,0.3,0.3,0.4,0.6,0.4c0.3,0,0.5-0.1,0.5-0.4
				C320.5,87.7,320.4,87.5,320,87.5z"/>
			<path fill="#554F48" d="M326.5,99.8c0.2,0,0.3,0,0.5,0c0.2-0.2,0.6-0.3,0.5-0.7c0,0,0,0,0,0c-0.5,0.1-0.1-0.9-0.7-0.6
				c-0.7,0.3,0,0.6,0,0.9C326.8,99.5,326.6,99.7,326.5,99.8z"/>
			<path fill="#4B4641" d="M321.3,93.3c0.3,0,0.4-0.1,0.4-0.3c0-0.3-0.2-0.6-0.5-0.5c-0.2,0-0.5,0.2-0.5,0.3
				C320.6,93.2,321,93.2,321.3,93.3z"/>
			<path fill="#4E4944" d="M325.3,94.7c0.2-0.1,0.6-0.1,0.5-0.4c-0.1-0.2-0.2-0.7-0.6-0.6c-0.2,0-0.5,0.3-0.4,0.5
				C324.8,94.5,325,94.7,325.3,94.7z"/>
			<path fill="#49453F" d="M327,100.6c-0.2,0.3-0.5,0.6-0.2,0.9c0.4,0.4,0.5-0.2,0.9-0.3C327.7,100.8,327.3,100.7,327,100.6z"/>
			<path fill="#544E48" d="M322.9,95.5c-0.1,0.4,0,0.5,0.3,0.4c0.2-0.1,0.6,0.1,0.5-0.4c0-0.2-0.1-0.6-0.5-0.6
				C322.9,95,322.9,95.3,322.9,95.5z"/>
			<path fill="#302E2C" d="M307,85.2c-0.3-0.2-0.6-0.2-0.6,0.3c0,0.2,0.1,0.4,0.4,0.3c0.2-0.1,0.7,0,0.7-0.4
				C307.5,85,307.2,85,307,85.2z"/>
			<path fill="#1F1F1E" d="M314.1,96.4c0,0.2,0,0.3,0,0.5c0.4-0.2,0.7-0.5,1.2-0.3c0.3,0.1,0.6,0,0.7-0.4c-0.3-0.2-0.4,0-0.5,0.2
				c-0.2-0.2-0.5-0.3-0.7-0.5l0,0C314.6,96.3,314.4,96.5,314.1,96.4z"/>
			<path fill="#565049" d="M319.5,98.6c-0.1,0-0.1,0-0.2,0c-0.3,0.4-0.1,0.5,0.3,0.7c0.3-0.1,0.7,0.1,1-0.2
				c-0.2-0.2-0.3-0.3-0.5-0.5C319.9,98.5,319.7,98.5,319.5,98.6z"/>
			<path fill="#423E3A" d="M312.3,88.6c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.3-0.4-0.6-0.2
				c-0.2,0.2-0.1,0.4,0.1,0.6C312,88.3,312.2,88.4,312.3,88.6z"/>
			<path fill="#33312E" d="M309.2,87.8c-0.3-0.2-0.6-0.2-0.9,0c-0.2,0.2,0.1,0.5,0.1,0.7l0,0c0.2,0,0.3,0,0.5,0
				C309.2,88.4,309.5,88.2,309.2,87.8z"/>
			<path fill="#1B1B1A" d="M314.3,94.8c0.3,0.3-0.6,1.2,0.5,1.2c0,0,0,0,0,0c0.3-0.4,0.1-0.7-0.2-0.9
				C314.6,94.8,314.5,94.7,314.3,94.8z"/>
			<path fill="#3A3733" d="M314.3,94.8c0.1,0.1,0.2,0.2,0.2,0.2c0.4,0,0.8,0,1.2,0c-0.1-0.4-0.4-0.4-0.7-0.5c-0.1,0-0.2,0-0.3,0
				c-0.1,0-0.3,0-0.4,0l0,0C314.3,94.6,314.3,94.7,314.3,94.8z"/>
			<path fill="#2C2B28" d="M316.9,89c0.1,0.5,0.4,0.7,0.8,0.9c0.2-0.6-0.1-0.9-0.6-0.9C317.1,88.9,317,88.9,316.9,89z"/>
			<path fill="#494540" d="M312.8,90c0-0.3-0.1-0.5-0.4-0.5c-0.1,0-0.2,0-0.3,0c0,0.3,0,0.7,0.4,0.8C312.8,90.4,312.8,90.2,312.8,90
				z"/>
			<path fill="#21211F" d="M319.4,85.1c-0.3,0.1-0.5,0.2-0.5,0.5c0,0.2,0.2,0.3,0.3,0.2c0.2-0.1,0.5-0.1,0.5-0.4
				C319.7,85.2,319.5,85.1,319.4,85.1z"/>
			<path fill="#59524C" d="M325.6,90.9c-0.5-0.2-0.7,0.2-0.6,0.5c0.1,0.3,0.4,0.7,0.8,0.2C325.9,91.4,325.6,91.2,325.6,90.9z"/>
			<path fill="#312F2D" d="M318.6,85c0.2-0.1,0.4-0.3,0.4-0.5c0-0.2-0.3-0.2-0.5-0.2c-0.3,0-0.3,0.2-0.2,0.4
				C318.4,84.8,318.3,85,318.6,85z"/>
			<path fill="#504A45" d="M317.4,97c0.4,0,0.4-0.2,0.3-0.5c-0.1-0.2-0.1-0.3-0.3-0.5c0,0,0,0,0,0C316.8,96.3,316.6,96.7,317.4,97z"
				/>
			<path fill="#292826" d="M329.4,96.5c-0.4,0.1-0.7,0.3-0.5,0.7c0.2,0.3,0.6,0.5,0.7-0.2C329.6,96.8,329.6,96.6,329.4,96.5z"/>
			<path fill="#3D3A37" d="M326.5,91.7c0.4,0.2,0.2,0.8,0.7,1c0.1,0.1,0.2,0.1,0.2,0c0-0.5-0.5-0.8-0.7-1.2
				C326.5,91.3,326.4,91.4,326.5,91.7z"/>
			<path fill="#59534C" d="M304.7,84.5c-0.2-0.1-0.4-0.3-0.7-0.2c0.2,0.2-0.2,0.8,0.3,0.8C304.7,85.2,304.7,84.8,304.7,84.5z"/>
			<path fill="#2E2D2A" d="M322.2,92.9c0.2,0,0.4-0.1,0.4-0.3c0-0.4-0.2-0.7-0.8-0.6C322,92.3,322.1,92.6,322.2,92.9z"/>
			<path fill="#46413D" d="M321,95.5c-0.3-0.1-0.7-0.4-0.8-0.3c-0.3,0.2,0.1,0.5,0.1,0.7C320.8,96.2,321,96,321,95.5z"/>
			<path fill="#272624" d="M324.1,94.4c-0.1,0.4,0,0.7,0.4,1.2C324.5,95,324.3,94.7,324.1,94.4z"/>
			<path fill="#1E1D1C" d="M327.3,94.3c0.3-0.1,0.5,0.3,0.8,0.1c-0.1-0.4-0.4-0.5-0.6-0.8l0,0c-0.1,0.1-0.2,0.2-0.2,0.2
				C327.1,94,327.1,94.2,327.3,94.3z"/>
			<path fill="#1E1D1C" d="M327.5,92.6c-0.1,0-0.2,0-0.2,0c0,0.3-0.3,0.8,0.3,0.9c0,0,0,0,0,0C327.8,93.3,327.9,92.9,327.5,92.6z"/>
			<path fill="#0B0B0B" d="M310.9,91.2c0.1,0.1,0.3,0.4,0.4,0c0.1-0.4,0-0.8-0.1-1.2c0,0,0,0,0,0c-0.3-0.2-0.3,0.1-0.5,0.2
				c0,0.1,0,0.2,0,0.2C311,90.6,310.9,90.9,310.9,91.2z"/>
			<path fill="#2B2A28" d="M310.7,90.2c0.2-0.1,0.3-0.2,0.5-0.2c0-0.1,0-0.2,0-0.2c0.2-0.3,0.2-0.6-0.2-0.7
				C310.7,89.4,310.6,89.8,310.7,90.2z"/>
			<path fill="#3E3A37" d="M307,88.8c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.4-0.1,0.4-0.3c0-0.2-0.1-0.3-0.2-0.3
				C307.2,88.6,307,88.6,307,88.8z"/>
			<path fill="#2B2A28" d="M314.3,87.6c-0.3,0.5-0.3,0.9,0,1.4c0.4-0.2,0.1-0.7,0.3-0.9C314.5,87.9,314.5,87.7,314.3,87.6z"/>
			<path fill="#504B45" d="M328.5,99.9c0.2-0.5,0-0.8-0.5-0.8C328.2,99.3,327.5,100.2,328.5,99.9z"/>
			<path fill="#3F3C38" d="M328.3,92.7c0.1,0.2,0.1,0.4,0.4,0.4c0.1,0,0.4,0,0.3-0.2c-0.1-0.2-0.1-0.4-0.4-0.4
				C328.4,92.4,328.3,92.5,328.3,92.7z"/>
			<path fill="#47433E" d="M323.5,90.8c-0.2,0-0.4,0.1-0.4,0.3c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.3-0.2,0.3-0.3
				C323.8,90.9,323.7,90.8,323.5,90.8z"/>
			<path fill="#534D47" d="M326.5,91.7c0.1-0.1,0.2-0.2,0.2-0.3c-0.3-0.3-0.9-0.1-1.2-0.5c0.1,0.3,0.2,0.5,0.2,0.8
				C326,91.7,326.3,91.7,326.5,91.7z"/>
			<path fill="#49443F" d="M307.2,87.2c0.1,0.3,0.3,0.6,0.6,0.8c0.4-0.2,0.1-0.4,0-0.6C307.6,87.3,307.5,87.1,307.2,87.2z"/>
			<path fill="#45413C" d="M314.3,87.6c0,0.2,0.1,0.4,0.3,0.5c0.2-0.2,0.5-0.3,0.3-0.6C314.7,87.2,314.4,87.4,314.3,87.6z"/>
			<path fill="#1C1C1B" d="M309.2,87.8c-0.1,0.2-0.2,0.5-0.2,0.7c0.3-0.1,0.7,0.2,0.8-0.2C309.9,88,309.4,88,309.2,87.8z"/>
			<path fill="#1E1D1C" d="M321,95.5c-0.1,0.3-0.3,0.5-0.7,0.5c0,0.2,0,0.3,0,0.5c0.2-0.1,0.5-0.2,0.7-0.2
				C321,96,321.4,95.8,321,95.5z"/>
			<path fill="#4F4A44" d="M315.5,96.5c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.3,0,0.4-0.1c0.2-0.2-0.1-0.2-0.2-0.3
				C315.6,95.6,315.6,96,315.5,96.5z"/>
			<path fill="#524C47" d="M319.1,96.9c0.5,0.2,0.8,0.1,0.7-0.5c-0.2-0.1-0.3-0.1-0.5-0.2C319.2,96.5,319,96.6,319.1,96.9z"/>
			<path fill="#2E2D2B" d="M319.5,98.6c0.2,0,0.3,0,0.5,0c-0.1-0.2,0.3-0.5,0-0.7C319.6,97.9,319.5,98.3,319.5,98.6z"/>
			<path fill="#4C4742" d="M306.8,89.4c-0.5,0.2-0.7,0.4-0.7,0.8C306.7,90.1,306.7,89.8,306.8,89.4z"/>
			<path fill="#151515" d="M325.1,89.7c-0.2-0.1-0.3,0.1-0.3,0.3c-0.1,0.2,0.1,0.3,0.2,0.3c0.1,0,0.3-0.1,0.4-0.2
				C325.4,89.8,325.2,89.7,325.1,89.7z"/>
			<path fill="#161615" d="M307.1,91.5c-0.2,0.1-0.3,0.2-0.2,0.4c0,0.1,0.2,0.2,0.2,0.2c0.2,0,0.3-0.1,0.3-0.3
				C307.4,91.6,307.2,91.5,307.1,91.5z"/>
			<path fill="#504A45" d="M306.6,93.8c0.2-0.2,0.3-0.5,0.5-0.7c-0.1-0.2-0.3-0.4-0.5-0.4c-0.1,0.2-0.2,0.4-0.2,0.7
				C306.6,93.4,306.6,93.6,306.6,93.8z"/>
			<path fill="#44403C" d="M327.3,96.8c0.4-0.2,0.5-0.4,0.5-0.8C327.4,96.1,327.2,96.3,327.3,96.8z"/>
			<path fill="#3C3936" d="M307.2,77.3c-0.2,0.1-0.4,0.2-0.3,0.4c0,0.1,0.1,0.2,0.2,0.1c0.2-0.1,0.4-0.1,0.3-0.4
				C307.4,77.3,307.3,77.3,307.2,77.3z"/>
			<path fill="#161616" d="M308.4,79.6c0.3,0,0.4-0.2,0.4-0.3c0.1-0.1,0-0.2-0.1-0.2c-0.2,0-0.5,0.1-0.5,0.3
				C308.2,79.4,308.3,79.5,308.4,79.6z"/>
			<path fill="#4A4641" d="M311.7,84.8c0.2,0,0.3-0.1,0.3-0.3c0-0.2,0-0.3-0.2-0.3c-0.2,0-0.3,0.1-0.3,0.2
				C311.5,84.7,311.5,84.8,311.7,84.8z"/>
			<path fill="#201F1E" d="M310.5,86.7c-0.4-0.1-0.5,0.2-0.6,0.4c-0.1,0.1,0.1,0.2,0.2,0.2C310.4,87.2,310.5,87,310.5,86.7z"/>
			<path fill="#554E49" d="M316.9,89c0.1,0,0.2,0,0.3,0c0-0.2,0.1-0.5,0-0.7c0-0.1-0.3-0.1-0.4,0.1C316.6,88.7,316.7,88.9,316.9,89z
				"/>
			<path fill="#2A2927" d="M314.1,77.2c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0,0.3,0,0.3-0.1c0-0.2-0.2-0.3-0.3-0.3
				C314.4,77,314.3,77.1,314.1,77.2z"/>
			<path fill="#3D3A37" d="M320.8,81.8c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.1-0.3-0.1
				C321,81.6,320.9,81.7,320.8,81.8z"/>
			<path fill="#2A2927" d="M320.2,83.1c-0.2,0-0.3,0-0.3,0.2c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.2-0.1,0.2-0.2
				C320.4,83.3,320.3,83.2,320.2,83.1z"/>
			<path fill="#232221" d="M306.7,86.5c0,0.1-0.1,0.4,0.2,0.3c0.1,0,0.2-0.2,0.3-0.3c0,0-0.1-0.2-0.2-0.3
				C306.8,86.1,306.7,86.3,306.7,86.5z"/>
			<path fill="#1F1E1D" d="M308.5,88.5c-0.2,0-0.5,0.1-0.4,0.4c0,0.1,0.2,0.2,0.2,0.2C308.6,89,308.5,88.8,308.5,88.5
				C308.5,88.5,308.5,88.5,308.5,88.5z"/>
			<path fill="#3E3A37" d="M309.2,89.2c0.1,0.2,0.2,0.4,0.3,0.5c0.2-0.1,0.3-0.2,0.5-0.3C309.8,89.2,309.6,88.9,309.2,89.2z"/>
			<path fill="#232321" d="M326.5,96c0.1,0.1,0.1,0.2,0.2,0.2c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1-0.1-0.2-0.2-0.1
				C326.6,95.7,326.5,95.9,326.5,96z"/>
			<path fill="#2B2A28" d="M318.4,80.6c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.3,0.2
				C318.1,80.5,318.2,80.5,318.4,80.6z"/>
			<path fill="#44403C" d="M316.3,81.2c0.3-0.3,0.3-0.6,0.1-0.7c-0.3-0.1-0.1,0.2-0.2,0.4C316.2,80.9,316.2,81,316.3,81.2z"/>
			<path fill="#282725" d="M309.6,80.5c-0.3-0.2-0.6-0.1-0.6,0.1c-0.1,0.2,0.1,0.2,0.3,0.1C309.3,80.7,309.4,80.6,309.6,80.5z"/>
			<path fill="#1B1B1A" d="M312.5,84.3c0.1,0.1,0.1,0.2,0.2,0.2c0.1-0.1,0.1-0.3,0.2-0.4c0,0-0.1-0.2-0.1-0.2
				C312.5,83.9,312.7,84.1,312.5,84.3z"/>
			<path fill="#151615" d="M317.9,86.5c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.1-0.3
				C318,86.2,317.9,86.3,317.9,86.5z"/>
			<path fill="#191918" d="M315.7,87.1c-0.2,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
				C315.8,87.2,315.8,87.1,315.7,87.1z"/>
			<path fill="#45403C" d="M321.3,90.5c-0.1-0.2-0.3-0.3-0.5-0.3c0,0.2,0,0.5,0.1,0.5C321,90.9,321.1,90.6,321.3,90.5z"/>
			<path fill="#3F3B38" d="M314,90.7c0,0.3,0,0.7,0.4,0.7C314.5,91.1,314.3,90.9,314,90.7z"/>
			<path fill="#544D47" d="M307.8,91.9c0.3-0.1,0.6-0.3,0.5-0.5c-0.1-0.3-0.4,0.1-0.6,0.1C307.8,91.6,307.8,91.7,307.8,91.9z"/>
			<path fill="#494440" d="M323.6,92c0.2,0.1,0.3,0.2,0.4,0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.2-0.2-0.2-0.2
				C323.8,91.8,323.7,91.9,323.6,92z"/>
			<path fill="#504A45" d="M307.5,92.6c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.2,0.3-0.3,0.5-0.5c-0.2-0.1-0.3-0.2-0.5-0.2
				C307.7,92.3,307.6,92.5,307.5,92.6z"/>
			<path fill="#49443F" d="M327.3,94.3c0-0.2,0-0.3,0-0.5c-0.2,0-0.5,0.1-0.4,0.4C326.9,94.4,327.1,94.5,327.3,94.3z"/>
			<path fill="#47433E" d="M314.3,94.6c0-0.2,0-0.5-0.3-0.6c0,0-0.1,0.1-0.1,0.1C313.8,94.5,314,94.5,314.3,94.6
				C314.3,94.5,314.3,94.6,314.3,94.6z"/>
			<path fill="#4F4A45" d="M329.4,96.5c0,0.2,0.1,0.4,0.3,0.5c0.2-0.1,0.2-0.3,0.1-0.5C329.7,96.4,329.5,96.3,329.4,96.5z"/>
			<path fill="#554E48" d="M369.7,16.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5,0.5-0.2,1-0.2,1.6c0.1,0.2,0.3,0.3,0.5,0.3
				c0.6,0,0.9-0.5,1.1-0.9c-0.3,0.8-0.1,1.5,0.8,1.6c1,0.1,1.4-0.5,1.5-1.4c0-0.2-0.2-0.3-0.3-0.4c-0.5,0.2-0.6-0.2-0.8-0.5
				C371.4,16,370.6,16.3,369.7,16.4z"/>
			<path fill="#554F49" d="M374.2,13.2c0-0.1,0-0.1,0-0.2c-0.2-0.1-0.4-0.1-0.5-0.2c0,0.9-0.5,0.8-1.2,0.7c-0.4-0.1-0.4,0.3-0.2,0.5
				c0.4,0.4-0.1,0.6-0.2,0.9c-0.1,0.2,0,0.3,0.1,0.4c0.3,0.2,0.7,0.2,1,0.3c0.6-0.2,0.4-0.6,0.2-1c-0.1-0.3-0.1-0.4,0.5-0.2
				c0.2-0.1,0.2-0.3,0.3-0.5C374.4,13.6,374.5,13.4,374.2,13.2z"/>
			<path fill="#0C0C0C" d="M373.3,15.6c0-0.8-0.7-0.2-0.9-0.5c-0.7,0.4-1.6,0.2-2.2,0.9c-0.2,0-0.4,0.1-0.5,0.3
				c0.7,0.6,1.6-0.1,2.4,0.3c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.2c0.3,0,0.6,0,0.9-0.3C373.2,15.9,373.2,15.7,373.3,15.6
				L373.3,15.6z"/>
			<path fill="#554E49" d="M371.6,11.7c-0.4-0.1-0.8,0.3-1.2,0c-0.6,0.2-1-0.1-1.4-0.5c-0.4,0-0.4,0.2-0.2,0.5l0,0
				c0.4,0.3-0.4,0.7,0,1c0.2,0.1,0.5,0.2,0.7,0.2c0.6-0.2,1.2-0.2,1.7-0.5C371.5,12.3,371.6,12.1,371.6,11.7z"/>
			<path fill="#4F4A44" d="M368,15.3c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.2,0.1,0.3,0c0.4-0.8-0.1-1.5-0.3-2.3c0-0.1-0.3,0-0.4,0.1
				c-0.2,0.2-0.6,0-0.5,0.5C367.9,14.5,367.9,14.9,368,15.3z"/>
			<path fill="#4A4540" d="M371.6,14.4c0.2-0.2,0.3-0.4,0.1-0.6c-0.2-0.2-0.3-0.1-0.5,0.1c-0.2,0.2-0.5,0.2-0.8,0.3
				c-0.1,0.1-0.4,0-0.5,0.2c0,0.2-1.1,0.7,0.1,0.6c0.1,0,0.2,0.4,0.3,0.5c0.4,0.2,0.6-0.1,0.8-0.3C371.3,14.9,371.4,14.6,371.6,14.4
				z"/>
			<path fill="#1C1C1A" d="M369.5,18.2c0-0.6,0-1.1,0-1.7c0-0.2,0-0.4-0.3-0.5c-0.1,0.2-0.4,0.2-0.5,0.5c0,0.2,0.1,0.5,0.1,0.7
				c0.2,0.3,0.2,0.6,0,1c-0.2,0.3-0.5,0.6-0.7,0.9c0.4,0.4,0.7,0.1,1-0.2c0.2-0.1,0.3-0.3,0.5-0.5C369.6,18.4,369.5,18.3,369.5,18.2
				z"/>
			<path fill="#5C554E" d="M366.6,13c-0.2,0.3-0.8,0.1-0.7,0.7c0.4,0.1,0.7,0.7,1.2,0.2c0.4-0.1,0.4-0.4,0.5-0.7
				c0.3,0,0.5-0.2,0.5-0.5c-0.2,0-0.5,0-0.7,0C367,12.7,366.8,12.7,366.6,13z"/>
			<path fill="#3C3935" d="M371.2,12.5c-0.6,0-1.3-0.3-1.7,0.5C370.1,13.2,370.8,13.5,371.2,12.5z"/>
			<path fill="#524C47" d="M371.1,15.1c-0.2,0.3-0.5,0.4-0.8,0.5c-0.2,0.1-0.2,0.3-0.2,0.5c0.7-0.4,1.7-0.1,2.2-0.9
				c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1,0-0.2,0-0.2,0C371.6,14.9,371.4,14.9,371.1,15.1z"/>
			<path fill="#2F2E2B" d="M368.7,12.7c0.1-0.3,1.1-0.7,0-1c-0.2,0.3-0.5,0.6-0.7,1C368.3,12.6,368.5,13,368.7,12.7z"/>
			<path fill="#34322F" d="M371.1,15.1c0.3,0.1,0.7,0.4,0.7-0.2c-0.1-0.2-0.2-0.3-0.2-0.5C371.2,14.5,370.9,14.6,371.1,15.1z"/>
			<path fill="#3F3C38" d="M372.8,12.5c-0.2-0.1-0.5-0.3-0.7-0.4c0,0.4-0.1,0.9,0.3,1C372.7,13.1,372.8,12.7,372.8,12.5z"/>
			<path fill="#2F2E2B" d="M367.6,13.2c-0.2,0.2-0.3,0.5-0.5,0.7c0.2,0.1,0.3,0.2,0.5,0.3c0.2-0.2,0.3-0.3,0.5-0.5
				C367.8,13.6,367.6,13.4,367.6,13.2z"/>
			<path fill="#3E3B37" d="M369.9,14.4c0.2,0,0.4,0,0.5-0.2c-0.3,0-0.1-0.7-0.5-0.5C369.6,13.8,369.6,14.1,369.9,14.4z"/>
			<path fill="#49443F" d="M368.7,16.6c0.3,0,0.5-0.1,0.5-0.5c-0.2,0-0.4-0.1-0.5-0.3c-0.1,0-0.2,0-0.3,0c0,0.2,0,0.5,0,0.7
				C368.6,16.6,368.7,16.6,368.7,16.6z"/>
			<path fill="#3E3B37" d="M369.1,14.2c0.1-0.1,0.3-0.1,0.3-0.2c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.2
				C368.9,14,369,14.1,369.1,14.2z"/>
			<path fill="#524C46" d="M377.4,25.4c-1.1,0.3-1.2,1-1,1.9c0.2,0,0.3,0,0.5,0c0.1-0.7,1-1.1,0.7-1.9
				C377.6,25.4,377.5,25.4,377.4,25.4z"/>
			<path fill="#4A4540" d="M377.9,30c-0.4-0.1-0.7-0.4-0.9,0c-0.1,0.1,0,0.5,0.1,0.5C377.5,30.7,377.6,30.3,377.9,30z"/>
			<path fill="#4B4641" d="M377.7,27.1c-0.3,0.1-0.5,0.2-0.8,0.2c0,0.3-0.1,0.8,0.5,0.5C377.5,27.6,378.1,27.5,377.7,27.1z"/>
			<path fill="#20201E" d="M376,30.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C376.1,30.1,376,30.1,376,30.3z"/>
			<path fill="#0D0D0D" d="M372.8,29.3c0.1,0.2,0.2,0.5,0.2,0.7c1.2,0.9,1-0.7,1.7-0.9c-0.5-0.1-1.2,0.4-1-0.5
				C373.5,28.8,373.4,29.3,372.8,29.3z"/>
			<path fill="#3B3835" d="M376.4,32.2c-0.1,0.9,0.4,0.9,1,0.7c0.1-0.2,0.1-0.3,0-0.5C377,32.5,376.7,32.4,376.4,32.2z"/>
			<path fill="#58514B" d="M377.4,32.4c0,0.2,0,0.3,0,0.5c0.2,0,0.5,0,0.7,0C378.2,32.1,377.7,32.3,377.4,32.4z"/>
			<path fill="#111110" d="M310.9,95.8c0.2,0,0.3,0,0.5,0c0.1-0.3-0.2-0.4-0.4-0.5c-0.3-0.1-0.6-0.2-1-0.2c-0.2,0-0.4,0.2-0.6,0
				c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.1-0.2,0.1-0.1,0.3c0.2,0.4,0.1,0.7-0.4,0.8c0.1,0.7,0.3,1.5,0.9,1.9c0.3-0.1,0.4-0.4,0.5-0.6
				c0.2-0.3,0.5-0.4,0.6-0.7C310.8,96.2,310.7,95.9,310.9,95.8z"/>
			<path fill="#4B4640" d="M311.2,93.6c-0.2-0.2-0.8-0.2-0.3-0.8c0.2-0.3,0-0.6-0.4-0.6c0,0,0,0,0,0c-0.3,0.2-0.5,0.1-0.6-0.2
				c-0.1-0.3-0.2-0.6-0.6-0.5c-0.1,0.6-0.1,1.1-0.2,1.7c0.8-0.5,0.9,0.2,1.2,0.7c0.2,0.4,0.5,0.6,0.9,0.3
				C311.1,93.9,311.1,93.7,311.2,93.6z"/>
			<path fill="#544D48" d="M309.7,97.6c-0.5-0.5-0.4-1.4-1-1.9c-1.1,0.8,0.1,1.3,0.2,1.9c0.2,0.4-0.2,0.5-0.2,0.8
				c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.3,0.1,0.5,0.1c0.5-0.1,0.4-0.7,0.8-0.9c0-0.2,0-0.3,0-0.5
				C310,97.8,309.9,97.7,309.7,97.6z"/>
			<path fill="#0D0D0C" d="M311.1,94.1c-0.3-0.1-0.6-0.2-0.9-0.3c0,0.1,0,0.2,0,0.3c-0.1,0.2,0.1,0.5-0.1,0.7c0,0.1,0,0.2,0.1,0.3
				c0.3,0.1,0.5,0.3,0.8,0.4c0.2,0,0.4,0,0.5,0.2c0.2,0,0.4,0.2,0.5,0.1c0.2-0.1,0-0.3,0-0.5C311.6,94.9,311.4,94.5,311.1,94.1z"/>
			<path fill="#302E2C" d="M308.9,98.6c-0.1-0.3,0.6-0.6,0-1c-0.2,0.4-0.4,0-0.7,0c-0.3,0-0.5,0.2-0.8,0.5c-0.1,0.2-0.1,0.4-0.2,0.5
				c0.3,0,0.5,0.2,0.5,0.5c0.3,0.1,0.6,0.2,0.9,0.3c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1-0.1-0.1l0,0
				C308.6,98.9,308.5,98.7,308.9,98.6z"/>
			<path fill="#4E4843" d="M308.1,95.6c0.3-0.1,0.3-0.4,0.4-0.6c0.1-0.2-0.1-0.4-0.3-0.4c-0.3,0-0.5,0.1-0.6,0.5
				C307.6,95.3,307.8,95.5,308.1,95.6z"/>
			<path fill="#242322" d="M310.2,94.8c0.5-0.3,0.2-0.5,0-0.7c-0.4,0-0.6,0.1-0.2,0.5C310,94.7,310.1,94.8,310.2,94.8z"/>
			<path fill="#2C2B29" d="M307.1,95c-0.2,0-0.4-0.1-0.5-0.3c-0.1,0-0.1,0-0.2,0c-0.6,0.7,0.4,0.4,0.5,0.7
				C307,95.4,307.1,95.2,307.1,95z"/>
			<path fill="#57504A" d="M307.5,98.1c0.3-0.1,0.6-0.2,0.8-0.5c-0.5-0.5-0.6,0-0.7,0.3C307.5,98,307.5,98.1,307.5,98.1z"/>
			<path fill="#504A45" d="M307.4,93.8c-0.5,0.3-0.2,0.8-0.3,1.2C307.5,94.7,307.2,94.2,307.4,93.8z"/>
			<path fill="#47433F" d="M310.2,94.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0.2-0.3,0.4-0.5,0.5c0.1,0.2,0.3,0.2,0.5,0.2
				c0.1-0.1,0.2-0.1,0.3-0.2C310.2,95,310.2,94.9,310.2,94.8z"/>
			<path fill="#373532" d="M368.7,11.7c0-0.2,0-0.4,0.2-0.5c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2,0.1-0.3,0.2-0.5,0.3
				C368.3,11.3,368.5,11.5,368.7,11.7z"/>
			<path fill="#5A534D" d="M368.8,10.3c0.1,0,0.2,0.1,0.4,0.2c0.2,0,0.3-0.1,0.2-0.3c-0.1-0.1-0.3-0.1-0.4-0.1
				C368.8,10.1,368.7,10.1,368.8,10.3z"/>
			<path fill="#272624" d="M370.4,9.1c-0.3,0-0.5,0-0.7,0.2C370,9.5,370.4,9.7,370.4,9.1z"/>
			<path fill="#5B544D" d="M372.1,16.3c0,0.1,0,0.2,0,0.3c0.2,0.2,0.5,0.5,0.8,0.7c0.1,0.1,0.2,0.1,0.2,0.2c0.4,0.1,0.8-0.1,1.3-0.1
				c0.1-0.1,0.3-0.2,0.2-0.4c0.2-1-0.9-0.9-1.2-1.4c-0.1,0.1-0.2,0.2-0.2,0.2C372.9,16.3,372.4,16.1,372.1,16.3z"/>
			<path fill="#4F4944" d="M374.5,17c-0.1,0.1-0.2,0.2-0.3,0.3c-1.2,0.6-1.3,0.8-0.8,2c0.7,0.3,1.2-0.1,1.5-0.7
				c0.3-0.3,0.7-0.4,0.8-0.9c0-0.1,0-0.3,0-0.4C375.2,17.7,374.9,17.3,374.5,17z"/>
			<path fill="#46423E" d="M375,14.6c-0.4-0.6-0.5,0.2-0.7,0.2c0,0.2,0,0.5,0.3,0.4C374.9,15.2,374.9,14.9,375,14.6z"/>
			<path fill="#4B4641" d="M377.4,35.5c0-0.1,0-0.2,0-0.3c-0.2,0.1-0.2-0.5-0.5-0.2c-0.3,0.5-0.1,1,0.2,1.4
				C377.7,36.2,377,35.7,377.4,35.5z"/>
			<path fill="#0D0D0C" d="M374.8,23.7c-0.1,0.5,0.5,0.6,0.5,1c0,0,0,0,0,0c0.3,0.2,0.6,0.3,0.9,0.2c0.2-0.1,0.3-0.2,0.3-0.4l0,0
				c-0.5-0.5,0.3-0.8,0.2-1.2c0-0.1,0-0.2,0-0.3c0.1-0.3,0.5-0.3,0.7-0.5c0.2-0.1,0.2-0.3,0.3-0.5c-0.3-0.6-0.4-1.1,0.1-1.6
				c-0.8-0.2-0.7,0.7-1.1,0.9c-0.1,0.1-0.1,0.1-0.1,0.2c-0.1,0.3-0.4,0-0.5,0.2c-0.4,0.3-0.5,0.7-0.6,1.1
				C375.4,23.3,375.1,23.5,374.8,23.7z"/>
			<path fill="#2A2927" d="M374.7,18.5c-0.3,0.5-0.7,0.7-1.2,0.8c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.2-0.2,0.5,0,0.7
				c0.2,0.1,0.4,0.2,0.5,0.5c0.1,0.1,0.2,0.1,0.3,0c0.1,0,0.1,0,0.2,0c0.4-0.1,0.3-0.6,0.6-0.8c0.1,0,0.1-0.1,0.2-0.2
				c0-0.4-0.2-0.9,0.5-0.9C375.3,18.5,375,18.5,374.7,18.5z"/>
			<path fill="#0D0E0D" d="M374.7,18.5c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.3,0.1,0.4,0.2c0-0.5,0.7-0.8,0.5-1.4c-0.2,0-0.3,0-0.5,0
				C375.3,17.9,374.9,18.1,374.7,18.5z"/>
			<path fill="#1D1C1B" d="M374.8,19.9c-0.1,0-0.2,0-0.3,0c-0.3,0.6,0.2,0.7,0.5,1l0,0c0.3,0.1,0.6,0,0.9-0.1c0,0,0.1-0.1,0.1-0.1
				C375.8,20,375,20.4,374.8,19.9z"/>
			<path fill="#3A3734" d="M376.2,17.8c-0.1,0.5-0.8,0.8-0.5,1.4c0.3-0.1,0.3-0.2,0.3-0.5c0.1-0.2,0.3-0.2,0.5-0.2
				C376.4,18.2,376.5,17.9,376.2,17.8z"/>
			<path fill="#121211" d="M376.7,21.6c0-0.1,0-0.1,0-0.2c-0.2-0.2-0.5-0.5-0.7-0.7c0,0,0,0,0,0c-0.3,0.4-0.1,0.7,0.2,1
				C376.3,21.7,376.5,21.7,376.7,21.6z"/>
			<path fill="#030303" d="M377.6,36.9c0.2,0.1,0.4,0.1,0.6,0.2c0-0.2,0-0.5,0-0.7C378,36.6,377.8,36.8,377.6,36.9z"/>
			<path fill="#EDE2CF" d="M437.1,162.9c-0.1-0.2-0.2-0.4-0.5-0.4c0,0-0.1,0.1-0.1,0.2C436.7,163,436.9,162.9,437.1,162.9
				C437.2,162.9,437.1,162.9,437.1,162.9z"/>
			<path fill="#58514B" d="M377.1,23.5c0.1,0.2,0.2,0.4,0.5,0.5c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.5
				C377.4,23.1,377.2,23.3,377.1,23.5z"/>
			<path fill="#4F4A44" d="M303.5,89c0.2,0,0.4,0.2,0.5-0.1c0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.1-0.1-0.2,0
				c-0.2,0.1-0.3,0.3-0.2,0.5C303.3,89,303.4,89,303.5,89z"/>
			<path fill="#0D0D0C" d="M303.5,88.5l0.1,0c0,0,0.1,0,0.1,0c0.1-0.4,0.2-0.7,0.5-0.9c-0.3-0.4-0.8-0.2-1.2-0.5
				C302.9,87.7,303.4,88,303.5,88.5z"/>
			<path fill="#0C0C0C" d="M374.2,13.2c0,0.2,0,0.5,0,0.7c0.2-0.1,0.4-0.3,0.6-0.4C374.7,13.4,374.5,13.3,374.2,13.2z"/>
			<path fill="#494540" d="M376.7,23.3c0,0.4-1.3,0.6-0.2,1.2c0-0.4,0.3-0.6,0.5-1C376.8,23.4,376.8,23.4,376.7,23.3z"/>
			<path fill="#46423D" d="M377.4,22.5c-0.3,0.1-0.9-0.4-0.7,0.5C377,23,377.3,22.9,377.4,22.5z"/>
			<path fill="#151514" d="M324.6,101.5c-0.5-0.4-1-0.6-1.9-0.3c1.7,0.4,1,1.6,1.2,2.5c0.5,0.1,0,0.5,0.2,0.7
				c0.7-0.5,1.5-0.4,2.2-0.2c0.2-0.4,0.7-0.9-0.2-1.1c-0.3,0-0.5,0.2-0.8,0.3c-0.1,0-0.3,0.2-0.2-0.1c0.1-0.2,0.3-0.3,0.4-0.5
				c0-0.3-0.6-0.4-0.3-0.9C325,101.5,324.7,101.6,324.6,101.5z"/>
			<path fill="#383632" d="M321,103.7c-0.5,1-1,0.4-1.4,0c-0.8-0.2-0.5,0.4-0.6,0.8c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.6-0.4,0.7,0.1
				c0.2,0.3,0.3,0.6,0.8,0.5l0,0c0.3-0.2,0.7-0.3,0.8-0.7c0.1-0.3,0.1-0.6,0.4-0.8c-0.2-0.2-0.5-0.3-0.7-0.5
				C321.2,103.6,321.1,103.6,321,103.7z"/>
			<path fill="#171615" d="M321.5,104.8c-0.1,0.4-0.8,0.2-0.7,0.7c0.4,1,1.1-0.1,1.6,0.2c0.3-0.3,0.5-0.6,0-1c0,0-0.1,0-0.1,0
				c-0.2,0-0.4-0.1-0.4-0.2C321.7,104.5,321.6,104.7,321.5,104.8z"/>
			<path fill="#524C46" d="M326,103.1c0.8,0.2-0.2,0.8,0.3,1c0.4,0.1,0.8,0.1,1.4,0.2c-0.5-0.7-0.5-1.5-1.5-1.6c0,0-0.1,0-0.1,0
				c-0.1,0-0.1,0.1-0.2,0.2C325.9,103,326,103,326,103.1z"/>
			<path fill="#4D4843" d="M326.3,102.7c-0.2-0.4,0.7-0.9-0.2-1.2c-0.2-0.1-0.5-0.1-0.8,0C325.4,102.1,325.3,102.8,326.3,102.7
				L326.3,102.7z"/>
			<path fill="#514C46" d="M320.5,101c0,0.1,0,0.2,0,0.4c0.1,0.3,0.1,0.7,0.5,0.7c0.4-0.1,0.2-0.5,0.2-0.8
				C321,101.2,320.7,101.1,320.5,101z"/>
			<path fill="#4C4742" d="M324.1,104.4c-0.1-0.2,0.8-0.8-0.2-0.7c-0.1,0-0.4,0-0.3,0.2C323.6,104.1,323.8,104.2,324.1,104.4z"/>
			<path fill="#4D4843" d="M321,103.7c0.1,0,0.2,0,0.3,0c0.2-0.1,0.5-0.2,0.2-0.5c-0.2-0.1-0.4-0.2-0.6-0.4
				C320.9,103.1,320.9,103.4,321,103.7z"/>
			<path fill="#44403B" d="M325.3,101.5c0.3,0,0.5,0,0.8,0c-0.5-0.4-1-0.2-1.5,0c0.1,0.2,0.3,0.2,0.5,0.2
				C325.2,101.7,325.3,101.6,325.3,101.5z"/>
			<path fill="#605851" d="M321.5,104.8c0.1-0.1,0.3-0.2,0.4-0.2c0-0.2,0-0.3,0-0.5C321.4,104.1,321.1,104.2,321.5,104.8z"/>
			<path fill="#423E3A" d="M322.2,102.5c0,0.3,0.1,0.6,0.4,0.6c0.1,0,0.2-0.1,0.2-0.2C322.8,102.6,322.5,102.5,322.2,102.5
				C322.2,102.5,322.2,102.5,322.2,102.5z"/>
			<path fill="#232321" d="M323.4,105.5c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2-0.1,0.1-0.2c-0.1-0.1-0.2-0.2-0.4-0.3
				C323.5,105.3,323.5,105.4,323.4,105.5z"/>
			<path fill="#090909" d="M308.8,99.1c0,0.1,0,0.2,0,0.3c-0.3,0.9,0,1.6,0.7,2.1c0.7,0.4,0.5-0.4,0.8-0.6c0.3,0.1,0.3,0.5,0.6,0.6
				c0.4,0,0.6-0.3,0.6-0.7c0.1-0.5-0.6-0.5-0.5-1c0-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.3-0.3-0.5-0.4c-0.5-0.5-0.8,0.6-1.3,0.2
				C309,99.1,308.9,99.1,308.8,99.1z"/>
			<path fill="#544E48" d="M311.9,102.5c0.1,0.3-1.2,0.6,0,0.9c0.3,0.5,0.6,0.8,1,1.2l0,0c0.2,0.1,0.5,0,0.7,0.2l0.1,0l0.1,0
				c0,0,0.1-0.1,0.1-0.1c0.4-0.5,0.3-1.1,0.6-1.6c0.3-0.7-0.2-1-0.9-1.1c-0.6-0.1-1.1,0.3-1.6,0.3
				C311.9,102.4,311.9,102.5,311.9,102.5z"/>
			<path fill="#4F4A44" d="M310.9,99.3c0,0.2,0,0.5,0,0.7c0.2,0.2,0.7,0.2,0.5,0.7c0,0.3-0.2,0.6-0.5,0.7c-0.1,0.2-0.1,0.3,0,0.5
				c0.3,0.2,0.6,0.3,1,0.5c0,0,0,0,0,0c0.7-0.7,1-1.6,1.1-2.5c0-0.9-0.7-0.7-1.3-0.8c-0.2-0.1-0.4-0.1-0.6,0
				C311,99.2,310.9,99.2,310.9,99.3z"/>
			<path fill="#181817" d="M307.1,99.8c-0.4,0.7,0,1.3,0.2,1.9c0.3,0,0.7,0.5,0.7,0c0-0.3,0.4-1-0.2-1.1
				C307.3,100.5,307.4,100,307.1,99.8z"/>
			<path fill="#242322" d="M310.9,102c0-0.2,0-0.3,0-0.5c0-0.7-0.5-0.9-1.1-1c-0.2,0.3,0.5,1-0.4,1c0.1,0.2,0.2,0.3,0.3,0.5
				c0.2,0.1,0.4,0.4,0.7,0.2C310.5,102,310.7,102,310.9,102z"/>
			<path fill="#131312" d="M314.3,106.1c0.5,0.5,0,0.9,0,1.4c0.4,0,0.6-0.5,1.1-0.1c-0.4-0.8-0.9-1.6-1-2.5c-0.2-0.2-0.4-0.2-0.6,0
				c0,0-0.1,0-0.1,0c0,0.1,0.1,0.1,0.1,0.2C314.2,105.2,314,105.8,314.3,106.1z"/>
			<path fill="#131312" d="M310.4,102.2c-0.2-0.1-0.3-0.5-0.7-0.2c0,0,0,0.1,0,0.1c0,0.5-0.6,1.2,0.1,1.4
				C310.4,103.6,310.4,102.7,310.4,102.2z"/>
			<path fill="#0D0D0C" d="M312.8,104.6c0.2-0.8-0.5-1-1-1.2c-0.3,0.6,0.1,1.1,0.3,1.5C312.4,105.4,312.6,104.6,312.8,104.6z"/>
			<path fill="#0B0C0B" d="M308.5,103.7c0.5-0.5-0.1-1.1,0.3-1.6C308.1,102.5,308,103,308.5,103.7
				C308.5,103.7,308.5,103.7,308.5,103.7z"/>
			<path fill="#272624" d="M311.2,105.6c0.2,0.4,0.3,1,1,0.7C311.8,106.1,312.1,105,311.2,105.6z"/>
			<path fill="#31302D" d="M313.5,106.8c0.4-0.2,0-0.5,0-0.7c0.1-0.2,0.1-0.3,0-0.5c-0.5,0.2-0.5,0.6-0.5,1
				C313.3,106.5,313.4,106.6,313.5,106.8z"/>
			<path fill="#56504A" d="M313.5,105.6c0,0.2,0,0.3,0,0.5c0.2,0,0.5,0,0.7,0c-0.1-0.4,0.1-0.9-0.5-1
				C313.8,105.3,314,105.6,313.5,105.6z"/>
			<path fill="#181817" d="M313.5,104.8c0-0.2,0-0.5-0.3-0.6c-0.1,0-0.3,0.2-0.4,0.3C313,105,313.2,105.1,313.5,104.8z"/>
			<path fill="#0F0F0E" d="M313.8,104.9c0.2,0,0.4,0,0.6,0c0.3-0.3,0.9-0.1,1.1-0.8c0.2-0.6,0.6-0.3,0.9,0c0.1,0.1,0.2,0.3,0.4,0.5
				c0.1,0.1,0.2,0.1,0.3,0.1c0.2-0.1,0.4-0.2,0.5-0.4c0-0.1,0-0.3,0-0.4c-0.2-0.2-0.4-0.2-0.5-0.5c0-0.1-0.1-0.2-0.1-0.3
				c-0.1-0.3-0.4-0.5-0.7-0.4c-0.1,0-0.2,0-0.3,0.1c-0.3,0.2-0.5,0.3-0.8,0.5c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2-0.1,0-0.2,0-0.2
				c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.3,0.3-0.5,0.3-0.7c0-0.2-0.1-0.4-0.3-0.5c-0.1-0.2-0.7,0.2-0.5-0.4c-0.3-0.3-0.7-0.6-1-1
				c-0.2-0.3-0.4-0.2-0.7-0.1c-0.3,0.8-0.7,1.5-1,2.3c0.2,0.1,0.4,0.8,0.8,0.2c0.3-0.4,0.7-0.2,1.1-0.3c0.5-0.1,0.9,0.3,0.5,0.9
				C313.9,103.7,313.8,104.3,313.8,104.9z"/>
			<path fill="#1F1E1D" d="M317.4,104.4c-0.2,0.1-0.3,0.2-0.5,0.3c0.1,0.6-0.3,0.6-0.7,0.7c-0.1,0.4-0.8,0.6-0.3,1.1
				c0.5,0.5,1,0.3,1.5-0.1c0,0,0.1-0.1,0.1-0.1c0.2-0.4,0.5-0.7,0.4-1.1C317.8,104.8,317.7,104.5,317.4,104.4z"/>
			<path fill="#494540" d="M316.2,105.4c0.5-0.1,0.4,0.5,0.8,0.7c0.4-0.5,0.4-0.9,0-1.4c0,0,0,0,0,0c-0.2-0.2-0.5,0.2-0.7,0
				C315.8,104.9,316.1,105.1,316.2,105.4z"/>
			<path fill="#060606" d="M317.4,107.3c0.7,0.3,1.1,0.3,0.9-0.7c0-0.1-0.1-0.1-0.2-0.1c-0.2,0-0.3-0.1-0.5-0.2
				c-0.1,0-0.2,0-0.2,0.1c0,0,0,0,0,0C317.4,106.6,317.4,106.9,317.4,107.3z"/>
			<path fill="#1F1E1D" d="M316.2,104.6c0.2,0.4,0.5,0.1,0.7,0c-0.1-0.2-0.3-0.4-0.5-0.5C316.4,104.3,316.4,104.5,316.2,104.6z"/>
			<path fill="#060606" d="M319.8,107c0.6-0.1,1.1,0.3,1.7,0c-0.4-0.4-1.1-0.5-1.2-1.2c0,0-0.1-0.1-0.1-0.1
				c-0.6-0.2-0.9,0.3-1.3,0.5c-0.1,0.1-0.1,0.2-0.1,0.3C319,106.9,319.1,107.5,319.8,107z"/>
			<path fill="#5A534D" d="M320.7,105.6c-0.2-0.2-0.5-0.5-0.7-0.7c-0.2-0.1-0.3,0-0.4,0.2c0,0.1,0,0.2,0,0.3
				c0.1,0.3,0.4,0.3,0.6,0.4c0,0,0,0,0,0C320.5,105.8,320.7,105.8,320.7,105.6z"/>
			<path fill="#5C554E" d="M322.4,104.9c0.2,0.3,0.1,0.6,0,1c0.6,0,0.5-0.4,0.5-0.8C322.9,104.6,322.6,104.8,322.4,104.9z"/>
			<path fill="#56504A" d="M318.8,106.5c0-0.1,0-0.1,0-0.2c0,0,0-0.1-0.1-0.1c-0.2-0.3-0.3-0.6-0.5-0.9c0,0-0.1-0.1-0.1-0.1
				c-0.1-0.1-0.2-0.1-0.3,0c-0.2,0.4-0.6,0.7-0.5,1.2c0.1,0,0.2,0,0.2,0c0.2,0.1,0.2,0.4,0.5,0.2c0.1,0,0.1,0,0.2,0
				C318.5,106.6,318.7,106.6,318.8,106.5z"/>
			<path fill="#0D0D0C" d="M373.3,19.7c0.2-0.1,0.2-0.3,0.2-0.4c0.3-0.5-0.9-1.7,0.8-1.4c0.2,0-0.1-0.4-0.1-0.6
				c-0.4-0.2-0.8-0.1-1.1,0.2c-0.1,0.5-0.4,0.9-0.8,1.2c-0.3,0.3-0.6,0.1-0.9-0.1c-0.7-0.3-0.1-0.8-0.2-1.2
				c-0.7-0.1-0.9,0.4-1.2,0.8c-0.2,0.3,0.2,0.5,0.3,0.7c0.4,0.3,1,0.4,1.1,1c0.1,0.2,0.1,0.3,0.2,0.5
				C372.3,20.4,372.8,20,373.3,19.7z"/>
			<path fill="#57504A" d="M376.2,21.6c-0.1-0.3-0.2-0.6-0.2-0.9c-0.4-0.1-0.7,0.1-1,0.2c0.1,0.2,0.7,0.5,0.1,0.7
				c-1.1,0.3-0.7,0.6-0.1,1c0.3,0.2,0,0.4-0.2,0.5c0,0.2,0,0.4,0,0.6c0.6,0.2,1.1,0.5,0.9-0.5c-0.1-0.5,0.3-0.9,0.5-1.3
				C376.3,21.7,376.3,21.7,376.2,21.6z"/>
			<path fill="#57514A" d="M371.4,19.9c0.7-1.2-0.8-0.7-1-1.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3,0.3-0.6,0.6-0.9,0.9
				c-0.1,0.3-0.2,0.5-0.1,0.8c-0.1,0.3-0.5,0.3-0.4,0.7c0.3,0.2,0.5,0.5,0.7-0.1C369.6,19.9,370.4,19.7,371.4,19.9z"/>
			<path fill="#171716" d="M372.1,24.4c0.1-0.2,0.3-0.2,0.5-0.2c0.3-0.1,0.1-0.3,0.1-0.5c-0.2-0.2,0-0.6-0.5-0.6
				c-0.1,0.2-0.3,0.3-0.5,0.3c0,0.1,0,0.3-0.1,0.3c-0.3,0.2-1.1,0.2-0.9,0.7C370.9,25,371.6,24.8,372.1,24.4z"/>
			<path fill="#4B4641" d="M373.3,19.7c-0.7-0.1-1.2,0.4-1.8,0.7c0,0.8,0.7,0.4,1,0.6c0.4,0.2,0.5-0.2,0.5-0.5
				C373.2,20.2,373.2,19.9,373.3,19.7z"/>
			<path fill="#47433E" d="M375,20.9c-0.2-0.3-0.3-0.7-0.5-1c-0.7,0.1-0.2,0.8-0.5,1C374.3,21.3,374.7,21.2,375,20.9z"/>
			<path fill="#59524C" d="M372.1,23.2c0.2,0.2,0.3,0.4,0.5,0.6c0.1-0.3,0.9-0.6,0.2-1C372.5,22.5,372.2,22.8,372.1,23.2z"/>
			<path fill="#58514B" d="M370.7,20.3c-0.5,0.4-0.9,0.5-0.8,0.9c0,0.2,0.2,0.3,0.5,0.2C371,21.4,370.5,20.9,370.7,20.3z"/>
			<path fill="#44403C" d="M373.8,20.9c-0.1,0-0.2,0-0.3,0c-0.2,0.1-0.3,0.3-0.1,0.5c0.1,0.1,0.2,0.1,0.3,0.1
				C373.7,21.2,373.8,21,373.8,20.9z"/>
			<path fill="#524C46" d="M373.8,23.7c0.2,0,0.5,0,0.5-0.3c0-0.2-0.2-0.2-0.3-0.2C373.8,23.4,373.8,23.5,373.8,23.7z"/>
			<path fill="#45413C" d="M376.4,24.5c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0.2-0.6,0-0.5,0.5c0.3,0.3,0.7,0.3,0.9,0.1
				C376.9,25.1,376.7,24.7,376.4,24.5z"/>
			<path fill="#2F2D2B" d="M375.7,25.2c0.2-0.2,0.3-0.3,0.5-0.5c-0.3,0-0.6,0-1,0C375.3,24.9,375.5,25.1,375.7,25.2z"/>
			<path fill="#4D4843" d="M370.1,32.6c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0,0.3-0.2,0.2-0.3c0-0.1-0.2-0.1-0.2-0.2
				C370.3,32.3,370.2,32.4,370.1,32.6z"/>
			<path fill="#5B544D" d="M366.8,15.9c-0.2,0.3-0.4,0.6,0,0.9c0.4-0.2,0.7-0.5,0.7-1C367.3,15.8,367.1,15.8,366.8,15.9z"/>
			<path fill="#373532" d="M368,11c-0.1-0.3-0.4-0.5-0.7-0.5c0.4,0.3-0.4,0.6,0.1,0.9C367.7,11.6,367.8,11.1,368,11z"/>
			<path fill="#2F2E2B" d="M366.6,13c0.3,0,0.6,0,0.7-0.3c0-0.1,0-0.3,0-0.4c-0.3-0.2-0.4,0.1-0.5,0.2c-0.1,0-0.2,0-0.3,0
				C366.6,12.6,366.6,12.8,366.6,13z"/>
			<path fill="#524D47" d="M366.8,12.5c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.4,0-0.4C366.9,11.8,366.8,12.1,366.8,12.5z"/>
			<path fill="#090908" d="M377.4,45.8c0-0.3,0.1-0.8-0.5-0.4c0,0.2,0,0.3,0,0.5C377.1,45.8,377.2,45.8,377.4,45.8z"/>
			<path fill="#040404" d="M368.7,16.6c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.5,0.2c0,0.5,0.4,0.5,0.7,0.5
				C369.3,17,369,16.8,368.7,16.6z"/>
			<path fill="#252523" d="M367.8,18c-0.1,0.1-0.2,0.2-0.2,0.2c-0.2,0.2-0.2,0.5-0.5,0.5c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0.1-0.3,0.1
				c0.2,0.3,0.5,0.3,0.8,0.4C368.1,19.2,367.7,18.5,367.8,18z"/>
			<path fill="#3B3935" d="M369,18.7c-0.3,0.2-0.5,0.6-0.9,0.5c0,0.1,0,0.2,0,0.3c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0.2,0.4,0.1,0.5,0
				C369.2,19.3,369.3,19,369,18.7z"/>
			<path fill="#050505" d="M369,19.6c-0.2,0-0.3,0-0.5,0c0,0.3,0.1,0.6,0.5,0.7c0.1-0.2,0.3-0.4,0.2-0.7
				C369.1,19.6,369.1,19.5,369,19.6z"/>
			<path fill="#040404" d="M367,18.7c0.7,0.4,0.4-0.3,0.5-0.5C367.4,18.4,367.2,18.5,367,18.7z"/>
			<path fill="#D1AF9D" d="M323.9,33.1c0.5,0.9,1,1.8,1.4,2.6c0.8-0.1,0.1-0.6,0.2-1l0,0c-0.4-0.2-0.5-0.6-0.5-1c0,0,0,0,0,0
				c-0.3-0.5-0.2-1.3-1.2-1C323.9,32.9,323.9,33,323.9,33.1z"/>
			<path fill="#C9AB96" d="M328.2,39.1c0,0.3-0.1,0.7-0.2,1c0.3-0.1,1.1,0.6,0.7-0.5C328.7,39.3,328.5,39.1,328.2,39.1z"/>
			<path fill="#C9AB96" d="M329.9,39.1c0.3,0.1,0.5,0.2,0.5-0.2c-0.2-0.1-0.5-0.4-0.7-0.2C329.4,38.9,329.7,39,329.9,39.1z"/>
			<path fill="#D4B1A0" d="M325.8,37.9c0.3-0.1,0.6,0.1,0.9-0.4C326.3,37.6,325.9,37.3,325.8,37.9C325.8,37.9,325.8,37.9,325.8,37.9
				z"/>
			<path fill="#D1AF9D" d="M332.3,36.5c-0.1-0.2-0.1-0.5-0.4-0.5c-0.1,0-0.3,0.4-0.2,0.5C331.8,36.7,332.1,36.4,332.3,36.5
				L332.3,36.5z"/>
			<path fill="#C9AB96" d="M329.1,39.6c0,0.3,0.2,0.5,0.5,0.3c0.1-0.1,0.2-0.1,0.3-0.2c-0.2,0-0.4-0.1-0.5-0.1
				C329.3,39.6,329.2,39.6,329.1,39.6z"/>
			<path fill="#D1AF9D" d="M325.5,34.8c0.2,0,0.4,0.2,0.4-0.1c0-0.1-0.1-0.3-0.1-0.3C325.6,34.4,325.6,34.6,325.5,34.8
				C325.5,34.8,325.5,34.8,325.5,34.8z"/>
			<path fill="#C1A790" d="M324.3,39.3c0-0.1,0.1-0.1,0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0.1-0.2,0.1
				C324,39.3,324.1,39.3,324.3,39.3z"/>
			<path fill="#DCB6A7" d="M323.9,32.9c0.7,0,0.8,0.6,1.2,1c0-1.1,0.1-2.1,1-2.9C324.7,30.9,324.6,32.3,323.9,32.9z"/>
			<path fill="#0C0C0C" d="M319.6,99.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.1,0-0.2,0-0.3c-0.5-0.4-0.6,0.2-0.7,0.4
				c-0.1,0.3-0.4,0.2-0.5,0.3c-0.3,0.1-0.8-0.5-0.9,0.3c-0.1,0.2-0.3,0.4-0.5,0.6c-0.1,0.1-0.3,0.2-0.4,0.4
				c-0.3,0.4-0.2,0.8,0.2,1.1c0.2,0.1,0.3,0.1,0.5,0.1c0.4,0,0.7-0.3,1-0.6c0.6-0.6,1.1-0.5,1.4,0.4c0.1,0.1,0.2,0.1,0.3,0
				c0.2-0.1,0.5,0,0.3-0.4C319.5,100.4,319.1,99.9,319.6,99.3z"/>
			<path fill="#534D47" d="M319.3,101.3c0-0.4,0.1-0.8-0.4-1c-0.4-0.1-0.8,0-1.1,0.3c-0.3,0.2-0.4,0.7-0.9,0.7
				c-0.1,0.1-0.2,0.3-0.1,0.5c0.1,0.4,0.5,0.4,0.8,0.5c0.4-0.5,0.7-1,1.4-1C319.2,101.3,319.2,101.4,319.3,101.3z"/>
			<path fill="#423E3A" d="M317.9,105.1c0.1,0,0.1,0,0.2,0c0.2-0.1,0.4-0.2,0.4-0.5c0-0.2-0.3-0.4,0.1-0.5c0.2-0.1,0.4-0.3,0.3-0.7
				c-0.5-0.5-0.6,0.3-0.9,0.3c-0.2,0.1-0.4,0-0.5,0.2c0,0.2,0,0.3,0,0.5C317.4,104.7,317.5,105,317.9,105.1z"/>
			<path fill="#191918" d="M318.8,103.4c0.1,0.5-1.2,0.5-0.4,1.2c0.4,0.3,0.5,0,0.7-0.2c0.4-0.1-0.1-0.8,0.5-0.7
				C319.4,103.4,319.1,103.4,318.8,103.4z"/>
			<path fill="#252523" d="M317.6,102.2c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0.1-0.1,0.2-0.1,0.3
				c0.1,0.2,0.3,0.3,0.6,0.3c0.2,0,0.3-0.1,0.5-0.2C317.5,102.4,317.6,102.3,317.6,102.2z"/>
			<path fill="#1F1E1D" d="M317.4,103.9c0.2-0.1,0.4-0.1,0.5-0.2c-0.5,0-0.5-0.4-0.5-0.8c-0.5-0.1-0.4,0.5-0.7,0.7
				C316.8,103.9,317.1,103.9,317.4,103.9z"/>
			<path fill="#56504A" d="M316.7,103.6c0.6,0.1,0.4-0.5,0.7-0.7c0-0.2,0-0.3,0-0.5c-0.1,0-0.3,0-0.4,0c-0.4,0.2,0,0.7-0.2,1
				C316.7,103.5,316.7,103.6,316.7,103.6z"/>
			<path fill="#222220" d="M319.3,101.3c-0.1,0-0.2,0-0.3,0c0,0.2-0.1,0.6,0.3,0.6c0.3,0,0.2-0.4,0.2-0.6
				C319.5,101.3,319.4,101.3,319.3,101.3z"/>
			<path fill="#2B2A28" d="M309.2,91.4c0.5,0.1,0.3,1.4,1.2,0.7c-0.5-0.2-0.4-0.9-1-1C309.4,91.3,309.3,91.3,309.2,91.4z"/>
			<path fill="#242422" d="M316.4,101.3c-0.2-0.3-0.2-0.6,0-0.9c-0.6,0-0.3-0.6-0.6-0.9c-0.2-0.1-0.5-0.2-0.7-0.3
				c-0.2-0.2-0.7,0-0.6-0.5c-0.2-0.2-0.3-0.3-0.5,0.1c-0.1,0.3-0.3,0.6-0.5,0.9c0,0,0.1,0.1,0.1,0.1c0.3,0.1,0.6,0.1,0.8,0.3
				c0.5,0.6,1.1,1.1,1.9,1.3C316.3,101.3,316.4,101.3,316.4,101.3z"/>
			<path fill="#57504A" d="M314.5,98.6c-0.3,0.5,0.2,0.6,0.4,0.7c0.3-0.1,0.6,0,0.8,0.3c0.2,0.3,0,0.9,0.7,0.8
				c0.1-0.1,0.2-0.2,0.2-0.3c0.3-0.3,0.2-0.6,0-1c-0.6,0-0.7-1.1-1.4-0.7C315,98.5,314.8,98.5,314.5,98.6z"/>
			<path fill="#090908" d="M312.8,97.9c0.1-0.4-0.3-0.6-0.5-0.9c-0.3,0-0.6-0.1-0.9-0.3c-0.3-0.2-0.5-0.1-0.6,0.3
				c0.1,0.4,0.3,0.8,0.3,1.2c0.1,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3,0,0.5-0.1C312.2,98.2,312.5,98,312.8,97.9z"/>
			<path fill="#1E1D1C" d="M311.9,98.4c-0.2,0-0.3,0-0.5,0c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.3,0.2,0.5,0.4,0.7c0.5,0.1,1.2-0.1,1.2,0.8
				c0.2,0.2,0.3-0.1,0.4-0.1c0.4,0,0.4-0.2,0.3-0.5c0,0,0,0,0,0c-0.3,0-0.4-0.1-0.5-0.4c-0.1-0.6-0.1-0.6-0.7-0.3
				C311.7,99.3,311.9,98.7,311.9,98.4z"/>
			<path fill="#242322" d="M310.9,97.2c0-0.3,0.2-0.5,0.5-0.5c-0.3-0.3-0.6-0.5-0.5-0.9c-0.3,0.2-0.3,0.4-0.3,0.7
				c-0.2,0.2-0.4,0.3-0.6,0.5c0,0.2,0.1,0.4,0.3,0.5C310.7,97.6,310.9,97.6,310.9,97.2z"/>
			<path fill="#060606" d="M318.1,99.1c0.2,0,0.4,0.7,0.6,0c0.1-0.2,0.2-0.7,0.6-0.7c0.1-1-0.3-0.9-0.9-0.5
				C318.8,98.4,318.1,98.7,318.1,99.1z"/>
			<path fill="#605851" d="M316.7,99.1c0,0.3,0,0.6,0,1c0.4-0.1,0.8-0.2,0.4-0.7C317,99.3,316.8,99.2,316.7,99.1z"/>
			<path fill="#3A3734" d="M317.4,96c0,0.2,0.1,0.4,0.3,0.5c0.3,0,0.5,0,0.7-0.3C318,96.3,317.7,96.2,317.4,96z"/>
			<path fill="#21201F" d="M372.8,17.3c0-0.5-0.4-0.6-0.8-0.7c0,0,0,0.1,0,0.1C372.2,17.1,371.9,17.9,372.8,17.3z"/>
			<path fill="#3A3834" d="M369.9,18.7c0.2,0,0.3,0,0.5,0c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2,0-0.3,0-0.5,0c0,0.1,0,0.2,0,0.2
				C369.6,18.7,369.7,18.8,369.9,18.7z"/>
			<path fill="#554E48" d="M372.1,16.3c0.4-0.1,0.9,0.2,1-0.5c-0.3,0.3-0.8,0.2-1.2,0.3c-0.2,0-0.1,0.2,0,0.1
				C371.9,16.2,372,16.3,372.1,16.3z"/>
			<path fill="#1E1E1C" d="M369.9,18.7c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0.1-0.3,0.2-0.5,0.3c0,0.3,0,0.6,0,0.9c0.1,0,0.1,0,0.2,0
				C369.5,19.4,370.2,19.4,369.9,18.7z"/>
			<path fill="#0A0A0A" d="M310.5,97.5c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.1-0.1-0.1-0.2,0c-0.3,0.2-0.3,0.4-0.3,0.7
				c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.1,0.4,0,0.5-0.2C310.5,97.6,310.5,97.6,310.5,97.5z"/>
			<path fill="#242322" d="M310.2,95c-0.1,0.1-0.2,0.1-0.3,0.2c0.3,0.1,0.7,0.2,1,0.3C310.8,95.2,310.6,95,310.2,95z"/>
			<path fill="#48443F" d="M310,96.9c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.2,0.5-0.5C310.3,96.5,310.1,96.6,310,96.9z"/>
			<path fill="#242322" d="M310.4,97.7c-0.2,0.1-0.3,0.2-0.5,0.2c0,0.1,0,0.3,0,0.4c0.1,0.3,0.4,0.4,0.5,0.7
				c0.1,0.2,0.2,0.3,0.4,0.3c0.1,0,0.2,0,0.3,0c0.2-0.2,0.1-0.5,0.2-0.7c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.2-0.2-0.2-0.2
				C310.9,98.1,310.9,97.5,310.4,97.7z"/>
			<path fill="#4F4A44" d="M310.4,99.1c0.3-0.6-0.1-0.6-0.5-0.7c-0.4,0.1-0.5,0.5-0.8,0.8C309.6,99.8,310,98.7,310.4,99.1z"/>
			<path fill="#090909" d="M308.9,98.6c-0.2-0.1-0.4-0.3-0.8-0.2c0.2,0.2,0.4,0.4,0.6,0.6C308.8,99,308.9,98.8,308.9,98.6z"/>
			<path fill="#4D4842" d="M376.2,21.6c0,0.1,0,0.2,0,0.3c0.3,0.1,0.6,0.5,0.9,0.2c0.2-0.3-0.1-0.4-0.4-0.5
				C376.5,21.6,376.4,21.6,376.2,21.6z"/>
			<path fill="#0C0C0C" d="M325.3,101.5c-0.1,0.1-0.2,0.1-0.2,0.2c-0.7,0.5,0,0.7,0.2,1c0,0.5,0.4,0.4,0.7,0.3
				c0.1-0.1,0.2-0.2,0.2-0.3C325.6,102.6,325.7,101.8,325.3,101.5z"/>
			<path fill="#4F4A44" d="M326,103c-0.2-0.1-0.6-0.1-0.7-0.3c-0.3,0-0.5,0.2-0.5,0.5c0.1,0.2,0.3,0.3,0.5,0.5
				C325.6,103.5,325.8,103.3,326,103C326,103.1,326,103,326,103z"/>
			<path fill="#060606" d="M325.3,103.7c-0.1-0.2-0.3-0.4-0.5-0.5c-0.1,0-0.2,0.1-0.3,0.1C324.7,103.6,325,103.6,325.3,103.7z"/>
			<path fill="#080807" d="M319.1,104.4c-0.2,0.1-0.5,0.2-0.7,0.2c-0.1,0.2-0.2,0.3-0.3,0.5c0,0,0,0,0,0c0.2,0.3,0.3,0.7,0.7,0.8
				c0.4-0.1,0.7-0.3,0.9-0.6c0-0.1,0-0.2,0-0.3c-0.1-0.2-0.3-0.3-0.5-0.2C319.1,104.8,319.1,104.6,319.1,104.4z"/>
			<path fill="#534D47" d="M319.3,104.9c0.2,0.1,0.3,0.1,0.5,0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.3-0.1-0.3-0.2-0.3
				C319.5,104.4,319.4,104.7,319.3,104.9z"/>
			<path fill="#242322" d="M311.4,100.8c0.4-0.7,0-0.7-0.5-0.7C310.8,100.5,311.4,100.4,311.4,100.8z"/>
			<path fill="#080808" d="M311.4,98.6c-0.4,0.1-0.4,0.4-0.2,0.7c0.2,0,0.3,0,0.5,0C311.5,99.1,311.5,98.9,311.4,98.6z"/>
			<path fill="#5A534C" d="M316.5,102.3c0.1,0,0.1,0,0.2,0c0.1-0.3,0-0.6-0.2-0.8c-0.3-0.1-0.7-0.1-1-0.4c-0.3-0.3-0.6-0.3-0.8,0.1
				c0,0.3,0.2,0.4,0.3,0.6c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.3,0.1,0.9,0.7,0.7l0,0C316.3,102.8,316.3,102.5,316.5,102.3z"/>
			<path fill="#514B45" d="M313.5,99.6c0,0.2-0.1,0.4-0.3,0.5c0,0.8,0.5,1.1,1.2,1.2c0,0,0.1-0.1,0.1-0.1c0.3-0.5-0.2-0.9-0.1-1.3
				C314.4,98.9,313.8,99.7,313.5,99.6z"/>
			<path fill="#4D4843" d="M314.8,102.7c0.1,0.5-0.5,1,0,1.5c0.5-0.1,0.1-0.7,0.5-0.8C315.5,102.9,315.3,102.7,314.8,102.7z"/>
			<path fill="#33312E" d="M314.8,102.7c0.3,0.1,0.5,0.4,0.5,0.7c0.3,0,0.6-0.2,0.7-0.5c-0.4-0.1-0.4-0.5-0.7-0.7
				C315.1,102.4,314.9,102.6,314.8,102.7z"/>
			<path fill="#33312E" d="M316.5,102.3c-0.3,0.1-0.4,0.4-0.5,0.7c0.4,0,0.7,0,0.7,0.5c0.3-0.3,0.2-0.6,0.2-1
				C316.8,102.4,316.6,102.3,316.5,102.3z"/>
			<path fill="#3E3B37" d="M315,101.8c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.1-0.2,0c0,0,0,0,0,0c-0.2,0.1-0.4,0.3-0.6,0.4
				C314.3,102,314.6,101.6,315,101.8z"/>
			<path fill="#1D1D1B" d="M318.1,106.6c-0.2-0.1-0.3-0.2-0.5-0.2c0,0.2-0.1,0.5,0.2,0.6C318.1,107,318.1,106.7,318.1,106.6z"/>
			<path fill="#413D39" d="M319.8,105.3c-0.4-0.1-0.7,0.2-1,0.5c-0.1,0.2-0.1,0.3,0,0.5c0,0,0,0,0,0c0.6,0.2,0.9-0.6,1.5-0.5
				C320.1,105.6,320,105.5,319.8,105.3z"/>
			<path fill="#232321" d="M318.8,106.3c0-0.2,0-0.3,0-0.5c-0.1-0.3-0.4-0.6-0.7-0.7C318.3,105.5,318,106.3,318.8,106.3z"/>
			<path fill="#10100F" d="M316.4,101.5c0.1,0.2,0.2,0.5,0.2,0.7c0.2-0.1,0.2-0.3,0.3-0.5c0-0.1,0-0.3-0.1-0.4c-0.2,0-0.3,0-0.5,0v0
				C316.3,101.3,316.3,101.4,316.4,101.5C316.4,101.5,316.4,101.5,316.4,101.5z"/>
			<path fill="#050505" d="M316.4,101.5c0-0.1,0-0.1,0-0.2c-0.8-0.3-1.3-0.9-1.9-1.5c-0.7,0.5,0.3,1,0,1.5c0.1,0,0.2,0,0.2,0
				C315.4,100.9,315.8,101.9,316.4,101.5z"/>
			<path fill="#121211" d="M315.8,99.6c0-0.2,0.3-0.5-0.1-0.6c-0.3-0.1-0.7-0.2-0.7,0.3C315.2,99.6,315.5,99.6,315.8,99.6z"/>
			<path fill="#524C47" d="M310.4,97.7c0.4,0,0,1,0.7,0.5c0.1-0.4,0.6-0.8-0.3-0.9c-0.2,0.1-0.3,0.2-0.5,0.2
				C310.4,97.5,310.4,97.6,310.4,97.7z"/>
		</g>
	</g>
</g>
</svg>
        )
    }
}

export default Character1Airplane;