import React from "react";
import GlobalHeader from "./GlobalHeader";
import ScreenFallback from "./ScreenFallback";
import Header from "./Header";
import BodyCopy from "./BodyCopy";
import ResultOptions from "./ResultOptions";
import { Link } from "react-router-dom";

class Contact extends React.Component {
  componentDidMount(){
    this.props.updateClickstream("Connect with us");
  }
  render() {
    const currentScreen = this.props.currentScreen;
    const setOffsiteProgress = this.props.setOffsiteProgress;
    const updateClickstream = this.props.updateClickstream;
    const updateLastClick = this.props.updateLastClick;
    return (
      <div className="screen">
        <div className="screen_content">
          <GlobalHeader
            updateClickstream={updateClickstream}
          />
          <div className="content_wrapper">
            <Header currentScreen={currentScreen} />
            <BodyCopy currentScreen={currentScreen} />
          </div>
          <ResultOptions
            currentScreen={currentScreen}
            setOffsiteProgress={setOffsiteProgress}
            updateClickstream={updateClickstream}
            updateLastClick={updateLastClick}
          />
          <div className="advance_button_wrapper">
            <Link
              to="/legal"
              onClick={() => updateLastClick('Disclosure')}
            >
              Disclosures
            </Link>
          </div>
        </div>
        <ScreenFallback
          updateClickstream={updateClickstream}
        />
      </div>
    );
  }
}

export default Contact;
