import React from "react"

class Character2Mail extends React.Component {
    render(){
        return(
<svg version="1.1" id="character-2" className="character-animated" x="0px" y="0px" viewBox="0 0 217.5 606.1" enable-background="new 0 0 217.5 606.1">
<g id="arm">
	<g id="YVtFde_1_">
		<g>
			<path fill="#BFBFC2" d="M184,201.1c0.2-0.4,0.5-0.8,0.7-1.2c0.2-0.5,0-0.7-0.5-0.7c-0.2,0-0.4,0.2-0.4-0.2c0-0.3,0.1-0.6,0.3-0.9
				c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.3-0.6,0.5-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0.7-1.2,0.7-1.2-0.7-1.5c0.5-0.4,1.1-0.3,1.4-0.7
				c0.1-0.3,0.1-0.6,0-0.9c-0.1-0.3-0.3-0.5-0.6-0.7s-0.7-0.3-1.1-0.3c-0.4,0-0.2-0.3-0.3-0.5c0-0.2-0.1-0.3-0.3-0.4
				c-0.4-0.2-0.6-0.6-0.7-1c-0.8-0.7-1.4-1.6-2.4-2.1c-0.3,0-0.3-0.1-0.3-0.3c-0.4-0.8-1-1.4-1.7-1.8c-0.5,0.2-0.5-0.2-0.6-0.5
				c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0.1-0.7,0.2-0.9,0.5c-0.3,0.3-0.6,0.6-0.3,1.1c0.9,0,1.3,0.8,2,1.2c0.6-0.2,1-0.2,0.9,0.6
				c-0.1,0,0.1,0.4-0.2,0.2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.4-0.2-0.7-0.1-1.1,0c-0.4,0.1-0.7,0.4-1.1,0.4c-0.1,0-0.2,0.1-0.2,0.2
				c0,0-0.1,0.1-0.1,0c0.1,0,0.1-0.1,0.2-0.1c0.2-0.2,0.3-0.4,0.4-0.6c0.2-0.3,0.1-0.6-0.3-0.7c-0.3-0.1-0.2-0.4-0.3-0.6
				c0-0.1-0.1-0.2-0.2-0.3c-0.4-0.1-0.7,0.2-1,0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.3-0.5-0.7-1-0.5c-0.2,0-0.2-0.1-0.1-0.3
				c0.2-0.2,0.4-0.4,0.4-0.7c0-0.1,0-0.2-0.1-0.3c-0.1-0.4-0.4-0.9,0.1-1.3c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3
				s0.1-0.3,0.3-0.3c0.4,0.2,0.7,1.4,1.2,0.1c-0.3-0.9-1-1.5-1.7-2.1c-0.6,0-0.5-0.6-0.7-0.9c-0.1-0.1-0.2-0.2-0.4-0.3
				c-0.5,0-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.5-0.5-0.6s-0.4-0.3-0.4-0.5c-0.1-0.4-0.3-0.7-0.6-0.9c-0.2-0.1-0.4,0-0.5,0.2
				c0,0.1,0,0.2,0,0.3c-0.1,0.2-0.2,0.2-0.4,0.1c-0.2-0.3,0.2-0.2,0.2-0.4c0.1-0.2,0.3-0.4,0.2-0.7c0-0.3-0.1-0.5-0.3-0.8
				c-0.1-0.3-0.3-0.6-0.5-0.8c-0.3-0.6-0.5-1.2-1.1-1.6c-0.2-0.2-0.6,0-0.7-0.4c-0.3-0.2,0-0.4,0.1-0.6c0-0.7-0.4-1.1-0.9-1.5
				c0,0-0.1,0-0.1,0.1c-0.5,0.5-0.6,1.7-1.5,1.4c-0.9-0.2-1.4-1.1-1.6-2.1c-0.2-0.9-1-1.6-1.4-2.4c-2.8-5.2-5.7-10.3-8.5-15.5
				c-0.5-0.1-0.4,0.2-0.4,0.5c-0.3-0.3,0.3-0.6,0.1-0.9c-4.2-7.9-8.7-15.6-14-22.8c-3.4-4.6-6.9-9.2-11-13.3
				c-2.7-2.7-5.5-5.5-8.6-7.8c-1-0.8-2-1.6-3-2.4c-0.5-0.4-1-0.5-1.5-0.2c-1.6,0.7-2.9,1.7-3.5,3.5c-1.1,3.5-2.3,7-3.3,10.5
				c-0.6,2.4-0.6,4.8-0.4,7.2c0.3,3.1,0.5,6.3,1.2,9.3c0.6,2.5,1.2,5,1.9,7.5c1.5,5.8,3.9,11.1,7.5,15.9c2.8,3.7,5.4,7.6,8.3,11.2
				c3.6,4.5,7.2,8.9,10.5,13.5c2.6,3.6,5.2,7.3,8,10.7c3.2,3.8,6.1,7.8,10.1,10.8c4.7,3.4,9.8,4.6,15.5,3.4c3.6-0.8,6.4-2.7,8.9-5.2
				c0.7-0.7,1.6-1.3,2.2-2.2C182.9,201.2,183.6,201.3,184,201.1z"/>
			<path fill="#DDB6A7" d="M173,180.2c0.1,0.1,0.2,0.2,0.3,0.2c0.5,0.2,0.3,0.8,0.7,1c0.6,0.6,1.1,1.3,1.7,1.9
				c0.5,0.8,1,1.4,1.6,2.1c0.1,0.1,0.2,0.3,0.4,0.3c0.1,0,0.3,0,0.4,0c0.2,0.2,0.3,0.4,0.5,0.5c0.5,0.6,1.1,1.3,1.6,1.9
				c0,0.1,0.1,0.2,0.2,0.2c0.8,0.7,1.6,1.4,2.4,2.2c0.3,0.3,0.4,0.7,0.7,0.9c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.1
				c0.2,0,0.3,0.2,0.5,0.3c0.3,0.2,0.5,0.4,0.7,0.5c0.2,0.2,0.3,0.3,0.5,0.5s0.2,0.6,0.5,0.8c0.5,0.3,0.9,0.6,1.5,0.8
				c0.2,0.1,0.5,0,0.6-0.2c3.6-6.5,5.8-13.4,7.7-20.5c2.2-8.2,3.6-16.6,5.3-24.9c1.3-6.7,2.2-13.5,3.3-20.3
				c0.8-5.1,1.6-10.2,2.4-15.3c0.2-1.5,0.3-3.1,0.5-4.6c0.1-0.7,0.3-1.4,0.6-2.1c2.3-4.5,4.5-9,6.1-13.7c1.1-3.1,1.7-6.2,2.1-9.4
				c0.4-4,0.9-7.9,1-11.9c0.1-1.6-0.3-2.8-1.9-3.5c-0.3-0.1-0.3-0.4-0.3-0.7c-0.1-2.2-0.7-4.1-2.6-5.4c-0.5-0.3-0.7-1-1.1-1.6
				c-1.1-1.7-2.4-1.9-3.9-0.6c-0.4,0.3-0.6,0.7-1.2,0.6c-1.7-0.3-2.5,0.8-3.1,2.1c-0.7,1.8-1.1,3.7-1.4,5.5
				c-0.9,5.4-2.1,10.7-4.5,15.7c-0.3,0.6-0.5,1.1-1,1.6c-0.4-0.4-0.2-0.8-0.2-1.2c-0.1-2.8,0.2-5.7-0.2-8.5c-0.2-1.4-0.6-2.6-1-3.9
				c-0.1-0.4-0.3-1-0.9-0.9c-0.5,0.1-0.7,0.6-0.8,1.1c-0.1,1.3-0.4,2.6-0.7,3.9c-0.7,3.7-1.6,7.5-2.3,11.2c-0.6,3.3-1.4,6.6-1.3,9.9
				c0,2.4,0.1,4.8,0.7,7.1c0.8,3.2-0.1,6.2-1.2,9.2c-1.8,5.1-3.2,10.3-4.7,15.5c-0.8,2.8-1.8,5.6-2.7,8.4c-1.4,4.2-2.9,8.4-4.4,12.5
				c-1.4,3.8-3.1,7.4-4.5,11.2c-1.4,3.5-3.4,6.8-4.9,10.2c0,0.7,0.7,1,0.7,1.7c0,0.2,0.2,0.4,0.1,0.6c0,0.3,0.4,0.2,0.6,0.4
				c0.3,0.6,0.7,1.1,1,1.7c0.3,0.2,0.5,0.4,0.5,0.7c0.1,0.2,0.2,0.5,0.2,0.7c0.2,0.2,0.3,0.4,0.5,0.5c0.1,0.3,0.5,0.5,0.5,0.9
				c0.2,0.2,0.2,0.4,0.5,0.5c0.1,0.2,0.3,0.3,0.4,0.5C172.8,179.7,172.7,180,173,180.2z"/>
			<path fill="#C0C0C3" d="M188.3,194.6c-0.2,0-0.4,0-0.7,0c-0.5-0.2-1-0.2-1.5-0.2c-0.2,0-0.3,0-0.5,0.1c0.1,1-0.2,1.9-1,2.5
				c-0.2,0.2-0.4,0.5-0.7,0.6c0,0.1,0,0.2,0,0.2c-0.3,0.5,0.1,0.7,0.4,0.9c0.3,0.2,0.7,0.4,1.1,0c0-0.1,0-0.1,0-0.2
				c0.3-0.3,0.4-0.7,0.7-1c0.2-0.2,0.3-0.5,0.5-0.7c0.1-0.3,0.3-0.5,0.5-0.7C187.6,195.7,188,195.2,188.3,194.6z"/>
			<path fill="#F1F0F0" d="M185.5,198.9c-0.4,0-0.7-0.1-1-0.3c-0.5-0.2-0.8,0.1-0.8,0.5c0,0.3,0.2,0.4,0.5,0.2
				c0.5-0.2,0.4,0.2,0.5,0.5C185.2,199.7,185.5,199.4,185.5,198.9z"/>
			<path fill="#E9E8E8" d="M184,201.1c-0.1,0-0.2,0-0.2,0c-0.4,0.1-0.7-0.8-1-0.3c-0.2,0.3,0.2,0.8,0.3,1.3
				C183.4,201.7,183.9,201.5,184,201.1z"/>
			<path fill="#CBCACC" d="M154.1,152.4c-0.4,0.4-0.3,0.9-0.2,1.5c0.3-0.4,0-0.8,0.4-1.1C154.2,152.7,154.1,152.5,154.1,152.4z"/>
			<path fill="#C9C8CB" d="M187.2,196.3c-0.3,0.1-1,0-0.5,0.7C187,196.9,187.2,196.6,187.2,196.3z"/>
			<path fill="#CCCBCD" d="M186.2,197.7c-0.5,0.2-0.6,0.6-0.7,1C186,198.6,186.1,198.1,186.2,197.7z"/>
			<path fill="#C4C4C6" d="M177.6,185.2c-0.7-0.6-1.1-1.5-1.9-1.9c-0.1,0.3-0.2,0.6-0.4,0.2c-0.2-0.3-0.3-0.6-0.8-0.6
				c-0.2,0.2,0.5,0.7-0.2,0.7c0,0.1,0,0.2,0,0.3v0.1v-0.1c-1.3,0.4-0.5,1.1-0.3,1.7c0,0.4-0.3,0.5-0.5,0.7c0.1,0.2,0.3,0.3,0.6,0.3
				c0.7,0,0.6,0.7,0.9,1l0,0c0.5,0.2,0.7-0.7,1.2-0.2l0,0c0.1,0.1,0.2,0.1,0.2,0c-0.1-0.5,0.2-0.7,0.5-1c0.1-0.2-0.6,0-0.2-0.3
				C177,185.7,177.3,185.5,177.6,185.2z"/>
			<path fill="#CACACC" d="M176.4,187.1c-0.1,0-0.2,0-0.2,0c-0.2,0.5-0.6,1.1,0.5,1c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.7
				c0.5,0.5,0.6-0.2,1-0.2c0.2-0.4,0.8-0.4,0.7-1C178,187.2,177.4,186.6,176.4,187.1z"/>
			<path fill="#C6C6C8" d="M184.8,197.2c1-0.6,1.4-1.5,0.9-2.6c0.1-0.2,0.1-0.3,0-0.5c-0.4,0.1-0.8,0.2-1.3,0.2
				c-0.2,0-0.5,0-0.5,0.3s0.1,0.6,0.5,0.6c0.3-0.1,0.5,0.2,0.8,0.2c0.1,0,0.3-0.3,0.3,0c0,0.1-0.1,0.3-0.1,0.3
				c-0.8,0.1-0.6,0.8-0.8,1.3C184.6,197.1,184.7,197.2,184.8,197.2z"/>
			<path fill="#E5E4E5" d="M178.3,188.1c-0.2,0.3-0.8,0.4-0.7,1c0.4-0.1,0.8-0.2,1.2-0.2c0.2,0.2,0.4,0.5,0.7,0.2
				c0-0.4,0.2-1-0.2-1.3C178.9,187.4,178.6,188,178.3,188.1z"/>
			<path fill="#C8C8CA" d="M180.7,191.2c-0.2,0.3-0.5,0.3-0.7,0.3c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.1
				c0.3,0.1,0.6,0.3,0.8,0.5c0.5-0.1,0.7,0.3,1,0.6c0.1,0.1,0.2,0.2,0.3,0.2c0.1-0.1,0.4,0,0.5-0.2c0.1-0.1,0.2-0.2,0.2-0.2
				c-0.1-0.5-0.8-0.6-0.7-1.3c-0.1-0.6-0.5-0.5-0.9-0.4C180.9,191.1,180.8,191.1,180.7,191.2z"/>
			<path fill="#EAE9EA" d="M176.9,191c0.6,0.5,0.9-0.5,1.5-0.2c0.2,0.4-0.2,0.3-0.3,0.4c-0.3,0.3-0.1,0.6,0.1,0.7
				c0.3,0.2,0.3-0.2,0.4-0.3c0.1-0.4,0.4-0.9,0-1.2C177.9,190.2,177.4,190.7,176.9,191z"/>
			<path fill="#E2E0E1" d="M164.4,174.1c0.3-0.3,1-0.3,0.6-0.9c-0.1-0.2-0.2-0.7-0.6-0.5C163.7,173.1,164.5,173.6,164.4,174.1z"/>
			<path fill="#F8F8F9" d="M177.6,185.2c-0.5,0.4-1.4,0-1.6,1c0.3,0,0.6-0.1,0.9-0.1c0.3-0.2,0.5-0.3,0.8-0.5
				C177.6,185.6,177.6,185.4,177.6,185.2z"/>
			<path fill="#EAE8E9" d="M171.7,188c0.1,0.3,0.3,0.3,0.4,0.2c0.3-0.1,0.7-0.3,0.7-0.7c0-0.3-0.3-0.4-0.5-0.4
				C171.9,187.3,171.8,187.7,171.7,188z"/>
			<path fill="#DFDEDF" d="M176.5,200.2c0,0.2,0.2,0.3,0.3,0.3c0.5-0.1,0.8-0.4,0.7-0.9c0-0.2-0.1-0.4-0.4-0.3
				C176.7,199.4,176.8,199.9,176.5,200.2z"/>
			<path fill="#C8C7CA" d="M163.1,175.5c-0.3-0.7-1-0.6-1.5-0.7c-0.1,0-0.2,0.2-0.3,0.3l0.2,0.2
				C162.1,175.1,162.6,175.7,163.1,175.5z"/>
			<path fill="#C7C7C9" d="M170.1,186.4c0-1.2-0.8-0.5-1.3-0.5c0.3,0.3,0.6,0.5,0.8,0.8C169.9,186.8,170.2,186.9,170.1,186.4z"/>
			<path fill="#DFDEE0" d="M178.9,195.1c0.8,0.2,1.1-0.1,1-1.1C179.4,194.5,179.3,194.9,178.9,195.1z"/>
			<path fill="#D5D5D6" d="M166.6,176.8c0.2,0.3,0.4,0.7,0.8,0.7c0.2,0,0.3-0.1,0.2-0.3c-0.2-0.3-0.4-0.7-0.8-0.8
				C166.6,176.4,166.6,176.6,166.6,176.8z"/>
			<path fill="#C7C6C9" d="M165.3,176.5c0.2-0.9-0.9-1.2-0.4-1.9C164.3,175.3,164.6,175.9,165.3,176.5z"/>
			<path fill="#CECED0" d="M172.9,196.9c-0.3,0.3-0.6,0.7-0.4,1.1c0,0.1,0.3,0.1,0.4,0C173.5,197.7,172.9,197.3,172.9,196.9z"/>
			<path fill="#CCCBCD" d="M184.3,199.4c-0.2,0-0.4,0-0.4-0.3c0.1-0.3,0.4-0.4,0.6-0.4c-0.2-0.2-0.5-0.3-0.5-0.7
				c-0.6,0.3-0.5,0.9-0.5,1.3C183.6,199.9,184,199.7,184.3,199.4z"/>
			<path fill="#E6E4E5" d="M174.9,187.4c0.1-0.6-0.1-1-0.7-1.2c-0.2,0.2-0.8,0.2-0.5,0.7c0.2,0,0.6-0.5,0.6,0.1
				C174.4,187.6,174.7,187.3,174.9,187.4z"/>
			<path fill="#E6E5E6" d="M180.9,191.2c0.2,0.1,0.7-0.4,0.7,0.2c0.2,0.1,0.3,0.1,0.5,0c0.1-0.8-0.1-1.2-1-0.7
				C181,190.9,180.8,191,180.9,191.2z"/>
			<path fill="#C6C6C8" d="M174.4,198.1c-0.7-0.2-0.9,0.1-1.1,0.5c0,0,0.1,0.3,0.2,0.3C174,198.9,174.1,198.5,174.4,198.1z"/>
			<path fill="#D1D0D2" d="M179.7,199.9c0.6-0.1,0.2-0.8,0.8-1.1C179,198.6,179.6,199.4,179.7,199.9L179.7,199.9z"/>
			<path fill="#F4F3F4" d="M182.4,192.9c-0.2,0-0.4,0-0.5,0.2c0.3,0.4,0.6,0.8,1.2,0.5c0.1-0.2,0.1-0.3,0-0.5
				C182.9,193.1,182.6,193,182.4,192.9z"/>
			<path fill="#EAE8E9" d="M176.4,189.3c-0.3-0.1-0.6-0.5-0.9,0c-0.1,0.2,0,0.5,0.2,0.5C176.1,190,176.3,189.8,176.4,189.3
				L176.4,189.3z"/>
			<path fill="#D6D5D7" d="M179.5,189.1c-0.2-0.1-0.5-0.1-0.7-0.2c0.1,0.4-0.5,0.9,0.2,1.1C179.4,190,179.2,189.3,179.5,189.1z"/>
			<path fill="#EAE9EA" d="M178.6,186.2c-0.1-0.3-0.2-0.5-0.5-0.5c0,0.3-0.6,0.5-0.3,0.8C178.1,186.9,178.4,186.5,178.6,186.2z"/>
			<path fill="#E6DFDD" d="M185.2,192.7c-0.1-0.3-0.4-0.5-0.7-0.7c-0.2,0-0.4-0.1-0.5-0.2c-0.1,0-0.1,0-0.2,0c0,0.4-0.3,0.8,0.4,0.7
				C184.6,192.4,184.9,192.6,185.2,192.7z"/>
			<path fill="#DFDEDF" d="M176.1,187.2c-0.3-0.5-0.3-0.5-1.2,0.2C175.4,187.9,175.7,187.2,176.1,187.2z"/>
			<path fill="#DCD7D7" d="M185.7,194.1c0,0.2,0,0.3,0,0.5c0.2,0,0.3,0,0.5,0c0.2-0.2,0.2-0.5,0-0.7c0-0.3-0.2-0.6-0.5-0.7
				C185.7,193.5,185.7,193.8,185.7,194.1z"/>
			<path fill="#D5D4D5" d="M164.9,187.3c0.9,0.2,0.9-0.3,0.9-1C165.4,186.7,165.2,187,164.9,187.3z"/>
			<path fill="#EBE7E7" d="M174,181.4c-0.3-0.3,0.1-1.1-0.7-1C173.7,180.6,172.8,181.8,174,181.4z"/>
			<path fill="#DBD6D6" d="M173,180.2c0-0.4,0-0.7-0.5-0.7c0.1,0.3-0.4,0.7-0.1,1C172.8,180.7,172.8,180.2,173,180.2z"/>
			<path fill="#D9D8D9" d="M182.1,191.5c-0.2,0-0.3,0-0.5,0c0.2,0.5,0.4,1,1,1.2C183,192,181.9,192,182.1,191.5z"/>
			<path fill="#C5C5C7" d="M147.4,152.5c0,0.2,0.2,0.4,0.4,0.3c0.2,0,0.4-0.1,0.4-0.4c0-0.1-0.2-0.2-0.3-0.3
				C147.6,152.1,147.4,152.2,147.4,152.5z"/>
			<path fill="#DCDADC" d="M183.1,193.1c0,0.2,0,0.3,0,0.5c0.4-0.1,0.8,0.2,1.1-0.2C183.9,193,183.5,193.2,183.1,193.1z"/>
			<path fill="#F4F3F4" d="M173.7,186.9c-0.1-0.4,0.3-0.5,0.5-0.7c-0.2,0-0.5,0-0.7,0c-0.3,0.2-0.3,0.5-0.1,0.8
				C173.5,187.1,173.6,187.1,173.7,186.9z"/>
			<path fill="#E7E6E7" d="M178,193.8c0.5-0.5,0.4-0.8-0.2-1.1C177.7,193.1,177.8,193.4,178,193.8z"/>
			<path fill="#CCC9CA" d="M168.5,173.5c0.3-0.3-0.1-0.4-0.2-0.5c-0.2-0.1-0.5-0.3-0.6,0.1c-0.1,0.4,0.3,0.4,0.6,0.4
				C168.3,173.6,168.4,173.6,168.5,173.5z"/>
			<path fill="#C6C6C8" d="M163.2,188.6c0.5-0.4,0.8-0.6,0.5-1.1C163.3,187.7,163.3,188,163.2,188.6z"/>
			<path fill="#E0DFE0" d="M181.7,192.9c-0.1-0.5-0.2-1-0.9-0.7c-0.1,0-0.2,0.1-0.2,0.2C180.8,192.8,181.4,192.4,181.7,192.9z"/>
			<path fill="#C9C8CB" d="M167.5,182.1c0.4-0.3,0.6-0.7,0.2-1.2C167.6,181.4,167.6,181.7,167.5,182.1z"/>
			<path fill="#EAE9EA" d="M181.1,195.9c-0.1-0.5-0.4-0.6-0.8-0.6c0,0-0.1,0.2-0.1,0.3C180.4,195.9,180.7,195.8,181.1,195.9z"/>
			<path fill="#CCCCCE" d="M171.5,184.5c0.1-0.1,0.3-0.3,0.3-0.4c0-0.2-0.2-0.3-0.4-0.3s-0.3,0.1-0.2,0.3
				C171.2,184.3,171.2,184.6,171.5,184.5z"/>
			<path fill="#EBEAEA" d="M181.8,194.3c-0.4,0.1-0.7,0.2-0.7,0.7c0,0.1,0.2,0.2,0.3,0.1C181.9,195,181.6,194.6,181.8,194.3z"/>
			<path fill="#E9E8E9" d="M164.7,179.4c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.2,0.2-0.3c0-0.2-0.2-0.3-0.3-0.3
				C164.8,179,164.7,179.2,164.7,179.4z"/>
			<path fill="#E3E2E3" d="M180.9,191.2c0.1-0.2,0.2-0.3,0.2-0.5c-0.3-0.1-0.3-0.7-0.7-0.3c-0.3,0.3,0.4,0.4,0.2,0.8h0.1H180.9z"/>
			<path fill="#E2E1E1" d="M168.3,189.8c0.1,0.2,0.3,0.2,0.5,0.2s0.3-0.2,0.2-0.3s-0.3-0.1-0.4-0.1
				C168.5,189.6,168.3,189.6,168.3,189.8z"/>
			<path fill="#F5F4F4" d="M182.2,197.1c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.1,0.2-0.3c-0.1-0.1-0.2-0.3-0.3-0.3
				C182.2,196.8,182.2,197,182.2,197.1z"/>
			<path fill="#DBDADC" d="M174.9,190.8c-0.4,0-0.8,0.1-1.3,0.6C174.3,191.2,174.7,191.3,174.9,190.8L174.9,190.8z"/>
			<path fill="#D1D0D2" d="M167.7,180.1c-0.2-0.5-0.5-0.6-0.9-0.7C166.9,179.9,167.2,180,167.7,180.1z"/>
			<path fill="#CBCACC" d="M168.5,173.5c-0.1,0-0.2,0-0.2,0c-0.1,0.2-0.5,0.3-0.3,0.5c0.3,0.3,0.7-0.2,1,0
				C168.8,173.8,168.6,173.6,168.5,173.5z"/>
			<path fill="#DDDCDD" d="M180,191.7c0-0.1,0-0.2,0-0.2c-0.5,0-0.6,0.5-0.8,0.8c0.4,0.1,0.5-0.3,0.8-0.3
				C180.1,191.9,180.1,191.8,180,191.7L180,191.7z"/>
			<path fill="#C8C7C9" d="M178.9,202.4c0.7,0.1,0.8-0.1,1.1-0.4C179.5,201.8,179.3,202.1,178.9,202.4z"/>
			<path fill="#CBCACD" d="M172.9,193c0,0.3,0,0.4,0.2,0.4s0.3-0.1,0.3-0.3s0-0.3-0.2-0.3C173.1,192.8,173,192.9,172.9,193z"/>
			<path fill="#ECEBEB" d="M174.2,183.5c0.2,0,0.5,0,0.5-0.1c0.2-0.3-0.2-0.4-0.3-0.6C173.8,182.9,174.1,183.2,174.2,183.5z"/>
			<path fill="#C9C8CA" d="M178.6,197.6c0,0.2,0.2,0.3,0.4,0.3c0.1,0,0.3-0.1,0.3-0.2c0-0.3-0.2-0.2-0.4-0.3
				C178.7,197.4,178.6,197.4,178.6,197.6z"/>
			<path fill="#C6C5C8" d="M173.5,200.6c0,0.2,0,0.3,0.2,0.3s0.2-0.2,0.2-0.3c0-0.2,0-0.3-0.2-0.3S173.5,200.4,173.5,200.6z"/>
			<path fill="#DBDADB" d="M179.7,200.8c0,0.2,0.1,0.5,0.4,0.4c0.1,0,0.2-0.2,0.2-0.2c-0.1-0.3-0.4-0.3-0.6-0.5
				C179.7,200.6,179.7,200.7,179.7,200.8L179.7,200.8z"/>
			<path fill="#E5E4E4" d="M168,178.5c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.2-0.2c0-0.1-0.1-0.3-0.3-0.3
				C168.1,178.1,168,178.2,168,178.5z"/>
			<path fill="#D8D8D9" d="M171,181.4c-0.1,0.1-0.3,0.2-0.2,0.2c0,0.1,0.1,0.3,0.2,0.3c0.1,0.1,0.3,0,0.3-0.2
				C171.2,181.7,171.1,181.6,171,181.4z"/>
			<path fill="#D2D2D4" d="M172.1,186.3c0,0.1,0,0.3,0.2,0.2c0.2,0,0.4-0.1,0.4-0.4c0-0.1-0.1-0.2-0.2-0.2
				C172.2,185.9,172.2,186.1,172.1,186.3z"/>
			<path fill="#E0D3CF" d="M183.6,191.5c0-0.5-0.3-0.7-0.7-0.9C183,190.9,183,191.4,183.6,191.5z"/>
			<path fill="#CECDCF" d="M180.7,193.4c-0.1-0.2-0.2-0.3-0.3-0.3c-0.2-0.1-0.3,0-0.3,0.2c0,0.1,0.2,0.2,0.3,0.3
				C180.4,193.6,180.5,193.5,180.7,193.4z"/>
			<path fill="#DFDADA" d="M171.1,177.6c0-0.3-0.2-0.5-0.5-0.5c-0.1,0.2-0.2,0.5-0.2,0.7c0.1,0.2,0.2,0.4,0.5,0.2
				C170.9,177.8,170.9,177.7,171.1,177.6z"/>
			<path fill="#DCDBDC" d="M170,179.9c0.1,0.2,0.2,0.3,0.4,0.3c0.1,0,0.4,0,0.2-0.3c-0.1-0.1-0.3-0.2-0.4-0.2
				C170.2,179.7,170.1,179.9,170,179.9z"/>
			<path fill="#E0DFE0" d="M168.7,179.7c-0.3,0-0.3,0.2-0.2,0.3c0,0.1,0,0.3,0.2,0.3s0.2-0.2,0.2-0.3S168.8,179.8,168.7,179.7z"/>
			<path fill="#ECEAEB" d="M179.8,189.6c0,0.2,0.2,0.2,0.3,0.1c0.2,0,0.3-0.2,0.2-0.3c0-0.2-0.2-0.2-0.3-0.1
				C180,189.3,179.8,189.4,179.8,189.6z"/>
			<path fill="#E5CEC5" d="M170.4,176.4c0.4-0.6-0.1-0.6-0.5-0.7C170.1,175.9,170.2,176.1,170.4,176.4z"/>
			<path fill="#EEECEC" d="M170.9,178c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0.1-0.5,0.1-0.3,0.5C170.4,178.4,170.8,178.6,170.9,178
				L170.9,178z"/>
			<path fill="#CECDD0" d="M168.7,196.6c0.1,0.1,0.1,0.3,0.2,0.3c0.2,0.1,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2
				C168.9,196.4,168.8,196.5,168.7,196.6z"/>
			<path fill="#CCCBCD" d="M177.2,196.5c0.1,0.4,0.3,0.6,0.7,0.6C177.8,196.8,177.7,196.6,177.2,196.5z"/>
			<path fill="#C8C8CA" d="M170.5,196.5c-0.2,0-0.3,0.2-0.2,0.3c0,0.1,0.2,0.1,0.2,0.2c0.1-0.1,0.2-0.3,0.2-0.4
				C170.7,196.6,170.5,196.6,170.5,196.5z"/>
			<path fill="#DBD9DB" d="M184.8,197.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0.2-0.3,0.5-0.5,0.7C184.4,197.7,184.6,197.5,184.8,197.2z"
				/>
			<path fill="#CFCED0" d="M181.1,197.4c-0.2,0-0.3,0.1-0.3,0.3c0,0.1,0.2,0.2,0.2,0.2c0.1-0.1,0.2-0.1,0.3-0.3
				C181.3,197.6,181.2,197.4,181.1,197.4z"/>
			<path fill="#EBE9E9" d="M162.4,171.8c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.2,0.1,0.3,0.3,0.2c0.1,0,0.1-0.2,0.1-0.3
				C162.6,172,162.5,172,162.4,171.8z"/>
			<path fill="#DADADB" d="M172,199.6c0,0.2,0.2,0.3,0.3,0.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2-0.1-0.2-0.2-0.2
				C172.2,199.4,172,199.4,172,199.6z"/>
			<path fill="#EEECEB" d="M179.7,200.8c0-0.1,0-0.2,0-0.3c0-0.2,0-0.5,0-0.7l0,0C179.3,200.2,179.2,200.5,179.7,200.8z"/>
			<path fill="#D3D2D4" d="M176.4,201.6c0.1,0.1,0.1,0.3,0.2,0.3c0.2,0.1,0.3-0.1,0.2-0.3c0-0.1-0.1-0.2-0.2-0.2
				C176.5,201.4,176.5,201.6,176.4,201.6z"/>
			<path fill="#D2D1D3" d="M171.2,204.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0,0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
				C171.3,204,171.2,204.1,171.2,204.3z"/>
			<path fill="#D4D3D5" d="M170.1,186.4c-0.1,0.2-0.3,0.2-0.5,0.2c0.2,0.3,0.6,0.4,0.8,0.1C170.6,186.5,170.3,186.5,170.1,186.4z"/>
			<path fill="#C9C9CB" d="M167.1,181.3c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.2,0.1-0.1,0.2
				C166.8,181.1,166.8,181.3,167.1,181.3z"/>
			<path fill="#C9C9CB" d="M170.6,185.2c0.1,0.1,0.2,0.3,0.4,0.3c0,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.3-0.3-0.3
				C170.8,185.1,170.8,185.2,170.6,185.2z"/>
			<path fill="#D7D4D5" d="M180.5,188.4c-0.1-0.1-0.2-0.2-0.2-0.2c-0.2,0.2-0.4,0.4,0,0.6C180.4,188.8,180.4,188.5,180.5,188.4z"/>
			<path fill="#D5D4D6" d="M176.6,189.3c0.3-0.2,0.3-0.5,0-0.7c-0.3,0.2-0.2,0.5-0.2,0.7l0,0C176.5,189.3,176.5,189.3,176.6,189.3z"
				/>
			<path fill="#D6D5D6" d="M166.4,190.7c-0.1-0.3-0.3-0.6-0.5-0.5c-0.4,0.2,0.1,0.3,0.2,0.4C166.1,190.7,166.3,190.7,166.4,190.7z"
				/>
			<path fill="#E5DFDE" d="M172.1,179c0-0.3-0.2-0.5-0.5-0.5C171.6,178.8,171.4,179.4,172.1,179z"/>
			<path fill="#CECED0" d="M166.8,184.1c0.2-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.3-0.2-0.2s-0.2,0.2-0.2,0.4
				C166.7,184,166.8,184,166.8,184.1z"/>
			<path fill="#DEDDDE" d="M170.9,178c-0.2,0.3-0.5,0.2-0.7,0.3c0.3,0.3,0.6,0.4,0.9,0.2C171.2,178.4,171.1,178.1,170.9,178z"/>
			<path fill="#CECDCF" d="M171.3,183c0,0.2,0.1,0.3,0.2,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.3-0.2-0.2
				C171.5,182.8,171.4,183,171.3,183z"/>
			<path fill="#F4F3F4" d="M180,191.7c0,0.1,0,0.2,0,0.2c0.2,0.2,0.3,0.3,0.5,0.5c0.1-0.1,0.2-0.2,0.2-0.2
				C180.7,191.7,180.3,191.7,180,191.7z"/>
			<path fill="#E3E2E3" d="M172.6,192.4c0.1-0.1,0.3-0.1,0.3-0.2c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.2
				C172.4,192.3,172.5,192.3,172.6,192.4z"/>
			<path fill="#D0CFD1" d="M158.6,178.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2-0.1,0.2-0.2c0-0.2-0.1-0.3-0.3-0.2
				C158.7,177.9,158.7,178.1,158.6,178.2z"/>
			<path fill="#DFDDDE" d="M172.5,182.4c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0,0.2-0.2c0-0.1-0.1-0.3-0.2-0.4
				C172.8,182.2,172.7,182.3,172.5,182.4z"/>
			<path fill="#ECEAEA" d="M168.8,183.9c0.2,0.2,0.3,0.3,0.4,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2-0.2-0.2
				C169.1,183.8,169,183.8,168.8,183.9z"/>
			<path fill="#D5D4D6" d="M169.4,182.3c0.1,0,0.2,0.1,0.2,0.1c0.1-0.1,0.2-0.2,0.2-0.3s-0.1-0.2-0.2-0.1
				C169.5,182.1,169.5,182.2,169.4,182.3z"/>
			<path fill="#D4D3D4" d="M181.1,202.5c-0.1,0.1-0.2,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
				C181.3,202.7,181.2,202.6,181.1,202.5z"/>
			<path fill="#D6D5D7" d="M179.6,203.6c0.1,0.1,0.2,0.1,0.3,0.1s0.1-0.1,0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.1
				C179.8,203.3,179.7,203.4,179.6,203.6z"/>
			<path fill="#D4D3D5" d="M148.6,153.7c0,0,0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C148.6,153.5,148.6,153.6,148.6,153.7z"/>
			<path fill="#CAC9CC" d="M158.6,168c0,0.1,0.1,0.3,0.2,0.2c0.1,0,0.1-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.2
				C158.7,167.9,158.7,168,158.6,168z"/>
			<path fill="#D0CFD1" d="M168.8,198.6c0-0.2,0.1-0.3,0.1-0.4c0-0.1-0.2-0.1-0.3-0.1c0,0.1-0.1,0.2-0.1,0.2
				C168.6,198.4,168.7,198.5,168.8,198.6z"/>
			<path fill="#CAC9CB" d="M158.9,172c0.1,0.1,0.2,0.2,0.3,0.2s0.1-0.1,0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
				C159.1,171.9,159,172,158.9,172z"/>
			<path fill="#D2D2D4" d="M167.6,186.6c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.1c0.1,0,0.1-0.1,0.2-0.2
				C167.7,186.8,167.7,186.7,167.6,186.6z"/>
			<path fill="#D8D7D8" d="M159.8,180.7c0.2-0.2,0.2-0.3,0.2-0.3c-0.1-0.1-0.2-0.3-0.3-0.3c-0.1-0.1-0.2,0-0.2,0.2
				C159.6,180.4,159.7,180.5,159.8,180.7z"/>
			<path fill="#D2D2D3" d="M162.9,189.3c-0.1,0.1-0.2,0.2-0.2,0.3s0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.1,0.2-0.2
				C163.1,189.6,163,189.5,162.9,189.3z"/>
			<path fill="#D4D3D5" d="M174,197.1c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.1-0.1,0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
				C174.1,196.9,174,196.9,174,197.1z"/>
			<path fill="#D0CFD0" d="M174.5,189.9c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C174.6,189.6,174.5,189.7,174.5,189.9z"/>
			<path fill="#E7E5E6" d="M174.9,190.7c0.2,0,0.4,0.3,0.6,0.1v-0.2C175.3,190.4,175.1,190.5,174.9,190.7
				C174.9,190.8,174.9,190.7,174.9,190.7z"/>
			<path fill="#C9C8CA" d="M165.1,194.7c-0.1,0.1-0.2,0.2-0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.2-0.2
				C165.3,194.8,165.2,194.7,165.1,194.7z"/>
			<path fill="#D3D2D4" d="M180.7,194.2c0.1,0,0.3,0.1,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3s-0.2-0.1-0.2-0.1
				C180.8,193.9,180.8,194,180.7,194.2z"/>
			<path fill="#CECED0" d="M176,193.6c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.1c0.1,0,0.1-0.1,0.2-0.2
				C176.1,193.7,176.1,193.7,176,193.6z"/>
			<path fill="#C6C6C8" d="M186.2,193.9c0,0.2,0,0.5,0,0.7c0.5,0.4,1-0.1,1.4,0C187.2,194.2,186.8,193.8,186.2,193.9z"/>
			<path fill="#F7F3F2" d="M184.1,191.7c0.1,0.3,0.1,0.6,0.5,0.2C184.4,191.8,184.4,191.4,184.1,191.7z"/>
			<path fill="#E7E7E7" d="M174.2,183.8c-0.1,0.2-0.2,0.4,0,0.6c0.1,0,0.3,0,0.3,0C174.5,184.1,174.4,183.9,174.2,183.8L174.2,183.8
				z"/>
			<path fill="#DBDBDC" d="M174,185.5c0.2-0.1,0.4-0.2,0.4-0.5c0,0-0.1-0.1-0.2-0.1C173.9,185,174,185.3,174,185.5L174,185.5z"/>
		</g>
	</g>
	<g>
		<path fill="#DCDCDC" d="M200.6,90c-3.1,1.8-6.2,3.7-9.4,5.5c-6.2,4.2-6,4.1-10.1-2.4C173,80.3,164.6,67.7,156.3,55
			c-0.9-1.3-1.7-2.6-2.5-4c-1-1.6-0.8-2.9,0.9-4c7-4.6,14-9.2,21-13.8c2.1-1.4,2.8,0.4,3.6,1.6c5.1,7.7,10.2,15.4,15.2,23.1
			c4.6,7,8.9,14.2,13.6,21.1c1.9,2.8,2.7,4.7-0.9,6.5c-0.6,0.3-1,1-1.5,1.5c-1.1-0.2-1.9,0.6-2.7,1.1
			C202.2,88.9,201.9,90.1,200.6,90z"/>
		<path fill="#B1B1B1" d="M177.6,43.2c1-0.2,1.6,0.4,2.1,1.1c3.1,4.7,6.2,9.5,9.4,14.2c1.1,1.6,0.6,2.7-1,3.5c-1.4,0.8-2.8,2-4.1,0
			c-3.2-4.8-6.4-9.7-9.4-14.6C173.6,45.9,175.4,43.4,177.6,43.2z"/>
		<path fill="#B1B1B1" d="M194.7,86.8c0.4,1.8-0.6,2.4-1.5,3c-1,0.7-2.1,0.9-2.8-0.3c-2.5-3.7-4.9-7.5-7.3-11.3
			c-0.7-1.1-0.4-2.1,0.7-2.8c1-0.7,2.3-1.6,3.2-0.3C189.7,79,192.3,83.1,194.7,86.8z"/>
		<path fill="#B3B3B3" d="M163.3,51.4c5.7,8.7,11.6,17.4,16.8,26.4c0.4,0.7,0,1-0.7,0.9c-6.2-8.8-12.3-17.7-17.6-27.1
			C162.2,51.1,162.7,51,163.3,51.4z"/>
		<path fill="#B1B1B1" d="M177.9,57.3c0,2.4-1.3,3.6-3.3,3.8c-2.1,0.2-3.5-1-3.7-3.1c-0.2-2.2,1.1-3.3,3.2-3.5
			C176.4,54.2,177.4,55.6,177.9,57.3z"/>
		<path fill="#B3B3B3" d="M179.4,78.7c0.2-0.3,0.5-0.6,0.7-0.9c3.7,4.1,6,9.2,8.6,14C184.9,87.9,181.8,83.6,179.4,78.7z"/>
		<path fill="#B6B6B6" d="M163.3,51.4c-0.5,0.1-1,0.1-1.4,0.2c-1.3-1.3-2.5-2.7-2.9-5.2C161.4,47.6,162,49.8,163.3,51.4z"/>
	</g>
</g>
<g id="body_1_">
	<g id="jV5h25_1_">
		<g>
			<path fill="#B14D4A" d="M41.1,300c0.4,4.8,0.9,9.6,1.2,14.3c0.2,4.5,0.8,9,0.9,13.6c0.2,5.5,0.5,11,0.7,16.5
				c0.2,4.4,0.2,8.9,0.5,13.3c0.2,2.9,0.2,5.7,0.5,8.6c0.2,1.8-0.1,3.7-0.1,5.5c0.1,1.7,0.1,3.3,0.3,5c0.3,3.8,0.5,7.6,0.3,11.4
				c-0.1,2.4,0.2,4.8,0.1,7.1c-0.3,5.4,0.2,10.8,0.1,16.2c-0.1,5.1-0.1,10.2-0.1,15.3c0,3.2,0.2,6.4,0.1,9.7c-0.1,4.1-1,8-1.6,12
				c-1.2,7.6-2.7,15.1-4.1,22.7c-1.1,6.5-2.1,13.1-3.3,19.6c-0.7,3.7-1.3,7.5-1.9,11.2c-0.5,3-0.8,6.1-1.4,9c-0.6,2.9-1,5.9-1.5,8.8
				c-0.5,2.8-1.1,5.5-1.6,8.3c-0.7,4-1.5,7.9-2.2,11.9c-0.9,5.4-2.1,10.8-3,16.3c-0.7,4.6-1.6,9.1-2.6,13.6
				c-0.6,2.6-0.3,2.5,1.9,2.6c0.3,0,0.6,0.1,0.8,0.2c1.1,0.6,2.4,0.6,3.6,0.7c4.2,0.6,8.4,1.1,12.6,1.3c0.4,0,0.9,0.2,1.3-0.2
				c0.5,0,0.9-0.1,1.4-0.1c1.2,0,1.7-0.9,2-1.9c0.6-1.9,1.2-3.8,1.8-5.7c0.8-2.7,1.6-5.3,2.4-8c1.7-5.9,3.6-11.8,5.4-17.7
				c1.6-5.3,3.2-10.6,4.8-15.9c1.3-4.3,2.4-8.7,3.8-13c1-3.1,2-6.2,3-9.3c0.7-2.3,1.1-4.6,1.9-6.9c0.6-1.8,1.1-3.6,1.6-5.5
				c1.1-3.9,2.1-7.8,3.2-11.7c0.8-2.8,1.5-5.6,2.3-8.4c0.5-1.9,0.9-3.8,1.4-5.7c0.8-3.3,1.7-6.5,2.5-9.8c0.8-3.4,1.6-6.8,2.3-10.2
				c1-4.9,1.9-9.7,2.8-14.6c0.8-4.7,1.6-9.5,2.3-14.2c0.7-4.3,1.4-8.5,2-12.8c0.6-4.2,1.3-8.5,1.6-12.7c0.1-1.5,0.4-3.1,0.6-4.6
				c0.5-3.2,1-6.4,1.4-9.6c0.5-3.6,1.1-7.3,1.5-10.9c0.6-4.9,1.1-9.8,1.7-14.7c0.4-3.5,0.8-7,1.2-10.5c0.4-3.7,0.7-7.5,1.1-11.2
				c0.1-0.6,0.1-1.1,0.3-1.6c0.1-0.3,0.1-0.9,0.7-0.8c0.5,0.1,0.7,0.5,0.8,0.9c0.1,0.9,0.5,1.8,0.7,2.6c1.3,5.5,2.6,11.1,3.8,16.6
				c1.5,6.9,2.9,13.8,4.1,20.8c0.7,4.1,1.6,8.1,1.9,12.2c0.3,3.2,1.1,6.3,1.5,9.4c0.6,4.7,1.3,9.4,1.8,14.1
				c0.3,3.4,0.7,6.9,1.1,10.3c0.5,5.3,1.5,10.6,1.7,15.9c0.1,2.7-0.2,5.4-0.1,8.1c0.2,4.9-0.4,9.8-0.3,14.8c0,3.4-0.4,6.8-0.4,10.1
				c0,2.4-0.1,4.9-0.3,7.3c-0.2,3.1-0.3,6.2-0.5,9.3c-0.2,2-0.3,3.9-0.4,5.9c-0.2,2.7-0.1,5.4-0.3,8.1c-0.3,4.2-0.5,8.3-0.8,12.5
				c-0.2,3.5-0.8,7-0.9,10.5c-0.2,4.3-0.9,8.5-1.2,12.8c-0.2,2.7-0.8,5.3-0.9,7.9c-0.2,3.6-0.8,7.1-1.3,10.6
				c-0.7,5.1-1.1,10.2-1.7,15.3c-0.4,3.2-1,6.4-1.3,9.7c-0.1,1.5,0.4,2.2,1.9,2.5c0.4,0.1,0.8,0.1,1.2,0.2c0.5,0.4,1.1,0.3,1.7,0.3
				c4.4-0.1,8.8,0.3,13.2,0.3c0.9,0,1.7-0.4,2.6-0.3c1,0,2.1,0,3.1,0.1s1.4-0.3,1.5-1.2c0.4-3.5,1.7-6.8,2.5-10.3
				c1.1-5.2,2.4-10.4,3.6-15.7c1.4-6.3,2.9-12.5,4.1-18.8c0.8-4,1.6-8,2.3-12c0.6-3.4,1.5-6.7,2.1-10.1c0.6-3.8,1.8-7.5,2.6-11.2
				c0.9-4.3,1.7-8.6,2.5-13c0.7-3.6,1.5-7.1,2.1-10.7c0.9-5.2,1.9-10.4,2.5-15.6c0.6-4.6,1.4-9.2,2-13.9c0.4-3.1,0.6-6.2,0.9-9.3
				c0.4-4.9,0.9-9.7,1.2-14.6c0.3-4.2,0.3-8.4,0.6-12.5c0.4-5.4,0.4-10.8,0.4-16.2c0-4.3,0.1-8.6-0.1-12.8c-0.1-2.6,0.1-5.3,0-7.9
				c-0.2-4-0.5-8.1-0.8-12.1c-0.3-3.4-0.6-6.8-1.1-10.1c-0.7-4.7-1.1-9.5-2-14.3c-0.8-4-1.4-8.1-2.1-12.2c-0.8-4.6-1.7-9.2-2.7-13.8
				c-1.5-7-3.5-14-5.8-20.8c-1.6-4.9-3.3-9.7-5.1-14.5c-1.8-4.6-3.6-9.1-5.3-13.7c-0.8-2.2-1.1-4.5-1-6.8c-0.3-0.9,0.1-1.8-0.2-2.7
				c-0.5-0.3-1-0.3-1.5-0.2c-4.8,0.6-9.6,1-14.5,1.4c-1.9,0.2-3.7-0.1-5.6,0.4c-0.3,0.1-0.7,0.1-1,0c-2.8-0.1-5.7,0-8.5,0.3
				c-4.9,0.4-9.9,0.1-14.8,0.2c-2.9,0-5.8,0.2-8.6-0.1c-4.2-0.3-8.4-0.1-12.6-0.5c-4.1-0.3-8.2-0.7-12.3-0.9c-1,0-1.9-0.2-2.9-0.4
				c-1.1-0.1-2.2-0.3-3.3,0.1c-0.3,0.2-0.3,0.4-0.4,0.7c-0.3,3.6-1.4,6.9-1.9,10.5c-0.4,2.8-1.1,5.5-1.5,8.2c-0.1,1,0.2,1.9,0.7,2.7
				c2.3,4.9,5.2,9.5,8.5,13.7c0.8,1,1.5,2.2,2.3,3.2c0.9,1.2,0.9,1.6,0.1,2.9c-2.1,3.2-4,6.6-5.7,10c-0.8,1.6-1.9,3-2.7,4.5
				c-0.5,0.9-1.2,0.8-2-0.2c-1.4-1.7-2.9-3.2-4.3-4.9c-0.3-0.4-0.5-0.9-1.2-0.9v0.1C41.1,299.9,41.1,300,41.1,300z"/>
			<path fill="#BFBFC2" d="M112.9,250.8c3.2-0.3,6.5-0.5,9.7-0.8c3.5-0.3,7-0.5,10.5-1.1c0.5-0.1,1-0.1,1.5-0.1
				c0.3-1.4,0-2.8,0.1-4.2c0-0.4-0.1-0.9,0.1-1.3c0-4.1,0.1-8.2,0.3-12.3c0.4-6.3,0.6-12.5,1-18.8c0.3-3.8,0.3-7.6,0.4-11.4
				c0.1-2,0.1-4,0.2-5.9c0.3-0.6-0.2-1.3,0.2-1.9c-0.1-3.4,0.3-6.8,0.2-10.1c0-4.4,0.4-8.7,0.6-13.1c0.1-2.1,0.1-4.2,0.3-6.3
				c0.2-2,0-4,0.1-6c0.3-5.3,0.6-10.6,0.9-15.9c0.1-2.4-0.1-4.8-0.5-7.1c-0.5-3.7-2.2-6.9-3.3-10.4c-0.6-2.1-1.6-4-2.1-6.1
				c-0.2-0.8-0.7-1.5-1.2-2.2c-1.4-1.9-2.8-3.8-4.3-5.6c-0.6-0.8-1.4-1.5-2.2-2.1c-2.2-1.6-4-3.9-6.8-4.8c-5.9-1.8-11.9-3.3-18-4
				c-3.6-0.4-7.1-1.2-10.7-1.6C89,97.6,88.4,97,88.3,96c-0.1-1.1-0.3-2.1-0.5-3.2c-0.3-1.5-1-1.7-2.8-1c-1.4,0.5-2.3,1.6-3.5,2.4
				c-1.7,1.2-3.5,2.3-5.1,3.6c-0.7,0.5-1.3,0.6-2.1,0.2c-3.2-1.5-6.6-2.6-9.9-4c-1.1-0.5-2.3-0.9-3.5-1.1c-0.6,0.1-1.2-0.2-1.7-0.4
				c-1.2-0.3-1.6,0-1.8,1.3c-0.3,1.6-0.2,3.2-0.2,4.9c0,0.8-0.3,1.3-1.1,1.5c-1.2,0.3-2.4,0.6-3.6,0.9c-3,0.8-6.1,1.4-8.9,2.8
				c-0.9,0.5-1.9,1-2.8,1.5c-0.3,0.4-0.7,0.7-1.2,0.7c-0.8,0.3-1.6,0.6-2.4,1c-0.3,0.4-0.6,0.8-1.2,0.7c-1.8,1.8-4.4,2.5-6,4.5
				c-0.2,0.3-0.3,0.7-0.1,1c0.5,0.5,0.9,0.1,1.4-0.1c-0.3,0.4-0.8,0.2-1.1,0.3c-0.4-0.1-0.2-0.9-0.8-0.7c-0.1,0.1-0.2,0.2-0.2,0.2
				c-0.2,0.8-1,0.8-1.4,1.2c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0.4-0.3,0.9-1,0.7c-3.2,3-6.3,6-8.8,9.5c-1.6,2.2-3.3,4.4-4.5,6.9
				c-0.8,1.6-2,3.2-2.5,5c-0.1,0.4,0.2,0.3,0.4,0.3c0.5-0.1,1-0.2,1.5-0.2c0.3,0,0.3,0.2,0.3,0.5c-0.1,0.8-1.1,1-1.2,1.8
				c-0.2,0.4-0.2,1-0.9,1.1c0.4-0.2,0.7-0.5,0.5-1c0-0.1-0.1-0.2-0.1-0.3l-0.1-0.1c-0.3-0.2-0.1-1.2-0.9-0.6c-1,1.4-1.4,3-2.1,4.5
				c-0.1,0.2-0.2,0.5-0.3,0.8c-0.2,0.3-0.1,0.7-0.5,1c-0.1,1.2-0.8,2.1-1.2,3.2c-0.3,0.9,0,2-1.1,2.6c-0.2,3.5-1.7,6.8-2.2,10.2
				c-0.5,3.6-1.3,7.2-1.6,10.8c-0.2,2.5-0.6,5-0.8,7.6c-0.2,2.2,0,4.3-0.2,6.5c-0.1,1.2-0.3,2.3-0.1,3.5c0.2,2.5,0.2,5.1,0.3,7.6
				c0.1,3.7,0.5,7.4,1.1,11c0.6,4,1.4,7.9,2.1,11.9c0.6,3.2,1.4,6.3,2.2,9.4c0.9,3.3,2,6.6,3,9.9c0.1,0.2,0.2,0.3,0.3,0.5
				c0.7,0.2,1.3-0.2,1.9-0.4c4-1.6,7.9-3.2,11.9-4.7c2.3-0.9,4.8-1.4,7-2.3c0.5-0.2,1.2-0.3,1.6-0.8c0.2-0.4,0.1-0.9,0.1-1.3
				c-0.9-5.1-1.4-10.2-2-15.3c-0.5-4.5-0.6-9.1-0.6-13.6c0-5.5,0.6-11,1.6-16.4c0.7-3.9,1.6-7.6,3-11.3c1.2,6.8,2.4,13.6,3.9,20.4
				c1.1,5.3,2,10.6,3.2,15.8c1.5,6.8,2.5,13.7,3.9,20.5c1.3,6.3,2.9,12.6,4.3,18.9c0.1,0.2,0.1,0.5,0.4,0.6c2.3-0.3,4.5,0.3,6.8,0.4
				c3.8,0.2,7.7,0.6,11.5,0.8c3.6,0.3,7.2,0.2,10.8,0.4c7.8,0.4,15.5,0.2,23.3,0.1c1.8,0,3.6-0.3,5.4-0.3c1.8-0.1,3.6,0,5.4-0.1
				C112.5,250.8,112.7,250.5,112.9,250.8z"/>
			<path fill="#DDB6A7" d="M61.2,93.1c1.2,0.9,2.7,1,4.1,1.6c3.1,1.3,6.3,2.4,9.4,3.8c0.7,0.3,1.3,0.3,2-0.2
				c2.6-2.1,5.5-3.7,8.1-5.9c0.2-0.2,0.3-0.3,0.5-0.5c0-1.6,0.1-3.3,0-4.9c-0.1-3.3,0-6.5-0.1-9.8c0-1,0.4-1.1,1.2-1
				c4,0.5,8,0.8,11.9-0.3c2.7-0.7,4.6-2.4,5.4-5.1c0.4-1.3,0.2-2.8,0.5-4.2c0.4-1.9-0.5-2.5-2.1-3.2c-2-0.8-3.9-2.1-5.4-3.7
				c-0.3-0.3-0.8-0.6-0.6-1.1c0.2-0.5,0.7-0.3,1.2-0.3c1.9,0.3,3.9,0.3,5.9,0.2c0.8,0,1.2-0.1,1.1-1.2c-0.1-1.6,0-3.2,0.1-4.8
				c0-0.7,0.6-0.9,1.2-1c0.9,0,1.8-0.3,2.7-0.5c0.7-0.2,0.9-0.5,0.8-1.3c-0.5-2-1.2-3.9-1.8-5.8c-1.3-3.9-2.6-7.9-3.8-11.8
				c-1.1-3.4-2.4-6.8-4.2-10c-0.1-0.3-0.1-0.4-0.1-0.7c-2.5-4.8-6.9-7.1-11.9-8.5c-2.3-0.7-4.6-0.5-6.9-0.4s-3.8,1.6-5.2,3.2
				c-3.9,4.3-5.7,9.3-5.8,15.1c0,1.8-0.2,3.7-0.1,5.5c0.1,2.1,0.5,4.1,0.9,6.2c0.3,1.8,0.1,2.1-1.7,2.3c-0.1,0-0.2,0-0.4,0
				c-1.4,0.2-1.5,0.1-1.7-1.4c-0.3-2.1-1.1-3.9-2.1-5.7c-0.9-1.6-2.3-2.3-4.1-2.3c-0.9,0-1.6,0.4-2.1,1.2c-1.4,2-1.1,4.1-0.3,6.2
				c0,0.1,0.1,0.2,0.1,0.4c0.9,2.7,2.6,4.8,5.3,6c0.7,0.3,1,0.8,0.9,1.6c-0.5,3.4-0.4,6.8-0.8,10.2c-0.4,3-0.6,6.1-0.8,9.2
				c-0.1,1.2-0.6,2.1-1.8,2.6c0.8,0.2,1,0.8,0.9,1.6c-0.1,4.3-0.2,8.7-0.4,13C61.2,89.2,61.3,91.2,61.2,93.1z"/>
			<path fill="#CA7F64" d="M58.1,43c-0.2-0.8-0.4-1.6-0.6-2.4c-0.4-1.6,0.3-3,1.2-4.2c0.6-0.8,2.5-0.7,3.7,0
				c0.8,0.5,1.4,1.1,1.7,1.9c0.9,1.9,1.9,3.9,1.9,6.1c0,0.9,0.6,1.2,1.4,1c0.5-0.1,0.9-0.2,1.4-0.2c1.5,0,2-0.5,1.7-2
				c-0.6-2.6-1.1-5.3-1.1-8c0-5.3,0.3-10.7,3.2-15.4c1.4-2.2,3-4.3,5.2-5.9c0.6-0.4,1.4-0.7,2.1-0.8c4.3-0.6,8.3,0.2,12.2,2.2
				c2.7,1.4,4.9,3.2,6.4,5.9c0.1,0.3,0.4,0.4,0.7,0.4c1.6-1.8,2.7-4,2.9-6.3c0.4-4.8-1.7-9.7-6.2-12.2c-3.2-1.7-6.5-3-10.2-3.1
				c-3.1-0.1-5.9,0.8-8.8,1.8c-3,1-5.9,2.3-9.1,2.3c-0.7,0-1.4-0.2-2.1-0.2c-2.9-0.2-5.7-0.3-8.6-0.4c-3-0.2-5.9,0.5-8.5,1.9
				c-5.9,3-9.1,8-11.1,14.1c0.3,0.3,0.6,0.5,0.9,0.9c0.3,0.3,0.5,0.6,0.7,1c0.3,0.5,0.3,1-0.5,1.2c0.5-0.7,0.1-1.3-0.2-1.9
				c-0.2-0.4-0.5-0.7-0.9-0.9c-0.6,1.1-0.7,2.4-0.9,3.6c0.1,0.2,0.1,0.3,0.3,0.4c1.3,0.3,2.6,0.6,3.7,1.4c-0.3-0.3-0.8-0.4-1.2-0.6
				c-0.7-0.3-0.8,0.4-1,0.8c-0.1,0.3-0.1,0.6-0.2,0.9c-1.2,0.5-1.2,0.5-0.5,1.6c0.1,0.1,0.2,0.2,0.4,0.3c0.3,0.1,0.7,0.2,0.9,0.5
				c0.1,0.2,0.2,0.5,0.4,0.6c-0.6-0.1-1.2-0.7-2-0.8c-0.2,0-0.4,0-0.5,0.1c-0.8,0.7-0.7,1.2,0.2,1.7c0.3,0,0.6,0.1,0.7,0
				c-0.6,0-1.1,0.3-1,1.2c0.2,0.6,0.8,0.3,1.1,0.6c0,0.3-0.3,0.5-0.4,0.8c0,0.6,0.5,0.8,1,0.8c0.3-0.1,0.5-0.3,0.7-0.5
				s0.6-0.5,0.7-0.2c0.1,0.4,0.7,0.7,0.4,1.2c-0.3,0.6-0.7,1-1.3,1.2s-0.9,0.6-1.1,1.2c-0.4,1.2-0.4,1.2,0.9,1.8
				c0.7-0.1,1-0.3,0.6-1c0.5,0,0.9,0.5,1.5,0.3c0.4-0.2,0.5-0.7,0.9-1.1c0.2,0.4-0.7,1.4,0.6,1.1c0.5-0.2,1.1-0.2,1.6-0.5
				c0.3-0.3,0.9,0,1.1-0.5c0.7-1.1-0.2-2-0.3-3c-0.5-0.4-0.3-1.1-0.8-1.5h-0.1C43.8,32,44,32.1,44,32.1c0.2-0.1,0.3-0.3,0.2-0.5
				c-0.3-0.3-0.6-0.5-0.9-0.7c-0.1,0-0.2-0.1-0.3,0c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.4-0.3,1.2-0.8,1.5c-0.3-0.4,0.4-0.9,0.2-1.4
				c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.3-0.7c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.2-0.2-0.4-0.4-0.4c-0.1,0-0.1-0.1-0.2-0.1
				c0.1-0.1,0.2,0,0.3,0c0.9-0.3,0.9-0.7,0.3-1.3c0,0,0-0.1,0.1-0.1h0.1c0.7,0.1,1.3,0,2,0.2c-0.2,0.3-0.4,0.2-0.6,0.3
				c-1,0.2-0.8,0.8-0.6,1.5c0,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.4,0.4,0.7,0.4c0.6,0,1.1-0.5,1.8-0.4c-0.1,0.2-0.3,0.5-0.4,0.9
				c0,0.1-0.1,0.2-0.1,0.3c0.1,0.4-0.2,0.5-0.4,0.6c-0.1,0.3,0,0.7,0,1c0.4,0.4,0.7,0.8,0.8,1.4c0.1,0.3,0.2,0.5,0.3,0.8
				c0.2,0.3,0.4,0.7,0.7,0.5c0.7-0.6,1.1,0.2,1.7,0.3c0.1,0,0.2,0,0.3,0c0.2,0,0.5-0.1,0.6,0c0.2,0.3-0.1,0.4-0.3,0.5
				c-0.3,0.3-0.6,0.2-0.9,0c-0.4-0.3-0.6-1-1.3-0.7c-0.3,0.3-0.6,0.7-0.5,1.2c0.2,0.2,0.7,0.4,0,0.6c-0.4,0.2-1.1,0.1-1,0.9
				c0.1,0.5,0.1,1-0.4,1.3s-0.8,0.7,0,0.9c0.3,0.1,0.6,0.2,0.9,0.3c0.4,0.1,0.6-0.1,0.6-0.5c0-0.5,0.3-0.9,0.5-1.4
				c0.2,0.8-0.9,1.8,0.4,2.3c0.5,0,0.4-0.8,0.9-0.6c0.4,0.2,1.3,0.1,0.7,1c-0.1,0.2,0,0.5,0.3,0.6c0.2,0,0.4,0,0.6,0
				c-0.2,0.1-0.4,0-0.5,0c-1,0.2-0.8,0.9-0.8,1.6c0.1,0.2,0.3,0.3,0.6,0.4c0.7,0,1.5,0,2-0.6c0.3-0.4,0.7,0.1,0.9,0
				c-0.6-0.2-1.1,0.1-1.4,0.7c-0.3,0.4-0.7,0.7-1.1,0.2c-0.5,0.2-1,0.5-1.6,0.5c-0.4,0-0.6,0.2-0.4,0.7c0.3,0.4,0.5,0.7,1,0.9
				c0.1,0,0.2-0.1,0.3-0.1c0,0.5-0.2,1-0.3,1.6c-0.1,0.4-0.5,0.2-0.8,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.3,0,0.5,0,0.8
				c0.1,0.4-0.3,0.6-0.1,1c0.1,0.2,0.2,0.4,0.4,0.5c0.3,0.1,0.6,0,0.8-0.2c0.2-0.6,0.4-1.2,1-1.5c0.1-0.1,0.2-0.3,0.3-0.2
				s-0.1,0.2-0.1,0.3c-0.7,0.7-0.7,1.3,0.1,1.9c0.3,0.2,0.7,0.1,1,0.4c0.1,0-0.2,0.2-0.1,0.1c0,0,0,0-0.1-0.1
				C49.8,51,49.5,51,49.2,51c-1.1,0-1.9,0.8-2.8,1.1c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.4-0.1,0.7,0.1,1c0.3,0.3,0.8-0.3,1,0.3
				c0.1,0.2,0.1,0.4,0.3,0.6c0.3,0.3,0.7,0.4,1.1,0.4c0.3,0,0.5-0.1,0.6-0.3c0-0.2,0-0.3-0.1-0.5c-0.9-0.2-0.2-1-0.5-1.4
				c-0.1-0.1-0.1-0.1,0-0.1c0.5,0.4-0.3,1.1,0.4,1.4c0.4,0,0.8,0.1,0.8,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.6-0.1,1-0.4,0.9-1
				c-0.1-0.1-0.2-0.3-0.3-0.5c0.2,0.1,0.2,0.3,0.3,0.4c0.2,0.1,0.4,0.2,0.6,0c0.3-0.5,0.6-1,1.2-1.2c0,0.5-0.4,0.9-0.2,1.4
				c0.5,0.2,1.2,0.1,1.6,0.6c0.1,0.1,0.3,0.1,0.5,0.2c0.7,0.4,0.1,0.6-0.1,0.9c-0.3,0.3-0.6,0.3-1,0.2c-0.7-0.5-1.2-0.3-1.6,0.3
				h-0.1c0,0,0,0,0-0.1s0-0.1,0-0.1c0-0.1,0-0.2,0.1-0.2c0-0.4,0.3-0.4,0.5-0.6c0.1-0.2,0.1-0.3,0-0.5c-0.2-0.3-0.3-0.9-0.8-1
				c-0.7,0.7-1,1.6-1.6,2.4c0,0.1,0,0.2,0,0.3c0.7,1.2-0.1,2.1-0.8,2.9c-0.1,0.1-0.1,0.2-0.1,0.3c0.5,0.7,1.3,0.2,2,0.5
				c-0.6,0.3-0.8,0.9-1.2,1.3c0.3,0.6,0.9,1,1.5,1.4c0.1-0.1,0.1-0.2,0.1-0.3c0,0.1-0.1,0.2-0.1,0.3c0,0.3,0.2,0.5,0.4,0.6
				c0.3,0,0.4,0.1,0.3,0.4c0.2,0.3,0.4,0.5,0.6,0.8c0.4-0.3,0.3-0.9,0.8-1.1c-0.5,0.3-0.5,0.8-0.8,1.1c2.4,2.7,4.8,5.4,7.1,8.1
				c0.3,0.4,0.7,0.6,1.1,0.9c1.3,0.3,1.8-0.3,2.1-2.4c0.4-3.2,0.4-6.4,0.8-9.6c0.4-3.4,0.2-6.9,0.9-10.3c0.1-0.6,0-1.2-0.6-1.4
				C60.9,48.2,59.3,45.8,58.1,43c-0.1,0.1-0.1,0.1-0.2,0.2C58,43.1,58.1,43,58.1,43z"/>
			<path fill="#DDB6A7" d="M31.3,231.1c-2.1,0.7-4.3,1.3-6.4,2c-4.8,1.6-9.4,3.7-14.1,5.5c-0.6,0.2-1.2,0.4-1.8,0.7
				c0.6,2.8,1.7,5.5,2.8,8.1c2.4,6.2,5.4,12.2,8.1,18.2c2.2,4.9,4.8,9.5,7.5,14.1c2.2,3.6,4.3,7.2,6.7,10.6c1.7,2.5,3.7,4.7,5.6,7.2
				c0.6,0.8,1.2,1.6,1.6,2.5c1,0.4,1.3,1.5,2,2.2c1.1,1.1,2,2.3,3,3.4c0.3,0.3,0.6,0.6,1,0.9c0.7,0.5,1.2,0.3,1.7-0.4
				c2.8-4.7,5.2-9.6,8.2-14.3c1.3-2,1.3-2.2-0.2-4.1c-3-4.1-6.1-8.2-8.6-12.7c-0.9-1.6-1.6-3.2-2.4-4.8c-0.2-0.5,0-0.9-0.1-1.4
				c-0.3-0.1-0.3-0.4-0.5-0.7c-2.8-5.7-5.6-11.4-7.8-17.3c-2.2-5.9-4.4-11.8-5.4-18.1C31.9,232.1,31.8,231.5,31.3,231.1z"/>
			<path fill="#6D6E72" d="M108.8,584.7c-1.7,1.6-2.1,3.8-2.4,6c-0.6,3.5,0.7,6.8,1.1,10.2c0.1,0.7,0.7,1,1.4,1.1
				c3.2,0.4,6.4,0.2,9.7,0.3c0.8,0,1.3-0.2,1.3-1.2c0.1-1,0.4-2,0.4-3.1c0-0.6,0.4-0.4,0.8-0.3c2.6,0.6,4.9,1.8,7.2,3
				c1.4,0.8,3,1.2,4.4,2c0.9,0.5,2.1,0.3,3,0.2c3-0.1,5.9,0.2,8.9-0.1c2.5-0.2,5.1,0.1,7.6,0.1c1.9,0,3.8,0,5.6,0
				c0.3,0,0.5-0.1,0.7-0.4c0.7-1.6-0.1-4.1-1.7-5c-2.3-1.4-5-1.9-7.4-3c-3.6-1.6-7.2-3.1-10.8-4.7c-2.1-1-4.3-1.8-6.5-2.7
				c-1.2-0.5-2.3-1-3.4-1.7c-0.5-0.3-1-0.6-1.5-0.5c-1-0.3-1.7,0.4-2.5,0.8c-1.6,0.9-3.4,1.3-5.2,1.7c-2.2,0.5-4.3-0.2-6.3-1
				c-1.1-0.4-2.1-1-3.1-1.5C109.6,584.8,109.2,584.5,108.8,584.7z"/>
			<path fill="#6D6E72" d="M22.9,588c-1.6,1.6-2,3.8-2.3,5.9c-0.5,3.5,0.6,6.8,1.1,10.2c0.1,0.7,0.7,1,1.3,1
				c1.7,0.1,3.5,0.3,5.2,0.3c1.4-0.1,2.9,0.1,4.3,0.1c0.9,0,1.5-0.2,1.5-1.3c0.1-0.9,0.4-1.9,0.4-2.8c0-0.7,0.3-0.7,0.9-0.5
				c2.6,0.6,4.9,1.8,7.2,3c1.4,0.7,2.9,1.4,4.3,1.9c1.3,0.5,2.9,0.4,4.4,0.3c2.1-0.1,4.3,0,6.4,0c2.5,0,5-0.1,7.5,0
				c2.2,0.1,4.4,0,6.6,0c0.8,0,1-0.2,1.1-1c0.3-2.9-1-4.6-4-5.5s-6-2.1-8.9-3.4c-4.5-1.9-9.1-3.9-13.6-5.8c-1.3-0.5-2.5-1.1-3.6-1.9
				c-0.4-0.2-0.7-0.5-1.2-0.3c-0.9-0.5-1.6,0.1-2.3,0.5c-2.4,1.5-5,1.9-7.7,2c-1.8,0-3.4-0.8-5-1.5c-0.8-0.4-1.5-0.8-2.3-1.1
				C23.7,588,23.3,587.7,22.9,588z"/>
			<path fill="#98989C" d="M22.9,588c1.5,0.7,3.1,1.3,4.6,2.1c2.7,1.4,5.5,1.2,8.4,0.4c1.6-0.4,3-1.2,4.4-2c0.4-0.2,0.7-0.2,1.1-0.3
				c-0.6-0.6-1.1-1.2-1.8-1.8c-0.6-0.5-0.2-1-0.1-1.5c1-3.4,2-6.8,3-10.1c-3.2-0.3-6.4-0.6-9.6-1c-2.6-0.3-5.2-0.9-7.9-1
				c-0.3,3.2-0.8,6.3-1.3,9.5C23.5,584.3,23.2,586.1,22.9,588z"/>
			<path fill="#98989C" d="M108.8,584.7c2.6,1.6,5.4,2.9,8.5,3.3c0.9,0.1,1.9-0.1,2.8-0.3c1.9-0.5,3.8-0.9,5.4-2.1
				c0.4-0.3,0.9-0.6,1.5-0.7c-2.4-0.9-2.5-2.6-1.7-4.7c0.4-1.2,0.6-2.6,0.8-3.9c0.1-0.6,0.4-0.8,0.9-0.8c-0.8-0.4-1.6-0.1-2.5-0.1
				c-5,0-10-0.4-15.1-0.3C109,578.2,109.1,581.5,108.8,584.7z"/>
			<path fill="#B5524C" d="M46.6,49.4c-0.1-0.4,0.6-0.5,0.2-1c-0.1-0.2-0.5-0.4-0.2-0.7l0,0c0.2-0.3,0.1-0.5,0-0.8
				c-0.1-0.1-0.1-0.2-0.2-0.2c-0.6,0.2-0.8-0.4-1.2-0.5c-0.3,0-0.5,0.2-0.7,0.4c0-0.4,0.3-0.8-0.1-1c-0.3-0.1-0.5,0.1-0.7,0.3
				c-0.5,0.4-1,0.9-1.8,0.6c-0.1,0-0.3,0.2-0.3,0.3c-0.1,0.4,0,0.7,0.2,1c0.5,0.5,0.2,0.8-0.2,1.2c1.1-0.8-0.3-1.4-0.1-2.1
				c-0.1-0.3-0.4-0.4-0.6-0.5c-0.3-0.1-0.6-0.3-0.9-0.4c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3-0.2-0.6-0.5-0.9-0.6
				c-0.2-0.1-0.5-0.2-0.4,0.2c0.2,0.8,0.7,1.5,1.2,2.1c0.3,0.3,0.4,0.6,0.5,0.9c0.4,0.6,0.6,1.3,1.2,1.7c0.8,0,1.7,0.2,2.5-0.2
				c0.5-0.4,0.4-1,0.6-1.5c-0.1-0.1-0.1,0.1-0.1-0.1c0-0.1,0.1-0.1,0.2,0c0.3,0,0.4-0.3,0.6-0.3c-0.3,0.3-0.7,0.6-0.6,1.1
				c0.1,0.2,0.3,0.3,0.5,0.4c0.5,0,1,0.2,1.4,0.4C47,50.5,46.8,49.9,46.6,49.4z"/>
			<path fill="#BC6456" d="M38.5,33.6c-0.4-0.2-0.8-0.3-1-0.7c0.1-0.2-0.1-0.3-0.2-0.3c-0.5,0.1-1-0.1-1.1,0.9
				c-0.1,0.8-0.6,1.6,0,2.5c-0.1,0.1-0.2,0-0.3,0.1c-0.3,1.2,0.3,2.3,0.5,3.4c0.1,0.1,0.2,0.1,0.3,0c0-1.2,0.7-2,1.4-2.9
				c0.3-0.4,0.5-0.7,1-0.8c1.1-0.4,1.6-1.2,1.9-2.5c-0.3,0.2-0.5,0.4-0.7,0.5c-0.2-0.4,0.1-0.7,0.1-1.3c-0.5,0.5-1.3-0.3-1.4,0.8
				c0.6,0.3,0.9,1,0.5,1.4c-0.5,0.5-1.3,0.3-1.8-0.4c0.3,0.1,0.5,0.3,0.8,0.4c0.3,0.1,0.7,0.3,1,0s0.1-0.6,0-0.9
				C39.2,33.3,38.8,33.5,38.5,33.6z"/>
			<path fill="#C77A62" d="M44.7,49.5c-0.2-0.1-0.3-0.2-0.5-0.2c-0.4,0.2-0.4,0.4-0.1,0.7l0,0H44c-0.2-0.1-0.4-0.1-0.6,0
				c-0.1-0.1-0.2-0.2-0.2-0.3c-0.6,0.6-1.6-0.2-2.1,0.5c0.2,0.4,0.3,0.8,0.7,1l0,0c1,1.3,1.6,2.9,2.9,4.1c0.1-0.1,0.2-0.2,0.2-0.4
				c-0.2-0.3-0.2-0.5,0.2-0.4c0.2,0,0.3-0.1,0.4-0.2c0.1-0.6-0.1-1.1-0.6-1.5c-0.1,0-0.1,0.1-0.1,0.1c0-0.1,0-0.1,0.1-0.2
				c0-0.5,0.1-0.9-0.4-1.2c-0.4-0.2-1.1,0.4-1.2-0.5c0.4-0.2,0.8-0.1,1.1,0.2c0.6,0.1,0.6,1.6,1.6,0.6c0.2,0,0.3,0,0.5,0
				c-0.1-0.5-0.4-0.9-0.3-1.5C45.8,49.8,45.2,49.7,44.7,49.5z"/>
			<path fill="#BA5E53" d="M37,41.8c0.1,0.8,0.4,1.6,0.7,2.4l0.1-0.1c0.1-1,0.9-0.4,1.4-0.6c0.3-0.3,0.3-0.8,0.4-1.2
				c0.2-1-1-1.7-0.4-2.8c0.1-0.2-0.5-0.3-0.7-0.6c-0.1-0.1-0.2-0.2-0.4-0.3c-1.3-0.6-1.4-0.5-1.5,0.9c1.1,0.3,1.2,0.5,0.6,1.6
				C37.1,41.3,36.9,41.5,37,41.8z"/>
			<path fill="#C87C63" d="M50,55.4c0-0.1,0-0.2,0-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.6-0.3-1.2-0.8-1.5,0.3c-0.2,0.5-0.3,0-0.4-0.1
				c-0.4-0.1-0.9-0.4-1.3,0.1c-0.1,0,0.1,0.4-0.2,0.2c-0.3-0.3-0.6-0.2-1-0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0.3,0.5,0.8,1,1.3,1.4
				c0.2-0.1,0.3-0.2,0.5-0.1c0.1,0.1,0.1,0.3-0.1,0.4c0,0.5,0.4,0.7,0.7,1c0.3-0.2,0.3-0.6,0.7-0.7C48.7,56.8,49.8,56.5,50,55.4z"/>
			<path fill="#C8C8CA" d="M11.8,139.2c0.3-0.4,0.4-0.9,1-1c0.7-0.1,0.1-0.6,0.2-0.9c0-0.1-0.1-0.1-0.1-0.1c-0.1,0.1,0,0.2-0.1,0
				s0,0.1,0.1-0.1C13,137,12.8,137,13,137c0-0.2,0-0.4-0.2-0.5c-0.8-0.2-1.4,0.3-2.2,0.2c-0.4,0.4-0.5,0.9-0.5,1.4
				c0.8-0.3,0.3,0.7,0.7,0.7c0-0.2-0.1,0,0-0.1c0.1,0,0.1,0.1,0,0.1c0.1,0.1,0.2,0.2,0.2,0.2C11.4,139.3,11.6,139.3,11.8,139.2z"/>
			<path fill="#C3725E" d="M35.8,36c0.3,0,0.4,0.7,0.8,0.3c0.3-0.3-0.1-0.5-0.2-0.7c-0.5-0.6-0.6-1.2,0.1-1.7c0.1,0,0.2-0.1,0.2-0.2
				c-0.4-0.7,0.2-0.9,0.7-1.1c0-0.6-0.7-0.7-0.9-1.2c-0.3-0.2-0.5,0.1-0.7,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
				C35.3,33.3,35.5,34.7,35.8,36z"/>
			<path fill="#C3725E" d="M37,30.3c-0.4-0.4-1-0.8-0.2-1.4c0-0.8-0.5-1.3-0.7-2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0.9-0.3,1.8-0.4,2.6
				c-0.1,0.4,0,0.8,0.4,1c0.1,0.1,0.2,0.2,0.3,0.2C36.5,30.6,36.8,30.4,37,30.3z"/>
			<path fill="#C06C59" d="M49.7,60.5c0.4-0.1,0.9-0.1,0.9-0.7c0-0.2,0-0.3,0.2-0.4c0.2,0,0.5,0.5,0.5-0.1c0-0.4-0.4-0.4-0.7-0.5
				c-0.6-0.2-1.2,0.4-1.7-0.2c-0.4-0.2-0.7-0.1-1,0.3c0.3,0.5,0.8,0.8,1.3,1.2C49.5,60.1,49.8,60.2,49.7,60.5L49.7,60.5z"/>
			<path fill="#C77B62" d="M37,41.8c0.7-0.4,1-1.2,1-1.9c0-1-0.7-0.5-1.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0-0.2,0
				C36.3,40.3,36.6,41,37,41.8z"/>
			<path fill="#CA8168" d="M36.8,23.5c-0.1-0.1-0.2-0.2-0.2-0.2c-0.2,0.6-0.3,1.1-0.5,1.7c0.1,0,0.1,0.1,0,0.2s0,0-0.1,0
				c-0.4,0.4-0.1,1-0.2,1.4l0.1,0.1c0.3,0,0.5,0.1,0.8,0c0.3-0.2,0.7-0.4,0.8-0.8c0-0.1,0.1-0.2,0.1-0.3
				C37.4,24.9,37.2,24.2,36.8,23.5z"/>
			<path fill="#D8D7D9" d="M134.9,243.4c-0.4,0.6-0.1,1.3-0.2,2c-0.1,0.4,0.2,1-0.4,1.2c-0.1,0-0.1,0.2-0.1,0.3
				c1.1,0.4,0.3,1.4,0.6,2.1c0.2,0.9-0.3,1.8,0.2,2.7C134.9,248.8,134.9,246.1,134.9,243.4z"/>
			<path fill="#B85A52" d="M48,58.8c0.3-0.1,0.7-0.2,1-0.3l0,0c0.2-0.5,0.3-0.9-0.4-0.7c-0.4,0.1-0.3-0.4-0.5-0.3
				c-0.8-0.2-0.4,0.5-0.5,0.7C47.7,58.6,47.8,58.7,48,58.8z"/>
			<path fill="#B5534D" d="M38.2,45.4c0.7-0.2,0.7,0.6,1.2,0.7c0.1-0.1,0.2-0.3,0.1-0.5c-0.4-0.8-0.6-1.7-1.8-1.5h-0.1
				C37.7,44.7,37.9,45,38.2,45.4z"/>
			<path fill="#F8F8F8" d="M26.5,115.4c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1-0.2,0.7,0.3,0.6c0.4-0.1,0.3-0.4,0.3-0.7c0-0.2,0-0.5,0-0.7
				C27.1,115,26.5,114.8,26.5,115.4z"/>
			<path fill="#C06C59" d="M45.2,55.7c0.1,0,0.2,0,0.3,0c0.2-0.4,0.5-0.7,0.4-1.2c-0.1-0.2-0.2-0.3-0.4-0.3s-0.3,0-0.5,0
				c0.5,0.5-0.2,0.3-0.2,0.5s0,0.4,0,0.5C44.8,55.4,45,55.6,45.2,55.7z"/>
			<path fill="#CAC9CC" d="M39.9,106.3c0.3,0.1,0.7,0.2,1.1,0.4c0-0.4,0.1-0.8,0.1-1.1C40.5,105.6,40.2,105.8,39.9,106.3z"/>
			<path fill="#C16E61" d="M37.5,19.7c0,0.5,0.5,0.7,0.7,1c0.4,0,0.4-0.2,0.2-0.5c-0.1-0.6-0.5-0.6-0.9-0.7
				C37.5,19.5,37.5,19.6,37.5,19.7z"/>
			<path fill="#E4E3E5" d="M36.3,108c0.9,0.6,1-0.1,1.2-0.7C37,107.3,36.7,107.7,36.3,108z"/>
			<path fill="#F5F4F4" d="M137.1,193c-0.3,0.4-0.6,0.8-1,1.3c0.9-0.4,0.7,0.2,0.7,0.6C137.4,194.3,137,193.6,137.1,193z"/>
			<path fill="#CBCACC" d="M27.9,114.5c0.8-0.1,1.4-0.3,1.4-1.2C29,113.8,28.1,113.8,27.9,114.5z"/>
			<path fill="#D6D5D7" d="M29.6,113.1c0.4,0-0.1,1,0.7,0.7l0,0c0-0.4,0-0.8,0-1.2C30.1,112.7,29.8,112.9,29.6,113.1z"/>
			<path fill="#B4514C" d="M36.1,30.3c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.5-0.2,0.9,0,1.4l0,0c0.1,0,0.2,0,0.3,0
				c0.2-0.3,0.3-0.7,0.6-1C36.9,30.5,36.3,30.4,36.1,30.3z"/>
			<path fill="#BA5F53" d="M46.9,57.4c0.3-0.1,0.8-0.1,0.5-0.5s-0.7-0.2-0.9,0.2C46.6,57.2,46.7,57.3,46.9,57.4z"/>
			<path fill="#E1E1E2" d="M7.3,144.5c0.9,0,0.5-0.6,0.5-1C7.4,143.8,7.3,144.1,7.3,144.5z"/>
			<path fill="#B95D52" d="M52,62.9c0.2-0.1,0.5-0.1,0.3-0.4c-0.2-0.3-0.4-0.3-0.6,0C51.7,62.6,51.8,62.7,52,62.9z"/>
			<path fill="#BC6456" d="M35.6,31.9c0-0.5,0-0.9,0-1.4C35.2,31,35.2,31.5,35.6,31.9z"/>
			<path fill="#C98271" d="M39.9,48.5c0.3-0.6-0.1-0.7-0.5-0.9C39.5,47.9,39.5,48.3,39.9,48.5z"/>
			<path fill="#BB6055" d="M36,25.2c0.1,0.2,0,0.4,0.3,0.4c0.1,0,0.1-0.1,0.1-0.2c0.1-0.3-0.1-0.4-0.4-0.4C36.1,25,36,25.1,36,25.2z
				"/>
			<path fill="#CA7F64" d="M49.7,60.5c0.1-0.2,0.4-0.5,0.1-0.7c0,0-0.4,0.1-0.6,0.2C49.4,60.3,49.5,60.4,49.7,60.5z"/>
			<path fill="#E4DBD9" d="M112.9,250.8c0-0.2,0-0.4,0-0.6c-0.4,0-0.3,0.4-0.5,0.6C112.5,251.2,112.7,251,112.9,250.8z"/>
			<path fill="#B14C4A" d="M63.8,104.9c0.2,2.7,0.4,5.4,0.6,8.1c0.1,1.5,0.7,1.8,2.2,1.5c2.7-0.5,5.1-1.7,7.5-3.1
				c0.4-0.3,0.7-0.4,1.3-0.2c0.5,0.3,1.3,0.5,1.8,0.2c1.5-0.9,2.8-0.1,4.1,0.5c1.7,0.7,3.3,1.6,5.1,1.9c0.4,0.1,0.8,0.2,1.2,0.2
				c1.4,0,1.7-0.3,1.8-1.7c0.2-3.6-0.1-7.1-0.6-10.6c-0.1-1-0.5-1.4-1.6-1.3c-1.9,0.2-3.6,0.9-5.3,1.8c-0.9,0.5-1.8,1-2.5,1.6
				c-1.1,1-2.4,1-3.8,1.2c-0.4,0.1-0.8-0.2-1.1-0.4c-2.8-1.8-5.9-3.1-9.3-3.2c-0.9,0-1.6,0.2-1.5,1.3
				C63.9,103.4,63.9,104.2,63.8,104.9C63.9,104.9,63.8,104.9,63.8,104.9z"/>
			<path fill="#FEFEFE" d="M89.6,232.3c0,1.5,0.9,2.3,3,2.6c0.3,0,1.6-1.9,1.6-2.4c0-1.1-1.2-2.4-2.3-2.5
				C90.5,230,89.6,230.9,89.6,232.3z"/>
			<path fill="#FEFEFE" d="M87.2,202.7c0,1.3,1,2.2,2.3,2.2s2.3-1.1,2.2-2.3c0-1.1-1.3-2.2-2.3-2.2
				C88.2,200.4,87.2,201.5,87.2,202.7z"/>
			<path fill="#FEFEFE" d="M80.9,146c0,1.2,1,2.3,2.3,2.3c1.1,0,2.2-1.1,2.3-2.2c0-1.2-1.1-2.4-2.4-2.4
				C81.9,143.8,81,144.8,80.9,146z"/>
			<path fill="#FEFEFE" d="M86.6,175.7c1.2,0,2.2-1,2.2-2.2c0-1.1-1.2-2.4-2.3-2.4c-1.2,0-2.3,1.1-2.3,2.3
				C84.3,174.7,85.4,175.7,86.6,175.7z"/>
			<path fill="#FEFEFE" d="M78,120.6c-0.2,1.2,1,2.2,2.1,2.2c1.2,0.1,2.2-1,2.2-2.2c0-1.3-1-2.4-2.4-2.3
				C78.8,118.3,78,119.2,78,120.6z"/>
			<path fill="#C9C9CB" d="M127.5,240.3c0,0.1,0,0.2,0,0.4c0,0.3-0.4,0.6,0,0.9c0.1-0.5,0.9-0.1,1-0.7c0-0.9,0.6-1,1.3-0.9
				c-0.3-0.9-1.3-1-2.3-0.3c0,0.1,0,0.1,0,0.2C127.5,239.8,127.9,240,127.5,240.3C127.6,240.2,127.5,240.3,127.5,240.3z"/>
			<path fill="#C7C6C9" d="M126.3,195.6c-0.1,0.2,0,0.7-0.2,0.5c-1.5-0.7-1.2,0.7-1.6,1.2c0.4,0.4,0.8-1.2,1.2,0.1c0,0,0.2,0,0.3,0
				c-0.1-0.8,1.2-0.7,1.1-1.6C126.8,195.7,126.6,195.4,126.3,195.6z"/>
			<path fill="#CFCED0" d="M40.5,109.1c0.4,0.1,0.8,0.2,0.8-0.3c0.1-0.4-0.3-0.5-0.6-0.5c-0.6,0-0.2-0.3-0.2-0.5
				c-0.1-0.3-0.1-0.6-0.5-0.6c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.3,0.1,0.4,0.2,0.5c0.2,0.2,0.2,0.3,0.2,0.6
				C39.8,109.1,39.9,109.5,40.5,109.1z"/>
			<path fill="#CECDCF" d="M34.6,118.1c0.7-0.2,0.9,0.5,1.3,1c0.1-0.6,0-1,0.4-1.3c0.3-0.2,0.3-0.6,0.1-0.8
				c-0.3-0.3-0.6-0.2-0.9,0.1c-0.3,0.2-0.6,0.5-0.9,0.8C34.6,117.9,34.6,118,34.6,118.1L34.6,118.1z"/>
			<path fill="#C9C8CA" d="M126.5,230.4c-1.5,0.6-1.6,1.2-0.5,2.5C126.9,232.2,125.4,231,126.5,230.4z"/>
			<path fill="#CCCCCE" d="M33.9,112.8c0-0.8,1-1.5,0.4-2.4c-0.9,0.6-1.2,2-0.6,2.9C33.9,113.2,33.7,112.9,33.9,112.8z"/>
			<path fill="#D7D7D8" d="M118.4,213.6c0.4-0.3,0.5,0.1,0.7,0.2c0.2-0.2,0.5-0.3,0.7-0.5c0.1-0.3,0.8-0.1,0.6-0.7
				c-0.2-0.4-0.6,0-0.8-0.1c-0.3-0.1-0.7,0.5-1-0.2c-0.1-0.2-0.2,0.4-0.4,0.4C117.9,213.2,118.2,213.4,118.4,213.6z"/>
			<path fill="#F7F6F6" d="M14.7,139.8c-0.4,0.2-0.9,0.4-0.9,0.9c0,0.4,0.5,0.6,0.9,0.6c0.3,0,0.7-0.2,0.5-0.6
				C15,140.4,15.4,139.8,14.7,139.8z"/>
			<path fill="#F8F7F8" d="M105.7,244.7c0.7,0,1.3,0.6,1.6,0c0.1-0.2-0.2-1.1-0.4-1.2C106.2,243.5,106.2,244.3,105.7,244.7z"/>
			<path fill="#C6C6C8" d="M125.5,228.1c0.3,0.2,0.6,0.7,0.9,0.7c0.6,0,0.4-0.7,0.5-1c0.1-0.2-0.1-0.7-0.5-0.6
				C126.1,227.3,125.8,227.6,125.5,228.1z"/>
			<path fill="#DBDADC" d="M123.9,211c0.9,0.2,1.5-0.2,1.5-1.3c-0.6-0.2-1.1-0.1-1.3,0.6C123.9,210.4,123.7,210.6,123.9,211z"/>
			<path fill="#D5D4D6" d="M111.3,229.3c0.2-0.4,0.2-0.7,0-1s-0.5-0.6,0.2-0.4c0.3,0.1,0.3-0.2,0.1-0.2c-0.2-0.1,0.1-0.7-0.4-0.5
				c-0.3,0.1-0.6,0.2-0.8,0.4c-0.3,0.3,0.1,0.5,0.3,0.7C110.9,228.6,111.1,228.9,111.3,229.3z"/>
			<path fill="#F4F4F4" d="M119.7,216.5c-0.1,0.4,0.2,0.5,0.4,0.4c0.4-0.2,1,0,1-0.5c0-0.4-0.5-0.8-0.9-0.8
				C119.6,215.6,120,216.2,119.7,216.5z"/>
			<path fill="#C7C6C9" d="M121.3,220.1c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.6-0.3-1.1-0.5-1.7c-0.3-0.2-0.7-0.2-1,0
				c-0.2,0.3,0.1,0.5,0.3,0.7c0,0.4,0.2,0.7,0.5,1C120.6,219.8,120.7,220.4,121.3,220.1z"/>
			<path fill="#D4D3D5" d="M10.4,145.9c0.2-0.3,0.8-0.2,0.9-0.7c0-0.2-0.1-0.4-0.3-0.4c-0.2-0.1-0.6-0.3-0.5,0.3
				c0.1,0.4-0.4,0.5-0.6,0.5c-0.6,0.2-0.7,0.5-0.6,1.1c0.4,0,0.5-0.6,0.9-0.5C10.3,146.2,10.4,146.1,10.4,145.9z"/>
			<path fill="#F5F4F4" d="M11.8,139.2c-0.2-0.2-0.5-0.2-0.7,0c0,0.3,0.1,0.7-0.4,0.6c-0.3,0-0.5,0.1-0.4,0.4c0,0.2,0.1,0.4,0.2,0.5
				c0.2,0.1,0.4,0,0.5-0.2C11.4,140.2,12,139.9,11.8,139.2z"/>
			<path fill="#F5F4F4" d="M107.1,247.1c-0.3,0-0.8-0.4-0.6,0.3c0,0.2-0.2,0.8,0.2,0.5c0.6-0.5,1.1,1.4,1.3,0
				C108,247.5,107.7,246.9,107.1,247.1z"/>
			<path fill="#CECDCF" d="M120.3,208.6c-1,0.1-1.3,0.8-1.3,1.3c0,0.4,0.7,0.9,1,0C120.2,209.5,119.9,209.1,120.3,208.6z"/>
			<path fill="#EBEAEA" d="M41,112.1c0.3-0.4,0.9-0.3,1-0.9c0-0.3-0.3-0.4-0.5-0.3c-0.4,0.2-1,0.1-1.2,0.6
				C40.2,111.8,40.7,112,41,112.1z"/>
			<path fill="#E6E5E5" d="M120.6,217.9c-0.3,0.7,0.2,1.1,0.5,1.7c0.3,0,0.5,0,0.7,0.2c0.3,0.2,0.5,0.2,0.5-0.2
				C121.5,219.2,121.2,218.4,120.6,217.9z"/>
			<path fill="#D1D0D2" d="M27.7,120c0.5,0.2,0.6-0.2,0.4-0.4c-0.3-0.5-0.3-1,0.1-1.6C27,118.6,27,118.9,27.7,120L27.7,120z"/>
			<path fill="#D8D7D9" d="M100.6,233.1c0,0.6,0.4,0.7,0.7,0.8c0.4,0.1,0.5-0.3,0.5-0.6c0.1-0.7-0.5-0.4-0.8-0.6
				C100.5,232.5,100.7,233,100.6,233.1z"/>
			<path fill="#D3D2D4" d="M123.9,228.7c0.5,0.2,0.8,0.1,0.9-0.5c0.1-0.3,0-0.6-0.4-0.7c-0.7-0.3-0.5,0.4-0.8,0.6
				c-0.1,0.1-0.2,0.2-0.3,0.4C123.5,228.7,123.7,228.7,123.9,228.7z"/>
			<path fill="#C7C6C9" d="M22.1,124.8c-1,0-0.2-0.9-0.6-1.2c-0.3,0-0.3,0.2-0.4,0.4c-0.1,0.4-0.9,0.8-0.5,1.2
				C21,125.5,21.7,125.1,22.1,124.8L22.1,124.8z"/>
			<path fill="#C8C8CA" d="M41.5,130.2c-0.3,0.5-1.2,0.3-1.4,1c0,0,0.3,0.3,0.3,0.2c0.5-0.3,1-0.5,1.5-0.9
				C42,130.5,41.8,130.2,41.5,130.2z"/>
			<path fill="#CFCED0" d="M41.5,108.1c0.7-0.9,1.7-1.2,2.9-1.2C42.9,106.4,42.2,106.7,41.5,108.1z"/>
			<path fill="#CECED0" d="M37,111.9c-0.4-0.1-0.4-0.5-0.6-0.8c-0.6,0.6-0.1,1.3-0.4,1.9c-0.1,0.1,0.5,0,0.8-0.1
				C36.8,112.5,37.1,112.2,37,111.9z"/>
			<path fill="#DAD9DB" d="M125.8,234.7c-0.4,0-0.7,0.1-0.6,0.3c0.4,0.4,0,1.1,0.6,1.3c0.4,0.1,0.5-0.5,0.5-0.6
				C126.3,235.3,126,234.9,125.8,234.7z"/>
			<path fill="#D3D3D4" d="M34.9,134.2c0,0.3,0,0.7,0.3,0.6c0.3-0.2,0.6-0.7,0.7-1.1c0.1-0.2-0.3-0.4-0.5-0.3
				C34.9,133.4,34.8,133.8,34.9,134.2z"/>
			<path fill="#CBCBCD" d="M107.6,240c0.4,0.4,0.7-0.1,1-0.2c0.2-0.5-0.9-0.1-0.4-0.7c-0.3-0.2-0.7-0.3-1-0.5
				c-0.3,0.5,0.8,0.4,0.2,0.9C107.2,239.8,107.5,239.9,107.6,240z"/>
			<path fill="#E2E1E2" d="M125.1,212.9c0.5,0.7,1.4,0.4,1.9,1.1c0,0,0.2,0,0.3-0.1c0.2-0.2,0-0.4-0.2-0.5
				C126.5,213.2,126,212.6,125.1,212.9z"/>
			<path fill="#D8D7D8" d="M10.4,142.6c-0.4-0.6-1.5-0.6-1.7,0c-0.4,0.9,0.5,0.4,0.7,0.5C9.8,142.9,10.2,142.9,10.4,142.6z"/>
			<path fill="#CCCBCD" d="M29.1,116.1c0.6,1.2,1.4,0.4,2.1,0.1C30.5,116.1,29.7,116.3,29.1,116.1z"/>
			<path fill="#E5E4E5" d="M117,210.4c0.5-0.2,1.1-0.4,1.2-1.1c0,0-0.3-0.2-0.5-0.3C117.5,209.6,116.5,209.6,117,210.4z"/>
			<path fill="#D6D5D7" d="M30.3,113.8c0.2,0.4,0.7,0.2,1,0.4c0.3,0.2,0.5,0,0.7-0.3c0.1-0.3-0.1-0.4-0.2-0.6
				c-0.3-0.4-0.4,0-0.5,0.1C30.9,113.6,30.6,113.6,30.3,113.8L30.3,113.8z"/>
			<path fill="#CDCDCF" d="M119.1,221.1c0.6-0.8,0.4-1.5-0.2-2.2c-0.1,0.2-0.2,0.4-0.4,0.6c0,0,0,0.1-0.1,0.1
				C119.1,219.8,118.5,220.8,119.1,221.1L119.1,221.1z"/>
			<path fill="#D7D6D8" d="M129.7,223c-0.3,0.1-0.8-0.2-0.8,0.3c0,0.4,0.4,0.4,0.7,0.3c0.3-0.1,0.8,0,0.8-0.5
				C130.3,222.6,129.9,223.1,129.7,223z"/>
			<path fill="#C7C6C9" d="M124.4,199.5c0.3,0.1,0.8,0.5,0.6-0.3c-0.1-0.4,0.1-0.4,0.4-0.4c0.1-0.3,0.6-0.3,0.5-0.7
				c-0.4,0.3-1.5-0.6-1.2,0.7C124.5,199,124.2,199.1,124.4,199.5z"/>
			<path fill="#EEEDED" d="M11.6,144.2c0,0.3,0.2,0.5,0.4,0.5c0.6,0.1,0.7-0.6,1.1-0.8c-0.1-0.1-0.2-0.3-0.3-0.3
				C12.2,143.7,12,144.1,11.6,144.2z"/>
			<path fill="#DBDADB" d="M103.1,236.9c-0.3,0.4-0.9,0.1-1.1,0.5c-0.1,0.2,0.4,0.7,0.5,0.7c0.5-0.1,0.5-0.8,1-0.9
				C103.3,237.2,103.2,237.1,103.1,236.9z"/>
			<path fill="#D1D0D2" d="M27.7,120c-0.2,0-0.3-0.1-0.5-0.1c-0.4,0-0.8,0.1-0.9,0.5c-0.1,0.3,0.3,0.3,0.5,0.4
				c0.5,0.3,0.6-0.1,0.8-0.4C27.6,120.3,27.7,120.1,27.7,120L27.7,120z"/>
			<path fill="#D9D9DA" d="M131.8,223.7c-0.1,0.8,0.2,1.3,1.2,1.4c-0.3-0.5-0.5-0.9-0.7-1.4C132.2,223.6,132,223.6,131.8,223.7z"/>
			<path fill="#CBCACC" d="M41.7,121.1c0.2-0.1,0.4-0.3,0.2-0.5c-0.3-0.4-0.3-1.1-1-1.1c-0.1,0-0.1,0.2-0.2,0.3
				C41.4,120,41,120.9,41.7,121.1z"/>
			<path fill="#FAFAFA" d="M104,235.4c0.1,0.3,0.3,0.6,0.7,0.7c0.2,0,0.4-0.1,0.3-0.4c-0.1-0.4-0.3-0.8-0.7-0.8
				C104.1,234.9,104,235.2,104,235.4z"/>
			<path fill="#F7F6F6" d="M123.4,212.2c-0.8-0.2-0.7,0.6-1,1c-0.1,0.1,0.1,0.2,0.2,0.2c0.5,0.1,0.6-0.4,0.8-0.7
				C123.6,212.5,123.6,212.3,123.4,212.2z"/>
			<path fill="#CDCDCF" d="M118.4,219.1c-0.9-0.8-1.2-0.2-1.8,0.6c0.7,0.1,1.2-0.9,1.7-0.1l0,0C118.5,219.5,118.5,219.3,118.4,219.1
				z"/>
			<path fill="#E4E3E4" d="M38.6,113.9c0.3-0.8,0.5-1.4,0.7-1.9C38.4,112.1,38.4,112.1,38.6,113.9z"/>
			<path fill="#E7E6E7" d="M121.1,211.1c0.5-0.1,0.5-0.5,0.7-0.8c0.1-0.3-0.1-0.5-0.2-0.8c0,0-0.2,0-0.3,0c-0.3,0.2-0.2,0.4,0,0.7
				C121.4,210.5,120.8,210.7,121.1,211.1z"/>
			<path fill="#EFEFEF" d="M125.3,203.1c-0.3,0-0.5,0.2-0.5,0.4c0,0.4,0.4,0.5,0.7,0.6c0.2,0.1,0.3-0.2,0.3-0.4
				C125.8,203.4,125.6,203.2,125.3,203.1z"/>
			<path fill="#E5E4E5" d="M37,111.9c-0.1,0.3-0.6,0.5-0.2,1c0.6-0.2,0.6-0.2,1.3-0.8C37.7,111.8,37.4,111.9,37,111.9z"/>
			<path fill="#CFCED0" d="M132.7,189.2c-0.4-0.3-0.6,0-0.9-0.1c-0.2,0-0.5-0.3-0.8,0c-0.1,0.1-0.1,0.3,0.1,0.4c0.3,0.1,0.6,0.4,1,0
				C132.2,189.4,132.4,189.3,132.7,189.2z"/>
			<path fill="#E3E2E3" d="M35,114.8c-0.6,0.4-1.1,0.2-1.1,0.8c0,0.3,0.2,0.6,0.6,0.7c0.1,0,0.3,0,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3
				C33.8,115.8,34.8,115.4,35,114.8z"/>
			<path fill="#DBDBDC" d="M33.9,112.8c-0.2,0.1-0.2,0.3-0.2,0.5c0.3,1.2,1,0.3,1.5,0.4C35,113,34,113.6,33.9,112.8z"/>
			<path fill="#E8E7E7" d="M10.2,153.1c0.1-0.1,0.2-0.2,0.3-0.3c0.3-0.3,0.9,0.2,1-0.5c0-0.2-0.1-0.3-0.3-0.2
				C10.8,152.3,9.8,151.7,10.2,153.1z"/>
			<path fill="#D5D4D6" d="M127.8,227.4c-0.9,1.1-0.8,1.2,0.6,1.4C127.8,228.5,127.7,228,127.8,227.4z"/>
			<path fill="#D0CFD1" d="M30.8,120.5c0.4-0.6,1-0.6,1.1-1.3C30.7,119.5,30.7,119.5,30.8,120.5z"/>
			<path fill="#D8D8D9" d="M126.8,220.2c0.1,0.3,0.1,0.8,0.3,1c0.2,0.1,0.5-0.3,0.5-0.5c-0.1-0.3,0.1-0.8-0.4-0.9
				C127,219.7,126.8,219.9,126.8,220.2z"/>
			<path fill="#CDCCCE" d="M45.4,111.4c-0.4,0.7-0.6,1.3,0.4,1.7C45.6,112.5,45.9,111.9,45.4,111.4z"/>
			<path fill="#E0DEDF" d="M123.5,218.5c0.8-0.2,0.1,0.8,0.7,0.9c0.2,0,0.5-0.2,0.4-0.5c-0.1-0.4-0.2-0.8-0.7-0.8
				C123.7,218.2,123.6,218.2,123.5,218.5z"/>
			<path fill="#CECED0" d="M128.3,215.3c0.3-0.3,0.9-0.2,1.1-0.7c-0.3-0.1-1.2,0.3-0.7-0.8c-0.7,0.4-0.7,0.8-0.5,1.2
				C128.2,215.1,128.2,215.2,128.3,215.3C128.2,215.3,128.2,215.3,128.3,215.3z"/>
			<path fill="#D4D3D5" d="M103.3,219c0,0.3,0.3,0.4,0.3,0.3c0.3-0.3,1-0.1,0.9-0.7c0-0.3-0.4-0.3-0.6-0.3
				C103.6,218.4,103.6,218.9,103.3,219z"/>
			<path fill="#E2E1E2" d="M22.5,123.2c0.3-0.5,0.2-1-0.2-1.4c-0.1,0-0.4,0.1-0.4,0.1C21.7,122.5,22.3,122.7,22.5,123.2z"/>
			<path fill="#F2F1F1" d="M121.2,204.4c-0.4,0.6-0.5,1.1-0.2,1.8C121.4,205.6,121.6,205.1,121.2,204.4z"/>
			<path fill="#C9C8CA" d="M114.6,209.5c0.8-0.2,0.3-0.5,0.1-0.8c-0.3-0.4-0.3-0.3-0.9,0.3C113.9,209.4,114.5,209,114.6,209.5
				L114.6,209.5z"/>
			<path fill="#E2E1E2" d="M128.2,194c0-0.2,0.1-0.6-0.3-0.5c-0.4,0.1-0.4,0.4-0.4,0.7c0,0.2,0,0.5,0.3,0.5
				C128.5,194.8,127.9,194.3,128.2,194z"/>
			<path fill="#D5D4D5" d="M124.7,207.9c0,0.2,0,0.4,0.2,0.3c0.6,0,0.4-0.7,0.7-1c0.2-0.2-0.1-0.4-0.3-0.3
				C124.8,207.1,124.9,207.6,124.7,207.9z"/>
			<path fill="#D2D2D3" d="M132.3,207.8c0.2,0,0.3,0,0.5,0c0.2-0.1,0.4-0.2,0.3-0.5c-0.1,0-0.1-0.1-0.2-0.1
				c-0.3-0.1-0.5-0.8-0.9-0.3C131.4,207.6,132.2,207.5,132.3,207.8L132.3,207.8z"/>
			<path fill="#D9D8D9" d="M127.2,206.4c-0.4,0.3-0.7,0.6-1.1,0.9C126.9,207.6,127.3,207.3,127.2,206.4z"/>
			<path fill="#DFDEDF" d="M102.2,248.9c0.4-0.2,0.8-0.5,1.2-0.5c0.3,0,0.3-0.3,0.2-0.6c-0.2-0.3-0.4-0.3-0.5,0
				C102.9,248.3,102.2,248.1,102.2,248.9z"/>
			<path fill="#E4E3E4" d="M115.1,240.8c0.1-0.1,0.1-0.2,0.1-0.3c-0.1-0.4,0.5-0.7,0.1-1.1c-0.2-0.2-0.4,0-0.5,0.2
				C114.6,240.1,114.6,240.5,115.1,240.8z"/>
			<path fill="#D3D2D4" d="M123.9,214.3c0-0.6,0.6-0.8,0.8-1.3c-1.1,0.3-1.4,0.9-1.1,2l0.1-0.1C123.9,214.8,123.9,214.5,123.9,214.3
				z"/>
			<path fill="#EAE9EA" d="M115,203.9c-1.2,0.3-0.5,0.9-0.3,1.4C115.2,204.9,114.7,204.4,115,203.9z"/>
			<path fill="#CECDCF" d="M134.1,206.3c0.8-0.1,1-0.5,1.1-0.9c0.1-0.2-0.2-0.2-0.4-0.2C134.3,205.3,134.5,205.9,134.1,206.3z"/>
			<path fill="#EAE9E9" d="M11.1,142.3c0.3,0,0.6,0,0.9,0c0.2,0,0.5-0.2,0.4-0.5s-0.4-0.2-0.6-0.2c-0.2,0.2-0.4,0.1-0.7,0.1
				C10.6,141.9,10.8,142.1,11.1,142.3z"/>
			<path fill="#CECED0" d="M121.9,237.8c-0.1-0.8-0.9-0.9-1.4-1.4C120.5,237.5,121.5,237.4,121.9,237.8z"/>
			<path fill="#D6D5D6" d="M39.1,114c0.1,0.4,0,1,0.5,1.1c0.2,0,0.5-0.2,0.5-0.4C40,114.1,39.4,114.3,39.1,114z"/>
			<path fill="#CACACC" d="M117.6,244.7c-0.3,0.5-0.6,1-0.9,1.6C117.7,246.1,117.6,245.3,117.6,244.7z"/>
			<path fill="#EEEDED" d="M19.5,146.7c0,0.3,0.2,0.3,0.5,0.2c0.2-0.1,0.7,0.1,0.6-0.3c0-0.2-0.3-0.5-0.6-0.5
				C19.7,146.1,19.5,146.3,19.5,146.7z"/>
			<path fill="#DFDEDF" d="M119.1,243.8c0.2-0.1,0.3-0.2,0.2-0.4c-0.2-0.3,0-0.8-0.6-0.9c-0.2,0-0.3,0.2-0.2,0.3
				C118.9,243,118.4,243.7,119.1,243.8z"/>
			<path fill="#F8F8F8" d="M109.7,231.7c1.1-0.1,0.9-0.8,0.7-1.5C110.2,230.7,110,231.1,109.7,231.7z"/>
			<path fill="#CAC9CC" d="M8.5,154.1c-0.4,0.1-0.8-0.4-1.1,0.1c-0.2,0.3,0.2,0.5,0.4,0.6C8.5,155.1,8.3,154.4,8.5,154.1L8.5,154.1z
				"/>
			<path fill="#D1D0D2" d="M103.7,241.7c-0.3-0.5-0.8-0.5-1.2-0.2c-0.2,0.1-0.4,0.2-0.3,0.4c0,0.1,0.1,0.2,0.2,0.3
				c0.3,0.2,0.4-0.1,0.5-0.3c0.1-0.1,0.3-0.1,0.4-0.2C103.4,241.8,103.6,241.8,103.7,241.7z"/>
			<path fill="#D5D5D6" d="M126.6,180.9c0.4-0.2,0.9,0.3,1.2-0.3c0.1-0.2,0-0.4-0.1-0.4C127.4,180.5,126.7,180.1,126.6,180.9z"/>
			<path fill="#EEEDED" d="M56.4,109.4c0.8,0.1,1.2,0,1.4-0.5c0-0.1-0.1-0.3-0.1-0.3C57.3,108.6,57,109,56.4,109.4z"/>
			<path fill="#C7C6C9" d="M22.2,124.8c1.7,0-0.4-0.8,0.3-1.1C21.9,124,21.9,124.4,22.2,124.8C22.1,124.8,22.2,124.8,22.2,124.8z"/>
			<path fill="#CECED0" d="M133.6,184.4c-0.4,0.2-0.8,0.3-0.8,0.7c0,0.2,0.2,0.5,0.3,0.3c0.3-0.2,0.5-0.5,0.7-0.8
				C133.8,184.6,133.7,184.5,133.6,184.4z"/>
			<path fill="#DDDCDD" d="M7.2,152.9c0.5,0.2,0.9,0.3,1.2-0.2c0-0.1,0-0.3-0.1-0.3C7.7,152.2,7.6,152.8,7.2,152.9z"/>
			<path fill="#F0EFF0" d="M42.8,114.4c0-0.2-0.2-0.4-0.2-0.3c-0.3,0.4-0.9-0.1-1,0.4c-0.1,0.3,0.2,0.3,0.4,0.3
				C42.3,115,42.6,114.8,42.8,114.4z"/>
			<path fill="#DCDBDC" d="M28.7,120.9c0.1,0.6,0.2,1.1,0.3,1.7C29.5,121.9,29.5,121.4,28.7,120.9z"/>
			<path fill="#E4E2E3" d="M115.9,249c-1.2-0.3-1.1,0.2-0.9,1C115.4,249.7,115.6,249.4,115.9,249z"/>
			<path fill="#E1E0E1" d="M105.4,240.9c-0.5-0.6-0.6-1.2-1.2-1.1c-0.3,0-0.7,0.4-0.2,0.5C104.4,240.4,104.7,240.7,105.4,240.9z"/>
			<path fill="#D6D5D6" d="M127.5,215.9c-0.4,0.2-0.9,0.4-0.5,0.9c0.1,0.1,0.3,0.2,0.4,0.1C127.9,216.6,127.5,216.2,127.5,215.9z"/>
			<path fill="#E9E8E9" d="M125.9,204.6c-0.3,0-0.4,0.2-0.3,0.3c0.1,0.4-0.6,0.5-0.3,0.9c0.1,0.1,0.3,0.1,0.4,0
				C126,205.4,126.2,205,125.9,204.6z"/>
			<path fill="#FBFBFB" d="M113.3,248.2c-0.2,0.5-0.4,0.8,0.1,1.1c0.1,0,0.3,0,0.3,0C114,248.8,113.7,248.5,113.3,248.2z"/>
			<path fill="#D4D3D5" d="M131.3,218.9c0.5,0.2,0.8,0.6,1.1,0.1c0.1-0.1,0.1-0.3-0.1-0.4C132,218.3,131.7,218.6,131.3,218.9z"/>
			<path fill="#E4E3E4" d="M14.6,144.7c-0.2,0.8-0.5,1.4,0.6,1.6C15,145.8,14.9,145.4,14.6,144.7z"/>
			<path fill="#CBCBCD" d="M110.6,219.9c0.8,0.1,1-0.3,0.9-0.9C110.9,219,111,219.7,110.6,219.9z"/>
			<path fill="#DAD9DB" d="M121.3,220.1c-0.3-0.2-0.6-0.3-0.9-0.5c0.1,0.4-0.2,0.9,0.2,1.1C120.9,220.8,121.1,220.4,121.3,220.1z"/>
			<path fill="#CDCCCE" d="M121.6,222.8c0.2,1,0.8,0.4,1.3,0.6C122.6,222.5,122.1,223,121.6,222.8z"/>
			<path fill="#D6D5D7" d="M33.5,136.1c-0.2,0-0.3,0-0.4,0.2c0.4,0.1,0.5,0.4,0.6,0.8c0.2-0.1,0.4-0.3,0.5-0.5
				C34.1,136.2,33.8,136.1,33.5,136.1z"/>
			<path fill="#CCCBCD" d="M44.1,109.1c0.2-0.1,0.4-0.3,0.3-0.4c-0.2-0.2-0.1-0.6-0.5-0.6c-0.2,0-0.3,0.2-0.3,0.4
				C43.7,108.8,43.7,109.1,44.1,109.1z"/>
			<path fill="#E4E3E4" d="M37.9,108c0.1,0.4-0.1,0.8,0.4,1.1c0.1,0,0.3-0.2,0.3-0.3C38.6,108.4,38.5,108.1,37.9,108z"/>
			<path fill="#D0CFD2" d="M87.9,240.3c-0.3,0.4-0.1,0.7,0.3,0.9c0.3,0.2,0.4-0.2,0.4-0.4C88.7,240.3,88.3,240.3,87.9,240.3
				L87.9,240.3z"/>
			<path fill="#E3E2E3" d="M19,132.9c0.1,0.4-0.1,0.9,0.4,1.2c0,0,0.2-0.1,0.3-0.2C19.6,133.4,19.4,133.1,19,132.9z"/>
			<path fill="#D3D2D4" d="M41.4,129.3c-0.4-0.2-0.2-0.9-0.9-0.9c-0.1,0-0.1,0.2-0.1,0.3C40.5,129.2,40.9,129.3,41.4,129.3z"/>
			<path fill="#D2D2D3" d="M122.2,209.3c0.8,0.3,1.1-0.1,1.6-0.6C123,208.7,122.6,208.8,122.2,209.3z"/>
			<path fill="#CBCACC" d="M129.3,208.9c-0.7,0.7-0.2,1,0.4,1.7C129.6,209.8,129.1,209.4,129.3,208.9z"/>
			<path fill="#CACACC" d="M11.7,157.3c0.1,0.2,0,0.5,0.3,0.5c0.2,0,0.4-0.2,0.4-0.5c0-0.2-0.1-0.5-0.4-0.5
				C11.8,156.8,11.7,157,11.7,157.3z"/>
			<path fill="#DEDDDE" d="M117.5,206.6c-0.5-0.6-0.9-0.6-1.4-0.9C116.4,206.4,116.4,206.4,117.5,206.6z"/>
			<path fill="#CBCBCD" d="M123.9,211c0.1-0.2,0.2-0.5,0.2-0.7c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0.2-0.3,0.2-0.5,0.2
				C123,210.9,123.2,211.2,123.9,211z"/>
			<path fill="#E7E5E6" d="M104.3,213.2c0.1-0.2,0.3-0.3,0.3-0.4c-0.1-0.3-0.2-0.7-0.6-0.7c-0.1,0-0.3,0.3-0.3,0.3
				C103.8,212.6,104,212.8,104.3,213.2z"/>
			<path fill="#D0CFD1" d="M104.6,245.7c0.3,0,0.3-0.2,0.3-0.3c0-0.3-0.2-0.5-0.5-0.5c-0.2,0-0.4,0.1-0.3,0.3
				C104.2,245.3,104.5,245.5,104.6,245.7z"/>
			<path fill="#DAD9DA" d="M110,226.3c0,0.3,0,0.4,0.2,0.4c0.3,0,0.5-0.1,0.6-0.4c0.1-0.2,0-0.4-0.3-0.4
				C110.4,226,110.1,226.1,110,226.3z"/>
			<path fill="#D2D1D3" d="M99,244.3c0,0.4,0.2,0.5,0.6,0.4c0.2,0,0.3-0.1,0.2-0.3c-0.1-0.2-0.4-0.4-0.6-0.5
				C99,243.9,99,244.2,99,244.3z"/>
			<path fill="#D2D1D3" d="M124.8,190.8c-0.1,0.7,0,1.1,0.4,1.6C125.5,191.6,125.4,191.3,124.8,190.8z"/>
			<path fill="#D6D5D6" d="M43.6,105.2c0.5-0.3,1-0.2,1.4-0.9C44.4,104.4,43.8,104.3,43.6,105.2z"/>
			<path fill="#D9D8DA" d="M127.3,242.2c-0.1,0-0.2,0-0.4,0c-0.3,0-0.9,0-0.7,0.5c0.1,0.4,0.5,0,0.8,0
				C127.5,242.7,127.4,242.4,127.3,242.2z"/>
			<path fill="#EFEEEE" d="M16.9,139.4c0,0.2,0,0.4,0.2,0.4c0.3,0,0.5-0.3,0.6-0.6c0.1-0.2,0-0.3-0.2-0.3
				C17.2,138.9,17,139.2,16.9,139.4z"/>
			<path fill="#CFCED0" d="M129.2,232.9c-0.2-0.9-0.6-1.1-1.3-0.9C128.4,232.3,128.7,232.5,129.2,232.9z"/>
			<path fill="#CECDCF" d="M104.8,203.3c-0.4,0.2-0.7,0.5-0.7,1c0,0.1,0.2,0.2,0.3,0.2C104.9,204.2,104.7,203.6,104.8,203.3z"/>
			<path fill="#F1F1F1" d="M27.4,135.9c0.5,0.2,0.8,0.5,1.2,0.3c0,0,0.1-0.3,0-0.3C28.2,135.5,27.9,135.5,27.4,135.9z"/>
			<path fill="#D8D7D8" d="M110.7,239c0.6-0.1,1-0.2,0.9-0.7c0-0.1-0.2-0.3-0.2-0.3C111,238.2,110.9,238.5,110.7,239z"/>
			<path fill="#D4D4D5" d="M16.8,146.6c0.3,0.4,0.5,0.6,0.8,1C17.8,146.8,17.8,146.8,16.8,146.6z"/>
			<path fill="#CCCCCE" d="M35.1,110.4c-0.3,0.4-0.2,0.8-0.5,1.4c0.8-0.2,0.7-0.8,0.7-1.2C35.3,110.5,35.2,110.4,35.1,110.4z"/>
			<path fill="#EEEDEE" d="M125.3,216.2c0.1,0.2,0.2,0.4,0.5,0.5c0.3,0,0.3-0.3,0.4-0.5s0-0.4-0.3-0.3
				C125.7,216,125.4,215.9,125.3,216.2z"/>
			<path fill="#CCCCCE" d="M129.7,216.5c0.1,0,0.2-0.1,0.2-0.1c0.1-0.3,0.9-0.1,0.6-0.6c-0.2-0.5-0.7-0.1-1,0
				C129.3,216.1,129.5,216.3,129.7,216.5z"/>
			<path fill="#F4F3F3" d="M119.7,239.7c0.2-0.1,0.3-0.2,0.2-0.4c-0.1-0.2,0-0.6-0.4-0.6c-0.2,0-0.4,0.2-0.3,0.4
				C119.2,239.3,119.4,239.5,119.7,239.7z"/>
			<path fill="#D0CFD2" d="M87.9,240.2c0.2-0.4,0.4-0.7,0.2-1.3C87.7,239.4,87.2,239.7,87.9,240.2C87.9,240.3,87.9,240.2,87.9,240.2
				z"/>
			<path fill="#E2E1E2" d="M131.3,213.1c-0.2,0-0.5,0.1-0.5,0.3c0,0.1,0.3,0.3,0.6,0.3c0.2,0.1,0.4-0.1,0.4-0.3
				C131.8,213.2,131.5,213.2,131.3,213.1z"/>
			<path fill="#DFDEDF" d="M23.4,121.3c0,0.3,0.2,0.4,0.5,0.5c0.2,0.1,0.4-0.1,0.4-0.3c0-0.3-0.2-0.4-0.4-0.5
				C23.6,121,23.4,121.1,23.4,121.3z"/>
			<path fill="#CECDCF" d="M34.3,125.5c0,0.3,0.1,0.4,0.4,0.4c0.2,0,0.3,0,0.3-0.2s-0.1-0.5-0.3-0.6
				C34.4,125.1,34.4,125.4,34.3,125.5z"/>
			<path fill="#DFDEDF" d="M115.5,242.4c0.3-0.5,0.3-0.8,0-1c-0.1-0.1-0.3,0-0.3,0C115.1,241.7,115.3,242,115.5,242.4z"/>
			<path fill="#D5D5D6" d="M127.2,202.7c0.9,0.1,1-0.2,0.6-1C127.5,202.1,127.3,202.4,127.2,202.7z"/>
			<path fill="#E9E8E9" d="M97.5,244.3c0.1,0.7,0.4,1,1,1.1C98.3,244.9,98,244.7,97.5,244.3z"/>
			<path fill="#F2F1F1" d="M115,246.2c-0.6,0-1,0.1-0.8,0.9C114.5,246.8,114.7,246.5,115,246.2z"/>
			<path fill="#F1F0F0" d="M122.7,198.6c0.1,0.2,0.2,0.2,0.4,0.1c0.2-0.1,0.5-0.2,0.5-0.6c0-0.2-0.2-0.2-0.4-0.2
				C122.9,198.1,122.7,198.3,122.7,198.6z"/>
			<path fill="#CDCDCF" d="M96,250.6c0.3-0.3,0.8-0.3,0.9-0.8c0-0.1-0.2-0.2-0.2-0.2C96.1,249.8,96.1,250.2,96,250.6z"/>
			<path fill="#D3D2D4" d="M130,200.6c-0.6-0.2-0.9-0.5-1.2-0.1c-0.1,0.1-0.1,0.4,0,0.4C129.2,201.1,129.4,200.8,130,200.6z"/>
			<path fill="#D4D3D5" d="M114.2,202.7c0.4-0.2,0.6-0.6,0.6-1.1C114.2,201.9,114.2,201.9,114.2,202.7z"/>
			<path fill="#CFCED0" d="M131,204.8c0,0.6,0.2,1,0.9,0.5C131.8,204.9,131.2,205.3,131,204.8z"/>
			<path fill="#D2D1D3" d="M113.7,207.3c-0.3,0.2-0.7-0.5-0.9,0c-0.1,0.2,0,0.3,0.1,0.4C113.3,208,113.5,207.6,113.7,207.3z"/>
			<path fill="#E5E5E5" d="M129.7,192.9c0,0.3,0.2,0.5,0.5,0.5c0.2,0,0.3-0.1,0.3-0.2c-0.1-0.2,0.1-0.6-0.4-0.6
				C129.9,192.6,129.7,192.6,129.7,192.9z"/>
			<path fill="#D4D3D5" d="M119.7,212.1c0.7,0,1,0.4,1.4,0.1c0,0-0.1-0.4-0.2-0.5C120.4,211.3,120.3,211.9,119.7,212.1z"/>
			<path fill="#D1D1D2" d="M121.6,214.1c0.2,0.5-0.2,0.9,0.3,1.1c0.1,0,0.2-0.1,0.3-0.1C122.3,214.7,122.2,214.4,121.6,214.1z"/>
			<path fill="#D7D6D8" d="M108.4,215.8c0,0.4,0.1,0.4,0.3,0.4c0.2,0,0.4,0,0.4-0.3c0-0.2-0.2-0.5-0.4-0.5
				C108.6,215.5,108.5,215.7,108.4,215.8z"/>
			<path fill="#E5E4E5" d="M116.6,216.4c0.2,0.3,0.6,0.5,0.8,0.9C117.5,216.7,117.3,216.3,116.6,216.4z"/>
			<path fill="#D1D0D2" d="M14.7,151.2c0-0.1,0-0.2,0-0.4c0-0.3,0.1-0.9-0.4-0.8c-0.5,0.1,0.1,0.6,0,0.9
				C14.4,151.1,14.4,151.3,14.7,151.2z"/>
			<path fill="#CBCBCD" d="M126.4,218.2c-0.9,0.5-0.9,0.5-0.7,1.3C125.9,219.1,126,218.8,126.4,218.2z"/>
			<path fill="#DEDDDF" d="M125.1,220.3c-0.3,0.1-0.6-0.3-0.9-0.1c-0.1,0.1-0.1,0.3,0,0.4c0.4,0.3,0.7,0,1.1-0.1
				C125.4,220.4,125.3,220.2,125.1,220.3z"/>
			<path fill="#CDCDCF" d="M119.1,221c0.1,0.5-0.5,0.2-0.6,0.6c0.3,0,0.6,0.5,0.9,0.1C119.6,221.6,119.8,221.1,119.1,221
				C119.1,221.1,119.1,221,119.1,221z"/>
			<path fill="#E5E4E5" d="M9.6,143.6c0.3,0.3,0.7,0.3,1,0.5c0.5,0.3,0.4-0.2,0.4-0.5c-0.1-0.2-0.3-0.1-0.5-0.2c-0.1,0-0.1,0-0.2,0
				h-0.1C10,143.4,9.8,143.4,9.6,143.6C9.7,143.5,9.7,143.5,9.6,143.6z"/>
			<path fill="#E5E4E5" d="M10.4,142.6c-0.4,0.1-0.9-0.2-1,0.5c0,0.2,0,0.4,0.2,0.5l0,0c0.2-0.3,0.4-0.2,0.7-0.2l0,0
				C10.5,143,10.8,142.8,10.4,142.6z"/>
			<path fill="#D0CFD1" d="M128.5,225.9c0.5-0.2,0.8-0.3,1.3-0.5C129.2,225.3,128.6,224.9,128.5,225.9z"/>
			<path fill="#E0DFE0" d="M125.3,225.7c-0.5,0-0.7,0.3-0.8,0.6c0,0.1,0.1,0.2,0.2,0.2C125.2,226.5,125.2,226.1,125.3,225.7z"/>
			<path fill="#D4D3D5" d="M99.6,229.7c-0.1,0.6-0.9,0.2-1,0.7c0,0.1,0.2,0.2,0.3,0.2C99.4,230.5,99.6,230.2,99.6,229.7z"/>
			<path fill="#E0DFE0" d="M124,232c0.1,0.5,0.5,0.4,0.7,0.5c0.1,0.1,0.4-0.1,0.3-0.2c-0.2-0.2-0.5-0.4-0.7-0.6
				C124.2,231.7,124.1,231.9,124,232z"/>
			<path fill="#D6D5D6" d="M122.2,195c0.4,0.2,0.7,0.4,1.3,0.7C123.2,194.8,122.7,194.9,122.2,195z"/>
			<path fill="#E2E1E2" d="M20.9,139.8c0.3,0,0.3-0.2,0.3-0.4s-0.1-0.4-0.4-0.4c-0.2,0-0.3,0.2-0.3,0.4S20.7,139.8,20.9,139.8z"/>
			<path fill="#DEDDDE" d="M117.8,232.9c-0.2,0.1-0.5,0.2-0.6,0.5c0,0.2,0.1,0.3,0.3,0.3c0.3-0.1,0.5-0.3,0.6-0.5
				C118.1,233,118,232.9,117.8,232.9z"/>
			<path fill="#E6E5E5" d="M117.4,237.4c0.6,0.4,0.6,0.4,1.6,0.1C118.3,237.4,117.8,237.4,117.4,237.4z"/>
			<path fill="#D4D3D5" d="M112.6,238.2c-0.7,0.5-0.8,0.9-0.6,1.8C112.3,239.3,112.4,238.9,112.6,238.2z"/>
			<path fill="#D5D4D6" d="M18.9,126.5c-0.4,0.1-0.9,0.1-1,0.6c0,0.1,0.1,0.2,0.1,0.2C18.6,127.3,18.6,126.7,18.9,126.5z"/>
			<path fill="#E3E2E3" d="M26.2,125.8c0.3,0.3,0.3,0.7,0.4,1.4C27,126.3,27.1,125.9,26.2,125.8z"/>
			<path fill="#CFCED0" d="M22.6,125.5c0,0.5,0,0.9,0.5,1c0.1,0,0.2,0,0.2-0.2C23.3,125.9,23,125.8,22.6,125.5z"/>
			<path fill="#E4E4E4" d="M46.7,107.4c-0.5,0.2-0.8,0.3-0.6,0.8c0,0.1,0.3,0.2,0.4,0.2C46.8,108.2,46.5,107.8,46.7,107.4z"/>
			<path fill="#DADADB" d="M37.7,114.7c0,0.3,0.3,0.2,0.5,0.2s0.4,0,0.4-0.3c0-0.2-0.2-0.5-0.5-0.4
				C37.8,114.3,37.7,114.5,37.7,114.7z"/>
			<path fill="#D0CFD1" d="M46.3,117.9c0.3-0.2,0.8-0.4,0.6-0.9c0-0.1-0.1-0.1-0.2-0.1C46.2,117.1,46.4,117.6,46.3,117.9z"/>
			<path fill="#E6E5E6" d="M8.3,157.7c0,0.2,0,0.4,0.2,0.3c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.1-0.1-0.3-0.3-0.3
				C8.5,157.3,8.3,157.5,8.3,157.7z"/>
			<path fill="#E4E3E4" d="M133.3,177.7c-0.5-0.1-0.9,0-0.9,0.7C132.8,178.4,133,177.9,133.3,177.7z"/>
			<path fill="#E0DFE0" d="M94.2,240.9c-0.2-0.2-0.4-0.4-0.8-0.8c0,0.7,0,1.1,0.5,1.3C94.1,241.3,94.2,241.2,94.2,240.9z"/>
			<path fill="#D1D1D3" d="M115.6,215.7c0.1,0.2,0.1,0.5,0.4,0.5c0.1,0,0.2-0.2,0.3-0.3c0-0.3-0.3-0.4-0.5-0.4
				C115.6,215.3,115.6,215.5,115.6,215.7z"/>
			<path fill="#EBEAEB" d="M130.7,220.7c0.2-0.7,0.3-1-0.1-1.2c-0.1,0-0.2,0.1-0.3,0.1C130,220.1,130.7,220.1,130.7,220.7z"/>
			<path fill="#EEEDED" d="M90.1,241.9c0.5-0.4,0.5-0.7,0.2-1c-0.1-0.1-0.3-0.1-0.3,0C89.8,241.2,90.2,241.5,90.1,241.9z"/>
			<path fill="#D7D7D8" d="M48.4,106.2c0.3,0,0.3-0.2,0.3-0.4c0-0.2,0-0.3-0.2-0.4s-0.4,0.1-0.4,0.3C48.1,106,48.3,106.2,48.4,106.2
				z"/>
			<path fill="#E2E2E2" d="M117.5,235.7c0.1,0.3,0.4,0.2,0.6,0.3s0.3-0.1,0.3-0.3s-0.2-0.4-0.4-0.4
				C117.8,235.4,117.6,235.4,117.5,235.7z"/>
			<path fill="#DAD9DA" d="M134.5,223.1c-0.3-0.4-0.4-0.8-0.8-0.6c-0.1,0-0.3,0.3-0.3,0.3C133.7,223.1,134.1,223,134.5,223.1z"/>
			<path fill="#D8D8D9" d="M131.8,223.7c0.2,0,0.3,0,0.5,0c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.1-0.4-0.1-0.5,0s-0.1,0.3,0,0.4
				C131.4,224,131.6,223.7,131.8,223.7z"/>
			<path fill="#CECDCF" d="M127.2,224.8c0,0.5,0,0.8,0,1.3C127.7,225.7,127.7,225.4,127.2,224.8z"/>
			<path fill="#D8D7D9" d="M103.4,225.9c0.4,0.2,0.7,0,1.2-0.2C103.9,225.2,103.7,225.6,103.4,225.9z"/>
			<path fill="#F5F4F4" d="M119.2,228.2c-0.1,0.6-0.1,0.9,0.2,1c0.1,0,0.3,0,0.3-0.1C119.9,228.7,119.5,228.6,119.2,228.2z"/>
			<path fill="#D2D1D3" d="M16.2,144.2c0.3-0.2,0.4-0.5,0.5-0.9c-0.5,0.2-0.8,0.3-1.2,0.4C15.5,144.1,15.8,144.2,16.2,144.2z"/>
			<path fill="#D2D1D3" d="M46.8,141.4c-0.6,0.4-0.2,0.9-0.4,1.5C47,142.4,46.9,142,46.8,141.4z"/>
			<path fill="#CAC9CB" d="M119.4,227.5c0.1,0.1,0.3,0.4,0.5,0.4c0.2,0.1,0.4-0.2,0.2-0.4c-0.1-0.1,0.1-0.6-0.3-0.4
				C119.7,227.2,119.4,227.1,119.4,227.5z"/>
			<path fill="#EFEEEE" d="M36.9,113.5c0.3,0.4,0.6,0.4,0.9,0.2v-0.3C37.5,113,37.2,113.3,36.9,113.5z"/>
			<path fill="#F0EEEF" d="M88.5,246.8c-0.3,0-0.6-0.1-0.8,0.3c-0.1,0.2,0.1,0.5,0.3,0.4C88.4,247.5,88.4,247.2,88.5,246.8z"/>
			<path fill="#D4D3D4" d="M111.9,246c0.3-0.1,0.8,0.2,0.8-0.3c0-0.1-0.1-0.2-0.2-0.3C112,245.3,112,245.7,111.9,246L111.9,246z"/>
			<path fill="#D2D1D3" d="M44.3,121.7c0.4,0.2,0.6,0.5,1,0.5c0.1,0,0.1-0.2,0.1-0.3C45.1,121.6,44.8,121.4,44.3,121.7z"/>
			<path fill="#D2D1D3" d="M45.8,122c0.4,0.1,0.7,0.2,1-0.1c0,0,0-0.2-0.1-0.3C46.2,121.4,46,121.7,45.8,122z"/>
			<path fill="#DADADB" d="M19.1,125.6c0.4,0.1,0.7,0,1.1-0.3C19.6,124.9,19.3,125,19.1,125.6z"/>
			<path fill="#E0DFE0" d="M104.6,244c-0.3-0.5-0.7-0.3-1.3-0.3C103.8,244.3,104.2,243.7,104.6,244z"/>
			<path fill="#DAD9DA" d="M33.1,120.9c-0.3,0-0.7-0.2-0.8,0.3c0,0.1,0.1,0.4,0.1,0.4C32.9,121.6,32.8,121.1,33.1,120.9z"/>
			<path fill="#D3D2D4" d="M113.5,243.3c-0.6-0.3-1,0.3-1.4,0.2C112.6,243.7,113,243.7,113.5,243.3z"/>
			<path fill="#D5D5D6" d="M24.8,120.5c-0.3,0.4-0.3,0.7,0,1.2C25.1,121.2,25.1,120.9,24.8,120.5z"/>
			<path fill="#D3D3D4" d="M128.6,197.9c0,0.2,0.1,0.4,0.3,0.5c0.3,0,0.2-0.3,0.3-0.4c0.1-0.2,0.1-0.4-0.2-0.4
				C128.8,197.6,128.7,197.7,128.6,197.9z"/>
			<path fill="#CFCED0" d="M46.1,127.1c0,0.5-0.3,0.9,0.1,1.2c0.1,0,0.3,0,0.3,0C46.6,127.9,46.4,127.6,46.1,127.1z"/>
			<path fill="#D5D4D6" d="M39.7,110.5c-0.4-0.3-0.4-0.8-0.9-0.7c-0.1,0-0.2,0.2-0.2,0.2C38.8,110.4,39.1,110.6,39.7,110.5z"/>
			<path fill="#E4E4E4" d="M113.8,214.8c0,0.2,0,0.4,0.3,0.4c0.2,0,0.4-0.2,0.4-0.4c0-0.1-0.1-0.3-0.3-0.4
				C113.9,214.3,113.9,214.6,113.8,214.8z"/>
			<path fill="#F0EFF0" d="M125.3,215.4c0-0.5-0.3-0.6-0.6-0.8c-0.4,0.2-0.1,0.4,0,0.6C124.8,215.4,124.9,215.5,125.3,215.4z"/>
			<path fill="#CBCACC" d="M120.2,197.4c0,0.4,0.3,0.6,0.6,0.7c0.3-0.3,0-0.4-0.1-0.6C120.6,197.4,120.4,197.3,120.2,197.4z"/>
			<path fill="#EAE9EA" d="M125.8,198c-0.1,0.3-0.8,0.1-0.5,0.7c0.1,0,0.1,0,0.2,0c0.3,0.1,0.4-0.1,0.5-0.3
				C126.4,198.1,126.2,198.1,125.8,198z"/>
			<path fill="#D4D3D5" d="M115.2,201.8c0.3,0.3,0.7,0.4,1,0C115.8,201.5,115.5,201.9,115.2,201.8z"/>
			<path fill="#EEEDED" d="M123.6,204.1c-0.2,0-0.3,0.1-0.3,0.3s0.1,0.3,0.4,0.3c0.2,0,0.4-0.1,0.4-0.3
				C123.9,204.2,123.7,204.2,123.6,204.1z"/>
			<path fill="#E9E7E8" d="M10.4,145.9c-0.1,0.1-0.2,0.2-0.3,0.3c0.3,0.4,0.7,0.2,1.1,0.3C11.1,146,10.7,146,10.4,145.9z"/>
			<path fill="#CFCFD1" d="M121.5,199c0,0.2,0.1,0.4,0.3,0.3c0.2,0,0.3-0.2,0.2-0.4c-0.1-0.2-0.1-0.4-0.4-0.4
				C121.5,198.6,121.5,198.8,121.5,199z"/>
			<path fill="#EBEAEA" d="M129.7,216.5c-0.1-0.2-0.1-0.5-0.2-0.7c-0.2-0.7-0.5-0.2-0.7,0c0.1,0.4,0.4,0.6,0.7,0.7H129.7
				L129.7,216.5z"/>
			<path fill="#F0EFF0" d="M119.8,213.4c-0.5-0.3-0.7,0-0.7,0.5C119.5,213.9,120.1,214.3,119.8,213.4z"/>
			<path fill="#D4D3D5" d="M129.4,216.5c-0.2-0.3-0.4-0.5-0.7-0.7C128.5,216.4,128.7,216.6,129.4,216.5z"/>
			<path fill="#CFCED0" d="M102.1,216.3c0,0.2,0.1,0.4,0.4,0.3c0.1-0.1,0.2-0.2,0.2-0.4s-0.2-0.3-0.4-0.3
				C102.1,216,102.1,216.2,102.1,216.3z"/>
			<path fill="#D1D0D2" d="M132.5,170.7c-0.4,0.3-0.3,0.7-0.2,1.3C132.6,171.5,132.8,171.2,132.5,170.7z"/>
			<path fill="#F1F1F1" d="M118.4,213.6c-0.1-0.2-0.2-0.5-0.2-0.7c-0.3-0.1-0.6-0.1-0.8-0.2C117.6,213.1,117.9,213.5,118.4,213.6z"
				/>
			<path fill="#DCDBDC" d="M131.8,186.3c-0.7-0.1-0.8,0.3-0.9,0.8C131.3,186.9,131.6,186.8,131.8,186.3z"/>
			<path fill="#DEDDDE" d="M130.8,188.3c-0.3,0-0.6,0-0.9,0C130.1,188.7,130.4,188.7,130.8,188.3z"/>
			<path fill="#D3D2D3" d="M112.2,211.4c-0.1-0.4-0.1-0.7-0.6-0.6c-0.1,0-0.2,0.2-0.2,0.2C111.4,211.5,111.9,211.3,112.2,211.4
				L112.2,211.4z"/>
			<path fill="#DEDDDF" d="M119.8,218.6c-0.1-0.2-0.2-0.5-0.3-0.7c-0.3,0-0.6,0-0.5,0.4S119.5,218.6,119.8,218.6z"/>
			<path fill="#ECEBEB" d="M114.9,220.1c0.1,0.2,0.2,0.4,0.4,0.3c0.2,0,0.3-0.2,0.3-0.4s-0.2-0.2-0.3-0.2
				C115.1,219.8,114.9,219.9,114.9,220.1z"/>
			<path fill="#D4D3D5" d="M130.8,194.4c-0.4-0.1-0.8,0-1,0.5C130.3,195,130.5,194.6,130.8,194.4z"/>
			<path fill="#DBDADB" d="M12.6,149c0.3,0,0.5-0.1,0.6-0.4c0-0.1-0.1-0.2-0.2-0.2c-0.2,0.1-0.5,0.1-0.5,0.4
				C12.4,148.9,12.6,149,12.6,149z"/>
			<path fill="#CECED0" d="M125.5,236.9c0.5,0.3,0.8,0.4,1.1,0C126.3,236.6,126,236.6,125.5,236.9z"/>
			<path fill="#D9D8D9" d="M112.4,237.2c-0.1-0.6-0.2-1-0.8-0.9C111.7,236.6,111.8,236.9,112.4,237.2z"/>
			<path fill="#E6E5E5" d="M122.9,245c0.5,0.3,0.8,0.3,1.1-0.1C123.7,244.7,123.4,244.8,122.9,245z"/>
			<path fill="#D2D2D3" d="M107.5,237.2c0.1-0.6,0.2-0.9,0.3-1.5C107.3,236.2,107,236.4,107.5,237.2z"/>
			<path fill="#D5D5D6" d="M38.1,135.4c0,0.3,0.2,0.4,0.5,0.4c0.2,0,0.4-0.1,0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.3
				C38.3,135.2,38.1,135.2,38.1,135.4z"/>
			<path fill="#F0EFF0" d="M12.7,135.6c-0.1-0.4-0.2-0.7-0.6-0.8l-0.2,0.2C11.9,135.5,12.2,135.6,12.7,135.6z"/>
			<path fill="#E8E6E7" d="M42.4,111.4c0.1,0.3,0.3,0.3,0.5,0.2c0.1,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.4-0.3
				C42.5,111,42.4,111.1,42.4,111.4z"/>
			<path fill="#EBEBEB" d="M132,232.8c-0.1-0.4-0.3-0.6-0.7-0.6c-0.2,0.3,0,0.4,0.2,0.6C131.6,232.8,131.8,233,132,232.8z"/>
			<path fill="#EEEDED" d="M102.6,245.1c-0.2,0-0.4,0.1-0.4,0.3s0.1,0.3,0.4,0.3c0.2,0,0.3-0.2,0.4-0.4
				C103,245.1,102.8,245.1,102.6,245.1z"/>
			<path fill="#DFDEDF" d="M48.6,108.4c-0.2,0.1-0.3,0.2-0.3,0.4c0,0.2,0.2,0.3,0.4,0.3s0.3-0.2,0.2-0.3
				C48.9,108.7,48.8,108.5,48.6,108.4z"/>
			<path fill="#E9E8E8" d="M118,230.9c0.4,0.3,0.7,0.2,1.1,0C118.7,230.5,118.4,230.7,118,230.9z"/>
			<path fill="#DDDCDD" d="M128.8,246.6c0.2,0.4,0.3,0.6,0.7,0.6c0.1,0,0.2-0.2,0.2-0.2C129.6,246.6,129.3,246.5,128.8,246.6z"/>
			<path fill="#D5D5D6" d="M44.8,110.4c0.2,0,0.3-0.1,0.3-0.2c0-0.2-0.1-0.4-0.3-0.4s-0.3,0.1-0.3,0.3
				C44.5,110.2,44.6,110.3,44.8,110.4z"/>
			<path fill="#D3D2D4" d="M113,242.6c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.4-0.1,0.4-0.3s-0.1-0.3-0.3-0.3
				C113.1,242.2,113,242.4,113,242.6z"/>
			<path fill="#C9C9CB" d="M128.3,241.9c-0.2,0-0.3,0-0.3,0.2s0,0.4,0.2,0.4c0.1,0,0.3-0.1,0.3-0.2
				C128.7,242.1,128.5,242,128.3,241.9z"/>
			<path fill="#E7E6E6" d="M35.4,129.2c0,0.2,0.1,0.4,0.4,0.3c0.1,0,0.2-0.2,0.2-0.3c0-0.2-0.2-0.3-0.4-0.3
				C35.3,128.8,35.4,129,35.4,129.2z"/>
			<path fill="#C8C8CA" d="M11.1,142.3c-0.3-0.8,0.7-0.3,0.7-0.7c-0.3,0-0.5,0-0.8,0c-0.2,0-0.5,0.1-0.5,0.4
				C10.6,142.3,10.9,142.3,11.1,142.3z"/>
			<path fill="#DEDDDE" d="M17.2,142.1c-0.1-0.4-0.1-0.8-0.5-0.8c-0.1,0-0.2,0.1-0.2,0.2C16.5,141.9,16.8,141.9,17.2,142.1z"/>
			<path fill="#EDECEC" d="M123.9,228.7c-0.1-0.2-0.3-0.2-0.5-0.3c-0.3,0.3-0.1,0.7,0.1,0.8C123.9,229.4,123.8,229,123.9,228.7z"/>
			<path fill="#E7E6E7" d="M117.8,241.9c0.1-0.1,0.2-0.2,0.2-0.4s-0.1-0.4-0.3-0.4c-0.2,0-0.2,0.2-0.3,0.3
				C117.4,241.6,117.5,241.8,117.8,241.9z"/>
			<path fill="#D5D5D6" d="M126.5,229.3c-0.2,0-0.3,0-0.3,0.2s0.1,0.4,0.4,0.4c0.1,0,0.3-0.1,0.4-0.2
				C126.9,229.4,126.6,229.4,126.5,229.3z"/>
			<path fill="#FAFAFA" d="M17,129.9c-0.1,0.5-0.4,0.7-0.2,1c0,0,0.2,0,0.3,0C17.5,130.6,17.2,130.4,17,129.9z"/>
			<path fill="#DAD9DA" d="M126.4,247.4c-0.4,0.5-0.4,0.8,0,1.4C126.4,248.2,126.4,247.9,126.4,247.4z"/>
			<path fill="#DEDDDE" d="M118.4,211c-0.2,0-0.3,0.2-0.3,0.3c0,0.2,0.1,0.3,0.3,0.2c0.1-0.1,0.2-0.2,0.3-0.3
				C118.8,211,118.6,211,118.4,211z"/>
			<path fill="#F3F2F2" d="M86.8,243.8c0.5-0.3,0.4-0.6,0.1-1.1C86.6,243.1,86.7,243.4,86.8,243.8z"/>
			<path fill="#DEDDDE" d="M102.4,243.2c-0.2,0.1-0.3,0.2-0.3,0.4c0,0.1,0.2,0.2,0.3,0.3c0.1-0.2,0.2-0.3,0.3-0.5
				C102.8,243.3,102.6,243.2,102.4,243.2z"/>
			<path fill="#ECEBEB" d="M128,211.6c0,0.2,0.2,0.4,0.4,0.3c0.1,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.4-0.3-0.4
				C128.1,211.3,128,211.4,128,211.6z"/>
			<path fill="#D0CFD1" d="M132.7,211.5c-0.2,0.1-0.4,0.2-0.4,0.4s0.1,0.3,0.3,0.3c0.1,0,0.2-0.2,0.3-0.4
				C133,211.7,133,211.6,132.7,211.5z"/>
			<path fill="#E7E7E7" d="M124.4,199.5c0.1-0.2,0.1-0.5,0.2-0.7c-0.4,0-0.7,0.3-0.6,0.7C124,199.7,124.3,199.6,124.4,199.5z"/>
			<path fill="#E0DFE0" d="M118.5,201.4c-0.4,0.4-0.4,0.7-0.1,1C118.7,202.1,118.7,201.9,118.5,201.4z"/>
			<path fill="#E6E6E6" d="M100.3,248.9c-0.2,0-0.4,0.1-0.4,0.3s0.1,0.3,0.3,0.3c0.1,0,0.3-0.2,0.3-0.3
				C100.6,249.1,100.5,249,100.3,248.9z"/>
			<path fill="#D7D7D8" d="M117.9,249.2c-0.2-0.8-0.5-1-1.2-1.1C117.2,248.5,117.4,248.8,117.9,249.2z"/>
			<path fill="#EBEAEB" d="M112.4,202c0.2,0.4,0.4,0.4,0.5,0.3c0.2-0.1,0.4-0.2,0.3-0.4c0-0.2-0.2-0.2-0.4-0.1
				C112.7,201.8,112.6,201.9,112.4,202z"/>
			<path fill="#EFEFEF" d="M130.4,248.8c-0.1-0.7-0.4-0.7-0.8-0.9C129.7,248.4,129.9,248.6,130.4,248.8z"/>
			<path fill="#CFCED0" d="M119,204.3c0.1,0.2,0.2,0.3,0.3,0.3c0.2,0,0.4-0.1,0.4-0.3s-0.1-0.3-0.3-0.2
				C119.3,204.2,119.2,204.3,119,204.3z"/>
			<path fill="#F3F3F2" d="M117.9,206.6c-0.2,0.5,0.1,0.7,0.1,1.1C118.4,207.3,118.2,207,117.9,206.6z"/>
			<path fill="#F6F5F5" d="M122.3,207.3c0,0.2,0.2,0.3,0.3,0.2c0.1-0.1,0.3-0.2,0.3-0.3c0-0.2-0.2-0.2-0.3-0.2
				C122.4,206.9,122.3,207.1,122.3,207.3z"/>
			<path fill="#D7D6D8" d="M111.2,246c0-0.3,0.5-0.4,0.3-0.8c0,0-0.2,0-0.3,0C110.9,245.5,110.9,245.7,111.2,246L111.2,246z"/>
			<path fill="#D1D0D2" d="M132.3,207.9c-0.6,0-0.5,0.5-0.6,0.9C132.3,208.6,132.3,208.2,132.3,207.9
				C132.3,207.8,132.3,207.9,132.3,207.9z"/>
			<path fill="#F1F1F1" d="M127.2,208.9c0.2-0.1,0.4-0.2,0.4-0.4s-0.2-0.2-0.4-0.1c-0.2,0-0.3,0.1-0.3,0.3
				C126.9,208.8,127,208.9,127.2,208.9z"/>
			<path fill="#CDCDCF" d="M130.3,245.1c0,0.1-0.1,0.2-0.1,0.2c0,0.3,0.2,0.4,0.4,0.4c0.1,0,0.2-0.1,0.2-0.2
				C130.7,245.5,130.7,245.2,130.3,245.1z"/>
			<path fill="#CFCFD0" d="M102.1,209.8c0.2,0,0.4,0,0.3-0.3c0-0.1-0.2-0.3-0.3-0.3c-0.3,0-0.3,0.2-0.3,0.4
				C101.7,209.8,102,209.7,102.1,209.8z"/>
			<path fill="#EBEAEA" d="M98.5,231.1c0.2,0.3,0.4,0.5,0.7,0.3c0.1,0,0.2-0.3,0.1-0.3C99.1,230.8,98.8,231.1,98.5,231.1z"/>
			<path fill="#DBDADB" d="M127.5,221.8c-0.2,0.7,0.3,0.7,0.6,1.1C128.1,222.2,127.8,222.1,127.5,221.8z"/>
			<path fill="#E8E7E7" d="M101.5,231.2c0.1-0.4,0-0.7-0.4-0.8l-0.2,0.1C100.9,231,101.2,231.1,101.5,231.2z"/>
			<path fill="#DDDCDE" d="M126.3,223.2c0.1,0.1,0.1,0.3,0.2,0.3c0.2,0.1,0.2-0.2,0.3-0.3c0-0.2,0-0.3-0.2-0.4
				C126.3,222.8,126.4,223,126.3,223.2z"/>
			<path fill="#CDCCCE" d="M86,231.1c0.2-0.3,0.4-0.5,0.6-0.8C86.2,230.5,85.5,230.4,86,231.1L86,231.1z"/>
			<path fill="#D0CFD1" d="M116.5,223.3c-0.3,0-0.6,0.1-0.7,0.4l0.2,0.2C116.4,223.9,116.5,223.6,116.5,223.3z"/>
			<path fill="#DFDEDF" d="M128.6,230.7c0,0.3,0.1,0.4,0.3,0.4s0.3-0.2,0.3-0.3s0.1-0.4-0.2-0.3
				C128.8,230.4,128.7,230.6,128.6,230.7z"/>
			<path fill="#E7E6E7" d="M99.9,230.3c0.4-0.1,0.7-0.1,0.7-0.5c0-0.1-0.1-0.2-0.2-0.2C100.1,229.6,100.1,229.9,99.9,230.3z"/>
			<path fill="#CECDCF" d="M119.7,225.4c0,0.2,0.2,0.4,0.3,0.3c0.1-0.1,0.2-0.3,0.3-0.5c0-0.1-0.1-0.2-0.2-0.2
				C119.8,225,119.7,225.2,119.7,225.4z"/>
			<path fill="#D8D7D9" d="M92.3,225.6c-0.2-0.2,0-0.6-0.5-0.6c-0.1,0-0.2,0.1-0.2,0.2C91.6,225.5,91.9,225.6,92.3,225.6z"/>
			<path fill="#D2D1D3" d="M132.8,228.9c-0.2,0.6,0,0.8,0.4,1.2C133.3,229.6,133.1,229.4,132.8,228.9z"/>
			<path fill="#E8E7E8" d="M108.9,225.5c0,0.3,0.1,0.4,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3
				C109.1,225.2,109,225.4,108.9,225.5z"/>
			<path fill="#DDDCDD" d="M123.2,225.9c-0.3-0.4-0.6-0.4-0.9-0.4C122.4,226,122.7,226.2,123.2,225.9z"/>
			<path fill="#E2E1E1" d="M121.5,227.2c0.2,0.8,0.5,0.9,1.2,1.1C122.3,227.9,122,227.7,121.5,227.2z"/>
			<path fill="#DDDCDD" d="M107.7,227.7c0.2-0.3,0.2-0.6,0-0.9c-0.1,0-0.2,0-0.2,0C107.3,227.1,107.3,227.4,107.7,227.7z"/>
			<path fill="#C9C9CB" d="M127.3,242.2c0,0.2,0,0.4-0.2,0.5c0.1,0.1,0.2,0.4,0.2,0.3c0.4-0.1,0.4-0.5,0.4-0.8
				C127.7,242,127.4,242.1,127.3,242.2z"/>
			<path fill="#D3D2D4" d="M123.7,215.1c-0.1,0.2-0.4,0.5,0,0.7c0.1,0.1,0.4,0.1,0.4,0c0.3-0.3-0.2-0.4-0.2-0.7
				C123.8,215,123.8,215,123.7,215.1L123.7,215.1z"/>
			<path fill="#D1D1D3" d="M91.7,241.3c0-0.2-0.1-0.4-0.3-0.4c-0.2,0-0.3,0.2-0.2,0.3c0.1,0.1,0.2,0.3,0.3,0.4
				C91.7,241.6,91.7,241.5,91.7,241.3z"/>
			<path fill="#E9E9E9" d="M98.1,240.7c0.2,0,0.3-0.1,0.3-0.3s0-0.4-0.2-0.3c-0.1,0-0.3,0.2-0.3,0.3
				C97.8,240.5,97.9,240.7,98.1,240.7z"/>
			<path fill="#E3E2E3" d="M131.8,216.1c0-0.2-0.1-0.3-0.4-0.3c-0.2,0-0.3,0.2-0.2,0.3c0,0.1,0.2,0.3,0.3,0.3
				C131.7,216.4,131.8,216.3,131.8,216.1z"/>
			<path fill="#DAD9DB" d="M107.6,240c-0.1-0.2-0.2-0.3-0.2-0.5c-0.2-0.1-0.5-0.2-0.7-0.2l0,0C106.6,240.1,107.1,240,107.6,240z"/>
			<path fill="#DCDBDC" d="M115.8,218.3c-0.3-0.3-0.6-0.3-1-0.2C115.1,218.6,115.5,218.3,115.8,218.3z"/>
			<path fill="#F0EFEF" d="M119.5,236.1c0.4-0.1,0.6-0.2,0.7-0.5c0,0-0.1-0.2-0.2-0.2C119.6,235.5,119.5,235.7,119.5,236.1z"/>
			<path fill="#D1D0D2" d="M129,220.8c0.2-0.3,0.2-0.6-0.1-0.8c0,0-0.3,0-0.3,0.1C128.4,220.5,128.7,220.7,129,220.8L129,220.8z"/>
			<path fill="#E8E7E8" d="M125.1,220.3c0.1,0.1,0.2,0.1,0.2,0.2c0.2,0,0.5,0.1,0.6-0.2c0.1-0.2-0.1-0.3-0.2-0.3
				C125.4,219.9,125.2,220.1,125.1,220.3z"/>
			<path fill="#DBDADB" d="M106.9,232.8c-0.3-0.6-0.5-0.8-1-0.9C106.1,232.4,106.4,232.5,106.9,232.8z"/>
			<path fill="#D8D8D9" d="M113.9,220.7c0.2,0,0.3-0.2,0.4-0.4c0-0.2-0.1-0.3-0.2-0.3c-0.2,0-0.3,0.2-0.3,0.4
				C113.6,220.6,113.7,220.7,113.9,220.7z"/>
			<path fill="#DEDDDE" d="M131.4,221.8c0,0.2,0.1,0.4,0.3,0.3c0.1-0.1,0.2-0.3,0.3-0.5c0,0-0.1-0.2-0.2-0.2
				C131.6,221.4,131.4,221.5,131.4,221.8z"/>
			<path fill="#DEDDDF" d="M118.8,196.7c-0.4,0.1-0.6,0.3-0.6,0.6c0,0.1,0.2,0.2,0.2,0.1C118.8,197.3,118.9,197.1,118.8,196.7z"/>
			<path fill="#CCCBCD" d="M32.7,126.4c-0.3-0.5-0.7-0.5-1-0.6C31.8,126.3,32.2,126.4,32.7,126.4z"/>
			<path fill="#CFCED0" d="M24.7,126.5c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3
				C24.4,126.3,24.5,126.4,24.7,126.5z"/>
			<path fill="#ECEBEB" d="M35.1,110.4c0.1,0.1,0.2,0.2,0.2,0.2c0.1-0.3,0.7-0.6,0.3-0.8C35.3,109.6,35.2,110.1,35.1,110.4z"/>
			<path fill="#D0CFD1" d="M120.8,195.7c0.4,0,0.7-0.1,0.8-0.5c0-0.1-0.1-0.2-0.1-0.2C121.1,195,121,195.3,120.8,195.7z"/>
			<path fill="#D9D8DA" d="M110.7,195.1c0,0.2,0,0.3,0.2,0.4c0.2,0.1,0.2-0.2,0.3-0.3c0-0.2,0-0.3-0.2-0.4
				C110.8,194.7,110.8,194.9,110.7,195.1z"/>
			<path fill="#E3E2E3" d="M16.5,128.6c0.2,0,0.3-0.1,0.3-0.3s0-0.4-0.2-0.3c-0.1,0-0.3,0.2-0.3,0.3
				C16.2,128.5,16.3,128.6,16.5,128.6z"/>
			<path fill="#DBDADB" d="M41.7,110.5c0.6,0,0.9,0,0.9-0.4c0-0.1-0.1-0.2-0.2-0.3C42.3,110,42.1,110.2,41.7,110.5z"/>
			<path fill="#D1D0D2" d="M23.8,133.7c-0.2,0.5,0,0.7,0.3,0.9C24.3,134.2,24.1,134,23.8,133.7z"/>
			<path fill="#F2F2F2" d="M14.1,135.7c0.1-0.3,0.1-0.6-0.2-0.8c-0.1,0-0.3,0-0.3,0.1C13.7,135.3,13.8,135.6,14.1,135.7z"/>
			<path fill="#F0F0F0" d="M45.1,107.7c0.4,0,0.6-0.1,0.7-0.4c0,0-0.1-0.2-0.2-0.2C45.2,107,45.2,107.3,45.1,107.7z"/>
			<path fill="#E8E8E8" d="M119.4,193.6c0,0.2,0.1,0.4,0.3,0.5c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.2-0.1-0.4-0.2-0.5
				C119.6,193.3,119.5,193.5,119.4,193.6z"/>
			<path fill="#D1D1D2" d="M23.8,138.8c-0.1,0.4,0,0.6,0.3,0.8l0.2-0.1C24.2,139.2,24.1,138.9,23.8,138.8z"/>
			<path fill="#D8D7D8" d="M123.1,187.6c-0.5,0.3-0.4,0.7-0.5,1C123,188.3,123.2,188.1,123.1,187.6z"/>
			<path fill="#D1D1D2" d="M129.6,184.9c-0.1-0.4-0.4-0.5-0.6-0.5c0,0-0.2,0.2-0.1,0.2C129,184.8,129.2,185,129.6,184.9z"/>
			<path fill="#DCDBDC" d="M43.6,112.1c0,0.3,0,0.4,0.2,0.4s0.3-0.1,0.3-0.3c0-0.2,0-0.4-0.2-0.3C43.8,112,43.7,112.1,43.6,112.1z"
				/>
			<path fill="#E8E7E7" d="M41.5,115.8c0.3,0.2,0.6,0.3,0.9,0.3c0-0.1,0.1-0.2,0-0.2C42.2,115.6,41.9,115.5,41.5,115.8z"/>
			<path fill="#E3E2E3" d="M33.6,117.1c-0.4,0-0.6,0-0.8,0.3c0,0,0.1,0.2,0.2,0.2C33.3,117.6,33.5,117.5,33.6,117.1z"/>
			<path fill="#D1D1D2" d="M111.5,197.5c0.3,0.1,0.6,0.1,0.9,0v-0.3C111.9,197,111.7,197.3,111.5,197.5z"/>
			<path fill="#E2E1E2" d="M33.5,123c-0.4,0.4-0.4,0.7,0,1C33.8,123.6,33.7,123.4,33.5,123z"/>
			<path fill="#E7E6E6" d="M25.9,121.2c0.1,0.4,0.2,0.7,0.5,0.6c0.1,0,0.2-0.1,0.2-0.2C26.5,121.4,26.2,121.3,25.9,121.2z"/>
			<path fill="#E0DFE0" d="M34.6,118.1c0-0.1,0-0.2,0-0.2c-0.2,0-0.5-0.1-0.6,0.2c-0.1,0.2,0.1,0.4,0.3,0.4
				C34.6,118.5,34.6,118.3,34.6,118.1z"/>
			<path fill="#EBEAEB" d="M8.6,149.5c-0.2,0-0.3,0.2-0.3,0.3s0.1,0.2,0.2,0.2c0.2-0.1,0.4-0.1,0.3-0.4
				C8.8,149.6,8.6,149.6,8.6,149.5z"/>
			<path fill="#D1D0D2" d="M129,220.8c0,0.3-0.1,0.6,0.4,0.8C129.5,221.2,129.5,220.9,129,220.8L129,220.8z"/>
			<path fill="#D2D2D3" d="M8.3,147.4c-0.1,0.1-0.3,0.2-0.2,0.2c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0,0.3-0.2
				C8.6,147.7,8.5,147.5,8.3,147.4z"/>
			<path fill="#DEDDDF" d="M37.5,117.9c0.2,0.1,0.3,0.3,0.4,0.3c0.2,0,0.3-0.1,0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
				C37.7,117.7,37.6,117.8,37.5,117.9z"/>
			<path fill="#E5E5E5" d="M124.8,224.5c-0.1-0.4-0.4-0.5-0.8-0.5C124.2,224.4,124.4,224.7,124.8,224.5z"/>
			<path fill="#D4D3D5" d="M112.2,225c0.2,0.1,0.3,0.2,0.4,0.3c0.2,0,0.3-0.1,0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.2
				C112.4,224.7,112.3,224.9,112.2,225z"/>
			<path fill="#EAE9EA" d="M16.2,144.2c-0.2-0.2-0.5-0.3-0.7-0.5c-0.5,0.4,0,0.5,0.2,0.7C15.8,144.4,16,144.4,16.2,144.2L16.2,144.2
				z"/>
			<path fill="#E9E8E8" d="M26.5,119.3c-0.1-0.2-0.2-0.3-0.3-0.4c-0.2-0.1-0.3,0-0.3,0.2c0,0.1,0.2,0.2,0.3,0.3
				C26.2,119.5,26.3,119.4,26.5,119.3z"/>
			<path fill="#D3D3D4" d="M111.6,226.5c0.4-0.1,0.5-0.4,0.5-0.8C111.7,225.9,111.4,226.1,111.6,226.5z"/>
			<path fill="#C8C8CA" d="M10.6,143.3c0.1,0.2,0.3,0.2,0.5,0.2c0-0.3,0.5-0.4,0.2-0.6C11,142.6,10.9,143.2,10.6,143.3z"/>
			<path fill="#CAC9CB" d="M118.2,227.5c0,0.2,0.2,0.2,0.3,0.2c0.1,0,0.2-0.2,0.2-0.2c-0.1-0.1-0.2-0.3-0.3-0.3
				C118.4,227.2,118.2,227.3,118.2,227.5z"/>
			<path fill="#CECDCF" d="M67.5,142.6c-0.3-0.3-0.5-0.3-0.7-0.1c0,0-0.1,0.2,0,0.2C66.9,142.8,67.2,142.7,67.5,142.6z"/>
			<path fill="#E5E4E5" d="M14.7,151.2c-0.2-0.1-0.3-0.2-0.5-0.2c0,0.3,0,0.6,0,1C14.7,151.8,14.7,151.5,14.7,151.2z"/>
			<path fill="#C7C6C9" d="M122.2,219.6c-0.1,0.2-0.3,0.2-0.5,0.2c0,0.3,0.2,0.6,0.5,0.4C122.6,220.1,122.5,219.8,122.2,219.6z"/>
			<path fill="#D0CFD1" d="M108.7,219.7c-0.7-0.3-0.9-0.1-1.3,0.4C107.8,219.9,108.1,219.9,108.7,219.7z"/>
			<path fill="#DFDEDF" d="M112.9,219.1c0.2,0.1,0.3,0.2,0.3,0.2c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.2-0.1-0.3-0.3-0.2
				C113.1,218.8,113,219,112.9,219.1z"/>
			<path fill="#DCDBDC" d="M9.3,155.1c0.2-0.1,0.2-0.3,0.1-0.4c0-0.2-0.1-0.3-0.3-0.2c-0.1,0-0.1,0.2-0.1,0.3
				C9,154.9,9.1,155.1,9.3,155.1z"/>
			<path fill="#D1D1D3" d="M6.1,159.1c0,0,0.2,0.1,0.3,0.1c0.2,0.1,0.3-0.1,0.3-0.2c0-0.1-0.2-0.2-0.3-0.3
				C6.2,158.7,6.2,158.8,6.1,159.1z"/>
			<path fill="#DEDDDE" d="M134.5,217.9c-0.1,0-0.3,0.1-0.3,0.2c-0.1,0.2,0.1,0.3,0.2,0.3c0.1,0,0.3-0.1,0.4-0.2
				C134.9,218.1,134.8,218,134.5,217.9z"/>
			<path fill="#F7F7F7" d="M128,175.6c0,0.1,0.1,0.2,0.2,0.2c0.3,0.1,0.3-0.2,0.4-0.3c0-0.2-0.1-0.2-0.2-0.2
				C128.2,175.2,128,175.3,128,175.6z"/>
			<path fill="#DBDBDC" d="M89.6,245.2c0.1,0.2,0,0.4,0.3,0.3c0.1,0,0.2-0.2,0.2-0.4c0-0.1,0-0.3-0.2-0.3
				C89.7,245,89.6,245.2,89.6,245.2z"/>
			<path fill="#EFEEEE" d="M114.1,244.5c0,0.3,0.2,0.3,0.3,0.3c0.1,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.3-0.3-0.3
				C114.3,244.2,114.2,244.4,114.1,244.5z"/>
			<path fill="#EDECEC" d="M51.8,110.3c0.3,0.3,0.6,0.2,0.8,0.1v-0.3C52.3,110,52.1,110.1,51.8,110.3z"/>
			<path fill="#CECDCF" d="M118.5,246.5c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.1,0.3,0,0.3-0.2s-0.1-0.2-0.3-0.2
				C118.8,246.2,118.6,246.2,118.5,246.5z"/>
			<path fill="#CECDCF" d="M44.7,124.2c0.2-0.5,0.1-0.7,0-1C44.4,123.5,44.4,123.7,44.7,124.2z"/>
			<path fill="#D5D4D6" d="M97.3,242.9c0,0.2,0.1,0.4,0.3,0.3c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1-0.1-0.3-0.3-0.2
				C97.4,242.7,97.3,242.9,97.3,242.9z"/>
			<path fill="#D2D2D4" d="M110,248.6c0.5-0.2,0.7-0.4,0.7-0.7c-0.2-0.2-0.3,0-0.4,0.2C110.1,248.2,110.1,248.3,110,248.6z"/>
			<path fill="#EAE9EA" d="M24,130.5c-0.2,0-0.3,0.2-0.3,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.3-0.2,0.4-0.3
				C24.3,130.7,24.2,130.6,24,130.5z"/>
			<path fill="#D4D3D4" d="M127.5,239.5c-0.2,0.1-0.5,0-0.6,0.2c-0.1,0.4,0.4,0.4,0.6,0.6l0,0C127.7,240,127.7,239.8,127.5,239.5
				L127.5,239.5z"/>
			<path fill="#F1EFF0" d="M96.1,248.4c-0.1,0-0.3,0.1-0.3,0.2c-0.1,0.2,0.1,0.3,0.2,0.3s0.3-0.1,0.4-0.2
				C96.5,248.6,96.4,248.5,96.1,248.4z"/>
			<path fill="#FAF9F9" d="M104.8,238.6c0.1,0.2,0.2,0.3,0.3,0.2c0.2,0,0.3-0.2,0.2-0.4c0-0.1-0.2-0.2-0.3-0.2
				C104.9,238.3,104.8,238.4,104.8,238.6z"/>
			<path fill="#D2D1D3" d="M100.9,236.9c0.2,0.1,0.3,0.2,0.3,0.2c0.1-0.1,0.3-0.2,0.3-0.3c0-0.2-0.1-0.3-0.3-0.2
				C101.1,236.6,101,236.7,100.9,236.9z"/>
			<path fill="#D7D6D7" d="M31.1,137.6c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4,0,0.5-0.2s-0.1-0.2-0.2-0.2
				C31.4,137.3,31.3,137.3,31.1,137.6z"/>
			<path fill="#E4E3E3" d="M99.3,234c0,0.4,0,0.8,0.3,0.8s0.2-0.4,0-0.5C99.6,234.2,99.5,234.1,99.3,234z"/>
			<path fill="#D4D4D5" d="M121.2,234.4c0,0.2,0.2,0.3,0.3,0.2c0.2,0,0.2-0.2,0.2-0.4c0-0.1-0.2-0.2-0.2-0.2
				C121.3,234.1,121.2,234.2,121.2,234.4z"/>
			<path fill="#E6E5E6" d="M15.8,137.3c-0.2,0-0.3,0.1-0.3,0.3s0.2,0.3,0.3,0.2c0.1,0,0.2-0.2,0.2-0.2
				C16.1,137.4,16,137.3,15.8,137.3z"/>
			<path fill="#F6F6F6" d="M42.5,137.9c0-0.2-0.1-0.3-0.3-0.2c-0.1,0-0.2,0.2-0.2,0.2c0.1,0.1,0.2,0.3,0.3,0.3
				C42.5,138.2,42.5,138.1,42.5,137.9z"/>
			<path fill="#E6E5E6" d="M20,138.4c0.2,0,0.3-0.1,0.3-0.2c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.2
				C19.7,138.2,19.8,138.4,20,138.4z"/>
			<path fill="#DDDCDD" d="M52.7,105.7c-0.3-0.4-0.6-0.4-0.9-0.2c0,0-0.1,0.2,0,0.2C52.1,105.9,52.3,105.7,52.7,105.7z"/>
			<path fill="#E9E8E8" d="M117.3,230.2c-0.1,0.1-0.3,0.2-0.3,0.2c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0,0.3-0.2
				C117.6,230.5,117.4,230.3,117.3,230.2z"/>
			<path fill="#EBEAEB" d="M105.8,228.2c0.5,0,0.7-0.3,0.8-0.7C106.1,227.5,106,227.8,105.8,228.2z"/>
			<path fill="#D5D4D5" d="M123.5,207.8c0.2,0.5,0.4,0.6,0.7,0.7c0.2-0.3,0-0.4-0.1-0.5C123.9,208,123.8,208,123.5,207.8z"/>
			<path fill="#DBDADB" d="M125.2,186.8c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.2
				C125.3,186.5,125.2,186.6,125.2,186.8z"/>
			<path fill="#D3D3D4" d="M132.4,214.8c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.3-0.3c0-0.1-0.2-0.2-0.4-0.2
				C132.6,214.4,132.5,214.6,132.4,214.8z"/>
			<path fill="#D9D8D9" d="M126.3,195.6c0.2,0.1,0.5,0.2,0.7,0.2c0-0.3-0.1-0.6-0.4-0.6C126.4,195.2,126.4,195.4,126.3,195.6z"/>
			<path fill="#EAE8E9" d="M115.5,205.9c0.3,0,0.3-0.2,0.4-0.4c0-0.2,0-0.4-0.2-0.3c-0.1,0.1-0.2,0.3-0.3,0.5
				C115.3,205.7,115.4,205.8,115.5,205.9z"/>
			<path fill="#D3D2D4" d="M129.1,191.9c0.1-0.3-0.1-0.7-0.3-0.7c-0.4,0,0,0.3,0,0.5C128.7,191.8,128.8,191.9,129.1,191.9z"/>
			<path fill="#D2D2D4" d="M134.1,215.5c0.2-0.4,0-0.6-0.4-0.9C133.7,215,133.7,215.3,134.1,215.5z"/>
			<path fill="#D6D5D7" d="M134.8,197.5c0.1,0,0.1-0.1,0.2-0.2c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.3,0-0.3,0.2
				C134.4,197.4,134.6,197.5,134.8,197.5z"/>
			<path fill="#F2F2F2" d="M120,205.6c-0.3,0.1-0.5,0.4-0.3,0.6c0.3,0.3,0.3-0.1,0.4-0.3C120.1,205.8,120,205.7,120,205.6z"/>
			<path fill="#EDEBEC" d="M134.2,203.7c-0.2-0.1-0.2,0.1-0.2,0.2c0,0.2,0,0.4,0.2,0.5c0.1,0,0.2-0.1,0.2-0.2
				C134.5,204,134.4,203.8,134.2,203.7z"/>
			<path fill="#DEDDDE" d="M115.6,210.2c0,0.2,0.2,0.2,0.3,0.3c0.1,0,0.3,0,0.4-0.1c0-0.1-0.2-0.2-0.3-0.3
				C115.8,210,115.7,210.1,115.6,210.2z"/>
			<path fill="#E1E0E1" d="M120.3,208.2c0.2,0,0.2-0.2,0.3-0.3c0-0.1,0-0.3-0.1-0.4c-0.2,0-0.2,0.2-0.3,0.3
				C120.1,208,120.1,208.2,120.3,208.2z"/>
			<path fill="#D6D5D7" d="M109.5,213.6c-0.5,0.2-0.9,0.2-0.8,0.5c0.1,0.4,0.3,0.1,0.5-0.1C109.3,214,109.3,213.9,109.5,213.6z"/>
			<path fill="#E8E7E8" d="M133,207.4c-0.1,0.2-0.2,0.3-0.3,0.5c0.5,0.3,0.6-0.1,0.7-0.4C133.4,207.1,133.2,207.4,133,207.4z"/>
			<path fill="#D6D5D6" d="M23.4,135.9c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.1-0.3-0.1
				C23.6,135.7,23.5,135.8,23.4,135.9z"/>
			<path fill="#D4D3D5" d="M114.3,236.7c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0,0.2-0.2c0-0.1-0.1-0.3-0.2-0.4
				C114.6,236.5,114.4,236.6,114.3,236.7z"/>
			<path fill="#D8D8D9" d="M121.9,194.1c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3
				C121.7,194.3,121.8,194.2,121.9,194.1z"/>
			<path fill="#E1E0E1" d="M26.3,132.8c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0-0.2-0.1-0.3-0.1
				S26.3,132.5,26.3,132.8z"/>
			<path fill="#DDDCDD" d="M126.7,194.2c-0.1,0.1-0.3,0.1-0.3,0.2c-0.1,0.2,0.1,0.3,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2
				C126.9,194.3,126.8,194.3,126.7,194.2z"/>
			<path fill="#EAE8E9" d="M109.9,240.2c0.1-0.1,0.2-0.2,0.2-0.3c0,0-0.1-0.2-0.2-0.2c-0.1,0.1-0.2,0.2-0.3,0.3
				C109.5,240.1,109.6,240.2,109.9,240.2z"/>
			<path fill="#D2D1D3" d="M127.7,207c0.1,0.3,0.2,0.4,0.2,0.5c0.1-0.1,0.2-0.2,0.3-0.3s0.1-0.3-0.1-0.3
				C127.9,206.8,127.8,207,127.7,207z"/>
			<path fill="#EDECEC" d="M101.3,240.4c0.1,0,0.2,0.2,0.2,0.1c0.2,0,0.3-0.2,0.3-0.3s-0.2-0.2-0.2-0.2
				C101.4,240.1,101.3,240.2,101.3,240.4z"/>
			<path fill="#E5E5E5" d="M99.7,240.2c-0.5,0.1-0.7,0.1-1,0.2C99.1,240.9,99.3,240.7,99.7,240.2z"/>
			<path fill="#EBE9EA" d="M47.3,104.3c-0.3-0.1-0.4-0.2-0.5-0.2s-0.3,0-0.4,0.1c-0.1,0.2,0,0.3,0.2,0.3
				C46.7,104.4,46.8,104.4,47.3,104.3z"/>
			<path fill="#E9E8E9" d="M45.5,105c0,0.2,0.1,0.3,0.2,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0-0.2-0.2-0.2
				C45.7,104.8,45.6,104.9,45.5,105z"/>
			<path fill="#D3D2D4" d="M70.5,140.2c0.1-0.5,0.1-0.7,0.1-0.8s-0.1-0.3-0.2-0.2c-0.2,0.1-0.2,0.2-0.2,0.4
				C70.3,139.7,70.3,139.8,70.5,140.2z"/>
			<path fill="#D6D5D7" d="M119.7,191.7c-0.1,0.3-0.1,0.5,0.1,0.7h0.2C120.1,192.1,119.9,191.9,119.7,191.7z"/>
			<path fill="#D2D1D2" d="M116.8,232.2c0.1,0.2,0.2,0.3,0.4,0.2c0.1,0,0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.3-0.2
				C116.9,232,116.9,232.2,116.8,232.2z"/>
			<path fill="#DEDDDE" d="M18.6,138c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.1,0.2-0.1,0.3
				C18.4,137.8,18.5,137.8,18.6,138z"/>
			<path fill="#D7D7D8" d="M129.4,233.5c-0.4,0-0.6,0.2-0.5,0.5C129.2,234.1,129.2,233.8,129.4,233.5z"/>
			<path fill="#D3D2D4" d="M116.1,208.4c-0.1,0.1-0.2,0.2-0.3,0.3l0.1,0.2c0.1-0.1,0.2-0.2,0.3-0.3
				C116.4,208.5,116.3,208.4,116.1,208.4z"/>
			<path fill="#E3E2E3" d="M129.1,234.6c-0.4,0.1-0.5,0.4-0.7,0.8C128.9,235.2,129.1,235,129.1,234.6z"/>
			<path fill="#D6D5D7" d="M102.1,208c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.2-0.1,0.3-0.1c0-0.2-0.1-0.2-0.3-0.3
				C102.3,207.8,102.2,207.7,102.1,208z"/>
			<path fill="#DFDEDF" d="M12.8,137.3c0.1,0,0.2,0,0.2,0c0.4-0.4,0.3-0.6-0.2-0.7C12.8,136.8,12.8,137.1,12.8,137.3L12.8,137.3z"/>
			<path fill="#EFEEEE" d="M17.8,136.6c0.1,0.1,0.1,0.3,0.2,0.3c0.2,0.1,0.3-0.1,0.2-0.3c0-0.1-0.1-0.2-0.2-0.2
				C18,136.4,17.9,136.6,17.8,136.6z"/>
			<path fill="#D5D4D6" d="M134.1,208.4c0.3-0.2,0.4-0.4,0.3-0.6c0,0-0.1-0.1-0.2-0.1C133.9,207.9,134,208.1,134.1,208.4z"/>
			<path fill="#DAD8DA" d="M41.2,123.2c-0.3-0.1-0.6-0.2-0.6,0.1c-0.1,0.3,0.2,0.2,0.4,0.2C41,123.4,41.1,123.3,41.2,123.2z"/>
			<path fill="#D5D4D5" d="M99.8,246.2c0.1,0.2,0.2,0.3,0.4,0.2c0.1,0,0.1-0.2,0.1-0.3c0-0.2-0.1-0.3-0.3-0.2
				C99.9,246,99.8,246.1,99.8,246.2z"/>
			<path fill="#D7D6D8" d="M111.9,246c-0.2,0-0.5,0-0.7,0l0,0c0,0.3,0.1,0.5,0.4,0.4C111.9,246.4,111.9,246.2,111.9,246L111.9,246z"
				/>
			<path fill="#F7F6F7" d="M119,247.6c0.3,0.2,0.4,0.4,0.7,0.2c0,0,0.1-0.2,0-0.2C119.5,247.4,119.3,247.4,119,247.6z"/>
			<path fill="#DBDADB" d="M34.6,121.6c0.1,0,0.2-0.1,0.2-0.2c0.1-0.2-0.1-0.3-0.2-0.3c-0.1,0-0.2,0.1-0.3,0.1
				C34.2,121.5,34.4,121.5,34.6,121.6z"/>
			<path fill="#DBDBDB" d="M37.4,121c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.2-0.2,0.2-0.3c0-0.1-0.1-0.1-0.2-0.2
				C37.6,120.7,37.6,120.8,37.4,121z"/>
			<path fill="#DEDDDF" d="M127.7,248.4c-0.2,0.3,0,0.7,0.3,0.6c0.3-0.1,0-0.4-0.1-0.6C127.9,248.3,127.8,248.3,127.7,248.4z"/>
			<path fill="#D1D0D2" d="M105.2,201.6c0,0.2,0.1,0.3,0.2,0.3c0.1-0.1,0.2-0.2,0.2-0.3s0-0.3-0.2-0.2
				C105.3,201.4,105.3,201.6,105.2,201.6z"/>
			<path fill="#D6D5D7" d="M112.6,198.8c0-0.1,0.2-0.2,0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3l-0.2,0.2
				C112.2,198.7,112.4,198.8,112.6,198.8z"/>
			<path fill="#E2E1E2" d="M105.4,249.5c0,0.1-0.1,0.1-0.1,0.2c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.1-0.2,0.1-0.3
				C105.6,249.6,105.6,249.5,105.4,249.5z"/>
			<path fill="#DADADC" d="M116.6,249.7c-0.1,0.1-0.1,0.2-0.2,0.4c0,0.1,0.1,0.2,0.2,0.2c0.1-0.1,0.2-0.2,0.3-0.3
				C116.9,249.8,116.8,249.7,116.6,249.7z"/>
			<path fill="#E1E0E1" d="M120.9,200.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
				C121,200,120.9,200.1,120.9,200.3z"/>
			<path fill="#D0CFD1" d="M109.9,199.9c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.3-0.2-0.4c-0.1,0.1-0.2,0.1-0.2,0.2
				C109.5,199.7,109.6,199.9,109.9,199.9z"/>
			<path fill="#C7C6C9" d="M126.1,198.5c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.2-0.4,0.5,0,0.7c0.2-0.2,0.4-0.2,0.5-0.5
				C126.1,198.8,126.1,198.7,126.1,198.5z"/>
			<path fill="#E2E1E2" d="M43.6,130.3c0.1-0.1,0.2-0.2,0.3-0.3s0-0.2-0.2-0.2c-0.1,0.1-0.3,0.1-0.3,0.3
				C43.3,130.2,43.4,130.3,43.6,130.3z"/>
			<path fill="#FCFCFC" d="M94.2,240.9c-0.2,0.1-0.3,0.3-0.3,0.5c0.2,0,0.5,0.2,0.6,0C94.7,241.2,94.4,241.1,94.2,240.9z"/>
			<path fill="#DFDEDF" d="M17.7,129.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.2-0.2-0.2s-0.2,0.2-0.3,0.3
				C17.5,129,17.6,129.2,17.7,129.2z"/>
			<path fill="#D5D5D6" d="M21.6,128.5c-0.2-0.1-0.4-0.2-0.5-0.2s-0.2,0.1-0.2,0.2c0,0.2,0.1,0.3,0.3,0.3
				C21.2,128.8,21.3,128.7,21.6,128.5z"/>
			<path fill="#E1E0E1" d="M108.4,242.3c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.3-0.3-0.3
				C108.5,242,108.4,242.1,108.4,242.3z"/>
			<path fill="#D7D6D7" d="M38.7,127.2c-0.1,0.2-0.2,0.3-0.3,0.4s0,0.3,0.1,0.2c0.1,0,0.2-0.2,0.3-0.3
				C38.9,127.5,38.8,127.4,38.7,127.2z"/>
			<path fill="#E0DFE0" d="M48.1,111.5c0.1,0,0.2-0.1,0.2-0.1c0.1-0.2,0-0.3-0.2-0.3c-0.1,0-0.2,0.1-0.3,0.1
				C47.7,111.3,47.9,111.4,48.1,111.5z"/>
			<path fill="#D5D4D6" d="M29.4,124.4c0.1,0.1,0.1,0.3,0.2,0.3c0.2,0.1,0.3-0.1,0.3-0.2s-0.1-0.2-0.2-0.2
				C29.6,124.2,29.5,124.3,29.4,124.4z"/>
			<path fill="#DDDCDD" d="M28.2,124c0,0.2,0.2,0.3,0.3,0.3c0.1,0,0.3,0,0.2-0.1c0-0.1-0.2-0.2-0.3-0.3
				C28.4,123.9,28.3,124,28.2,124z"/>
			<path fill="#DBDADB" d="M23.8,123.5c0.1,0,0.2,0.1,0.3,0.2c0.1-0.1,0.2-0.2,0.2-0.2c-0.1-0.1-0.2-0.3-0.3-0.3
				C23.8,123.2,23.7,123.3,23.8,123.5z"/>
			<path fill="#F0F0F0" d="M123.2,217c-0.2,0-0.3,0.2-0.3,0.3s0.2,0.1,0.2,0.1c0.1,0,0.3-0.1,0.2-0.3
				C123.4,217.1,123.3,217.1,123.2,217z"/>
			<path fill="#ECEBEB" d="M123.7,215c0.1,0,0.2,0,0.2,0c0.3-0.2,0.3-0.5,0-0.7C123.6,214.5,123.7,214.8,123.7,215z"/>
			<path fill="#CBCBCD" d="M126.7,218.5c0.3,0,0.4-0.1,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.2
				C126.6,218.3,126.6,218.5,126.7,218.5z"/>
			<path fill="#DCDBDC" d="M19.3,155.6c0.1,0.1,0.2,0.1,0.3,0.2c0-0.1,0.1-0.2,0.1-0.3s-0.2-0.3-0.3-0.3
				C19.3,155.2,19.3,155.4,19.3,155.6z"/>
			<path fill="#F5F5F5" d="M110.1,218.1c0,0.2,0.1,0.4,0.3,0.4c0.1,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.3-0.3-0.3
				C110.2,218,110.1,218.1,110.1,218.1z"/>
			<path fill="#E0DFE0" d="M17.8,145.4c-0.2-0.1-0.3-0.2-0.4-0.3s-0.3,0-0.2,0.1c0,0.1,0.2,0.2,0.3,0.3
				C17.6,145.6,17.7,145.5,17.8,145.4z"/>
			<path fill="#E7E6E7" d="M117.2,224c-0.1,0.1-0.1,0.2-0.2,0.4c0,0,0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.2,0.2-0.4
				C117.5,224,117.4,224,117.2,224z"/>
			<path fill="#E8E7E8" d="M113.9,218.4c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.1-0.3-0.1
				C114.1,218.2,114,218.3,113.9,218.4z"/>
			<path fill="#CFCFD1" d="M112.2,211.5c0,0.4,0.2,0.6,0.6,0.5C112.8,211.5,112.5,211.5,112.2,211.5
				C112.2,211.4,112.2,211.5,112.2,211.5z"/>
			<path fill="#E7E6E6" d="M7.7,146.5c0.2,0,0.3-0.1,0.3-0.2c0-0.1,0-0.3-0.1-0.3c-0.2-0.1-0.3,0.1-0.3,0.2
				C7.5,146.4,7.6,146.5,7.7,146.5z"/>
			<path fill="#D9D8DA" d="M130.7,185.8c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.2-0.3c0-0.1-0.2-0.1-0.3-0.1
				C130.8,185.6,130.8,185.7,130.7,185.8z"/>
			<path fill="#DBDADB" d="M129.6,185.7c-0.1,0-0.4-0.1-0.3,0.2c0,0.1,0.2,0.2,0.3,0.3c0,0,0.2-0.1,0.3-0.2
				C129.9,185.7,129.8,185.7,129.6,185.7z"/>
			<path fill="#DBDADB" d="M15.9,146.8c-0.1,0.2,0.1,0.3,0.2,0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.3-0.2-0.2
				C16.1,146.6,15.9,146.8,15.9,146.8z"/>
			<path fill="#E0DFE0" d="M12,146.7c-0.4,0.1-0.6,0.3-0.7,0.6C11.6,147.3,11.8,147.1,12,146.7z"/>
			<path fill="#D6D5D7" d="M10.8,149c0.1-0.1,0.2-0.2,0.2-0.3s-0.1-0.3-0.2-0.2c-0.1,0.1-0.2,0.2-0.3,0.3
				C10.5,148.9,10.6,149,10.8,149z"/>
			<path fill="#D3D2D4" d="M123.4,212.2c0,0.2,0,0.3,0,0.5c0.2,0,0.5,0.3,0.6,0C124.1,212.3,123.7,212.3,123.4,212.2z"/>
			<path fill="#D7D6D8" d="M130.2,212.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.2,0.2-0.2,0.3s0.2,0.2,0.2,0.2
				C130,212.7,130.1,212.6,130.2,212.4z"/>
			<path fill="#DBDADB" d="M11.3,150.7c0.1-0.1,0.2-0.2,0.2-0.3s0-0.3-0.1-0.3c-0.2-0.1-0.3,0.1-0.3,0.2
				C11.2,150.5,11.3,150.5,11.3,150.7z"/>
			<path fill="#DDDDDE" d="M18.5,150.7c0.1-0.1,0.2-0.2,0.2-0.3c0-0.2-0.2-0.2-0.3-0.2c-0.1,0-0.1,0.2-0.2,0.3
				C18.3,150.6,18.4,150.7,18.5,150.7z"/>
			<path fill="#EBE9E9" d="M102.2,215.1c0,0.2,0,0.3,0.2,0.3c0.1-0.1,0.3-0.1,0.4-0.3c0.1-0.1-0.1-0.2-0.2-0.2
				C102.4,214.9,102.3,215,102.2,215.1z"/>
			<path fill="#E7E6E6" d="M128.3,215.3c0-0.1,0-0.2,0-0.3c-0.3,0-0.5,0.1-0.3,0.5c0.1,0.1,0.1,0.2,0.2,0.3
				C128.2,215.6,128.2,215.5,128.3,215.3z"/>
			<path fill="#D2D2D4" d="M124.7,180.9c0.1,0.1,0.2,0.2,0.3,0.2l0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
				C124.8,180.6,124.7,180.6,124.7,180.9z"/>
			<path fill="#D3D2D4" d="M129,181c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.3-0.2-0.4
				C129,180.6,129,180.8,129,181z"/>
			<path fill="#C9C8CA" d="M114.6,209.5c-0.3,0.2-0.6,0.4-0.8,0.9C114.3,210.2,114.5,209.9,114.6,209.5L114.6,209.5z"/>
			<path fill="#D5D4D6" d="M133.4,180.6c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2-0.1,0.2-0.2c-0.1-0.1-0.2-0.3-0.3-0.3
				C133.4,180.3,133.3,180.4,133.4,180.6z"/>
			<path fill="#E2E1E3" d="M135,182.9c0.2,0,0.4-0.1,0.3-0.3c0-0.1-0.2-0.2-0.2-0.1c-0.2,0-0.4,0.1-0.4,0.3
				C134.7,182.9,134.9,182.9,135,182.9z"/>
			<path fill="#D2D2D3" d="M135,210.4c0.1,0.3,0.4,0.4,0.7,0.5C135.7,210.4,135.4,210.4,135,210.4z"/>
			<path fill="#E4E3E4" d="M131.6,227.5c0.1-0.1,0.2-0.3,0.2-0.3c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0.1-0.2,0.2
				C131.3,227.3,131.4,227.4,131.6,227.5z"/>
			<path fill="#D4D4D5" d="M105.8,219.7c0.1,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2
				C105.9,219.5,105.9,219.6,105.8,219.7z"/>
			<path fill="#D6D6D7" d="M99.2,243.4c0.1,0.1,0.2,0.2,0.3,0.1c0.1,0,0.1-0.1,0.2-0.2c-0.1,0-0.2-0.1-0.2-0.1
				C99.4,243.2,99.3,243.3,99.2,243.4z"/>
			<path fill="#EFEDED" d="M40.6,126.6c0.1,0.1,0.2,0.2,0.2,0.2c0.1-0.1,0.2-0.1,0.2-0.2s-0.1-0.2-0.1-0.3
				C40.9,126.5,40.8,126.5,40.6,126.6z"/>
			<path fill="#D7D7D9" d="M37.3,126.8c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.2-0.1
				C37.4,126.5,37.4,126.6,37.3,126.8z"/>
			<path fill="#D6D5D6" d="M132.1,243.2c-0.1,0.1-0.3,0.1-0.3,0.2c-0.1,0.1,0,0.2,0.1,0.2s0.2-0.1,0.3-0.1
				C132.2,243.4,132.1,243.3,132.1,243.2z"/>
			<path fill="#DCDBDC" d="M117.9,204.9c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0,0.2-0.2c0-0.1-0.1-0.2-0.2-0.3
				C118,204.6,118,204.7,117.9,204.9z"/>
			<path fill="#E7E5E6" d="M129.4,204.8c-0.2,0-0.3,0-0.2,0.2c0,0.1,0.2,0.2,0.3,0.2s0.2-0.1,0.2-0.2
				C129.5,204.9,129.4,204.8,129.4,204.8z"/>
			<path fill="#E9E7E8" d="M101,242.6c0,0.2,0,0.3,0.2,0.3c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2-0.1-0.2-0.2-0.2
				C101.1,242.4,101.1,242.5,101,242.6z"/>
			<path fill="#D9D8D9" d="M112.2,247.3c-0.2,0-0.3,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2-0.1,0.2-0.2
				C112.3,247.5,112.3,247.4,112.2,247.3z"/>
			<path fill="#DFDEDF" d="M22.3,127.4c0.2,0,0.3-0.1,0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0.2-0.1,0.2
				S22.2,127.4,22.3,127.4z"/>
			<path fill="#DFDDDE" d="M131.2,242.6c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
				C130.9,242.5,131,242.6,131.2,242.6z"/>
			<path fill="#CBCACC" d="M28.4,115c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
				C28.7,114.8,28.6,114.9,28.4,115z"/>
			<path fill="#E8E6E7" d="M115.8,199.6c0.1,0.1,0.2,0.2,0.2,0.2c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.2-0.1-0.2-0.2-0.2
				C116,199.3,115.9,199.5,115.8,199.6z"/>
			<path fill="#DCDBDC" d="M126.1,199c-0.2,0.2-0.3,0.3-0.5,0.5C125.8,199.4,126.4,199.6,126.1,199z"/>
			<path fill="#DCDBDC" d="M127.7,248.4c0.1,0,0.2,0,0.3,0c0.1-0.2,0.2-0.4,0.4-0.6C127.9,247.8,127.9,248.2,127.7,248.4z"/>
			<path fill="#EDECED" d="M103.7,241.7c-0.2,0-0.3,0-0.5,0c0,0.2,0,0.5,0.3,0.5C103.9,242.2,103.7,241.9,103.7,241.7z"/>
			<path fill="#D2D2D4" d="M103,246.2c-0.1,0.2,0,0.2,0.2,0.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0-0.2-0.2-0.2
				C103.2,246.1,103.1,246.2,103,246.2z"/>
			<path fill="#CCCBCE" d="M124.7,246.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1,0.1,0.2,0.2,0.1c0.1-0.1,0.2-0.2,0.3-0.3
				C124.9,246.2,124.8,246.1,124.7,246.1z"/>
			<path fill="#D0D0D1" d="M131.7,246.1c0,0.2,0,0.3,0.2,0.3c0.1,0,0.2-0.1,0.2-0.2c0,0-0.1-0.1-0.1-0.2
				C131.8,246,131.8,246.1,131.7,246.1z"/>
			<path fill="#EEEDED" d="M11.4,155.3c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.1c0.1,0,0.2-0.1,0.2-0.2
				C11.6,155.4,11.5,155.3,11.4,155.3z"/>
			<path fill="#F2F1F1" d="M128.7,218.3c0.1,0.2,0.2,0.2,0.3,0.1c0.1-0.1,0.2-0.2,0.3-0.4c-0.1,0-0.2-0.1-0.3-0.1
				C128.9,218.1,128.8,218.2,128.7,218.3z"/>
			<path fill="#E2E1E2" d="M31.3,124.2c0,0.2,0.1,0.3,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.2-0.2-0.2S31.3,124.1,31.3,124.2z"/>
			<path fill="#D2D1D3" d="M127.7,183.5c-0.2,0-0.3,0-0.3,0.2c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1-0.1,0.2-0.1
				C127.8,183.6,127.7,183.5,127.7,183.5z"/>
			<path fill="#DDDCDD" d="M133.1,175.6c0.1-0.1,0.1-0.2,0.1-0.3s-0.1-0.1-0.2-0.2c-0.1,0.1-0.2,0.2-0.1,0.2
				C132.9,175.4,133,175.5,133.1,175.6z"/>
			<path fill="#D5D4D6" d="M128.6,181.8c0.2,0.2,0.3,0.2,0.3,0.3c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C128.8,181.6,128.7,181.7,128.6,181.8z"/>
			<path fill="#CAC9CC" d="M8.5,154.1c0.2,0,0.5,0.1,0.6-0.2c0,0,0-0.2-0.1-0.2C8.7,153.7,8.6,153.9,8.5,154.1L8.5,154.1z"/>
			<path fill="#DAD9DA" d="M133.3,213.8c0.1,0,0.2,0,0.3-0.1c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.1-0.2,0.1
				C133.1,213.5,133.3,213.7,133.3,213.8z"/>
			<path fill="#D6D5D7" d="M134.1,196.2c-0.2,0-0.3,0-0.2,0.2c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2-0.1,0.2-0.2
				C134.2,196.4,134.1,196.3,134.1,196.2z"/>
			<path fill="#E5E4E4" d="M128.5,244.5c0-0.1-0.1-0.3-0.2-0.2c-0.1,0.1-0.2,0.2-0.2,0.3s0.1,0.2,0.2,0.2
				C128.4,244.7,128.4,244.5,128.5,244.5z"/>
			<path fill="#E9E8E8" d="M11.4,153.6c0.1,0.1,0.2,0.2,0.3,0.2s0.2-0.1,0.3-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
				C11.6,153.4,11.5,153.5,11.4,153.6z"/>
			<path fill="#F0EFEF" d="M118.4,219.1c0,0.2,0,0.3,0,0.5c0.5,0,0.5-0.4,0.5-0.7C118.7,219,118.6,219.1,118.4,219.1z"/>
			<path fill="#E7E6E7" d="M121.3,202.3c0-0.2,0-0.5-0.1-0.5c-0.4-0.2-0.2,0.2-0.2,0.3s0.1,0.2,0.1,0.3
				C121.2,202.4,121.2,202.3,121.3,202.3z"/>
			<path fill="#D9D8DA" d="M108.5,243.9c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1-0.1-0.2-0.1-0.2
				C108.7,243.6,108.6,243.7,108.5,243.9z"/>
			<path fill="#E1E1E2" d="M116.4,225.3c-0.1,0.2,0,0.3,0.2,0.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0-0.2-0.2-0.2
				C116.6,225.2,116.5,225.3,116.4,225.3z"/>
			<path fill="#E2E1E2" d="M100.9,234.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
				C101.2,234.3,101.1,234.4,100.9,234.5z"/>
			<path fill="#D5D5D6" d="M136.1,190.5c-0.1,0.2,0,0.3,0.1,0.3s0.3-0.1,0.2-0.1c-0.1-0.1-0.1-0.3-0.2-0.4
				C136.2,190.3,136.1,190.4,136.1,190.5z"/>
			<path fill="#DCDBDD" d="M125.8,234.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.1-0.3S125.9,234,125.8,234.1z
				"/>
			<path fill="#DBDADB" d="M112.6,233.6c0-0.2-0.1-0.3-0.2-0.2c-0.1,0-0.1,0.2-0.1,0.3s0,0.2,0.2,0.2
				C112.5,233.8,112.5,233.7,112.6,233.6z"/>
			<path fill="#D8D8D9" d="M103.8,233.4c0.1,0.1,0.3,0.2,0.4,0.2s0.2-0.1,0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.2
				C104,233.2,103.9,233.3,103.8,233.4z"/>
			<path fill="#D1D1D2" d="M123.3,233.7c0.1-0.1,0.1-0.2,0.1-0.3s-0.1-0.1-0.2-0.2c-0.1,0.1-0.2,0.2-0.1,0.2
				C123.1,233.5,123.2,233.5,123.3,233.7z"/>
			<path fill="#D1D1D2" d="M122.8,232.7c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2-0.2c0-0.1-0.2-0.2-0.2-0.2
				C122.9,232.4,122.8,232.5,122.8,232.7z"/>
			<path fill="#D3D2D4" d="M124.2,191.7c0-0.1,0.1-0.2,0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.1,0.2
				C123.9,191.6,124,191.7,124.2,191.7z"/>
			<path fill="#CDCCCE" d="M86,231.1c0,0.2,0.1,0.4,0.1,0.5c0.1,0,0.2-0.1,0.3-0.1C86.5,231.2,86.2,231.2,86,231.1L86,231.1z"/>
			<path fill="#E1E0E1" d="M14.5,142.7c-0.1,0.1-0.2,0.2-0.2,0.3s0.1,0.1,0.2,0.2c0.1-0.1,0.2-0.1,0.2-0.2
				C14.7,142.9,14.6,142.8,14.5,142.7z"/>
			<path fill="#E0DFE0" d="M134.8,209.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1-0.2,0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1
				C134.9,208.9,134.8,208.9,134.8,209.1z"/>
			<path fill="#D7D6D7" d="M122.6,192.5c0.1,0,0.2,0.1,0.2,0.1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1-0.1-0.2-0.2-0.1
				C122.7,192.3,122.7,192.4,122.6,192.5z"/>
			<path fill="#E8E7E7" d="M123.2,210.2c0.3,0.1,0.5,0.2,0.5-0.2C123.3,209.6,123.2,209.9,123.2,210.2z"/>
			<path fill="#E1E0E1" d="M20,141.7c0.1,0,0.3,0.1,0.3,0c0.1-0.1,0.1-0.2,0.1-0.3s-0.2-0.1-0.2-0.1C20.1,141.4,20.1,141.5,20,141.7
				z"/>
			<path fill="#D3D2D4" d="M96.7,229.7c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.1c-0.1,0-0.1,0.2-0.2,0.3
				C96.5,229.6,96.6,229.6,96.7,229.7z"/>
			<path fill="#DBDADB" d="M107.4,229.5c0,0.2,0.1,0.3,0.2,0.2c0.1,0,0.2-0.2,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				S107.4,229.5,107.4,229.5z"/>
			<path fill="#DDDCDD" d="M26.3,140.5c-0.2,0-0.3,0.1-0.2,0.2c0,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2-0.2
				C26.5,140.6,26.4,140.5,26.3,140.5z"/>
			<path fill="#EEECED" d="M19.1,140.6c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.1c0.1,0,0.2-0.1,0.2-0.2
				C19.3,140.8,19.2,140.7,19.1,140.6z"/>
			<path fill="#CECDCF" d="M35.8,141c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.2,0.1,0.3,0.2,0.3c0.1,0,0.2-0.1,0.2-0.2
				C35.9,141.2,35.8,141.1,35.8,141z"/>
			<path fill="#D9D9DA" d="M113.5,241.2c0,0.2,0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C113.7,241.1,113.6,241.2,113.5,241.2z"/>
			<path fill="#D1D0D2" d="M34.2,129.1c-0.1,0.2-0.2,0.3-0.2,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.2-0.1,0.2-0.2
				C34.4,129.5,34.3,129.4,34.2,129.1z"/>
			<path fill="#D4D3D5" d="M26.5,130.3c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.2s-0.2,0.1-0.2,0.1
				C26.4,130.2,26.4,130.2,26.5,130.3z"/>
			<path fill="#EFEEEE" d="M8.5,148.5c-0.2,0-0.3,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
				C8.6,148.7,8.5,148.6,8.5,148.5z"/>
			<path fill="#D4D3D4" d="M114.3,221.7c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0,0.2-0.2c0-0.1-0.1-0.2-0.2-0.3
				C114.4,221.4,114.4,221.5,114.3,221.7z"/>
			<path fill="#E9E8E8" d="M27.9,131.5c0,0.2,0.1,0.3,0.2,0.2s0.2-0.2,0.3-0.3c0-0.1,0-0.2-0.2-0.2C28,131.3,27.9,131.4,27.9,131.5z
				"/>
			<path fill="#E0DFE0" d="M92.7,240.2c0.1-0.1,0.2-0.3,0.1-0.3c0-0.1-0.1-0.1-0.2-0.2c0,0.1-0.1,0.2-0.1,0.2
				C92.5,240,92.6,240.1,92.7,240.2z"/>
			<path fill="#F4F3F3" d="M94,239c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
				C94.2,238.8,94.1,238.9,94,239z"/>
			<path fill="#D7D7D8" d="M96,239.2c0-0.2,0-0.3-0.2-0.3c-0.1,0-0.2,0.1-0.2,0.2c0,0,0.1,0.1,0.1,0.2
				C95.8,239.3,95.9,239.2,96,239.2z"/>
			<path fill="#DAD9DB" d="M106.6,239.3c0-0.2-0.1-0.5-0.4-0.4c-0.1,0-0.1,0.1-0.1,0.1C106.2,239.3,106.4,239.3,106.6,239.3
				L106.6,239.3z"/>
			<path fill="#DBDADB" d="M56.2,134.6c-0.1-0.1-0.2-0.2-0.3-0.2s-0.1,0.1-0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2
				C56,134.8,56.1,134.7,56.2,134.6z"/>
			<path fill="#DFDEDF" d="M133.3,194.1c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.1-0.3
				C133.4,193.8,133.3,193.9,133.3,194.1z"/>
			<path fill="#D3D2D3" d="M130.2,238.4c0-0.1,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.1,0.1-0.2,0.2
				C129.9,238.3,130,238.4,130.2,238.4z"/>
			<path fill="#D4D4D5" d="M114.9,237.7c0,0.2,0,0.3,0.2,0.3c0.1,0,0.2-0.1,0.2-0.2c0,0-0.1-0.1-0.1-0.2
				C115,237.6,115,237.7,114.9,237.7z"/>
			<path fill="#E8E7E8" d="M94.4,238c0.1-0.4,0.2-0.5,0.1-0.6c0-0.1-0.2-0.1-0.2-0.2c0,0.1-0.1,0.2-0.1,0.3
				C94.2,237.6,94.3,237.7,94.4,238z"/>
			<path fill="#D7D6D8" d="M119.1,224.6c0.1-0.1,0.2-0.2,0.1-0.3c0-0.1-0.1-0.1-0.2-0.2c0,0.1-0.1,0.2-0.1,0.2
				C118.9,224.4,119,224.5,119.1,224.6z"/>
			<path fill="#E5E4E5" d="M115.5,212.1c0.1-0.4,0.2-0.5,0.2-0.5c0-0.1-0.2-0.1-0.2-0.2c0,0.1-0.1,0.2-0.1,0.3
				C115.3,211.8,115.4,211.9,115.5,212.1z"/>
			<path fill="#DEDDDE" d="M97.1,236.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
				S97.2,236.1,97.1,236.2z"/>
			<path fill="#E0DFDF" d="M14.3,136.5c0.1,0.1,0.2,0.2,0.3,0.2s0.1-0.1,0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.2
				C14.6,136.3,14.5,136.4,14.3,136.5z"/>
			<path fill="#D2D1D3" d="M16.2,144.2c-0.2,0-0.4,0-0.5,0.2c0.2,0,0.3,0.5,0.6,0.3C16.3,144.7,16.2,144.4,16.2,144.2z"/>
			<path fill="#E0DFE0" d="M17.5,144.4c0,0.2,0,0.3,0.2,0.2c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1-0.1-0.2-0.2-0.2
				C17.6,144.3,17.5,144.4,17.5,144.4z"/>
			<path fill="#CF8D74" d="M96.3,48.7c0-0.1,0-0.1,0-0.2c0.1-0.1,0.2-0.1,0.2-0.3c0-0.5-0.8-0.2-0.7-0.7c0-0.5-0.3-0.8-0.4-1.2
				c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.4-0.6-0.3-0.9c-0.1-0.4,0.1-0.7,0-1.1c-0.1-0.1-0.1-0.1-0.3-0.2c-0.5,0-0.8,0.9-1.4,0.2
				c-0.3-0.2-0.6-0.2-0.9,0c-0.2,0.2-0.6,0.4-0.5,0.7c-0.4-0.6,0.3-1.3,0.1-2c-0.9-0.5-1.4,0-1.9,0.6c-0.1,0-0.2,0-0.2-0.1
				c0-0.4-0.2-0.8-0.6-0.9c-0.3-0.1-0.4,0.2-0.5,0.4c-0.1,0.5-0.5,1,0,1.4c0.1,0.1,0.3,0.3,0.5,0.3c0.5,0.2,1.3,1.1,1.4,1.5
				c0.1,0.1,0.3,0.2,0.4,0.2c0.5,0.5,1.2,0.5,1.8,0.4c0.5,0,0.5-0.4,0.5-0.7c0-0.1,0.1-0.2,0.2-0.1c0,0.1,0,0.2,0,0.2
				c0.1,0.3,0,0.6-0.3,0.8c-0.3,0.4-0.5,0.7-0.4,1.2c0.5,0.8,0.5,0.8,0.1,2.4c0.2,0.3,0.6,0.4,0.9,0.6c0.7,0.5,0.8,0.2,0.7-0.5
				c-0.2-0.5-0.2-0.9,0.1-1.4c0.1-0.3-0.1-0.9,0.6-0.5C95.7,48.8,96,49,96.3,48.7z"/>
			<path fill="#DAAC9B" d="M92.9,37.9c-0.3-0.3-1.2-0.1-0.6-1.1c0.3-0.4-0.5-0.4-0.9-0.6c-0.2,0.5,0.3,0.6,0.3,0.9
				c-0.2,0.2-0.2,0.4-0.3,0.6c-0.3-0.1-0.2-0.5-0.4-0.6c-0.2,0-0.5,0-0.7,0c-0.2,0.3-0.4,0.3-0.7,0.2c-0.2-0.2-0.3-0.3-0.5-0.5
				c-0.1-0.1-0.2-0.1-0.2,0c-0.5,0.2-1,0.5-1.4,0.7c-0.2,0.2,0,0.5-0.2,0.7c0,0.1,0,0.2,0,0.2c0.1,0.3,0.2,0.5,0.5,0.6
				c0.2,0,0.3,0,0.5-0.1c0.2-0.2,0.1-0.5,0.2-0.8c0.1-0.4,0.2-0.2,0.4-0.1c0,0.5,0.9,0,0.7,0.7c0,0.5-0.9,0.9-0.1,1.4
				c0.7,0.5,1.5,0.7,2.2,0c0.2-0.5-0.1-0.6-0.7-0.7c0.8-0.3,1.4-0.4,1.5,0.6c0.1,0.6,0.7,0.6,1.1,0.6c0.5,0,0.5-0.4,0.4-0.8
				c-0.1-0.6,0.4-0.6,0.7-0.8c0-0.1,0-0.2,0-0.2c0.1-0.6-0.5-0.4-0.7-0.7c0.3-0.5,0.2-1-0.2-1.4c-0.3-0.2-0.6,0.1-0.7,0.4
				C93.1,37.6,93.1,37.8,92.9,37.9z"/>
			<path fill="#DAAC9B" d="M93.2,50.4c0.4-0.4,0.5-0.9,0.9-1.4c0.2-0.2-0.5-0.7-0.9-1l-0.1,0.1c-0.2,0.5-0.5,0.9-1,1.2
				c-0.4,0.3-1.1,0.4-0.9,1.3c0.1,0.2-0.6,0.3-1,0c-0.5-0.3-0.8,0-0.7,0.3c0.3,0.9-0.4,0.4-0.6,0.5c-0.1,0-0.2,0.1-0.3,0.1
				c-0.2,0.2-0.7,0.2-0.6,0.7c0.8-0.2,1.1,0.6,1.7,0.9c0.1,0.1,0.1,0.1,0.2,0c0.4,0,0.3,1.1,1,0.5c0.2-0.2,0.2-0.3,0-0.5
				c-0.3-0.5-0.3-1,0.2-1.4c0.4-0.1,0.7,0,0.7,0.5c0.3,0.4,0.7,0.5,1.2,0.5c0-0.3,0.1-0.6,0.3-0.8c0-0.1,0-0.2-0.1-0.3
				c-0.1-0.3-0.6-0.6-0.1-1C93.1,50.5,93.2,50.4,93.2,50.4z"/>
			<path fill="#D0927A" d="M94.6,39.4c-0.3-0.3-0.7,0.3-1.2-0.4c0.1,0.8,0.2,1.2,0.3,1.7c-1.3-0.4-1.3-0.4-0.6-1.3
				c-0.9,0.6-1.5-1-2.3,0c-0.1,0.1-0.5-0.1-0.5,0.3c0,0.2,0.3,0.4,0.5,0.3c0.4-0.2,0.5,0,0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2
				c0,0.1,0,0.1,0,0.1c0.2,0.1,0.5,0.2,0.6,0.4c0.2,0.3,0.6,0.6,1,0.6c0.4,0.1,0.1,0.6,0.4,0.7c0.2,0.1,0.3,0.1,0.5,0
				c0.2,0,0-0.6,0.4-0.2c0.4,0.4,0.8,0.6,1.1-0.1l0,0c0.3-0.7,0.3-0.7-0.2-1.4C95.2,40.1,94.2,40,94.6,39.4z"/>
			<path fill="#DAAD9C" d="M81.9,40.6c-0.8,0.3-1.4,0.7-1.6,1.6c-0.1,0.3-0.4,0.6,0.2,0.9c0.4,0.2,0.6,0.5,0.8-0.1
				c0.1,0.1,0.2,0.2,0.4,0.3l0.1-0.1c0.2-0.2,0.4-0.6,0.8-0.6c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.3,0.3-0.4
				c-0.4-0.7-0.1-1.2,0.4-1.7c0.4-0.4,0.2-0.6-0.4-0.7l0,0C82.9,40.1,82.3,40.2,81.9,40.6z"/>
			<path fill="#CE8B72" d="M83.1,39.6c0.8,0.6,0.2,0.9-0.3,1.1c-0.7,0.3-0.8,0.6-0.2,1c0.6,0.4,0,0.6-0.1,0.8c0,0.4,0.2,0.7,0.7,0.8
				c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0-0.3c-0.1-0.3,0-0.5,0.3-0.6c0.3-0.3,0.6-0.6,0.9-0.9c-0.1-0.6-0.1-1.2-0.6-1.6
				c-0.2-0.1-0.4-0.3-0.5-0.5c-0.3-0.5-0.7-0.4-0.9-0.2C82.2,39.5,83.1,39.3,83.1,39.6z"/>
			<path fill="#CD886E" d="M81.4,45.1c0.9-0.2,0.3,0.6,0.5,0.8s0.1,0.6-0.2,0.5c-1.1-0.2-0.5,0.4-0.2,0.6c0.5,0.3,0.3,1.5,1.3,1
				c0.3-0.2,0.5-0.4,0.7-0.7c0.3-0.4,0.3-0.8-0.1-1.2c-0.5-0.6-0.9-1.4-1-2.2c-0.1-0.1-0.2-0.2-0.3-0.2C81.6,44,81.2,44.4,81.4,45.1
				z"/>
			<path fill="#CF8F77" d="M83.4,47.3c-0.3,0.2-0.4,0.5-0.5,0.8c0.1,1.1,0.3,1.9,1.7,1.9c0.3-0.1,0.6-0.2,0.8-0.5
				c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.5-0.3-0.8-0.5-1.2c-0.2-0.2-0.4-0.3-0.6-0.2C84.1,47.9,83.7,47.8,83.4,47.3z"/>
			<path fill="#D7A390" d="M94.9,43.9c0.1,0.4-0.5,0.8,0,1.2c0,0.7,1,0.4,1.1,1c0.1,0.2,0.2,0.6,0.6,0.4c0.5,0,0.9-0.4,1.2-0.7
				c-0.2-0.6-1-0.6-1.2-1.2c0-0.1,0-0.2,0-0.2C96,44.3,95.6,43.5,94.9,43.9z"/>
			<path fill="#D9AB9A" d="M84.1,39.6c0.2,0.6,0.3,1.1,0.5,1.7c0.2,0.1,0.4,0,0.6-0.1c0.2-0.3,0.7-0.2,0.9-0.5
				c0.2-0.2,0.4-0.4,0.5-0.6c0-0.2,0-0.3-0.1-0.4c-0.4,0.1-0.5-0.7-1-0.2c-0.3,0-0.6-0.1-1,0C84.5,39.6,84.3,39.6,84.1,39.6z"/>
			<path fill="#DBAF9F" d="M87.2,50.9c0,0.2,0,0.3,0,0.5c0.3,0.3,0.6,0.3,1,0c0.2-0.3,0.1-0.6,0.2-0.8c0-0.5-0.4-0.8-0.8-1
				c-0.6,0-1.2-0.8-1.8,0c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.4,0.1,0.8,0.5,1C86.5,51.1,86.9,50.3,87.2,50.9z"/>
			<path fill="#DAAD9C" d="M81.4,45.1c0.2-0.5,0.4-1,1-1.2c0.3,0.1,0.6,0.1,0.9-0.1c0.4-0.2,0.5-0.4,0-0.6c-0.2-0.2-0.5-0.5-0.7-0.7
				l0,0c-0.4,0.1-0.7,0.4-1,0.7l0,0c-0.2,0.2-0.3,0.5-0.5,0.7c-0.3,0.4-0.4,0.8,0,1.2h0.1L81.4,45.1z"/>
			<path fill="#CF8F76" d="M94.9,43.9c0.7-0.3,0.9,1.1,1.7,0.5c0.3-0.1,0.8,0.1,0.6-0.4c-0.1-0.5-0.4,0.1-0.6,0
				c-0.3-0.1-0.5-0.4-1-0.3c0.3-0.4,0.5-0.7,0.7-1c0.1-0.3,0.2-0.5-0.2-0.5c-0.1,0-0.1,0-0.2,0c-0.4,0.3-0.6,0.7-0.9,1.1
				C94.9,43.5,94.7,43.7,94.9,43.9L94.9,43.9z"/>
			<path fill="#D9AA98" d="M94.6,49.2c-0.4,0.4-0.7,0.8,0,1.2c0.1,0.1,0.2,0.1,0.2,0c0.4,0,0.8-0.4,1.3-0.2c0.2,0.1,0.3,0,0.3-0.2
				c0.1-0.4-0.3-0.6-0.5-0.9c-0.4-0.2-0.8,0.3-1.3,0C94.7,49.2,94.7,49.2,94.6,49.2z"/>
			<path fill="#CB8166" d="M86.5,39.6c0,0.2,0,0.3,0,0.5c0.5,0.3,0.9,0.7,1.6,0.6c0.2-0.1,0.3-0.2,0.4-0.3c0-0.1,0-0.2,0-0.3
				c-0.3-0.3-0.4-0.7-0.8-1c-0.2-0.2-0.3-0.3-0.5-0.5C87,39,86.7,39.3,86.5,39.6z"/>
			<path fill="#DBAF9F" d="M95.1,43.5c0.3-0.4,0.7-0.7,0.7-1.2c-0.2-0.1-0.3-0.2-0.5-0.3c-1.3,1-0.5-0.9-1.3-0.8
				c-0.2,0.3-0.3,0.6-0.2,1.1c-0.1,0.1-0.2,0.3-0.1,0.5c0,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2,0,0.3,0c0.4-0.3,0.6,0,0.7,0.3
				C95,43.4,95.1,43.4,95.1,43.5z"/>
			<path fill="#DAAD9D" d="M94.9,50.4c-0.1,0-0.2,0-0.2,0c-0.1,0.2,0.2,1-0.5,0.3c-0.3-0.3-0.6-0.2-1-0.3l0,0
				c-0.5,0.4-0.4,0.8,0,1.2l0,0c0.2,0.3,0.5,0.1,0.7,0c0.3-0.2,0.6-0.2,1,0c0.1-0.2,0.2-0.4,0.2-0.7C95,50.7,95,50.6,94.9,50.4z"/>
			<path fill="#D0927A" d="M87.2,50.9c0.2-0.1,0.3-0.3,0.1-0.5c-0.4-0.4-0.7,0.3-1,0.2c-0.1,0.2-0.1,0.3,0,0.5
				c-0.6,0.4-0.5,0.8,0.1,1.3C86.7,52,86.3,51,87.2,50.9z"/>
			<path fill="#D9AA98" d="M96.3,48.7c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3-0.4-0.8-0.5-1.1-0.3c-0.3,0.1-0.1,0.6-0.1,1l0,0
				c0.6,0.4,1,0,1.4-0.2C96.1,48.9,96.2,48.8,96.3,48.7z"/>
			<path fill="#CD876D" d="M88.2,51.1c-0.3,0.3-0.7,0.2-1,0.2c0,0.5-0.2,1.2,0.7,0.7c0.2-0.2,0.5-0.3,0.7-0.5c0.1-0.2,0.1-0.3,0-0.5
				C88.5,51,88.3,51,88.2,51.1z"/>
			<path fill="#CE8B71" d="M96.6,44.7c-0.2,1.1,0.8,0.7,1.2,1.2c0.2,0,0.5-0.1,0.3-0.3C97.6,45.2,97.1,44.8,96.6,44.7z"/>
			<path fill="#D1947C" d="M86.2,51.1c0-0.2,0-0.3,0-0.5c-0.3-0.3-0.4-0.6-0.5-1c-0.1-0.1-0.2-0.1-0.3,0c-0.3,0.3-0.7,0.5-0.4,1
				C85.6,50.3,85.6,51.5,86.2,51.1z"/>
			<path fill="#D7A390" d="M95.1,46.3c0.2,0.4,0.3,0.9,0.7,1.2c0.5,0,0.5-0.4,0.5-0.7C96,46.3,95.7,46.1,95.1,46.3z"/>
			<path fill="#D0927A" d="M91,37.2C91,37.2,90.9,37.3,91,37.2c0.2,0.4-0.2,1.1,0.2,1.1c0.6,0,0.5-0.6,0.5-1.1
				C91.5,37.2,91.3,37.2,91,37.2z"/>
			<path fill="#D2967F" d="M93.9,51.6c-0.2-0.3-0.5,0.1-0.7,0c-0.3,0.3-0.6,0.5-0.2,1C93.2,52.1,94.1,52.5,93.9,51.6z"/>
			<path fill="#DBAF9F" d="M95.1,40.6c0.1,0.5,0.2,1,0.2,1.4c0.2-0.3,0.6-0.3,0.5-0.8C95.8,40.5,95.2,40.9,95.1,40.6z"/>
			<path fill="#DBAF9F" d="M85,50.7c0.2-0.3,0.3-0.6,0.5-1c0-0.1-0.1-0.2-0.2-0.2c-0.4,0-0.6,0.2-0.7,0.5c0.1,0.2,0.2,0.5,0.2,0.7
				l0,0C84.9,50.7,85,50.7,85,50.7z"/>
			<path fill="#CF8E75" d="M84.6,39.4c0.3,0.1,0.6,0.9,1,0c-0.3-0.2-0.4-0.7-0.9-0.6C84.3,38.9,84.5,39.2,84.6,39.4z"/>
			<path fill="#CF8E76" d="M90.8,53.1c0,0.2,0,0.3,0,0.5c0.1,0,0.2,0,0.2-0.1c0.3-0.2,0.8-0.1,0.9-0.6c0-0.1-0.1-0.2-0.2-0.3
				C91.2,52.3,91.2,53,90.8,53.1z"/>
			<path fill="#D1947C" d="M84.8,50.6c-0.4,0.1-0.9,0.3-0.9,0.8s0.6,0.3,0.8-0.2C84.7,51,84.7,50.8,84.8,50.6L84.8,50.6z"/>
			<path fill="#CD886E" d="M81.2,45.1c-0.1-0.4-0.1-0.8,0-1.2c-0.6,0.1-0.5,0.9-1.3,1.2C80.5,45.1,80.9,45.1,81.2,45.1z"/>
			<path fill="#CF8F77" d="M95.1,46.3c0.4,0.2,0.8,0.3,1.2,0.5c0.1-0.1,0.2-0.2,0.2-0.2c-0.2-0.2-0.3-0.3-0.5-0.5
				c-0.3-0.2-0.6-0.3-1,0C95.1,46.2,95.1,46.2,95.1,46.3z"/>
			<path fill="#D7A390" d="M97,48.2c0.8-0.5,0.5-0.9-0.3-1.4C96.7,47.5,97.1,47.8,97,48.2L97,48.2z"/>
			<path fill="#DAAC9B" d="M89.6,36c-0.3,0-0.7,0.1-1-0.2c-0.2-0.2-0.4,0-0.4,0.2c0,0.3,0.2,0.3,0.4,0.3c0.2,0.1,0,0.6,0.5,0.5
				c0-0.3,0.2-0.5,0.5-0.5C89.6,36.2,89.7,36.1,89.6,36L89.6,36z"/>
			<path fill="#D2967F" d="M81.9,40.6c0.5-0.2,1.3-0.1,1.2-1C82.6,39.8,82.1,39.9,81.9,40.6z"/>
			<path fill="#CF8E76" d="M85.3,36.8c0,0.3,0.2,0.5,0.5,0.6c0.2,0,0.4,0,0.3-0.2s-0.3-0.4-0.5-0.6C85.5,36.5,85.3,36.6,85.3,36.8z"
				/>
			<path fill="#D2947D" d="M89.8,53.1c-0.1,0-0.2,0-0.2,0c-0.4,0-0.8-0.1-1.3-0.1C88.7,53.6,88.9,53.6,89.8,53.1z"/>
			<path fill="#D1937C" d="M96.1,49c-0.5,0.1-0.9,0.3-1.4,0.2c0.5,1,1,0,1.4,0C96.1,49.1,96.1,49,96.1,49z"/>
			<path fill="#D0927A" d="M93.9,38.4c0.2,0.2,0.5,0.5,0.7,0.7c0.2,0,0.4-0.1,0.3-0.4C94.7,38.3,94.3,38.4,93.9,38.4z"/>
			<path fill="#CF8F77" d="M86.5,38.9c-0.2-0.7-0.4-1.1-1.2-1C85.7,38.2,86,38.5,86.5,38.9z"/>
			<path fill="#D1927B" d="M87.2,38.4c0.7,0,0.4-0.4,0.2-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.1-0.2,0
				C86.8,37.8,86.8,38.2,87.2,38.4z"/>
			<path fill="#D39984" d="M81.6,47.4c-0.4,0.6-0.6,0.9-0.4,1.3C81.7,48.4,81.6,48,81.6,47.4z"/>
			<path fill="#D59E89" d="M87,37.5c0.1,0,0.2,0,0.2,0c0-0.4,0-0.7-0.4-0.9c-0.1,0-0.2,0.1-0.2,0.2c0,0.1-0.1,0.4,0,0.4
				C86.7,37.3,87,37,87,37.5z"/>
			<path fill="#D9AA98" d="M96.5,48.2c-0.1,0.1-0.1,0.2-0.2,0.3c0.2,0.2,0.5,0.4,0.8,0.7c0-0.4,0-0.6,0-0.9l0,0
				C96.9,48.3,96.7,48.3,96.5,48.2z"/>
			<path fill="#CE8B72" d="M89.6,37.4c0,0.5,0.3,0.3,0.6,0.3c0.4-0.1,0.3-0.3,0.2-0.5C90,37.2,89.8,37.2,89.6,37.4z"/>
			<path fill="#CF8B72" d="M84.7,38.2c-0.1-0.4,0-0.9-0.7-1C84.3,37.5,84,38,84.7,38.2z"/>
			<path fill="#D39984" d="M96.8,42.7c0.5,0.2,0.4-0.2,0.6-0.5L97.3,42c-0.3,0-0.4,0.2-0.4,0.4C96.7,42.5,96.7,42.6,96.8,42.7
				C96.7,42.7,96.8,42.7,96.8,42.7z"/>
			<path fill="#DBAF9F" d="M96.6,40.8c-0.3,0-0.5,0.2-0.3,0.5c0.1,0.2,0.2,0.6,0.6,0.2C96.6,41.3,96.8,41,96.6,40.8z"/>
			<path fill="#D29781" d="M91.8,52.1c0.2-0.1,0.2-0.3,0-0.4c-0.2-0.1-0.5-0.4-0.8,0C91.3,51.8,91.5,51.9,91.8,52.1z"/>
			<path fill="#D0937B" d="M89.6,36c0,0.1,0,0.2,0,0.2c0.3,0.4,0.5,0,0.8-0.1C90.1,35.9,89.8,36,89.6,36z"/>
			<path fill="#CE8C72" d="M96.6,40.8c0,0.3-0.1,0.5,0.2,0.7c0.1-0.1,0.3-0.3,0.3-0.4C97.1,40.8,96.8,40.8,96.6,40.8z"/>
			<path fill="#D2957E" d="M92.9,37.9c0.2-0.1,0.6,0.1,0.6-0.3c0-0.3-0.4-0.2-0.6-0.2C93,37.6,92.9,37.8,92.9,37.9z"/>
			<path fill="#DBAF9F" d="M96.8,42.7c0-0.1,0-0.2,0-0.2c-0.3,0-0.3-0.7-0.7-0.2c0.2,0.1,0.2,0.3,0.2,0.5
				C96.5,42.7,96.6,42.7,96.8,42.7z"/>
			<path fill="#D19279" d="M95.1,50.9c-0.3,0.2-0.2,0.4-0.2,0.7C95.2,51.4,95.5,51.3,95.1,50.9z"/>
			<path fill="#D2967F" d="M89.1,36.7c-0.2-0.2-0.3-0.3-0.5-0.5c0.1,0.2-0.4,0.7,0.2,0.7c0.1,0,0.2,0,0.2,0
				C89.1,36.9,89.1,36.8,89.1,36.7z"/>
			<path fill="#D29882" d="M81.8,39.8c0.1-0.1,0.1-0.2,0.1-0.3s-0.1-0.1-0.2-0.2c-0.1,0.1-0.2,0.2-0.1,0.2
				C81.5,39.6,81.7,39.6,81.8,39.8z"/>
			<path fill="#BC6354" d="M42.1,37.5c-0.9,0.3-0.3-0.2-0.2-0.4c0.2-0.3,0.6-0.5,0.1-0.8s-0.7,0-0.9,0.4c-0.2,0.7-0.4,1.2-1.2,0.5
				c-0.2-0.1-0.8,0-1.3,0c0.2,0.4,1.5,0.6,0.2,1c-0.1,0.4,0.2,0.5,0.5,0.6c0.6,0.2,1.3-0.2,1.6,0.7c0.1,0.2,0.4,0,0.6-0.1
				c0.4-0.5,1.1,0.3,1.5-0.4c0-0.1,0-0.2,0-0.3c0-0.3-0.5-0.5-0.4-1C42.5,37.5,42.2,37.6,42.1,37.5z"/>
			<path fill="#B6554D" d="M46.6,40.6c-0.5-0.5-0.6-1.1,0-1.6c0.3-0.2,0.3-0.6,0-0.8c-0.2-0.2-0.3-0.4-0.6-0.3
				c-0.4,0.1-0.2,0.5-0.3,0.7c-0.2,0.5-0.1,1.2-0.6,1.6c-0.6-0.1-0.6,0-0.4,0.7c0.2,0.3,0.4,0.5,0.6,0.6c0.3,0.2,0.4,0.4,0.5,0.8
				c0.3,1.1,0.6,1.2,1.6,0.4c0-0.4-0.4-0.8,0-1.2c0.3-0.3,0.5-0.7,0.1-1C47.2,39.9,47,40.8,46.6,40.6z"/>
			<path fill="#C4745D" d="M42.8,31c0.1,0,0.2,0,0.3,0c0.3,0.2,0.6,0.3,0.7,0.7c0.2,0.1,0.2,0.2,0,0.3c0,0.4,0.2,0.4,0.5,0.2
				c0.2-0.3,0-0.6,0-1c0.3-0.2,0.4-0.4,0.1-0.6c-0.4-0.2-0.6-0.7-1.1-0.8c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1,0.1-0.1,0.2-0.2,0.2
				c-0.1,0-0.1-0.1-0.1-0.2c0.1-0.5-0.4-1.2,0.5-1.4c-0.3-0.7-1.3-0.3-1.7-1c-0.3,0.2-0.5,0.3-0.8,0.5c0.1,0.5,1,0,0.9,0.6
				c0,0.3-0.2,0.6-0.6,0.6c-0.1,0.1-0.1,0.1-0.1,0.2s0,0.1,0.1,0.1c0.1,0.2,0.2,0.3,0.3,0.5s0.3,0.3,0,0.5c0,0.3,0.2,0.5,0.5,0.5
				c0.2,0.2,0.4,0.7,0.7,0.5C42.8,30.2,42.6,31.1,42.8,31z"/>
			<path fill="#C77B62" d="M45,40.8c0.1-0.2-0.4-0.7,0.2-0.7c-0.2-0.6-0.8-0.4-1.2-0.5c0-0.3,0.3-0.3,0.5-0.4
				c-0.2-1.2-0.9-1.8-1.7-1.5c-0.3,0.3,0.2,0.7,0,1.1c0,0.1,0,0.2,0,0.2c-0.2,0.7-0.2,0.7,0.3,1.1c-0.3-0.1-0.7-0.1-1,0
				c-0.3,0.4,0.1,0.8,0.1,1.2c0.6-0.2,0.6,0.7,1.1,0.7c0.5-0.1,0.7-0.6,1.1-0.9C44.5,40.6,44.8,41,45,40.8z"/>
			<path fill="#C06C59" d="M46.6,55.5c0.3-0.4,0.7,0,1,0c0.4,0.1,0.8,0.5,1-0.4c0.2-0.6,0.7,0.4,1.1,0.1c-0.3-0.5,0.2-1,0-1.4
				c-0.1,0-0.2,0-0.2,0c-0.2,0-0.5-0.1-0.7,0C48.7,54,48.5,54,48.3,54c-0.3-0.2-1,0.4-1-0.5c-0.1-0.2-0.2-0.3-0.2-0.5
				c-0.2-0.1-0.3,0-0.4,0.1c-0.2,0.2-0.6,0.4-0.8,0.3c-0.4,0.3-0.1,0.7-0.1,1C45.7,55.1,45.9,55.5,46.6,55.5z"/>
			<path fill="#B5524C" d="M45.9,50.4c-0.3,0.6-0.1,1.1,0.5,1.4l0.1,0.1c0.9-0.4,1.7-0.8,2.6-1.3c0.1-0.2,0.1-0.3,0-0.5
				c-0.7-0.6-0.5-1.1,0-1.7c-0.1-0.2-0.2-0.4-0.5-0.2c-1,0-1,0.7-1,1.4c-0.1,0.2-0.1,0.5-0.4,0.6c-0.2,0-0.3,0.1-0.4,0.3
				c0,0.1,0,0.1-0.1,0.1s0-0.2-0.1-0.2C46.4,50.2,46.1,50.2,45.9,50.4z"/>
			<path fill="#C5765F" d="M51.7,55.7c0.5-0.5,0.9-1.1,1.7-0.5c0.4-0.1,0.8-0.3,1.2-0.2c0.3-0.5,1.2-1,0-1.4c-0.2,0.2-0.3,0.2-0.5,0
				c-0.4-0.7-1-0.8-1.7-0.5c-0.3,0.2-0.5,0.4-0.2,0.7c0,0.2,0,0.3,0,0.5c-0.3,0.1-0.4,0.4-0.5,0.7l0,0
				C51.5,55.2,51.5,55.5,51.7,55.7z"/>
			<path fill="#BD6656" d="M36.8,23.5c-0.1,0.8,0.5,1.4,0.5,2.2c0.4,0.2,0.8-0.3,1.2-0.2c0.5-1.3,1.2-0.5,1.8-0.1
				c0.3,0.2,0.6,0.7,0.9,0.3c0.4-0.5-0.3-0.6-0.6-0.8C39.4,24.2,38.2,23.4,36.8,23.5z"/>
			<path fill="#C06B59" d="M46.6,40.6c0.3,0.6,0.7-0.5,0.9,0c0.2,0.4-0.2,0.6-0.5,0.9c-0.7,0.6,0.1,0.7,0.3,1s-0.3,0.6,0.1,0.8
				c0.2,0.1,0.3,0,0.4-0.1c0.2-0.5-0.2-1.3,0.7-1.4c0.1,0,0.1-0.1,0.1-0.1s0-0.1-0.1-0.1c-0.4-0.4-0.3-0.6,0.2-0.7
				c0.2,0,0.4-0.1,0.4-0.3s-0.2-0.4-0.4-0.3c-0.3,0.1-0.4-0.1-0.6-0.3C47.3,39.4,46.8,39.6,46.6,40.6z"/>
			<path fill="#C06C59" d="M48.1,57.6c0.3,0-0.1,1.1,0.7,0.4c0.2-0.2,0.2,0.3,0.3,0.6c0.8-0.2,0.9-1,1.4-1.5c0.6-0.6-0.6-1-0.5-1.6
				c-0.1,0.1-0.1,0.4-0.2,0.4C48.7,55.9,48.7,57.1,48.1,57.6z"/>
			<path fill="#C06B59" d="M48.4,24c-0.1,1.6-0.9,2.1-2.4,1.7c-0.4-0.1-0.5,0.2-0.6,0.4c-0.1,0.3-0.3,0.6,0.1,0.8
				c0.5,0.3,1.5,0.1,1.7-0.5c0.1-0.3,0.3-0.3,0.5-0.2c0.4,0.1,0.8,0.2,0.9-0.4C48.7,25.1,49.1,24.5,48.4,24z"/>
			<path fill="#C16D5A" d="M51.3,50.4c0.8-0.2,1.5-1.4,1.4-2.2c-0.1-0.4-0.3-0.4-0.6-0.4c-0.4,0-0.3,0.3-0.2,0.5c0,0.2,0,0.4,0,0.7
				c-0.7-1.1-1.7,0.6-2.3-0.4c0.3,0.4,0.6,0.8,0.9,1.1c0.1,0.1,0.3,0.3,0.3,0.1c0.2-0.5,0.6-0.3,0.7-0.2
				C51.9,50,51.1,50.1,51.3,50.4z"/>
			<path fill="#BA5E52" d="M46.1,35c0.6,0,0.6,0.5,0.9,0.8c0.4,0.4,0.8,1,1.4,0.2c0.2-0.3,0.9-0.6,0.6-1c-0.3-0.5-0.9-0.1-1.3,0v0.1
				V35c-0.5-0.3-1-0.5-1.1-1.1c-0.5,0.1-0.5,1.4-1.3,0.4C45.2,35.1,45.4,35.3,46.1,35z"/>
			<path fill="#B7584F" d="M39.2,38.7c-0.2-0.2-0.3-0.3-0.5-0.5c-0.7-0.4-1.2-0.8-0.7-1.7c-1,0.7-1.9,1.5-1.4,2.9
				c0.1,0.1,0.2,0.2,0.2,0.2c0.1-0.4,0.1-0.7,0.2-1.2c0.6,0.3,1,0.5,1.5,0.7C39,39.4,39.2,39.2,39.2,38.7z"/>
			<path fill="#B5544D" d="M47.8,43.2c-0.2,0-0.3,0-0.5,0c-0.7,0.1-1,0.6-1.3,1.1c-0.2,0.4-0.7,0.2-1,0.3c-0.4,0.4-0.9,0.8,0,1.2
				c0.1,0,0.2,0,0.4,0c0.4-0.1,0.9-0.1,1.3-0.3c0.2-0.2,0.4-0.4,0.2-0.6c-0.2-0.4,0.1-0.4,0.3-0.3c0.7,0.3,1.1-0.1,1.4-0.6
				c0.1-0.3-0.1-0.3-0.2-0.5C48.2,43.4,48,43.3,47.8,43.2z"/>
			<path fill="#B5534C" d="M46.1,35c-0.5,0.1-0.8-0.2-0.7-0.7c-0.1-0.2-0.2-0.5-0.2-0.7c-0.5-0.6-0.6-0.1-0.7,0.2
				c-0.4,0.3-0.1,0.7,0,0.8c0.6,0.7,0.3,1.4,0.2,2.1c0.2,0.4,0.5,0.5,0.9,0.3c0.3-0.2,0.3-0.5,0.1-0.8C45.8,35.8,46,35.4,46.1,35z"
				/>
			<path fill="#B75951" d="M36.5,26.6c-0.2-0.1-0.5-0.2-0.7,0c0.1,0.8,0.3,1.5,0.9,2.1c0.2,0,0.3,0,0.5,0c0.3-0.2,0.8-0.4,0.5-1
				c-0.2-0.3-0.3-0.6-0.4-0.8c0-0.9,1.3,0.9,0.9-0.6C38.3,26,38,26,37.8,26C37.4,26.2,36.9,26.4,36.5,26.6z"/>
			<path fill="#BF6857" d="M48.3,43.5c0.1,0.2,0.2,0.3,0.2,0.5c0.3,0.2,0.1,0.6,0.5,0.9c0.4-0.5,1-0.8,1.3-1.4
				c0.2-0.6,0.8,0,1.2-0.3c0.2-0.1,0.5,0,0.4-0.4c-0.1-0.2-0.3-0.2-0.5-0.2C50.3,42.9,49.3,43.2,48.3,43.5z"/>
			<path fill="#BB6153" d="M46.4,47c0.1,0.2,0.2,0.5,0.2,0.7c0.8,0.1,1.8,0.7,1.4-1c-0.2-0.6,0.8-1.1,0.1-1.8
				c-0.5,0.2-0.2,0.7-0.5,0.9c0,0-0.1,0-0.1,0.1C47.1,46.3,46.7,46.7,46.4,47z"/>
			<path fill="#B85950" d="M44.1,36.2c0-1,0-1.9-1.1-2.3c-0.5-0.2-0.7-0.1-0.7,0.5C42.3,35.6,43.1,35.9,44.1,36.2z"/>
			<path fill="#C5765F" d="M38.5,33.6c0.2,0,0.4,0.1,0.6,0.1c0.5,0,0.3,0.4,0.3,0.6c0,0.3-0.4,0.3-0.5,0.2c-0.4-0.4-1.1-0.3-1.3-1
				c-0.5,0.8,0.4,1.1,0.4,1.6c0.6-0.1,1.2-0.1,1.7-0.4c0.5-0.2,0-0.8,0.2-1.3c0.1-0.5-0.5-0.2-0.8-0.3
				C38.8,33.3,38.6,33.4,38.5,33.6z"/>
			<path fill="#BB6254" d="M43.3,29.8c0,0.7,1,0.7,1,1.4c0.2,0.1,0.5,0.4,0.7,0.1c0.3-0.4-0.2-0.5-0.2-0.8c-0.1-0.1,0,0-0.1-0.1
				c0,0,0,0,0.1,0.1c0.1,0,0.2,0,0.2-0.1c0-0.5,0.7-1.2,0.4-1.3C44.7,29,43.9,29.2,43.3,29.8z"/>
			<path fill="#BF6A58" d="M46.2,54.5c-0.1-0.3-0.2-0.6-0.2-1c0.1-0.2,0.4-0.4,0.2-0.7c-0.4-0.3-0.2-0.6-0.2-1
				c-0.9,0.6-0.9-0.6-1.4-0.7c-0.1,0.2-0.2,0.3,0,0.5c0.1,0.4,0.2,0.8,0.2,1.2l0,0c0.6,0.3,0.5,1,0.7,1.4c0.1,0.1,0.2,0.2,0.2,0.2
				C45.8,54.6,46,54.6,46.2,54.5z"/>
			<path fill="#BD6555" d="M38.5,20.2c0,0.2,0,0.4-0.2,0.5c0,0.1-0.1,0.1-0.1,0.2c-0.4,0.6,1,0.3,0.5,0.9c-0.3,0.4-0.4,0.9-0.1,1.3
				C40,21.6,40,21.4,38.5,20.2z"/>
			<path fill="#BD6555" d="M52.2,53.8c0.1-0.2,0.2-0.5,0.2-0.7c0.3-0.6,0.6-1.3,0.8-1.9c-0.1-0.1-0.2-0.1-0.3-0.2
				c-0.3,0.9-1.9,0.3-1.5,1.9l0,0C51.8,53,51.6,53.7,52.2,53.8z"/>
			<path fill="#BF6A58" d="M44.5,33.8c0.2-0.1,0.4-0.5,0.7-0.2c0-0.7-0.1-1.3-0.9-1.5c-0.2,0-0.4,0-0.5-0.2
				c-0.3,0.1-0.9-0.2-0.9,0.3c0,0.7,0.7-0.1,0.9,0.4C43.2,33.3,44,33.5,44.5,33.8z"/>
			<path fill="#BB6153" d="M49.8,53.8c-0.2,0.5-0.6,1,0,1.4c0.1,0,0.2,0,0.2,0c1-0.5,1-1.6,1.4-2.4l0,0c-0.2,0-0.5,0-0.7,0l0,0
				C50.6,53.4,50.3,53.7,49.8,53.8z"/>
			<path fill="#BA5F52" d="M42.8,37.7c0.2-0.1,0.5-0.1,0.8-0.2c0,0.4-0.6,1.3,0.5,1.1c0.1,0,0.2,0.4,0.4,0.6
				c0.8-0.3,0.5-0.9,0.5-1.4c-0.7,0.2-0.6-0.8-1.2-0.7c-0.6,0.2-1.1,0.3-1.7,0.5c0.1,0.2,0.3,0.2,0.5,0.2
				C42.6,37.8,42.7,37.8,42.8,37.7z"/>
			<path fill="#B95C51" d="M41.4,26.9c0.1,1,1,0.8,1.7,1c0.6,0.5,0.8,0.1,1.1-0.3C43.3,26.7,42.2,27.2,41.4,26.9z"/>
			<path fill="#C3715C" d="M43.7,37c0.1,0.2,0.3,0.3,0.3,0.5c0,1.2,0.5,0.4,0.9,0.3c-0.2-1,0.9-0.2,1-0.7c0.1-0.2,0.8,0.2,0.6-0.5
				c-0.2-0.5-0.5-0.5-0.8-0.3c0,0.9-0.4,0.8-1,0.5C44.4,37,44,36.5,43.7,37z"/>
			<path fill="#C2715C" d="M36.1,30.3c0,0.2,0.5,0.3,0.2,0.5c-0.5,0.3-0.4,0.7-0.4,1.2c0.2-0.2,0.3-0.3,0.5-0.5c0.2,0,0.3,0.2,0.5,0
				c0.3-0.4,0.3-1.2,1.2-0.7c0.3,0.1,0-0.6,0.3-1c-0.4,0.2-0.8,0.3-1.2,0.5C36.7,30.3,36.4,30.3,36.1,30.3z"/>
			<path fill="#BF6A58" d="M41.6,31c-0.1,0.8-0.3,1.7-0.1,2.7c0.5-0.6,0.3-1.1,0.4-1.5c0.3,0,0.2,0.5,0.5,0.3
				c0.2-0.1,0.2-0.4,0.1-0.5c-0.4-0.4-0.1-0.7,0.1-1C42.2,30.8,41.9,30.7,41.6,31z"/>
			<path fill="#C06C59" d="M44.9,18.4c-0.1,1.1-2.1-0.4-1.7,1.4c0.6-0.7,1.2-0.3,1.9,0C44.9,19.3,44.9,18.9,44.9,18.4z"/>
			<path fill="#C2715C" d="M37.8,27.9c0,0.4-0.3,0.6-0.5,1c0.8,0.5,1.7,0.8,2.7,0.6c-0.5-0.1-0.9-0.4-1-0.8c-0.3-0.2-0.7-0.2-1-0.5
				C37.9,28,37.8,27.9,37.8,27.9z"/>
			<path fill="#C16E5A" d="M46.8,21c-1.5,0.5-2.2,1.8-1.8,2.8C45.8,23.1,45.8,21.9,46.8,21z"/>
			<path fill="#B95C51" d="M51.9,58.8c0.9,0.2,1.4-0.5,1.6-1.2c0.2-0.6-0.4-0.8-0.9-0.8C52.7,57.6,51.8,58,51.9,58.8z"/>
			<path fill="#B5534D" d="M36.8,31.4c-0.2,0-0.3-0.3-0.5,0c0,0.7,0.6,0.8,1,1.2c0.1,0.1,0.2,0.2,0.2,0.2c0.7-0.1,0.5-0.8,1-1.1
				C37.9,31.5,37.2,32,36.8,31.4z"/>
			<path fill="#BE6757" d="M52.7,47.3c0.5,0.3,0.8-0.2,1.2,0.1c0.3,0.2,0.6,0.5,0.8,0c0.3-0.5-0.6-0.5-0.6-1c0-0.2-0.4-0.2-0.7,0
				C53.2,46.8,52.9,47.1,52.7,47.3z"/>
			<path fill="#C06C59" d="M51.9,58.8c0.4-0.6,1.1-1.1,0.7-1.9c-0.2,0.1-0.3,0.2-0.5,0.2c-0.3,0.3-0.5,0.6-1,0.5
				c-0.3-0.1-0.6-0.3-0.8,0c-0.1,0.1-0.1,0.3-0.1,0.4c0.2,0.4,0.5,0.1,0.7,0C51.6,57.9,51.6,58.6,51.9,58.8z"/>
			<path fill="#C6775F" d="M46.6,28.1c0,0.1,0,0.2,0.1,0.2c1-0.5,1.4,0.4,2.1,0.7l0,0c0.1-1.3-0.8-1.4-1.7-1.6
				C46.3,27.3,47.4,28.4,46.6,28.1L46.6,28.1z"/>
			<path fill="#C4745D" d="M57.4,30.9c0.6-0.5,1-1.1,1.9-0.9c-0.5-0.5-1.2-0.6-1.7-0.5C56.6,29.7,58,30.5,57.4,30.9z"/>
			<path fill="#B5534C" d="M40.9,21.4c-0.5,0.6-0.5,1.7,0.1,2.1c0.4,0.3,0.4,0.3,0.6-0.9C41.4,22.1,41.3,21.7,40.9,21.4z"/>
			<path fill="#BE6757" d="M51.1,46.9c0.6-0.2,1.3,0.3,1.7-0.4c0.1-0.2-0.2-0.7-0.3-0.7C51.8,45.7,51.3,46,51.1,46.9z"/>
			<path fill="#BD6455" d="M46.9,50.6c0.1-0.2,0.3-0.2,0.5-0.2c0.1-0.4-0.4-0.3-0.5-0.5c0-0.2,0-0.4-0.2-0.5
				c-0.1,0.1,0.2,0.7-0.3,0.4s-0.9-0.8-1.6-0.4c0.2,0.6,0.9,0.5,1.2,1c0.3,0,0.5,0,0.7,0.2C46.7,50.7,46.8,50.7,46.9,50.6z"/>
			<path fill="#C5755E" d="M40.6,20.2c0.8,0.6,0.8,0.6,1.8-0.1c-0.4-0.8-1.2-0.3-1.8-0.3C40.5,19.9,40.5,20,40.6,20.2z"/>
			<path fill="#B5554D" d="M48.6,37.1c-0.4,0.7-0.4,1.2-1,1.5c0.5-0.2,1,0.6,1.4-0.1C49.4,37.9,48.8,37.7,48.6,37.1z"/>
			<path fill="#BF6A58" d="M50.8,44c-0.8,0.7-0.2,1.3,0,2C51,45.4,51.7,44.7,50.8,44z"/>
			<path fill="#C3735D" d="M41.3,17.4c0.1,0.6,1.4-0.4,0.7,0.8c-0.1,0.2,0.3,0.1,0.4,0.1c0.2,0.1,0.4,0.2,0.6,0
				c0.2-0.2,0.1-0.4-0.1-0.5C42.5,17.3,42,17,41.3,17.4z"/>
			<path fill="#C6775F" d="M48.8,29.1c0.2,0.4-0.5,0.9,0.2,1.3c0.2,0.1,0.5,0.3,0.7,0.1c0.4-0.5-0.6-0.8-0.2-1.4
				C49.4,28.9,49,29,48.8,29.1C48.8,29,48.8,29.1,48.8,29.1z"/>
			<path fill="#BF6A58" d="M44.2,24.4c-0.2-0.6-0.5-1-1.1-1c-0.2,0-0.6,0.2-0.5,0.5c0,0.2,0.2,0.4,0.4,0.3
				C43.4,24,43.7,24.4,44.2,24.4z"/>
			<path fill="#C06C59" d="M49.1,30.9c-0.6,0.1-0.9-1.1-1.6-0.2c-0.1,0.2-0.2,0.4-0.1,0.5c0.1,0.2,0.4,0.1,0.5,0
				C48.3,30.8,48.8,31.4,49.1,30.9z"/>
			<path fill="#BE6757" d="M48.8,53.3c-0.3-0.4,0-0.8,0-1.2c0-0.2-0.1-0.4-0.4-0.4c-0.2,0-0.5,0-0.5,0.1c-0.2,0.5,0.2,0.1,0.3,0.3
				c0.3,0.5-0.4,0.5-0.5,0.9C48.1,53.1,48.4,53.2,48.8,53.3L48.8,53.3z"/>
			<path fill="#B6554D" d="M46.2,52.8c0,0.3-0.5,0.4-0.2,0.7c0.8,0.9,0.7-0.4,1.2-0.5c0-0.2,0.2-0.5,0.1-0.5
				C46.8,52.2,46.5,52.7,46.2,52.8z"/>
			<path fill="#BE6757" d="M40.6,20.2c0-0.2,0-0.3,0-0.5l0,0c-0.2-0.4-0.3-0.8-0.7-1c-0.5,0.5-0.4,1.2-0.7,1.7
				C39.7,20.5,40.2,20.5,40.6,20.2L40.6,20.2z"/>
			<path fill="#C6775F" d="M50.1,29.1c0.3-0.4,1-0.4,0.8-1.2c-0.1-0.2-0.3-0.3-0.6-0.2C49.6,28.1,50.2,28.7,50.1,29.1z"/>
			<path fill="#B4524C" d="M37.8,26.2c0.2,0,0.4,0,0.5,0.2c0.4-0.2,0.2-0.6,0.3-0.9c-0.3-0.4-0.3-0.4-1.2,0.2c0,0.1,0,0.2,0,0.2
				C37.4,26.1,37.6,26.2,37.8,26.2z"/>
			<path fill="#C16D5A" d="M47.1,16.4c0-0.5-0.3-0.7-0.5-0.5c-0.3,0.3-0.9,0.1-0.9,0.6c0,0.3,0.4,0.3,0.6,0.2
				C46.6,16.7,47.1,16.9,47.1,16.4z"/>
			<path fill="#BC6455" d="M54.1,59.5c0.2-0.1,0.4-0.2,0.5-0.2c0.3-0.1,0.4-0.3,0.4-0.6c0-0.2-0.2-0.4-0.4-0.4
				c-0.4-0.1-0.4,0.3-0.5,0.5s-0.2,0.5-0.3,0.8C53.9,59.6,54,59.6,54.1,59.5z"/>
			<path fill="#BB6153" d="M40.2,31.2c0,0.3-0.6,0.5-0.3,0.9c0.3,0.3,0.6-0.1,0.9-0.2c0.4-0.1,0.2-0.4,0.1-0.6
				C40.7,30.8,40.4,31.1,40.2,31.2L40.2,31.2z"/>
			<path fill="#BF6B58" d="M55,61.3c0.2-0.4,0.7-0.8,0.4-1.4c-0.1-0.1-0.3,0-0.4,0.1C54.4,60.5,55,60.9,55,61.3z"/>
			<path fill="#C16E5A" d="M52.5,30.5c-0.5,0.3-1,0.1-1.1,0.5c0.5,0.1,0.2,0.8,0.3,0.8C52.3,31.8,52.1,31.1,52.5,30.5z"/>
			<path fill="#BC6354" d="M52.6,63.6c0.8,0.1,0.4-1.2,1.3-0.9C52.9,62.3,52.9,62.3,52.6,63.6L52.6,63.6z"/>
			<path fill="#BF6A58" d="M45.8,13.6c0.5,0.1,1,0.2,1.3-0.4c0.2-0.4-0.2-0.5-0.7-0.2c-0.1,0.1-0.3,0.2-0.4,0.2
				C45.9,13.4,45.8,13.4,45.8,13.6z"/>
			<path fill="#BB6154" d="M49,50.2c0,0.2,0,0.3,0,0.5c0.7-0.1,0.6,0.3,0.6,0.8c0.5-0.2,0.7-0.5,0.6-0.9C50,49.9,49.3,50.6,49,50.2z
				"/>
			<path fill="#C6775F" d="M49.2,28.5c0.4-0.1,0.3-0.3,0.3-0.5c0.1-0.4,0.8,0.2,0.7-0.4c-0.1-0.4-0.6-0.3-0.9-0.1
				C48.7,27.8,49.1,28.1,49.2,28.5z"/>
			<path fill="#BB6153" d="M48.8,53.8c0.2,0.2,0.5,0.1,0.7,0c0.1-0.3,0.4-0.7,0.1-0.9c-0.4-0.3-0.4,0.5-0.8,0.5l0,0
				C48.8,53.5,48.8,53.6,48.8,53.8z"/>
			<path fill="#BF6958" d="M53.4,8.6c0.6-0.2,0.8,0.6,1.3,0.3c0.1-0.1,0.1-0.3-0.1-0.4C54.3,8.3,54,7.8,53.4,8.6z"/>
			<path fill="#C5755E" d="M39.9,18.7c0,0.5,0.5,0.7,0.7,1c-0.1-0.5,0.4-0.9,0.2-1.5C40.5,18.4,40.2,18.6,39.9,18.7z"/>
			<path fill="#C06A58" d="M40.9,21.4c0.1,0.5,0.2,0.9,0.7,1.2C42.4,21.5,41.3,21.6,40.9,21.4z"/>
			<path fill="#C16E5A" d="M46.6,28.1c0,0,0-0.1-0.1-0.1c-0.3-0.3-0.5-0.8-1-0.4c-0.2,0.1-0.1,0.2,0,0.4
				C45.8,28.4,46.2,28.3,46.6,28.1L46.6,28.1z"/>
			<path fill="#B5554D" d="M41.6,31c0.3,0,0.6,0,1,0c0.1,0,0.2,0,0.2,0c0-1-1-0.2-1.2-0.7C41.5,30.5,41.2,30.7,41.6,31z"/>
			<path fill="#C4745E" d="M54.8,32.5c-0.4,0.1-0.9-0.2-1.2,0.3c0,0.1,0.1,0.3,0.2,0.4C54.4,33.4,54.5,32.8,54.8,32.5z"/>
			<path fill="#BE6757" d="M40.5,36.8c0-0.6-0.1-1.1,0.7-0.9c-0.4-0.5-0.7-0.4-1-0.2C39.8,36.1,40.1,36.3,40.5,36.8z"/>
			<path fill="#BB6053" d="M52,41.5c0.1-0.2,0.4-0.4,0.5-0.6c0-0.2-0.3-0.3-0.5-0.3c-0.3,0-0.5,0.1-0.4,0.4
				C51.5,41.3,51.6,41.4,52,41.5z"/>
			<path fill="#BA5E52" d="M58.9,49.6c-0.2-0.7-0.7-0.9-1.6-1.3C57.7,49.1,58.4,49.3,58.9,49.6z"/>
			<path fill="#B34F4A" d="M48.6,41.5c0,0.1,0,0.1,0,0.2c0.3,0.2,0.6,0.4,1,0.1c0.2-0.2,0-0.4-0.1-0.5C49,40.9,48.8,41.4,48.6,41.5z
				"/>
			<path fill="#B95C51" d="M51.2,57.6c0.3,0.3,0.7,0.3,1,0.1c0.2-0.2,0-0.4-0.1-0.6C51.6,56.7,51.5,57.4,51.2,57.6z"/>
			<path fill="#BA6052" d="M54.1,59.5c-0.1,0-0.2,0-0.2,0c-0.3,0.3-0.8-0.3-1,0.3c-0.1,0.2-0.1,0.3,0.2,0.3
				C53.5,60.2,54,60.1,54.1,59.5z"/>
			<path fill="#BD6555" d="M52.5,39.6c-0.1,0-0.4-0.1-0.4,0.2s0.2,0.4,0.5,0.4s0.5,0,0.5-0.4C53,39.6,52.8,39.6,52.5,39.6z"/>
			<path fill="#BE6857" d="M45.2,25.5c-0.6-0.4-1.1-0.1-1.6,0C44.1,25.8,44.6,25.8,45.2,25.5z"/>
			<path fill="#BE6857" d="M42.8,13.5c-0.5,0.2-0.7,0.5-0.8,0.9c0,0.1,0.2,0.2,0.4,0.1C42.7,14.4,43,14.1,42.8,13.5z"/>
			<path fill="#C16D5A" d="M48.6,48.2c0.2,0.1,0.3,0.2,0.5,0.2c0.2-0.1,0.4-0.3,0.6-0.4C49.3,48.1,48.6,47,48.6,48.2z"/>
			<path fill="#B95C51" d="M43.2,21c-0.1,0.3,0.1,0.4,0.3,0.3c0.3,0,0.5-0.1,0.4-0.4c0-0.2-0.1-0.4-0.4-0.4
				C43.2,20.5,43.3,20.8,43.2,21z"/>
			<path fill="#C26F5B" d="M55,17.4c0.5,0.2,0.9,0.1,1.2-0.3c0-0.1,0-0.3-0.1-0.3C55.4,16.6,55.4,17.3,55,17.4z"/>
			<path fill="#C06B59" d="M56.1,47.6c0.6-0.4,0.8-0.7,0.7-1.1c0-0.1-0.3-0.2-0.4-0.1C55.9,46.7,56.3,47.1,56.1,47.6z"/>
			<path fill="#B5534C" d="M56.6,50.4c-0.1-0.5,0.6-0.6,0.2-1c-0.2-0.2-0.5-0.3-0.6-0.1C56.1,49.6,56.2,50,56.6,50.4z"/>
			<path fill="#BF6958" d="M62.3,62.5c0,0.4,0.1,0.5,0.3,0.5s0.3-0.2,0.3-0.4c0-0.3-0.1-0.5-0.4-0.4C62.3,62.3,62.3,62.5,62.3,62.5z
				"/>
			<path fill="#BE6756" d="M49.5,7c-0.6,0.1-0.9,0.3-1,0.7c0,0,0.1,0.1,0.1,0.2C49.2,7.9,49.3,7.6,49.5,7z"/>
			<path fill="#BE6756" d="M55.3,25.2c-0.7-0.2-1.2-0.3-1.6-0.4C54.1,25.3,54.4,25.7,55.3,25.2z"/>
			<path fill="#C16F5A" d="M39.7,27.1c0-0.9,0-0.9-0.9-0.5C39,27.1,39.5,26.8,39.7,27.1L39.7,27.1z"/>
			<path fill="#B7584F" d="M47.4,53.5c-0.7,0.3,0,0.5,0.1,0.6c0.4,0.7,0.5-0.2,0.8-0.1C48.1,53.6,47.6,53.9,47.4,53.5z"/>
			<path fill="#C06A58" d="M48.4,13.1c0.2-0.3,0.4-0.6,0.6-0.9C48.1,12.4,48.1,12.4,48.4,13.1z"/>
			<path fill="#BB6153" d="M54.5,55c-0.5-0.5-0.8,0-1.2,0.2C53.8,55.6,54.2,55.4,54.5,55z"/>
			<path fill="#B7564F" d="M51.2,61.9c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.4-0.3,0.2-0.6c-0.1-0.1-0.2-0.2-0.3-0.1
				c-0.3,0.1-0.2,0.4-0.3,0.6C51.2,61.6,51.2,61.8,51.2,61.9L51.2,61.9z"/>
			<path fill="#BF6A58" d="M55.1,63.7c0,0.3,0.1,0.5,0.4,0.5c0.2,0,0.2-0.2,0.2-0.3c0-0.2-0.2-0.4-0.4-0.4
				C55.2,63.4,55.1,63.6,55.1,63.7z"/>
			<path fill="#C16F5A" d="M39.7,27.1c-0.3,0.2-0.3,0.6-0.4,1C40.1,27.7,40.1,27.7,39.7,27.1L39.7,27.1z"/>
			<path fill="#C26F5B" d="M56.5,54.3c-0.1,0.3-0.7,0.1-0.6,0.6c0,0.1,0.3,0.2,0.4,0.2C56.6,54.9,56.9,54.6,56.5,54.3L56.5,54.3z"/>
			<path fill="#B85B51" d="M50.7,52.8c-0.1-0.3,0.4-0.6,0-0.8c-0.1-0.1-0.5,0.1-0.5,0.2C50.1,52.6,50.3,52.8,50.7,52.8L50.7,52.8z"
				/>
			<path fill="#BC6455" d="M50.5,32.3c0-0.4-0.1-0.6-0.4-0.7c-0.1,0-0.3,0.2-0.3,0.3C49.9,32.2,50.2,32.3,50.5,32.3z"/>
			<path fill="#C06C59" d="M44.9,21.8c-0.7,0-1,0-1.4,0.3C44,22.5,44.3,22.2,44.9,21.8z"/>
			<path fill="#BB6153" d="M43.5,15.5c-0.4-0.2-0.6-0.3-1-0.3c0,0.1-0.1,0.2,0,0.2C42.7,15.7,43,16,43.5,15.5z"/>
			<path fill="#BE6757" d="M40.9,28.8c0-0.1,0-0.2,0-0.2c-0.1-0.6-0.4-0.2-0.7-0.2c-0.1,0-0.2,0.2-0.2,0.2
				C40.2,29,40.6,28.8,40.9,28.8z"/>
			<path fill="#BB6153" d="M46.9,44.9c0,0.2,0,0.4-0.2,0.5c0.1,0.6,0.5,0.7,1,0.5l0,0C47.5,45.4,47.1,45.2,46.9,44.9z"/>
			<path fill="#BF6A58" d="M56.3,9.2c0,0.3,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3C56.5,9,56.4,9.2,56.3,9.2z"/>
			<path fill="#BD6555" d="M46.9,49.9c0.2,0.2,0.3,0.3,0.5,0.5c0.8,0,0.3-0.4,0.2-0.7C47.3,49.8,47.1,49.8,46.9,49.9z"/>
			<path fill="#BB6254" d="M39.2,16.5c0,0.2,0.1,0.3,0.3,0.2c0.1-0.1,0.2-0.2,0.3-0.3c0-0.2-0.2-0.2-0.3-0.2
				C39.3,16.2,39.2,16.3,39.2,16.5z"/>
			<path fill="#BF6A58" d="M41.7,24.9c0.2,0.4,0.4,0.4,0.5,0.3c0.2-0.1,0.4-0.2,0.3-0.4c0-0.2-0.2-0.2-0.4-0.1
				C41.9,24.7,41.8,24.8,41.7,24.9z"/>
			<path fill="#BD6555" d="M58.1,43c-0.4,0-0.7,0.2-0.8,0.6C57.9,43.7,58.1,43.5,58.1,43C58.2,43,58.1,43,58.1,43z"/>
			<path fill="#B5544D" d="M38,28.1c0.2,0.3,0.5,0.7,1,0.5C38.9,27.9,38.4,28.1,38,28.1z"/>
			<path fill="#BB6053" d="M50.6,30.8c0.2-0.1,0.4-0.2,0.3-0.4c0-0.1-0.2-0.3-0.3-0.3c-0.2-0.1-0.3,0.1-0.3,0.2
				C50.4,30.5,50.5,30.7,50.6,30.8z"/>
			<path fill="#BC6354" d="M56.2,56.4c-0.1-0.5-0.2-0.9-0.5-0.8c-0.4,0.1-0.1,0.3,0,0.5C55.9,56.2,56,56.2,56.2,56.4z"/>
			<path fill="#BC6355" d="M51.7,55c0.2-0.2,0.3-0.5,0.5-0.7C51.6,54.2,51.1,54.2,51.7,55z"/>
			<path fill="#BF6B58" d="M52.6,41.8c0.1,0,0.2,0.1,0.4,0.1s0.3-0.1,0.2-0.3c-0.1-0.1-0.3-0.1-0.4-0.1
				C52.7,41.5,52.5,41.6,52.6,41.8z"/>
			<path fill="#BA5E52" d="M55.8,42.3c0.2,0,0.4,0.1,0.3-0.1c0-0.1-0.2-0.3-0.3-0.3s-0.3,0.1-0.4,0.2C55.4,42.3,55.7,42.2,55.8,42.3
				z"/>
			<path fill="#BA5E52" d="M54.1,53.5c0,0.2-0.4,0.5,0,0.6s0.5-0.3,0.5-0.6C54.4,53.5,54.2,53.5,54.1,53.5z"/>
			<path fill="#B95C51" d="M48.7,26.7c-0.1,0.1-0.3,0.2-0.2,0.2c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0,0.3-0.2
				C48.9,26.9,48.8,26.8,48.7,26.7z"/>
			<path fill="#C5755E" d="M40.6,20.2c-0.5,0-1,0-1.4,0.2c0.1,0.2,0.3,0.2,0.5,0.2c0.3,0,0.5,0,0.7-0.2
				C40.5,20.3,40.6,20.3,40.6,20.2z"/>
			<path fill="#B6564E" d="M42.6,29.3c-0.2,0.1-0.3,0.3-0.3,0.6c0,0,0.2,0.1,0.3,0.1c0.3-0.1,0.3-0.4,0.3-0.7
				C42.7,29.3,42.6,29.3,42.6,29.3z"/>
			<path fill="#B5544D" d="M43.7,31.7c0.4-0.8-0.3-0.7-0.7-0.7C43.2,31.3,43.4,31.5,43.7,31.7z"/>
			<path fill="#BE6756" d="M55.4,18.6c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2,0.1,0.3,0.3,0.2c0.1-0.1,0.2-0.2,0.3-0.3
				C55.7,18.7,55.6,18.6,55.4,18.6z"/>
			<path fill="#BD6555" d="M50.6,35c0.2,0,0.3-0.1,0.2-0.3c0-0.1-0.2-0.2-0.2-0.2c-0.1,0.1-0.3,0.2-0.3,0.3
				C50.3,34.9,50.5,35,50.6,35z"/>
			<path fill="#B5544D" d="M41.1,29.8c0.2,0,0.5,0.4,0.6,0c0.1-0.3-0.4-0.3-0.6-0.5C41.1,29.5,41.1,29.6,41.1,29.8z"/>
			<path fill="#BE6757" d="M40.4,20.4c-0.3,0-0.6,0-0.7,0.2C40.1,21,40.3,20.9,40.4,20.4z"/>
			<path fill="#BD6656" d="M53.5,11.4c0,0.2,0.1,0.3,0.2,0.2c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1-0.1-0.2-0.2-0.2
				C53.7,11.2,53.6,11.4,53.5,11.4z"/>
			<path fill="#BB6153" d="M46.6,47.8c-0.1,0.1-0.4,0.1-0.4,0.2c0,0.4,0.4,0.4,0.6,0.5C46.8,48.3,46.7,48,46.6,47.8z"/>
			<path fill="#BA5F52" d="M49.7,25.2c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.2-0.2,0.2-0.3c0-0.1-0.2-0.2-0.2-0.2
				C49.9,24.9,49.8,25,49.7,25.2z"/>
			<path fill="#BF6957" d="M46.5,24.5c0,0-0.1,0.1-0.2,0.3c0.1,0,0.2,0.2,0.2,0.1c0.1-0.1,0.2-0.2,0.3-0.3
				C46.8,24.4,46.7,24.4,46.5,24.5z"/>
			<path fill="#BC6355" d="M51.7,55.7c0-0.2,0-0.5,0-0.7c-0.3,0.1-0.5,0.3-0.4,0.7C51.3,55.9,51.5,55.7,51.7,55.7z"/>
			<path fill="#C26F5B" d="M56.5,54.3c0.1-0.2,0.2-0.4,0-0.6c-0.1-0.1-0.3-0.1-0.3,0.1C56,54,56.3,54.1,56.5,54.3L56.5,54.3z"/>
			<path fill="#BC6254" d="M57.1,61.4c-0.2,0-0.3,0.2-0.3,0.3s0.1,0.3,0.1,0.3c0.2,0,0.3-0.2,0.3-0.3C57.2,61.6,57.1,61.5,57.1,61.4
				z"/>
			<path fill="#C3735D" d="M40.5,16.9c0.3,0.2,0.4,0.3,0.4,0.3c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.2-0.1-0.2-0.2-0.1
				C40.8,16.7,40.7,16.8,40.5,16.9z"/>
			<path fill="#C16E5A" d="M44.1,23.2c0,0,0.1,0.2,0.2,0.2c0.1,0.1,0.2-0.1,0.2-0.2S44.4,23,44.3,23C44.1,22.9,44.1,23,44.1,23.2z"
				/>
			<path fill="#BB6153" d="M44.5,20.5c0,0.1,0.1,0.3,0.2,0.2c0.1,0,0.1-0.2,0.2-0.3c0-0.1-0.1-0.2-0.2-0.2
				C44.6,20.3,44.5,20.4,44.5,20.5z"/>
			<path fill="#BD6656" d="M53.5,49.7c0.2,0.2,0.3,0.3,0.4,0.3c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
				C53.7,49.6,53.6,49.7,53.5,49.7z"/>
			<path fill="#B4524C" d="M40.8,35c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.2-0.2-0.2-0.2
				C41.1,34.7,41.1,34.8,40.8,35z"/>
			<path fill="#B95B50" d="M53.5,52.5c0,0,0.2-0.1,0.2-0.2c0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
				C53.2,52.4,53.3,52.5,53.5,52.5z"/>
			<path fill="#B95C51" d="M53.7,60.9c0,0.2,0.1,0.3,0.2,0.2c0.1,0,0.1-0.2,0.1-0.3s-0.1-0.2-0.2-0.1C53.8,60.7,53.7,60.8,53.7,60.9
				z"/>
			<path fill="#B7584F" d="M54.6,36.5c-0.1,0.1-0.2,0.2-0.2,0.3s0.1,0.2,0.1,0.3c0.1-0.1,0.2-0.1,0.2-0.2
				C54.7,36.8,54.6,36.7,54.6,36.5z"/>
			<path fill="#BE6757" d="M58.3,65.4c0.1,0.1,0.2,0.2,0.3,0.1c0.1,0,0.2-0.1,0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.2
				C58.5,65.1,58.4,65.3,58.3,65.4z"/>
			<path fill="#BA6053" d="M53.3,28.6c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0,0.2-0.1,0.1-0.2c-0.1-0.1-0.2-0.2-0.4-0.3
				C53.3,28.5,53.3,28.5,53.3,28.6z"/>
			<path fill="#BE6857" d="M48,39.4c0.1,0,0.2,0.1,0.3,0.1s0.2-0.2,0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1C48.1,39.2,48,39.3,48,39.4z"/>
			<path fill="#BF6958" d="M39,30.6c0.1-0.1,0.2-0.2,0.1-0.3c0-0.1-0.1-0.2-0.2-0.2c-0.1,0.1-0.2,0.2-0.2,0.2
				C38.8,30.4,38.9,30.5,39,30.6z"/>
			<path fill="#BB6153" d="M40.2,31.2c0-0.2-0.1-0.4-0.1-0.5c-0.1,0-0.2,0.1-0.3,0.1C39.7,31.2,39.9,31.2,40.2,31.2L40.2,31.2z"/>
			<path fill="#B7574E" d="M55.9,52.8c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2S55.8,52.8,55.9,52.8z"/>
			<path fill="#C77960" d="M45.2,45.6c-1-0.3,0.3-0.6,0-1c0.4-0.1,0.3-0.5,0.4-0.7c0.3,0.1,0.1,0.6,0.5,0.5c0.4-0.4,0.8-0.8,1.2-1.2
				c0.1-0.2,0.4-0.5,0-0.7c-0.6,0.3-1.4,0.9-1.2-0.6c0.1-0.6-0.4-0.5-0.8-0.7l-0.1,0.1c-0.1,0.3-0.1,0.6-0.1,0.9
				c-0.6,1.2-0.7,1.3-2.2,0.7c-0.5,0-1,0-1.5-0.1c-0.2,0.8,0.3,1.1,0.9,1.4c0.3,0.2,0.2,0.4,0.2,0.7c0,0.2-0.2,0,0,0
				c0,0,0.1-0.1,0.1-0.2c0-0.2,0-0.3-0.1-0.4c-0.2-0.1-0.6-0.1-0.6,0.1c-0.4,1.1-1,0.4-1.5,0.3c-0.5,0-0.8,0.3-1,0.8
				c0,0.2,0,0.3,0,0.5c0.2,0,0.3,0,0.5,0c0.5-0.3,0.7,0.1,0.7,0.5c0.2,0.1,0.5,0.2,0.7,0.2c0.1,0.1,0.2,0.1,0.3,0h0.1
				c1.2,0,1.2,0,2.7-1c0,0.5-0.5,0.9-0.2,1.5c0.6,0,0.5-0.8,1-0.9C45.4,46.1,45.4,45.9,45.2,45.6z"/>
			<path fill="#C77961" d="M47.6,45.8c-0.4,0-0.7-0.2-1-0.5c-0.4,0-0.9-0.2-1.2,0.2c-0.1,0.7,0.5,0.9,1,1.2c0,0.1,0,0.2,0,0.2
				C47.1,46.9,47.5,46.5,47.6,45.8z"/>
			<path fill="#C77A61" d="M43.5,49.9c0.2,0.1,0.5,0.2,0.7,0.2c0.1,0,0.2-0.1,0.2-0.2c-0.2-0.2-0.5-0.4-0.2-0.7
				c0.3-0.6,0.9-1,1.5-1.4c-0.9-0.3-1.2,0.4-1.8,0.5l0,0C43.8,48.8,43.7,49.4,43.5,49.9z"/>
			<path fill="#C06B59" d="M41.6,46.8c-0.1,0-0.2,0-0.3,0c-0.1,0.7,0.8,1.5-0.2,2.2c-0.1,0,0,0.3,0,0.4c0.2,0.2,0.4,0.1,0.5-0.1
				c0.4-0.4,0.9-0.9,0.3-1.5C41.8,47.6,41.5,47.2,41.6,46.8z"/>
			<path fill="#BB6153" d="M46.4,46.8c-0.1-0.6-0.7-0.7-1-1.2c-0.1,0-0.2,0-0.3,0c0,0.2,0,0.5,0,0.7C45.5,46.6,45.5,47.9,46.4,46.8z
				"/>
			<path fill="#B5534D" d="M40.6,46.6c0.2-0.3,0.5-0.8,0-1c-0.5-0.3-0.5,0.3-0.7,0.5C40.1,46.2,40.4,46.4,40.6,46.6z"/>
			<path fill="#C77A62" d="M43.2,48.1c-0.2,0.4-0.4,0.6-0.7,1C43.1,49,43.4,48.8,43.2,48.1z"/>
			<path fill="#BF6A59" d="M44,48.3c0-0.2,0.1-0.5-0.2-0.6c0,0-0.2,0-0.2,0.1C43.6,48,43.8,48.1,44,48.3L44,48.3z"/>
			<path fill="#B7584F" d="M36.2,37.3c0.1,0,0.2,0,0.3,0s0.2-0.1,0.2-0.2c0-0.2-0.1-0.2-0.2-0.1C36.3,37,36.1,37,36.2,37.3z"/>
			<path fill="#B5544D" d="M44.5,51.6c0-0.2,0-0.3,0-0.5c-0.6-0.7-0.6-0.7-1.8-0.1c0,0.3,0.7,0,0.5,0.5c-0.1,0.3-0.1,0.6,0.2,0.7
				c0.4,0.1,0.2-0.4,0.3-0.5C43.9,51.7,44.2,51.7,44.5,51.6z"/>
			<path fill="#BF6A58" d="M44.5,49.9c-0.1,0.1-0.2,0.2-0.2,0.2c0.1,0.2,0.2,0.4,0.4,0.3C44.8,50.3,44.7,50.1,44.5,49.9z"/>
			<path fill="#BF6A58" d="M44.7,52.8c-0.2,0.1-0.4,0.2-0.4,0.5c0,0,0.1,0.1,0.2,0.1C44.8,53.3,44.7,53,44.7,52.8L44.7,52.8z"/>
			<path fill="#B6544D" d="M44.7,54.7c0.2,0,0.4,0.1,0.5-0.1c0-0.1-0.2-0.2-0.3-0.4C44.5,54.2,44.6,54.5,44.7,54.7z"/>
			<path fill="#BA5E52" d="M41.8,51.1c0.2-0.1,0.4-0.2,0.4-0.5c0,0-0.1-0.1-0.2-0.1C41.7,50.6,41.9,50.9,41.8,51.1L41.8,51.1z"/>
			<path fill="#C77B62" d="M39.2,38.7c-0.1,0.3-0.4,0.5-0.7,0.5c0.2,0.3,0.9,0.5,0.2,1c-0.1,0.1,0,0.6,0.2,0.8
				c0.9,0.8,0.5,1.7,0.4,2.5c-0.4,1,0.3,1.2,1,1.3c0.4,0.2,0.8,0.3,0.8-0.3c0-0.5,0.2-1,0.3-1.5c0.1-0.8,0.4-0.5,0.8-0.3
				c1.3-0.5,0-1,0-1.5c-0.3-0.3-0.5-0.1-0.8,0c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.1-0.4,0.4-0.6,0.6c0-0.1,0-0.1,0-0.2
				c0-0.6,0.5-0.2,0.7-0.4c0.2-0.4-0.1-0.8,0.2-1.2c0.1-0.3,0-0.6-0.2-0.8c-0.1,0.1-0.4,0.2-0.4,0.2C40.3,38.5,40.3,38.5,39.2,38.7z
				"/>
			<path fill="#B5524C" d="M40.4,44.6c-0.4-0.4-1.4-0.2-1.2-1.2c-0.5,0-0.7-0.6-1.3-0.7c0,0.5-0.1,1-0.1,1.4
				c0.7,0.3,1.2,0.9,1.7,1.4c0.3-0.2,0.6-0.5,1-0.7C40.5,44.8,40.5,44.8,40.4,44.6C40.4,44.7,40.4,44.6,40.4,44.6z"/>
			<path fill="#B5544D" d="M46.2,54.5c-0.2,0-0.3,0-0.5,0c-0.1,0.4-0.2,0.8-0.2,1.2c0.3-0.1,0.6-0.2,0.9,0c0.3,0.2,0.3,0,0.3-0.2
				C46.2,55.3,45.8,55.1,46.2,54.5z"/>
			<path fill="#BC6354" d="M47.1,56c0.1,0.1,0.1,0.2,0.2,0.3c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.1-0.2
				C47.3,55.8,47.2,55.9,47.1,56z"/>
			<path fill="#E4E3E3" d="M10.9,139c0.3-0.2,0.6-0.4,0.3-0.9C10.7,138.3,11,138.7,10.9,139L10.9,139z"/>
			<path fill="#DFDEDF" d="M12.8,137.3c-0.2,0.1-0.4,0.2-0.4,0.5c0,0,0.1,0.1,0.2,0.1C12.9,137.8,12.8,137.5,12.8,137.3L12.8,137.3z
				"/>
			<path fill="#BD6656" d="M37.8,26.2C37.7,26,37.5,26,37.3,26c-0.2,0.2-0.5,0.5-0.7,0.7C37,26.6,37.4,26.5,37.8,26.2z"/>
			<path fill="#F9F9F9" d="M127.5,239.5c0,0.2,0,0.5,0,0.7c0.2,0.1,0.3,0.7,0.6,0.3C128.5,240,127.9,239.8,127.5,239.5z"/>
			<path fill="#FDFDFE" d="M10.4,143.3c-0.2-0.1-0.2-0.5-0.5-0.4s-0.2,0.4-0.2,0.6C9.9,143.5,10.2,143.6,10.4,143.3z"/>
			<path fill="#CB8166" d="M83.3,43.2c0.2,0.9-0.9,0.2-0.9,0.7l0,0c0.2,0.2,0.1,0.5,0.2,0.8c0.2,0.7,0.4,0.7,1,0
				c-0.2,0.5-0.2,1,0.3,1.4c0.1,0,0.6-0.4,0.2,0.2c-0.2,0.2-0.2,0.6-0.3,0.9c-0.1,0.2-0.1,0.5,0.1,0.6c0.3,0.2,0.5,0,0.7-0.3
				c0.2,0.1,0.3,0.2,0.5,0.2c0.7,0.3,1.3,0.5,1.9-0.1c0.1-0.1,0-0.3,0.1-0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.3,0.3,0.6,0.6,0.9
				c0.2,0.2,0.3,0.4,0.7,0.4c0.2-0.1,0.3-0.3,0.3-0.5c0-0.1-0.2-0.2-0.2-0.3s0.2,0,0.3,0c0.6-0.2,0.6-0.6,0.3-1.2
				c0.7,0.7-0.3,1.5,0.4,2c0.1,0.1,0.3,0.2,0.5,0.1c0.3,0,0.5,0,0.8,0c0.5-0.1,0.5-0.5,0.3-0.8c-0.1-0.5,0.4-0.2,0.5-0.4
				c0.2-0.1,0,0.1,0.1,0.1c0.5,0.5,1,0.6,1.5,0.2l0,0c0.2-0.4,0.3-0.8,0.5-1.2c0.2-0.2,0.3-0.4,0.2-0.7c0.1-0.4,0.1-0.9-0.4-0.8
				c-1,0.1,0.2,0.8-0.3,1.1c-0.4,0-0.3-0.5-0.7-0.6c-0.3,1.5-0.8-0.2-1.2,0.1c-0.2,0.2-0.3,0.2-0.5,0c0-0.1-0.1-0.3-0.1-0.3
				c0.5-1.1,0.4-1.2-0.9-1.2c-1.5-0.1-0.7-1.1-0.7-1.8c0.2,0.2,0.6,0.2,0.4,0.6c-0.3,0.6,0.2,0.6,0.5,0.7c0.6,0.3,0.2-0.3,0.3-0.4
				c0.4-0.5,1-0.6,1.6-0.6l0.1-0.1c0-0.1,0-0.2,0-0.3c-0.1-0.3-0.3-0.6-0.4-1c-0.3-0.2-0.2-0.5-0.1-0.8c0-0.1,0-0.2,0-0.2
				c-0.5-0.1-0.8,0.2-1.2,0.4c-0.2-0.5-0.2-1.3-1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.4-0.9,0.2-1.3,0.6
				c-0.1,0.1,0.1,0.2-0.1,0.1c-0.1-0.1,0.1-0.1,0.1-0.2c-0.3-1-1-0.5-1.5-0.4c-0.3,0.2-0.9-0.2-1,0.5c-0.1,0.4,0.3,0.4,0.4,0.6
				c-0.5,0-1.1,0-1.5,0.5c0,0.1-0.1,0.2-0.1,0.3C83.8,43,83.6,43.1,83.3,43.2C83.3,43.1,83.3,43.1,83.3,43.2z"/>
			<path fill="#CA8065" d="M92,42.7c-0.9,0.8-0.4,1.7-0.1,2.7c-0.1-0.6,0.4-0.8,0.6-1.2c0-0.2,0.3-0.4,0.3-0.6c0-0.1,0-0.2-0.1-0.3
				c-0.1-0.2-0.3-0.4-0.5-0.6C92.2,42.6,92.1,42.6,92,42.7z"/>
			<path fill="#DBAF9F" d="M94.9,43.9c0.1-0.2,0.2-0.3,0.2-0.5l0,0c-0.3-0.2,0-1.5-1-0.5l0,0c0.1,0.7-0.8,0.7-1,1.2
				c0.2,0.3,0.6,0.5,0.6,1.3C94.2,44.6,94.2,44,94.9,43.9z"/>
			<path fill="#D29781" d="M93.2,44.2c0.5-0.2,1.3-0.3,1-1.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2-0.3-0.5-0.4-0.6,0.1
				c0,0.5-0.4,0.5-0.6,0.7c0,0.3-0.5,0.4-0.2,0.7C92.7,44,92.9,44,93.2,44.2z"/>
			<path fill="#DCB4A5" d="M95.1,46.1c0.3,0,0.6,0,1,0c-0.1-0.3,0.5-1.2-0.5-0.6c-0.7,0.4-0.2-1-0.7-0.4
				C94.6,45.6,95,45.8,95.1,46.1z"/>
			<path fill="#D8A794" d="M93.9,46.1c-0.1,0.2-0.2,0.5-0.2,0.7C94.2,46.7,94.3,46.5,93.9,46.1z"/>
			<path fill="#D6A390" d="M90.8,45.9c0,0.2-0.2,0.4,0.1,0.4c0.3,0.1,0.4-0.2,0.4-0.5C91.1,45.9,91,45.9,90.8,45.9z"/>
			<path fill="#D39882" d="M87.7,39.1c0.2,0.4,0,1,0.7,1c0.5-0.2,0.7-0.8,1.2-1c0.8-1.1-0.6-0.6-0.7-1c-0.4,0.2-0.5,0.7-0.7,1
				C88,39.1,87.8,39.1,87.7,39.1z"/>
			<path fill="#CD886F" d="M88.1,39.1c0.4-0.2,0.7-0.5,0.7-1c-0.2-0.2-0.2-0.6-0.5-0.6c-0.4,0-0.3,0.5-0.3,0.8
				C88,38.6,88.1,38.9,88.1,39.1z"/>
			<path fill="#CC866C" d="M89.6,39.1c-0.8-0.1-1,0.4-1.2,1l0,0c0.2,0.2,0.4,0.4,0.7,0.2c0.1,0,0.2,0,0.3,0
				C88.9,39.8,89.7,39.5,89.6,39.1z"/>
			<path fill="#CF8D74" d="M92.5,38.5c-0.1,0.1-0.4,0.2-0.3,0.3c0,0.3,0.3,0.3,0.5,0.3c0.1,0,0.4,0,0.3-0.2
				C93,38.7,92.9,38.4,92.5,38.5z"/>
			<path fill="#CE8C73" d="M91.8,47.5c-0.3,0-0.6,0-0.9,0c-0.2,0-0.4-0.2-0.4,0.1c0,0.1,0.2,0.3,0.4,0.4c0.3,0.1,0.4,0.3,0.3,0.6
				s-0.3,0.2-0.6,0.1c-0.2-0.2-0.3-0.3-0.5,0c-0.2,0.2-0.2,0.4-0.1,0.6c0.1,0.2,0.3,0.3,0.6,0.5c-0.2-0.1-0.3-0.2-0.4-0.3
				c-0.3-0.2-0.3-0.6-0.5-0.8c0-0.4-0.5-0.8,0.2-1.3c0.4-0.2-0.4-0.9-1.5-0.9c0.9,0.7,0.9,0.7,0.3,1.2c-0.2,0.2-0.1,0.3,0,0.5
				c-0.1,0.2-0.2,0.3-0.2,0.5c-0.2,0.3-0.5,0.6-0.9,0.8l-0.1,0.1c0.2,0.3,0.8,0.4,0.7,1c0.1,0.1,0.3,0.2,0.4,0.1
				c0.1,0,0.1-0.2,0.2-0.1c0.1,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0,0.2,0,0.3,0,0.5c0.6-0.5,0.8,0.3,1.2,0.4
				c0.5-0.5-0.6-1,0-1.6c0.4,1.6,1.4,0,2,0.4c0,0,0.1-0.2,0-0.4c-0.3-0.4-0.2-0.7,0.3-0.9c0.7-0.3,1.3-0.7,1.1-1.6
				C92.7,48,92.1,48.2,91.8,47.5L91.8,47.5z"/>
			<path fill="#D2967F" d="M93.2,51.6c-0.1-0.4-0.1-0.8,0-1.2c-0.2-0.1-0.6-0.4-0.7,0C92.4,50.9,92.6,51.4,93.2,51.6z"/>
			<path fill="#CC856A" d="M92.7,43.4c0.6,0.2,1.3,0.6,0.8-0.6c-0.1-0.3,0.2-0.1,0.4-0.1c0-0.2,0-0.3,0-0.5c-0.2,0-0.3,0-0.5,0
				c-0.3-0.2-0.1-0.5-0.3-0.7c-0.3-0.2-0.8-0.3-0.9-0.7c-0.5,0-0.5,0.4-0.5,0.7c-0.2,0.4,0.8,0.5,0.3,1c0,0.1,0,0.2,0,0.2
				c0.1,0,0.2,0,0.2,0C92.3,43,92.9,43,92.7,43.4L92.7,43.4z"/>
			<path fill="#DBAF9F" d="M91.8,41.5c0.2-0.2,0.3-0.5,0.5-0.7c-0.1-0.6-0.4-0.4-0.7-0.2c-0.8,0.3-0.2,0.6,0,0.9
				C91.6,41.6,91.7,41.7,91.8,41.5z"/>
			<path fill="#DBAF9E" d="M93.2,41.5c-0.1,0.3-0.4,0.7,0.3,0.7c0-0.2,0.1-0.4,0.1-0.7c0-0.1,0.2-0.3,0-0.3S93.3,41.4,93.2,41.5z"/>
			<path fill="#CF8D75" d="M81.7,43.2c0.5,0,0.7-0.3,1-0.7C81.5,41.6,82,43,81.7,43.2z"/>
			<path fill="#D59F8A" d="M84.1,42.5c0.4-1,1.3-0.4,1.9-0.5c-0.1-0.6-0.7-0.6-1-0.9c-0.2,0.1-0.3,0.2-0.5,0.2c-0.5,0-0.5,0.6-1,0.7
				C83.5,42.4,83.7,42.6,84.1,42.5z"/>
			<path fill="#DBB1A1" d="M84.1,42.5c-0.2-0.2-0.3-0.3-0.5-0.5c-0.4,0.1-0.4,0.4-0.2,0.7C83.6,42.8,84,43.1,84.1,42.5L84.1,42.5z"
				/>
			<path fill="#D59F8A" d="M84.1,42.5c-0.2,0.3-0.5,0.2-0.7,0.2c0,0.1,0,0.2,0,0.2C83.6,42.8,84.3,43.3,84.1,42.5z"/>
			<path fill="#DAAE9D" d="M84.5,47.5c-0.3,0.1-0.9,0.4-0.6-0.3c0.1-0.3,0.3-0.6,0.4-1c0.1-0.5,0.2-0.5-0.3-0.5
				c-0.2,0-0.4-0.6-0.3-0.9c0.1-0.2,0.7-0.3,0.3-0.6c-0.4-0.3-0.4,0.3-0.6,0.5c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4-0.3-0.3-0.9-0.8-1.1
				c-0.2,1,0.3,1.8,0.9,2.4c0.4,0.4,0.4,0.6,0.1,1c0,0.4,0,0.7,0.5,0.8C84.2,48.2,84.5,48,84.5,47.5z"/>
			<path fill="#D8A693" d="M82.5,47.2c0.1-0.1,0.2-0.1,0.2-0.2c0-0.3-0.2-0.2-0.3-0.2c-0.1,0-0.3,0.1-0.3,0.1
				C82.1,47.2,82.2,47.2,82.5,47.2z"/>
			<path fill="#DAAE9E" d="M87,47.6c-0.6,0.5-1.3,0-1.9,0.2c0.2,0.4,0.3,0.8,0.5,1.2c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.3-0.3
				c0.1-0.2,0.2-0.3,0.4-0.2c0.4-0.1,0.7-0.3,0.7-0.7C87.2,47.7,87.1,47.6,87,47.6z"/>
			<path fill="#CB8166" d="M85.7,49c-0.1,0-0.2,0-0.2,0c-0.2,0.1-0.2,0.3-0.3,0.5c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.2,0,0.3,0l0,0
				C86,49.5,86.2,49.2,85.7,49z"/>
			<path fill="#D09078" d="M87.4,41.3c0.4-0.1,0.8-0.2,1.2-0.2c0.7,0,0.2-0.3,0.2-0.6c-0.2-0.2-0.5-0.2-0.7,0
				c-0.6,0.1-1-0.7-1.7-0.5c-0.3,0-0.5,0.2-0.5,0.5c0.2,0,0.3,0.5,0.5,0.3c0.5-0.7,0.6,0.1,0.9,0.2C87.4,41.1,87.4,41.2,87.4,41.3
				L87.4,41.3z"/>
			<path fill="#CF8F77" d="M85.7,49c0.2,0.2,0.1,0.5,0,0.7c0.6-0.5,1.1,0,1.7,0l0,0c0.1-0.1,0.2-0.2,0.1-0.4
				c-0.2-0.3,0.2-0.4,0.2-0.6c0.1-0.1,0.1-0.3,0.1-0.5c-0.2-0.2-0.3-0.5-0.5-0.7c-0.2-0.1-0.3,0-0.3,0.2c-0.1,0.3-0.4,0.6-0.7,0.7
				c-0.1,0.1-0.3,0.1-0.5,0.2C85.9,48.8,85.8,48.9,85.7,49z"/>
			<path fill="#DAAC9B" d="M88.6,50.6c-0.2,0-0.3,0-0.5,0c-0.3,0.2-0.2,0.3,0,0.5c0.1,0,0.3,0,0.4,0c0-0.1,0-0.2,0-0.2
				C88.7,50.8,88.7,50.8,88.6,50.6C88.7,50.7,88.7,50.6,88.6,50.6z"/>
			<path fill="#DAAC9B" d="M88.2,40.6c0.2,0,0.5,0,0.7,0c0.1-0.1,0.2-0.2,0.2-0.2c-0.2-0.1-0.5-0.2-0.7-0.2
				C88.3,40.2,88.2,40.4,88.2,40.6z"/>
			<path fill="#D29781" d="M94.2,43c0.7-0.5,0.6,0.5,1,0.5c-0.1-0.3,0.3-0.7-0.1-0.9C94.7,42.4,94.2,42.4,94.2,43z"/>
			<path fill="#CA7F64" d="M41.4,39.1c0,0.3,0,0.6,0,0.9c0.2,0.2,0.5,0.2,0.7,0c0.5,0.2,1,0,1.6-0.1c-0.4-0.2-0.6-0.3-0.9-0.4
				c0.1-0.3,0.7-0.1,0.5-0.6c0-0.1-0.3,0-0.4-0.1C42.4,39.4,41.7,38.3,41.4,39.1z"/>
			<path fill="#CA7F64" d="M42.8,37.7c-0.1,0-0.2,0-0.2,0c-0.7,0.5,0.2,0.6,0.2,1C43.2,38.3,42.9,38,42.8,37.7z"/>
			<path fill="#C77960" d="M45,40.8c-0.2,0-0.3-0.3-0.5,0c-0.1,0.4,0.1,0.7,0.3,1.1c0.5,0.1,0.5-0.3,0.6-0.6l0,0
				C45.3,41.1,45.1,41,45,40.8z"/>
			<path fill="#C26F5B" d="M42.1,41.1c0,0.3,0,0.5,0.3,0.7c0.4,0.4,0.2,0.6-0.2,0.7c-0.5-0.2-0.7,0-0.5,0.5c0.5,0.2,0.9,0.6,1.4,0.2
				c1.3,0.3,1.3,0.2,1.3-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2-0.1-0.7,0.3-0.6-0.4c-0.4-0.2-0.8-0.5-1.2-0.7
				C42.2,41,42.1,41,42.1,41.1z"/>
			<path fill="#B85A50" d="M43.5,41.8c-0.1,0.3-0.9,0.2-0.6,0.7c0.4,0.6,0.7-0.1,1-0.2c0.2-0.1,0.3-0.2,0.5-0.3
				c0.1,0,0.2-0.1,0.2-0.2c0.3-0.4-0.1-0.7-0.2-1C43.9,40.9,43.8,41.4,43.5,41.8z"/>
			<path fill="#B4504B" d="M42.1,41.1c0.1,0,0.2,0,0.2,0c0.5-0.5-0.2-0.6-0.2-1c-0.2,0-0.5,0-0.7,0c-1,0.3,0,0.6,0,1
				c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0.1,0.1,0.1,0.1C41.6,41.2,41.8,41.1,42.1,41.1z"/>
			<path fill="#C5755E" d="M46.9,50.6c-0.1,0-0.2,0-0.2,0c-0.2,0.2-0.3,0.4-0.1,0.6c0.1,0.1,0.2,0,0.3,0
				C47.1,51,47.1,50.8,46.9,50.6z"/>
			<path fill="#CA7F64" d="M47.8,35.1c-0.2,0.1-0.4,0.2-0.4,0.5c0,0,0.1,0.1,0.2,0.1C47.9,35.5,47.8,35.3,47.8,35.1
				C47.8,35,47.8,35.1,47.8,35.1z"/>
			<path fill="#BC6254" d="M46.2,44.4c-0.2-0.3,0.2-1-0.5-1c-0.8,0.1-0.1,0.9-0.5,1.3C45.6,44.8,46.1,45.7,46.2,44.4z"/>
			<path fill="#C06C59" d="M36.9,28c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2S36.7,28,36.9,28z"/>
			<path fill="#C5745E" d="M43.2,35.2c0.1,0.2,0.2,0.3,0.3,0.2c0.2,0,0.2-0.2,0.2-0.4c0-0.1-0.2-0.2-0.2-0.2
				C43.3,34.9,43.2,35,43.2,35.2z"/>
			<path fill="#CA7F64" d="M44.7,30.5c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1,0.1-0.2,0.2-0.2,0.2C44.2,30.4,44.5,30.5,44.7,30.5L44.7,30.5z
				"/>
			<path fill="#B5534D" d="M41.6,43c0-0.3,0.2-0.5,0.5-0.5c0-0.4-0.2-0.7-0.7-0.7c-0.4,0.1-0.3,0.4-0.3,0.7c0,0.7-0.6,1.3-0.3,2
				c0.1,0.3-0.3,0.1-0.4,0.1c0,0.1,0,0.2,0,0.2c0.3,0.2,0.6,0.4,0.9,0.6c0.5,0.3,0.7,0.2,0.7-0.4c-0.1-0.5,0.4-0.5,0.6-0.7l0,0
				C42.3,43.9,41.6,43.7,41.6,43z"/>
			<path fill="#B6564E" d="M44.2,42.3c0,0.8-0.4,1.1-1.2,1c0.3,0.7,1.1,0.2,1.5,0.6c0.2-0.6,1.2-0.7,0.9-1.6c-0.1,0-0.1,0-0.2,0
				C44.9,42.1,44.6,42.1,44.2,42.3z"/>
			<path fill="#B6554D" d="M43.3,44.8c0.1,0.3,0.4,0.5,0.6,0.5c0.3-0.1,0.8-0.4,0.8-0.7c0.1-0.4-0.4-0.4-0.7-0.3
				C43.7,44.3,43.4,44.4,43.3,44.8z"/>
			<path fill="#BD6455" d="M42.6,44.4c0,0.1,0,0.2,0,0.2c0.1,0.3-0.4,0.6,0,0.9c0,0,0.1,0,0.2,0C42.6,45.2,43.2,44.6,42.6,44.4
				C42.5,44.4,42.6,44.4,42.6,44.4z"/>
			<path fill="#CA7F64" d="M44.7,41.8c-0.1,0-0.2,0-0.2,0c0.1,0.6,0.6,0.4,1,0.5l0,0c0-0.3,0.2-0.6,0-1
				C45.2,41.4,45.1,41.8,44.7,41.8z"/>
			<path fill="#B85B50" d="M41.4,41.3c0-0.1,0-0.2,0-0.2c-1.6-0.7-0.7,0.9-1.4,1.3C40.9,42.3,40.7,41.4,41.4,41.3z"/>
			<path fill="#D09077" d="M86,44.2c1,0.6,1.3-0.4,1.8-0.8C87.1,43.5,86.4,43.5,86,44.2L86,44.2z"/>
			<path fill="#D6A38F" d="M87.4,49.2c0,0.2,0,0.3,0,0.5c0.6,0,1-0.2,1-1c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1,0.1-0.2,0.2-0.2,0.2
				C87.9,48.8,87.5,49,87.4,49.2z"/>
			<path fill="#D29781" d="M91.8,41.5c-0.1,0-0.2,0-0.2,0c-0.1,0.4,0.3,0.6,0.5,1c0.2,0,0.5,0.1,0.6-0.2
				C92.7,41.7,91.9,41.9,91.8,41.5z"/>
			<path fill="#D7A38F" d="M90.6,48.7c0.2,0.2,0.5,0.5,0.7,0.2s0-0.7,0-1.1c-0.2,0-0.4,0.1-0.5,0.1C90.9,48.3,91.2,48.7,90.6,48.7z"
				/>
			<path fill="#D1937C" d="M89.7,41.5c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.1,0.3-0.2,0.4-0.4s-0.1-0.4-0.3-0.4
				C89.9,41.1,89.8,41.2,89.7,41.5z"/>
			<path fill="#D5A08C" d="M87.2,47.8c0.1-0.1,0.2-0.2,0.3-0.2c-0.2-0.4-0.6-0.7-1.1-1.1c0,0.7,0.6,0.7,0.6,1.1
				C87,47.6,87.1,47.7,87.2,47.8z"/>
			<path fill="#D7A693" d="M86.5,46.3c-0.3-0.5-0.6-0.4-1.3-0.3C85.8,46.1,86.2,46.2,86.5,46.3z"/>
			<path fill="#D6A38F" d="M87.4,41.3c0-0.1,0-0.2,0-0.2c-0.4,0-0.5,0.3-0.5,0.6c0,0.1,0.1,0.1,0.1,0.1
				C87.4,41.8,87.4,41.5,87.4,41.3z"/>
			<path fill="#D59E8A" d="M87.4,46.1c0-0.3,0.2-0.6-0.2-0.8c0,0-0.2,0-0.2,0.1C86.9,45.7,87.1,45.9,87.4,46.1L87.4,46.1z"/>
			<path fill="#D09077" d="M86,44.2c-0.3,0.2-0.5,0.4-0.8,0.6C85.9,45,86,44.7,86,44.2L86,44.2z"/>
			<path fill="#CE8C73" d="M91.8,47.5c0.2,0,0.5,0.1,0.6-0.2c0,0-0.1-0.2-0.2-0.2C91.8,47,91.8,47.3,91.8,47.5L91.8,47.5z"/>
			<path fill="#D5A08B" d="M88.6,48.3c0-0.2,0-0.3,0-0.5c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1,0.1-0.2,0.1-0.2,0.2
				C88.1,47.9,88.3,48.1,88.6,48.3z"/>
			<path fill="#D8A896" d="M89.6,48.7c0,0.4,0,0.7,0.5,0.7l0,0c0-0.2,0-0.5,0-0.7C89.9,48.7,89.8,48.7,89.6,48.7z"/>
			<path fill="#D09078" d="M88.9,41.5c-0.3,0.4-0.4,0.6-0.3,1C89,42.2,88.9,41.9,88.9,41.5z"/>
			<path fill="#D09179" d="M89.4,45.8c0.1,0,0.2,0,0.2-0.1s-0.1-0.2-0.2-0.3c0,0-0.2,0-0.3,0.1C89.2,45.6,89.3,45.7,89.4,45.8z"/>
			<path fill="#D8A693" d="M85.6,42.6c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0,0.2-0.1,0.1-0.2c-0.1-0.1-0.2-0.2-0.4-0.3
				C85.6,42.5,85.6,42.5,85.6,42.6z"/>
			<path fill="#D59E8A" d="M87.4,46.1c0,0.2-0.1,0.5,0.2,0.6l0.2-0.1C87.9,46.3,87.6,46.2,87.4,46.1L87.4,46.1z"/>
			<path fill="#D29781" d="M92.7,43.5c0.3-0.5-0.2-0.5-0.5-0.7C92.2,43.1,92.4,43.3,92.7,43.5z"/>
			<path fill="#D7A490" d="M90.1,49.4c0,0.4,0.4,0.5,0.7,0.9C90.8,49.7,90.5,49.5,90.1,49.4C90.1,49.5,90.1,49.4,90.1,49.4z"/>
			<path fill="#DCB1A2" d="M88.6,50.6c0,0.1,0,0.2,0,0.2c0.2,0,0.5-0.1,0.4-0.4c0-0.1-0.3-0.2-0.4-0.2
				C88.4,50.3,88.6,50.5,88.6,50.6z"/>
			<path fill="#CB8166" d="M86,48.7c0.2,0.1,0.3,0.1,0.5,0.2c0-0.1,0-0.3,0-0.4c0-0.2-0.1-0.5-0.4-0.4C85.7,48.3,85.9,48.5,86,48.7z
				"/>
			<path fill="#CB8166" d="M87.4,49.2c0.5-0.1,0.6-0.3,0.2-0.7C88.1,48.9,86.5,48.6,87.4,49.2z"/>
			<path fill="#C77960" d="M45.4,42.3c-0.3-0.2-0.6-0.3-1-0.5c-0.4-0.1-0.3,0.3-0.5,0.5H44h0.1C44.6,42.3,45,42.6,45.4,42.3z"/>
		</g>
	</g>
</g>
</svg>
        )
    }
}

export default Character2Mail;