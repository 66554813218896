import React from "react";
import data from "../data.json";

class QuizHeadline extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    let wrapper = function(element, i) {
      if (i === 0) {
        return (
          <span key={i}>
            {element}
          </span>
        );
      } else {
        return (
          <strong key={i}>
            {element}
          </strong>
        );
      }
    };
    return (
      <div className="quiz_headline_wrapper">
        <h1 className="quiz_headline">{data[currentScreen].title.map(wrapper)}</h1>
      </div>
    );
  }
}

export default QuizHeadline;
