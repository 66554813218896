import React from "react";

class GlobalHeader extends React.Component {
  render() {
    const updateClickstream = this.props.updateClickstream;
    return (
      <div className="global_header">
        <span className="ui-logo">UBS Logo</span>
        <a
          href="http://ubs.com"
          className="ui-close"
          onClick={() => {
            updateClickstream('http://ubs.com', 'Close Icon');
          }}
        >
          Close
        </a>
      </div>
    );
  }
}

export default GlobalHeader;
