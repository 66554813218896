import React from "react";
import { Link } from "react-router-dom";
import data from "../data.json";

class ScenarioOptions extends React.Component {
  render() {
    const checkScenarioProgress = this.props.checkScenarioProgress;
    const currentScreen = this.props.currentScreen;
    const characterReference = this.props.characterReference;
    const updateLastClick = this.props.updateLastClick;
    let wrapper = function(element, i) {
      const destination = element.scenario + "-1";
      const scenarioRef = "scenario" + element.scenario;
      if (element.type === "image") {
        return (
          <div className="option" key={i}>
            <span className="option_image">{element.image}</span>
          </div>
        );
      } else if (element.type === "character") {
        return(
          <div className={"option option_image character " + characterReference} key={i}></div>
        )
      } else if (checkScenarioProgress(scenarioRef) === true) {
        return (
          <Link
            to={destination}
            className="option option--button option--completed"
            key={i}
            onClick={() => {
              console.log('onClick: COMPLETED scenario select');
            }}
          >
            <span className="image-path-complete" />
            <span className={"option_image image image-" + element.image}></span>
            <h3 className="option_headline">{element.headline}</h3>
            {element.copy.map((copy, i) => (
              <p key={i} className="option_copy">
                {copy}
              </p>
            ))}
          </Link>
        );
      } else {
        return (
          <Link
            to={destination}
            className="option option--button"
            key={i}
            onClick={() => updateLastClick(element.headline)}
          >
            <span className={"option_image image image-" + element.image}></span>
            <h3 className="option_headline">{element.headline}</h3>
            {element.copy.map((copy, i) => (
              <p key={i} className="option_copy">
                {copy}
              </p>
            ))}
          </Link>
        );
      }
    };

    return (
      <div className="options options--scenario">{data[currentScreen].options.map(wrapper)}</div>
    );
  }
}

export default ScenarioOptions;
