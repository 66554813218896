import React from "react"

class Character4Airplane extends React.Component {
    render(){
        return(
<svg version="1.1" id="character-4" className="character-animated character-animated--airplane" x="0px" y="0px" viewBox="0 0 437.8 583.5" enable-background="new 0 0 437.8 583.5">
<g id="glide">
	<g id="_x37_aV8Vf_1_">
		<g>
			<path fill="#F9F9F8" d="M230.1,98.5c0.5-0.2,0.7-0.3,0.9-0.4c0,0.2-0.1,0.4-0.4,0.5C230.6,98.6,230.4,98.5,230.1,98.5z"/>
			<path fill="#FEFEFE" d="M31.9,40.3c-1.2-1.5-2.4-3-3.6-4.5c-1.7-2.2-3.3-4.3-4.9-6.5c-1.3-1.7-2.5-3.4-3.7-5
				c-0.2-0.4-0.5-0.6-0.7-1c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.9-0.7-1.2-1.2-1.7l0,0c-0.2-0.2-0.1-0.7-0.5-0.7c-0.2-0.1-0.3-0.2-0.5-0.3
				c-0.1-0.4-0.4-0.7-0.7-1c-0.4-1.1-1.4-1.8-2-2.7c-0.1-0.2-0.3-0.2-0.5-0.1c-0.3,0.1-0.6,0.2-0.6-0.2c0-0.1,0.3-0.3,0.5-0.4
				c0.3-0.8,0-1.3-0.7-1.7c-0.7-0.4-0.8-1.1-0.8-1.9c0-0.8-0.3-1.3-1.1-1.4c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.1,0-1-0.9-0.9
				c-0.4,0-0.8-0.7-1.1-1.1l0,0C7.6,7.7,7.7,6.5,7.8,5.3C7.9,5.2,7.9,5,8,4.9C8.3,4.6,8.5,4,9.2,4.3c0.2,0.2,0.4,0.3,0.7,0.1
				c0.6-0.4,1.5-0.2,2,0.3c-0.2,0.2-0.5,0-0.7,0.1c0.3,0,0.7,0,0.9-0.2c0.4,0,0.7-0.1,1.1,0.1c0.3,0,0.5,0,0.7,0.2
				c0.3,0.4,0.6,0.8,0.9,1.2c0.2,0.2,0,0.5,0.5,0.4c1-0.1,2-0.2,3-0.3c1.4-0.2,2.6,0.4,4,0.6c0.1,0,0.2,0,0.2,0
				c0.1,0.1,0.2,0.3,0.1,0.4c-0.6,1.3,0.3,1.2,1.1,1.2c1.4,0,2.8,0,4.1,0c0.5,0,1-0.1,1.4,0.3c1.3,0.3,2.6,0.2,3.8,0.8l0,0
				c-0.1,0.7-0.7,0.4-1.2,0.6c1,0.4,1.9,0.4,2.9,0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.5-0.1,0.8,0c1,0.5,2,0.4,3.1,0.6l0,0
				c0.7,1.2,1.7,1.6,3.1,1.5c1.8,0,3.6,0,5.3,0.4c0.1,0,0.2,0,0.2,0c0.3,0.3,0.8,0.3,1.2,0.5c0.3,0.1,1.1-0.1,0.5,0.8
				c-0.2,0.3,0.2,0.2,0.3,0.2c1,0.1,1.9,0.2,3,0c0.9-0.2,1.9,0.3,2.9,0.5c1.1,0.2,2.1,0.3,3.2,0.6c3.3,0.9,6.8,1.4,10.1,2.4
				c1.2,0.4,2.5,0.2,3.6,0.8c0.2,0.2,0.3,0.5,0.4,0.7c0.2,0.7,0.6,1,1.2,0.8c0.6-0.2,1.2-0.1,1.9,0c1.2,0.3,2.4-0.4,3.5,0.1
				c2.6,0.3,5.2,0.8,7.7,1.7c0.1,0.4-0.3,0.5-0.5,0.7c-0.4,0.3-0.9,0.7-0.8,1.1c0.1,1-0.4,1.1-1.2,1.1c-0.7,0-1.1,0.2-1,1
				c0,0.2-0.2,0.3-0.3,0.5c-0.4,0.9-1.4,1-2.1,1.6c-1.1,1-2.4,1.8-3.7,2.7c-0.7,0.4-1.3,0.7-1.9,1.2c-0.1,0.1-0.2,0.2-0.2,0.2
				c-0.2,0.2-0.5,0.2-0.7,0.5l0,0c-0.5,0.5-0.9,0.2-1.3-0.4c0,0.6,0.8,0.6,0.4,1.1l0,0c-0.4,0.1-0.8,0.3-1,0.7c-0.1,0-0.2,0-0.2,0
				c-0.7-0.4-0.3,0.3-0.5,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.7-0.5-1.6-0.4-2.4-0.4c-0.4,0-0.9,0.1-1.3,0.1c-0.1,0-0.2-0.1-0.3-0.2
				c-0.2-0.8-0.8-0.9-1.4-0.8c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.5,0.4-0.4,0.6-0.6c-0.4,0.1-0.7,0.3-0.9,0.6c0,0-0.1,0-0.1,0
				c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.4-0.4-0.5-0.8-0.3c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.3-0.7-0.7-0.4c-0.1,0-0.2,0-0.3-0.1
				c-0.3-0.2-0.5-0.3-0.8-0.4c-0.1-0.1-0.1-0.2-0.1-0.2c-0.2-0.7-0.2-0.7-1.2-0.9c-0.7-0.1-1.4,0-2-0.1c-1.7-0.7-3.5-1.1-5-2.3
				c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.4,0-1-0.6-0.4c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.2,0-0.6-0.6-0.6c-0.7,0-1.4-0.1-2.1-0.3
				c-0.6-0.1-1.3-0.3-1.6-0.9c0-0.9-0.9-0.7-1.3-1c-0.3-0.2-0.8-0.2-1.3-0.1c-0.3,0-0.6-0.1-0.8-0.3c-0.5-0.9-0.4-1.2,0.5-1.3
				c-1-0.1-2-0.1-3,0c-2-0.4-3.7-1.4-5.4-2.4c-0.3-0.4-0.6-1-1.3-0.7c-0.1,0-0.2,0-0.3-0.1c-0.2-1.2-1.1-0.9-1.9-0.8
				c-0.5,0-0.9-0.1-1.2-0.5c-0.5-0.7-1.2-1-2-1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.3-0.8-0.6-1.3-0.3c-0.1,0.1-0.1,0.1-0.2,0.2
				c0,0.5,0.5,0.4,0.7,0.5c0.2,0.1,0.4,0.3,0.6,0.5c0.3,0.3,0.6,0.4,0.9,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.3,0.6,0.4,0.8,0.7
				c0.3,0.5,0.3-0.3,0.5-0.1c0.4,0,0.7,0.2,0.9,0.5c0.4,1.7,0.4,1.7,2.4,1.7c0.2,0,0.3,0.1,0.5,0.2c0.7,0.3,0.8,1.2,1.6,1.5
				c0.3,0.1,0.5,0,0.8-0.1c0.3,0,0.6,0.1,0.9,0.3c1.8,1.2,3.7,2.3,5.5,3.5c0.2,0.1,0.3,0.3,0.4,0.4c0.3,0.1-0.6,0.6,0.3,0.6
				c0.3,0,0.7,0.5,1.1,0.8c0.4,0.3,0.7,0.1,1,0.1c1,0.6,2.3,0.7,3,1.9c-0.3,1.2,0.8,1.1,1.4,1.4c0.5,0.2,1.2,0.1,1.4,0.8
				c0,0.1-0.2,0.1-0.1,0.2c0-0.1,0.1-0.1,0.2-0.2c0.2,0.3,0.4,0,0.6,0c0.3,0,0.6,0.2,0.8,0.4c0.3,0.3,0.8,0.4,1.2,0.7
				c0.3,0.3,0.8,0.4,0.9,0.8c0,0.1-0.2,0.1-0.2,0.3c0,0.1,0.2,0,0.3,0c1.2,0.4,2,1.4,3.3,1.4c1.2,0.5,2.3,1.3,3.4,2
				c0.6,0.4,0.8,1.1,0.8,1.8c-0.1,1.1,0.1,2.2-0.3,3.3c-0.2,0.5,0,0.9,0.5,1.2c1.1,0.7,1.4,1.8,1.5,3.1c0,0.6-0.2,1.3,0.4,1.9
				c0.2,0.2,0.1,0.9-0.6,0.7l0,0c-0.3-0.5-0.9-0.7-1.4-1c-2.3-1.1-4.3-2.7-6.5-4.1c-0.2-0.2-0.5-0.3-0.7-0.5l0,0
				c-0.4-0.3-0.8-0.4-1.2,0l0,0c-0.8,0.2-1.3,0.7-1.9,1.2l0,0c-0.5,0.1-0.8,0.4-1.2,0.7l0,0c-0.6-0.1-0.4-0.4-0.3-0.8
				c0.1-0.3,0.5-0.4,0.6-0.8c-0.7,0-0.7,0.5-0.7,0.8c-0.1,0.6-0.4,0.9-1,1c-1.2,0.1-1.9,0.8-2.2,1.9c-2.1,1.4-4.2,2.9-6.2,4.3
				c-0.5,0.3-0.5-0.1-0.6-0.4c-0.3-0.9-0.6-1.8-1.7-2.1c-0.6-0.2-0.4-0.9-0.4-1.4c-0.3,0.6-0.6,1-1,0.2c-0.1-0.3-0.4-0.4-0.6-0.6
				c-0.9-0.9-1.5-1.9-2.2-2.9c-0.4-0.9,0.1-1.9-0.5-3.1c-0.1,0.6,0.4,1.2-0.1,1.4c-0.5,0.2-0.7-0.3-1.1-0.5c0-0.1,0-0.2,0-0.2
				c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.8,0-1.6-1.2-1.5c-0.3,0-0.6-0.3-0.6-0.6C32.9,40.4,32.4,40.3,31.9,40.3z"/>
			<path fill="#98989C" d="M54.7,33.8c-0.2-0.2-0.5-0.3-0.7-0.5c-1.2-0.8-2.6-1.1-3.6-2.2c-0.8-1-2.1-1-3-1.9
				c-0.9-0.3-1.7-0.8-2.3-1.5c-2.2-1.4-4.4-2.9-6.7-4.1c-1-0.2-1.6-1.1-2.5-1.4c-0.1-0.1-0.2-0.2-0.2-0.2c-1.1-0.3-1.9-0.9-2.6-1.7
				c-0.2-0.2-0.5-0.3-0.7-0.5c-0.5-0.3-1-0.3-1.4-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3-0.2-0.7-0.2-0.9-0.5c-0.2-0.2-0.4-0.3-0.6-0.5
				c-0.3-0.3-1.2-0.1-0.9-1l0,0c0.6-0.4,1.1-0.2,1.7,0.2c0.1,0,0.1,0.1,0.2,0c0.8,0.1,1.5,0.6,2.2,1c0.3,0.3,0.7,0.4,1.2,0.5
				c0.8,0.1,1.3,0.6,2,1l0,0c0.5,0.1,1.1,0.2,1.5,0.7c1.9,0.8,3.6,1.8,5.5,2.4c1,0.1,1.9,0.6,2.6,1.3c0.2,0.2,0.4,0.2,0.7,0.2
				c0.9,0.4,1.9,0.6,2.7,1.2c0.5,0.4,1,0.6,1.6,0.8c0.8,0.5,1.9,0.5,2.7,1.2l0,0c0.3,0.2,0.8,0.1,0.9,0.5c1.6,0.8,3.3,1.6,5,2.1
				c1,0.5,2.3,0.5,3.1,1.5l0,0c0.3,0.1,0.7,0.2,1,0.5l0,0c0.3,0.2,0.7,0.2,1,0.5c0.1,0,0.1,0.1,0.2,0c0.4,0.1,0.8,0.1,1,0.5l0,0
				c0.2,0.2,0.6,0,0.7,0.3l0,0c0.6,0.2,1.4,0.2,1.7,0.9l0,0c0.9,0.3,2,0.3,2.6,1.2c0.1,2.4-0.6,4.6-1,7c-0.4,2.8-0.9,5.6-1.4,8.6
				c-0.2-0.3-0.4-0.5-0.5-0.7c-0.7-0.5-0.7-1.2-0.9-1.9c-0.4-1.7-1.1-3.3-1.6-5c-0.2-0.8-0.4-1.6-0.4-2.4c0-1.1-0.6-1.7-1.5-2.2
				c-0.9-0.4-1.7-0.9-2.6-1.4c-1.1-0.5-2.1-1.1-3.1-1.7c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3-0.2-0.7-0.5-1-0.7
				C55.5,34.2,55.1,33.9,54.7,33.8z"/>
			<path fill="#6D6E72" d="M174.2,76.6c0.5,1.4,1.4,1.1,2.4,0.6c0.5-0.2,1.3-0.7,1.3,0.3c0.1,0.7,1.3,1.4-0.3,2.2
				c-3.5,1.8-7.2,2.8-10.9,3.6c-0.5,0.1-1.1,0.4-1.4-0.2c-0.3-0.7-0.7-1.5-0.6-2.3c0-0.4,0.5-0.3,0.8-0.3c1.7-0.4,3.5-0.8,5.2-1.2
				c0.8-0.2,0.9-0.4,0.3-1.1c-1.7-1.9-2.8-4.2-4.1-6.3c-0.7-1.1-0.5-1.4,0.8-1.8c2-0.7,2-0.7,3,1.3c0.6,1.1,1.3,2.2,2,3.3
				C173.3,75.1,173.8,75.8,174.2,76.6z"/>
			<path fill="#6E6E72" d="M165.1,51.9c1.1,0.4,2.3,0.5,3.2,1.2c0.3,0.2,0.2,0.5,0.1,0.8c-1,3.1-1,6.2-0.5,9.3
				c0.1,0.8-0.3,1.1-0.9,1.4c-2.3,1-2.4,1-2.9-1.3c-0.5-2.2-0.6-4.4-0.2-6.6c0.2-1.4,0.3-2.8,0.9-4.2
				C164.9,52.2,164.8,51.9,165.1,51.9z"/>
			<path fill="#6E6E73" d="M82.7,56.9c1.2,0.7,2.1,1.7,3.4,2.2c0.4,0.1,0.5,0.4,0.4,0.8c-0.8,2.2-1.2,2.4-3.1,1.2
				c-3.6-2.3-6.9-5-10.3-7.6c-0.6-0.4-0.6-0.8-0.1-1.2c1-1,1.8-1.1,2.9-0.3c1.9,1.4,3.7,3,5.8,4.2C82.1,56.3,82.4,56.6,82.7,56.9z"
				/>
			<path fill="#6E6E73" d="M92.1,62.6c0.6,0.3,1.2,0.6,1.8,1c2.9,2.1,6.1,3.5,9.4,5c0.6,0.3,0.7,0.5,0.4,1c-1.2,2.6-1.1,2.5-3.6,1.2
				c-2.5-1.2-4.9-2.7-7.3-4.1c-0.7-0.4-1.3-0.9-1.9-1.4c-0.1-0.1-0.3-0.2-0.3-0.3C90.3,64.6,91.6,62.7,92.1,62.6z"/>
			<path fill="#6E6E73" d="M140.8,81.2c-0.1,0.6-0.3,1.2-0.4,1.7c-0.1,0.3-0.3,0.4-0.6,0.3c-4.2-0.5-8.5-1-12.5-2.2
				c-0.7-0.2-0.9-0.4-0.4-1c0.1-0.1,0.2-0.3,0.2-0.4c0.8-1.9,0.9-2,2.8-1.3c2.1,0.7,4.3,1,6.4,1.2c1.2,0.1,2.4,0.5,3.6,0.6
				C140.5,80.3,140.9,80.6,140.8,81.2z"/>
			<path fill="#6E6E73" d="M121.8,76.6c-0.3,1-0.5,1.9-0.9,2.7c-0.1,0.4-0.4,0.2-0.7,0.1c-4-1.2-7.8-2.8-11.6-4.5
				c-0.1,0-0.3-0.1-0.3-0.2c-0.2-0.5,0-1.1,0.4-1.3c0.7-0.4,0.3-2,1.7-1.4c2.4,1,4.8,2,7.1,3.1c1.1,0.5,2.3,0.9,3.5,1.2
				C121.4,76.3,121.8,76.2,121.8,76.6z"/>
			<path fill="#6E6E73" d="M198.9,54.4c0.1,1.4-0.4,2.7-0.3,4c-0.4,1.3-1,2.7-1.3,4c-0.2,0.8-0.5,0.9-1.2,0.6
				c-0.3-0.1-0.6-0.2-0.9-0.3c-0.8-0.3-1.2-0.8-0.8-1.8c0.6-1.4,0.7-2.9,0.9-4.4l0,0c0.5-1.6,0.1-3.1-0.3-4.7
				c-0.2-0.8,0.1-1.2,0.7-1.6c0.8-0.4,1.5-0.8,2.4-0.9c0.4,0.3,0.4,0.8,0.5,1.2c0,0.1,0.1,0.2,0.1,0.4
				C198.9,52.1,199.3,53.3,198.9,54.4z"/>
			<path fill="#6D6E73" d="M193.1,69.3c-0.5,0.6-1.1,1.1-1.5,1.9c0,0.1-0.1,0.2-0.2,0.1c-1.1,0.7-2.1,1.6-2.9,2.6
				c-0.1,0.4-0.4,0.5-0.7,0.5c-1.3,0.4-2.2,1.5-3.4,2.1c-0.8,0.4-1.2,0.1-1.5-0.6c-0.4-1-0.8-1.8,0.7-2.4c1-0.5,1.9-1.4,2.8-2.1
				c0.2-0.7,0.8-1,1.4-1.2c0.3-0.2,0.5-0.5,0.8-0.7c0.2-0.5,0.5-1,1.1-1c0.4-0.1,0.6-0.5,0.9-0.8c0.4-0.6,0.8-0.8,1.5-0.6
				c0,0,0,0,0,0c0.3,0.2,0.8,0.1,1,0.4C194.5,68,193.5,68.7,193.1,69.3L193.1,69.3z"/>
			<path fill="#6E6E73" d="M154.2,84.6c-2-0.3-4.3-0.1-6.4-0.4c-0.6-0.1-0.8-0.2-0.7-0.8c0.1-0.6,0.2-1.2,0.3-1.8
				c0.1-0.4,0.3-0.7,0.8-0.6c3.5,0.3,6.9,0.4,10.4,0.2c0.4,0,0.6,0.2,0.7,0.6c0.8,2.4,0.8,2.4-1.8,2.6
				C156.5,84.5,155.4,84.6,154.2,84.6z"/>
			<path fill="#6E6E73" d="M191,44.6c-1.6-1.8-3.6-2.9-6-3.3c-1.1-0.2-1.9-1.9-1.5-2.9c0.1-0.2,0.3-0.2,0.5-0.2
				c2.9,0,5.5,0.8,7.8,2.6c1.2,0.9,2.1,2.1,3.1,3.1c0.4,0.4,0.3,0.7-0.2,0.9c-0.3,0.1-0.6,0.2-0.8,0.3c-1,0.8-1.9,1-2.7-0.3
				C191.1,44.7,191.1,44.7,191,44.6z"/>
			<path fill="#6E6E72" d="M178.2,41.4c0.1,0.5-0.2,0.7-0.6,0.9c-2.6,1.1-4.8,2.9-6.5,5.2c-0.4,0.5-0.8,0.4-1.3,0.3
				c-2.7-0.6-2.8-0.7-1-2.8c2.3-2.7,5.2-4.6,8.5-5.8c0.5-0.2,0.7,0.1,0.7,0.5C178.1,40.2,178.2,40.8,178.2,41.4z"/>
			<path fill="#6E6E72" d="M180.2,82.5c2,2,4.5,3.3,6.9,4.6c1.9,1,1.7,0.9,0.8,2.8c-0.8,1.8-1.6,0.3-2.4,0.3l0,0
				c-1.8-1.2-3.7-2.3-5.5-3.5c-0.1-0.1-0.3-0.1-0.4-0.2c-0.5-0.5-1.6-0.8-1.5-1.6c0.1-1,0.9-1.7,1.7-2.4
				C179.8,82.4,180,82.5,180.2,82.5L180.2,82.5z"/>
			<path fill="#6E6E73" d="M218.8,95.2c0,0.6-0.4,1.1-0.3,1.6c-0.1,0.2-0.1,0.5-0.1,0.7c0.1,0.9-0.3,1-1.1,0.8c-0.3,0-0.6,0-0.9-0.1
				c-1.8-0.4-3.6,0-5.3-0.4c-0.9-0.2-1.7-0.2-2.6-0.3c-0.7-0.1-0.9-0.4-0.7-1.1c0.6-1.9,0.5-1.9,2.7-1.8c1.7,0.1,3.4,0.2,5.1,0.3
				c0.3,0.3,0.5-0.1,0.7,0C217.2,95.2,218,94.9,218.8,95.2z"/>
			<path fill="#6E6E73" d="M194.4,94c-0.1-0.1-0.3-0.2-0.4-0.3c-0.5-0.4-1.7-0.2-1.7-0.9c0-0.8,0.7-1.6,1.1-2.3
				c0.1-0.3,0.3-0.2,0.6-0.1c1.1,0.4,2.2,0.7,3.2,1.2c1.1,0.5,2.3,0.7,3.5,1.1c0.2-0.1,0.4,0,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.1
				c0.6,0.1,1.4,0,1.6,0.6c0.2,0.8-0.1,1.7-0.5,2.5c-0.3,0.5-0.6,0.2-0.9,0.1C199.2,95.4,196.8,94.7,194.4,94L194.4,94z"/>
			<path fill="#FCFCFB" d="M8.1,5c0,1.3,0,2.6,0,3.8c-1.5-1.5-2.5-3.4-3.8-5C4.1,3.5,3.9,3.1,4.1,2.7c0.3-0.5,0.7-0.1,1.1,0
				c1,0.3,2,0.4,2.9,0.9C8.5,4,8.4,4.5,8.1,5z"/>
			<path fill="#F9F8F6" d="M64.3,40.7c1,3.1,1.9,6.2,2.9,9.3c0,0.9-0.3,1.3-1.2,0.7c0.4,0,0.6-0.5,0.4-0.6c-1.1-0.7-0.5-1.8-0.5-2.6
				c0-1.1-0.8-1.8-1.3-2.1c-0.6-0.4-0.8-0.8-0.6-1.3C64.3,43.1,64,41.9,64.3,40.7z"/>
			<path fill="#FCFCFB" d="M29.2,8.8c-0.4-0.2-0.8-0.2-1.3-0.1c-2.1,0.3-4.2,0.1-6.5,0.1c0.6-0.7,1-1.3,1.1-1.9
				c2.3,0.5,4.5,0.9,6.7,1.7C29.3,8.6,29.3,8.7,29.2,8.8C29.3,8.8,29.2,8.8,29.2,8.8z"/>
			<path fill="#FCFBF9" d="M8.1,8.8c0.4,0.3,0.8,0.7,1.2,1c0.4-0.1,0.2-0.6,0.6-0.8c0,0,0.1,0.1,0.1,0.1c0,0.8-0.1,1.6,1.2,1.7
				c0.7,0,0.9,0.9,0.8,1.6c-0.2,1.2,0.6,1.6,1.4,2c0.4,0.2,0.3,0.5,0.4,0.7c0.2,0.5-0.2,0.4-0.5,0.4c-0.1,0-0.1,0.2-0.2,0.4
				c-1-0.7-1.2-1.8-1.8-2.7c-0.9-1.3-2-2.6-2.9-3.8C8.3,9.3,8.2,9,8.1,8.8z"/>
			<path fill="#FBFAF9" d="M83,26.1c0.1-1.8,0.1-1.8,2.1-1.8c0,0,0.1,0,0.1-0.1c-0.4-1,0-1.6,1.6-2.5c0.5,0.1,1.1-0.2,1.3,0.3
				c0.2,0.6-0.4,0.8-0.7,1.1C86,24.3,84.7,25.4,83,26.1z"/>
			<path fill="#FCFBFA" d="M22.3,6.9c-0.8,0-1.7,0.1-2.4-0.4c-0.4-0.2-0.8-0.1-1.2,0c-1.5,0.4-3,0.4-4.6,0.3c0-0.6,0.5-0.1,0.6-0.5
				c-0.6-0.2-1-0.7-0.8-1.4c2.1,0.2,4.2,1,6.3,1.3C20.9,6.5,21.7,6.3,22.3,6.9z"/>
			<path fill="#FCFCFB" d="M47.2,12.7c-2.1,0-4.2,0-6.3,0c-1.1,0-2-0.8-2.1-1.9c1.9,0.3,3.8,0.7,5.7,1.3
				C45.4,12.3,46.4,12,47.2,12.7z"/>
			<path fill="#FCFBF9" d="M79.1,20.1c-1.5-0.4-3.1,0.8-4.6-0.1c-0.1,0-0.2,0-0.2,0c-2,0.4-2.1,0.3-2.2-1.6
				C74.6,18.8,76.9,19.3,79.1,20.1z"/>
			<path fill="#FBFBF9" d="M38.8,49.4c0.6-0.1,0.6,0.8,1.1,0.8c0.4-0.3-0.2-0.7,0.1-1c0.1,0,0.4,0.1,0.4,0.1
				c0.1,1.7,2.1,2.2,2.5,3.7c0,0.2-0.5,0.8,0.2,0.7c-0.8,0.9-1.4,0.2-1.7-0.4C40.8,51.8,39.6,50.8,38.8,49.4z"/>
			<path fill="#FDFCFA" d="M55.4,14.6c-0.6,0-1.2,0.1-1.7-0.2C53,14,52.5,14.5,52,14.6c-1.1,0.2-2.1-0.3-3.2-0.1
				c-0.2,0-0.3-0.1-0.4-0.4c0-0.5,0.4-0.4,0.7-0.6c-0.5-0.5-1.5,0.2-1.7-0.9C50.1,13.2,52.8,13.8,55.4,14.6z"/>
			<path fill="#FCFBFA" d="M49.4,49.4c-0.3-0.9,0.6-2.2,1.7-2.1c1,0,1.3-0.4,1.3-1.2c0-1.2,0.8-0.8,1.3-0.7c0.6,0.2,0.2,0.5-0.1,0.7
				c-0.4,0.3-1.1,0.4-0.5,1.1C51.9,48.1,50.7,48.9,49.4,49.4z"/>
			<path fill="#FCFBFA" d="M38.8,10.7c-1.1-0.1-2.3,0.3-3.3-0.5c-0.3-0.2-0.4,0.2-0.6,0.3c-0.5-0.3-1,0-1.2-0.1
				c0.2,0,0.8-0.2,1.2,0.1c-1.1,0.4-2.2,0.5-3.3-0.2c-0.2-0.1-0.6-0.1-0.5-0.5c0.1-0.3,0.4-0.1,0.6-0.1c0.4,0,0.9,0.2,1.2-0.2
				C35.1,9.4,36.9,10.3,38.8,10.7z"/>
			<path fill="#FCFBF9" d="M31.9,40.3c0.3,0,0.4-0.2,0.6-0.4c0.4-0.4,0.6-0.3,0.6,0.2c-0.1,1,0.1,1.6,1.3,1.3
				c0.4-0.1,0.6,0.1,0.6,0.6c-0.1,0.7,0,1.3,0.1,2C33.6,43.1,32.8,41.6,31.9,40.3z"/>
			<path fill="#FCFBF9" d="M35,44.3c0.4-0.1,0.6,0.5,1.1,0.3c0.2-0.6-0.5-0.7-0.6-1.1c1.2-0.3,1.1-0.3,1.3,0.9
				c0.1,0.7,0.1,1.4-0.1,2.1C35.8,46,35.5,45.1,35,44.3z"/>
			<path fill="#FBFAF9" d="M8.1,5c0-0.5,0-1,0-1.4c1.7,0.4,3.4,0.6,5,1.2c-0.4,0-0.8,0-1.2,0l0,0c-0.6,0-1.3-1-1.9,0.1
				C9.9,5.1,9.2,5.1,9.1,4.5c0.1-0.2,0.3-0.4-0.1-0.3C8.5,4.3,8.5,4.7,8.3,5C8.3,5,8.2,5,8.1,5z"/>
			<path fill="#FCFBFA" d="M29.2,8.8c0-0.1,0-0.2,0-0.2c1.3,0.3,2.7,0,3.8,1C31.8,9.3,30.5,9.1,29.2,8.8z"/>
			<path fill="#FBF9F6" d="M17.5,21.3c0.8,0.2,1.8,0.4,1.2,1.7C18.1,22.5,17.6,22.1,17.5,21.3z"/>
			<path fill="#FCFBFA" d="M54.2,46.5c-0.2-0.3,0.9-0.5,0.1-0.9c-0.1,0,0.2-0.3,0.3-0.2c0.5,0.3,1-0.1,1.5,0
				C55.5,45.8,55,46.4,54.2,46.5z"/>
			<path fill="#F4EDE2" d="M75.3,31.6c0.5-0.7,1.1-1.1,1.9-1.2C76.7,31.1,76,31.3,75.3,31.6z"/>
			<path fill="#F9F8F6" d="M64.5,49.9c0.6-0.1,1,0.5,1.6,0.5c0.1,0-0.1,0.3-0.1,0.5C65.4,50.6,64.9,50.3,64.5,49.9z"/>
			<path fill="#F6F0E8" d="M56.1,45.3c0.4-0.9,0.8-0.5,1.2,0C56.9,45.3,56.5,45.3,56.1,45.3z"/>
			<path fill="#FCFBFA" d="M53,47.2c0.2-0.5,0.6-0.8,1.2-0.7C53.8,46.8,53.5,47.2,53,47.2z"/>
			<path fill="#FBF9F6" d="M17,20.6c0.2,0,0.4-0.2,0.5-0.1c0.3,0.3-0.1,0.6,0,0.8C17.3,21.1,17.1,20.8,17,20.6z"/>
			<path fill="#F5EEE3" d="M15.8,19.4c0.6,0,0.7,0.4,0.7,1C16.2,20,15.8,19.8,15.8,19.4z"/>
			<path fill="#FBF9F6" d="M18.9,23.2c0.4,0.2,0.7,0.5,0.7,1C19.2,24,18.9,23.7,18.9,23.2z"/>
			<path fill="#DFD2B9" d="M82.7,56.9c-0.5-0.1-0.7-0.4-1-0.7C82.3,56.1,82.5,56.4,82.7,56.9z"/>
			<path fill="#F4EDE2" d="M57.3,45.3c0.4,0,0.7,0,0.7,0.5C57.7,45.8,57.4,45.6,57.3,45.3z"/>
			<path fill="#6E6F73" d="M234.6,97.1c-1.9,0.3-3.9,0.7-5.8,0.9c-1.7,0.2-3.4,0.2-5.1,0.3c-0.5,0-0.7-0.2-0.6-0.7
				c0.1-0.5,0.1-1.1,0.1-1.7c0.2-0.4,0.3-0.7,0.8-0.7c3.3,0,6.6-0.2,9.9-0.7c0.5-0.1,0.8,0.1,0.9,0.5
				C234.9,95.8,235.7,96.5,234.6,97.1z"/>
			<path fill="#F9F9F9" d="M61.8,31.4c-1.1-0.5-2.2-0.7-3.1-1.5c1.3-0.2,2.6,0.5,3.9,0c0.1,0.5-1,0.6-0.3,1.2
				C62.1,31.2,62,31.3,61.8,31.4z"/>
			<path fill="#F8F8F8" d="M50.4,31.1c1.3,0.6,2.5,1.3,3.6,2.2c-0.2,0.4-0.5,0.3-0.7,0c-0.6-0.9-1.8-0.4-2.6-1.1
				C50.1,31.7,49.9,31.6,50.4,31.1z"/>
			<path fill="#FAFAFA" d="M45,24c-0.9-0.4-1.9-0.6-2.6-1.3c0.4-0.2,0.9-0.9,1.3,0c0.1,0.1,0.2,0.2,0.3,0c0.4-0.9,0.8-0.5,1.2,0
				c0.1,0.1,0.4,0,0.6,0.1C45,22.9,44.3,23,45,24z"/>
			<path fill="#FAF9F9" d="M33.1,20.3c1,0.4,1.8,1,2.6,1.7c-0.3,0.3-0.4,1.1-0.9,0.1c-0.2-0.4-0.7,0.4-1.2-0.1
				C33.1,21.5,33.2,20.9,33.1,20.3z"/>
			<path fill="#F6F5F5" d="M45.1,27.7c0.8,0.5,1.7,0.7,2.3,1.5c-0.8,0.8-1.6-0.1-1.8-0.4c-0.4-0.8-0.6-0.3-0.9,0
				C44.5,28.3,45,28.1,45.1,27.7z"/>
			<path fill="#F7F7F6" d="M52.7,27.3c-1-0.2-1.9-0.5-2.7-1.2c0.7-0.1,1.4,0.4,2.3,0.1C52.7,26.1,52.9,26.8,52.7,27.3z"/>
			<path fill="#FAFAFA" d="M48.4,25.4c-1-0.1-1.9-0.6-2.7-1.2c1.1-0.4,2,0.4,2.9,0.7C48.8,25,48.4,25.2,48.4,25.4z"/>
			<path fill="#F9F9F9" d="M57.1,35.2c1.1,0.5,2.2,1.1,3.2,1.9c-1.3,0-2.2-1.1-3.4-1.4C56.7,35.4,56.9,35.3,57.1,35.2z"/>
			<path fill="#FAF9F9" d="M35.9,22.2c1,0.1,1.7,0.9,2.5,1.4c-0.5,0.4-1.1,0.3-1.4-0.3C36.7,23,36.3,22.6,35.9,22.2z"/>
			<path fill="#F9F9F8" d="M35.5,19.6c-0.8-0.1-1.4-0.4-2-1c0.3-0.3,0.7-0.1,1-0.1c0.4,0,1.4-0.4,0.7,0.7
				C35.2,19.4,35.4,19.5,35.5,19.6z"/>
			<path fill="#F7F7F7" d="M67.4,34c-0.5-0.4-1.4-0.2-1.7-0.9C66.9,32.6,67.3,32.8,67.4,34z"/>
			<path fill="#F6F5F5" d="M32.3,18.2c-0.8-0.2-1.6-0.5-2.2-1C31.1,17,31.8,17.4,32.3,18.2z"/>
			<path fill="#F9F9F8" d="M36.9,20.3c-0.6-0.1-1-0.3-1.5-0.7C36.2,19.4,36.9,19.1,36.9,20.3z"/>
			<path fill="#F8F7F7" d="M30.9,19.1c0.5,0.1,1,0.3,1.4,0.7C31.8,20.3,31.2,20,30.9,19.1z"/>
			<path fill="#F7F7F6" d="M53.7,27.8c-0.3-0.1-0.8-0.1-0.9-0.5c0.1,0,0.2,0,0.2-0.1c0.3-0.2,0.5-0.6,1-0.4
				C53.5,27.1,53.9,27.6,53.7,27.8z"/>
			<path fill="#F7F7F7" d="M65.7,33.1c-0.3,0-0.5,0-0.7-0.3c0.5-0.4,1.1-0.8,1.9-0.6C66.5,32.7,65.7,32.4,65.7,33.1z"/>
			<path fill="#F9F9F9" d="M65,32.8c-0.4,0-0.7-0.2-1-0.5c0.3-0.1,0.4-0.5,0.8-0.4C65.2,32.2,64.9,32.6,65,32.8z"/>
			<path fill="#F6F5F5" d="M29.9,17.2c-0.6-0.1-1.1-0.4-1.7-0.2C28.9,16.3,29.5,16.5,29.9,17.2z"/>
			<path fill="#FCFCFB" d="M8.3,5c0-0.9,0.5-1.1,1.2-0.9c0,0.3-0.3,0.3-0.5,0.4C8.5,4.2,8.7,4.9,8.3,5z"/>
			<path fill="#F8F7F7" d="M28.3,17c0,0.6,0.7,0.5,0.9,1C28,17.8,28,17.8,28.3,17z"/>
			<path fill="#F9F9F9" d="M63.8,32.3c-0.4,0-0.7-0.2-1-0.5C63.5,31.2,63.6,31.9,63.8,32.3z"/>
			<path fill="#F0F0F0" d="M54.7,33.8c0.6-0.1,0.9,0.2,1.2,0.7C55.4,34.4,54.9,34.3,54.7,33.8z"/>
			<path fill="#F5F4F3" d="M57.1,35.2c-0.1,0.2-0.2,0.3-0.2,0.5c-0.2-0.2-0.6,0-0.8-0.3c0.3-0.3,0.6-0.1,0.9-0.2
				C56.9,35.2,57,35.2,57.1,35.2z"/>
			<path fill="#FAF9F9" d="M35.8,23.5c0.4-0.1,0.8-0.1,0.8,0.2c0,0.5-0.3,0.1-0.4,0C36.1,23.8,36,23.7,35.8,23.5z"/>
			<path fill="#F8F8F8" d="M53.3,33.3c-0.4,0.3-0.6,0.5-1.1,0.3C52.6,33.2,52.9,33.3,53.3,33.3z"/>
			<path fill="#F9F9F9" d="M62.8,31.9c-0.4-0.1-0.7-0.1-1-0.5C62.3,31.3,62.6,31.4,62.8,31.9z"/>
			<path fill="#F8F7F7" d="M30.4,19.9c-0.1,0.1-0.2,0.2-0.3,0.1c-0.1,0-0.1-0.1-0.2-0.2c0.1,0,0.2-0.1,0.2-0.1
				C30.2,19.7,30.3,19.8,30.4,19.9z"/>
			<path fill="#F8F7F7" d="M29.7,18.4c0.4,0,0.7,0.1,0.9,0.5C30.2,18.9,29.9,18.8,29.7,18.4z"/>
			<path fill="#FBFAF9" d="M11.9,4.7c-0.3,0.3-0.6,0.3-1,0.1C11.3,4.6,11.6,4.8,11.9,4.7L11.9,4.7z"/>
			<path fill="#FEFEFE" d="M39,11.3c0.6-0.4,1-0.1,1.4,0.1C39.9,11.5,39.5,11.5,39,11.3z"/>
			<path fill="#FEFEFE" d="M35,10.5c-0.5,0-1.1,0-1.6,0C33.9,10.3,34.5,10.1,35,10.5L35,10.5z"/>
		</g>
	</g>
</g>
<g id="arm_and_plane">
	<g id="plane">
		<g id="_x37_aV8Vf_2_">
			<g>
				<path fill="#FEFEFE" d="M233.3,85.1c-1.6-1.1-3.2-2.1-4.8-3.2c-2.3-1.5-4.5-3.1-6.7-4.6c-1.7-1.2-3.4-2.4-5.1-3.6
					c-0.3-0.3-0.7-0.5-1-0.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.8-1-0.9-1.7-1.2l0,0c-0.2-0.2-0.3-0.6-0.7-0.5c-0.2,0-0.3-0.1-0.5-0.1
					c-0.2-0.4-0.6-0.6-1-0.7c-0.7-0.9-1.8-1.3-2.7-1.9c-0.2-0.1-0.4-0.1-0.5,0.1c-0.2,0.2-0.5,0.4-0.7,0c0-0.1,0.2-0.3,0.4-0.5
					c0-0.8-0.5-1.2-1.2-1.4c-0.8-0.2-1.1-0.8-1.3-1.5c-0.2-0.7-0.7-1.2-1.5-1c-0.2,0-0.3,0-0.5,0c-0.5,0-0.3-0.9-1.1-0.6
					c-0.4,0.2-1-0.4-1.4-0.7l0,0c-0.8-0.9-1.1-2.1-1.3-3.3c0-0.1,0-0.3,0-0.4c0.2-0.4,0.2-1,0.9-0.9c0.3,0.1,0.4,0.2,0.7-0.1
					c0.4-0.5,1.4-0.6,2-0.3c-0.2,0.3-0.5,0.2-0.7,0.3c0.3-0.1,0.6-0.2,0.8-0.5c0.3-0.1,0.7-0.3,1-0.2c0.3-0.1,0.5-0.2,0.8,0
					c0.4,0.3,0.8,0.6,1.3,0.9c0.2,0.1,0.2,0.5,0.6,0.3c0.9-0.4,1.8-0.8,2.7-1.2c1.2-0.6,2.6-0.4,3.9-0.7c0.1,0,0.1,0,0.2-0.1
					c0.1,0.1,0.3,0.2,0.3,0.4c-0.1,1.4,0.6,1,1.4,0.8c1.3-0.5,2.6-0.8,3.9-1.3c0.4-0.2,0.9-0.3,1.4-0.2c1.3-0.1,2.6-0.6,3.9-0.4l0,0
					c0.1,0.7-0.5,0.6-1,0.9c1.1,0.1,2-0.2,2.8-0.7c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.3,0.7-0.2c1.1,0.2,2.1-0.2,3.1-0.4l0,0
					c1,0.9,2.1,1,3.4,0.5c1.7-0.6,3.4-1.2,5.2-1.3c0.1,0,0.1-0.1,0.2-0.1c0.4,0.2,0.9,0,1.3,0.1c0.3,0,1-0.4,0.7,0.6
					c-0.1,0.3,0.3,0.1,0.4,0.1c1-0.2,1.9-0.4,2.8-1c0.8-0.5,1.9-0.4,2.9-0.4c1.1-0.2,2.1-0.4,3.2-0.5c3.5-0.1,6.9-0.8,10.3-0.8
					c1.2,0,2.4-0.6,3.7-0.3c0.3,0.1,0.4,0.4,0.6,0.6c0.4,0.6,0.9,0.7,1.4,0.3c0.5-0.4,1.1-0.5,1.8-0.6c1.2-0.1,2.1-1.2,3.4-1
					c2.6-0.6,5.2-0.9,7.8-0.8c0.2,0.4-0.2,0.6-0.3,0.8c-0.3,0.4-0.6,0.9-0.4,1.3c0.5,0.9-0.1,1.2-0.8,1.4c-0.7,0.2-1,0.5-0.7,1.3
					c0.1,0.2-0.1,0.4-0.1,0.5c-0.1,1-1.1,1.4-1.5,2.2c-0.8,1.3-1.7,2.5-2.6,3.7c-0.5,0.5-1.1,1.1-1.4,1.7c-0.1,0.1-0.1,0.2-0.2,0.3
					c-0.2,0.2-0.4,0.4-0.5,0.7l0,0c-0.3,0.6-0.8,0.4-1.4,0c0.2,0.6,1,0.3,0.7,0.9l0,0c-0.3,0.3-0.7,0.5-0.7,1
					c-0.1,0-0.1,0.1-0.2,0.1c-0.7-0.1-0.2,0.4-0.3,0.6c-0.1,0.1-0.2,0.3-0.4,0.4c-0.9-0.3-1.6,0.1-2.4,0.3c-0.4,0.1-0.8,0.4-1.2,0.5
					c-0.1,0-0.2,0-0.3-0.1c-0.4-0.7-1-0.6-1.6-0.3c-0.1,0-0.2,0-0.3-0.1c-0.2-0.5,0.2-0.5,0.4-0.8c-0.3,0.2-0.6,0.5-0.7,0.8
					c0,0-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.3-0.1c-0.3-0.4-0.6-0.4-0.9,0c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.5-0.5-0.8-0.2
					c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1,0-0.2-0.1-0.2-0.2c-0.4-0.6-0.4-0.6-1.4-0.5c-0.7,0.1-1.3,0.5-2,0.5
					c-1.8-0.1-3.7,0-5.4-0.6c-0.1-0.1-0.1-0.1-0.1-0.2c-0.2-0.3-0.3-0.9-0.7-0.1c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.2-0.6-0.8-0.4
					c-0.6,0.2-1.4,0.3-2,0.4c-0.6,0.1-1.3,0.1-1.8-0.3c-0.3-0.9-1-0.4-1.6-0.5c-0.4-0.1-0.9,0.1-1.2,0.3c-0.3,0.1-0.6,0.1-0.8,0
					c-0.8-0.7-0.8-1,0-1.4c-1,0.2-2,0.6-2.9,1c-2,0.2-4-0.1-5.9-0.6c-0.4-0.3-0.9-0.8-1.5-0.3c-0.1,0-0.2,0-0.3,0
					c-0.6-1-1.3-0.5-2-0.2c-0.4,0.2-0.9,0.1-1.3-0.1c-0.7-0.5-1.4-0.6-2.2-0.3c-0.1,0-0.2,0-0.3,0c-0.5-0.1-0.9-0.3-1.3,0.1
					c0,0.1-0.1,0.2-0.1,0.3c0.2,0.4,0.6,0.2,0.9,0.3c0.3,0,0.5,0.2,0.7,0.3c0.3,0.2,0.7,0.2,1,0.2c0.2,0,0.3,0.1,0.4,0.2
					c0.3,0.3,0.7,0.2,0.9,0.4c0.4,0.4,0.2-0.4,0.5-0.2c0.4-0.1,0.7,0,1,0.2c0.9,1.4,0.9,1.4,2.8,0.9c0.2,0,0.3,0,0.5,0
					c0.7,0.1,1.2,0.8,1.9,0.9c0.4,0,0.5-0.2,0.7-0.3c0.3-0.1,0.6-0.1,0.9,0c2.1,0.5,4.2,1,6.3,1.6c0.2,0.1,0.4,0.1,0.5,0.3
					c0.3,0-0.4,0.8,0.4,0.5c0.3-0.1,0.8,0.3,1.3,0.4c0.5,0.1,0.7-0.1,1-0.2c1.2,0.2,2.4,0,3.4,0.9c0.1,1.2,1.1,0.8,1.7,0.9
					c0.6,0.1,1.2-0.3,1.6,0.3c0.1,0.1-0.2,0.1-0.1,0.2c0-0.1,0-0.1,0.1-0.2c0.3,0.2,0.4-0.1,0.6-0.2c0.3-0.1,0.6,0,0.9,0.2
					c0.4,0.2,0.9,0.1,1.4,0.2c0.4,0.2,0.8,0.1,1.1,0.5c0,0.1-0.2,0.2-0.1,0.3c0,0,0.2,0,0.3-0.1c1.2,0,2.4,0.7,3.6,0.3
					c1.3,0.1,2.6,0.5,3.8,0.8c0.7,0.2,1.1,0.8,1.3,1.5c0.2,1.1,0.8,2.1,0.8,3.2c0,0.5,0.3,0.9,0.8,1c1.3,0.4,1.9,1.3,2.4,2.5
					c0.2,0.6,0.2,1.3,1,1.6c0.3,0.1,0.4,0.8-0.4,0.8l0,0c-0.5-0.4-1.1-0.4-1.7-0.5c-2.5-0.4-4.9-1.3-7.4-1.9
					c-0.3-0.2-0.6-0.1-0.8-0.2l0,0c-0.5-0.1-0.9-0.1-1.1,0.4l0,0c-0.7,0.4-1,1.1-1.4,1.7l0,0c-0.4,0.3-0.7,0.7-0.9,1.1l0,0
					c-0.6,0.1-0.5-0.3-0.5-0.6c0-0.3,0.3-0.5,0.3-0.9c-0.7,0.2-0.5,0.7-0.4,1c0.1,0.6,0,1-0.6,1.2c-1.1,0.5-1.5,1.3-1.5,2.5
					c-1.5,2-3.1,4-4.6,6c-0.4,0.4-0.5,0-0.7-0.2c-0.6-0.7-1.1-1.5-2.2-1.5c-0.6,0-0.6-0.7-0.9-1.2c-0.1,0.7-0.2,1.2-0.9,0.5
					c-0.2-0.2-0.5-0.3-0.7-0.3c-1.1-0.5-2-1.3-3-2c-0.6-0.7-0.5-1.8-1.4-2.8c0.1,0.6,0.8,1,0.3,1.4c-0.4,0.4-0.8-0.1-1.2-0.2
					c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.2-0.4-0.4-0.6-0.7c-0.3-0.8-0.5-1.5-1.6-1c-0.3,0.1-0.7-0.1-0.8-0.4
					C234.3,84.9,233.8,85,233.3,85.1z"/>
				<path fill="#98989C" d="M252.9,71.8c-0.3-0.1-0.6-0.2-0.9-0.2c-1.3-0.4-2.8-0.2-4.1-1c-1-0.7-2.3-0.3-3.4-0.8
					c-1,0-1.8-0.2-2.7-0.7c-2.5-0.6-5-1.3-7.6-1.8c-1,0.2-1.8-0.5-2.8-0.6c-0.1,0-0.2-0.1-0.3-0.1c-1.1,0.1-2.1-0.2-3-0.8
					c-0.3-0.1-0.6-0.1-0.9-0.2c-0.5-0.1-1.1,0-1.6-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.7,0-1-0.2c-0.2-0.1-0.4-0.2-0.7-0.3
					c-0.4-0.2-1.2,0.3-1.1-0.6l0,0c0.5-0.5,1-0.5,1.6-0.3c0.1,0,0.1,0,0.2,0c0.8-0.1,1.6,0.1,2.4,0.2c0.4,0.2,0.8,0.2,1.2,0.1
					c0.8-0.1,1.4,0.2,2.2,0.3l0,0c0.5,0,1.1-0.1,1.6,0.2c2,0.1,4,0.6,6,0.6c1-0.2,2,0,2.9,0.4c0.2,0.2,0.5,0.1,0.7,0
					c1,0.1,2-0.1,2.9,0.3c0.6,0.3,1.2,0.3,1.8,0.2c1,0.3,2-0.2,2.9,0.3l0,0c0.3,0.1,0.8-0.2,1.1,0.2c1.8,0.2,3.6,0.5,5.4,0.5
					c1.1,0.2,2.4-0.3,3.4,0.4l0,0c0.4,0,0.7,0,1.1,0.1l0,0c0.4,0,0.7,0,1.1,0.1c0.1,0,0.1,0,0.2,0c0.4-0.1,0.8-0.2,1.1,0.1l0,0
					c0.2,0.2,0.5-0.2,0.7,0l0,0c0.7,0,1.4-0.2,1.9,0.3l0,0c1,0,2-0.3,2.9,0.3c0.8,2.2,0.8,4.6,1.3,6.9c0.5,2.8,0.9,5.6,1.3,8.6
					c-0.3-0.2-0.5-0.4-0.7-0.5c-0.8-0.2-1.1-0.9-1.4-1.5c-0.9-1.5-2-2.8-3.1-4.3c-0.5-0.7-0.9-1.4-1.2-2.2c-0.3-1-1.1-1.5-2.1-1.6
					c-1-0.1-1.9-0.4-2.9-0.6c-1.2-0.2-2.3-0.4-3.5-0.6c-0.1,0-0.2-0.1-0.3-0.2c-0.4-0.1-0.8-0.2-1.2-0.4
					C253.9,72,253.4,71.8,252.9,71.8z"/>
				<path fill="#FCFCFB" d="M199.7,59c0.4,1.2,0.8,2.4,1.2,3.6c-1.9-1-3.4-2.4-5.2-3.6c-0.3-0.2-0.6-0.6-0.5-1
					c0.1-0.6,0.6-0.4,1-0.4c1,0,2-0.3,3.1-0.1C199.8,58,199.9,58.4,199.7,59z"/>
				<path fill="#F9F8F6" d="M264.3,75.4c1.9,2.7,3.8,5.3,5.6,8c0.3,0.9,0.1,1.3-0.9,1.1c0.4-0.2,0.4-0.7,0.2-0.7
					c-1.3-0.3-1.1-1.6-1.3-2.3c-0.4-1.1-1.3-1.4-1.9-1.6c-0.7-0.2-1-0.5-1-1C265,77.7,264.3,76.6,264.3,75.4z"/>
				<path fill="#FCFCFB" d="M221,56.1c-0.5-0.1-0.8,0.1-1.2,0.3c-1.9,0.9-3.9,1.4-6.2,2.2c0.4-0.9,0.6-1.5,0.4-2.2
					c2.3-0.3,4.6-0.6,6.9-0.5C221,55.8,221,55.9,221,56.1C221,56,221,56,221,56.1z"/>
				<path fill="#FCFBF9" d="M200.9,62.6c0.5,0.2,1,0.4,1.5,0.6c0.4-0.2,0-0.7,0.3-0.9c0,0,0.2,0,0.2,0.1c0.3,0.8,0.4,1.6,1.6,1.2
					c0.7-0.2,1.1,0.6,1.2,1.3c0.2,1.2,1.1,1.4,2,1.4c0.4,0,0.5,0.4,0.6,0.6c0.3,0.4-0.1,0.4-0.3,0.5c-0.1,0-0.1,0.3-0.1,0.4
					c-1.1-0.3-1.7-1.4-2.6-2c-1.3-1-2.7-1.8-4-2.7C201.2,63,201.1,62.8,200.9,62.6z"/>
				<path fill="#FBFAF9" d="M277.5,55.7c-0.5-1.8-0.5-1.8,1.5-2.4c0,0,0-0.1,0.1-0.1c-0.7-0.8-0.5-1.5,0.7-2.9
					c0.4-0.1,1-0.5,1.3-0.1c0.4,0.5-0.1,0.9-0.3,1.3C279.7,53,278.8,54.6,277.5,55.7z"/>
				<path fill="#FCFBFA" d="M213.8,56.4c-0.8,0.2-1.6,0.6-2.4,0.4c-0.4-0.1-0.8,0.1-1.1,0.4c-1.3,0.9-2.8,1.4-4.2,1.7
					c-0.2-0.6,0.4-0.2,0.4-0.6c-0.7,0-1.1-0.3-1.2-1.1c2.1-0.5,4.3-0.4,6.4-0.7C212.3,56.4,213,56.1,213.8,56.4z"/>
				<path fill="#FCFCFB" d="M239.3,54.1c-2,0.7-4,1.3-6,2c-1.1,0.3-2.1-0.1-2.6-1.2c1.9-0.3,3.9-0.6,5.8-0.6
					C237.5,54.3,238.3,53.8,239.3,54.1z"/>
				<path fill="#FCFBF9" d="M271.9,51.2c-1.6,0.1-2.7,1.7-4.4,1.3c-0.1,0-0.1,0-0.2,0.1c-1.7,1-1.9,0.9-2.6-0.9
					C267.2,51.4,269.5,51.2,271.9,51.2z"/>
				<path fill="#FBFBF9" d="M242.8,91.6c0.6-0.3,0.8,0.5,1.3,0.4c0.3-0.5-0.4-0.6-0.3-1c0.1,0,0.4-0.1,0.4,0
					c0.6,1.6,2.7,1.4,3.5,2.8c0.1,0.2-0.2,0.9,0.5,0.6c-0.5,1.1-1.3,0.6-1.7,0.1C245.4,93.3,243.9,92.7,242.8,91.6z"/>
				<path fill="#FDFCFA" d="M247.6,53.4c-0.6,0.2-1.1,0.4-1.7,0.3c-0.7-0.1-1,0.5-1.5,0.7c-0.9,0.5-2.1,0.4-3,0.9
					c-0.2,0.1-0.4,0-0.5-0.2c-0.2-0.5,0.3-0.5,0.5-0.8c-0.6-0.4-1.4,0.7-1.9-0.3C242.2,53.7,244.9,53.4,247.6,53.4z"/>
				<path fill="#FCFBFA" d="M252.8,88.3c-0.6-0.8-0.1-2.3,0.9-2.6c0.9-0.3,1.1-0.8,0.8-1.5c-0.4-1.1,0.5-1,1-1
					c0.6,0,0.3,0.4,0.1,0.7c-0.3,0.4-0.9,0.7-0.1,1.2C254.8,86.3,253.9,87.4,252.8,88.3z"/>
				<path fill="#FCFBFA" d="M230.7,54.9c-1.1,0.2-2.1,1-3.3,0.6c-0.3-0.1-0.3,0.3-0.4,0.4c-0.5-0.1-0.9,0.3-1.2,0.3
					c0.3,0,0.7-0.4,1.2-0.3c-0.9,0.7-2,1.1-3.2,0.9c-0.3-0.1-0.6,0.1-0.6-0.4c0-0.3,0.4-0.2,0.6-0.2c0.4-0.1,0.9-0.1,1.1-0.6
					C226.7,54.7,228.8,55,230.7,54.9z"/>
				<path fill="#FCFBF9" d="M233.3,85.1c0.2-0.1,0.3-0.3,0.4-0.5c0.2-0.5,0.5-0.5,0.7,0c0.2,0.9,0.6,1.5,1.7,0.9
					c0.4-0.2,0.6-0.1,0.7,0.4c0.2,0.6,0.4,1.3,0.7,1.9C235.9,87.2,234.6,86.1,233.3,85.1z"/>
				<path fill="#FCFBF9" d="M237.5,88c0.4-0.2,0.7,0.3,1.1,0c0-0.6-0.7-0.6-1-0.9c1-0.6,1-0.6,1.6,0.4c0.3,0.6,0.6,1.3,0.6,2
					C238.9,89.3,238.3,88.6,237.5,88z"/>
				<path fill="#FBFAF9" d="M199.7,59c-0.1-0.5-0.3-0.9-0.4-1.4c1.7-0.2,3.4-0.5,5.2-0.4c-0.4,0.1-0.8,0.3-1.1,0.4l0,0
					c-0.6,0.2-1.6-0.6-1.8,0.7c0,0.3-0.7,0.5-1-0.1c0-0.2,0.1-0.4-0.2-0.2c-0.4,0.2-0.2,0.6-0.3,0.9C199.9,58.9,199.8,59,199.7,59z"
					/>
				<path fill="#FCFBFA" d="M221,56.1c0-0.1,0-0.2-0.1-0.2c1.3-0.1,2.6-0.8,4-0.3C223.6,55.7,222.3,55.9,221,56.1z"/>
				<path fill="#FBF9F6" d="M213.7,71.6c0.9,0,1.8-0.1,1.7,1.2C214.7,72.5,214,72.3,213.7,71.6z"/>
				<path fill="#FCFBFA" d="M256.5,84.1c-0.3-0.3,0.7-0.8-0.2-0.9c-0.1,0,0.1-0.3,0.2-0.3c0.6,0.1,0.9-0.4,1.4-0.5
					C257.5,83,257.2,83.7,256.5,84.1z"/>
				<path fill="#F4EDE2" d="M271.9,63.3c0.2-0.8,0.7-1.4,1.4-1.7C273.1,62.3,272.5,62.8,271.9,63.3z"/>
				<path fill="#F9F8F6" d="M267.3,84c0.5-0.3,1.1,0.2,1.6-0.1c0.1,0,0,0.3,0.1,0.5C268.4,84.4,267.8,84.3,267.3,84z"/>
				<path fill="#F6F0E8" d="M257.9,82.3c0.1-1,0.6-0.7,1.1-0.4C258.7,82.1,258.3,82.2,257.9,82.3z"/>
				<path fill="#FCFBFA" d="M255.5,85.1c0.1-0.5,0.3-0.9,0.9-1.1C256.2,84.5,256,84.9,255.5,85.1z"/>
				<path fill="#FBF9F6" d="M213,71.1c0.2-0.1,0.4-0.3,0.5-0.3c0.4,0.2,0.1,0.6,0.2,0.8C213.5,71.4,213.3,71.2,213,71.1z"/>
				<path fill="#F5EEE3" d="M211.5,70.3c0.6-0.1,0.8,0.2,1,0.7C212.2,70.8,211.7,70.7,211.5,70.3z"/>
				<path fill="#FBF9F6" d="M215.7,73c0.4,0.1,0.8,0.2,1,0.7C216.2,73.6,215.9,73.4,215.7,73z"/>
				<path fill="#F4EDE2" d="M259,81.9c0.3-0.1,0.7-0.2,0.8,0.2C259.6,82.3,259.3,82.2,259,81.9z"/>
				<path fill="#F9F9F9" d="M259,67.3c-1.1-0.1-2.3,0.1-3.4-0.4c1.2-0.6,2.6-0.3,3.7-1.2c0.3,0.4-0.8,0.9,0.1,1.2
					C259.2,67.1,259.1,67.2,259,67.3z"/>
				<path fill="#F8F8F8" d="M248,70.6c1.4,0.2,2.8,0.4,4.1,1c-0.1,0.4-0.4,0.4-0.7,0.2c-0.9-0.6-1.9,0.2-2.9-0.2
					C248,71.3,247.8,71.2,248,70.6z"/>
				<path fill="#FAFAFA" d="M240.8,65.6c-1-0.1-2,0-2.9-0.4c0.4-0.3,0.5-1.1,1.3-0.5c0.1,0.1,0.2,0.1,0.3-0.1c0.1-1,0.6-0.7,1.1-0.4
					c0.1,0.1,0.4-0.1,0.6-0.1C240.4,64.5,239.8,64.8,240.8,65.6z"/>
				<path fill="#FAF9F9" d="M228.2,65.8c1,0.1,2,0.4,3,0.8c-0.2,0.4-0.1,1.2-0.9,0.4c-0.3-0.3-0.5,0.6-1.1,0.3
					C228.6,66.9,228.5,66.3,228.2,65.8z"/>
				<path fill="#F6F5F5" d="M242,69.1c0.9,0.2,1.9,0.2,2.7,0.7c-0.5,1-1.5,0.4-1.9,0.1c-0.7-0.6-0.7-0.1-0.9,0.3
					C241.5,69.8,242,69.5,242,69.1z"/>
				<path fill="#F7F7F6" d="M249.1,66.3c-1,0.1-2,0.2-2.9-0.3c0.7-0.3,1.5-0.1,2.2-0.6C248.7,65.2,249.1,65.8,249.1,66.3z"/>
				<path fill="#FAFAFA" d="M244.4,65.8c-1,0.2-2,0.1-2.9-0.3c1-0.7,2-0.2,3-0.2C244.7,65.3,244.4,65.6,244.4,65.8z"/>
				<path fill="#F9F9F9" d="M255.7,72.4c1.2,0.1,2.4,0.4,3.6,0.8c-1.3,0.4-2.4-0.3-3.7-0.3C255.4,72.8,255.6,72.6,255.7,72.4z"/>
				<path fill="#FAF9F9" d="M231.6,66.7c1-0.2,1.9,0.3,2.8,0.6c-0.4,0.6-0.9,0.6-1.4,0.2C232.5,67.1,232,67,231.6,66.7z"/>
				<path fill="#F9F9F8" d="M230.3,64.4c-0.8,0.1-1.5,0.1-2.2-0.3c0.2-0.3,0.6-0.3,0.9-0.4c0.4-0.1,1.2-0.8,0.9,0.5
					C229.9,64.2,230.2,64.3,230.3,64.4z"/>
				<path fill="#F7F7F7" d="M265.1,68.1c-0.6-0.2-1.4,0.2-1.9-0.3C264.2,66.9,264.6,67,265.1,68.1z"/>
				<path fill="#F6F5F5" d="M226.9,64c-0.8,0.1-1.6,0-2.4-0.2C225.3,63.2,226.1,63.4,226.9,64z"/>
				<path fill="#F9F9F8" d="M231.9,64.6c-0.6,0.1-1.1,0-1.6-0.2C230.9,63.9,231.5,63.4,231.9,64.6z"/>
				<path fill="#F8F7F7" d="M225.8,65.3c0.5-0.1,1.1,0,1.6,0.3C227,66.2,226.3,66,225.8,65.3z"/>
				<path fill="#F7F7F6" d="M250.1,66.5c-0.4,0-0.8,0.1-1.1-0.2c0.1,0,0.1-0.1,0.2-0.1c0.3-0.3,0.3-0.7,0.8-0.7
					C249.8,65.8,250.3,66.1,250.1,66.5z"/>
				<path fill="#F7F7F7" d="M263.2,67.7c-0.3,0.1-0.5,0.1-0.7,0c0.4-0.5,0.8-1.1,1.7-1.2C263.9,67.1,263,67.1,263.2,67.7z"/>
				<path fill="#F9F9F9" d="M262.4,67.7c-0.4,0.1-0.7,0-1.1-0.1c0.2-0.2,0.2-0.6,0.6-0.6C262.4,67,262.3,67.5,262.4,67.7z"/>
				<path fill="#F6F5F5" d="M224.3,63.8c-0.5,0.1-1.1,0-1.6,0.3C223,63.2,223.6,63.3,224.3,63.8z"/>
				<path fill="#FCFCFB" d="M200,58.9c-0.3-0.8,0.1-1.1,0.9-1.2c0.1,0.3-0.2,0.4-0.3,0.6C199.9,58.1,200.3,58.8,200,58.9z"/>
				<path fill="#F8F7F7" d="M222.7,64.1c0.2,0.6,0.8,0.3,1.1,0.6C222.6,65,222.6,65,222.7,64.1z"/>
				<path fill="#F9F9F9" d="M261.2,67.6c-0.4,0.1-0.7,0-1.1-0.1C260.6,66.6,260.8,67.3,261.2,67.6z"/>
				<path fill="#F0F0F0" d="M252.9,71.8c0.5-0.3,1-0.1,1.4,0.3C253.8,72.1,253.3,72.3,252.9,71.8z"/>
				<path fill="#F5F4F3" d="M255.7,72.4c0,0.2-0.1,0.4-0.1,0.5c-0.3-0.1-0.6,0.2-0.9-0.1c0.2-0.3,0.5-0.3,0.8-0.4
					C255.6,72.4,255.6,72.4,255.7,72.4z"/>
				<path fill="#FAF9F9" d="M231.8,68c0.4-0.2,0.7-0.4,0.8,0c0.1,0.5-0.2,0.2-0.4,0.2C232.2,68.1,232.1,68.1,231.8,68z"/>
				<path fill="#F8F8F8" d="M251.5,71.8c-0.3,0.4-0.4,0.7-0.9,0.6C250.8,71.9,251.2,72,251.5,71.8z"/>
				<path fill="#F9F9F9" d="M260.1,67.5c-0.4,0.1-0.7,0.1-1.1-0.1C259.4,67.1,259.8,67.1,260.1,67.5z"/>
				<path fill="#F8F7F7" d="M225.6,66.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.2
					C225.3,66.1,225.4,66.1,225.6,66.2z"/>
				<path fill="#F8F7F7" d="M224.4,65c0.4-0.1,0.7-0.1,1,0.2C225.1,65.3,224.7,65.4,224.4,65z"/>
				<path fill="#FBFAF9" d="M203.3,57.6c-0.2,0.4-0.5,0.5-0.9,0.4C202.6,57.7,203,57.7,203.3,57.6L203.3,57.6z"/>
				<path fill="#FEFEFE" d="M231,55.3c0.5-0.6,0.9-0.4,1.4-0.3C232,55.3,231.6,55.4,231,55.3z"/>
				<path fill="#FEFEFE" d="M227,55.9c-0.5,0.2-1,0.3-1.5,0.5C225.9,56,226.3,55.6,227,55.9L227,55.9z"/>
			</g>
		</g>
	</g>
	<g id="arm">
		<g id="HkX3dz_2_">
			<g>
				<path fill="#D9DFE7" d="M350.3,102.6c0.1,0,0.2,0,0.2,0c0.2,0.8,0.3,1.6,0.5,2.3c-0.1,0.1-0.3,0.1-0.4,0
					C350.2,104.2,349.9,103.4,350.3,102.6z"/>
				<path fill="#E5EAEF" d="M350.8,105c0.1,0,0.1,0,0.2-0.1c0.1,0.4,0.2,0.8,0.3,1.2c-0.1,0-0.1,0-0.2,0.1
					C350.6,105.8,350.5,105.4,350.8,105z"/>
				<path fill="#EBE2CF" d="M335.8,141.1c-2.3,3.2-4.4,6.4-6.9,9.4c-1.8,2.2-3.3,4.7-5.1,6.9c-1.6,1.9-2.9,4-4.5,5.8
					c-2.2,2.6-4.1,5.6-6.2,8.3c-1.3,1.6-2.4,3.4-3.7,5.1c-2.2,2.8-4.3,5.6-6.6,8.3c-1.7,2-3.2,4.3-5.3,6c-2.5,2-5.3,3.5-8.8,2.7
					c-2.6-0.6-4.8-2.1-6.6-4.1c-2-2.1-4-4.3-5.2-6.9c-1.3-2.7-2.6-5.4-3.6-8.3c-0.7-1.9-1.4-3.9-2.2-5.8c-1.2-3.1-2.4-6.2-3.5-9.3
					c-1.7-4.9-3.5-9.7-4.9-14.6c-1.2-4-2.7-8-4-11.9c-1.5-4.8-3.4-9.4-4.7-14.2c-0.8-3-1.5-6-2.8-8.8c-1.2-2.5-3-4.6-5-6.4
					c-3-2.8-5.7-5.7-6.8-9.9c-0.7-2.7-1.1-5.5-1.7-8.2c-0.4-1.5-0.2-2.6,1.1-3.5c0.4-0.3,0.2-0.7,0.1-1.1c-0.4-2.2,0-2.8,2.2-3.4
					c0.2-0.1,0.4-0.1,0.6-0.2c1.6-0.8,2.4-0.8,3.5,0.5c0.8,0.8,1.4,1.7,1.8,2.8c1,2.7,1.9,5.4,3.2,8c1.3,2.4,3.3,4.5,5.1,6.5
					c0.5,0.5,0.6,0,0.7-0.3c0.4-1.5,0.8-3.1,1.2-4.6c0.3-1,0.7-1.9,1.5-2.7c0.3-0.4,0.7-0.9,1.3-0.6c0.6,0.3,0.8,0.9,0.6,1.4
					c-0.2,0.9,0,1.8-0.2,2.7c-0.3,2,0.2,3.9,0.5,5.8c0.5,3.1,1.1,6.2,0.7,9.4c-0.2,1.7,0.4,3.3,1.1,4.8c2.3,4.7,4.7,9.3,7.2,13.9
					c1.5,2.7,2.7,5.7,4.3,8.4c2.8,4.8,5.2,9.9,8.1,14.7c2.3,3.8,4.3,7.8,6.7,11.5c0.7,1.1,1.4,2.3,2.2,3.4c0.6,0.8,1.2,0.9,2,0.1
					c1.5-1.5,2.3-3.5,3.3-5.2c1-1.6,1.9-3.2,2.7-4.9c1.7-3.4,3.7-6.5,5.5-9.8c2.3-4,4.7-7.9,7.1-11.8c1.4-2.3,2.8-4.7,4.4-7
					c0.5-0.3,0.7,0.1,1.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0.1,0.4,0.3,0.6,0.4c0.1,0.1,0.3,0.1,0.4,0.2c0.5,0.2,0.9,0.4,1.3,0.8
					c0.3,0.3,0.7,0.4,1.1,0.6c0.8,0.6,1.7,1.2,2.5,1.8c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.3,0.4,0.5,0.7,0.5c1.3,0.9,2.6,1.8,3.9,2.7
					c0.3,0.3,0.8,0.5,1.1,0.7c1.5,1.6,3.3,2.8,4.7,4.4C335.6,138.4,336.1,139.6,335.8,141.1z"/>
				<path fill="#BBC8D6" d="M323.5,128.6c-1-0.5-1.9-1-2.6-1.9c-0.4-0.2-0.4-1-1.1-0.5c-0.5-0.3-1-0.6-1.4-0.9
					c-0.1-0.1-0.1-0.2,0-0.3c0.1-0.3,0.3-0.5,0.1-0.8c-0.3-0.3-0.6,0.1-0.8,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.4-0.2-0.7-0.3-1.1-0.5
					c0,0,0-0.1,0-0.1c-0.2-0.7,1.6-0.4,0.8-1.4c0.6-0.4,0.8-1.3,1.8-1.4c0.3,0,0.3-0.3,0-0.6c-0.4-0.4-0.2-0.7,0.1-1
					c0.3-0.1,0.3-0.4,0.4-0.6c0.4-0.8,1-1.4,1.5-2.1c0.4-0.2,0.5,0.1,0.7,0.3c0.1,0.1,0.1,0.3,0.1,0.5c0,0.1,0,0.1,0,0.2
					c0.1,0,0.1-0.1,0.1-0.2c0-0.2,0-0.4,0.1-0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.4-0.2,0.6-0.7,1-0.7c0.1,0,0.2,0,0.3,0
					c0.3,0.1,0.6,0.2,0.9,0.4c0.4,0.4,0.8,0.7,0.3,1.3c-0.3,0.2-0.6,0.2-1,0c-0.1-0.1-0.3-0.4-0.4-0.1c-0.1,0.2,0.2,0.4,0.4,0.5
					c0.2,0.1,0.4,0.3,0.6,0.5c0.5,0.4,0.3,0.8-0.1,1.1c-0.1,0.3-0.1,0.6-0.2,0.8c-0.2,0.7,0.5,0,0.5,0.3c0.4,0.1,0.9,0.2,0.5,0.9
					c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0.2-0.1,0.2-0.3,0.1-0.4c0-0.4-0.2-0.8-0.3-1.1c0-0.4,0.1-0.7,0.3-0.9c0.1-0.1,0.2-0.1,0.3-0.2
					c0.5-0.1,0.9-0.3,0.9-0.9c0-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.3-0.3,0.5-0.3c0.2,0,0.3,0.1,0.4,0.2c0.1,0.3,0.2,0.6,0.3,0.9
					c0,0.4-0.1,0.9,0.5,0.5c0.2-0.1,0.4,0,0.6,0.1c0.2,0.2,0.5,0.1,0.8,0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.2,0.1
					c0.1,0.1,0.2,0.1,0.3,0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.1-0.1-0.2-0.1-0.3
					c0-0.4-0.2-0.8-0.2-1.2c0-0.2,0.1-0.3,0.2-0.4c0.5-0.8-0.5-0.5-0.6-0.8c-0.1-0.1-0.1-0.2-0.2-0.3c0.2-0.5,0.3-1.2,0.8-1.5
					c0.2-0.1,0.4-0.2,0.6-0.2c0.3-0.1,0.8,0.3,1-0.4c-0.4-0.1-0.6,0.3-0.9,0.2c-1-0.2-0.9-0.7-0.5-1.3c0.1-0.2,0.4-0.4,0.6-0.6
					c0.1-0.4,0.8,0.4,0.6-0.3c0-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.5,0.7-0.5c0.3,0.1,0.6,0.1,0.8,0
					c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.3,0.6-0.4c0.2,0,0.4,0.1,0.6-0.1c0.2,0,0.4,0,0.6-0.2c0.1-0.1,0.2-0.1,0.2-0.2
					c0.2-0.1,0.4-0.2,0.5-0.4c-0.1-0.3-0.4-0.1-0.6-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.5-1-0.4-1.5,0c-0.1,0.1-0.3,0.1-0.5,0.1
					c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.4-0.4-0.8-0.5-1.2c-0.1-0.6,0.6-0.9,0.6-1.4c0-0.5-0.3-1.1,0.1-1.6c0.1-0.1,0.2-0.1,0.3-0.2
					c0.3-0.1,0.6-0.1,0.9-0.2c0.1,0,0.1,0,0.2,0c0.2,0,0.2-0.1,0.1,0s-0.1-0.2-0.1,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4-0.1-0.5-0.4
					c-0.1-0.4,0.3-0.7,0.4-1c0.4-0.7-0.1-1.3,0-2c-0.1-0.4,0.1-0.8,0.5-0.9c0,0,0.1,0,0.1,0.1c0.2,0.2,0.5,0,0.7,0
					c0.2,0,0.3,0,0.5,0.1c0.2,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.2,0.4,0.1,0.5c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.1,0,0.2
					c0.2-0.1,0.1-0.3,0.2-0.4c0-0.1,0-0.2,0.1-0.3c0.2-0.6,0.7-0.7,1.3-0.6c0.2,0.2,0.2,0.4,0.2,0.6c0,0.3-0.2,0.6-0.2,0.9
					c0,0.2-0.1,0.5,0,0.7c0,0.1,0.1,0.1,0.2,0.1c0-0.1,0-0.2-0.1-0.2c0-0.2,0-0.4,0.2-0.6c0.1-0.1,0.3-0.2,0.4-0.3
					c0.3-0.1,0.6-0.2,0.4-0.7c0-0.1,0-0.2-0.1-0.3c0-0.3,0.2-0.5,0.4-0.6c0.1,0,0.2-0.1,0.3-0.1c-0.2-0.1-0.3,0.1-0.5,0
					c-0.4-0.2-0.4-0.5-0.5-0.8c0-0.4,0-0.9,0.1-1.3c0.3-0.7,0.9-1.2,1.2-1.8c0.1,0,0.1,0,0.2-0.1c0.1-0.2,0.3-0.1,0.5-0.2
					c0.1-0.1,0.5,0.6,0.5-0.2c0-0.3,0.7,0,1.1,0c2,1.1,3.6,2.7,5,4.4c1,1.1,1.8,2.4,2.4,3.9c0.2,0.8,0.3,1.6,0.5,2.4
					c0.1,0.4,0.2,0.8,0.3,1.2c-0.2,1.4,0.4,2.7,0.5,4.1c0.1,1.9-0.2,3.8-0.5,5.7c-0.4,2.4-1.7,4.4-2.9,6.4c-1.8,3-3.7,5.8-5.6,8.7
					c-0.6,1-1.3,1.9-2,2.8c-1.5,1.7-2.5,3.8-3.3,5.9c-0.2,0.6-0.5,1.2-0.9,1.7c-0.5,0.7-0.3-0.2-0.5-0.3c-0.1-2.9-2.2-4.5-4.1-6.1
					c-0.8-0.6-1.5-1.3-2.1-2.1c-0.1-0.3-0.4-0.2-0.6-0.3c-0.1-0.1-0.2-0.6-0.5-0.2c-1.2-1-2.7-1.7-3.8-2.8c-0.2-0.1-0.4-0.2-0.4-0.4
					c0-0.1,0.1-0.2,0.1-0.3c0.2-0.4,0.2-0.9,0.4-1.3c0,0,0.1-0.1,0.1-0.1c-0.1-0.1-0.1-0.1-0.2,0c-0.2,0.1-0.4,0.2-0.5,0.4
					C323.8,128.1,323.5,128.2,323.5,128.6z"/>
				<path fill="#BCC9D7" d="M331.2,111.2c0,0.3,0.2,0.5-0.3,0.7c-0.7,0.3-0.6,0.6,0.2,1c0.2,0.2,0.1,0.4-0.1,0.5
					c-0.2,0.1-0.3,0.2-0.5,0.3c-0.4-0.1-0.4-0.6-0.8-0.7c-0.2,0-0.5,0.1-0.7,0c-0.4-0.2-0.5,0.2-0.7,0.3c-0.1,0-0.2,0-0.3,0
					c0.1-0.4-0.1-0.4-0.4-0.4c-0.1-0.2-0.1-0.4,0-0.7c-0.1-0.2-0.1,0.1-0.2,0c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.5-0.6-0.6-1
					c0-0.2-0.1-0.4,0-0.6c0.2-0.6-0.4,0-0.4-0.2c-0.3-0.3-1-0.2-1.1-0.8c0.3-0.9,1-1.5,1.5-2.3c0.3-0.1,0.7-0.2,1-0.4
					c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.2,0,0.3,0c0.5,0.1,1,0.2,1.1-0.6c0.1-0.2,0.1-0.3,0.3-0.4c0.2,0,0.5,0,0.7,0.1
					c0.2,0.2,0.2,0.4,0.2,0.6c-0.1,0.7,0.5,1,0.9,1.2c0.1,0.1,0.2,0.2,0.3,0.4c0,0.1,0,0.2-0.1,0.3c0,0.2-0.5,0-0.3,0.4
					c0.1,0.1,0.1,0.1,0.1,0.2c-0.1,0.4-0.4,0.7-0.4,1.1c-0.1,0.3-0.2,0.6-0.2,0.9C331.2,111.1,331.2,111.1,331.2,111.2z"/>
				<path fill="#BCC9D7" d="M327.7,112.9c0.4-0.1,0.4,0.1,0.3,0.4c0.4,0.4-0.7,0.2-0.2,0.6c0.1,0.2,0.1,0.4-0.1,0.5
					c-0.3,0.1-0.2,0.4-0.3,0.6c-0.3,0.3-0.5,0.7-1,0.8c-0.4,0-0.7-0.1-1-0.2c-0.3,0.1,0,0.2,0,0.3c0.7,0.7,0.6,1.6,0.6,2.5
					c0,0.3-0.2,0.5-0.3,0.8c-0.1,0-0.1,0-0.2,0c-0.8,0-0.4,0.5-0.3,0.8c0.3,0.3,0.9,0.6,0.3,1.2c0.2,0.2,0,0.4-0.1,0.6
					c-0.1,0.2-0.3,0.5-0.5,0.4c-0.3-0.1-0.3-0.4-0.1-0.7c0.6-0.6-0.2-0.3-0.3-0.5c-0.2-0.3-0.2-0.7-0.2-1c0-0.2-0.2-0.4-0.4-0.6
					c0-0.3,1-0.2,0.3-0.8c-0.1-0.5,0.3-0.8,0.5-1.1c0.2-0.5-0.2-0.8-0.5-1.1c-0.1-0.4-0.6-0.2-0.8-0.3l0,0c-0.8-0.3-0.9,0.5-1.3,0.8
					c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0-0.3,0.1-0.5,0.1c-0.2-0.1-0.4-0.3-0.6-0.4c0-0.2,0-0.4,0.1-0.4c0.9,0,0.5-0.7,0.6-1.2
					c0-0.4,0.9,0,0.6-0.6c0.2-0.5,0.8-1,0.7-1.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.5,0,0.9,0.3,1.4,0.3c0.1,0,0.2,0,0.3,0.1
					c0.1,0,0.3,0.2,0.3,0.1c0.1-0.2-0.1-0.2-0.2-0.3c-0.2-0.3-0.5-0.6-0.6-0.9c0-0.3,0.2-0.4,0.4-0.5c0.5-0.1,0.8-0.5,1.1-0.9
					c0.1-0.1,0.2-0.1,0.3-0.1c0.3,0,0.5,0.2,0.7,0.4c0.1,0.5,0.4,1,0.9,1.3C327.5,112.6,327.5,112.8,327.7,112.9z"/>
				<path fill="#C4CFDB" d="M334.7,99.9c0.4,0.7,0.7,1.4,0.2,2.2c-0.5,0.1-0.8,0.2-0.8,0.8c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2-0.1,0.3
					c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.2-0.4,0.4-0.5,0.7c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.2-0.4,0.5-0.7,0.6
					c-0.4,0-0.6-0.2-0.6-0.6c0.1-0.3,0.2-0.7,0.1-1c0-0.1,0-0.2,0-0.3c0-0.3,0-0.6-0.1-0.9c0-0.1,0.1-0.2,0.1-0.2
					c0.1-0.1,0.3-0.1,0.4,0c0.4,0,0.1-0.3,0.2-0.4c0-0.3,0.1-0.5,0.5-0.5c0.3,0.2,0.6,0.3,1,0c1-0.2,0.3-1.2,0.8-1.7
					C334.5,99.9,334.6,99.9,334.7,99.9z"/>
				<path fill="#C0CCD9" d="M324.5,111.9c-0.2,0.1-0.4,0.1-0.4,0.3c0.1,0.3,0,0.3-0.2,0.3c-0.3,0-0.5,0-0.5-0.4
					c0.2-1.1,0.9-1.8,1.6-2.6c0.3,0.4,1,0.1,1.1,0.7c-0.1,0.4-0.7,0.3-0.9,0.6C325,111.3,324.7,111.5,324.5,111.9z"/>
				<path fill="#C9D3DE" d="M339.4,96.6c0,0.5,0,1,0.1,1.5c-0.5,0.6-1.1,0.2-1.7,0.3c-0.2,0-0.4,0-0.6-0.2c-0.1-0.4,0.3-0.5,0.4-0.7
					c-0.1-0.7,0.5-1,1-1.2C338.7,96.6,339,96.7,339.4,96.6z"/>
				<path fill="#BBC8D6" d="M334.4,100c0.1,0.8,0.8,1.8-0.8,1.9c-0.3,0.1-0.4-0.1-0.5-0.3c-0.1-0.3,0.1-0.6,0.3-0.8
					C333.6,100.2,333.7,99.7,334.4,100z"/>
				<path fill="#CBD5DF" d="M331.8,103.7c0,0.1,0,0.2,0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.4,0.3-0.2,1-0.8,1
					c-0.3-0.1-0.4-0.5-0.5-0.7c-0.1-0.9,0.3-1.6,1-2.2c0.2,0.1,0.4,0.1,0.3,0.4l0,0C331.6,103.1,331.6,103.4,331.8,103.7z"/>
				<path fill="#C6D1DC" d="M337.2,98.1c0.1,0.2,0.3,0.2,0.5,0.1c0.2,0.2,0.1,0.4-0.1,0.6c-0.3,0.3-0.7,0.4-1.2,0.4
					c-0.2,0-0.3,0.1-0.5,0.1c-0.2-0.2-0.6-0.1-0.8-0.3c0,0,0,0,0,0c0.3-0.3,0.7-0.6,1-1C336.5,97.9,336.9,98,337.2,98.1z"/>
				<path fill="#CCD6E0" d="M330.1,105.8c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.4-1.1,0.1-1.3,0.8c-0.1,0-0.1,0-0.2,0
					c-0.2-0.1-0.5-0.1-0.7-0.4c-0.1-0.3,0.1-0.5,0.2-0.7c0.3-0.3,0.5-0.4,0.9-0.2c0.4,0.2,0.7,0.1,0.6-0.4c0.2-0.1,0.3-0.1,0.4,0
					c0.1,0.1,0.2,0.2,0.2,0.4C330.1,105.7,330.1,105.7,330.1,105.8z"/>
				<path fill="#C5D0DC" d="M329.7,105.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4-0.2-0.4-0.4-0.1-0.8c0.4-0.4,0.6-0.1,0.9,0.1
					c0.4,0.1,0.5,0.3,0.4,0.7c0.2,0.2,0.2,0.4,0,0.6C330.3,105.7,330.1,105.4,329.7,105.4z"/>
				<path fill="#BFCCD9" d="M331.6,102.7c-0.1-0.1-0.2-0.3-0.3-0.4c0.5-0.1,0.7-0.5,1-0.9c0.3-0.4,0.6-0.8,1.1-0.8
					c0,0.3-0.1,0.5-0.1,0.8c-0.1,0.2-0.2,0.3-0.4,0.3c-0.3,0.1-0.4,0.3-0.4,0.6c-0.1,0.2-0.2,0.3-0.4,0.3
					C331.9,102.7,331.8,102.7,331.6,102.7z"/>
				<path fill="#C3CFDB" d="M323.3,112.2c0.2,0.2,0.5,0.4,0.8,0.1c0.4,0.2,0.6,0.5,0.7,0.8c0.1,0.4-0.1,0.4-0.4,0.3
					c-0.1,0-0.2,0-0.3,0.1c-0.3-0.3-1.1,0-0.9-0.8C323.2,112.5,323.2,112.3,323.3,112.2z"/>
				<path fill="#EBEEF2" d="M339.4,96.6c-0.5,0.4-0.7,0.2-0.8-0.3c0.2,0,0.6,0.3,0.7-0.2c0.2-0.7,0.7-0.9,1.2-1.2
					C340.5,95.7,339.8,96.1,339.4,96.6z"/>
				<path fill="#CBD5DF" d="M342.8,94.4c-0.5,0.1-1.3-0.1-0.9,0.9c0.1,0.1-0.1,0.3-0.3,0.3c-0.2,0-0.1-0.1-0.1-0.3
					c0.1-0.8-0.5-0.5-0.8-0.5C341.3,94.4,341.9,93.4,342.8,94.4z"/>
				<path fill="#C3CEDB" d="M337.2,98.1c-0.4,0.4-0.7,0-1,0c0.4-0.4,0.8-0.7,1.4-0.5C337.7,97.9,337.7,97.9,337.2,98.1z"/>
				<path fill="#D1D9E3" d="M327.9,105.8c0,0.2-0.1,0.5-0.1,0.7c0,0.3-0.3,0.4-0.4,0.6c-0.2,0.5-0.6,0.3-0.9,0.2
					C326.8,106.7,327.4,106.3,327.9,105.8z"/>
				<path fill="#CDD6E0" d="M323.2,112.7c0,0.6,0.9,0.2,0.9,0.8c-0.6,0.4-0.9-0.2-1.3-0.4C322.9,112.9,323.1,112.8,323.2,112.7z"/>
				<path fill="#EEF0F3" d="M321.9,114.7c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.3-0.2,0.2-0.4,0.2c-0.2,0-0.5,0.1-0.7,0.1
					C321.1,114.7,321.6,114.8,321.9,114.7z"/>
				<path fill="#CBD5DF" d="M319.2,119c0.1,0.2,0.1,0.4,0.2,0.6c-0.2,0-0.4,0-0.5,0C318.8,119.3,319,119.1,319.2,119z"/>
				<path fill="#CCD5DD" d="M317.2,124.6c0.3-0.4,0.4-1,1.1-0.9c0.4,0.1,0.5,0.4,0.6,0.7c0.2,0.5-0.4,0.6-0.5,1
					c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.3-0.4-0.6-0.4C317.4,124.7,317.3,124.6,317.2,124.6z"/>
				<path fill="#E9ECF0" d="M324.6,129.1c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0-0.2-0.1-0.3-0.2c-0.3,0.1-0.5-0.1-0.6-0.4
					c-0.3-0.6,0.3-0.6,0.6-0.9C324.9,127.9,324.4,128.6,324.6,129.1z"/>
				<path fill="#E4E7EC" d="M328.5,132.4c0.4-0.2-0.3-0.6,0.2-0.9c0.2,0.5,0.4,1,1.1,1c0.2,0-0.1,0.3-0.2,0.4
					C329.1,133,328.8,132.8,328.5,132.4z"/>
				<path fill="#F2F3F4" d="M319.8,126.2c0.6,0,0.1-0.8,0.5-0.7c0.5,0.1,0.5,0.7,0.6,1.2C320.4,126.8,320.1,126.6,319.8,126.2z"/>
				<path fill="#E3E5E3" d="M324.1,129c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.3,0.3,0.4C324.1,129.7,323.9,129.6,324.1,129z"/>
				<path fill="#EDEFF1" d="M317.5,124.7c0.4-0.3,0.4-0.3,0.6,0.4C317.8,125.1,317.6,124.9,317.5,124.7z"/>
				<path fill="#BFCCD9" d="M327.7,113.9c-0.3,0-0.6-0.1-0.5-0.4c0.2-0.6,0.6,0.1,0.9-0.1c0.1,0,0.2,0,0.2-0.1
					c0.4,0.1,0.7-0.2,1.1,0c0.5,0.4,0.7,0.8,0.2,1.4c-0.2,0.2,0,0.4,0.1,0.5l0,0c0.2,0.4,0.4,0.8,0.7,1.1c-0.1,0.1-0.1,0.2-0.2,0.3
					c-0.4,0.5,0.4,0.7,0.3,1.2c0,0.1,0,0.1,0,0.2c-0.1,0.3,0.3,0.2,0.3,0.4c0.2,0.3,0.6,0.5,0.2,0.9c-0.3,0.1-0.7-0.3-0.9,0.2
					c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2-0.6,0.1-1,0c-0.1,0-0.2-0.1-0.3-0.1c-0.4,0-0.6,0.8-1.1,0.2c-0.3-0.4,0.1-0.7,0.2-1.1
					c0.2-0.4,0.7-0.5,0.7-1c-0.1-0.5,0-1.1-0.4-1.6c-0.1-0.1-0.2-0.2-0.3-0.4c0-0.2,0-0.3,0.1-0.5
					C328,114.6,328.1,114.2,327.7,113.9z"/>
				<path fill="#C4D0DC" d="M325.7,118.5c0.1-0.9-0.2-1.8-0.6-2.6c0-0.2,0-0.4,0.1-0.5c0.4-0.1,0.7,0.2,1.1,0.3
					c0.5,0.7,1-0.2,1.5,0.1c0.5,0.4,0.4,1,0.6,1.5c-0.2,0.3-0.5,0.5-0.9,0.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3,0-0.4,0.1-0.4,0.4
					c-0.1,0.1-0.1,0.2-0.2,0.3C326.4,118.4,326.2,118.8,325.7,118.5z"/>
				<path fill="#BECBD8" d="M333.1,109.8c-0.4,0-0.4,0.4-0.6,0.6c-0.2-0.1-0.4-0.1-0.5-0.4c0-0.2,0.1-0.5,0-0.7
					c-0.1-0.2-0.2-0.3-0.3-0.4l0,0c-0.2-0.4,0.3-0.3,0.4-0.6l0,0c0.4-0.8,1-0.6,1.6-0.4l0,0c0.2,0.3,0,0.5-0.1,0.7
					c-0.2,0.3,0,0.8-0.2,1.1C333.3,109.8,333.2,109.8,333.1,109.8z"/>
				<path fill="#F7F8FA" d="M333.6,113.7c0.3,0.1,0.7-0.1,0.6,0.2c-0.2,0.6,0,1.4-0.6,1.9c-0.2,0.1-0.6,0.2-0.6-0.3
					C333.1,114.9,333.1,114.2,333.6,113.7z"/>
				<path fill="#F1F3F5" d="M329.7,115.2c-0.3-0.1-1.1-0.3-0.5-0.6c1-0.5,0.1-0.9,0.1-1.3c0-0.1,0-0.1,0-0.2
					c0.1-0.3,0.4-0.2,0.6-0.5c0.9-0.1,0.2,0.9,0.7,1.1C330,114.1,330.2,114.9,329.7,115.2z"/>
				<path fill="#C5D0DC" d="M337.6,98.7c0-0.2,0.1-0.3,0.1-0.5c0.5-0.6,1.2,0.3,1.7-0.1c-0.1,0.3-0.1,0.5,0.2,0.7
					c0,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.3,0.3-0.5,0.3c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.7,0.1-0.8-0.3
					C337.5,98.9,337.5,98.8,337.6,98.7z"/>
				<path fill="#F4F6F8" d="M342.4,120.6c0.4,1,0.3,1.6-0.4,1.5c-0.4-0.1-1,0.1-1.1-0.4c-0.1-0.5,0.6-0.3,0.9-0.7
					C341.8,120.6,342,120.4,342.4,120.6z"/>
				<path fill="#D2DAE3" d="M333.7,107.9c-0.5,0.3-1.2-0.2-1.6,0.4c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.1-0.2-0.1-0.3
					c0.3-0.3,0.7-0.4,1.2-0.2c-0.4-0.3-0.9-0.7-0.6-1.3c0.6-0.3,0.6,0.3,0.9,0.5C333.6,107,333.6,107.5,333.7,107.9z"/>
				<path fill="#EBEFF2" d="M333.2,106.6c-0.5,0.2-0.5-0.4-0.8-0.6c-0.2-0.1-0.2-0.3-0.1-0.4c0.1-0.3,0.3-0.5,0.6-0.6
					c0.3,0.1,0.7,0.2,1.1,0.3C334,105.9,333.3,106,333.2,106.6z"/>
				<path fill="#E4E8ED" d="M338.5,123.3c0.2-0.5-0.5-0.6-0.5-1.1c0.1-0.3-0.4-0.7,0-1c0.1,0,0.2,0,0.2,0c0.2,0.2,0.3,0.1,0.5-0.1
					c0.3,0.1,0.7,0.2,0.4,0.6c-0.5,0.6-0.1,1.2-0.1,1.8C338.8,123.7,338.7,123.5,338.5,123.3z"/>
				<path fill="#C5D0DC" d="M338.7,118.1c-0.2,0-0.5,0.2-0.6-0.1c-0.1-0.3,0.1-0.5,0.3-0.5c0.6-0.2,1.1-0.5,1.7-0.2
					c-0.5,1.1-0.5,1.1-0.9,0.7C339,117.9,338.8,117.9,338.7,118.1z"/>
				<path fill="#F0F2F5" d="M338.9,108.9c0,0.2,0.7,0.5-0.1,0.6c-0.4,0.1-0.8,0-0.9-0.5c-0.1-0.4,0-0.7,0.4-0.8
					C338.9,108.1,339,108.5,338.9,108.9z"/>
				<path fill="#C5D0DC" d="M334.9,112.3c0,0.4,0.5,0.6,0.3,1c-0.7,0.1-0.9-0.9-1.6-0.8c1.1-0.1,0.9-0.9,0.8-1.5
					c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.2,0.1,0.3,0.3,0.3,0.5C335.1,111.7,335,112,334.9,112.3z"/>
				<path fill="#C8D2DE" d="M323.8,126.6c-0.2,0.3-0.4,0.6-0.6,0.9c-0.1,0.2-0.3,0.3-0.5,0.2c-0.3-0.2-0.1-0.4-0.1-0.6
					c-0.4-0.5,0-0.7,0.3-1c0.3-0.1,0.6,0,0.8,0.3C323.8,126.4,323.9,126.4,323.8,126.6C323.8,126.5,323.8,126.6,323.8,126.6z"/>
				<path fill="#F7F8F9" d="M330.4,116.3c-0.8,0-0.9-0.4-0.7-1.1c0.2,0,0.4,0.1,0.7,0.1c0.3,0,0.7,0.1,0.8,0.4
					C331.2,116.2,330.7,116.2,330.4,116.3z"/>
				<path fill="#F4F6F8" d="M331,113.4c0-0.2,0.1-0.3,0.1-0.5c0.3-0.2,0.7-0.5,1.1-0.7c0.2,0.5-0.2,1,0.3,1.7
					C331.8,113.7,331.4,113.6,331,113.4z"/>
				<path fill="#EBEEF2" d="M337.4,115.1c-0.2-1.1,0.7-1.3,1.2-1.8c0,0,0.3,0.1,0.3,0.3c0.1,0.2,0,0.5-0.1,0.5
					C338,114,337.8,114.6,337.4,115.1z"/>
				<path fill="#D2DAE3" d="M331.7,108.9c0.2,0,0.4,0,0.5,0.2c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.3,0.1,0.5,0.3,0.7
					c-0.1,0.2-0.2,0.3-0.4,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3-0.2-0.5-0.4-0.3-0.7C331,109.6,331.4,109.3,331.7,108.9z"/>
				<path fill="#C4CFDB" d="M335.2,109.1c-0.1,0.3-0.3,0.1-0.5,0.1c-0.3-0.3-0.3-0.7-0.3-1.1c0.4-1,1-0.6,1.5-0.2l0,0
					c0.2,0.2,0.2,0.4,0.1,0.6C335.8,108.8,335.4,108.8,335.2,109.1z"/>
				<path fill="#DCE2E9" d="M337.1,112.6c0,0.3-0.2,0.5-0.4,0.5c-0.4,0-0.7-0.5-0.9-0.8c-0.1-0.2,0-0.4,0.3-0.4
					C336.4,112,336.8,112.3,337.1,112.6z"/>
				<path fill="#C4D0DC" d="M344.1,118.7c0-0.2,0.2-0.3,0.3-0.3c0.4,0,0.6-0.6,1-0.3c0.1,0.1,0.1,0.5-0.1,0.5
					C344.9,119,344.5,119.2,344.1,118.7z"/>
				<path fill="#F3F5F7" d="M327.5,117.6c0.3-0.2,0.5-0.4,0.9-0.4c0.1,0,0.2,0.1,0.3,0.2c0.1,0.8-0.5,0.8-1,0.9
					C327.4,118.1,327.4,117.9,327.5,117.6z"/>
				<path fill="#F3F5F7" d="M341,126.6c-0.1-0.6-1.1-0.3-1.1-1c0-0.2,0.2-0.5,0.4-0.4C340.8,125.5,341,126,341,126.6z"/>
				<path fill="#F0F2F5" d="M335.9,107.9c-0.5,0-1.1-0.6-1.4,0.3c-0.1,0.2-0.2,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.2-0.2
					c0-0.2,0.1-0.3,0.2-0.4C334.9,107,335.5,107.1,335.9,107.9z"/>
				<path fill="#C9D3DE" d="M337.6,98.7c0,0.1,0,0.2,0,0.3c-0.1,0.4-0.6,0.6-0.5,1.1l0,0c-0.1,0.2-0.1,0.4-0.4,0.3l0,0
					c-0.3-0.1-0.5-0.3-0.4-0.7c0-0.2,0.1-0.3,0.1-0.5C336.7,98.8,337.2,98.9,337.6,98.7z"/>
				<path fill="#C1CDD9" d="M335.1,104.9c-0.4,0-0.6-0.2-0.7-0.5c-0.1-0.2,0-0.5,0.3-0.5c0.4,0,0.5,0.3,0.7,0.6
					C335.5,104.7,335.3,104.8,335.1,104.9z"/>
				<path fill="#D6DDE5" d="M347.7,116.5c-0.2,0.3-0.6,0.4-0.8,0.7c-0.1,0.2-0.4,0.1-0.5-0.1c-0.1-0.2-0.2-0.5,0.2-0.5
					C346.9,116.6,347.2,116,347.7,116.5z"/>
				<path fill="#C2CEDA" d="M324.6,129.1c-0.2-0.5-0.4-0.9-0.6-1.4c-0.1-0.6,0.4-0.2,0.7-0.4c0.2-0.1,0.3,0,0.3,0.2
					C324.9,128,324.7,128.6,324.6,129.1z"/>
				<path fill="#CDD6E0" d="M341,109.8c0.7,0,1.1,0.7,1.7,0.7c0,0.1,0,0.3,0,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3-0.2-0.6-0.3-1-0.2
					C341.1,110.5,341.3,110,341,109.8z"/>
				<path fill="#CCD5E0" d="M324.5,117.6c0,0.5-0.3,0.7-0.5,1.1c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.3-0.4-0.5,0.1-0.7
					C323.9,117.6,324.2,117.7,324.5,117.6z"/>
				<path fill="#F3F5F7" d="M336,124.1c0,0-0.1,0-0.1,0c-0.3-0.2-0.9,0-0.9-0.6c0-0.1,0.1-0.3,0.2-0.4c0.6-0.4,0.6,0.4,0.9,0.5
					C336.3,123.8,336.2,124,336,124.1z"/>
				<path fill="#ECEEF2" d="M333.4,109.7c0-0.4-0.4-0.8,0-1.2c0.3,0.2,0.4-0.3,0.7-0.2l0,0c0.5,0.4,0.5,0.9,0.3,1.4
					c-0.1,0-0.2,0-0.2,0.1C333.9,109.4,333.6,110,333.4,109.7z"/>
				<path fill="#EAEDF0" d="M325.7,118.5c0.3-0.1,0.6-0.3,0.9-0.4c0.3,0.9-0.3,1.1-1,1.2C325.7,119,325.7,118.8,325.7,118.5z"/>
				<path fill="#CFD8E1" d="M332.8,119.8c0,0.3-0.1,0.4-0.3,0.4c-0.3,0.1-0.6,0-0.7-0.3c-0.1-0.3,0.1-0.5,0.3-0.5
					C332.4,119.4,332.6,119.6,332.8,119.8z"/>
				<path fill="#F3F5F7" d="M339.3,105.5c0-0.1,0-0.2,0-0.2c0-0.3-0.4-0.8,0.2-1c0.1,0,0.3,0,0.3,0c0.2,0.4,0.3,0.8,0,1.1
					C339.7,105.6,339.5,105.6,339.3,105.5z"/>
				<path fill="#EDF0F3" d="M323.5,117.5c-0.2,0.2-0.1,0.5-0.1,0.7c-0.6,0.1-0.7-0.4-0.7-0.7c0-0.3,0.3-0.4,0.6-0.4
					C323.7,117.1,323.5,117.4,323.5,117.5z"/>
				<path fill="#C3CEDB" d="M344.3,98.7c0-0.2-0.5-0.5,0-0.6c0.3,0,0.6,0.1,0.6,0.4c0,0.3-0.1,0.6-0.5,0.6
					C344,99.2,344.1,98.9,344.3,98.7z"/>
				<path fill="#D9DFE7" d="M339.6,100c0.3,0.5,0.3,0.6-0.5,0.9c-0.3-0.2-0.9-0.1-0.5-0.7C338.9,99.8,339.2,99.8,339.6,100z"/>
				<path fill="#F5F6F8" d="M333.9,103c-0.2-0.9,0-1.3,1-1c-0.2,0.3-0.4,0.6-0.6,0.9C334.2,103.1,334.1,103.1,333.9,103z"/>
				<path fill="#C4CFDB" d="M338.1,128.3c-0.3,0.1-0.4,0-0.5-0.2c0-0.3,0.2-0.5,0.5-0.6c0.2-0.1,0.3,0,0.3,0.2
					C338.5,128,338.4,128.2,338.1,128.3z"/>
				<path fill="#FAFAFB" d="M334.4,109.7c-0.1-0.5-0.2-0.9-0.3-1.4c0.2,0,0.3-0.1,0.5-0.1c0.3,0.3,0.3,0.6,0.2,0.9
					C334.7,109.4,334.6,109.6,334.4,109.7z"/>
				<path fill="#F6F7F8" d="M339.6,100c-0.4,0.1-0.8,0.2-1.2,0.3c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0.1-0.1
					c-0.1-0.2-0.1-0.5-0.2-0.7c0.1-0.1,0.1-0.1,0.2,0c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.1,0.6,0,0.8,0.3
					C339.6,99.9,339.6,99.9,339.6,100z"/>
				<path fill="#FCFCFD" d="M337.3,126.6c-0.2-0.2-0.4-0.3-0.8-0.6c0.5,0,0.9-0.7,1.4-0.1C337.8,126.2,337.9,126.8,337.3,126.6z"/>
				<path fill="#E1E6EC" d="M334.8,110.9c0.2-0.3,0-0.8,0.5-0.7c0.2,0,0.4,0.3,0.4,0.5c0,0.4-0.5,0.3-0.7,0.4
					C335,111.1,334.9,111,334.8,110.9z"/>
				<path fill="#ECEEF2" d="M329.5,122.4c0,0,0.1,0,0.1,0c0.4-0.1,0.9-0.1,1.1,0.3c0,0.1,0,0.3-0.1,0.3c-0.5,0.2-0.8-0.2-1.1-0.4
					C329.5,122.6,329.5,122.5,329.5,122.4C329.5,122.4,329.5,122.4,329.5,122.4z"/>
				<path fill="#D1D9E2" d="M333.9,105.2c-0.4,0.3-0.8,0.2-1.1-0.3c0,0,0,0,0,0c0.8-0.1,0.2-0.8,0.5-1.1c0.2,0,0.3-0.1,0.5-0.1
					C333.5,104.2,333.9,104.7,333.9,105.2z"/>
				<path fill="#F7F8F9" d="M330.5,117.7c0.2-0.4-1.8-0.4-0.3-1.2C330.4,116.9,330.8,117.2,330.5,117.7z"/>
				<path fill="#F2F4F6" d="M345.7,111c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.2-0.6-0.4-0.4-0.8c0-0.1,0.2-0.2,0.2-0.2
					c0.3,0.2,0.6,0.4,0.5,0.8c0,0.1,0,0.2,0,0.3C345.8,111,345.8,111,345.7,111z"/>
				<path fill="#EAEDF1" d="M319.4,124.7c0.1-0.3-0.1-0.9,0.5-0.7c0.2,0,0.4,0.3,0.4,0.4C320.2,124.9,319.7,124.6,319.4,124.7z"/>
				<path fill="#F8F9FA" d="M330.8,120.3c-0.1,0.7,0.1,1.1-0.4,1.2c-0.1,0-0.2-0.1-0.3-0.2C330,120.9,330.3,120.7,330.8,120.3z"/>
				<path fill="#C7D2DD" d="M331.6,110.8c0.1,0,0.2,0,0.3,0c-0.1,0.4,0.8,0.4,0.4,0.8c-0.4,0.4-0.7-0.3-1.1-0.4c0,0,0,0,0,0
					C331.1,110.8,331.3,110.8,331.6,110.8z"/>
				<path fill="#F3F5F7" d="M322.9,126c0.2,0.4-0.4,0.6-0.3,1c-1-0.2-0.3-0.7-0.3-1.1c-0.1-0.1-0.1-0.2,0-0.3
					C322.7,125.5,322.7,125.9,322.9,126L322.9,126z"/>
				<path fill="#F2F3F6" d="M325.1,120.1c-0.4-0.3-0.6-0.8-0.3-1.2c0.2-0.2,0.5,0,0.7,0.4C325.3,119.6,325.2,119.8,325.1,120.1z"/>
				<path fill="#C7D2DD" d="M337.3,126.6c0.4,0,0.4-0.4,0.6-0.7c0.3,0.8-0.3,1.1-0.8,1.5C336.7,127,337.2,126.9,337.3,126.6z"/>
				<path fill="#C8D2DD" d="M335.8,133.5c0.6-0.5,1-0.2,1.4-0.3c0,0.1,0,0.3-0.1,0.3C336.7,133.9,336.3,133.6,335.8,133.5z"/>
				<path fill="#F1F3F5" d="M339.2,99.1c0.1-0.2,0.2-0.3,0.4-0.4c0.4,0.2,0.5-0.8,0.9-0.2c0.3,0.4-0.2,0.6-0.6,0.7
					C339.7,99,339.4,99.3,339.2,99.1z"/>
				<path fill="#C9D3DE" d="M335.8,108.5c0.2-0.1,0.2-0.3,0.1-0.5c0.4,0.2,1.3-0.8,1.1,0.5c-0.5-0.3-0.6,0.2-0.9,0.4
					c-0.1,0-0.1,0-0.2,0.1C335.8,108.8,335.7,108.7,335.8,108.5z"/>
				<path fill="#F1F3F5" d="M338.1,121.2c-0.2,0.3,0.3,0.7,0,1c-0.2-0.3-0.7,0-0.7-0.4C337.4,121.4,337.7,121.3,338.1,121.2z"/>
				<path fill="#D4DCE4" d="M332.3,116.8c-0.3,0.1-0.5,0.6-0.9,0.5c-0.1,0-0.1-0.3,0-0.3C331.6,116.6,332,116.7,332.3,116.8z"/>
				<path fill="#DFE4EA" d="M321.9,116.9c0.2-0.7,0.4-1.3,1.3-0.8C322.6,116.1,322.5,116.9,321.9,116.9z"/>
				<path fill="#FCFCFC" d="M340.5,103.2c0,0-0.1,0.1-0.3,0.2c-0.4,0.1-0.5-0.3-0.7-0.5c-0.1-0.1,0.1-0.3,0.2-0.3
					C340.1,102.6,340.3,102.7,340.5,103.2z"/>
				<path fill="#F4F5F7" d="M338.5,99.3c-0.1,0-0.2,0-0.2,0c-0.4,0.3-0.9,0.2-1.1,0.7c-0.5-0.7,0.2-0.8,0.5-1.1
					C337.9,99.1,338.2,99.2,338.5,99.3z"/>
				<path fill="#E5E9EF" d="M346.7,99.9c-1-0.2-0.2-0.7-0.2-1.1C347.2,99.2,346.8,99.6,346.7,99.9L346.7,99.9z"/>
				<path fill="#CED7E1" d="M344.4,112c-0.4,0.7-0.9,0.7-1.4,0.9C343.3,112.4,343.8,112.2,344.4,112z"/>
				<path fill="#EDEFF3" d="M338.5,101.5c0.3,0,0.6,0,0.7,0.5c-0.5,0.1-0.9,0.1-1.2-0.3C338.1,101.4,338.3,101.4,338.5,101.5z"/>
				<path fill="#E4E9EE" d="M322.9,126c-0.2,0.1-0.3-0.5-0.5-0.1c-0.5-0.7,0.3-0.6,0.5-1C323.2,125.4,322.7,125.7,322.9,126z"/>
				<path fill="#E9ECF0" d="M341.8,117.8c-0.3,0.5-0.6,0.8-1.3,1.1C340.8,118.3,341.1,117.9,341.8,117.8z"/>
				<path fill="#FEFEFE" d="M327.5,123.1c0.3-0.3,0.7-0.1,1.2-0.2C328,123.4,328,123.4,327.5,123.1z"/>
				<path fill="#DAE1E8" d="M324.3,119.8c0.1,0.4,0.2,0.8,0,1.2c-0.3-0.1-0.8,0-0.6-0.6C324,120.3,323.8,119.8,324.3,119.8z"/>
				<path fill="#FAFBFC" d="M335.4,117.9c-0.4,0.4-0.5,0-0.6-0.3c0-0.3-0.2-0.7,0.4-0.5C335.5,117.4,335.5,117.6,335.4,117.9z"/>
				<path fill="#F1F3F6" d="M343.1,119.9c-0.3,0.1-0.4-0.1-0.6-0.3c-0.1-0.2,0.1-0.3,0.2-0.3c0.2,0,0.4,0.1,0.5,0.2
					C343.4,119.6,343.3,119.8,343.1,119.9z"/>
				<path fill="#E5E9EE" d="M334.9,112.3c-0.3-0.2,0-0.5-0.2-0.7c0.1-0.2,0.2-0.3,0.4-0.4C335.7,111.8,335.4,112.1,334.9,112.3z"/>
				<path fill="#DBE1E8" d="M348.7,118.3c-0.2-0.1-0.5-0.1-0.5-0.4c0-0.2,0.2-0.2,0.3-0.2c0.2,0,0.4,0,0.4,0.3
					C348.9,118.2,348.8,118.3,348.7,118.3z"/>
				<path fill="#F2F4F6" d="M341,109.8c0.4,0.2,0.3,0.5,0.4,0.9C340,111,341.1,110.1,341,109.8z"/>
				<path fill="#F9FAFB" d="M336.4,99.7c0.1,0.2,0.3,0.4,0.4,0.7c-0.3-0.1-0.6,0.3-0.8-0.1C335.8,100,336,99.8,336.4,99.7z"/>
				<path fill="#C8D3DE" d="M337.7,120c0.4,0.1,0.7,0.2,0.6,0.6c0,0.1-0.1,0.1-0.2,0.1C337.6,120.7,337.9,120.2,337.7,120z"/>
				<path fill="#E7EAEF" d="M335.2,99c0.3-0.1,0.7-0.2,0.8,0.3c-0.3,0-0.5,0.5-0.8,0.2C335.1,99.5,335.2,99.2,335.2,99z"/>
				<path fill="#E6EAEF" d="M345,103.2c-0.2,0-0.3-0.1-0.2-0.3c0.1-0.2,0-0.4,0.3-0.5c0.2,0,0.3,0.2,0.2,0.3
					C345.3,102.9,345.2,103.1,345,103.2z"/>
				<path fill="#FAFAFB" d="M338.9,103.3c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.2,0,0.5-0.2,0.6c-0.2,0.2-0.3-0.1-0.2-0.2
					C338.9,103.7,338.5,103.5,338.9,103.3z"/>
				<path fill="#DFE4EA" d="M330.1,119.5c0-0.2-0.1-0.5,0.2-0.6c0.3-0.1,0.5,0.2,0.8,0.4c-0.1,0.1-0.1,0.2-0.2,0.3
					c-0.2,0-0.4,0-0.5-0.1C330.3,119.5,330.2,119.5,330.1,119.5z"/>
				<path fill="#E2E6EC" d="M322.2,120.4c-0.3,0.1-0.6,0.6-1,0.3c0,0,0-0.2,0-0.2C321.5,120,321.9,120.4,322.2,120.4z"/>
				<path fill="#EFF1F4" d="M332,125c0.4,0.3,0.4,0.6,0.4,1C331.9,125.8,331.8,125.5,332,125z"/>
				<path fill="#C3CEDB" d="M332.2,127.2c-0.1,0.1-0.1,0.4-0.2,0.5c-0.2,0.1-0.3-0.1-0.3-0.2c0-0.2,0.1-0.4,0.2-0.5
					C332,126.8,332.1,126.9,332.2,127.2z"/>
				<path fill="#CDD6E0" d="M333.4,109.7c0.5,0,0.1-0.6,0.5-0.7c0.3,0.2,0.2,0.5,0.3,0.7c-0.3,0.1-0.7,0.5-1,0
					C333.2,109.8,333.3,109.7,333.4,109.7z"/>
				<path fill="#D3DAE3" d="M334.3,123.6c-0.4-0.3-0.7-0.7-0.4-1.4C334,122.8,334.1,123.2,334.3,123.6z"/>
				<path fill="#E6EAEE" d="M335.8,108.5c0,0.2,0.1,0.3,0.1,0.5c-0.1,0.8-0.5,0-0.7,0.1C335.1,108.6,335.5,108.5,335.8,108.5z"/>
				<path fill="#DFE4EA" d="M348.7,108.1c-0.3-0.1-0.7-0.2-0.8-0.6c0.4-0.4,0.6,0.1,0.9,0.1C349,107.8,348.9,108,348.7,108.1z"/>
				<path fill="#D2DAE3" d="M331.4,125.6c-0.1,0.3-0.3,0.6,0,1.1c-0.7-0.4-0.6-0.8-0.7-1.2C331,125.3,331.2,125.6,331.4,125.6z"/>
				<path fill="#D3DBE4" d="M346.7,99.9c0.3,0.1,0.6,0.1,0.6,0.5c0,0-0.3,0.1-0.4,0.1C346.4,100.5,346.7,100.2,346.7,99.9
					C346.7,99.9,346.7,99.9,346.7,99.9z"/>
				<path fill="#F0F2F5" d="M338.4,100.1c0,0.1,0,0.1,0,0.2c0.2,0.2,0.4,0.4,0.6,0.6c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.5-0.1
					C338,100.5,338.1,100.2,338.4,100.1z"/>
				<path fill="#E4E8ED" d="M336.8,104.7c-0.2,0.2-0.2,0.6-0.6,0.4c-0.1,0-0.1-0.2-0.1-0.3C336.3,104.6,336.5,104.6,336.8,104.7z"/>
				<path fill="#E7EAEF" d="M333,124.4c-0.1-0.4-0.4-0.6-0.1-0.9c0,0,0.2,0,0.3,0.1C333.4,123.7,333.3,124,333,124.4z"/>
				<path fill="#EBEEF2" d="M331.1,128.3c0.2-0.1,0.4,0,0.4,0.2c0,0.2,0,0.4-0.2,0.5c-0.2,0.1-0.3,0-0.3-0.2
					C331,128.6,331.1,128.4,331.1,128.3z"/>
				<path fill="#C6D1DD" d="M349.7,106.1c-0.2,0.1-0.4,0.1-0.4-0.1c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4,0.1
					C350.1,105.9,349.8,105.9,349.7,106.1z"/>
				<path fill="#FDFDFD" d="M336,124.1c0-0.2,0.1-0.4,0.1-0.5c0.3-0.1,0.6,0.1,0.5,0.4C336.6,124.4,336.3,124.4,336,124.1z"/>
				<path fill="#FBFBFC" d="M328.8,119.3c0.3-0.3,0.6-0.4,1,0C329.5,119.3,329.2,120.1,328.8,119.3z"/>
				<path fill="#D2DAE3" d="M347.1,114.5c0.1,0.3,0.1,0.5-0.2,0.5c-0.1,0-0.3-0.2-0.3-0.3c0-0.2,0.1-0.4,0.3-0.5
					C347.1,114.2,347,114.5,347.1,114.5z"/>
				<path fill="#E8ECF0" d="M339.9,111.5c-0.2,0.1-0.5,0.3-0.5,0.2c-0.3-0.3,0.1-0.3,0.2-0.5c0.1-0.1,0.1-0.3,0.1-0.4l0,0
					c0.3,0.1,0.3,0.4,0.4,0.6C340.1,111.5,340,111.6,339.9,111.5z"/>
				<path fill="#E4E8ED" d="M336.7,100.4c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0.6-0.4,0.8-0.8,1.2C336.2,100.7,336.7,100.7,336.7,100.4z"/>
				<path fill="#CCD5E0" d="M333.1,111.7c0.2-0.4,0.6-0.6,1-0.8c0.1,0.5-0.2,0.6-0.5,0.7C333.4,111.8,333.3,111.8,333.1,111.7z"/>
				<path fill="#CCD6E0" d="M342.4,120.6c-0.2,0.1-0.4,0.2-0.7,0.4c-0.1-0.2-0.6-0.3-0.4-0.4C341.6,120.2,342,120.3,342.4,120.6z"/>
				<path fill="#EAEDF1" d="M340.8,124.1c0.2,0,0.4-0.1,0.4,0.2c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0-0.4-0.2
					C340.5,124.3,340.6,124.2,340.8,124.1z"/>
				<path fill="#F5F6F8" d="M342.7,100.8c-0.2-0.1-0.4-0.1-0.4-0.3c0-0.2,0.3-0.3,0.5-0.3c0.3,0.1,0.4,0.3,0.2,0.5
					C342.9,100.9,342.8,100.9,342.7,100.8z"/>
				<path fill="#D3DBE4" d="M342.3,124c0,0.2,0.2,0.4-0.1,0.5c-0.2,0.1-0.4,0-0.4-0.2c0-0.2,0-0.4,0.2-0.5
					C342.1,123.6,342.2,123.8,342.3,124z"/>
				<path fill="#F9F9FA" d="M324,124.4c0,0.2,0,0.4-0.2,0.3c-0.1,0-0.3-0.2-0.3-0.3c-0.1-0.2,0-0.3,0.1-0.4
					C323.8,124,323.9,124.2,324,124.4z"/>
				<path fill="#D6DEE5" d="M344.5,106c0.1,0.4,0.1,0.6-0.2,0.8c-0.1,0-0.2-0.1-0.2-0.1C344.1,106.3,344.2,106.1,344.5,106z"/>
				<path fill="#F4F5F7" d="M335.3,126.5c0-0.3,0-0.6,0.4-0.4c0.4,0.2,0.2,0.4,0,0.6C335.4,126.8,335.3,126.7,335.3,126.5z"/>
				<path fill="#F5F6F8" d="M326.5,120.1c0,0-0.1,0.1-0.2,0.2c-0.2,0.1-0.3,0-0.4-0.2c-0.1-0.2,0-0.3,0.2-0.3
					C326.2,119.7,326.4,119.7,326.5,120.1z"/>
				<path fill="#F1F2F5" d="M335.4,117.9c-0.1-0.3-0.1-0.5-0.2-0.8c0.3,0,0.6,0.1,0.6,0.4C336,117.9,335.6,117.8,335.4,117.9z"/>
				<path fill="#D3DBE4" d="M331.5,122.7c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.1-0.3,0-0.4-0.2
					C331.2,122.9,331.4,122.8,331.5,122.7z"/>
				<path fill="#CDD6E0" d="M343,122.9c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.2c0,0.2-0.1,0.3-0.3,0.3
					C343.3,123.1,343.1,123.1,343,122.9z"/>
				<path fill="#CED7E1" d="M344.4,115.9c0,0.3,0,0.4-0.2,0.4c-0.1,0-0.4-0.1-0.4-0.2c-0.1-0.1,0.1-0.3,0.2-0.3
					C344.2,115.8,344.3,115.9,344.4,115.9z"/>
				<path fill="#D9DFE7" d="M339.2,99.1c0.3,0,0.5-0.1,0.7,0.1c-0.3,0.1-0.4,0.3-0.4,0.6c-0.3-0.1-0.5-0.2-0.8-0.3
					C339,99.4,339.1,99.3,339.2,99.1z"/>
				<path fill="#D7DEE5" d="M346,115.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.3-0.1-0.3-0.2c-0.1-0.2,0.1-0.3,0.2-0.2
					C345.7,115.6,345.8,115.7,346,115.8z"/>
				<path fill="#D6DDE6" d="M318.3,122.1c0.3-0.1,0.5,0,0.5,0.2c0.1,0.2,0,0.3-0.2,0.3c-0.1,0-0.3-0.1-0.4-0.2
					C318.3,122.3,318.3,122.1,318.3,122.1z"/>
				<path fill="#EDF0F3" d="M330,128.2c0.3,0,0.6-0.1,0.6,0.3c0,0.1-0.2,0.3-0.2,0.3C330.1,128.7,330.1,128.4,330,128.2L330,128.2z"
					/>
				<path fill="#F9F9FA" d="M321.7,121.9c0.2,0,0.3,0,0.3,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0-0.4-0.1
					C321.5,122.1,321.6,122,321.7,121.9z"/>
				<path fill="#C9D3DE" d="M338.1,100.8c0.2,0,0.4,0,0.5,0.1c0,0.2-0.1,0.3-0.1,0.5c-0.2,0-0.3,0.1-0.5,0.2
					C338,101.4,338.1,101.1,338.1,100.8z"/>
				<path fill="#DAE0E7" d="M322.6,121.7c0.4,0.1,0.6,0.3,0.6,0.8C322.7,122.4,322.8,122,322.6,121.7z"/>
				<path fill="#D9DFE7" d="M338.3,119.3c-0.3,0-0.6-0.1-0.5-0.5c0-0.1,0.2-0.2,0.2-0.2C338.3,118.8,338.3,119,338.3,119.3z"/>
				<path fill="#E2E6EC" d="M342.7,100.8c0.1,0,0.2,0,0.3-0.1c0.5,0.5,0.2,0.8-0.3,1.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.1
					C342.7,101.3,342.7,101.1,342.7,100.8z"/>
				<path fill="#C9D3DE" d="M334,103.7c0-0.1,0-0.1,0-0.2c0.1-0.4,0.7-0.1,0.9-0.5c0.2,0,0.4,0.1,0.5,0.1c0.1,0.2,0.1,0.3-0.2,0.3
					C334.8,103.5,334.4,103.6,334,103.7z"/>
				<path fill="#FEFEFE" d="M334.8,103c-0.2,0.4-0.6,0.3-0.9,0.5c-0.3-0.1-0.1-0.3-0.1-0.5c0.1,0,0.3-0.1,0.4-0.1
					C334.5,102.9,334.7,103,334.8,103z"/>
				<path fill="#FAFAFB" d="M341.6,98.1c0,0-0.1,0.1-0.3,0.2c-0.2,0.1-0.3-0.1-0.2-0.2c0-0.2,0-0.4,0.1-0.4
					C341.4,97.6,341.5,97.7,341.6,98.1z"/>
				<path fill="#F0F2F5" d="M336.1,108.9c0.4-0.9,0.4-0.9,0.9-0.4C336.7,108.6,336.4,108.7,336.1,108.9z"/>
				<path fill="#F0F2F5" d="M336.6,106.8c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.2,0-0.3,0.2-0.4
					C336.4,106.5,336.5,106.6,336.6,106.8z"/>
				<path fill="#E8ECF0" d="M348.7,108.1c0-0.2,0.1-0.3,0.1-0.5c0.2,0,0.5-0.1,0.4,0.3C349.2,108.2,349.1,108.4,348.7,108.1z"/>
				<path fill="#C5D0DC" d="M339.8,110.8c-0.3,0.1-0.6,0-0.8-0.3c0-0.2,0-0.4,0.1-0.5C339.7,109.9,339.3,110.7,339.8,110.8
					C339.8,110.8,339.8,110.8,339.8,110.8z"/>
				<path fill="#F6F7F8" d="M339.1,130c0.4,0.2,0.5,0.4,0.4,0.6c-0.3,0.3-0.4,0-0.4-0.2C339.1,130.2,339.1,130.1,339.1,130z"/>
				<path fill="#FCFCFD" d="M345.2,103.9c0.1,0.1,0,0.3-0.1,0.2c-0.2,0-0.3-0.1-0.5-0.2c0.1-0.1,0.1-0.2,0.2-0.3
					C345,103.6,345.1,103.8,345.2,103.9z"/>
				<path fill="#E5E9EE" d="M344,104.2c0,0.2,0.1,0.5-0.2,0.5c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.3,0.2-0.4,0.3-0.6
					C343.8,104.1,343.9,104.1,344,104.2z"/>
				<path fill="#CED7E1" d="M334.2,129.9c-0.1,0.2-0.2,0.3-0.4,0.2c-0.1-0.1-0.3-0.2-0.3-0.3c0-0.2,0.2-0.2,0.3-0.1
					C334,129.7,334.2,129.7,334.2,129.9z"/>
				<path fill="#ECEFF2" d="M320,119.1c0.2,0,0.4,0.1,0.4,0.3c0.1,0.2,0,0.3-0.2,0.4c-0.1,0-0.2-0.2-0.3-0.3
					C319.9,119.4,319.8,119.2,320,119.1z"/>
				<path fill="#F5F6F8" d="M341.4,103.7c-0.3-0.2-0.4-0.5-0.3-0.9C341.5,103,341.3,103.4,341.4,103.7z"/>
				<path fill="#E9ECF0" d="M332.3,128.7c0.4,0.2,0.5,0.4,0.4,0.7c0,0.1-0.2,0.1-0.3,0.1C332.3,129.3,332.3,129,332.3,128.7z"/>
				<path fill="#F0F2F5" d="M342.6,101.5c0,0.1,0,0.2,0.1,0.3c-0.1,0.2-0.2,0.5-0.5,0.5c0,0-0.1-0.1-0.1-0.2
					C342.1,101.8,342.4,101.6,342.6,101.5z"/>
				<path fill="#DEE3EA" d="M339.1,110c0,0.2,0,0.4-0.1,0.5c-0.2,0-0.4,0-0.5,0C338.5,110.2,338.8,110.1,339.1,110z"/>
				<path fill="#F9FAFB" d="M335.2,111.3c-0.1,0.2-0.2,0.3-0.4,0.4c0-0.2-0.1-0.5-0.1-0.7c0.1,0,0.1,0,0.2-0.1
					c0.1,0.1,0.2,0.1,0.3,0.2C335.2,111.1,335.2,111.2,335.2,111.3z"/>
				<path fill="#DAE0E8" d="M339.3,105.5c0.2,0,0.3-0.1,0.5-0.1c0.1,0.3-0.1,0.6-0.3,0.6C339,106,339.4,105.7,339.3,105.5z"/>
				<path fill="#E9ECF0" d="M344.3,114.1c0.6-0.2,0.8-0.5,1.1-0.4C345.3,114.1,345,114.1,344.3,114.1z"/>
				<path fill="#CAD4DE" d="M343.8,120.6c-0.1,0-0.2,0-0.3-0.1c-0.1-0.2,0-0.3,0.2-0.3c0.1,0,0.2,0,0.3,0.1
					C344.1,120.4,344,120.6,343.8,120.6z"/>
				<path fill="#D2DAE3" d="M334.1,108.4c-0.2,0.1-0.4,0.5-0.7,0.2c0.2-0.1,0.3-0.3,0.4-0.6c0.1,0,0.3-0.1,0.4-0.1
					C334.1,108,334.1,108.2,334.1,108.4z"/>
				<path fill="#CDD6E0" d="M344.3,99.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.2,0.1-0.3,0.3-0.3
					C344.2,99.5,344.3,99.6,344.3,99.8z"/>
				<path fill="#C6D1DD" d="M342.5,107.3c0.1,0.1,0.3,0.2,0.3,0.2c0,0.1-0.1,0.3-0.2,0.4c0,0-0.2-0.1-0.2-0.1
					C342.4,107.6,342.4,107.5,342.5,107.3z"/>
				<path fill="#DFE4EB" d="M339.4,107.8c0.3,0.2,0.5,0.3,0.4,0.6c0,0-0.2,0.1-0.2,0.1C339.4,108.3,339.4,108.2,339.4,107.8z"/>
				<path fill="#DCE1E8" d="M328.7,125.4c0,0-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.2-0.2-0.2c0.1-0.1,0.1-0.3,0.3-0.3
					C328.7,125.1,328.7,125.2,328.7,125.4z"/>
				<path fill="#D7DDE5" d="M325,127.5c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.2,0.1-0.4,0.3-0.4c0.1,0,0.2,0.1,0.3,0.2
					C325.4,127.4,325.2,127.5,325,127.5z"/>
				<path fill="#F4F6F7" d="M327.4,125.6c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0-0.3-0.2
					C327.2,125.9,327.3,125.8,327.4,125.6z"/>
				<path fill="#DDE3E9" d="M329.2,125.4c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2,0,0.3-0.2,0.3c-0.1,0-0.2-0.2-0.2-0.3
					C329.1,125.7,329.1,125.6,329.2,125.4z"/>
				<path fill="#F7F8F9" d="M331.4,125.6c-0.2,0-0.5,0-0.7-0.1c0-0.2,0-0.4,0.1-0.4C331.2,125.1,331.2,125.4,331.4,125.6z"/>
				<path fill="#FEFEFE" d="M338.5,123.3c0.2,0,0.4,0.1,0.5,0.1c-0.2,0.3-0.5,0.5-0.7,0.3C338.2,123.7,338.4,123.5,338.5,123.3z"/>
				<path fill="#F5F6F8" d="M324.3,119.8c-0.2,0.2-0.2,0.6-0.6,0.6c0-0.3,0-0.6,0-1C324,119.5,324.2,119.6,324.3,119.8z"/>
				<path fill="#DFE5EA" d="M330.8,118.4c-0.2-0.1-0.4-0.2-0.6-0.3c0.1-0.1,0.2-0.1,0.3-0.2C330.8,117.9,331.1,117.9,330.8,118.4z"
					/>
				<path fill="#E2E6EC" d="M335.2,103.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.3,0.3-0.2c0.1,0,0.1,0.2,0,0.3
					C335.6,103.5,335.4,103.7,335.2,103.4z"/>
				<path fill="#E7EAEF" d="M335.3,126.5c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0.2-0.3,0.6-0.6,0.4C334.7,126.8,335.1,126.6,335.3,126.5z"
					/>
				<path fill="#DFE4EB" d="M336.8,119.1c-0.1,0.2-0.1,0.3-0.2,0.3c-0.1,0.1-0.3,0.1-0.4-0.1c0-0.1,0-0.3,0.1-0.3
					C336.5,119.1,336.6,119.1,336.8,119.1z"/>
				<path fill="#E5E9EE" d="M321.3,117.3c0-0.1-0.1-0.3,0.1-0.3c0.1,0,0.2,0.2,0.3,0.2c0,0.2-0.1,0.3-0.1,0.5
					c-0.1,0.1-0.1,0.1-0.2,0C321.3,117.6,321.3,117.4,321.3,117.3z"/>
				<path fill="#E0E5EB" d="M323.2,116.1c0.3,0.1,0.9-0.6,0.8,0.3C323.8,116.3,323.5,116.2,323.2,116.1z"/>
				<path fill="#E8EBEF" d="M345.7,111c0.1,0,0.1,0,0.2-0.1c0.1,0.2,0.3,0.4,0,0.5c-0.1,0-0.3,0.1-0.3,0
					C345.5,111.3,345.5,111.1,345.7,111z"/>
				<path fill="#C5D0DC" d="M347.9,102.4c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.3,0.1,0.3,0.3c0,0.1-0.1,0.1-0.2,0.2
					C348.2,102.6,348.1,102.6,347.9,102.4z"/>
				<path fill="#EAEEF1" d="M328.3,121.9c-0.1-0.1-0.3-0.1-0.3-0.1c-0.1-0.2,0-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.1
					C328.5,121.7,328.4,121.8,328.3,121.9z"/>
				<path fill="#E7EAEF" d="M327.3,128.9c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.3-0.1-0.2-0.3c0-0.1,0.1-0.1,0.2-0.2
					C327.1,128.7,327.2,128.8,327.3,128.9z"/>
				<path fill="#DBE1E8" d="M339.9,111.5c0.1,0,0.2,0,0.2-0.1c0.2,0.1,0.3,0.2,0.3,0.5c0,0.1-0.1,0.1-0.2,0.1
					C339.9,112,340,111.7,339.9,111.5z"/>
				<path fill="#E2E7EC" d="M333.1,111.7c0.2,0,0.3-0.1,0.5-0.1c0,0.3,0,0.6-0.4,0.5C332.9,112.1,332.9,111.9,333.1,111.7z"/>
				<path fill="#ECEFF2" d="M341.2,127.1c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.3-0.1-0.3-0.3
					C341.1,127.2,341.2,127.1,341.2,127.1z"/>
				<path fill="#F4F5F7" d="M324.7,123.9c0-0.2,0-0.5,0.3-0.5c0.3,0.1,0.2,0.3,0.2,0.6C325,124.2,324.8,124,324.7,123.9z"/>
				<path fill="#C4CFDB" d="M337.7,111.2c0.3-0.2,0.7-0.3,0.8-0.1c0.1,0.2-0.2,0.3-0.3,0.3C338,111.3,337.9,111.3,337.7,111.2z"/>
				<path fill="#EEF0F3" d="M329.5,122.4c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.2,0-0.2-0.1c0-0.2-0.5-0.3-0.2-0.6
					C329.2,122.1,329.4,122.3,329.5,122.4z"/>
				<path fill="#E6EAEE" d="M321.4,117.8c0.1,0,0.1,0,0.2,0c0.1,0.2,0.1,0.4,0.2,0.5c-0.1,0-0.2,0.1-0.3,0.1
					C321.2,118.2,321.2,118,321.4,117.8z"/>
				<path fill="#F8F8FA" d="M335.5,105.8c0.1,0.2,0,0.3-0.1,0.3c-0.1,0-0.4,0.1-0.4,0c-0.1-0.1,0-0.3,0.1-0.3
					C335.3,105.7,335.4,105.8,335.5,105.8z"/>
				<path fill="#F9FAFB" d="M340.8,129.8c-0.2,0.1-0.3,0-0.3-0.1c0-0.1,0-0.3,0.1-0.4c0.2-0.2,0.3,0,0.3,0.2
					C340.8,129.5,340.8,129.7,340.8,129.8z"/>
				<path fill="#D5DCE5" d="M332.2,121.1c0.2,0,0.5,0,0.5,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1-0.1-0.2-0.2-0.3-0.3
					C332.1,121.2,332.1,121.2,332.2,121.1C332.1,121.1,332.1,121.1,332.2,121.1z"/>
				<path fill="#CED6E1" d="M332.2,121.1c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.4,0.4-0.7,0.3C331.4,121.1,331.9,121.2,332.2,121.1z"/>
				<path fill="#FCFCFD" d="M330,128.1c-0.2,0-0.5-0.1-0.4-0.4c0-0.1,0.2-0.1,0.3-0.1C330.2,127.7,330,128,330,128.1
					C330,128.2,330,128.1,330,128.1z"/>
				<path fill="#F6F7F9" d="M339.3,128.5c0.3,0.1,0.4,0.2,0.4,0.5c0,0-0.1,0.1-0.2,0.1C339.3,129,339.2,128.8,339.3,128.5z"/>
				<path fill="#EFF1F4" d="M338.2,131.3c-0.1,0.1-0.2,0.3-0.3,0.3c-0.1,0.1-0.2-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3
					C338.1,131.2,338.1,131.2,338.2,131.3z"/>
				<path fill="#CBD4DF" d="M331,132c-0.1,0.1-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.2
					C330.8,131.9,330.9,132,331,132z"/>
				<path fill="#EFF1F4" d="M333.5,125.1c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0,0.2-0.2,0.2c-0.1-0.1-0.2-0.2-0.3-0.3
					C333.4,125.2,333.5,125.2,333.5,125.1z"/>
				<path fill="#E0E5EA" d="M329.2,130.2c0,0.1,0,0.2-0.1,0.3c-0.1,0-0.2,0-0.3-0.1c0-0.1,0-0.2,0.1-0.3
					C329.1,129.9,329.1,130,329.2,130.2z"/>
				<path fill="#C7D2DD" d="M344.1,126.1c0.1,0.2,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.2c-0.2,0.1-0.2,0-0.2-0.1
					C343.9,126.4,344,126.3,344.1,126.1z"/>
				<path fill="#F5F6F7" d="M347.4,106.4c0.1,0.2,0,0.3-0.1,0.3c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1,0-0.2,0.1-0.2
					C347.3,106.3,347.4,106.4,347.4,106.4z"/>
				<path fill="#EAEDF1" d="M332.3,110.5c-0.3-0.1-0.7-0.2-0.4-0.7c0.2,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.3,0.3,0.4
					C332.5,110.4,332.4,110.4,332.3,110.5z"/>
				<path fill="#E3E7EC" d="M346.8,103.3c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.2c0,0,0.2,0,0.2,0.1
					C347.1,103.2,346.9,103.3,346.8,103.3z"/>
				<path fill="#D5DCE5" d="M341.9,111.6c0.1,0.1,0.2,0.2,0.3,0.2c0,0.1,0,0.2-0.1,0.3c-0.1-0.1-0.2-0.1-0.3-0.2
					C341.8,111.9,341.8,111.7,341.9,111.6z"/>
				<path fill="#EBEEF2" d="M343.5,103.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.2
					C343.3,103.2,343.4,103.3,343.5,103.3z"/>
				<path fill="#ECEFF2" d="M331.9,115.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.2
					C331.6,115.1,331.7,115.2,331.9,115.2z"/>
				<path fill="#F7F8FA" d="M326.8,117.8c-0.1-0.2-0.3-0.3-0.2-0.5c0.2-0.3,0.4,0,0.6,0.1C327,117.5,326.9,117.7,326.8,117.8z"/>
				<path fill="#E2E7EC" d="M343.6,114.6c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0.1
					C343.7,114.4,343.7,114.5,343.6,114.6z"/>
				<path fill="#CCD5E0" d="M339.3,115.9c0.1,0.2,0,0.3-0.1,0.3c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0-0.2
					C339.1,115.8,339.2,115.9,339.3,115.9z"/>
				<path fill="#F8F9FA" d="M337.5,102.9c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0-0.2,0-0.3-0.1c0.1-0.1,0.1-0.2,0.2-0.3
					C337.2,102.8,337.4,102.9,337.5,102.9z"/>
				<path fill="#F1F3F5" d="M330.4,119.5c0.2,0,0.4,0,0.5,0.1c0,0.2-0.1,0.4-0.3,0.4C330.4,119.9,330.4,119.6,330.4,119.5z"/>
				<path fill="#F2F3F6" d="M338.7,118.1c-0.1-0.2-0.3-0.3,0-0.4c0.3-0.1,0.4,0.1,0.5,0.4C339,118.1,338.8,118.1,338.7,118.1z"/>
				<path fill="#CDD6E0" d="M349.9,115.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.2-0.1,0.3-0.2
					C349.8,115.3,349.9,115.4,349.9,115.5z"/>
				<path fill="#DFE4EA" d="M325.2,121.9c0-0.2,0.1-0.4,0.1-0.6c0.2,0,0.4-0.1,0.4,0.2C325.7,121.8,325.4,121.8,325.2,121.9z"/>
				<path fill="#D6DCE5" d="M319.4,123.4c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0-0.2,0-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
					C319.4,123.1,319.5,123.2,319.4,123.4z"/>
				<path fill="#F4F6F8" d="M321.5,123.7c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.2
					C321.4,124,321.5,123.8,321.5,123.7z"/>
				<path fill="#F8F9FA" d="M323.7,123.3c-0.1,0.1-0.2,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.1,0.1-0.2,0.2-0.2
					C323.5,123.2,323.6,123.3,323.7,123.3z"/>
				<path fill="#F3F4F6" d="M335.8,102.1c0.2,0.1,0.3,0.1,0.4,0.1c0.1,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.3-0.1
					C335.8,102.4,335.8,102.2,335.8,102.1z"/>
				<path fill="#C8D2DE" d="M347.9,119c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0-0.2-0.2c0-0.1,0.1-0.2,0.2-0.3
					C347.8,118.9,347.8,118.9,347.9,119z"/>
				<path fill="#EFF1F4" d="M324.7,123.9c0.2,0,0.3,0.1,0.5,0.1c0,0.2-0.1,0.6-0.4,0.5C324.6,124.5,324.8,124.1,324.7,123.9z"/>
				<path fill="#FCFCFD" d="M338.8,121.1c0,0.2,0.1,0.5-0.2,0.6c0,0-0.2-0.3-0.2-0.5C338.5,121.1,338.6,121.1,338.8,121.1z"/>
				<path fill="#EEF0F3" d="M326.3,124.8c-0.2-0.3-0.2-0.4-0.2-0.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0.1,0.2,0.1,0.2,0.2
					C326.4,124.5,326.4,124.6,326.3,124.8z"/>
				<path fill="#E3E7ED" d="M328.9,124c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.2,0.1,0.2,0.1c-0.1,0.1-0.1,0.2-0.2,0.3
					C329.1,124.1,329,124,328.9,124z"/>
				<path fill="#E2E6EB" d="M324.8,125.1c0.1,0.2,0,0.3-0.1,0.3c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1,0-0.2,0.1-0.2
					C324.6,125,324.7,125.1,324.8,125.1z"/>
				<path fill="#D2DAE3" d="M343.9,121.5c-0.1,0.2-0.2,0.3-0.2,0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1,0-0.2,0.1-0.2
					C343.6,121.4,343.7,121.5,343.9,121.5z"/>
				<path fill="#DFE4EB" d="M323.8,126.6c0-0.1,0-0.2-0.1-0.2c0.3-0.1,0.5,0.1,0.6,0.4c0,0-0.1,0.2-0.1,0.1
					C324.1,126.7,324,126.7,323.8,126.6z"/>
				<path fill="#BFCCD9" d="M332.2,105.6c0,0.1,0.1,0.3,0.1,0.4c-0.1,0.7,0.5,1,0.9,1.7c-0.6-0.4-1.1-0.2-1.6-0.1
					c-0.3-0.2-0.4-0.5-0.6-0.8c-0.1-0.4-0.2-0.7-0.5-0.9c-0.2,0-0.4,0-0.5-0.1c0,0,0,0,0,0c0.1-0.2,0.3-0.2,0.5-0.1
					c0.2-0.1,0.2-0.3,0.1-0.5c0.6-0.1,0.2-0.9,0.7-1.1c0.5,0.2,0.6,0.5,0.2,0.9C331.7,105.3,331.9,105.5,332.2,105.6z"/>
				<path fill="#CBD5DF" d="M328.9,108.2c-0.5,0.4-0.5,1.4-1.4,1.3c-0.2-0.1-0.3-0.2-0.4-0.4c0.1-0.3-0.4-1,0.6-0.8
					c0.1-0.5,0.8-0.1,0.9-0.6C328.9,107.7,329,108,328.9,108.2z"/>
				<path fill="#E8EBEF" d="M328.6,106.9c0-0.3-0.6-0.8,0.3-0.7c0.3,0,0.7,0,1-0.1C330,107.3,329.3,107.1,328.6,106.9z"/>
				<path fill="#FAFAFB" d="M331.2,106.8c0.4,0.1,0.3,0.6,0.5,0.9c0,0.1,0,0.2,0,0.2c-0.6-0.2-1.3-0.3-1-1.2
					C331,106.4,331.1,106.6,331.2,106.8z"/>
				<path fill="#D3DAE3" d="M329.7,107.5c0.6,0.4,0.5,1,0.6,1.6C329.4,108.8,329.9,108.1,329.7,107.5z"/>
				<path fill="#E3E7EC" d="M327.4,112.5c-0.4-0.3-1.2-0.4-1-1.3c0.1-0.2,0.3-0.2,0.5-0.1c0.1,0.4,0.2,0.8,0.5,1.1
					C327.4,112.3,327.4,112.4,327.4,112.5C327.4,112.5,327.4,112.5,327.4,112.5z"/>
				<path fill="#E3E7EC" d="M325,110.8c0.2-0.5,0.7-0.3,1.1-0.5c0.3,0.1,0.5-0.5,0.8-0.1c0.1,0.1-0.2,0.2-0.3,0.2
					c-0.2,0.4-0.6,0.3-0.9,0.4c-0.1,0-0.2,0.1-0.3,0.1C325.3,111,325.2,110.9,325,110.8z"/>
				<path fill="#F8F9FB" d="M325.8,110.9c0.2-0.4,0.6-0.3,0.9-0.4c0.1,0.2,0.1,0.5,0.2,0.7c-0.2,0-0.3,0.1-0.5,0.1
					C326.2,111.1,326,111,325.8,110.9z"/>
				<path fill="#F3F5F7" d="M331.2,106.8c-0.2,0-0.3-0.1-0.5-0.1c-0.1-0.2-0.1-0.5-0.2-0.7C331.3,105.8,331.3,106.2,331.2,106.8z"/>
				<path fill="#D2DAE3" d="M332.1,108.3c-0.1,0.2-0.2,0.4-0.4,0.6c-0.3,0-0.5,0-0.9-0.3C331.3,108.5,331.7,108.4,332.1,108.3z"/>
				<path fill="#EAEDF0" d="M329.3,113.3c-0.3,0.3-0.7,0.3-1,0c0.2-0.2,0.2-0.6,0.7-0.4C329.1,113,329.3,113,329.3,113.3
					L329.3,113.3z"/>
				<path fill="#E2E6EC" d="M328.6,110.3c0.4,0,0.5,0.1,0.6,0.3c0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.4,0-0.5-0.3
					C328.6,110.5,328.6,110.3,328.6,110.3z"/>
				<path fill="#F2F4F7" d="M331.6,110.8c-0.1,0.1-0.3,0.2-0.4,0.3c-0.6-0.3-0.3-0.7,0-1C331.4,110.4,331.5,110.6,331.6,110.8z"/>
				<path fill="#EAEDF1" d="M327.4,107.1c-0.1-0.3,0.1-0.5,0.4-0.6c0.2,0.1,0.4,0.3,0.6,0.4C328,107,327.7,107,327.4,107.1z"/>
				<path fill="#CFD8E2" d="M327.4,112.5c0-0.1,0-0.2,0-0.2c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1-0.1,0.2,0c0,0.2-0.1,0.5-0.1,0.7
					C327.3,113,327.3,112.8,327.4,112.5z"/>
				<path fill="#DEE3E9" d="M327.1,109.1c0.2,0.1,0.3,0.2,0.4,0.4c-0.1,0.2-0.2,0.3-0.3,0.2C326.9,109.6,326.9,109.3,327.1,109.1z"
					/>
				<path fill="#EBEEF1" d="M327.8,112.2c-0.1,0-0.2,0-0.2,0c0-0.3,0-0.5,0.3-0.5c0.1,0,0.2,0.1,0.2,0.2
					C328,112,327.9,112.1,327.8,112.2z"/>
				<path fill="#E8ECF0" d="M330,110.4c0-0.3,0.1-0.7,0.4-0.6c0.4,0.1,0.1,0.3-0.1,0.5C330.2,110.3,330.1,110.3,330,110.4z"/>
				<path fill="#F1F3F6" d="M328.9,108.2c-0.1-0.1-0.2-0.3-0.3-0.4c0.1-0.2,0.3-0.4,0.5-0.2C329.4,107.9,329.1,108.1,328.9,108.2z"
					/>
				<path fill="#BFCCD9" d="M329.3,113.3c-0.1-0.1-0.2-0.3-0.3-0.4c0.3-0.1,0.6-0.1,0.9-0.2C329.6,112.9,329.4,113.1,329.3,113.3z"
					/>
				<path fill="#CBD4DF" d="M327.8,115.9c-0.2,0.1-0.5,0.1-0.6,0.3c-0.8,0.9-0.9,0.3-0.9-0.4c0.4-0.1,0.5-0.8,1-0.7
					c0.2,0,0.2,0.3,0.4,0.4c0.1,0.1,0.2,0.1,0.3,0.2C328,115.7,328,115.8,327.8,115.9z"/>
				<path fill="#F8F9FA" d="M325,110.8c0.2,0,0.4,0,0.5,0.1c-0.1,0.5-0.4,0.9-1,0.9C324.2,111.3,324.8,111.2,325,110.8z"/>
				<path fill="#FCFCFD" d="M324.4,113.4c0.2,0,0.3-0.1,0.4-0.3c0.3,0.1,0.6,0.2,0.6,0.6c0,0.2-0.2,0.2-0.3,0.2
					C324.6,114,324.4,113.8,324.4,113.4z"/>
				<path fill="#D9DFE7" d="M327.7,113.9c0.7,0.3,0.8,0.6,0.2,1.2c-0.2-0.2-0.4-0.3-0.4-0.6C327.7,114.3,327.7,114.1,327.7,113.9z"
					/>
				<path fill="#EBEEF1" d="M327.5,114.4c0.1,0.2,0.3,0.4,0.4,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2-0.1-0.3-0.2-0.4-0.4
					C327.4,114.8,326.8,114.4,327.5,114.4z"/>
				<path fill="#EBEEF1" d="M325.3,115.4c0.1,0.2,0.1,0.4-0.1,0.5c-0.2-0.1-0.6-0.1-0.5-0.5C324.8,115.2,325.1,115.4,325.3,115.4z"
					/>
				<path fill="#EBEEF1" d="M325.8,112.2c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.2,0.1-0.4,0.2c-0.1,0-0.2,0-0.2-0.1
					C325.6,112.3,325.7,112.2,325.8,112.2z"/>
				<path fill="#FAFAFB" d="M333.3,103.9c0.1,0.5,0.6,1.1-0.5,1.1C332.7,104.5,332.7,104,333.3,103.9z"/>
				<path fill="#E9ECF1" d="M332.9,101.7c0.1-0.2,0.2-0.3,0.4-0.3c0.1,0.2,0.1,0.4,0.3,0.4c-0.1,0.3-0.2,0.5-0.5,0.4
					C332.7,102.2,332.8,101.9,332.9,101.7z"/>
				<path fill="#F9F9FB" d="M331.8,103.7c-0.8-0.2-0.3-0.6-0.2-0.9C331.9,103,332,103.3,331.8,103.7z"/>
				<path fill="#EFF1F4" d="M332.1,102.6c0.1-0.2,0.2-0.3,0.4-0.3c0,0.3,0,0.6-0.2,0.8C332.2,103,332.1,102.8,332.1,102.6z"/>
				<path fill="#E7EAEF" d="M331.6,104.9c0.2-0.4,0.1-0.7-0.2-0.9c0.2,0,0.3-0.1,0.5-0.1C331.9,104.3,332.2,104.7,331.6,104.9z"/>
				<path fill="#F6F7F8" d="M330.5,105.7c-0.2,0-0.3,0.1-0.5,0.1c-0.1-0.1-0.2-0.3-0.3-0.4C330.1,105.3,330.4,105.2,330.5,105.7z"/>
				<path fill="#CAD4DF" d="M327.8,115.9c0.1-0.1,0.1-0.2,0.2-0.3c0.7,0.5,0.4,1.3,0.6,1.9c-0.1-0.1-0.2-0.1-0.3-0.2
					C327.9,116.9,328.2,116.2,327.8,115.9z"/>
				<path fill="#F9FAFB" d="M332.2,110c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.3,0-0.6,0.3-0.8C332.7,109.4,332.4,109.7,332.2,110z"/>
			</g>
		</g>
	</g>
</g>
<g id="body">
	<g id="sM0XBO_1_">
		<g>
			<path fill="#EBE2CF" d="M437.8,199.4c-0.4,0.3-0.2,0.7-0.2,1.1c-0.1,5.2-0.1,10.3-0.3,15.5c-0.2,4-0.6,8-1,11.9
				c-0.4,4.2-0.8,8.3-1.4,12.5c-0.6,4.2-1.4,8.3-2.2,12.5c-0.4,2-0.5,4.1-1,6.2c-0.4,1.9-0.9,3.9-1.3,5.8c-0.5,2.8-1.2,5.6-1.8,8.4
				c-0.4,1.9-1.1,3.8-0.9,5.9c0.3,3,0.5,6,0.6,9c0.1,2.2,0.2,4.4,0.4,6.6c0.5,5.5-1.6,10.2-3.6,15.1c-0.9,2.2-1.9,4.4-2.8,6.6
				c-0.4,0.2-0.4,0.8-0.8,1c-1.3,0.5-1.9-0.4-2.4-1.3c-0.4-0.6-0.7-0.9-1.5-0.6c-1.2,0.5-2.3-0.4-2.4-1.9c-0.2-2.1-0.2-4.1,0.1-6.2
				c0.5-2.7,0.6-5.4,0.4-8.5c-0.6,1.1-1,1.9-1.6,2.7c-0.6,0.8-1.3,1.4-2.3,1.5c-0.8,0.1-1.2-0.3-1.1-1.1c0-0.5,0.1-1,0.2-1.5
				c1-3.3,1.3-6.7,2-10c0.9-4.5,2.4-8.9,4-13.2c0.3-0.7,0.3-1.5,0.5-2.2c0.3-1.9,0.4-3.8,0.4-5.7c0-2.6,0-5.2-0.1-7.8
				c-0.1-3.8-0.1-7.5,0-11.3c0-3.1,0.3-6.1,0.1-9.2c-0.2-3.7-0.1-7.4-0.1-11.1c0-5.8-0.6-11.5-0.8-17.2c-0.1-3.6-0.4-7.1-0.6-10.7
				c-0.1-2.8-0.6-5.6-0.7-8.4c-0.3-5.1-1-10.2-1.7-15.2c-0.8-5.7-1.4-11.5-2.6-17.2c-1-4.8-1.7-9.7-2.7-14.5
				c-0.1-1.1-0.2-2.3-0.6-3.4c-0.4-1.1,0.4-1.7,1.2-2.2c4.2-2.9,8.8-4.9,13.4-6.9c2.9-1.2,5.9-2.2,9.1-2.6c0.6-0.1,0.8-0.6,1.3-0.7
				c0.3,0.2,0.3,0.5,0.4,0.7c0.8,5,1.7,10,2.1,15.1c0.4,4.3,0.9,8.7,1.1,13c0.2,2.7,0.6,5.4,0.6,8.1c0.1,3.7,0.1,7.3,0.2,11
				c0.1,5.1,0,10.1,0.1,15.2c0.1,1.6-0.1,3.2,0.1,4.8C437.8,199.3,437.8,199.4,437.8,199.4z"/>
			<path fill="#DBB22C" d="M430.3,50.2c0,0.3,0,0.6,0,1c-0.2,0.3-0.1,0.6-0.1,0.9c-0.1,0.5-0.4,0.5-0.7,0.2
				c-0.1-0.1-0.2-0.4-0.5-0.3c-0.1,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.2c0.2,0.5,1,0.7,0.7,1.4l0,0c-0.9,0.2-1.2,1-1.7,1.6
				c-0.2,0.2-0.9-0.1-0.7,0.6c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.6,0.2,0.6,0.6c-0.1,0.3-0.3,0.6-0.5,0.8
				c-0.1,0.1-0.2,0.1-0.3,0.1c-0.8-0.3-0.4,0.2-0.3,0.5c-0.1,0.3-0.2,0.7-0.7,0.4c-0.4-0.4-0.9-0.4-1.4,0.2c0.6,0.1,0.9-0.2,1.3-0.1
				c0.2,0,0.4,0.1,0.6,0.3c0.1,0.2,0.2,0.5,0.4,0.1c0.1-0.2,0.3-0.3,0.5-0.4c0.2,0,0.3,0,0.5,0.1c0.3,0.8,0.9,1.6,0.6,2.5
				c0,0.4-0.3,0.6-0.7,0.6c-0.3,0-0.6-0.1-0.9,0.1c-0.5,0.4-0.8,1.1-1.6,0.4c-0.2-0.1-0.6,0.2-1,0.2c-0.3,0.1-0.8,0.1-0.2,0.5
				c0.3,0.2,0.6,0.4,0.9,0.6c0.3,0.3,0.5,0.1,0.6-0.2c0.2-0.4,0.3-0.9,0.9-0.9c0.5,0,0.7,0.3,0.8,0.7c0.1,0.1,0.1,0.2,0.1,0.3
				c0,0.1-0.1,0.2-0.2,0.3c-0.5,0.2-0.6,0.7-0.8,1.2c0.4-0.3,0.2-1.1,0.9-1.2c0.8,0.2,0.4,1.1,0.9,1.6c0.3-0.4,0.2-1.2,1-1.2
				c0.5,1.6,1,3.1,1.4,4.7c0.5,2.2,0.8,4.4-0.1,6.5c-1.5,3.6-5.4,5-8.9,3.7c-0.6-0.2-1.3-0.4-1.8-1c-0.1-0.6,0.5-0.5,0.8-0.7
				c0.3-0.2,0.9-0.2,0.9-0.8c-0.1-0.7-0.7-0.5-1.1-0.5c-0.4-0.1-0.4-0.4-0.3-0.7c0.1-0.2,0.1-0.2-0.1,0c0,0,0.1,0,0,0.1
				c-0.6,0.5-1.2-0.1-1.8,0c-0.5,0-0.8-0.1-0.9-0.7c0-0.7,0.7-1,0.8-1.7c0.3-0.4,0.7-0.2,1.1-0.6c-0.4,0-0.7,0.2-1.1,0.2
				c-0.1,0-0.2,0-0.3-0.1c-0.4-0.3-1-0.3-1.2-0.7c0-0.1-0.1-0.2,0-0.3c-0.1,0.1,0,0.3-0.1,0.3c-0.3,0.2-0.6,0.3-1,0.1
				c-0.2-0.8-0.5-1.5-0.5-2.3c0.3-0.8,0.2-1.3-0.2-2.1c-0.3-0.6-0.5-1.7,0.2-2.5c-0.4-0.3-0.3-0.8-0.5-1.2c0.2-2.8,0.5-5.6,0.9-8.4
				c0.3-0.2,1.1-0.4,0.3-0.9c-0.2-0.1-0.1-0.4,0-0.5l0,0c0.9-0.1,0.4-0.5,0.3-0.9c-0.2-0.9,0.3-1.7,0.3-2.6c0.5-0.4,1-0.2,1.5,0.1
				c0.2,0.2,0.6,0.2,0.8,0.5c0.5,0.4,0.7,0.8,0,1.3c-0.2,0.2-0.7-0.1-0.5,0.4c0.1,0.4,0.4,0.6,0.7,0.7c0.2,0.1,0.3,0.5,0.6,0.4
				c0.1,0,0.1,0.1,0.1,0.2c0-0.1,0-0.1-0.1-0.2c-0.3-0.2-0.5-0.4-0.8-0.6c-0.2-0.1-0.3-0.3-0.2-0.6c0-0.1,0.1-0.2,0.2-0.2
				c0.2-0.1,0.4-0.1,0.5,0c0.1,0,0,0,0-0.1c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.3-0.2,0.2-0.5c0-0.3,0.2-0.4,0.3-0.6c0,0,0-0.1,0-0.1
				s-0.1,0-0.1,0c-0.3,0.3-0.6,0.4-0.9,0c-0.2-0.2-0.5-0.1-0.7-0.3c-0.3-0.5-0.9-0.6-1.4-0.7c-0.2-2.1,0.3-4.2,0.1-6.3
				c-0.3-3.4-0.8-6.8-2.8-9.7c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2,2-0.4,4-1,5.8c-1.2,3.9-2.5,7.6-5,11.1
				c-3.2,4.5-7.8,6.8-12.5,9.1c-2.8,1.4-5.7,2.7-8.5,4c-0.4,0.2-0.8,0.3-1.3,0.2c-0.7-0.1-0.9-0.6-1-1.2c-0.4-3.2-1-6.4-1.5-9.6
				c-0.3-1.9-0.6-3.7-1-5.5c-0.4-1.7-0.2-2.2,1.5-2.7c3.6-1.2,5.3-3.9,5.1-7.7c-0.1-0.9-0.1-1.8-0.4-2.7c-0.9-2.2-2.8-2.5-4.5-0.9
				c-0.3,0.3-0.6,0.7-0.8,1c-0.8,0.9-1.8,0.9-2.5-0.1c-0.6-1-1.4-1.9-2-2.9c-0.4-0.9-1.1-1.6-1.6-2.5c-2-3.6-4-7.2-5.9-10.8
				c-0.9-1.8-1.7-3.7-2.6-5.5c-0.6-1.3-0.6-1.3-1.7-0.3c-2.4,2.3-5.1,4.1-8,5.7c-2.1,1.2-4.4,1.9-6.8,2.2c-0.3,0-0.6,0.1-0.8-0.1
				c-1-0.1-1.2-0.6-1-1.6c1.1-5,3.1-9.6,7-13c3.6-3.2,7.7-4.6,12.5-2.8c1.2,0.4,2.4,0,3.6-0.4c2.6-1.1,5.4-1.6,8.2-2
				c0.6-0.3,1.3,0.1,1.9-0.2c0.2,0,0.5,0,0.7,0c0.1,0.1,0.3,0.2,0.4,0.2c0.8,0,1,0.5,1.2,1.1c0,0.1,0,0.2,0.1,0.2
				c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.4,0,0.6,0.3,0.7,0.6c0.1,0.2,0,0.4,0,0.5c0.1-0.1,0.2-0.3,0.4-0.4
				c0.2-0.1,0.3-0.1,0.5,0c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0.5-0.2,1-0.5,1.4c-0.1,0.2-0.5,0.3-0.2,0.6c0.1-0.1,0-0.4,0.1-0.5
				c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.1,0.5-0.2,0.8,0c0.4,0.5,0.6,1.1,1.1,1.5c0.1,0.1,0.2,0.1,0.2,0.2c0.3,0.4,0.6,0.8,0.7,1.3
				c0,0.4-0.2,0.6-0.6,0.7c-0.3,0.2-0.5-0.6-0.6-0.2c-0.2,0.5,0.6,0.1,0.7,0.5c0.1,0.1,0.1,0.2,0.1,0.3c0,0.6,0,1.2,0.3,1.7
				c0.4,0.5,1,0.8,1.3,1.4c0.1,0.2,0,0.4-0.1,0.7c0.4-0.2,0.5-0.8,0.9-0.9c0.2-0.1,0.3,0,0.4,0.1c0.1,0.2,0.1,0.4,0.1,0.6
				c0,0.1,0,0.2-0.1,0.3c-0.1,0.3,0,0.6-0.3,0.8c-0.2,0.2-0.7,0-0.6,0.5c0,0.3,0.2,0.6,0.4,0.7c0.3,0.1,0.6-0.2,0.7-0.5
				c0.1-0.2,0.2-0.5,0.4-0.7c0.3-0.2,0.6-0.2,0.9,0c0.3,0.3-0.1,0.7,0.1,1c0.6,0.3,1.1,0.6,1.3,1.3c0.2-0.4,0.3-0.7,0.7-0.7
				c0.2,0,0.3,0.1,0.4,0.3c0.3,1.1,1.3,1,2.1,1.1c0.4,0.1,0.8-0.1,0.9,0.5c-0.1,0.6-0.1,1.2,0.6,1.5c0.5,0.7,0.7,1.6,1.5,2.1
				c0.1,0.2,0.2,0.4,0.1,0.6c-0.1,0.2-0.5,0.1-0.6,0.5c0,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.3,0.1,0.6-0.2,0.8
				c-0.2,0.1-0.3-0.1-0.5,0c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0.1,0.3,0.3,0.4,0.5c0,0.2,0,0.3,0,0.5c0,0.2-0.2,0.5,0,0.7
				c0.1,0.1,0.2-0.2,0.3-0.3c0.4-0.6,1-0.8,1.7-0.7c0.1,0,0.2,0.1,0.3,0.2c-0.4,0.8,0.8,0.8,0.7,1.5c0,0.2-0.3,0.1-0.2,0.3
				c0,0,0.1-0.2,0.2-0.2c0.2-0.3,0.7-0.2,0.9-0.6c0.3-0.5,0.7-0.7,1.1-0.9c0.5,0.7,1.4,0.1,1.9,0.7c0.1,0.1,0.2,0.3,0.2,0.4
				c-0.2,1.1-0.9,1.4-2.1,1.2c1.3,0.5,1.9,0.3,2.1-1c0.1-0.4,0.1-0.9,0.5-1.2c0.1-0.8,0.5-1.6-0.1-2.4c-0.5-0.6-0.1-1.2,0.6-1.2
				c0.2,0.2,0.2,0.5,0.5,0.6c0-0.4-0.3-0.6-0.3-1c0-0.1,0-0.2,0-0.3c0.2-0.6,0.6-0.6,1.1-0.2c0.7,1.3,0.8,2.9,1.2,4.3
				c-0.1,0.4-0.6,0.4-0.8,0.8c0.3,0.4,0.7,0,1,0.2c0,0.3,0,0.6,0.1,0.9c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.4,0.2-0.3,0.5
				c0.1-0.1,0.3-0.2,0.5-0.1c0,1.1,0.9,0.7,1.4,0.6c2.1-0.2,4.1,0.1,6,1c3.1,1.3,5.1,3.6,6.6,6.5c0.8,1.6,1.4,3.2,1.9,5
				c0.1,0.5,0.3,1,0.3,1.5c0,1.2-0.8,0.2-1.2,0.5c1.4-0.1,1.6,0.7,1.5,1.8c0,0.3-0.1,0.6,0.1,0.8c0.3,1.5,0.1,3,0.2,4.6
				c-0.6,0.4-0.6,1.4-1.3,1.8c-0.3,0.2-0.3,0.5-0.2,0.8c-0.1-0.3,0-0.6,0.3-0.7C429.6,49.4,430,49.6,430.3,50.2z"/>
			<path fill="#F6F2E9" d="M437.6,194.4c0.1,0,0.2,0,0.2,0c0,1.6,0,3.2,0,4.8C437.2,197.6,437.7,196,437.6,194.4z"/>
			<path fill="#F9F4E3" d="M384.5,0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0.8,0,1.6,0,2.4,0c0,0.1,0,0.3,0,0.4c0,0-0.1,0.1-0.1,0.1
				c-0.2,0.1-0.5,0-0.7,0C385.4,0.3,385,0.4,384.5,0.3z"/>
			<path fill="#F3E7C1" d="M383.5,0c-0.6,0.6-1.3,0.1-1.9,0.2c0-0.1,0-0.2,0-0.2C382.3,0,382.9,0,383.5,0z"/>
			<path fill="#5C7891" d="M417.6,275.3c0.1,1.6-0.6,2.9-1.1,4.3c-2.1,5.3-3.3,10.9-4.2,16.5c-0.3,1.9-0.9,3.8-1.3,5.7
				c-0.2,1,0,1.3,1.1,1.1c0.7-0.2,1.2-0.7,1.7-1.3c0.8-1.1,1.4-2.3,2-3.5c0.7,2.1,0.5,4.2,0.3,6.3c-0.2,2.6-0.8,5.1-0.7,7.7
				c0,0.6,0,1.2,0.2,1.8c0.2,0.7,0.4,1.3,1.2,1.4c0.8,0.1,1.3-0.2,1.7-1c0.4,0.7,0.7,1.3,1.1,1.9c0.5,0.7,1.1,1.4,2.1,0.8
				c0.5-0.2,0.3,0.4,0.5,0.5c0.3,3.2,0.5,6.4,0.5,9.6c0.1,3.6,0.5,7.2,0.7,10.8c0.2,3.3,0.4,6.6,0.5,9.9c0.2,4.2,0.3,8.3,0.4,12.5
				c0.1,5.1,0.2,10.2,0.3,15.2c0,0.9-0.7,0.8-1.2,0.8c-2.1,0.3-4.2,0.5-6.4,0.8c-4,0.6-8.1,0.8-12.1,0.7c-4,0-7.9,0.5-11.8,0.4
				c-2.6,0-5.2,0.2-7.8-0.1c-1.8-0.2-3.7,0.2-5.5,0.2c-0.9,0-1.5-0.7-2.4-0.4c-3.7-0.1-7.5-0.1-11.2-0.2c-1.5,0-3.1,0-4.6,0
				c-1.5,0.5-3.1,0.2-4.6,0.1c-3.7-0.1-7.3-0.1-11-0.1c-2.4,0-4.9-0.2-7.3-0.3c-4.5-0.1-9.1-0.3-13.6-0.5c-0.9,0-1.7-0.3-2.6-0.4
				c-3.7-0.2-7.4-0.3-11-0.5c-0.8,0-1.1-0.5-1.1-1.1c0-1.9,0-3.8,0.1-5.7c0-2.9,0-5.9,0.2-8.8c0.2-4.3,0.5-8.6,0.5-12.9
				c0-2.9,0.3-5.7,0.3-8.5c0-2.4,0.3-4.9,0.5-7.3c0.3-3.9,0.4-7.7,0.6-11.6c0.3-4.4,0.9-8.8,1.2-13.2c0.2-3.2,0.5-6.5,0.8-9.7
				c0.6-7.8,1.5-15.5,2.7-23.3c0.7-4.7,1.4-9.4,2.2-14c0.6-3.5,1.4-7,2.3-10.5c1.3-5,2.6-10.1,4.2-15.1c1.7-5.4,3.9-10.7,6.3-15.8
				c1.1-2.4,2.2-4.8,3.3-7.2c0.3-0.7,0.9-1.2,1.2-1.9c0.3-0.5,0.8-0.5,1.4-0.5c5.8,0.6,11.6,0.8,17.4,0.9c6.6,0.1,13.2,0.2,19.9,0.2
				c3.7,0,7.4-0.4,11.1-0.7c1.9-0.2,3.9-0.2,5.8-0.4c0.3,0,0.6-0.1,0.9-0.1c0.9,0.1,1.8-0.6,2.8-0.1c0.6,0.4,0.9,1,1.3,1.5
				c4.5,6.9,7.6,14.4,10.3,22c2.9,8,5.1,16.1,6.9,24.4c1.2,5.7,2.4,11.4,3.1,17.2c0,0.4,0,0.8,0,1.2
				C417.6,275.2,417.6,275.2,417.6,275.3z"/>
			<path fill="#BBC8D6" d="M392.7,209.3c-4.1,0.3-8.1,0.8-12.2,0.9c-6.3,0.3-12.6,0.3-18.9,0.2c-4.5-0.1-9,0-13.5-0.2
				c-3.4-0.1-6.8-0.5-10.2-0.9c-0.5,0-0.9,0-1.3,0.2c0.6-3-0.5-5.8-1.1-8.6c-0.8-3.9-1.8-7.7-2.5-11.6c-0.9-5.4-1.7-10.9-2.3-16.4
				c-0.3-3.1-0.9-6.2-1.3-9.3c-0.3-2.1-0.4-4.3-0.6-6.4c-0.4-6.4-0.3-12.8-0.3-19.2c0-2-0.3-3.9-0.4-5.8c-0.1-2,0.1-4.1,0.2-6.1
				c0.1-4.6,0.2-9.1,1-13.6c0.1-0.6,0-1.8,1.4-1.5c0.3,0.1,0.3-0.7,0.3-1c0.1-0.5-0.2-0.3-0.5-0.2c-0.3,0.1-0.4-0.2-0.3-0.3
				c0.2-0.5-0.1-1.2,0.7-1.4c0.3-0.1,0.5-0.2,0.6-0.6c0-0.2,0.1-0.4,0.4-0.4c0.3,0,0.6,0.1,0.9,0c0.5-0.7,1-0.1,1.5-0.1
				c0.2,0,0.4-0.1,0.6,0c0.2,0,0.4,0.1,0.7,0c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.1,0.5-0.2c0-0.3-0.3-0.2-0.4-0.3
				c-0.5-0.4-1-1-1.8-0.5c-0.2,0.1-0.3,0.1-0.5,0c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.5-0.4-1.1,0.1-1.6c-0.2-0.7,0.7-0.2,0.7-0.7
				c-0.1-1.8,0-1.9,1.5-1.9c0.3,0.2,0.6,0.1,0.8-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0-0.1,0-0.1s-0.1,0-0.1,0
				c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2-0.1-0.4-0.1-0.6-0.3c-0.4-0.5-0.2-0.8,0.2-1.2c0-0.1,0.2-0.1,0-0.2
				c-0.2-0.1-0.6,0.1-0.5-0.4c0.3-0.6,1-1,1.2-1.7c0.4-0.1,0.8-0.3,1.3-0.1c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2-0.1,0.5,0.2,0.3
				c0.5-0.2,0.9-0.4,1.5-0.3c0.1,0,0.2,0.1,0.2,0.2c0.3,0.4-0.5,1.1,0.4,1.3c0.7,0.1,0.5-0.4,0.5-0.8c0-0.3,0.2-0.5,0.5-0.6
				c0.1-0.1,0.4,0,0.4-0.2c0,0-0.1-0.1-0.2-0.1c-0.5-0.2-0.4-0.7-0.5-1c0-0.8,0.4-1.3,1.2-1.5c0.5-0.1,0.3-1,0.9-1
				c0,0,0.1-0.1,0.1-0.1c0.4,0,1.8-0.4,2.3-0.7c0.9-0.6,1.8-1,2.8-1.3c0.3-0.1,0.5-0.2,0.7,0.1c-0.1,0.2-0.3,0.4-0.1,0.5
				c0.3,0.2,0.6,0.1,0.8-0.1c0.3-0.3,0.4-0.8,0.9-0.6c0-0.2-0.4-0.2-0.2-0.5c1.5-0.5,3-1,4.6-1.2c0.7-0.1,0.8,0.6,1.1,1
				c1.2,1.9,2.4,3.8,3.9,5.4c2.5,2.7,5.6,4.1,9.1,4.9c2.5,0.6,5.1,0.8,7.6,0.8c6.8,0,13.2-1.5,18.2-6.5c1.5-1.5,2.8-3.1,3.8-4.9
				c0.2-0.3,0.3-0.8,0.9-0.7c2.5,0.9,4.9,1.9,7.2,3.1c1.8,0.9,3.7,1.8,5.4,2.9c4,2.5,7.7,5.4,10.7,9.1c2.4,3,4.5,6.1,6,9.6
				c0.7,1.7,1.3,3.6,1.7,5.4c0.2,1.2,0.6,2.3,0.9,3.5c0.6,2.4,0.8,4.9,1,7.3c0,0.4,0.1,0.9-0.6,1c-3.6,0.3-7,1.5-10.2,2.9
				c-3.3,1.4-6.6,2.8-9.7,4.7c-1.1,0.7-2.1,1.4-3.2,2c-0.8,0.5-1.1,1.1-0.7,1.9c0.4,1,0.4,2.1,0.5,3.2c0.2,1-0.2,1.9-0.5,2.8
				c-1.7,6-3.2,12.1-4.6,18.2c-0.4,1.7-0.6,3.5-1.1,5.1c-0.7,2.4-0.7,4.9-1.4,7.3c-0.3,1.1-0.3,2.2-0.6,3.3
				c-0.3,1.1-0.5,2.3-0.9,3.3c-0.3,0.2-0.6,0.1-0.8,0.1c-0.3-0.3-0.8-0.1-1.2-0.4c-0.1-0.1-0.1-0.3-0.1-0.4c0,0.1,0,0.2,0.1,0.3
				c0.3,0.4,0.8,0,1.1,0.4c0.4,0.1,0.8,0.1,0.8,0.6c0,0.2-0.2,0.4-0.4,0.6c-0.4,0.2-0.8-0.1-1.2-0.1c-0.2,0-0.2,0.1-0.1,0.1
				c0,0,0.1,0.1,0.1,0.2c0.1,0.2,0.2,0.4,0.2,0.6c-0.6,1.2-1.1,0.3-1.6-0.1c0.3,0.4,0.5,0.9,1.3,0.7c0.5-0.2,0.9,0.2,1,0.8
				c0.1,0.5,0.1,0.9,0,1.4c-0.1,0.8-0.4,1.5-0.5,2.3c-0.1,0.7-0.5,1-1.2,0.6c1.1,0.9,0.6,1.9,0.2,2.8c-0.2,0.3-0.1,0.6-0.1,0.9
				c-0.2,0.8,0.1,1.6-0.4,2.4c-0.1,0.1-0.1,0.1-0.2,0c-0.1,0-0.2-0.2-0.4-0.2c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.6,0,0.9-0.6,1.1
				c0.4,0.2,0.8,0.4,0.4,0.9c-0.3,0.2-0.4,0.4-0.2,0.7c0,1-0.1,2-0.9,2.8c-0.1,0.1-0.1,0.1,0,0.1c0.7,0.7,0.5,1.5,0,2.2
				c-0.8-0.1-1.6,0.5-2.4,0.2C392.9,208.6,392.8,209,392.7,209.3z"/>
			<path fill="#EBE2CF" d="M322.6,376.6c13,0.4,26.1,0.8,39.1,1.2c-0.6,0.2-0.9,0.7-0.9,1.3c-0.1,3.1-0.5,6.1-0.6,9.1
				c0,2.3-0.4,4.6-0.5,6.9c-0.2,3.2-0.2,6.3,0.1,9.5c0.6,6,1.7,11.9,2.8,17.9c0.8,4.4,1.2,8.7,1.7,13.1c0.5,4.6,1.2,9.2,1.1,13.8
				c-0.1,4.3,0,8.6-0.4,12.8c-0.4,3.9-0.8,7.9-1.1,11.8c-0.1,1.8-0.2,3.6-0.2,5.4c-0.1,1.9-0.4,3.9-0.6,5.8c-0.2,2.7-0.7,5.4-1,8.1
				c-0.3,2.9-0.6,5.9-1,8.8c-0.2,1.4-0.2,2.9-0.4,4.3c-0.4,4.2-0.8,8.3-1.4,12.5c-0.4,2.7-0.5,5.5-0.8,8.3c-0.3,2.6-0.7,5.2-1,7.8
				c-0.3,2.6-0.7,5.3-0.9,7.9c-0.1,1.1-0.4,2.2-0.5,3.3c-0.6,0.5-1.3,0.3-2,0.3c-0.5,0-1,0-1.4,0.2c-0.3,0.1-0.6,0.1-0.9,0
				c-0.7-0.2-1.3-0.1-2-0.1c-3.8,0-7.6,0-11.3,0c-0.5,0-1.1,0.1-1.6-0.3c-0.2-4.1-0.6-8.1-0.8-12.1c-0.1-3.5-0.5-7-0.8-10.4
				c-0.4-3.6-0.6-7.3-0.6-10.9c0-2.8-0.5-5.7-0.8-8.5c-0.1-1.3-0.3-2.6-0.3-3.9c-0.2-4.5-0.8-9-1.2-13.5c-0.2-3-0.5-6-0.9-9
				c-0.4-2.8-0.2-5.6-0.8-8.4c-0.3-1.2-0.2-2.4-0.3-3.7c-0.2-3.6-0.6-7.2-0.9-10.8c-0.2-2.4-0.4-4.8-0.5-7.1
				c-0.3-3.8-0.5-7.6-0.9-11.4c-0.2-2.2-0.4-4.4-0.6-6.5c-0.3-3.6-0.4-7.1-0.9-10.7c-0.3-2.2-0.2-4.4-0.3-6.5
				c-0.2-3.4-0.5-6.8-0.9-10.2c-0.2-2.2-0.4-4.3-0.6-6.5c-0.5-4.7-0.5-9.4-1.2-14.1c-0.2-1.4,0-2.8-0.2-4.2
				C323.3,377.1,323,376.8,322.6,376.6z"/>
			<path fill="#EBE2CF" d="M377.5,378c0.7-0.5,1.4,0,2.1,0c2.9-0.1,5.8-0.1,8.8,0c2.7,0.1,5.5,0.2,8.2-0.1c3.6-0.4,7.2-0.3,10.8-0.4
				c3.2-0.1,6.5-0.6,9.7-0.4c-0.8,0.2-1.2,0.5-1.3,1.5c-0.1,2.1-0.3,4.1-0.2,6.2c0,2.3,0.2,4.7,0.1,7c-0.1,1.7,0.3,3.3,0.4,5
				c0.2,2.7,1,5.2,1.2,7.8c0.1,1.1,0.5,2,0.8,3c1.5,4.4,2.9,8.9,4,13.5c0.9,3.6,1.4,7.2,2,10.9c0.2,1.7,0.4,3.4,0.7,5
				c0.4,2.8,0.6,5.7,0.7,8.5c0.2,4.4,0,8.9-0.1,13.3c0,4.1-0.4,8.2-0.6,12.3c-0.3,4.3-0.7,8.6-1.3,12.9c-0.2,1.7-0.3,3.5-0.4,5.3
				c-0.1,2.3-0.5,4.6-0.7,6.9c-0.3,3.7-0.7,7.3-0.8,11c0,1.7-0.3,3.4-0.6,5.1c-0.3,2.1-0.2,4.2-0.3,6.2c-0.1,1.5-0.3,3.1-0.5,4.6
				c-0.1,0.9-0.2,1.9-0.2,2.8c0,3.3-0.5,6.5-0.6,9.7c-0.2,4.1-0.6,8.2-1.1,12.2c-0.5,0.4-1.1,0.3-1.7,0.3c-4.1,0-8.1,0-12.2,0
				c-0.5,0-1,0-1.5,0.1c-0.3,0.1-0.5,0.1-0.8,0.1c-1-0.1-1.7,0.2-2.1,1.1c-0.3-0.4-0.2-0.9-0.2-1.4c0-1.6,0.1-3.1-0.4-4.6
				c-0.3-1-0.1-2.2-0.1-3.3c-0.1-2.9-0.7-5.7-0.7-8.6c-0.1-2.7-0.4-5.4-0.7-8.1c-0.4-3.5-0.7-7-1-10.4c-0.2-2.2-0.5-4.4-0.7-6.6
				c-0.4-4.1-1-8.2-1.4-12.2c-0.4-3.5-0.7-7-1-10.4c-0.3-3.3-0.7-6.5-1.1-9.8c-0.6-4.8-1.3-9.6-1.8-14.4c-0.5-4.4-0.8-8.8-1.4-13.1
				c-0.7-5.1-0.9-10.3-1.8-15.4c-0.7-4.3-1.2-8.6-2.2-12.9c-0.5-2.2-0.7-4.6-1.1-6.9c-0.5-3.4-1.4-6.7-1.8-10.1
				c-0.7-5.6-1.9-11-2.8-16.5c-0.3-1.9-0.6-3.7-0.9-5.6C378.8,378.5,378.4,377.9,377.5,378z"/>
			<path fill="#EBE2CF" d="M400.1,90.4c-1.1,1.6-2.2,3.2-3.5,4.7c-1.9,2.3-4.2,4-6.8,5.2c-3.8,1.8-7.9,2.7-12,2.5
				c-4.7-0.1-9.5-0.3-13.7-2.8c-2.4-1.4-4.4-3.2-5.9-5.6c-0.9-1.4-1.8-2.7-2.7-4c3.6-1,7.1-2,10.7-2.9c1.7-0.4,1.7-0.4,1.8-2.2
				c0.1-4.4-0.1-8.8-0.3-13.1c-0.1-1.9-0.1-3.9-2.2-4.9c-1.5-0.8-3.2-1-4.8-1.2c-2.7-0.2-5.5-0.4-7.8-2c-1.6-1.1-2.3-2.9-2.8-4.7
				c-0.4-1.2-0.6-2.5-0.9-3.7c-0.2-0.8-0.2-1.1,0.8-1.4c2.4-0.7,4.2-2.3,5.2-4.7c0.3-0.7,0.2-1-0.7-0.8c-1.7,0.4-3.4,0.6-5,0.9
				c-0.6,0.1-1.1,0.1-1.1-0.7c0-1-0.3-2-0.3-2.9c-0.1-1.1-1.1-1.3-1.8-1.7c-0.6-0.4-1.3-0.7-2-1c-1.2-0.5-1.5-1.4-0.8-2.4
				c1.8-2.8,3.4-5.9,4.7-9c0.2-0.4,0.2-0.8,0.3-1.1c1.5,0.2,2.5,1.1,2.8,2.6c0.2,1.1,0.4,2.3,0.8,3.4c1.1,3.4,2.9,4.9,6.5,5
				c0.2,0,0.5,0,0.5,0.2c0.3,0.7,0.8,0.4,1.3,0.3c0.3,0.1,0.6,0.3,0.8,0.6c-0.4,0.5,0,1,0,1.5c-0.1,0.2-0.4,0.4-0.3,0.6
				c0.1,0.2,0.3,0,0.5,0c0.1,0,0.2,0.1,0.2,0.2c0,0.5-0.2,1.1,0.7,1c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.5-0.4,0.8-0.1
				c0.3,0.3,0.1,0.7-0.2,1c0.8-0.6,1.7-0.7,2.6-0.6c0.3,0.8,0.8,0.1,1.1,0.1c0.5,0,0.5,1.2,1.3,0.6c0.5-0.1,0.3-0.5,0.3-0.8
				c0-0.7,0-1.2,0.9-0.8c0.4,0.4,0.7,0.1,0.9-0.2c0.2-0.3,0.3-0.7,0.5-1.1c-0.7,0.7-0.7,0.7-1.2,0c0.1-1,1-1,1.6-1.3
				c0.2-0.8,0.3-1.6,0.4-2.4c-0.2,0.2-0.5,0.5-0.9,0.4c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1-0.8-0.1-1.6-0.5-2.3c0.1-0.3,0.4-0.6,0.1-0.9
				c-0.3-0.2-0.5-0.4-0.9-0.2c-0.2,0.1-0.5,0.2-0.5,0.1c-0.2-1.3-1.1-0.5-1.7-0.6c-0.8-0.2-1.6-0.3-2.5-0.2
				c-0.5,0.3-0.9,0.1-0.9-0.5c0.1-1.2,0.5-2.3,0.6-3.5c0.1-0.6,0.4-0.9,1-1c4.3-0.2,8.6-0.4,12.9-0.2c0.2,0,0.4,0.1,0.6,0.2
				c0.7,1,1.5,2,2.1,3c0.6,1,1.3,1,2,0c0.1-0.2,0.3-0.4,0.4-0.6c0.8-0.9,1.7-1.9,3.2-1.5c1.5,0.3,2.1,1.4,2.4,2.8
				c0.5,2.8,0.6,5.6-1.6,8c-1.1,1.2-2.3,1.9-3.8,2.2c-1.1,0.2-1.3,0.8-1.1,1.7c1.1,5.2,1.9,10.5,2.6,15.7c0.1,0.5,0.4,0.8,0.7,1.2
				c-0.6,0.5-0.6,1.1-0.4,1.8c0.5,3.1,0.4,6.3,0.7,9.5c0.3,3,0.5,6.1,0.7,9.1c0.1,1,0.7,1.3,1.6,1.7c2.1,0.7,4.2,1.5,6.3,2.3
				C396.5,89,398.3,89.7,400.1,90.4z"/>
			<path fill="#6E6E73" d="M352.1,546.5c0.1,0.1,0.2,0.1,0.2,0c1.2-0.2,2.4-0.3,3.6,0c-0.3,3-0.7,6-0.9,9c-0.1,1.6,0.1,3.2,0,4.7
				c-0.1,3.1,0.6,6.1,0.6,9.1c0,3-0.4,6-0.9,9c-0.2,1.6-0.5,1.8-2.2,1.8c-2.3,0-4.6,0-6.8,0c-1.1,0-1.4-0.3-1.4-1.4
				c0-1.4-0.2-2.8-0.2-4.2c0-0.5-0.2-0.8-0.9-0.5c-3.2,1.8-6.3,3.6-9.5,5.5c-1.4,0.8-2.8,1.3-4.4,1.5c-6.9,0.8-13.8,0.1-20.7,0.3
				c-0.3,0-0.8,0.1-0.7-0.5c0.2-2.1,0.5-4.3,2.7-5.3c1.8-0.9,3.6-1.6,5.5-2.2c2.6-0.8,5-2,7.4-3.2c1.7-0.9,3.5-1.6,5.1-2.8
				c1.6-1.1,2.5-2.7,3.7-4.1c0.8-0.9,1.4-2,2.1-2.9c1.7-2.2,2.1-4.7,2.3-7.4c0.2-2.2-0.2-4.4-0.1-6.5c0.2-0.3,0.4-0.2,0.6-0.2
				c4.7,0,9.4,0,14.1,0C351.7,546.4,351.9,546.4,352.1,546.5z"/>
			<path fill="#6E6E73" d="M400.1,549.6c-0.2-1.2,0.3-1.7,1.5-1.5c0.4,0.1,0.8,0,1.2,0c4.9-0.1,9.8,0,14.7,0c0.3,0,0.6,0,0.9,0.2
				c0.1,2.2-0.4,4.3-0.6,6.4c-0.4,3.4,0.1,6.8,0.8,10.1c0.5,2.3,0.4,4.7,0.2,7c-0.3,2.6-0.7,5.1-1.1,7.6c-0.2,1.6-0.5,1.8-2.1,1.9
				c-1.7,0-3.4,0.1-5.2-0.1c-1.6-0.2-1.9-0.7-1.8-2.3c0.1-1.1-0.1-2.2-0.2-3.3c-0.1-1.3-0.8-1.1-1.5-0.6c-1.4,0.9-2.8,1.7-4.1,2.7
				c-3.8,2.8-8,4.3-12.6,4.9c-4.8,0.7-9.6,0.8-14.5,0.7c-0.5,0-1-0.1-1.6-0.1c-1.9-0.3-2.2-1-1.7-3c0.8-3.2,3.4-4.2,6.1-5
				c3.3-1,6.4-2.3,9.3-4.2c3.4-2.2,5.6-5.4,8-8.5c1.5-2,3.1-4,4.1-6.4c0.2-0.6,0.4-1.2,0.4-1.8C400.3,552.8,400.1,551.2,400.1,549.6
				z"/>
			<path fill="#EBE2CF" d="M351.4,20.1c5.9-0.7,10.7-3.7,15-7.7c1.6-1.5,1.6-1.5,2.6,0.6c2.5,5.5,5.5,10.7,8.3,16
				c-1.4,0.6-2.8,0.4-4.2,0.3c-1.9-0.2-3.7-0.2-5.6-0.2c-1.6,0-3.2-0.3-4.7-1.2c-3.4-2.1-6.9-2-10.5-0.1c-0.9,0.5-1.7,1.2-2.9,0.6
				c0.6-2,1.4-3.9,1.8-5.9C351.5,21.9,352.2,21,351.4,20.1z"/>
			<path fill="#6E6E73" d="M349.5,28.5c0.9-0.1,1.7-0.3,2.5-0.8c3.7-2.3,7.5-2.2,11.2,0c1,0.6,2,0.9,3.1,1c3.7,0.2,7.3,0.2,11,0.4
				c0.5,0.7,1.1,1.4,1.5,2.3c-4.3,0.1-8.5,0.2-12.8,0.3c-0.9,0-1.4,0.1-1.4,1.2c-0.1,1.1-0.4,2.3-0.7,3.4c-0.1,0.4,0,0.9-0.2,1.3
				c0,0.1-0.1,0.2-0.1,0.3c-0.5,1.1-1.2,2.1-1.9,2.9c-0.8,0.9-1.8,1.5-3.1,1.5c-3.8,0-5.8-1.6-6.9-5.2c-0.3-0.9-0.5-1.9-0.6-2.8
				c-0.2-1.4-0.7-2.5-2.2-2.9c-0.2,0-0.3-0.2-0.5-0.4C348.4,30,347.9,28.7,349.5,28.5z"/>
			<path fill="#F1E8D3" d="M418.3,548.4c-4.8,0-9.6,0-14.3-0.1c-0.4,0-0.9,0.1-1.3-0.1c0.3-0.4,0.8-0.3,1.2-0.3c4.8,0,9.6,0,14.4,0
				C418.3,548.1,418.3,548.2,418.3,548.4z"/>
			<path fill="#C1CDDA" d="M341.6,97c0.4,0.1-0.1,0.8,0.5,0.7c0,0.6-0.6,0.3-0.8,0.6c-0.2,0.2-0.4,0.2-0.6,0.2
				c-0.1-0.3-0.2-0.4-0.5-0.3l0,0c-0.5-0.2-1-0.5-1.5,0.1c-0.1,0.1-0.3,0-0.4-0.1c0-0.2,0-0.4-0.2-0.5c-0.1-0.2-0.6-0.3-0.5-0.7
				c0.5-0.3,1-0.5,1.4-0.8c0.4-0.2,0.7-0.2,1,0.2C340.4,96.6,341,96.8,341.6,97z"/>
			<path fill="#F4EBD6" d="M352.1,546.5c-5.1,0-10.2,0-15.3,0c0-0.1,0-0.2,0-0.3c4.7,0,9.3,0,14,0
				C351.2,546.3,351.7,546,352.1,546.5z"/>
			<path fill="#EAEEF1" d="M350.9,91.7c0.3,0.5,0.7-0.1,1,0.1c0.1,0.2-0.1,0.5-0.2,0.5c-0.9-0.2-1.4,0.6-2.1,1
				c-0.5,0.2-0.7,0.1-0.8-0.2c-0.2-0.5,0.2-0.6,0.6-0.7c0.3,0.3,0.7,0.4,0.7-0.1C350.1,91.4,350.6,91.7,350.9,91.7z"/>
			<path fill="#D9DFE7" d="M341.6,97c-0.7,0.4-1.2-0.2-1.7-0.5c0.2,0,0.3,0,0.4-0.2c0.8-0.2,0.1-0.5,0-0.7c0.7-0.6,1.4-0.2,2.2-0.2
				C341.9,95.7,341.5,96.2,341.6,97z"/>
			<path fill="#D1D9E3" d="M333.8,103.9c0,0.6,0,1.1,0,1.7c-0.6,0.4-1.4,0.2-2.1,0.3c0,0-0.1-0.1-0.1-0.1c-0.2-0.6,0.4-0.7,0.7-0.9
				c0.9,0.1,0.1-0.8,0.5-1C333.2,103.9,333.5,104.1,333.8,103.9z"/>
			<path fill="#CBD5DF" d="M345.9,93.6c0,0.3-0.1,0.7-0.4,0.6c-0.7-0.1-1,0.1-0.9,0.8c0,0-0.2,0.2-0.3,0.2c-0.2,0-0.2-0.1-0.2-0.3
				c0.3-0.8-0.4-0.6-0.7-0.6C344.2,94,344.9,93.4,345.9,93.6z"/>
			<path fill="#F1F3F6" d="M336.3,100.1c-0.4,0.2-0.7,0.5-0.2,1c-0.5,0.6-0.7,0.5-1.4-0.2c0.1-0.5,0.2-0.9,0.7-1.2
				C335.6,99.9,336.2,99.4,336.3,100.1z"/>
			<path fill="#C6D1DC" d="M334.6,100.8c0.2-0.1,0.4-0.2,0.5,0.2c0.2,0.5,0.6,0.4,0.9,0.1c0.2,0.1,0.3,0.2,0.5,0.2
				c0.1,0.2,0,0.3-0.2,0.2c-1.5-0.4-1.5,0.8-1.7,1.7C334,102.4,334.5,101.6,334.6,100.8z"/>
			<path fill="#D1D9E3" d="M332,107.5c-0.1,0.5-0.2,0.6-0.8,0.4c-0.2-0.8,0.1-1.5,0.5-2.1c0,0,0,0,0,0c0.2,0.1,0.4,0.2,0.4,0.5
				C332.2,106.8,331.5,107.1,332,107.5z"/>
			<path fill="#BBC8D6" d="M340.3,95.5c0.5-0.1,0.7,0.1,0.9,0.6c0,0.1,0,0.3-0.1,0.4c-0.3,0.3-0.5-0.2-0.8-0.2
				C339.9,96,339.9,95.8,340.3,95.5z"/>
			<path fill="#C1CDDA" d="M332.9,103.9c0.2,0.4,0.3,0.8,0.5,1.2c-0.4,0.2-0.6-0.3-1-0.2C332,104.3,332.4,104.1,332.9,103.9z"/>
			<path fill="#F4EBD6" d="M355.9,546.5c-1.2,0-2.4,0-3.6,0c1.2-0.6,2.4-0.1,3.6-0.3C355.9,546.3,355.9,546.4,355.9,546.5z"/>
			<path fill="#E2E7ED" d="M337.5,97c0.1,0.3,0.5,0.3,0.5,0.7c-0.4,0-0.8,0.3-1.2,0C336.9,97.3,337.1,97.1,337.5,97z"/>
			<path fill="#C6D1DC" d="M336.3,100.1c-0.2-0.4-0.8-0.1-1-0.5c0.1-0.1,0.2-0.2,0.2-0.2c0.4,0.6,0.7-0.2,1.1-0.1
				C336.7,99.6,336.5,99.9,336.3,100.1z"/>
			<path fill="#728A9E" d="M422.2,317.5c-0.2-0.2-0.3-0.3-0.5-0.5c0.2-0.2,0.1-0.8,0.7-0.7C422.3,316.8,422.3,317.1,422.2,317.5z"/>
			<path fill="#E2C674" d="M408.8,13.2c0.4,0.4,1,0.8,1.1,1.5c-0.1,0.4-0.4,0.6-0.5,0.9c-0.2,1,0.3,2.1-0.4,3
				c-0.1,0.4-0.3,0.6-0.6,0.7c-0.6,0-1-0.6-1.6-0.6c-0.3-0.2-0.4-0.6-0.3-0.9c0.2-0.5,0.6-0.5,1-0.4c-0.7-0.3-1-1.1-1.8-1.2
				c-0.1-0.2-0.1-0.3,0-0.5c0.2-0.3,0.5-0.5,0.9-0.4c0.5,0.3,0.8,0.7,1.1,1.2c0.2-0.5,0.3-1-0.1-1.5
				C407.1,13.7,407.4,13.3,408.8,13.2z"/>
			<path fill="#E2C674" d="M405.7,9.3c0.9,1,1.6,2.2,2.7,3.1c-0.7,0.4-1,1-0.9,1.8c0,0.3,0.1,0.6-0.2,0.7c-0.4,0.1-0.4-0.2-0.6-0.4
				c-0.5-0.7-0.6,0.3-1,0.3c-0.5,0.1-0.8-0.2-1.1-0.6c-0.3-1.3-0.3-1.4,0.7-1.6c0.3-0.3,0.1,0.8,0.5,0.2c0.2-0.2,0.4-0.4,0.5-0.7
				c-0.3-0.1-0.5,0.1-0.8,0c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.7-0.3-1.2-1.1-1.3c-0.2-0.4-0.2-0.7,0-1.1c0.1-0.1,0.2-0.1,0.3-0.1
				C404.8,9.3,405.2,9.3,405.7,9.3z"/>
			<path fill="#E2C674" d="M401.1,5.5c0.5,0.1,0.8,0.4,1.2,0.7c0.2,0.6-0.2,1-0.4,1.5c-0.1,0.1-0.2,0.2-0.4,0.3
				c-0.7-0.3-1.3-0.9-2-1.2c-0.2-0.3-0.3-0.5,0-1c-0.4,0.5,0,1.3-0.8,1.1c-0.8-0.6-0.5-1.2,0.1-1.8c0.2-0.2,0.2-0.6,0.5-0.8
				c0.6,0.2,1.2,0.6,1.7,1C400.8,5.4,400.9,5.5,401.1,5.5z"/>
			<path fill="#E2C776" d="M401.5,7.9c0.1-0.1,0.2-0.2,0.3-0.3c0.9-0.9,0.9-0.9,1.7,0.1c0.2,0.2,0.4,0.2,0.7,0.2
				c0.2,0.1,0.3,0.3,0.4,0.5c0,0.6-0.5,0.7-0.8,0.9c-1.1,0.1-2.2,0-3.4,0.1c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.3-0.3-0.5-0.6-0.6
				c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.2-0.8-0.3-0.6-0.7c0.2-0.5,0.8-0.2,1.2-0.3C400.2,7.8,400.9,8,401.5,7.9z"/>
			<path fill="#DBB32E" d="M392.2,1.4c0.2,0,0.5,0,0.7,0c0.1,1,0.9,0.8,1.5,1c0.8,0.1,1,1.2,2,0.9c0.3,0,0.6,0,0.7,0.4
				c-0.5,1.5-0.9,1.7-2.2,0.8c-0.2-0.1-0.5-0.3-0.7-0.4c-0.5-0.2-1-0.7-1.7-0.3c-0.4,0.2-0.7-0.1-0.6-0.6c0-0.5,0.1-1.1,0.2-1.6
				C392,1.6,392.1,1.5,392.2,1.4z"/>
			<path fill="#DCB433" d="M389.4,0.7c0.1,0,0.3,0.1,0.4,0.1c0.5,0.1,0.9,0.3,1.3,0.7c0,0.1,0,0.2-0.1,0.3c0,0.1-0.6,0.1-0.1,0.4
				c0.2,0.1,0.4,0.2,0.5,0.4c0.3,0.7-0.1,1.4-0.3,2c-0.2,0.6-0.7,0.6-1.1,0.3l0,0c-0.4-0.4-0.4-0.8-0.2-1.3c0-0.2,0.7-0.2,0.1-0.6
				c-0.5-0.3-1-0.5-1.5-0.6c-0.2-0.1-0.3-0.2-0.4-0.4C387.9,0.7,388.1,0.6,389.4,0.7z"/>
			<path fill="#DEBA47" d="M428.4,55c0-0.2,0.3-0.2,0.2-0.5c-0.1-0.6-0.1-1.3,0.9-0.8c0.1,0.1,0.4-0.1,0.5-0.1c0,1,0,2.1-0.2,3.1
				c-0.2,0.2-0.4,0.5-0.8,0.5c-0.2,0-0.3-0.1-0.2-0.3c-0.2-0.2-0.5-0.3-0.7-0.5C428.3,55.9,428.1,55.4,428.4,55z"/>
			<path fill="#DCB431" d="M405.6,14.6c0.3-0.4,0.6-0.7,0.9-1.1c0.6,0.2,0.1,0.9,0.6,1.2c0.3-0.4,0.1-0.7,0.1-1.1
				c-0.1-0.8,0.1-1.4,1.1-1.2c0.3,0.2,0.4,0.5,0.4,0.8c-0.6,0.4-1.8,0.5-1,1.7c0.2,0.3,0.2,0.6,0,0.7c-0.5,0.4-0.8-0.1-1.2-0.3
				c-0.4,0-0.4,0.3-0.7,0.5c-0.2-0.1-0.3-0.2-0.4-0.3C405.3,15.2,405.4,14.9,405.6,14.6z"/>
			<path fill="#DEBC50" d="M408.7,18.5c0.7-1-0.2-2.2,0.5-3.1c0.3,0,0.6-0.2,0.9,0c0.2,0.2,0.3,0.5,0.5,0.7c0,0.6-0.4,0.5-0.7,0.6
				c0.2,0.4,0.6,0.8,0.5,1.3c0,0,0,0.1-0.1,0.1c-0.3,0.1-0.5,0.4-0.6,0.6C409.3,19.4,409,19.1,408.7,18.5z"/>
			<path fill="#E0C05E" d="M415.5,64.3c0.3,0.1,0,1,0.8,0.6c0.1-0.1,0.3,0.2,0.2,0.4c-0.1,0.2,0.1,0.6-0.3,0.7
				c-0.2,0.1-0.4-0.7-0.5-0.1c-0.1,0.7-0.4,1.6,0.2,2c1,0.8,0.9,1.4,0,2.1C415.3,68.2,415.4,66.3,415.5,64.3z"/>
			<path fill="#E2C775" d="M410.4,18c-0.5-0.6-0.7-1.3-1.1-2.1c0.5,0,0.8,1,1.3,0.2c0.6,0.8,0.9,1.7,1.2,2.6c-0.4-0.1-0.7-0.1-1,0.2
				c0,0-0.1-0.1-0.1-0.1C410.6,18.6,410.5,18.3,410.4,18z"/>
			<path fill="#E1C46D" d="M430.3,50.2c-0.2-0.1-0.4-0.2-0.7-0.3c-0.6-0.2-1-0.2-0.5,0.6c0.1,0.2,0.2,0.5-0.1,0.5
				c-0.3,0.1-0.3-0.2-0.4-0.4c-0.2-0.6-0.2-1.1,0.4-1.4c0.6-0.4,0.7-0.7-0.2-0.9c0.6-0.2,1.2,0,1.5-0.5
				C430.3,48.6,430.3,49.4,430.3,50.2z"/>
			<path fill="#E1C56F" d="M428.4,64.1c0-0.1,0-0.2,0-0.2c0.6-0.5,1.2-0.2,1.7-0.1c0.3,0.2,0.2,0.5,0.1,0.8
				c-0.2,0.4-0.7,0.7-0.6,1.2c0,0.2,0.3,0.5,0,0.6c-0.3,0.1-0.5-0.2-0.7-0.5c-0.3-0.5,0.1-1.2-0.5-1.6
				C428.3,64.3,428.3,64.2,428.4,64.1C428.4,64.1,428.4,64.1,428.4,64.1z"/>
			<path fill="#DCB536" d="M430.2,63.8c-0.6,0.5-1.2-0.2-1.7,0.1c-0.1,0-0.2,0-0.2,0c-0.1-0.5,0.4-0.7,0.4-1.2
				c0-0.3-0.1-0.5,0.1-0.8c0.3,0,0.7,0,0.7-0.5c0.2-0.2,0.3-0.1,0.5,0C429.7,62.3,430.1,63,430.2,63.8z"/>
			<path fill="#DEBC51" d="M429.8,61.4c-0.2,0-0.3,0-0.5,0c0.1-0.9-0.7-1.5-0.5-2.4c-0.1-0.2-0.2-0.5-0.1-0.8
				c0.2-0.7,0.5-0.8,1.1-0.4C429.5,59,430,60.2,429.8,61.4z"/>
			<path fill="#E2C672" d="M397,3.8c-0.2-0.3-0.5-0.2-0.7-0.2c0,0,0-0.1,0-0.1c0.1-0.2,0.1-0.5,0.3-0.6c0.7,0.2,1.3,0.6,1.9,1
				c-0.1,0.4-0.1,0.8-0.2,1.2c-0.4,0.2-0.7,0.6-1.2,0.6c-0.2-0.1-0.2-0.3-0.3-0.4C396.7,4.7,396.8,4.2,397,3.8z"/>
			<path fill="#E2C674" d="M419.3,73.4c0.2,0.8-0.4,1.1-0.8,1.7c-0.2,0.3,0.1,0.8-0.4,0.9c-0.4-0.3-0.5-0.8-0.7-1.2
				c0.1-0.8,1.1-1,1.3-1.8c0,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.2,0C419.2,73.1,419.3,73.2,419.3,73.4z"/>
			<path fill="#E2C675" d="M417.6,72.2c0.3,0.4,1.1,0,1.2,0.7c0,0,0,0,0,0c-0.1,0.1,0,0-0.1,0.1c-0.4,0.4-0.9,0.4-1.4,0.2
				c0.5,0.5,0.1,0.7-0.3,0.9c-0.3-0.5-0.7-1-0.7-1.7c0.3-0.2,0.6-0.3,0.9-0.2C417.5,72.1,417.5,72.1,417.6,72.2z"/>
			<path fill="#E1C570" d="M430.1,43.2c-0.2-0.1-0.3-0.3-0.2-0.5c0.1-1.1-0.1-2-1.6-1.8c-0.2,0-0.5-0.1-0.5-0.4
				c0.1-0.4,0.4-0.4,0.7-0.4c0.4-0.1,0.8,0.9,1.1,0C430.1,41,430.1,42.1,430.1,43.2z"/>
			<path fill="#DCB536" d="M417.1,74.2c0-1.2,0.9-1.1,1.7-1.2c0.3,1.2-1,1.2-1.4,1.9C417.3,74.6,417.2,74.4,417.1,74.2z"/>
			<path fill="#E2C671" d="M419.5,75.6c0.5-0.3,0.9,0.5,1.4,0.3c0.2,0.3,0.1,0.5,0,0.8c-0.2,0.4-0.8,0.5-1,1
				c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2-0.2-0.3-0.3-0.5-0.5c0-0.3,0-0.7-0.2-1C418.9,75.8,419.3,75.7,419.5,75.6z"/>
			<path fill="#DDB73C" d="M419.5,75.6c0,0.2-0.4,0.2-0.2,0.5c0.3,0.4,0.3,0.8-0.1,1.1c-0.5-0.3-0.8-0.8-1.2-1.2
				c0.1-0.3,0.3-0.6,0.4-0.9C418.7,75.7,419.2,75.6,419.5,75.6z"/>
			<path fill="#DDB73E" d="M403.5,7.9c-0.3,0-0.4-0.1-0.5-0.4c-0.2-0.4-0.5-0.6-0.6,0.1c0,0.2-0.1,0.3-0.3,0.2
				c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.6,0.5-0.9,0.5-1.4c0.4,0.4,0.8,0.8,1.2,1.2C403.6,7.6,403.6,7.7,403.5,7.9z"/>
			<path fill="#E1C46B" d="M413.3,24c-0.2,0.1-0.4,0.2-0.5,0.3c-0.4,0.3-0.7,0-0.9-0.3c-0.2-0.4,0.1-0.6,0.5-0.7
				c0.2,0,0.5-0.1,0.7-0.2C413.4,23.3,413.3,23.7,413.3,24z"/>
			<path fill="#DEBC4D" d="M389.4,0.7c-0.8,0-1.5,0.2-1,1.2c-0.2,0.2-0.3,0.1-0.5,0c-0.2,0-0.3,0-0.5,0c-0.2-0.2-0.2-0.3-0.2-0.6
				c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0-0.2-0.1-0.2c-0.1,0-0.3-0.1-0.4-0.2c0-0.1,0-0.2,0.1-0.3C387.8,0.1,388.6,0.4,389.4,0.7z"/>
			<path fill="#E0BF5B" d="M419.8,77.8c0-0.1,0-0.2,0-0.3c0.7,0.1,1.3-0.9,2,0c0.1,0.1,0.2,0.3,0.2,0.5c-0.2,0.4-0.8,0.1-1,0.5
				C420.5,78.3,420,78.3,419.8,77.8z"/>
			<path fill="#E1C571" d="M396.3,3.6c-0.4,0.5-0.7,0.1-1-0.1c-0.3-0.3-0.6-0.6-1-0.8c-0.2-0.2-0.4-0.4-0.2-0.8
				c0.4,0.1,0.8,0.2,1.2,0.5C395.8,2.7,396,3.1,396.3,3.6L396.3,3.6z"/>
			<path fill="#E2C671" d="M394.1,1.9c0.1,0.3,0.2,0.5,0.2,0.8c-0.5,0.3-1.1-0.7-1.5,0.2c-0.3-0.5,0.2-0.9,0.1-1.3
				C393.3,1.5,393.8,1.6,394.1,1.9z"/>
			<path fill="#E2C774" d="M429.8,57.8c-0.4-0.1-0.8,0-0.9,0.5c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2-0.3-0.2-0.5-0.1-0.8
				c0-0.3,0.1-0.6,0.5-0.7c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0,0.4-0.4,0.7-0.3C429.8,57.2,429.8,57.5,429.8,57.8z"/>
			<path fill="#DFBE54" d="M398.2,5c-0.1-0.5-0.2-0.9,0.3-1.2c0.3,0.1,0.6,0.1,0.7,0.4c0,0.3,0.4,0.8-0.3,0.8
				C398.6,5.2,398.4,5.3,398.2,5z"/>
			<path fill="#E2C572" d="M418.8,51.1c-0.6-0.1-1.1-0.1-1.7-0.2c0.1-0.2,0.1-0.3,0.2-0.5C418.3,49.8,418.7,50.2,418.8,51.1
				L418.8,51.1z"/>
			<path fill="#DEBA4A" d="M416.6,54.4c0.1,0.5,0.6,0.7,0.9,1.2c-0.4,0.2-0.8,0.3-1.2,0.2C416.2,55.4,416.4,54.9,416.6,54.4z"/>
			<path fill="#E1C46C" d="M430.1,53.5c-0.1-0.6-1-0.7-0.7-1.5l0,0c1.2,0.6,0.4-0.9,1-1C430.2,51.9,430.5,52.8,430.1,53.5z"/>
			<path fill="#DEBA4A" d="M416.9,53.5c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.3,0.8,0.5,0.5,0.9c-0.3,0.4-0.7,0-1-0.2
				C416.6,54.1,416.7,53.8,416.9,53.5z"/>
			<path fill="#DCB535" d="M396.3,3.6c-0.7-0.1-0.7-0.8-1-1.3c0.4,0.1,0.9,0.2,1.2,0.5C396.7,3.2,396.5,3.4,396.3,3.6z"/>
			<path fill="#DFBD54" d="M391,1.4c-0.5,0-0.8-0.4-1.2-0.7c0.6,0,1.2,0.1,1.7,0.3C391.5,1.6,391.3,1.6,391,1.4z"/>
			<path fill="#DFBD54" d="M413.5,25.5c-0.4,0.1-0.4,0.5-0.6,1c-0.4-0.8-0.1-1.2,0.5-1.5C413.6,25,413.6,25.2,413.5,25.5z"/>
			<path fill="#E1BF51" d="M391,1.4c0.2,0,0.4-0.1,0.5-0.3c0.3-0.1,0.6-0.1,0.7,0.3c0,0.1,0,0.2,0,0.3c-0.2,0.3-0.5,0.5-0.9,0.4
				C391.1,2,391,1.9,391,1.7C391,1.6,391,1.5,391,1.4z"/>
			<path fill="#DCB432" d="M410.2,15.4c-0.3,0.1-0.6,0.4-0.9,0c0.1-0.3,0.4-0.5,0.7-0.7C410.1,14.9,410.3,15.1,410.2,15.4z"/>
			<path fill="#DBB32E" d="M405.7,9.3c-0.4,0.4-0.8,0.4-1.2,0c0.2-0.4,0.4-0.7,0.9-0.5C405.5,9,405.6,9.2,405.7,9.3z"/>
			<path fill="#E2C674" d="M405.3,8.9c-0.4,0-0.6,0.3-0.9,0.5c0,0,0,0,0,0c-0.2-0.2-0.6,0.1-0.7-0.3c0.3-0.3,0.7-0.3,0.9-0.7
				C404.9,8.6,405.1,8.7,405.3,8.9z"/>
			<path fill="#DDB944" d="M403.5,7.9c0-0.2,0-0.3,0-0.5c0.2,0.2,0.5,0.3,0.7,0.5c-0.1,0.2-0.2,0.4-0.5,0.4
				C403.5,8.3,403.5,8.1,403.5,7.9z"/>
			<path fill="#DEBA48" d="M386.9,0.5c0,0.1,0,0.2,0,0.3c0.2,0.2,0.3,0.5,0.1,0.8c0,0-0.1,0.1-0.1,0.1c-0.2-0.1-0.3-0.5-0.7-0.5
				c-0.1-0.1-0.1-0.2-0.1-0.3c0.1-0.3,0.3-0.3,0.6-0.4c0,0,0-0.1,0-0.1C386.7,0.4,386.8,0.4,386.9,0.5z"/>
			<path fill="#DDB63A" d="M401.1,5.5c-0.2,0.3-0.4,0.4-0.6,0.1c-0.1-0.2,0.2-0.2,0.4-0.3C400.9,5.3,401,5.4,401.1,5.5z"/>
			<path fill="#DBB22C" d="M429.8,56.9c-0.3,0.1-0.3,0.7-0.7,0.3c0.1-0.3,0.4-0.5,0.8-0.5C429.9,56.7,429.9,56.8,429.8,56.9z"/>
			<path fill="#E2C674" d="M400.1,15.8c-0.2-0.9-0.6,0-0.8-0.1c-0.7-0.3-1.3-0.7-2.1-0.8c-0.4-0.1,0-0.5,0.1-0.8
				c0.6-1.2,0.7-1.2,1.6-0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.3,0.4,0.5,0.8,0.4c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-0.4-0.4-0.6-0.8-0.6
				c-0.3,0-0.6,0.2-0.8,0.1c-0.4-0.2-0.6-0.6-0.4-1.1c0.1-0.4,0.4-0.7-0.3-1c-0.7-0.4-0.9-1.2-1.2-1.9c-0.2-0.4-0.2,0-0.3-0.1
				c-0.6-0.1-1.1-0.1-1.1-0.9c0.3-0.6,0.8-0.4,1.3-0.2c0.4,0.3,0.8,0.5,1.4,0.4c0.5-0.2,0.9,0.1,1.1,0.6c0.2,0.1-0.4,0.4,0.1,0.4
				c0.4,0,0.8,0.1,1.2,0.3c0.2,0.1-0.1,0.5,0.4,0.4c0.5-0.1,1,0.2,1.3,0.7c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.2,0.3,0.4,0.2,0.6
				c-0.1,0.7-0.8,0.3-1.1,0.6c-0.3-0.1-0.4-0.4-0.5-0.6c-0.2-0.4-0.6-0.5-1-0.2c0.7-0.1,1,0.4,1.1,1c0,0.7,0.6,0.9,1,1.2
				c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.6,1.6,0.4,1,1.5c-0.4,0.1-0.8,0.2-1.1,0.5c-0.7,0.1-1.3-0.5-2-0.1
				C400.3,16,400.2,15.9,400.1,15.8z"/>
			<path fill="#E1C36B" d="M391.2,1.9c0.4,0,0.7-0.1,1-0.2c0.1,0.7-0.1,1.3-0.2,2c0.1,0,2.4,0.2,2.4,0.2c0.2,0.6,1.1,1.1,0.5,1.9
				c-0.2,0.4-0.4,0.5-0.8,0.5c-0.7-0.1-0.8-0.7-0.8-1.2c0-0.1,0-0.3-0.2-0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.6,0.6-1,0.4
				c-0.4-0.1-0.6,0-0.7,0.4C391,5.9,390.9,6,390.7,6c-0.3-0.4-0.7-0.7-0.7-1.2c0.9-0.1,1.3-1,1.2-2.4C391.1,2.2,391.1,2.1,391.2,1.9
				z"/>
			<path fill="#DDB73E" d="M401.6,20.6c-0.3-0.7-0.3-0.7,0.9-0.7c0,0,0.1,0,0.1,0c0.3,0,0.6,0.1,0.9,0.1c0.1,0,0.2,0,0.3,0.1
				c0.3,0.2,1-0.3,1,0.6c-0.1,0.3-0.3,0.4-0.4,0.7c-0.7,0.6,0,1.2,0,1.7c-0.6,0.1-1.1,0.2-1.5,0.7c-0.2-0.2-0.5-0.3-0.7-0.5
				c0-0.2,0-0.3,0-0.5c-0.1-0.5,0.9-0.9,0-1.4c0-0.4-0.3-0.5-0.5-0.7C401.7,20.5,401.7,20.5,401.6,20.6z"/>
			<path fill="#E0C268" d="M396.7,16.8c-0.2,0.6-0.2,1.4-1.2,1.3c-0.7-0.1-1-0.6-1-1.4c0-0.2-0.7-0.8-1.3-0.8
				c-0.2,0-0.5,0.1-0.5-0.2c0-0.4,0.4-0.3,0.6-0.3c0.7,0.1,1.4,0.3,2,0.8C395.8,16.4,396.1,16.9,396.7,16.8L396.7,16.8z"/>
			<path fill="#E1C572" d="M410.9,19c0,0.1,0,0.2,0,0.2c0.1,0.4-0.1,0.5-0.5,0.5c-0.5,0.7-0.5,0.7,0.1,1.6c0.5,0.7,0.5,0.7-0.2,1.3
				c0.2,0.3,0.7,0.1,0.8,0.6c-0.2,0.3-0.6,0.1-0.8,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.3-0.2c-1.2-0.8-0.6-1.9-0.7-2.9
				C409.5,18.7,409.8,18.5,410.9,19L410.9,19z"/>
			<path fill="#DBB22C" d="M398.2,12.2c0,0.7,0.1,1.1,0.9,0.8c0.6-0.2,1.1,0.2,1.3,0.8c0.1,0.4,0.1,0.9-0.4,1
				c-0.6,0.2-1,0.1-1.3-0.6c-0.4-1.1-1-0.7-1.5,0c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0.1-0.1,0,0,0.1-0.1
				c0.2-0.2,0.8-0.3,0.1-0.6c0-0.1,0-0.2,0.1-0.3c0.2-0.3,0.5-0.4,0.9-0.3C398.1,12.2,398.1,12.2,398.2,12.2z"/>
			<path fill="#E1C46D" d="M428.9,62.6c0.2,0.6-0.5,0.8-0.7,1.2c-0.5-1.6-1-0.2-1.4,0c-0.1,0-0.1,0-0.2-0.1
				c-0.1-0.1-0.2-0.3-0.3-0.3c-0.1,0-0.1,0.3-0.1,0.4c0,0-0.1,0.1-0.1,0.1c-0.4,0-0.8,0-1-0.5c-0.1-0.4-0.2-0.7,0.2-0.9
				c0.3,0,0.6-0.1,0.9-0.3c0.1-0.1,0.5-0.2,0.5-0.2c0.8,1.4,0.8-0.5,1.3-0.3C428.3,62.1,428.6,62.4,428.9,62.6z"/>
			<path fill="#E0C267" d="M427.5,74.4c0-0.4,0.4-0.6,0.7-0.9c0.2-0.3,0.9-0.3,0.8,0c-0.1,0.9,0.7,0.3,0.9,0.7l0,0
				c-0.4,0.6-1.2,1.1-1.1,2c-0.8-0.1-0.6-1-1.1-1.3C427.5,74.7,427.4,74.6,427.5,74.4z"/>
			<path fill="#E1C571" d="M397.7,21.4c-0.2-0.5-0.2-1.2-1.1-1.1c-0.3,0-0.4-0.3-0.3-0.5c0.2-0.3,0-0.8,0.4-0.9
				c0.3-0.1,0.3,0.3,0.4,0.5c0.5,0.7,1.2,0.1,1.8,0.2c0.6,0.2,0.9,0.4,0.2,0.9l0,0c-0.5-0.2-0.2,0.4-0.5,0.5c-0.3,0-0.6,0.2-0.7,0.5
				C397.9,21.5,397.8,21.5,397.7,21.4z"/>
			<path fill="#DDB840" d="M427.2,75.1c-0.2,0.2-0.5,0.3-0.7,0.5l0,0c-0.2,0.3-0.3,0.6-0.5,1c-0.2,0.6-0.6,0.8-1.2,0.7
				c-0.2,0-0.3,0-0.5,0c-0.2-0.1-0.4-0.2-0.3-0.5c0.1-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7,0.1,0.7-0.5c0.2-0.1,0.2-0.3,0.2-0.5
				c0-0.2,0.1-0.3,0.3-0.3c0.6-0.6,0.4-1.4,0.7-2.1c0.3-0.1,0.4,0,0.5,0.2c-0.1,0.4-0.3,0.8,0,1.2C426.6,74.9,427.1,74.9,427.2,75.1
				z"/>
			<path fill="#DCB63A" d="M407.5,23.3c-0.2,0.4-0.9,0.4-1,1c-0.6-0.4-1.1-0.8-1.7-1.2c0-0.8,0.9-0.5,1.1-1c0.1-0.1,0.2-0.1,0.3-0.2
				c0.5-0.1,0.9-0.1,1.4-0.4c0.4,0.1,0.5,0.4,0.6,0.8C408.2,22.8,408,23.1,407.5,23.3z"/>
			<path fill="#E1C572" d="M393.1,12c0-0.2-0.4-0.5,0-0.7c0.9,0.1,1.7,0,2.3-0.6c0.1,0,0.2,0,0.3,0c0.2,0.4,0.5,0.4,0.9,0.3
				c0.1,0.1,0.1,0.2,0.1,0.3c-0.2,0.9-1.2,1-1.6,1.7c-0.2,0.3-0.3-0.3-0.5-0.4c-0.2-0.4-0.6-0.2-1-0.2c0,0-0.1,0-0.1,0
				c-0.1,0-0.3,0-0.4-0.1C393.2,12.1,393.2,12.1,393.1,12z"/>
			<path fill="#E1C570" d="M409.7,24.5c0.1,0.3,0.7,0.3,0.6,0.9c-0.5-0.1-0.9,0.1-1.1,0.6c-0.1,0.3-0.5,0.6-0.8,0.3
				c-0.5-0.5-1.3-0.2-1.6-0.9c0-0.1,0-0.2,0.1-0.3c0.2-0.2,0.4-0.2,0.7,0c1.1,0.8,1.4-0.1,1.9-0.8C409.7,24.2,409.8,24.2,409.7,24.5
				z"/>
			<path fill="#E1C570" d="M425.3,54.5c-0.3,0-0.7-0.1-0.6-0.6c0-0.2,0-0.4,0-0.6c-0.1-0.4-1-0.3-0.6-0.8c0.3-0.4,0.9,0,1.3-0.1
				c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.4,0.2,0.5,0.5c-0.2,0.1,0.8,0.9-0.4,0.6C425.4,53.5,425.6,54.2,425.3,54.5L425.3,54.5z"/>
			<path fill="#E0C265" d="M425.5,61c-0.1,0.3,0.4,0.6,0.1,1.1c-0.5-0.6-1.5-0.4-1.7-1.4c0-0.2-0.1-0.5,0.1-0.7
				c0.2-0.2,0.5-0.2,0.6,0c0.4,0.5,1,0.2,1.4,0.5C426,60.7,425.7,60.8,425.5,61z"/>
			<path fill="#E1C36B" d="M426.5,52.3c0-0.4,0.1-0.4,0.4-0.4c0.5,0,0.9-0.1,1.4-0.2c0.3,0.4-0.4,0.6-0.1,1c0.1,0.3,0.8,0.4,0.3,0.8
				c-0.1,0.1-0.9,0.3-1.2-0.2C427.1,52.8,426.5,52.8,426.5,52.3z"/>
			<path fill="#E1C570" d="M397,13.4c0,0.2,0,0.3,0,0.5c-0.5,0.2-0.4,0.7-0.8,1.1c0-1.1-1.1-1.2-1.6-1.8c0.8,0,1.3-1.3,2.2-0.4
				C396.9,13,396.9,13.2,397,13.4z"/>
			<path fill="#DFBD53" d="M399.1,20.4c0.3-0.4,0.1-0.7-0.2-0.9c0.2-0.6,1.4-1,1.8-0.7c0.3,0.3-0.2,1.3-0.8,1.5
				C399.7,20.4,399.4,20.4,399.1,20.4z"/>
			<path fill="#E1C36A" d="M423.5,72.6c-0.2-0.8,0.5-1.7-0.7-1.7c0.1-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.5-0.2,0.7-0.2
				c0.3,0,0.4,0.3,0.4,0.5c-0.2,0.5,0,0.8,0.2,1.2C424.7,72.2,423.8,72,423.5,72.6z"/>
			<path fill="#E1C268" d="M427.6,67.5c0.1,0.5,0.1,1.1-0.3,1.2c-0.4,0.1-0.5-0.5-0.5-0.9c0-0.4-0.9-0.6-0.3-1.1
				c0.3-0.2,0.6-0.2,0.9,0C427.8,66.9,427.7,67.3,427.6,67.5z"/>
			<path fill="#E1C673" d="M421.9,78c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.3-0.1-0.7-0.6-0.7c-0.2,0-0.2-0.3-0.2-0.5
				c0.1-0.2,0.4-0.1,0.6-0.3c0.7-0.4,1.2-0.3,1.5,0.5C423.4,77.9,422.3,77.7,421.9,78z"/>
			<path fill="#E1C46C" d="M404.9,23c0.8,0.1,1.5,0.2,1.7,1.2c-0.1,0.8-0.9,0.1-1.2,0.5l0,0c-0.1-0.6-1.2-0.8-0.7-1.7
				C404.8,23,404.8,23,404.9,23z"/>
			<path fill="#E1C36A" d="M424.8,42.7c0,0.5-1.6,1.5-2,1.3c-0.2-0.1-0.4-0.6-0.2-0.7c0.8-0.1,1-0.8,1.5-1.2
				C424.3,41.9,424.6,42.4,424.8,42.7z"/>
			<path fill="#DDB842" d="M396.7,13c-0.3-0.1-0.5-0.6-0.8-0.2c-0.4,0.7-1,0.3-1.4,0.5c-0.5-0.4,0.2-0.5,0.3-0.7
				c0.2,0,0.5,0.5,0.5,0.3c0-1,1.1-0.8,1.4-1.5c0.3-0.1,0.4,0,0.6,0.2c0.2,0.3-0.2,0.7-0.1,1c0,0.1,0,0.2,0,0.2
				C397.1,12.9,396.9,13,396.7,13z"/>
			<path fill="#E2C674" d="M390.7,6c0.1-0.1,0.2-0.2,0.3-0.2c0.8-0.5,1.4-0.1,1.8,0.6c0,0.6-0.5,0.6-0.9,0.7c-0.1,0-0.2,0-0.3,0
				c-0.5-0.3-0.8,0.5-1.3,0.3c0-0.1,0-0.1,0-0.2c-0.1-0.2-0.1-0.3,0-0.5C390.4,6.5,390.8,6.4,390.7,6z"/>
			<path fill="#DCB330" d="M390.3,7.4c0.3-0.1,0.4-0.3,0.5-0.6c0.3-0.5,0.5-0.2,0.7,0.1c0.2,0.4,0.3,0.7-0.1,1
				c-0.5,0.2-0.2,0.6-0.3,0.9c-0.1,0.3-0.4,0.3-0.6,0.5C390.2,8.7,389.9,8.1,390.3,7.4z"/>
			<path fill="#DDB841" d="M395.5,10.8c-0.6,1-1.4,1-2.4,0.5c0,0,0,0,0,0c0.3-0.2,0.3-0.5,0-0.7c-0.1-0.1-0.2-0.2-0.2-0.2
				c0-0.1,0-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.2c0.5-0.2,0.9,0.1,1.2,0.5c0.1,0,0.2,0,0.3,0C394.9,10.5,395.4,10.1,395.5,10.8z"/>
			<path fill="#DFC05E" d="M393.1,12c0.1,0,0.2,0,0.3,0c0.1,0.3-0.2,0.4-0.3,0.6c0.3,0.1,0.4-0.5,0.8-0.3c0,0,0,0,0,0
				c-0.1,0.4,0.2,1.1-0.3,1.1c-0.4,0-0.4,0.8-0.9,0.3c-0.4-0.4-0.9-0.8-0.8-1.3C392,11.8,392.7,12,393.1,12z"/>
			<path fill="#DFBE59" d="M388.5,11.6c0.7-0.3,1.1-1.5,2.3-1.1C390.2,11.4,389.7,12.2,388.5,11.6z"/>
			<path fill="#E1C36B" d="M419.6,41.9c-0.2-0.6-1-1.1-0.1-1.7c0.2-0.1,0.5-0.5,0.7-0.1C420.5,40.7,420.3,41.3,419.6,41.9z"/>
			<path fill="#E0C267" d="M427,59c-0.3,0.2-0.9-0.1-0.9,0.9c-0.3-0.7-0.5-1.1-0.9-1.8c0.7,0.4,1.5-0.7,2,0.4
				C427.1,58.7,427.2,58.9,427,59z"/>
			<path fill="#E1C36B" d="M404.9,23c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.6-0.6-1-1.1-0.3-1.9c0.4,0.8,1.2,0.8,1.9,0.9
				C406.2,23,405,22.4,404.9,23z"/>
			<path fill="#DFBD55" d="M402.3,19.4c-1-0.4-1.2-1.3-1.4-2.2c0.1,0,0.1,0,0.2-0.1c0.3,0,0.6,0.2,0.7,0.4c0.3,0.6,0.7,0.4,1.2,0.4
				c0.2,0.4,0.2,0.8,0.3,1.2c0,0.2-0.1,0.3-0.3,0.4C402.6,19.6,402.4,19.6,402.3,19.4z"/>
			<path fill="#E0C267" d="M430.3,72.2c-0.2-0.2-0.8,0.1-0.6-0.4c0.1-0.3,0.1-1,0.7-0.8c0.2,0.1,0.9-0.1,0.8,0.6
				C431.1,72,430.8,72.2,430.3,72.2z"/>
			<path fill="#E1C570" d="M427,42.2c-0.3-0.2-0.8,0.5-0.9-0.2c-0.1-0.4-0.5-0.9,0.1-1.1c0.7-0.2,0.9,0.2,0.9,0.8
				C427.4,42,427.4,42.2,427,42.2z"/>
			<path fill="#DEBB4E" d="M420.8,55.4c-0.1,0.4-0.2,1-0.4,0.9c-0.5-0.2-1,0-1.5-0.1c-0.2,0-0.3-0.4-0.3-0.6
				c0.1-0.3,0.4-0.3,0.6-0.2C419.8,55.7,420.3,55.7,420.8,55.4z"/>
			<path fill="#E1C571" d="M424.1,38.4c0.7-0.3,1.2,0.1,1.8,0.4c-1,0.6-1.5,0.6-2.1-0.2C423.8,38.4,423.9,38.3,424.1,38.4z"/>
			<path fill="#E0C369" d="M420,53c-0.2,0-0.5,0-0.7,0c-0.1-0.2-0.1-0.3,0-0.5c0.7-0.3,0.3-0.6,0-1c0-0.2,0.1-0.4,0.2-0.5
				c0.5,0.6,0.6-0.2,1-0.2c0.2,0.2,0.2,0.5,0.5,0.7c-0.2,0.2-0.5,0.3-0.7,0.5c0.1,0.3-0.1,0.4-0.2,0.5C420,52.7,420,52.9,420,53
				C420,53,420,53,420,53z"/>
			<path fill="#E1C46E" d="M407.5,23.3c0.2-0.3,0.3-0.6,0.5-1c0.6,0.3,1.4,0.5,1.4,1.4c0,0.1-0.1,0.2-0.2,0.3
				c-0.3,0-0.5-0.5-0.7-0.3C407.7,24.6,407.8,23.5,407.5,23.3z"/>
			<path fill="#E1C36B" d="M426.7,36.7c0.5,0.8,1.4,0.4,1.6,1.1c0.1,0.2-0.2,0.6-0.5,0.5C427.2,38.2,426.7,37.9,426.7,36.7z"/>
			<path fill="#DDB843" d="M390,3.6c-0.1,0.4-0.1,0.8,0,1.2c-1-0.1-1.1-0.9-1.2-1.7c0,0,0.1-0.1,0.1-0.1C389.5,2.8,389.9,3,390,3.6z
				"/>
			<path fill="#E1C572" d="M424.8,77.3c0.4-0.2,0.8-0.5,1.2-0.7c0.2,0.4,0.7,0.8,0.1,1.2C425.5,78.2,425.2,77.7,424.8,77.3z"/>
			<path fill="#E0C267" d="M427.2,47c-0.4,1.1-1.1,0.3-1.7,0.3c-0.2,0-0.3-0.5-0.1-0.7c0.2-0.2,0.4-0.2,0.6,0
				C426.4,46.9,426.8,47,427.2,47L427.2,47z"/>
			<path fill="#E1C369" d="M426.8,73.2c-0.2-0.1-0.3-0.2-0.5-0.2c-0.5-0.1-0.6-0.4-0.3-0.7c0.2-0.3,0.5-0.2,0.7,0
				c0.4,0.3,0.7,0.8,0.9,1.3C427.2,73.6,427.1,73.1,426.8,73.2z"/>
			<path fill="#DFBF5B" d="M428.3,67.8c1.4-0.5,1.4-0.5,1.2,1.2C429,68.7,428.5,68.4,428.3,67.8z"/>
			<path fill="#DEBC50" d="M424.1,38.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.9-1.2-0.6-1.5-1.4C423.3,37.1,424.2,36.9,424.1,38.4z"/>
			<path fill="#E1C46C" d="M422.2,40.3c0.3-0.1,0.5-0.2,0.8-0.2c0.3,0,0.6-0.2,0.7,0.2c0.2,0.3-0.2,0.5-0.3,0.7
				c-0.2,0.3-0.4,0.2-0.7,0c-0.2-0.2-0.5-0.3-0.8-0.4C422,40.4,422,40.3,422.2,40.3z"/>
			<path fill="#E0C163" d="M386,6.5c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.2-0.4,0.7-0.7,0.4c-0.5-0.4,0.3-0.3,0.4-0.6
				c0.2-0.6,0.2-1.2,0.8-1.6C386.3,5.6,385.8,6,386,6.5L386,6.5z"/>
			<path fill="#E0C369" d="M425.5,61c0.1-0.2,0.3-0.4,0.5-0.5c0.6-0.5,1.2-0.2,1.9,0.1C427.2,61.4,426.3,60.7,425.5,61z"/>
			<path fill="#E0C061" d="M422.7,74.9c-0.2,0-0.7,0.4-0.6,0c0.2-0.6-0.5-0.8-0.4-1.4c0.5,0.3,1.1,0.2,1.9,0.3
				c-0.5,0.3-0.9,0.4-0.9,0.9C422.8,74.7,422.8,74.7,422.7,74.9C422.7,74.8,422.7,74.9,422.7,74.9z"/>
			<path fill="#DFBE57" d="M421.7,58.6c-0.3,0-0.7,0.8-0.7-0.2c0-0.2-0.1-0.4-0.1-0.6c0-0.2-0.7-0.2-0.1-0.6
				c0.3-0.2,0.6-0.3,0.8,0.1c0.1,0.2,0.3,0.4,0.5,0.6C421.9,58.3,421.9,58.5,421.7,58.6z"/>
			<path fill="#E0C267" d="M430.4,75.1c0-0.4,0-0.8-0.5-0.9c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.4-0.2c0.6-0.1,1.1,0.1,1.1,0.8
				C431.4,75.4,430.7,75.1,430.4,75.1L430.4,75.1z"/>
			<path fill="#E1C571" d="M424.1,76.8c0.1,0.2,0.2,0.3,0.3,0.5c0,0,0,0.1,0,0.1c-0.3,0.4,0.2,1-0.4,1.2c-0.2,0.1-0.5-0.1-0.4-0.2
				C423.7,77.9,423.3,77.1,424.1,76.8z"/>
			<path fill="#E2C674" d="M428.4,55c-0.1,0.5,0.5,1.1-0.2,1.4l0,0c-0.2-0.1-0.2-0.4-0.5-0.5c-0.2-0.2-0.5-0.4-0.6-0.8
				C427.5,54.8,428,55,428.4,55z"/>
			<path fill="#E1C46D" d="M421.7,58.6c0-0.2,0.1-0.4,0.3-0.5c0.1,0.1,0.3,0.2,0.4,0.2c0.3,0.2,0.7,0.3,0.7,0.7
				c0,0.3-0.3,0.3-0.6,0.3C422,59.3,421.7,59.2,421.7,58.6z"/>
			<path fill="#DFBE5A" d="M428.4,64.1c0,0.1,0,0.2,0,0.2c-0.3,0.3-0.5,0.6-0.4,1c0,0.2,0,0.5-0.3,0.5c-0.2,0-0.5,0-0.6-0.3
				c-0.1-0.2-0.2-0.5,0.1-0.6C427.7,64.7,427.8,64.1,428.4,64.1z"/>
			<path fill="#DCB536" d="M419.3,73.4c-0.2-0.1-0.3-0.3-0.3-0.5c0.1-0.1,0.2-0.1,0.3-0.1c0.5-0.1,1-0.1,1.5-0.2c0,0,0.1,0.3,0,0.3
				C420.4,73.3,420,73.6,419.3,73.4z"/>
			<path fill="#E0C163" d="M400.3,22.1c-1.1,0.3,0.1-0.8-0.5-0.8c-0.1,0,0.1-0.4,0.3-0.3c0.5,0.1,0.9,0.6,0.9,1
				C401,22.4,400.6,22.2,400.3,22.1z"/>
			<path fill="#E1C36A" d="M423,51.6c0.4,0,0.6,0.2,0.6,0.6c-0.1,0.3-0.2,0.7-0.6,0.6c-0.4-0.1-0.6-0.4-0.6-0.8
				C422.3,51.6,422.7,51.6,423,51.6z"/>
			<path fill="#E0C163" d="M429.1,70.1c0.4,1.5-0.9,0.9-1.3,1.3c0,0-0.2-0.1-0.2-0.2c-0.1-0.2,0.1-0.3,0.2-0.3
				C428.3,70.8,428.6,70.4,429.1,70.1z"/>
			<path fill="#E2C87A" d="M390.5,9.4c0-0.3,0.1-0.5,0.5-0.5c0.7,0.3,1.2,0.9,1.5,1.5c-0.1,0.3-0.3,0.3-0.6,0.2
				C391.5,10.2,391,9.8,390.5,9.4z"/>
			<path fill="#E1C369" d="M424.3,49.8c-0.7-0.1-1.3-0.1-1.5-0.8c-0.1-0.2,0-0.3,0.2-0.3C423.9,48.6,424,49.2,424.3,49.8z"/>
			<path fill="#E1C571" d="M390.3,6.7c0,0.2,0,0.3,0,0.5c-0.1,0-0.3-0.1-0.3-0.1c-0.4,0.3-0.7,0.2-0.9-0.1c-0.3-0.4,0.3-0.4,0.5-0.6
				c0.1-0.2,0.3-0.3,0.4-0.5C390.5,6,389.8,6.6,390.3,6.7z"/>
			<path fill="#E0C05F" d="M410.4,19.7c0.2-0.2,0.3-0.3,0.5-0.5c0.3,0.4,0.4,0.9,0.9,1.2C410.7,20.8,410.6,20.7,410.4,19.7z"/>
			<path fill="#E0C162" d="M422.6,62.4c-0.5-0.1-1,0.7-1.4,0c-0.1-0.2-0.2-0.5,0-0.5C421.7,61.7,422.2,62,422.6,62.4z"/>
			<path fill="#DEBA4B" d="M381.9,9.2c-0.9,0-1.2-0.6-1.8-0.7C380.6,8,380.6,8,381.9,9.2z"/>
			<path fill="#E0C269" d="M422.2,46.6c-0.2,0.3-0.4,0.7-0.8,1c-0.1,0.1-0.4-0.1-0.3-0.3c0.2-0.4-0.1-1,0.6-1
				C421.9,46.2,422.2,46.1,422.2,46.6z"/>
			<path fill="#DFBD54" d="M391.9,10.6c0.2,0,0.4-0.1,0.5-0.3c0.2-0.1,0.3-0.1,0.5,0c0,0,0,0,0,0c-0.2,0.4-0.5,0.7-0.7,1.1
				c-0.2,0.2-0.4,0.5-0.7,0.1c-0.3-0.4,0-0.5,0.4-0.6C391.9,10.9,391.9,10.7,391.9,10.6z"/>
			<path fill="#E0C164" d="M412,25.3c0,0.3-0.1,0.5-0.4,0.5c-0.8-0.1,0.1-0.9-0.5-1.2c-0.1,0,0-0.4,0.2-0.3
				C412,24.4,411.8,25,412,25.3z"/>
			<path fill="#E1C570" d="M402.3,23.3c0.5-0.3,0.7,0,0.7,0.5c-0.3,0.2-0.2,0.6-0.4,0.9C402,24.3,402,23.8,402.3,23.3z"/>
			<path fill="#E0BF5E" d="M422.7,74.9c0-0.1,0-0.2,0-0.2c0.4-0.1,0.8,0,1,0.3c0.2,0.2,0.4,0.4,0.3,0.7c-0.1,0.1-0.3,0.3-0.5,0.3
				c-0.4,0-0.3-0.3-0.3-0.6C423.3,74.9,422.9,74.9,422.7,74.9z"/>
			<path fill="#DFC05E" d="M381.4,1.1c1.4-0.2,1.4-0.2,0.9,1C382.1,1.8,381.8,1.5,381.4,1.1z"/>
			<path fill="#E1C36C" d="M396.7,25.3c0.1-0.4-0.2-1.1,0.5-1.3c0.1,0,0.3,0.1,0.3,0.1C397.6,24.7,397.3,25.1,396.7,25.3z"/>
			<path fill="#E2C572" d="M402.3,21.4c0.3-0.2,0.5-0.2,0.7,0c0.3,0.3,0,0.5-0.2,0.6c-0.3,0.2,0.1,0.8-0.5,0.7
				c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0.1-0.2,0.2-0.2C402.4,21.8,402.4,21.6,402.3,21.4z"/>
			<path fill="#DEBC53" d="M397.7,21.4c0.1,0,0.2,0,0.2,0c-0.4,0.6,0.4,0.4,0.5,0.7l0,0c-0.3,0.1-0.6,0.6-1,0.1
				c-0.1-0.2-0.6-0.2-0.5-0.6C397.1,21.3,397.4,21.4,397.7,21.4z"/>
			<path fill="#E1C369" d="M385.9,6.5c0.3-0.2,0.9,0,0.9-0.8c0.4,0.5,0.6,1,0.2,1.3C387,7,386.1,7.2,385.9,6.5
				C386,6.5,385.9,6.5,385.9,6.5z"/>
			<path fill="#E0C267" d="M400.1,15.8c0.1,0,0.1,0,0.2,0c0.4,0.3,0.9,0.5,0.8,1.1c-0.1,0.2-0.2,0.2-0.4,0.3c0,0,0,0,0,0
				C399.7,17.2,399.8,16.6,400.1,15.8z"/>
			<path fill="#E0C164" d="M417.9,60c-0.6-0.8-0.3-1.3,0.1-1.6c0.1-0.1,0.3,0,0.4,0.2C418.9,59.4,417.8,59.3,417.9,60z"/>
			<path fill="#E0C165" d="M402.3,25.4c0.4,0.3,1,0.4,0.6,1c-0.1,0.2-0.4,0.3-0.6,0.2C401.8,26.2,402.5,25.8,402.3,25.4z"/>
			<path fill="#E1C56F" d="M396.7,22.8c-0.4,0.5-0.2,1.1-0.8,1.2c-0.2,0-0.4-0.2-0.3-0.4C395.7,23.1,396,22.8,396.7,22.8z"/>
			<path fill="#DFBF5A" d="M383.5,5.3c-0.2,0.6,0.3,1-0.3,1.3c-0.2,0.1-0.4-0.1-0.4-0.3C382.5,5.8,382.8,5.6,383.5,5.3z"/>
			<path fill="#E1C46F" d="M427.2,75.1c-0.3-0.1-0.7,0.1-1-0.1c0-0.4,0.4-0.3,0.5-0.6c0.2,0,0.5,0,0.7,0c0.2,0.1,0.2,0.3,0.2,0.5
				C427.5,75,427.4,75,427.2,75.1z"/>
			<path fill="#E0BF5D" d="M430.3,75.1c-0.1,0.4,1.7,0.9-0.1,1.3C430.3,76,429.7,75.6,430.3,75.1C430.4,75.1,430.3,75.1,430.3,75.1z
				"/>
			<path fill="#E1C571" d="M425.3,54.5c0.1,0,0.2,0,0.2,0c0.4,0,0.9-0.1,1.1,0.4c0.1,0.1-0.1,0.3-0.3,0.3
				C425.9,55.2,425.3,55.2,425.3,54.5C425.3,54.5,425.3,54.5,425.3,54.5z"/>
			<path fill="#E0C163" d="M386.2,3.3c-1.2,0-1.2,0-1.2-1.3C385.5,2.3,385.4,3.2,386.2,3.3z"/>
			<path fill="#E0C061" d="M419.3,70.6c0.7,0.1,0.7,0.6,0.7,1.3C419.3,71.6,418.8,71.4,419.3,70.6L419.3,70.6z"/>
			<path fill="#E1C570" d="M420.1,66.6c-0.1-0.6-0.3-1.1,0.1-1.4c0.1-0.1,0.5,0.1,0.5,0.2C420.9,65.8,420.6,66.1,420.1,66.6z"/>
			<path fill="#DEBC51" d="M427,59c0-0.2,0-0.4,0.2-0.5c0.3,0.3,0.3-0.3,0.5-0.2c0.2-0.2,0.2-0.5,0.5-0.7c0.1,0,0.2,0,0.2,0
				c0.1,0.2,0.2,0.5,0,0.7c0.1,0.2,0,0.4-0.2,0.6c-0.3,0.1-0.4,0.6-0.8,0.4C427.4,59.1,427.2,59,427,59z"/>
			<path fill="#DEBC52" d="M426.3,44.4c-0.8,0.3-1.3,0.3-1.8,0c0,0,0-0.3,0-0.3C425.1,43.9,425.6,44.2,426.3,44.4z"/>
			<path fill="#DEBB4F" d="M379.8,1.1c0.5,0.4,0.9,0.7,1.2,1c0,0-0.2,0.3-0.3,0.2c-0.4-0.2-0.7-0.5-1-0.8
				C379.5,1.5,379.7,1.3,379.8,1.1z"/>
			<path fill="#DFC05E" d="M401.8,26.7c0.3,0.4,1.3,0.3,0.8,1.3C402.2,27.6,401.7,27.4,401.8,26.7z"/>
			<path fill="#DEBA48" d="M387.4,1.2c0,0.2,0,0.5,0,0.7c-0.1,0.4-0.3,0.7-0.4,1.1c-1-0.4,0-0.9-0.1-1.3c0,0,0,0,0,0
				C387.1,1.6,386.9,1.1,387.4,1.2z"/>
			<path fill="#DEBC52" d="M401.1,23.5c-0.6,0-1.3,0.2-1.9-0.5c0.7-0.4,1.3,0.4,1.9,0C401.2,23.2,401.2,23.4,401.1,23.5z"/>
			<path fill="#DDB946" d="M419.9,68.2c0.5,0.4,0.9,0.9,1.5,0.8C420.4,69.4,420.3,69.4,419.9,68.2z"/>
			<path fill="#E2C674" d="M428.2,57.6c0,0.7,0,0.7-0.5,0.7c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.3-0.2-0.3-0.5
				C427.5,57.1,427.8,57.7,428.2,57.6z"/>
			<path fill="#DDB740" d="M386.9,9.5c-0.7-0.4-1.1,0.4-1.5-0.3c-0.1-0.1,0-0.3,0-0.4C385.8,9.2,386.4,8.5,386.9,9.5z"/>
			<path fill="#E1C570" d="M427.2,47c0.1-0.3-0.1-0.8,0.5-0.6c0.3,0.1,0.6,0.4,0.4,0.7C427.8,47.8,427.5,47,427.2,47
				C427.2,47,427.2,47,427.2,47z"/>
			<path fill="#E1C673" d="M388.1,3.4c-0.2,0.1-0.5,0.2-0.7,0.2l0,0c0-0.5,0-1.1,0.5-1.4C388.7,2.4,388.3,2.9,388.1,3.4z"/>
			<path fill="#DDB73D" d="M421.5,76.3c-0.1,0.2-0.3,0.2-0.5,0.2c-0.1,0.1-0.2,0.1-0.3,0c0.1-0.2,0.2-0.5,0.2-0.7
				c0-0.3-0.1-0.6,0.1-0.8c0.1,0,0.2,0,0.3,0.1c0.5,0.3,0,0.5-0.1,0.8C421.3,76.1,421.3,76.2,421.5,76.3z"/>
			<path fill="#E0C268" d="M427.4,59.3c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.1,0.3,0,0.2,0.2c-0.1,0.2-0.2,0.5-0.4,0.7
				c-0.1,0.2-0.4,0.3-0.6,0.1C427.2,59.6,427.2,59.5,427.4,59.3z"/>
			<path fill="#E0C163" d="M425.3,66.9c-0.3,0-0.6-0.1-0.4-0.4c0.1-0.2,0.4-0.4,0.7-0.5c0.2-0.1,0.4,0.1,0.3,0.4
				C425.8,66.7,425.7,67,425.3,66.9z"/>
			<path fill="#DDB948" d="M376.5,10.2c-0.2,0-0.4-0.1-0.3-0.2c0.1-0.6,0.6-0.3,0.9-0.5c0.2-0.2,0.3,0,0.3,0.3
				C377.3,10.3,376.6,9.8,376.5,10.2z"/>
			<path fill="#E1C36A" d="M429.6,45.7c0,0.4,0.2,0.9-0.2,1.2c-0.1,0-0.3-0.1-0.4-0.2C428.8,46.2,429.1,45.9,429.6,45.7z"/>
			<path fill="#DDB946" d="M375.5,2.2c0.5,0.2,0.9,0.4,0.7,1c0,0.1-0.2,0.2-0.3,0.2C375.4,3.1,375.7,2.6,375.5,2.2z"/>
			<path fill="#DDB73F" d="M404,25.5c0.5,0.5,0.5,0.9,0.3,1.6C403.9,26.6,403.6,26.2,404,25.5z"/>
			<path fill="#DDB946" d="M423.1,69.1c-0.3,0.1-0.7,0.8-1,0.3c-0.3-0.5,0.4-0.7,0.7-1C423.1,68.6,423.3,68.8,423.1,69.1z"/>
			<path fill="#DDB843" d="M380.2,12c-0.3-0.1-0.5-0.3-0.6-0.6c0-0.2,0.2-0.4,0.4-0.4c0.2,0.2,0.4,0.4,0.6,0.7
				C380.6,11.9,380.5,12,380.2,12z"/>
			<path fill="#DFBD55" d="M423.1,64.4c0.3-0.7,0.4-1.1,0.9-1.2c0,0.2,0.9,0.6-0.1,0.7C423.7,64,423.5,64.1,423.1,64.4z"/>
			<path fill="#DEBC53" d="M381.2,6.5c0-0.3,0.2-0.5,0.5-0.3c0.3,0.1,0.4,0.3,0.4,0.6c-0.1,0.4-0.4,0.4-0.7,0.4
				C381.6,6.8,381.2,6.7,381.2,6.5z"/>
			<path fill="#E1C36A" d="M423.8,55.5c0.6-0.3,0.9-0.1,1.3,0.2c0,0,0,0.3-0.1,0.3C424.3,56.4,424.3,55.5,423.8,55.5z"/>
			<path fill="#E1C56F" d="M396.8,16.8c0-0.3-0.3-0.7,0.3-0.8c0.3,0,0.6,0.2,0.5,0.5C397.5,17.1,397,16.7,396.8,16.8
				C396.7,16.8,396.8,16.8,396.8,16.8z"/>
			<path fill="#E1C46D" d="M424.1,56.8c0.3,0.2,0.8,0.3,0.9,0.7c0,0.1-0.1,0.2-0.2,0.2C424.2,57.8,424.1,57.4,424.1,56.8z"/>
			<path fill="#DDB740" d="M383.8,7.9c0.1,0,0.2,0,0.3,0c0.3,0,0.5,0.2,0.6,0.5c0,0.2,0,0.3-0.2,0.4c-0.4,0.2-0.5-0.3-0.7-0.4
				C383.7,8.2,383.7,8.1,383.8,7.9z"/>
			<path fill="#DEBC54" d="M426.5,52.3c0.2,0.3,0.8,0.4,0.7,1c-0.3-0.1-0.6-0.5-1-0.2c-0.2-0.1-0.4-0.3-0.5-0.5
				C426,52.3,426.2,52.3,426.5,52.3z"/>
			<path fill="#DFBD54" d="M420.9,59c-0.7-0.1-1.1-0.1-1.3-0.5c0-0.1,0-0.3,0.1-0.3C420.2,58.1,420.5,58.4,420.9,59z"/>
			<path fill="#DEBC4D" d="M386.2,1c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0c-0.1,0.1-0.3,0.3-0.5,0.3c-0.2-0.6-0.5-1-1.2-1.2
				c0.6,0,1.1,0,1.7,0C386.3,0.5,386.1,0.7,386.2,1z"/>
			<path fill="#E0C05F" d="M418.8,68.5c-0.4,0.1-0.5,0.9-1.1,0.4c-0.1-0.1-0.2-0.2-0.1-0.3C418,68.2,418.4,68.5,418.8,68.5z"/>
			<path fill="#E1C46D" d="M425.1,75.9c0.2,0.2,0.5,0.4,0,0.6c-0.4,0.2-0.6,0.4-0.7-0.2C424.5,76,424.7,75.8,425.1,75.9z"/>
			<path fill="#E1C36A" d="M428.7,76.9c-0.5,0-0.3,0.9-0.9,0.7c-0.1,0-0.2-0.3-0.2-0.3C427.8,76.8,428.2,76.8,428.7,76.9z"/>
			<path fill="#E0C060" d="M423.1,69.1c-0.1-0.2-0.2-0.5-0.2-0.7c0.3,0.1,0.9-0.3,0.8,0.5C423.7,69.6,423.4,69.2,423.1,69.1z"/>
			<path fill="#E2C674" d="M421.5,70.9c-0.1,0.2,0,0.6-0.4,0.6c-0.2,0-0.3-0.1-0.3-0.4c0-0.3,0.2-0.5,0.5-0.5
				C421.5,70.6,421.6,70.7,421.5,70.9z"/>
			<path fill="#E0C265" d="M422.2,49.4c0,0.3,0.7,0.4,0.3,0.8c-0.1,0.1-0.5,0.1-0.6,0c-0.5-0.4,0.1-0.5,0.2-0.8
				C422,49.4,422.1,49.4,422.2,49.4z"/>
			<path fill="#DFC05E" d="M418.6,42.3c-0.2-0.5-0.5-0.7-0.2-1.1c0.1-0.1,0.3-0.2,0.4,0C419.1,41.5,418.8,41.8,418.6,42.3z"/>
			<path fill="#DEBC51" d="M418.1,38.3c0.5,0,0.8,0.1,0.8,0.6c0,0.1-0.2,0.3-0.3,0.3C418.1,39.2,418.3,38.7,418.1,38.3z"/>
			<path fill="#E1C572" d="M409.6,27.3c-0.1,0.2-0.1,0.5-0.3,0.4c-0.2-0.1-0.5-0.3-0.6-0.5c-0.1-0.2,0.2-0.3,0.3-0.3
				C409.4,26.8,409.5,27,409.6,27.3z"/>
			<path fill="#DEBB4D" d="M396.6,26.9c-0.4,0.2-0.2,0.9-0.8,0.8c-0.1,0-0.2-0.2-0.2-0.2C395.7,26.9,396.2,27,396.6,26.9z"/>
			<path fill="#E1C266" d="M423.6,62c-0.3-0.3-0.8-0.5-0.7-1c0-0.1,0.2-0.1,0.3-0.1C423.6,61.1,423.9,61.4,423.6,62z"/>
			<path fill="#DDB947" d="M417.4,63c0.2,0.7,0.4,1.1,0.6,1.7C417.3,64.1,417.3,64.1,417.4,63z"/>
			<path fill="#E1C46D" d="M388.3,8.6c0.8,0.4,0.8,0.4,0.2,1.1C388.2,9.4,388.4,9,388.3,8.6L388.3,8.6z"/>
			<path fill="#DEBA48" d="M388.1,3.4c-0.1-0.4,0.2-0.9-0.2-1.2c0-0.1,0-0.1,0-0.2c0.2,0,0.3,0,0.5,0c0.1,0.1,0.2,0.2,0.2,0.2
				c0.2,0.3,0.4,0.6,0.2,1c0,0,0,0,0,0C388.6,3.2,388.3,3.3,388.1,3.4z"/>
			<path fill="#DDB740" d="M387.4,3.6c0.3,0.4,0.2,0.7-0.2,1c-0.1,0.1-0.3,0-0.3-0.1C386.8,4.1,386.9,3.7,387.4,3.6
				C387.4,3.6,387.4,3.6,387.4,3.6z"/>
			<path fill="#DFBF5B" d="M426.3,49.8c-0.3,0-0.5-0.1-0.4-0.4c0-0.2,0.2-0.4,0.4-0.3c0.3,0,0.4,0.3,0.4,0.5
				C426.8,49.9,426.4,49.7,426.3,49.8z"/>
			<path fill="#DFBD55" d="M391.3,15.3c-0.3,0-0.5-0.2-0.5-0.4c0-0.3,0.3-0.3,0.5-0.3c0.2,0,0.5,0,0.4,0.2
				C391.7,15.1,391.4,15.2,391.3,15.3z"/>
			<path fill="#DDB73F" d="M390.2,18.7c-0.1-0.1-0.3-0.2-0.3-0.3c0.1-0.4,0.7-0.2,0.7-0.6c0.1,0.1,0.2,0.3,0.2,0.4
				C390.8,18.4,390.6,18.6,390.2,18.7z"/>
			<path fill="#E1C46C" d="M388.3,8.6c-0.3-0.2-0.7-0.3-0.6-0.8c0-0.2,0.3-0.2,0.5-0.1c0.4,0.2,0.1,0.4,0.1,0.7
				C388.4,8.5,388.4,8.6,388.3,8.6C388.3,8.6,388.3,8.6,388.3,8.6z"/>
			<path fill="#E0C267" d="M425.1,51.3c0-0.3,0.1-0.5,0.3-0.5c0.2,0,0.3,0.2,0.3,0.4c0,0.3-0.1,0.5-0.3,0.5
				C425.2,51.7,425.1,51.5,425.1,51.3z"/>
			<path fill="#DEBA4B" d="M424.4,67.9c-0.3,0-0.7,0.2-0.9-0.3c-0.1-0.1,0-0.3,0.2-0.4c0.4-0.1,0.6,0.1,0.7,0.4
				C424.4,67.7,424.5,67.8,424.4,67.9C424.4,67.9,424.4,67.9,424.4,67.9z"/>
			<path fill="#DDBA4A" d="M419.3,52.6c0,0.2,0,0.3,0,0.5c0,0,0,0,0,0c-0.1,0.1-0.2,0.4-0.5,0.2c-0.1-0.3-0.7-0.2-0.6-0.5
				C418.4,52.3,418.9,52.6,419.3,52.6z"/>
			<path fill="#DDB944" d="M425.3,62.7c-0.4,0.2,0.2,0.7-0.2,0.9c-0.3,0-0.6-0.2-0.5-0.5C424.6,62.7,425,62.7,425.3,62.7z"/>
			<path fill="#DBB22C" d="M428.9,62.6c-0.6,0.1-0.8-0.3-1-0.7c0.2-0.1,0.5-0.4,0.7,0C428.7,62.2,428.8,62.4,428.9,62.6z"/>
			<path fill="#E1C572" d="M389.5,12.8c0,0.2-0.1,0.4-0.3,0.3c-0.2,0-0.5-0.1-0.5-0.2c-0.1-0.3,0.2-0.4,0.4-0.4
				C389.3,12.5,389.5,12.6,389.5,12.8z"/>
			<path fill="#E1C36C" d="M380.9,7c-0.5,0.2-0.9-0.2-1.4-0.4c0.6-0.2,1-0.4,1.4,0.1C381,6.8,381,6.8,380.9,7
				C381,6.9,380.9,7,380.9,7z"/>
			<path fill="#DEBC51" d="M399,25.4c0.2-0.4,0.4-0.7,0.8-1.3C399.8,24.9,399.5,25.2,399,25.4z"/>
			<path fill="#DCB63B" d="M379.5,2.8c-0.5,0.4-0.8,0.4-1.3-0.2C378.8,2.5,379.1,3.1,379.5,2.8z"/>
			<path fill="#E1C46F" d="M409.2,24c0-0.1,0-0.1,0-0.2c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.1,0.3-0.2,0.5-0.1c0,0,0,0,0,0
				c-0.1,0.4-0.1,0.9-0.5,1.2c-0.1-0.1-0.1-0.1-0.2-0.2C409.4,24.2,409.3,24.1,409.2,24z"/>
			<path fill="#DEBB4C" d="M424,32.2c0,0.2-0.1,0.4-0.4,0.4c-0.2,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.3,0.3-0.3
				C423.7,31.9,424,31.9,424,32.2z"/>
			<path fill="#DFBF5A" d="M424,58.6c-0.4-0.2-0.8-0.3-0.7-0.8c0-0.1,0.2-0.2,0.3-0.1C424,57.9,423.8,58.3,424,58.6z"/>
			<path fill="#E1C46C" d="M383.8,7.9c0,0.2,0,0.3,0,0.5c-0.2,0.2-0.4,0.2-0.6,0c-0.1-0.2-0.2-0.3,0-0.5
				C383.4,7.7,383.6,7.7,383.8,7.9z"/>
			<path fill="#DFBD55" d="M386.7,10.3c-0.4,0.2-0.4,0.2-1.1-0.2C386.1,9.6,386.3,10.2,386.7,10.3L386.7,10.3z"/>
			<path fill="#E0C061" d="M419.3,70.6c-0.4-0.1-0.6-0.3-0.2-0.5c0.2-0.1,0.6,0.1,0.9,0.1C419.9,70.5,419.4,70.2,419.3,70.6
				C419.3,70.6,419.3,70.6,419.3,70.6z"/>
			<path fill="#DFBE59" d="M406.7,27.2c0.1,0.3,0.1,0.6,0.2,1.2C406.3,28,406.3,27.7,406.7,27.2z"/>
			<path fill="#E1C571" d="M404.6,28.3c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.2-0.3-0.4c0-0.2,0.2-0.3,0.3-0.3
				C404.5,27.9,404.7,28,404.6,28.3z"/>
			<path fill="#E0C060" d="M394.6,19.5c0.2,0.1,0.4,0.1,0.3,0.4c0,0.2-0.2,0.3-0.4,0.2c-0.2-0.1-0.4-0.2-0.3-0.4
				C394.2,19.5,394.4,19.5,394.6,19.5z"/>
			<path fill="#DEBA4B" d="M425.3,68.2c0.2,0.2,0.5,0.3,0.7,0.5c0.1,0.3-0.1,0.4-0.2,0.5c-0.2-0.1-0.5-0.2-0.7-0.2l0,0
				c0.1-0.2,0.1-0.5,0-0.7C425.1,68.1,425.2,68.1,425.3,68.2z"/>
			<path fill="#E1C36B" d="M418.8,53.3c0.2-0.1,0.3-0.2,0.5-0.2c0,0.2,0,0.4,0.2,0.5c0.1,0.2,0.2,0.3,0,0.5
				C419.1,53.9,418.7,53.8,418.8,53.3z"/>
			<path fill="#DEBB4E" d="M418.6,45.2c0.2,0.1,0.4,0.2,0.3,0.4c0,0.2-0.2,0.2-0.4,0.2c-0.2,0-0.4-0.1-0.3-0.4
				C418.3,45.3,418.4,45.2,418.6,45.2z"/>
			<path fill="#E2C672" d="M427,42.2c0.1-0.2,0.1-0.3,0.2-0.5c0.3,0.2,0.9,0.3,0.4,0.8C427.4,42.9,427.1,42.5,427,42.2z"/>
			<path fill="#E0C162" d="M424.2,65.9c0.1,0.4-0.2,0.6-0.4,0.7c0,0-0.2-0.1-0.2-0.2C423.6,66,423.8,65.8,424.2,65.9z"/>
			<path fill="#DFBF5A" d="M422.3,64.9c-0.3,0.2-0.5,0.5-0.9,0.2c0,0,0-0.2,0-0.2C421.6,64.6,421.9,64.6,422.3,64.9z"/>
			<path fill="#DEBC51" d="M418.2,65.4c0.1-0.5,0.4-0.5,0.8-0.5C418.9,65.4,418.6,65.5,418.2,65.4z"/>
			<path fill="#DFBC52" d="M391.9,16.6c-0.4,0.2-0.5,0.4-0.7,0.4c-0.2,0-0.3-0.2-0.3-0.4c0-0.2,0-0.3,0.2-0.3
				C391.4,16.3,391.6,16.4,391.9,16.6z"/>
			<path fill="#DFBE56" d="M399.7,29.9c0.2,0.3,0.4,0.6,0.1,1C399.5,30.6,399.3,30.3,399.7,29.9z"/>
			<path fill="#DEBD55" d="M398.4,29.1c0.5,0,0.6,0.4,0.7,0.8C398.7,29.7,398.4,29.5,398.4,29.1z"/>
			<path fill="#E1C46C" d="M429.4,52.1c-0.3-0.1-0.4,0.4-0.7,0.2c-0.1-0.1-0.1-0.5,0.2-0.6C429.2,51.5,429.4,51.8,429.4,52.1
				C429.4,52.1,429.4,52.1,429.4,52.1z"/>
			<path fill="#E2C776" d="M428.4,59c-0.1-0.1-0.2-0.2-0.2-0.2c0.1-0.2,0.2-0.3,0.2-0.5c0.2,0,0.3,0,0.5,0c-0.1,0.2,0.3,0.5,0,0.7
				C428.7,59,428.6,59,428.4,59z"/>
			<path fill="#E0BF5D" d="M410,26c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3
				C410.2,26.4,410,26.3,410,26z"/>
			<path fill="#E1C46B" d="M401.1,23.5c0-0.2,0-0.3,0-0.5c0.3,0,0.7,0.1,0.6,0.5C401.6,23.9,401.3,23.8,401.1,23.5z"/>
			<path fill="#DEB946" d="M400.2,25.8c0.1,0.4,0,0.7-0.3,0.7c0,0-0.2-0.1-0.2-0.1C399.7,26.1,400,25.9,400.2,25.8z"/>
			<path fill="#E0C163" d="M385.7,1.5c0.1-0.2,0.3-0.2,0.5-0.3c0,0.4-0.1,0.7-0.3,1C385.6,2,385.8,1.7,385.7,1.5z"/>
			<path fill="#DFBD55" d="M418.9,60.8c-0.4,0.4-0.6,0.5-1,0.2C418.2,60.6,418.5,60.7,418.9,60.8z"/>
			<path fill="#E1C369" d="M403.7,38.5c-0.3,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.4,0.1,0.4,0.3
				C404,38.4,403.8,38.4,403.7,38.5z"/>
			<path fill="#E2C674" d="M425.6,75.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2-0.1-0.4-0.3-0.5-0.4c0.1-0.1,0.2-0.2,0.4-0.2
				C425.5,74.6,425.5,74.9,425.6,75.1z"/>
			<path fill="#E3CA7F" d="M426.7,63.8c-0.1,0.3,0.2,0.7-0.2,0.8c-0.3,0.1-0.5-0.2-0.5-0.5c0,0,0,0,0,0
				C426.2,63.9,426.4,63.7,426.7,63.8L426.7,63.8z"/>
			<path fill="#DDB843" d="M421.1,53.8c0.1,0.3,0.4,0.5,0.2,0.8c0,0-0.2,0-0.2,0C420.6,54.4,421.1,54.1,421.1,53.8z"/>
			<path fill="#E0C060" d="M425.1,68.9c-0.1,0.3-0.2,0.5-0.4,0.8C424.6,69.3,424.2,68.8,425.1,68.9
				C425.1,68.9,425.1,68.9,425.1,68.9z"/>
			<path fill="#E2C673" d="M425.3,68.2c-0.1,0-0.2,0-0.2,0c-0.2-0.1-0.5-0.2-0.7-0.3c0-0.1,0-0.2,0-0.2
				C424.8,67.5,425.2,67.6,425.3,68.2z"/>
			<path fill="#E0C163" d="M425.8,69.1c0.1-0.2,0.2-0.3,0.2-0.5c0.2-0.3,0.5-0.2,0.5,0C426.5,69.1,426.1,69.1,425.8,69.1z"/>
			<path fill="#DFBE5A" d="M428.9,72.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.2,0-0.3,0.2-0.3
				C428.7,72,428.8,72.1,428.9,72.3z"/>
			<path fill="#DFBB50" d="M377.1,8.6c0,0.3-0.2,0.2-0.3,0.2c-0.1,0-0.3,0-0.3-0.2c0-0.2,0.2-0.2,0.3-0.2
				C376.9,8.5,377,8.6,377.1,8.6z"/>
			<path fill="#E0C266" d="M383.4,3.3c-0.2,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0.1,0.3,0.2,0.4,0.4
				C383.7,3.3,383.5,3.3,383.4,3.3z"/>
			<path fill="#DEB947" d="M394,20.8c0.2,0,0.3,0.2,0.3,0.3c0,0.1,0,0.3-0.1,0.3c-0.2,0.1-0.3-0.1-0.3-0.2
				C393.9,21.1,393.8,20.9,394,20.8z"/>
			<path fill="#DFBD54" d="M384.7,11.6c-0.3,0.5-0.4,0.8-0.7,0.7c0,0-0.1-0.1-0.1-0.2C384,11.9,384.3,11.9,384.7,11.6z"/>
			<path fill="#E1C46D" d="M385.3,12.4c0-0.4-0.1-0.6,0.2-0.8c0,0,0.2,0.1,0.3,0.1C385.9,12.1,385.6,12.2,385.3,12.4z"/>
			<path fill="#DDB73E" d="M402.3,22.1c-0.1,0.1-0.2,0.2-0.2,0.2c-0.2,0-0.4,0.1-0.6,0c-0.2-0.1,0.1-0.2,0.2-0.3
				C401.9,21.6,402.1,22,402.3,22.1z"/>
			<path fill="#DFBE56" d="M404.7,31c0.2,0,0.3,0,0.3,0.2c0,0.1-0.2,0.2-0.3,0.3c0,0-0.2-0.1-0.2-0.2C404.5,31.2,404.5,31,404.7,31z
				"/>
			<path fill="#E1C36A" d="M419.2,49.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.2
				C419.5,49.3,419.4,49.4,419.2,49.4z"/>
			<path fill="#DEBC53" d="M407,30.1c0,0.2-0.1,0.2-0.3,0.2c-0.1,0-0.4,0.1-0.3-0.2c0-0.1,0.2-0.3,0.3-0.3
				C406.9,29.8,407,29.9,407,30.1z"/>
			<path fill="#DEBB4C" d="M420,52.6c0.1-0.2,0.2-0.3,0.2-0.5c0.2,0,0.6,0,0.6,0.3C420.8,52.7,420.3,52.5,420,52.6z"/>
			<path fill="#E2C97D" d="M419.5,51.1c0,0.2-0.1,0.4-0.2,0.5c-0.2-0.1-0.4-0.3-0.5-0.5c0,0,0,0,0,0
				C419.1,51.1,419.3,50.7,419.5,51.1z"/>
			<path fill="#DFBD56" d="M427.7,55.9c0.6-0.3,0.4,0.2,0.5,0.5C427.9,56.4,427.4,56.5,427.7,55.9z"/>
			<path fill="#E1C36A" d="M402,24.7c0,0.3-0.1,0.4-0.2,0.4c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1-0.2,0.1-0.3,0.3-0.2
				C401.8,24.6,401.9,24.7,402,24.7z"/>
			<path fill="#DDB947" d="M426.5,75.6c0.5,0.1,0.5,0.3,0.4,0.8C426.4,76.3,426.5,75.9,426.5,75.6C426.5,75.6,426.5,75.6,426.5,75.6
				z"/>
			<path fill="#DEBC52" d="M427.7,51c0,0.1-0.1,0.2-0.1,0.2c-0.3,0.1-0.3-0.2-0.4-0.3c-0.1-0.2,0.1-0.2,0.2-0.2
				C427.5,50.7,427.6,50.8,427.7,51z"/>
			<path fill="#DFBE5A" d="M401.1,33.9c0.1-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.2,0.1,0.3,0.3c0.1,0.5-0.3,0.5-0.6,0.5
				C401,34,401,34,401.1,33.9C401,33.9,401,33.9,401.1,33.9z"/>
			<path fill="#E1C46C" d="M427.9,44.6c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0-0.3,0.2-0.3
				C427.7,44.3,427.8,44.4,427.9,44.6z"/>
			<path fill="#DDBA4A" d="M383.2,12.7c-0.1-0.1-0.3-0.1-0.3-0.2c-0.1-0.2,0.1-0.3,0.3-0.2c0.1,0,0.2,0.1,0.2,0.2
				C383.4,12.5,383.3,12.6,383.2,12.7z"/>
			<path fill="#DFC061" d="M421.6,65.7c0.1,0.2,0.2,0.4,0,0.6c0,0-0.2,0-0.2,0C421.2,66,421.5,65.9,421.6,65.7z"/>
			<path fill="#E0C162" d="M410.5,33.2c0.1,0.2,0.2,0.3,0.2,0.5c0,0-0.2,0.2-0.2,0.2c-0.2,0-0.3-0.1-0.2-0.3
				C410.3,33.4,410.4,33.3,410.5,33.2z"/>
			<path fill="#E1C572" d="M393.2,10.6c0.2,0,0.4,0,0.4,0.3c0,0.3-0.2,0.4-0.4,0.4C393.1,11,393.1,10.8,393.2,10.6z"/>
			<path fill="#DEBB4E" d="M420.3,54.2c0.3,0.2,0.5,0.5,0.3,0.9C420.1,54.9,420.3,54.5,420.3,54.2L420.3,54.2z"/>
			<path fill="#DCB63B" d="M380.9,7c0-0.1,0-0.2,0-0.2c0.1-0.1,0.2-0.2,0.2-0.2c0.3,0.2,0.8,0.3,0.2,0.7C381.2,7.1,381.1,7,380.9,7z
				"/>
			<path fill="#E0C369" d="M420,53c0.3,0,0.6,0,0.6,0.4c0,0.1-0.1,0.1-0.2,0.1C420,53.6,420.1,53.3,420,53C420,53,420,53,420,53z"/>
			<path fill="#DFBD53" d="M386.8,7.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.1-0.3-0.1c0-0.1,0.1-0.2,0.2-0.2
				C386.5,7.7,386.6,7.7,386.8,7.9z"/>
			<path fill="#DEBB4E" d="M419.5,54c0-0.2,0-0.3,0-0.5c0.3,0.1,0.6,0.4,0.7,0.7c0,0,0,0,0,0C419.9,54.5,419.8,54.1,419.5,54z"/>
			<path fill="#E2C674" d="M422.2,40.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2-0.1-0.5-0.3-0.3-0.5C421.9,39.7,422.1,40.1,422.2,40.3z"/>
			<path fill="#E0C164" d="M428.6,69.3c0,0-0.2,0.2-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.2,0.1-0.2,0.2-0.2
				C428.5,68.9,428.6,69,428.6,69.3z"/>
			<path fill="#E1C36C" d="M423.8,53.9c0,0.2-0.1,0.3-0.2,0.3c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1,0.1-0.2,0.2-0.2
				C423.6,53.7,423.7,53.8,423.8,53.9z"/>
			<path fill="#E1C570" d="M421,51.6c-0.5,0-0.5-0.4-0.5-0.7C421,50.9,421,51.2,421,51.6z"/>
			<path fill="#E0C164" d="M420.3,59.9c0.2-0.1,0.2,0.1,0.2,0.2c0,0.1-0.1,0.3-0.1,0.4c-0.1-0.1-0.2-0.1-0.3-0.2
				C420,60.2,420.1,60,420.3,59.9z"/>
			<path fill="#E0C060" d="M416.9,67c-0.2,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.3-0.3c0,0,0.2-0.1,0.3-0.1
				C416.6,66.8,416.7,66.9,416.9,67z"/>
			<path fill="#E0BF5C" d="M380.2,3.8c-0.1-0.2-0.2-0.3-0.2-0.4c0-0.1,0-0.4,0.2-0.3c0.1,0,0.2,0.2,0.2,0.4
				C380.4,3.5,380.3,3.6,380.2,3.8z"/>
			<path fill="#E1C571" d="M424.5,46.9c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.1-0.3-0.2-0.3-0.3c0-0.1,0.1-0.2,0.2-0.1
				C424.3,46.6,424.5,46.6,424.5,46.9z"/>
			<path fill="#DDB73E" d="M402.3,19.4c0.2,0,0.3,0,0.5,0c0.3,0.1,0.6-0.1,0.8,0.3c0,0.1,0,0.2-0.2,0.3c-0.3,0-0.6,0.1-0.9-0.1
				c0,0,0,0,0,0C402.4,19.8,402.3,19.6,402.3,19.4z"/>
			<path fill="#E0C36A" d="M425.2,49c-0.1,0-0.2,0.2-0.2,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.2
				C425.1,48.6,425.2,48.7,425.2,49z"/>
			<path fill="#E0C163" d="M412.6,22.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.1,0.3-0.1
				C412.4,22.4,412.4,22.5,412.6,22.6z"/>
			<path fill="#E2C778" d="M421.3,49c0.1,0.4,0,0.5-0.1,0.5c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.3,0.2-0.4
				C421.2,49,421.3,49,421.3,49z"/>
			<path fill="#DEBC53" d="M396.5,21.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.3,0.2-0.3
				C396.4,21.3,396.4,21.5,396.5,21.6z"/>
			<path fill="#DDB843" d="M383.5,21.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.2-0.3c0-0.1,0.2-0.1,0.3-0.1
				C383.3,21.4,383.4,21.5,383.5,21.6z"/>
			<path fill="#DEBC53" d="M398.4,22.1c0.2,0,0.5-0.3,0.5,0.2c0,0.1-0.2,0.3-0.2,0.3C398.3,22.6,398.5,22.3,398.4,22.1
				C398.4,22.1,398.4,22.1,398.4,22.1z"/>
			<path fill="#DDB740" d="M373.4,4.5c0.2-0.2,0.5-0.2,0.6,0c0.1,0.3-0.2,0.3-0.3,0.2C373.6,4.6,373.5,4.5,373.4,4.5z"/>
			<path fill="#DDB842" d="M425,64.2c0.2,0.1,0.3,0.2,0.3,0.4c0,0.1-0.2,0.2-0.2,0.2c-0.2,0-0.3-0.1-0.2-0.3
				C424.8,64.3,424.9,64.3,425,64.2z"/>
			<path fill="#DFBF5C" d="M424.7,70.2c0.2,0.1,0.4,0.4,0.3,0.6c-0.3,0.3-0.3,0-0.4-0.2C424.6,70.5,424.6,70.4,424.7,70.2z"/>
			<path fill="#E1C46C" d="M405.4,24.7c0.1,0.1,0.3,0.2,0.3,0.3c0,0.2-0.2,0.1-0.4,0.1c-0.2-0.1-0.4-0.2-0.3-0.4
				C405.1,24.5,405.3,24.7,405.4,24.7C405.4,24.7,405.4,24.7,405.4,24.7z"/>
			<path fill="#DDBA49" d="M400.7,32.1c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.2,0.2,0.2c-0.1,0.1-0.1,0.2-0.3,0.3
				C400.9,32.3,400.8,32.2,400.7,32.1z"/>
			<path fill="#DDB945" d="M418,63.7c0-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.1,0.1,0.1,0.2
				C418.3,63.6,418.3,63.8,418,63.7z"/>
			<path fill="#E0C267" d="M424.3,72.7c0.3,0.1,0.4,0.2,0.4,0.4c0,0.1-0.2,0.1-0.2,0.2c-0.1-0.1-0.2-0.2-0.2-0.4
				C424.2,72.9,424.3,72.8,424.3,72.7z"/>
			<path fill="#E0C369" d="M422.2,49.4c-0.1,0-0.1,0-0.2,0c-0.3-0.2,0-0.4,0.1-0.6c0,0,0.1,0,0.2,0.1
				C422.4,49.1,422.2,49.3,422.2,49.4z"/>
			<path fill="#DFBF5B" d="M427.5,49.4c0,0.1,0,0.2,0,0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3,0.1-0.3
				C427.3,49.1,427.4,49.3,427.5,49.4z"/>
			<path fill="#DFBC51" d="M376.8,3.7c0-0.1,0.1-0.3,0.2-0.2c0.1,0,0.1,0.2,0.2,0.3c0,0.1-0.1,0.2-0.2,0.2
				C376.9,3.9,376.9,3.8,376.8,3.7z"/>
			<path fill="#DEBA4B" d="M424.6,45.1c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.1,0.1,0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.2
				C424.8,45.3,424.7,45.2,424.6,45.1z"/>
			<path fill="#E1C369" d="M426.6,33.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C426.5,32.9,426.6,32.9,426.6,33.2z"/>
			<path fill="#DEBC50" d="M403,33.3c0-0.1,0.1-0.3,0.2-0.2c0.1,0,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.2,0.2
				C403.1,33.5,403,33.3,403,33.3z"/>
			<path fill="#DEBA4A" d="M425.8,70.8c0.1-0.2-0.1-0.7,0.3-0.6c0.3,0.1,0,0.4-0.1,0.6C425.9,70.9,425.9,70.9,425.8,70.8z"/>
			<path fill="#DEBA4A" d="M419.9,54.9c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.2
				C419.7,54.7,419.8,54.8,419.9,54.9z"/>
			<path fill="#DDB843" d="M392.4,16.4c0.2,0,0.2,0.1,0.2,0.3c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.1-0.2-0.1-0.2-0.2
				C392.2,16.6,392.3,16.5,392.4,16.4z"/>
			<path fill="#E0C369" d="M399.2,16.2c0.2,0.2,0.3,0.3,0.3,0.4c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C399.1,16.4,399.1,16.3,399.2,16.2z"/>
			<path fill="#E1C56F" d="M425.8,70.8c0.1,0,0.2,0,0.2,0c0,0.2,0,0.5-0.3,0.5c0,0-0.1,0-0.2-0.1C425.5,71.1,425.6,70.9,425.8,70.8z
				"/>
			<path fill="#E1C571" d="M398.1,18.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.2c0-0.1,0-0.2,0.1-0.3
				C397.8,18.7,397.9,18.7,398.1,18.8z"/>
			<path fill="#DFBE5A" d="M417.6,72.2c-0.1,0-0.2,0-0.2,0c0-0.3-0.1-0.7,0.3-0.7c0.5,0,0,0.2,0,0.3C417.7,72,417.7,72.1,417.6,72.2
				z"/>
			<path fill="#E1C369" d="M403,29.8c0-0.1-0.1-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
				C403.2,29.7,403.2,29.8,403,29.8z"/>
			<path fill="#DFBD53" d="M398.7,20.9c0-0.3-0.1-0.7,0.5-0.5C399.1,20.7,399,20.9,398.7,20.9z"/>
			<path fill="#E2C775" d="M401.6,20.6c0-0.2,0-0.4,0.3-0.4c0.1,0,0.3,0,0.2,0.2c0,0.1-0.2,0.2-0.3,0.3
				C401.7,20.6,401.6,20.6,401.6,20.6z"/>
			<path fill="#DDB945" d="M406,25.9c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0-0.3,0.2-0.2
				C405.9,25.7,406,25.8,406,25.9z"/>
			<path fill="#DFBE57" d="M422.3,53.5c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0.1-0.2,0.2-0.1c0.1,0.1,0.2,0.2,0.2,0.3
				C422.5,53.2,422.4,53.3,422.3,53.5z"/>
			<path fill="#DEB945" d="M424.1,40.6c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.1,0.1,0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.2
				C424.3,40.7,424.3,40.6,424.1,40.6z"/>
			<path fill="#E0C165" d="M422.5,39c-0.2,0.2-0.3,0.2-0.3,0.3c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C422.3,38.8,422.4,38.9,422.5,39z"/>
			<path fill="#DFBD55" d="M386.7,10.3c0-0.3,0.1-0.6,0.5-0.6C387.2,10.1,386.9,10.2,386.7,10.3C386.7,10.3,386.7,10.3,386.7,10.3z"
				/>
			<path fill="#E1C46D" d="M420.7,36.5c0.1,0.1,0.2,0.2,0.1,0.3c0,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.2-0.2
				C420.5,36.6,420.6,36.6,420.7,36.5z"/>
			<path fill="#E0C267" d="M388.5,11.1c-0.5,0.2-0.6,0.3-0.8,0.3c0,0-0.1-0.1-0.1-0.1C387.7,11.1,387.9,11,388.5,11.1z"/>
			<path fill="#E1C36A" d="M401.1,33.9c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.4,0.1-0.5,0.1c0,0-0.1-0.1-0.1-0.2
				C400.6,33.8,400.8,33.9,401.1,33.9z"/>
			<path fill="#DFBE59" d="M389.3,14.6c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0.1,0.2,0.2,0.2,0.2
				C389.5,14.4,389.4,14.5,389.3,14.6z"/>
			<path fill="#DBB330" d="M386.2,1c-0.9-0.2-0.1-0.5,0-0.7c0.2,0.1,0.3,0.1,0.5,0.2C386.5,0.7,386.3,0.8,386.2,1z"/>
			<path fill="#DFE4EA" d="M392.7,209.3c-0.3-0.5,0.1-0.7,0.3-1c0.3,0.2,0.4,0.5,0.4,0.8C393.1,209.1,392.9,209.2,392.7,209.3z"/>
			<path fill="#EBE2CF" d="M417.4,274.1c0.4,0.3,0.1,0.8,0.2,1.2C417.2,274.9,417.5,274.5,417.4,274.1z"/>
			<path fill="#8BA6BE" d="M379.3,129.4c4.5-0.2,9,0.1,13.5,0.3c1.7,0.1,3.5,0,5.2,0.3c0.7,0.2,1,0.4,0.9,1.1
				c-0.7,3.1-0.7,6.2-1.7,9.2c-0.9,2.7-1.8,5.4-3.9,7.4c-2,2-4.4,3.1-7.4,3.1c-2.3-0.1-4.2-0.6-6-2.1c-1.5-1.3-2.1-3-2.6-4.7
				c-1-4-0.8-8.1-0.1-12.1c0-0.1,0.1-0.2,0.1-0.4C377.3,129.4,377.3,129.4,379.3,129.4z"/>
			<path fill="#E9ECF0" d="M335.4,119.5c0.7,0,1.4,0.1,0.6,1c-0.3,0.4-0.2,0.8-0.1,1.2c0,0.2-0.1,0.5-0.4,0.5c-0.1,0-0.3,0-0.4-0.1
				c-0.2-0.2-0.2-0.4,0-0.6c0.5-0.5-0.2-0.8-0.2-1.1c0-0.4-0.7-0.2-0.5-0.7C334.7,119.5,335.1,119.5,335.4,119.5z"/>
			<path fill="#EFF1F4" d="M334.3,105.6c0.8-0.6,1.3-0.5,1.9,0.5c0,0.3-0.1,0.6-0.5,0.5c-0.3-0.2-0.6-0.9-0.7,0.1
				c0,0.2-0.3,0.2-0.4,0.4c-0.6,0.5-0.6,0.5-0.9-0.3c-0.3,0.1,0,0.7-0.5,0.6c-0.3-0.4-0.3-0.7-0.1-1.1
				C333.4,105.8,334,106,334.3,105.6z"/>
			<path fill="#FBFBFC" d="M399.4,189.6c-0.5,0.7,0.3,1.6-0.5,2.2c0-0.4,0-0.9,0-1.3c0-0.6-0.3-0.9-0.8-0.6c-1.3,0.6-1-1.2-1.9-1.2
				c0-0.2,0.1-0.4,0.4-0.3c0.3,0.1,0.6,0.3,0.8,0.6c0.4,0.6,0.7,0.4,1.1-0.1c0.3-0.1,0.4,0.2,0.6,0.3
				C399.1,189.3,399.2,189.5,399.4,189.6z"/>
			<path fill="#F8F9FA" d="M389.8,196.1c0.6,0.3,1,0.8,0.4,1.4c-0.7,0.8-1.3,0.2-2,0c0.2-0.6,1.2-0.4,1.1-1.1
				C389.4,196.2,389.5,195.9,389.8,196.1z"/>
			<path fill="#CBD5DF" d="M333.9,115.2c1-0.2,1-0.2,1,0.7c0,0.9-0.1,1.5-1.1,1.9c-0.2-0.8,1.3-1.4,0.1-2.1
				C333.9,115.5,333.7,115.4,333.9,115.2z"/>
			<path fill="#F6F7F9" d="M385.8,197.4c0.3,0.3,1,0.2,1,0.7c-0.1,0.4,0.6,1,0,1.1c-0.4,0.1-1-0.2-1.5-0.4c-0.2-0.1-0.1-0.3,0.1-0.3
				C385.9,198.3,385.6,197.8,385.8,197.4z"/>
			<path fill="#F0F2F5" d="M337.4,120.3c1,0.1,1.5-0.9,2.2-0.3c0.4,0.3,0.1,0.9-0.1,1C338.8,121.4,338.3,120.9,337.4,120.3z"/>
			<path fill="#F5F7F8" d="M333.1,111.8c-0.2,0.4-0.7,0.6-0.6,1.2c0,0.3-0.2,0.3-0.4,0.3c-0.2,0-0.5-0.1-0.5-0.2
				c-0.1-0.5,0.2-0.9,0.4-1.4c0.2-0.4,0.4-0.7,0.8-0.4C333.3,111.3,333.3,111.5,333.1,111.8z"/>
			<path fill="#C4CFDB" d="M333.4,106.1c-0.4,0.3-0.2,0.8-0.2,1.2c-0.3,0-0.7,0.1-1-0.2c0.4-0.4-0.3-0.4-0.2-0.7
				c-0.1-0.2-0.2-0.3-0.2-0.5c0.7-0.4,1.4-0.3,2.2-0.2c0,0,0,0,0,0C333.9,106,333.8,106.2,333.4,106.1z"/>
			<path fill="#E1E6EB" d="M377.8,208.3c-0.5,0.4-1.2,0.3-1.6,0.9c-0.2,0.3-0.1-0.7-0.6-0.5c0-0.1-0.1-0.2-0.1-0.3
				c0.1-0.4,1.8-0.8,2.1-0.5C377.7,208,377.7,208.1,377.8,208.3z"/>
			<path fill="#EDF0F3" d="M393.7,192.5c-1.1,0.2-1,1-1.2,1.7c-0.1,0.2,0,0.4-0.3,0.4c-0.3,0-0.5-0.2-0.5-0.4c0-0.6,0.8-1,0.6-1.7
				C392.8,192.5,393.1,192.1,393.7,192.5z"/>
			<path fill="#EFF2F4" d="M395.9,187.5c-0.6,0.9-1.3,1.2-2.4,0.2c0.7,0.2,0.7-1.4,1.5-0.1C395.2,187.8,395.5,187.1,395.9,187.5z"/>
			<path fill="#F1F3F6" d="M391.9,186.2c-0.4-0.5-0.8-0.8-1.4-0.7c-0.2,0-0.4,0.6-0.6,0c-0.1-0.3-0.2-0.6,0-0.9
				c0.3-0.4,0.5,0.1,0.7,0.1C391.4,184.9,391.9,185.2,391.9,186.2z"/>
			<path fill="#FDFDFE" d="M397.5,180.2c-0.6,0.1-0.6-0.9-1.3-0.6c-0.1,0-0.1-0.3,0-0.4c0.3-0.5,0.9-0.2,1.3-0.2c0.2,0,1,0,0.7,0.7
				C397.9,179.9,397.6,180,397.5,180.2z"/>
			<path fill="#FCFCFC" d="M394.8,196.3c0.5,0.4,0.8-1,1.2-0.2c0.3,0.6-0.6,0.7-0.8,1c-0.1,0.1-0.3,0.2-0.5,0.4
				c-0.1-0.3-0.2-0.7-0.3-1c-0.1-0.1-0.1-0.2,0-0.3C394.5,196.2,394.7,196.2,394.8,196.3z"/>
			<path fill="#F2F4F7" d="M384.7,186.1c-0.5,0.8,0,1.4,0.1,2.1c-0.5-0.5-1.1-1-1.2-1.7C383.6,186.3,384.2,186.1,384.7,186.1z"/>
			<path fill="#E2E7ED" d="M399.9,172.8c0.2,0.6,1.4,1,0.6,2c-0.5-0.5-1.2-0.9-1.1-1.8C399.5,172.9,399.6,172.7,399.9,172.8z"/>
			<path fill="#F0F2F5" d="M338.7,107.6c-0.3,0.1,0.6,0.6-0.2,0.6c-0.5,0-0.9-0.2-0.9-0.7c0-0.4,0.2-0.7,0.5-0.6
				C338.8,106.9,339,107.1,338.7,107.6z"/>
			<path fill="#F2F4F6" d="M394.4,180c0.3,0.1,0.9,0.2,0.3,0.5c-0.6,0.4-0.2,0.6-0.1,0.9c0,0.4-0.2,0.4-0.5,0.2
				c-0.6-0.5-0.6-1-0.3-1.7C394,179.6,394,179.6,394.4,180z"/>
			<path fill="#E0E6EB" d="M393.4,201.6c-0.4,0-0.9-0.1-1-0.5c0-0.3,0.7-0.3,0.6-0.8c0.5,0,0.9,0.1,1.3,0.6
				C393.8,200.9,393.4,201,393.4,201.6L393.4,201.6z"/>
			<path fill="#EEF0F4" d="M388.2,194.2c-0.9,0.4,0.7,1.3-0.3,1.4c-0.4,0.1-0.9-0.3-0.9-0.8C387,194.2,387.7,194.4,388.2,194.2z"/>
			<path fill="#DDE2E9" d="M335.3,111.8c-0.4-0.3,0-0.7-0.1-0.9c-0.1-0.3-0.6-0.5-0.2-0.8c0.4-0.2,0.7,0.2,1,0.5
				c0.4,0.4,0,0.5-0.2,0.7C335.6,111.5,335.5,111.6,335.3,111.8z"/>
			<path fill="#F4F5F7" d="M333.2,122.2c-0.4,0.3-0.5-0.1-0.6-0.3c-0.3-0.3-1-0.3-0.6-0.9c0.5-0.7,0.7,0,1,0.3
				c0.1,0.1,0.4-0.1,0.5-0.1C333.7,121.6,333.5,121.9,333.2,122.2z"/>
			<path fill="#D5DCE4" d="M387.2,193.4c-0.2-0.1-0.4,1.3-0.9,0.3c-0.1-0.3-0.5-0.9-0.2-1.5C386.9,192.2,386.4,193.7,387.2,193.4z"
				/>
			<path fill="#F4F5F7" d="M338.2,98.1c0.2,0,0.4,0,0.4,0c0.6-0.6,0.6-0.6,1.5,0.1c-0.5,0.2-1.1,0.3-1.6,0.5
				c-0.2,0.1-0.5,0.3-0.6,0.1C337.8,98.6,338,98.3,338.2,98.1z"/>
			<path fill="#F1F3F5" d="M399.4,188.2c0.3,0.3,0.3,0.5,0,0.7c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3-0.1-0.6-0.3-0.9-0.6
				c-0.3-0.1-0.8,0.1-0.7-0.4c0.1-0.5,0.5-0.2,0.8-0.1C398.7,188,399,188.1,399.4,188.2z"/>
			<path fill="#D9DFE7" d="M385.9,174.9c0.7-0.3,0.6,0.7,1.2,0.7c-0.5,0.1-0.7,1-1.3,0.5C385.5,175.9,385.2,175.3,385.9,174.9
				c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.3,0,0.4c0-0.1,0-0.2,0-0.3C386.1,175.1,386,175,385.9,174.9z"/>
			<path fill="#EBEEF2" d="M336,113.4c0-1.1,0.9-1.1,1.5-1.5c0.1-0.1,0.3,0.2,0.3,0.4c0,0.2-0.2,0.4-0.4,0.4
				C336.7,112.4,336.4,113,336,113.4z"/>
			<path fill="#FDFDFD" d="M392.5,189.8c0.3,0.6,0.5,1,0.7,1.4c0.1,0.2,0.2,0.4,0,0.6c-0.2,0.2-0.5,0-0.6-0.1
				c-0.2-0.1-0.5-0.2-0.1-0.6C392.8,190.8,392.2,190.4,392.5,189.8z"/>
			<path fill="#EBEEF1" d="M392.7,186.3c0.1-0.8,0.3-1.4-0.3-2.1C393.8,184.7,393.9,185.1,392.7,186.3z"/>
			<path fill="#EBEEF2" d="M343.3,101.8c1.1-0.4,0.5-1.7,1.2-2.2C344.7,100.9,344.5,101.4,343.3,101.8z"/>
			<path fill="#DCE2E9" d="M393,167.9c-0.3-0.2-1,0.5-1-0.4c0-0.3-0.1-0.9,0.3-0.9C392.9,166.6,392.7,167.4,393,167.9z"/>
			<path fill="#DCE2E9" d="M395.1,177.4c-0.7-0.4-0.8-0.9-0.5-1.6c0.1-0.3,0.5-0.2,0.5-0.1c-0.4,0.7,0.5,0.9,0.4,1.5
				C395.4,177.3,395.3,177.5,395.1,177.4z"/>
			<path fill="#F0F2F5" d="M387.9,181.4c-0.2-0.4-0.3-0.8-0.1-1.4c0.2,0.7,1.6,0.5,0.8,1.6C388.2,181.9,388,181.7,387.9,181.4z"/>
			<path fill="#EDEFF3" d="M395.7,191.6c0,0.7-0.5,0.4-0.8,0.5c-0.6,0.4-0.7-0.3-0.7-0.4c-0.1-0.6,0.5-0.3,0.8-0.5
				C395.4,190.9,395.5,191.5,395.7,191.6z"/>
			<path fill="#E8EBEF" d="M387.4,205.2c-0.1,0.1-0.3,0.2-0.3,0.3c0,0.3,0.6,0.7-0.1,0.9c-0.6,0.2-0.5-0.5-0.8-0.8
				c-0.2-0.3-0.2-0.4,0.2-0.5C386.7,204.9,387.1,204.9,387.4,205.2z"/>
			<path fill="#F2F4F6" d="M384.3,181c-0.4,0.5-0.6,0.9-0.6,1.5c0,0.2-0.1,0.4-0.3,0.4c-0.3,0-0.2-0.3-0.2-0.4
				C383.2,181.7,383.5,181.1,384.3,181z"/>
			<path fill="#E6EAEF" d="M390.8,171.3c-0.2-0.2-0.6-0.5-0.5-0.6c0.1-0.4,0.5-0.6,0.8-0.7c0.3-0.1,0.4,0.2,0.4,0.5
				C391.4,170.9,391.1,171,390.8,171.3z"/>
			<path fill="#C7D2DD" d="M387.4,205.2c-0.3,0-0.6,0-1,0c-0.1-0.3-0.2-0.6-0.3-0.8C386.5,204.7,387.8,203.8,387.4,205.2z"/>
			<path fill="#F3F5F7" d="M340.1,98.2c0.3-0.1,0.5-0.2,0.5,0.2c0.3,0.2,0.8,0.3,0.3,0.8c-0.8-0.1-0.1,0.5-0.3,0.7
				C338.9,99.7,340.4,98.7,340.1,98.2z"/>
			<path fill="#EEF1F4" d="M382.6,204.5c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.1-0.2,1.1-0.8,0.7c-0.4-0.2-0.3-1,0.3-1.3
				C382.2,204,382.6,203.9,382.6,204.5z"/>
			<path fill="#ECEFF2" d="M397,181.9c0.1-0.4-0.1-0.9,0.4-1c0.2-0.1,0.6,0.3,0.5,0.5c-0.1,0.4-0.2,0.9-0.9,0.8
				C396.9,182.1,396.9,182.1,397,181.9C396.9,181.9,397,181.9,397,181.9z"/>
			<path fill="#E8EBEF" d="M386.5,185.7c0.2,0,0.5-0.3,0.6,0.1c0,0.3-0.2,0.4-0.5,0.3c-0.1,0-0.2,0-0.2,0c-0.3,0-0.4,0.7-0.8,0.3
				c-0.1-0.1-0.1-0.3,0-0.4C385.7,185.6,386.2,185.8,386.5,185.7z"/>
			<path fill="#FAFBFB" d="M393.4,178.8c0.5,0.4,0.8-0.1,1.2-0.2l0,0c0.1,0.2,0.4,0.5,0,0.7c-0.4,0.2-0.7,0.6-1.2,0.2
				c-0.7,0-0.3-0.4-0.3-0.7C393.2,178.7,393.3,178.7,393.4,178.8z"/>
			<path fill="#C7D1DD" d="M334.6,107c0.1-0.2,0.3-0.4,0.3-0.5c-0.1-0.3-0.3-0.8,0.2-0.9c0.6-0.1,0.6,0.5,0.7,0.9c0,0,0,0.1-0.1,0.1
				c-0.1,0.1-0.2,0.3-0.4,0.4C335.1,107,334.8,107,334.6,107z"/>
			<path fill="#F0F2F4" d="M382.1,183c0.2,0.2,0.1,0.4,0,0.5c-0.3,0.3-0.2,1-0.9,0.9c-0.1,0-0.3-0.3-0.2-0.3
				C381.3,183.6,381.4,183,382.1,183z"/>
			<path fill="#F6F7F9" d="M392.4,195.6c-0.7-0.6-0.5,1.1-1.2,0.5c-0.2-0.2-0.2-0.5,0-0.7C391.6,194.9,392,195.2,392.4,195.6z"/>
			<path fill="#ECEFF2" d="M345.2,110.8c-0.2,0.2,0.4,0.8-0.2,0.7c-0.5-0.1-0.1-0.8-0.5-1.2c-0.1-0.1,0.1-0.6,0.3-0.5
				C345.2,110,345.3,110.4,345.2,110.8z"/>
			<path fill="#E1E6EB" d="M381.4,186c0-0.8,0.3-1.1,0.8-1.2c0.4-0.1,0.3,0.3,0.3,0.5C382.5,186.1,381.8,185.6,381.4,186z"/>
			<path fill="#CAD4DE" d="M389.4,166.8c0.1,0.5,0.6,1,0.1,1.5c-0.1,0.1-0.3,0-0.3,0C388.7,167.7,389.2,167.3,389.4,166.8z"/>
			<path fill="#D7DEE6" d="M333.2,122.2c0.1-0.3,0.2-0.6,0.2-0.9c0.1-0.2,0.1-0.5,0.2-0.5c0.5,0,0.2,0.4,0.4,0.7
				c0.2,0.3,0.3,0.6-0.2,0.7C333.6,122.2,333.4,122.2,333.2,122.2z"/>
			<path fill="#D2DAE3" d="M344.2,116.6c0.4,0.5,1.1-0.4,1.5,0.4C345.3,116.4,344.3,118.9,344.2,116.6L344.2,116.6z"/>
			<path fill="#DEE4EA" d="M398.9,185.2c-0.9-0.5-0.3-1.2-0.6-1.7c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.3,0.3
				C398.9,184.2,398.9,184.6,398.9,185.2z"/>
			<path fill="#F7F7F9" d="M333.9,115.2c0,0.2,0.3,0.4,0,0.5c-0.3-0.1-0.7,0.4-0.9-0.1c-0.1-0.2-0.1-0.6,0.1-0.8
				C333.4,114.6,333.6,115,333.9,115.2z"/>
			<path fill="#D3DBE4" d="M395.5,170.1c-0.8-0.4-0.7-0.9-0.5-1.3c0.2-0.3,0.7-0.1,0.8,0.3C395.8,169.4,395,169.4,395.5,170.1z"/>
			<path fill="#F1F3F5" d="M394.3,190.1c0.4-0.1,0.5-0.8,1-0.5c0.5,0.3-0.1,0.8-0.1,1.2C395,190.6,394.6,190.3,394.3,190.1
				L394.3,190.1z"/>
			<path fill="#E4E8ED" d="M347.9,108.1c0.4,0.2,1,0.1,1.2,0.7c0,0.1-0.2,0.3-0.3,0.3C348.3,109,348,108.7,347.9,108.1z"/>
			<path fill="#DFE5EA" d="M400.4,169.9c0,0.2,0,0.3-0.1,0.4c-0.4,0.3-0.4,0.9-0.9,1c-0.1,0-0.2-0.2-0.2-0.2
				C399.5,170.6,399.7,170.1,400.4,169.9z"/>
			<path fill="#D7DEE6" d="M387.1,171.8c-0.6,0.3-1.1,0.6-1.9,0.5C385.8,171.7,386.3,171.3,387.1,171.8z"/>
			<path fill="#F9FAFB" d="M381.8,188.5c0.2,0,0.5,0.1,0.4,0.3c-0.1,0.4-0.4,0.6-0.8,0.6c-0.2,0-0.3-0.2-0.3-0.4
				C381.2,188.6,381.5,188.6,381.8,188.5z"/>
			<path fill="#F1F3F5" d="M398.5,177.3c-0.5,0.3-0.5,0.9-1.1,0.7c-0.2-0.1-0.2-0.3-0.2-0.5C397.5,176.9,397.9,177.1,398.5,177.3z"
				/>
			<path fill="#D8DFE7" d="M394.8,196.3c-0.2,0-0.3,0-0.5,0c-0.1,0.1-0.2,0.1-0.2,0c0.2-0.4,0.3-0.7-0.2-1c-0.2-0.1-0.6-0.3-0.3-0.6
				c0.3-0.3,0.4,0.2,0.5,0.3C394.7,195.3,394.6,195.9,394.8,196.3z"/>
			<path fill="#F3F5F7" d="M336.8,125.3c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.1-0.6,0-0.4-0.4c0.1-0.2,0.3-0.3,0.4-0.4
				c0.2-0.1,0.3,0.2,0.4,0.3c0.1,0.3,0.2,0.7,0.3,1C337.1,125.4,336.9,125.4,336.8,125.3z"/>
			<path fill="#D2DAE3" d="M389.8,182.4c0.4,0.1,0.9,0.6,1.4,0c0.1-0.1,0.2-0.1,0.3,0c0.1,0.2,0.1,0.4-0.1,0.5
				c-0.3,0.2-0.7,0.1-1,0.1C389.7,183.2,389.6,182.9,389.8,182.4z"/>
			<path fill="#E0E5EB" d="M334.6,109.2c0.3,0.4,0,0.7-0.3,0.9c-0.1,0.1-0.4,0-0.4-0.2c-0.1-0.5-0.3-1,0.4-1.2c0,0,0.1,0.1,0.1,0.1
				C334.4,109,334.3,109.2,334.6,109.2z"/>
			<path fill="#F1F3F6" d="M382.8,177c0.1,0.6,0,1-0.5,1.2c-0.2,0.1-0.3,0-0.4-0.2C381.8,177.4,382.4,177.4,382.8,177z"/>
			<path fill="#DCE2E9" d="M387.4,184.1c0,0.3,0.3,0.6,0.1,1.1c-0.5-0.1-0.8-0.5-0.9-0.9C386.6,183.9,387.1,184.2,387.4,184.1
				L387.4,184.1z"/>
			<path fill="#E5E9EE" d="M394.1,196.3c0.1,0,0.2,0,0.2,0c0,0.1,0,0.2,0,0.3c-0.5,0.1-1,0.6-1.6-0.1
				C393.2,196.2,393.6,196,394.1,196.3z"/>
			<path fill="#E6EAEF" d="M396.8,193.3c-0.6,0.1-1,0.3-1.2-0.2c-0.1-0.2-0.1-0.5,0.2-0.5C396.2,192.6,396.4,192.9,396.8,193.3z"/>
			<path fill="#D0D8E2" d="M340.6,99.8c-0.1-0.3-0.8-0.8,0.2-0.7c0.2,0,0.3-0.2,0.5-0.2C341.7,99.6,341.5,100,340.6,99.8z"/>
			<path fill="#CFD8E1" d="M396,174.5c-0.2-0.2-0.2-0.5-0.2-0.7l0,0c0.1-0.2,0.9,0.5,0.6-0.5c-0.1-0.4,0.5-0.1,0.6,0
				c0.1,0.3,0,0.6,0,0.9C396.6,174.3,396.3,174.4,396,174.5z"/>
			<path fill="#E0E5EB" d="M395.6,199c-0.4-0.4,0.1-0.7,0.1-1.1c0-0.4,0.4-0.1,0.4,0C396.4,198.5,396.2,198.9,395.6,199L395.6,199z"
				/>
			<path fill="#F1F3F6" d="M392.1,183.3c0.3-0.5,0.5-0.8,1-0.5c0.2,0.1,0.2,0.3,0.1,0.4C392.9,183.8,392.5,183.1,392.1,183.3z"/>
			<path fill="#EAEDF1" d="M395.1,177.4c0.1-0.1,0.3-0.2,0.4-0.2c-0.1,0.6-0.4,1.1-1,1.4c0,0,0,0,0,0
				C394.1,177.9,394.8,177.7,395.1,177.4z"/>
			<path fill="#DCE2E9" d="M355.8,108.3c-0.1,0.3-0.4,0.4-0.7,0.4c-0.3,0-0.5-0.2-0.4-0.4c0.1-0.3,0.4-0.4,0.6-0.3
				C355.6,108,355.8,108,355.8,108.3z"/>
			<path fill="#ECEFF2" d="M392.9,172.4c0,0.4,0,0.7-0.1,0.8c-0.4,0.3,0.2,1.1-0.4,1c-0.4-0.1,0-0.8,0-1.3
				C392.4,172.7,392.7,172.6,392.9,172.4z"/>
			<path fill="#F8F9FA" d="M339.9,104.4c0-0.1,0.1-0.2,0.1-0.2c0.1-0.3-0.2-0.7,0.3-0.9c0.1-0.1,0.4-0.1,0.4,0.1
				c0,0.4,0.1,0.8-0.3,1C340.2,104.5,340,104.5,339.9,104.4z"/>
			<path fill="#DCE2E8" d="M353.3,102.7c-0.4,0.3-0.5,0.8-1,0.7c-0.2,0-0.2-0.2-0.1-0.4C352.4,102.5,352.7,102.4,353.3,102.7z"/>
			<path fill="#EDF0F3" d="M350.3,100.8c-0.3,0-0.5-0.1-0.5-0.4c-0.1-0.3,0.2-0.4,0.4-0.4c0.3,0,0.6,0,0.6,0.4
				C350.8,100.6,350.6,100.7,350.3,100.8z"/>
			<path fill="#C7D2DD" d="M384.9,169.5c0.4-0.3,0.2-1,0.7-1.1c0.2,0,0.3,0.2,0.3,0.3C385.7,169.2,385.4,169.5,384.9,169.5z"/>
			<path fill="#DFE4EA" d="M339.1,111.1c0.2-0.6-0.4-0.5-0.7-0.7c0.3-0.2,0.7-0.6,0.9-0.3C339.5,110.3,340,110.9,339.1,111.1
				L339.1,111.1z"/>
			<path fill="#F6F7F9" d="M392.7,197.4c0,0.3,0,0.5-0.3,0.4c-0.3-0.1-0.5-0.2-0.6-0.5c-0.1-0.3,0.1-0.4,0.4-0.4
				C392.5,196.9,392.6,197.2,392.7,197.4z"/>
			<path fill="#EEF1F4" d="M402.5,160.5c0.3,0.4,0.6,0.8,0.3,1.3c0,0.1-0.3,0.1-0.3,0C402.1,161.4,402.3,160.9,402.5,160.5z"/>
			<path fill="#EDF0F3" d="M378,189c0.9,0.2,1.1,0.7,1,1.2c-0.1,0.5-0.5,0.3-0.5,0C378.4,189.8,378.4,189.5,378,189z"/>
			<path fill="#C7D2DD" d="M341.6,118.2c0.5-0.1,0.9-0.1,1.4-0.2C342.9,118.6,342.9,118.6,341.6,118.2z"/>
			<path fill="#CBD5DF" d="M371.3,201.3c-0.6-0.2-1-0.3-1.1-0.9c0-0.1,0.2-0.2,0.2-0.2C371,200.2,371.1,200.7,371.3,201.3z"/>
			<path fill="#F0F2F5" d="M390.5,200.8c-0.1,0.3-0.3,0.3-0.5,0.3c-0.3,0-0.4-0.2-0.4-0.5c0-0.2,0.1-0.4,0.4-0.3
				C390.2,200.3,390.5,200.4,390.5,200.8z"/>
			<path fill="#DAE0E7" d="M337,116.2c0.2,0.1,0.5,0.1,0.9,0c-0.1,0.6-0.7,0.8-0.5,1.4c-0.5-0.3-0.3-0.6-0.4-0.9
				C336.8,116.5,336.8,116.3,337,116.2z"/>
			<path fill="#F6F7F9" d="M400.9,179.2c-0.6,0-1,0-1.2-0.4c0-0.1,0.1-0.4,0.1-0.4C400.3,178.4,400.5,178.7,400.9,179.2z"/>
			<path fill="#F4F5F8" d="M390.3,188.2c0.2,0,0.4,0,0.5,0.1c0.2,0.1,0.2,0.4,0.3,0.6c0,0-0.1,0.2-0.2,0.2c-0.3,0-0.5-0.1-0.6-0.4
				C390.3,188.5,390.3,188.3,390.3,188.2L390.3,188.2z"/>
			<path fill="#E0E5EB" d="M334.3,108.7c0.2-0.2,0.2-0.8,0.7-0.5c0.2,0.1,0.4,0.4,0.3,0.6c-0.1,0.4-0.5,0.2-0.7,0.3
				C334.5,109,334.4,108.8,334.3,108.7L334.3,108.7z"/>
			<path fill="#F9FAFB" d="M379.9,167.5c-0.1,0.4-0.5,0.3-0.8,0.4c-0.2,0-0.3-0.1-0.3-0.3c0.1-0.3,0.3-0.5,0.7-0.5
				C379.8,167.1,379.9,167.2,379.9,167.5z"/>
			<path fill="#D9DFE7" d="M394.1,181.7c0.2,0,0.4,0,0.5-0.2c0.2-0.1,0.3-0.6,0.6-0.2c0.1,0.1,0.2,0.3,0.2,0.4
				c-0.1,0.3-0.4,0.3-0.6,0.3C394.6,181.9,394.3,182,394.1,181.7z"/>
			<path fill="#D8DFE7" d="M375,200.1c-0.3-0.3-1,0.3-1.2-0.4c0-0.1,0.1-0.2,0.1-0.3C374.3,199.7,374.9,199.2,375,200.1z"/>
			<path fill="#E3E7EC" d="M393.4,178.8c-0.1,0-0.2,0-0.3,0c-0.1-0.6,0.3-1.1,0.6-1.6C394.1,177.9,393.5,178.3,393.4,178.8z"/>
			<path fill="#EBEEF2" d="M385.3,177.9c0.6-0.1,1-0.3,1.3,0.2c0.1,0.1-0.1,0.5-0.2,0.5C385.8,178.7,385.8,178.1,385.3,177.9z"/>
			<path fill="#F5F6F8" d="M393.8,202.6c-0.1,0.3-0.2,0.4-0.5,0.4c-0.3,0-0.4-0.2-0.5-0.4c-0.1-0.3,0.3-0.4,0.5-0.4
				C393.5,202.2,393.8,202.3,393.8,202.6z"/>
			<path fill="#FCFCFD" d="M333.9,124.1c-0.5,0.6-0.6,0.6-1.2-0.5c0.3-0.2,0.4,0.2,0.7,0.3C333.5,124,333.8,123.9,333.9,124.1z"/>
			<path fill="#F7F8FA" d="M396.9,166.9c0.3,0.1,0.4,0.3,0.3,0.5c0,0.2-0.1,0.4-0.4,0.4c-0.2,0-0.5-0.1-0.4-0.4
				C396.5,167.2,396.6,167,396.9,166.9z"/>
			<path fill="#DFE4EA" d="M392.4,176.9c0.1,1.3,0.1,1.3-0.7,1c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.2l0,0
				C391.9,176.9,392.2,176.9,392.4,176.9L392.4,176.9z"/>
			<path fill="#F6F7F9" d="M341.1,98.2c0.2-0.4,0.8-0.1,1-0.5c0.2-0.1,0.5-0.2,0.5,0.2c0,0.5-0.3,0.5-0.7,0.5
				C341.6,98.2,341.3,98.4,341.1,98.2z"/>
			<path fill="#D0D8E2" d="M391.4,206.2c0.5,0.2,0.9-0.2,1.1,0.2c0.1,0.1-0.1,0.4-0.2,0.5C391.9,207.1,391.7,206.8,391.4,206.2z"/>
			<path fill="#DCE2E9" d="M387.9,181.4c0.2,0.1,0.5,0.2,0.7,0.3c-0.3,0.3,0.2,0.8-0.2,1.1C388,182.4,387.9,182,387.9,181.4z"/>
			<path fill="#EAEDF1" d="M386.8,191.1c-0.3-0.1-0.4-0.2-0.5-0.5c-0.1-0.3,0.1-0.3,0.3-0.3c0.3,0,0.5,0.1,0.5,0.4
				C387.1,190.9,387,191.1,386.8,191.1z"/>
			<path fill="#CDD6E0" d="M335.8,106.6c0.2-0.2,0.3-0.3,0.5-0.5c1,0,1,0,0.7,0.7c-0.4-0.4-0.7,0.1-1,0.2c-0.1,0-0.2,0-0.2,0
				C335.7,106.9,335.6,106.7,335.8,106.6L335.8,106.6z"/>
			<path fill="#D8DEE6" d="M376.2,191.9c0.3,0.4,0.5,0.7,0.2,1.2c0,0.1-0.3,0.1-0.3,0C375.8,192.7,376.2,192.4,376.2,191.9z"/>
			<path fill="#D4DBE4" d="M345.8,114.2c-0.1,0.4-0.3,0.7-0.4,1.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.5,0.4-0.6,0.4-1.1
				C345.5,114.1,345.7,114.2,345.8,114.2z"/>
			<path fill="#F7F7F9" d="M341,109c-0.1,0.6-0.1,0.9-0.6,0.9c-0.2,0-0.3-0.2-0.3-0.3C340.2,109.2,340.6,109.2,341,109z"/>
			<path fill="#CCD5E0" d="M343.1,107c-0.5,0.3-0.7,0.7-1.1,0.6c-0.1,0-0.2-0.4-0.1-0.5C342.1,106.8,342.5,107,343.1,107z"/>
			<path fill="#D3DBE4" d="M340.3,106.9c-0.3,0-0.4,0.7-0.9,0.4c-0.1-0.1-0.1-0.3-0.1-0.4C339.7,106.3,340,106.9,340.3,106.9z"/>
			<path fill="#FEFEFE" d="M331.9,106.3c0.7,0.5,0.7,0.5,0.2,0.7c-0.2,0.1-0.2,0.3-0.2,0.5C331.2,107.1,331.9,106.7,331.9,106.3z"/>
			<path fill="#E0E5EB" d="M344.6,103.7c0,0.6,0,1-0.4,1c-0.1,0-0.3-0.1-0.3-0.1C343.8,104.1,344.4,104.2,344.6,103.7z"/>
			<path fill="#E8ECF0" d="M339.7,103c-0.1-0.3-0.2-0.7,0.2-0.9c0.1,0,0.3,0.1,0.3,0.2C340.4,102.7,340.1,102.9,339.7,103z"/>
			<path fill="#FCFCFC" d="M341.5,102.2c0,0.2-0.1,0.4-0.3,0.4c-0.3,0-0.4-0.4-0.4-0.6c0-0.2,0.1-0.3,0.2-0.3
				C341.2,101.7,341.4,101.9,341.5,102.2z"/>
			<path fill="#E5E9EF" d="M348.3,100.6c-1-0.4,0.1-0.7-0.1-1.1C348.9,99.9,348.4,100.2,348.3,100.6L348.3,100.6z"/>
			<path fill="#C7D2DD" d="M386.7,187.4c0.1-0.6,0.5-0.9,1.1-1.1c0,0.6-0.7,0.6-0.6,1.1C387,187.6,386.8,187.6,386.7,187.4z"/>
			<path fill="#D6DDE5" d="M378.4,186c-0.6-0.2-1-0.3-1-0.8c0-0.1,0.2-0.2,0.3-0.2C378.3,185,378,185.5,378.4,186z"/>
			<path fill="#DEE3EA" d="M389.9,180.5c0.3,0,0.3,0.4,0.3,0.5c0,0.3-0.4,0.2-0.5,0.2c-0.2,0.1-0.4-0.1-0.3-0.3
				C389.5,180.7,389.7,180.6,389.9,180.5z"/>
			<path fill="#D6DDE5" d="M396,180.1c0,0,0.1,0.2,0.1,0.3c0.1,0.4-0.3,0.4-0.5,0.5c-0.2,0.1-0.3-0.1-0.2-0.3
				C395.5,180.4,395.5,180.1,396,180.1z"/>
			<path fill="#F2F4F6" d="M396,174.5c0.2-0.7,0.6-0.4,1-0.2C396.7,174.6,396.5,175.2,396,174.5z"/>
			<path fill="#E8ECF0" d="M389.8,196.1c-0.2,0.1-0.3,0.2-0.5,0.3c0-0.5,0-0.9-0.5-1.4C389.7,195.1,389.8,195.5,389.8,196.1z"/>
			<path fill="#D4DBE4" d="M379.8,172.2c0-0.5,0.1-0.9,0.4-1c0.1,0,0.3,0.1,0.3,0.1C380.6,171.8,380.2,172,379.8,172.2z"/>
			<path fill="#F8F9FA" d="M401.8,169c-0.4,0.2-0.7,0.4-1,0c0,0,0-0.2,0.1-0.3C401.2,168.3,401.4,168.7,401.8,169z"/>
			<path fill="#D9E0E7" d="M377,200.3c0,0.2-0.2,0.3-0.3,0.4c-0.2,0.1-0.4,0-0.4-0.2c-0.1-0.3,0.1-0.4,0.4-0.5
				C376.8,200,376.9,200.1,377,200.3z"/>
			<path fill="#D1D9E3" d="M382.6,203.2c-0.3-0.1-0.6-0.1-0.6-0.4c0-0.3,0.3-0.2,0.5-0.2c0.2,0,0.4-0.1,0.4,0.2
				C382.9,203,382.7,203.2,382.6,203.2z"/>
			<path fill="#F3F4F7" d="M381.3,162.4c0,0.3-0.2,0.3-0.4,0.4c-0.3,0.1-0.4-0.2-0.4-0.4c0-0.2,0.1-0.3,0.2-0.3
				C381,162,381.2,162.1,381.3,162.4z"/>
			<path fill="#D9E0E7" d="M336.8,125.3c0.2,0,0.3,0,0.5,0c0,0.3,0.1,0.7,0.1,1.1C336.3,126.4,337,125.6,336.8,125.3z"/>
			<path fill="#D1D9E2" d="M347.1,103.7c0.4-0.2,0.7-0.4,1.2-0.3C347.9,103.8,347.6,104.4,347.1,103.7L347.1,103.7z"/>
			<path fill="#CFD8E2" d="M341.8,112.6c0.3-0.6,0.9-0.5,1.8-0.8C342.9,112.5,342.3,112.4,341.8,112.6L341.8,112.6z"/>
			<path fill="#DCE2E9" d="M387.4,184.1c0.1-1,0.1-1,0.9-0.8C387.9,183.6,387.7,183.8,387.4,184.1
				C387.4,184.1,387.4,184.1,387.4,184.1z"/>
			<path fill="#EDEFF3" d="M340.6,100.9c-0.5,0.1-0.8-0.1-1.2-0.5C340,100.3,340.3,100.3,340.6,100.9z"/>
			<path fill="#ECEEF2" d="M392.3,179.7c0,0.3-0.2,0.3-0.4,0.4c-0.3,0.1-0.3-0.2-0.4-0.3c-0.1-0.2,0.1-0.4,0.3-0.4
				C392.2,179.3,392.3,179.5,392.3,179.7z"/>
			<path fill="#D5DCE4" d="M387.8,203.1c0.7,0.2,0.6,0.6,0.7,1c-0.1,0-0.2,0.1-0.3,0C387.6,203.9,388,203.5,387.8,203.1z"/>
			<path fill="#EFF1F4" d="M331.2,124.5c0.3-0.2,0.1-0.8,0.6-0.8c0.1,0,0.2,0.2,0.2,0.3C331.9,124.3,331.7,124.5,331.2,124.5z"/>
			<path fill="#E0E5EB" d="M346.3,102.8c0,0.2-0.1,0.5-0.4,0.5c-0.2,0-0.2-0.2-0.2-0.3c0.1-0.2-0.1-0.5,0.3-0.5
				C346.2,102.5,346.3,102.7,346.3,102.8z"/>
			<path fill="#D9DFE7" d="M338.3,123.5c-0.1-0.4,0.1-0.7,0.5-0.8c0.1,0,0.2,0.2,0.2,0.2C338.9,123.1,338.7,123.5,338.3,123.5z"/>
			<path fill="#E9ECF0" d="M339.5,117.1c-0.3,0.2-0.7,0.5-1,0.7C338.6,117.2,338.9,116.9,339.5,117.1z"/>
			<path fill="#E3E7EC" d="M383.5,172.4c-0.3,0.6-0.4,0.9-0.6,1.2C382.7,173,382.7,173,383.5,172.4z"/>
			<path fill="#ECEFF2" d="M377.7,170.7c-0.2,0-0.3-0.2-0.4-0.4c0-0.2,0-0.4,0.3-0.5c0.3-0.1,0.3,0.2,0.4,0.4
				C378,170.5,377.9,170.6,377.7,170.7z"/>
			<path fill="#D5DCE5" d="M387.6,173.4c0.4,0.2,0.8,0.3,0.8,0.8c0,0.1-0.2,0.2-0.3,0.2C387.7,174.1,387.8,173.7,387.6,173.4z"/>
			<path fill="#F5F7F8" d="M387.9,208.7c-0.5,0.1-0.9,0.3-1.3,0.1c0,0,0-0.2,0-0.2C386.9,208.4,387.3,208.2,387.9,208.7z"/>
			<path fill="#F0F2F4" d="M380.1,208.4c-0.1,0.2-0.1,0.5-0.4,0.5c-0.1,0-0.3-0.2-0.3-0.3c-0.1-0.3,0.2-0.4,0.4-0.4
				C380,208.1,380.1,208.2,380.1,208.4z"/>
			<path fill="#E2E6EC" d="M341.1,98.2c0.3,0,0.5,0,0.7,0.2c-0.3,0-0.5,0.2-0.5,0.5c-0.1,0.1-0.1,0.5-0.5,0.2
				c0.3-0.4-0.1-0.5-0.2-0.7C340.8,98.4,341,98.3,341.1,98.2z"/>
			<path fill="#D2DAE3" d="M344.2,116.6c-0.6-0.1-0.3-0.8-0.6-1C344.3,115.7,344.2,116.2,344.2,116.6
				C344.2,116.6,344.2,116.6,344.2,116.6z"/>
			<path fill="#F7F8F9" d="M396.2,186.3c0.3-0.2,0.6-0.1,0.9,0.1c0,0.1,0,0.3-0.1,0.3C396.6,186.9,396.3,186.7,396.2,186.3z"/>
			<path fill="#D9E0E7" d="M390.3,188.2c-0.4-0.3-0.9-0.3-1.3-1C389.7,187.4,390.2,187.6,390.3,188.2
				C390.3,188.2,390.3,188.2,390.3,188.2z"/>
			<path fill="#F7F9FA" d="M392.2,187.7c0,0.3-0.2,0.5-0.5,0.4c-0.2-0.1-0.2-0.3-0.2-0.5c0-0.4,0.3-0.3,0.5-0.2
				C392,187.5,392.2,187.5,392.2,187.7z"/>
			<path fill="#DEE4EA" d="M383.1,191.3c-0.2,0.3-0.3,0.6-0.7,0.8C382.3,191.5,382.4,191.2,383.1,191.3z"/>
			<path fill="#EDF0F3" d="M397.2,192.4c-0.3-0.3-0.4-0.7-0.2-1c0,0,0.2,0,0.2,0.1C397.6,191.7,397.2,192.1,397.2,192.4z"/>
			<path fill="#CCD5DF" d="M381.8,193.4c-0.5-0.5-0.4-0.9-0.3-1.5C381.8,192.5,381.8,192.8,381.8,193.4z"/>
			<path fill="#F0F2F5" d="M380.7,193.5c0.1,0.3,0.2,0.5,0.4,0.8c-0.1,0-0.2,0.1-0.3,0.1C380.3,194.2,380.5,193.9,380.7,193.5z"/>
			<path fill="#F1F3F5" d="M399.9,172.8c-0.2,0.1-0.3,0.1-0.5,0.2c0-0.3,0.1-0.5,0.3-0.6c0.2-0.1,0.4-0.4,0.6-0.1
				C400.4,172.5,400.1,172.7,399.9,172.8z"/>
			<path fill="#E7EAEF" d="M384.6,171.6c0.1,0.2,0,0.4-0.2,0.4c-0.3,0.1-0.3-0.1-0.3-0.3c0-0.2,0-0.3,0.2-0.4
				C384.5,171.2,384.5,171.5,384.6,171.6z"/>
			<path fill="#C9D3DE" d="M380.9,173.2c0.6,0.6,0.7,1,0.5,1.6C381.2,174.3,381.1,173.9,380.9,173.2z"/>
			<path fill="#EAEDF1" d="M375.4,169.2c-0.2-0.1-0.3-0.3-0.2-0.5c0.1-0.2,0.4,0,0.5,0c0.3,0.1,0.4,0.3,0.1,0.5
				C375.7,169.3,375.5,169.3,375.4,169.2z"/>
			<path fill="#DBE1E8" d="M382.7,174.5c0.3-0.1,0.6-0.4,0.8-0.1c0,0.1,0,0.3,0,0.3C383.1,175.1,383,174.6,382.7,174.5z"/>
			<path fill="#EFF1F4" d="M393.8,165.8c-0.1,0.1-0.2,0.4-0.3,0.4c-0.3,0-0.3-0.3-0.3-0.5c0-0.1,0-0.4,0.2-0.3
				C393.5,165.5,393.9,165.3,393.8,165.8z"/>
			<path fill="#E3E7ED" d="M388.5,175.3c0.8,0,1.1,0.2,1.2,0.8C389.2,176,389,175.8,388.5,175.3z"/>
			<path fill="#E2E7EC" d="M381.5,176c0.2,0,0.3,0.1,0.3,0.3c-0.1,0.2-0.2,0.4-0.5,0.4c0,0-0.1-0.1-0.2-0.2
				C381.1,176.2,381.2,176,381.5,176z"/>
			<path fill="#D0D9E2" d="M394.8,159.6c0.1,0.3,0.6,0.3,0.4,0.8c-0.1,0.2-0.3,0.2-0.4,0C394.6,160.1,394.5,159.9,394.8,159.6
				L394.8,159.6z"/>
			<path fill="#F5F7F8" d="M390.9,178.7c-0.2,0.5-0.7,0.3-0.9,0.5C390.2,178.7,390.4,178.5,390.9,178.7z"/>
			<path fill="#CAD4DF" d="M333.3,135c0,0.2-0.2,0.3-0.3,0.2c-0.2-0.1-0.4-0.1-0.4-0.3c0-0.1,0.2-0.2,0.4-0.2
				C333.2,134.7,333.4,134.8,333.3,135z"/>
			<path fill="#D0D8E2" d="M330.1,132c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0-0.4,0.3-0.5c0.2,0,0.3,0,0.4,0.2
				C330.4,131.8,330.2,131.9,330.1,132z"/>
			<path fill="#F7F8F9" d="M395.4,184.5c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.2,0.2-0.4,0.4-0.3c0.2,0.1,0.3,0.3,0.2,0.5
				C395.7,184.4,395.6,184.5,395.4,184.5z"/>
			<path fill="#D4DCE4" d="M389.8,184c-0.4,0.3-0.4,0.7-0.8,0.8c0,0-0.2-0.2-0.2-0.3C389,184.2,389.3,184.1,389.8,184z"/>
			<path fill="#D5DCE5" d="M330.9,119.6c0.4,0.5-0.2,0.9,0.1,1.3C330.7,120.5,330.5,120.1,330.9,119.6z"/>
			<path fill="#F1F3F6" d="M340.7,118.9c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.4-0.3c-0.1-0.2-0.1-0.3,0.1-0.3
				C340.5,118.6,340.6,118.7,340.7,118.9z"/>
			<path fill="#DFE4EA" d="M346.2,118.3c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.4-0.2-0.5-0.4
				C345.8,118.3,346,118.4,346.2,118.3z"/>
			<path fill="#DFE4EB" d="M382.6,204.5c-0.2-0.2-0.5-0.3-0.7-0.5c0.3-0.2,0.7-0.3,1-0.5c0.2,0.2,0.2,0.5,0,0.7
				C382.7,204.3,382.7,204.4,382.6,204.5z"/>
			<path fill="#E2E7ED" d="M384,205.1c0,0.3-0.2,0.5-0.4,0.5c-0.1,0-0.2-0.2-0.2-0.2c0-0.2,0.1-0.5,0.4-0.5
				C384,204.8,384,205,384,205.1z"/>
			<path fill="#E5E9EE" d="M336.6,104.7c0.3,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.2-0.3,0.2c-0.2,0-0.4-0.1-0.3-0.3
				C336.4,104.9,336.5,104.8,336.6,104.7z"/>
			<path fill="#D6DDE5" d="M348.3,100.6c0.2,0.2,0.7,0.1,0.6,0.6c0,0.1-0.3,0.1-0.4,0.1C347.9,101.1,348.2,100.8,348.3,100.6
				C348.3,100.6,348.3,100.6,348.3,100.6z"/>
			<path fill="#CED7E1" d="M379.5,206.7c0.1-0.3-0.5-0.4-0.2-0.8c0.1-0.1,0.3-0.1,0.4,0c0.4,0.3,0.2,0.5,0.1,0.8
				C379.6,206.7,379.6,206.7,379.5,206.7z"/>
			<path fill="#D9DFE7" d="M393.7,199c0.3-0.1,0.5-0.4,0.8-0.1c0.1,0.1,0.1,0.3-0.1,0.4C394.1,199.5,394,199.2,393.7,199z"/>
			<path fill="#EFF1F4" d="M379.5,206.7c0.1,0,0.2,0,0.2,0c0.2,0.2,0.4,0.4,0.2,0.7c-0.1,0.1-0.3,0.1-0.4,0
				C379.1,207.1,379.4,206.9,379.5,206.7z"/>
			<path fill="#E3E8ED" d="M349.1,94.1c-0.1,0.2-0.2,0.2-0.4,0.2c-0.2-0.1-0.4-0.2-0.4-0.5c0.1-0.2,0.3-0.1,0.4,0
				C348.9,93.8,349.2,93.9,349.1,94.1z"/>
			<path fill="#EFF1F4" d="M397,200.9c-0.3-0.1-0.7,0.2-0.8-0.2c-0.1-0.2,0.1-0.5,0.4-0.5C397.1,200.2,396.8,200.7,397,200.9
				C397,200.9,397,200.9,397,200.9z"/>
			<path fill="#E5E9EE" d="M343.5,97.1c0.2,0.1,0.2,0.2,0.2,0.4c-0.1,0.2-0.2,0.4-0.5,0.4c-0.2,0-0.1-0.2-0.1-0.4
				C343.2,97.3,343.2,97.1,343.5,97.1z"/>
			<path fill="#EAEDF1" d="M345.5,103.8c0.3,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.2-0.3,0.2c-0.2,0-0.4-0.1-0.3-0.3
				C345.3,103.9,345.4,103.8,345.5,103.8z"/>
			<path fill="#E4E8ED" d="M337.6,103.1c-0.3,0.1-0.4,0.6-0.7,0.3c-0.1-0.1-0.1-0.3,0-0.3C337.1,102.9,337.4,102.9,337.6,103.1z"/>
			<path fill="#E7EBEF" d="M393.4,179.5c0.4-0.1,0.8-0.2,1.2-0.2c-0.1,0.2-0.2,0.5-0.2,0.7c-0.2,0-0.4-0.2-0.5,0
				C393.7,179.9,393.5,179.7,393.4,179.5z"/>
			<path fill="#EAEDF1" d="M337.6,123.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.2-0.3c0-0.2,0.2-0.3,0.3-0.3
				C337.6,122.9,337.6,123,337.6,123.3z"/>
			<path fill="#E5E9EE" d="M397.5,180.2c0-0.6,0.2-0.8,0.7-0.5C398.2,180.3,397.8,180.2,397.5,180.2z"/>
			<path fill="#EEF0F3" d="M389.8,182.4c0,0.3,0.2,0.6,0.5,0.7c0,0.3-0.2,0.4-0.5,0.2C389.4,183.1,389.4,182.7,389.8,182.4z"/>
			<path fill="#E4E8ED" d="M382.5,180.5c0,0.3,0,0.4-0.2,0.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0-0.4,0.2-0.3
				C382.3,180.3,382.4,180.5,382.5,180.5z"/>
			<path fill="#EEF0F3" d="M377.7,178.5c-0.1,0.2-0.2,0.3-0.3,0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.2,0.2-0.2,0.3-0.2
				C377.7,178.2,377.8,178.3,377.7,178.5z"/>
			<path fill="#D0D9E2" d="M394.8,159.6c0-0.3-0.6-0.1-0.4-0.5c0.1-0.1,0.2-0.4,0.4-0.3C395.2,159,395.1,159.3,394.8,159.6
				L394.8,159.6z"/>
			<path fill="#DEE3EA" d="M388.6,162.2c0-0.4-0.1-0.7,0.5-0.9C389.2,161.7,388.8,161.9,388.6,162.2z"/>
			<path fill="#F5F6F8" d="M391.7,177.1c0.5,0.2,0.5,0.5,0,0.7C391.2,177.6,391.2,177.4,391.7,177.1z"/>
			<path fill="#D5DCE4" d="M375.4,169.2c0.2,0,0.3,0,0.5,0c0,0.4,0,0.7-0.5,0.9C375,169.8,375.8,169.5,375.4,169.2z"/>
			<path fill="#D1D9E2" d="M384.8,189.4c0.2,0.1,0.4,0.2,0.4,0.4c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.2-0.3-0.4
				C384.5,189.6,384.6,189.5,384.8,189.4z"/>
			<path fill="#D3DBE4" d="M398.2,188.4c0.4,0,0.7,0.2,1,0.5c0,0.2-0.1,0.4-0.2,0.5c-0.1-0.2-0.3-0.3-0.4-0.5
				C398.3,188.8,398.2,188.6,398.2,188.4z"/>
			<path fill="#CED7E1" d="M344.9,106.2c0,0.2-0.1,0.4-0.4,0.5c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.2-0.4,0.4-0.5
				C344.8,106,344.9,106.1,344.9,106.2z"/>
			<path fill="#E5E9EE" d="M352.6,105.8c0.1,0.4,0.2,0.6,0,0.9c0,0-0.2-0.1-0.3-0.1C352,106.2,352.3,106.1,352.6,105.8z"/>
			<path fill="#D6DEE6" d="M333.1,111.8c0-0.2,0-0.4-0.2-0.5c0.1-0.3,0.2-0.6,0.5-0.2C333.5,111.3,333.8,111.8,333.1,111.8z"/>
			<path fill="#D1D9E2" d="M371.7,198.6c-0.2,0-0.3-0.1-0.2-0.2c0.1-0.2,0.2-0.4,0.5-0.4c0.1,0,0.1,0.1,0.2,0.2
				C372,198.4,371.8,198.5,371.7,198.6z"/>
			<path fill="#DDE2E9" d="M356.5,105.8c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0-0.4,0.2-0.3c0.1,0,0.3,0.2,0.4,0.4
				C356.8,105.6,356.7,105.7,356.5,105.8z"/>
			<path fill="#E2E6EC" d="M389,185.8c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.2-0.2-0.2-0.4c0-0.1,0-0.4,0.2-0.3
				C388.7,185.5,388.8,185.7,389,185.8z"/>
			<path fill="#D4DBE4" d="M335.7,117c0.3,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.3-0.2,0.2c-0.2,0-0.4-0.2-0.4-0.4
				C335.4,117.1,335.6,117,335.7,117z"/>
			<path fill="#DEE3EA" d="M329.9,118.9c-0.2-0.5-0.4-0.8-0.5-1.1C329.8,118,330.1,118.2,329.9,118.9z"/>
			<path fill="#E7EAEF" d="M329.8,121.4c-0.1-0.3-0.2-0.5-0.1-0.8c0,0,0.3,0,0.3,0C330.3,121,330.1,121.2,329.8,121.4z"/>
			<path fill="#E5E9EE" d="M378.4,192.9c0,0-0.2-0.1-0.3-0.2c0-0.3,0.2-0.3,0.4-0.3c0.1,0,0.3,0,0.3,0.2
				C378.8,192.9,378.7,192.9,378.4,192.9z"/>
			<path fill="#E2E7EC" d="M393.9,193.5c0.1-0.3,0.1-0.6,0.5-0.7c0.1,0,0.2,0.2,0.2,0.2C394.5,193.4,394.3,193.5,393.9,193.5z"/>
			<path fill="#D3DBE4" d="M395.5,199c0,0.2,0.5,0.3,0.2,0.5c-0.2,0.2-0.5,0.1-0.6-0.1C395,199,395.3,199,395.5,199
				C395.6,199,395.5,199,395.5,199z"/>
			<path fill="#E0E5EB" d="M394.7,184.7c-0.1,0.1-0.2,0.2-0.4,0.2c-0.3,0-0.3-0.2-0.2-0.4c0-0.2,0.2-0.2,0.3-0.2
				C394.6,184.3,394.8,184.4,394.7,184.7z"/>
			<path fill="#D3DBE4" d="M380.5,175.7c0.1,0.5,0,0.8-0.2,0.8c-0.4,0,0-0.3,0-0.5C380.2,175.9,380.3,175.8,380.5,175.7z"/>
			<path fill="#D2D9E2" d="M360.5,191c0.2-0.1,0.3-0.1,0.4-0.1c0.2,0.1,0.4,0.2,0.4,0.5c-0.1,0-0.2,0-0.3,0
				C360.8,191.3,360.7,191.2,360.5,191z"/>
			<path fill="#CFD8E2" d="M366.3,206.8c-0.4-0.1-0.7-0.3-0.6-0.6c0.2-0.4,0.3,0,0.4,0.1C366.1,206.4,366.2,206.5,366.3,206.8z"/>
			<path fill="#D8DFE6" d="M365.7,191.4c0,0.2-0.1,0.4-0.2,0.4c-0.3,0-0.2-0.3-0.2-0.4c0-0.1,0-0.3,0.1-0.3
				C365.6,191.1,365.6,191.3,365.7,191.4z"/>
			<path fill="#D9DFE7" d="M385.3,196.9c-0.1-0.4,0.2-0.5,0.4-0.6c0.1-0.1,0.2,0.1,0.2,0.2C385.9,196.8,385.6,196.9,385.3,196.9z"/>
			<path fill="#E6EAEF" d="M390.8,200.1c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.2
				C391,199.9,390.9,200,390.8,200.1z"/>
			<path fill="#D2DAE3" d="M384.4,183.4c0.4-0.3,0.6-0.5,0.9-0.3c0,0,0.1,0.2,0.1,0.2C385.1,183.5,384.9,183.4,384.4,183.4z"/>
			<path fill="#D1D9E2" d="M391.7,199c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.2-0.2
				C391.5,199.2,391.5,199.1,391.7,199z"/>
			<path fill="#CFD8E1" d="M369.2,200.1c-0.1-0.3-0.2-0.6-0.4-1.1C369.4,199.3,369.4,199.7,369.2,200.1z"/>
			<path fill="#DEE3EA" d="M381.3,181.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2
				C381.2,181,381.3,181.1,381.3,181.3z"/>
			<path fill="#EFF1F4" d="M369.4,202.1c0.5,0.2,0.7,0.4,0.7,0.7c-0.2,0.2-0.3,0-0.4-0.2C369.5,202.6,369.5,202.4,369.4,202.1z"/>
			<path fill="#E1E6EC" d="M367.4,202.5c0,0.2-0.2,0.2-0.3,0.2c-0.1,0-0.3,0-0.4-0.1c0-0.2,0.1-0.2,0.3-0.3
				C367.2,202.3,367.3,202.4,367.4,202.5z"/>
			<path fill="#D2DAE3" d="M398.6,180c0.1-0.2,0-0.4,0.2-0.3c0.1,0,0.2,0.2,0.2,0.4c0,0.1,0,0.3-0.2,0.3
				C398.8,180.3,398.7,180.1,398.6,180z"/>
			<path fill="#D9E0E7" d="M398.9,187c-0.4,0.2-0.7,0.1-1-0.2l0,0C398.2,186.9,398.8,186.1,398.9,187L398.9,187z"/>
			<path fill="#FDFDFD" d="M395.2,204.3c0.4-0.1,0.6-0.2,1,0.1C395.8,204.8,395.5,204.6,395.2,204.3z"/>
			<path fill="#F9FAFA" d="M386.7,187.4c0.2,0,0.3,0,0.5,0c0,0.4-0.3,0.6-0.6,0.5C386.3,187.9,386.5,187.6,386.7,187.4z"/>
			<path fill="#E1E6EC" d="M392.2,187.7c-0.1-0.1-0.2-0.2-0.3-0.2c0.2,0,0.3,0,0.5,0c0.3,0,0.5,0.1,0.5,0.4c0,0.1-0.1,0.2-0.1,0.2
				C392.6,187.9,392.4,187.8,392.2,187.7z"/>
			<path fill="#D6DDE5" d="M391,205.2c-0.1,0.1-0.3,0.2-0.4,0.1c-0.1-0.1-0.2-0.3-0.2-0.4c0.1-0.2,0.2-0.1,0.4,0
				C390.9,205,391.1,205,391,205.2z"/>
			<path fill="#CBD5DF" d="M400.4,176.4c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.2
				C400.7,176.4,400.5,176.4,400.4,176.4z"/>
			<path fill="#D4DBE4" d="M384.5,163.9c-0.2,0.1-0.3,0.2-0.3,0.1c-0.1-0.1-0.3-0.2-0.3-0.3c0-0.2,0.1-0.3,0.3-0.2
				C384.3,163.6,384.4,163.8,384.5,163.9z"/>
			<path fill="#F7F8F9" d="M336,107c0.3-0.2,0.4-1.1,1-0.2C336.7,106.9,336.4,107,336,107z"/>
			<path fill="#E4E8ED" d="M397.8,162.6c-0.2-0.1-0.3-0.2-0.2-0.3c0-0.2,0.2-0.2,0.4-0.2c0.1,0,0.2,0.2,0.2,0.2
				C398.1,162.4,398,162.6,397.8,162.6z"/>
			<path fill="#CFD8E2" d="M341.8,112.6c-0.5,0.5-0.8,0.1-1.1-0.3C341.1,112.4,341.4,112.5,341.8,112.6L341.8,112.6z"/>
			<path fill="#DAE0E7" d="M398.3,160.6c-0.6,0.1-0.8,0-1-0.3C397.6,160.2,397.9,160.3,398.3,160.6z"/>
			<path fill="#EAEDF1" d="M334.6,128.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.2,0,0.3-0.2,0.3c-0.2,0-0.2-0.2-0.2-0.3
				C334.4,128.5,334.3,128.4,334.6,128.3z"/>
			<path fill="#D4DBE4" d="M348.2,111.9c0.6,0,0.8,0.2,0.9,0.5C348.7,112.5,348.5,112.3,348.2,111.9z"/>
			<path fill="#CED7E1" d="M329.4,127.4c-0.3,0-0.4-0.2-0.4-0.4c0-0.1,0-0.3,0.2-0.2c0.2,0.1,0.3,0.2,0.4,0.3
				C329.6,127.2,329.5,127.3,329.4,127.4z"/>
			<path fill="#DDE3E9" d="M383.5,171.5c0,0-0.1,0.2-0.2,0.2c-0.2-0.1-0.4-0.2-0.5-0.5c0-0.1,0.1-0.2,0.2-0.1
				C383.3,171.1,383.5,171.2,383.5,171.5z"/>
			<path fill="#CED7E1" d="M342.6,115.7c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.2,0.1-0.3,0.3-0.3
				C342.4,115.5,342.5,115.6,342.6,115.7z"/>
			<path fill="#E9ECF1" d="M330.9,115.8c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0-0.3,0-0.2-0.2c0.1-0.1,0.3-0.2,0.4-0.3
				C330.7,115.6,330.8,115.7,330.9,115.8z"/>
			<path fill="#F5F6F8" d="M342.1,102.1c0.4,0.4,0.1,0.7,0.2,1.3C341.9,102.8,342,102.5,342.1,102.1z"/>
			<path fill="#E1E6EC" d="M388.2,165c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.2,0.1-0.3,0.3-0.2c0.1,0.1,0.2,0.2,0.3,0.3
				C388.5,165,388.4,165.1,388.2,165z"/>
			<path fill="#DDE3E9" d="M375.3,189.6c-0.1-0.4-0.2-0.7,0-1C375.5,189,375.4,189.3,375.3,189.6z"/>
			<path fill="#C6D1DC" d="M336.7,101c0.1-0.2,0.1-0.3,0.2-0.5c0.3,0,0.5,0.1,0.7,0.2c-0.1,0.3-0.4,0.3-0.7,0.5
				C336.9,101.3,336.8,101.2,336.7,101z"/>
			<path fill="#D9E0E7" d="M399.9,186.9c0,0.3,0.1,0.5-0.2,0.7c0-0.5-0.6-0.4-0.7-0.7c0,0,0,0,0,0C399.2,187,399.5,187,399.9,186.9z
				"/>
			<path fill="#E4E8ED" d="M351.3,111.3c0,0.3,0,0.7-0.3,0.6c-0.4-0.1-0.1-0.3,0-0.5C351.1,111.3,351.2,111.3,351.3,111.3z"/>
			<path fill="#CFD7E1" d="M381.5,191.3c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1,0.1-0.2,0.2-0.1
				C381.4,191,381.5,191.1,381.5,191.3z"/>
			<path fill="#DAE0E7" d="M331.9,114.3c-0.1,0.1-0.1,0.3-0.2,0.3c-0.2,0.1-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.2-0.2
				C331.7,114.1,331.8,114.2,331.9,114.3z"/>
			<path fill="#E2E7EC" d="M384.4,190.7c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.1c0-0.2,0.1-0.3,0.3-0.3
				C384.3,190.5,384.4,190.5,384.4,190.7z"/>
			<path fill="#E3E8ED" d="M343.2,113.8c0.3,0,0.6-0.1,1-0.2C343.9,114.1,343.5,114,343.2,113.8z"/>
			<path fill="#DAE0E8" d="M380.5,204.2c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.3,0-0.3-0.2c0-0.1,0.1-0.2,0.2-0.3
				C380.4,203.8,380.4,204.1,380.5,204.2z"/>
			<path fill="#CED7E0" d="M389.8,190.8c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.1,0.2,0.1,0.3
				C390,190.6,389.9,190.6,389.8,190.8z"/>
			<path fill="#D5DDE5" d="M367.3,203.6c-0.1,0.1-0.1,0.2-0.3,0.3c-0.1,0.1-0.2-0.1-0.2-0.2c0-0.1,0.2-0.3,0.3-0.3
				C367.3,203.3,367.3,203.4,367.3,203.6z"/>
			<path fill="#CFD8E2" d="M396.7,203c0,0.2,0,0.4-0.2,0.5c-0.1-0.2-0.4-0.4-0.4-0.5C396.3,202.7,396.5,203,396.7,203z"/>
			<path fill="#E4E9EE" d="M350.7,114.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.3,0.3-0.2
				C350.6,114.1,350.6,114.3,350.7,114.4z"/>
			<path fill="#DAE0E7" d="M377.6,203.9c0.1-0.4,0.3-0.6,0.6-0.8C378.2,203.6,378,203.7,377.6,203.9z"/>
			<path fill="#E5E9EE" d="M336.7,101c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0.2-0.3,0.7-0.8,0.2c0.1-0.1,0.2-0.2,0.2-0.2
				C336.6,101.2,336.7,101.1,336.7,101z"/>
			<path fill="#D8DFE7" d="M373.6,200c0.1,0.2,0.1,0.3,0.1,0.5c0,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.2-0.2-0.3
				C373.4,200.2,373.5,200.1,373.6,200z"/>
			<path fill="#F8F8FA" d="M336.1,104c0,0.2-0.2,0.2-0.3,0.2c-0.1,0-0.3-0.1-0.3-0.1c0-0.2,0.1-0.3,0.3-0.3
				C335.9,103.7,336,103.8,336.1,104z"/>
			<path fill="#EBEEF2" d="M366.8,199.7c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3,0,0.3,0.1c0.1,0.2-0.1,0.3-0.2,0.3
				C367,199.9,366.9,199.8,366.8,199.7z"/>
			<path fill="#D8DFE6" d="M350.3,104.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.2-0.2-0.2-0.3c0-0.1,0.2-0.2,0.2-0.2
				C350.1,103.8,350.1,104,350.3,104.1z"/>
			<path fill="#D5DCE5" d="M339.9,104.4c0.2,0,0.3,0,0.5,0c0,0.3-0.2,0.6-0.5,0.6C339.5,104.8,339.9,104.6,339.9,104.4z"/>
			<path fill="#EBEEF2" d="M344.4,103.1c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.2-0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.2
				C344.3,102.8,344.4,102.9,344.4,103.1z"/>
			<path fill="#D1D9E3" d="M333.4,106.1c0.2-0.1,0.4-0.3,0.5-0.5c0.2,0,0.3,0,0.5,0C334.1,105.9,334,106.5,333.4,106.1z"/>
			<path fill="#D0D8E2" d="M372,201.1c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.2,0.2,0.2,0.3c0,0.1-0.1,0.1-0.2,0.2
				C372.2,201.4,372.1,201.3,372,201.1z"/>
			<path fill="#E9ECF0" d="M335.8,106.6c0,0.2,0,0.3,0,0.5c-0.2,0.2-0.3,0.2-0.5,0C335.3,106.7,335.5,106.6,335.8,106.6z"/>
			<path fill="#CAD3DF" d="M379.1,196c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.2,0.3-0.3,0.3c-0.1,0.1-0.2,0-0.2-0.2
				C379,196.2,379.1,196.1,379.1,196z"/>
			<path fill="#D7DEE6" d="M371.6,196.2c0.4-0.5,0.7-0.2,1.1-0.4C372.3,196.2,372,196.1,371.6,196.2z"/>
			<path fill="#F7F7F9" d="M374.4,196.1c-0.2,0-0.4,0-0.3-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.3,0,0.3,0.2
				C374.6,195.9,374.4,196,374.4,196.1z"/>
			<path fill="#CFD8E1" d="M370,201.1c0.2,0,0.4,0.1,0.4,0.3c0,0-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.1-0.3-0.3
				C369.8,201.2,370,201.2,370,201.1z"/>
			<path fill="#D9E0E7" d="M389.8,201.8c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.3,0.3-0.2
				C389.7,201.5,389.7,201.7,389.8,201.8z"/>
			<path fill="#DDE3E9" d="M395.7,193.9c0.2,0,0.4,0,0.4,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.3-0.1-0.3-0.2
				C395.5,194,395.6,194,395.7,193.9z"/>
			<path fill="#DEE3EA" d="M337.9,109.1c0.2-0.2,0.4-0.4,0.6-0.3c0.3,0.2-0.1,0.3-0.2,0.3C338.1,109.2,338,109.1,337.9,109.1z"/>
			<path fill="#E1E6EC" d="M391.9,192.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.1,0.3-0.1
				C391.7,192.3,391.8,192.4,391.9,192.5z"/>
			<path fill="#E2E7EC" d="M332.6,109.7c-0.1-0.1-0.3-0.1-0.3-0.2c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.2
				C332.8,109.5,332.7,109.6,332.6,109.7z"/>
			<path fill="#E9EDF1" d="M338.3,101.4c0.2,0,0.3,0.1,0.3,0.2c0,0.2-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.2-0.2-0.2
				C338.1,101.5,338.2,101.4,338.3,101.4z"/>
			<path fill="#C9D3DE" d="M380.7,174.7c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.3,0.1-0.3c0.2-0.1,0.3,0.1,0.3,0.2
				C380.9,174.4,380.8,174.5,380.7,174.7z"/>
			<path fill="#E9ECF0" d="M378,181.4c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.3,0.1-0.3c0.2-0.1,0.3,0.1,0.3,0.2
				C378.2,181.2,378.1,181.3,378,181.4z"/>
			<path fill="#F6F7F9" d="M335.2,127.2c0,0.2,0,0.3-0.1,0.3c-0.1,0-0.3-0.1-0.4-0.2c0-0.1,0.1-0.3,0.2-0.4
				C335.1,127,335.2,127.1,335.2,127.2z"/>
			<path fill="#F9FAFB" d="M336.1,127.7c0.1,0.2,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.3,0.3c0,0-0.1-0.2-0.1-0.3
				C335.9,128,336,127.9,336.1,127.7z"/>
			<path fill="#F1F3F5" d="M349,95.5c-0.2,0.1-0.3,0.2-0.3,0.1c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.1
				C348.8,95.2,348.9,95.3,349,95.5z"/>
			<path fill="#E5E9EE" d="M397.1,175.6c0,0.2-0.1,0.3-0.2,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.2,0.2-0.2
				C396.9,175.5,397,175.6,397.1,175.6z"/>
			<path fill="#ECEFF2" d="M338.6,134.6c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.3,0-0.3-0.1c0-0.2,0.2-0.3,0.4-0.3
				C338.3,134.4,338.4,134.5,338.6,134.6z"/>
			<path fill="#DFE4EA" d="M392.4,176.9c-0.1-0.2-0.3-0.4,0.1-0.6c0.1,0,0.2,0.1,0.3,0.2C392.9,176.8,392.7,176.9,392.4,176.9
				C392.4,176.9,392.4,176.9,392.4,176.9z"/>
			<path fill="#E7EAEF" d="M391.7,176.9c-0.2-0.1-0.4-0.2-0.3-0.5c0.1-0.1,0.2-0.2,0.4-0.1C392,176.5,391.7,176.7,391.7,176.9
				C391.7,176.9,391.7,176.9,391.7,176.9z"/>
			<path fill="#DFE4EA" d="M397.3,164.5c-0.1,0.3-0.2,0.4-0.2,0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.3,0.1-0.3
				C397.1,164.3,397.2,164.5,397.3,164.5z"/>
			<path fill="#E4E8ED" d="M376.3,187.1c-0.2,0-0.3-0.1-0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.2,0.2,0.2,0.2
				C376.4,186.9,376.3,187,376.3,187.1z"/>
			<path fill="#F2F3F6" d="M337,116.2c0,0.2,0,0.3,0,0.5c-0.3-0.1-0.6-0.1-0.8-0.5C336.5,116.2,336.7,116.2,337,116.2z"/>
			<path fill="#F0F2F4" d="M397.9,186.7c-0.3-0.3-0.3-0.7,0.1-1.2C398,186,398,186.4,397.9,186.7
				C397.9,186.7,397.9,186.7,397.9,186.7z"/>
			<path fill="#D7DDE6" d="M392.8,204.7c0-0.3,0.2-0.6,0.5-0.5c0.4,0.2,0.1,0.3-0.1,0.4C393.1,204.6,393,204.6,392.8,204.7z"/>
			<path fill="#F4F5F7" d="M366.9,205c-0.1,0.1-0.2,0.2-0.4,0.1c-0.1,0-0.1-0.2-0.1-0.3c0.1,0,0.2-0.1,0.3-0.1
				C366.8,204.8,366.9,204.8,366.9,205z"/>
			<path fill="#D3DBE3" d="M331.6,118.7c0.2,0.1,0.3,0.2,0.2,0.4c0,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.3-0.1-0.2-0.3
				C331.4,118.8,331.5,118.7,331.6,118.7z"/>
			<path fill="#D7DEE6" d="M375.8,205.3c-0.1,0.1-0.1,0.3-0.2,0.3c-0.2,0-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.1-0.3
				C375.6,205,375.7,205.2,375.8,205.3z"/>
			<path fill="#E3E8ED" d="M347.1,94.6c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3-0.2-0.2-0.3c0.1-0.1,0.3-0.1,0.4-0.1
				C347.1,94.3,347.2,94.4,347.1,94.6z"/>
			<path fill="#CDD6E0" d="M346.3,99.8c-0.3,0.2-0.5,0.2-0.6,0.3c-0.1,0.1-0.2,0-0.2-0.1c0-0.2,0.1-0.3,0.3-0.3
				C345.8,99.6,345.9,99.7,346.3,99.8z"/>
			<path fill="#ECEEF2" d="M386.3,182.9c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0.2,0.2,0.3c0,0.1-0.2,0.2-0.2,0.2
				C386.5,183.2,386.5,183.1,386.3,182.9z"/>
			<path fill="#E4E9EE" d="M333.9,124.1c-0.2,0-0.4,0-0.5-0.2c0.2,0,0.2-0.5,0.5-0.3C334.1,123.7,334.1,123.9,333.9,124.1z"/>
			<path fill="#D1D9E2" d="M394.4,182.6c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.2-0.2-0.2-0.3c0-0.1,0.1-0.1,0.2-0.2
				C394.2,182.3,394.3,182.4,394.4,182.6z"/>
			<path fill="#D7DEE6" d="M397,181.9c0,0.1,0,0.2,0,0.3c-0.2-0.1-0.5,0.2-0.6,0c-0.1-0.1,0.1-0.4,0.2-0.5
				C396.8,181.5,396.8,181.9,397,181.9z"/>
			<path fill="#E8ECF0" d="M389,173.7c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.4-0.2,0.2
				C389.2,174,389.1,173.8,389,173.7z"/>
			<path fill="#D0D9E2" d="M386.2,200.6c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.1,0.1,0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.2
				C386.4,200.8,386.3,200.7,386.2,200.6z"/>
			<path fill="#F9F9FA" d="M353,91.6c0.1,0.4,0,0.6-0.1,0.6c-0.4,0.1-0.2-0.2-0.1-0.3C352.8,91.8,352.9,91.7,353,91.6z"/>
			<path fill="#E2E6EC" d="M347.1,103.7c-0.2,0-0.4,0.3-0.6,0.1c0,0,0-0.2,0-0.2C346.7,103.4,346.9,103.5,347.1,103.7
				C347.1,103.7,347.1,103.7,347.1,103.7z"/>
			<path fill="#E3E8ED" d="M347.3,96c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.2,0.2
				C347.5,96.2,347.4,96.1,347.3,96z"/>
			<path fill="#E0E6EB" d="M393.4,201.6c0.2,0,0.4-0.3,0.6-0.1c0,0,0,0.2,0,0.2C393.7,201.9,393.5,201.8,393.4,201.6
				C393.4,201.6,393.4,201.6,393.4,201.6z"/>
			<path fill="#D8DFE6" d="M384.1,208.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
				C384.4,208.3,384.2,208.3,384.1,208.3z"/>
			<path fill="#F5F7F8" d="M379.5,202.8c-0.2-0.1-0.5-0.2-0.3-0.5c0.3-0.3,0.4,0,0.6,0.2C379.7,202.7,379.6,202.7,379.5,202.8z"/>
			<path fill="#D1D9E2" d="M383.3,207.1c0.2-0.1,0.2,0,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0-0.2-0.2
				C383.2,207.2,383.2,207.1,383.3,207.1z"/>
			<path fill="#D8DFE7" d="M383.9,202.3c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.1
				C383.8,202.1,383.8,202.2,383.9,202.3z"/>
			<path fill="#E3E7ED" d="M394.7,202.3c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.2-0.2
				C394.5,202.5,394.6,202.4,394.7,202.3z"/>
			<path fill="#D6DDE6" d="M379.5,202.8c0.1-0.1,0.2-0.2,0.3-0.2c0.2,0,0.5,0.1,0.3,0.3C379.9,203.1,379.7,202.8,379.5,202.8z"/>
			<path fill="#D9DFE6" d="M370.8,203.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0,0.2-0.1,0.2c-0.1,0-0.2-0.1-0.3-0.1
				C370.7,203.3,370.8,203.2,370.8,203.1z"/>
			<path fill="#D5DCE4" d="M342.9,100.4c-0.1,0.1-0.2,0.3-0.3,0.2c-0.1,0-0.2-0.2-0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.2
				C342.9,100.2,342.9,100.3,342.9,100.4z"/>
			<path fill="#FBFCFD" d="M382.8,204.3c0-0.2,0-0.5,0-0.7c0.2,0,0.4,0,0.4,0.3C383.2,204,383,204.2,382.8,204.3z"/>
			<path fill="#F3F4F6" d="M337.7,100.8c-0.3,0-0.5,0.1-0.7-0.2C337.4,100.1,337.5,100.5,337.7,100.8z"/>
			<path fill="#D2DAE3" d="M340.5,120.8c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1-0.1,0.2-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.4
				C340.3,120.9,340.4,120.8,340.5,120.8z"/>
			<path fill="#E8ECF0" d="M330.1,122.3c0.1,0.1,0.2,0.2,0.3,0.4c0,0.2-0.1,0.2-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.4
				C329.9,122.4,330,122.4,330.1,122.3z"/>
			<path fill="#D7DEE5" d="M386.7,180.7c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1-0.1-0.2-0.1-0.2-0.2
				C386.5,181,386.6,180.9,386.7,180.7z"/>
			<path fill="#E7EBEF" d="M332.9,129.7c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.3c0.1-0.1,0.2,0.1,0.1,0.2
				C333.1,129.5,333,129.6,332.9,129.7z"/>
			<path fill="#D8DEE6" d="M336,132.9c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.1-0.2
				C335.8,132.7,335.9,132.8,336,132.9z"/>
			<path fill="#D3DAE3" d="M331.6,136.3c0.1-0.4,0.4-0.4,0.6-0.4C332.2,136.3,331.9,136.3,331.6,136.3z"/>
			<path fill="#EAEDF1" d="M334,136.8c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0-0.2-0.1c0-0.1,0.1-0.2,0.1-0.3
				C333.9,136.6,334,136.7,334,136.8z"/>
			<path fill="#E3E7ED" d="M390.4,164c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.1-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.2-0.2
				C390.2,163.8,390.3,163.9,390.4,164z"/>
			<path fill="#DEE3EA" d="M386.9,168.2c0.1-0.2,0.3-0.5,0.6-0.6C387.6,168.2,387.2,168.2,386.9,168.2L386.9,168.2z"/>
			<path fill="#C7D2DD" d="M386.9,168.2c0,0.2,0,0.5-0.3,0.5c0,0-0.1-0.1-0.1-0.1C386.4,168.3,386.7,168.3,386.9,168.2
				C386.9,168.2,386.9,168.2,386.9,168.2z"/>
			<path fill="#CFD8E1" d="M395.8,173.8c-0.2,0.1-0.4,0.2-0.6,0.3c0-0.1,0-0.2,0-0.3C395.4,173.6,395.6,173.5,395.8,173.8
				C395.8,173.8,395.8,173.8,395.8,173.8z"/>
			<path fill="#D5DDE5" d="M397.4,171c-0.2,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.2
				C397.2,170.8,397.3,170.9,397.4,171z"/>
			<path fill="#E9ECF0" d="M339.4,104.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C339.1,104.2,339.2,104.3,339.4,104.4z"/>
			<path fill="#D3DBE4" d="M376.2,198.3c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.1
				C376.1,198,376.2,198.2,376.2,198.3z"/>
			<path fill="#E9EDF0" d="M397.5,197.5c0.1,0.2,0.4,0.5,0,0.7C397.2,198,397.2,197.8,397.5,197.5z"/>
			<path fill="#DBE1E8" d="M342,105.4c-0.2,0.1-0.3,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.2-0.2c0.1,0,0.2-0.1,0.2-0.1
				C341.8,105.2,341.9,105.3,342,105.4z"/>
			<path fill="#D4DCE4" d="M377,197.5c-0.1,0-0.2,0.1-0.3,0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.2-0.1
				C376.9,197.3,377,197.4,377,197.5z"/>
			<path fill="#F5F6F7" d="M347.4,106.8c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
				C347.1,107,347.3,106.9,347.4,106.8z"/>
			<path fill="#F9FAFB" d="M334.4,108.7c0.1,0.1,0.2,0.2,0.3,0.2c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.2,0.3-0.4,0.2
				C334,109.1,334.3,108.9,334.4,108.7z"/>
			<path fill="#E7EBEF" d="M384,191.8c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.1-0.2
				C383.9,191.7,383.9,191.7,384,191.8z"/>
			<path fill="#E0E5EA" d="M339.1,111.1c-0.1,0.2-0.3,0.3-0.6,0.1c0,0-0.1-0.2,0-0.2C338.8,110.8,339,111.1,339.1,111.1
				C339.1,111.1,339.1,111.1,339.1,111.1z"/>
			<path fill="#D5DCE5" d="M341.3,111.2c0,0.2,0,0.3-0.2,0.2c-0.1,0-0.2-0.2-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
				C341.2,111,341.2,111.1,341.3,111.2z"/>
			<path fill="#D3DBE3" d="M391.5,190.4c-0.2,0-0.3,0.1-0.3,0.1c-0.1,0-0.1-0.2-0.2-0.2c0.1,0,0.2-0.1,0.3-0.1
				C391.3,190.1,391.3,190.2,391.5,190.4z"/>
			<path fill="#DEE4EA" d="M375.5,190.4c-0.1,0.1-0.2,0.2-0.2,0.2c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.2-0.2
				C375.3,190.2,375.4,190.3,375.5,190.4z"/>
			<path fill="#F1F3F5" d="M394.3,190.1c-0.2,0-0.5,0.1-0.6-0.2c0,0,0.1-0.2,0.1-0.2C394.1,189.6,394.2,189.9,394.3,190.1
				C394.3,190.1,394.3,190.1,394.3,190.1z"/>
			<path fill="#E1E6EC" d="M341.1,113.5c0.1,0.2,0,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1,0-0.2,0.2-0.2
				C341,113.4,341,113.5,341.1,113.5z"/>
			<path fill="#DAE0E7" d="M384.4,188.8c0,0.2-0.1,0.3-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0.2-0.2
				C384.3,188.6,384.4,188.7,384.4,188.8z"/>
			<path fill="#E2E7EC" d="M342.4,113.9c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.1,0.1-0.2,0.2-0.2
				C342.2,113.8,342.3,113.9,342.4,113.9z"/>
			<path fill="#FBFBFC" d="M380.4,186.8c0.1,0.3,0.2,0.3,0.2,0.4c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.1-0.2-0.1-0.2-0.2
				C380.2,187.2,380.3,187.1,380.4,186.8z"/>
			<path fill="#DDB7A8" d="M363.4,37.4c0.2-0.4,0.3-0.8,0.5-1.2c0.4,0.4,0.5-0.3,0.7-0.2c0.9,0.3,1.5,1,2.4,1.2
				c0.4,0.1,0.1,1,0.9,0.8c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0.3-0.1,0.6,0.2,0.8c0.1,0.2,0.2,0.4,0.3,0.6
				c0,0.2,0,0.3-0.1,0.5c-0.9,0.6-0.9,1-0.1,1.4c0.1,0,0.2,0,0.3,0.1c0.8,0.3,0.2,0.9,0.1,1.2c-0.2,0.4-0.7,0.6-1.2,0.3
				c-0.3-0.2-0.5-0.5-0.9-0.9c0,0.7,0,1.3-0.6,1.5c-0.6,0.3-1.3,0.4-1.8,0c-0.6-0.5-1.3-0.3-2-0.4c-0.1,0-0.2-0.1-0.3-0.2
				c0-0.4,0.3-0.6,0.6-0.7c0,0,0.1,0,0.1-0.1c0,0.1-0.1,0.1-0.2,0.2c-0.7-0.2-0.7-0.8-0.9-1.3c-0.1-0.3-0.2-0.6-0.3-0.9
				c0.6-1,1.2-2,1.9-2.9c0.3,0.1,0.6-0.2,0.9,0c0.1,0,0.1,0.4,0.3,0.1c0.1-0.1-0.1-0.3-0.2-0.4c0-0.2-0.1-0.2-0.2-0.1
				C363.9,37.5,363.7,37.6,363.4,37.4z"/>
			<path fill="#E0BEAF" d="M362.2,43.4c0.8-0.1,1.5-0.3,2.3,0.2c1.4,0.9,2.1,0.2,2.1-2.1c0.5,0.6,0.9,1,1.2,1.4c0.4,0.6,0.7,0.2,1,0
				c0.3-0.2,0.6-0.6,0.1-1c0.2-0.3,0.5-0.5,0.9-0.1c0.1,0.2,0.2,0.4,0.2,0.7c0,0,0,0.1,0,0.1s0.1,0,0.1,0c0.1-0.1,0.3-0.1,0.4-0.1
				c0.2,0,0.4,0.1,0.7,0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0,0.4,0.2,0.5c0,0.1-0.1,0.2-0.1,0.3c-0.8-0.4-1.2,0.1-1.6,0.7
				c-0.2,0.1-0.1,0.4-0.3,0.6c-0.6,0.1-1.1,0.2-1.5,0.7c-0.2,0-0.2-0.3-0.5-0.2c0.1,0.1,0.3,0,0.4,0.2c0.1,0.7-0.4,1-1,1.1
				c-0.7,0-0.3,0.9-0.9,1c-0.3-0.3,0.1-0.9-0.3-1.2c-0.2-0.1-0.3-0.4-0.4-0.5c-0.5-0.1-1.1,0.2-1.5-0.4c-0.1-0.1,0-0.3-0.1-0.2
				c-0.1,0.1,0,0.5-0.3,0.5c-0.3,0-0.5-0.3-0.5-0.5C363,44.4,362,44.2,362.2,43.4z"/>
			<path fill="#E0BFB0" d="M367.9,38.2c-0.4,0-0.6,0.3-0.9,0.4c-0.4-0.3-0.1-0.5,0-0.7c0.1-0.3,0-0.6-0.2-0.5
				c-1.1,0.2-1.5-0.8-2.2-1.3c0.5,0,1,0.1,1.5,0.1c0.9,0.1,1.8,0.4,2.7-0.3c0.2,0.4-0.3,0.8,0,1.2c0.6-0.5,1.1-0.4,1.6,0.2
				c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.1,0,0.3,0,0.4c-0.1,1,0.8,0.2,1.1,0.7c-0.7,0.5-0.7,1.2-0.4,1.9
				c0.1,0.1,0.1,0.3,0,0.5c-0.7,0.2-1.5,0.3-2.2,0.7c-0.3,0.1-0.7,0.4-0.8,0.1c-0.2-0.5-0.1-1.1,0-1.6c0.1-0.6,0.6,0.2,0.9-0.1
				c0.3-0.1,0.2-0.3,0-0.5c-0.2-0.1-0.2-0.3-0.2-0.5c-0.2-0.3,0.1-0.7-0.2-1l0,0c-0.1-0.2-0.1-0.3,0-0.5
				C368,37.9,368,38.1,367.9,38.2z"/>
			<path fill="#E4CCBB" d="M362.2,43.4c0.8,0.5,1,1.2,1.1,2.2c0.4-0.4,0.1-1,0.6-1.1c0.1,0,0.4-0.1,0.4,0.1c0,0.3-0.3,0.5-0.4,0.7
				c0.5,0.4,1.1,0.4,1.7,0.2c0.3,0.2,0.2,0.5,0.2,0.7c-0.8,0.5-2,0.1-2.7,1.1c0,0-0.3-0.2-0.5-0.3c-0.2-0.2-0.1-0.4,0.1-0.5
				c0.2-0.2,0.7-0.1,0.5-0.7c-0.4,0.2-0.7,0.4-1.1,0.6c0-0.6-0.4-1-0.7-1.4l0,0c-0.2-0.2-0.4-0.4-0.5-0.7c0-0.6-0.7-0.5-0.9-0.9
				c0.2-0.4,0.7-0.1,0.9-0.5C361.6,42.5,361.9,43,362.2,43.4L362.2,43.4z"/>
			<path fill="#E6D0BF" d="M367.9,45.4c0.3-0.9,0.9-0.9,1.7-0.7c0.3,0.2,0.5,0.4,0.4,0.8c-0.1,0.3-0.4,0.2-0.6,0.2
				c-1-0.3-0.7,0.3-0.5,0.7c-0.3,0.2-0.8,0.4-0.5,1c-1,0.9-1.2-0.1-1.4-0.7C367.4,46.2,367.8,45.9,367.9,45.4L367.9,45.4z"/>
			<path fill="#E4D2C1" d="M361.5,40.6c0.5,0,0.5,0.4,0.5,0.7c-0.4,0.6-0.9,1.1-1.7,1.2c-0.7,0.9-1.2,0-1.7-0.3
				C359.6,41.8,360.7,41.4,361.5,40.6z"/>
			<path fill="#E0BFAF" d="M360.3,42.5c0.6-0.4,1-0.9,1.7-1.2c0.3,0.5,0.2,1.1,0.7,1.4l0,0c-0.2,0.2-0.3,0.5-0.5,0.7
				c-0.5,0-0.7-0.6-1.2-0.5C360.6,43,360.4,42.8,360.3,42.5z"/>
			<path fill="#E3C7B7" d="M369.4,45.6c0.6-0.2,0.5-0.6,0.2-1c0-0.2,0-0.4,0.2-0.5c0.1,0.4,0.3,0.6,0.5,0.1c0.2-0.4,0.4-0.6,0.8-0.4
				c0.5,0.3-0.1,0.5-0.2,0.7c-0.2,0.5-0.2,1.1-0.8,1.4C369.5,46.3,369.4,46.1,369.4,45.6z"/>
			<path fill="#E3C9B8" d="M370.8,41.3c0-0.2,0-0.3,0-0.5c0.5-0.2,1-0.4,1-1.1c0.2,1.3,0.2,2.4-0.2,3.6c-0.2-0.1-0.2-0.3-0.2-0.5
				c-0.4-0.4-0.2-0.9-0.5-1.3C370.8,41.4,370.8,41.3,370.8,41.3z"/>
			<path fill="#E8D8C6" d="M361.5,45.1c0.6,0.3,1.2,0.6,0.7,1.4C361.1,46.3,360.9,46,361.5,45.1z"/>
			<path fill="#E8D8C6" d="M361,44.4c0.5,0,0.5,0.4,0.5,0.7c-0.3-0.3-0.7,0.8-1,0C360.2,44.5,360.5,44.4,361,44.4z"/>
			<path fill="#DFBDAE" d="M368.4,47.3c-0.1-0.3-0.7-0.1-0.5-0.5c0.2-0.4,0.6-0.4,1-0.5C368.7,46.6,369.1,47.2,368.4,47.3z"/>
			<path fill="#E0BFB0" d="M370.1,38.2c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.4,0.3-0.5,0.5-0.7c0,0,0,0,0,0
				C370.7,37.6,370.8,38,370.1,38.2z"/>
			<path fill="#E4CBBA" d="M364.5,47.4c-0.1,0.1-0.2,0.2-0.3,0.1c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2
				C364.3,47.1,364.3,47.2,364.5,47.4z"/>
			<path fill="#EBE2CF" d="M352.8,32.3c0-2.9,1.9-4.4,5.2-3.9c1.2,0.2,2.3,0.5,3.3,1.2c0.8,0.6,1.1,1.2,1,2.1c-0.2,2.5-0.5,5-2.4,7
				c-1.2,1.3-3.3,1.3-4.6,0.1C353.3,37.1,352.8,34.8,352.8,32.3z"/>
			<path fill="#E2C5B5" d="M363.4,37.4c0.4-0.2,0.7-0.5,1.1-0.7c0.2,0.2-0.1,0.5,0.2,0.7c0.5,0.4,0.1,0.6-0.2,0.7
				c-0.6,0.3-0.3,0-0.3-0.3c0-0.4-0.7,0.3-0.8-0.3C363.3,37.6,363.4,37.5,363.4,37.4z"/>
			<path fill="#C0CCD9" d="M398.9,189.4c0-0.2,0.1-0.4,0.2-0.5c0.1,0,0.2,0,0.2,0c0,0.2,0,0.5,0,0.7
				C399.2,189.6,399,189.6,398.9,189.4z"/>
			<path fill="#DCB538" d="M410.9,19c-1.2-0.3-1.5,0.6-1.7,1.4c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2-0.3-0.3-0.7-0.6-1
				c0-0.1,0-0.2,0.1-0.3c0.2-0.2,0.5-0.3,0.5-0.7c0.4,0.4,0.8,0.7,0.9-0.1c0.1-0.6,0.5-0.3,0.8-0.4C410.9,18.2,410.9,18.6,410.9,19z
				"/>
			<path fill="#DCB538" d="M405.6,16.1c0.5,0,1.1-0.2,1.3,0.6c0.1,0.3,0.4,0.4,0.8,0.4c0.2,0,0.5,0.1,0.4,0.5
				c-0.1,0.3-0.3,0.3-0.5,0.1c-0.3-0.3-0.6-0.3-0.9,0c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.2-0.5-0.4
				C405.9,17,404.7,16.6,405.6,16.1z"/>
			<path fill="#DEBC52" d="M406.3,17.8c0.1,0,0.2,0,0.2,0c0.1,0.3,0.2,0.6,0.2,1c0.3,0.1,0.7,0.2,0.6,0.7c0,0.2-0.1,0.3-0.3,0.3
				c-0.6-0.1-1.1-0.9-1.7-0.1c-0.3,0.2-0.7,0.6-1,0.2c-0.3-0.3,0.1-0.6,0.3-0.9C405,18.2,405.7,18.1,406.3,17.8z"/>
			<path fill="#DFC05E" d="M405.6,16.1c0,0.2-0.2,0.5-0.1,0.5c1,0,0.3,0.6,0.4,0.9c-0.7,0.2-1.3-0.2-1.9-0.2
				c-0.2-0.1-0.3-0.3-0.3-0.6c0-0.4,0.3-0.6,0.5-0.8c0.1-0.1,0.3-0.1,0.5,0c0.5,0.3,0.4-0.5,0.7-0.4c0.1,0,0.2,0,0.3,0.1
				c0.1,0.1,0.2,0.2,0.2,0.2C405.8,15.9,405.7,16,405.6,16.1z"/>
			<path fill="#DDB946" d="M406.6,15.4c0.4-0.1,1.2,0.7,1.2-0.5c0.7,0.5,0.4,1.1,0.2,1.7c-0.1,0.3-0.4,0.3-0.6,0
				C407.1,16.2,406.9,15.8,406.6,15.4z"/>
			<path fill="#DBB32E" d="M407.3,19.4c0-0.4-0.4-0.5-0.5-0.7c0.8-0.9,0.9,0.5,1.4,0.5c0,0.1,0,0.2,0,0.3
				C407.9,19.7,407.6,19.7,407.3,19.4z"/>
			<path fill="#DDB948" d="M408.1,14c0.3,0.1,0.6,0.2,0.7,0.6c0,0-0.2,0.2-0.2,0.2C408.2,14.8,408,14.5,408.1,14z"/>
			<path fill="#DCB434" d="M401.8,11c-0.3-0.6-0.8-0.7-1.4-0.7c-0.7-0.1,0-0.3-0.1-0.5c-0.1-0.2-0.1-0.3,0-0.5
				c0.6-0.7,1.4-0.3,2.1-0.3c0.4-0.1,0.9,0.1,1.3,0.1c0.3,0,0.6,0,0.7,0.3c-0.4,0.3-0.2,0.8-0.3,1.2c-0.1,0-0.1,0-0.2,0.1
				c-0.5,0.1-0.8-0.3-1.3-0.3c-0.4,0-0.3,0.2-0.3,0.4C402.3,10.9,402.1,11.2,401.8,11z"/>
			<path fill="#E0C163" d="M405.4,11.8c0,0.1,0,0.2,0,0.2c0.2,0.2,0.2,0.5,0,0.7c-1.3-0.1-0.6,0.9-0.7,1.4c-0.2,0.1-0.3,0-0.4-0.1
				c-0.3-0.4-0.7-0.3-1.1-0.3c-0.6,0.1-0.5-0.3-0.4-0.7c0.2-0.4,0.7-0.7,0.5-1.2c0.1-0.5,0.3-1,0.8-1.2c0.7-0.1,0.9,0.3,0.9,0.9
				c0,0.1-0.1,0.2,0,0.4C405.1,11.8,405.2,11.7,405.4,11.8z"/>
			<path fill="#DDBA48" d="M404.4,13.9c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.5,0.5,0.9,0.5c0,0.3,0,0.6,0,1c-0.1,0-0.2,0-0.2,0
				c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0-0.3,0-0.5,0c-0.1-0.1-0.2-0.2-0.2-0.4c0.1-0.4-0.2-0.7-0.3-1C403.8,14.2,403.8,13.9,404.4,13.9
				z"/>
			<path fill="#DDB947" d="M405.4,12.7c0-0.2,0-0.5,0-0.7c0.4-0.3,0.9-0.2,1.4-0.8c-0.3,0.9-0.4,1.4-0.9,1.9
				c-0.2,0.2-0.4,0.8-0.7,0.5C404.7,13.3,405.6,13.1,405.4,12.7z"/>
			<path fill="#DCB537" d="M405.4,11.8c-0.1,0.2-0.2,0.3-0.3,0.5c-0.5-0.3,0.2-1.4-0.9-1.4c-0.1-0.1-0.1-0.1-0.1-0.2
				c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0,0C405.3,10.1,405.4,10.2,405.4,11.8z"/>
			<path fill="#DDB842" d="M398.9,9.1c-0.4-0.3-0.8-0.7-1.4-0.2c-0.5,0.4-0.6-0.3-1-0.5c0.1-0.2,0.3-0.3,0.4-0.5
				c0.8-0.1,0.6-1,0.9-1.4c0.3-0.1,0.6-0.1,0.8,0.2c0.9-0.2,0.2-0.9,0.4-1.2c1,0.1,0,1,0.5,1.2c-0.1,0.3,0.2,0.6,0,1
				c-0.3,0.2-0.7,0.1-1.1,0c0,0.6,0.5,0.4,0.6,0.7c0.3,0.3,0.1,0.5-0.1,0.7C399,9.1,399,9.1,398.9,9.1z"/>
			<path fill="#DCB435" d="M398.7,6.7c-0.3,0-0.7,0-1,0c-0.6,0.1-0.6-0.4-0.6-0.8c0-0.1,0.2-0.3,0.1-0.4c0.2-0.3,0.4-0.8,1-0.5
				c0.2,0,0.5,0,0.7,0C398.6,5.6,398.2,6.1,398.7,6.7z"/>
			<path fill="#DCB435" d="M399.6,7.7c-0.1-0.3-0.7-0.7,0-1c1-0.1,1.3,0.7,1.9,1.2C400.8,8.3,400.2,8.4,399.6,7.7z"/>
			<path fill="#DDB842" d="M400.3,9.4c0,0.2,0,0.3,0,0.5c-0.7-0.1-1.4-0.1-2-0.2c0.1-0.4,0.5-0.2,0.6-0.5c0,0,0,0,0,0
				c0.2-0.1,0.4-0.2,0.7-0.2c0.1,0,0.2,0.1,0.3,0.2C400.1,9.1,400.2,9.2,400.3,9.4z"/>
			<path fill="#DCB639" d="M399.6,9.1c-0.2,0-0.5,0-0.7,0c0.1-0.2,0.2-0.5,0.2-0.7c0.1,0,0.2,0,0.2,0C399.6,8.6,399.9,8.8,399.6,9.1
				z"/>
			<path fill="#E2C674" d="M399.6,9.1c-0.1-0.2-0.2-0.5-0.3-0.7c0.5,0,0.8,0.2,0.5,0.7c0,0-0.1,0-0.1,0S399.6,9.1,399.6,9.1z"/>
			<path fill="#DEBC50" d="M394.6,5.5c0.1-0.4,0.4-0.8-0.2-1.1c-0.3-0.1-0.2-0.4-0.1-0.6c0.1,0.1,0.3,0.3,0.4,0.4
				c1.3,0.9,1.3,0.9,2.2-0.4c0,0.5,0,1,0,1.5c-0.5,0.7-1.4,0.4-2,0.9C394.7,6.5,394.8,5.7,394.6,5.5z"/>
			<path fill="#E1C36B" d="M388.8,3.1c-0.2-0.3-0.3-0.6-0.2-1c0.7-0.1,1.1,0.3,1.6,0.6c0.8,0.5,0.3,0.7-0.2,0.8
				C389.7,3.2,389.3,3.1,388.8,3.1z"/>
			<path fill="#DFBD54" d="M391.2,1.9c0,0.2,0,0.3,0,0.5c-0.5,0-0.9-0.1-1.4-0.5c0.4-0.4,0.8-0.2,1.1-0.3
				C391.1,1.8,391.1,1.8,391.2,1.9z"/>
			<path fill="#DCB330" d="M394.6,5.5c0.3,0.1,0.3,0.6,0.4,0.4c0.5-0.8,1.4-0.3,2-0.7c0.1,0.1,0.2,0.1,0.2,0.2
				c0.1,0.1,0.2,0.2,0,0.3c-0.2,0.3,0,0.6-0.1,0.9c-0.1,0.5-0.3,0.4-0.6,0.2c0,0-0.1-0.1-0.1-0.2c0,0.1,0,0.1,0.1,0.2
				c0.2,0.4,0.8,0.7,0.5,1.3c-0.1,0.2-0.3,0.3-0.5,0.3c-0.4,0-0.8-0.5-1.2,0C394.9,8.5,395,8.2,395,8c0.1-0.2,0.2-0.3,0.3-0.4
				c0.2-0.2,0.2-0.4-0.1-0.5c-0.6,0.1-0.1,0.6-0.2,0.8c0,0.2,0,0.4-0.2,0.6c-0.2,0.1-0.4,0.1-0.6,0.2c-0.6,0.2-1,0.1-0.7-0.6
				c0.2-0.4,0-0.8-0.2-1.1c0.1-0.4-0.3-1.1,0.6-0.9C394.2,6,394.6,6,394.6,5.5z"/>
			<path fill="#DBB32F" d="M420.7,76.6c0.1,0,0.2,0,0.3,0c0.1,0.5,1.3,0.3,0.7,1.2c-0.3-0.1-0.5-0.9-1-0.1c-0.2,0.3-0.6,0.1-0.9-0.1
				C419.7,76.8,420.5,76.9,420.7,76.6z"/>
			<path fill="#DBB22C" d="M387.4,1.2c-0.2,0.2-0.3,0.3-0.5,0.5c0-0.3,0-0.6,0-1c0.2,0,0.3,0,0.5,0c0.2,0.1,0.4,0.2,0.2,0.5
				C387.5,1.2,387.5,1.2,387.4,1.2z"/>
			<path fill="#DEBA48" d="M387.6,1.2c-0.1-0.2-0.2-0.3-0.2-0.5c0.1-0.1,0.2-0.2,0.4-0.1C387.9,0.8,387.8,1,387.6,1.2z"/>
			<path fill="#DCB536" d="M404.2,15.6c0,0.2,0,0.3,0,0.5c0,0.4-0.3,0.5-0.5,0.7c-0.1,0-0.1,0-0.2,0c-0.4-0.3-0.6-0.7-0.3-1.2
				c0.4-0.4,0.3-0.6-0.2-0.7c-0.5-0.1-0.7-0.3-0.7-0.7c0-0.4-0.1-0.7,0.2-1c0.2-0.1,0.3-0.1,0.5,0.1c-0.1,0.4-0.1,0.6,0.3,0.3
				c0.6-0.4,0.9-0.2,1.1,0.4c-0.4,0.2-0.5,0.5-0.5,0.9C403.9,15.2,404.4,15.3,404.2,15.6z"/>
			<path fill="#DDB841" d="M403.7,16.8c0.2,0.1,0.2,0.3,0.2,0.5c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.1c-0.1,0.1-0.2,0.1-0.3,0.1
				c-0.3,0-0.6,0-0.9,0c-0.6-0.1-1.4,0.4-1.8-0.6c0-0.5-0.8-0.6-0.7-1.2c0.6-0.9,1.3,0.1,1.9,0C403,15.8,403,16.8,403.7,16.8
				L403.7,16.8z"/>
			<path fill="#DCB535" d="M403,13.2c-0.2,0-0.3,0-0.5,0c-0.2,0.2-0.2,0.6-0.5,0.7c-0.5-0.3-1.2-0.4-1.2-1.2
				c0.2-0.2,0.5-0.3,0.7-0.5c0.1-0.5,0.8,0.1,1-0.5c0.4-0.3,0.7,0,1,0.2C404.6,12.9,402.8,12.7,403,13.2z"/>
			<path fill="#DCB535" d="M397.2,11.5c-0.2-0.1-0.3-0.2-0.5-0.2c0-0.1,0-0.1,0-0.2c-0.3-0.3-0.7-0.4-0.8-0.8
				c-0.1-0.3-0.1-0.7,0.2-0.9c0.1-0.1,0.3-0.1,0.4-0.3c0.5,0.3,0.6,0.8,0.9,1.2c0.2,0.3,0.1,1.1,0.9,0.6c0.3,0.5,0.7,0.9-0.1,1.3
				c0,0,0,0,0,0C397.8,12.1,397.4,11.9,397.2,11.5z"/>
			<path fill="#E2C675" d="M403.5,12c-0.3-0.1-0.6-0.2-1-0.2c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.2,0.2-0.5,0-0.7c0-0.2-0.2-0.6,0-0.6
				c0.3-0.1,0.8-0.1,0.9,0.1c0.2,0.4,0.7,0.3,1,0.6c0,0.1,0,0.2,0,0.2C403.9,11.2,403.7,11.6,403.5,12z"/>
			<path fill="#DDB945" d="M401.5,12.3c0,0.6-0.2,0.8-0.7,0.5c-0.1-0.4,0.2-1.1-0.7-0.8c-0.4,0.1-0.4-0.3-0.4-0.5
				c0-0.4,0.4-0.5,0.7-0.5C401.2,11,401.3,11.7,401.5,12.3z"/>
			<path fill="#DDB842" d="M396.5,9.1c-0.1,0.1-0.2,0.2-0.2,0.2c-0.4,0.3-0.9,0.1-1.4,0.1c-0.3,0-0.6,0-0.7-0.3
				c-0.1-0.4,0.1-0.6,0.4-0.7c0.2-0.1,0.2-0.3,0.2-0.5c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0.1c0,0.2,0,0.4,0.2,0.5
				C395.4,9.1,396,9,396.5,9.1z"/>
			<path fill="#DBB32F" d="M403.7,16.8c-0.7,0.1-1-0.5-1.4-1c0.3-0.3,0.6-0.4,1-0.2C403.3,16,403.4,16.5,403.7,16.8z"/>
			<path fill="#E0C163" d="M402,13.9c0-0.3,0.1-0.6,0.5-0.7c0,0.3,0.1,0.7-0.2,0.9C402.2,14.1,402.1,14,402,13.9z"/>
			<path fill="#DCB535" d="M402.3,10.6c0.4,0.2,0.1,0.5,0,0.7c-0.2,0-0.4,0-0.5-0.2C402,10.9,402.2,10.8,402.3,10.6z"/>
			<path fill="#DCB330" d="M392.7,6.5c-0.4-0.7-1-0.7-1.7-0.7c-0.4-0.8,0.2-1.1,0.6-0.8c0.7,0.5,0.9,0,1.3-0.4
				C393.5,5.3,393.6,5.9,392.7,6.5z"/>
			<path fill="#DCB330" d="M392.7,6.5c0.5-0.6,0.5-1.2,0.2-1.9c0,0,0,0,0,0c0.3-0.1,0.4,0,0.5,0.3c0.3,0.4,0,0.9,0.5,1.2
				c-0.6,0.1-0.3,0.6-0.5,1c-0.5,1.1-0.7,1.1-1.7,0C391.9,6.6,392.5,6.9,392.7,6.5z"/>
			<path fill="#DCB330" d="M393.4,4.8c-0.2-0.1-0.3-0.2-0.5-0.3c0.1-0.2,0.2-0.4,0.5-0.3C393.6,4.4,393.7,4.6,393.4,4.8z"/>
			<path fill="#E1C46F" d="M403.7,17.5c0.1,0,0.1,0,0.2,0c1,0,1.1,0.6,1,1.4c-0.1,0.2-0.3,0.4-0.5,0.7c0.4,0.2,0.7-0.2,0.9-0.2
				c1.1,0.5,0.3,0.9,0,1.3c-0.3,0.1-0.5,0.1-0.7-0.1c0-0.4-0.2-0.4-0.4-0.2c-0.4,0.3-0.5,0.1-0.5-0.3c-0.2-0.3,0.6-0.4,0.1-0.7
				c0-0.3,0.1-0.7-0.1-1C403.5,18.1,403.4,17.8,403.7,17.5z"/>
			<path fill="#DDB73F" d="M404.7,20.6c0.2,0,0.5,0,0.7,0c0.5,0.1,0.4,0.8,0.8,1c0.1,0.2,0,0.3-0.1,0.4c0,0,0,0,0,0
				c-0.1,0-0.2,0-0.2,0c-1.1,0.7-1.5,0.1-1.7-0.9C404.3,21,404.5,20.8,404.7,20.6z"/>
			<path fill="#DFBD55" d="M403.9,19.4c0.3,0.4-0.1,0.5-0.2,0.7c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.1
				c0-0.1,0-0.2,0-0.2c-0.1-0.1-0.1-0.3-0.1-0.5C403.7,19.1,403.8,19.2,403.9,19.4z"/>
			<path fill="#DBB32E" d="M403.5,19.9c0,0.1,0,0.2,0,0.2c-0.4,0.2-0.7,0-1-0.2C402.8,19.9,403.1,19.9,403.5,19.9z"/>
			<path fill="#DCB432" d="M406.1,21.6c-0.9,0.2-0.2-0.8-0.7-1c0-0.4,0.6-0.8,0-1.2c0.7-0.8,1.2-0.4,1.7,0.2
				c0.3,0.8,1.1,0.6,1.7,0.7c0.2,0,0.3,0,0.5,0c0.1,0.5,0.3,1,0.1,1.6c-0.1,0.2,0.4,0.7,0.7,1.1c-0.1,0.2-0.2,0.3-0.2,0.5
				c-0.1,0.2-0.3,0.2-0.5,0.2c0-0.8-1.1-0.7-1.2-1.4c-0.2-0.2-0.3-0.5-0.5-0.7C407.1,22,406.6,21.7,406.1,21.6z"/>
			<path fill="#DBB22C" d="M409.7,23.5c-0.4-0.4-0.1-0.4,0.2-0.5c0.1,0.1,0.2,0.2,0.2,0.2C410,23.4,409.9,23.4,409.7,23.5z"/>
			<path fill="#E2C776" d="M397.2,11.5c0.5,0,0.6,0.5,1,0.7c-0.4,0-0.7,0.1-1,0.2C396.4,12.2,397.2,11.8,397.2,11.5z"/>
			<path fill="#DBB22C" d="M396.7,13c0.1-0.2,0.3-0.2,0.5-0.3c0.5,0.4,0.4,0.7-0.3,0.7C396.5,13.5,396.6,13.2,396.7,13z"/>
			<path fill="#DBB22C" d="M426.8,63.8c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2-0.4-0.1-0.8,0-1.2C426.7,63,426.5,63.6,426.8,63.8z"/>
			<path fill="#E1C673" d="M406.1,21.6c0.5-0.5,1,0.2,1.5,0c-0.2,0.9-1,0.3-1.5,0.5C406.1,21.9,406.1,21.8,406.1,21.6z"/>
			<path fill="#E1C46E" d="M391.7,7c0.6,0,1.1,1,1.7,0c1.2,0.4,0.2,1,0.2,1.5c0.4,0.3,0.7-0.4,1.1-0.1c-0.2,0.4-0.6,0.9,0.4,0.8
				c0.4-0.1,0.8,0.1,1.3,0.2c-0.2,0.3-0.3,0.6-0.2,1c0,0.2-0.1,0.4-0.2,0.5c-0.1,0-0.2,0-0.3,0c-0.4-0.5-0.8,0.1-1.2,0
				c-0.1-0.1-0.1-0.1,0-0.2c-0.2,0-0.1,0.2-0.2,0.2c-0.3-0.4-0.6-0.9-1.2-0.7c-0.5,0.1-0.7-0.2-0.8-0.5c0-0.6,0-1.1-0.9-0.9
				c-0.2,0-0.2-0.4,0-0.7c0.5-0.2-0.1-0.7,0.2-1C391.6,7,391.6,7,391.7,7z"/>
			<path fill="#DCB433" d="M393.9,12.2c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0.3-0.5,0.3-0.7,0.3c-0.1,0-0.2-0.2-0.3-0.3
				c-0.1-0.4,0.3-0.4,0.5-0.5c0.1,0,0.2-0.1,0.3-0.2C393.6,12,393.8,12,393.9,12.2z"/>
			<path fill="#DDB842" d="M395.8,10.8c0-0.2,0-0.4,0.2-0.5c0.3,0.2,0.6,0.4,0.7,0.7C396.4,11.1,396,11.2,395.8,10.8z"/>
			<path fill="#DBB22C" d="M391.2,7.9c0,0.5,0.1,0.7,0.6,0.3c0.2-0.2,0.6-0.2,0.8,0.1c0.2,0.2,0,0.5-0.1,0.6
				c-0.5,0.6,0.3,0.7,0.5,1.1c0,0.1,0,0.2,0,0.2c-0.2,0-0.3,0-0.5,0c-0.6-0.4-1.1-0.8-1.4-1.5C390.8,8.5,390.6,8.1,391.2,7.9z"/>
			<path fill="#DDB73E" d="M394.1,10.8c0.1-0.3-0.2-0.6,0.2-0.8c0.1,0,0.3,0,0.3,0.1c0.2,0.3-0.2,0.4-0.3,0.7
				C394.3,10.8,394.2,10.8,394.1,10.8z"/>
			<path fill="#DBB32E" d="M401.1,17c0.2-0.1,0.4-0.2,0.5,0.2c0.1,0.4,0.1,0.5,0.4,0c0.3-0.6,0.7,0.1,1,0.3c0.2,0.3,0.2,0.5-0.2,0.5
				c-0.4,0.1-0.2,0.6-0.5,0.9c-0.5-0.5-0.7-1.3-1.5-1.6C400.9,17.2,401,17.1,401.1,17z"/>
			<path fill="#E2C778" d="M402.8,18c0.2-0.1,0.2-0.3,0.2-0.5c0.2-0.3,0.5-0.3,0.7,0c0,0.3,0,0.7,0.2,0.9c-0.1,0.3-0.4,0.4-0.6,0.6
				c-0.1,0.1-0.2,0.2-0.4,0.1C402.6,18.9,402.8,18.4,402.8,18z"/>
			<path fill="#DBB32E" d="M403,19.2c0.1-0.1,0.2-0.2,0.2-0.2c0.2-0.1,0.3,0,0.2,0.2c0,0.2,0,0.3,0,0.5c-0.3,0-0.5,0.1-0.7-0.2
				C402.8,19.4,402.9,19.3,403,19.2z"/>
			<path fill="#FCFCFD" d="M385.9,174.9c0.3,0.3,0.3,0.6,0.1,1C385.8,175.6,386,175.3,385.9,174.9
				C385.9,175,385.9,174.9,385.9,174.9z"/>
			<path fill="#E3C8B8" d="M368.7,42c0.6-0.7,1.2-1.1,2.2-0.7c0,0,0,0,0,0c0.4,0.4,0.5,0.9,0.2,1.4c-0.2,0.2-0.3,0.2-0.5,0
				c-0.3-0.3-0.6-0.5-1-0.7c-0.2-0.3-0.5,0.1-0.7,0C368.8,42,368.7,42,368.7,42z"/>
			<path fill="#E0BFB0" d="M367.3,41.4c-0.7-0.5-0.9-1.4-2.1-1.5C366.9,39.2,367,40.4,367.3,41.4z"/>
			<path fill="#E7D3C1" d="M367.9,38.2c0.1-0.2-0.2-0.5,0-0.7c0.2-0.1,0.4,0,0.5,0.1c0.5,0.5-0.5,0.4-0.3,0.8
				C368.1,38.3,368,38.2,367.9,38.2z"/>
			<path fill="#E6D3C1" d="M368.2,38.4c0.5,0.2,0.5,0.6,0.2,1C367.4,39.3,368.2,38.7,368.2,38.4z"/>
			<path fill="#E0BFAF" d="M362.7,42.7c0.1-0.3-0.2-0.6,0-0.9c0,0,0.4,0.1,0.5,0.2C363.3,42.5,363,42.7,362.7,42.7L362.7,42.7z"/>
			<path fill="#E8D7C5" d="M368.7,39.8c0.5,0.1,0.6,0.4,0.4,0.7c-0.1,0.2-0.3-0.1-0.4-0.3C368.7,40.2,368.7,40,368.7,39.8z"/>
			<path fill="#EBE2CF" d="M365.5,45.6c-0.7,0.7-1.2,0.3-1.7-0.2c0.5,0.1,1,0.4,1.5-0.1C365.4,45.2,365.5,45.5,365.5,45.6z"/>
			<path fill="#E8D8C6" d="M369.6,42c0.6-0.1,0.9,0.1,1,0.7c-0.2,0-0.5,0-0.7,0l0,0C369.8,42.5,369.7,42.2,369.6,42z"/>
			<path fill="#DDB7A8" d="M365.2,44.9c0.4-0.4,0.7-0.6,1.1-0.5c0,0,0.1,0.2,0.1,0.3C366,45,365.6,44.9,365.2,44.9z"/>
			<path fill="#E7D6C4" d="M367.9,45.4c-0.1,0-0.2,0-0.2,0c-0.3,0-0.4,0.6-0.8,0.2c0,0,0-0.3,0-0.3
				C367.3,45.2,367.7,44.6,367.9,45.4C367.9,45.4,367.9,45.4,367.9,45.4z"/>
			<path fill="#E8D8C6" d="M371.1,42.7c0.1-0.5-0.2-1-0.2-1.4c0.9,0.2,0.3,1,0.5,1.4C371.2,42.7,371.1,42.7,371.1,42.7z"/>
			<path fill="#E7D5C4" d="M366.4,46c-0.2-0.3-0.3-0.5-0.6-0.9C366.5,45.3,366.5,45.6,366.4,46z"/>
			<path fill="#E8D8C6" d="M369.9,42.7c0,0.2,0.1,0.5-0.2,0.6c0,0-0.2-0.1-0.2-0.2C369.4,42.8,369.6,42.7,369.9,42.7
				C369.9,42.7,369.9,42.7,369.9,42.7z"/>
			<path fill="#EBE2CF" d="M370.3,37.2c-0.2,0.2-0.3,0.5-0.5,0.7C369.4,37.3,370,37.3,370.3,37.2z"/>
			<path fill="#E2C675" d="M408.7,20.4c-0.7,0.8-1.3,0.1-1.9-0.1c-0.5-0.1,0-0.4,0.2-0.6c0.1-0.1,0.2-0.2,0.3-0.2c0.3,0,0.6,0,1,0
				C408.6,19.7,409.2,19.8,408.7,20.4z"/>
			<path fill="#DCB535" d="M404.9,19c-0.1-0.6-0.5-1.1-1-1.4c0-0.1,0-0.2,0-0.3c0.7-0.1,1.3-0.2,1.9,0.3c0.2,0.1,0.3,0.2,0.5,0.2
				C406.2,18.6,405.2,18.3,404.9,19z"/>
			<path fill="#DCB431" d="M404.7,16.1c0.1-0.3,0.1-0.9,0.7-0.5c0,0.3-0.2,0.4-0.3,0.6c-0.1,0.1,0.2,0.4-0.2,0.4
				C404.8,16.6,404.7,16.3,404.7,16.1z"/>
			<path fill="#E1C46F" d="M404.2,15.6c0-0.3-0.7-0.3-0.2-0.7C404.2,15.1,404.6,15.2,404.2,15.6z"/>
			<path fill="#E1C36B" d="M397,8.1c-0.2-0.5-0.5-0.9-0.7-1.4c0.1-0.1,0.1-0.1,0.2,0c0.3,0.1,0.6,0.1,0.4-0.3
				c-0.1-0.3-0.2-0.6,0.3-0.6c0.2,0.3-0.1,0.8,0.5,1C398.7,7.8,397.6,7.9,397,8.1z"/>
			<path fill="#DFBD55" d="M394.8,7.9c-0.2-0.3-0.5-0.7-0.3-1.1c0.2-0.2,0.5,0.1,0.7,0.2c0.1,0.1,0,0.3-0.1,0.4
				C395.1,7.5,395,7.7,394.8,7.9L394.8,7.9z"/>
			<path fill="#DDB842" d="M395.1,7.2c0.1-0.1,0.2-0.2,0.2-0.2c0.1-0.1,0.3-0.2,0.4-0.1c0.2,0.4,0.2,0.7-0.1,1
				C395.4,7.7,394.9,7.7,395.1,7.2z"/>
			<path fill="#DEBA4B" d="M396.5,6.7c-0.1,0-0.1,0-0.2,0c-0.1-0.2-0.3-0.5-0.2-0.8c0,0,0.1-0.1,0.2-0.1
				C396.7,6.1,396.4,6.4,396.5,6.7z"/>
			<path fill="#E1C572" d="M395.1,7.2c0.1,0.3,0.5,0.3,0.5,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.2,0
				C394.8,7.6,394.8,7.4,395.1,7.2z"/>
			<path fill="#DCB431" d="M403.5,19.2c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.5,0.4-0.5,0.7-0.5c0.4,0.3,0.1,0.7,0,1
				C403.8,19.4,403.6,19.3,403.5,19.2z"/>
		</g>
	</g>
</g>
</svg>

        )
    }
}

export default Character4Airplane;