import React from "react";
import { BrowserRouter } from "react-router-dom";
import Screen from "./Screen";
import data from "../data.json";
import firebase from "../firebase.js";
import moment from "moment";

const APP_ID = "269c73f8-9a02-4893-93f0-a63618955a09";

const clickMap = {};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      character: "character-1",
      marketingId: null,
      databaseKey: null,
      lastClick: null,
      currentPage: null,
      clickUrl: null,
      clickstream: [],
      progress: {
        scenario1: null,
        scenario2: null,
        scenario3: null
      }
    };
    this.checkProgress = this.checkProgress.bind(this);
    this.checkScenarioProgress = this.checkScenarioProgress.bind(this);
  }

  // Called by AdvanceButton
  // This function checks to see if the user has completed all 3 scenarios

  checkProgress() {
    const scenario1Status = this.checkScenarioProgress("scenario1");
    const scenario2Status = this.checkScenarioProgress("scenario2");
    const scenario3Status = this.checkScenarioProgress("scenario3");
    if (scenario1Status && scenario2Status && scenario3Status) {
      return true;
    } else {
      return false;
    }
  }

  // Checks if a scenario is complete and returns a boolean

  checkScenarioProgress(scenario) {
    if (this.state.progress && this.state.progress[scenario]) {
      return this.checkQuestionProgress(scenario);
    } else {
      return false;
    }
  }

  // Called by App.js
  // Iterates over a single scenario within the progress object in state and checks to see if the user has answered each question
  // If all 3 questions have not been answered, it returns false

  checkQuestionProgress(scenario) {
    if (
      this.state.progress[scenario]["q1"] &&
      this.state.progress[scenario]["q2"] &&
      this.state.progress[scenario]["q3"]
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Called by CharacterOptions.js
  // Sets the character prop in state to the name of the selected character
  // The value passed to this function can be modified via the JSON file "data.json"

  setCharacter = character => {
    this.setState({
      character: character
    });
  };

  // Called at initial app load
  // If the user has a marketing ID appended to their URL, it gets logged in state
  // Otherwise, if no ID is found, it prints an error message to state

  setMarketingId = id => {
    if (id) {
      this.setState({
        marketingId: id
      });
    } else {
      this.setState({
        marketingId: "No ID found"
      });
    }
  };

  // Triggered by ChoiceOptions.js
  // If a user clicks an Option on one of the many "Choice" pages, the copy of the option is logged to the progress object

  setQuizProgress = (currentScreen, element) => {
    const scenario = "scenario" + data[currentScreen].properties.scenario;
    const choice = "q" + data[currentScreen].properties.choice;
    const question = data[currentScreen].copy[0];
    const answer = element.copy[0];
    this.setState(state => ({
      progress: {
        ...state.progress,
        [scenario]: {
          ...state.progress[scenario],
          [choice]: {
            q: question,
            a: answer
          }
        }
      }
    }));
  };

  // Triggered by ResultOptions.js and Contact.js
  // If a user clicks a link that leads offsite, the URL of the link is logged to the progress object

  setOffsiteProgress = (currentScreen, element) => {
    const scenario = "scenario" + data[currentScreen].properties.scenario;
    const choice = "q" + data[currentScreen].properties.choice;
    const offsiteDestination = element.destination[1];
    this.setState(state => ({
      progress: {
        ...state.progress,
        [scenario]: {
          ...state.progress[scenario],
          [choice]: {
            ...state.progress[scenario][choice],
            offsite: offsiteDestination
          }
        }
      }
    }));
  };

  // Creates a new database key for this quiz session
  // All progress is written to this key as the user completes the quiz

  setDatabaseRef = () => {
    const newRef = firebase
      .database()
      .ref()
      .push();
    this.setState({
      databaseKey: newRef.key
    });
  };

  // Collects a name and creates a timestamp. This object is pushed to the clickstream array in App.js' state.

  updateClickstream = (name, clicked, cta = false) => {
    let lastClick = clicked ? clicked : this.state.lastClick;
    if (lastClick && clickMap[lastClick]) {
      lastClick = clickMap[lastClick];
    }

    const timeStr = moment().format("YYYY-MM-DDTHH:mm:ss");

    // const pre = {
    //   page: this.state.currentPage || APP_ID,
    //   time: timeStr,
    // };
    let clickKey = "choice";
    if (!lastClick || lastClick.startsWith("http") || cta === true) {
      clickKey = "CTA";
    }
    let click = {
      [clickKey]: lastClick || "Article page: Play Now",
      time: timeStr
    };
    const post = {
      page: name,
      time: timeStr
    };

    this.setState(({ clickstream }) => ({
      clickstream: [...clickstream, click, post],
      currentPage: name,
      clickUrl: window.location.href
    }));
  };

  updateLastClick = name => {
    let clickName = clickMap[name] ? clickMap[name] : name;
    this.setState({
      lastClick: clickName,
      clickUrl: window.location.href
    });
  };

  // Writes the state object to firebase under a new key
  // Note that, even if a user retakes the quiz with the same marketing ID, it will generate a new entry in Firebase

  // IMPORTANT!!!!
  // REPLACE FIREBASE.JS BEFORE LAUNCH! The attached database is insecure and is meant for testing purposes ONLY!

  pushProgress(reference) {
    const progress = {
      marketingId: this.state.marketingId,
      character: this.state.character,
      clickstream: this.state.clickstream,
      otherProgress: this.state.progress
    };

    firebase
      .database()
      .ref(reference)
      .update(progress);
  }

  componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search);
    this.setMarketingId(urlParams.get("mpid"));
    this.setDatabaseRef();
    document.title = "Small Steps, Big Results";
  }

  componentDidUpdate() {
    this.pushProgress("/smallStepsTwo/" + this.state.databaseKey);
  }

  render() {
    return (
      <BrowserRouter>
        <Screen
          characterReference={this.state.character}
          checkProgress={this.checkProgress}
          checkScenarioProgress={this.checkScenarioProgress}
          setCharacter={this.setCharacter}
          setQuizProgress={this.setQuizProgress}
          setOffsiteProgress={this.setOffsiteProgress}
          updateClickstream={this.updateClickstream}
          updateLastClick={this.updateLastClick}
        />
      </BrowserRouter>
    );
  }
}

export default App;
