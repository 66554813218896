import React from "react";
import data from "../data.json";

class Header extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    return (
      <div className="header_wrapper">
        {data[currentScreen].title.map((title, i) => (
          <h1 className="header" key={i}>
            {title}
          </h1>
        ))}
      </div>
    );
  }
}

export default Header;
