import React from "react";
import { Link } from "react-router-dom";
import data from "../data.json";

class AdvanceButton extends React.Component {
  render() {
    const currentScreen = this.props.currentScreen;
    const screenType = data[currentScreen].properties.type;
    const scenario = data[currentScreen].properties.scenario;
    const choice = data[currentScreen].properties.choice + 1;
    const advanceDestination = scenario + "-" + choice;

    const getDestination = () => {
      if (choice > 3 && this.props.checkProgress() === true) {
        return "/scenario-select-complete";
      } else if (choice > 3) {
        return "/scenario-select";
      } else {
        return advanceDestination;
      }
    };

    switch (screenType) {
      case "landing":
        return (
          <div className="advance_button_wrapper play_now_wrapper">
            <div className="arrow-right" />
            <Link
              to="/character-select"
              className="play_now"
              onClick={() => this.props.updateLastClick('Play now')}
            >
              Play now
            </Link>
          </div>
        );
      default:
        return (
          <div className="advance_button_wrapper">
            <Link
              to={getDestination()}
              onClick={() => this.props.updateLastClick('Next')}
            >
              Next
            </Link>
          </div>
        );
    }
  }
}

export default AdvanceButton;
