import React from "react";
import GlobalHeader from "./GlobalHeader";
import ScreenFallback from "./ScreenFallback";
import Header from "./Header";
import BodyCopy from "./BodyCopy";
import ScenarioOptions from "./ScenarioOptions";
import data from "../data.json";

class ScenarioSelect extends React.Component {
  componentDidMount(){
    this.props.updateClickstream("Scenario Select");
  }
  
  render() {
    let currentScreen = null;
    const characterReference = this.props.characterReference;
    const updateLastClick = this.props.updateLastClick;
    const updateClickstream = this.props.updateClickstream;
    let hasProgress = false
    if(this.props.checkScenarioProgress("scenario1")){
      hasProgress = true;
    } else if(this.props.checkScenarioProgress("scenario2")) {
      hasProgress = true;
    } else if (this.props.checkScenarioProgress("scenario3")) {
      hasProgress = true;
    }

    if (!hasProgress) {
      currentScreen = this.props.currentScreen;
    } else {
      const findScreen = element => {
        if (element.properties.name === "scenario-select-continue") {
          return element;
        }
      };
      currentScreen = data.findIndex(findScreen);
    }

    return (
      <div className="screen">
        <div className="screen_content">
          <GlobalHeader
            updateClickstream={updateClickstream}
          />
          <div className="content_wrapper">
            <Header currentScreen={currentScreen} />
            <BodyCopy currentScreen={currentScreen} />
          </div>
          <ScenarioOptions
            checkScenarioProgress={this.props.checkScenarioProgress}
            currentScreen={currentScreen}
            characterReference={characterReference}
            updateLastClick={updateLastClick}
          />
        </div>
        <ScreenFallback
          updateClickstream={updateClickstream}
        />
      </div>
    );
  }
}

export default ScenarioSelect;
